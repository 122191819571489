import useAvailablePeriods from "./useAvailablePeriods";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../Form";
import useFormValue from "./useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import getDisplayPeriodList from "./useWatchChartPeriods.getDisplayPeriodList";
import isEqual from "lodash/isEqual";
import updateHiddenPeriodList from "./useWatchChartPeriods.updateHiddenPeriodList";
import { usePrevious } from "@clearpoint/hooks";


let useWatchChartPeriods = (parentName) => {
	let { get } = useOldQueryStore();
	let hiddenPeriodListNameInForm = parentName ? `${parentName}.hiddenPeriods` : "hiddenPeriods";
	let showGridHiddenNameInForm = parentName ? `${parentName}.showGridHidden` : "showGridHidden";
	let { _displayPeriods: displayPeriodListInForm } = useFormValue();
	let hiddenPeriodList = useFormValue(hiddenPeriodListNameInForm);
	let showGridHiddenFlag = useFormValue(showGridHiddenNameInForm);
	let { setFormValue } = useFormContext();

	let availablePeriodList = useAvailablePeriods(parentName);

	// get available periods that are not hidden
	let displayPeriodList = useMemo(
		() => getDisplayPeriodList({ availablePeriodList, hiddenPeriodList, showGridHiddenFlag }),
		[availablePeriodList, hiddenPeriodList, showGridHiddenFlag]
	);

	let previousDisplayPeriodList = usePrevious(displayPeriodList);
	let previousHiddenPeriodList = usePrevious(hiddenPeriodList);
	let previousHiddenPeriodListLength = previousHiddenPeriodList?.length;
	useEffect(() => {
		if (!isEqual(previousDisplayPeriodList?.length, displayPeriodList?.length)) {
			setFormValue("_displayPeriods", displayPeriodList);
		}
	}, [displayPeriodList, previousDisplayPeriodList?.length, setFormValue]);

	// Derive hidden periods from display periods
	let periodListAll = get({ object: "period" });
	useEffect(() => {
		updateHiddenPeriodList({
			availablePeriodList,
			displayPeriodListInForm,
			hiddenPeriodListNameInForm,
			periodListAll,
			previousHiddenPeriodListLength,
			setFormValue,
		});
	}, [
		availablePeriodList,
		displayPeriodListInForm,
		hiddenPeriodListNameInForm,
		periodListAll,
		previousHiddenPeriodListLength,
		setFormValue,
	]);
};

export default useWatchChartPeriods;
