import FormField from "@clearpoint/old-theme/Form/Field/Field";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormSelectField from "@clearpoint/old-theme/Form/Select/Select.Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { useFieldList } from "@clearpoint/hooks";


let EditFieldsTab = () => {
	let translate = useTranslate();
	let fieldList = useFieldList({ object: "measureSeries", multipleFlag: false });
	let selectedFieldList = useFormValue("_selectedFieldList");
	let displayedFieldList = useMemo(
		() => fieldList && [...fieldList?.filter((field) => selectedFieldList?.includes(field.value))],
		[selectedFieldList, fieldList]
	);
	return (
		<>
			<FormGroup title={translate("edit.element.selectFields")} paddingBottom={theme.tinySpace}>
				<FormSelectField label="" parent="measure" object="measureSeries" name="_selectedFieldList" multipleFlag />
			</FormGroup>
			{displayedFieldList?.map((field) => (
				<FormField
					editFlag
					fieldId={field.value}
					key={field.value}
					label={field.label}
					parent="measure"
					object="measureSeries"
				/>
			))}
		</>
	);
};
export default EditFieldsTab;
