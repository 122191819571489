let addDefaultsFromLanguage = (scorecardLanguageData, languageData) => {
	if (!languageData) return;
	for (let [key, value] of Object.entries(scorecardLanguageData)) {
		if (key.endsWith(".default")) {
			let valueSansBraces = value.replace("{{", "").replace("}}", "");
			scorecardLanguageData[key] = languageData[valueSansBraces];
		}
	}
};

export default addDefaultsFromLanguage;
