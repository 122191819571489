import { isDate } from "@clearpoint/utils";

let getGenerateDescription =
	({ translate, statusList, userList, formatDate }) =>
	(criterion) => {
		let { compare, fieldId, name, value } = criterion;
		if ([compare, name, value].every((x) => x === "any")) return translate("notifications.anyChange");
		if ([compare, value].every((x) => x === "any")) return `${name} (${translate("notifications.anyChange")})`;
		let compareLookup = {
			eq: "=",
			neq: "≠",
			lt: "<",
			lte: "≤",
			gt: ">",
			gte: "≥",
		};
		compare = compareLookup[compare] || translate(`notifications.compare.${compare}`);
		let fieldValueTransformLookup = {
			statusId: () => statusList.find((x) => x.statusId === +value)?.name,
			ownerId: () => userList.find((x) => x.userId === +value)?.fullName,
		};
		if (fieldValueTransformLookup[fieldId]) value = fieldValueTransformLookup[fieldId]();
		if (isDate(value)) value = formatDate(value, "short");
		if (value === undefined) value = "";
		if (["Contains", "Does Not Contain"].includes(compare)) value = `'${value}'`;
		return `${name} ${compare} ${value}`;
	};

export default getGenerateDescription;
