let resetSession = () => {
	let session = window.localStorage.getItem("session");
	try {
		session = JSON.parse(session);
		let { token, sessionId, success } = session;
		let newSession = {
			success,
			token,
		};
		if (sessionId) newSession.sessionId = sessionId;
		window.localStorage.setItem("session", JSON.stringify(newSession));
	} catch (e) {
		let username = window.localStorage.getItem("username");
		window.localStorage.clear();
		window.localStorage.setItem("username", username);
	}
};

export default resetSession;
