let mergeWithChartState = (chartRef, currentList) => {
	if (!chartRef.current) return currentList;

	let chartState = chartRef.current.getChartState();
	let { data } = chartState;

	let output = currentList.map((x) => {
		let { id } = x;
		let dataNode =
			data?.find((node) => {
				return node.id === id;
			}, {}) || {};

		return { ...dataNode, ...x };
	});

	return output;
};

export default mergeWithChartState;
