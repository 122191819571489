import cloneDeep from "lodash/cloneDeep";

let addNewKeyResultFunction = ({ setFormValue, uuid }) => {
	let newKeyResult = {
		active: true,
		currencyCode: "     ",
		currentValue: 0,
		dataTypeId: 5,
		keyResultId: uuid(),
		name: "",
		sortOrder: 0,
		startValue: 0,
		targetValue: 100,
	};

	setFormValue("keyResults", (previousKeyResultList) => {
		if (!previousKeyResultList) previousKeyResultList = [];
		let previousKeyResultListCopy = cloneDeep(previousKeyResultList);
		let previousKeyResultListWithUpdatedSortOrders = previousKeyResultListCopy.map((x) => ({
			...x,
			sortOrder: ++x.sortOrder,
		}));
		return [newKeyResult, ...previousKeyResultListWithUpdatedSortOrders];
	});
};

export default addNewKeyResultFunction;
