import { useMemo } from "react";
import getDeepValue from "lodash/get";

import ChecklistCheckAll from "@clearpoint/old-theme/Checklist/Checklist.Check.All";
import Column from "@clearpoint/old-theme/Column";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FormChecklistCheck from "@clearpoint/old-theme/Form/Checklist/Checklist.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormSelectScorecardUserTypeId from "@clearpoint/old-theme/Form/Select/Select.ScorecardUserTypeId";
import Row from "@clearpoint/old-theme/Row";
import { theme } from "@clearpoint/old-theme/Theme";

import { useScorecardLockedFlag, useUserTypeList } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import ChecklistProvider from "@clearpoint/providers/ChecklistProvider";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";

let UserAccessTabContent = () => {
	let translate = useTranslate();
	let formValue = useFormValue();
	let scorecardLockedFlag = useScorecardLockedFlag(formValue.scorecardId);
	let { data: filteredUserList } = useFilter();
	let userTypeList = useUserTypeList();
	userTypeList = useMemo(
		() =>
			[1, 9, 2, 3, 4, 10]
				.map((userTypeId) => userTypeList.find((userType) => userType.userTypeId === userTypeId))
				.filter((userType) => filteredUserList.some((user) => user.userTypeId === userType.userTypeId)),
		[filteredUserList, userTypeList]
	);
	return (
		<>
			<FilterInput name="name" filterBy="fullName" placeholder={translate("edit.filterUsers") + "..."} />
			{userTypeList.map((userType) => {
				let userList = filteredUserList
					.filter((user) => user.userTypeId === userType.userTypeId)
					.filter((user) => user.visible);
				return (
					<FormGroup key={userType.userTypeId} title={userType.name} paddingBottom={theme.smallSpace}>
						<ChecklistProvider>
							{userList.length > 1 && (
								<Row marginBottom={theme.smallSpace}>
									<Column>
										<ChecklistCheckAll disabled={scorecardLockedFlag}>
											{translate("edit.checkAll")}
										</ChecklistCheckAll>
									</Column>
								</Row>
							)}
							{userList.map((user) => {
								let index = filteredUserList.findIndex((x) => x.userId === user.userId);
								let scorecardUserTypeFlag =
									user.userTypeId === 10 && getDeepValue(formValue, `users[${index}].selected`);
								return (
									<Row key={user.userId} marginBottom={`-${theme.smallSpace}`}>
										<Column small={scorecardUserTypeFlag ? 6 : undefined}>
											<FormChecklistCheck disabled={scorecardLockedFlag} name={`users[${index}].selected`}>
												{user.fullName}
											</FormChecklistCheck>
										</Column>
										{scorecardUserTypeFlag && (
											<Column small={6}>
												<FormSelectScorecardUserTypeId
													disabled={scorecardLockedFlag}
													name={`users[${index}].scorecardUserTypeId`}
												/>
											</Column>
										)}
									</Row>
								);
							})}
						</ChecklistProvider>
					</FormGroup>
				);
			})}
		</>
	);
};

let UserAccessTab = () => {
	let formUserList = useFormValue("users");
	return (
		<FilterProvider data={formUserList}>
			<UserAccessTabContent />
		</FilterProvider>
	);
};

export default UserAccessTab;
