import { useEffect } from "react";
import { useFormContext } from "../../../Form/Form";
import transformSeriesData from "./transformSeriesData";
import { sleep } from "@clearpoint/utils";


let useDebouncedMeasureDataUpdate = () => {
	let { formDefaultValue, setOnSubmit, formValueRef } = useFormContext();
	let initialSeriesData = formDefaultValue._seriesData;
	useEffect(() => {
		setOnSubmit({
			name: "measureData",
			onSubmit: async (submitValue) => {
				await sleep(16);
				let seriesData = formValueRef.current._seriesData;
				let measureData = transformSeriesData(initialSeriesData, seriesData);
				submitValue.measureData = measureData;
			},
		});
	}, [formValueRef, initialSeriesData, setOnSubmit]);
};

export default useDebouncedMeasureDataUpdate;
