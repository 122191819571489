import Button from "../../../Button/Button";
import ColorPickerModal from "../../../Form/Input/Color/ColorPickerModal";
import FormInput from "../../../Form/Input/Input";
import Icon from "../../../Icon/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import useFormError from "../../../Form/hooks/useFormError";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../../Theme";
import { idealTextColor } from "@clearpoint/utils";


let StyledButton = styled(Button)`
	background-color: ${(props) => props.$color};
	color: ${(props) => idealTextColor(props.$color)};
	border: ${(props) => `1px solid ${props.$errorVisible ? props.theme.danger : props.theme.gray}`};
	transition: filter 0.15s ease-in-out;
	margin-right: 0 !important;
	&:hover,
	&:active,
	&:focus {
		background-color: ${(props) => props.$color} !important;
		border: ${(props) => (props.$errorVisible ? `1px solid ${props.theme.danger}` : undefined)};
		border-color: ${(props) => (props.$errorVisible ? props.theme.danger : props.theme.gray)} !important;
		color: ${(props) => idealTextColor(props.$color)} !important;
		border-left: ${(props) => (props.$errorVisible ? "none" : undefined)} !important;
	}
	&:hover {
		filter: brightness(0.85);
	}
	border-left: ${(props) => (props.$errorVisible ? "none" : undefined)};
`;

let propTypes = {
	name: PropTypes.string,
	onColorSave: PropTypes.func,
};

let FormInputColor = ({ name, onColorSave, ...props }) => {
	let [colorPickerModalVisible, setColorPickerModalVisible] = useState(false);
	let formColor = useFormValue(name);
	let error = useFormError(name);
	let [color, setColor] = useState(formColor);
	useEffect(() => {
		setColor(formColor || theme.lightGray4);
	}, [formColor]);

	let setTruthyColor = useCallback((color) => {
		if (color) {
			setColor(color);
		}
	}, []);
	return useMemo(
		() => (
			<>
				<FormInput
					name={name}
					overflowFlag
					{...props}
					right={
						<StyledButton
							$color={color}
							color="neutral"
							$errorVisible={!!error}
							onClick={() => setColorPickerModalVisible(true)}
						>
							<Icon name="colorPicker" />
						</StyledButton>
					}
				/>
				{colorPickerModalVisible && (
					<ColorPickerModal
						formColor={formColor}
						color={color}
						setColor={setTruthyColor}
						colorPickerModalVisible={colorPickerModalVisible}
						setColorPickerModalVisible={setColorPickerModalVisible}
						name={name}
						onColorSave={onColorSave}
					/>
				)}
			</>
		),
		[color, colorPickerModalVisible, error, formColor, name, onColorSave, props, setTruthyColor]
	);
};

FormInputColor.propTypes = propTypes;

export default FormInputColor;
