import HTML from "../../../HTML";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { formatNumber } from "@clearpoint/utils";

let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplaySeriesData = ({ name, parentName }) => {
	let value = useFormValue(name);
	let numberFormat = useFormValue(`${parentName}.numberFormat`);
	return <HTML>{typeof value === "number" ? formatNumber({ number: value, numberFormat }) : value}</HTML>;
};

FormFieldDisplaySeriesData.propTypes = propTypes;
export default FormFieldDisplaySeriesData;
