import goalViewLookup from "@components/Elements/Goals/goalViewLookup";

let { DEPARTMENT_PERSONAL, INDIVIDUAL, INDIVIDUAL_PERSONAL, MANAGER_PERSONAL } = goalViewLookup;

let generateOnSubmitCallback =
	({
		buildQueryString,
		history,
		objectId,
		periodId,
		profile,
		set,
		setSupervisorId,
		toast,
		translate,
		userList,
		viewType,
	}) =>
	async (submitValue, _apiResponse, formContext) => {
		if (objectId) {
			toast.success(translate("toaster.messages.objects.objectUpdated", { object: "goal" }));
		} else {
			toast.success(translate("toaster.messages.objects.objectAdded", { object: "goal" }));
		}

		let { supervisorId: submitValueSupervisorId } = submitValue || {};
		let { formValue: { ownerId } = {} } = formContext || {};

		let owner = ownerId && userList?.find((x) => x.userId === ownerId);
		await setSupervisorId(submitValueSupervisorId, owner, { set, toast, translate });

		if (profile && owner && [DEPARTMENT_PERSONAL, INDIVIDUAL_PERSONAL, MANAGER_PERSONAL].includes(viewType)) {
			let shouldPushFlag;

			if (viewType === DEPARTMENT_PERSONAL) {
				shouldPushFlag = owner.department !== profile.department;
			} else if (viewType === INDIVIDUAL_PERSONAL) {
				shouldPushFlag = owner.userId !== profile.userId;
			} else if (viewType === MANAGER_PERSONAL) {
				shouldPushFlag = owner.supervisorId !== profile.userId;
			}

			if (shouldPushFlag) {
				history.push(
					`/index/goals${buildQueryString({
						view: INDIVIDUAL,
						mode: "list",
						periodId,
					})}`
				);
			}
		}
	};

export default generateOnSubmitCallback;
