import { toast } from "@clearpoint/services/toastService/index";
import { noop } from "@clearpoint/utils";


let getUpload = ({
	afterUpload,
	config,
	data,
	http,
	selectedFileListRef,
	setSelectedFileList,
	setUploadProgress,
	translate,
	url,
}) => {
	let selectedFileList = selectedFileListRef.current;
	if (selectedFileList.length === 0) {
		toast.warning(translate("toaster.messages.selectFile"));
		return;
	}
	let uploadList = selectedFileList.map((file) => {
		let formData = new FormData();
		let trimmedFileName = file.name.replace(/\s+/g, " ");
		formData.append("file", file, trimmedFileName);
		if (data) {
			for (let key in data) {
				formData.append(key, data[key]);
			}
		}
		return http.post(url, formData, config);
	});
	return Promise.all(uploadList)
		.then(afterUpload || noop)
		.then(() => {
			setUploadProgress(null);
			setSelectedFileList([]);
		});
};

export default getUpload;
