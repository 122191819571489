import generateRetry from "./Store.generateRetry";

let generateUndeleteFunction =
	({ clear, clearAssociated, getRoute, http }) =>
	async ({ object, objectId }) => {
		if (objectId === undefined || object === undefined) {
			throw new Error("Bad call to trash. " + JSON.stringify({ objectId, object }));
		} else {
			let retry = generateRetry();
			let url = `/${getRoute(object)}/${objectId}?undelete=true`;
			let makeRequest = () =>
				http.delete(url).catch(async (error) => {
					let { retryRequest } = retry({
						error,
						makeRequest,
						url,
					});
					return retryRequest;
				});
			let response = await makeRequest();
			clear({ objectId, object });
			clearAssociated(object);
			return response;
		}
	};

export default generateUndeleteFunction;
