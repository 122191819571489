import Button from "@clearpoint/old-theme/Button/Button";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import Modal from "@clearpoint/old-theme/Modal/Modal";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useFieldList } from "@clearpoint/hooks";


let propTypes = {
	close: PropTypes.func,
	objectId: PropTypes.number,
};
let MapOptionsModal = ({ close, objectId, ...props }) => {
	let translate = useTranslate();
	let fieldList = useFieldList({ object: "map", objectId, addFlag: !objectId });
	let { formStatus } = useFormContext();
	let { setLocalState } = useLocalState();
	let cancel = useCallback(
		() => setLocalState({ mapOptionsModalVisible: false, editMapModalWindowVisible: false }),
		[setLocalState]
	);
	return (
		<Modal data-testid="element-add-new-map-modal" close={close} {...props}>
			<ModalHeader>
				<ModalTitle>{translate(objectId ? "edit.map.mapOptions" : "edit.map.addNewMap")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{fieldList.map((field) => (
					<FormField fieldId={field.value} key={field.value} label={field.label} object="map" />
				))}
			</ModalBody>
			<ModalFooter>
				{objectId ? (
					<Button data-testid="close-button" onClick={close} type="button" color="primary">
						{translate("global.close")}
					</Button>
				) : (
					<>
						<Button data-testid="cancel-button" onClick={cancel} type="button" color="default">
							{translate("global.cancel")}
						</Button>
						<Button
							data-testid="continue-button"
							onClick={close}
							type="button"
							color="primary"
							disabled={!formStatus.valid}
						>
							{translate("edit.map.continue")}
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
};
MapOptionsModal.propTypes = propTypes;

export default MapOptionsModal;
