import FormGroup from "../../../Form/Form.Group";
import StyleWrapper from "../../../StyleWrapper";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let exclusionList = ["percentComplete", "statusId"];

let propTypes = {
	children: PropTypes.node.isRequired,
	fieldId: PropTypes.string,
	label: PropTypes.string,
	modalFlag: PropTypes.bool,
};

let FormGroupWrapper = ({ children, fieldId, label, modalFlag }) => {
	let wrapFlag = useMemo(() => modalFlag && !exclusionList.includes(fieldId), [fieldId, modalFlag]);
	return wrapFlag ? (
		<StyleWrapper minHeight="75px">
			<FormGroup title={label} paddingBottom={theme.space}>
				{children}
			</FormGroup>
		</StyleWrapper>
	) : (
		<>{children}</>
	);
};

FormGroupWrapper.propTypes = propTypes;
export default FormGroupWrapper;
