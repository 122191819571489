import FormSelectUser from "../../Form/Select/Select.User";
import useStringToIntegerFormValue from "../../Form/hooks/useStringToIntegerFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};
let User = ({ name, ...props }) => {
	useStringToIntegerFormValue(name);
	return <FormSelectUser data-testid="user" includeNotDefinedFlag name={name} {...props} />;
};
User.propTypes = propTypes;
export default User;
