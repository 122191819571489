import PropTypes from "prop-types";

import Button from "@clearpoint/old-theme/Button/Button";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FormCheckArray from "@clearpoint/old-theme/Form/CheckArray/CheckArray";
import FormCheckArrayItem from "@clearpoint/old-theme/Form/CheckArray/CheckArray.Item";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import { theme } from "@clearpoint/old-theme/Theme";

import { useScorecardName } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import SelectElementsModal from "./SelectElementsModal";
import { useOldSession } from "@clearpoint/old-session/index";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";

let alignmentMatrixPropTypes = {
  object: PropTypes.string,
};

let AlignmentMatrix = ({ object }) => {
  let translate = useTranslate();
  let { session } = useOldSession();
  let { data: filteredScorecardList } = useFilter();
  let { object: formObject, elementList, scorecardId } = useFormValue();
  object = object || formObject;
  scorecardId = scorecardId || session.scorecardId;
  let elementCount = elementList?.find((x) => x.scorecardId === scorecardId)?.elements?.length || 0;
  let scorecardName = useScorecardName(scorecardId);
  return (
    <>
      <Help section="edit-grid-layout" feature="report-matrix" />
      <FormGroup marginTop="0px" title={translate("layout.selectElements")}>
        <ModalOnClick Modal={SelectElementsModal} scorecardId={scorecardId} object={object}>
          <Button blockFlag marginBottom={theme.smallSpace} outlineFlag={!elementCount} size="small">
            {translate(
              elementCount
                ? "select.nElementsSelectedInScorecard"
                : "tabGridElements.specificElementsInScorecard",
              {
                count: elementCount,
                objects: object + "s",
                scorecardName,
              }
            )}
          </Button>
        </ModalOnClick>
      </FormGroup>
      <FilterInput
        placeholder={translate("edit.filterElements", { InsertText: "tabGridOptions.matrixScorecards" }) + "..."}
        name="scorecardName"
        filterBy="name"
      />
      <FormGroup title={translate("tabGridElements.selectMatrixScorecards")}>
        <FormCheckArray name="reportOptions.matrixScorecards">
          {filteredScorecardList
            .filter((x) => x.visible && x.scorecardId !== scorecardId)
            .map((x) => (
              <FormCheckArrayItem
                key={x.scorecardId}
                labelWeight="bold"
                marginBottom={theme.smallSpace}
                value={x.scorecardId}
              >
                {x.name}
              </FormCheckArrayItem>
            ))}
        </FormCheckArray>
      </FormGroup>
    </>
  );
};

AlignmentMatrix.propTypes = alignmentMatrixPropTypes;

let elementsTabPropTypes = {
  object: PropTypes.string,
};

let ElementsTab = ({ object }) => {
  let { get } = useOldQueryStore();
  let scorecardList = get({ object: "scorecard" });
  return (
    <FilterProvider data={scorecardList}>
      <AlignmentMatrix object={object} />
    </FilterProvider>
  );
};

ElementsTab.propTypes = elementsTabPropTypes;

export default ElementsTab;
