import DefaultForm from "../DefaultForm";
import useDefaultStoreFormValue from "./useDefaultStoreFormValue";
import { useMemo } from "react";
import useStoreFormOnSubmit from "./useStoreFormOnSubmit";
import PropTypes from "prop-types";

let propTypes = {
	beforeSubmit: PropTypes.func,
	children: PropTypes.node.isRequired,
	copyFlag: PropTypes.bool,
	defaultValue: PropTypes.object,
	duplicateRouteFlag: PropTypes.bool,
	newObjectFlag: PropTypes.bool,
	noObjectIdPropFlag: PropTypes.bool,
	object: PropTypes.string.isRequired,
	objectId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
	onSubmit: PropTypes.func,
	parent: PropTypes.string,
	parentId: PropTypes.number,
	periodId: PropTypes.number,
	preventDefaultOnSubmitFlag: PropTypes.bool,
	queryString: PropTypes.string,
	queryStringOnSubmit: PropTypes.string,
	scorecardId: PropTypes.number,
	skipClearFlag: PropTypes.bool,
	updateFlag: PropTypes.bool,
};

let StoreWrapper = ({
	beforeSubmit,
	children,
	copyFlag,
	defaultValue,
	duplicateRouteFlag,
	newObjectFlag,
	noObjectIdPropFlag,
	object,
	objectIdList,
	onSubmit,
	parent,
	parentId,
	periodId,
	preventDefaultOnSubmitFlag,
	queryString,
	queryStringOnSubmit,
	scorecardId,
	skipClearFlag,
	updateFlag,
	...props
}) => {
	let objectId = props.objectId;
	if (Object.keys(props).includes("objectId") && !noObjectIdPropFlag && !objectId && object !== "detailLayout") {
		newObjectFlag = true;
	}
	delete props.objectId;
	props.submitUnchangedFlag = props.submitUnchangedFlag || (copyFlag && !duplicateRouteFlag) || newObjectFlag;
	let defaultStoreFormValue = useDefaultStoreFormValue({
		copyFlag,
		defaultValue,
		duplicateRouteFlag,
		newObjectFlag,
		object,
		objectId,
		periodId,
		queryString,
	});
	let updateServerValue = useStoreFormOnSubmit({
		beforeSubmit,
		copyFlag,
		defaultValue,
		duplicateRouteFlag,
		newObjectFlag,
		noObjectIdPropFlag,
		object,
		objectId,
		objectIdList,
		onSubmit,
		parent,
		parentId,
		queryStringOnSubmit,
		scorecardId,
		skipClearFlag,
		updateFlag,
		...props,
	});
	return useMemo(
		() => (
			<DefaultForm
				defaultValue={defaultStoreFormValue}
				onSubmit={preventDefaultOnSubmitFlag ? onSubmit : updateServerValue}
				newObjectFlag={newObjectFlag}
				{...props}
			>
				{children}
			</DefaultForm>
		),
		[children, defaultStoreFormValue, newObjectFlag, onSubmit, preventDefaultOnSubmitFlag, props, updateServerValue]
	);
};
StoreWrapper.propTypes = propTypes;

export default StoreWrapper;
