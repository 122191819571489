import UserImageAndName from "../../../Form/shared/UserImageAndName";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
	noInitialsFlag: PropTypes.bool,
};
let LastEditedBy = ({ name, noInitialsFlag }) => {
	let { get } = useOldQueryStore();
	let userId = useFormValue(name);
	let user = get({ object: "user" })?.find((x) => x.userId === userId);
	return user ? noInitialsFlag ? user.fullName : <UserImageAndName user={user} /> : null;
};
LastEditedBy.propTypes = propTypes;

export default LastEditedBy;
