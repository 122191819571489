let generateOnDrop =
	({ addItemToList, onDropProp, removeItemFromList, reorderList }) =>
	(dropProps) => {
		if (!dropProps) return;
		let { removedIndex, addedIndex, payload } = dropProps;
		if (onDropProp) {
			let shouldDropFlag = onDropProp({ removedIndex, addedIndex, payload });
			if (shouldDropFlag === false) return;
		}
		if (removedIndex === addedIndex) return;
		if (addedIndex === null || addedIndex === undefined) return removeItemFromList(removedIndex, payload);
		if (removedIndex === null || removedIndex === undefined) return addItemToList(addedIndex, payload);
		reorderList(removedIndex, addedIndex);
	};

export default generateOnDrop;
