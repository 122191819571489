import HTML from "../../../HTML";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayAnalysis = ({ name }) => {
	let value = useFormValue(name);
	return <HTML className="htmlarea">{value}</HTML>;
};

FormFieldDisplayAnalysis.propTypes = propTypes;
export default FormFieldDisplayAnalysis;
