import Block from "../Block";
import ReactSlider from "react-slider";
import StyleWrapper from "../StyleWrapper";
import { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import roundSliderValueAwayFromMax from "./roundSliderValueAwayFromMax";
import styled from "styled-components";
import { theme } from "../Theme";
import { usePrevious } from "@clearpoint/hooks";


let StyledSlider = styled(ReactSlider)`
	margin-top: ${(props) => (props.valueLabelPosition === "side" ? "0" : "30px")};
	${(props) => (props.valueLabelPosition === "top" ? "width: 100%;" : "flex-grow: 1;")}
	height: ${(props) => props.height};
	${(props) =>
		props.valueLabelPosition === "top" &&
		`&:before {
		position: absolute;
		top: -18px;
		left: 4px;
		content: "${(function () {
			let contentDisplay = props.invert ? props.max : props.min;
			if (props.$hideLeftLabelFlag && props.value > contentDisplay) {
				return "";
			}
			return props.formatNumber ? props.formatNumber(Math.abs(contentDisplay)) : Math.abs(contentDisplay);
		})()}";
	}
	&:after {
		position: absolute;
		top: -18px;
		right: 0px;
		content: "${(function () {
			let contentDisplay = props.invert ? props.min : props.max;
			if (props.$hideRightLabelFlag && props.value < contentDisplay) {
				return "";
			}
			let maxValueDisplay;
			if (props.invert) {
				maxValueDisplay =
					props.displayValue && props.displayValue < contentDisplay ? props.displayValue : contentDisplay;
			} else {
				maxValueDisplay =
					props.displayValue && props.displayValue > contentDisplay ? props.displayValue : contentDisplay;
			}
			return props.formatNumber ? props.formatNumber(Math.abs(maxValueDisplay)) : Math.abs(maxValueDisplay);
		})()}";
	}`}
	& .track {
		margin: 6px 0px;
		height: 4px;
		background: #d8e0f3;
		border-radius: 2px;
	}
	& .thumb {
		z-index: unset !important;
		height: 16px;
		line-height: 16px;
		width: 16px;
		text-align: center;
		background-color: #0db9f0;
		border-radius: 50%;
		cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
		outline: none;
		&:after {
			position: absolute;
			top: 6px;
			left: 6px;
			width: 4px;
			height: 4px;
			border-radius: 2px;
			content: "";
		}
		${(props) =>
			!props.$hideThumbValueFlag &&
			`&:before {
			position: absolute;
			top: -17px;
			width: 25px;
			left: 50%;
			transform: translateX(-50%);
			content: "${props.value > props.min && props.value < props.max ? props.value : ""}";
		}`}
	}
	& .mark {
		display: none;
	}
`;

let propTypes = {
	disabled: PropTypes.bool,
	displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	formatNumber: PropTypes.func,
	height: PropTypes.string,
	hideThumbValueFlag: PropTypes.bool,
	onChange: PropTypes.func,
	max: PropTypes.number,
	min: PropTypes.number,
	step: PropTypes.number,
	valueLabelPosition: PropTypes.oneOf(["top", "side"]),
};
let defaultProps = {
	height: "25px",
	max: 100,
	min: 0,
	valueLabelPosition: "top",
};
let Slider = ({
	disabled,
	displayValue,
	formatNumber,
	hideThumbValueFlag,
	max,
	min,
	step,
	value,
	valueLabelPosition,
	...props
}) => {
	// displayValue shows values that are above the maximum value
	// other props: see https://zillow.github.io/react-slider/
	let marks = useMemo(() => [min, max], [max, min]);
	let ref = useRef();
	// hide labels conditionally
	let minCharacterLength = min.toString().length;
	let maxCharacterLength = max.toString().length;
	let { left: containerLeft } = ref.current?.slider?.getBoundingClientRect() || {};
	let { left: childLeft } = ref.current?.thumb0?.getBoundingClientRect() || {};
	let hideLeftLabelFlag = !hideThumbValueFlag && childLeft - containerLeft < (minCharacterLength + 1) * 6;
	let { right: containerRight } = ref.current?.slider?.getBoundingClientRect() || {};
	let { right: childRight } = ref.current?.thumb0?.getBoundingClientRect() || {};
	let hideRightLabelFlag = !hideThumbValueFlag && containerRight - childRight < (maxCharacterLength + 1) * 6;
	// delay determination of label visibility
	let [, setTriggerRefresh] = useState();
	let previousChildLeft = usePrevious(childLeft);
	useEffect(() => {
		if (childLeft !== previousChildLeft) setTimeout(() => setTriggerRefresh((x) => !x));
	}, [childLeft, previousChildLeft]);

	value = roundSliderValueAwayFromMax({ max, min, step, value });

	return valueLabelPosition === "top" ? (
		<StyledSlider
			disabled={disabled}
			displayValue={displayValue}
			formatNumber={formatNumber}
			$hideLeftLabelFlag={hideLeftLabelFlag}
			$hideRightLabelFlag={hideRightLabelFlag}
			$hideThumbValueFlag={hideThumbValueFlag}
			invert={min > max}
			marks={marks}
			max={Math.max(max, min)}
			min={Math.min(min, max)}
			ref={ref}
			step={step}
			thumbClassName="thumb"
			trackClassName="track"
			value={value}
			valueLabelPosition="top"
			{...props}
		/>
	) : (
		<Block display="flex" width="100%" alignItems="center">
			<Block
				display="flex"
				flexDirection="column"
				justifyContent="center"
				height="100%"
				paddingLeft={theme.smallSpace}
				paddingRight={theme.smallSpace}
			>
				<StyleWrapper lineHeight="1">
					<span>{formatNumber?.(Math.abs(min)) ?? Math.abs(min)}</span>
				</StyleWrapper>
			</Block>
			<StyledSlider
				disabled={disabled}
				displayValue={displayValue}
				$hideThumbValueFlag={hideThumbValueFlag}
				invert={min > max}
				marks={marks}
				max={Math.max(max, min)}
				min={Math.min(min, max)}
				ref={ref}
				step={step}
				thumbClassName="thumb"
				trackClassName="track"
				value={value}
				valueLabelPosition={valueLabelPosition}
				{...props}
			/>
			<Block
				display="flex"
				flexDirection="column"
				justifyContent="center"
				height="100%"
				paddingLeft={theme.smallSpace}
				paddingRight={theme.smallSpace}
			>
				<StyleWrapper lineHeight="1">
					<span>{formatNumber?.(Math.abs(max)) ?? Math.abs(max)}</span>
				</StyleWrapper>
			</Block>
		</Block>
	);
};
Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;
export default Slider;
