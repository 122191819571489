import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import DropdownDivider from "@clearpoint/old-theme/Dropdown/Dropdown.Divider";
import GenerateExcelOnClick from "@clearpoint/old-theme/Dropdown/GenerateExcel.OnClick";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import DropdownSplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton";
import ModalEmailPageOnClick from "@clearpoint/old-theme/Modal/Modal.EmailPage.OnClick";
import ModalGeneratePDFOnClick from "@clearpoint/old-theme/Modal/Modal.GeneratePDF.OnClick";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate, useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";

import generateCreatePPT from "./generateCreatePPT";
import { useOldSession } from "@clearpoint/old-session/index";
import EditReferenceLayoutModal from "@components/Layouts/ManageLayouts/EditReferenceLayoutModal";

let propTypes = {
	layout: PropTypes.shape({
		access: PropTypes.string,
		layoutId: PropTypes.number,
		name: PropTypes.string,
		object: PropTypes.string,
		reportType: PropTypes.string,
		scorecardId: PropTypes.number,
	}),
};

let EditSplitButton = ({ layout }) => {
	let translate = useTranslate();
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let checkAccess = useCheckAccess();
	let { set } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId } = session;
	let { access, layoutId, name, object, reportType, scorecardId } = layout;
	let adminAccessFlag = checkAccess({ access, action: "admin", scorecardId });

	let EditOnClick = useCallback(
		({ children }) => (
			<ModalOnClick Modal={EditReferenceLayoutModal} layoutId={layoutId} object={object}>
				{children}
			</ModalOnClick>
		),
		[layoutId, object]
	);

	let createPPT = useMemo(
		() => generateCreatePPT(set, translate, translateOnlyInsideCurlyBraces).bind(null, layout, periodId, null),
		[layout, periodId, set, translate, translateOnlyInsideCurlyBraces]
	);

	return !adminAccessFlag ? (
		<ModalGeneratePDFOnClick object={object} layoutId={layoutId} title={translate(name)}>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<DropdownSplitButton icon="pdf">
					<ModalGeneratePDFOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
					</ModalGeneratePDFOnClick>
					{["grid", "matrix"].includes(reportType) && (
						<GenerateExcelOnClick object={object} layoutId={layoutId} title={translate(name)}>
							<DropdownItem icon="excel">{translate("buttonBar.excelExport")}</DropdownItem>
						</GenerateExcelOnClick>
					)}
					{reportType === "dashboard" && (
						<DropdownItem icon="powerpoint" onClick={createPPT}>
							{translate("buttonBar.exportPPT")}
						</DropdownItem>
					)}
					<ModalEmailPageOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
					</ModalEmailPageOnClick>
				</DropdownSplitButton>
			</StyleWrapper>
		</ModalGeneratePDFOnClick>
	) : (
		<EditOnClick>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<DropdownSplitButton icon="editReport">
					<EditOnClick>
						<DropdownItem icon="editReport">{translate("layout.editLayout")}</DropdownItem>
					</EditOnClick>
					<DropdownDivider />
					<ModalGeneratePDFOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
					</ModalGeneratePDFOnClick>
					{["grid", "matrix"].includes(reportType) && (
						<GenerateExcelOnClick object={object} layoutId={layoutId} title={translate(name)}>
							<DropdownItem icon="excel">{translate("buttonBar.excelExport")}</DropdownItem>
						</GenerateExcelOnClick>
					)}
					{reportType === "dashboard" && (
						<DropdownItem icon="powerpoint" onClick={createPPT}>
							{translate("buttonBar.exportPPT")}
						</DropdownItem>
					)}
					<ModalEmailPageOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
					</ModalEmailPageOnClick>
				</DropdownSplitButton>
			</StyleWrapper>
		</EditOnClick>
	);
};

EditSplitButton.propTypes = propTypes;
export default EditSplitButton;
