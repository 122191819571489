import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

let useKpiDonutChartData = (chart) => {
	let { get } = useOldQueryStore();
	let periodId = useFormValue("updates.periodId");
	let { color1, color2, measureId, measureSeriesId, targetSeriesId } = chart || emptyObject;
	let measureGridData = measureId && get({ parent: "measure", parentId: measureId, object: "measureGrid" });

	let periodData = measureGridData?.find((x) => x.periodId === periodId);
	let measureSeriesValue = periodData?.["series" + measureSeriesId];
	let targetSeriesValue = periodData?.["series" + targetSeriesId];

	let data = useMemo(
		() => [
			{ value: measureSeriesValue, color: color1 },
			{ value: targetSeriesValue - measureSeriesValue, color: color2 },
		],
		[color1, color2, measureSeriesValue, targetSeriesValue]
	);

	return data;
};

export default useKpiDonutChartData;
