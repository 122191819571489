import Block from "@clearpoint/old-theme/Block";
import NoResults from "@clearpoint/old-theme/NoResults";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyArray } from "@clearpoint/utils";


let NotFound = () => {
	let translate = useTranslate();
	return (
		<Block height="100%" display="flex" flexDirection="column" justifyContent="center" fontWeight={theme.bold}>
			<Block fontSize="14px" textAlign="center" marginBottom={theme.mediumSpace}>
				{translate("placeholder.notFound")}
			</Block>
			<Block>
				<NoResults results={emptyArray} />
			</Block>
		</Block>
	);
};

export default NotFound;
