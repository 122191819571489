import isArray from "lodash/isArray";

import { emptyArray } from "@clearpoint/utils";

import { formatSeriesNumber } from "./utils";


// chartFormat.yAxis comes down from API as an object for gauge charts
let createGaugeYAxis = (yAxis, yAxisFormat) => ({
	...yAxis,
	labels: {
		...yAxis.labels,
		formatter () {
			return formatSeriesNumber(this.value, yAxisFormat);
		},
	},
});

let createTransposedYAxis = (yAxisList, yAxisFormat) => {
	let yAxis = yAxisList?.[0];
	if (yAxis) {
		return [
			{
				...yAxis,
				labels: {
					...yAxis.labels,
					formatter () {
						return formatSeriesNumber(this.value, yAxisFormat);
					},
				},
			},
			...yAxisList.slice(1),
		];
	}
	return yAxisList;
};

let createDefaultYAxis = (yAxisList, yAxisFormat, yAxis2Format) => {
	let firstYAxis = yAxisList?.[0];
	if (firstYAxis) {
		firstYAxis = {
			...firstYAxis,
			allowDecimals: yAxisFormat.allowDecimals,
			labels: {
				...firstYAxis.labels,
				formatter () {
					return formatSeriesNumber(this.value, yAxisFormat);
				},
			},
		};
	}
	if (yAxisList && yAxisList.length > 1) {
		let secondYAxis = yAxisList[1];
		secondYAxis = {
			...secondYAxis,
			allowDecimals: yAxis2Format.allowDecimals,
			labels: {
				...secondYAxis.labels,
				formatter () {
					return formatSeriesNumber(this.value, yAxis2Format);
				},
			},
		};
		return [firstYAxis, secondYAxis];
	} else {
		return [firstYAxis];
	}
};

let addPlotBands = (yAxisList, plotBandList) => {
	let yAxis = yAxisList?.[0];

	if (yAxis) {
		// the incoming plotBandList contains elements that can either be plot bands or plot lines
		let plotLineList = plotBandList
			.filter((band) => band.from === band.to)
			.map((band) => ({
				color: band.color,
				value: band.from,
				width: 2,
			}));
		plotBandList = plotBandList
			.filter((band) => band.from !== band.to)
			.map((band) => ({
				color: band.color,
				from: band.from,
				to: band.to,
			}));

		return [
			{
				...yAxis,
				plotBands: [...(yAxis.plotBands || emptyArray), ...plotBandList],
				plotLines: [...(yAxis.plotLines || emptyArray), ...plotLineList],
			},
			...yAxisList.slice(1),
		];
	}
	return yAxisList;
};

let configureYAxis = (chartData) => {
	let {
		chartFormat: {
			chart: { polar: polarFlag, type },
			yAxis,
		},
		enablePlotBands: enablePlotBandsFlag,
		plotBands: plotBandList,
		transposed: transposedFlag,
		yAxisFormat,
		yAxis2Format,
	} = chartData;

	if (type === "pie" || polarFlag) {
		return yAxis;
	} else if (type === "gauge" || type === "solidgauge") {
		return createGaugeYAxis(yAxis, yAxisFormat);
	}

	if (!isArray(yAxis)) {
		yAxis = [yAxis];
	}

	yAxis = transposedFlag ? createTransposedYAxis(yAxis, yAxisFormat) : createDefaultYAxis(yAxis, yAxisFormat, yAxis2Format);

	return enablePlotBandsFlag ? addPlotBands(yAxis, plotBandList) : yAxis;
};

export default configureYAxis;
