import "@clearpoint/query";
import "@clearpoint/styles";
import { getEnvironment } from "@clearpoint/utils";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
import "./initialize/initializeChecksum";
import "./initialize/initializeSentry";
import * as serviceWorker from "./serviceWorker";

let environment = getEnvironment();

// Display Welcome Message in console
console.log(
	`
	   _____ __                 ___         _       __  
	  / ___// /___  ___ _ ____ / _ \\ ___   (_)___  / /_ 
	 / /__ / // -_)/ _ \`// __// ___// _ \\ / // _ \\/ __/ 
	 \\___//_/ \\__/ \\_,_//_/  /_/    \\___//_//_//_/\\__/  
		  ____ __              __                       
		 / __// /_ ____ ___ _ / /_ ___  ___ _ __ __     
		_\\ \\ / __// __// _ \`// __// -_)/ _ \`// // /     
	   /___/ \\__//_/   \\_,_/ \\__/ \\__/ \\_, / \\_, /      
									  /___/ /___/       
	
	            Version ${import.meta.env.VITE_APP_VERSION}-${environment} ${import.meta.env.VITE_GIT_SHA}
	
	  Do you dream in code? So do we. Check out our job 
	   openings at www.clearpointstrategy.com/careers   
	`
);

Modal.setAppElement(".root");

// expose fetch events
(function () {
	var origOpen = XMLHttpRequest.prototype.open;
	XMLHttpRequest.prototype.open = function (...args) {
		this.addEventListener("load", function () {
			let method = args[0];
			let url = args[1];
			let response;
			try {
				response = JSON.parse(this.response);
			} catch (e) {}
			document.dispatchEvent(new CustomEvent("fetch", { detail: { method, url, response } }));
		});
		origOpen.apply(this, arguments);
	};
})();

let container = document.querySelector(".root");
let root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
