import EditGoalModalWindowContent from "./EditGoalModalWindowContent";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import useBeginningOfMonthDate from "./useBeginningOfMonthDate";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOnGoalSubmit from "./useOnGoalSubmit";
import usePreviousAndNextGoalNavigation from "./usePreviousAndNextGoalNavigation";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import processIncomingKeyResultList from "../processIncomingKeyResultList";
import { findKeyResultUpdates } from "@clearpoint/utils";

let propTypes = {
	close: PropTypes.func.isRequired,
	linkedElement: PropTypes.shape({
		fieldId: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		scorecardId: PropTypes.number,
	}),
	modalWindowVisible: PropTypes.bool.isRequired,
	objectId: PropTypes.number,
};

let EditGoalModalWindow = ({ close, linkedElement, modalWindowVisible, ...props }) => {
	let { get } = useOldQueryStore();

	let objectIdProp = props.objectId;
	let [objectId, setObjectId] = useState(props.objectId);
	useEffect(() => {
		setObjectId(objectIdProp);
	}, [objectIdProp]);

	let getBeginningOfMonthDate = useBeginningOfMonthDate();
	let { periodId: currentPeriodId } = useOldSession().session;
	let periodList = get({ object: "period" });

	let goal = objectId && get({ object: "goal", objectId });
	let keyResultList = objectId && get({ parent: "goal", parentId: objectId, object: "keyResult" });
	let linkList = goal && get({ parent: "goal", parentId: objectId, object: "link", periodId: goal.periodId });
	let defaultValue = useMemo(() => {
		if (objectId) {
			return goal
				? {
						...goal,
						endDate: periodList?.find?.((x) => x.periodId === goal.periodId)?.periodDate,
						keyResults: processIncomingKeyResultList(keyResultList),
						links: linkList || [],
				  }
				: undefined;
		}
		let currentPeriodEndDate = periodList?.find((x) => x.periodId === currentPeriodId)?.periodDate;

		return {
			endDate: currentPeriodEndDate,
			links: linkedElement ? [linkedElement] : undefined,
			startDate: getBeginningOfMonthDate(currentPeriodEndDate),
			visibility: 2,
		};
	}, [currentPeriodId, getBeginningOfMonthDate, goal, keyResultList, linkList, linkedElement, objectId, periodList]);

	let beforeSubmit = useCallback(
		(submitValue) => {
			let submitValueCopy = cloneDeep(submitValue);
			let updateSortOrder = (keyResult, i) => ({ ...keyResult, sortOrder: i });
			let removeUUID = (keyResult) => {
				if (typeof keyResult.keyResultId === "string") {
					delete keyResult.keyResultId;
				}
				return keyResult;
			};

			if (isArray(submitValueCopy.keyResults)) {
				submitValueCopy.keyResults = submitValueCopy.keyResults.map((x, i) => {
					x = updateSortOrder(x, i);
					x = removeUUID(x);
					delete x.newKeyResultFlag;
					return x;
				});
			}

			submitValueCopy.keyResults = findKeyResultUpdates({
				original: defaultValue.keyResults,
				updated: submitValueCopy.keyResults,
			});

			return submitValueCopy;
		},
		[defaultValue]
	);

	let onSubmit = useOnGoalSubmit(objectId);

	let { navigableGoalListFlag, openPreviousGoal, openNextGoal } = usePreviousAndNextGoalNavigation();

	return (
		<ModalWindowEdit
			{...props}
			beforeSubmit={beforeSubmit}
			close={close}
			defaultValue={defaultValue}
			modalWindowVisible={modalWindowVisible}
			object="goal"
			objectId={objectId}
			onSubmit={onSubmit}
			setObjectId={setObjectId}
		>
			<EditGoalModalWindowContent
				navigableGoalListFlag={navigableGoalListFlag}
				openNextGoal={openNextGoal}
				openPreviousGoal={openPreviousGoal}
			/>
		</ModalWindowEdit>
	);
};

EditGoalModalWindow.propTypes = propTypes;
export default EditGoalModalWindow;
