import Block from "../Block";
import Hide from "../Hide";
import Loading from "../Loading";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";
import React, { useMemo } from "react";
import TabButton from "./TabButton/TabButton";
import { useFormContext } from "../Form/Form";
import useHandleTabChange from "./useHandleTabChange";
import useMissingTitleError from "./useMissingTitleError";
import useSetInitialSelectedTab from "./useSetInitialSelectedTab";
import { useWindowContext } from "./ModalWindow";
import { useWindowTabContext } from "./WindowTabProvider";
import PropTypes from "prop-types";
import groupTabsByTabGroup from "./groupTabsByTabGroup";
import { theme } from "../Theme";
import { emptyArray } from "@clearpoint/utils";
import { useWindowSize } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.node,
};

let isTabGroupSelected = (tabGroup, selectedGroup) => tabGroup && selectedGroup === tabGroup;
let isVisible = (props) => (!props ? false : Object.keys(props).includes("visible") ? props.visible : true);

let ModalWindowTabContainer = ({ children }) => {
	let { loadingFlag } = useWindowContext();
	let { selectedTab, setSelectedTab, selectedTabList, noFormFlag, setNextTab } = useWindowTabContext();
	let formContext = useFormContext();
	let formValidFlag = formContext?.formStatus?.valid;
	let fieldListLoadingError = formContext?.formError?.fieldListLoadingError;
	let handleTabChange = useHandleTabChange({
		formValidFlag,
		loadingFlag,
		fieldListLoadingError,
		noFormFlag,
		setNextTab,
		setSelectedTab,
	});
	useMissingTitleError(children);
	useSetInitialSelectedTab({ children, setSelectedTab });
	let childList = useMemo(() => React.Children.toArray(children).filter((x) => !!x), [children]);
	let selectedGroup = useMemo(() => childList[selectedTab]?.props.tabGroup, [childList, selectedTab]);
	let groupedTabList = useMemo(() => groupTabsByTabGroup(childList), [childList]);
	let { height: windowHeight } = useWindowSize();

	return (
		<>
			<LocalStateProvider buttonStateList={emptyArray}>
				<Block
					backgroundColor={theme.backgroundGrey}
					display="flex"
					flexBasis="20%"
					flexDirection="column"
					flexGrow="0 !important"
					height="100% !important"
					minWidth="250px"
					overflowY="auto"
					width="20% !important"
				>
					{groupedTabList.map(
						(x) =>
							isVisible(x?.props) && (
								<React.Fragment key={x.props.title}>
									{x.tabGroupMemberList ? (
										<Block maxHeight={`${windowHeight * 0.5}px`} overflowY="scroll">
											{x.tabGroupMemberList.map((child) => (
												<TabButton
													handleTabChange={handleTabChange}
													key={child.title}
													selectedFlag={selectedTab === child.tabIndex}
													tabGroupSelectedFlag={isTabGroupSelected(x.tabGroup, selectedGroup)}
													tabIndex={child.tabIndex}
													{...child.props}
												/>
											))}
										</Block>
									) : (
										<TabButton
											handleTabChange={handleTabChange}
											key={x.props.title}
											selectedFlag={selectedTab === x.tabIndex}
											tabGroupSelectedFlag={isTabGroupSelected(x.props.tabGroup, selectedGroup)}
											tabIndex={x.tabIndex}
											{...x.props}
										/>
									)}
								</React.Fragment>
							)
					)}
				</Block>
			</LocalStateProvider>
			{loadingFlag ? (
				<Block height="100%" flex="1" backgroundColor={theme.white}>
					<Loading />
				</Block>
			) : (
				childList.map((x, i) =>
					isVisible(x?.props) && selectedTabList.includes(i) ? (
						<Hide
							key={i}
							visible={selectedTab === i}
							padding={`${theme.space} ${theme.mediumSpace}`}
							height="100%"
							flex="1"
							overflowY="auto"
							overflowX="hidden"
							backgroundColor={theme.white}
						>
							{x}
						</Hide>
					) : null
				)
			)}
		</>
	);
};

ModalWindowTabContainer.propTypes = propTypes;

export default ModalWindowTabContainer;
