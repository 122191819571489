import handleClear from "./Store.reducer.handleClear";
import handleDataUpdate from "./Store.reducer.handleDataUpdate ";

const reducer = (state, action) => {
	if (action.type === "HARD_SET") {
		return action.state;
	}
	// action: id, value OR clear
	let { ListOnlyFlag } = action;
	delete action.ListOnlyFlag;
	let newState = [...state];
	if (action.clearFlag) {
		let { stopPropagationFlag } = action;
		delete action.stopPropagationFlag;
		delete action.clearFlag;
		// find parent
		handleClear({ action, newState, ListOnlyFlag, stopPropagationFlag });
	} else {
		handleDataUpdate({ action, newState });
	}
	return newState;
};

export default reducer;
