import { useCallback, useState, useEffect } from "react";

export default function useWindowSize() {
	const isClient = typeof window === "object";

	let getSize = useCallback(
		() => ({
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
		}),
		[isClient]
	);

	const isSmall = (width) => width <= 991 && width > 768;
	const isMedium = (width) => width <= 1199 && width >= 992;
	const isLarge = (width) => width >= 1200;

	const getClass = useCallback(
		(width) => (isLarge(width) ? "large" : isMedium(width) ? "medium" : isSmall(width) ? "small" : "tiny"),
		[]
	);

	const [windowSize, setWindowSize] = useState(getSize());
	const [displayClass, setDisplayClass] = useState(getClass(getSize().width));

	useEffect(() => {
		function handleResize() {
			const size = getSize();
			setWindowSize(size);
			setDisplayClass(getClass(size.width));
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [getClass, getSize]); // Empty array ensures that effect is only run on mount and unmount

	let returnObj = {
		height: windowSize.height,
		width: windowSize.width,
		displayClass: displayClass,
	};
	return returnObj;
}
