import ModalConfirm from "../../Modal/Modal.Confirm";
import Select from "./FormSelect";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	object: PropTypes.string,
};
let FormSelectReportType = ({ name, object, ...props }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let { setFormValue, clearFormValue } = useFormContext();
	let [{ confirmModalVisible, newValue }, setState] = useStateObject({
		confirmModalVisible: false,
		newValue: null,
	});
	let options = useMemo(() => {
		let grid = { value: "grid", label: translate("layout.standardLayout") };
		let matrix = { value: "matrix", label: translate("layout.alignmentMatrix") };
		let dashboard = { value: "dashboard", label: translate("layout.dashboard") };
		let gantt = { value: "gantt", label: translate("layout.gantt") };
		let optionsLookup = {
			measure: [grid, matrix, dashboard],
			initiative: [grid, matrix, gantt],
			objective: [grid, matrix],
		};
		return optionsLookup[object];
	}, [object, translate]);
	let closeConfirmModal = useCallback(() => {
		setState({
			confirmModalVisible: false,
		});
	}, [setState]);
	let changeReportType = useCallback(
		(e) => {
			let newValue = e?.value;
			if (options.some((x) => x.value === newValue)) {
				let switchingToDashboardFlag = newValue === "dashboard" && value !== "dashboard";
				let switchingFromDashboardFlag = newValue !== "dashboard" && value === "dashboard";
				if (switchingToDashboardFlag || switchingFromDashboardFlag) {
					setState({
						confirmModalVisible: true,
						newValue,
					});
				} else {
					setFormValue(name, newValue);
				}
			}
		},
		[name, options, setFormValue, setState, value]
	);

	let confirmReportType = useCallback(() => {
		setFormValue(name, newValue);
		clearFormValue("elementList");
	}, [clearFormValue, name, newValue, setFormValue]);
	return (
		<>
			<Select name={name} options={options} onChange={changeReportType} preventDefaultOnChangeFlag {...props} />
			{confirmModalVisible && (
				<ModalConfirm
					body={translate("layout.confirmReportType")}
					close={closeConfirmModal}
					confirmButtonColor="warning"
					confirmButtonText={translate("global.yes")}
					modalVisible={confirmModalVisible}
					onConfirm={confirmReportType}
					title={translate("layout.changeReportType")}
				/>
			)}
		</>
	);
};
FormSelectReportType.propTypes = propTypes;

export default FormSelectReportType;
