import FormSelect from "@clearpoint/old-theme/Form/Select/FormSelect";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getDeepValue from "lodash/get";

let propTypes = {
	name: PropTypes.string,
};

let PodWidthSelect = ({ name }) => {
	let translate = useTranslate();
	let { formDefaultValue } = useFormContext();
	let incomingValue = getDeepValue(formDefaultValue, name);

	let options = useMemo(() => {
		let defaultOptionList = [
			{ label: translate("edit.podWidth.full"), value: "col-xs-12" },
			{ label: translate("edit.podWidth.3/4"), value: "col-xs-9" },
			{ label: translate("edit.podWidth.2/3"), value: "col-xs-8" },
			{ label: translate("edit.podWidth.1/2"), value: "col-xs-6" },
			{ label: translate("edit.podWidth.1/3"), value: "col-xs-4" },
			{ label: translate("edit.podWidth.1/4"), value: "col-xs-3" },
		];

		if (!incomingValue || defaultOptionList.map((x) => x.value).includes(incomingValue)) {
			return defaultOptionList;
		}

		let incomingWidth = incomingValue.replace("col-xs-", "");
		return [
			{ label: translate("edit.podWidth.custom", { width: `${incomingWidth}/12` }), value: incomingValue },
			...defaultOptionList,
		];
	}, [incomingValue, translate]);

	return <FormSelect label={translate("edit.podWidth")} name={name} options={options} />;
};

PodWidthSelect.propTypes = propTypes;
export default PodWidthSelect;
