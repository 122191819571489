import PaginatedImageSelector from "../../Form/Image/Paginate/ImagePaginate";
import PropTypes from "prop-types";

// TO DO: Add explorer, upload to server
let propTypes = {
	disabled: PropTypes.bool,
	fieldId: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	parentName: PropTypes.string,
};
let Image = ({ disabled, fieldId, parentName, name, ...props }) => {
	if (name.endsWith(".value")) name = name.slice(0, -6);
	return <PaginatedImageSelector {...props} disabled={disabled} name={props.name || name} customFieldFlag />;
};
Image.propTypes = propTypes;
export default Image;
