import Select from "../Select/Select";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "@clearpoint/utils";
import { useEffectOnce } from "@clearpoint/hooks";



let propTypes = {
	defaultValue: PropTypes.object,
	filter: PropTypes.func,
	filterBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	name: PropTypes.string.isRequired,
	noFilterStyleFlag: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
};

let FilterSelect = ({ filter: customFilter, defaultValue, filterBy, name, options, noFilterStyleFlag, ...props }) => {
	let { filter = noop, setFilterSelectValue } = useFilter() || {};
	let [value, setValue] = useState();
	useEffectOnce(() => {
		if (defaultValue) {
			setValue(defaultValue);
		}
	});
	return (
		<Select
			onChange={(e) => {
				setFilterSelectValue(e.value);
				if (customFilter) customFilter(e);
				else
					filter({
						[name]: (x) => x[filterBy] === e.value || e.value === 0,
					});
				let selectValue = options.find((x) => x.value === e.value);
				setValue(selectValue);
			}}
			options={options}
			inputGroupFlag={!noFilterStyleFlag}
			value={value}
			{...props}
		/>
	);
};

FilterSelect.propTypes = propTypes;

export default FilterSelect;
