import { useCallback, useEffect, useMemo } from "react";
import usePrevious from "./usePrevious";
import useQueryParameters from "./useQueryParameters";
import { useOldSession } from "@clearpoint/old-session/index";

let useReportFilter = () => {
	let { filter, layoutId } = useQueryParameters();
	let previousLayoutId = usePrevious(layoutId);
	if (filter) filter = decodeURIComponent(filter);
	let { session, setSession } = useOldSession();
	let reportFilter = session.reportFilter || filter;
	let setReportFilter = useCallback(
		(value) => {
			setSession({
				reportFilter: value,
			});
		},
		[setSession]
	);
	let clearReportFilter = useCallback(() => {
		setSession({
			reportFilter: null,
		});
	}, [setSession]);
	useEffect(() => {
		if (previousLayoutId && layoutId !== previousLayoutId && reportFilter) {
			clearReportFilter();
		}
	}, [clearReportFilter, layoutId, previousLayoutId, reportFilter]);
	return useMemo(
		() => ({ reportFilter, setReportFilter, clearReportFilter }),
		[clearReportFilter, reportFilter, setReportFilter]
	);
};

export default useReportFilter;
