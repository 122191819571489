import { useLocation } from "react-router-dom";
import { useOldQueryStore } from "@clearpoint/old-query-store";

let useUserId = () => {
	let location = useLocation();
	let { get } = useOldQueryStore();
	let getDataFlag = !location.pathname.includes("login") && !location.pathname.includes("reset-password");
	let profile = getDataFlag ? get({ object: "profile" }) : undefined;
	return profile?.userId;
};

export default useUserId;
