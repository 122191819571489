import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

import Block from "../Block";
import { theme } from "../Theme";
import Animation from "./Animation";
import LoadingText from "./LoadingText";

let propTypes = {
	className: PropTypes.string,
	offsetTop: PropTypes.string,
	size: PropTypes.string,
	store: PropTypes.object,
	translate: PropTypes.func,
};

let StyledBlock = styled(Block)`
		opacity: 0;
		animation: fadeIn 1s;
		animation-delay: 0.5s;
		animation-fill-mode: forwards;
		@keyframes fadeIn {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
	`;

let Loading = ({ className, offsetTop, size, store, translate }) => {
	return (
		<StyledBlock
			textAlign="center"
			height={size === "small" ? "20px" : "100%"}
			width="100%"
			display="flex"
			flexDirection="column"
			marginTop={offsetTop || 0}
			alignItems="center"
			justifyContent="center"
			className={classNames(className, "cps-loading")}
		>
			<Block>
				<Animation />
				{size !== "small" && (
					<Block
						marginTop={theme.tinySpace}
						fontWeight={theme.bold}
						fontSize="14px"
						marginBottom={theme.smallSpace}
					>
						<LoadingText store={store} translate={translate} />
					</Block>
				)}
			</Block>
		</StyledBlock>
	);
};

Loading.propTypes = propTypes;

export default Loading;
