// all queries with the object "objective" should go here
import { useMutation, useQuery } from "@tanstack/react-query";
import { DETAIL, LIST, STATUS } from "../types";
import { CUSTOM_FIELD, OBJECTIVE, EVALUATION, LINK } from "../objects";
import { OBJECTIVE_PATH } from "../paths";
import http from "@clearpoint/services/httpService/index";
import { invalidateElement } from "./utils/invalidateElement";
import { captureMissingObjectId } from "./utils/captureMissingObjectId";
import { captureMissingPeriodId } from "./utils/captureMissingPeriodId";
import { queryClient } from "../QueryProvider";

// To do: add other objective queries
// To do: add tests

let useObjectiveListQuery = ({ periodId, scorecardId }) => {
	return useQuery({
		enabled: !!periodId && !!scorecardId,
		queryKey: [{ object: OBJECTIVE, scorecardId, periodId, type: LIST }],
		queryFn: () => http.get(`/scorecards/${scorecardId}${OBJECTIVE_PATH}?periodId=${periodId}`),
		placeholderData: [],
		select: (data) =>
			data.sort((a, b) => (a.name > b.name ? 1 : -1)).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)),
	});
};

let useObjectiveDetailQuery = ({ objectId }) => {
	return useQuery({
		enabled: !!objectId, // note that queries should simply be disabled if they are missing parameters
		// this query could be dependent on another one to get the objectId
		queryKey: [{ object: OBJECTIVE, type: DETAIL }],
		queryFn: () => http.get(`${OBJECTIVE_PATH}/${objectId}`),
		placeholderData: {},
	});
};

let useObjectiveStatusQuery = ({ objectId, periodId }) => {
	return useQuery({
		enabled: !!objectId && !!periodId,
		queryKey: [{ object: OBJECTIVE, periodId, type: STATUS }],
		queryFn: () => http.get(`${OBJECTIVE_PATH}/${objectId}/status?periodId=${periodId}`),
		placeholderData: {},
	});
};

let useObjectiveLinkListQuery = ({ objectId, periodId }) => {
	return useQuery({
		enabled: !!objectId && !!periodId,
		queryKey: [{ object: LINK, parent: OBJECTIVE, parentId: objectId, periodId, type: LIST }],
		queryFn: () => http.get(`${OBJECTIVE_PATH}/${objectId}/links?periodId=${periodId}`),
		placeholderData: {},
	});
};

let useObjectiveEvaluationListQuery = ({ objectId }) => {
	return useQuery({
		enabled: !!objectId,
		queryKey: [{ object: EVALUATION, parent: OBJECTIVE, parentId: objectId, type: LIST }],
		queryFn: () => http.get(`${OBJECTIVE_PATH}/${objectId}/evaluation`),
		placeholderData: {},
	});
};

let useObjectiveCustomFieldListQuery = ({ objectId, periodId }) => {
	return useQuery({
		enabled: !!objectId && !!periodId,
		queryKey: [{ object: CUSTOM_FIELD, parent: OBJECTIVE, parentId: objectId, periodId, type: LIST }],
		queryFn: () => http.get(`${OBJECTIVE_PATH}/${objectId}/customFields?periodId=${periodId}`),
		placeholderData: {},
	});
};

let useCreateObjectiveMutation = () => {
	return useMutation({
		mutationFn: (data) => http.post(OBJECTIVE_PATH, data),
		onSettled: () => invalidateElement({ element: OBJECTIVE }),
		// invalidation should occur for every mutation
		// reference the "clear.js" file of the old query store for invalidation logic
	});
};

let useEditObjectiveMutation = ({ objectId }) => {
	return useMutation({
		mutationFn: (data) => {
			if (!objectId) return captureMissingObjectId();
			return http.put(`${OBJECTIVE_PATH}/${objectId}`, data);
		},
		onSettled: () => invalidateElement({ element: OBJECTIVE }),
	});
};

let useUpdateObjectiveMutation = ({ objectId, periodId }) => {
	return useMutation({
		mutationFn: (data) => {
			if (!objectId) return captureMissingObjectId();
			if (!periodId) return captureMissingPeriodId();
			return http.put(`${OBJECTIVE_PATH}/${objectId}/status?periodId=${periodId}`, data);
		},
		onSettled: () => invalidateElement({ element: OBJECTIVE }),
	});
};

let useDeleteObjectiveMutation = ({ objectId, scorecardId, periodId }) => {
	return useMutation({
		mutationFn: () => {
			if (scorecardId && periodId) {
				let listKey = [{ object: OBJECTIVE, scorecardId, periodId, type: LIST }];
				let previousList = queryClient.getQueryData(listKey);
				if (previousList?.find((x) => x.objectId === objectId)) {
					let newList = previousList.map((x) => (x.objectId === objectId ? { ...x, active: false } : x));
					queryClient.setQueryData(listKey, newList);
				}
			} else {
				console.warn("Pass a periodId and scorecardId to update list data");
			}
			if (!objectId) return captureMissingObjectId();
			return http.delete(`${OBJECTIVE_PATH}/${objectId}`);
		},
		onSettled: () => invalidateElement({ element: OBJECTIVE }),
	});
};

let useUndeleteObjectiveMutation = ({ objectId, scorecardId, periodId }) => {
	return useMutation({
		mutationFn: () => {
			if (scorecardId && periodId) {
				let listKey = [{ object: OBJECTIVE, scorecardId, periodId, type: LIST }];
				let previousList = queryClient.getQueryData(listKey);
				if (previousList?.find((x) => x.objectId === objectId)) {
					let newList = previousList.map((x) => (x.objectId === objectId ? { ...x, active: true } : x));
					queryClient.setQueryData(listKey, newList);
				}
			} else {
				console.warn("Pass a periodId and scorecardId to update list data");
			}
			if (!objectId) return captureMissingObjectId();
			return http.delete(`${OBJECTIVE_PATH}/${objectId}?undelete=true`);
		},
		onSettled: () => invalidateElement({ element: OBJECTIVE }),
	});
};

let useDestroyObjectiveMutation = ({ objectId }) => {
	return useMutation({
		mutationFn: () => {
			if (!objectId) return captureMissingObjectId();
			return http.delete(`/delete/${OBJECTIVE}/${objectId}`);
		},
		onSettled: () => invalidateElement({ element: OBJECTIVE }),
	});
};

export {
	useObjectiveListQuery,
	useObjectiveDetailQuery,
	useObjectiveEvaluationListQuery,
	useObjectiveStatusQuery,
	useObjectiveLinkListQuery,
	useObjectiveCustomFieldListQuery,
	useCreateObjectiveMutation,
	useEditObjectiveMutation,
	useUpdateObjectiveMutation,
	useDeleteObjectiveMutation,
	useUndeleteObjectiveMutation,
	useDestroyObjectiveMutation,
};
