import describePie from "./describePie";
import describeDefault from "./describeDefault";
import describeGauge from "./describeGauge";
import describePolar from "./describePolar";
import describeTransposed from "./describeTransposed";
import describePlotBandList from "./describePlotBandList";
import { getChartType } from "../utils";

let configureAccessibility = ({ chartData, series, translate }) => {
	let { chartType, name } = chartData;
	let formatType = chartData.chartFormat.chart.type;
	if (formatType === "solidgauge") formatType = "gauge";
	let chartTypeKey = chartType > 0 ? "select.chartTypes.chartTypeId" + chartType : `chart.${formatType}`;
	let chartTypeDescription = `${translate("logFieldLabels.chartType")}: ${translate(chartTypeKey)}`;
	let description = `${name} (${chartTypeDescription})\n`;
	let plotBandList = chartData.plotBands;
	if (plotBandList) description += "\n" + describePlotBandList({ plotBandList, translate }) + "\n";
	let type = getChartType(chartData);
	let describeLookup = {
		pie: describePie,
		default: describeDefault,
		gauge: describeGauge,
		polar: describePolar,
		transposed: describeTransposed,
	};
	if (series && describeLookup[type]) {
		description += describeLookup[type]({ chartData, series, translate });
	}
	return {
		description,
	};
};

export default configureAccessibility;
