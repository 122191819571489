import { memo,useMemo } from "react";
import PropTypes from "prop-types";
import BootstrapButton from "react-bootstrap/Button";

import { useTranslate } from "@clearpoint/translate";

import { useFormContext } from "../Form/Form";
import Icon from "../Icon/Icon";
import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";
import { sizes as allSizes } from "../values";

let sizes = { ...allSizes };
delete sizes.medium; // no btn-md class

let propTypes = {
	activeFlag: PropTypes.bool,
	alignSelf: PropTypes.string,
	as: PropTypes.oneOf(["a", "input", "button"]),
	blockFlag: PropTypes.bool,
	border: PropTypes.string,
	centerFlag: PropTypes.bool,
	children: PropTypes.node,
	color: PropTypes.oneOf([
		"blank",
		"danger",
		"dark",
		"default",
		"info",
		"light",
		"nav",
		"neutral",
		"primary",
		"secondary",
		"success",
		"transparent",
		"warning",
		"white",
	]),
	cursor: PropTypes.string,
	"data-ascb": PropTypes.string,
	"data-object": PropTypes.string,
	"data-object-id": PropTypes.number,
	"data-testid": PropTypes.string,
	delayFlag: PropTypes.bool,
	disabled: PropTypes.bool,
	downloadFlag: PropTypes.bool,
	flex: PropTypes.string,
	float: PropTypes.string,
	href: PropTypes.string,
	id: PropTypes.string,
	noShadowFlag: PropTypes.bool,
	noWrapFlag: PropTypes.bool,
	onClick: PropTypes.func,
	outlineFlag: PropTypes.bool,
	separator: PropTypes.string,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	target: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
};

let defaultProps = {
	// as: "button", // bug in react bootstrap prevents this from working with type={type}
	color: "white",
	outlineFlag: false,
	size: "medium",
};

let Button = ({
	activeFlag,
	alignSelf,
	as,
	blockFlag,
	backgroundColor,
	border,
	centerFlag,
	children,
	className,
	color,
	"data-acsb": dataAcsb,
	"data-object": dataObject,
	"data-object-id": dataObjectId,
	"data-testid": dataTestId,
	disabled,
	downloadFlag,
	flex,
	float,
	height,
	href,
	id,
	marginBottom,
	marginLeft,
	marginRight,
	marginTop,
	noShadowFlag,
	noWrapFlag,
	onClick,
	onMouseDown,
	onMouseOut,
	onMouseOver,
	onMouseUp,
	outlineFlag,
	padding,
	size,
	style,
	target,
	title,
	type,
	width,
}) => {
	let formContext = useFormContext();
	let translate = useTranslate();
	let handlingSubmitFlag = formContext?.handlingSubmitFlag && type === "submit";
	children = useMemo(
		() =>
			handlingSubmitFlag ? (
				<>
					<Icon name="saving" marginRight={theme.smallSpace} spinFlag />
					{translate("button.saving")}
				</>
			) : (
				children
			),
		[children, handlingSubmitFlag, translate]
	);
	if (handlingSubmitFlag) disabled = true;
	return (
		<StyleWrapper
			alignSelf={alignSelf}
			backgroundColor={backgroundColor}
			border={border || (color === "white" ? `1px solid ${theme.lightGray} !important` : undefined)}
			pointerEvents="initial !important"
			cursor={disabled ? "not-allowed" : undefined}
			flex={flex ? `${flex} !important` : undefined}
			float={float}
			height={height}
			lineHeight={1.5}
			marginTop={marginTop}
			marginBottom={marginBottom}
			marginLeft={marginLeft}
			marginRight={marginRight}
			padding={padding || "6px 12px"}
			boxShadow={noShadowFlag && "none !important"}
			textAlign={centerFlag ? "center" : undefined}
			whiteSpace={noWrapFlag ? "nowrap" : undefined}
			width={width}
			$style={theme.shadowCss + `&.btn-nav {background: ${theme.navDarkBackground} !important;}`}
		>
			<BootstrapButton
				active={activeFlag}
				as={as}
				block={blockFlag}
				className={className}
				data-acsb={dataAcsb}
				data-object={dataObject}
				data-object-id={dataObjectId}
				data-testid={dataTestId}
				disabled={disabled}
				flex={flex}
				height={height}
				href={href}
				id={id}
				onClick={onClick}
				onMouseDown={onMouseDown}
				onMouseOut={onMouseOut}
				onMouseOver={onMouseOver}
				onMouseUp={onMouseUp}
				size={sizes[size]}
				style={style}
				target={target}
				title={title}
				type={type}
				value={as === "input" ? children : undefined}
				variant={outlineFlag ? "outline-" + color : color}
				download={downloadFlag}
			>
				{as === "input" ? undefined : children}
			</BootstrapButton>
		</StyleWrapper>
	);
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default memo(Button);
