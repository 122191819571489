import ThemeDiscussion from "@clearpoint/old-theme/OldDiscussion/Discussion";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	closeEditMode: PropTypes.func,
	editPodFlag: PropTypes.bool,
};

let Discussion = ({ closeEditMode, editPodFlag }) => {
	let { object, objectId } = useFormValue();

	return <ThemeDiscussion editFlag={editPodFlag} object={object} objectId={objectId} close={closeEditMode} />;
};

Discussion.propTypes = propTypes;
export default Discussion;
