import { useCallback } from "react";

import { useUserId } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";


let checkApproval = ({ userId, type, requireApprovals, approvalOptions, ownerId, collaborators }) => {
	if (!approvalOptions) return false;
	if (!requireApprovals) return false;
	if (!approvalOptions[type]) return false;
	if (ownerId !== null && ownerId === userId) return false;
	if (
		approvalOptions.allowCollaboratorChanges &&
		collaborators?.includes(userId) 
	)
		return false;
	return true;
};

let useCheckApproval = ({ scorecardId }) => {
	let userId = useUserId();
	let { get } = useOldQueryStore();
	let scorecard = scorecardId && get({ object: "scorecard", objectId: scorecardId });
	let { requireApprovals, approvalOptions, ownerId, collaborators } = scorecard || {};
	return useCallback(
		({ type }) => {
			return checkApproval({ userId, type, requireApprovals, approvalOptions, ownerId, collaborators });
		},
		[userId, requireApprovals, approvalOptions, ownerId, collaborators]
	);
};

export default useCheckApproval;
