import { useCallback } from "react";
import PropTypes from "prop-types";

import { useEffectOnce } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import Block from "../../Block";
import Button from "../../Button/Button";
import { useFormContext } from "../../Form/Form";
import { theme } from "../../Theme";

let propTypes = {
	compactFlag: PropTypes.bool,
	marginBottom: PropTypes.string,
	name: PropTypes.string,
	selectAll: PropTypes.func.isRequired,
	selectNone: PropTypes.func,
};

let SelectAllSelectNoneButtons = ({ compactFlag, marginBottom, marginTop, disabled, name, selectAll, selectNone }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();

	let defaultSelectNone = useCallback(() => {
		setFormValue(name, []);
	}, [name, setFormValue]);

	useEffectOnce(() => {
		if (name === undefined && selectNone === undefined) {
			console.error(
				"Component 'SelectAllSelectNoneButtons' requires either a name prop or a selectNone prop, but neither was provided"
			);
		}
	});
	return (
		<Block
			marginTop={marginTop || `-${theme.tinySpace}`}
			marginBottom={marginBottom || theme.space}
			textAlign="right"
		>
			<Button
				data-testid="select-none-button"
				color="primary"
				marginRight={compactFlag ? "0px" : theme.smallSpace}
				onClick={selectNone || defaultSelectNone}
				outlineFlag
				disabled={disabled}
				type="button"
				width={compactFlag ? "50%" : undefined}
			>
				{translate(compactFlag ? "global.none" : "global.selectNone")}
			</Button>
			<Button
				data-testid="select-all-button"
				onClick={selectAll}
				disabled={disabled}
				color="primary"
				type="button"
				width={compactFlag ? "50%" : undefined}
			>
				{translate(compactFlag ? "global.all" : "global.selectAll")}
			</Button>
		</Block>
	);
};

SelectAllSelectNoneButtons.propTypes = propTypes;

export default SelectAllSelectNoneButtons;
