import Column from "../../../../Column";
import FormLabelLeft from "../../../../Form/Form.Label.Left";
import FormSelectObject from "../../../../Form/Select/Select.Object";
import SelectScorecard from "../../../../Form/Select/Select.Scorecard";
import SelectShadowMeasure from "../../../../Form/Select/Select.ShadowMeasure";
import SelectShadowObjectType from "../../../../Form/Select/Select.ShadowObjectType";
import Tooltip from "../../../../Tooltip";
import useFormValue from "../../../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useCheckFeature } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};

let SpecificSeries = ({ name }) => {
	let translate = useTranslate();
	let { session } = useOldSession();
	let formValue = useFormValue();
	let { measureId } = formValue;
	let checkFeature = useCheckFeature();
	return (
		<>
			<Column large={6}>
				<FormLabelLeft label={translate("scorecard")}>
					<SelectScorecard name="_specificSeriesScorecardId" defaultValue={session.scorecardId} />
				</FormLabelLeft>
			</Column>
			<Column large={6}>
				<Tooltip disabled={checkFeature("initiativeEvaluation")} text={translate("tooltips.calculations")}>
					<FormLabelLeft label={translate("global.element")}>
						<SelectShadowObjectType
							name="_specificSeriesObject"
							defaultValue="measure"
							disabled={!checkFeature("initiativeEvaluation")}
						/>
					</FormLabelLeft>
				</Tooltip>
			</Column>
			{formValue._specificSeriesObject && (
				<Column large={6}>
					<FormLabelLeft label={translate(formValue._specificSeriesObject)}>
						<SelectShadowMeasure
							name="_specificSeriesMeasureId"
							scorecardId={formValue._specificSeriesScorecardId}
							object={formValue._specificSeriesObject}
							defaultValue={measureId}
						/>
					</FormLabelLeft>
				</Column>
			)}
			{formValue._specificSeriesMeasureId && (
				<Column large={6} className="test-this">
					<FormLabelLeft label={translate("global.series")}>
						<FormSelectObject
							parent="measure"
							parentId={formValue._specificSeriesMeasureId}
							object="measureSeries"
							name={name}
							valueKey="name"
							activeOnlyFlag
						/>
					</FormLabelLeft>
				</Column>
			)}
		</>
	);
};

SpecificSeries.propTypes = propTypes;

export default SpecificSeries;
