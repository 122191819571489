import FormSelect from "../../../Form/Select/FormSelect";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectAggregateMilestoneType = (props) => {
	let translate = useTranslate();
	let parent = useFormValue("parent");
	let object = useFormValue("object");
	if (!parent) parent = object;
	let options = useMemo(
		() =>
			[
				{
					label: translate("edit.allMilestones"),
					value: 0,
				},
				parent === "milestone"
					? {
							label: translate("edit.childMilestones"),
							value: 6,
					  }
					: null,
				parent === "initiative"
					? {
							label: translate("edit.milestonesOfInitiatives"),
							value: 5,
					  }
					: null,
				{
					label: translate("edit.milestoneNameEquals"),
					value: 3,
				},
				{
					label: translate("edit.milestoneNameContains"),
					value: 4,
				},
				{
					label: translate("edit.series.milestonesTaggedWith"),
					value: 7,
				},
			].filter((x) => !!x),
		[parent, translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectAggregateMilestoneType;
