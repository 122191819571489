import { useCallback, useState, useEffect } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";
import { emptyObject } from "@clearpoint/utils";


let discardModalEvents = {
	discardOnNavigate: "discardOnNavigate",
	discardOnClose: "discardOnClose",
	discardOnDiscard: "discardOnDiscard",
};

let useDiscardEventHandler = () => {
	let translate = useTranslate();
	let { discardOnClose, discardOnDiscard, discardOnNavigate } = discardModalEvents;
	let { setLocalState, editElementFlag } = useLocalState() || emptyObject;

	let [discardModalVisible, setDiscardModalVisible] = useState(false);

	let openDiscardModal = useCallback(
		(e) => {
			e.preventDefault();
			setDiscardModalVisible(true);
			return false;
		},
		[setDiscardModalVisible]
	);

	let closeDiscardModal = useCallback(() => {
		toast.warning(translate("global.navigationCancelled"));
		document.dispatchEvent(new Event(discardOnClose));
		setDiscardModalVisible(false);
	}, [discardOnClose, translate]);

	let onDiscardModalDiscard = useCallback(() => {
		document.dispatchEvent(new Event(discardOnDiscard));
		setDiscardModalVisible(false);
		if (editElementFlag && setLocalState) setLocalState({ editElementFlag: false });
	}, [discardOnDiscard, editElementFlag, setLocalState]);

	useEffect(() => {
		document.addEventListener(discardOnNavigate, openDiscardModal);
		return () => {
			document.removeEventListener(discardOnNavigate, openDiscardModal);
		};
	}, [discardOnNavigate, openDiscardModal]);

	return {
		closeDiscardModal,
		discardModalVisible,
		onDiscardModalDiscard,
	};
};

// dispatchDiscardEvent will return true if the event was dispatched and false if it was not dispatched for discardOnNavigate
let dispatchDiscardEvent = (eventType) => {
	let eventCancelFlag = document.dispatchEvent(new Event(eventType, { cancelable: true }));
	return !eventCancelFlag;
};

export default useDiscardEventHandler;
export { discardModalEvents, dispatchDiscardEvent };
