import Block from "@clearpoint/old-theme/Block";
import Hide from "@clearpoint/old-theme/Hide";

import { useWindowSize } from "@clearpoint/hooks";

import ApprovalBadge from "./ApprovalBadge";
import CompletedBadge from "./CompletedBadge";
import PeriodBadge from "./PeriodBadge";
import ScoreBadge from "./ScoreBadge";

let BadgeList = () => {
	let { displayClass } = useWindowSize();
	let tinyWindowSizeFlag = displayClass === "tiny";

	return (
		<Hide visible={!tinyWindowSizeFlag}>
			<Block display="flex" justifyContent="flex-end" alignItems="center">
				<ApprovalBadge/>
				<ScoreBadge />
				<PeriodBadge />
				<CompletedBadge />
			</Block>
		</Hide>
	);
};

export default BadgeList;
