import Block from "../Block";
import { Container } from "react-smooth-dnd";
import { useCallback, useRef } from "react";
import generateOnDrop from "./DragAndDropList.generateOnDrop";
import generateReorderList from "./DragAndDropList.generateReorderList";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useOnEvent } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.node,
	dragClass: PropTypes.string,
	getChildPayload: PropTypes.func,
	groupName: PropTypes.string,
	list: PropTypes.array,
	marginTop: PropTypes.string,
	onDrop: PropTypes.func,
	onDragStart: PropTypes.func,
	removeItemFromList: PropTypes.func,
	reorderList: PropTypes.func,
	setList: PropTypes.func.isRequired,
	shouldAcceptDrop: PropTypes.func,
	size: PropTypes.string,
	styleProps: PropTypes.object,
};

let getBody = () => document.body;

let DragAndDropList = ({
	children,
	dragClass,
	getChildPayload,
	groupName,
	list: listProp,
	marginTop,
	onDrop: onDropProp,
	onDragStart,
	onDragEnd,
	reorderList: reorderListProp,
	setList,
	shouldAcceptDrop,
	size,
	styleProps,
	...props
}) => {
	// allow access to current state from functions without triggering rerenders
	let listRef = useRef(listProp);
	listRef.current = listProp;
	// helper functions
	let removeItemFromList = useCallback(
		(removeIndex, _payload) => {
			let list = listRef.current;
			let listWithItemRemoved = [...list.slice(0, removeIndex), ...list.slice(removeIndex + 1)];
			setList(listWithItemRemoved);
		},
		[setList]
	);
	removeItemFromList = props.removeItemFromList || removeItemFromList;
	let addItemToList = useCallback(
		(addIndex, payload) => {
			let list = listRef.current;
			let newItem = payload.item;
			let listWithItemAdded = [...list.slice(0, addIndex), newItem, ...list.slice(addIndex)];
			setList(listWithItemAdded, payload);
		},
		[setList]
	);
	let reorderList = useCallback(
		(originIndex, destinationIndex) => {
			let list = listRef.current;
			let reorderListCallback = generateReorderList({ list, setList, reorderListProp });
			return reorderListCallback(originIndex, destinationIndex);
		},
		[reorderListProp, setList]
	);
	// track if draggable is inside or outside of container
	let insideContainerFlagRef = useRef(true);
	let onDragEnter = useCallback(() => {
		insideContainerFlagRef.current = true;
	}, []);
	let onDragLeave = useCallback(() => {
		insideContainerFlagRef.current = false;
	}, []);
	// track drop data (index removed / added)
	let dropDataRef = useRef();
	let onDropReady = useCallback(({ removedIndex, addedIndex, payload }) => {
		dropDataRef.current = { removedIndex, addedIndex, payload };
	}, []);
	// update list
	let onDrop = useCallback(() => {
		let dropData = dropDataRef.current;
		let insideContainerFlag = insideContainerFlagRef.current;
		dropDataRef.current = undefined;
		insideContainerFlagRef.current = true;
		if (!insideContainerFlag || !dropData) return;
		let onDropCallback = generateOnDrop({ addItemToList, onDropProp, removeItemFromList, reorderList });
		let returnData = onDropCallback(dropData);
		return returnData;
	}, [addItemToList, onDropProp, removeItemFromList, reorderList]);
	useOnEvent({
		// updates state before animation
		// onDrop prop for Container is called after animation
		eventName: "mouseup",
		handlerFunction: onDrop,
	});
	let noDropAnimation = useCallback(() => false, []);
	return (
		<Block
			{...styleProps}
			className={props.className}
			marginTop={marginTop}
			$style={`& .drop-preview {
				box-shadow: 4px 5px 4px #c6c6c6 inset;
				background: ${theme.lightGray5};
				border: 1px dashed #cecece;
				height: ${size === "small" ? "44px" : "50px"};
			}
			& .smooth-dnd-draggable-wrapper, & .smooth-dnd-container	{
				overflow: visible !important;
			}
			`}
		>
			<Container
				dragClass={dragClass}
				getChildPayload={getChildPayload}
				dragHandleSelector=".listitem-handle"
				getGhostParent={getBody}
				groupName={groupName}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDragStart={onDragStart}
				onDropReady={onDropReady}
				onDragEnd={onDragEnd}
				shouldAnimateDrop={noDropAnimation}
				shouldAcceptDrop={shouldAcceptDrop}
				dropPlaceholder={props.dropPlaceholder ?? null}
			>
				{children}
			</Container>
		</Block>
	);
};

DragAndDropList.propTypes = propTypes;

export default DragAndDropList;
