import { getRoute } from "@clearpoint/utils";

let createUrl = (id) => {
	// create url from identifying data
	// [parent]/[parentId]/[object]/[objectId]?periodId=[periodId]
	// scorecards/[scorecardId]/[object]/[objectId]?periodId=[periodId]
	// gridLayouts?scorecardId=[scorecardId]&object=[object]
	// dashboardLayouts?scorecardId=[scorecardId]
	let { object, objectId, parent, parentId, periodId, queryString, scorecardId } = id;
	// Scorecard Layout List
	let url;
	if (scorecardId && getRoute(object) === "dashboardLayouts" && !objectId) {
		url = `/dashboardLayouts?scorecardId=${scorecardId}`;
	}
	// Object Layout List by Scorecard
	else if (scorecardId && getRoute(object) === "gridLayouts" && !objectId) {
		url = `/gridLayouts?scorecardId=${scorecardId}&object=${object.slice(0, -6)}`;
	}
	// Object List by Scorecard
	else if (scorecardId && object && object !== "scorecard" && !objectId && !parentId && !periodId) {
		url = `/scorecards/${scorecardId}/${getRoute(object)}`;
	}
	// Child Status
	else if (periodId && parent && parentId && object && !objectId) {
		// Calculation Map with Measure Series
		let route = object === "map" ? "map" : getRoute(object);
		url = `/${getRoute(parent)}/${parentId}/${route}?periodId=${periodId}`;
	}
	// Status List by Scorecard
	else if (periodId && scorecardId && object && !objectId && !parentId) {
		url = `/scorecards/${scorecardId}/${getRoute(object)}?periodId=${periodId}`;
	}
	// Object List by Parent
	else if (parent && parentId && object && !objectId) {
		url = `/${getRoute(parent)}/${parentId}/${getRoute(object)}${periodId ? `periodId=${periodId}` : ""}`;
	}
	// Object Status
	else if (periodId && object && objectId && !parentId) {
		url = `/${getRoute(object)}/${objectId}${
			["chart", "map"].includes(object) || object.endsWith?.("Layout") ? "" : "/status"
		}?periodId=${periodId}`;
	}
	// Object by Parent
	else if (parent && parentId && object && objectId && !periodId) {
		url = `/${getRoute(parent)}/${parentId}/${getRoute(object)}/${objectId}`;
	}
	// Object
	else if (object && objectId) {
		url = `/${getRoute(object)}/${objectId}`;
	}
	// Object List
	else if (object) {
		url = `/${getRoute(object)}`;
	} else {
		throw new Error("Invalid keys for route: " + JSON.stringify(id));
	}
	// query string
	if (queryString) {
		if (queryString.slice(0, 1) === "?") queryString = queryString.slice(1);
		if (url.includes("?")) {
			url += `&${queryString}`;
		} else {
			url += `?${queryString}`;
		}
	}

	return url;
};

export default createUrl;
