import useCheckFeature from "./useCheckFeature";
import useLicense from "./useLicense";

let useCheckAiAccess = () => {
	let license = useLicense();
	let checkFeature = useCheckFeature();
	let disableAiFlag = !checkFeature("ai");
	let basicLicenseFlag = license?.edition === "basic" || license?.edition === "starter";
	let aiAccessFlag = !disableAiFlag;
	let aiUpsellFlag = basicLicenseFlag && disableAiFlag;
	return { aiAccessFlag, aiUpsellFlag, basicLicenseFlag, disableAiFlag };
};

export default useCheckAiAccess;
