import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import ThemeAttachments from "@clearpoint/old-theme/Attachments/Attachments";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	closeEditMode: PropTypes.func.isRequired,
	editPodFlag: PropTypes.bool.isRequired,
};

let Attachments = ({ closeEditMode, editPodFlag }) => {
	let translate = useTranslate();
	let { object, objectId } = useFormValue();

	return (
		<>
			<ThemeAttachments editFlag={editPodFlag} object={object} objectId={objectId} forceMarginFlag />
			{editPodFlag && (
				<Block textAlign="right" marginTop={theme.smallSpace}>
					<Button color="default" onClick={closeEditMode} size="small">
						{translate("global.close")}
					</Button>
				</Block>
			)}
		</>
	);
};

Attachments.propTypes = propTypes;
export default Attachments;
