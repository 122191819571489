import Input from "./Input";
import { useCallback } from "react";
import useFormValue from "../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useFormatDate, useUserTimezone } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
	noTimeFlag: PropTypes.bool,
};
let FormInputDate = ({ name, noTimeFlag, ...props }) => {
	let formatDate = useFormatDate();
	let previousValue = useFormValue(name);
	let { ianaName } = useUserTimezone();
	let elementValueTransform = useCallback(
		(value) => {
			if (value) {
				// Check if value is a non-ISO string
				if (typeof value === "string" && value.length <= 10) {
					return new Date(`${value} 00:00:00`);
				} else {
					return utcToZonedTime(value, ianaName);
				}
			}
			return null;
		},
		[ianaName]
	);
	let formValueTransform = useCallback(
		(inputValue) => {
			if (inputValue) {
				if (previousValue) {
					// set date based on date shown (zoned time)
					// When testing with different time zones
					// be sure to do a hard refresh of the page
					// or react-date may not work properly.
					if (typeof inputValue === "string") {
						inputValue = new Date(inputValue);
					}

					let newValue = utcToZonedTime(previousValue, ianaName);
					newValue = new Date(
						inputValue.getFullYear(),
						inputValue.getMonth(),
						inputValue.getDate(),
						newValue.getHours(),
						newValue.getMinutes(),
						newValue.getSeconds()
					);
					inputValue = newValue;
				}
				// convert input value to UTC
				let date = zonedTimeToUtc(inputValue, ianaName);
				inputValue = noTimeFlag ? formatDate(date, "apiDate") : date.toISOString();
			}
			return inputValue;
		},
		[formatDate, ianaName, noTimeFlag, previousValue]
	);

	return (
		<Input
			dateFlag
			elementValueTransform={elementValueTransform}
			formValueTransform={formValueTransform}
			name={name}
			{...props}
		/>
	);
};
FormInputDate.propTypes = propTypes;

export default FormInputDate;
