import Select from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectBorderStyle = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("options.admin.styles.border.solid"),
				value: "solid",
			},
			{
				label: translate("options.admin.styles.border.dotted"),
				value: "dotted",
			},
			{
				label: translate("options.admin.styles.border.dashed"),
				value: "dashed",
			},
		],
		[translate]
	);
	return (
		<Select options={options} placeholder={translate("options.admin.styles.border.selectBorderStyle")} {...props} />
	);
};
export default SelectBorderStyle;
