import { useOldQueryStore } from "@clearpoint/old-query-store";

let useTableStyle = ({ cellFlag, cellStyle, customStyleFlag, tableStyleId }) => {
	let { get } = useOldQueryStore();
	let tableStyleList = get({ object: "tableStyle" });
	const defaultStyle = tableStyleList?.find((x) => x.defaultStyle);
	let tableStyleObject = customStyleFlag
		? tableStyleId
			? tableStyleList?.find((x) => x.tableStyleId === tableStyleId)
			: defaultStyle
		: {};

	let tableStyle = tableStyleObject
		? cellFlag
			? `&&&&&& {
					${tableStyleObject.cellCss || cellStyle}
					}`
			: `&&&& {
					${tableStyleObject.tableCss}
					th {
						${tableStyleObject.headerCss}
					}
					td:not(.full-span) {
						${tableStyleObject.cellCss}
					}
					}`
		: "";
	if (tableStyleId === -1) {
		tableStyle = "";
		tableStyleObject = {};
	}
	let loadingFlag = customStyleFlag && !tableStyleList;
	return { loadingFlag, tableStyle, tableStyleObject, defaultStyle };
};

export default useTableStyle;
