import Column from "@clearpoint/old-theme/Column";
import PrimarySeriesSelect from "./PrimarySeriesSelect";

let SingleSeries = () => (
	<Column tiny={12}>
		<PrimarySeriesSelect />
	</Column>
);

export default SingleSeries;
