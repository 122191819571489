import Block from "../../../Block";
import HTML from "../../../HTML";
import StatusIcon from "../../../StatusIcon";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { formatNumber, getTotalObjectiveScore } from "@clearpoint/utils";
import { useObjectiveLinkListSortedByCategory } from "@clearpoint/hooks";

let propTypes = {
	name: PropTypes.string,
	noStatusIconFlag: PropTypes.bool,
};

let FormFieldDisplayCalculated = ({ name, noStatusIconFlag }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let value = useFormValue(name);
	let numberFormat = useFormValue(name.replace("value", "numberFormat"));
	let statusId = useFormValue(name.replace("value", "statusId"));
	let formLinkList = useFormValue("links");
	let linkList = useObjectiveLinkListSortedByCategory(formLinkList);
	let objectiveScoreFlag = name === "updates.score";
	let statusList = get({ object: "status", queryString: "?includeNull" });
	if (objectiveScoreFlag) {
		let totalScore = getTotalObjectiveScore(linkList, statusList);
		value = totalScore ? totalScore.toFixed(2) : translate("global.notAvailable");
	}

	return objectiveScoreFlag ? (
		<Block>{value}</Block>
	) : (
		<>
			{value && statusId && !noStatusIconFlag && <StatusIcon statusId={statusId} />}
			<HTML>{value && !isNaN(value) ? formatNumber({ number: value, numberFormat }) : value}</HTML>
		</>
	);
};

FormFieldDisplayCalculated.propTypes = propTypes;
export default FormFieldDisplayCalculated;
