import Check from "../../Check/Check";
import ChecklistCheck from "../../Checklist/Checklist.Check";
import InputController from "../../Form/InputController";
import Label from "../../Form/Form.Label";
import useBooleanFormValue from "../../Form/hooks/useBooleanFormValue";
import { useMemo, memo } from "react";
import PropTypes from "prop-types";

let propTypes = {
	checkListFlag: PropTypes.bool,
	"data-testid": PropTypes.string,
	defaultValue: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	persistInSession: PropTypes.string,
};

let ControlledCheck = ({
	checklistFlag,
	"data-testid": dataTestId,
	defaultValue,
	label,
	name,
	persistInSession,
	...props
}) => {
	let CheckComponent = checklistFlag ? ChecklistCheck : Check;
	useBooleanFormValue(name);
	return useMemo(
		() => (
			<>
				{label && <Label>{label}</Label>}
				<InputController
					defaultValue={defaultValue}
					name={name}
					type="checkbox"
					persistInSession={persistInSession}
				>
					<CheckComponent data-testid={dataTestId || "form-check"} formFlag {...props} />
				</InputController>
			</>
		),
		[dataTestId, defaultValue, label, name, persistInSession, props]
	);
};

ControlledCheck.propTypes = propTypes;

export default memo(ControlledCheck);
