let codeMirrorOptions = {
	autofocus: true,
	autoRefresh: true,
	lineNumbers: true,
	lineWrapping: true,
	mode: {
		name: "css",
		highlightNonStandardPropertyKeywords: false,
	},
	theme: "solarized light",
};

export default codeMirrorOptions;
