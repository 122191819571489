import { useState, useEffect } from "react";
import getuseEffectAfterRender from "./useEffectAfterRender.getuseEffectAfterRender";

export default function useEffectAfterRender(callback, dependencyList, disabled) {
	let [loaded, setLoaded] = useState();
	useEffect(() => {
		getuseEffectAfterRender({ setLoaded, disabled, window });
	}, [disabled]);
	return useEffect(() => {
		if (loaded) {
			callback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaded, ...dependencyList]);
}
