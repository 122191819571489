import Block from "../Block";
import Button from "../Button/Button";
import ButtonLink from "../Button/Button.Link";
import LogEntryListItem from "./LogEntryListItem";
import ModalOnClick from "../Modal/Modal.OnClick";
import ModalWindowEdit from "../ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "../ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "../ModalWindow/ModalWindow.Header";
import RevertModal from "./RevertModal";
import StyleWrapper from "../StyleWrapper";
import Table from "../Table";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getElementAndPageProps from "./ViewLogEntryModalWindow.getElementAndPageProps";
import { theme } from "../Theme";
import {  useFormatDate } from "@clearpoint/hooks";

let propTypes = {
	close: PropTypes.func.isRequired,
	logFileId: PropTypes.number,
	modalVisible: PropTypes.bool,
};

let LogEntryModalWindow = ({ close, logFileId, modalVisible }) => {
	let { get } = useOldQueryStore();
	let logEntry = useMemo(() => logFileId && get({ object: "logFile", objectId: logFileId }), [get, logFileId]);
	return logEntry ? (
		<ViewLogEntryModalWindow logEntry={logEntry} modalVisible={modalVisible} close={close}></ViewLogEntryModalWindow>
	) : null;
};

let ViewLogEntryModalWindow = ({ close, logEntry, modalVisible }) => {
	let formatDate = useFormatDate();
	let translate = useTranslate();

	let { linkButtonProps, modifiedElement, revertModalProps } = getElementAndPageProps(logEntry);
	let {
		action,
		activity,
		apiKey,
		date,
		deleted: deletedFlag,
		details,
		name,
		page,
		period,
		revert: revertFlag,
		scorecard,
	} = modifiedElement;

	let linkedUpdates = logEntry.slice(1);

	return (
			<ModalWindowEdit
				size="medium"
				className="view-log-entry-modal"
				modalVisible={modalVisible}
				noFormFlag
				close={close}
				data-testid="revision-history-modal"
			>
				<ModalWindowHeader title={translate("revisionHistory.revisionHistory")} />
				<Block
					padding={`${theme.space} ${theme.mediumSpace}`}
					height="100%"
					flex="1"
					overflowY="auto"
					backgroundColor={theme.white}
				>
					<Table borderedFlag>
						<colgroup>
							<col width="34%" />
							<col width="33%" />
							<col width="33%" />
						</colgroup>
						<thead>
							<StyleWrapper backgroundColor={theme.gray100}>
								<tr>
									<th colSpan="3">
										<Block display="flex">
											<Block flex={1}>
												{name} <br />
												<small>{scorecard}</small>
											</Block>
											<Block paddingTop={theme.tinySpace}>
												{revertFlag && (
													<ModalOnClick Modal={RevertModal} afterClose={close} {...revertModalProps}>
														<Button
															data-testid="undo-change-button"
															marginRight={theme.tinySpace}
															size="small"
															color="info"
														>
															{translate("revisionHistory.revertChange")}
														</Button>
													</ModalOnClick>
												)}
												{page && !deletedFlag && (
													<ButtonLink
														data-testid="view-page-button"
														hideIconFlag
														text={translate("revisionHistory.viewPage")}
														color="info"
														size="small"
														padding="6px 12px"
														target="_blank"
														{...linkButtonProps}
													/>
												)}
											</Block>
										</Block>
									</th>
								</tr>
							</StyleWrapper>
						</thead>
						<tbody>
							<tr>
								<th>{translate("revisionHistory.activityDate", { action: "" })}</th>
								<td colSpan="2">{formatDate(date, "long")}</td>
							</tr>
							<tr>
								<th>{translate("revisionHistory.activity")}</th>
								<td colSpan="2">{activity}</td>
							</tr>
							{action === "updated" && (
								<tr>
									<th>{translate("global.period")}</th>
									<td colSpan="2">{period}</td>
								</tr>
							)}
							{apiKey && (
								<tr>
									<th>{translate("global.apiKey")}</th>
									<td colSpan="2">{apiKey}</td>
								</tr>
							)}
							{(action === "added" || action === "edited" || action === "updated") && (
								<tr>
									<th>{translate("revisionHistory.field")}</th>
									<th>{translate("revisionHistory.beforesave")}</th>
									<th>{translate("revisionHistory.aftersave")}</th>
								</tr>
							)}
							{details?.modifiedFields &&
								details.modifiedFields.map((x, i) => <LogEntryListItem key={i} field={x} />)}
						</tbody>
					</Table>
					{linkedUpdates?.length > 0 && (
						<Table borderedFlag marginTop={theme.smallSpace}>
							<colgroup>
								<col width="34%" />
								<col width="33%" />
								<col width="33%" />
							</colgroup>
							<thead>
								<StyleWrapper backgroundColor={theme.gray100}>
									<tr>
										<th>{translate("revisionHistory.impactedElements")}</th>
										<th>{translate("revisionHistory.beforesave")}</th>
										<th>{translate("revisionHistory.aftersave")}</th>
									</tr>
								</StyleWrapper>
							</thead>
							<tbody>
								{linkedUpdates.map((update, i) => (
									<tr key={update.logFileId}>
										<td>{update.activity}</td>
										<td>
											{`${update.details.modifiedFields[0].label}: ${update.details.modifiedFields[0].beforeValue.value}`}
										</td>
										<td>{`${update.details.modifiedFields[0].label}: ${update.details.modifiedFields[0].afterValue.value}`}</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</Block>
				<ModalWindowFooter />
			</ModalWindowEdit>
	);
};

LogEntryModalWindow.propTypes = propTypes;

export default LogEntryModalWindow;
