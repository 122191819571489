import { getEnvironment } from "@clearpoint/utils";
let productionFlag = getEnvironment() === "prod";
let printFlag = window?.location?.hash?.startsWith("#/print");
let deployedFlag = window?.location?.hostname.includes("clearpoint") && ["stage", "dev"].includes(getEnvironment()); // add prod later, keep on stage and dev for now
let loadChecksumSdk = () => {
	// Access the environment variable CHECKSUM_SDK_KEY
	let checksumSdkKey = process.env.REACT_APP_CHECKSUM_SDK_KEY;
	// Check if the variable exists
	if (checksumSdkKey) {
		// Load the script
		let script = document.createElement("script");
		script.src = "https://cdn.checksum.ai/checksum-sdk-min-0.2.js";
		script.async = true;
		// Add an event listener to initialize the script after it has loaded
		script.addEventListener("load", () => {
			window.checksumai?.init(
				checksumSdkKey,
				{
					sessionRecording: {
						hashing: {
							hashAllText: productionFlag,
							hashAllInputs: productionFlag,
						},
						masking: {
							maskSensitiveText: productionFlag,
						},
					},
				},
				{}
			);
			window.checksumai.posthog.config.persistence = "localstorage+cookie";
		});
		// Append the script to the document
		document.head.appendChild(script);
	}
};
if (deployedFlag && !printFlag) loadChecksumSdk();
