import Create from "./Create";
import Destroy from "./Destroy";
import Detail from "./Detail";
import Edit from "./Edit";
import InvalidateButton from "./InvalidateButton";
import List from "./List";
import Status from "./Status";
import Delete from "./Delete";
import Undelete from "./Undelete";
import Update from "./Update";
import { useState } from "react";

const CREATE = "create";
const DETAIL = "detail";
const STATUS = "status";
const LIST = "list";
const EDIT = "edit";
const UPDATE = "update";
const DELETE = "delete";
const UNDELETE = "undelete";
const DESTROY = "destroy";

let viewLookup = {
	[CREATE]: Create,
	[LIST]: List,
	[DETAIL]: Detail,
	[STATUS]: Status,
	[EDIT]: Edit,
	[UPDATE]: Update,
	[DELETE]: Delete,
	[UNDELETE]: Undelete,
	[DESTROY]: Destroy,
};

let ObjectivesTest = () => {
	let [view, setView] = useState(LIST);
	let View = viewLookup[view];
	return (
		<div>
			<button onClick={() => setView(LIST)}>List</button>
			<button onClick={() => setView(DETAIL)}>View Detail</button>
			<button onClick={() => setView(STATUS)}>View Status</button>
			<button onClick={() => setView(CREATE)}>Create</button>
			<button onClick={() => setView(EDIT)}>Edit</button>
			<button onClick={() => setView(UPDATE)}>Update</button>
			<button onClick={() => setView(DELETE)}>Delete</button>
			<button onClick={() => setView(UNDELETE)}>Undelete</button>
			<button onClick={() => setView(DESTROY)}>Destroy</button>
			<InvalidateButton />
			<View />
		</div>
	);
};

export default ObjectivesTest;
