import Block from "./Block";
import ImageCircle from "./Image.Circle";
import InitialsCircle from "./Circle/Circle.Initials";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";

let propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	user: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		profileImage: PropTypes.string,
	}),
};

let ProfileImage = ({ className, size, user }) => {
	let { firstName, lastName, profileImage } = user;
	let { excelFlag } = useOldSession().session;
	return excelFlag ? null : (
		<Block alignSelf="center" className={className} flexShrink={0}>
			{profileImage ? (
				<ImageCircle size={size} src={profileImage} alt={`${firstName} ${lastName}`} />
			) : (
				<InitialsCircle firstName={firstName} lastName={lastName} size={size} />
			)}
		</Block>
	);
};

ProfileImage.propTypes = propTypes;

export default ProfileImage;
