/* eslint-disable complexity */
import PropTypes from "prop-types";

import { useId } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { localizeStyle } from "@clearpoint/utils";

import Circle from "../Circle/Circle";
import HTML from "../HTML";


let propTypes = {
	hideBeforeFlag: PropTypes.bool,
	field: PropTypes.shape({
		label: PropTypes.string,
		type: PropTypes.string,
		fieldId: PropTypes.string,
		beforeValue: PropTypes.any,
		afterValue: PropTypes.any,
	}),
};

let LogFileListItem = ({ field, hideBeforeFlag }) => {
	let { label, fieldId, beforeValue, afterValue } = field;
	let translate = useTranslate();
	let id = useId();
	let beforeValueClass = `beforevalue-${id}`;
	let afterValueClass = `aftervalue-${id}`;
	return (
		<tr>
			{label && <th>{translate(label)}</th>}
			{!label && <th>{translate(fieldId.slice(0, 1).toUpperCase() + fieldId.slice(1))}</th>}
			{!hideBeforeFlag && beforeValue?.percentComplete  !== undefined && <td>{beforeValue.percentComplete}</td>}
			{!hideBeforeFlag && beforeValue?.type === "string" && <td>{beforeValue.value}</td>}
			{!hideBeforeFlag && beforeValue?.type === "text" && (
				<td>
					<code>{beforeValue.value}</code>
				</td>
			)}
			{!hideBeforeFlag && beforeValue?.type === "html" && (
				<td>
					{beforeValue.value?.match(/<style>[\S\s]*?<\/style>/g) ? (
						<HTML className={`html-field htmlarea ${beforeValueClass}`}>
							{localizeStyle(beforeValue.value, beforeValueClass)}
						</HTML>
					) : (
						<HTML className="htmlarea">{beforeValue.value}</HTML>
					)}
				</td>
			)}
			{!hideBeforeFlag && beforeValue?.type === "list" && (
				<td>
					{beforeValue.value?.map((x, i) => (
						<li key={i}>{x.value}</li>
					))}
				</td>
			)}
			{!hideBeforeFlag && beforeValue?.type === "nestedList" && (
				<td>
					{beforeValue.value?.map((x, i) => (
						<div key={i}>
							{i % 2 === 0 && <li key={i}>{x}</li>}
							{i % 2 === 1 && (
								<div className="m-t-xs">
									{x.map((x1, i1) => (
										<li key={i1} className="m-l-lg">
											{x1.value}
										</li>
									))}
								</div>
							)}
						</div>
					))}
				</td>
			)}
			{!hideBeforeFlag && beforeValue?.type === "image" && (
				<td>
					<img src={beforeValue.value} alt={label} height="32" width="32" />
				</td>
			)}
			{!hideBeforeFlag && beforeValue?.type === "color" && (
				<td>
					<Circle backgroundColor={beforeValue.value}></Circle>
					{beforeValue.value}
				</td>
			)}
			{afterValue?.percentComplete !== undefined && <td>{afterValue.percentComplete}</td>}
			{afterValue?.type === "string" && <td> {translate(afterValue.value)}</td>}
			{afterValue?.type === "text" && (
				<td>
					<code>{afterValue.value}</code>
				</td>
			)}
			{afterValue?.type === "html" && (
				<td>
					{afterValue?.value?.match(/<style>[\S\s]*?<\/style>/g) ? (
						<HTML className={`html-field htmlarea ${afterValueClass}`}>
							{localizeStyle(afterValue.value, afterValueClass)}
						</HTML>
					) : (
						<HTML className="htmlarea">{afterValue.value}</HTML>
					)}
				</td>
			)}
			{afterValue?.type === "list" && (
				<td>
					{afterValue?.value?.map((x, i) => (
						<li key={i}>{x.value}</li>
					))}
				</td>
			)}
			{afterValue?.type === "nestedList" && (
				<td>
					{afterValue.value.map((x, i) => (
						<div key={i}>
							{i % 2 === 0 && <li key={i}>{x}</li>}
							{i % 2 === 1 && (
								<div className="m-t-xs">
									{x.map((x1, i1) => (
										<li key={i1} className="m-l-lg">
											{x1.value}
										</li>
									))}
								</div>
							)}
						</div>
					))}
				</td>
			)}
			{afterValue?.type === "image" && (
				<td>
					<img src={afterValue.value} alt={label} height="32" width="32" />
					{afterValue.value}
				</td>
			)}
			{afterValue?.type === "color" && (
				<td>
					<Circle backgroundColor={afterValue.value}></Circle>
				</td>
			)}
		</tr>
	);
};
LogFileListItem.propTypes = propTypes;

export default LogFileListItem;
