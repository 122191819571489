import { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import CopyForwardButton from "@clearpoint/old-theme/Button/Button.CopyForward";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";
import { theme } from "@clearpoint/old-theme/Theme";

import { useFieldList, useFormFieldsLoading, useScorecardLockedFlag } from "@clearpoint/hooks";

import AIAssistantButton from "./AIAssistantButton";

let propTypes = {
	periodLockedFlag: PropTypes.bool,
};

let UpdateFieldsTab = ({ periodLockedFlag }) => {
	let scorecardId = useFormValue("scorecardId");
	let fieldList = useFieldList({ object: "scorecard", objectId: scorecardId, updateFlag: true });
	let shouldShowAIButton = useCallback((fieldId) => ["analysis", "recommendations"].includes(fieldId), []);
	let loadingFlag = useFormFieldsLoading({ fieldList, updateFlag: true });
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);

	return (
		<>
			<Help section="edit-element" feature="update-fields" />
			<Block>
				{!periodLockedFlag && <CopyForwardButton parentName="updates" />}
				{fieldList?.map((field, i) => {
					let showAIButtonFlag = shouldShowAIButton(field.value);
					return (
						<Fragment key={field.value}>
							{i !== 0 && !periodLockedFlag && (
								<CopyForwardButton
									disabled={loadingFlag || scorecardLockedFlag}
									field={field.value}
									parentName="updates"
									right={showAIButtonFlag ? "50px" : theme.smallSpace}
								/>
							)}
							{showAIButtonFlag && (
								<AIAssistantButton
									disabled={loadingFlag || scorecardLockedFlag}
									fieldId={field.value}
									parentName="updates"
									periodLockedFlag={periodLockedFlag}
									right={theme.smallSpace}
								/>
							)}
							<FormField
								disabled={loadingFlag || scorecardLockedFlag}
								fieldId={field.value}
								label={field.label}
								object="scorecard"
								parentName="updates"
								periodLockedFlag={periodLockedFlag}
								customFieldType={field.customFieldType}
								modalFlag
							/>
						</Fragment>
					);
				})}
			</Block>
		</>
	);
};

UpdateFieldsTab.propTypes = propTypes;

export default UpdateFieldsTab;
