import Block from "@clearpoint/old-theme/Block";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { useEffectOnce } from "@clearpoint/hooks";


let RestrictedContent = () => {
	let translate = useTranslate();
	useEffectOnce(() => {
		document.body.setAttribute("loaded", true);
	});
	return (
		<Block backgroundColor={theme.white} textAlign="center" lineHeight={2.4}>
			<Block height="400px" />
			<Block fontWeight={theme.bold} fontSize="26px">
				{translate("print.restrictedContent")}
			</Block>
			<Block height="80px" />
			<Block fontWeight={theme.bold} fontSize="20px">
				{translate("print.notVisible")}
			</Block>
			<Block fontWeight={theme.bold} fontSize="20px">
				{translate("print.contactSupport")}
			</Block>
		</Block>
	);
};
export default RestrictedContent;
