/* eslint-disable complexity */
import PropTypes from "prop-types";

import DropdownDivider from "@clearpoint/old-theme/Dropdown/Dropdown.Divider";
import GenerateExcelOnClick from "@clearpoint/old-theme/Dropdown/GenerateExcel.OnClick";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import EmailPageModalOnClick from "@clearpoint/old-theme/Modal/Modal.EmailPage.OnClick";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import LinkMapModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.LinkMap.OnClick";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";

import { useCheckAccess, useCheckAiAccess, useNearestPeriodId, usePageName } from "@clearpoint/hooks";
import { useTranslate, useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import MilestonesAssistantModal from "../../../Detail/AIAssistants/MilestonesAssistantModal";
import GeneratePDFOnClick from "../wrappers/GeneratePDFOnClick";
import RecalculateDropdownItem from "./RecalculateDropdownItem";
import RevisionHistoryDropdownItem from "./RevisionHistoryDropdownItem";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import EditElementModalWindowOnClick from "@components/Elements/ModalWindow.EditElement.OnClick";
import EditDetailLayoutModal from "@components/Layouts/Shared/EditDetailLayoutModal";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import ViewQueuedChangeModal from "@components/MyClearpoint/MyApprovals/ViewQueuedChangeModal";

let propTypes = {
	editButtonType: PropTypes.oneOf(["edit", "editReport", "pdf"]).isRequired,
};

let DropdownMenu = ({ editButtonType }) => {
	let translate = useTranslate();
	let pageName = usePageName();
	let { access, links: linkList, name, object, objectId, scorecardId, shadowMeasureId } = useFormValue();
	let { element, layout } = useDetailPageData();
	let { layoutId, name: layoutName } = layout || emptyObject;
	let { pagePeriodId, setLocalState } = useLocalState();
	let approvalPending = element?.approvalsPending?.length > 0;
	let session = useOldSession();
	let { approvers, approvalsPending } = element || emptyObject;
	let isApprover = approvers?.includes(session.session.userId);
	let scorecardFlag = object === "scorecard";
	let periodId = useNearestPeriodId();
	let checkAccess = useCheckAccess();
	let adminAccessFlag = scorecardId && checkAccess({ access, action: "admin", scorecardId });
	let editAccessFlag = scorecardId && checkAccess({ access, action: "edit", scorecardId });
	let updateAccessFlag = scorecardId && checkAccess({ access, action: "update", scorecardId });
	let { aiAccessFlag } = useCheckAiAccess();
	let editElementFlag = editButtonType === "edit" || editButtonType === "editReport";
	let editLayoutAccessFlag = scorecardFlag ? adminAccessFlag : editAccessFlag;
	let recalculateFlag = (object === "measure" || (shadowMeasureId && shadowMeasureId > 0)) && updateAccessFlag;
	let linkMapItemFlag = !scorecardFlag && editAccessFlag && linkList && linkList.length > 0;
	let excelItemFlag = object === "measure" || (shadowMeasureId && shadowMeasureId > 0);
	let milestoneFlag = object === "initiative";
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let exportTitle = translateOnlyInsideCurlyBraces(layoutName) || "";

	return (
		<>
			{editElementFlag && !approvalPending && (
				<EditElementModalWindowOnClick object={object} objectId={objectId}>
					<DropdownItem icon="edit">{`${translate("global.edit")} ${translate(object)}`}</DropdownItem>
				</EditElementModalWindowOnClick>
			)}
			{approvalPending &&
				!isApprover &&
				approvalsPending.map((approval) => (
					<ModalWindowOnClick
						accordionName="approvals.myChanges"
						key={`approval${approval.approvalId}`}
						ModalWindow={ViewQueuedChangeModal}
						approvalId={approval.approvalId}
					>
						<DropdownItem icon="cancelChange">{translate(`approvals.${approval.type}Cancel`)}</DropdownItem>
					</ModalWindowOnClick>
				))}
			{approvalPending &&
				isApprover &&
				approvalsPending.map((approval) => (
					<ModalWindowOnClick
						accordionName="approvals.myApprovals"
						key={`approval${approval.approvalId}`}
						ModalWindow={ViewQueuedChangeModal}
						approvalId={approval.approvalId}
					>
						<DropdownItem icon="reviewApproval">{translate(`approvals.${approval.type}Review`)}</DropdownItem>
					</ModalWindowOnClick>
				))}

			{approvalPending && <DropdownDivider />}
			{editLayoutAccessFlag && !approvalPending && (
				<ModalWindowOnClick
					ModalWindow={EditDetailLayoutModal}
					elementName={name}
					periodId={pagePeriodId}
					scorecardId={element?.scorecardId}
				>
					<DropdownItem icon="editReport">{translate("edit.editDetailLayout")}</DropdownItem>
				</ModalWindowOnClick>
			)}
			{recalculateFlag && !approvalPending && <RecalculateDropdownItem />}
			{(editElementFlag || editLayoutAccessFlag || recalculateFlag) && !approvalPending && <DropdownDivider />}
			{aiAccessFlag && milestoneFlag && !approvalPending && (
				<>
					<ModalOnClick Modal={MilestonesAssistantModal} fieldId="milestones" fieldNameInForm="initiative">
						<DropdownItem icon="ai">{translate("ai.assistant.projectPlan")}</DropdownItem>
					</ModalOnClick>
					<DropdownDivider />
				</>
			)}
			{updateAccessFlag && <RevisionHistoryDropdownItem />}
			{linkMapItemFlag && (
				<LinkMapModalWindowOnClick object={object} objectId={objectId} periodId={periodId}>
					<DropdownItem icon="linkMap" iconSize="small">
						{translate("edit.map.viewLinkMap")}
					</DropdownItem>
				</LinkMapModalWindowOnClick>
			)}
			{(updateAccessFlag || linkMapItemFlag) && <DropdownDivider />}
			<GeneratePDFOnClick>
				<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
			</GeneratePDFOnClick>
			{excelItemFlag && (
				<GenerateExcelOnClick
					layoutId={layoutId}
					object="measure"
					objectId={object === "measure" ? objectId : shadowMeasureId}
					scorecardId={scorecardId}
					title={exportTitle}
				>
					<DropdownItem icon="excel">{translate("buttonBar.excelExport")}</DropdownItem>
				</GenerateExcelOnClick>
			)}
			<EmailPageModalOnClick layoutId={layoutId} objectId={objectId} object={object} title={pageName}>
				<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
			</EmailPageModalOnClick>
		</>
	);
};

DropdownMenu.propTypes = propTypes;

export default DropdownMenu;
