import { useCallback, useRef } from "react";

let useSetFastValue = () => {
	let setFastValueRef = useRef({});
	let setFastValue = useCallback(({ name, value }) => {
		if (setFastValueRef.current[name]) setFastValueRef.current[name](value);
	}, []);
	return { setFastValueRef, setFastValue };
};

export default useSetFastValue;
