import Select from "../../Form/Select/FormSelect";
import SelectAllSelectNoneButtons from "../../Form/Select/SelectAllSelectNoneButtons";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../DefaultForm";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import useStringToArrayFormValue from "../../Form/hooks/useStringToArrayFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";
import { emptyArray, parseArrayElementsToIntegers } from "@clearpoint/utils";


let propTypes = {
	name: PropTypes.string,
};

let UserMultipick = ({ name, ...props }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { get } = useOldQueryStore();
	let userList = get({ object: "user" });

	let options = useMemo(
		() =>
			userList?.map((x) => ({
				label: x.fullName,
				value: x.userId,
			})) || emptyArray,
		[userList]
	);

	let arrayName = useStringToArrayFormValue(name, parseArrayElementsToIntegers);

	let selectAll = useCallback(() => {
		setFormValue(
			arrayName,
			options.map((x) => x.value)
		);
	}, [options, arrayName, setFormValue]);

	return (
		<>
			<Select
				name={arrayName}
				options={options}
				placeholder={translate("edit.selectItem")}
				multipleFlag={true}
				{...props}
			/>
			<SelectAllSelectNoneButtons marginTop={theme.space} name={arrayName} selectAll={selectAll} />
		</>
	);
};

UserMultipick.propTypes = propTypes;
export default UserMultipick;
