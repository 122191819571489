import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectEmailNotificationSettings = (props) => {
	let translate = useTranslate();

	let notificationEmailSettings = useMemo(
		() => [
			{ value: 2, label: translate("myProfile.emailSetting.email") },
			{ value: 1, label: translate("myProfile.emailSetting.digest") },
			{ value: 0, label: translate("myProfile.emailSetting.disabled") },
		],
		[translate]
	);

	return (
		<Select
			label={translate("myProfile.selectNotificationEmailSettings")}
			options={notificationEmailSettings}
			placeholder={translate("myProfile.selectNotificationEmailSettings")}
			{...props}
		/>
	);
};
export default SelectEmailNotificationSettings;
