let getTranslateLookup = ({ generateScorecardTranslate, localeId, scorecardIdList, translateLookup }) => {
	let translateLookupCopy = { ...translateLookup };
	let updateFlag = false;
	for (let scorecardId of scorecardIdList) {
		let scorecardTranslate = generateScorecardTranslate(scorecardId);
		if (scorecardTranslate && scorecardTranslate !== translateLookup[scorecardId]) {
			updateFlag = true;
			translateLookupCopy[scorecardId] = scorecardTranslate;
			translateLookupCopy[scorecardId]._localeId = localeId;
		}
	}
	return updateFlag ? translateLookupCopy : translateLookup;
};

export default getTranslateLookup;
