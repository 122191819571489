import FormSelect from "./FormSelect";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getDeepValue from "lodash/get";

let propTypes = {
	allIsNullFlag: PropTypes.bool,
	defaultValue: PropTypes.number,
	name: PropTypes.string,
	noAllPeriodsOptionFlag: PropTypes.bool,
	noSpecificPeriodsOptionFlag: PropTypes.bool,
};

let FormSelectPeriodGroup = ({
	allIsNullFlag,
	defaultValue,
	name,
	noAllPeriodsOptionFlag,
	noSpecificPeriodsOptionFlag,
	...props
}) => {
	let translate = useTranslate();
	let { formDefaultValue, setFormDefaultValue } = useFormContext();
	let { session, setSession } = useOldSession();
	let { scorecardId } = session;
	let { get, getPromise } = useOldQueryStore();
	let periodGroupList = get({ object: "periodGroup" });
	let periodGroupFormDefaultValue = getDeepValue(formDefaultValue, name);

	let options = useMemo(() => {
		if (periodGroupList === undefined) return;
		if (!periodGroupList) return [];
		let options = [];
		if (!noAllPeriodsOptionFlag)
			options = [
				{
					label: translate("layout.allPeriods"),
					value: allIsNullFlag ? "null" : -1,
				},
			];
		options = [
			...options,
			...periodGroupList
				.filter((periodGroup) => periodGroup.active)
				.filter((periodGroup) => (periodGroup.scorecardId ? periodGroup.scorecardId === scorecardId : true)) // filter out periods that don't belong to this scorecard
				.map((periodGroup) => ({ label: periodGroup.name, value: periodGroup.periodGroupId })),
		];
		if (!noSpecificPeriodsOptionFlag)
			options = [...options, { label: translate("global.specificPeriods"), value: 0 }];
		return options;
	}, [allIsNullFlag, noAllPeriodsOptionFlag, noSpecificPeriodsOptionFlag, periodGroupList, scorecardId, translate]);
	useEffect(() => {
		setSession({ periodGroupOptions: options });
	}, [options, setSession]);

	useEffect(() => {
		let defaultPeriodGroupId = periodGroupList?.find((x) => x.defaultGroup)?.periodGroupId;
		if (periodGroupList && defaultPeriodGroupId === undefined) defaultPeriodGroupId = options?.[0].value;
		if (
			periodGroupFormDefaultValue === undefined &&
			defaultValue === undefined &&
			defaultPeriodGroupId !== undefined
		) {
			setFormDefaultValue(name, defaultPeriodGroupId);
		}
	}, [defaultValue, getPromise, name, options, periodGroupFormDefaultValue, periodGroupList, setFormDefaultValue]);
	let defaultValueSetFlag = periodGroupFormDefaultValue !== undefined || defaultValue !== undefined;

	return (
		<FormSelect
			options={options}
			placeholder={translate("edit.selectPeriodicity")}
			loadingFlag={!options || !defaultValueSetFlag}
			name={name}
			defaultValue={defaultValue}
			defaultFirstOptionFlag
			{...props}
		/>
	);
};
FormSelectPeriodGroup.propTypes = propTypes;

export default FormSelectPeriodGroup;
