let getGoalModeAndView = (page) => {
	let goalMode = page?.includes("Personal")
		? "personal"
		: page?.includes("List")
		? "list"
		: page?.includes("Tree")
		? "tree"
		: undefined;
	let departmentViewList = ["goalsPersonalDepartment", "goalsListDepartment", "goalsTreeDepartment"];
	let individualViewList = ["goalsPersonalIndividual", "goalsListIndividual", "goalsTreeIndividual"];
	let managerViewList = ["goalsPersonalManager", "goalsListManager", "goalsTreeManager"];
	let scorecardViewList = ["goalsListScorecards", "goalsTreeScorecard"];
	let goalView = departmentViewList.includes(page)
		? "department"
		: individualViewList.includes(page)
		? "individual"
		: managerViewList.includes(page)
		? "manager"
		: scorecardViewList.includes(page)
		? "scorecard"
		: undefined;
	return { goalMode, goalView };
};
export default getGoalModeAndView;
