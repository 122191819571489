import StyleWrapper from "../../../../StyleWrapper";
import Table from "../../../../Table";
import TableStyle from "./TableStyle";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { formatNumber } from "@clearpoint/utils";

let propTypes = {
	measureGridList: PropTypes.arrayOf(
		PropTypes.shape({
			periodId: PropTypes.number,
			periodName: PropTypes.string,
			series123: PropTypes.number, // e.g.
		})
	),
	measureId: PropTypes.number,
	measureSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			measureSeriesId: PropTypes.number,
			name: PropTypes.string,
			numberFormat: PropTypes.object,
		})
	),
	periodId: PropTypes.number,
};

let CurrentPeriodTable = ({ measureGridList, measureId, measureSeriesList, periodId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();

	let measure = measureId && periodId && get({ object: "measure", objectId: measureId, periodId });
	let displayPeriodId = measure?.periodId;
	let currentPeriod = measureGridList?.find((x) => x.periodId === displayPeriodId);

	return (
		<TableStyle currentPeriodOnlyFlag>
			<Table borderedFlag>
				<thead>
					<tr>
						<StyleWrapper width="50%">
							<th>{translate("global.series")}</th>
						</StyleWrapper>
						<StyleWrapper width="50%" minWidth="100px" overflow="hidden">
							<th>{currentPeriod?.periodName}</th>
						</StyleWrapper>
					</tr>
				</thead>
				<tbody>
					{measureSeriesList?.map((x) => {
						let valueInCurrentPeriod = currentPeriod?.["series" + x.measureSeriesId];
						return (
							<tr key={x.measureSeriesId}>
								<td>{x.name}</td>
								<StyleWrapper textAlign="right">
									<td>
										{isNaN(valueInCurrentPeriod)
											? valueInCurrentPeriod
											: formatNumber({ number: valueInCurrentPeriod, numberFormat: x.numberFormat })}
									</td>
								</StyleWrapper>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</TableStyle>
	);
};

CurrentPeriodTable.propTypes = propTypes;
export default CurrentPeriodTable;
