import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { useTranslateBooleans } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};

let Picklist = ({ name }) => {
	let value = useFormValue(name);
	let translateBooleans = useTranslateBooleans();
	return <span>{translateBooleans(value)}</span>;
};

Picklist.propTypes = propTypes;
export default Picklist;
