import CustomSeriesList from "./CustomSeriesList";
import Help from "@clearpoint/old-theme/Help";

let CustomChartSeriesTab = () => (
	<>
		<Help feature="chart-custom-series" />
		<CustomSeriesList />
	</>
);

export default CustomChartSeriesTab;
