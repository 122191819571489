import { useCallback, useContext, useMemo, useRef, useState, createContext } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getUpload from "./UploadProvider.getUpload";
import http from "@clearpoint/services/httpService/index";

let UploadContext = createContext();

let propTypes = {
	afterUpload: PropTypes.func,
	children: PropTypes.node,
	url: PropTypes.string,
	data: PropTypes.object,
};
let UploadProvider = ({ afterUpload, children, url, data }) => {
	let translate = useTranslate();
	let [selectedFileList, setSelectedFileList] = useState([]);
	let selectedFileListRef = useRef();
	selectedFileListRef.current = selectedFileList;
	let [uploadProgress, setUploadProgress] = useState(null);
	let [errorMessage, setErrorMessage] = useState(null);
	let config = useMemo(
		() => ({
			onUploadProgress: (progressEvent) => {
				let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setUploadProgress(percentCompleted);
			},
		}),
		[setUploadProgress]
	);

	let upload = useCallback(() => {
		return getUpload({
			afterUpload,
			config,
			data,
			http,
			selectedFileListRef,
			setSelectedFileList,
			setUploadProgress,
			translate,
			url,
		});
	}, [afterUpload, config, data, translate, url]);

	return (
		<UploadContext.Provider
			value={{
				errorMessage,
				setErrorMessage,
				selectedFileList,
				setSelectedFileList,
				setUploadProgress,
				uploadProgress,
				upload,
			}}
		>
			{children}
		</UploadContext.Provider>
	);
};

UploadProvider.propTypes = propTypes;

export default UploadProvider;
export const useUpload = () => useContext(UploadContext);
