import cloneDeep from "lodash/cloneDeep";
import { v1 as uuid } from "uuid";

let differentiateKpiChartPods = (layoutData) => {
	if (!layoutData || !layoutData.layout) return layoutData;
	let layoutDataCopy = cloneDeep(layoutData);

	for (let area of layoutDataCopy.layout) {
		for (let pod of area.pods) {
			if (pod.fieldId === "kpi" && pod.chart) {
				pod.chart._id = uuid();
			}
		}
	}

	return layoutDataCopy;
};

export default differentiateKpiChartPods;
