import FormCheck from "../../Form/Check/Form.Check";
import StyleWrapper from "../../StyleWrapper";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { theme } from "../../Theme";
import { useEffectOnce } from "@clearpoint/hooks";


let propTypes = {
	defaultValue: PropTypes.bool,
	editPodFlag: PropTypes.bool,
	milestonePodFlag: PropTypes.bool,
	name: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	onClick: PropTypes.func,
};

let CompletedCheck = ({
	defaultValue,
	editPodFlag,
	milestoneFlag,
	name,
	object,
	objectId,
	onClick: onClickProp,
	...props
}) => {
	let { setFormValue } = useFormContext();
	name = name || `_${object}${objectId}completed`;
	let formValue = useFormValue();
	let links = formValue?.links;
	let podIndex = useMemo(
		() => Array.isArray(links) && links?.findIndex((x) => x?.objectId === objectId),
		[links, objectId]
	);

	let onClick = useCallback(
		(e) => {
			let completedFlag = e.target.checked;
			setFormValue(name, completedFlag);
			if (onClickProp) onClickProp(completedFlag);
			if (editPodFlag || milestoneFlag) {
				setFormValue(`links.${podIndex}.completed`, completedFlag);
			}
		},
		[editPodFlag, milestoneFlag, name, onClickProp, podIndex, setFormValue]
	);

	useEffectOnce(() => {
		setFormValue(name, defaultValue);
	});

	return (
		<StyleWrapper marginRight={theme.smallSpace} $style={`&, * { margin-bottom: 0px !important; line-height: 0; }`}>
			<FormCheck
				data-testid="completed-check"
				defaultValue={defaultValue}
				name={name}
				onClick={onClick}
				{...props}
			/>
		</StyleWrapper>
	);
};
CompletedCheck.propTypes = propTypes;
export default CompletedCheck;
