import { getEnvironment } from "@clearpoint/utils";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import beforeSendAction from "./initializeSentry.beforeSend";

let beforeSend = (event) => {
	if (getEnvironment(true) === "localdev") return null;
	if (!event?.user?.email && event?.breadcrumbs?.values?.some?.((x) => x?.data?.status_code === 0)) return null;
	if (event?.title?.includes("Loading") && event?.title?.includes("chunk")) return null; // handled in webpack plugin - page refresh (see build.js)
	if (event?.location === "/api/v1/assets/js/acsb.js") return null; // ignore Accessibe errors
	let href = window?.location?.href;
	return beforeSendAction(event, href);
};

// sentry
const history = createBrowserHistory();
Sentry.init({
	dsn: `https://b2366f901a864dbd9b02a74ad692f0fe@o122241.ingest.sentry.io/6465985`,
	environment: getEnvironment(),
	integrations: [
		Sentry.reactRouterV5BrowserTracingIntegration({ history }),
		Sentry.replayIntegration({
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false,
			networkDetailAllowUrls: [/\/api\/v1\/.*/],
		}),
	],
	tracesSampleRate: 0.1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	tracePropagationTargets: ["localhost", /\/api\/v1\/.*/],
	release: `react@${import.meta.env.VITE_APP_VERSION}`,
	dist: `${import.meta.env.VITE_GIT_SHA}`,
	beforeSend,
});

export { beforeSend };
