import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useTranslate } from "@clearpoint/translate";
import { formatMoney } from "accounting-js";
import { notDefinedStatusIcon } from "@clearpoint/utils";


let useMeasureGridData = ({ measureGridList, measureSeriesList, statusIndicatorLookup }) => {
	let [HandsontableImport, setHandsontableImport] = useState({});
	useLayoutEffect(() => {
		import(/* webpackPrefetch: true */ "handsontable").then((x) =>
			setHandsontableImport({ _import: x.default || x })
		);
	}, []);
	let Handsontable = HandsontableImport._import;
	let translate = useTranslate();

	measureGridList = useMemo(
		() =>
			measureGridList
				?.filter((x) => !x.hideInGrid)
				.map((x) => {
					let status = statusIndicatorLookup[x.statusId];
					return {
						...x,
						statusIcon: `<img src="${status?.icon}" alt="${status?.name}" width=16 height=16 />`,
						statusName: status?.name,
					};
				}),
		[measureGridList, statusIndicatorLookup]
	);

	measureSeriesList = useMemo(
		() => measureSeriesList?.filter((x) => x.active && !x.disabled && !x.hiddenSeries),
		[measureSeriesList]
	);

	let renderFunction = useCallback(
		(_instance, td, _row, _col, _prop, value, cellProperties) => {
			let column = cellProperties.data;
			let series = measureSeriesList.find((x) => x.seriesData === column);

			td.innerHTML = value == null ? "" : Handsontable.helper.stringify(value);
			if (series && (value || value === 0) && !isNaN(value) && series.seriesDataTypeId !== 11) {
				td.innerHTML = formatMoney(value, series.numberFormat);
				td.classList.add("htRight");
			}
		},
		[Handsontable, measureSeriesList]
	);

	let columnList = useMemo(
		() =>
			measureGridList &&
			measureSeriesList && [
				{
					data: "periodName",
					readOnly: true,
					renderer: renderFunction,
					title: translate("global.period"),
					width: 100,
				},
				{
					data: "statusIcon",
					readOnly: true,
					renderer: renderFunction,
					title: `<img src="${notDefinedStatusIcon}" width=16 height=16 />`,
					width: 26,
				},
				{
					allowInvalid: false,
					data: "statusName",
					readOnly: true,
					renderer: renderFunction,
					title: translate("global.status"),
					width: 149,
				},
				...(measureSeriesList?.map((x) => ({
					allowInvalid: false,
					className: "htRight",
					data: x.seriesData,
					readOnly: true,
					renderer: renderFunction,
					title: x.name,
					type: x.seriesDataTypeId !== 11 ? "numeric" : "text",
				})) || []),
			],
		[measureGridList, measureSeriesList, renderFunction, translate]
	);

	return { columnList, measureGridList };
};

export default useMeasureGridData;
