import FormFieldDisplayEndDate from "@clearpoint/old-theme/Form/Field/Display/EndDate";
import FormFieldEndDate from "@clearpoint/old-theme/Form/Field/EndDate";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let EndDate = ({ editPodFlag, name }) =>
	editPodFlag ? (
		<FormFieldEndDate name={name} label={null} marginBottom="0" />
	) : (
		<FormFieldDisplayEndDate name={name} />
	);

EndDate.propTypes = propTypes;
export default EndDate;
