import AddCustomChartTypeModal from "./AddCustomChartTypeModal";
import Button from "@clearpoint/old-theme/Button/Button";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { isNotValidJson5 } from "@clearpoint/utils";

let propTypes = {
	closeChartJsonModal: PropTypes.func,
};

let AddCustomTypeButton = ({ closeChartJsonModal }) => {
	let translate = useTranslate();
	let customJson = useFormValue("customJson");
	return (
		<ModalOnClick Modal={AddCustomChartTypeModal} closeChartJsonModal={closeChartJsonModal}>
			<Button color="success" disabled={!customJson || isNotValidJson5(customJson)}>
				{translate("edit.chart.addChartType")}
			</Button>
		</ModalOnClick>
	);
};

AddCustomTypeButton.propTypes = propTypes;
export default AddCustomTypeButton;
