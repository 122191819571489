import Block from "@clearpoint/old-theme/Block";
import HTML from "@clearpoint/old-theme/HTML";
import PageNumberFooter from "./PageNumberFooter";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useEffect } from "react";
import useHtml from "./useHtml";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";
import { useQueryParameters } from "@clearpoint/hooks";


let Footer = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { reportId } = useQueryParameters();
	let report = get({ object: "report", objectId: reportId });
	let {
		confidentialityStatement,
		hideFooter: hideFooterFlag,
		htmlFooter,
		name,
		pageNumberLabel,
		period,
	} = report || emptyObject;

	let footerHtml = useHtml(htmlFooter);
	let defaultFooterFlag = !footerHtml;
	// add no-table-pdf-tag to footer html
	useEffect(() => {
		let table = document.querySelector("footer table");
		if (table && !table.classList.contains("no-table-pdf-tag")) table.classList.add("no-table-pdf-tag");
	});

	return hideFooterFlag || !report ? null : (
		<Block as="footer">
			{defaultFooterFlag ? (
				<table className="no-table-pdf-tag" width="100%">
					<tbody>
						<tr>
							<StyleWrapper textAlign="left">
								<td width="33%">{`${name} (${period}) ${translate("global.report")}`}</td>
							</StyleWrapper>
							<StyleWrapper textAlign="center">
								<td width="33%">{confidentialityStatement}</td>
							</StyleWrapper>
							<StyleWrapper textAlign="right">
								<td width="33%">
									<PageNumberFooter pageNumberLabel={pageNumberLabel} />
								</td>
							</StyleWrapper>
						</tr>
					</tbody>
				</table>
			) : (
				<HTML>{footerHtml}</HTML>
			)}
		</Block>
	);
};

export default Footer;
