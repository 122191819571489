import { useCallback } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import generateBuildLinkFunction from "./useBuildLink.generatedBuildLinkFunction";
import { emptyObject } from "@clearpoint/utils";

export default function useBuildLink() {
	let { session } = useOldSession() || emptyObject;

	return useCallback(
		(x, page = "") => {
			let buildLink = generateBuildLinkFunction(session);
			return buildLink(x, page);
		},
		[session]
	);
}
