import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import FieldTypeFilterSelect from "./FieldTypeFilterSelect";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import PlaceChartModal from "../PlaceChartModal";
import PodListItem from "./MenuPodListItem";
import { useCallback } from "react";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { usePodListContext } from "../PodListProvider";
import useScrollbar from "./useScrollbar";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";

let MenuPanel = () => {
	let translate = useTranslate();
	let object = useFormValue("object");
	let { data: filteredPodList } = useFilter();
	let { menuPanelPodList } = usePodListContext();
	let { scrollbarVisible, showScrollbar, hideScrollbar } = useScrollbar();

	let customFilter = useCallback(
		(pod, inputValue) =>
			!inputValue ||
			[pod.fieldId, pod.name, translate(pod.name, { objectType: object })].some((x) =>
				x.toLowerCase().includes(inputValue.toLowerCase())
			),
		[object, translate]
	);

	return (
		<Block
			id="menuPanel"
			backgroundColor={theme.gray2}
			display="flex"
			flexDirection="column"
			padding={theme.mediumSpace}
			width="25%"
			paddingBottom="0px"
		>
			<Block paddingBottom={theme.space}>
				<FilterInput
					name="pod"
					filter={customFilter}
					noFilterStyleFlag
					placeholder={translate("edit.filterList") + "..."}
				/>
				<Block marginBottom={theme.space}>
					<FieldTypeFilterSelect podList={menuPanelPodList} />
				</Block>
				<ModalWindowOnClick ModalWindow={PlaceChartModal}>
					<Button blockFlag color="primary">
						{translate("edit.placeChart")}
					</Button>
				</ModalWindowOnClick>
			</Block>
			<Block
				className="element-list no-wrap-list"
				height="100%"
				overflowY="overlay"
				onMouseOver={showScrollbar}
				onMouseOut={hideScrollbar}
				$style={`
					&::-webkit-scrollbar	{
						position: absolute;
						z-index: 99;
						top: 0;
						background-color: none;
						width: 7px;
						
					} 
					&::-webkit-scrollbar-thumb	{
						display: ${scrollbarVisible ? "contents" : "none"};
						background: rgb(51, 51, 51, 0.5);
						border-radius: 4px;
					}
				`}
			>
				{filteredPodList
					.filter((x) => x.visible)
					.map((x) => (
						<PodListItem draggableFlag={false} key={x.fieldId} pod={x} />
					))}
			</Block>
		</Block>
	);
};

export default MenuPanel;
