import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import getSeriesValueWithNumberFormat from "./useSeriesValueAtCurrentPeriod.getSeriesValueWithNumberFormat";
import { emptyObject } from "@clearpoint/utils";


let useSeriesValueAtCurrentPeriod = (chart) => {
	let { get } = useOldQueryStore();
	let { measureId, measureSeriesId } = chart || emptyObject;
	let periodId = useFormValue("updates.periodId");
	let measureGridData = measureId && get({ parent: "measure", parentId: measureId, object: "measureGrid" });
	let measureSeriesList = measureId && get({ object: "measureSeries", parent: "measures", parentId: measureId });
	let measureSeries = useMemo(
		() => measureSeriesList?.find((m) => m.measureSeriesId === measureSeriesId),
		[measureSeriesId, measureSeriesList]
	);

	return getSeriesValueWithNumberFormat({ measureGridData, measureSeries, measureSeriesId, periodId });
};

export default useSeriesValueAtCurrentPeriod;
