import { useEffect } from "react";
import useTranslate from "./useTranslate";
import useTranslatorState from "../Translator/useTranslatorState";

let useTranslateByScorecard = (scorecardId) => {
	let translate = useTranslate();
	let { translateLookup, addScorecardId } = useTranslatorState();
	useEffect(() => {
		addScorecardId(scorecardId);
	}, [addScorecardId, scorecardId]);
	return translateLookup[scorecardId] || translate;
};

export default useTranslateByScorecard;
