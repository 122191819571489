import Block from "@clearpoint/old-theme/Block";
import NameField from "@clearpoint/old-theme/Form/Field/Name";
import NameFieldDisplay from "@clearpoint/old-theme/Form/Field/Display/Name";
import OwnerIdFieldDisplay from "@clearpoint/old-theme/Form/Field/Display/OwnerId";
import ScorecardIdFieldDisplay from "@clearpoint/old-theme/Form/Field/Display/ScorecardId";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	cell: PropTypes.shape({
		showOwner: PropTypes.bool,
	}),
	editAccessFlag: PropTypes.bool,
	editCellFlag: PropTypes.bool,
	objectData: PropTypes.shape({
		completed: PropTypes.bool,
		name: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		ownerId: PropTypes.number,
		scorecardId: PropTypes.number,
		treeLevel: PropTypes.number,
	}),
	parentName: PropTypes.string,
};

let Name = ({ cell, editAccessFlag, editCellFlag, objectData, parentName }) => {
	let { get } = useOldQueryStore();
	let { excelFlag, scorecardId: sessionScorecardId } = useOldSession().session;
	let { showOwner: ownerFlag } = cell;
	let ownerId = useFormValue(`${parentName}.ownerId`);
	let { completed: completedValue, scorecardId, treeLevel } = objectData || emptyObject;
	let scorecardFlag = scorecardId !== sessionScorecardId;
	let options = get({ object: "options" });
	let optionsOwnerFlag = options?.options?.showOwners;
	ownerFlag = ownerId ? ownerFlag || optionsOwnerFlag : false;
	let strikethroughFlag = options?.options?.strikeComplete && completedValue;
	let subTitleFlag = !excelFlag && (scorecardFlag || ownerFlag);
	let joinFlag = scorecardFlag && ownerFlag;
	editCellFlag = editCellFlag && editAccessFlag;

	return editCellFlag ? (
		<NameField
			data-testid="name-input"
			flexGrowFlag
			label={null}
			marginBottom="0px"
			name={`${parentName}.name`}
			disabled={!editAccessFlag}
		/>
	) : (
		<Block marginLeft={treeLevel ? `${treeLevel * 20}px` : undefined} flex="1">
			<NameFieldDisplay
				name={`${parentName}.name`}
				parentName={parentName}
				treeLevel={treeLevel}
				strikethroughFlag={strikethroughFlag}
			/>
			{subTitleFlag && (
				<StyleWrapper display="inline-block">
					<small data-testid="name-subtitle">
						{scorecardFlag && <ScorecardIdFieldDisplay name={`${parentName}.scorecardId`} />}
						{joinFlag && <span> | </span>}
						{ownerFlag && <OwnerIdFieldDisplay name={`${parentName}.ownerId`} noInitialsFlag />}
					</small>
				</StyleWrapper>
			)}
		</Block>
	);
};

Name.propTypes = propTypes;

export default Name;
