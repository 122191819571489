import { useDetailPageData } from "../DataProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyArray } from "@clearpoint/utils";
import { useEvaluationEnabledLookup } from "@clearpoint/hooks";


let useLoadingFlag = () => {
	let { isLoading } = useOldQueryStore();

	let {
		element,
		elementStatus,
		layout,
		linkList,
		measureGrid,
		measureSeriesList,
		customFieldData,
		customFieldQuery,
		elementQuery,
		elementStatusQuery,
		layoutQuery,
		linkListQuery,
		measureGridQuery,
		measureSeriesQuery,
	} = useDetailPageData();

	let evaluationEnabledLookup = useEvaluationEnabledLookup();
	let evaluationEnabledFlag = element?.object && evaluationEnabledLookup[element.object];

	let allDataFetchedFlag = [
		customFieldData,
		element,
		elementStatus,
		layout,
		linkList,
		...(evaluationEnabledFlag ? [measureGrid, measureSeriesList] : emptyArray),
	].every((x) => x);

	let storeLoadingFlag = [
		customFieldQuery,
		elementQuery,
		elementStatusQuery,
		layoutQuery,
		linkListQuery,
		measureGridQuery,
		measureSeriesQuery,
	].some((query) => query && isLoading(query));

	return !allDataFetchedFlag || storeLoadingFlag;
};

export default useLoadingFlag;
