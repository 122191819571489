import { useCallback } from "react";
import useCheckFeature from "../useCheckFeature";
import { useTranslate } from "@clearpoint/translate";

let useMeasureSeriesEditFieldList = () => {
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let tagFlag = checkFeature("tags");

	return useCallback(() => {
		let fieldList = [
			{ value: "tags", label: translate("global.tags") },
			{ value: "seriesDataTypeId", label: translate("edit.series.seriesDataType") },
			{ value: "hiddenSeries", label: translate("edit.series.hiddenSeries") },
			{ value: "hiddenSummary", label: translate("edit.series.hiddenSummary") },
			{ value: "locked", label: translate("edit.lockSeriesForUpdaters") },
			{ value: "seriesType", label: translate("edit.seriesCalcType") },
			{ value: "evaluations", label: translate("edit.evaluation") },
		];
		if (!tagFlag) fieldList = fieldList.filter((x) => x.value !== "tags");
		return fieldList;
	}, [tagFlag, translate]);
};
export default useMeasureSeriesEditFieldList;
