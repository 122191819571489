import Alert from "../../../Alert/Alert";
import FormInput from "../../../Form/Input/Input";
import JSON5 from "json5";
import StyleWrapper from "../../../StyleWrapper";
import { useCallback, useEffect } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import useTab from "../../../Form/hooks/useTab";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { beautifyJson, isNotValidJson5 } from "@clearpoint/utils";
import { useId, usePrevious } from "@clearpoint/hooks";


let propTypes = {
	minHeight: PropTypes.string,
	name: PropTypes.string,
	showAlertFlag: PropTypes.bool,
};

let defaultProps = {
	showAlertFlag: true,
};

let FormInputJson = ({ minHeight, name, showAlertFlag, ...props }) => {
	let translate = useTranslate();
	let id = useId();
	let { setFormValue } = useFormContext();
	let value = useFormValue(name);
	let previousJson5 = usePrevious(value);
	useTab({ name, query: `.json5-${id}` });
	useEffect(() => {
		// expect people not to know what JSON5 is -- make initial value look like JSON
		if (
			value &&
			!previousJson5 &&
			!isNotValidJson5(value) &&
			value !== beautifyJson(JSON.stringify(JSON5.parse(value)))
		) {
			setFormValue(name, beautifyJson(JSON.stringify(JSON5.parse(value))));
		}
	}, [value, previousJson5, setFormValue, name]);

	let convertToJsonOnBlur = useCallback(
		(e) => {
			if (e?.target?.value) {
				if (!isNotValidJson5(e.target.value)) {
					setFormValue(name, beautifyJson(JSON.stringify(JSON5.parse(e.target.value))));
				}
			}
		},
		[name, setFormValue]
	);

	return (
		<>
			<StyleWrapper minHeight={minHeight || "300px"}>
				<FormInput
					as="textarea"
					className={`json5-${id}`}
					cols={30}
					errorTextNotVisible
					flexGrowFlag
					json5
					monospace
					name={name}
					rows={10}
					spellcheck="false"
					onBlur={convertToJsonOnBlur}
					{...props}
				/>
			</StyleWrapper>
			{showAlertFlag && value && (
				<StyleWrapper marginBottom="0">
					{isNotValidJson5(value) ? (
						<Alert color="danger">{translate("edit.script.invalidJSON")}</Alert>
					) : (
						<Alert color="success">{translate("edit.script.validJSON")}</Alert>
					)}
				</StyleWrapper>
			)}
		</>
	);
};

FormInputJson.propTypes = propTypes;
FormInputJson.defaultProps = defaultProps;
export default FormInputJson;
