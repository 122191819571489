import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import { setAuthorization } from "@clearpoint/services/authService";
import setCredentialsLoader from "./CredentialsLoader.setCredentialsLoader";
import { luceeAxios } from "@clearpoint/services/axiosService";
import { useStateObject } from "@clearpoint/hooks";

let propTypes = { children: PropTypes.node };

let CredentialsLoader = ({ children }) => {
	let [{ authorizationStatus }, setState] = useStateObject({
		authorizationStatus: null,
	});
	let { session } = useOldSession();
	let authHeader = luceeAxios.defaults.headers.common["Authorization"];

	return setCredentialsLoader({ authHeader, authorizationStatus, children, session, setAuthorization, setState });
};
CredentialsLoader.propTypes = propTypes;

export default CredentialsLoader;
