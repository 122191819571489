let checkEnv = (hostname, sentryFlag) => {
	if (!hostname) return "localdev";
	let subdomainList = ["app", "demo", "preview", "labs", "dev", "stage", "leetio"];
	let parsedHostName = subdomainList.find((x) => hostname.toLowerCase().includes(x)) || "localdev";
	if (sentryFlag) {
		return parsedHostName;
	} else {
		if (parsedHostName === "app") {
			return "prod";
		} else if (parsedHostName === "leetio") {
			return "dev";
		}
		return parsedHostName;
	}
};

export default checkEnv;
