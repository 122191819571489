import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editFlag: PropTypes.bool,
};
let HiddenSummary = ({ editFlag, ...props }) => {
	let translate = useTranslate();
	return editFlag ? (
		<RadioBoolean
			{...props}
			trueText={translate("global.hide")}
			falseText={translate("global.unhide")}
			label={translate("edit.hideSummary")}
		/>
	) : (
		<Check {...props} data-testid="hiddenSummary" text={translate("edit.hideSummary")} label="" />
	);
};
HiddenSummary.propTypes = propTypes;
export default HiddenSummary;
