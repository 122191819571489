import PropTypes from "prop-types";

import ChecklistCheckAll from "@clearpoint/old-theme/Checklist/Checklist.Check.All";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import AddDropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Add";
import AddMultipleDropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.AddMultiple";
import EditMultipleDropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.EditMultiple";
import DropdownItemTrashMultiple from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.TrashMultiple";
import DropdownSplitButtonAdd from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Add";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterShowDeleted from "@clearpoint/old-theme/Filter/Filter.ShowDeleted";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import EditMilestoneModal from "./EditMilestoneModal";
import EditMultipleMilestonesModal from "./EditMultipleMilestonesModal";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	apiMilestoneList: PropTypes.array,
};

let MilestoneFilter = ({ apiMilestoneList }) => {
	let { access, scorecardId, objectId, periodGroupId, milestoneCustomSortEnabled } = useFormValue();
	let { setData } = useFilter();
	let { setFormValue, formDefaultValue } = useFormContext();
	let { milestoneCustomSortEnabled: apiMilestoneCustomSortEnabled } = formDefaultValue;
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let checklist = useChecklist();
	let objectIdList = checklist?.checkedObjectIdList;
	let addEditFlag = access && checkAccess({ access, action: "edit", scorecardId });
	let { set } = useOldQueryStore();
	let handleResetSortOrder = () => {
		if (!apiMilestoneCustomSortEnabled) {
			setData(apiMilestoneList);
			setFormValue("milestoneCustomSortEnabled", false);
		} else {
			set({
				object: "update",
				data: {
					edits: [{ fields: { milestoneCustomSortEnabled: false }, object: "initiative", objectId }],
					updates: [],
				},
			}).then(() => {
				toast.success(translate("global.sortOrderReset"));
			});
		}
	};
	return (
		<FilterInput
			name="initiativeMilestoneName"
			placeholder={translate("manage.filterElements", { elements: "milestones" }) + "..."}
			filterBy="name"
			object="milestone"
			left={addEditFlag && <ChecklistCheckAll selectAllFlag />}
			right={
				<>
					<FilterShowDeleted />
					{addEditFlag && (
						<DropdownSplitButtonAdd
							showDropdownFlag
							ModalWindow={EditMilestoneModal}
							size="medium"
							scorecardId={scorecardId}
							parentId={objectId}
							periodGroupId={periodGroupId}
							marginLeft={theme.smallSpace}
						>
							<AddDropdownItem
								object="milestone"
								size="medium"
								scorecardId={scorecardId}
								parentId={objectId}
								ModalWindow={EditMilestoneModal}
							/>
							<AddMultipleDropdownItem
								object="milestone"
								ModalWindow={EditMultipleMilestonesModal}
								parentId={objectId}
								scorecardId={scorecardId}
							/>
							<EditMultipleDropdownItem
								ModalWindow={EditMultipleMilestonesModal}
								objectIdList={objectIdList}
								parentId={objectId}
								object="milestone"
							/>
							<DropdownItemTrashMultiple object="milestone" objectIdList={objectIdList} elementFlag />
							{milestoneCustomSortEnabled && (
								<DropdownItem icon="sort" onClick={handleResetSortOrder}>
									{translate("global.resetSortOrder")}
								</DropdownItem>
							)}
						</DropdownSplitButtonAdd>
					)}
				</>
			}
		/>
	);
};

MilestoneFilter.propTypes = propTypes;

export default MilestoneFilter;
