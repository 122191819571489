import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";

let usePeriodDate = (periodId) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	periodId = periodId || session.periodId;
	let periodList = get({ object: "period" });
	let period = periodList?.find((x) => x.periodId === periodId);
	return period?.periodDate;
};

export default usePeriodDate;
