import FormDragAndDropList from "../../Form/DragAndDropList/DragAndDropList";
import SortItem from "./DragAndDropList.Sort.Item";
import { useEffect } from "react";
import { useKey } from "react-use";
import PropTypes from "prop-types";
import { moveDown, moveToBottom, moveToTop, moveUp } from "@clearpoint/utils";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
	object: PropTypes.string,
	objectList: PropTypes.array,
	setObjectList: PropTypes.func,
	reportFlag: PropTypes.bool,
};
let FormDragAndDropListSort = ({ name, object, objectList, setObjectList, reportFlag, ...props }) => {
	let [{ arrowUp, arrowDown, shift, pageUp, pageDown }, setKeyState] = useStateObject({
		arrowUp: false,
		arrowDown: false,
		shift: false,
		pageUp: false,
		pageDown: false,
	});
	useKey("ArrowUp", () => setKeyState({ arrowUp: true }));
	useKey("ArrowDown", () => setKeyState({ arrowDown: true }));
	useKey("Shift", () => setKeyState({ shift: true }), { event: "keydown" });
	useKey("Shift", () => setKeyState({ shift: false }), { event: "keyup" });
	useKey("PageUp", () => setKeyState({ pageUp: true }));
	useKey("PageDown", () => setKeyState({ pageDown: true }));
	let index = objectList.findIndex((x) => x.selectedFlag);
	if (document.activeElement) document.activeElement.blur();
	useEffect(() => {
		if (index !== -1) {
			if (shift && arrowUp) setObjectList((list) => moveUp(list, index));
			if (shift && arrowDown) setObjectList((list) => moveDown(list, index));
			if (shift && pageUp) setObjectList((list) => moveToTop(list, index));
			if (shift && pageDown) setObjectList((list) => moveToBottom(list, index));
			if (shift && (arrowUp || arrowDown || pageUp || pageDown))
				setKeyState({
					arrowUp: false,
					arrowDown: false,
					pageUp: false,
					pageDown: false,
				});
		}
		// adding index to dependencies causes infinite rerenders
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arrowDown, arrowUp, pageDown, pageUp, setKeyState, shift]);
	return (
		<FormDragAndDropList
			name={name}
			list={objectList}
			listKey={object.endsWith("Layout") ? "layoutId" : "objectId"}
			setList={setObjectList}
			size="small"
			{...props}
		>
			{objectList.map((object, i) => (
				<SortItem
					reportFlag={reportFlag}
					key={object.sortKey || object.objectId || object.layoutId}
					object={object}
					setList={setObjectList}
					index={i}
					firstFlag={i === 0}
					lastFlag={i === objectList.length - 1}
				/>
			))}
		</FormDragAndDropList>
	);
};
FormDragAndDropListSort.propTypes = propTypes;
export default FormDragAndDropListSort;
