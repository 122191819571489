import { useEffect, useMemo } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { escapeSpecialCharacters } from "@clearpoint/utils";

import Block from "../../Block";
import HTML from "../../HTML";
import Image from "../../Image";
import StyleWrapper from "../../StyleWrapper";
import Text from "../../Text/Text";
import { theme } from "../../Theme";
import Select from "./FormSelect";
import { useOldSession } from "@clearpoint/old-session/index";

let formatOptionLabelPropTypes = {
	label: PropTypes.string,
	image: PropTypes.string,
};

let formatOptionLabel = ({ label, image }, { inputValue }) => {
	const bolded = label.replaceAll(
		new RegExp(escapeSpecialCharacters(inputValue), "gi"),
		(bolded) => `<span class="search-text">${bolded}</span>`
	);
	return (
		<Block className="custom-option" display="flex" alignItems="center" height="24px" ellipsisFlag>
			{image && <Image src={image} height="24px" width="24px" alt={label} />}
			<Text className="status-text" display="inline-block" marginLeft={theme.smallSpace} ellipsisFlag>
				{inputValue ? <HTML>{bolded}</HTML> : label}
			</Text>
		</Block>
	);
};

let propTypes = {
	activeOnlyFlag: PropTypes.bool,
	customField: PropTypes.string,
	excludeNullFlag: PropTypes.bool,
	object: PropTypes.string,
};

let defaultProps = {
	activeOnlyFlag: true,
};

let SelectStatus = ({ activeOnlyFlag, customField, excludeNullFlag, iconOnlyFlag, object, ...props }) => {
	let translate = useTranslate();
	let { setSession } = useOldSession();
	let { get } = useOldQueryStore();
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let options = useMemo(() => {
		if (statusList === undefined) return;
		if (!isArray(statusList)) {
			return [];
		}
		let filteredStatusList = statusList;
		if (excludeNullFlag) filteredStatusList = filteredStatusList.filter((x) => x.statusId !== 0);
		if (customField && customField.startsWith("custom")) {
			filteredStatusList = filteredStatusList.filter((x) => !x.elements || x.elements.includes(customField));
		} else if (object) {
			filteredStatusList = filteredStatusList.filter((x) => !x.elements || x.elements.includes(object));
		}
		if (activeOnlyFlag) {
			filteredStatusList = filteredStatusList.filter((x) => x.active);
		}
		return filteredStatusList.map((x) => ({
			label: x.name,
			value: x.statusId,
			image: x.image,
		}));
	}, [activeOnlyFlag, customField, excludeNullFlag, object, statusList]);
	useEffect(() => {
		setSession({ statusOptions: options });
	}, [options, setSession]);
	return (
		<StyleWrapper
			$style={
				iconOnlyFlag
					? `
			.custom-option img {
				width: 24px !important;
				height: 24px !important;
			}
			.form-control {
				margin-top: -2px;
				width: 55px;
				border-radius: 0px;
			}
			.select__value-container.select__value-container--has-value {
				padding-left: 0px;
				padding-right: 0px;
			}
			.select__indicator.select__dropdown-indicator {
				bottom: unset;
				margin-right: 0;
				padding: 0px;
			}
			.status-text {
				display: none;
			}`
					: undefined
			}
		>
			<Select
				controlShouldRenderValueFlag={iconOnlyFlag}
				isSearchableFlag={!iconOnlyFlag}
				menuWidth={iconOnlyFlag ? "200px" : undefined}
				noFacadeFlag
				options={options}
				formatOptionLabel={formatOptionLabel}
				padding="0px"
				placeholder={translate("edit.selectStatus")}
				width={iconOnlyFlag ? "20px" : undefined}
				{...props}
			/>
		</StyleWrapper>
	);
};

formatOptionLabel.propTypes = formatOptionLabelPropTypes;
SelectStatus.propTypes = propTypes;
SelectStatus.defaultProps = defaultProps;

export default SelectStatus;
