import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";

let propTypes = {
	measureId: PropTypes.number.isRequired,
	peerGroupId: PropTypes.number.isRequired,
};

let SelectPeerSeries = ({ measureId, peerGroupId, ...props }) => {
	let { get } = useOldQueryStore();
	let peerSeriesList =
		peerGroupId && measureId && get({ url: `/peerGroups/${peerGroupId}/measures/${measureId}/measureSeries` });
	let options = useMemo(() => {
		if (peerSeriesList === undefined) return;
		if (!isArray(peerSeriesList)) return [];
		return peerSeriesList.map((x) => ({
			label: x.name,
			value: x.measureSeriesId,
		}));
	}, [peerSeriesList]);
	return <Select options={options || []} loadingFlag={!peerSeriesList} {...props} />;
};

SelectPeerSeries.propTypes = propTypes;

export default SelectPeerSeries;
