import isArray from "lodash/isArray";

let generateOpenPreviousGoalCallback =
	(activeGroupId, goalListFilteredByStatus, currentGoalIndex, setLocalState) => () => {
		if (activeGroupId && isArray(goalListFilteredByStatus)) {
			let previousGoalIndex;
			if (currentGoalIndex === 0) {
				previousGoalIndex = goalListFilteredByStatus.length - 1;
			} else {
				previousGoalIndex = currentGoalIndex - 1;
			}
			let previousGoalId = goalListFilteredByStatus[previousGoalIndex]?.goalId;
			if (previousGoalId !== undefined) {
				setLocalState({ modalGoalId: previousGoalId });
			}
		}
	};

export default generateOpenPreviousGoalCallback;
