import classNames from "classnames";
import PropTypes from "prop-types";

import { emptyObject } from "@clearpoint/utils";

import Block from "../Block";
import { theme } from "../Theme";
import DefaultPodHeader from "./DefaultPodHeader/DefaultPodHeader";
import { useOldSession } from "@clearpoint/old-session/index";

let bodyPropTypes = {
	backgroundCss: PropTypes.string,
	children: PropTypes.node,
	"data-testid": PropTypes.string,
	hideBorderFlag: PropTypes.bool,
	overflowX: PropTypes.string,
};

let Body = ({ backgroundCss, children, "data-testid": dataTestId, hideBorderFlag, overflowX }) => {
	let { exportFlag } = useOldSession().session;
	return (
		<Block
			data-testid={dataTestId}
			borderRadius="0 0 0.5rem 0.5rem"
			color="inherit"
			backgroundColor={!hideBorderFlag && theme.white}
			height="auto !important"
			minHeight={"58px"}
			overflowX={overflowX} // setting overflow hidden will break sticky headers
			overflowY={exportFlag && "visible !important"}
			padding={hideBorderFlag ? 0 : `${theme.space} ${theme.mediumSpace} ${theme.mediumSpace} ${theme.mediumSpace}`}
			$style={
				`.htmlarea  {
						img { max-width: 100%; }
						&:after {
							content: "";
  							display: table;
  							clear: both;
						}
					}` + (backgroundCss ? `&&& { ${backgroundCss}  }` : "")
			}
		>
			{children}
		</Block>
	);
};

let podPropTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	CustomHeaer: PropTypes.elementType,
	"data-testid": PropTypes.string,
	HeaderLeftElement: PropTypes.elementType,
	headerRightContent: PropTypes.node,
	headerText: PropTypes.string,
	marginTop: PropTypes.string,
	onDoubleClick: PropTypes.func,
	overflowX: PropTypes.string,
	podStyle: PropTypes.shape({
		backgroundCss: PropTypes.string,
		borderCss: PropTypes.string,
		headerCss: PropTypes.string,
		headerTextCss: PropTypes.string,
	}),
};

let Pod = ({
	border,
	className,
	children,
	CustomHeader,
	"data-testid": dataTestId,
	HeaderLeftElement,
	headerRightContent,
	headerText,
	hideBorderFlag,
	marginTop,
	onDoubleClick,
	overflowX,
	podStyle,
}) => {
	let { exportFlag } = useOldSession().session;
	let { backgroundCss, borderCss, headerCss, headerTextCss } = podStyle || emptyObject;
	let exportBorder = exportFlag && !hideBorderFlag ? `1px solid ${theme.lightGray5}` : "";
	let HeaderComponent = CustomHeader || DefaultPodHeader;

	return (
		<Block
			data-testid={dataTestId}
			border={border ?? exportBorder}
			borderRadius="0.5rem 0.5rem"
			boxShadow={!hideBorderFlag && "1px 1px 9px -6px rgb(0 0 0 / 50%)"}
			className={classNames(className)}
			marginTop={marginTop}
			position="relative"
			onDoubleClick={onDoubleClick}
			$style={!hideBorderFlag && borderCss && `${borderCss}`}
		>
			{!hideBorderFlag && (
				<HeaderComponent
					headerCss={headerCss}
					headerTextCss={headerTextCss}
					headerText={headerText}
					HeaderLeftElement={HeaderLeftElement}
					headerRightContent={headerRightContent}
				/>
			)}
			<Body
				data-testid={`${dataTestId}-body`}
				hideBorderFlag={hideBorderFlag}
				backgroundCss={backgroundCss}
				overflowX={overflowX}
			>
				{children}
			</Body>
		</Block>
	);
};

Body.propTypes = bodyPropTypes;
Pod.propTypes = podPropTypes;
export default Pod;
