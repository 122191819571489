import { querystring } from "zustand-querystring";

let querySync = (callback) => {
	return querystring(callback, {
		// select controls what part of the state is synced with the query string
		// pathname is the current route (e.g. /about or /)
		select(pathname) {
			let urlParams = new URLSearchParams(window.location.search);
			return {
				periodId: urlParams.has("periodId"),
				layoutId: urlParams.has("layoutId"),
				objectId: urlParams.has("objectId"),
				object: urlParams.has("layoutId"),
				scorecardId: urlParams.has("layoutId"),
			};
		},
	});
};

export { querySync };
