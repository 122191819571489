import { useCallback, useEffect, useState } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { removeUndefined } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

let useHomepage = ({ layoutId, page, object, objectId, scorecardId }) => {
	let translate = useTranslate();
	let [homepageFlag, setHomepageFlag] = useState(null);
	let { set, get, clear } = useOldQueryStore();
	let profile = get({ object: "profile" });
	let profileUserId = profile?.userId;

	useEffect(() => {
		if (profile) {
			let { home } = profile;
			if (home.object || home.page) {
				let objectMatchFlag = home.object && home.object === object;
				let objectIdMatchFlag = home.objectId && home.objectId === objectId;
				let layoutIdMatchFlag = home.layoutId && home.layoutId === layoutId;
				let idMatchFlag = objectIdMatchFlag || layoutIdMatchFlag;
				let pageMatchFlag = home.page === page || home.page === `${object}?objectId=${objectId}`;
				let defaultHomepageFlag = home.default;
				let newHomepageFlag =
					!defaultHomepageFlag && (home.page ? pageMatchFlag : objectMatchFlag && idMatchFlag);
				setHomepageFlag(newHomepageFlag);
			}
		}
	}, [homepageFlag, layoutId, object, objectId, page, profile, setHomepageFlag]);

	let toggleHomepage = useCallback(() => {
		setHomepageFlag((x) => !x);
		set({
			object: "favorite",
			data: removeUndefined({
				favorite: true,
				homepage: !homepageFlag,
				layoutId,
				page,
				object,
				objectId,
				scorecardId,
			}),
		}).then(() => {
			if (homepageFlag) {
				toast.success(translate("myProfile.alertHomeReset"));
			} else {
				toast.success(translate("toaster.messages.favorites.homepageSet"));
			}
			clear({ object: "myFavorite" });
			clear({ object: "favorite" });
			clear({ object: "profile" });
			if (profileUserId) {
				clear({ object: "user", objectId: profileUserId });
			}
			if (!layoutId) clear({ object, objectId });
		});
	}, [
		clear,
		homepageFlag,
		layoutId,
		object,
		objectId,
		page,
		profileUserId,
		scorecardId,
		set,
		setHomepageFlag,
		translate,
	]);

	return { homepageFlag, toggleHomepage };
};
export default useHomepage;
