let getIconName = ({ chartEarlyReturnPatternList, chartEarlyReturnValue, exactMatchList, fieldId }) => {
	if (exactMatchList.includes(fieldId)) return fieldId;

	// Semi-alphabetical order intentional to work with Array.find() -- do not alphabetize
	// e.g. we want to match "measureSeries" before "measure"
	let startingPatternList = [
		"actionItem",
		"attachments",
		"calculated",
		"category",
		"chartData",
		"chart",
		"child",
		"collaborators",
		"custom",
		"initiative",
		"kpi",
		"link",
		"measureSeries",
		"measure",
		"milestone",
		"objective",
		"owner",
		"parent",
		"risk",
		"scorecard",
		"series",
		"tag",
	];

	let startingPatternMatch = startingPatternList.find((x) => fieldId?.startsWith(x));
	if (chartEarlyReturnPatternList.includes(startingPatternMatch)) return chartEarlyReturnValue;
	return startingPatternMatch;
};

export default getIconName;
