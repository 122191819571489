import Icon from "@clearpoint/old-theme/Icon/Icon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import Tooltip from "@clearpoint/old-theme/Tooltip";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import goalStatusLookup from "@components/Elements/Goals/goalStatusLookup";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";


let propTypes = {
	statusId: PropTypes.number,
};

let StatusIcon = ({ statusId }) => {
	let translate = useTranslate();
	let status = goalStatusLookup[statusId] || emptyObject;
	let { color, icon, name } = status;

	return status ? (
		<StyleWrapper marginRight={theme.space}>
			<Tooltip text={translate(name)} placement="top" blockFlag>
				<StyleWrapper height="28px" width="28px">
					<Icon name={icon} color={color} noFixedWidthFlag />
				</StyleWrapper>
			</Tooltip>
		</StyleWrapper>
	) : null;
};

StatusIcon.propTypes = propTypes;
export default StatusIcon;
