import Alert from "../../../Alert/Alert";
import Column from "../../../Column";
import DropZone from "./DropZone";
import FormLabel from "../../../Form/Form.Label";
import ImageClearConfirmModal from "./ImageClearConfirmModal";
import ImageList from "./ImageList";
import ModalOnClick from "../../../Modal/Modal.OnClick";
import Pagination from "../../../Pagination/Pagination";
import PreviewImage from "./PreviewImage";
import Row from "../../../Row";
import StyleWrapper from "../../../StyleWrapper";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useMemo, useState } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	customFieldFlag: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
};

let ImagePaginate = ({ customFieldFlag, disabled, label, name }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();

	let [imageCount, setImageCount] = useState(0);
	let [uploadProgress, setUploadProgress] = useState(null);

	let localImage = useFormValue("_localImage");
	let imageFromAPI = useFormValue(name);
	let imageExistsFlag = localImage || !!imageFromAPI?.value;

	let getData = (startIndex) => {
		let imageObj = get({
			object: "image",
			queryString: `?start=${startIndex}&count=6&search=&generateThumbnails=true`,
		});

		// Make sure we can return the new state value without waiting for setState
		let returnValueImageCount = imageCount;

		if (imageObj && imageCount !== imageObj?.fileCount) {
			returnValueImageCount = imageObj?.fileCount;
			setImageCount(imageObj?.fileCount);
		}

		return { ...imageObj, total: returnValueImageCount };
	};

	let dropZoneText = useMemo(() => {
		if (uploadProgress !== null) {
			return uploadProgress === 100
				? translate("components.processingFile")
				: translate("components.uploadPercentComplete", { uploadProgress });
		}
		return translate("library.dragDropImageHelp");
	}, [translate, uploadProgress]);

	return (
		<>
			<FormLabel>{label}</FormLabel>
			{uploadProgress !== null && <Alert color="warning">{translate("detailLayouts.imageUploading")}</Alert>}
			{!disabled && (
				<Row>
					<StyleWrapper height="85px !important">
						<Column small={2} marginBottom={theme.smallSpace}>
							{imageExistsFlag ? (
								<ModalOnClick Modal={ImageClearConfirmModal} name={name} customFieldFlag={customFieldFlag}>
									<PreviewImage label={label} name={name} />
								</ModalOnClick>
							) : (
								<PreviewImage label={label} name={name} />
							)}
						</Column>
					</StyleWrapper>
					<Column small={10}>
						<DropZone
							customFieldFlag={customFieldFlag}
							height="85px"
							name={name}
							setUploadProgress={setUploadProgress}
						>
							{dropZoneText}
						</DropZone>
					</Column>
				</Row>
			)}
			<Pagination getData={getData} noHeightFlag sessionPageIndexFlag={false}>
				<ImageList customFieldFlag={customFieldFlag} name={name} />
			</Pagination>
		</>
	);
};

ImagePaginate.propTypes = propTypes;

export default ImagePaginate;
