import { persist } from "zustand/middleware";

let configuredPersist = (callback) =>
	persist(callback, {
		name: "bound-store",
		partialize: (state) =>
			Object.fromEntries(Object.entries(state).filter(([key]) => !["featureFlagList"].includes(key))),
	});

export { configuredPersist as persist };
