import StyleWrapper from "../../../StyleWrapper";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { useCallback, useEffect } from "react";
import usePrettyValue from "./usePrettyValue";
import useSetValue from "./useSetValue";
import PropTypes from "prop-types";
import codeMirrorOptions from "./codeMirrorOptions";
import { emptyObject } from "@clearpoint/utils";
import { useMeasure } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
	styleClass: PropTypes.string,
	redactorFlag: PropTypes.bool,
	tinyCssValue: PropTypes.object,
};

let CSSEditor = ({ name, styleClass, tinyCssValue, redactorFlag = false }) => {
	let setValue = useSetValue(name);
	let value = usePrettyValue(name);
	useEffect(() => {
		if (tinyCssValue) tinyCssValue.current = value;
	}, [tinyCssValue, value]);
	let { height: toolbarHeight } =
		useMeasure({ query: `.${styleClass} ${redactorFlag ? ".redactor-toolbar-wrapper" : ""}` }) || emptyObject;
	let refreshEditor = useCallback((editor) => {
		setTimeout(() => {
			editor.refresh();
			editor.focus();
		}, 500);
	}, []);
	return redactorFlag ? (
		toolbarHeight ? (
			<StyleWrapper
				position="absolute"
				top={`${toolbarHeight}px`}
				bottom="0px"
				left="0px"
				width="100%"
				$style=".CodeMirror { height: 100%; }"
			>
				<CodeMirror detach onChange={setValue} options={codeMirrorOptions} value={value || ""} />
			</StyleWrapper>
		) : null
	) : (
		<StyleWrapper height="100%" $style=".CodeMirror { height: 100%; }">
			<CodeMirror
				detach
				editorDidMount={refreshEditor}
				onChange={setValue}
				options={codeMirrorOptions}
				value={value || ""}
			/>
		</StyleWrapper>
	);
};
CSSEditor.propTypes = propTypes;
export default CSSEditor;
