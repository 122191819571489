import ModalDiscard from "./Modal.Discard";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "../Form/Form";
import PropTypes from "prop-types";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	children: PropTypes.node,
	closeOnDiscardFlag: PropTypes.bool,
	discardMessage: PropTypes.string,
	onDiscard: PropTypes.func.isRequired,
	showDiscardModalOnFormDirtyFlag: PropTypes.bool,
	showDiscardModalFlag: PropTypes.bool,
};

let DiscardOnClick = ({
	children,
	closeOnDiscardFlag,
	discardMessage,
	onDiscard,
	showDiscardModalFlag,
	showDiscardModalOnFormDirtyFlag,
}) => {
	let child = React.Children.only(children);
	let { formStatus } = useFormContext() || {};
	let [{ modalVisible }, setState] = useStateObject({
		modalVisible: false,
	});

	let showModal = useCallback(() => {
		setState({
			modalVisible: true,
		});
	}, [setState]);

	let close = useCallback(() => {
		setState({
			modalVisible: false,
		});
	}, [setState]);

	let handleDiscard = useCallback(() => {
		onDiscard();
		if (closeOnDiscardFlag) {
			close();
		}
	}, [close, closeOnDiscardFlag, onDiscard]);

	let handleClick = useCallback(() => {
		let defaultShowModalFlag = formStatus?.dirty && formStatus?.touched;
		let showModalFlag = showDiscardModalOnFormDirtyFlag ? formStatus?.dirty : defaultShowModalFlag;
		if (showModalFlag || showDiscardModalFlag) {
			showModal();
		} else {
			handleDiscard();
		}
	}, [formStatus, handleDiscard, showDiscardModalFlag, showDiscardModalOnFormDirtyFlag, showModal]);

	return useMemo(
		() => (
			<>
				{React.cloneElement(child, {
					onClick: handleClick,
				})}
				{modalVisible && (
					<ModalDiscard
						modalVisible={modalVisible}
						close={close}
						discardMessage={discardMessage}
						onDiscard={handleDiscard}
					/>
				)}
			</>
		),
		[child, close, discardMessage, handleClick, handleDiscard, modalVisible]
	);
};

DiscardOnClick.propTypes = propTypes;

export default DiscardOnClick;
