import Badge from "../Badge/Badge";
import Block from "../Block";
import Icon from "../Icon/Icon";
import ModalDiscardOnClick from "../Modal/Modal.Discard.OnClick";
import Text from "../Text/Text";
import { useCallback } from "react";
import { useWindowContext } from "./ModalWindow";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";
import { theme } from "../Theme";
import { useMenuColor } from "@clearpoint/hooks";

let containerPropTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.string,
	style: PropTypes.string,
};

let Container = ({ children, className, color, style, "data-testid": dataTestId }) => {
	let menuColor = useMenuColor();
	return (
		<Block
			alignItems="center"
			backgroundColor={menuColor}
			className={className}
			color={theme.white}
			data-testid={dataTestId}
			display="flex"
			fontWeight={theme.bold}
			justifyContent="space-between"
			minHeight={null}
			padding={`${theme.space} ${theme.mediumSpace}`}
			userSelect="none"
			$style={(style || "") + "& .badge { background: transparent !important; }"}
		>
			{children}
		</Block>
	);
};

let HeaderLeft = styled.div`
	flex-shrink: 1;
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
	.badge {
		display: flex;
	}
`;

let HeaderRight = styled.div`
	flex-shrink: 0;
	flex-grow: 0;
`;

let Title = styled(Text)`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 16px;
	font-weight: 700;
`;

let IconContainer = styled.div`
	display: inline-block;
	margin-right: ${(props) => props.theme.mediumSpace};
	:last-child {
		margin-right: ${(props) => props.theme.smallSpace};
	}
	cursor: pointer;
	transition: transform 200ms;
	&:hover {
		transform: scale(1.2);
	}
`;

let headerIconPropTypes = {
	className: PropTypes.string,
	grow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	style: PropTypes.string,
};

let HeaderIcon = ({ className, grow, name, onClick, style }) => (
	<IconContainer className={className} onClick={onClick} style={style}>
		<Icon name={name} grow={grow} />
	</IconContainer>
);

let propTypes = {
	badgeColor: PropTypes.string,
	badgeIcon: PropTypes.string,
	badgeText: PropTypes.string,
	className: PropTypes.string,
	showDiscardModalFlag: PropTypes.bool,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	right: PropTypes.object,
};

let ModalWindowHeader = ({
	badgeColor,
	badgeIcon,
	badgeText,
	className,
	onHelpClick,
	showDiscardModalFlag,
	style,
	title,
	"data-testid": dataTestId,
	right,
}) => {
	let {
		close,
		color,
		maximizeFlag,
		showDiscardModalOnFormDirtyFlag,
		toggleHelpFlag: toggleHelpFlagContext,
		toggleSize,
	} = useWindowContext();
	let toggleHelpFlag = useCallback(() => {
		toggleHelpFlagContext();
		if (onHelpClick) onHelpClick();
	}, [onHelpClick, toggleHelpFlagContext]);
	return (
		<Container className={classNames(className)} style={style} color={color} data-testid={dataTestId}>
			<HeaderLeft>
				{(badgeIcon || badgeText) && (
					<Badge color={badgeColor || "info"} marginRight={theme.mediumSpace} size="small">
						{badgeIcon && <Icon name={badgeIcon} marginRight="9px" />}
						{badgeText}
					</Badge>
				)}
				<Title>
					<span data-testid="modal-window-title">{title}</span>
				</Title>
			</HeaderLeft>
			<HeaderRight>
				{right}
				<HeaderIcon grow="2" name="help" onClick={toggleHelpFlag} />
				<HeaderIcon
					className="js-maximize"
					name={maximizeFlag ? "restore" : "maximize"}
					grow="2"
					onClick={toggleSize}
				/>
				{
					<ModalDiscardOnClick
						onDiscard={close}
						showDiscardModalFlag={showDiscardModalFlag}
						showDiscardModalOnFormDirtyFlag={showDiscardModalOnFormDirtyFlag}
					>
						<HeaderIcon className="js-close" name="close" grow="4" />
					</ModalDiscardOnClick>
				}
			</HeaderRight>
		</Container>
	);
};

Container.propTypes = containerPropTypes;
HeaderIcon.propTypes = headerIconPropTypes;
ModalWindowHeader.propTypes = propTypes;

export { HeaderIcon };
export default ModalWindowHeader;
