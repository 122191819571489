import CellWrapper from "../CellWrapper";
import Block from "@clearpoint/old-theme/Block";
import FormCustomField from "@clearpoint/old-theme/Form/CustomField/index";
import FormCustomFieldDisplay from "@clearpoint/old-theme/Form/CustomField/Display/index";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import { Fragment, useMemo } from "react";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import getDeepValue from "lodash/get";
import { capitalizeFirstLetter } from "@clearpoint/utils";

let propTypes = {
	cell: PropTypes.shape({
		fieldId: PropTypes.string,
		periodId: PropTypes.number,
	}),
	cellFlag: PropTypes.bool,
	close: PropTypes.func,
	editCellFlag: PropTypes.bool,
	objectData: PropTypes.object,
	parentName: PropTypes.string,
};

let ViewGrid = () => null; // prevent infinite nesting from legacy reports inside reports field.

let CustomFieldCell = (props) => {
	let { cell, cellFlag, close, editCellFlag, objectData, parentName } = props;
	let { formDirty } = useFormContext();
	let { fieldId, periodId, updateMode } = cell;
	let customField = objectData?._customFields?.[fieldId];
	let editFieldFlag = !!customField;
	if (!customField) customField = objectData._customFields?.[periodId]?.[fieldId];
	let { customFieldType, name: title } = customField || {};
	let dataPrefix = editFieldFlag
		? `${parentName}._customFields[${fieldId}]`
		: `${parentName}._customFields[${periodId}][${fieldId}]`;
	let picklist = useFormValue(`${dataPrefix}].picklist`);
	let displayName = `${dataPrefix}.value`;
	let displayParentName = editFieldFlag ? `${parentName}._customFields` : `${parentName}._customFields[${periodId}]`;
	let inputFieldId = updateMode ? `status.${periodId}.${fieldId}` : fieldId;
	let inputName = `${parentName}.${inputFieldId}.value`;
	let dirtyFlag = getDeepValue(formDirty, inputName);
	let currentValue = useFormValue(inputName);
	let displayValue = dirtyFlag && currentValue;
	let inputParentName = `${parentName}.${inputFieldId}`;
	let EditField = FormCustomField[capitalizeFirstLetter(customFieldType)];
	let DisplayField = FormCustomFieldDisplay[capitalizeFirstLetter(customFieldType)];
	let modalFlag = ["image", "date", "chart", "grid"].includes(customFieldType);
	let EditWrapper = useMemo(
		() =>
			modalFlag
				? ({ children }) => (
						<FormModal title={title} name={inputName} modalVisible close={close}>
							{children}
						</FormModal>
				  )
				: Fragment,
		[close, inputName, modalFlag, title]
	);

	return (
		<CellWrapper {...props}>
			{!customField ? (
				<Block />
			) : editCellFlag ? (
				<EditWrapper>
					<StyleWrapper marginBottom="0px">
						<EditField
							cellFlag={cellFlag}
							clickToActivateFlag={false}
							fieldId={fieldId}
							name={inputName}
							parentName={inputParentName}
							picklist={picklist}
						/>
					</StyleWrapper>
				</EditWrapper>
			) : (
				<StyleWrapper marginBottom="0px !important" paddingBottom="0px !important">
					<DisplayField
						fieldId={fieldId}
						name={displayName}
						parentName={displayParentName}
						dirtyFlag={dirtyFlag}
						displayValue={displayValue}
						objectData={objectData}
						periodId={periodId}
						ViewGrid={ViewGrid}
					/>
				</StyleWrapper>
			)}
		</CellWrapper>
	);
};
CustomFieldCell.propTypes = propTypes;

export default CustomFieldCell;
