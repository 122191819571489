import GeneratePDFOnClickModal from "@clearpoint/old-theme/Modal/Modal.GeneratePDF.OnClick";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	children: PropTypes.node.isRequired,
};

let GeneratePDFOnClick = ({ children }) => {
	let { object, objectId, scorecardId } = useFormValue();
	let { layout } = useDetailPageData();
	let { layoutId, name: layoutName } = layout || emptyObject;

	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let exportTitle = translateOnlyInsideCurlyBraces(layoutName) || "";

	return (
		<GeneratePDFOnClickModal
			layoutId={layoutId}
			object={object}
			objectId={objectId}
			scorecardId={scorecardId}
			title={exportTitle}
		>
			{children}
		</GeneratePDFOnClickModal>
	);
};

GeneratePDFOnClick.propTypes = propTypes;
export default GeneratePDFOnClick;
