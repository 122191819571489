import buildQueryString from "./buildQueryString";
import getGoalModeAndView from "./getGoalModeAndView";
import getQueryParameters from "./getQueryParameters";

let redirect = ({ session, profile, location, scorecardList }) => {
	let queryString = location.search || location.hash.match(/\?.*/)?.[0] || "";
	let pathRegex = /#?\/(index|login)\//;
	let path;
	let [matchResult, loginOrIndexCaptureGroup] = location.pathname.match(pathRegex) || [];
	if (matchResult) {
		path = location.pathname.replace(`/${loginOrIndexCaptureGroup}/`, "");
	}
	if (!path) path = queryString;

	let { commentId, layoutId, object, objectId, scorecardId } = getQueryParameters(queryString);
	let commentLinkFlag = !!commentId;
	let invalidScorecardFlag = !scorecardList?.some?.((x) => x.scorecardId === +scorecardId);
	let noObjectInUrlFlag = !(scorecardId && object && (objectId || layoutId));
	let elementOrReportPageFlag = path.startsWith("?");
	let {periodId} = session;
	if (path && path.startsWith("manage-layouts")) {
		let manageReportsPageHref = `manage-layouts${buildQueryString({
			object,
			periodId,
			scorecardId,
		})}`;
		path = manageReportsPageHref;
		return { commentLinkFlag, path, queryString };
	}
	if (path && !path.startsWith("home") && !elementOrReportPageFlag) {
		return { commentLinkFlag, path, queryString };
	}
	if (profile?.home && (invalidScorecardFlag || noObjectInUrlFlag)) {
		let {home} = profile;
		path =
			home.page ||
			buildQueryString({
				scorecardId: home.scorecardId,
				object: home.object,
				objectId: home.objectId,
				layoutId: home.layoutId,
				periodId,
			});
	}
	if (path.includes("goals")) {
		let { goalMode, goalView } = getGoalModeAndView(path);
		let goalPageHref = `goals${buildQueryString({ mode: goalMode, periodId, view: goalView })}`;
		path = goalPageHref;
	}
	return { commentLinkFlag, path, queryString };
};
export default redirect;
