import * as d3 from "d3";

// removes transitions for the sake of testing
let flushAllD3Transitions = () => {
	let now = performance.now;
	performance.now = () => Infinity;
	d3.timerFlush();
	performance.now = now;
};

export default flushAllD3Transitions;
