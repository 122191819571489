import { useCallback, useRef } from "react";

let useScrollIntoView = (
	options = {
		behavior: "smooth",
		block: "end",
		inline: "nearest",
	}
) => {
	let containerRef = useRef();
	let { behavior, block, inline } = options;

	let scrollIntoView = useCallback(() => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior, block, inline });
		}
	}, [behavior, block, inline]);

	return { containerRef, scrollIntoView };
};

export default useScrollIntoView;
