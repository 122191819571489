import Alert from "@clearpoint/old-theme/Alert/Alert";
import AIChat from "@clearpoint/old-theme/AI/AIChat/AIChat";
import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import CopyConversationSplitButton from "./CopyConversationSplitButton";
import EditMultipleActionItemsModal from "@components/Elements/ActionItems/ManageActionItems/EditMultipleActionItemsModal/index";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useAIAssistant } from "@clearpoint/old-theme/AI/AIAssistantProvider/AIAssistantProvider";
import { useCallback } from "react";
import useCreateLinkElement from "./useCreateLinkElement";
import useOnHideOrShowBootstrapModal from "./hooks/useOnHideOrShowBootstrapModal";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	close: PropTypes.func.isRequired,
	description: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	periodLockedFlag: PropTypes.bool,
	setFieldFormValue: PropTypes.func.isRequired,
	template: PropTypes.string,
};

let AssistantTemplateContent = ({ close, description, name, periodLockedFlag, setFieldFormValue, template }) => {
	let translate = useTranslate();

	let {
		assistantState: { validatingFlag },
		scrollBottomContainerRef,
		setAssistantState,
	} = useAIAssistant();

	let [{ aiAddActionItemsName, editMultipleActionItemsModalVisible }, setAiAddActionItemsModalState] = useStateObject({
		editMultipleActionItemsModalVisible: false,
		aiAddActionItemsName: null,
	});

	let linkElementList = useCreateLinkElement();

	let editMultipleActionItemsModalClose = useCallback(() => {
		setAssistantState({ validatingFlag: false });
		setAiAddActionItemsModalState({ editMultipleActionItemsModalVisible: false });
	}, [setAiAddActionItemsModalState, setAssistantState]);

	useOnHideOrShowBootstrapModal({ modalVisible: editMultipleActionItemsModalVisible });

	return (
		<>
			<EditMultipleActionItemsModal
				linkElementList={linkElementList}
				aiAddActionItemsName={aiAddActionItemsName}
				modalVisible={editMultipleActionItemsModalVisible}
				close={editMultipleActionItemsModalClose}
			/>
			<ModalHeader closeButtonFlag={!validatingFlag}>
				<ModalTitle>{name}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Block padding={`${theme.tinySpace} ${theme.smallSpace}`} ref={scrollBottomContainerRef}>
					<StyleWrapper marginBottom="0">
						<Alert color="primary">{description}</Alert>
					</StyleWrapper>
					<Block>
						<AIChat setAiAddActionItemsModalState={setAiAddActionItemsModalState} template={template} />
					</Block>
				</Block>
			</ModalBody>
			<ModalFooter>
				<Button disabled={validatingFlag} onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				{!periodLockedFlag && <CopyConversationSplitButton close={close} setFieldFormValue={setFieldFormValue} />}
			</ModalFooter>
		</>
	);
};

AssistantTemplateContent.propTypes = propTypes;
export default AssistantTemplateContent;
