import Block from "../../Block";
import ProfileImage from "../../ProfileImage";
import StyleWrapper from "../../StyleWrapper";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	user: PropTypes.shape({
		fullName: PropTypes.string.isRequired,
	}),
	className: PropTypes.string,
};

let UserImageAndName = ({ user, className }) =>
	user ? (
		<Block data-testid="user-image-and-name" display="flex" alignItems="center" className={className}>
			<StyleWrapper marginRight={theme.smallSpace}>
				<ProfileImage user={user} size="tiny" />
			</StyleWrapper>
			<div>{user.fullName}</div>
		</Block>
	) : null;

UserImageAndName.propTypes = propTypes;
export default UserImageAndName;
