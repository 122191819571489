import Column from "../../Column";
import InputColor from "../../Form/Input/Color/FormInputColor";
import InputSlider from "../../Form/Input/Input.Slider";
import Row from "../../Row";
import Select from "../../Form/Select/FormSelect";
import { useCallback, useMemo } from "react";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	name: PropTypes.string,
	parentName: PropTypes.string,
};
let ShowNames = ({ name, parentName, ...props }) => {
	let translate = useTranslate();
	let elementDisplayOptions = useMemo(
		() => [
			{ label: translate("edit.map.showIcons"), value: false },
			{ label: translate("edit.map.showNames"), value: true },
		],
		[translate]
	);
	let showNamesFlag = useFormValue(name);
	let appendParent = useCallback((name) => (parentName ? `${parentName}.name` : name), [parentName]);
	return (
		<>
			<Select name={name} options={elementDisplayOptions} defaultValue={false} {...props} />
			{showNamesFlag && (
				<>
					<Row>
						<Column medium={4}>
							<InputColor
								name={appendParent("boxColor")}
								label={translate("logFieldLabels.boxColor")}
								defaultValue="#4F81BD"
							/>
						</Column>
						<Column medium={4}>
							<InputColor
								name={appendParent("boxBorder")}
								label={translate("logFieldLabels.boxBorder")}
								defaultValue="#315885"
							/>
						</Column>
						<Column medium={4}>
							<InputColor
								name={appendParent("textColor")}
								label={translate("map.textColor")}
								defaultValue={theme.white}
							/>
						</Column>
					</Row>
					<Row>
						<Column medium={4}>
							<InputSlider
								name={appendParent("boxWidth")}
								label={translate("map.boxWidth")}
								min={85}
								defaultValue={150}
								max={341}
							/>
						</Column>
						<Column medium={4}>
							<InputSlider
								name={appendParent("borderWidth")}
								label={translate("map.borderWidth")}
								min={0}
								defaultValue={2}
								max={10}
							/>
						</Column>
						<Column medium={4}>
							<InputSlider
								name={appendParent("boxRadius")}
								label={translate("map.cornerRadius")}
								min={0}
								defaultValue={6}
								max={50}
							/>
						</Column>
					</Row>
				</>
			)}
		</>
	);
};
ShowNames.propTypes = propTypes;
export default ShowNames;
