import FormFieldDisplayStartDate from "@clearpoint/old-theme/Form/Field/Display/StartDate";
import FormFieldStartDate from "@clearpoint/old-theme/Form/Field/StartDate";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let StartDate = ({ editPodFlag, name }) =>
	editPodFlag ? (
		<FormFieldStartDate name={name} label={null} marginBottom="0" />
	) : (
		<FormFieldDisplayStartDate name={name} />
	);

StartDate.propTypes = propTypes;
export default StartDate;
