let periodInitialState = {
	// periodId: 0,
};
let createPeriodSlice = (set) => ({
	...periodInitialState,
	setPeriodId: (periodId) =>
		set((state) => {
			// TO DO: Move period validation from session to here
			let newPeriodId = +periodId;
			let oldPeriodId = state.periodId;
			let invalidPeriodIdFlag = isNaN(newPeriodId);
			if (invalidPeriodIdFlag) console.warn(`periodId is not valid: ${periodId}`);
			return { dashboardChartCount: invalidPeriodIdFlag ? oldPeriodId : newPeriodId };
		}),
});
export { periodInitialState, createPeriodSlice };
