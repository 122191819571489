import { chartPeriodListContainsPeriod, formatSeriesNumber, getMonthDiff, sortSeries } from "../utils";
import isObject from "lodash/isObject";
import regression from "regression";

let createDefaultSeriesList = ({ seriesList, measureGridList, chartPeriodList, sortDirection, reportPeriodList }) => {
	let filteredMeasureGridList = measureGridList.filter((period) =>
		chartPeriodListContainsPeriod(period, chartPeriodList)
	);
	seriesList.forEach((series) => {
		if (series.dataLabels && isObject(series.dataLabels)) {
			//since the user chose to include data labels, we will provide a formatter
			//if the api returns a dataLabels.format value, this is ignored by HighCharts - which is fine
			//this formatter is an optional one that will run in the absence of a server provided format.
			series.dataLabels.formatter = function () {
				return formatSeriesNumber(this.y, this.series.options.numberFormat);
			};
		}

		if (series.regressionModel) {
			let startDate = new Date(measureGridList[0].periodDate);
			let regressionData = filteredMeasureGridList
				.filter((period) => period["series" + series.measureSeriesId] !== null)
				.map((period) => {
					let date = new Date(period.periodDate);
					let monthDiff = getMonthDiff(startDate, date);
					return [monthDiff, period["series" + series.measureSeriesId]];
				});
			let regressionResult = regression[series.regressionModel](regressionData, { precision: 3 });
			series.data = filteredMeasureGridList.map((period) => {
				let date = new Date(period.periodDate);
				let monthDiff = getMonthDiff(startDate, date);
				return regressionResult.predict(monthDiff)[1];
			});
		} else {
			series.animation = false;
			series.data = filteredMeasureGridList.map((period) => {
				if (series.periodOffset === 0) {
					return period["series" + series.measureSeriesId];
				} else {
					let offsetIndex =
						reportPeriodList.findIndex((x) => x.periodId === period.periodId) + series.periodOffset;
					if (offsetIndex >= 0 && offsetIndex < reportPeriodList.length) {
						return (
							measureGridList.find((period) => period.periodId === reportPeriodList[offsetIndex].periodId)?.[
								"series" + series.measureSeriesId
							] ?? null
						);
					} else {
						return null;
					}
				}
			});
		}
	});
	if (sortDirection !== "none") {
		seriesList = sortSeries(seriesList, sortDirection);
	}
	return seriesList;
};

export default createDefaultSeriesList;
