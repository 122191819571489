import { useCallback } from "react";
import PropTypes from "prop-types";

import FormGantt from "@clearpoint/old-theme/Form/Gantt/Gantt";
import Gantt from "@clearpoint/old-theme/Gantt/Gantt";
import Loading from "@clearpoint/old-theme/Loading/index";

import { emptyObject } from "@clearpoint/utils";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	tableLoadingFlag: PropTypes.bool,
	value: PropTypes.array,
};
let GanttReport = ({ tableLoadingFlag, value }) => {
	let { elementListName, endDate, ganttScale, layout, setLocalState, startDate } = useLocalState();
	let { gridWidth } = layout?.reportOptions || emptyObject;

	let editTable = useCallback(() => {
		setLocalState({ editTableFlag: true });
	}, [setLocalState]);
	let GanttElement = value ? Gantt : FormGantt;

	let showSaveBar = useCallback(() => {
		setLocalState({
			editTableFlag: true,
		});
	}, [setLocalState]);

	return ganttScale ? (
		<GanttElement
			ganttEndDate={endDate}
			ganttStartDate={startDate}
			gridWidth={gridWidth?.ganttSide || gridWidth || undefined}
			name={elementListName || "elementList"}
			onAfterTaskDrag={editTable}
			scale={ganttScale}
			value={value || undefined}
			onTaskModalSave={showSaveBar}
		/>
	) : null;
};
GanttReport.propTypes = propTypes;

export default GanttReport;
