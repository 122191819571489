import FormFieldDisplayName from "@clearpoint/old-theme/Form/Field/Display/Name";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let Name = ({ editPodFlag, name }) => {
	let translate = useTranslate();

	return editPodFlag ? (
		<FormFieldName
			name={name}
			placeholder={translate("error.required")}
			flexGrowFlag
			label={null}
			marginBottom="0px"
			required
			errorNotVisible
		/>
	) : (
		<FormFieldDisplayName name={name} noLinkFlag />
	);
};

Name.propTypes = propTypes;
export default Name;
