import { useMemo } from "react";
import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";

import useFormError from "../Form/hooks/useFormError";
import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	error: PropTypes.bool,
	for: PropTypes.string,
	marginBottom: PropTypes.string,
	uppercaseFlag: PropTypes.bool,
	wrapTextFlag: PropTypes.bool,
};

let FormLabel = ({
	children,
	className,
	"data-testid": dataTestId,
	error,
	for: _for,
	marginBottom,
	marginTop,
	uppercaseFlag,
	wrapTextFlag,
}) => {
	let forError = useFormError(_for);
	if (error === undefined && _for !== undefined) error = forError;
	return useMemo(
		() => (
			<StyleWrapper
				data-testid={dataTestId || "form-label"}
				display="block"
				overflow={wrapTextFlag ? undefined : "hidden"}
				textOverflow={wrapTextFlag ? undefined : "ellipsis"}
				wordBreak={wrapTextFlag ? "break-word" : undefined}
				color={error ? theme.danger : undefined}
				fontSize={uppercaseFlag ? "80%" : undefined}
				fontWeight={uppercaseFlag ? theme.light : undefined}
				marginBottom={marginBottom || (uppercaseFlag ? theme.tinySpace : undefined)}
				marginTop={marginTop}
				textTransform={uppercaseFlag ? "uppercase" : undefined}
			>
				<BootstrapForm.Label className={className} htmlFor={_for}>
					{children}
				</BootstrapForm.Label>
			</StyleWrapper>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[_for, children, className, error, marginBottom, uppercaseFlag, wrapTextFlag]
	);
};

FormLabel.propTypes = propTypes;

export default FormLabel;
