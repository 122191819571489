let createNewPodForLayoutPanel = (pod) => {
	let { chart, fieldId, name, type, updateMode: updateModeFlag } = pod;

	let newPod = {
		fieldId,
		hideBorder: false,
		hideLevel: 0,
		hideOnline: false,
		hidePrint: false,
		hidePublish: false,
		podStyleId: 0,
		podWidth: "col-xs-12",
		title: name,
		type: type === "custom" ? (updateModeFlag ? "update" : "edit") : type,
		updateMode: updateModeFlag,
	};

	if (chart) newPod.chart = chart;

	return newPod;
};

export default createNewPodForLayoutPanel;
