import { theme } from "../../Theme";

const STYLES = {
	user: {
		backgroundColor: theme.lighterBlue,
		borderColor: "#bce8f1",
		color: "#31708f",
	},
	assistant: {
		backgroundColor: theme.lightestPrimary,
		borderColor: theme.lightGreen,
		color: theme.darkGreen,
	},
	system: {
		backgroundColor: "#fff3cd",
		borderColor: "#ffeeba",
		color: "#856404",
	},
	validating: {
		backgroundColor: theme.brownWhite,
		borderColor: theme.brownerWhite,
		color: theme.brown,
	},
};

let messageStyles = (role, validatingFlag) => {
	if (validatingFlag) return STYLES.validating;
	return STYLES[role] || STYLES.system;
};

export default messageStyles;
