import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useOldSession } from "@clearpoint/old-session/index";

let useScorecard = (scorecardId) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	scorecardId = scorecardId || session.scorecardId;
	return scorecardId && get({ object: "scorecard", objectId: scorecardId });
};

export default useScorecard;
