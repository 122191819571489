import Block from "../../Block";
import CopyMessageToClipboardButton from "./CopyMessageToClipboardButton";
import Loading from "../../Loading";
import Icon from "../../Icon/Icon";
import InitialsCircle from "../../Circle/Circle.Initials";
import Parser from "./Parser";
import RegenerateButton from "./RegenerateButton";
import RestartButton from "./RestartButton";
import { useAIAssistant } from "../AIAssistantProvider/AIAssistantProvider";
import { useEffect, useRef, useState } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import messageStyles from "./ChatMessage.messageStyles";
import { theme } from "../../Theme";
import { emptyObject, parseTableString } from "@clearpoint/utils";

let propTypes = {
	aiAddActionItemsFlag: PropTypes.bool,
	generateNewResponse: PropTypes.func,
	index: PropTypes.number,
	message: PropTypes.object.isRequired,
	milestonesFlag: PropTypes.bool,
	mostRecentMesssageFlag: PropTypes.bool,
	placeholderFlag: PropTypes.bool,
	restartChatFromMessage: PropTypes.func,
	streamFlag: PropTypes.bool,
	validatingFlag: PropTypes.bool,
	waitForAIResponseFlag: PropTypes.bool,
};

const STREAM_TYPING_INTERVAL = 5;

let ChatMessage = ({
	aiAddActionItemsFlag,
	generateNewResponse,
	index,
	message,
	milestonesFlag,
	mostRecentMessageFlag,
	placeholderFlag,
	restartChatFromMessage,
	streamFlag,
	validatingFlag,
	waitForAIResponseFlag,
}) => {
	let translate = useTranslate();
	let [streamContent, setStreamContent] = useState("");
	let streamContentIntervalRef = useRef();
	let { parseHtmlFlag } = useAIAssistant();
	let { get } = useOldQueryStore();
	let profile = get({ object: "profile" });

	let { role, content } = message || emptyObject;
	let isUserFlag = role === "user";
	let isAIFlag = role === "assistant" && !validatingFlag;
	let messageStyle = messageStyles(role, validatingFlag);

	// Fakes typing by adding one character at a time to the message content until the entire message is displayed.
	// This is due to us not getting each character due to throttled api calls -> 500ms delay.

	useEffect(() => {
		if (!streamFlag) return setStreamContent("");
		if (streamContentIntervalRef.current) clearInterval(streamContentIntervalRef.current);

		let messageContentArray = content.slice(streamContent.length).split("");
		streamContentIntervalRef.current = setInterval(() => {
			let nextCharacter = messageContentArray.shift();
			if (!nextCharacter) return clearInterval(streamContentIntervalRef.current);
			setStreamContent((prevStreamContent) => prevStreamContent + nextCharacter);
		}, STREAM_TYPING_INTERVAL);

		return () => clearInterval(streamContentIntervalRef.current);
	}, [content, streamContent, streamFlag]);

	let showRegenerateButtonFlag =
		isAIFlag && !validatingFlag && !placeholderFlag && mostRecentMessageFlag && !aiAddActionItemsFlag;
	let showRestartButtonFlag = isUserFlag && !waitForAIResponseFlag;
	content = parseTableString(content);
	return (
		<Block margin={`${theme.smallSpace} 0px`}>
			<Block
				display="flex"
				justifyContent="space-between"
				alignItems="flex-start"
				flexWrap="wrap"
				flexDirection={isUserFlag ? "row-reverse" : "reverse"}
			>
				<Block display="flex" alignItems="center" marginTop={theme.tinySpace}>
					{isUserFlag && (
						<InitialsCircle
							firstName={profile?.firstName || " "}
							lastName={profile?.lastName || " "}
							marginLeft={theme.smallSpace}
							size="tiny"
						/>
					)}
					{isAIFlag && (
						<InitialsCircle
							firstName="Artificial"
							lastName="Intelligence"
							marginRight={theme.smallSpace}
							size="tiny"
						/>
					)}
				</Block>
				<Block
					flex="1"
					padding={theme.smallSpace}
					minHeight={theme.mediumSpace}
					border={`1px solid`}
					borderRadius="4px"
					boxShadow="inset 0 1px 1px rgb(0 0 0 / 5%)"
					className="well"
					overflow="hidden"
					{...messageStyle}
				>
					{placeholderFlag ? (
						<Loading size="small" />
					) : validatingFlag ? (
						milestonesFlag ? (
							<Block display="flex" alignItems="center">
								<Block marginRight={theme.smallSpace}>
									<Icon name="saving" spinFlag />
								</Block>
								{translate("ai.validatingMilestonesData")}
							</Block>
						) : aiAddActionItemsFlag ? (
							<Block>
								<Icon name="saving" marginRight={theme.smallSpace} spinFlag />
								{translate("ai.validatingData.addActionItems")}
							</Block>
						) : (
							<>
								{translate("ai.validatingData")}
								<Block whiteSpace="nowrap" overflow="hidden" display="flex" alignItems="center">
									<Block>
										<Icon name="saving" spinFlag />
									</Block>
									<Block
										display="inline-block"
										whiteSpace="nowrap"
										overflow="hidden"
										marginLeft={theme.tinySpace}
									>
										{content}
									</Block>
								</Block>
								{translate("ai.validatingData.workingInBackground")}
							</>
						)
					) : (
						<Block display="flex">
							<Block flex="1">
								<Parser parseHtmlFlag={parseHtmlFlag} noParserFlag={parseHtmlFlag && streamFlag}>
									{streamFlag ? streamContent : content}
								</Parser>
							</Block>
							{showRestartButtonFlag && (
								<Block>
									<RestartButton index={index} restartChatFromMessage={restartChatFromMessage} />
								</Block>
							)}
							{showRegenerateButtonFlag && (
								<Block>
									<RegenerateButton index={index} generateNewResponse={generateNewResponse} />
								</Block>
							)}
							<Block>
								<CopyMessageToClipboardButton message={content} />
							</Block>
						</Block>
					)}
				</Block>
			</Block>
		</Block>
	);
};

ChatMessage.propTypes = propTypes;

export default ChatMessage;
