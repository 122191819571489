import Block from "./Block";
import PropTypes from "prop-types";
import React, { Suspense } from "react";
import isString from "lodash/isString";
import { theme } from "./Theme";

let Icon = React.lazy(() => import("./Icon/Icon"));

let propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string.isRequired,
	message: PropTypes.node.isRequired,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let Toast = ({ className, icon, message, style }) => (
	<Block className={className} style={style} display="flex" alignItems="center" fontFamily={theme.defaultFontFamily}>
		<Block padding="0 0.75rem 0 0.1rem" fontSize="1.5rem">
			<Suspense>
				<Icon data-testid={`${icon}-icon`} name={icon} spinFlag={icon === "saving"} />
			</Suspense>
		</Block>
		<Block
			data-testid={isString(message) && `toast-message-${message}`}
			className="toast-message"
			padding="0 0.3rem 0 0"
			$style={` a { color: #fff };`}
		>
			{message}
		</Block>
	</Block>
);

Toast.propTypes = propTypes;

export default Toast;
