import Select from "../../Select/Select";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import styled from "styled-components";

let StyledSelect = styled(Select)`
	& .select__control {
		border-color: ${(props) => (props.errorVisible ? props.theme.danger : undefined)};
		&:hover,
		&.hover {
			border-color: hsl(0, 0%, 80%);
		}
		&.select__control--is-focused:hover {
			border-color: ${(props) => (props.errorVisible ? props.theme.danger : undefined)};
		}
	}
`;

let propTypes = {
	createableFlag: PropTypes.bool,
	"data-testid": PropTypes.string,
	errorVisible: PropTypes.bool,
	multipleFlag: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.arrayOf(PropTypes.string),
	]),
};

let SelectWrapper = ({
	"data-testid": dataTestId,
	createableFlag,
	errorVisible,
	multipleFlag,
	name,
	options,
	onChange,
	setOptions,
	value,
	...props
}) => {
	let { setFormTouched } = useFormContext();
	let handleChange = useCallback(
		(e) => {
			setFormTouched(name, true);
			if (onChange) onChange(e);
		},
		[name, onChange, setFormTouched]
	);
	if (multipleFlag && value && isString(value)) {
		value = value.split(",");
	}
	let addedValue =
		createableFlag &&
		(isArray(value)
			? value.find((value) => !options?.some((option) => option?.value === value))
			: !options?.some((option) => option.value === value)
			? value
			: null);
	let optionsWithAddedValue = useMemo(
		() => (addedValue ? [...options, { label: addedValue, value: addedValue }] : options),
		[addedValue, options]
	);
	let newOptions = useMemo(
		() =>
			optionsWithAddedValue.some((x) => x.group)
				? optionsWithAddedValue.reduce((acc, cur) => {
						let groupOption = acc.find((y) => y.label === cur.group);
						let newOption = cur;
						if (groupOption) {
							groupOption.options.push(newOption);
						} else {
							acc.push({
								label: cur.group,
								options: [newOption],
							});
						}
						return acc;
				  }, [])
				: optionsWithAddedValue,
		[optionsWithAddedValue]
	);
	if (addedValue) setOptions(newOptions);
	let newValue = useMemo(
		() =>
			multipleFlag && isArray(value)
				? optionsWithAddedValue.filter((option) => value.includes(option.value))
				: optionsWithAddedValue.find((option) => option.value === value),
		[multipleFlag, optionsWithAddedValue, value]
	);
	return (
		<StyledSelect
			createableFlag={createableFlag}
			options={newOptions}
			multipleFlag={multipleFlag}
			errorVisible={errorVisible}
			value={newValue}
			data-testid={dataTestId}
			name={name}
			{...props}
			onChange={handleChange}
		/>
	);
};

SelectWrapper.propTypes = propTypes;

export default SelectWrapper;
