import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { removeUnderscoredKeys } from "@clearpoint/utils";

let useChartPreviewPromise = () => {
	let { set } = useOldQueryStore();
	let { measureId } = useFormValue();
	let {
		session: { periodId },
	} = useOldSession();

	return useCallback(
		(chartData) =>
			measureId &&
			periodId &&
			set({
				parent: "measure",
				parentId: measureId,
				object: "chart",
				queryString: `?preview=true&periodId=${periodId}`,
				data: removeUnderscoredKeys(chartData),
				skipClearFlag: true,
			}),
		[measureId, periodId, set]
	);
};

export default useChartPreviewPromise;
