import isEqual from "lodash/isEqual";

let getSetData = ({ dataState, options, setState, visibleKey, x }) => {
	let { allowEmptyListFlag } = options;
	if (x && (x.length > 0 || allowEmptyListFlag)) {
		if (!dataState || dataState.length === 0) x.forEach((x) => x[visibleKey] === true);
		setState({
			dataState: (dataState) => (!isEqual(dataState, x) ? x : dataState),
		});
	}
};

export default getSetData;
