import { useMemo, useState, useTransition } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

import useFormatChart from "./useFormatChart";
import { useOldSession } from "@clearpoint/old-session/index";

let useChartFormatData = ({
	chart,
	chartId,
	chartFormatDataProp,
	get,
	measureId,
	session,
	setChartFormatData,
	translate,
	...parameters
}) => {
	let [, startTransition] = useTransition();
	let storeHook = useOldQueryStore();
	if (!get) ({ get } = storeHook);
	let formatChart = useFormatChart();
	let sessionHook = useOldSession();
	let {
		session: { exportFlag, periodId: sessionPeriodId },
	} = session || sessionHook;

	let [initialChartLoadedFlag, setInitialChartLoadedFlag] = useState(false);
	let [chartLoadingFlag, setChartLoadingFlag] = useState(false);
	let periodId = parameters.periodId || sessionPeriodId;

	let chartPeriodData = chart || (chartId && periodId && get({ object: "chart", objectId: chartId, periodId }));

	let { name, altText } = chartPeriodData || emptyObject;
	if (!measureId) measureId = chartPeriodData?.measureId;

	let measureGridList =
		chart?.seriesData || (measureId && get({ parent: "measure", parentId: measureId, object: "measureGrid" }));

	let chartFormatData = useMemo(() => {
		if (measureGridList && chartPeriodData) {
			let chartFormatData = formatChart({ chartData: chartPeriodData, measureGridList, translate });
			startTransition(() => {
				if (setChartFormatData) setChartFormatData(chartFormatData);
				setInitialChartLoadedFlag(true);
				setChartLoadingFlag(false);
			});
			return chartFormatData;
		} else if (initialChartLoadedFlag) {
			setChartLoadingFlag(true);
		}
		return emptyObject;
	}, [chartPeriodData, formatChart, initialChartLoadedFlag, measureGridList, setChartFormatData, translate]);

	if (exportFlag && chartFormatData?.exporting?.buttons?.contextButton) {
		chartFormatData.exporting.buttons.contextButton.enabled = false;
	}

	return {
		chartFormatData: chartFormatDataProp ?? chartFormatData,
		chartName: name,
		initialChartLoadedFlag,
		chartLoadingFlag,
		setChartFormatData,
		chartDescription: altText,
	};
};

export default useChartFormatData;
