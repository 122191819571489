import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	measureId: PropTypes.number.isRequired,
	objectIdList: PropTypes.arrayOf(PropTypes.number).isRequired,
};
let ChartsTab = ({ measureId, objectIdList }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let chartList = get({ parent: "measure", parentId: measureId, object: "chart" });
	let selectedChartList = useMemo(
		() => chartList?.filter((chart) => objectIdList.some((objectId) => objectId === chart.objectId)),
		[objectIdList, chartList]
	);
	return (
		<FormGroup
			title={translate("edit.element.elementsToUpdate", { objects: "charts" })}
			paddingBottom={theme.tinySpace}
		>
			{selectedChartList?.map((chart) => (
				<ListItem key={chart.objectId} left={<span>{chart.name}</span>} size="small" />
			))}
		</FormGroup>
	);
};
ChartsTab.propTypes = propTypes;
export default ChartsTab;
