let parseTableString = (content) => {
	let tableRegex = /(^\|.*|[\s\S]*\|$\n)+/gm;
	if (content.match(tableRegex)) {
		content = content.replace(/(?:^\|\w|$\|*\n)\n{1}/gm, "\n");
		let table = content.match(tableRegex);
		content = content.replace(tableRegex, `\n ${table} \n`);
		return content;
	} else {
		return content;
	}
};

export default parseTableString;
