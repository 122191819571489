/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { set } from "lodash";

import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import DiscussionButton from "@clearpoint/old-theme/Button/Button.Discussion";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import FormSaveBar from "@clearpoint/old-theme/Form/Form.SaveBar";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useQueryParameters,useScorecardLockedFlag } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

import BadgeList from "./BadgeList";
import EditSplitButton from "./EditSplitButton";
import FavoriteSplitButton from "./FavoriteSplitButton";
import Header from "./Header";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let Toolbar = () => {
	let { editElementFlag, editLayoutFlag, inModalFlag, setLocalState } = useLocalState();
	let [editHeaderFlag, setEditHeaderFlag] = useState(false);
	let [dataStale, setDataStale] = useState(false);
	let { layout, element } = useDetailPageData();
	let { exportFlag } = useOldSession().session;
	let { handlingSubmitFlag } = useFormContext();
	let { refresh, staleData } = useOldQueryStore();
	let [dataRefreshing, setDataRefreshing] = useState(false);

	let closeEditElementMode = useCallback(() => {
		setLocalState({ editElementFlag: false });
		setEditHeaderFlag(false);
	}, [setLocalState]);

	let closeEditLayoutMode = useCallback(() => {
		setLocalState({ editLayoutFlag: false });
		setEditHeaderFlag(false);
	}, [setLocalState]);

	useEffect(() => {
		if (staleData.some((data) => data.object === element?.object && data.objectId === element?.objectId)) {
			setDataRefreshing(false);
			setDataStale(true);
		} else {
			setDataRefreshing(false);
			setDataStale(false);
		}
	}, [staleData, element?.object, element?.objectId, staleData.length]);

	let refreshPage = useCallback(() => {
		if (staleData.some((data) => data.object === element?.object && data.objectId === element?.objectId)) {
			setDataRefreshing(true);
			refresh();
		}
	}, [refresh, staleData, element?.object, element?.objectId]);

	// Don't leave edit mode open across element changes, i.e. close if query parameters change
	let { scorecardId, object, objectId, periodId } = useQueryParameters();
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(closeEditElementMode, [scorecardId, object, objectId, periodId]);

	let { hideTitle: hideTitleFlag } = layout?.reportOptions || emptyObject;

	return (
		<>
			{editElementFlag || editLayoutFlag || handlingSubmitFlag ? (
				<FormSaveBar close={editLayoutFlag && !scorecardLockedFlag ? closeEditLayoutMode : closeEditElementMode}>
					<Header editHeaderFlag={editHeaderFlag} setEditHeaderFlag={setEditHeaderFlag} />
				</FormSaveBar>
			) : (
				<Block>
					{/* do not remove unstyled wrapping Block -- it helps minHeight and flexWrap work as expected on child*/}
					<Block
						data-testid="toolbar"
						display="flex"
						alignItems="start"
						justifyContent="space-between"
						$style="* {margin-bottom: 0;}"
						padding={exportFlag ? "0" : `${theme.mediumSpace} ${theme.largeSpace} 0`}
						width="100%"
						minHeight="60px"
						height="100%"
					>
						{!hideTitleFlag && (
							<Header
								editHeaderFlag={editHeaderFlag && !scorecardLockedFlag}
								setEditHeaderFlag={setEditHeaderFlag}
							/>
						)}
						{!exportFlag && (
							<Block
								display="flex"
								flex="1"
								alignItems="center"
								flexWrap="nowrap"
								gap={`${theme.smallSpace} 0`}
								justifyContent="flex-end"
							>
								<BadgeList />
								{!inModalFlag && (
									<Block display="flex" alignItems="start" maxHeight="34px" justifyContent="flex-end">
										{!exportFlag && (dataStale || dataRefreshing) && (
											<Button
												color="white"
												onClick={refreshPage}
												disabled={dataRefreshing}
												marginLeft={theme.smallSpace}
											>
												<Icon name="refresh" spinFlag={dataRefreshing} />
											</Button>
										)}
										<DiscussionButton />
										<FavoriteSplitButton />
										<EditSplitButton />
									</Block>
								)}
							</Block>
						)}
					</Block>
				</Block>
			)}
		</>
	);
};

export default Toolbar;
