import { useOldQueryStore } from "@clearpoint/old-query-store";
import { isEqual } from "lodash";

let useClearUpdatedLinks = () => {
	let { clear } = useOldQueryStore();

	let clearUpdatedLinks = ({ newLinkList, oldLinkList }) => {
		if (!newLinkList) return;
		let linkUpdates = [];
		let oldLinkListIds = oldLinkList?.map((x) => x.objectId);
		let newLinkListIds = newLinkList?.map((x) => x.objectId);
		if (!isEqual(newLinkListIds, oldLinkListIds)) {
			let addedLinks = newLinkList?.filter((x) => !oldLinkListIds?.includes(x.objectId));
			if (addedLinks?.length > 0) linkUpdates = [...linkUpdates, ...addedLinks];
			let deletedLinks = oldLinkList?.filter((x) => !newLinkListIds?.includes(x.objectId));
			if (deletedLinks?.length > 0) linkUpdates = [...linkUpdates, ...deletedLinks];
		}
		linkUpdates?.forEach((link) => clear({ object: link.object, objectId: link.objectId }));
	};

	return clearUpdatedLinks;
};

export default useClearUpdatedLinks;
