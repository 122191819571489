import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import createFilter from "./useCreateFilter.createFilter";

export default function useCreateFilter() {
	let translate = useTranslate();

	return useCallback(
		(keys) => {
			let createFilterCallback = createFilter(translate);
			return createFilterCallback(keys);
		},
		[translate]
	);
}
