import Select from "./FormSelect";
import { useEffect, useMemo } from "react";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { emptyArray, idealTextColor } from "@clearpoint/utils";

let StyledSelect = styled(Select)`
	${(props) => props.$style}
`;

let propTypes = {
	name: PropTypes.string,
	showEvenWithNoOptionsFlag: PropTypes.bool,
};

let FormSelectTag = ({ name, showEvenWithNoOptionsFlag, ...props }) => {
	let translate = useTranslate();
	let { setSession } = useOldSession();
	let { get } = useOldQueryStore();
	let tagList = get({ object: "tag" });
	tagList = useMemo(() => tagList?.filter((x) => x.active), [tagList]);
	let value = useFormValue(name);
	let $style;
	if (tagList) {
		if (isArray(value)) {
			value = value.filter((x) => tagList.map((tag) => tag.tagId).includes(x));
		} else if (!tagList.map((tag) => tag.tagId).includes(value)) {
			value = null;
		}
	}
	if (props.multipleFlag && value && tagList) {
		let selectedTagList = (isArray(value) ? value : value ? [value] : []).map((tagId) =>
			tagList.find((x) => x.tagId === tagId)
		);
		$style = selectedTagList
			.map(
				(tag, i) => `& .select__multi-value:nth-child(${i + 1}) {
background-color: ${tag.color} !important;
}`
			)
			.join("\n");
	}
	if (!props.multipleFlag) {
		let selectedTag = tagList?.find((x) => x.tagId === value);
		if (selectedTag)
			$style = `& .tag-label {
			display: inline-block;
			background-color: ${selectedTag.color};
			border-radius: 14px;
			padding: 2px 11px 3px 11px;
			color: ${idealTextColor(selectedTag.color)}
		}`;
	}
	let options = useMemo(() => {
		if (tagList === undefined) return;
		if (!tagList) return [];
		return tagList.map((x) => ({
			label: `<span class="tag-label">${x.name}</span>`,
			value: x.tagId,
		}));
	}, [tagList]);
	let showFlag = showEvenWithNoOptionsFlag || (options && !isEqual(options, emptyArray));
	useEffect(() => {
		setSession({
			tagOptions: tagList?.map((x) => ({
				label: x.name,
				value: x.tagId,
			})),
		});
	}, [tagList, setSession]);
	return showFlag ? (
		<StyledSelect
			name={name}
			options={options}
			label={translate("global.tags")}
			$style={$style}
			placeholder={translate("measureLibrary.selectTags") + "..."}
			{...props}
		/>
	) : null;
};

FormSelectTag.propTypes = propTypes;
export default FormSelectTag;
