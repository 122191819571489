import { useEffect } from "react";
import { useFormContext } from "../Form";
import handleTab from "./useTab.handleTab";

let useTab = ({ name, query }) => {
	// tab = four spaces
	let { setFormValue } = useFormContext();
	useEffect(() => {
		let handleTabCallback = handleTab({ setFormValue, name });
		let element = document.querySelector(query);
		if (element) element.addEventListener("keydown", handleTabCallback);
		return () => document.querySelector(query)?.removeEventListener("keydown", handleTabCallback);
	});
};
export default useTab;
