import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useChartTypeList } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};

let FormSelectChartCategory = ({ name, ...props }) => {
	let translate = useTranslate();
	let { customChartTypeList, defaultChartTypeList } = useChartTypeList();

	let options = useMemo(() => {
		let categoryList = [...(defaultChartTypeList || []), ...(customChartTypeList || [])].map(
			(x) => x.category || "chart.custom"
		);
		let uniqueCategoryList = [...new Set(categoryList)];
		return uniqueCategoryList.map((x) => ({ value: x, label: translate(x) }));
	}, [customChartTypeList, defaultChartTypeList, translate]);
	return <Select name={name} options={options} placeholder={translate("select.selectChartType") + "..."} {...props} />;
};

FormSelectChartCategory.propTypes = propTypes;
export default FormSelectChartCategory;
