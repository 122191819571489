import { useEffect, useState } from "react";
import http from "@clearpoint/services/httpService/index";
import { removeEmpty } from "@clearpoint/utils";

let useCount = (payload) => {
	let [count, setCount] = useState();
	useEffect(() => {
		if (payload?.filter?.aggregateScorecardType !== undefined) {
			http.debouncedPost("/count", removeEmpty(payload)).then((count) => setCount(count));
		}
	}, [payload]);
	return count;
};

export default useCount;
