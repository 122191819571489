import Block from "../../../Block";
import Button from "../../../Button/Button";
import FormGroup from "../../../Form/Form.Group";
import Icon from "../../../Icon/Icon";
import ListItem from "../../../ListItem/ListItem";
import StyleWrapper from "../../../StyleWrapper";
import { useCallback } from "react";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";
import { useStateObject } from "@clearpoint/hooks";

let propTypes = {
	name: PropTypes.string.isRequired,
	previewObjectList: PropTypes.string,
};
let InputFilterList = ({ name, previewObjectList }) => {
	let translate = useTranslate();
	let { getPromise } = useOldQueryStore();
	let [{ previewList, matchCount }, setState] = useStateObject({
		previewList: [],
		matchCount: null,
	});
	let filter = useFormValue(name);
	let preview = useCallback(() => {
		getPromise({
			object: "filter",
			queryString: "?start=1&count=10",
			payload: {
				filterProperties: filter.filter,
				filterRule: filter.filterRule,
				objectList: previewObjectList,
				periodId: filter.periodId,
				seriesType: filter.seriesType,
			},
		}).then((filterData) => {
			setState({ previewList: filterData?.matches || [], matchCount: filterData?.matchCount || 0 });
		});
	}, [filter, getPromise, previewObjectList, setState]);
	return (
		<FormGroup
			data-testid="preview-form-group"
			title={translate("edit.script.filterPreview")}
			paddingBottom={theme.smallSpace}
		>
			{previewList.map((preview, i) => {
				return (
					<StyleWrapper
						marginBottom={i === previewList.length - 1 ? theme.mediumSpace + "!important" : undefined}
						minHeight="42px"
						key={i + preview.objectId}
					>
						<ListItem
							left={
								<Block display="flex" alignItems="center">
									<Icon name={preview.object} />
									<Block
										ellipsisFlag
										marginLeft={theme.smallSpace}
										display="flex"
										flexDirection="column"
										whiteSpace="normal !important"
									>
										<Block ellipsisFlag>{preview.name}</Block>
										<Block ellipsisFlag>
											<small>{preview.parentName}</small>
										</Block>
									</Block>
								</Block>
							}
							padding={theme.tinySpace}
							size="small"
						/>
					</StyleWrapper>
				);
			})}
			{filter?.filter?.length > 0 && (
				<Block data-testid={"preview-filter-count"} display="flex" alignItems="center" flexWrap="wrap">
					<Block flex="1" whiteSpace="nowrap" marginBottom={theme.tinySpace}>
						{matchCount === null && translate("edit.script.previewFilterText")}
						{matchCount !== null &&
							matchCount <= 10 &&
							translate("edit.script.totalMatches", { count: matchCount })}
						{matchCount > 10 &&
							translate("edit.script.excessMatches", { count: matchCount, countMinusTen: matchCount - 10 })}
					</Block>
					<Block flex="1" textAlign="right" whiteSpace="nowrap">
						<Button data-testid={"preview-filter-button"} size="small" onClick={preview}>
							<Icon name="filter" marginRight={theme.smallSpace} />
							{translate("edit.script.previewFilter")}
						</Button>
					</Block>
				</Block>
			)}
		</FormGroup>
	);
};
InputFilterList.propTypes = propTypes;

export default InputFilterList;
