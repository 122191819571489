import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let LineStrokeSelect = ({ name, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("edit.chartSeries.chartDefault"), value: "" },
			{ label: translate("edit.chartSeries.solid"), value: "Solid" },
			{ label: translate("edit.chartSeries.shortDash"), value: "ShortDash" },
			{ label: translate("edit.chartSeries.shortDot"), value: "ShortDot" },
			{ label: translate("edit.chartSeries.shortDashDot"), value: "ShortDashDot" },
			{ label: translate("edit.chartSeries.shortDashDotDot"), value: "ShortDashDotDot" },
			{ label: translate("edit.chartSeries.dot"), value: "Dot" },
			{ label: translate("edit.chartSeries.dash"), value: "Dash" },
			{ label: translate("edit.chartSeries.longDash"), value: "LongDash" },
			{ label: translate("edit.chartSeries.dashDot"), value: "DashDot" },
			{ label: translate("edit.chartSeries.longDashDot"), value: "LongDashDot" },
			{ label: translate("edit.chartSeries.longDashDotDot"), value: "LongDashDotDot" },
		],
		[translate]
	);
	return <Select placeholder={translate("edit.selectStroke")} options={options} name={name} {...props} />;
};

LineStrokeSelect.propTypes = propTypes;

export default LineStrokeSelect;
