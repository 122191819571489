import Button from "../../../Button/Button";
import PropTypes from "prop-types";
import styled from "styled-components";

let StyledButton = styled(Button)`
	font-family: monospace;
	margin-right: ${(props) => props.theme.tinySpace};
`;

let propTypes = {
	insertText: PropTypes.func,
	text: PropTypes.node,
};

let OperationButton = ({ insertText, text }) => {
	return (
		<StyledButton color="default" onClick={() => insertText(text)}>
			{text}
		</StyledButton>
	);
};

OperationButton.propTypes = propTypes;

export default OperationButton;
