import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
	parentName: PropTypes.string,
};
let FormFieldDisplayPeriodGroupId = ({ parentName, name }) => {
	let { get } = useOldQueryStore();
	let periodGroupId = useFormValue(name);
	let periodGroup = useFormValue(parentName).periodGroup;
	if (!periodGroup)
		periodGroup =
			periodGroupId && get({ object: "periodGroup" })?.find((x) => x.periodGroupId === periodGroupId)?.name;
	return <>{periodGroup || null}</>;
};
FormFieldDisplayPeriodGroupId.propTypes = propTypes;

export default FormFieldDisplayPeriodGroupId;
