import { useCallback } from "react";
import { useStateObject } from "@clearpoint/hooks";

let useDropZoneHover = () => {
	let [{ hoverFlag }, setState] = useStateObject({
		hoverFlag: false,
	});
	let onHover = useCallback(
		(e) => {
			if (!!document.querySelector(".smooth-dnd-ghost") && e.isSource !== false && !hoverFlag) {
				setState({ hoverFlag: true });
			}
		},
		[hoverFlag, setState]
	);
	let offHover = useCallback(() => {
		if (hoverFlag) setState({ hoverFlag: false });
	}, [hoverFlag, setState]);
	return { hoverFlag, onHover, offHover };
};

export default useDropZoneHover;
