import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectComparison = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: "gt", label: ">" },
			{ value: "gte", label: ">=" },
			{ value: "lt", label: "<" },
			{ value: "lte", label: "<=" },
			{ value: "eq", label: "=" },
			{ value: "neq", label: "!=" },
			{ value: "btw", label: translate("global.range") },
			{ value: "any", label: translate("global.any") },
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectComparison;
