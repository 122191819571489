import isArray from "lodash/isArray";
import { capitalizeFirstLetter } from "@clearpoint/utils";


let syncChildLinkWeights = (submitValue) => {
	if (!isArray(submitValue?.links)) return submitValue;

	let createChildLinkWeightSyncFunction = (linkType) => (link) => {
		if (link.fieldId === `child${capitalizeFirstLetter(linkType)}`) {
			let childLink = link;
			let correspondingStandardLink = submitValue.links.find(
				(x) => x.fieldId === linkType && x.objectId === childLink.objectId
			);
			if (correspondingStandardLink) {
				link.weight = correspondingStandardLink.weight;
			}
		}
	};

	let linkTypeWithPossibleChildLinkTypeList = ["initiatives", "measures"];
	let matchChildLinkWeightToUpdatedLinkWeightCallbackList = linkTypeWithPossibleChildLinkTypeList.map((x) =>
		createChildLinkWeightSyncFunction(x)
	);

	for (let link of submitValue.links) {
		for (let weightMatchingCallback of matchChildLinkWeightToUpdatedLinkWeightCallbackList) {
			weightMatchingCallback(link);
		}
	}

	return submitValue;
};

export default syncChildLinkWeights;
