import Block from "../Block";
import PaginationSelect from "./Select";
import SharedControl from "./SharedControl";
import { useMemo } from "react";
import { usePagination } from "@clearpoint/providers/PaginationProvider";
import PropTypes from "prop-types";
import styled from "styled-components";

let StyledPlaceholder = styled(Block)`
	height: 28px;
`;

let propTypes = {
	color: PropTypes.string,
	setSortOrder: PropTypes.func,
	size: PropTypes.oneOf(["small", "medium", "large"]),
	sortOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	),
	sortOrder: PropTypes.string,
};

let defaultProps = {
	color: "success",
	size: "small",
};

let PageControl = ({ color, size, sortOptions, sortOrder, ...props }) => {
	let { pageCount, goToPage, goToNextPage, goToPreviousPage, currentPage, getData, data } = usePagination();
	let setSortOrder = (e) => {
		props.setSortOrder && props.setSortOrder(e.value);
		getData();
	};

	let selectValue = useMemo(() => sortOptions?.find((x) => x.value === sortOrder), [sortOptions, sortOrder]);

	return pageCount > 1 ? (
		<Block $marginRight={size === "small" ? "50px" : "34px"} display="flex" flexDirection="row">
			<Block flex="1"></Block>
			<SharedControl
				size={size}
				color={color}
				pageCount={pageCount}
				currentPage={currentPage}
				goToPage={goToPage}
				goToPreviousPage={goToPreviousPage}
				goToNextPage={goToNextPage}
			/>
			{sortOptions && data?.length > 0 && (
				<PaginationSelect options={sortOptions} onChange={setSortOrder} value={selectValue} />
			)}
			<Block flex="1"></Block>
		</Block>
	) : sortOptions && data?.length > 0 ? (
		<Block $marginRight={size === "small" ? "50px" : "34px"} display="flex" flexDirection="row">
			<Block flex="1"></Block>
			<PaginationSelect options={sortOptions} onChange={setSortOrder} value={selectValue} />
			<Block flex="1"></Block>
		</Block>
	) : (
		<StyledPlaceholder>&nbsp;</StyledPlaceholder>
	);
};

PageControl.propTypes = propTypes;
PageControl.defaultProps = defaultProps;

export default PageControl;
