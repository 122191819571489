import ChartPreview from "../../../Chart/Chart.Preview";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayChart = ({ name }) => {
	let translate = useTranslate();
	let chart = useFormValue(name);
	let { chartId, measureId } = chart;
	let { excelFlag, exportFlag } = useOldSession().session;
	return (
		<span>
			{excelFlag ? (
				translate("error.chartsUnavailable")
			) : (
				<ChartPreview chartId={chartId} measureId={measureId} chartHeight={200} noExportButtonFlag={exportFlag} />
			)}
		</span>
	);
};

FormFieldDisplayChart.propTypes = propTypes;
export default FormFieldDisplayChart;
