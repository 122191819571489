import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormSelectDateRange = ({ name, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("layout.allPeriods"), value: "all" },
			{ label: translate("layout.currentPeriod"), value: "cp" },
			{ label: translate("layout.currentCalendarYear"), value: "cy" },
			{ label: translate("layout.calendarYTD"), value: "cytd" },
			{ label: translate("layout.currentFiscalYear"), value: "fy" },
			{ label: translate("layout.fiscalYTD"), value: "fytd" },
			{ label: translate("layout.lastNPeriods"), value: "last" },
			{ label: translate("layout.plusMinusNPeriods"), value: "plusminus" },
			{ label: translate("layout.nextNPeriods"), value: "next" },
		],
		[translate]
	);
	return <Select options={options} name={name} {...props} />;
};

FormSelectDateRange.propTypes = propTypes;

export default FormSelectDateRange;
