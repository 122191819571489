import FormSelect from "../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectAggregateActionItemType = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("edit.allActionItems"),
				value: 0,
			},
			{
				label: translate("edit.actionItemNameEquals"),
				value: 3,
			},
			{
				label: translate("edit.actionItemNameContains"),
				value: 4,
			},
			{
				label: translate("edit.series.actionItemsTaggedWith"),
				value: 7,
			},
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectAggregateActionItemType;
