let baseLanguageLookup = {};

let setLanguage = ({
	clearScorecardIdList,
	generateTranslateFunction,
	loadingFlagRef,
	localeId,
	setTranslateLookup,
	translateLookup,
}) => {
	if (!localeId) return;
	if (!loadingFlagRef.current && !baseLanguageLookup[localeId]) {
		loadingFlagRef.current = true;
		import(/* webpackPrefetch: true */ `../../../languages/src/${localeId}.json`).then(({ default: language }) => {
			baseLanguageLookup[localeId] = language;
			setTranslateLookup({ [localeId]: generateTranslateFunction(language) });
			clearScorecardIdList();
			loadingFlagRef.current = false;
		});
	} else if (baseLanguageLookup[localeId] && !translateLookup[localeId]) {
		setTranslateLookup({ [localeId]: generateTranslateFunction(baseLanguageLookup[localeId]) });
		clearScorecardIdList();
	}
};
export default setLanguage;
export { baseLanguageLookup };
