import Block from "@clearpoint/old-theme/Block";
import FormFieldDisplayToday from "@clearpoint/old-theme/Form/Field/Display/Today";

let Today = () => (
	<Block display="flex" justifyContent="flex-end">
		<FormFieldDisplayToday marginLeft="auto" name="updates.calculatedValues.today" />
	</Block>
);

export default Today;
