let getChildMilestoneList = (milestoneList, parentMilestone) => {
	milestoneList = milestoneList.filter((x) => x.active !== false);
	let indexOfParentMilestone = milestoneList.findIndex((x) => x.objectId === parentMilestone.objectId);

	let childMilestoneList = [];
	for (let i = indexOfParentMilestone + 1; i < milestoneList.length; i++) {
		let milestone = milestoneList[i];
		if (milestone.treeLevel > parentMilestone.treeLevel) {
			childMilestoneList = [...childMilestoneList, milestone];
			continue;
		} else {
			break;
		}
	}
	return childMilestoneList;
};

export default getChildMilestoneList;
