import generateRetry from "./Store.generateRetry";
import { getRoute } from "@clearpoint/utils";

let generateTrashFunction =
	({ clear, clearAssociated, http, httpExpress }) =>
	async (parameters) => {
		let { elementFlag, expressFlag, listKey, method, object, objectId, objectIdList, queryString } = parameters;
		let retry = generateRetry();
		let httpFunction = expressFlag ? httpExpress : http;
		if ((!objectId && !objectIdList) || !object) {
			throw new Error("Bad call to trash." + JSON.stringify({ objectId, object }));
		} else if (objectIdList) {
			if (elementFlag) {
				let url = `/delete/?object=${object}`;
				let makeRequest = () =>
					httpFunction
						.post(url, {
							objectIds: objectIdList,
						})
						.catch(async (e) => {
							let { retryRequest } = retry({
								error: e,
								makeRequest,
								url,
							});
							return retryRequest;
						});
				let response = await makeRequest();
				clear({ object });
				clearAssociated(object);
				return response;
			} else if (method === "post") {
				let response;
				if (listKey) {
					if (["pageExport", "image", "report", "image", "attachment"].includes(object)) {
						objectIdList = objectIdList.map((x) => ({ [object + "Id"]: x }));
					}
					let url = `/${getRoute(object)}${queryString || "?delete=true"}`;
					let makeRequest = () =>
						httpFunction
							.post(url, {
								[listKey]: objectIdList,
							})
							.then(() => clearAssociated(object))
							.catch(async (e) => {
								let { retryRequest } = retry({
									error: e,
									makeRequest,
									url,
								});
								return retryRequest;
							});
					response = await makeRequest();
				} else {
					let url = `/${getRoute(object)}${queryString || "?delete=true"}`;
					let makeRequest = () =>
						httpFunction
							.post(url, {
								[`${object}Ids`]: objectIdList,
							})
							.then(() => clearAssociated(object))
							.catch(async (e) => {
								let { retryRequest } = retry({
									error: e,
									makeRequest,
									url,
								});
								return retryRequest;
							});
					response = await makeRequest();
				}
				for (let objectId of objectIdList) {
					clear({ object, objectId });
				}
				return response;
			} else {
				let url = `/${getRoute(object)}?bulk=true`;
				let makeRequest = () =>
					httpFunction
						.delete(url, {
							[`${object}Ids`]: objectIdList,
						})
						.catch(async (e) => {
							let { retryRequest } = retry({
								error: e,
								makeRequest,
								url,
							});
							return retryRequest;
						});
				let response = await makeRequest();
				for (let objectId of objectIdList) {
					clear({ object, objectId });
				}
				clearAssociated(object);
				return response;
			}
		} else {
			let url = `/${getRoute(object)}/${objectId}`;
			let makeRequest = () =>
				httpFunction.delete(`/${getRoute(object)}/${objectId}`).catch(async (e) => {
					let { retryRequest } = retry({
						error: e,
						makeRequest,
						url,
					});
					return retryRequest;
				});
			let response = await makeRequest();
			clear({ object, objectId });
			clearAssociated(object);
			return response;
		}
	};

export default generateTrashFunction;
