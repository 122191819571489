import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import ModalWindowRevisionHistory from "@clearpoint/old-theme/ModalWindow/ModalWindow.RevisionHistory";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let RevisionHistoryDropdownItem = () => {
	let translate = useTranslate();
	let { object, objectId } = useFormValue();

	let initialFilter = useMemo(
		() => [
			{
				field: "object",
				value: object,
			},
			{
				field: "objectId",
				value: objectId,
			},
		],
		[object, objectId]
	);

	return (
		<ModalWindowOnClick
			ModalWindow={ModalWindowRevisionHistory}
			filterOptionList={["activity", "period", "user"]}
			initialFilter={initialFilter}
		>
			<DropdownItem icon="revisionHistory">{translate("revisionHistory.revisionHistory")}</DropdownItem>
		</ModalWindowOnClick>
	);
};

export default RevisionHistoryDropdownItem;
