import { useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

import { useChartFormatData } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import Block from "../Block";
import Hide from "../Hide";
import Loading from "../Loading";
import { theme } from "../Theme";
import Chart from "./Chart";

let propTypes = {
	chartFormatData: PropTypes.object,
	chartId: PropTypes.number.isRequired,
	hideLoadingFlag: PropTypes.bool,
	HeaderRightComponent: PropTypes.elementType,
	measureId: PropTypes.number,
	periodId: PropTypes.number,
	session: PropTypes.object,
	setChartFormatData: PropTypes.func,
	showDescriptionFlag: PropTypes.bool,
	store: PropTypes.object,
	translate: PropTypes.func,
};

let ChartPreview = ({
	chart,
	chartHeight,
	chartId,
	HeaderRightComponent,
	headerText,
	hideLoadingFlag,
	measureId,
	noExportButtonFlag,
	periodId,
	session,
	setChartFormatData,
	showDescriptionFlag,
	store,
	translate,
	...props
}) => {
	let translateHook = useTranslate();
	if (!translate) translate = translateHook;
	let { chartFormatData, initialChartLoadedFlag, chartLoadingFlag, chartDescription } = useChartFormatData({
		chartFormatDataProp: props.chartFormatData,
		chartId,
		chart,
		get: store?.get,
		measureId,
		periodId,
		session,
		setChartFormatData,
		translate,
	});
	showDescriptionFlag = showDescriptionFlag && !!chartDescription;
	chartFormatData = useMemo(() => {
		let newChartFormatData = cloneDeep(chartFormatData);
		if (chartHeight && chartFormatData?.chart) {
			newChartFormatData.chart.height = chartHeight;
		}
		if (noExportButtonFlag && chartFormatData) {
			newChartFormatData.exporting = false;
		}
		return newChartFormatData;
	}, [chartFormatData, chartHeight, noExportButtonFlag]);
	
	return initialChartLoadedFlag ? (
		<>
			{hideLoadingFlag ? null : (
				<Hide
					visible={chartLoadingFlag}
					position="absolute"
					height="100%"
					width="100%"
					backgroundColor="hsla(100, 0%, 100%, .7)"
					display="flex"
					justifyContent="center"
					alignItems="center"
					zIndex="10000000000"
				>
					<Block fontSize="14px" fontWeight={theme.bold}>
						{translate("placeholder.loading")}
					</Block>
				</Hide>
			)}
			<Chart options={chartFormatData} />
			{showDescriptionFlag && <div>{chartDescription}</div>}
		</>
	) : (
		null
	);
};

ChartPreview.propTypes = propTypes;
export default ChartPreview;
