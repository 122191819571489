import PropTypes from "prop-types";
import styled from "styled-components";


let propTypes = {
	marginTop: PropTypes.string,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
};
let defaultProps = {
	size: "medium",
};

let HR = styled.hr`
	margin: ${(props) => props.theme[`${props.size}Space`]} 0;
	margin-top: ${(props) => props.marginTop};
`;

HR.propTypes = propTypes;
HR.defaultProps = defaultProps;

export default HR;
