import { useCallback, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import Row from "@clearpoint/old-theme/Row";
import { theme } from "@clearpoint/old-theme/Theme";

import Area from "./Area";
import AreaColumn from "./AreaColumn";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";


let containerPropTypes = {
	children: PropTypes.node.isRequired,
};

let Container = ({ children }) => {
	let { editLayoutFlag } = useLocalState();

	return editLayoutFlag ? (
		<Block display="flex" flexDirection="column" gap={theme.mediumLargeSpace}>
			{children}
		</Block>
	) : (
		children
	);
};



let propTypes = {
	pageNumber: PropTypes.number.isRequired,
	areaList: PropTypes.array.isRequired,
};

let TopArea = ({ pageNumber }) => <Area areaClass="area0" pageNumber={pageNumber} />;
let LeftArea = ({ pageNumber }) => <Area areaClass="area1" pageNumber={pageNumber} />;
let RightArea = ({ pageNumber }) => <Area areaClass="area2" pageNumber={pageNumber} />;
let BottomArea = ({ pageNumber }) => <Area areaClass="area3" pageNumber={pageNumber} />;


let Page = ({ pageNumber, areaList }) => {
	let checkPodsLength = useCallback(
		(filterFunc) => {
			return areaList.filter(filterFunc).some((x) => x.pods.length > 0);
		},
		[areaList]
	);

	let showTopAreaFlag = checkPodsLength((x) => x.class === "area0");
	let showRowFlag = checkPodsLength((x) => x.class === "area1" || x.class === "area2");
	let showBottomAreaFlag = checkPodsLength((x) => x.class === "area3");

	return (
		<Container>
			{pageNumber > 0 && <Block className="page-break" />}
			{showTopAreaFlag && <TopArea pageNumber={pageNumber} />}
			{showRowFlag && (
				<Row className="detail-page-row">
					<AreaColumn>
						<LeftArea pageNumber={pageNumber} />
					</AreaColumn>
					<AreaColumn>
						<RightArea pageNumber={pageNumber} />
					</AreaColumn>
				</Row>
			)}
			{showBottomAreaFlag && <BottomArea pageNumber={pageNumber} />}
		</Container>
	);
};

Container.propTypes = containerPropTypes;
Page.propTypes = propTypes;
export default Page;
