import CellWrapper from "../CellWrapper";
import FormFieldAnalysis from "@clearpoint/old-theme/Form/Field/Analysis";
import FormFieldDisplayAnalysis from "@clearpoint/old-theme/Form/Field/Display/Analysis";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};

let AnalysisCell = (props) => {
	let { cell, cellFlag, editCellFlag, parentName } = props;
	let { periodId } = cell;
	let name = `${parentName}.status.${periodId}.analysis`;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldAnalysis name={name} cellFlag={cellFlag} />
			) : (
				<FormFieldDisplayAnalysis name={name} />
			)}
		</CellWrapper>
	);
};

AnalysisCell.propTypes = propTypes;

export default AnalysisCell;
