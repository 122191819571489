import ElementTabDisplay from "./ElementTabDisplay";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import useFormValue from "../../../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { useCheckFeature } from "@clearpoint/hooks";


let propTypes = {
	currentObjectFlag: PropTypes.bool,
	object: PropTypes.string,
	objectList: PropTypes.array,
};
let ElementTabContent = ({ currentObjectFlag, object, objectList }) => {
	let checkFeature = useCheckFeature();
	let _fieldList = useFormValue("_fieldList");
	let { data: objectFieldList } = useFilter();
	let { get } = useOldQueryStore();
	let options = get({ object: "options" });
	let seriesFlag =
		object === "measure" ||
		(checkFeature("initiativeEvaluation") &&
			options.options.evaluations.some((x) => x.object === object && x.active));
	let elementFieldList = useMemo(
		() =>
			[
				...objectFieldList.filter((x) => x.type === "edit"),
				...objectFieldList.filter((x) => x.type === "custom" && !x.updateMode),
				...objectFieldList.filter((x) => x.type === "update"),
				...objectFieldList.filter((x) => x.type === "custom" && x.updateMode),
				...objectFieldList.filter((x) => x.type === "calculated"),
				...objectFieldList.filter((x) => x.type === "reference"),
			].filter((x) => x.visible),
		[objectFieldList]
	);
	let seriesFieldList = useMemo(
		() => objectFieldList.filter((x) => x.type === "series").filter((x) => x.visible),
		[objectFieldList]
	);
	let linkFieldList = useMemo(
		() => [
			...objectList
				.filter((_object) => _object !== object)
				.map((_object) => _fieldList.find((field) => field.object === _object && field.fieldId === "name")),
			...objectFieldList.filter((x) => x.type === "link"),
		],
		[_fieldList, objectFieldList, object, objectList]
	);
	return (
		<ElementTabDisplay
			currentObjectFlag={currentObjectFlag}
			elementFieldList={elementFieldList}
			objectFieldList={objectFieldList}
			linkFieldList={linkFieldList}
			object={object}
			seriesFieldList={seriesFieldList}
			seriesFlag={seriesFlag}
		/>
	);
};
ElementTabContent.propTypes = propTypes;

export default ElementTabContent;
