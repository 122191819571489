import DropdownItemCopy from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Copy";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import DropdownItemTrash from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Trash";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let CopyModalOptions = () => {
	let translate = useTranslate();
	return (
		<>
			<FormCheck name="copyKeyResults">{translate("goals.copyKeyResults")}</FormCheck>
			<FormCheck name="copyLinks">{translate("edit.copyLinks")}</FormCheck>
		</>
	);
};

let propTypes = {
	discussionFlag: PropTypes.bool,
	name: PropTypes.string,
	objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

let EditGoalDropdown = ({ discussionFlag, name, objectId }) => {
	let translate = useTranslate();
	let { setLocalState } = useLocalState();

	let openGoalModal = useCallback(() => {
		setLocalState({ modalGoalId: objectId, modalWindowVisible: true });
	}, [objectId, setLocalState]);

	let openDiscussionBar = useCallback(() => {
		setLocalState({ discussionVisible: true, discussionGoalId: objectId });
	}, [objectId, setLocalState]);

	return (
		<>
			<DropdownItem icon="edit" onClick={openGoalModal}>
				{translate("edit.editElement", { InsertText: "global.goal" })}
			</DropdownItem>
			{discussionFlag && (
				<DropdownItem icon="comment" onClick={openDiscussionBar}>
					{translate("global.discussElement", { element: "global.goal" })}
				</DropdownItem>
			)}
			<DropdownItemCopy
				label={translate("global.goal")}
				object="goal"
				objectId={objectId}
				toast={translate("toaster.messages.goals.goalDuplicated")}
				Options={CopyModalOptions}
				duplicateRouteFlag
			/>
			<DropdownItemTrash
				label={translate("global.goal")}
				object="goal"
				objectId={objectId}
				title={translate("global.delete", { InsertText: "global.goal" })}
				body={translate("button.deleteConfirm", { name })}
				toast={translate("toaster.messages.goals.goalDeleted")}
			/>
		</>
	);
};

EditGoalDropdown.propTypes = propTypes;

export default EditGoalDropdown;
