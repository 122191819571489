import StyleWrapper from "../../../StyleWrapper";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";

let propTypes = {
	name: PropTypes.string,
};

let MultipickDisplay = ({ name }) => {
	let value = useFormValue(name);
	if (!isArray(value)) {
		value = value?.split(",");
	}
	return (
		<StyleWrapper listStylePosition="inside" margin="0 !important">
			<ul>
				{value
					?.filter((x) => !!x)
					.map((x, i) => (
						<li key={i}>
							<span>{x}</span>
						</li>
					))}
			</ul>
		</StyleWrapper>
	);
};

MultipickDisplay.propTypes = propTypes;
export default MultipickDisplay;
