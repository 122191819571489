import Toast from "@clearpoint/old-theme/Toast";
import React, { Suspense } from "react";
import getToast from "./toastService.getToast";
import { toast as toastify } from "react-toastify";

let SuspenseToast = ({ message, icon }) => (
	<Suspense>
		<Toast message={message} icon={icon || "bolt"} />
	</Suspense>
);

const toast = getToast(toastify, SuspenseToast);

export { toast };
