import isArray from "lodash/isArray";

let createFilterLookup = (setFilterQuery) => ({
	activityList: (value) => {
		setFilterQuery({
			activityList: isArray(value) ? value.join(",") : value || "",
		});
	},
	apiKeyList: (value) => {
		setFilterQuery({
			apiKeyList: isArray(value) ? value.join(",") : value || "",
		});
	},
	object: (value) => {
		setFilterQuery({
			object: value || "",
		});
	},
	objectId: (value) => {
		setFilterQuery({
			objectId: value || "",
		});
	},
	objectList: (value) => {
		setFilterQuery({
			objectList: isArray(value) ? value.join(",") : value || "",
		});
	},
	periodList: (value) => {
		setFilterQuery({
			periodList: isArray(value) ? value.join(",") : value || "",
		});
	},
	scorecardList: (value) => {
		setFilterQuery({
			scorecardList: isArray(value) ? value.join(",") : value || "",
		});
	},
	search: (value) => {
		setFilterQuery({
			search: value || "",
		});
	},
	userList: (value) => {
		setFilterQuery({
			userList: isArray(value) ? value.join(",") : value || "",
		});
	},
});
export default createFilterLookup;
