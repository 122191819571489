import AddCustomChartTypeButton from "./AddCustomChartTypeButton";
import Alert from "@clearpoint/old-theme/Alert/Alert";
import FormInputJson from "@clearpoint/old-theme/Form/Input/Json5/InputJson5";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import GetChartJsonButton from "./GetChartJsonButton";
import JSON5 from "json5";
import SelectChartJson from "./SelectChartJson";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback, useEffect, useRef } from "react";
import useChartPreviewPromise from "../../useChartPreviewPromise";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { theme } from "@clearpoint/old-theme/Theme";
import { beautifyJson, emptyObject, isNotValidJson5 } from "@clearpoint/utils";

let propTypes = {
	close: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool.isRequired,
	previousChartType: PropTypes.number,
};

let ChartJsonModal = ({ close, modalVisible, previousChartType }) => {
	let translate = useTranslate();
	let { periodId } = useOldSession()?.session;
	let { formDefaultValue, formValue, newObjectFlag, setFormValue } = useFormContext();

	let { chartSeries: chartSeriesList, customJson } = formValue || emptyObject;
	let validJSONFlag = !isNotValidJson5(customJson) || !customJson;

	let getChartPreviewPromise = useChartPreviewPromise();

	let buildInitialJson = useCallback(() => {
		if (!formDefaultValue) return;

		let { defaultChartType, customJson } = formDefaultValue;
		let { currentChartType } = formValue;

		// chartType is 0 only use it if default chart type is also 0
		let chartType = currentChartType || defaultChartType;

		if (chartType === 0 && !isNotValidJson5(customJson)) {
			customJson = beautifyJson(JSON.stringify(JSON5.parse(customJson)));
		}

		getChartPreviewPromise(formDefaultValue).then((response) => {
			let chartFormatData = cloneDeep(response).chartFormat;
			chartFormatData?.series?.forEach((series) => {
				series.measureSeriesId = undefined;
				series.name = undefined;
			});
			// TODO: determine when to show this array (modifying it often does nothing to the chart)
			// if (!newObjectFlag) {
			// 	delete chartFormatData.colors;
			// }
			setFormValue("customJson", beautifyJson(JSON.stringify(chartFormatData)));
		});
	}, [formDefaultValue, formValue, getChartPreviewPromise, setFormValue]);

	let intitialJsonBuiltFlag = useRef(false);
	useEffect(() => {
		if (!intitialJsonBuiltFlag.current) {
			if (!customJson && periodId) {
				buildInitialJson();
				intitialJsonBuiltFlag.current = true;
			} else if (customJson) {
				// TODO: figure out exactly when to show the colors array and update below code accordingly
				// // Remove colors field from incoming JSON -- it's a red herring that has no bearing on the chart, even in Angular
				// // Users can instead edit chart colors in the Chart Series Tab or the upcoming Chart Colors tab
				// let customJsonParsed = JSON5.parse(customJson);
				// if (customJsonParsed.colors && !newObjectFlag) delete customJsonParsed.colors;
				// setFormValue("customJson", beautifyJson(JSON.stringify(customJsonParsed)));
				setFormValue("customJson", beautifyJson(customJson));
				intitialJsonBuiltFlag.current = true;
			}
		}
	}, [buildInitialJson, customJson, newObjectFlag, periodId, setFormValue]);

	let setChartTypeAndCustomSeries = useCallback(() => {
		if (customJson) {
			setFormValue("chartType", 0);
			let customSeries = JSON5.parse(customJson).series;
			if (customSeries) {
				chartSeriesList?.forEach((series, index) => {
					if (index < customSeries.length) {
						customSeries[index].measureSeriesId = series.measureSeriesId;
					}
				});
			}
			setFormValue("customSeries", customSeries);
		} else {
			setFormValue("chartType", previousChartType || 75);
			setFormValue("customSeries", undefined);
		}
	}, [chartSeriesList, customJson, previousChartType, setFormValue]);

	return (
		<FormModal
			modalVisible={modalVisible}
			closeButtonText={translate("global.save")}
			close={close}
			name="customJson"
			size="large"
			title={translate("edit.chart.editChartJson")}
			footerLeftContent={
				<>
					<GetChartJsonButton buildInitialJson={buildInitialJson} />
					<AddCustomChartTypeButton closeChartJsonModal={close} />
				</>
			}
			onSave={setChartTypeAndCustomSeries}
			valid={validJSONFlag}
		>
			{customJson && (
				<StyleWrapper marginBottom={theme.smallSpace}>
					{isNotValidJson5(customJson) ? (
						<Alert color="danger">{translate("edit.chart.jsonInvalid")}</Alert>
					) : (
						<Alert color="success">{translate("edit.chart.jsonValid")}</Alert>
					)}
				</StyleWrapper>
			)}
			<SelectChartJson />
			<FormInputJson name="customJson" showAlertFlag={false} marginBottom="0" data-testid="chart-json-input" />
		</FormModal>
	);
};

ChartJsonModal.propTypes = propTypes;
export default ChartJsonModal;
