import HTML from "../../../HTML";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	displayValue: PropTypes.string,
	name: PropTypes.string,
};

let HTMLDisplay = ({ displayValue, name }) => {
	name = name.endsWith(".value") ? `${name.slice(0, -6)}.displayValue` : name;
	let formDisplayValue = useFormValue(name);
	displayValue = displayValue || formDisplayValue;
	return <HTML className="htmlarea">{displayValue}</HTML>;
};

HTMLDisplay.propTypes = propTypes;
export default HTMLDisplay;
