import Select from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectBorderPosition = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("options.admin.styles.borders.noBorder"),
				value: "none",
			},
			{
				label: translate("options.admin.styles.borders.allBorders"),
				value: "",
			},
			{
				label: translate("options.admin.styles.borders.topBorder"),
				value: "-top",
			},
			{
				label: translate("options.admin.styles.borders.leftBorder"),
				value: "-left",
			},
			{
				label: translate("options.admin.styles.borders.bottomBorder"),
				value: "-bottom",
			},
			{
				label: translate("options.admin.styles.borders.rightBorder"),
				value: "-right",
			},
		],
		[translate]
	);
	return (
		<Select
			options={options}
			placeholder={translate("options.admin.styles.border.selectBorderPosition")}
			{...props}
		/>
	);
};
export default SelectBorderPosition;
