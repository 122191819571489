import cloneDeep from "lodash/cloneDeep";
import threeDigitHexToSix from "./threeDigitHexToSix";

let beforeChartSubmit = (submitValue, formContext) => {
	let chartData = cloneDeep(submitValue);
	let chartFormValue = formContext?.formValue || {};

	if (chartData.periodGroupId !== 0 && chartFormValue.periodGroupId !== 0) {
		delete chartData.hiddenPeriods;
	}
	if (chartData.chartType === 0 || chartFormValue.chartType === 0) {
		chartData.chartType = 75;
		chartData.chartSeries =
			chartData.customSeries?.filter((series) => !!series.measureSeriesId) ?? chartData.chartSeries;
	} else {
		chartData.customJson = "";
	}

	chartData.chartSeries = chartData.chartSeries?.map((x, index) => ({
		...x,
		color: threeDigitHexToSix(x.color),
		sortOrder: index + 1,
	}));
	chartData.customSeries = undefined;

	return chartData;
};

export default beforeChartSubmit;
