import { getEnvironment } from "@clearpoint/utils";

let configInitialState = {
	dashboardChartCount: null,
	environment: getEnvironment(),
	localeId: "en_us",
	lockedFlag: false,
	scorecardMenuExpandedFlag: null,
	sideNavigationVisible: true,
	view: "list",
};
let createConfigSlice = (set) => ({
	...configInitialState,
	setDashboardChartCount: (dashboardChartCount) =>
		set((state) => {
			// 999 will render all charts on a single page
			let newCount = +dashboardChartCount;
			let oldCount = state.dashboardChartCount;
			let invalidNewCountFlag = isNaN(newCount) || newCount < 1 || newCount > 999;
			if (invalidNewCountFlag) console.warn(`dashboardChartCount is not valid: ${dashboardChartCount}`);
			return { dashboardChartCount: invalidNewCountFlag ? oldCount : newCount };
		}),
	setLocaleId: (localeId) => set(() => ({ localeId })),
	setLockedFlag: (lockedFlag) => set(() => ({ lockedFlag })),
	setScorecardMenuExpandedFlag: (scorecardMenuExpandedFlag) => set(() => ({ scorecardMenuExpandedFlag })),
	toggleView: () =>
		set((state) => {
			console.log(state.view === "grid" ? "list" : "grid");
			return { view: state.view === "grid" ? "list" : "grid" };
		}),
	toggleSideNavigationVisible: () => set((state) => ({ sideNavigationVisible: !state.sideNavigationVisible })),
});
export { configInitialState, createConfigSlice };
