import { useObjectiveDetailQuery, useObjectiveListQuery } from "@clearpoint/query";
import { useMainStore } from "@clearpoint/store";

let Detail = () => {
	let periodId = useMainStore((state) => state.periodId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { data: objectiveList } = useObjectiveListQuery({ periodId, scorecardId });
	let objectId = objectiveList[0]?.objectId;
	let { data: objectiveDetail } = useObjectiveDetailQuery({ objectId });
	return (
		<div>
			<div>
				<strong>{objectiveDetail.name}</strong>
			</div>
			<div>{JSON.stringify(objectiveDetail, 0, 3)}</div>
		</div>
	);
};

export default Detail;
