import SelectLayout from "./Select.Layout";
import { useTranslate } from "@clearpoint/translate";

let SelectInitiativeLayout = (props) => {
	let translate = useTranslate();
	return (
		<SelectLayout label={`${translate("initiative")} ${translate("global.report")}`} object="initiative" {...props} />
	);
};

export default SelectInitiativeLayout;
