import { useObjectiveListQuery } from "@clearpoint/query";
import { useMainStore } from "@clearpoint/store";

let List = () => {
	let periodId = useMainStore((state) => state.periodId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { data: objectiveList } = useObjectiveListQuery({ periodId, scorecardId });
	return (
		<>
			<div>
				<strong>Objectives</strong>
			</div>
			<div>
				{objectiveList?.map((x) => (
					<div key={x.objectId}>
						{x.name} <em>{x.active ? "active" : "inactive"}</em>
					</div>
				))}
			</div>
		</>
	);
};

export default List;
