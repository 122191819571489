import Select from "../../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	filterObjectList: PropTypes.array,
};

let SelectObject = ({ filterObjectList, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() =>
			filterObjectList?.map((x) => ({
				label: translate(x),
				value: x,
			})) || [],
		[filterObjectList, translate]
	);
	return <Select options={options} {...props} />;
};
SelectObject.propTypes = propTypes;

export default SelectObject;
