let fetchData = ({ dataKey, filterQuery, get, getPromise, itemsPerPage, setFilterCache, startIndex }) => {
	let queryString = `?start=${startIndex}&count=${itemsPerPage}`;
	for (let [key, value] of Object.entries(filterQuery)) {
		if (value !== undefined) {
			queryString += `&${key}=${value}`;
		}
	}
	let call = {
		object: dataKey,
		queryString,
	};
	setFilterCache({
		storeCall: call,
	});
	let fetchedData = get(call);
	let fetchPromise = getPromise(call);
	return { fetchedData, fetchPromise };
};
export default fetchData;
