import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let nameKeyLookup = {
	any: "notifications.defaultAny",
	edited: "notifications.defaultEdit",
	updated: "notifications.defaultUpdate",
};
let useCreateDefaultNotification = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { object, objectId, name } = useFormValue();
	let profile = get({ object: "profile" });
	return useCallback(
		(action) => ({
			action,
			criteria: [
				{
					action,
					compare: "any",
					fieldId: "any",
					value: "any",
				},
			],
			isTemplate: false,
			links: [
				{
					name,
					object,
					objectId,
					users: [
						{
							fullName: profile?.fullName,
							userId: profile?.userId,
						},
					],
				},
			],
			name: translate(nameKeyLookup[action]),
			object,
		}),
		[name, object, objectId, profile, translate]
	);
};
export default useCreateDefaultNotification;
