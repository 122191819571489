import { useCallback } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useUnmount } from "react-use";
import cloneDeep from "lodash/cloneDeep";

let useMapCheck = (checkId) => {
	let { checklist, setChecklist } = useChecklist();
	let checked = checklist?.find((x) => x.id === checkId)?.checked;
	let toggleChecked = useCallback(
		() =>
			setChecklist((checklist) => {
				checklist = cloneDeep(checklist);
				let check = checklist.find((x) => x.id === checkId);
				if (check) {
					check.checked = !check.checked;
				} else {
					checklist.push({ id: checkId, checked: true });
				}
				return checklist;
			}),
		[checkId, setChecklist]
	);
	useUnmount(() => {
		setChecklist((checklist) => checklist.filter((x) => x.id !== checkId));
	});
	return { checked, toggleChecked };
};

export default useMapCheck;
