import Block from "../Block";
import Button from "../Button/Button";
import DiscardButton from "../Button/Button.Discard";
import FooterContainer from "./FooterContainer";
import FormButton from "../Form/Button/Button";
import SaveAndContinueButton from "../Dropdown/SplitButton/SplitButton.SaveAndContinue";
import StyleWrapper from "../StyleWrapper";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "./ModalWindow";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let defaultSubmitButtonPropTypes = {
	doNotSubmitFlag: PropTypes.bool,
	submitButtonOnClick: PropTypes.func,
	disabled: PropTypes.bool,
	submitText: PropTypes.string,
	translate: PropTypes.func,
};
let DefaultSubmitButton = ({ doNotSubmitFlag, submitButtonOnClick, disabled, submitText, translate }) => {
	return (
		<FormButton
			data-testid={`${submitText || "save"}-button`}
			type={doNotSubmitFlag ? "button" : "submit"}
			color="primary"
			onClick={submitButtonOnClick}
			disabled={disabled}
		>
			{submitText || translate("global.save")}
		</FormButton>
	);
};
DefaultSubmitButton.propTypes = defaultSubmitButtonPropTypes;

let propTypes = {
	disabled: PropTypes.bool,
	doNotSubmitFlag: PropTypes.bool,
	left: PropTypes.node,
	noSaveFlag: PropTypes.bool,
	right: PropTypes.node,
	saveAndContinueFlag: PropTypes.bool,
	SubmitButton: PropTypes.func,
	submitButtonOnClick: PropTypes.func,
	submitText: PropTypes.string,
};

let ModalWindowFooter = ({
	disabled,
	doNotSubmitFlag,
	left,
	noSaveFlag,
	right,
	saveAndContinueFlag,
	SubmitButton,
	submitButtonOnClick,
	submitText,
}) => {
	let translate = useTranslate();
	let { close, noFormFlag, showDiscardModalOnFormDirtyFlag } = useWindowContext();
	SubmitButton = SubmitButton || (saveAndContinueFlag ? SaveAndContinueButton : DefaultSubmitButton);

	return (
		<FooterContainer>
			{left || <div />}
			<Block display="flex">
				{right ||
					(noFormFlag || noSaveFlag ? (
						<Button onClick={close} color="primary">
							{translate("global.close")}
						</Button>
					) : (
						<>
							<StyleWrapper marginRight={theme.smallSpace}>
								<DiscardButton
									onDiscard={close}
									showDiscardModalOnFormDirtyFlag={showDiscardModalOnFormDirtyFlag}
								/>
							</StyleWrapper>
							<SubmitButton
								doNotSubmitFlag={doNotSubmitFlag}
								submitButtonOnClick={submitButtonOnClick}
								disabled={disabled}
								submitText={submitText}
								translate={translate}
							/>
						</>
					))}
			</Block>
		</FooterContainer>
	);
};

ModalWindowFooter.propTypes = propTypes;

export default ModalWindowFooter;