import { Container } from "react-smooth-dnd";
import { useCallback } from "react";
import generateOnDrop from "./DragAndDropTable.generateOnDrop";
import PropTypes from "prop-types";
import styled from "styled-components";

let StyledDiv = styled.div`
	flex: 1;
	& .smooth-dnd-container {
		min-width: 100%;
		border: ${(props) => props.$border};
		table-layout: fixed;
	}
	& .drop-preview {
		background: #efefef;
		border: 1px dashed #cecece;
		height: 100%;
	}
`;

let propTypes = {
	children: PropTypes.node,
	list: PropTypes.array,
	setList: PropTypes.func.isRequired,
};

let getBody = () => document.body;

let DragAndDropTable = ({ border, children, dragHandleSelector, list, setList, size }) => {
	let onDrop = useCallback(
		(dropProps) => {
			let onDropCallback = generateOnDrop(list, setList);
			return onDropCallback(dropProps);
		},
		[list, setList]
	);
	return (
		<StyledDiv $border={border} size={size}>
			<Container
				dragHandleSelector={dragHandleSelector}
				dropPlaceholder={{
					animationDuration: 150,
					showOnTop: true,
					className: "drop-preview",
				}}
				getGhostParent={getBody}
				onDrop={onDrop}
				orientation="horizontal"
			>
				{children}
			</Container>
		</StyledDiv>
	);
};

DragAndDropTable.propTypes = propTypes;

export default DragAndDropTable;
