const LOADING_TIMEOUT = 350;

let generatePauseLoadingFunction = ({
	cancelResumeLoadingRef,
	pauseLoadingFlagRef,
	pauseLoadingTimeoutRef,
	requests,
	resumeLoading,
}) => {
	clearTimeout(pauseLoadingTimeoutRef.current);
	pauseLoadingFlagRef.current = true;
	cancelResumeLoadingRef.current = true;
	if (requests.current.length > 0) {
		cancelResumeLoadingRef.current = false;
		resumeLoading();
	} else {
		pauseLoadingTimeoutRef.current = setTimeout(() => generatePauseLoadingFunction, LOADING_TIMEOUT);
	}
};

export default generatePauseLoadingFunction;
