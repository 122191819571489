import { useEffect, useRef } from "react";
import getDeepValue from "lodash/get";
import PropTypes from "prop-types";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";

import { useFormatDate } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { getCurrentDate } from "@clearpoint/utils";

import useFormValue from "../../../Form/hooks/useFormValue";

let propTypes = {
	name: PropTypes.string,
	parentName: PropTypes.string,
};

let FormFieldDisplayCompletedDate = ({ name, parentName }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { setFormValue, formDefaultValue } = useFormContext();
	let completedFlag = useFormValue(parentName ? `${parentName}.completed` : "completed");
	let defaultCompletedDate = getDeepValue(formDefaultValue, name);
	let defaultCompletedFlag = getDeepValue(formDefaultValue, parentName ? `${parentName}.completed` : "completed");
	let completedDateRef = useRef();

	useEffect(() => {
		if (defaultCompletedFlag === false) {
			completedDateRef.current = getCurrentDate();
			setFormValue(name, formatDate(completedDateRef.current, "apiDate"));
		} else {
			completedDateRef.current = defaultCompletedDate;
		}
	}, [defaultCompletedDate, completedFlag, setFormValue, name, defaultCompletedFlag, formatDate]);

	return completedFlag ? (
		<span>{formatDate(completedDateRef.current, "short")}</span>
	) : (
		translate("displayDetail.fields.boolean.notCompleted")
	);
};

FormFieldDisplayCompletedDate.propTypes = propTypes;
export default FormFieldDisplayCompletedDate;