import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	email: PropTypes.string,
	fullName: PropTypes.string,
	organization: PropTypes.string,
	phoneNumber: PropTypes.string,
};

let Contact = ({ email, fullName, organization, phoneNumber }) => {
	let translate = useTranslate();

	return (
		<FormGroup
			title={translate("measureLibrary.addEditLibraryMeasure.contactName")}
			marginTop="0"
			marginBottom={theme.smallSpace}
			paddingBottom={theme.space}
		>
			<strong>{organization}</strong>
			<br />
			<span>{fullName}</span>
			<br />
			<span>{email}</span>
			<br />
			<span>{phoneNumber}</span>
		</FormGroup>
	);
};

Contact.propTypes = propTypes;

export default Contact;
