import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";
import { emptyArray } from "@clearpoint/utils";


let propTypes = {
	object: PropTypes.string.isRequired,
	scorecardId: PropTypes.number.isRequired,
};
let SelectLayout = ({ scorecardId, object, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let menu = scorecardId && get({ object: "menu", scorecardId });
	let options = useMemo(() => {
		if (!isArray(menu)) return emptyArray;
		return menu
			?.find((objectData) => objectData.object === object)
			?.layouts?.map((layout) => ({
				label: translate(layout.name),
				value: layout.layoutId,
			}));
	}, [menu, object, translate]);
	return <FormSelect options={options} placeholder={translate("options.selectReport") + "..."} {...props} />;
};
SelectLayout.propTypes = propTypes;
export default SelectLayout;
