import { useCallback, useRef } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import debounce from "lodash/debounce";
import saveGridWidth from "./useSaveGridWidth.saveGridWidth";
import { emptyObject } from "@clearpoint/utils";
import { useCheckAccess } from "@clearpoint/hooks";

let useSaveGridWidth = () => {
	let { get, set } = useOldQueryStore();
	let checkAccess = useCheckAccess();
	let { layoutId, object } = useOldSession().session;

	let layout = layoutId ? get({ object: `${object}Layout`, objectId: layoutId }) : undefined;
	let { access, reportOptions, scorecardId } = layout || emptyObject;
	let adminAccessFlag = checkAccess({ access, action: "admin", scorecardId });

	let saveGridWidthRef = useRef(
		debounce((adminAccessFlag, gridWidth, layout, layoutId, object, reportOptions, set) => {
			saveGridWidth({ adminAccessFlag, gridWidth, layout, layoutId, object, reportOptions, set });
		}, 500)
	);

	return useCallback(
		(gridWidth) => {
			saveGridWidthRef.current(adminAccessFlag, gridWidth, layout, layoutId, object, reportOptions, set);
		},
		[adminAccessFlag, layout, layoutId, object, reportOptions, set]
	);
};

export default useSaveGridWidth;
