import Button from "@clearpoint/old-theme/Button/Button";
import ModalConfirmOnClick from "@clearpoint/old-theme/Modal/Modal.Confirm.OnClick";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let wrapperPropTypes = {
	buildInitialJson: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

let ConfirmModalWrapper = ({ buildInitialJson, children }) => {
	let translate = useTranslate();
	let customJson = useFormValue("customJson");
	return !!customJson ? (
		<ModalConfirmOnClick
			title={translate("edit.chart.getChartJson")}
			body={translate("edit.chart.overwriteDefault")}
			confirmButtonText={translate("global.ok")}
			confirmButtonColor="warning"
			onConfirm={buildInitialJson}
		>
			{children}
		</ModalConfirmOnClick>
	) : (
		<>{children}</>
	);
};

let buttonPropTypes = {
	buildInitialJson: PropTypes.func.isRequired,
};

let GetChartJsonButton = ({ buildInitialJson }) => {
	let translate = useTranslate();
	return (
		<ConfirmModalWrapper buildInitialJson={buildInitialJson}>
			<Button color="success" outlineFlag onClick={buildInitialJson}>
				{translate("edit.chart.getChartJson")}
			</Button>
		</ConfirmModalWrapper>
	);
};

ConfirmModalWrapper.propTypes = wrapperPropTypes;
GetChartJsonButton.propTypes = buttonPropTypes;
export default GetChartJsonButton;
