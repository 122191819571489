import Block from "../../../Block";
import Button from "../../../Button/Button";
import ButtonEdit from "../../../Button/Button.Edit";
import ButtonTrash from "../../../Button/Button.Trash";
import EditFilterModal from "./EditFilterModal/EditFilterModal";
import FormGroup from "../../../Form/Form.Group";
import Icon from "../../../Icon/Icon";
import ListItem from "../../../ListItem/ListItem";
import ModalOnClick from "../../../Modal/Modal.OnClick";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";
import StyleWrapper from "../../../StyleWrapper";
import { emptyArray } from "@clearpoint/utils";
import { useStateObject } from "@clearpoint/hooks";

let propTypes = {
	filterObjectList: PropTypes.array,
	name: PropTypes.string.isRequired,
};

let FilterList = ({ name, filterObjectList }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let filterList = useFormValue(name) || emptyArray;
	filterList = useMemo(
		() => filterList.filter((x) => !(x?.object === "measureSeries" && x?.fieldId === "parent")),
		[filterList]
	);
	let onTrash = useCallback(
		(index) => {
			setFormValue(
				name,
				filterList.filter((x, i) => i !== index)
			);
		},
		[filterList, name, setFormValue]
	);
	let [{ addIndex }, setState] = useStateObject({
		addIndex: filterList.length,
	});
	let setAddIndex = useCallback(() => setState({ addIndex: (i) => i + 1 }), [setState]);
	return (
		<FormGroup title={translate("layout.filters")} paddingBottom={theme.smallSpace}>
			{filterList.map((filter, i) =>
				!filter ? null : (
					<ListItem
						key={i + filter.description}
						size="small"
						left={
							<Block ellipsisFlag display="flex" alignItems="center">
								<Icon name={filter.object} />
								<Block
									ellipsisFlag
									marginLeft={theme.smallSpace}
									data-testid="filter-description"
									fontWeight="normal"
								>
									{filter.description}
								</Block>
							</Block>
						}
						right={
							<>
								<ButtonEdit
									Modal={EditFilterModal}
									name={`${name}[${i}]`}
									filterObjectList={filterObjectList}
								/>
								<ButtonTrash
									title={translate("layout.deleteReportFilter")}
									body={translate("layout.deleteReportFilterText")}
									onTrash={() => onTrash(i)}
								/>
							</>
						}
					/>
				)
			)}
			<Block textAlign="right" marginTop={theme.space}>
				<ModalOnClick
					Modal={EditFilterModal}
					name={`${name}[${addIndex}]`}
					onSave={setAddIndex}
					filterObjectList={filterObjectList}
				>
					<StyleWrapper fontSize="12px">
						<Button data-testid={"add-filter-button"} color="primary" size="small">
							<Icon name="add" marginRight={theme.smallSpace} />
							{translate("layout.addFilter")}
						</Button>
					</StyleWrapper>
				</ModalOnClick>
			</Block>
		</FormGroup>
	);
};

FilterList.propTypes = propTypes;

export default FilterList;
