import { useMemo } from "react";

import Block from "@clearpoint/old-theme/Block";
import { theme } from "@clearpoint/old-theme/Theme";

import Page from "./Page";
import useShouldRenderPod from "./useShouldRenderPod";
import { useOldSession } from "@clearpoint/old-session/index";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let PageList = () => {
	let { exportFlag } = useOldSession().session;
	let shouldRenderPod = useShouldRenderPod();
	let { layout } = useDetailPageData();
	let areaList = layout?.layout;

	let pageList = useMemo(
		() => [
			...new Set(
				areaList?.filter((x) => x.pods.some((x) => shouldRenderPod(x))).map((x) => x.page) || []
			),
		],
		[areaList, shouldRenderPod]
	);

	return pageList?.length === 0 ? null : (
		<Block
			marginTop={exportFlag ? `-${theme.mediumLargeSpace}` : ""}
			marginBottom={exportFlag ? "" : theme.mediumSpace}
		>
			{pageList.map((pageNumber) => (
				<Page areaList={areaList.filter((x) => x.page === pageNumber)} key={pageNumber} pageNumber={pageNumber} />
			))}
		</Block>
	);
};

export default PageList;
