import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let SelectCustomField = (props) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let customFieldListByObject = get({ object: "customField" });
	let selectOptions = useMemo(
		() =>
			customFieldListByObject?.reduce(
				(customFieldList, customFieldObject) => [
					...customFieldList,
					...customFieldObject.fields.map((x) => ({
						value: x.customFieldId,
						label: x.name,
						group: translate(customFieldObject.object),
					})),
				],
				[]
			),
		[customFieldListByObject, translate]
	);
	return (
		<Select
			options={selectOptions || []}
			placeholder={translate("edit.selectElement", { element: "global.customField" })}
			{...props}
		/>
	);
};

export default SelectCustomField;
