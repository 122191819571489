import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let SettingsButton = () => {
	let { setLocalState } = useLocalState();
	let openOptionsModal = useCallback(() => {
		setLocalState({
			mapOptionsModalVisible: true,
		});
	}, [setLocalState]);
	return <IconButton color="primary" name="settings" onClick={openOptionsModal} />;
};

export default SettingsButton;
