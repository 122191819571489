import AIAssistantModal from "@components/Layouts/ViewLayout/ViewDetail/Detail/AIAssistants/AIAssistantModal";
import Block from "@clearpoint/old-theme/Block";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import PropTypes from "prop-types";

let propTypes = {
	fieldId: PropTypes.string,
	parentName: PropTypes.string,
	periodLockedFlag: PropTypes.bool,
	right: PropTypes.string,
};

let defaultProps = {
	size: "tiny",
};

let AIAssistantButton = ({ fieldId, parentName, periodLockedFlag, right, ...props }) => (
	<Block position="relative" height="0" width="100%">
		<ModalOnClick
			Modal={AIAssistantModal}
			fieldId={fieldId}
			fieldNameInForm={`${parentName}.${fieldId}`}
			periodLockedFlag={periodLockedFlag}
		>
			<StyleWrapper position="absolute" top="-4px" right={right || "0px"}>
				<IconButton name="ai" {...props} />
			</StyleWrapper>
		</ModalOnClick>
	</Block>
);

AIAssistantButton.propTypes = propTypes;
AIAssistantButton.defaultProps = defaultProps;

export default AIAssistantButton;
