import EditDefaultLayoutModal from "./EditDefaultLayoutModal";
import EditDetailLayoutModal from "@components/Layouts/Shared/EditDetailLayoutModal";
import EditGridLayoutModal from "./EditGridLayoutModal";
import EditReferenceLayoutModal from "./EditReferenceLayoutModal";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	layoutId: PropTypes.number.isRequired,
	object: PropTypes.string.isRequired,
	scorecardId: PropTypes.number,
};
let EditLayoutModal = ({ layoutId, object, scorecardId, ...props }) => {
	let { get } = useOldQueryStore();
	let layoutList = scorecardId && get({ object: `${object}Layout`, scorecardId });
	let layout;
	if (layoutList) layout = layoutList.find((x) => x.layoutId === layoutId);
	else layout = get({ object: `${object}Layout`, objectId: layoutId });
	let { defaultLayout: defaultLayoutFlag, referenceLayoutId, reportType } = layout || emptyObject;
	let EditModal = referenceLayoutId
		? EditReferenceLayoutModal
		: reportType === "detail"
		? EditDetailLayoutModal
		: defaultLayoutFlag
		? EditDefaultLayoutModal
		: EditGridLayoutModal;
	return layout ? <EditModal object={object} layoutId={layoutId} {...props} /> : null;
};
EditLayoutModal.propTypes = propTypes;

export default EditLayoutModal;
