import BootstrapForm from "react-bootstrap/Form";
import FormError from "../../Form/Form.Error";
import Input from "../../Input/Input";
import InputController from "../../Form/InputController";
import StyleWrapper from "../../StyleWrapper";
import React, { Suspense, useCallback, useMemo } from "react";
import useFormError from "../../Form/hooks/useFormError";
import useFormTouched from "../../Form/hooks/useFormTouched";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../Theme";
import InputTextEditor from "../../Input/TextEditor/TextEditor";

let InputDate = React.lazy(() => import("@clearpoint/old-theme/Input/Input.Date"));
let InputDateTime = React.lazy(() => import("@clearpoint/old-theme/Input/Input.DateTime"));
let InputTimeWrapper = React.lazy(() => import("@clearpoint/old-theme/Form/Input/Input.TimeWrapper"));
let TypeAhead = React.lazy(() => import("@clearpoint/old-theme/TypeAhead"));

let StyledFormGroup = styled(BootstrapForm.Group)`
	display: ${(props) => (props.$flexGrowFlag ? "flex" : props.$display)};
	flex: ${(props) => (props.$flexGrowFlag ? "1" : undefined)};
	flex-direction: ${(props) => (props.$flexGrowFlag ? "column" : undefined)};
	margin-bottom: ${(props) => props.$marginBottom};
`;
let propTypes = {
	disabled: PropTypes.bool,
	cellFlag: PropTypes.bool,
	dateFlag: PropTypes.bool,
	dateTimeFlag: PropTypes.bool,
	description: PropTypes.string,
	errorAfterTouchedFlag: PropTypes.bool,
	errorMessage: PropTypes.node,
	errorNotVisible: PropTypes.bool,
	elementValueTransform: PropTypes.func,
	flexGrowFlag: PropTypes.bool,
	formValueTransform: PropTypes.func,
	helpText: PropTypes.string,
	marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	message: PropTypes.node,
	name: PropTypes.string.isRequired,
	options: PropTypes.array,
	persistInSession: PropTypes.string,
	secureHtml: PropTypes.bool,
	textEditorFlag: PropTypes.bool,
	timeFlag: PropTypes.bool,
	width: PropTypes.string,
	uniqueEmail: PropTypes.string,
	uniqueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	url: PropTypes.string,
};

let defaultProps = {
	type: "text",
};
let FormInput = ({
	disabled,
	cellFlag,
	dateFlag,
	dateTimeFlag,
	description,
	errorAfterTouchedFlag,
	errorMessage,
	errorNotVisible,
	errorTextNotVisible,
	elementValueTransform,
	flexGrowFlag,
	formValueTransform,
	marginBottom,
	message,
	name,
	options,
	persistInSession,
	secureHtml,
	textEditorFlag,
	timeFlag,
	uniqueEmail,
	uniqueDate,
	url,
	width,
	...props
}) => {
	let touched = useFormTouched(name);
	let error = useFormError(name);
	let InputComponent;
	if (dateFlag) {
		InputComponent = InputDate;
	} else if (dateTimeFlag) {
		InputComponent = InputDateTime;
	} else if (timeFlag) {
		InputComponent = InputTimeWrapper;
	} else if (textEditorFlag) {
		InputComponent = InputTextEditor;
	} else if (options) {
		InputComponent = TypeAhead;
	} else {
		InputComponent = Input;
	}

	let defaultElementValueTransform = useCallback((value) => (value === undefined || value === null ? "" : value), []);
	elementValueTransform = elementValueTransform || defaultElementValueTransform;
	let asCopy = props.as;
	delete props.as;
	let errorVisible = !errorNotVisible && error && (!errorAfterTouchedFlag || touched);
	return useMemo(
		() => (
			<StyledFormGroup
				$flexGrowFlag={flexGrowFlag}
				$marginBottom={marginBottom}
				$display={width ? "inline-block" : undefined}
				controlId={name}
			>
				<Suspense fallback={<input disabled />}>
					<StyleWrapper
						borderColor={errorVisible ? theme.danger : undefined}
						$style={`
						border-radius: 4px;
						&:focus {
						border-color: ${errorVisible ? theme.danger : undefined};
						border-right-color: ${props.right ? `${theme.gray} !important` : undefined};
					}`}
					>
						<InputController
							{...props}
							as={undefined}
							asCopy={asCopy}
							elementValueTransform={props.elementValueTransform || elementValueTransform}
							errorVisible={errorVisible}
							flexGrowFlag={flexGrowFlag}
							formValueTransform={props.formValueTransform || formValueTransform}
							name={name}
							options={options}
							persistInSession={persistInSession}
							secureHtml={secureHtml}
							timeFlag={timeFlag}
							uniqueDate={uniqueDate}
							uniqueEmail={uniqueEmail}
							url={url}
							width={width}
						>
							<InputComponent cellFlag={cellFlag} disabled={disabled} />
						</InputController>
					</StyleWrapper>
				</Suspense>
				{description && <BootstrapForm.Text className="text-muted">{description}</BootstrapForm.Text>}
				{errorVisible && !errorTextNotVisible && (
					<FormError
						name={name}
						errorMessage={errorMessage}
						margin={
							textEditorFlag && !props.noFormGroupFlag ? `-${theme.tinySpace} 0 ${theme.smallSpace}` : undefined
						}
					/>
				)}
				{message}
			</StyledFormGroup>
		),
		[
			InputComponent,
			asCopy,
			cellFlag,
			description,
			elementValueTransform,
			errorMessage,
			errorTextNotVisible,
			errorVisible,
			flexGrowFlag,
			formValueTransform,
			marginBottom,
			message,
			name,
			options,
			persistInSession,
			props,
			secureHtml,
			textEditorFlag,
			timeFlag,
			uniqueDate,
			uniqueEmail,
			url,
			width,
		]
	);
};

FormInput.propTypes = propTypes;
FormInput.defaultProps = defaultProps;

export default FormInput;
