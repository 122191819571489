// these constants should be used in query keys for consistency
export const CUSTOM_FIELD = "customField";
export const ELEMENT = "element";
export const EVALUATION = "evaluation";
export const LINK = "link";
export const MEASURE_GRID = "measureGrid";
export const MENU = "menu";
export const MY_FAVORITE = "myFavorite";
export const MY_SCORECARD = "myScorecard";
export const MY_UPDATES = "myUpdates";
export const OBJECTIVE = "objective";
export const OPTIONS = "options";
export const PEER_GROUP = "peerGroup";
export const PREVIEW = "preview";
export const RECENT = "recent";
export const REPORT = "report";
export const SEARCH = "search";
export const USER = "user";
export const WORKFLOW = "workflow";
