import FormSelect from "../../Form/Select/FormSelect";
import { useEffect, useMemo } from "react";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useFormContext } from "../../Form/Form";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	measureId: PropTypes.number,
	name: PropTypes.string,
	object: PropTypes.string,
};

let SelectEvaluationOption = ({ defaultValue, measureId, name, object, ...props }) => {
	let translate = useTranslate();
	let { get, getPromise } = useOldQueryStore();
	let { setFormDefaultValue } = useFormContext();
	let { data: seriesList } = useFilter();
	let measure = measureId && get({ object: "measure", objectId: measureId });
	let options = useMemo(() => {
		if (!seriesList) return;
		let evaluations = [
			{ value: 0, label: translate("notify.manualEvaluation") },
			{ value: -1, label: translate("notify.bestSeries") },
			{ value: -2, label: translate("notify.worstSeries") },
		];
		seriesList.forEach(({ measureSeriesId, name, active, evaluated }) => {
			if (active && evaluated) {
				evaluations.push({
					value: measureSeriesId,
					label: translate("notify.useSeriesEvaluation", { seriesName: name }),
				});
			}
		});
		return [...evaluations];
	}, [seriesList, translate]);

	useEffect(() => {
		if (measureId) {
			getPromise({ object: "measure", objectId: measureId }).then((measure) => {
				if (measure) setFormDefaultValue(name, defaultValue ?? measure.evaluationOption);
			});
		}
	}, [defaultValue, getPromise, measureId, name, setFormDefaultValue]);

	return measure ? (
		<FormSelect defaultValue={defaultValue} name={name} options={options} {...props} />
	) : (
		<FormSelect name={name} options={options} {...props} />
	);
};

SelectEvaluationOption.propTypes = propTypes;

export default SelectEvaluationOption;
