import StyleWrapper from "../../../StyleWrapper";
import TagList from "../../../Tag/Tag.List";
import useFormValue from "../../../Form/hooks/useFormValue";
import { theme } from "../../../Theme";

let FormFieldDisplayTags = ({ name }) => {
	let value = useFormValue(name);
	return value ? (
		<StyleWrapper marginRight={theme.smallSpace} fontSize="13px !important">
			<TagList tags={value} />
		</StyleWrapper>
	) : null;
};

export default FormFieldDisplayTags;
