import cloneDeep from "lodash/cloneDeep";

let removeSSOParameters = (pageData) => {
	pageData = cloneDeep(pageData);
	delete pageData.token;
	delete pageData.sessionId;
	return pageData;
};

export default removeSSOParameters;
