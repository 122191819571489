import { formatSeriesNumber } from "../utils";

let describePie = ({ chartData, series }) => {
	let measureSeriesList = chartData.chartFormat.series;
	let description = "";
	for (let period of series) {
		let { data } = period;
		let totalValue = data.reduce((sum, dataPoint) => sum + dataPoint.y, 0);
		let i = 0;
		for (let measureSeries of measureSeriesList) {
			let { numberFormat } = measureSeries;
			let { name, y: value } = data[i];
			description += `\n${name}: ${(Math.round((value / totalValue) * 1000) / 10).toFixed(1)}% (${formatSeriesNumber(
				value,
				numberFormat
			)})`;
			i++;
		}
	}
	return description;
};
export default describePie;
