let getMovableFlag = ({ selectedTaskInForm, ganttData }) => {
	if (selectedTaskInForm?.hasOwnProperty("movableFlag")) {
		return selectedTaskInForm.movableFlag;
	}
	let correspondingElementInGanttData =
		selectedTaskInForm &&
		ganttData?.tasks?.find(
			(x) => x.object === selectedTaskInForm.object && x.objectId === selectedTaskInForm.objectId
		);
	return correspondingElementInGanttData?.movableFlag;
};

export default getMovableFlag;
