import { useCallback } from "react";
import { useCheckFeature, useEvaluationEnabledLookup } from "@clearpoint/hooks";

let checkPodLicensed = (pod, flags) => {
	if (!pod?.fieldId) return false;
	let { calculatedFieldsEnabledFlag, evaluationEnabledFlag, tagsEnabledFlag } = flags;
	if (pod.fieldId.startsWith("calculatedField")) {
		return calculatedFieldsEnabledFlag && evaluationEnabledFlag;
	}
	if (pod.fieldId === "tags") {
		return tagsEnabledFlag;
	}
	return true;
};

let useCheckPodLicensed = (object) => {
	let checkFeature = useCheckFeature();

	let calculatedFieldsEnabledFlag = checkFeature("calculatedFields");
	let tagsEnabledFlag = checkFeature("tags");
	let evaluationEnabledFlag = useEvaluationEnabledLookup()?.[object];

	return useCallback(
		(pod) => checkPodLicensed(pod, { calculatedFieldsEnabledFlag, evaluationEnabledFlag, tagsEnabledFlag }),
		[calculatedFieldsEnabledFlag, evaluationEnabledFlag, tagsEnabledFlag]
	);
};

export { checkPodLicensed };
export default useCheckPodLicensed;
