import CustomSeriesTab from "./Custom";
import CustomTransposedTab from "./CustomTransposed";
import DefaultSeriesTab from "./Default";
import Loading from "@clearpoint/old-theme/Loading/index";
import TransposedSeriesTab from "./Transposed";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import PropTypes from "prop-types";

let propTypes = {
	chartFormatData: PropTypes.shape({
		chart: PropTypes.shape({
			polar: PropTypes.bool,
		}),
	}),
	gaugeFlag: PropTypes.bool,
};

let ChartSeriesTab = ({ chartFormatData, gaugeFlag }) => {
	let {
		chartSeries: chartSeriesList,
		colors: colorList,
		customSeries,
		isTransposed: isTransposedFlag,
	} = useFormValue();

	let TabComponent = useMemo(() => {
		let transposedSeriesFlag = isTransposedFlag || chartFormatData?.chart?.polar;
		let customSeriesFlag = !!customSeries;

		if (transposedSeriesFlag) {
			if (customSeriesFlag) {
				return CustomTransposedTab;
			} else {
				return TransposedSeriesTab;
			}
		} else if (customSeriesFlag) {
			return CustomSeriesTab;
		} else {
			return DefaultSeriesTab;
		}
	}, [chartFormatData, customSeries, isTransposedFlag]);

	let loadingFlag = !colorList || !chartSeriesList;

	return loadingFlag ? <Loading /> : <TabComponent gaugeFlag={gaugeFlag} chartFormatData={chartFormatData} />;
};

ChartSeriesTab.propTypes = propTypes;
export default ChartSeriesTab;
