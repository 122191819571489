import { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import SelectScorecard from "../../Form/Select/Select.Scorecard";
import ModalConfirm from "../../Modal/Modal.Confirm";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	editObjectFlag: PropTypes.bool,
	editFlag: PropTypes.bool,
	name: PropTypes.string,
};

let ScorecardId = ({ editObjectFlag, editFlag, name, ...props }) => {
	let translate = useTranslate();
	let { session } = useOldSession();
	let { scorecardId } = session;
	let [modalVisible, setModalVisible] = useState(false);

	let { setFormValue } = useFormContext();
	let value = useFormValue(name);
	let previousValueRef = useRef();

	let openScorecardConfirmModal = useCallback(() => {
		setModalVisible(true);
		previousValueRef.current = value;
	}, [value]);

	let closeScorecardConfirmModal = useCallback(() => {
		setModalVisible(false);
	}, []);

	let onCancelScorecardChange = useCallback(() => {
		setFormValue(name, previousValueRef.current);
	}, [name, setFormValue]);

	return (
		<>
			<SelectScorecard
				disableLockedFlag
				data-testid="scorecardId"
				name={name}
				onChange={editObjectFlag || editFlag ? openScorecardConfirmModal : null}
				defaultValue={scorecardId}
				{...props}
			/>
			{modalVisible && (
				<ModalConfirm
					modalVisible={modalVisible}
					close={closeScorecardConfirmModal}
					onConfirm={closeScorecardConfirmModal}
					onCancel={onCancelScorecardChange}
					title={translate("edit.element.changeHomeScorecard")}
					body={translate("edit.element.changeHomeScorecardText")}
					confirmButtonText={translate("global.ok")}
				/>
			)}
		</>
	);
};

ScorecardId.propTypes = propTypes;
export default ScorecardId;
