import CellWrapper from "../CellWrapper";
import Discussion from "@clearpoint/old-theme/OldDiscussion/Discussion";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func,
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let DiscussionCell = (props) => {
	let { cell, close, editCellFlag } = props;
	let { object, objectId } = cell;
	return (
		<CellWrapper {...props}>
			<Discussion editFlag={editCellFlag} object={object} objectId={objectId} close={close} />
		</CellWrapper>
	);
};

DiscussionCell.propTypes = propTypes;
export default DiscussionCell;
