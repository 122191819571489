let toggleFavoriteThen = ({ favoriteFlagState, translate, clear, layoutId, object, objectId, toast }) => {
	if (favoriteFlagState) {
		toast.success(translate("toaster.messages.favorites.favoriteRemoved"));
	} else {
		toast.success(translate("toaster.messages.favorites.favoriteAdded"));
	}
	clear({ object: "myFavorite" });
	clear({ object: "favorite" });
	clear({ object: "menu" });
	if (!layoutId) clear({ object, objectId });
};

export default toggleFavoriteThen;
