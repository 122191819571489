import { useCallback } from "react";
import { useFormContext } from "../../../Form/Form";
import debounce from "lodash/debounce";
import { noop } from "@clearpoint/utils";

let useSetValue = (name) => {
	let formContext = useFormContext();
	let { setFormValue } = formContext || {};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let debouncedSetFormValue = useCallback(setFormValue ? debounce(setFormValue, 250, { maxWait: 1000 }) : noop, [
		setFormValue,
	]);
	return useCallback(
		(_editor, _data, value) => {
			debouncedSetFormValue(name, `<style>${value}</style>`);
		},
		[debouncedSetFormValue, name]
	);
};

export default useSetValue;
