import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import HTML from "@clearpoint/old-theme/HTML";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	definition: PropTypes.string,
};

let Definition = ({ definition }) => {
	let translate = useTranslate();

	return (
		<FormGroup
			marginTop="0"
			marginBottom={theme.smallSpace}
			paddingBottom={theme.space}
			title={translate("options.admin.measureLibrary.measureDefinition")}
		>
			<HTML>{definition}</HTML>
		</FormGroup>
	);
};

Definition.propTypes = propTypes;

export default Definition;
