import Analysis from "./Analysis";
import Attachments from "./Attachments";
import Calculated from "./Calculated";
import Collaborators from "./Collaborators";
import Completed from "./Completed";
import CompletedDate from "./CompletedDate";
import DateTime from "./DateTime";
import Discussion from "./Discussion";
import ElapsedDays from "./ElapsedDays";
import ElapsedPercentComplete from "./ElapsedPercentComplete";
import EndDate from "./EndDate";
import LinkedElement from "./LinkedElement";
import Name from "./Name";
import ObjectId from "./ObjectId";
import ObjectiveScoring from "./ObjectiveScoring";
import Owner from "./Owner";
import PercentComplete from "./PercentComplete";
import Period from "./Period";
import Periodicity from "./Periodicity";
import PropTypes from "prop-types";
import Recommendations from "./Recommendations";
import Scorecard from "./Scorecard";
import SeriesData from "./SeriesData";
import SeriesEvaluations from "./SeriesEvaluations";
import StartDate from "./StartDate";
import Tags from "./Tags";
import Today from "./Today";
import TotalDays from "./TotalDays";
import User from "./User";
import Variance from "./Variance";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";

let podContentLookup = {
	analysis: Analysis,
	attachments: Attachments,
	calculated: Calculated,
	collaborators: Collaborators,
	comments: Analysis,
	completed: Completed,
	completedDate: CompletedDate,
	dateTime: DateTime,
	discussion: Discussion,
	elapsedDays: ElapsedDays,
	elapsedPercentComplete: ElapsedPercentComplete,
	endDate: EndDate,
	linkedElement: LinkedElement,
	name: Name,
	objectId: ObjectId,
	objectiveScore: Calculated,
	objectiveScoring: ObjectiveScoring,
	owner: Owner,
	percentComplete: PercentComplete,
	period: Period,
	periodicity: Periodicity,
	recommendations: Recommendations,
	scorecard: Scorecard,
	seriesData: SeriesData,
	seriesEvals: SeriesEvaluations,
	startDate: StartDate,
	tags: Tags,
	today: Today,
	totalDays: TotalDays,
	user: User,
	variance: Variance,
};

let propTypes = {
	fieldId: PropTypes.string,
	name: PropTypes.string,
	template: PropTypes.string,
};

let PodContent = ({ fieldId, name, template }) => {
	let Content = podContentLookup[template];
	let { closeEditMode, editPodFlag } = usePodContext();

	return Content ? (
		<Content closeEditMode={closeEditMode} editPodFlag={editPodFlag} fieldId={fieldId} name={name} />
	) : (
		<></>
	);
};

PodContent.propTypes = propTypes;
export default PodContent;
