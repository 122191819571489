import FilterSelect from "../../../../Filter/Filter.Select";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	fieldList: PropTypes.array,
	object: PropTypes.string,
};
let SelectFilterField = ({ fieldList, object, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(() => {
		let fieldTypeList = fieldList.reduce((fieldTypeList, field) => {
			if (!fieldTypeList.some((x) => x.value === field.type)) {
				return [
					...fieldTypeList,
					{
						label: translate(`layout.fieldType.${field.type}`),
						value: field.type,
					},
				];
			}
			return fieldTypeList;
		}, []);
		return [
			{
				label: translate("layout.fieldType.all"),
				value: 0,
			},
			...fieldTypeList,
		];
	}, [fieldList, translate]);
	return (
		<FilterSelect
			defaultValue="all"
			placeholder={translate("layout.fieldType.all")}
			options={options}
			name={`${object}Type`}
			filterBy="type"
			{...props}
		/>
	);
};
SelectFilterField.propTypes = propTypes;

export default SelectFilterField;
