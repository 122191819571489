import { useFormContext } from "../Form";
import getDeepValue from "lodash/get";

let useFormValue = (name) => {
	let formContext = useFormContext();
	if (!formContext) return undefined;
	let { formValue } = formContext;
	if (name) return getDeepValue(formValue, name);
	return formValue;
};

export default useFormValue;
