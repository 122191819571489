import FilterSelect from "@clearpoint/old-theme/Filter/Filter.Select";
import { useCallback, useMemo } from "react";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { emptyArray, emptyObject, noop } from "@clearpoint/utils";

let propTypes = {
	podList: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
		})
	),
};

let FieldTypeFilterSelect = ({ podList }) => {
	let translate = useTranslate();
	let { filter = noop } = useFilter() || emptyObject;

	// handle fields of type "custom"
	let customFilter = useCallback(
		(e) =>
			filter({
				fieldType: (pod) => {
					if (e.value === "edit") return (pod.type === "custom" && !pod.updateMode) || pod.type === "edit";
					if (e.value === "update") return (pod.type === "custom" && pod.updateMode) || pod.type === "update";
					return e.value === 0 || pod.type === e.value;
				},
			}),
		[filter]
	);

	let fieldTypeOptions = useMemo(
		() => [
			{ value: 0, label: translate("layout.fieldType.all") },
			...(podList
				.map((x) => {
					if (x.type === "custom") {
						return x.updateMode ? "update" : "edit";
					}
					return x.type;
				})
				.reduce(
					(typeList, type) =>
						typeList.find((x) => x.value === type)
							? typeList
							: [...typeList, { value: type, label: translate("layout.fieldType." + type) }],
					[]
				) || emptyArray),
		],
		[podList, translate]
	);

	return (
		<FilterSelect
			options={fieldTypeOptions}
			placeholder={translate("layout.fieldType.all")}
			filter={customFilter}
			name="fieldType"
			noFilterStyleFlag
		/>
	);
};

FieldTypeFilterSelect.propTypes = propTypes;
export default FieldTypeFilterSelect;
