import HTML from "../../../HTML";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	fieldId: PropTypes.string,
	modalFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let PageHtmlDisplay = ({ fieldId, modalFlag, parentName }) => {
	let displayValue = useFormValue(`${parentName ? parentName + "." : ""}${fieldId}.displayValue`);
	if (modalFlag) displayValue = displayValue.replace(/<a\b[^>]*>/i, "").replace(/<\/a>/i, "");
	return <HTML className="htmlarea">{displayValue}</HTML>;
};

PageHtmlDisplay.propTypes = propTypes;
export default PageHtmlDisplay;
