import { pushScrollLock, removeScrollLock, _lockingElements } from "@polymer/iron-overlay-behavior/iron-scroll-manager";
import { useState } from "react";
let useStopScrollEvent = () => {
	let [lockedFlag, setLockedFlag] = useState(false);
	// call this to Disable
	let disableScroll = (query) => {
		let passedQuery = query;
		if (!passedQuery)
			return console.warn(
				"No query passed to disableScroll. To not include any scrollable elements while disabling scroll, please pass 'none'"
			);
		if (passedQuery === "none") {
			query = undefined;
		}
		let element = document.querySelector(query);
		if (!element && passedQuery !== "none")
			return console.warn(
				`No element found for query '${query}'. Will not disable scroll. \n To not include any scrollable elements while disabling scroll, please pass 'none'`
			);
		pushScrollLock(element);
		document.body.style.overflow = "hidden";
		setLockedFlag(true);
	};

	// call this to Enable
	let enableScroll = (query) => {
		let element = document.querySelector(query);
		removeScrollLock(element);
		document.body.style.overflow = "visible";
		_lockingElements.length <= 0 && setLockedFlag(false);
	};

	return { disableScroll, enableScroll, lockedFlag };
};

export default useStopScrollEvent;
