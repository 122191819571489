import ChartSeriesList from "./ChartSeriesList";
import Help from "@clearpoint/old-theme/Help";
import PropTypes from "prop-types";

let propTypes = {
	gaugeFlag: PropTypes.bool,
};

let ChartSeriesTab = ({ gaugeFlag }) => {
	return (
		<>
			<Help section="sect18" feature="chart-series" />
			<ChartSeriesList gaugeFlag={gaugeFlag} />
		</>
	);
};

ChartSeriesTab.propTypes = propTypes;
export default ChartSeriesTab;
