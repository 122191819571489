import EditChartModalDisplay from "./EditChartModalDisplay";
import JSON5 from "json5";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import { useCallback, useMemo, useState } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "@clearpoint/services/toastService/index";
import { beforeChartSubmit, emptyObject } from "@clearpoint/utils";

let propTypes = {
	measureId: PropTypes.number.isRequired,
	modalWindowVisible: PropTypes.bool,
	objectId: PropTypes.number,
	periodId: PropTypes.number,
};

let EditChartModal = ({ measureId, modalWindowVisible, ...props }) => {
	let translate = useTranslate();
	let [objectId, setObjectId] = useState(props.objectId);
	let { session } = useOldSession();
	let periodId = props.periodId || session.periodId;
	let { clear, get } = useOldQueryStore();
	let options = get({ object: "options" });

	let parentObject = useFormValue("object");
	let defaultChartTypeId = options?.chartId?.find((x) => x.object === parentObject)?.chartId || 75;
	let chartData = objectId && get({ object: "chart", objectId });

	let defaultValue = useMemo(() => {
		if (objectId) {
			if (!chartData) return emptyObject;

			let chartDataCopy = cloneDeep(chartData);
			if (chartDataCopy.customJson) {
				chartDataCopy.customSeries = JSON5.parse(chartDataCopy.customJson).series;
				if (chartDataCopy.customSeries) {
					chartDataCopy.chartSeries.forEach((series, index) => {
						if (index < chartDataCopy.customSeries.length) {
							chartDataCopy.customSeries[index].measureSeriesId = series.measureSeriesId;
						}
					});
				}
				chartDataCopy.chartType = 0;
			}
			chartDataCopy._legend = chartDataCopy.showLegend ?? "chartDefault";
			return chartDataCopy;
		}
		return {
			_legend: true,
			chartSize: "medium",
			chartType: defaultChartTypeId,
			hiddenPeriods: [],
			measureId,
			legendPosition: "",
			name: undefined,
			object: "chart",
			periodDisplay: null,
			periodGroupId: -1,
			showFuture: true,
			showGridHidden: false,
			showLegend: true,
			sortSeries: "none",
		};
	}, [chartData, defaultChartTypeId, measureId, objectId]);

	let onSubmit = useCallback(() => {
		clear({ object: "chart", objectId, queryString: `periodId=${periodId}` });
		toast.success(translate(objectId ? "edit.chart.chartUpdated" : "edit.chart.chartAdded"));
	}, [clear, objectId, periodId, translate]);
	return (
		<ModalWindowEdit
			beforeSubmit={beforeChartSubmit}
			className="chart-modal"
			color="desaturatedNormal"
			defaultValue={defaultValue}
			loadingFlag={objectId && !chartData}
			modalVisible={modalWindowVisible}
			onSubmit={onSubmit}
			object="chart"
			objectId={objectId}
			parent="measure"
			parentId={measureId}
			size="medium"
			setObjectId={setObjectId}
			updateFlag
			{...props}
		>
			<EditChartModalDisplay periodId={periodId} />
		</ModalWindowEdit>
	);
};

EditChartModal.propTypes = propTypes;
export default EditChartModal;
