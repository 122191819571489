import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let ChartSeriesShapeSelect = ({ name, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("edit.chartSeries.chartDefault"), value: "" },
			{ label: translate("edit.chartSeries.column"), value: "bar" }, // intentional -- https://github.com/ClearPoint-Strategy/clearPoint10/issues/6968
			{ label: translate("edit.chartSeries.line"), value: "line" },
			{ label: translate("edit.chartSeries.area"), value: "area" },
			{ label: translate("edit.chartSeries.spline"), value: "spline" },
			{ label: translate("edit.chartSeries.bar"), value: "column" }, // intentional -- https://github.com/ClearPoint-Strategy/clearPoint10/issues/6968
		],
		[translate]
	);
	return <Select placeholder={translate("edit.selectShape")} options={options} name={name} {...props} />;
};

ChartSeriesShapeSelect.propTypes = propTypes;

export default ChartSeriesShapeSelect;
