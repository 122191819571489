import React, { memo } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap/Table";
import styled from "styled-components";

import { sizes } from "./values";
import { useOldSession } from "@clearpoint/old-session/index";

let cpsTableStyles = (exportFlag) => `
	table-layout: fixed;
	margin-bottom: 0px;
	border-spacing: 0;

	thead {
		width: 100%;
		th hr {
			margin-top: 5px;
			margin-bottom: 5px;
		}

		tr th {
			color: white;
			background-color: #27245e;
			word-break: normal;
			white-space: ${exportFlag ? "" : "pre-wrap"};
		}
	}

	tbody > td {
		border-bottom: none;
		min-width: 100px;
	}

	thead > th {
		min-width: 100px;
	}

	td ul {
		margin-left: 15px;
	}

	/* IE!!!! */
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		table.cps-table li {
			margin-left: 15px;
		}
	}

	th.cps-th {
		overflow-x: hidden;
	}

	tbody tr {
		td {
			border: none;
			border-left: 1px solid #e7eaec;
			border-right: 1px solid #e7eaec;
			border-top: 1px solid #e7eaec;
			padding: 6px 10px;
			vertical-align: top;
			overflow-x: hidden;
			height: 37px;
		}
	}

	tbody > td.table-edit,
	thead > th.table-edit {
		min-width: 40px;
	}

	tbody tr td > * {
		min-height: 24px;
		height: min-content;
	}
`;

let subTableStyles = `
	width: 100%;
	margin-bottom: 0;

	thead tr th {
		padding: 6px 12px;
		height: auto;
		font-size: 1em;
		font-weight: normal;
		text-align: center;
	}

	tbody tr td {
		padding: 6px 12px;
		height: auto;
		font-size: 1em;
	}
`;

let stickyHeaderStyles = `
	border-top: none;

	thead {
		tr th {
			position: sticky;
			top: 0px;
			vertical-align: top;
			border: 0px;
			z-index: 10;
		}
		tr th:first-child {
			z-index: 30;
		}

		th:before {
			content: "";
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			top: -1px;
      border-top: 1px solid transparent;
			border-left: 1px solid white;
			box-sizing: border-box;
		}
		tr:first-child th:first-child:before {
			border-left: 0px;
		}
		tr:first-child th {
			border-top: 0px;
		}
	}
`;

let stickyLeftColumnStyles = `
	border-left: none !important;

	th:first-child, td:first-child {
		position: sticky;
		left: 0px;
		border-left: 0px;
		z-index: 20;
	}

	th:first-child:before, td:first-child:before{
		content: "";
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		top: 0px;
		border-left: 1px solid #e7eaec;
		box-sizing: border-box;
	}
`;

let approvalTableStyles = `
	th, td {
		height: 45px;
		vertical-align: middle;
	}
`;

let StyledTable = styled(BootstrapTable)`
	${(props) => `
	${props.$cpsTableFlag ? cpsTableStyles(props.$exportFlag) : ""}
	${props.$cpsSubTableFlag ? subTableStyles : ""}
	${props.$stickyHeaderFlag ? stickyHeaderStyles : ""}
	${props.$stickyLeftColumnFlag ? stickyLeftColumnStyles : ""}
	${props.$approvalTableFlag ? approvalTableStyles : ""}
	width: ${props.width || "100%"};
		`}
`;

let propTypes = {
	borderedFlag: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	cpsSubTableFlag: PropTypes.bool,
	approvalTableFlag: PropTypes.bool,
	cpsTableFlag: PropTypes.bool,
	hoverFlag: PropTypes.bool,
	id: PropTypes.string,
	size: PropTypes.oneOf(["small", "large"]),
	stickyHeaderFlag: PropTypes.bool,
	stickyLeftColumnFlag: PropTypes.bool,
	stripedFlag: PropTypes.bool,
};

let Table = React.forwardRef(
	(
		{
			approvalTableFlag,
			borderedFlag,
			children,
			className,
			cpsSubTableFlag,
			cpsTableFlag,
			hoverFlag,
			id,
			size,
			stickyHeaderFlag,
			stickyLeftColumnFlag,
			stripedFlag,
			width,
		},
		ref
	) => {
		let { exportFlag } = useOldSession();
		return (
			<StyledTable
				$approvalTableFlag={approvalTableFlag}
				$cpsSubTableFlag={cpsSubTableFlag}
				$cpsTableFlag={cpsTableFlag}
				$exportFlag={exportFlag}
				$stickyHeaderFlag={stickyHeaderFlag}
				$stickyLeftColumnFlag={stickyLeftColumnFlag}
				bordered={borderedFlag}
				className={className}
				hover={hoverFlag}
				id={id}
				ref={ref}
				size={sizes[size]}
				striped={stripedFlag}
				width={width}
			>
				{children}
			</StyledTable>
		);
	}
);

Table.propTypes = propTypes;
export default memo(Table);
