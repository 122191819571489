import SelectMeasureSeries from "@clearpoint/old-theme/Form/Select/Select.MeasureSeries";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import useSetSeriesNameAsChartTitle from "./useSetSeriesNameAsChartTitle";
import { useTranslate } from "@clearpoint/translate";

let PrimarySeriesSelect = () => {
	let translate = useTranslate();
	let { measureId } = useFormValue("chart");
	let setSeriesNameAsChartTitle = useSetSeriesNameAsChartTitle();

	return (
		<SelectMeasureSeries
			label={translate("global.series")}
			name="chart.measureSeriesId"
			measureId={measureId}
			onChange={setSeriesNameAsChartTitle}
			required
		/>
	);
};

export default PrimarySeriesSelect;
