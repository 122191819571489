import useStateObject from "./useStateObject";
import { useEffect } from "react";

let useImageDimensions = (src) => {
	let [state, setState] = useStateObject({
		height: null,
		width: null,
	});
	useEffect(() => {
		if (src) {
			let image = new Image();
			image.src = src;
			image.onload = () => {
				setState({
					height: image.height,
					width: image.width,
				});
			};
			return () => (image.onload = undefined);
		}
	}, [setState, src]);
	return state;
};

export default useImageDimensions;
