import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { useDescribeTimePassed, useUserType } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import Block from "../../Block";
import InitialsCircle from "../../Circle/Circle.Initials";
import DropdownItem from "../../Dropdown/Item/Dropdown.Item";
import EditDropdownItem from "../../Dropdown/Item/Dropdown.Item.Edit";
import DropdownItemTrash from "../../Dropdown/Item/Dropdown.Item.Trash";
import KebabButton from "../../Dropdown/Kebab/KebabButton";
import { theme } from "../../Theme";
import Resolve from "../Resolve";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";


let propTypes = {
	commentData: PropTypes.shape({
		comment: PropTypes.string,
		commentId: PropTypes.number,
		discussionId: PropTypes.number,
		userId: PropTypes.number,
	}),
	editModeFlag: PropTypes.bool,
	setReplyFlag: PropTypes.func,
	setState: PropTypes.func,
	threadParentFlag: PropTypes.bool,
};

let CommentHeader = ({ commentData, editModeFlag, setReplyFlag, setState, threadParentFlag }) => {
	let translate = useTranslate();
	let { get, set } = useOldQueryStore();
	let { commentDate, commentId, discussionId, userId, resolved: resolvedFlag } = commentData;
	let { newCommentBoxVisible, setLocalState } = useLocalState();

	let toggleEditMode = useCallback(() => {
		setState({ editModeFlag: (previousFlag) => !previousFlag });
	}, [setState]);
	let describeTimePassed = useDescribeTimePassed();

	let userList = get({ object: "user" });
	let user = userList?.find((x) => x.userId === userId);
	let profile = get({ object: "profile" });
	let userType = useUserType();
	let allowEditFlag = userId === profile?.userId && !editModeFlag && !resolvedFlag;
	let allowDeleteFlag = allowEditFlag || userType === "Administrator";

	let [resolvingFlag, setResolvingFlag] = useState(false);
	let toggleThreadResolution = useCallback(() => {
		setResolvingFlag(true);
		if (!newCommentBoxVisible) {
			setReplyFlag(false);
			setLocalState({ newCommentBoxVisible: true });
		}
		set({
			object: "comment",
			objectId: discussionId,
			data: { resolved: !resolvedFlag },
		}).then(() => {
			setResolvingFlag(false);	
		});
	}, [discussionId, newCommentBoxVisible, resolvedFlag, set, setLocalState, setReplyFlag, setResolvingFlag]);

	return (
		<Block display="flex" justifyContent="space-between" alignItems="start" flexWrap="nowrap">
			<Block display="flex" alignItems="start" lineHeight="1.1" ellipsisFlag paddingRight="5px">
				<InitialsCircle
					firstName={user?.firstName || " "}
					lastName={user?.lastName || " "}
					marginRight={theme.smallSpace}
					size="tiny"
					$style={`opacity: ${!user && 0};`}
				/>
				<Block lineHeight="1.05" fontSize="14px">
					<Block className="cps-name" fontWeight="bold" ellipsisFlag>
						{user?.fullName || translate("global.deletedUser")}
					</Block>
					<Block className="cps-time" ellipsisFlag>
						<small>{describeTimePassed(commentDate)}</small>
					</Block>
				</Block>
			</Block>
			<Block display="flex">
				{threadParentFlag && (
					<Resolve
						toggleThreadResolution={toggleThreadResolution}
						resolvingFlag={resolvingFlag}
						resolvedFlag={resolvedFlag}
					/>
				)}
				<KebabButton>
					{allowEditFlag && (
						<EditDropdownItem object="comment" onClick={toggleEditMode} objectId={commentId || discussionId} />
					)}
					{threadParentFlag && (
						<DropdownItem icon={resolvedFlag ? "unresolve" : "resolve"} onClick={toggleThreadResolution}>
							{resolvedFlag ? translate("global.unresolveThread") : translate("global.resolveThread")}
						</DropdownItem>
					)}
					{allowDeleteFlag && (
						<DropdownItemTrash
							body={
								threadParentFlag
									? translate("edit.confirmThreadDelete")
									: translate("edit.confirmCommentDeleteWithButtons")
							}
							object="comment"
							objectId={commentId || discussionId}
							label={threadParentFlag && translate("global.thread")}
							title={threadParentFlag && translate("global.delete", { InsertText: translate("global.thread") })}
							noFixedWidthFlag
							toast={
								threadParentFlag
									? translate("toaster.messages.discussions.threadDeleted")
									: translate("button.commentDeleted")
							}
						/>
					)}
				</KebabButton>
			</Block>
		</Block>
	);
};

CommentHeader.propTypes = propTypes;
export default CommentHeader;
