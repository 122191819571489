import FormFieldDisplayPercentComplete from "@clearpoint/old-theme/Form/Field/Display/PercentComplete";
import FormFieldPercentComplete from "@clearpoint/old-theme/Form/Field/PercentComplete";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let PercentComplete = ({ editPodFlag, name }) =>
	editPodFlag ? <FormFieldPercentComplete name={name} /> : <FormFieldDisplayPercentComplete name={name} />;

PercentComplete.propTypes = propTypes;
export default PercentComplete;
