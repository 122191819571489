let getSendMessageToGPT = ({ chatId, context, endpoint, httpExpress, setAssistantState, startIndex }) => {
	let updateStateWithResponse = (response) => {
		let { chatId, messages: messageList, startIndex } = response || {};
		let newState = {};
		if (startIndex !== undefined) {
			newState = {
				...newState,
				initialResponseReceivedFlag: true,
				startIndex,
			};
		}
		if (messageList?.[startIndex]) {
			newState.messageList = [messageList?.[startIndex]];
		}

		if (chatId) newState = { ...newState, chatId };
		setAssistantState(newState);
	};

	return async (message, index) => {
		let payload = { context, message };
		if (message === "" || message === "save-cps-chat") {
			payload.index = index;
		} else if (index !== undefined) {
			payload.index = startIndex + index;
		}
		if (chatId) payload.chatId = chatId;
		let response = await httpExpress.post(endpoint, payload);
		updateStateWithResponse(response);
		return response.chatId;
	};
};

export default getSendMessageToGPT;
