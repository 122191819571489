import PropTypes from "prop-types";
import styled from "styled-components";


let StyledHelpText = styled.span`
	display: inline-block;
	margin: -2px 0 7px;
	font-size: 0.9em;
	color: ${(props) => (props.error ? props.theme.dangerDark : undefined)};
`;

let propTypes = {
	children: PropTypes.node.isRequired,
	error: PropTypes.bool,
};

let HelpText = ({ children, error }) => <StyledHelpText error={error}>{children}</StyledHelpText>;

HelpText.propTypes = propTypes;

export default HelpText;
