import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useFormatDate } from "@clearpoint/hooks";


let propTypes = {
	displayValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	name: PropTypes.string,
};

let DateDisplay = ({ displayValue, name }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let date = useFormValue(name);
	let fieldValue = displayValue || date;
	return fieldValue ? <span>{formatDate(fieldValue, "short")}</span> : translate("global.notAvailable");
};

DateDisplay.propTypes = propTypes;
export default DateDisplay;
