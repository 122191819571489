import { useCallback, useState } from "react";


let useOverflowXFlag = ({ containerWidth }) => {
	let [overflowXFlag, setState] = useState(false);

	let setOverflowXFlag = useCallback(
		(columnWidthList) => {
			let occupiedWidth = columnWidthList.reduce((usedWidth, columnWidth) => usedWidth + (columnWidth || 0), 0);
			setState(containerWidth < occupiedWidth);
		},
		[containerWidth]
	);

	return { overflowXFlag, setOverflowXFlag };
};

export default useOverflowXFlag;
