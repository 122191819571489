import Block from "../../Block";
import KebabButton from "../../Dropdown/Kebab/KebabButton";
import StyleWrapper from "../../StyleWrapper";
import { useMemo } from "react";
import PropTypes from "prop-types";
import includesObjectGenerator from "../utils/includesObjectGenerator";
import { theme } from "../../Theme";
import { emptyObject } from "@clearpoint/utils";


let propTypes = {
	objectData: PropTypes.object,
};

let ObjectMenu = ({ objectData }) => {
	let { id, name, object } = objectData || emptyObject;

	let includesObject = useMemo(() => includesObjectGenerator(object), [object]);

	let displayGoalMenuFlag = includesObject(["goal"]);

	return (
		displayGoalMenuFlag && (
			<Block position="absolute" bottom={theme.smallSpace} right={theme.smallSpace}>
				<StyleWrapper position="relative">
					<KebabButton
						serverSideFlag
						id="linkChartKebabBtn"
						data-object={object}
						data-objectid={id}
						data-name={name}
					/>
				</StyleWrapper>
			</Block>
		)
	);
};

ObjectMenu.propTypes = propTypes;

export default ObjectMenu;
