let getLoginRedirectFromHash = (hash) => {
	if (hash.includes("index") || hash.includes("login")) {
		if (hash.match(/#?\/index\//g)) {
			return hash.replace(/#?\/index\//g, "#/login/");
		} else if (hash.match(/#?\/index/g)) {
			return hash.replace(/#?\/index/g, "#/login");
		}
	}
	return "/#/login";
};

export default getLoginRedirectFromHash;
