import http from "@clearpoint/services/httpService/index";
import { DETAIL } from "../types";
import { OPTIONS } from "../objects";
import { OPTIONS_PATH } from "../paths";
import { emptyObject, objectList } from "@clearpoint/utils";
import { queryClient } from "../QueryProvider";
import { useMutation, useQuery } from "@tanstack/react-query";

// to do: add mutation test

let useOptionsQuery = () =>
	useQuery({
		queryKey: [{ object: OPTIONS, type: DETAIL }],
		queryFn: () => http.get(OPTIONS_PATH),
		placeholderData: {},
	});

let getOptionsData = () => queryClient.getQueryData([{ object: OPTIONS, type: DETAIL }]);

let useOptionsMutation = (options) => {
	let { onSettled } = options || emptyObject;

	return useMutation({
		mutationFn: (data) => {
			if (data.hostname === "") data.hostname = null;
			return http.post(OPTIONS_PATH, data);
		},
		onSettled: (...args) => {
			queryClient.invalidateQueries([{ object: OPTIONS }]);
			queryClient.invalidateQueries([{ object: "measureSeries" }, { object: "customField" }]);
			queryClient.invalidateQueries(objectList.map((object) => ({ object })));
			if (onSettled) onSettled(...args);
		},
	});
};

export { getOptionsData, useOptionsQuery, useOptionsMutation };
