import PodComponent from "./PodComponent";
import PodProvider from "./PodProvider";
import PropTypes from "prop-types";

let propTypes = {
	areaClass: PropTypes.string,
	chart: PropTypes.object,
	draggableFlag: PropTypes.bool,
	fieldId: PropTypes.string,
	hideBorder: PropTypes.bool,
	podResizeOnClick: PropTypes.func,
	podStyleId: PropTypes.number,
	podWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // [new UI, old UI]
	readOnly: PropTypes.bool,
	template: PropTypes.string,
	title: PropTypes.string,
	updateMode: PropTypes.bool,
};

let Pod = ({
	areaClass,
	chart,
	draggableFlag,
	fieldId,
	hideBorder: hideBorderFlag,
	podResizeOnClick,
	podStyleId,
	podWidth,
	readOnly: readOnlyFlag,
	template,
	title,
	updateMode: updateModeFlag,
}) => (
	<PodProvider
		areaClass={areaClass}
		podWidth={podWidth}
		chart={chart}
		fieldId={fieldId}
		hideBorderFlag={hideBorderFlag}
		podStyleId={podStyleId}
		readOnlyFlag={readOnlyFlag}
		template={template}
		title={title}
		updateModeFlag={updateModeFlag}
	>
		<PodComponent areaClass={areaClass} draggableFlag={draggableFlag} podResizeOnClick={podResizeOnClick} />
	</PodProvider>
);

Pod.propTypes = propTypes;
export default Pod;
