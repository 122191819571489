import Button from "../../Button/Button";
import Icon from "../../Icon/Icon";
import ModalConfirm from "../../Modal/Modal.Confirm";
import ModalOnClick from "../../Modal/Modal.OnClick";
import StyleWrapper from "../../StyleWrapper";
import { useAIAssistant } from "../../AI/AIAssistantProvider/AIAssistantProvider";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getHandleChatValidation from "./AddActionItemButton.getHandleChatValidation";
import { theme } from "../../Theme";
import { useOnEvent } from "@clearpoint/hooks";


let propTypes = {
	handleAfterSubmitAddActionItems: PropTypes.func,
};

let AddActionItemButton = ({ handleAfterSubmitAddActionItems }) => {
	let translate = useTranslate();
	let { assistantState, sendMessageToGPT, setAssistantState } = useAIAssistant();
	let { responseReceivedFlag } = assistantState;
	const GET_JSON_MESSAGE = "send-cps-json";

	let getJson = useCallback(() => {
		sendMessageToGPT(GET_JSON_MESSAGE);
		setAssistantState({ validatingFlag: true });
	}, [sendMessageToGPT, setAssistantState]);

	let handleChatValidation = useCallback(
		(e) => {
			getHandleChatValidation({ e, setAssistantState, handleAfterSubmitAddActionItems });
		},
		[handleAfterSubmitAddActionItems, setAssistantState]
	);

	useOnEvent({
		dependencyList: [handleChatValidation],
		eventName: "chatBotEvent",
		handlerFunction: handleChatValidation,
	});

	return (
		<ModalOnClick
			Modal={ModalConfirm}
			formFlag={false}
			body={translate("global.confirmAddToClearPoint.plural", { objects: "actionItems" })}
			confirmButtonText={translate("global.add")}
			confirmButtonColor="info"
			title={translate("edit.addElement", { element: "actionItems" })}
			onConfirm={getJson}
		>
			<StyleWrapper marginTop={theme.mediumSpace}>
				<Button type="button" color="primary" disabled={!responseReceivedFlag} float="right">
					<Icon name="add" /> {translate("ai.assistant.addActionItems")}
				</Button>
			</StyleWrapper>
		</ModalOnClick>
	);
};

AddActionItemButton.propTypes = propTypes;
export default AddActionItemButton;
