import { Fragment, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import Discussion from "@clearpoint/old-theme/Discussion/index";
import PaginationControl from "@clearpoint/old-theme/Pagination/Control";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";

import Gantt from "./Gantt";
import Grid from "./Grid";
import SelectGanttScale from "./SelectGanttScale";
import SelectRowCount from "./SelectRowCount";
import Toolbar from "./Toolbar";
import Search from "./Toolbar/Search";
import useUnsetEditTableFlagOnDetailPageSubmit from "./useUnsetEditTableFlagOnDetailPageSubmit";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let wrapperPropTypes = {
	children: PropTypes.node,
	elementList: PropTypes.array,
};

let GanttWrapper = ({ children, elementList }) => {
	let { customFieldFlag } = useLocalState();
	let height = (elementList?.length + 2) * 26;

	return customFieldFlag ? <Block height={height ? height + "px" : "auto"}>{children}</Block> : children;
};

GanttWrapper.propTypes = wrapperPropTypes;

let propTypes = {
	brokenApiCallFlag: PropTypes.bool,
	customFieldFlag: PropTypes.bool,
	elementData: PropTypes.object,
	elementList: PropTypes.array,
	filteredElementDataFlag: PropTypes.bool,
	layoutId: PropTypes.number,
	noResultsFlag: PropTypes.bool,
	page: PropTypes.number,
	previousElementData: PropTypes.object,
	reportType: PropTypes.string,
	rowCount: PropTypes.number,
	searchFlag: PropTypes.bool,
	setPage: PropTypes.func,
	setRowCount: PropTypes.func,
	tableLoadingFlag: PropTypes.bool,
	tableStyle: PropTypes.string,
	tableStyleObject: PropTypes.object,
};

let ViewLayoutDisplay = ({
	customFieldFlag,
	elementData,
	elementList,
	layoutId,
	noResultsFlag,
	page,
	previousElementData,
	reportType,
	rowCount,
	searchFlag,
	setPage,
	setRowCount,
	tableLoadingFlag,
	tableStyle,
	tableStyleObject,
}) => {
	let translate = useTranslate();
	let { session } = useOldSession();
	let { object } = session;
	let { editTableFlag, gridPodFlag } = useLocalState();
	let scrollableRef = useRef(null);

	useEffect(() => {
		if (scrollableRef.current) {
			scrollableRef.current.scrollTo(0, 0);
		}
	}, [page]);

	let currentReport = useMemo(
		() => (elementData ? elementData?.report : previousElementData?.report),
		[elementData, previousElementData]
	);

	useUnsetEditTableFlagOnDetailPageSubmit();

	let hideRowChooser = elementData?.pageCount === 1 && elementData?.report[0]?.length <= 10;

	let height = elementData?.pageCount > 1 ? "calc(100vh - 280px)" : "auto";

	return (
		<Block
			data-testid="view-layout-display-page"
			display="flex"
			flex="1 0 1px"
			height="100%"
			maxWidth="100%"
			fontWeight="normal"
			overflowX="hidden"
		>
			<Block height="100%" position="relative" display="flex" flexDirection="column" overflow="hidden" width="100%">
				{!customFieldFlag && <Toolbar elementData={elementData} />}
				<Block
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					marginTop={customFieldFlag ? undefined : `10px`}
					padding={customFieldFlag ? undefined : `0 ${theme.largeSpace} 0 ${theme.largeSpace}`}
					overflow="hidden"
					overflowY="hidden"
					width="100%"
				>
					{currentReport?.map((x, i) => (
						<Fragment key={i}>
							<Block
								ref={scrollableRef}
								className="grid-container"
								flex="1"
								width="100%"
								backgroundColor={theme.white}
								maxHeight={gridPodFlag ? "75vh" : height}
								height={height}
								minHeight={height}
								marginBottom={customFieldFlag ? undefined : `5px`}
								overflow="auto"
								marginTop={customFieldFlag ? undefined : theme.tinySpace}
							>
								{!noResultsFlag &&
									(reportType === "gantt" ? (
										<GanttWrapper elementList={elementList}>
											<Gantt tableLoadingFlag={tableLoadingFlag} />
										</GanttWrapper>
									) : (
										<Grid
											cellData={x}
											elementData={elementData}
											tableStyle={tableStyle}
											tableStyleObject={tableStyleObject}
											tableLoadingFlag={tableLoadingFlag}
										/>
									))}
							</Block>
						</Fragment>
					))}
					<Block display="flex" alignItems="center">
						{customFieldFlag && <Search />}
						{(customFieldFlag && searchFlag) || hideRowChooser ? null : (
							<Block
								margin={`${theme.space} auto 0 auto `}
								display="flex"
								flexWrap="wrap-reverse"
								justifyContent="center"
								paddingRight="75px"
							>
								<PaginationControl
									pageCount={elementData?.pageCount}
									currentPage={page}
									size={"small"}
									onClick={setPage}
									preventPaginationFlag={editTableFlag}
									preventPaginationMessage={translate("layout.saveOrCancel")}
								/>
								{elementData?.pageCount && (
									<SelectRowCount minWidth="220px" value={rowCount} onChange={setRowCount} inlineFlag />
								)}
								{elementData?.pageCount && reportType === "gantt" && (
									<SelectGanttScale minWidth="120px" width="120px" inlineFlag />
								)}
							</Block>
						)}
						{hideRowChooser && reportType === "gantt" && (
							<Block
								margin={`${theme.space} auto 0 auto `}
								display="flex"
								flexWrap="wrap-reverse"
								justifyContent="center"
								paddingRight="75px"
							>
								<SelectGanttScale minWidth="120px" width="220px" inlineFlag />
							</Block>
						)}
					</Block>
				</Block>
			</Block>
			{!customFieldFlag && <Discussion object={object} layoutId={layoutId} />}
		</Block>
	);
};

ViewLayoutDisplay.propTypes = propTypes;

export default ViewLayoutDisplay;
