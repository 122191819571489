import EditChartTab from "@components/Layouts/Shared/EditDetailLayoutModal/PlaceChartModal/PlaceChartTab";
import EditPodTab from "./EditPodTab";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	object: PropTypes.string,
};

let EditPodModalContent = ({ object }) => {
	let translate = useTranslate();
	let { chart, fieldId, title } = useFormValue();
	let kpiChartPodFlag = fieldId === "kpi";
	let modalTitle = kpiChartPodFlag ? chart?.title : translate(title);

	return (
		<>
			<ModalWindowHeader
				badgeIcon={object || "scorecard"}
				badgeText={translate("edit.editPod")}
				title={modalTitle}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<EditPodTab title={translate("edit.editPod")} />
					{kpiChartPodFlag && <EditChartTab title={translate("edit.editChart")} />}
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter />
		</>
	);
};

EditPodModalContent.propTypes = propTypes;
export default EditPodModalContent;
