import { useMemo } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormSelectStatus from "@clearpoint/old-theme/Form/Select/Select.Status";
import Hide from "@clearpoint/old-theme/Hide";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import StackedIcon from "@clearpoint/old-theme/Icon/Icon.Stacked";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess, useGetLinkAccess } from "@clearpoint/hooks";

import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";

let propTypes = {
	link: PropTypes.shape({
		evaluated: PropTypes.bool,
		fieldId: PropTypes.string,
		object: PropTypes.string,
		access: PropTypes.string,
		periodLocked: PropTypes.bool,
		scorecardId: PropTypes.number,
		statusId: PropTypes.number,
	}),
	podFieldId: PropTypes.string,
};

let Icons = ({ link, podFieldId }) => {
	let {
		evaluated: evaluatedFlag,
		fieldId: linkFieldId,
		object,
		periodLocked: periodLockedFlag,
		scorecardId,
		access,
		statusId,
	} = link;
	let podContext = usePodContext();
	let editPodFlag = podContext?.editPodFlag;
	let checkAccess = useCheckAccess();
	let updateAccessFlag = checkAccess({ access, action: "update", scorecardId });
	let allowStatusUpdateFlag = editPodFlag && updateAccessFlag && !evaluatedFlag && !periodLockedFlag;

	let formValue = useFormValue();
	let links = formValue?.links;

	let podIndex = useMemo(
		() => Array.isArray(links) && links?.findIndex((x) => x?.objectId === link?.objectId),
		[link, links]
	);

	let showElementIconFlag = podFieldId === "linkedElements";
	let elementIconName = ["parents", "children"].includes(linkFieldId) ? linkFieldId : object;

	return (
		<Hide visible={object !== "category"}>
			<Block display="flex" flexShrink="0" alignItems="center">
				{showElementIconFlag && (
					<Block width="24px" marginRight={theme.smallSpace}>
						{linkFieldId === "initiativeMeasures" ? (
							<StackedIcon name="initiativeMeasures" />
						) : (linkFieldId === "measureInitiatives" ? (
							<StackedIcon name="measureInitiatives" />
						) : (
							<Icon name={elementIconName} size="large" />
						))}
					</Block>
				)}
				{allowStatusUpdateFlag ? (
					<Block marginTop="2px" marginRight={theme.smallSpace}>
						<FormSelectStatus object={object} defaultValue={0} iconOnlyFlag name={`links.${podIndex}.statusId`} />
					</Block>
				) : (
					<StatusIcon statusId={statusId} />
				)}
			</Block>
		</Hide>
	);
};

Icons.propTypes = propTypes;
export default Icons;
