import ChartSeriesValueSelect from "@clearpoint/old-theme/Form/Select/Select.ChartSeriesValue";
import Column from "@clearpoint/old-theme/Column";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import Input from "@clearpoint/old-theme/Form/Input/Input";
import Row from "@clearpoint/old-theme/Row";
import TrendlineSelect from "@clearpoint/old-theme/Form/Select/Select.Trendline";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	chartSeriesNameInForm: PropTypes.string,
};

let AdvancedOptions = ({ chartSeriesNameInForm }) => {
	let translate = useTranslate();
	let chartSeriesFormValue = useFormValue(chartSeriesNameInForm);
	let { setFormValue } = useFormContext();

	let defaultPeriodOffsetToZero = useCallback(
		(e) => {
			if (e.target.value === "") setFormValue(`${chartSeriesNameInForm}.periodOffset`, 0);
		},
		[chartSeriesNameInForm, setFormValue]
	);

	let onSeriesValueTypeChange = useCallback(
		(e) => {
			if (e) {
				if (e.value !== "trendline") setFormValue(`${chartSeriesNameInForm}.regressionModel`, "");
				if (e.value !== "offset") setFormValue(`${chartSeriesNameInForm}.periodOffset`, 0);
			}
		},
		[chartSeriesNameInForm, setFormValue]
	);

	return (
		<FormGroup title={translate("chart.advancedOptions")}>
			<Row>
				<Column small={12} large={6}>
					<ChartSeriesValueSelect
						name={`${chartSeriesNameInForm}.seriesType`}
						onChange={onSeriesValueTypeChange}
						label={translate("edit.chart.seriesType")}
					/>
				</Column>
				<Column small={12} large={6}>
					{chartSeriesFormValue?.seriesType === "trendline" && (
						<TrendlineSelect
							name={`${chartSeriesNameInForm}.regressionModel`}
							label={translate("edit.chart.trendline")}
						/>
					)}
					{chartSeriesFormValue?.seriesType === "offset" && (
						<Input
							type="number"
							onBlur={defaultPeriodOffsetToZero}
							label={translate("edit.chart.periodOffset")}
							name={`${chartSeriesNameInForm}.periodOffset`}
						/>
					)}
				</Column>
			</Row>
		</FormGroup>
	);
};

AdvancedOptions.propTypes = propTypes;
export default AdvancedOptions;
