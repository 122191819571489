import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { deepFreeze } from "@clearpoint/utils";


let useFormStatus = ({ formTouched, formError, formDirty }) =>
	useMemo(() => {
		let formStatus = {
			touched: !isEmpty(formTouched),
			valid: isEmpty(formError),
			dirty: !isEmpty(formDirty),
		};
		deepFreeze(formStatus);
		return formStatus;
	}, [formDirty, formError, formTouched]);

export default useFormStatus;
