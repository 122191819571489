import { alertSound } from "@clearpoint/assets";

let AlertAudioInitializer = () => {
	let audio;

	let initializeAudio = () => {
		audio = new Audio(alertSound);
		try {
			audio.muted = true;
			audio.play();
		} catch (err) {
			console.error("Failed to initialize audio:", err);
		}

		document.removeEventListener("click", playAudio);

		setTimeout(() => {
			audio.muted = false;
		}, 5000);
	};

	let playAudio = () => {
		try {
			if (audio) {
				audio.play();
			} else {
				initializeAudio();
			}
		} catch (err) {
			console.error("Failed to play sound:", err);
		}
	};

	let setupEventListener = () => {
		document.addEventListener("click", playAudio);
	};

	return { playAudio, setupEventListener };
};

let alertAudioInitializer = AlertAudioInitializer();

window.onload = () => setTimeout(alertAudioInitializer.setupEventListener, 500);

export default alertAudioInitializer.playAudio;
