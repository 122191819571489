import { useCallback, useMemo } from "react";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";

import { useFormatDate, useNavigateToScorecard } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray, emptyObject } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import LinksTab from "../EditActionItemModal/LinksTab";
import ActionItemsTab from "./ActionItemsTab";
import EditFieldsTab from "./EditFieldsTab";
import UpdateFieldsTab from "./UpdateFieldsTab";
import useCheckApproval from "@clearpoint/hooks-dir/useCheckApproval";
import { useOldSession } from "@clearpoint/old-session/index";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";

let propTypes = {
	aiAddActionItemsName: PropTypes.string,
	linkElementList: PropTypes.arrayOf(PropTypes.object),
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};

let EditMultipleActionItemsModal = ({ aiAddActionItemsName, linkElementList, objectIdList, ...props }) => {
	let navigateToScorecard = useNavigateToScorecard();
	let translate = useTranslate();
	let { clear } = useOldQueryStore();
	let formatDate = useFormatDate();
	let { session } = useOldSession();
	let { scorecardId } = session;

	let defaultValue = useMemo(
		() => ({
			_names: aiAddActionItemsName ?? undefined,
			scorecardId,
			links: linkElementList ?? emptyArray,
			completedDate: formatDate(new Date(), "apiDateWithoutTimezone"),
			endDate: formatDate(lastDayOfMonth(new Date()), "apiDateWithoutTimezone"),
			startDate: formatDate(startOfMonth(new Date()), "apiDateWithoutTimezone"),
		}),
		[aiAddActionItemsName, formatDate, linkElementList, scorecardId]
	);
	let addFlag = !objectIdList;
	let { setChecklist } = useChecklist() || emptyObject;
	let approvalRequired = useCheckApproval({ scorecardId })({ type: addFlag ? "adds" : "edits" });

	let onSubmit = useCallback(
		(value, apiResponse, contextValue) => {
			if (addFlag) {
				if (apiResponse?.action === "bulkAdded") {
					toast.success(
						translate("toaster.messages.objects.multiObjectsAdded", {
							count: apiResponse.objects.length,
							objects: translate("actionItems"),
						})
					);					
				} else {
					toast.warning(
						translate("approvals.multiObjectsAdded", {
							count: apiResponse.objects.length,
							objects: translate("actionItems"),
						})
					);
				}
			} else {
				setChecklist((checklist) => {
					checklist = cloneDeep(checklist);
					return checklist.map((x) => ({ ...x, checked: false }));
				});
				navigateToScorecard(value?.scorecardId);
				if ((apiResponse.updates?.length > 0 && apiResponse.updates[0].action === "updateQueued")
					|| (apiResponse.edits?.length > 0 && apiResponse.edits[0].action === "editQueued")) {

					toast.warning(
						translate("approvals.multiObjectsUpdated", {
							count: Math.max(apiResponse.edits.length, apiResponse.updates.length),
							objects: translate("actionItems"),
						})
					);
				} else {
					toast.success(
						translate("toaster.messages.objects.multiObjectsUpdated", {
							count: Math.max(apiResponse.edits.length, apiResponse.updates.length),
							objects: translate("actionItems"),
						})
					);
				}
			}
			clear({ object: "menu", parent: "scorecard", parentId: scorecardId });
		},
		[addFlag, clear, navigateToScorecard, scorecardId, setChecklist, translate]
	);
	return (
		<ModalWindowEdit
			color="desaturatedNormal"
			defaultValue={addFlag ? defaultValue : undefined}
			object="actionItem"
			objectIdList={objectIdList}
			emptySubmitWarning={translate("edit.element.selectFieldsWarning")}
			onSubmit={onSubmit}
			size="large"
			submitUnchangedFlag={!objectIdList}
			{...props}
		>
			<ModalWindowHeader
				badgeIcon="actionItem"
				badgeText={translate("actionItem")}
				title={`${translate(addFlag ? "edit.element.addElement" : "global.editMultiple")} ${translate(
					"actionItems"
				)}`}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					{!addFlag && <ActionItemsTab title={translate("actionItems")} objectIdList={objectIdList} />}
					{!addFlag && <UpdateFieldsTab title={translate("edit.element.updateFields")} />}
					<EditFieldsTab
						title={translate(addFlag ? "actionItems" : "edit.element.editFields")}
						addFlag={addFlag}
						objectIdList={objectIdList}
					/>
					{addFlag && !approvalRequired && <LinksTab title={translate("edit.links")} />}
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter />
		</ModalWindowEdit>
	);
};

EditMultipleActionItemsModal.propTypes = propTypes;
export default EditMultipleActionItemsModal;
