import { ToastContainer as Container } from "react-toastify";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";

let propTypes = {
	position: PropTypes.string,
	autoClose: PropTypes.number,
};

let ToastContainer = ({ position = "top-center", autoClose = 2000 }) => {
	let { exportFlag } = useOldSession()?.session || {};

	return exportFlag ? null : (
		<Container data-testid="toast-container" position={position} autoClose={autoClose} newestOnTop />
	);
};

ToastContainer.propTypes = propTypes;

export default ToastContainer;
