import Block from "@clearpoint/old-theme/Block";
import DropdownSplitButtonFavorite from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Favorite";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { theme } from "@clearpoint/old-theme/Theme";
import { buildQueryString, emptyObject } from "@clearpoint/utils";


let FavoriteSplitButton = () => {
	let { get } = useOldQueryStore();
	let { map } = useLocalState();
	let { mapId: objectId, scorecardId } = map;
	let { favorite: favoriteFlag } =
		(objectId && get({ object: "favorite", queryString: buildQueryString({ objectId, object: "map" }) })) ||
		emptyObject;
	return (
		<Block marginLeft={theme.smallSpace}>
			<DropdownSplitButtonFavorite
				favoriteFlag={favoriteFlag}
				object="map"
				objectId={objectId}
				scorecardId={scorecardId}
			/>
		</Block>
	);
};
export default FavoriteSplitButton;
