import Select from "./FormSelect";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useFieldList } from "@clearpoint/hooks";


let propTypes = {
	addFlag: PropTypes.bool,
	multipleFlag: PropTypes.bool,
	fieldList: PropTypes.array,
};
let FormSelectField = ({
	addFlag,
	fieldList: fieldListProp,
	name,
	multipleFlag,
	object,
	onChange,
	parentName,
	updateFlag,
	...props
}) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { clearFormValue } = useFormContext();

	let tagList = get({ object: "tag" });
	let tagListEmptyFlag = useMemo(() => {
		let activeTagList = tagList?.filter((x) => x.active);
		return activeTagList && activeTagList.length === 0;
	}, [tagList]);

	let fieldList = useFieldList({ addFlag, multipleFlag, object: fieldListProp ? null : object, updateFlag });
	fieldList = fieldListProp || fieldList;
	if (tagListEmptyFlag) {
		fieldList = fieldList.filter((x) => x.value !== "tags");
	}
	let optionalFieldList = useMemo(() => fieldList.filter((x) => !x.required), [fieldList]);
	if (addFlag) fieldList = optionalFieldList;

	let selectedFieldList = useFormValue(name);
	let handleChange = useCallback(
		(selectValue) => {
			if (selectValue === undefined) return;
			let valueList = selectValue?.map((x) => x.value) || [];
			let removedField = selectedFieldList?.find((x) => !valueList.includes(x));
			if (removedField) {
				clearFormValue(`${parentName ? parentName + "." : ""}${removedField}`);
				let linkedFieldListLookup = {
					completedDated: ["completed"],
					seriesDataTypeId: ["currency", "numberFormatId"],
					seriesType: [
						"calculation",
						"aggregateScorecardType",
						"aggregateScorecards",
						"aggregateObject",
						"aggregateMeasureType",
						"aggregateMeasureName",
						"aggregateName",
						"aggregateType",
						"aggregateNulls",
						"statusScorecardType",
						"statusObjects",
						"statusType",
						"statusSeriesName",
						"statusStatus",
						"statusScorecards",
						"referenceObject",
						"referenceObjectId",
						"referenceFieldId",
						"peerGroupId",
						"peerDistrictId",
						"peerMeasureId",
						"peerMeasureSeriesId",
					],
					showLegend: ["legendPosition"],
					yAxisFormat: ["yAxisCustom"],
					yAxis2Format: ["yAxis2Custom"],
					periodDisplay: ["periodCount"],
					enablePlotBands: ["plotBands"],
				};
				let linkedFieldList = linkedFieldListLookup[removedField];
				if (linkedFieldList) {
					for (let linkedField of linkedFieldList) {
						clearFormValue(`${parentName ? parentName + "." : ""}${linkedField}`);
					}
				}
			}
			if (onChange) onChange(selectValue);
		},
		[clearFormValue, onChange, parentName, selectedFieldList]
	);
	return (
		<Select
			data-testid={`${object}-${updateFlag ? "update" : "edit"}-field`}
			options={fieldList}
			multipleFlag={multipleFlag}
			label={translate("edit.element.selectFields")}
			loadingFlag={!fieldList}
			name={name}
			onChange={handleChange}
			placeholder={translate("edit.element.selectFields") + "..."}
			{...props}
		/>
	);
};
FormSelectField.propTypes = propTypes;
export default FormSelectField;
