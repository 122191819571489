import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { idealTextColor } from "@clearpoint/utils";

let propTypes = {
	children: PropTypes.node,
	statusDisplay: PropTypes.string,
	statusId: PropTypes.number,
};

let CellStyleWrapper = ({ children, statusDisplay, statusId }) => {
	let { get } = useOldQueryStore();

	let statusColor = get({ object: "status", queryString: "?includeNull" })?.find(
		(x) => x.statusId === statusId
	)?.statusColor;
	let statusTextColor = statusId ? idealTextColor(statusColor) : undefined;

	return statusDisplay === "color" ? (
		<StyleWrapper backgroundColor={statusColor + " !important"} color={statusTextColor + " !important"}>
			{children}
		</StyleWrapper>
	) : (
		<>{children}</>
	);
};

CellStyleWrapper.propTypes = propTypes;

export default CellStyleWrapper;
