import ChartJsonModal from "../ChartJsonModal";
import ChartTypeModal from "../ChartTypeModal";
import FormSelectChartType from "@clearpoint/old-theme/Form/Select/Select.ChartType";
import ModalButton from "./ModalButton";
import ModalConfirm from "@clearpoint/old-theme/Modal/Modal.Confirm";
import { useCallback } from "react";
import useChartSelectModalState from "./useChartSelectModalState";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useChartTypeList, usePrevious } from "@clearpoint/hooks";


let propTypes = {
	setChartFormatData: PropTypes.func.isRequired,
};

let ChartTypeSelect = ({ setChartFormatData }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { combinedChartTypeList } = useChartTypeList();
	let { chartType, customJson } = useFormValue();
	let previousChartType = usePrevious(chartType);

	let {
		chartJsonModalVisible,
		chartTypeModalVisible,
		clearJsonConfirmModalVisible,
		closeChartJsonModal,
		closeChartTypeModal,
		closeClearJsonConfirmModal,
		openChartJsonModal,
		openChartTypeModal,
		openClearJsonConfirmModal,
	} = useChartSelectModalState();

	let clearJson = useCallback(() => {
		setFormValue("customSeries", undefined);
		setFormValue("customJson", "");
	}, [setFormValue]);

	let setChartTypeToCustom = useCallback(() => {
		setFormValue("chartType", 0);
	}, [setFormValue]);

	let onChartTypeChange = useCallback(
		(e) => {
			if (e?.value === undefined) return;
			let category = combinedChartTypeList?.find((x) => x.chartTypeId === e.value)?.category;
			if (category) setFormValue("category", category);

			if (e.value === 0) {
				setFormValue("chartType", previousChartType);
				openChartJsonModal();
			} else {
				if (customJson) {
					openClearJsonConfirmModal();
				}
			}
		},
		[
			combinedChartTypeList,
			customJson,
			openChartJsonModal,
			openClearJsonConfirmModal,
			previousChartType,
			setFormValue,
		]
	);

	return (
		<>
			<FormSelectChartType
				label={translate("logFieldLabels.chartType")}
				name="chartType"
				onChange={onChartTypeChange}
				right={<ModalButton openChartJsonModal={openChartJsonModal} openChartTypeModal={openChartTypeModal} />}
			/>
			{chartTypeModalVisible && (
				<ChartTypeModal close={closeChartTypeModal} modalVisible setChartFormatData={setChartFormatData} />
			)}
			{chartJsonModalVisible && (
				<ChartJsonModal close={closeChartJsonModal} previousChartType={previousChartType} modalVisible />
			)}
			<ModalConfirm
				title={translate("edit.chart.clearJson")}
				body={translate("edit.chart.clearJsonConfirm")}
				confirmButtonText={translate("global.clear")}
				confirmButtonColor="warning"
				onCancel={setChartTypeToCustom}
				onConfirm={clearJson}
				modalVisible={clearJsonConfirmModalVisible}
				close={closeClearJsonConfirmModal}
			/>
		</>
	);
};

ChartTypeSelect.propTypes = propTypes;
export default ChartTypeSelect;
