import PropTypes from "prop-types";

import Column from "@clearpoint/old-theme/Column";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormSelectSeriesDataType from "@clearpoint/old-theme/Form/Select/Select.SeriesDataType";
import Help from "@clearpoint/old-theme/Help";
import Row from "@clearpoint/old-theme/Row";

import { useTranslate } from "@clearpoint/translate";

let yAxisControlPropTypes = {
	index: PropTypes.number,
};

let YAxisControls = ({ index }) => {
	index = index ?? "";
	let translate = useTranslate();

	let minValueInForm = useFormValue(`y${index}Min`);
	let maxValueInForm = useFormValue(`y${index}Max`);

	return (
		<FormGroup title={translate(`manage.chart.yAxis${index || "1"}`)}>
			<FormInput name={`y${index}Title`} label={translate(`manage.chart.yAxis${index}Title`)} />
			<Row>
				<Column huge={6}>
					<FormInput
						name={`y${index}Min`}
						label={translate(`manage.chart.yAxis${index}MinValue`)}
						type="number"
						max={maxValueInForm ?? undefined}
					/>
				</Column>
				<Column huge={6}>
					<FormInput
						name={`y${index}Max`}
						label={translate(`manage.chart.yAxis${index}MaxValue`)}
						type="number"
						min={minValueInForm ?? undefined}
					/>
				</Column>
			</Row>
			<FormCheck name={`hideYAxis${index}`}>{translate("global.hidden")}</FormCheck>
			<FormSelectSeriesDataType
				name={`yAxis${index}Format`}
				numberFormat={`yAxis${index}Custom`}
				label={translate(`manage.chart.yAxis${index}Format`)}
				defaultFlag
			/>
		</FormGroup>
	);
};

let ChartAxisFormatTab = () => {
	let translate = useTranslate();
	return (
		<>
			<Help section="sect26" feature="chart-axis" />
			<FormInput name="xTitle" label={translate("manage.chart.xAxisTitle")} />
			<YAxisControls />
			<YAxisControls index={2} />
			<FormCheck name="isReversed">{translate("manage.chart.reverseYAxis")}</FormCheck>
		</>
	);
};

YAxisControls.propTypes = yAxisControlPropTypes;
export default ChartAxisFormatTab;
