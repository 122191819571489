import ChartSeriesDataLabelSelect from "@clearpoint/old-theme/Form/Select/Select.ChartSeriesDataLabel";
import ChartSeriesLineStrokeSelect from "@clearpoint/old-theme/Form/Select/Select.ChartSeriesLineStroke";
import ChartSeriesMarkerSelect from "@clearpoint/old-theme/Form/Select/Select.ChartSeriesMarker";
import ChartSeriesShapeSelect from "@clearpoint/old-theme/Form/Select/Select.ChartSeriesShape";
import Column from "@clearpoint/old-theme/Column";
import ConfirmButton from "@clearpoint/old-theme/Button/Button.Confirm";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormInputColor from "@clearpoint/old-theme/Form/Input/Color/FormInputColor";
import Input from "@clearpoint/old-theme/Form/Input/Input";
import Row from "@clearpoint/old-theme/Row";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	chartSeriesNameInForm: PropTypes.string,
};

let SeriesOptions = ({ chartSeriesNameInForm }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();

	let onResetConfirm = useCallback(() => {
		setFormValue(`${chartSeriesNameInForm}.shape`, "");
		setFormValue(`${chartSeriesNameInForm}.lineWidth`, "");
		setFormValue(`${chartSeriesNameInForm}.dataLabelStyle`, "");
		setFormValue(`${chartSeriesNameInForm}.marker`, "");
		setFormValue(`${chartSeriesNameInForm}.lineStroke`, "");
		setFormValue(`${chartSeriesNameInForm}.color`, "");
		setFormValue(`${chartSeriesNameInForm}.isSecondAxis`, false);
	}, [chartSeriesNameInForm, setFormValue]);

	return (
		<StyleWrapper marginBottom={theme.smallSpace}>
			<FormGroup title={translate("edit.chart.seriesOptions")}>
				<Row>
					<Column small={12} large={4}>
						<ChartSeriesShapeSelect
							name={`${chartSeriesNameInForm}.shape`}
							label={translate("manage.chart.shape")}
						/>
					</Column>
					<Column small={12} large={4}>
						<Input
							name={`${chartSeriesNameInForm}.lineWidth`}
							placeholder={translate("edit.chartSeries.chartDefault")}
							label={translate("edit.chart.lineWidth")}
						/>
					</Column>
					<Column small={12} large={4}>
						<ChartSeriesDataLabelSelect
							name={`${chartSeriesNameInForm}.dataLabelStyle`}
							label={translate("edit.dataLabelStyle")}
						/>
					</Column>
				</Row>
				<Row>
					<Column small={12} large={4}>
						<ChartSeriesMarkerSelect
							name={`${chartSeriesNameInForm}.marker`}
							label={translate("manage.chart.marker")}
						/>
					</Column>
					<Column small={12} large={4}>
						<ChartSeriesLineStrokeSelect
							name={`${chartSeriesNameInForm}.lineStroke`}
							label={translate("edit.chart.lineStroke")}
						/>
					</Column>
					<Column small={12} large={4}>
						<FormInputColor
							label={translate("manage.chart.color")}
							name={`${chartSeriesNameInForm}.color`}
							placeholder={translate("edit.chartSeries.chartDefault")}
						/>
					</Column>
				</Row>
				<Row>
					<Column small={12} large={4}>
						<FormCheck name={`${chartSeriesNameInForm}.isSecondAxis`}>
							{translate("manage.chart.secondAxis")}
						</FormCheck>
					</Column>
					<StyleWrapper marginLeft="auto" marginBottom={theme.space} textAlign="end">
						<Column small={12} large={4}>
							<div data-backdrop="false">
								<ConfirmButton
									title={translate("edit.chart.resetSeriesOptions")}
									body={translate("edit.chart.resetSeriesOptionsText")}
									confirmButtonText={translate("global.ok")}
									confirmButtonColor="warning"
									size="medium"
									outlineFlag
									color="info"
									onConfirm={onResetConfirm}
								>
									{translate("edit.chart.resetSeriesOptions")}
								</ConfirmButton>
							</div>
						</Column>
					</StyleWrapper>
				</Row>
			</FormGroup>
		</StyleWrapper>
	);
};

SeriesOptions.propTypes = propTypes;
export default SeriesOptions;
