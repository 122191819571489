import Loading from "../Loading";
import Modal from "./Modal";
import StoreForm from "../Form/StoreForm/Form.StoreForm";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";

let propTypes = {
	beforeSubmit: PropTypes.func,
	children: PropTypes.node,
	close: PropTypes.func.isRequired,
	copyFlag: PropTypes.bool,
	defaultValue: PropTypes.object,
	modalVisible: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number,
	onSubmit: PropTypes.func,
	periodId: PropTypes.number,
	scorecardId: PropTypes.number,
	size: PropTypes.oneOf(["small", "medium", "large", "huge"]),
};

let ModalEdit = ({
	beforeSubmit,
	children,
	close,
	copyFlag,
	defaultValue,
	modalVisible,
	object,
	objectId,
	onSubmit,
	periodId,
	scorecardId,
	size,
}) => {
	let { get } = useOldQueryStore();
	let loadingFlag = !defaultValue && !!object && !!objectId && get({ object, objectId, periodId }) === undefined;
	return (
		<Modal size={size} modalVisible={modalVisible} close={close}>
			{loadingFlag ? (
				<Loading />
			) : (
				<StoreForm
					beforeSubmit={beforeSubmit}
					copyFlag={copyFlag}
					defaultValue={defaultValue}
					object={object}
					objectId={objectId}
					periodId={periodId}
					scorecardId={scorecardId}
					onSubmit={
						onSubmit
							? (submitValue, apiResponse, contextValue) => {
									onSubmit(submitValue, apiResponse, contextValue);
									close();
							  }
							: close
					}
				>
					{children}
				</StoreForm>
			)}
		</Modal>
	);
};

ModalEdit.propTypes = propTypes;

export default ModalEdit;
