import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { buildQueryString, differentiateKpiChartPods } from "@clearpoint/utils";

let useLayoutData = ({ element, layoutId, object, objectId, periodId }) => {
	let { get } = useOldQueryStore();
	let scorecardFlag = object === "scorecard";

	layoutId =
		layoutId ||
		(scorecardFlag
			? element?.home?.referrer
				? element?.home?.referrer.layoutId
				: element?.home?.layoutId
			: undefined);
	let layoutQuery = useMemo(() => {
		if (scorecardFlag) {
			if (periodId && layoutId) {
				return {
					object: "dashboardLayout",
					objectId: layoutId,
					queryString: buildQueryString({ periodId }),
				};
			}
		} else {
			if (object && objectId) {
				return {
					object: "detailLayout",
					queryString: buildQueryString({ object, objectId }),
				};
			}
		}
	}, [layoutId, object, objectId, periodId, scorecardFlag]);

	let layout = layoutQuery && get(layoutQuery);

	return {
		layout: differentiateKpiChartPods(layout),
		layoutQuery,
	};
};

export default useLayoutData;
