import Alert from "./Alert";
import Block from "../Block";
import Button from "../Button/Button";
import HTML from "../HTML";
import Hide from "../Hide";
import Icon from "../Icon/Icon";
import StyleWrapper from "../StyleWrapper";
import { useState } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useCheckAiAccess, useCheckFeature } from "@clearpoint/hooks";

let StyledIcon = (props) => (
	<StyleWrapper
		cursor="pointer"
		transition="filter 150ms"
		$style={`&:hover {
    filter: brightness(0.5);
}`}
	>
		<Icon {...props} />
	</StyleWrapper>
);

let helpLinkLookup = {
	accessGroups: "managing-user-groups",
	advancedCharting: "adding-trendlines-to-charts",
	aggregateSeries: "types-of-calculations",
	apiKeys: "getting-started-clearpoint-api",
	dataHTML: "using-html-data-fields-reference-information-clearpoint",
	embedCodes: "embedding-charts-from-clearpoint",
	initiativeEvaluation: "project-evaluations",
	integratedLogins: "enabling-integrated-logins",
	menuCustomization: "managing-menu-and-element-names",
	notifications: "notifications-for-your-reporting-process",
	objectiveEvaluation: "setting-objective-evaluations",
	peerSharing: "peer-to-peer-sharing",
	referenceSeries: "types-of-calculations",
	reportFilters: "filtering-summary-reports",
	scheduling: "automating-your-reporting-process",
	userGroups: "managing-user-groups",
	webhooks: "webhooks-care-package",
	workflows: "reporting-workflows",
};
let AlertUpsell = ({ feature, featureList, subFeature }) => {
	let translate = useTranslate();
	let { aiUpsellFlag } = useCheckAiAccess();
	let [expandedFlag, setExpandedFlag] = useState();
	let checkFeature = useCheckFeature();
	let aiFlag = feature === "ai";

	let upsellAlertVisible;
	if (featureList) {
		upsellAlertVisible = !featureList.every((x) => checkFeature(x));
	} else if (aiFlag) {
		upsellAlertVisible = aiUpsellFlag || checkFeature(feature);
	} else {
		upsellAlertVisible = !checkFeature(feature);
	}

	let helpLink = helpLinkLookup[feature];
	if (feature === "workflows") feature = "workflowOverview";
	let featureText = subFeature
		? translate(`upsell.${feature}.subFeatureDescription`)
		: translate(`upsell.${feature}.description`);
	return (
		<Hide visible={upsellAlertVisible}>
			<Alert className="js-upsell-alert" color="success">
				<Block display="flex" justifyContent="space-between">
					<Block fontWeight="bold">{translate("upsell.clearPointCanDoMore")}</Block>
					{!aiFlag && (
						<Hide visible={!expandedFlag}>
							<StyleWrapper margin="-3px">
								<Button size="tiny" className="js-expand" onClick={() => setExpandedFlag(true)}>
									{translate("upsell.howClearPointCanHelp")}
								</Button>
							</StyleWrapper>
						</Hide>
					)}
					{!aiFlag && (
						<Hide visible={expandedFlag}>
							<StyledIcon className="js-collapse" onClick={() => setExpandedFlag(false)} name="close" />
						</Hide>
					)}
				</Block>
				<Hide visible={aiFlag || expandedFlag}>
					<Block className="js-upsell-text" margin={`${theme.smallSpace} 0px`}>
						<HTML>{featureText}</HTML>
						{` ${translate("upsell.pleaseContact")}`}
						&nbsp;<a href="mailto:support@clearpointstrategy.com">support@clearpointstrategy.com</a>&nbsp;
						{translate("upsell.forMoreInformation")}
					</Block>
					<Block textAlign="right">
						<StyleWrapper marginRight={theme.smallSpace}>
							<Button
								color="primary"
								size="tiny"
								href="https://www.clearpointstrategy.com/pricing/"
								target="_plans"
							>
								{translate("upsell.viewPlans")}
							</Button>
						</StyleWrapper>
						<Button
							color="primary"
							size="tiny"
							href={
								aiFlag
									? "https://www.clearpointstrategy.com/platform/ai"
									: `https://www.clearpointstrategy.com/support/${helpLink}/`
							}
							target="_support"
						>
							{translate("upsell.learnMore")}
						</Button>
					</Block>
				</Hide>
			</Alert>
		</Hide>
	);
};

AlertUpsell.propTypes = {
	feature: PropTypes.string,
	featureList: PropTypes.array,
	subFeature: PropTypes.string,
};

export default AlertUpsell;
export { helpLinkLookup };
