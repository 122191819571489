import { useEffect, useMemo, useState } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import createThreadedDiscussionList from "./useDiscussionList.createThreadedDiscussionList";
import isEqual from "lodash/isEqual";
import { buildQueryString } from "@clearpoint/utils";
import { usePrevious } from "@clearpoint/hooks";

let useDiscussionList = ({ object, objectId, layoutId, showResolvedCommentsFlag, testFlag }) => {
	let { get } = useOldQueryStore();

	let [currentObject, setCurrentObject] = useState(null);
	useEffect(() => {
		if (object && (layoutId || objectId)) {
			setCurrentObject(`${object}-${objectId || layoutId}`); // force rerender when objectId or layoutId changes
		}
	}, [layoutId, object, objectId]);

	let discussionList =
		object &&
		(objectId || layoutId) &&
		get({
			object: "comment",
			queryString: buildQueryString({
				include: showResolvedCommentsFlag ? "all" : undefined,
				layoutId,
				object,
				objectId,
			}),
			mockDataFlag: testFlag,
		});

	let filteredDiscussionList = useMemo(
		() =>
			discussionList
				?.filter?.((x) => !x.deleted)
				.map((item) => {
					return {
						...item,
						comment: item.comment.replace(/\s*\([^)]*\)\s*/g, ""),
					};
				}),
		[discussionList]
	);
	let loadingFlag = (!filteredDiscussionList && (objectId || layoutId)) || !discussionList;

	let threadedDiscussionList = useMemo(() => {
		return createThreadedDiscussionList(filteredDiscussionList);
	}, [filteredDiscussionList]);

	return { loadingFlag, discussionList: filteredDiscussionList, threadedDiscussionList };
};

export default useDiscussionList;
