import getScopedHtmlDisplayStyles from "./useScopedHtmlDisplayStyles.getScopedHtmlDisplayStyles";
import { v1 as uuid } from "uuid";

// "p { color: green }" --> "".html-display-1 p { color: green }"

let useScopedHtmlDisplayStyles = (htmlDisplayValue) => {
	let id = uuid();
	let containerStyleClass = global.testFlag ? `html-display-1` : `html-display-${id}`;
	let scopedHtmlDisplayStyles = getScopedHtmlDisplayStyles(htmlDisplayValue, containerStyleClass);
	return scopedHtmlDisplayStyles;
};

export default useScopedHtmlDisplayStyles;
