import { useCallback } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import { useDetailPageData } from "../DataProvider";

let useClearAllOnSubmit = () => {
	let { clear } = useOldQueryStore();
	let { customFieldQuery, elementQuery, elementStatusQuery, layoutQuery, linkListQuery } = useDetailPageData();

	return useCallback(() => {
		for (const query of [customFieldQuery, elementQuery, elementStatusQuery, layoutQuery, linkListQuery]
			.filter(Boolean)) {
				clear(query);
			}
	}, [clear, customFieldQuery, elementQuery, elementStatusQuery, layoutQuery, linkListQuery]);
};

export default useClearAllOnSubmit;
