import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray } from "@clearpoint/utils";

let SelectPodStyle = (props) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let podStyleList = get({ object: "podStyle" });

	let options = useMemo(() => {
		let activePodStyleList = podStyleList?.filter((x) => x.active) ?? emptyArray;
		let organizationalDefaultPodStyle = activePodStyleList.find((x) => x.defaultStyle);
		let organizationalDefaultStyleOption = organizationalDefaultPodStyle && {
			value: 0,
			label: `${translate("edit.useDefaultStyle")} (${organizationalDefaultPodStyle.name})`,
		};
		let clearPointDefaultOption = {
			value: organizationalDefaultStyleOption ? -1 : 0,
			label: translate("edit.useClearPointDefault"),
		};

		let defaultOptionList = organizationalDefaultPodStyle
			? [organizationalDefaultStyleOption, clearPointDefaultOption]
			: [clearPointDefaultOption];

		return [...defaultOptionList, ...activePodStyleList.map((x) => ({ value: x.podStyleId, label: x.name }))];
	}, [podStyleList, translate]);

	return (
		<FormSelect
			label={translate("edit.customPodStyle")}
			data-testid="select-pod-style"
			options={options}
			{...props}
		/>
	);
};
export default SelectPodStyle;
