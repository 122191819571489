import Block from "@clearpoint/old-theme/Block";
import Discussion from "@clearpoint/old-theme/Discussion/index";
import DashboardList from "./DashboardList";
import Export from "./Export";
import Loading from "@clearpoint/old-theme/Loading/index";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";
import PageControl from "./PageControl";
import Search from "./Toolbar/Search";
import Toolbar from "./Toolbar";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useQueryParameters } from "@clearpoint/hooks";

let propTypes = {
	customFieldFlag: PropTypes.bool,
	layoutId: PropTypes.number,
	object: PropTypes.string,
	ReferenceEditSplitButton: PropTypes.elementType,
	ReferenceFavoriteSplitButton: PropTypes.elementType,
	ReferenceTitle: PropTypes.elementType,
};

let ViewDashboard = ({
	customFieldFlag,
	layoutId,
	object,
	ReferenceEditSplitButton,
	ReferenceFavoriteSplitButton,
	ReferenceTitle,
}) => {
	let { get } = useOldQueryStore();
	let { exportFlag } = useOldSession().session;
	let layout = layoutId && get({ object: `${object}Layout`, objectId: layoutId });
	let loadingFlag = !layout;
	let pageKey = `dashboard${object}${layoutId}page`;
	let { commentId } = useQueryParameters();

	return loadingFlag ? (
		<Loading />
	) : (
		<LocalStateProvider
			customFieldFlag={customFieldFlag}
			discussionVisible={!!commentId}
			layout={layout}
			layoutId={layoutId}
			pageKey={pageKey}
			searchFlag={false}
			ReferenceEditSplitButton={ReferenceEditSplitButton}
			ReferenceFavoriteSplitButton={ReferenceFavoriteSplitButton}
			ReferenceTitle={ReferenceTitle}
		>
			{exportFlag ? (
				<Export />
			) : (
				<Block
					data-testid="view-dashboard-page"
					display="flex"
					flex="1 0 1px"
					height="100%"
					maxWidth="100%"
					overflowX="hidden"
				>
					<Block display="flex" height={!customFieldFlag && "100%"} flexDirection="column" width="100%">
						{!customFieldFlag && <Toolbar />}
						<Block
							flex="1"
							overflowY="auto"
							overflowX="hidden"
							padding={!customFieldFlag && `0 ${theme.mediumLargeSpace}`}
						>
							<DashboardList />
						</Block>
						{customFieldFlag ? <Search /> : <PageControl />}
					</Block>
					{!customFieldFlag && <Discussion object={object} layoutId={layoutId} />}
				</Block>
			)}
		</LocalStateProvider>
	);
};

ViewDashboard.propTypes = propTypes;

export default ViewDashboard;
