import CellWrapper from "../CellWrapper";
import FormFieldDisplayPercentComplete from "@clearpoint/old-theme/Form/Field/Display/PercentComplete";
import FormFieldPercentComplete from "@clearpoint/old-theme/Form/Field/PercentComplete";
import PropTypes from "prop-types";

let propTypes = {
	cell: PropTypes.shape({
		periodId: PropTypes.number,
	}),
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let PercentCompleteCell = (props) => {
	let { cell, editCellFlag, parentName } = props;
	let { periodId } = cell;
	let name = `${parentName}.status.${periodId}.percentComplete`;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? <FormFieldPercentComplete name={name} /> : <FormFieldDisplayPercentComplete name={name} />}
		</CellWrapper>
	);
};
PercentCompleteCell.propTypes = propTypes;

export default PercentCompleteCell;
