import Block from "@clearpoint/old-theme/Block";
import DetailLayoutSaveButton from "./DetailLayoutSaveButton";
import DragAndDropWrapper from "./DragAndDropWrapper";
import LayoutPanel from "./LayoutPanel";
import Loading from "@clearpoint/old-theme/Loading/index";
import MenuPanel from "./MenuPanel";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import PodListProvider from "./PodListProvider";
import useCheckPodLicensed from "./useCheckPodLicensed";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { buildQueryString } from "@clearpoint/utils";
import {
	useCheckDefunctPod,
	useCheckHiddenElement,
	useFilterHiddenDefaultFields,
	useScorecardName,
} from "@clearpoint/hooks";

let propTypes = {
	dashboardLayoutFlag: PropTypes.bool,
	elementName: PropTypes.string,
};

let EditDetailLayoutModalContent = ({ dashboardLayoutFlag, elementName }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { loadingFlag: windowLoadingFlag } = useWindowContext();
	let { layout: areaList, object, objectId, scorecardId: formValueScorecardId } = useFormValue();
	let { scorecardId: sessionScorecardId } = useOldSession().session;
	let scorecardId = dashboardLayoutFlag ? sessionScorecardId : formValueScorecardId;

	let scorecardName = useScorecardName(scorecardId);
	elementName = dashboardLayoutFlag ? scorecardName : elementName;
	object = dashboardLayoutFlag ? "scorecard" : object;

	let podList =
		object &&
		(scorecardId || objectId) &&
		get({
			object: "pod",
			queryString: buildQueryString({ object, objectId: scorecardId || objectId }),
		});
	let filterHiddenDefaultFields = useFilterHiddenDefaultFields();
	let checkDefunctPod = useCheckDefunctPod();
	let checkPodLicensed = useCheckPodLicensed(object);
	let checkHiddenElement = useCheckHiddenElement();
	podList = useMemo(() => {
		let filteredPodList = filterHiddenDefaultFields({ fieldList: podList, object });
		return filteredPodList?.filter((x) => {
			let defunctPodFlag = checkDefunctPod(x);
			let licensedPodFlag = checkPodLicensed(x);
			let hiddenElementFlag = checkHiddenElement(x.fieldId);
			return !defunctPodFlag && licensedPodFlag && !hiddenElementFlag;
		});
	}, [checkDefunctPod, checkHiddenElement, checkPodLicensed, filterHiddenDefaultFields, object, podList]);

	let loadingFlag = !podList || windowLoadingFlag || !areaList;

	let SubmitButton = dashboardLayoutFlag ? undefined : DetailLayoutSaveButton;

	return (
		<>
			<ModalWindowHeader
				title={elementName}
				badgeIcon={object}
				badgeText={`${translate(object)} ${translate("layout.layout")}`}
			/>

			<ModalWindowContent>
				{loadingFlag ? (
					<Block backgroundColor={theme.lighterGray} height="100%" width="100%">
						<Loading />
					</Block>
				) : (
					<PodListProvider podList={podList}>
						<DragAndDropWrapper>
							<MenuPanel />
							<LayoutPanel />
						</DragAndDropWrapper>
					</PodListProvider>
				)}
			</ModalWindowContent>
			<ModalWindowFooter SubmitButton={SubmitButton} />
		</>
	);
};

EditDetailLayoutModalContent.propTypes = propTypes;
export default EditDetailLayoutModalContent;
