import { useCallback } from "react";
import getReceiveResponseMessageHandler from "./useReceiveResponseMessage.getResponseMessageHandler";
import { useOnEvent } from "@clearpoint/hooks";


let useReceiveResponseMessage = ({ chatId, scrollToBottom, setAssistantState }) => {
	let receiveResponseMessage = useCallback(
		(e) => {
			let receiveResponseMessageHandler = getReceiveResponseMessageHandler({
				assistantChatId: chatId,
				scrollToBottom,
				setAssistantState,
			});
			receiveResponseMessageHandler(e);
		},
		[chatId, scrollToBottom, setAssistantState]
	);

	useOnEvent({
		dependencyList: [chatId],
		eventName: "chatBotEvent",
		handlerFunction: receiveResponseMessage,
	});
};

export default useReceiveResponseMessage;
