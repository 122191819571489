import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useCheckAccess, useId } from "@clearpoint/hooks";

import AnalysisCell from "./Analysis";
import AttachmentsCell from "./Attachments";
import CalculatedCell from "./Calculated";
import CategoryCell from "./Category";
import CellWrapper from "./CellWrapper";
import ChartCell from "./Chart";
import CollaboratorsCell from "./Collaborators";
import CompletedCell from "./Completed";
import CompletedDateCell from "./CompletedDate";
import CustomFieldCell from "./CustomField";
import DateTimeCell from "./DateTime";
import DiscussionCell from "./Discussion";
import ElapsedDaysCell from "./ElapsedDays";
import ElapsedPercentCompleteCell from "./ElapsedPercentComplete";
import ElementCell from "./Element";
import EndDateCell from "./EndDate";
import GanttCell from "./Gantt";
import NameCell from "./Name";
import NoAccessCell from "./NoAccess";
import ObjectIdCell from "./ObjectId";
import ObjectiveScoreCell from "./ObjectiveScore/index";
import OwnerCell from "./Owner";
import PercentCompleteCell from "./PercentComplete";
import PeriodCell from "./Period";
import PeriodGroupCell from "./PeriodGroup";
import RecommendationsCell from "./Recommendations";
import ScorecardCell from "./Scorecard";
import SeriesDataCell from "./SeriesData";
import StartDateCell from "./StartDate";
import StatusIconCell from "./StatusIcon";
import TagsCell from "./Tags";
import TodayCell from "./Today";
import TotalDaysCell from "./TotalDays";
import UserCell from "./User";
import VarianceCell from "./Variance";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let cellLookup = {
	analysis: AnalysisCell,
	attachments: AttachmentsCell,
	calculated: CalculatedCell,
	category: CategoryCell,
	chart: ChartCell,
	collaborators: CollaboratorsCell,
	completed: CompletedCell,
	completedDate: CompletedDateCell,
	custom: CustomFieldCell,
	dateTime: DateTimeCell,
	discussion: DiscussionCell,
	elapsedDays: ElapsedDaysCell,
	elapsedPercentComplete: ElapsedPercentCompleteCell,
	endDate: EndDateCell,
	gantt: GanttCell,
	name: NameCell,
	noAccess: NoAccessCell,
	objectId: ObjectIdCell,
	objectiveScore: ObjectiveScoreCell,
	owner: OwnerCell,
	percentComplete: PercentCompleteCell,
	period: PeriodCell,
	periodicity: PeriodGroupCell,
	recommendations: RecommendationsCell,
	scorecard: ScorecardCell,
	seriesData: SeriesDataCell,
	span: () => <td />,
	startDate: StartDateCell,
	status: ElementCell,
	statusIcon: StatusIconCell,
	tags: TagsCell,
	today: TodayCell,
	totalDays: TotalDaysCell,
	user: UserCell,
	variance: VarianceCell,
};
let propTypes = {
	cell: PropTypes.shape({
		access: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		fieldId: PropTypes.string,
		periodId: PropTypes.number,
		template: PropTypes.string,
		scorecardId: PropTypes.number,
	}),
	cellStyle: PropTypes.string,
	columnIndex: PropTypes.number,
};
let CellRouter = ({ cell, cellStyle }) => {
	let id = useId();
	let { setFormValue } = useFormContext();
	let { access, object, objectId, periodId, template, scorecardId } = cell;
	if (template === "comments") template = "analysis";
	let { elementListName } = useLocalState();
	let elementList = useFormValue(elementListName);
	let checkAccess = useCheckAccess();
	let noAccessFlag = access === "No Access";
	if (noAccessFlag) template = template === "status" ? "noAccess" : "span";
	let editCellFlagName = `_editCell${id}`;
	let editCellFlag = useFormValue(editCellFlagName);
	let Component = cellLookup[template] || CellWrapper;
	object = object === "linkedChart" ? "chart" : object;
	let objectIndex = elementList?.findIndex(
		(x) =>
			x !== undefined &&
			x.object === object &&
			x.objectId === objectId &&
			(!x.status || Object.keys(x.status).some((x) => +x === periodId))
	);
	let objectData = elementList?.[objectIndex];
	let approvalsPending = objectData?.approvalsPending?.length > 0;
	let parentName = `${elementListName}[${objectIndex}]`;
	let close = useCallback(() => {
		setFormValue(editCellFlagName, false);
	}, [editCellFlagName, setFormValue]);
	let cellFlag = template === "custom" || template === "analysis" || template === "recommendations";
	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId }) && !approvalsPending;
	let updateAccessFlag = checkAccess({ access, action: "update", scorecardId }) && !approvalsPending;

	return useMemo(() => {
		return objectData || noAccessFlag ? (
			<Component
				cell={cell}
				cellFlag={cellFlag}
				cellStyle={cellStyle}
				close={close}
				editAccessFlag={editAccessFlag}
				editCellFlag={editCellFlag}
				editCellFlagName={editCellFlagName}
				objectData={objectData}
				parentName={parentName}
				periodId={periodId}
				updateAccessFlag={updateAccessFlag}
			/>
		) : (
			<td className="cps-td" data-col-index={cell.columnIndex} />
		);
	}, [Component, cell, cellFlag, cellStyle, close, editAccessFlag, editCellFlag, editCellFlagName, noAccessFlag, objectData, parentName, periodId, updateAccessFlag]);
};
CellRouter.propTypes = propTypes;

export default CellRouter;
