import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectDependency = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				value: 0,
				label: translate("edit.element.linkMilestonesManual"),
			},
			{
				value: 1,
				label: translate("edit.element.linkMilestonesCalculateInitiative"),
			},
			{
				value: 2,
				label: translate("edit.element.linkMilestonesCalculateMilestone"),
			},
		],
		[translate]
	);
	return (
		<Select options={options} placeholder={translate("edit.element.linkMilestonesPlaceholder") + "..."} {...props} />
	);
};

export default SelectDependency;
