import ChartPodHeader from "./ChartPodHeader";
import EditChartModal from "@components/Elements/Shared/ChartsTab/EditChartModal";
import PodChart from "@clearpoint/old-theme/Pod/ChartPod/ChartPod";
import { useCallback } from "react";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePodContext } from "../PodProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import classNames from "classnames";

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	onDoubleClick: PropTypes.func.isRequired,
	overflowX: PropTypes.string,
	podStyle: PropTypes.object,
};

let ChartPod = ({ className, marginTop, onDoubleClick, overflowX, podStyle }) => {
	let { get, clear } = useOldQueryStore();
	let { layoutQuery } = useDetailPageData();
	let { closeEditMode, editPodFlag, fieldId, hideBorderFlag } = usePodContext();
	let { pagePeriodId } = useLocalState();

	let object = useFormValue("object");

	let chartId = +fieldId.replace("chart", "");

	// The chart's measureId may be different from the element's measureId or shadowMeasureId
	let measureId =
		chartId && pagePeriodId && get({ object: "chart", objectId: chartId, periodId: pagePeriodId })?.measureId;

	let close = useCallback(() => {
		closeEditMode();
		clear({ object });
		clear(layoutQuery);
	}, [clear, closeEditMode, layoutQuery, object]);

	return (
		<>
			<PodChart
				chartId={chartId}
				className={classNames(className, "avoid-page-break")}
				HeaderLeftElement={ChartPodHeader}
				hideBorderFlag={hideBorderFlag}
				marginTop={marginTop}
				measureId={measureId}
				onDoubleClick={onDoubleClick}
				overflowX={overflowX}
				periodId={pagePeriodId}
				podStyle={podStyle}
			/>
			{measureId && editPodFlag && (
				<EditChartModal
					measureId={measureId}
					objectId={chartId}
					periodId={pagePeriodId}
					modalWindowVisible
					close={close}
				/>
			)}
		</>
	);
};

ChartPod.propTypes = propTypes;
export default ChartPod;
