import getDeepValue from "lodash/get";

import { useFormContext } from "../Form";

let useDefaultFormValue = (name) => {
	let formContext = useFormContext();
	if (!formContext) return undefined;
	let { formDefaultValue } = formContext;
	if (name) return getDeepValue(formDefaultValue, name);
	return formDefaultValue;
};

export default useDefaultFormValue;
