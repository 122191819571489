import Bar from "./Bar";
import Donut from "./Donut";
import Line from "./Line";
import MeasureChart from "./MeasureChart";
import Metric from "./Metric";
import Pod from "@clearpoint/old-theme/Pod/Pod";
import { usePodContext } from "../PodProvider";
import PropTypes from "prop-types";

let chartLookup = {
	bar: Bar,
	chart: MeasureChart,
	donut: Donut,
	line: Line,
	metric: Metric,
};

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	overflowX: PropTypes.string,
	podStyle: PropTypes.object,
};

let KpiPod = ({ className, marginTop, overflowX, podStyle }) => {
	let { chart, hideBorderFlag, title } = usePodContext();

	let ChartComponent = chartLookup[chart?.chartType];
	return (
		<Pod
			className={className}
			hideBorderFlag={hideBorderFlag}
			marginTop={marginTop}
			overflowX={overflowX}
			podStyle={podStyle}
			headerText={title}
		>
			{ChartComponent && <ChartComponent chart={chart} />}
		</Pod>
	);
};

KpiPod.propTypes = propTypes;
export default KpiPod;
