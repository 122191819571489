import Select from "../../../../Form/Select/FormSelect";
import { useEffect, useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useScorecard } from "@clearpoint/hooks";


let propTypes = {
	fieldId: PropTypes.string,
	object: PropTypes.string,
};
let SelectFilterCompare = ({ fieldId, object, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { setSession } = useOldSession();
	let { scorecardId } = useScorecard();
	let filterFieldList = get({ object: "filterField", queryString: `?scorecardId=${scorecardId}&object=${object}` });
	let compareOptionLookup = useMemo(() => {
		let _after = { label: translate("layout.after"), value: "gt" };
		let _before = { label: translate("layout.before"), value: "lt" };
		let _contains = { label: translate("layout.contains"), value: "in" };
		let _doesNotContain = { label: translate("layout.doesNotContain"), value: "notin" };
		let _doesNotEqual = { label: translate("layout.doesNotEqual"), value: "neq" };
		let _equals = { label: translate("layout.equals"), value: "eq" };
		let _greaterThan = { label: translate("layout.greaterThan"), value: "gt" };
		let _in = { label: translate("layout.equals"), value: "in" };
		let _isBlank = { label: translate("layout.isBlank"), value: "null" };
		let _isFalse = { label: translate("layout.isFalse"), value: "false" };
		let _isNotBlank = { label: translate("layout.isNotBlank"), value: "notnull" };
		let _isTrue = { label: translate("layout.isTrue"), value: "true" };
		let _lessThan = { label: translate("layout.lessThan"), value: "lt" };
		let _lk = { label: translate("layout.contains"), value: "lk" };
		let _nlk = { label: translate("layout.doesNotContain"), value: "nlk" };
		let _notin = { label: translate("layout.doesNotEqual"), value: "notin" };
		let _range = { label: translate("layout.datePlusMinusNDays"), value: "range" };
		return {
			boolean: [_isTrue, _isFalse],
			contains: [_contains, _doesNotContain, _isBlank, _isNotBlank],
			date: [_equals, _doesNotEqual, _before, _after, _range],
			equals: [_in, _notin],
			number: [_equals, _doesNotEqual, _lessThan, _greaterThan, _isBlank, _isNotBlank],
			select: [_in, _notin, _isBlank, _isNotBlank],
			string: [_equals, _doesNotEqual, _lk, _nlk, _isBlank, _isNotBlank],
		};
	}, [translate]);
	let options = useMemo(() => {
		let compare = filterFieldList?.[object]?.find((field) => field.fieldId === fieldId)?.compare;
		let options = compareOptionLookup[compare] || [];
		return options;
	}, [compareOptionLookup, fieldId, filterFieldList, object]);
	useEffect(() => {
		setSession({ filterCompareOptions: options });
	}, [options, setSession]);
	return <Select data-testid={"select-comparison"} options={options} {...props} />;
};
SelectFilterCompare.propTypes = propTypes;

export default SelectFilterCompare;
