let clearLocalStorageExceptKeys = (keys) => {
	let keysSet = new Set(keys);
	let tempMap = new Map();

	for (let key of keysSet) {
		let value = localStorage.getItem(key);
		if (value !== null) {
			tempMap.set(key, value);
		}
	}

	localStorage.clear();

	for (let [key, value] of tempMap) {
		localStorage.setItem(key, value);
	}
};

export default clearLocalStorageExceptKeys;
