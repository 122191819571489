import getDeepValue from "lodash/get";
import http from "@clearpoint/services/httpService/index";
import isEqual from "lodash/isEqual";
import { isNotValidJson, isNotValidJson5, validateEmail } from "@clearpoint/utils";

let isNull = (value) => value === undefined || value === null || value === "";

let validationLookup = {
	date: ({ value }) => isNull(value) || !isNaN(new Date(value).getTime()),
	email: ({ value }) => isNull(value) || validateEmail(value),
	endDate: ({ value, testValue: endDate }) => isNull(value) || new Date(value) <= new Date(endDate),
	json: ({ value }) => isNull(value) || !isNotValidJson(value),
	json5: ({ value }) => isNull(value) || !isNotValidJson5(value),
	match: ({ value, testValue: match }) => (isNull(value) && isNull(match)) || value === match,
	max: ({ value, testValue: max }) => isNull(value) || +value <= +max,
	maxLength: ({ value, testValue: maxLength }) => isNull(value) || value.length <= +maxLength,
	min: ({ value, testValue: min }) => isNull(value) || +value >= +min,
	minLength: ({ value, testValue: minLength }) => isNull(value) || value.length >= +minLength,
	notEndingWithCalcWordFlag: ({ value, setError, name, errorMessageRouter, formDefaultValue }) => {
		let defaultName = getDeepValue(formDefaultValue, name) || "";
		let validate = async (value) => {
			if (isNull(value)) return true;
			return (
				/(average|count|sum)$/.test(value.toLowerCase()) &&
				setError(name, errorMessageRouter.notEndingWithCalcWordFlag())
			);
		};
		if (defaultName?.toLowerCase() !== value?.toLowerCase()) {
			validate(value);
		}
		return true;
	},
	pattern: ({ value, testValue: pattern }) => isNull(value) || new RegExp(pattern).test(value),
	pageBreakValidationFlag: ({ value }) => {
		if (!value) return true;
		if (value.match(/[^0-9,]+/gi) || value.match(/,{2,}/gi)) {
			return false;
		}
		if (value.includes(",")) {
			let breakList = value.split(",");
			for (let i = 1; i < breakList.length; i++) {
				let previousBreak = breakList[i - 1];
				let currentBreak = breakList[i];
				if (currentBreak && parseInt(currentBreak, 10) <= parseInt(previousBreak, 10)) return false;
			}
		}
		return true;
	},
	required: ({ value }) => !isNull(value) && !isEqual(value, []),
	secureHtml: ({ value }) => isNull(value) || !/<embed/.test(value),
	startDate: ({ value, testValue: startDate }) => isNull(value) || new Date(value) >= new Date(startDate),
	uniqueDate: ({ value, setError, name, errorMessageRouter, formDefaultValue }) => {
		let defaultDate = getDeepValue(formDefaultValue, name) || "";
		let validate = async (value) => {
			let response;
			try {
				response = await http.get(`/periods?check=${value}`);
			} catch (error) {
				response = -1;
			} finally {
				if (response !== 0 && defaultDate !== value.slice(0, 10)) {
					setError(name, errorMessageRouter.uniqueDate());
				}
			}
		};
		if (defaultDate !== value.slice(0, 10)) {
			validate(value);
		}
		return true;
	},
	uniqueEmail: ({ value, setError, name, errorMessageRouter, formDefaultValue }) => {
		let defaultEmail = getDeepValue(formDefaultValue, name) || "";
		let validate = async (value) => {
			let response = await http.get(`/users?check=${value}`);
			if (response === true) {
				if (defaultEmail !== value) {
					setError(name, errorMessageRouter.uniqueEmail());
				}
			}
		};
		if (defaultEmail !== value) {
			validate(value);
		}
		return true;
	},
	uniqueHostname: ({ value, setError, name, errorMessageRouter, formDefaultValue }) => {
		let defaultHostname = getDeepValue(formDefaultValue, name) || "";
		let validate = async (value) => {
			let response = await http.debouncedGet(`/options?check=${value}`);
			if (!response?.available) {
				setError(name, errorMessageRouter.uniqueHostname());
			}
		};
		if (value && defaultHostname !== value) {
			validate(value);
		}
		return true;
	},
	uniqueDashboardLink: ({ value, setError, name, errorMessageRouter, testValue }) => {
		if (value && testValue.some((link) => link === value.trim())) {
			setTimeout(() => {
				setError(name, errorMessageRouter.uniqueDashboardLink());
			}, 100);
		}
		return true;
	},
	uniqueEmailDebounce: ({ value, setError, name, errorMessageRouter, formDefaultValue }) => {
		let defaultEmail = getDeepValue(formDefaultValue, name) || "";
		let validate = async (value) => {
			let response = await http.debouncedGet(`/users?check=${value}`);
			if (response === true) {
				if (defaultEmail !== value) {
					setError(name, errorMessageRouter.uniqueEmail());
				}
			}
		};
		if (defaultEmail !== value) {
			validate(value);
		}
		return true;
	},
	url: ({ value }) =>
		isNull(value) ||
		/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i.test(
			value
		),
};

export default validationLookup;
