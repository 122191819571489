import { useCallback } from "react";
import useConvertDateStringToDateObject from "./useConvertDateStringToDateObject";
import useDateConfig from "./useDateConfig";
import { useOldSession } from "@clearpoint/old-session/index";
import formatDate from "./useFormatDate.formatDate";
import { noop } from "@clearpoint/utils";

export default function useFormatDate() {
	let { session } = useOldSession();
	let { dateConfig } = useDateConfig();
	let convertDateStringToDateObject = useConvertDateStringToDateObject();
	let formatDateCallback = useCallback(
		(date, dateFormatType, ignoreTimezoneFlag = false) => {
			let formatDateCallback = formatDate({ convertDateStringToDateObject, dateConfig, session });
			return formatDateCallback(date, dateFormatType, ignoreTimezoneFlag);
		},
		[convertDateStringToDateObject, dateConfig, session]
	);
	return !dateConfig ? noop : formatDateCallback;
}
