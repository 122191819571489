import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import TemplateTile from "./TemplateTile";
import useTemplateList from "./useTemplateList";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	close: PropTypes.func.isRequired,
	fieldId: PropTypes.oneOf(["analysis", "recommendations"]).isRequired,
	outerFormObject: PropTypes.string.isRequired,
	periodLockedFlag: PropTypes.bool,
	setTemplate: PropTypes.func.isRequired,
};

let TemplateChooser = ({ close, fieldId, outerFormObject, periodLockedFlag, setTemplate }) => {
	let translate = useTranslate();
	let templateList = useTemplateList({ fieldId, outerFormObject, periodLockedFlag });

	return (
		<>
			<ModalHeader>
				<ModalTitle>{translate("global.aiAssistant")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Block padding={`${theme.tinySpace} ${theme.smallSpace}`}>
					<p>{translate("ai.assistant.selectTemplate")}</p>
					<Block
						display="flex"
						justifyContent="space-around"
						alignItems="center"
						marginTop={theme.mediumSpace}
						flexWrap="wrap"
					>
						{templateList.map(({ description, icon, name, stateKey }) => (
							<TemplateTile
								key={icon}
								description={description}
								icon={icon}
								setTemplate={setTemplate}
								stateKey={stateKey}
								templateName={name}
							/>
						))}
					</Block>
				</Block>
			</ModalBody>
			<ModalFooter>
				<Button onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
			</ModalFooter>
		</>
	);
};

TemplateChooser.propTypes = propTypes;
export default TemplateChooser;
