const nonPlural = [
	"chartJson",
	"comment",
	"count",
	"dataLoader",
	"evaluation",
	"filter",
	"ganttData",
	"language",
	"measureGrid",
	"menu",
	"myScorecard",
	"preview",
	"profile",
	"recalc",
	"search",
	"tfa",
	"timezone",
	"update",
	"upload",
	"measureData",
];

export default function getRoute(object) {
	if (object === "scorecardLayout") {
		return "dashboardLayouts";
	} else if (object.endsWith?.("Layout") && !object.startsWith?.("dashboard") && !object.startsWith?.("detail")) {
		return "gridLayouts";
	} else if (nonPlural.includes(object) || object.slice(-1) === "s") {
		return object;
	} else if (["ay", "ey", "iy", "oy", "uy"].includes(object.slice(-2))) {
		return object + "s";
	} else if (object.slice(-1) === "y") {
		return object.slice(0, -1) + "ies";
	} else {
		return object + "s";
	}
}
