import ChartPod from "../ChartPod";
import CustomFieldPod from "../CustomFieldPod";
import DefaultPod from "../DefaultPod";
import GanttPod from "../GanttPod";
import GridReportPod from "../GridReportPod";
import KpiPod from "../KpiPod";
import LinksPod from "../LinksPod";
import MeasureGridPod from "../MeasureGridPod";
import { usePodContext } from "../PodProvider";

let podLookup = {
	chart: ChartPod,
	chartData: MeasureGridPod,
	custom: CustomFieldPod,
	gantt: GanttPod,
	gridReport: GridReportPod,
	kpi: KpiPod,
	linkedElements: LinksPod,
};

let usePodLookup = ({ fieldId, template }) => {
	let { chartPodFlag, customFieldFlag, gridReportFlag } = usePodContext();

	let podType = fieldId;
	if (chartPodFlag) {
		podType = "chart";
	} else if (gridReportFlag) {
		podType = "gridReport";
	} else if (customFieldFlag) {
		podType = "custom";
	} else if (template === "linkedElements") {
		podType = "linkedElements";
	}

	return podLookup[podType] || DefaultPod;
};

export default usePodLookup;
