import BriefingBookNavigation from "@components/Print/BriefingBook/Navigation";
import HeaderDisplayStatus from "../Toolbar/Header/HeaderDisplayStatus";
import HeaderDisplayTitle from "../Toolbar/Header/HeaderDisplayTitle";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { theme } from "@clearpoint/old-theme/Theme";

let PrintTitle = () => {
	let { object } = useFormValue();
	let { briefingBookFlag } = useOldSession().session;
	let scorecardFlag = object === "scorecard";

	return (
		<StyleWrapper marginBottom={theme.space} width="100%">
			<table className="report-title no-table-pdf-tag avoid-page-break-after">
				<tbody>
					<tr>
						{!scorecardFlag && (
							<StyleWrapper width="41px">
								<td>
									<HeaderDisplayStatus />
								</td>
							</StyleWrapper>
						)}
						<td>
							<HeaderDisplayTitle />
						</td>
						{briefingBookFlag && (
							<td>
								<BriefingBookNavigation />
							</td>
						)}
					</tr>
				</tbody>
			</table>
		</StyleWrapper>
	);
};

export default PrintTitle;
