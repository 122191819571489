import PaginationSelect from "@clearpoint/old-theme/Pagination/Select";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	value: PropTypes.number,
};
let SelectRowCount = ({ value, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: 10, label: translate("global.rowsPerPage", { rowCount: "10+" }) },
			{ value: 25, label: translate("global.rowsPerPage", { rowCount: "25+" }) },
			{ value: 50, label: translate("global.rowsPerPage", { rowCount: "50+" }) },
			{ value: 100, label: translate("global.rowsPerPage", { rowCount: "100+" }) },
			{ value: 999, label: translate("search.displayAllRows") },
		],
		[translate]
	);
	value = options.find((x) => x.value === value);
	return <PaginationSelect options={options} value={value} {...props} />;
};
SelectRowCount.propTypes = propTypes;
export default SelectRowCount;
