import PropTypes from "prop-types";
import styled from "styled-components";

import Select from "../Select/Select";

let StyledSelect = styled(Select)`
	min-width: ${(props) => props.$minWidth || "225px"};
	margin-left: 10px;
	.select__control {
		margin-top: -3px;
		border: none !important;
		background: none !important;
		height: 28px !important;
		min-height: 28px !important;
		padding: 0px;
	}
	.select__indicator {
		padding: 10px 10px 10px 0 !important;
	}
	* {
		background-color: transparent !important;
		color: #676767 !important;
	}
	*:active {
		color: #000000 !important;
	}
`;

let propTypes = {
	minWidth: PropTypes.string,
};
let PaginationSelect = ({ minWidth, ...props }) => {
	return <StyledSelect $minWidth={minWidth} noFacadeFlag menuPlacement="top" {...props} />;
};
PaginationSelect.propTypes = propTypes;
export default PaginationSelect;
