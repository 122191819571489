import CellWrapper from "../CellWrapper";
import Gantt from "@clearpoint/old-theme/Gantt/Gantt";
import Loading from "@clearpoint/old-theme/Loading/index";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import { useGanttData } from "@clearpoint/hooks";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let GanttCell = (props) => {
	let { exportFlag } = useOldSession().session;
	let { object, objectId, periodId, scorecardId } = props.cell;
	let ganttData = useGanttData({ object, objectId, periodId, scorecardId });
	return (
		<CellWrapper {...props}>
			{!ganttData ? (
				<Loading />
			) : (
				<StyleWrapper
					$style={
						exportFlag
							? ".gantt_container, .gantt_layout_x {  display: flex; height: 100% !important; width: 100% !important; }; .gantt_container > *, .gantt_layout_x > * { flex-shrink: 0; }"
							: undefined
					}
				>
					<Gantt autosize readOnlyFlag value={ganttData} />
				</StyleWrapper>
			)}
		</CellWrapper>
	);
};
GanttCell.propTypes = propTypes;

export default GanttCell;
