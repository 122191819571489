import ImageAttachment from "./ImageAttachment";
import PDFAttachment from "./PDFAttachment";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";
import { buildQueryString } from "@clearpoint/utils";
import { useQueryParameters } from "@clearpoint/hooks";



let propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
};

let Attachments = ({ height, width }) => {
	let { get } = useOldQueryStore();
	let { layoutId, object, objectId, periodId } = useQueryParameters();
	let attachmentList =
		(objectId || layoutId) &&
		periodId &&
		get({
			object: "attachment",
			queryString: buildQueryString(layoutId ? { layoutId, object, periodId } : { object, objectId, periodId }),
		});
	height = Math.floor(height);
	width = Math.floor(width);

	return (
		isArray(attachmentList) &&
		attachmentList
			?.filter((x) => x.includeInBook)
			?.map((x) =>
				x.filename.endsWith("pdf") ? (
					<PDFAttachment key={x.attachmentId} attachment={x} height={height} width={width} />
				) : (
					<ImageAttachment key={x.attachmentId} attachment={x} height={height} width={width} />
				)
			)
	);
};

Attachments.propTypes = propTypes;

export default Attachments;
