/* eslint-disable complexity */
import { useEffect } from "react";
import PropTypes from "prop-types";
import { StyleSheetManager } from "styled-components";

import Block from "@clearpoint/old-theme/Block";

import { PrintProvider,useEffectOnce, useMeasure, useQueryParameters, useSentryContext } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";
import { luceeAxios, setAxiosProperty } from "@clearpoint/services/axiosService";

import Attachments from "./Attachments";
import BriefingBookFooter from "./BriefingBook/Footer";
import BriefingBookHeader from "./BriefingBook/Header";
import BriefingBookWatermark from "./BriefingBook/Watermark";
import Footer from "./Footer";
import getId from "./getId";
import HTMLMenu from "./HTML/Menu";
import PrintStyle from "./Style";
import useSetBodyLoaded from "./useSetBodyLoaded";
import { useOldSession } from "@clearpoint/old-session/index";

let pageSizeLookup = {
	a4: {
		width: 1310,
		height: 896,
		ganttRowCount: {
			landscape: 29,
			portrait: 44,
		},
		seriesGridColumnCount: {
			landscape: 10,
			portrait: 5,
		},
	},
	legal: {
		width: 1585,
		height: 926,
		ganttRowCount: {
			landscape: 30,
			portrait: 54,
		},
		seriesGridColumnCount: {
			landscape: 12,
			portrait: 6,
		},
	},
	letter: {
		width: 1226,
		height: 926,
		ganttRowCount: {
			landscape: 30,
			portrait: 41,
		},
		seriesGridColumnCount: {
			landscape: 9,
			portrait: 6,
		},
	},
	tabloid: {
		width: 1945,
		height: 1226,
		ganttRowCount: {
			landscape: 41,
			portrait: 68,
		},
		seriesGridColumnCount: {
			landscape: 15,
			portrait: 9,
		},
	},
};

const MILLIMETER_TO_PIXEL_CONVERSION = 3.78;
const PIXEL_TO_MILLIMETER_CONVERSION = 1 / MILLIMETER_TO_PIXEL_CONVERSION;

let propTypes = {
	children: PropTypes.node,
};

let Print = ({ children }) => {
	let { get } = useOldQueryStore();
	let { session, setSession } = useOldSession();
	let { token, sessionId, exportFlag } = session;
	let sessionLoadedFlag = token && sessionId && exportFlag;
	if (!luceeAxios.defaults.headers.common["Authorization"]) {
		setAxiosProperty('defaults.headers.common["Authorization"]', token);
		setAxiosProperty('defaults.headers.common["SessionId"]', sessionId);
	}

	useSetBodyLoaded();

	useSentryContext(sessionLoadedFlag);
	let {
		format,
		layoutId,
		object,
		objectId,
		orientation = "landscape",
		pageSize = "letter",
		reportId,
		restrictedContent,
		stylePreview,
		tableOfContents,
		viewportWidth,
		zoom,
	} = useQueryParameters();
	let { height: reportTitleHeight } = useMeasure({ query: ".report-title" }) || emptyObject;
	let { height: footerHeight } = useMeasure({ query: "footer" }) || emptyObject;
	let { height: headerHeight } = useMeasure({ query: "header" }) || emptyObject;
	let briefingBookFlag = !!reportId;
	let stylePreviewFlag = !!stylePreview;
	let restrictedContentFlag = !!restrictedContent;
	let marginTop = 12;
	let marginBottom = briefingBookFlag ? 12 : pageSize?.toLowerCase() === "legal" ? 20 : 17;
	let homeFlag = false;
	let reportLoadingFlag, htmlMenuFlag;
	if (reportId && sessionLoadedFlag) {
		let report = get({ object: "report", objectId: reportId });
		reportLoadingFlag = !report;
		if (report?.topMargin) marginTop = report.topMargin;
		if (report?.bottomMargin) marginBottom = report.bottomMargin;
		let homepage = report?.contents?.[0]?.pages[0];
		if (report?.toc && tableOfContents) homeFlag = true;
		else if (homepage)
			homeFlag = homepage.object === object && (homepage.objectId === objectId || homepage.layoutId === layoutId);
		if (report?.format === "html") homeFlag = false;
		if (!format) format = report?.format;
		briefingBookFlag = report?.format !== "html";
		if (format === "html" && report?.toc) {
			htmlMenuFlag = true;
		}
		document.title = report?.name;
	}
	orientation = orientation?.toLowerCase();
	let page = pageSizeLookup[pageSize?.toLowerCase()];
	let ganttRowCount = page?.ganttRowCount[orientation];
	let seriesGridColumnCount = page?.seriesGridColumnCount[orientation];
	let width = page?.width || 1226;
	let height = page?.height || 926;
	if (orientation === "portrait") {
		[width, height] = [height, width];
	}
	let apiHeight = height;
	let apiWidth = width;
	if (marginTop) {
		let headerHeightInMillimeters = headerHeight * PIXEL_TO_MILLIMETER_CONVERSION;
		if (headerHeightInMillimeters > marginTop) {
			marginTop = headerHeightInMillimeters;
		}
		height -= marginTop * MILLIMETER_TO_PIXEL_CONVERSION;
	}
	if (marginBottom) {
		let footerHeightInMillimeters = footerHeight * PIXEL_TO_MILLIMETER_CONVERSION;
		if (footerHeightInMillimeters > marginBottom) {
			marginBottom = footerHeightInMillimeters;
		}
		height -= marginBottom * MILLIMETER_TO_PIXEL_CONVERSION;
	}
	let ganttTitleRenderedFlag;
	let ganttRowHeight = 26;
	if (zoom && zoom !== 1) {
		ganttRowHeight = zoom > 1 ? ganttRowHeight * zoom : ganttRowHeight / Math.abs(zoom);
		zoom *= zoom; // to match zooming from old version
		width = Number.parseInt(width / zoom, 10);
		height = Number.parseInt(height / zoom, 10);
		ganttTitleRenderedFlag = !!reportTitleHeight;
	} else {
		ganttTitleRenderedFlag = true;
	}

	ganttRowCount = Math.max(Math.floor(height / ganttRowHeight) - Math.ceil(reportTitleHeight / ganttRowHeight) - 1, 1); // minus 1 to allow extra vertical space for the Gantt column headers

	let excelFlag = format === "excel";
	let htmlExportFlag = format === "html";
	let pdfFlag = format === "pdf" || format === "page";
	useEffectOnce(() => {
		document.cookie = `SESSIONID=${sessionId}; SameSite=None; Secure`;
		document.cookie = `token=${token}; SameSite=None; Secure`;
		setTimeout(() =>
			setSession({
				briefingBookFlag,
				exportFlag: true,
				exportApiHeight: apiHeight,
				exportApiWidth: apiWidth,
				exportHeight: height,
				exportWidth: width,
				htmlExportFlag,
				ganttTitleRenderedFlag,
				rowCount: ganttRowCount,
				seriesGridMaxColumnCount: seriesGridColumnCount,
				excelFlag,
			})
		);
	});

	useEffect(() => {
		if (session.rowCount !== ganttRowCount || briefingBookFlag !== session.briefingBookFlag) {
			setSession({ briefingBookFlag, ganttTitleRenderedFlag, rowCount: ganttRowCount });
		}
	}, [briefingBookFlag, ganttRowCount, ganttTitleRenderedFlag, session, setSession]);
	let id = object ? getId({ object, objectId, layoutId }) : undefined;

	// render attachments checks
	let dashboardReportType =
		!!layoutId && object === "scorecard" && get({ object: "dashboardLayout", objectId: layoutId })?.reportType;
	let detailPageFlag = !!layoutId ? dashboardReportType === "detail" : true;
	let renderAttachmentsFlag =
		pdfFlag && detailPageFlag && !stylePreviewFlag && !tableOfContents && !restrictedContentFlag;
	if (htmlExportFlag) width = viewportWidth || 1024;
	return sessionLoadedFlag && !reportLoadingFlag ? (
		<StyleSheetManager disableCSSOMInjection>
			<div>
				<PrintStyle
					htmlExportFlag={htmlExportFlag}
					marginTop={marginTop + "mm"}
					marginBottom={marginBottom + "mm"}
					orientation={orientation}
					pageSize={pageSize}
					zoom={zoom}
					width={width}
				/>
				{/* DocRaptor only requires us to render the header and footer only once -- 
				keep an eye on this to see if anything breaks now that we're rendering these multiple times  */}
				{/* These must be at the top of the page for docraptor to register them */}
				{(briefingBookFlag || stylePreviewFlag) && <BriefingBookHeader />}
				{(briefingBookFlag || stylePreviewFlag) && <BriefingBookFooter />}
				{format === "page" && <Footer />}
				{(homeFlag || stylePreviewFlag) && (
					<BriefingBookWatermark zoom={zoom} stylePreviewFlag={stylePreviewFlag} />
				)}
				{htmlMenuFlag && <HTMLMenu />}
				<PrintProvider>
					<Block
						className={htmlExportFlag ? undefined : "print page-break"}
						id={id}
						width={`${width}px`}
						height={object === "map" ? `${height}px` : undefined}
						padding={htmlExportFlag ? "6px 8px" : undefined}
						overflowX={htmlExportFlag ? "auto" : undefined}
					>
						{children}
					</Block>
				</PrintProvider>
				{renderAttachmentsFlag && <Attachments height={height} width={width} />}
				{/* Double clear and break because float is bad but we need it for now */}
				<Block $style="clear: both !important; break-after: always"></Block>
				<Block $style="page-break-after: always; display: block!important; break-after: page;">&nbsp;</Block>
			</div>
		</StyleSheetManager>
	) : null;
};

Print.propTypes = propTypes;

export default Print;
