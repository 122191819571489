let defaultSeries = {
	shape: "",
	marker: "",
	color: null,
	dataLabelStyle: "",
	name: undefined,
	lineStroke: "",
	lineWidth: "",
	isSecondAxis: false,
	regressionModel: "",
	periodOffset: 0,
	measureSeriesId: undefined,
	active: undefined,
	seriesType: "standard",
	sortOrder: undefined,
};

export default defaultSeries;
