import Button from "../Button/Button";
import Form from "../Form/Form";
import FormButton from "../Form/Button/Button";
import FormCheck from "../Form/Check/Form.Check";
import FormInput from "../Form/Input/Input";
import FormInputTextEditor from "../Form/Input/TextEditor/Input.TextEditor";
import FormSelectPageOrientation from "../Form/Select/Select.PageOrientation";
import FormSelectPageSize from "../Form/Select/Select.PageSize";
import FormSelectRecipient from "../Form/Select/Select.Recipient";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import { useCallback } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { buildQueryString, validateEmail } from "@clearpoint/utils";

let propTypes = {
	close: PropTypes.func.isRequired,
	layoutId: PropTypes.number,
	modalVisible: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number,
	title: PropTypes.string,
};

let EmailPageModal = ({ close, layoutId, modalVisible, object, objectId, title }) => {
	let translate = useTranslate();
	let { get, set } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId } = session;
	let userList = get({ object: "user" });
	let onSubmit = useCallback(
		(submitValue) => {
			let { body, ccSender, pageSize, orientation, recipients, subject } = submitValue;
			if (recipients.every((x) => !validateEmail(x))) {
				toast.error(translate("toaster.messages.global.invalidRecipientList"));
				return;
			}
			periodId &&
				set({
					object: layoutId ? `${object}Layout` : "detailLayout",
					objectId: layoutId || undefined,
					queryString: buildQueryString(
						layoutId
							? {
									periodId,
									pageSize,
									orientation,
									format: "pdf",
							  }
							: { object, objectId, periodId, pageSize, orientation, format: "pdf" }
					),
					data: {
						body,
						ccSender: ccSender || false,
						recipients: recipients.map((email) => {
							let user = userList.find((x) => x.email === email);
							return user
								? {
										email: user.notificationEmail || user.email,
										fullName: user.fullName || email,
								  }
								: {
										email,
										fullName: translate("select.externalEmail", { email }),
								  };
						}),
						subject,
					},
					method: "put",
				}).then(() => {
					toast.success(translate("toaster.messages.global.emailSent"));
					if (close) close();
				});
		},
		[close, layoutId, object, objectId, periodId, set, translate, userList]
	);
	return (
		<Modal modalVisible={modalVisible} close={close} size="large">
			<Form onSubmit={onSubmit} submitUnchangedFlag>
				<ModalHeader>
					<ModalTitle>{translate("emailPage.emailPage")}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormSelectRecipient
						createableFlag
						label={translate("emailPage.recipients")}
						multipleFlag
						name="recipients"
						placeholder={translate("edit.selectRecipients")}
						required
					/>
					<FormInput
						label={translate("emailPage.subject")}
						name="subject"
						defaultValue={`${translate("buttonBar.clearPointMessage")} ${title}`}
						required
					/>
					<FormCheck name="ccSender">{translate("workflow.sendMeACopy")}</FormCheck>
					<FormInputTextEditor noFormGroupFlag label={translate("emailPage.body")} name="body" required />
					<FormSelectPageSize label={translate("pdf.selectPageSize")} name="pageSize" defaultValue="letter" />
					<FormSelectPageOrientation
						name="orientation"
						label={translate("pdf.pageOrientation")}
						defaultValue="landscape"
					/>
				</ModalBody>
				<ModalFooter>
					<Button onClick={close} type="button" color="default">
						{translate("global.cancel")}
					</Button>
					<FormButton color="primary">{translate("global.send")}</FormButton>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

EmailPageModal.propTypes = propTypes;

export default EmailPageModal;
