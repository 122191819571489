import StyleWrapper from "../../StyleWrapper";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	children: PropTypes.node.isRequired,
	customHeaderStylesFlag: PropTypes.bool,
	headerTextCss: PropTypes.string,
};

let PodHeaderTextWrapper = ({ children, customHeaderStylesFlag, headerTextCss, ...props }) => {
	let { exportFlag } = useOldSession().session;
	return (
		<StyleWrapper
			color={exportFlag ? theme.black : theme.darkGray}
			display="block"
			fontFamily={theme.defaultFontFamily}
			fontSize={customHeaderStylesFlag ? "12px" : theme.defaultFontSize}
			fontWeight={theme.bold}
			margin={customHeaderStylesFlag ? `${theme.tinySpace} 0 ${theme.smallSpace}` : "2px 0 6px"}
			{...props}
			$style={headerTextCss && `&&& { ${headerTextCss} } `}
		>
			{children}
		</StyleWrapper>
	);
};

PodHeaderTextWrapper.propTypes = propTypes;
export default PodHeaderTextWrapper;
