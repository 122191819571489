import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useTranslateByScorecard } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

let propTypes = {
	scorecardId: PropTypes.number,
	object: PropTypes.string,
};

let FormSelectLinkType = ({ scorecardId, object, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();

	let {
		session: { scorecardId: sessionScorecardId },
	} = useOldSession();

	let translateByScorecard = useTranslateByScorecard(scorecardId);

	let scorecard = scorecardId && get({ object: "scorecard", objectId: scorecardId });
	let scorecardElementList = scorecard?.elements;
	let sortOrderLookup = useMemo(
		() => ({
			category: -1,
			parent: 100,
			child: 101,
			milestone: scorecardElementList?.find((x) => x.object === "initiative").sortOrder + 0.1,
			default: (type) => scorecardElementList?.find((x) => x.object === type)?.sortOrder,
		}),
		[scorecardElementList]
	);

	let options = useMemo(() => {
		if (!scorecardElementList) return;

		let linkTypeLookup = {
			actionItem: ["objective", "measure", "initiative", "milestone", "actionItem", "risk"],
			goal: ["objective", "measure", "initiative", "actionItem", "risk"],
			initiative: ["objective", "measure", "parent", "child", "actionItem", "risk"],
			objective: ["measure", "initiative", "parent", "child", "actionItem", "risk"],
			measure: ["objective", "initiative", "parent", "child", "actionItem", "risk"],
			milestone: ["actionItem", "risk"],
			risk: ["objective", "measure", "initiative", "milestone", "actionItem", "risk"],
		};

		let elementOptions = cloneDeep(linkTypeLookup[object]);
		if (object === "objective" && scorecardId === sessionScorecardId) {
			elementOptions.push("category");
		}

		return elementOptions
			.filter((x) => {
				let found = scorecardElementList.find((element) => element.object === x);
				if (found) {
					return found?.hidden === false;
				} else {
					return x;
				}
			})
			.map((x) => ({ type: x, sortOrder: sortOrderLookup[x] || sortOrderLookup.default(x) }))
			.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
			.map((x) => ({
				label: translateByScorecard(x.type),
				value: x.type,
			}));
	}, [object, scorecardElementList, scorecardId, sessionScorecardId, sortOrderLookup, translateByScorecard]);

	return (
		<FormSelect
			options={options}
			placeholder={translate("layout.selectObjects", { objects: "global.linkType" }) + "..."}
			{...props}
		/>
	);
};

FormSelectLinkType.propTypes = propTypes;

export default FormSelectLinkType;
