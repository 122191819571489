import { useCallback, useMemo } from "react";
import useGoalListByGroup from "../../ManageGoals/useGoalListByGroup";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import generateOpenNextGoal from "./usePreviousAndNextGoalNavigation.openNextGoal";
import generateOpenPreviousGoal from "./usePreviousAndNextGoalNavigation.openPreviousGoal";
import isArray from "lodash/isArray";
import { emptyObject } from "@clearpoint/utils";


let usePreviousAndNextGoalNavigation = () => {
	let { activeGroupId, modalGoalId, statusId, setLocalState } = useLocalState() || emptyObject;
	let goalList = useGoalListByGroup(activeGroupId);

	let goalListFilteredByStatus = useMemo(() => {
		if (statusId === null) {
			return goalList;
		}
		return goalList.filter((goal) => goal.statusId === statusId);
	}, [goalList, statusId]);

	let currentGoalIndex = goalListFilteredByStatus?.findIndex?.((goal) => goal.goalId === modalGoalId);

	let navigableGoalListFlag =
		activeGroupId && isArray(goalListFilteredByStatus) && goalListFilteredByStatus.length > 1;

	let openPreviousGoal = useCallback(() => {
		let openPreviousGoal = generateOpenPreviousGoal(
			activeGroupId,
			goalListFilteredByStatus,
			currentGoalIndex,
			setLocalState
		);
		openPreviousGoal();
	}, [activeGroupId, currentGoalIndex, goalListFilteredByStatus, setLocalState]);

	let openNextGoal = useCallback(() => {
		let openNextGoal = generateOpenNextGoal(activeGroupId, goalListFilteredByStatus, currentGoalIndex, setLocalState);
		openNextGoal();
	}, [activeGroupId, currentGoalIndex, goalListFilteredByStatus, setLocalState]);

	return {
		navigableGoalListFlag,
		openNextGoal,
		openPreviousGoal,
	};
};

export default usePreviousAndNextGoalNavigation;
