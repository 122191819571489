import { useCallback } from "react";

import Block from "@clearpoint/old-theme/Block";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useReportFilter } from "@clearpoint/hooks";

import SearchInput from "./SearchInput";
import Title from "./Title";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";


let Search = () => {
	let { customFieldFlag, searchFlag, setLocalState } = useLocalState();
	let { clearReportFilter } = useReportFilter();
	let toggleSearchFlag = useCallback(() => {
		setLocalState({ searchFlag: (x) => !x });
		clearReportFilter();
	}, [clearReportFilter, setLocalState]);
	return (
		<Block
			display="flex"
			alignItems="flex-start"
			marginTop={customFieldFlag && theme.space}
			flex={(!customFieldFlag || searchFlag) && "1"}
		>
			<IconButton
				data-testid="search-grid-button"
				name={searchFlag ? "close" : "search"}
				color={customFieldFlag ? "default" : "transparent"}
				size="large"
				iconTransform="down-1"
				marginRight={theme.smallSpace}
				onClick={toggleSearchFlag}
			/>
			{searchFlag ? <SearchInput /> : (customFieldFlag ? null : <Block marginTop="-3px"><Title /></Block>)}
		</Block>
	);
};
export default Search;
