import Block from "../../Block";
import Icon from "../../Icon/Icon";
import { theme } from "../../Theme";
import { emptyObject } from "@clearpoint/utils";


let LinkChartExpandButton = ({ node, rotatedFlag }) => {
	let { children: childFlag } = node?.node || emptyObject;

	return (
		<Block
			borderRadius="3px"
			height="25px"
			width="25px"
			margin="auto auto"
			backgroundColor={theme.navDarkBackground}
			color="white"
			borderColor="lightgray"
			zIndex="2022"
			lineHeight="1.3"
			fontSize="20px"
			textAlign="center"
			fontWeight="bold"
		>
			{rotatedFlag ? (
				<Icon name={childFlag ? "collapsed" : "collapsedRight"} />
			) : (
				<Icon name={childFlag ? "collapseChart" : "expandChart"} />
			)}
		</Block>
	);
};

export default LinkChartExpandButton;
