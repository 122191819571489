import { useCallback, useRef } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import useTranslatorState from "./useTranslatorState";
import addDefaultsFromLocaleLanguage from "./addDefaultsFromLocaleLanguage";
import { baseLanguageLookup } from "./useBaseLanguage.setLanguage";
import cloneDeep from "lodash/cloneDeep";
import generateTranslateFunction from "./generateTranslateFunction";
import removePureInterpolationEntries from "./removePureInterpolationEntries";
import { useLocation } from "react-router-dom";

let useCheckValidScorecard = () => {
	let { pathname } = useLocation();
	let { getStoredValue } = useOldQueryStore();
	let scorecardList = pathname !== "/print" && getStoredValue({ object: "scorecard" });
	return useCallback(
		(scorecardId) =>
			pathname === "/print" || (scorecardId && scorecardList?.some((x) => x.scorecardId === scorecardId)),
		[pathname, scorecardList]
	);
};

let useGenerateScorecardTranslate = () => {
	let { translateLookup } = useTranslatorState();
	let { get, isLoading } = useOldQueryStore();
	let { localeId } = useOldSession()?.session || {};
	let checkValidScorecard = useCheckValidScorecard();
	let previousScorecardLanguageRef = useRef({});
	return useCallback(
		(scorecardId) => {
			if (!localeId) return;
			if (!checkValidScorecard(scorecardId)) return;
			if (global.testFlag) global.testFunction(scorecardId);
			let scorecardLanguage = get({ object: "language", scorecardId });
			let loadingFlag = isLoading({ object: "language", scorecardId });
			let baseLanguage = baseLanguageLookup[localeId];
			if (!scorecardLanguage || loadingFlag || !baseLanguage) return;
			if (previousScorecardLanguageRef.current[scorecardId] === scorecardLanguage) {
				if (translateLookup[scorecardId]) return translateLookup[scorecardId];
			} else {
				previousScorecardLanguageRef.current[scorecardId] = scorecardLanguage;
			}
			scorecardLanguage = cloneDeep(scorecardLanguage);
			removePureInterpolationEntries(scorecardLanguage);
			addDefaultsFromLocaleLanguage(scorecardLanguage, baseLanguage);
			return generateTranslateFunction({ ...baseLanguage, ...scorecardLanguage });
		},
		[checkValidScorecard, get, isLoading, localeId, translateLookup]
	);
};

export default useGenerateScorecardTranslate;
