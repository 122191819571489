import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import getGenerateDescription from "./useGenerateNotificationDescription.getGenerateDescription";
import { useFormatDate } from "@clearpoint/hooks";


let useGenerateDescription = () => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { get } = useOldQueryStore();
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let userList = get({ object: "user" });

	return useCallback(
		(criterion) => {
			let generateDescription = getGenerateDescription({ translate, statusList, userList, formatDate });
			return generateDescription(criterion);
		},
		[formatDate, statusList, translate, userList]
	);
};
export default useGenerateDescription;
