import isEmpty from "lodash/isEmpty";

let setLocalStateProvider = ({ previousProps, props, setLocalState }) => {
	if (previousProps)
		for (let key in props) {
			let newState = {};
			if (props[key] !== previousProps[key]) {
				newState[key] = props[key];
			}
			if (!isEmpty(newState)) setLocalState(newState);
		}
};

export default setLocalStateProvider;
