import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import Button from "../Button/Button";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";

let propTypes = {
	body: PropTypes.string,
	close: PropTypes.func.isRequired,
	confirmButtonText: PropTypes.string,
	modalVisible: PropTypes.bool,
	object: PropTypes.string.isRequired,
	objectId: PropTypes.number,
	onUndelete: PropTypes.func,
	title: PropTypes.string,
	toastMessage: PropTypes.string,
};

let UndeleteModal = ({
	body,
	close,
	confirmButtonText,
	modalVisible,
	object,
	objectId,
	onUndelete,
	title,
	toastMessage,
}) => {
	let translate = useTranslate();
	let { clear, undelete } = useOldQueryStore();
	let reportFlag = object.endsWith("Layout");
	let [submittingFlag, setSubmittingFlag] = useState(false);

	let undeleteObject = useCallback(
		async (e) => {
			if (e) {
				e.stopPropagation();
			}

			if (object === "defaultSeries") {
				clear({ object: "calculatedField" });
			}

			if (submittingFlag) return;
			setSubmittingFlag(true);

			await undelete({ object, objectId });
			toast.success(
				toastMessage ||
					translate(reportFlag ? "button.reportUndeleted" : "toaster.messages.objects.objectUndeleted", {
						object: `global.${object}`,
					})
			);

			clear({ object });
			clear({ object: "menu" });
			if (onUndelete) onUndelete(e);
			close();
		},
		[clear, submittingFlag, undelete, object, objectId, toastMessage, translate, reportFlag, onUndelete, close]
	);
	return (
		<Modal data-testid="undelete-element-modal" modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<ModalTitle>
					{title || translate(reportFlag ? "button.undeleteReport" : "button.undeleteElement")}
				</ModalTitle>
			</ModalHeader>
			<ModalBody>{body || translate("button.undeleteElementConfirm")}</ModalBody>
			<ModalFooter>
				<Button data-testid="cancel-button" onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button data-testid="undelete-button" onClick={undeleteObject} color="warning" disabled={submittingFlag}>
					{confirmButtonText || translate("button.undelete")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

UndeleteModal.propTypes = propTypes;

export default UndeleteModal;
