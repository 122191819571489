import { chartPeriodListContainsPeriod, getPeriodLabel } from "./utils";

let configureXAxis = (chartData, measureGridList) => {
	let {
		chartFormat: {
			chart: { polar: polarFlag, type },
			xAxis,
			series: seriesList,
		},
		chartPeriods: chartPeriodList,
		transposed: transposedFlag,
		xTitle,
	} = chartData;

	if (!["gauge", "pie", "solidgauge"].includes(type)) {
		if (!xAxis) xAxis = {};
		xAxis.title = { text: xTitle };

		if (transposedFlag || polarFlag) {
			xAxis.categories = seriesList.map((series) => series.name);
		} else {
			xAxis.categories = measureGridList
				.filter((period) => chartPeriodListContainsPeriod(period, chartPeriodList))
				.map((period) => getPeriodLabel(period, chartPeriodList));
		}
		return xAxis;
	}
	return null;
};

export default configureXAxis;
