import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editFlag: PropTypes.bool,
};
let IsReversed = ({ editFlag, ...props }) => {
	let translate = useTranslate();
	return editFlag ? (
		<RadioBoolean label={translate("manage.chart.reverseYAxis")} {...props} />
	) : (
		<Check data-testid="isReversed" text={translate("manage.chart.reverseYAxis")} {...props} label="" />
	);
};
IsReversed.propTypes = propTypes;
export default IsReversed;
