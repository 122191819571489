let configureChartHeight = (chartData) => {
	let {
		chartSize,
		chartFormat: { chart },
	} = chartData;

	if (!chart.height) {
		if (chartSize === "large") return 600;
		if (chartSize === "small") return 150;
		return 300;
	}
	return chart.height;
};

export default configureChartHeight;
