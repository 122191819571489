import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";

let propTypes = {
	editFlag: PropTypes.bool,
};
let RequireApprovals = ({ editFlag, ...props }) => {
	let translate = useTranslate();
	return editFlag ? (
		<RadioBoolean label={translate("scorecard.requireApprovals")} {...props} />
	) : (
		<Check data-testid="requireApprovals" text={translate("scorecard.requireApprovals")} {...props} label="" />
	);
};
RequireApprovals.propTypes = propTypes;
export default RequireApprovals;
