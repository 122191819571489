import CellStyleWrapper from "../CellStyleWrapper";
import CellWrapper from "../CellWrapper";
import FormFieldDisplayObjectiveScore from "@clearpoint/old-theme/Form/Field/Display/ObjectiveScore";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";


let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let ObjectiveScoreCell = (props) => {
	let { cell, objectData, parentName, periodId } = props;
	let status = objectData?.status?.[periodId];
	let { statusId } = status || emptyObject;
	let totalScore = objectData.status[periodId].score;
	let { hideRepeated: hideRepeatedFlag, statusDisplay } = cell;
	let statusFlag = statusDisplay !== "hidden";
	let periodStatus = useFormValue(`${parentName}.status.${periodId}`);
	let { reportPeriodId, periodId: dataPeriodId } = periodStatus;
	let hideDataFlag = hideRepeatedFlag && reportPeriodId !== dataPeriodId;

	return (
		<CellStyleWrapper {...props} {...cell} statusId={statusId}>
			<CellWrapper hideDataFlag={hideDataFlag} {...props}>
				{!hideDataFlag && (
					<FormFieldDisplayObjectiveScore statusFlag={statusFlag} statusId={statusId} totalScore={totalScore} />
				)}
			</CellWrapper>
		</CellStyleWrapper>
	);
};
ObjectiveScoreCell.propTypes = propTypes;

export default ObjectiveScoreCell;
