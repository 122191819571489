import { differenceInMinutes } from "date-fns";

export default function minutesPassed(time) {
	let now = new Date();
	let then = new Date(time);
	if (now < then) {
		then = now;
	}
	return differenceInMinutes(now, then);
}
