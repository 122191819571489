import { useContext, createContext } from "react";
import PropTypes from "prop-types";
import { useChartFormatData } from "@clearpoint/hooks";


let ChartPodContext = createContext();

let propTypes = {
	chartFormatDataProp: PropTypes.object,
	chartId: PropTypes.number,
	children: PropTypes.node,
	measureId: PropTypes.number,
	periodId: PropTypes.number,
	setChartFormatData: PropTypes.func,
};

let ChartPodProvider = ({ chartFormatDataProp, chartId, children, measureId, periodId, setChartFormatData }) => {
	let { chartFormatData, chartDescription, chartName, initialChartLoadedFlag } = useChartFormatData({
		chartId,
		chartFormatDataProp,
		measureId,
		periodId,
		setChartFormatData,
	});

	return (
		<ChartPodContext.Provider
			value={{ chartFormatData, chartDescription, chartName, initialChartLoadedFlag, setChartFormatData }}
		>
			{children}
		</ChartPodContext.Provider>
	);
};

export let useChartPodContext = () => useContext(ChartPodContext);

ChartPodProvider.propTypes = propTypes;
export default ChartPodProvider;
