import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OrgChart } from "d3-org-chart";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { useDebounce } from "react-use";

import { useCurrentRef, useStatefulRef } from "@clearpoint/hooks";
import { useTranslate, useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import { emptyObject, noop } from "@clearpoint/utils";

import Block from "../Block";
import Loading from "../Loading/index";
import { useWindowContext } from "../ModalWindow/ModalWindow";
import mergeWithChartState from "./hooks/mergeWithChartState";
import useFormatLinkData from "./hooks/useFormatLinkData";
import useLinkChartEventHandlers from "./hooks/useLinkChartEventHandlers";
import useOrgChartFunctions from "./hooks/useOrgChartFunctions";
import flushAllD3Transitions from "./utils/flushAllD3Transitions";
import { getLinkChartId } from "./utils/idHandler";
import mergeLinks from "./utils/mergeLinks";
import renderLinkChart from "./utils/renderLinkChart";
import { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	chartClickCallback: PropTypes.func,
	"data-testid": PropTypes.string,
	fetchLinkDataCallback: PropTypes.func,
	linkList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	),
	nodeHeight: PropTypes.number,
	nodeWidth: PropTypes.number,
	testFlag: PropTypes.bool,
	allowLoadingChildrenFlag: PropTypes.bool,
};

let defaultProps = {
	fetchLinkDataCallback: noop,
	linkList: [],
	nodeHeight: 150,
	nodeWidth: 280,
};

let linkChartEventLookup = {
	collapseChart: "linkChartCollapse",
	expandChart: "linkChartExpand",
	fit: "linkChartFit",
	rotate: "linkChartRotate",
	zoomIn: "linkChartZoomIn",
	zoomOut: "linkChartZoomOut",
};

let dispatchLinkChartEvent = (eventType) => {
	document.dispatchEvent(new Event(eventType));
};

let LinkChart = ({
	"data-testid": dataTestId,
	chartClickCallback,
	linkList,
	nodeHeight,
	nodeWidth,
	testFlag,
	allowLoadingChildrenFlag,
}) => {
	// refs
	let linkListRef = useStatefulRef(linkList);
	let previousLinkListRef = useRef(null);
	let chartRef = useRef(null);
	let [chartDataLoadedFlag, setChartDataLoadedFlag] = useState(false);
	let loadedFlagRef = useCurrentRef(chartDataLoadedFlag);
	let d3ContainerRef = useRef(null);
	let [childNodes, setChildNodes] = useState([]);
	let previousChildNodesRef = useRef([]);

	// hooks
	let translate = useTranslate();
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let formatLinkData = useFormatLinkData(allowLoadingChildrenFlag);

	let orgChartFunctions = useOrgChartFunctions(chartRef);
	let { rotatedFlagRef } = orgChartFunctions;
	let { dataRef, filterInputFlagRef, setData: setFilterList } = useFilter() || emptyObject;
	let windowContext = useWindowContext();

	let linkChartRefs = useMemo(() => {
		return { chartRef, d3ContainerRef, linkListRef, loadedFlagRef, rotatedFlagRef };
	}, [linkListRef, loadedFlagRef, rotatedFlagRef]);

	useLinkChartEventHandlers({ ...linkChartRefs, chartClickCallback, orgChartFunctions, setChildNodes });

	// chart setup
	useEffect(() => {
		if (linkListRef.current && d3ContainerRef.current && !chartRef.current) chartRef.current = new OrgChart();
		if (testFlag) flushAllD3Transitions();
	}, [linkListRef, d3ContainerRef, chartRef, testFlag]);

	let handleFilter = useCallback(() => {
		if (!loadedFlagRef.current) return;
		chartRef.current.clearHighlighting();
		if (!filterInputFlagRef.current) return;
		for (let link of dataRef.current) {
			if (!link?.visible) continue;
			chartRef.current.setUpToTheRootHighlighted(getLinkChartId(link));
		}
		chartRef.current.render();
	}, [dataRef, filterInputFlagRef, loadedFlagRef]);

	useDebounce(handleFilter, 300, [dataRef.current]);

	let nodeProps = useMemo(() => {
		return { translate, windowContext, translateOnlyInsideCurlyBraces, allowLoadingChildrenFlag };
	}, [allowLoadingChildrenFlag, translate, translateOnlyInsideCurlyBraces, windowContext]);

	useEffect(() => {
		let mounted = true;
		if (chartRef.current) {
			let renderChart = async () => {
				if (isEqual(previousLinkListRef.current, linkList) && isEqual(previousChildNodesRef.current, childNodes)) {
					return;
				}

				previousLinkListRef.current = linkList;
				previousChildNodesRef.current = childNodes;

				let formattedLinkList = mergeLinks(linkList, childNodes);
				setFilterList(formattedLinkList);
				formattedLinkList = await Promise.all(
					formattedLinkList.map((link, i) => formatLinkData(link, i, formattedLinkList))
				);
				linkListRef.current = mergeWithChartState(chartRef, formattedLinkList);
				if (!linkListRef.current) return;
				renderLinkChart({ ...linkChartRefs, nodeProps, nodeWidth, nodeHeight });
				setChartDataLoadedFlag(true);
			};
			if (mounted) renderChart();
		}
		return () => {
			mounted = false;
		};
	}, [
		formatLinkData,
		linkChartRefs,
		linkList,
		linkListRef,
		chartRef,
		childNodes,
		setFilterList,
		nodeProps,
		nodeWidth,
		nodeHeight,
	]);

	return (
		<Block
			data-testid={dataTestId || "link-chart-container"}
			height="100%"
			width="100%"
			flex="1 1 auto"
			id="linkChartContainer"
			position="relative"
			$style=" & > svg { position: absolute }"
			ref={d3ContainerRef}
		>
			{!chartDataLoadedFlag && <Loading />}
		</Block>
	);
};

LinkChart.propTypes = propTypes;
LinkChart.defaultProps = defaultProps;
LinkChart = memo(LinkChart);

export default LinkChart;
export { dispatchLinkChartEvent, linkChartEventLookup };
