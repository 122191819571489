import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import Tooltip from "@clearpoint/old-theme/Tooltip";
import { useCallback } from "react";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	description: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	setTemplate: PropTypes.func.isRequired,
	stateKey: PropTypes.string.isRequired,
	templateName: PropTypes.string.isRequired,
};

let TemplateTile = ({ description, icon, setTemplate, stateKey, templateName }) => {
	let selectTemplate = useCallback(() => {
		setTemplate(stateKey);
	}, [setTemplate, stateKey]);

	return (
		<Block
			onClick={selectTemplate}
			flex="120px 0 0"
			cursor="pointer"
			margin={`0 ${theme.tinySpace} ${theme.smallSpace}`}
		>
			<Tooltip text={description}>
				<Block
					alignItems="center"
					backgroundColor={theme.white}
					border={`1px solid ${theme.success}`}
					borderTop={`2px solid ${theme.success}`}
					display="flex"
					flexDirection="column"
					height="120px"
					justifyContent="space-around"
					padding={`${theme.space} ${theme.smallSpace}`}
					textAlign="center"
				>
					<Icon name={icon} color="success" size="2x" />
					<Block>
						<StyleWrapper fontSize="16px" margin={`${theme.tinySpace} 0 ${theme.smallSpace}`}>
							<h4>{templateName}</h4>
						</StyleWrapper>
					</Block>
				</Block>
			</Tooltip>
		</Block>
	);
};

TemplateTile.propTypes = propTypes;
export default TemplateTile;
