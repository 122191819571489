import Block from "@clearpoint/old-theme/Block";
import DashboardPageTitle from "./DashboardPageTitle";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import PageControl from "../PageControl";
import SearchInput from "./SearchInput";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { theme } from "@clearpoint/old-theme/Theme";
import { useReportFilter } from "@clearpoint/hooks";

let Search = () => {
	let { customFieldFlag, searchFlag, setLocalState } = useLocalState();
	let { clearReportFilter } = useReportFilter();
	let toggleSearchFlag = useCallback(() => {
		setLocalState({ searchFlag: (x) => !x });
		clearReportFilter();
	}, [clearReportFilter, setLocalState]);
	return (
		<Block display="flex" alignItems="center" marginTop={customFieldFlag && theme.space}>
			<IconButton
				data-testid="search-grid-button"
				name={searchFlag ? "close" : "search"}
				color={customFieldFlag ? "default" : "transparent"}
				size="medium"
				iconTransform="down-1"
				marginRight={theme.smallSpace}
				onClick={toggleSearchFlag}
			/>
			{searchFlag ? <SearchInput /> : !customFieldFlag ? <DashboardPageTitle /> : <PageControl />}
		</Block>
	);
};
export default Search;
