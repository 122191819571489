import { createContext, useContext } from "react";
import PropTypes from "prop-types";

const PrintContext = createContext(false);

export const usePrint = () => {
	const isPrint = useContext(PrintContext);
	return isPrint;
};
export const PrintProvider = ({ children }) => {
	return <PrintContext.Provider value={true}>{children}</PrintContext.Provider>;
};

PrintProvider.propTypes = {
	children: PropTypes.node,
};
