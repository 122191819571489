import ErrorPage from "./ErrorPage";
import HandleNetworkError from "./HandleNetworkError";
import * as Sentry from "@sentry/react";

// https://github.com/bvaughn/react-error-boundary#error-recovery

let ErrorHandler = ({ children }) => (
	<Sentry.ErrorBoundary fallback={(props) => <ErrorPage {...props} />}>
		<HandleNetworkError>{children}</HandleNetworkError>
	</Sentry.ErrorBoundary>
);

export default ErrorHandler;
