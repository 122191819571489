export default function getQueryParameters(queryString) {
	if (queryString) {
		if (queryString.startsWith("?")) queryString = queryString.slice(1);
		let keyValuePairs = queryString?.split("&");
		let queryParameters = {};
		for (let pair of keyValuePairs) {
			let [key, value] = pair?.split("=");
			if (!isNaN(+value)) value = +value;
			queryParameters[key] = value;
		}
		return queryParameters;
	}
	return {};
}
