import { useCallback } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import defaultSeries from "./defaultSeries";

let propTypes = {
	gaugeFlag: PropTypes.bool,
	series: PropTypes.shape({
		active: PropTypes.bool,
		measureSeriesId: PropTypes.number,
		name: PropTypes.string,
		seriesType: PropTypes.string,
	}),
};

let DeactivatedChartSeriesListItem = ({ gaugeFlag, series }) => {
	let { setFormValue } = useFormContext();
	let { chartSeries: chartSeriesList } = useFormValue();
	let { active, measureSeriesId, name, seriesType } = series;

	let activateSeries = useCallback(() => {
		let newSeries = { ...defaultSeries, active, measureSeriesId, name, seriesType };
		setFormValue("chartSeries", [...chartSeriesList, newSeries]);
	}, [active, chartSeriesList, measureSeriesId, name, seriesType, setFormValue]);

	return (
		<ListItem
			color="deactivated"
			left={
				<Block ellipsisFlag paddingBottom={theme.tinySpace}>
					{name}
				</Block>
			}
			right={<IconButton onClick={activateSeries} name="activate" />}
		/>
	);
};

DeactivatedChartSeriesListItem.propTypes = propTypes;
export default DeactivatedChartSeriesListItem;
