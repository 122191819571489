import AlignLeftButton from "./AlignLeftButton";
import AlignTopButton from "./AlignTopButton";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import Input from "@clearpoint/old-theme/Input/Input";
import MoveDownButton from "./MoveDownButton";
import MoveLeftButton from "./MoveLeftButton";
import MoveRightButton from "./MoveRightButton";
import MoveUpButton from "./MoveUpButton";
import RemoveButton from "./RemoveButton";
import SettingsButton from "./SettingsButton";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let MapToolbar = () => {
	let { mapOptionsModalVisible } = useLocalState();
	let Component = mapOptionsModalVisible ? Input : FormFieldName; // prevents infinite rerender when reusing field.name
	let value = useFormValue("name");
	return (
		<Component
			label={null}
			name="name"
			right={
				<>
					<RemoveButton />
					<AlignLeftButton />
					<AlignTopButton />
					<MoveUpButton />
					<MoveDownButton />
					<MoveLeftButton />
					<MoveRightButton />
					<SettingsButton />
				</>
			}
			value={value}
		/>
	);
};

export default MapToolbar;
