import Block from "./Block";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	display: PropTypes.string,
	opacityFlag: PropTypes.bool,
	visible: PropTypes.bool,
};

let Hide = ({ children, className, "data-testid": dataTestId, display, opacityFlag, visible, ...props }) => {
	let hideWithOpacityFlag = !visible && opacityFlag;
	let hideFlag = !visible && !opacityFlag;
	return (
		<Block
			className={className}
			display={hideFlag ? "none" : display}
			opacity={hideWithOpacityFlag ? 0 : props.opacity}
			pointerEvents={hideWithOpacityFlag ? "none" : props.pointerEvents}
			data-testid={dataTestId}
			$style={
				hideFlag
					? `${"&".repeat(10)}, ${"&".repeat(10)}> * { 
						display: none !important; 
						height: 0px !important; 
						width: 0px !important 
					}`
					: props.$style
			}
			{...props}
		>
			{children}
		</Block>
	);
};

Hide.propTypes = propTypes;

export default Hide;
