import AttachmentError from "./AttachmentError";
import Block from "@clearpoint/old-theme/Block";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useState } from "react";
import PropTypes from "prop-types";
import { useDataURL } from "@clearpoint/hooks";


let propTypes = {
	attachment: PropTypes.object,
	height: PropTypes.number,
	width: PropTypes.number,
};
let ImageAttachment = ({ attachment, height, width }) => {
	let dataURL = useDataURL(attachment.link.url);
	let [error, setError] = useState();
	return error ? (
		<AttachmentError attachment={attachment} />
	) : (
		<Block className={"page-break"} display={"block"}>
			<StyleWrapper maxHeight={height + "px"} maxWidth={width + "px"}>
				<img
					alt={attachment.filename}
					onError={setError}
					key={attachment.attachmentId}
					src={dataURL || attachment.link.url}
				/>
			</StyleWrapper>
		</Block>
	);
};
ImageAttachment.propTypes = propTypes;

export default ImageAttachment;
