import { useFormContext } from "../Form/Form";
import useFormValue from "../Form/hooks/useFormValue";
import { useRadio } from "@clearpoint/providers/CustomRadioGroup";
import styled from "styled-components";

let StyledLabel = styled.label`
	margin-left: ${(props) => props.theme.tinySpace};
	font-weight: ${(props) => (props.boldFlag ? props.theme.bolder : props.theme.normal)} !important;
`;

let RadioCustom = ({ label, value, onClick, style }) => {
	let { name, radioClass, boldFlag } = useRadio();
	let { setFormValue } = useFormContext();
	let handleClick = () => {
		if (onClick) {
			onClick();
		}
		setFormValue(name, value);
	};

	let valueInForm = useFormValue(name);
	return (
		<div className={radioClass} style={style}>
			<input type="radio" id={value} checked={valueInForm === value} onClick={handleClick} readOnly />
			<StyledLabel htmlFor={value} onClick={handleClick} boldFlag={boldFlag}>
				{label}
			</StyledLabel>
		</div>
	);
};

export default RadioCustom;
