import DefaultModalWindow, { TRANSITION_DURATION } from "./ModalWindow";
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	children: PropTypes.element.isRequired,
	isReady: PropTypes.func,
	ModalWindow: PropTypes.elementType,
	onClick: PropTypes.func,
};
let defaultProps = {
	ModalWindow: DefaultModalWindow,
};
let ModalWindowOnClick = ({ children, isReady, ModalWindow, onClick, onClose, ...props }) => {
	let child = React.Children.only(children);
	let [{ modalWindowVisible, modalWindowClosed }, setState] = useStateObject({
		modalWindowVisible: false,
		modalWindowClosed: true,
	});
	let showModalWindow = useCallback(
		async (e) => {
			if (isReady) {
				let res = await isReady();
				if (res === false) return;
			}
			if (onClick) onClick(e);
			setState({
				modalWindowVisible: true,
				modalWindowClosed: false,
			});
		},
		[isReady, onClick, setState]
	);
	let close = useCallback(() => {
		if (global.testFlag) {
			setState({
				modalWindowClosed: true,
				modalWindowVisible: false,
			});
			if (onClose) onClose();
		} else {
			setState({
				modalWindowVisible: false,
			});
			setTimeout(() => {
				setState({
					modalWindowClosed: true,
				});
				if (onClose) onClose();
			}, TRANSITION_DURATION);
		}
	}, [onClose, setState]);
	return useMemo(
		() => (
			<>
				{React.cloneElement(child, {
					onClick: showModalWindow,
				})}
				{!modalWindowClosed && (
					<ModalWindow
						modalWindowVisible={modalWindowVisible}
						modalVisible={modalWindowVisible}
						close={close}
						{...props}
					/>
				)}
			</>
		),
		[child, close, modalWindowClosed, modalWindowVisible, props, showModalWindow]
	);
};
ModalWindowOnClick.propTypes = propTypes;
ModalWindowOnClick.defaultProps = defaultProps;

export default ModalWindowOnClick;
