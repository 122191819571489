import { useEffect } from "react";
import usePrevious from "./usePrevious";
import useStateObject from "./useStateObject";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import isEqual from "lodash/isEqual";

let useFilterCache = () => {
	let { get } = useOldQueryStore();

	let [{ count, previousFilterQuery, previousPayload, storeCall, storedData }, setFilterCache] = useStateObject({
		count: undefined,
		previousFilterQuery: {},
		previousPayload: {},
		storeCall: null,
		storedData: [],
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	let apiResponse = storeCall ? get(storeCall) : [];
	let previousStoreCall = usePrevious(storeCall);
	let previousApiResponse = usePrevious(apiResponse);

	// Listen for when a list item is deleted, undeleted, etc. and trigger update
	useEffect(() => {
		if (
			isEqual(storeCall, previousStoreCall) &&
			!isEqual(apiResponse, previousApiResponse) &&
			previousApiResponse !== undefined
		) {
			setFilterCache({ storedData: [] });
		}
	}, [apiResponse, previousApiResponse, previousStoreCall, setFilterCache, storeCall]);

	return {
		count,
		previousFilterQuery,
		previousPayload,
		setFilterCache,
		storedData,
	};
};

export default useFilterCache;
