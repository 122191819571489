import Accounting from "./Accounting";
import Boolean from "./Boolean";
import Chart from "./Chart";
import Currency from "./Currency";
import Datahtml from "./Datahtml";
import Date from "./Date";
import Display from "./Display";
import Grid from "./Grid";
import Html from "./Html";
import Image from "./Image";
import Integer from "./Integer";
import Link from "./Link";
import Multipick from "./Multipick";
import MyFavorites from "./MyFavorites";
import MyScorecard from "./MyScorecard";
import Numeric from "./Numeric";
import Pagehtml from "./Pagehtml";
import Percent from "./Percent";
import Picklist from "./Picklist";
import Status from "./Status";
import StatusIcon from "./StatusIcon";
import _String from "./String";
import Text from "./Text";
import User from "./User";
import UserMultipick from "./UserMultipick";

let CustomField = {};
CustomField.Boolean = Boolean;
CustomField.Accounting = Accounting;
CustomField.Chart = Chart;
CustomField.Currency = Currency;
CustomField.Date = Date;
CustomField.Datahtml = Datahtml;
CustomField.Display = Display;
CustomField.Grid = Grid;
CustomField.Image = Image;
CustomField.Html = Html;
CustomField.MyScorecard = MyScorecard;
CustomField.MyFavorites = MyFavorites;
CustomField.Multipick = Multipick;
CustomField.Link = Link;
CustomField.Integer = Integer;
CustomField.Numeric = Numeric;
CustomField.Picklist = Picklist;
CustomField.Percent = Percent;
CustomField.Pagehtml = Pagehtml;
CustomField.Status = Status;
CustomField.String = _String;
CustomField.StatusIcon = StatusIcon;
CustomField.User = User;
CustomField.Usermultipick = UserMultipick;
CustomField.Text = Text;
export default CustomField;
