import PropTypes from "prop-types";
import { PieChart } from "react-minimal-pie-chart";

import Block from "./Block";
import { theme } from "./Theme";

let propTypes = {
	backgroundColor: PropTypes.string,
	fillColor: PropTypes.string,
	marginTop: PropTypes.string,
	percentComplete: PropTypes.number,
	size: PropTypes.string,
	lineWidth: PropTypes.number,
};

let defaultProps = {
	backgroundColor: "#a7e8e9",
	fillColor: theme.info,
	marginTop: "-1%",
	size: "22px",
};

let PercentCompletePie = ({ backgroundColor, fillColor, marginTop, percentComplete, size }) => {
	return percentComplete === undefined ? null : (
		<Block marginTop={marginTop} width={size} flexShrink="0" overflow="visible !important">
			<PieChart
				startAngle={-90}
				lineWidth={size === "22px" || size === "28px" ? 55 : 35}
				data={[
					{ title: "", value: percentComplete, color: fillColor },
					{ title: "", value: 100 - percentComplete, color: backgroundColor },
				]}
			/>
		</Block>
	);
};

PercentCompletePie.propTypes = propTypes;
PercentCompletePie.defaultProps = defaultProps;

export default PercentCompletePie;
