import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useDescribeTimePassed } from "@clearpoint/hooks";


let propTypes = {
	deleteDate: PropTypes.string,
};
let TextDeleted = ({ deleteDate }) => {
	let translate = useTranslate();
	let describeTimePassed = useDescribeTimePassed();
	return deleteDate ? (
		<small>
			{translate("global.deleted")} {describeTimePassed(deleteDate)}
		</small>
	) : null;
};
TextDeleted.propTypes = propTypes;
export default TextDeleted;
