import FormGroup from "../../Form/Form.Group";
import { Fragment, useCallback, useEffect } from "react";
import Input from "../../Form/Input/Input";
import LabelLeft from "../../Form/Form.Label.Left";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getDeepValue from "lodash/get";
import { theme } from "../../Theme";
import { useEffectOnce } from "@clearpoint/hooks";

let propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
};
let Link = ({ disabled, name, label }) => {
	let translate = useTranslate();
	let link = useFormValue(`_link${name}`);
	let value = useFormValue(name);
	let { setFormValue, formDefaultValue } = useFormContext();
	let defaultValue = getDeepValue(formDefaultValue, name);

	useEffectOnce(() => {
		let link = {};
		link.href = value?.match(/href=([^\s]*)\s/)?.[1];
		link.href = link.href?.substring(1, link.href.length - 1);
		link.text = value?.match(/<a [^>]+>([^<]+)<\/a>/)?.[1];
		if (link.href) setFormValue(`_link${name}`, link);
	});

	useEffect(() => {
		if (!defaultValue && (!link?.href || link?.href === "https://")) return;
		if (link?.href) {
			let value = `<a href="${link?.href}" target="_new">${link?.text || ""}</a>`;
			setFormValue(name, value);
		}
	}, [defaultValue, link, name, setFormValue]);

	let Wrapper = useCallback(({ children }) => <FormGroup title={label}>{children}</FormGroup>, [label]);
	Wrapper = label ? Wrapper : Fragment;
	return (
		<Wrapper>
			<LabelLeft name={`_link${name}.text`} label={translate("global.linkText")}>
				<Input disabled={disabled} marginBottom={theme.smallSpace} data-testid="link" name={`_link${name}.text`} />
			</LabelLeft>
			<LabelLeft name={`_link${name}.href`} label={translate("global.link")}>
				<Input
					disabled={disabled}
					marginBottom={theme.smallSpace}
					data-testid="link"
					defaultValue="https://"
					name={`_link${name}.href`}
				/>
			</LabelLeft>
		</Wrapper>
	);
};
Link.propTypes = propTypes;
export default Link;
