import Nav from "react-bootstrap/Nav";
import React, { useEffect, useState } from "react";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	defaultActiveKey: PropTypes.number,
	onClick: PropTypes.func,
	transitionFlag: PropTypes.bool,
};

let defaultProps = {
	defaultActiveKey: 0,
	transitionFlag: false,
};

let Tabs = ({ children, className, defaultActiveKey, onClick, transitionFlag }) => {
	useEffect(() => {
		React.Children.toArray(children).forEach((child) => {
			if (!child.props || !child.props.title) {
				console.error(
					`Child of tab container with key ${child.key} was not passed a "title" prop which is required`
				);
			}
		});
	});

	let [activeKey, setActiveKey] = useState(defaultActiveKey);

	return (
		<TabContainer defaultActiveKey={defaultActiveKey} transition={transitionFlag}>
			<Nav
				className={className}
				onSelect={(eventKey) => {
					if (onClick) onClick(eventKey);
					setActiveKey(+eventKey);
				}}
			>
				{React.Children.map(
					children,
					(x, i) =>
						x?.props && (
							<Nav.Item key={x.props.title} className={activeKey === i ? "active" : ""}>
								<Nav.Link eventKey={i}>{x.props.title}</Nav.Link>
							</Nav.Item>
						)
				)}
			</Nav>
			<TabContent>
				{React.Children.map(
					children,
					(x, i) =>
						x?.props && (
							<TabPane key={x.props.title} eventKey={i}>
								{x}
							</TabPane>
						)
				)}
			</TabContent>
		</TabContainer>
	);
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
