import Button from "../../Button/Button";
import { useCallback } from "react";
import { useFormContext } from "../../Form/Form";
import PropTypes from "prop-types";

let propTypes = {
	"data-testid": PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.string,
};

let defaultProps = {
	type: "submit",
};

let FormButton = ({ children, "data-testid": dataTestId, onClick, type, ...props }) => {
	let { formStatus, resetForm } = useFormContext();
	let handleClick = useCallback(
		(e) => {
			if (type === "reset") {
				e.preventDefault();
				resetForm();
			}
			if (onClick) onClick(e);
		},
		[onClick, resetForm, type]
	);
	let disabled = (type === "submit" && !formStatus.valid) || props.disabled;
	delete props.disabled;
	return (
		<Button
			data-testid={dataTestId || "form-button"}
			disabled={disabled}
			onClick={handleClick}
			type={type}
			{...props}
		>
			{children}
		</Button>
	);
};

FormButton.propTypes = propTypes;
FormButton.defaultProps = defaultProps;

export default FormButton;
