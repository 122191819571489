import Button from "./Button";
import ConfirmModalOnClick from "../Modal/Modal.Confirm.OnClick";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";

let propTypes = {
	body: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.oneOf(["success", "primary", "warning", "neutral", "danger", "info"]),
	confirmButtonText: PropTypes.string,
	confirmButtonColor: PropTypes.string,
	icon: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	size: PropTypes.oneOf(["tiny", "medium", "small"]),
	title: PropTypes.string.isRequired,
	transform: PropTypes.string,
};

let defaultProps = {
	color: "success",
	size: "tiny",
	transform: "up-1",
};

let ConfirmButton = ({
	body,
	children,
	confirmButtonColor,
	confirmButtonText,
	icon,
	onConfirm,
	title,
	transform,
	...props
}) => (
	<ConfirmModalOnClick
		title={title}
		body={body}
		confirmButtonText={confirmButtonText}
		confirmButtonColor={confirmButtonColor}
		onConfirm={onConfirm}
	>
		<Button type="button" {...props}>
			{icon && <Icon name={icon} transform={transform} />}
			{children}
		</Button>
	</ConfirmModalOnClick>
);

ConfirmButton.propTypes = propTypes;
ConfirmButton.defaultProps = defaultProps;

export default ConfirmButton;
