let codeMirrorOptions = {
	autofocus: true,
	autoRefresh: true,
	lineNumbers: true,
	lineWrapping: true,
	mode: "htmlmixed",
	matchClosing: false,
};

export default codeMirrorOptions;
