import Form from "@clearpoint/old-theme/Form/Form";
import { useCallback } from "react";
import { useDetailPageData } from "../DataProvider";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { buildQueryString } from "@clearpoint/utils";

let propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

let LayoutForm = ({ children, className }) => {
	let { object, objectId } = useDetailPageData();
	let { session } = useOldSession();

	let { clear } = useOldQueryStore();
	let queryString = buildQueryString({ object, objectId });

	let beforeSubmit = useCallback(
		(submitValue) => {
			submitValue.scorecardId = session.scorecardId;
			submitValue.saveOption = 0;
			return submitValue;
		},
		[session]
	);

	let onSubmit = useCallback(() => {
		clear({ object: "detailLayout", queryString });
	}, [clear, queryString]);

	return (
		<Form
			className={className}
			onSubmit={onSubmit}
			beforeSubmit={beforeSubmit}
			queryStringOnSubmit={queryString}
			object="detailLayout"
			queryString={queryString}
		>
			{children}
		</Form>
	);
};

LayoutForm.propTypes = propTypes;
export default LayoutForm;
