import { useFormContext } from "../Form";
import useFormValue from "./useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useUpdateEffect } from "react-use";
import { useEffectOnce } from "@clearpoint/hooks";

let useSessionFormValue = ({ defaultValue, nameInForm, nameInSession }) => {
	let { setFormValue } = useFormContext();
	let { setSession, session } = useOldSession();
	let formValue = useFormValue(nameInForm);
	let sessionValue = session[nameInSession];
	useEffectOnce(() => {
		// set initial form value
		if (nameInSession) {
			if (sessionValue) setFormValue(nameInForm, sessionValue);
			else if (defaultValue) setFormValue(nameInForm, defaultValue);
		}
	});
	useUpdateEffect(() => {
		// save updated form value to session
		if (nameInSession) {
			if (formValue !== sessionValue) {
				setSession({ [nameInSession]: formValue });
			}
		}
	}, [formValue, nameInSession, sessionValue, setSession]);
};
export default useSessionFormValue;
