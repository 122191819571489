import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { toast } from "@clearpoint/services/toastService/index";

import generateLogout from "./useLogout.generateLogout";
import useQueryParameters from "./useQueryParameters";
import useStateObject from "./useStateObject";
import { useOldSession } from "@clearpoint/old-session/index";

let logout;
export default function useLogout() {
	let { getStoredValue } = useOldQueryStore();
	let { setSession } = useOldSession();
	let [{ logoutStatus }, setState] = useStateObject({ logoutStatus: null });
	let { trackEvent, update } = useIntercom();
	let queryParameters = useQueryParameters();
	let options = getStoredValue({ object: "options" });
	let logoutUrl = options?.options?.customLogoutUrl;
	logout = useCallback(
		({ timeoutFlag: timeout, message }) => {
			let logoutCallback = generateLogout({
				logoutStatus,
				logoutUrl,
				queryParameters,
				sessionStorage,
				setSession,
				setState,
				toast,
				trackEvent,
				update,
			});
			logoutCallback({ timeoutFlag: timeout, message });
		},
		[logoutStatus, logoutUrl, queryParameters, setSession, setState, trackEvent, update]
	);
	return { logout, logoutStatus };
}

window.addEventListener("storage", (event) => {
	// when a user logs out from another tab, logout
	let loginPageFlag = window.location.hash.includes("login");
	if (event.storageArea !== localStorage) return;
	if (!loginPageFlag && event.key === "loginFlag" && !event.newValue && !!logout) logout({ timeoutFlag: false });
});
