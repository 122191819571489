import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ModalConfirmOnClick from "@clearpoint/old-theme/Modal/Modal.Confirm.OnClick";
import { useCallback } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useTranslate } from "@clearpoint/translate";

let RemoveButton = () => {
	let { checklist } = useChecklist();
	let { setFormValue } = useFormContext();
	let translate = useTranslate();
	let remove = useCallback(
		() =>
			setFormValue("elements", (elementList) =>
				elementList?.filter(
					(x) => !checklist.some((check) => check.checked && check.id === `${x.object},${x.objectId}`)
				)
			),
		[checklist, setFormValue]
	);
	let disabledFlag = !checklist.some((x) => x.checked);
	return (
		<ModalConfirmOnClick
			body={translate("edit.map.removeFromMapText")}
			confirmButtonColor="warning"
			confirmButtonText={translate("global.remove")}
			onConfirm={remove}
			title={translate("edit.map.removeFromMap")}
		>
			<IconButton
				color="default"
				disabled={disabledFlag}
				name="remove"
				title={disabledFlag ? translate("edit.map.noElements") : undefined}
			/>
		</ModalConfirmOnClick>
	);
};

export default RemoveButton;
