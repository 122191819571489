import FormSelect from "../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let SelectSeries = ({ name }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let seriesList = get({ url: "/calculatedFields?series=true" });
	let uniqueSeriesList = useMemo(() => {
		if (!seriesList) return [];
		let uniqueSeriesList = [];
		for (let series of seriesList) {
			if (!uniqueSeriesList.some((x) => x.name === series.name)) {
				uniqueSeriesList.push(series);
			}
		}
		return uniqueSeriesList;
	}, [seriesList]);
	let selectOptions = useMemo(
		() =>
			uniqueSeriesList.map((x) => ({
				group: x.defaultSeries ? translate("recycleBin.objects.defaultSeries") : translate("measureSeries"),
				label: x.name,
				value: x.name,
			})),
		[uniqueSeriesList, translate]
	);
	return (
		<FormSelect
			placeholder={translate("detailLayouts.seriesName")}
			loadingFlag={!seriesList}
			options={selectOptions}
			inline
			name={name}
		/>
	);
};

SelectSeries.propTypes = propTypes;

export default SelectSeries;
