import EditorToggleButton from "./EditorToggleButton";
import FormGroup from "../../Form/Form.Group";
import FormLabel from "../../Form/Form.Label";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	noFormGroupFlag: PropTypes.bool,
	setTinyMceFlag: PropTypes.func,
	tinyMceFlag: PropTypes.bool,
};

let Wrapper = ({ children, className, disabled, label, noFormGroupFlag, setTinyMceFlag, tinyMceFlag }) =>
	!label ? (
		<>
			{children}
			{!disabled && <EditorToggleButton tinyMceFlag={tinyMceFlag} setTinyMceFlag={setTinyMceFlag} />}
		</>
	) : noFormGroupFlag ? (
		<>
			<FormLabel marginBottom={theme.smallSpace}>{label}</FormLabel>
			{children}
			{!disabled && <EditorToggleButton tinyMceFlag={tinyMceFlag} setTinyMceFlag={setTinyMceFlag} />}
		</>
	) : (
		<FormGroup className={className} title={label} paddingBottom={theme.smallSpace}>
			{children}
			{!disabled && <EditorToggleButton tinyMceFlag={tinyMceFlag} setTinyMceFlag={setTinyMceFlag} />}
		</FormGroup>
	);
Wrapper.propTypes = propTypes;

export default Wrapper;
