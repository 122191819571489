import cloneDeep from "lodash/cloneDeep";

let returnDataWithTotal = ({ count, dataKey, fetchedData, setFilterCache }) => {
	if (!fetchedData) {
		return {
			total: count,
			_loading: true,
		};
	}

	// remove last character from dataKey if there is a "s"
	let fetchedDataKey = `${dataKey.slice(0, -1)}Count`;
	if (dataKey === "logFile") {
		fetchedDataKey = "logCount";
	}
	if (dataKey === "matches") {
		fetchedDataKey = "matchCount";
	}

	let object = cloneDeep(fetchedData[dataKey]) || {};
	object.total = fetchedData?.[fetchedDataKey];
	if (count !== object.total)
		setFilterCache({
			count: object.total,
		});
	return object;
};

export default returnDataWithTotal;
