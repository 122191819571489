import DeactivatedChartSeriesListItem from "../shared/DeactivatedChartSeriesListItem";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterWrapper from "../shared/FilterWrapper";
import FormDragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import NoResults from "@clearpoint/old-theme/NoResults";
import TransposedSeriesListItem from "./TransposedSeriesListItem";
import { useCallback, useMemo } from "react";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import useSeriesData from "../shared/useSeriesData";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import mapVisibleToList from "../shared/mapVisibleToList";

let listContentPropTypes = {
	activatedSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			dragAndDropListKey: PropTypes.number.isRequired,
			label: PropTypes.string,
			name: PropTypes.string,
			sortOrder: PropTypes.number,
		})
	),
	deactivatedSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			name: PropTypes.string,
			object: PropTypes.string,
		})
	),
	gaugeFlag: PropTypes.bool,
};

let TransposedSeriesListContent = ({ activatedSeriesList, deactivatedSeriesList, gaugeFlag }) => {
	let translate = useTranslate();
	let { data: filteredSeriesList, setData: setSeriesList } = useFilter();

	activatedSeriesList = useMemo(
		() =>
			mapVisibleToList(activatedSeriesList, filteredSeriesList)?.map((series, index) => ({
				...series,
				sortOrder: index,
			})),
		[activatedSeriesList, filteredSeriesList]
	);

	deactivatedSeriesList = useMemo(
		() =>
			mapVisibleToList(deactivatedSeriesList, filteredSeriesList)?.map((series, index) => ({
				...series,
				sortOrder: index,
			})),
		[deactivatedSeriesList, filteredSeriesList]
	);

	let setList = useCallback(
		(list) => {
			setSeriesList([...list, ...deactivatedSeriesList]);
		},
		[deactivatedSeriesList, setSeriesList]
	);

	return (
		<>
			<FilterInput
				name="seriesFilter"
				filterBy="name"
				placeholder={translate("adminProjectEval.filterSeries") + "..."}
			/>
			<NoResults />
			{!activatedSeriesList.every((series) => !series.visible) && (
				<FormDragAndDropList name="chartSeries" list={activatedSeriesList} setList={setList}>
					{activatedSeriesList
						?.filter((x) => x.visible)
						?.map((series) => (
							<TransposedSeriesListItem key={series.dragAndDropListKey} series={series} />
						))}
				</FormDragAndDropList>
			)}
			{deactivatedSeriesList
				.filter((x) => x.visible)
				.map((series, index) => (
					<DeactivatedChartSeriesListItem key={index} series={series} gaugeFlag={gaugeFlag} />
				))}
		</>
	);
};

let propTypes = {
	gaugeFlag: PropTypes.bool,
};

let TransposedSeriesList = ({ gaugeFlag }) => {
	let { combinedSeriesList, activatedSeriesList, deactivatedSeriesList } = useSeriesData();

	return (
		<FilterWrapper data={combinedSeriesList}>
			<TransposedSeriesListContent
				activatedSeriesList={activatedSeriesList}
				deactivatedSeriesList={deactivatedSeriesList}
				gaugeFlag={gaugeFlag}
			/>
		</FilterWrapper>
	);
};

TransposedSeriesList.propTypes = listContentPropTypes;
TransposedSeriesListContent.defaultProps = {
	activatedSeriesList: [],
	deactivatedSeriesList: [],
};
TransposedSeriesList.propTypes = propTypes;
export default TransposedSeriesList;
