import Badge from "@clearpoint/old-theme/Badge/Badge";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let priorityLookup = {
	1: "low",
	2: "medium",
	3: "high",
};

let propTypes = {
	priority: PropTypes.number,
};

let PriorityBadge = ({ priority }) => {
	let translate = useTranslate();

	return (
		<Badge color="success">
			<Icon name="priority" marginRight={theme.tinySpace} />
			{translate(`goals.priority.${priorityLookup[priority]}`)}
		</Badge>
	);
};

PriorityBadge.propTypes = propTypes;
export default PriorityBadge;
