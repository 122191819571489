import { queryClient } from "../../QueryProvider";
import {
	ELEMENT,
	LINK,
	MEASURE_GRID,
	MENU,
	MY_FAVORITE,
	MY_SCORECARD,
	MY_UPDATES,
	PEER_GROUP,
	PREVIEW,
	RECENT,
	REPORT,
	SEARCH,
	WORKFLOW,
} from "../../objects";

let invalidateElement = ({ element }) => {
	// invalidation for changes to core elements: scorecard, objective, measure, initiative, milestone, actionItem, risk
	queryClient.invalidateQueries([{ object: element }]);
	queryClient.invalidateQueries([{ object: ELEMENT }]);
	queryClient.invalidateQueries([{ object: LINK }]);
	queryClient.invalidateQueries([{ object: MEASURE_GRID }]);
	queryClient.invalidateQueries([{ object: MENU }]);
	queryClient.invalidateQueries([{ object: MY_FAVORITE }]);
	queryClient.invalidateQueries([{ object: MY_SCORECARD }]);
	queryClient.invalidateQueries([{ object: MY_UPDATES }]);
	queryClient.invalidateQueries([{ object: PEER_GROUP }]);
	queryClient.invalidateQueries([{ object: PREVIEW }]);
	queryClient.invalidateQueries([{ object: RECENT }]);
	queryClient.invalidateQueries([{ object: REPORT }]);
	queryClient.invalidateQueries([{ object: SEARCH }]);
	queryClient.invalidateQueries([{ object: WORKFLOW }]);
};
export { invalidateElement };
