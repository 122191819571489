import SharedControl from "./SharedControl";
import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { usePreviousDefined, useStateObject } from "@clearpoint/hooks";


let propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	currentPage: PropTypes.number,
	onClick: PropTypes.func,
	preventPaginationFlag: PropTypes.bool,
	preventPaginationMessage: PropTypes.string,
	size: PropTypes.string,
};
let Control = ({
	className,
	color,
	currentPage: currentPageProp,
	onClick,
	pageCount,
	preventPaginationFlag,
	preventPaginationMessage,
	size,
}) => {
	let previousPageCount = usePreviousDefined(pageCount);
	let [{ currentPage }, setState] = useStateObject({
		currentPage: 1,
	});
	useEffect(() => setState({ currentPage: currentPageProp }), [currentPageProp, setState]);

	let checkPaginationAccess = useCallback(() => {
		if (!preventPaginationFlag) return true;
		if (preventPaginationMessage) toast.warning(preventPaginationMessage);
		return false;
	}, [preventPaginationFlag, preventPaginationMessage]);

	let goToPage = useCallback(
		(page) => {
			if (!checkPaginationAccess()) return;
			setState({ currentPage: page });
			onClick(page);
		},
		[checkPaginationAccess, onClick, setState]
	);
	let goToPreviousPage = useCallback(() => currentPage > 1 && goToPage(currentPage - 1), [currentPage, goToPage]);
	let goToNextPage = useCallback(
		() => currentPage < pageCount && goToPage(currentPage + 1),
		[currentPage, goToPage, pageCount]
	);
	return (
		<SharedControl
			className={className}
			size={size}
			color={color}
			pageCount={pageCount === undefined ? previousPageCount : pageCount}
			currentPage={currentPage}
			goToPage={goToPage}
			goToPreviousPage={goToPreviousPage}
			goToNextPage={goToNextPage}
		/>
	);
};
Control.propTypes = propTypes;
export default Control;
