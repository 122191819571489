import { compareTab } from "@clearpoint/utils";

import removeSSOParameters from "./removeSSOParameters";

let documentLibraryDefaultViewLookup = {
	images: "list",
};

let updateHistoryStack = ({ pathname, profile, session, page, queryParameters }) => {
	if (!profile) return null;
	let locationData = { pathname, page, ...queryParameters };
	locationData = removeSSOParameters(locationData);

	if (page === "document-library") {
		locationData.view = documentLibraryDefaultViewLookup[locationData.mode] ?? locationData.view;
	}
	delete locationData.periodId;
	if (!page) {
		delete locationData.page;
	}
	if (page === "search") locationData = { pathname, page };
	let historyStack = session.historyStack || [];

	if (pathname.includes("index")) {
		let i = historyStack.findIndex((x) => compareTab(x, locationData));
		historyStack =
			i === -1
				? [...historyStack, locationData]
				: [...historyStack.slice(0, i), ...historyStack.slice(i + 1), locationData];
	}
	return historyStack;
};

export default updateHistoryStack;
