import { useCallback, useMemo } from "react";
import useFormValue from "../../Form/hooks/useFormValue";
import getReplaceCommaSeparatedSelector from "./useStyleValue.getReplaceCommaSeparatedSelector.js";
import getStyleValue from "./useStyleValue.getStyleValue";
import { useId } from "@clearpoint/hooks";


let useStyleValue = (name) => {
	let id = useId();
	let styleHTML = useFormValue(`_style${name}`);
	let styleClass = `redactor-styles-${id}`;
	let replaceCommaSeparatedSelector = useCallback(
		(selector) => getReplaceCommaSeparatedSelector({ selector, styleClass }),
		[styleClass]
	);
	let styleValue = useMemo(
		() => getStyleValue({ replaceCommaSeparatedSelector, styleHTML }),
		[replaceCommaSeparatedSelector, styleHTML]
	);
	return { styleClass, styleValue };
};
export default useStyleValue;
