import Button from "../Button/Button";
import FormCheck from "../Form/Check/Form.Check";
import FormSelectPeriod from "../Form/Select/Select.Period";
import Modal from "../Modal/Modal";
import ModalBody from "../Modal/Modal.Body";
import ModalFooter from "../Modal/Modal.Footer";
import ModalHeader from "../Modal/Modal.Header";
import ModalTitle from "../Modal/Modal.Title";
import NameField from "../Form/Field/Name";
import { useCallback } from "react";
import { useFormContext } from "../Form/Form";
import useFormValue from "../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { buildQueryString, findUpdates } from "@clearpoint/utils";
import { useEffectOnce } from "@clearpoint/hooks";

let propTypes = {
	attachment: PropTypes.shape({
		attachmentId: PropTypes.number,
	}),
	close: PropTypes.func,
};
let EditModal = ({ attachment, close, ...props }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { clear, set } = useOldQueryStore();
	let { periodId } = useOldSession().session;
	let { attachmentId } = attachment;
	let parentName = `_attachment${attachmentId}`;
	useEffectOnce(() => {
		let attachmentCopy = cloneDeep(attachment);
		attachmentCopy.allPeriods = attachment.periodId === -1;
		setFormValue(parentName, attachmentCopy);
	});
	let formAttachment = useFormValue(parentName);
	let allPeriodsFlag = useFormValue(`${parentName}.allPeriods`);
	let editAttachment = useCallback(() => {
		let data = findUpdates({ original: attachment, updated: formAttachment });
		if (data.allPeriods) data.periodId = -1;
		delete data.allPeriods;
		set({ object: "attachment", objectId: attachment.attachmentId, data, stopPropagationFlag: true }).then(() => {
			let { object, objectId } = attachment;
			clear({ object: "attachment", queryString: buildQueryString({ object, objectId }) });
			close();
		});
		let { object, objectId } = attachment;
		clear({ object: "attachment", queryString: buildQueryString({ object, objectId, periodId }) });
		close();
	}, [attachment, clear, close, formAttachment, periodId, set]);
	return (
		<Modal close={close} {...props}>
			<ModalHeader>
				<ModalTitle>{translate("button.editAttachment")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<NameField name={`${parentName}.name`} />
				<FormCheck name={`${parentName}.allPeriods`}>{translate("edit.showInAllPeriods")}</FormCheck>
				{!allPeriodsFlag && (
					<FormSelectPeriod label={translate("options.periodName")} name={`${parentName}.periodId`} />
				)}
				<FormCheck name={`${parentName}.includeInBook`}>{translate("edit.includeInBriefingBook")}</FormCheck>
			</ModalBody>
			<ModalFooter>
				<Button color="default" onClick={close}>
					{translate("global.cancel")}
				</Button>
				<Button color="primary" type="button" onClick={editAttachment}>
					{translate("global.save")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
EditModal.propTypes = propTypes;
export default EditModal;
