import { useCallback, useMemo, useRef, useState } from "react";
import useContainerWidth from "./useContainerWidth";
import useOverflowXFlag from "./useOverflowXFlag";
import autoFitColumnWidths from "./autoFitColumnWidths";
import cloneDeep from "lodash/cloneDeep";

let useColumnWidthManager = ({ columns, containerId, measuredContainerWidth }) => {
	let [manuallySetColumnWidthList, setManualColumnWidthList] = useState([]);
	let containerWidth = useContainerWidth({ measuredContainerWidth, containerId });
	let { overflowXFlag, setOverflowXFlag } = useOverflowXFlag({ containerWidth });

	let previousColumnWidthList = useRef([]);

	let fillManuallySetColumnWidths = useCallback(
		(columnWidthList) => columnWidthList.map((columnWidth, i) => manuallySetColumnWidthList[i] ?? columnWidth),
		[manuallySetColumnWidthList]
	);

	let fillPreviouslyCalculatedColumnWidths = useCallback(
		(columnWidthList) => columnWidthList.map((columnWidth, i) => columnWidth ?? previousColumnWidthList.current[i]),
		[]
	);

	let columnWidthList = useMemo(() => {
		if (!columns?.[0]?.width) return;

		let widthList = columns?.map((column) => column.width);
		if (!measuredContainerWidth) return widthList;

		if (manuallySetColumnWidthList.length === 0) {
			widthList = autoFitColumnWidths({ columnWidthList: widthList, containerWidth });
		} else {
			widthList = fillManuallySetColumnWidths(widthList);

			let rightTableBorderHasBeenMovedFlag = !!manuallySetColumnWidthList[widthList.length - 1];
			if (rightTableBorderHasBeenMovedFlag) {
				widthList = fillPreviouslyCalculatedColumnWidths(widthList);
			} else {
				widthList = autoFitColumnWidths({ columnWidthList: widthList, containerWidth });
			}
		}

		previousColumnWidthList.current = widthList;
		setOverflowXFlag(widthList);
		return widthList;
	}, [
		columns,
		measuredContainerWidth,
		manuallySetColumnWidthList,
		setOverflowXFlag,
		containerWidth,
		fillManuallySetColumnWidths,
		fillPreviouslyCalculatedColumnWidths,
	]);

	let updateColumnWidthList = useCallback(
		(newSize, updatedColumnIndex) =>
			setManualColumnWidthList((list) => {
				let listCopy = [...list];
				listCopy[updatedColumnIndex] = newSize;
				return listCopy;
			}),
		[]
	);

	let columnListWithManuallySetWidthsRemoved = useMemo(() => {
		if (manuallySetColumnWidthList.length === 0) return columns;
		let columnList = cloneDeep(columns);
		if (columnList) {
			manuallySetColumnWidthList.forEach((x, i) => {
				if (x) {
					delete columnList?.[i]?.width;
				}
			});
		}
		return columnList;
	}, [columns, manuallySetColumnWidthList]);

	return {
		columnListWithManuallySetWidthsRemoved,
		columnWidthList,
		overflowXFlag,
		updateColumnWidthList,
	};
};

export default useColumnWidthManager;
