import Alert from "@clearpoint/old-theme/Alert/Alert";
import Column from "@clearpoint/old-theme/Column";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormSelectEmbedReportingPeriod from "@clearpoint/old-theme/Form/Select/Select.EmbedReportingPeriod";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import Input from "@clearpoint/old-theme/Input/Input";
import Row from "@clearpoint/old-theme/Row";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback, useMemo } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { sendToClipboard } from "@clearpoint/utils";

let embedUrlLookup = {
	demo: "https://embed-demo.clearpointstrategy.com",
	dev: "https://embed-dev.clearpointstrategy.com",
	localdev: "https://embed-dev.clearpointstrategy.com",
	preview: "https://embed-preview.clearpointstrategy.com",
	prod: "https://embed.clearpointstrategy.com",
	stage: "https://embed-stage.clearpointstrategy.com",
};

let ChartEmbedCodeDisplay = () => {
	let { chartId, _embedPeriodId, _embedWidth, _embedHeight } = useFormValue();
	let translate = useTranslate();
	let { formDefaultValue } = useFormContext();
	let { session } = useOldSession();
	let alreadyPublishedFlag = formDefaultValue.published;

	let embedCode = useMemo(() => {
		let env = session?.environment?.toLowerCase() || "dev";
		let url = embedUrlLookup[env];
		let src = url + "/?#/chart?chartId=" + chartId + "&periodId=" + (_embedPeriodId ?? -1);

		if (_embedWidth) {
			src += "&width=" + _embedWidth;
		}
		if (_embedHeight) {
			src += "&height=" + _embedHeight;
		}
		return '<iframe src="' + src + '"></iframe>';
	}, [_embedHeight, _embedPeriodId, _embedWidth, chartId, session?.environment]);

	let sendEmbedCodeToClipboard = useCallback(() => {
		sendToClipboard(embedCode);
	}, [embedCode]);

	return (
		<>
			<FormGroup title={translate("edit.chart.chartEmbedCode")}>
				{alreadyPublishedFlag && (
					<FormCheck name="updateEmbed">{translate("edit.chart.updateEmbeddedChart")}</FormCheck>
				)}
				<Input
					data-testid="embedCodeDisplay"
					readOnly
					rows={4}
					as="textarea"
					disabled
					value={embedCode}
					right={
						<IconButton
							padding="6px 24px !important"
							iconSize="large"
							name="clipboard"
							onClick={sendEmbedCodeToClipboard}
							outlineFlag
							color="white"
						/>
					}
				/>
				<StyleWrapper marginTop={theme.space}>
					<Alert color="info">{translate("manage.chart.embedWarning")}</Alert>
				</StyleWrapper>
				<FormSelectEmbedReportingPeriod label={translate("global.selectReportingPeriod")} name="_embedPeriodId" />
				<Row>
					<Column medium={6} small={12}>
						<FormInput
							placeholder={translate("global.auto")}
							label={translate("edit.chart.chartWidth")}
							type="number"
							min={0}
							name="_embedWidth"
						/>
					</Column>
					<Column medium={6} small={12}>
						<FormInput
							placeholder={translate("edit.chartSeries.chartDefault")}
							label={translate("edit.chart.chartHeight")}
							type="number"
							min={0}
							name="_embedHeight"
						/>
					</Column>
				</Row>
			</FormGroup>
		</>
	);
};

export default ChartEmbedCodeDisplay;
