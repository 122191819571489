import { useMemo } from "react";

import DropdownSplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess, useScorecardLockedFlag } from "@clearpoint/hooks";

import DropdownMenu from "./DropdownMenu";
import ButtonWrapper from "./wrappers/EditSplitButtonWrapper";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let EditSplitButton = () => {
	let checkAccess = useCheckAccess();
	let { access, object, scorecardId } = useFormValue();
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let { element } = useDetailPageData();
	let approvalPending = element?.approvalsPending?.length > 0;

	let editButtonType = useMemo(() => {
		let scorecardFlag = object === "scorecard";
		let adminAccessFlag =
			scorecardId && !scorecardLockedFlag && checkAccess({ access, action: "admin", scorecardId });
		let updateAccessFlag =
			scorecardId && !scorecardLockedFlag && checkAccess({ access, action: "update", scorecardId });

		if (scorecardFlag) return adminAccessFlag ? "editReport" : "pdf";
		return updateAccessFlag && !approvalPending ? "edit" : "pdf";
	}, [access, approvalPending, checkAccess, object, scorecardId, scorecardLockedFlag]);

	return (
		<ButtonWrapper editButtonType={editButtonType}>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<DropdownSplitButton data-testid="edit-split-button" icon={editButtonType}>
					<DropdownMenu editButtonType={editButtonType} />
				</DropdownSplitButton>
			</StyleWrapper>
		</ButtonWrapper>
	);
};
export default EditSplitButton;
