import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	excludeDistrictLanguageFlag: PropTypes.bool,
};

let SelectLanguage = ({ excludeDistrictLanguageFlag, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let localeList = get({ object: "locales" });
	let options = get({ object: "options" });
	let languageOptions = useMemo(() => {
		if (localeList === undefined || options === undefined) return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (!localeList) localeList = [];
		let languageOptions = localeList.map((locale) => ({ value: locale.localeId, label: locale.name }));
		if (excludeDistrictLanguageFlag) languageOptions = languageOptions.filter((x) => x.value !== options?.localeId);
		return languageOptions;
	}, [excludeDistrictLanguageFlag, localeList, options]);
	return (
		<FormSelect
			label={translate("myProfile.language")}
			options={languageOptions}
			placeholder={`${translate("login.selectLanguage")}...`}
			{...props}
		/>
	);
};

SelectLanguage.propTypes = propTypes;

export default SelectLanguage;
