import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString } from "@clearpoint/utils";

import Block from "../Block";
import Button from "../Button/Button";
import Input from "../Input/Input";
import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";
import Comment from "./Comment";


let propTypes = {
	close: PropTypes.func,
	editFlag: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number,
};
let Discussion = ({ close, object, objectId, editFlag }) => {
	let translate = useTranslate();
	let [value, setValue] = useState();
	let updateValue = useCallback((e) => setValue(e.target.value), []);
	let { clear, get, set } = useOldQueryStore();
	let discussionList =
		object && objectId ? get({ object: "comment", queryString: buildQueryString({ object, objectId }) }) : undefined;
	let addComment = useCallback(() => {
		set({
			object: "comment",
			queryString: buildQueryString({ object, objectId }),
			data: { comment: value },
			skipClearFlag: true,
		}).then(() => {
			setValue("");
			clear({ object: "comment", queryString: buildQueryString({ object, objectId }) });
		});
	}, [clear, object, objectId, set, value]);

	let addButtonDisabledFlag = !value?.replace(/\s+/m, "");

	return (
		<>
			{discussionList
				?.filter?.((x) => !x.deleted)
				.map((x) => (
					<Comment key={x.discussionId} comment={x} editFlag={editFlag} />
				))}
			{editFlag && (
				<>
					<Input
						as="textarea"
						placeholder={translate("global.addComment")}
						rows="2"
						value={value}
						onChange={updateValue}
					/>
					<Block textAlign="right">
						<StyleWrapper marginTop={theme.smallSpace}>
							<Button color="default" onClick={close} size="small">
								{translate("global.close")}
							</Button>
							<Button
								color="primary"
								marginLeft={theme.smallSpace}
								disabled={addButtonDisabledFlag}
								onClick={addComment}
								size="small"
							>
								{translate("global.addComment")}
							</Button>
						</StyleWrapper>
					</Block>
				</>
			)}
		</>
	);
};
Discussion.propTypes = propTypes;
export default Discussion;
