import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import http from "@clearpoint/services/httpService/index";
import getSetSessionData from "./useWatchQueryParameters.getSetSessionData";
import { useQueryParameters } from "@clearpoint/hooks";


let useWatchQueryParameters = ({ sessionReducer, getDataFlag }) => {
	let [{ page }, setSession] = sessionReducer;
	let { pathname } = useLocation();
	let { get } = useOldQueryStore();
	let profile = getDataFlag ? get({ object: "profile" }) : undefined;
	let scorecardList = getDataFlag ? get({ object: "scorecard" }) : undefined;
	let { layoutId, mode, object, objectId, periodId, scorecardId, sessionId, token, view } = useQueryParameters();
	let periodList = getDataFlag
		? get({ object: "period", queryString: scorecardId ? `scorecardId=${scorecardId}` : undefined })
		: undefined;
	let loadingFlag = !profile || !scorecardList || !periodList;
	useEffect(() => {
		if (pathname !== "/login" && page !== "/login" && pathname !== "/index/home") {
			http.cancel();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scorecardId, objectId, object, layoutId, pathname]);

	useEffect(() => {
		setSession(() => {
			let newSession = {};
			if (sessionId) newSession.sessionId = sessionId;
			if (token && pathname === "/print") {
				newSession.token = token;
			}
			return newSession;
		});
		// only update when auth updates
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionId, token]);

	useEffect(
		() => {
			if (loadingFlag) return;
			let setSessionData = getSetSessionData({
				layoutId,
				mode,
				object,
				objectId,
				periodId,
				periodList,
				profile,
				scorecardId,
				scorecardList,
				view,
			});
			setSession((oldSession) => setSessionData(oldSession));
		},
		// only update when query params or profile updates
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[layoutId, loadingFlag, mode, object, objectId, periodId, periodList, profile, scorecardId, view]
	);
};

export default useWatchQueryParameters;
