import Block from "../../../Block";
import HTML from "../../../HTML";
import StatusIcon from "../../../StatusIcon";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	statusFlag: PropTypes.bool,
	statusId: PropTypes.number,
	totalScore: PropTypes.number,
};

let FormFieldDisplayObjectiveScore = ({ statusFlag, statusId, totalScore }) => {
	let translate = useTranslate();

	return (
		<Block display="flex" height="min-content" justifyContent="space-between">
			{statusFlag && <StatusIcon statusId={statusId} />}
			<HTML>
				{totalScore === null
					? "0.00"
					: totalScore === 0
					? translate("global.notAvailable")
					: totalScore?.toFixed(2)}
			</HTML>
		</Block>
	);
};

FormFieldDisplayObjectiveScore.propTypes = propTypes;
export default FormFieldDisplayObjectiveScore;
