import Block from "@clearpoint/old-theme/Block";
import Chart from "@clearpoint/old-theme/Chart/Chart";
import Loading from "@clearpoint/old-theme/Loading/index";
import Pod from "@clearpoint/old-theme/Pod/Pod";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { memo } from "react";

let propTypes = {
	chartFormatData: PropTypes.object,
	chartLoadingFlag: PropTypes.bool,
	name: PropTypes.string,
	previewFailedFlag: PropTypes.bool,
};

let ChartPreviewInListItem = memo(({ chartFormatData, chartLoadingFlag, name, previewFailedFlag }) => {
	let translate = useTranslate();
	let renderChartFlag = !chartLoadingFlag && !previewFailedFlag;
	return (
		<Block height="388px">
			<Pod headerText={name}>
				{renderChartFlag ? (
					<Chart options={chartFormatData || {}} />
				) : (
					<Block height="300px" display="flex" justifyContent="center" alignItems="center">
						{previewFailedFlag ? <div>{translate("chart.previewError")}</div> : <Loading />}
					</Block>
				)}
			</Pod>
		</Block>
	);
});

ChartPreviewInListItem.propTypes = propTypes;
export default ChartPreviewInListItem;
