import Loading from "@clearpoint/old-theme/Loading/index";
import Row from "./Row";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import Table from "@clearpoint/old-theme/Table";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let headerCellPropTypes = {
	children: PropTypes.node,
};

let TableHeaderCell = ({ children }) => (
	<StyleWrapper wordBreak="normal" whiteSpace="pre-wrap">
		<th>{children}</th>
	</StyleWrapper>
);

let SeriesEvaluations = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { object, objectId, shadowMeasureId } = useFormValue();
	let { pagePeriodId } = useLocalState();

	let measureId = object === "measure" ? objectId : shadowMeasureId;

	let seriesList =
		pagePeriodId &&
		measureId &&
		get({ parent: "measure", parentId: measureId, object: "measureSeries", periodId: pagePeriodId });
	let statusList = get({ object: "status", queryString: "?includeNull" });

	let rowList = useMemo(
		() =>
			statusList &&
			seriesList
				?.filter((series) => series.criteria)
				.map((series) =>
					series.criteria.map((criterion, criterionIndex) => {
						let statusName = statusList.find((status) => status.statusId === criterion.statusId)?.name;

						return {
							calculation: criterion.calculation,
							criteria: criterion.criteria,
							highlightFlag: series.statusId === criterion.statusId,
							seriesName: series.name,
							spanCount: series.criteria.length,
							spanRowFlag: criterionIndex === 0,
							statusId: criterion.statusId,
							statusName,
						};
					})
				)
				?.flat(),
		[seriesList, statusList]
	);

	return !rowList ? (
		<Loading />
	) : rowList.length > 0 ? (
		<Table borderedFlag cpsSubTableFlag>
			<thead>
				<tr>
					<TableHeaderCell>{translate("global.series")}</TableHeaderCell>
					<TableHeaderCell>{translate("global.status")}</TableHeaderCell>
					<TableHeaderCell>{translate("global.calculation")}</TableHeaderCell>
					<TableHeaderCell>{translate("global.criteria")}</TableHeaderCell>
				</tr>
			</thead>
			<tbody>
				{rowList?.map((row) => (
					<Row key={row.seriesName + row.statusId} row={row} />
				))}
			</tbody>
		</Table>
	) : null;
};

TableHeaderCell.propTypes = headerCellPropTypes;
export default SeriesEvaluations;
