import { cloneDeep } from "lodash";

let splitEditAndUpdateFieldData = (fieldData) => {
	let editFieldData = {};
	let updateFieldData = {};
	let fieldDataCopy = cloneDeep(fieldData);
	for (let [key, field] of Object.entries(fieldDataCopy || {})) {
		if (field.updateMode) {
			updateFieldData[key] = field;
		} else {
			editFieldData[key] = field;
		}
	}
	return { editFieldData, updateFieldData };
};

export default splitEditAndUpdateFieldData;
