import EditDetailLayoutModal from "@components/Layouts/Shared/EditDetailLayoutModal";
import EditElementModalWindowOnClick from "@components/Elements/ModalWindow.EditElement.OnClick";
import GeneratePDFOnClick from "./GeneratePDFOnClick";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node.isRequired,
	editButtonType: PropTypes.oneOf(["edit", "editReport", "pdf"]).isRequired,
};

let EditSplitButtonWrapper = ({ children, editButtonType }) => {
	let { object, objectId } = useFormValue();
	let { element } = useDetailPageData();
	let elementName = useFormValue("name");
	let { pagePeriodId } = useLocalState();

	return editButtonType === "edit" ? (
		<EditElementModalWindowOnClick object={object} objectId={objectId}>
			{children}
		</EditElementModalWindowOnClick>
	) : editButtonType === "editReport" ? (
		<ModalWindowOnClick
			ModalWindow={EditDetailLayoutModal}
			elementName={elementName}
			periodId={pagePeriodId}
			scorecardId={element?.scorecardId}
		>
			{children}
		</ModalWindowOnClick>
	) : (
		<GeneratePDFOnClick>{children}</GeneratePDFOnClick>
	);
};

EditSplitButtonWrapper.propTypes = propTypes;
export default EditSplitButtonWrapper;
