import { useEffect, useRef } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import * as Sentry from "@sentry/react";
import { sleep } from "@clearpoint/utils";

let useSentryContext = (sessionLoadedFlag) => {
	let { session } = useOldSession() || {};
	let { getPromise } = useOldQueryStore();
	let getPromiseRef = useRef(getPromise);
	getPromiseRef.current = getPromise;
	let sessionRef = useRef(session);
	sessionRef.current = session;
	useEffect(() => {
		if (!sessionRef.current) return;
		let setSentryContext = async () => {
			let getData = async () => {
				let options = await getPromiseRef.current({ object: "options" });
				let profile = await getPromiseRef.current({ object: "profile" });
				return { options, profile };
			};
			let options, profile;
			while ([options, profile].includes(undefined)) {
				if (!sessionRef.current.success) return; // logout escape hatch
				({ options, profile } = await getData());
				await sleep(16);
			}
			Sentry.setUser({
				email: profile.email,
				id: profile.userId,
			});
			Sentry.setTag("districtId", options.districtId);
			Sentry.setTag("license", options.license?.edition);
			Sentry.setTag("organization", options.name);
			Sentry.setTag("username", profile.fullName);
			Sentry.setTag("premiumSupport", profile.premiumSupport);
			Sentry.setTag("userType", profile.userType);
		};
		if (sessionLoadedFlag) setSentryContext();
		// trigger only when session is loaded or login is completed (session.success)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionLoadedFlag, session?.success]);
};
export default useSentryContext;
