import Block from "../../Block";
import Chart from "../../Chart/Chart";
import ChartPodProvider from "./ChartPodProvider";
import Loading from "../../Loading";
import Pod from "../Pod";
import { useChartPodContext } from "../../Pod/ChartPod/ChartPodProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";

let propTypes = {
	chartId: PropTypes.number.isRequired,
	chartFormatData: PropTypes.object,
	CustomHeader: PropTypes.elementType,
	headerText: PropTypes.string,
	measureId: PropTypes.number,
	periodId: PropTypes.number,
	setChartFormatData: PropTypes.func,
};

let PodChart = ({
	chartId,
	chartFormatData,
	CustomHeader,
	headerText,
	measureId,
	periodId,
	setChartFormatData,
	...props
}) => (
	<ChartPodProvider
		chartFormatDataProp={chartFormatData}
		chartId={chartId}
		measureId={measureId}
		periodId={periodId}
		setChartFormatData={setChartFormatData}
	>
		<ChartPodContent CustomHeader={CustomHeader} headerText={headerText} measureId={measureId} {...props} />
	</ChartPodProvider>
);

let contentPropTypes = {
	CustomHeader: PropTypes.elementType,
	headerText: PropTypes.string,
	measureId: PropTypes.number,
};

let ChartPodContent = ({ CustomHeader, headerText, measureId, ...props }) => {
	let { get } = useOldQueryStore();
	let { chartFormatData, chartDescription, chartName, initialChartLoadedFlag } = useChartPodContext();
	let options = get({ object: "options" });
	let showDescriptionFlag = options?.options?.showChartDescriptions;
	let chartHeight = chartFormatData?.chart?.height || "300px";
	// console.log(chartFormatData);

	return (
		<Pod CustomHeader={CustomHeader} headerText={headerText || chartName} {...props}>
			<Block height={chartHeight}>
				{measureId && initialChartLoadedFlag ? (
					<>
						<Chart options={chartFormatData} />
						{showDescriptionFlag && <Block>{chartDescription}</Block>}
					</>
				) : (
					<Loading />
				)}
			</Block>
		</Pod>
	);
};

ChartPodContent.propTypes = contentPropTypes;
PodChart.propTypes = propTypes;
export default PodChart;
