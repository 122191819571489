import { emptyArray, emptyObject } from "@clearpoint/utils";

import { formatSeriesNumber, getChartType } from "../utils";
import createDefaultSeriesList from "./createDefaultSeriesList";
import createGaugeSeriesList from "./createGaugeSeriesList";
import createPieSeriesList from "./createPieSeriesList";
import createPolarSeriesList from "./createPolarSeriesList";
import createRadialSeriesList from "./createRadialSeriesList";
import createSolidGaugeSeriesList from "./createSolidGaugeSeriesList";
import createTransposedSeriesList from "./createTransposedSeriesList";

let addTooltips = (seriesList, chartData) => {
	let {
		chartFormat: {
			chart: { polar: polarFlag, type },
			tooltip: tooltipFormat,
		},
	} = chartData;

	let tooltip = {
		headerFormat: "",
		pointFormatter() {
			let { valuePrefix, valueSuffix } = tooltipFormat || {};
			let numberFormat = polarFlag ? this.numberFormat : this.series.options.numberFormat;
			let value = `${valuePrefix || ""}${formatSeriesNumber(this.y, numberFormat)}${valueSuffix || ""}`;
			let valueLabel = type === "gauge" || type === "solidgauge" ? this.options.name : this.category;
			if (valueLabel) {
				return `<b>${this.series.name}</b><br/>${valueLabel}: ${value}<br/>`;
			}

			return `<b>${this.series.name}</b><br/>`;
		},
	};

	return seriesList.map((series) => ({ tooltip, ...series }));
};

// moved lineWidth doubling logic from stored proc to frontend so that chart json matches with lineWidth fields
let doubleLineWidths = (seriesList) =>
	seriesList.map((series) => (series.lineWidth ? { ...series, lineWidth: series.lineWidth * 2 } : series));

let configureChartSeries = (chartData, measureGridList) => {
	let {
		chartFormat: { series: seriesList } = { series: emptyArray },
		chartPeriods: chartPeriodList,
		colors: colorList,
		chartFormat,
		reportPeriods: reportPeriodList,
		sortSeries: sortDirection,
	} = chartData ?? emptyObject;
	let type = getChartType(chartData);
	let createSeriesListLookup = {
		default: createDefaultSeriesList,
		gauge: createGaugeSeriesList,
		solidgauge: createSolidGaugeSeriesList,
		pie: createPieSeriesList,
		polar: createPolarSeriesList,
		radial: createRadialSeriesList,
		transposed: createTransposedSeriesList,
	};
	seriesList = createSeriesListLookup[type]({
		chartFormat,
		seriesList,
		measureGridList,
		chartPeriodList,
		colorList,
		sortDirection,
		reportPeriodList,
	});

	seriesList = addTooltips(seriesList, chartData);
	seriesList = doubleLineWidths(seriesList);

	return seriesList;
};

export default configureChartSeries;
