import { calculateStartAndEndDate } from "@clearpoint/utils";

let parseDateArguments = ({ endDate, startDate, value, ...props }) => {
	if (!startDate && props.ganttStartDate) {
		startDate = props.ganttStartDate;
	}
	if (!endDate && props.ganttEndDate) {
		endDate = props.ganttEndDate;
	}
	let { startDate: calculatedStartDate, endDate: calculatedEndDate } = value ? calculateStartAndEndDate(value) : {};
	if (!startDate) startDate = calculatedStartDate;
	if (!endDate) endDate = calculatedEndDate;
	return { startDate, endDate };
};

export default parseDateArguments;
