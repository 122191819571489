import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { renderToString } from "react-dom/server";

import { useCheckFeature, useUserId } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject, REACTIONS } from "@clearpoint/utils";

import Badge from "../../Badge/Badge";
import Block from "../../Block";
import IconButton from "../../Button/Button.Icon";
import ReactionButton from "../../Button/Button.Reaction";
import HTML from "../../HTML";
import Loading from "../../Loading/index";
import StyleWrapper from "../../StyleWrapper";
import { theme } from "../../Theme";
import Tooltip from "../../Tooltip";
import createUserListText from "./createUserListText";
import setReaction from "./DisplayComment.setReaction";
import SelectReactions from "./SelectReactions";

let propTypes = {
	commentData: PropTypes.shape({
		comment: PropTypes.string,
		commentId: PropTypes.number,
		discussionId: PropTypes.number,
		resolved: PropTypes.bool,
	}),
	commentValue: PropTypes.string,
	updatingFlag: PropTypes.bool,
};

let DisplayComment = ({ commentData, commentValue, updatingFlag }) => {
	let checkFeature = useCheckFeature();
	let reactionsFlag = checkFeature(REACTIONS);
	let translate = useTranslate();
	let [showReactions, setShowReactions] = useState();
	let { comment, commentId, discussionId, reactions } = commentData;
	let { get, set } = useOldQueryStore();
	let currentUserId = useUserId();
	let userList = get({ object: "user" });

	let commentText = updatingFlag ? commentValue : comment;
	let displayComment = useMemo(
		() =>
			commentText?.replace(/&amp;/g, "&").replaceAll(/\[@\d+\|(.*?)]/g, (_token, name) =>
				renderToString(
					<StyleWrapper verticalAlign="text-top" textOverflow="ellipsis" overflow="hidden" maxWidth="200px">
						<Badge color="darkTeal" size="small" discussionBadgeFlag>
							{name}
						</Badge>
					</StyleWrapper>
				)
			),
		[commentText]
	);

	if (!displayComment) displayComment = commentText || comment;
	let showReactionEmojis = useCallback(() => {
		setShowReactions(true);
	}, []);

	let [reactionState, setReactionState] = useState(reactions);
	let [reactionInProgress, setReactionInProgress] = useState(false);

	useEffect(() => {
		if (reactionState === undefined) {
			setReactionState(reactions);
		}
	}, [reactions, reactionState]);

	let setReactionHandler = useCallback(
		(name) => {
			setReaction({
				commentId,
				currentUserId,
				discussionId,
				name,
				reactionInProgress,
				reactionState,
				set,
				setReactionInProgress,
				setReactionState,
			});
		},
		[commentId, currentUserId, discussionId, reactionInProgress, reactionState, set]
	);

	return reactionState ? (
		<Block
			display="flex"
			flexDirection="column"
			margin={`5px 0px 0px 38px`}
			fontSize="14px"
			$style={`p { vertical-align: top; line-height: 20px; } .badge {padding: 2px 6px 2px 6px; line-height: 12px;}`}
		>
			<HTML>{displayComment}</HTML>
			{reactionsFlag ? (
				<SelectReactions
					commentId={commentId || discussionId}
					display={showReactions ? "flex" : "none"}
					setShowReactions={setShowReactions}
					setReactionHandler={setReactionHandler}
				/>
			) : null}
			{reactionsFlag && (
				<Block marginTop={theme.smallSpace} display="flex" flexDirection="row" alignItems="center">
					<IconButton
						padding="5px"
						id={`reaction-button-${commentId || discussionId}`}
						name="smile"
						onClick={showReactionEmojis}
					/>
					{reactionState && (
						<Block marginLeft={theme.tinySpace} display="flex" flexDirection="row">
							{Object.keys(reactionState).map((reaction, index) => {
								return (
									reactionState?.[reaction]?.length > 0 && (
										<StyleWrapper key={index} marginRight={theme.tinySpace}>
											<Tooltip
												placement="top"
												text={translate("tooltips.reactions", {
													users: createUserListText(reactionState[reaction], userList),
													emoji: translate(`reaction.${reaction}`),
												})}
											>
												<ReactionButton
													currentUserFlag={reactionState[reaction]?.includes(+currentUserId)}
													id={index + ""}
													name={reaction}
													size="small"
													padding="5px"
													commentId={commentId || discussionId}
													reactedFlag
													setReactionHandler={setReactionHandler}
												>
													{reactionState[reaction]?.length}
												</ReactionButton>
											</Tooltip>
										</StyleWrapper>
									)
								);
							})}
						</Block>
					)}
				</Block>
			)}
			{reactions?.length > 0 && (
				<Block marginTop={theme.smallSpace}>
					{reactions.map((reaction, index) => {
						return (
							reaction.users?.length > 0 && (
								<StyleWrapper key={index} margin="2px">
									<Tooltip
										placement="top"
										text={translate("tooltips.reactions", {
											users: createUserListText(reaction.users, userList),
											emoji: translate(`reaction.${reaction.name}`),
										})}
									>
										<ReactionButton
											currentUserFlag={reaction.users?.includes(+currentUserId)}
											id={index + ""}
											name={reaction.name}
											size="small"
											padding="3px 6px"
											reactedFlag
										>
											{reaction.users?.length}
										</ReactionButton>
									</Tooltip>
								</StyleWrapper>
							)
						);
					})}
				</Block>
			)}
		</Block>
	) : (
		null
	);
};

DisplayComment.propTypes = propTypes;
export default DisplayComment;