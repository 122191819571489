import Block from "../../Block";
import Input from "../../Form/Input/Input";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Slider from "../../Slider/Slider";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { theme } from "../../Theme";

let propTypes = {
	formatNumber: PropTypes.func,
	hideThumbValueFlag: PropTypes.bool,
	inputPosition: PropTypes.oneOf(["right", "top"]),
	max: PropTypes.number,
	min: PropTypes.number,
	name: PropTypes.string,
	onAfterChange: PropTypes.func,
	onChange: PropTypes.func,
	sliderHeight: PropTypes.string,
	step: PropTypes.number,
	valueLabelPosition: PropTypes.string,
};

let FormSlider = ({
	formatNumber,
	hideThumbValueFlag,
	inputOnlyFlag,
	inputPosition = "top",
	label,
	max = 100,
	min = 0,
	name,
	onAfterChange,
	onBeforeChange,
	onChange,
	sliderHeight,
	step,
	valueLabelPosition,
	...props
}) => {
	let formValue = useFormValue(name);
	let { setFormValue } = useFormContext();
	let valueRef = useRef();
	valueRef.current = formValue;
	let [sliderValue, setSliderValue] = useState(min);
	let debouncedSetFormValue = useCallback(debounce(setFormValue, 100, { maxWait: 100 }), [setFormValue]); // eslint-disable-line react-hooks/exhaustive-deps
	let debouncedOnAfterChange = useMemo(() => debounce((value) => onAfterChange(value), 1000), []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (typeof formValue === "number" && sliderValue !== formValue) {
			setTimeout(() => {
				if (valueRef.current !== sliderValue) {
					setSliderValue(valueRef.current);
				}
			}, 100);
		}
	}, [formValue, sliderValue]);

	let onSliderChange = useCallback(
		(value) => {
			setSliderValue(value);
			debouncedSetFormValue(name, value);
			if (onBeforeChange) onBeforeChange();
			if (onChange) onChange();
			if (value >= min && value <= max && onAfterChange) {
				debouncedOnAfterChange.cancel();
				debouncedOnAfterChange(value);
			}
		},
		[debouncedOnAfterChange, debouncedSetFormValue, max, min, name, onAfterChange, onBeforeChange, onChange]
	);

	let onInputChange = useCallback(
		(e) => {
			let newSliderValue = +e.target.value;
			setSliderValue(newSliderValue);
			if (onBeforeChange) onBeforeChange();
			if (onChange) onChange();
			if (newSliderValue >= min && newSliderValue <= max && onAfterChange) {
				debouncedOnAfterChange.cancel();
				debouncedOnAfterChange(newSliderValue);
			}
		},
		[debouncedOnAfterChange, max, min, onAfterChange, onBeforeChange, onChange]
	);

	return inputPosition === "top" ? (
		<Block marginBottom={theme.space}>
			<Input
				data-testid="percent"
				name={name}
				onChange={onInputChange}
				type="number"
				label={label}
				max={Math.max(min, max)}
				min={Math.min(min, max)}
				step="any"
				{...props}
			/>
			<Slider
				{...props}
				displayValue={formValue}
				formatNumber={formatNumber}
				height={sliderHeight}
				hideThumbValueFlag={hideThumbValueFlag}
				min={min}
				max={max}
				onChange={onSliderChange}
				onAfterChange={onAfterChange}
				onBeforeChange={onBeforeChange}
				step={step}
				value={sliderValue}
				valueLabelPosition={valueLabelPosition}
			/>
		</Block>
	) : inputPosition === "right" ? (
		<Block display="flex" alignItems="center" justifyContent="space-between">
			{!inputOnlyFlag && (
				<Slider
					{...props}
					displayValue={formValue}
					formatNumber={formatNumber}
					height={sliderHeight}
					hideThumbValueFlag={hideThumbValueFlag}
					min={min}
					max={max}
					onChange={onSliderChange}
					onAfterChange={onAfterChange}
					onBeforeChange={onBeforeChange}
					step={step}
					value={sliderValue}
					valueLabelPosition={valueLabelPosition}
				/>
			)}
			<Block
				flexGrow={inputOnlyFlag ? 0 : 1}
				flexShrink={1}
				flexBasis="130px"
				marginLeft={theme.smallSpace}
				marginTop={valueLabelPosition === "top" && theme.smallSpace}
			>
				<Input
					data-testid="percent"
					name={name}
					onChange={onInputChange}
					type="number"
					max={Math.max(min, max)}
					min={Math.min(min, max)}
					step="any"
					marginBottom="0"
					{...props}
				/>
			</Block>
		</Block>
	) : null;
};

FormSlider.propTypes = propTypes;

export default FormSlider;
