import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import { useUnmount } from "react-use";
import debounce from "lodash/debounce";
import { useReportFilter } from "@clearpoint/hooks";


let SearchInput = () => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { layout } = useLocalState();
	let { setReportFilter } = useReportFilter();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let debouncedSetFilter = useCallback(
		debounce((e) => {
			setReportFilter(encodeURIComponent(e.target.value));
		}, 500),
		[setReportFilter]
	);
	useUnmount(() => {
		setFormValue("_filter", null);
	});
	return (
		<FormInput
			className="filter-grid"
			flexGrowFlag
			marginBottom="0px"
			name="_filter"
			onChange={debouncedSetFilter}
			placeholder={translate("search.findInElement", { element: layout.name || "layout.dashboard" })}
		/>
	);
};
export default SearchInput;
