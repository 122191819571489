import Block from "@clearpoint/old-theme/Block";
import Discussion from "@clearpoint/old-theme/Discussion/index";
import Loading from "@clearpoint/old-theme/Loading/index";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";
import Map from "./Map";
import Toolbar from "./Toolbar";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { useQueryParameters } from "@clearpoint/hooks";

let propTypes = {
	skipImageLoadFlag: PropTypes.bool,
};
let ViewMap = ({ skipImageLoadFlag }) => {
	let { get } = useOldQueryStore();
	let { exportFlag, object, objectId, periodId } = useOldSession().session;
	let map = object === "map" && objectId && periodId && get({ object, objectId, periodId });
	let loadingFlag = !map;
	let { commentId } = useQueryParameters();

	return loadingFlag ? (
		<Loading />
	) : (
		<LocalStateProvider
			discussionVisible={!!commentId}
			editMapModalWindowVisible={false}
			editObjectId={map?.mapId}
			map={map}
			mapOptionsModalVisible={false}
			pageWidthTransitionFlag={false}
			skipImageLoadFlag={skipImageLoadFlag}
		>
			<Block display="flex" flex="1 0 1px" height="100%" maxWidth="100%" overflowX="hidden">
				<Block
					height={exportFlag ? undefined : "100%"}
					className="avoid-page-break"
					display="flex"
					flexDirection="column"
					width="100%"
				>
					<Toolbar />
					<Map />
				</Block>
				<Discussion object="map" objectId={objectId} />
			</Block>
		</LocalStateProvider>
	);
};

ViewMap.propTypes = propTypes;

export default ViewMap;
