import {
	BRIEFING_BOOK_ENHANCEMENTS,
	CLEARBOT,
	CUSTOM_SCRIPT_SCHEDULES,
	DISCUSSION_BAR,
	DISCUSSION_POD,
	POD_WIDTHS,
	REACTIONS,
} from "./featureLookup";
import getEnvironment from "./getEnvironment";

// oldFeatureFlagList: features that are enabled when new completed feature set is toggled off.
// featureFlagList: features that are enabled when new completed feature set is toggled on

let featureFlagListLookupByEnvironment = {
	dev: {
		oldFeatureFlagList: [],
		incompleteFeatureFlagList: [CUSTOM_SCRIPT_SCHEDULES, DISCUSSION_POD, POD_WIDTHS, BRIEFING_BOOK_ENHANCEMENTS],
		featureFlagList: [CLEARBOT, DISCUSSION_BAR, REACTIONS],
	},
	stage: {
		oldFeatureFlagList: [DISCUSSION_POD],
		incompleteFeatureFlagList: [],
		featureFlagList: [CLEARBOT, DISCUSSION_BAR, REACTIONS],
	},
	prod: {
		oldFeatureFlagList: [DISCUSSION_POD],
		incompleteFeatureFlagList: [],
		featureFlagList: [CLEARBOT, DISCUSSION_BAR, REACTIONS],
	},
};
featureFlagListLookupByEnvironment.preview = featureFlagListLookupByEnvironment.stage;
featureFlagListLookupByEnvironment.demo = featureFlagListLookupByEnvironment.prod;
let environment = getEnvironment();
let featureFlagListLookup = featureFlagListLookupByEnvironment[environment] || featureFlagListLookupByEnvironment.dev;
export default featureFlagListLookup;
