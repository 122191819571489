import Button from "./Button";
import Image from "../Image";
import StyleWrapper from "../StyleWrapper";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useHoverFlag } from "@clearpoint/hooks";


let propTypes = {
	className: PropTypes.string,
	commentId: PropTypes.number,
	currentUserFlag: PropTypes.bool,
	iconSize: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
	padding: PropTypes.string,
	reactedFlag: PropTypes.bool,
	setReactionHandler: PropTypes.func,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large", "huge"]),
};

let defaultProps = {
	size: "tiny",
};

let reactionIconSrcLookup = {
	thumbsUp: "https://s3.us-east-2.amazonaws.com/assets.clearpointstrategy.com/images/thumbsUp-emoji.png",
	thumbsDown: "https://s3.us-east-2.amazonaws.com/assets.clearpointstrategy.com/images/thumbsDown-emoji.png",
	heart: "https://s3.us-east-2.amazonaws.com/assets.clearpointstrategy.com/images/heart-emoji.png",
	laugh: "https://s3.us-east-2.amazonaws.com/assets.clearpointstrategy.com/images/laugh-emoji.png",
	surprised: "https://s3.us-east-2.amazonaws.com/assets.clearpointstrategy.com/images/surprised-emoji.png",
	hooray: "https://s3.us-east-2.amazonaws.com/assets.clearpointstrategy.com/images/hooray-emoji.png",
};

let ReactionButton = ({
	children,
	className,
	commentId,
	currentUserFlag,
	iconSize,
	name,
	onClick,
	padding,
	reactedFlag,
	setReactionHandler,
	size,
	...props
}) => {
	let { hoverFlag, onMouseOver, onMouseOut } = useHoverFlag();
	let translate = useTranslate();

	let handleOnClick = useCallback(() => {
		setReactionHandler(name);
		if (onClick) onClick();
	}, [name, onClick, setReactionHandler]);

	return (
		<Button
			border={!reactedFlag ? "none !important" : `1px solid ${theme.lightGray}`}
			color="white"
			onClick={handleOnClick}
			size={size}
			padding={padding || "1px 3px"}
			noHoverFlag
			noShadowFlag
			style={{ display: "flex", justfiyContent: "center", alignItems: "center" }}
			{...props}
		>
			<Image
				alt={name}
				title={translate(`reaction.${name}`)}
				style={{
					transform: hoverFlag ? "scale(1.4)" : "scale(1)",
					transition: "transform 0.3s ease",
				}}
				src={reactionIconSrcLookup[name]}
				maxWidth={iconSize || "13px"}
				maxHeight={iconSize || "13px"}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			/>
			<StyleWrapper
				marginLeft={theme.microSpace}
				fontSize="10pt"
				color={currentUserFlag ? theme.primary : "inherit"}
			>
				{children}
			</StyleWrapper>
		</Button>
	);
};

ReactionButton.propTypes = propTypes;
ReactionButton.defaultProps = defaultProps;

export default ReactionButton;
