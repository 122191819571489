import { useEffect } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

let useAccountStyles = () => {
	let { getStoredValue } = useOldQueryStore();

	let { options } = getStoredValue({ object: "options" }) || emptyObject;
	let { customAccountCSS } = options || emptyObject;

	useEffect(() => {
		if (!customAccountCSS) return;
		let customCSSElement = document.querySelector("#customAccountCSS");
		if (customCSSElement) {
			customCSSElement.remove();
		}

		let styleElement = document.createElement("style");
		styleElement.id = "customAccountCSS";
		styleElement.innerHTML = customAccountCSS;
		document.head.appendChild(styleElement);
	}, [customAccountCSS]);
};

export default useAccountStyles;
