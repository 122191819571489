import Block from "@clearpoint/old-theme/Block";
import { theme } from "@clearpoint/old-theme/Theme";

import { emptyObject } from "@clearpoint/utils";

import PageList from "../Detail/PageList";
import MeasureGridExcel from "./MeasureGridExcel";
import PrintTitle from "./PrintTitle";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let PrintDisplay = () => {
	let { loadingFlag } = useLocalState();
	let { excelFlag } = useOldSession().session;
	let { layout } = useDetailPageData();

	let { hideTitle: hideTitleFlag } = layout?.reportOptions || emptyObject;

	if (loadingFlag) return null;

	return excelFlag ? (
		<MeasureGridExcel />
	) : (
		<>
			<Block height="100%" padding={`0 ${theme.smallSpace}`}>
				{!hideTitleFlag && <PrintTitle />}
				<Block
					$style={`
				// Prevent margin collapse between top of PageList and bottom of PrintTitle
				&::before {
					content: " ";
					display: table;
					break-after: avoid;
				}
				
				.detail-page-pod:not(:first-of-type) {
					break-inside: avoid !important;
				  }
				`}
				>
					<PageList />
				</Block>
			</Block>
			<Block $style="clear: both;" />
		</>
	);
};

export default PrintDisplay;
