import Block from "../../Block";
import Button from "../../Button/Button";
import Grid from "../../Grid/Grid";
import StyleWrapper from "../../StyleWrapper";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import useRowControls from "./Grid.useRowControls";
import { useSeriesGridData } from "./Series/Provider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import getDeepValue from "lodash/get";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import setDeepValue from "lodash/set";
import { theme } from "../../Theme";
import { emptyArray, emptyObject } from "@clearpoint/utils";
import { useOnEvent, usePrevious } from "@clearpoint/hooks";

let defaultProps = {
	addNewRowOnEditFlag: true,
	initialLength: 5,
	rowHeaders: false,
	width: "100%",
	height: "150",
	stretchHorizontal: "all",
};

let propTypes = {
	addNewRowOnEditFlag: PropTypes.bool,
	afterOnCellMouseDown: PropTypes.func,
	className: PropTypes.string,
	columnHeaders: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string, PropTypes.bool]),
	columnKeyList: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			validation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		})
	),
	defaultValue: PropTypes.array,
	disableHiddenColumnsFlag: PropTypes.bool,
	errorColumn: PropTypes.number,
	initialLength: PropTypes.number,
	name: PropTypes.string.isRequired,
	noControlsFlag: PropTypes.bool,
	onChange: PropTypes.func,
};

let FormGrid = ({
	addNewRowOnEditFlag,
	afterOnCellMouseDown,
	className,
	columnHeaders,
	columnKeyList,
	defaultValue,
	disableHiddenColumnsFlag,
	errorColumn,
	initialLength,
	name,
	noControlsFlag,
	onChange,
	...props
}) => {
	let translate = useTranslate();
	let formValue = useFormValue(name) || emptyArray;
	let { formDirty, formError, setFormDefaultValue, setFormTouched, setFormValidation, setFormValue, formValueRef } =
		useFormContext();
	let previousDefaultValue = usePrevious(defaultValue);
	let selectedRowRef = useRef();
	let dirtyFlag = getDeepValue(formDirty, name);
	let { addRow, removeRow, createRow } = useRowControls(columnKeyList, initialLength, name, selectedRowRef);

	useEffect(() => {
		// update default value
		if (
			(defaultValue !== undefined || previousDefaultValue !== undefined) &&
			!isEqual(defaultValue, previousDefaultValue)
		) {
			setFormDefaultValue(name, defaultValue);
			if (!dirtyFlag) {
				setFormValue(name, defaultValue);
			}
		}
	}, [defaultValue, dirtyFlag, name, previousDefaultValue, setFormDefaultValue, setFormValue]);

	let errorMessageOverride = useMemo(() => {
		return props.errorMessageOverride ? props.errorMessageOverride : (errorMessage) => errorMessage;
	}, [props.errorMessageOverride]);

	let hiddenColumns = useMemo(() => {
		if (disableHiddenColumnsFlag || !errorColumn || formError[name]) return undefined;
		return { columns: [errorColumn], indicators: false };
	}, [disableHiddenColumnsFlag, errorColumn, formError, name]);

	// add initial rows
	useEffect(() => {
		if (formValue.length === 0 && !defaultValue) {
			let newRows = [];
			for (let i = 0; i < initialLength; i++) {
				newRows.push(createRow());
			}
			setFormValue(name, newRows);
		}
	}, [columnKeyList, createRow, defaultValue, formValue, initialLength, name, setFormValue]);

	//update grid validation
	useEffect(() => {
		columnKeyList?.forEach((keyObject) => {
			let keyValidation = keyObject?.validation || keyObject?._validation;
			if (!keyValidation) return;
			let isStructValidationFlag = isObject(keyValidation) && !isArray(keyValidation);
			let validation = isStructValidationFlag ? keyValidation : { [keyValidation]: true };

			for (let [index, item] of formValue.entries()) {
				let hasValueFlag = Object.values(item).some((value) => value !== "");
				let fieldKey = `${name}.${index}.${keyObject.key}`;
				setFormValidation(fieldKey, hasValueFlag ? { _validation: validation } : undefined);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnKeyList, formValue, name]);

	let data = useMemo(
		() =>
			columnKeyList &&
			formValue.map((row, rowIndex) => {
				let rowArray = [];
				columnKeyList.forEach((keyObject) => {
					rowArray.push(row[keyObject.key]);
				});
				if (errorColumn && formError[name]) {
					if (formError[name][rowIndex]) {
						let errorMessage = Object.values(formError[name][rowIndex])[0];
						let errorKey = Object.keys(formError[name][rowIndex])[0];
						rowArray[errorColumn] = errorMessageOverride(errorMessage, errorKey);
					} else {
						let validFlag = rowArray.slice(0, errorColumn).every((x) => !!x);
						rowArray[errorColumn] = validFlag ? translate("manage.users.valid") : "";
					}
				}
				return rowArray;
			}),
		[columnKeyList, errorColumn, errorMessageOverride, formError, formValue, name, translate]
	);

	let { statusList } = useSeriesGridData() || emptyObject;

	let formResetting = useRef(false);
	let resetForm = useCallback(() => {
		if (formResetting.current) return;
		formResetting.current = true;
		setTimeout(() => {
			formResetting.current = false;
		}, 10);
	}, []);
	useOnEvent({ eventName: "resetForm", handlerFunction: resetForm });

	let updateForm = useCallback(
		(changes, source) => {
			if (formResetting.current) return;
			if (changes && changes.length > 0) {
				let newFormState = cloneDeep(formValueRef.current);
				changes.forEach((change) => {
					let [row, column, originalValue, newValue] = change;
					if (newValue === originalValue) return;
					if (newFormState[row] && statusList && column === "statusName") {
						let status = statusList.find((x) => x.name === newValue);
						if (status) {
							newFormState[row].statusIcon = `<img src="${status?.image}" width=16 height=16 />`;
							newFormState[row].statusId = status?.statusId;
						}
						setFormValue("_seriesData", newFormState);
					}
					column = columnKeyList ? columnKeyList[column]?.key : column;
					setDeepValue(newFormState, `${row}.${column}`, newValue);
				});
				setFormValue(name, newFormState);
				if (
					addNewRowOnEditFlag &&
					Object.values(newFormState).every((item) => item && Object.values(item).some((value) => value !== ""))
				)
					addRow(newFormState);
				if (onChange) onChange({ changes, source, newFormState, setFormValue });
				setFormTouched(name, false);
			}
		},
		[
			formValueRef,
			setFormValue,
			name,
			addNewRowOnEditFlag,
			addRow,
			onChange,
			setFormTouched,
			statusList,
			columnKeyList,
		]
	);

	let value = useMemo(() => cloneDeep(formValue), [formValue]);

	let handleMouseDown = useCallback(
		(e, cell, ...args) => {
			setFormTouched(name, true);
			selectedRowRef.current = cell.row;
			if (afterOnCellMouseDown) afterOnCellMouseDown(e, cell, ...args);
		},
		[afterOnCellMouseDown, name, setFormTouched]
	);

	return (
		<StyleWrapper $style={`.handsontable table thead th div, .handsontable table tbody td	{ font-size: 16px; }`}>
			<Grid
				allowRowInsertionFlag
				afterOnCellMouseDown={handleMouseDown}
				hiddenColumns={hiddenColumns}
				data={data || value}
				columnHeaders={columnHeaders}
				afterChange={updateForm}
				className={classNames(className, `grid-${name}`)}
				{...props}
			/>
			{!noControlsFlag && (
				<Block textAlign="right" marginTop={theme.tinySpace}>
					<Button color="primary" size="small" marginTop="10px" marginRight={theme.tinySpace} onClick={removeRow}>
						{translate("manage.users.bulkRemoveRow")}
					</Button>
					<Button color="primary" size="small" marginTop="10px" onClick={addRow}>
						{translate("manage.users.bulkAddRow")}
					</Button>
				</Block>
			)}
		</StyleWrapper>
	);
};

FormGrid.defaultProps = defaultProps;
FormGrid.propTypes = propTypes;

export default FormGrid;
