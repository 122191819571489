import FormSelectLegendPosition from "../../Form/Select/Select.LegendPosition";
import SelectLegend from "../../Form/Select/Select.Legend";
import { useCallback } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";

let ShowLegend = ({ name, parentName, ...props }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let { clearFormValue } = useFormContext();
	let clearLegendPosition = useCallback(() => {
		clearFormValue(`${parentName}.legendPosition`);
	}, [clearFormValue, parentName]);
	return (
		<>
			<SelectLegend onChange={clearLegendPosition} data-testid="showLegend" name={name} {...props} />
			{value === true && (
				<FormSelectLegendPosition
					label={translate("manage.chart.selectLegendPosition")}
					name={`${parentName}.legendPosition`}
					placeholder={translate("manage.chart.selectLegendPosition") + "..."}
				/>
			)}
		</>
	);
};
export default ShowLegend;
