import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import Column from "../../Column";
import FormError from "../../Form/Form.Error";
import useFormValue from "../../Form/hooks/useFormValue";
import Row from "../../Row";
import { theme } from "../../Theme";
import EndDate from "./EndDate";
import StartDate from "./StartDate";

let propTypes = {
	disabled: PropTypes.bool,
	parentName: PropTypes.string,
	object: PropTypes.string,
	elementNameInForm: PropTypes.string,
	endDateNameInForm: PropTypes.string,
	group: PropTypes.string,
	startDateNameInForm: PropTypes.string,
};

let defaultProps = {
	endDateNameInForm: "endDate",
	startDateNameInForm: "startDate",
};

let FormFieldStartAndEndDate = ({
	disabled: disabledProp,
	parentName,
	elementNameInForm,
	endDateNameInForm,
	group,
	object: objectProp,
	startDateNameInForm,
}) => {
	let translate = useTranslate();

	startDateNameInForm = `${parentName ? parentName + "." : ""}${startDateNameInForm}`;
	endDateNameInForm = `${parentName ? parentName + "." : ""}${endDateNameInForm}`;
	let startDate = useFormValue(startDateNameInForm);
	let endDate = useFormValue(endDateNameInForm);

	let { hasChildren: hasChildrenFlag, linkMilestoneDates, object } = useFormValue(elementNameInForm || "");
	let milestoneDependencyFlag = object === "initiative" && linkMilestoneDates === 1;
	let initiativeDependencyFlag = object === "initiative" && linkMilestoneDates === 2;
	let subMilestoneDependencyFlag = object === "milestone" && linkMilestoneDates === 1 && hasChildrenFlag;
	if (objectProp) object = objectProp;

	let disabled = disabledProp || milestoneDependencyFlag || subMilestoneDependencyFlag;

	let dependencyWarning = initiativeDependencyFlag
		? translate("edit.element.linkMilestoneShift")
		: milestoneDependencyFlag
		? translate("edit.element.linkMilestoneWarning")
		: subMilestoneDependencyFlag
		? translate("edit.element.linkChildMilestoneWarning")
		: null;

	return (
		<Row>
			<Column large={6}>
				<StartDate
					disabled={disabled}
					endDate={endDate}
					group={group}
					name={startDateNameInForm}
					object={object}
					noTimeFlag
				/>
			</Column>
			<Column large={6}>
				<EndDate
					disabled={disabled}
					group={group}
					name={endDateNameInForm}
					object={object}
					noTimeFlag
					startDate={startDate}
				/>
			</Column>
			{dependencyWarning && (
				<Column>
					<FormError errorMessage={dependencyWarning} margin={`-${theme.tinySpace} 0 ${theme.mediumSpace}`} />
				</Column>
			)}
		</Row>
	);
};

FormFieldStartAndEndDate.defaultProps = defaultProps;
FormFieldStartAndEndDate.propTypes = propTypes;
export default FormFieldStartAndEndDate;
