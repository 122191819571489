import Check from "../../Form/Check/Form.Check";
import Column from "../../Column";
import FormGroup from "../../Form/Form.Group";
import Help from "../../Help";
import Input from "../../Form/Input/Input";
import Loading from "../../Loading";
import Row from "../../Row";
import SelectComparison from "../../Form/Select/Select.Comparison";
import SelectMeasureSeries from "../../Form/Select/Select.MeasureSeries";
import StatusIcon from "../../StatusIcon";
import { useEffect } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import getDeepValue from "lodash/get";
import isEqual from "lodash/isEqual";
import { theme } from "../../Theme";

let propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	parent: PropTypes.string,
	section: PropTypes.string,
	seriesSelectFlag: PropTypes.bool,
	seriesType: PropTypes.string,
};
let Evaluations = ({ label, name, parent, section, seriesSelectFlag, seriesType }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { setFormDefaultValue, setFormValue, formDefaultValue } = useFormContext();
	let defaultEvaluationList = getDeepValue(formDefaultValue, name);
	let { measureId, measureSeriesId } = useFormValue();
	let evaluationList = useFormValue(name);
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let loadingFlag = statusList === undefined;
	seriesType = parent || seriesType;

	useEffect(() => {
		let newEvaluationList = statusList
			?.filter((x) => x.name !== "Not Defined")
			.filter((x) => x.active)
			.filter((x) => !seriesType || x.elements?.includes(seriesType))
			.map((status) => {
				let storedEvaluation = defaultEvaluationList?.find((evaluation) => evaluation.statusId === status.statusId);
				if (storedEvaluation) {
					storedEvaluation = cloneDeep(storedEvaluation);
					storedEvaluation.statusName = status.name;
					storedEvaluation.sortOrder = status.sortOrder;
				}
				return (
					storedEvaluation || {
						statusId: status.statusId,
						series: "",
						statusName: status.name,
						calculation: "",
						enabled: false,
						compare: "gt",
						sortOrder: status.sortOrder,
					}
				);
			});
		if (newEvaluationList && !isEqual(defaultEvaluationList, newEvaluationList)) {
			setFormDefaultValue(name, newEvaluationList);
			setFormValue(name, newEvaluationList);
		}
	}, [defaultEvaluationList, name, seriesType, setFormDefaultValue, setFormValue, statusList]);

	return loadingFlag ? (
		<Loading />
	) : (
		<FormGroup data-testid="evaluations" title={label} paddingBottom={theme.smallSpace}>
			<Help feature="evaluation-template" section={section} />
			{evaluationList?.map((evaluation, i) => {
				return (
					<Row
						marginBottom={i === evaluationList.length - 1 ? `-${theme.space}` : undefined}
						key={evaluation.statusId}
					>
						<Column large={4}>
							<Check name={`${name}[${i}].enabled`}>
								<StatusIcon statusId={evaluation.statusId} marginLeft={theme.smallSpace} />
								{evaluation.statusName}
							</Check>
						</Column>
						{evaluation.enabled && (
							<>
								<Column large={2}>
									<SelectComparison
										name={`${name}[${i}].compare`}
										placeholder={translate("layout.comparison") + "..."}
									/>
								</Column>
								{evaluation.compare !== "any" && (
									<>
										{seriesSelectFlag ? (
											<Column large={3}>
												<SelectMeasureSeries
													measureId={measureId}
													measureSeriesIdListToFilterFromOptions={[measureSeriesId]}
													name={`${name}[${i}].seriesId`}
													placeholder={translate("options.selectMeasureSeries")}
													includeStaticValueFlag
												/>
											</Column>
										) : (
											<Column large={3}>
												<Input name={`${name}[${i}].series`} placeholder={translate("edit.seriesName")} />
											</Column>
										)}
										<Column large={3}>
											<Input
												name={`${name}[${i}].calculation`}
												placeholder={translate(
													evaluation.compare === "btw"
														? "edit.series.range"
														: evaluation.compare !== "btw" &&
														  evaluation.compare !== "any" &&
														  evaluation.seriesId > 0
														? "global.calculation"
														: "global.value"
												)}
											/>
										</Column>
									</>
								)}
							</>
						)}
					</Row>
				);
			})}
		</FormGroup>
	);
};
Evaluations.propTypes = propTypes;
export default Evaluations;
