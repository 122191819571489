import { useCallback } from "react";
import useStateObject from "./useStateObject";

let useHoverFlag = () => {
	let [{ hoverFlag }, setState] = useStateObject({
		hoverFlag: false,
	});
	let onMouseOver = useCallback(() => setState({ hoverFlag: true }), [setState]);
	let onMouseOut = useCallback(() => setState({ hoverFlag: false }), [setState]);
	return { hoverFlag, onMouseOver, onMouseOut };
};
export default useHoverFlag;
