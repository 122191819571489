import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

let useMeasure = ({ query, addMarginFlag, subtractPaddingFlag }) => {
	let [measure, setMeasure] = useState();
	let updateMeasure = useCallback(() => {
		if (!query || !document.querySelector(query)) return;
		let style = window.getComputedStyle(document.querySelector(query));
		let parse = (x) => {
			if (x) {
				let parsed = parseInt(x);
				if (!isNaN(parsed)) return parsed;
			}
			return 0;
		};
		let height = parse(style.height);
		let width = parse(style.width);
		if (addMarginFlag) {
			let {
				"margin-top": marginTop,
				"margin-bottom": marginBottom,
				"margin-left": marginLeft,
				"margin-right": marginRight,
			} = style;
			height += parse(marginTop) + parse(marginBottom);
			width += parse(marginLeft) + parse(marginRight);
		}
		if (subtractPaddingFlag) {
			let {
				"padding-top": paddingTop,
				"padding-bottom": paddingBottom,
				"padding-left": paddingLeft,
				"padding-right": paddingRight,
			} = style;
			height -= parse(paddingTop) + parse(paddingBottom);
			width -= parse(paddingLeft) + parse(paddingRight);
		}
		let newMeasure = { height, width };
		if (!isEqual(measure, newMeasure)) {
			setMeasure(newMeasure);
		}
	}, [addMarginFlag, measure, query, subtractPaddingFlag]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let debouncedUpdateMeasure = useCallback(debounce(updateMeasure, 17), [updateMeasure]);
	// run this effect all the time, in case of change in size triggered by some update
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(debouncedUpdateMeasure);
	// also run on window resize, when state does not update
	useEffect(() => {
		window.addEventListener("resize", debouncedUpdateMeasure);
		return () => window.removeEventListener("resize", debouncedUpdateMeasure);
	}, [debouncedUpdateMeasure]);
	// also on resize of element (for modern browsers only)
	useEffect(() => {
		let resizeObserver = new ResizeObserver(debouncedUpdateMeasure);
		if (query && document.querySelector(query)) {
			resizeObserver.observe(document.querySelector(query));
		}
		return () => resizeObserver.disconnect();
	});
	return measure;
};
export default useMeasure;
