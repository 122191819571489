import CellWrapper from "../CellWrapper";
import FormFieldDisplayPeriod from "@clearpoint/old-theme/Form/Field/Display/Period";
import PropTypes from "prop-types";

let propTypes = {
	cell: PropTypes.shape({
		fieldId: PropTypes.string,
		periodId: PropTypes.number,
	}),
	parentName: PropTypes.string,
};
let PeriodCell = (props) => {
	let { cell, parentName } = props;
	let { periodId } = cell;
	let name = `${parentName}.status.${periodId}.period`;
	return (
		<CellWrapper {...props}>
			<FormFieldDisplayPeriod name={name} />
		</CellWrapper>
	);
};
PeriodCell.propTypes = propTypes;

export default PeriodCell;
