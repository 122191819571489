import PaginationSelect from "@clearpoint/old-theme/Pagination/Select";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import differenceInYears from "date-fns/differenceInYears";
import { useDefaultGanttScale } from "@clearpoint/hooks";


let SelectGanttScale = (props) => {
	let translate = useTranslate();
	let { endDate, ganttScale, gridContainerWidth, layout, setLocalState, startDate } = useLocalState();

	let setGanttScale = useCallback(
		(value) => {
			setLocalState({
				ganttScale: value?.value || value,
			});
		},
		[setLocalState]
	);
	let years = startDate && endDate ? differenceInYears(new Date(endDate), new Date(startDate)) + 1 : undefined;

	let defaultValue = useDefaultGanttScale({ startDate, endDate, layout, containerWidth: gridContainerWidth });

	let options = useMemo(() => {
		let options = [
			{ label: translate("layout.gantt.year"), value: "year" },
			{ label: translate("layout.gantt.sixMonth"), value: "0.5 years" },
			{ label: translate("layout.gantt.quarter"), value: "quarter" },
			{ label: translate("layout.gantt.month"), value: "month" },
			{ label: translate("layout.gantt.week"), value: "week" },
			{ label: translate("schedule.day.day"), value: "day" },
		];
		if (years >= 5) options = [{ label: translate("layout.gantt.fiveYear"), value: "5 years" }, ...options];
		if (years >= 10) options = [{ label: translate("layout.gantt.tenYear"), value: "10 years" }, ...options];
		return options;
	}, [translate, years]);
	let defaultValueFlagRef = useRef(false);
	useEffect(() => {
		if (defaultValue && options && !defaultValueFlagRef.current) {
			setGanttScale(defaultValue);
			defaultValueFlagRef.current = true;
		}
	}, [defaultValue, options, setGanttScale]);
	let value = options.find((x) => x.value === ganttScale);
	return <PaginationSelect options={options} value={value} onChange={setGanttScale} {...props} />;
};

export default SelectGanttScale;
