import Button from "../Button/Button";
import HTML from "../HTML";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	body: PropTypes.node,
	close: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool,
	title: PropTypes.string,
};

let ModalPreview = ({ body, close, modalVisible, title }) => {
	let translate = useTranslate();
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<ModalTitle>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>{typeof body === "string" ? <HTML>{body}</HTML> : body}</ModalBody>
			<ModalFooter>
				<Button onClick={close} type="button" color="primary">
					{translate("global.close")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

ModalPreview.propTypes = propTypes;

export default ModalPreview;
