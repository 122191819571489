import Block from "../Block";
import Icon from "../Icon/Icon";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import PropTypes from "prop-types";
import classNames from "classnames";
import { colorPalettes } from "../colorPalettes";
import styled from "styled-components";
import { theme } from "../Theme";
import { emptyObject, minutesPassed } from "@clearpoint/utils";

let regularStyle = (props) => `
	width: 100%;
	min-height: 48px;
	margin-bottom: ${props.theme.space};
	background-color: ${props.backgroundColor || colorPalettes[props.$color].backgroundColor};
	${
		props.highlightFlag
			? `::after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 12px 12px 0;
				border-color: transparent ${props.theme.orange2} transparent transparent;
				right: 0px;
				top: 0;
				position: absolute;
			}`
			: ""
	}
`;

let smallStyle = (props) => `
	height: ${props.unsetDefaultHeightFlag ? "auto" : "44px"};
	font-size: 16px;
	border-left: 6px solid ${props.highlightFlag ? "red" : props.theme.lightGray};
	border-radius: 4px;
	margin-bottom: 10px;
	background-color: ${props.backgroundColor || props.theme.lighterGray};
	padding: 2px 10px 3px 5px;
`;

let Container = styled.div`
	display: flex;
	background-color: ${(props) => props.backgroundColor};
	box-shadow: ${(props) => props.boxShadow};
	border-radius: ${(props) => props.borderRadius};
	border: ${(props) => props.border};
	${(props) =>
		!props.border &&
		`border-style: solid solid none;
	border-width: 2px 0 0 ${props.borderLeftFlag ? "2px" : ""};
	border-color: ${props.theme.lightGray};
	`}
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	padding: ${(props) => `${props.theme.tinySpace} ${props.theme.space}`};
	padding-left: ${(props) => props.paddingLeft};
	padding-right: ${(props) => props.paddingRight};
	${(props) => (props.size === "small" ? smallStyle(props) : regularStyle(props))}
	& .badge {
		padding: 4px 8px;
		font-size: 12px;
		margin-left: 15px;
	}
	& &:not(button) {
		vertical-align: middle;
		margin-bottom: 0px !important;
		overflow: ${(props) => (props.ellipsisFlag ? "hidden !important" : undefined)};
		white-space: ${(props) => (props.ellipsisFlag ? undefined : "unset !important")};
		font-weight: ${(props) => colorPalettes[props.$color].fontWeight};
	}
	& .btn {
		overflow: visible !important;
	}
	& * &:not(a) {
		text-overflow: ellipsis;
		vertical-align: middle;
		margin-bottom: 0px !important;
		overflow: ${(props) => (props.ellipsisFlag ? "hidden !important" : undefined)};
		white-space: ${(props) => (props.ellipsisFlag ? undefined : "unset !important")};
	}
	font-weight: ${(props) => colorPalettes[props.$color].fontWeight};
	position: relative;
	cursor: ${(props) => props.cursor};
	user-select: none;
	& .form-group {
		margin-bottom: 0 !important;
	}
	${(props) => props.$style}
`;

Container.propTypes = {
	highlightFlag: PropTypes.bool,
};

let LeftChild = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	word-wrap: break-word;
	max-height: ${(props) => (props.height ? props.height + "!important" : undefined)};
	& a {
		text-overflow: ellipsis;
		font-weight: ${(props) => props.theme.boldish};
	}
	${(props) =>
		props.pseudoPadding
			? `::after {
		content: "";
		width: ${props.pseudoPadding};
	}`
			: ``}
	.form-group, label {
		margin-bottom: 0 !important;
	}
	label {
		display: block;
	}
`;

let RightChild = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	overflow: visible !important;
	max-height: ${(props) => (props.height ? props.height : undefined)};
	white-space: nowrap;
	& > * {
		margin-left: ${(props) => props.theme.smallSpace};
		flex-shrink: 0;
	}
	& > span:not(.badge):not(.tag),
	& > small,
	& > div:not(.form-group),
	& > a {
		flex-shrink: 1;
		min-width: 0;
		white-space: nowrap;
		overflow: ${(props) => (props.ellipsisFlag ? "hidden" : undefined)} !important;
		text-overflow: ellipsis;
	}
	& badge {
		width: 100%;
	}
`;

let propTypes = {
	backgroundColor: PropTypes.string,
	borderRadius: PropTypes.string,
	border: PropTypes.string,
	borderLeftFlag: PropTypes.bool,
	boxShadow: PropTypes.string,
	buttonCount: PropTypes.number,
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.oneOf(Object.keys(colorPalettes)),
	"data-testid": PropTypes.string,
	draggableFlag: PropTypes.bool,
	dragHandleAttributes: PropTypes.object,
	dragHandleListeners: PropTypes.object,
	ellipsisFlag: PropTypes.bool,
	handle: PropTypes.string,
	highlightFlag: PropTypes.bool,
	lastEdited: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	left: PropTypes.node,
	leftHeight: PropTypes.string,
	leftPseudoPadding: PropTypes.string,
	paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	right: PropTypes.node,
	rightHeight: PropTypes.string,
	rightFlexShrink: PropTypes.number,
	setActivatorNodeRef: PropTypes.func,
	visible: PropTypes.bool,
};

let defaultProps = {
	borderRadius: "4px",
	borderLeftFlag: true,
	boxShadow: theme.modalListItemBoxShadow,
	color: "normal",
	draggableFlag: false,
	dragHandleAttributes: {},
	dragHandleListeners: {},
	visible: true,
	rightFlexShrink: 20,
};

// refactor styling for left and right props
let ListItem = ({
	"data-testid": dataTestId,
	backgroundColor,
	borderRadius,
	border,
	borderLeftFlag,
	boxShadow,
	buttonCount,
	children,
	className,
	color,
	draggableFlag,
	dragHandleAttributes,
	dragHandleListeners,
	ellipsisFlag,
	handle,
	lastEdited,
	left,
	leftHeight,
	leftPseudoPadding,
	right,
	rightHeight,
	setActivatorNodeRef,
	unsetDefaultHeightFlag,
	visible,
	rightFlexShrink,
	...props
}) => {
	let highlightFlag = props.highlightFlag || (lastEdited && minutesPassed(lastEdited) < 5);
	let { deletedVisible } = useFilter() || emptyObject;
	if (deletedVisible) draggableFlag = false;
	return visible ? (
		<Container
			data-testid={dataTestId || "list-item"}
			backgroundColor={backgroundColor}
			borderRadius={borderRadius}
			border={border}
			borderLeftFlag={borderLeftFlag}
			boxShadow={boxShadow}
			$color={color}
			className={classNames("list-item-container", className)}
			ellipsisFlag={ellipsisFlag}
			noSidesFlag={!left && !right}
			{...props}
			highlightFlag={highlightFlag}
			unsetDefaultHeightFlag={unsetDefaultHeightFlag}
		>
			{left && (
				<>
					<LeftChild height={leftHeight} pseudoPadding={leftPseudoPadding} draggableFlag>
						{draggableFlag && (
							<Block
								ref={setActivatorNodeRef}
								{...dragHandleAttributes}
								{...dragHandleListeners}
								className="listitem-handle"
								cursor="grab"
								margin={`0 -${theme.tinySpace} !important }`}
								height="20px"
							>
								<Icon name="drag" size="large" marginRight={theme.smallSpace} />
							</Block>
						)}
						{left}
					</LeftChild>
				</>
			)}
			{children && draggableFlag ? (
				<>
					<Block
						ref={setActivatorNodeRef}
						{...dragHandleAttributes}
						{...dragHandleListeners}
						className="listitem-handle"
						cursor="grab"
						margin={`0 -${theme.tinySpace} !important }`}
						height="20px"
					>
						<Icon name="drag" size="large" marginRight={theme.smallSpace} />
					</Block>
					{children}
				</>
			) : (
				children
			)}
			{/* {children} */}
			{right && (
				<RightChild buttonCount={buttonCount} height={rightHeight} rightFlexShrink={rightFlexShrink}>
					{right}
				</RightChild>
			)}
		</Container>
	) : null;
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
