let localizeStyle = (html, className) => {
	return `${
		html
			.match(/<style>[\s\S]*?<\/style>/g)
			?.join("\n")
			.replace(/<style>\s*?(\S.*?){/, (_match, selector) => `<style>.${className} ${selector}{`)
			.replace(/}\s*?(\S.*?){/, (_match, selector) => `} .${className} ${selector}{`) +
		html?.replace(/<style>[\s\S]*?<\/style>/g, "")
	}`;
};

export default localizeStyle;
