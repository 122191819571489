import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import Block from "../Block";
import { theme } from "../Theme";
import Comment from "./Comment";
import ReplyBox from "./ReplyBox";
import ReplyInput from "./ReplyInput";

let propTypes = {
	layoutId: PropTypes.number,
	marginTopFlag: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number,
	thread: PropTypes.shape({
		parent: PropTypes.shape({
			discussionId: PropTypes.number,
			resolved: PropTypes.bool,
		}),
		replyList: PropTypes.arrayOf(PropTypes.shape({ discussionId: PropTypes.number })),
	}),
	updatingFlag: PropTypes.bool,
};

let Thread = ({ layoutId, marginTopFlag, object, objectId, thread, updatingFlag }) => {
	let { parent, replyList } = thread;
	let { discussionId: parentDiscussionId, resolved: resolvedFlag } = parent;
	let [replyFlag, setReplyFlag] = useState(false);
	let toggleReplyFlag = useCallback(() => setReplyFlag((x) => !x), []);

	return (
		<Block
			backgroundColor={theme.lighterGray}
			borderRadius="5px"
			display="flex"
			margintop={theme.tinySpace}
			marginBottom={theme.smallSpace}
			flexDirection="column"
			padding={theme.smallSpace}
		>
			<Block marginBottom={theme.smallSpace}>
				<Comment
					comment={parent}
					parentDiscussionId={parentDiscussionId}
					resolvedFlag={resolvedFlag}
					threadParentFlag
					setReplyFlag={setReplyFlag}
					updatingFlag={updatingFlag}
				/>
			</Block>
			<Block>
				{replyList?.map((x) => (
					<Block key={x.discussionId} backgroundColor="#fbfbfb" borderRadius="4px" marginBottom={theme.smallSpace} padding={theme.smallSpace}>
						<Comment comment={x} updatingFlag={updatingFlag} />
					</Block>
				))}
			</Block>

			{replyFlag ? (
				<ReplyInput
					parentId={parentDiscussionId}
					layoutId={layoutId}
					object={object}
					objectId={objectId}
					toggleReplyFlag={toggleReplyFlag}
				/>
			) : (
				<ReplyBox onClick={toggleReplyFlag} />
			)}
		</Block>
	);
};

Thread.propTypes = propTypes;
export default Thread;
