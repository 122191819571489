import { isManageScorecardAndElementsPage } from "@clearpoint/utils";

import isManageLayoutsPage from "./isManageLayoutsPage";

let updateScorecardIdOnManagePages = ({ tabbedNavigationData, session }) => {
	let isManagePage = (x) => isManageScorecardAndElementsPage(x) || isManageLayoutsPage(x);
	return tabbedNavigationData.map((x) => (isManagePage(x) ? { ...x, scorecardId: session.sidenavScorecardId } : x));
};

export default updateScorecardIdOnManagePages;
