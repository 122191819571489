import Block from "../../Block";
import Button from "../../Button/Button";
import Check from "../../Form/Check/Form.Check";
import FormGroup from "../../Form/Form.Group";
import ListItemPlotBand from "../../ListItem/ListItem.PlotBand";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import StyleWrapper from "../../StyleWrapper";
import { useCallback } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	editFlag: PropTypes.bool,
	name: PropTypes.string,
	parentName: PropTypes.string,
};

let EnablePlotBands = ({ editFlag, name, parentName, ...props }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let plotBandListName = parentName ? `${parentName}.plotBands` : "plotBands";
	let plotBandList = useFormValue(plotBandListName);
	let { setFormValue } = useFormContext();

	let addPlotBand = useCallback(() => {
		setFormValue(plotBandListName, (plotBandList) => [...(plotBandList || []), { color: null }]);
	}, [plotBandListName, setFormValue]);
	return (
		<>
			{editFlag ? (
				<RadioBoolean label={translate("edit.chart.addPlotBands")} name={name} />
			) : (
				<Check data-testid="enablePlotBands" name={name} text={translate("edit.chart.addPlotBands")} {...props} />
			)}
			{(value === true || editFlag) && (
				<FormGroup title={translate("edit.chart.plotBands")}>
					<StyleWrapper marginBottom={theme.space}>
						{plotBandList?.map((band, i) => (
							<ListItemPlotBand key={i} plotBandListNameInForm={plotBandListName} index={i} />
						))}
						<Block textAlign="right">
							<Button onClick={addPlotBand} size="small" outlineFlag>
								{translate("edit.chart.addPlotBand")}
							</Button>
						</Block>
					</StyleWrapper>
				</FormGroup>
			)}
		</>
	);
};
EnablePlotBands.propTypes = propTypes;
export default EnablePlotBands;
