import CellWrapper from "../CellWrapper";
import FormFieldDisplayRecommendations from "@clearpoint/old-theme/Form/Field/Display/Recommendations";
import FormFieldRecommendations from "@clearpoint/old-theme/Form/Field/Recommendations";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let RecommendationsCell = (props) => {
	let { cell, cellFlag, editCellFlag, parentName } = props;
	let { periodId } = cell;
	let name = `${parentName}.status.${periodId}.recommendations`;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldRecommendations cellFlag={cellFlag} name={name} />
			) : (
				<FormFieldDisplayRecommendations name={name} />
			)}
		</CellWrapper>
	);
};
RecommendationsCell.propTypes = propTypes;

export default RecommendationsCell;
