import { useRef } from "react";
import isEqual from "lodash/isEqual";
let useWindowContextValue = ({
	color,
	handleClose,
	helpFlag,
	id,
	loadingFlag,
	maximizeFlag,
	noFormFlag,
	object,
	objectId,
	props,
	setObjectId,
	toggleHelpFlag,
	toggleSize,
}) => {
	let contextValueRef = useRef({
		close: handleClose,
		color,
		helpFlag,
		id,
		loadingFlag,
		maximizeFlag,
		noFormFlag,
		object,
		objectId,
		setObjectId,
		toggleHelpFlag,
		toggleSize,
		...props,
	});
	let currentValue = {
		close: handleClose,
		color,
		helpFlag,
		id,
		loadingFlag,
		maximizeFlag,
		noFormFlag,
		object,
		objectId,
		setObjectId,
		toggleHelpFlag,
		toggleSize,
		...props,
	};
	// NOTE that a simple useMemo will not work here because props will be a dependency that always updates.  So we do a manual equality check here.
	if (!isEqual(currentValue, contextValueRef.current)) contextValueRef.current = currentValue;
	return contextValueRef.current;
};
export default useWindowContextValue;
