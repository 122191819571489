/* eslint-disable complexity */
import { useMemo } from "react";
import camelCase from "lodash/camelCase";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { isManageScorecardAndElementsPage, pageNameLookup } from "@clearpoint/utils";

import { useOldSession } from "@clearpoint/old-session/index";

export default function usePageName(props) {
	let treeLookup = useMemo(
		() => ({
			element: "menu.elementTree",
			report: "menu.reportTree",
			scorecard: "menu.scorecardTree",
		}),
		[]
	);
	let { session } = useOldSession();
	let { get } = useOldQueryStore();

	let translate = useTranslate();
	let { page, mode, object, layoutId, objectId, periodId, scorecardId, type, view } = props || session;
	periodId = periodId || session.periodId;
	let viewChangesNameFlag =
		(view !== "list" && mode && mode !== "images") ||
		(page === "schedules" && view === "calendar") ||
		page === "integrations";
	if (mode) page += "-" + mode;

	if (view && viewChangesNameFlag) page += "-" + view;
	let elementsInterpolation = isManageScorecardAndElementsPage(props) ? "global.elements" : object + "s";
	if (page) {
		if (props?.page === "manageLayouts") return translate("layout.manageReports");
		if (props?.page === "manageScorecards") return translate("layout.manageScorecards");
		if (page === "workspace") {
			let workspaceList = get({
				object: "workspace",
			});
			let workspace = workspaceList?.find((x) => x.workspaceId === objectId);
			return workspace?.name || "";
		}
		page = camelCase(page);
		return translate(pageNameLookup[page] || page, {
			elements: elementsInterpolation,
			object,
		});
	} else if (["element", "report", "scorecard"].includes(type)) {
		return translate(treeLookup[type]);
	} else if (object && (objectId || layoutId || scorecardId)) {
		let queryObjectId = layoutId ? layoutId : (object === "scorecard" ? scorecardId : objectId || scorecardId);
		let objectData = layoutId
			? queryObjectId &&
			  periodId &&
			  get({
					object: object + "Layout",
					objectId: layoutId,
					periodId,
			  })
			: queryObjectId &&
			  periodId &&
			  get({
					object,
					objectId: queryObjectId,
			  });

		if (!objectData) return "";
		return translate(objectData.name);
	}
	return "";
}
