import { utcToZonedTime } from "date-fns-tz";

let convertDateStringToDateObject =
	({ ianaName, offset, offsetSign }) =>
	(date, ignoreTimezoneFlag) => {
		offset = Math.abs(+offset);
		offset = "" + offset.length === 1 ? "0" + offset : offset;

		if (!date || !ianaName) return;

		try {
			if (typeof date === "string" && date.length <= 10) {
				// Date is a non-ISO string
				let utcOffset = `${offsetSign}${offset}:00`;
				date = new Date(date).toISOString();
				date = date.split("Z")[0] + utcOffset;
			}
		} catch (error) {
			console.error(error);
			return null;
		}

		return ignoreTimezoneFlag ? new Date(date) : utcToZonedTime(date, ianaName);
	};

export default convertDateStringToDateObject;
