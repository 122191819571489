import Column from "../../../../Column";
import FormInput from "../../../../Form/Input/Input";
import FormLabelLeft from "../../../../Form/Form.Label.Left";
import FormSelectPeriod from "../../../../Form/Select/Select.Period";
import SelectAggregationType from "./SelectAggregationType";
import useFormValue from "../../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
	seriesName: PropTypes.string,
};

let Aggregation = ({ name, seriesName }) => {
	let translate = useTranslate();
	let formValue = useFormValue();
	let periodCountFlag = formValue[name]?.includes("NPeriod");
	let periodCountLabel = translate(
		formValue[name] === "Back{{NPeriod}}" ? "layout.backNperiods" : "layout.lastNPeriods"
	);
	return (
		<>
			<Column medium={6}>
				<FormLabelLeft label={translate("edit.aggregation")}>
					<SelectAggregationType name={name} disabled={seriesName ? !formValue[seriesName] : false} />
				</FormLabelLeft>
			</Column>
			{formValue[name] === "SpecificPeriod" && (
				<Column medium={6}>
					<FormLabelLeft label={translate("global.specificPeriods")}>
						<FormSelectPeriod name="_aggregationPeriodName" valueKey="name" />
					</FormLabelLeft>
				</Column>
			)}
			{periodCountFlag && (
				<Column medium={6}>
					<FormLabelLeft label={periodCountLabel}>
						<FormInput type="number" name="_aggregationPeriodCount" placeholder={periodCountLabel} min={1} />
					</FormLabelLeft>
				</Column>
			)}
		</>
	);
};

Aggregation.propTypes = propTypes;

export default Aggregation;
