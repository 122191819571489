import SelectLayout from "./Select.Layout";
import { useTranslate } from "@clearpoint/translate";

let SelectScorecardLayout = (props) => {
	let translate = useTranslate();
	return (
		<SelectLayout label={`${translate("scorecard")} ${translate("global.report")}`} object="scorecard" {...props} />
	);
};

export default SelectScorecardLayout;
