import Block from "../Block";
import Button from "../Button/Button";
import Form from "../Form/Form";
import FormButton from "../Form/Button/Button";
import HTML from "../HTML";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import SelectPageOrientation from "../Form/Select/Select.PageOrientation";
import SelectPageSize from "../Form/Select/Select.PageSize";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { toast } from "@clearpoint/services/toastService/index";
import { buildQueryString, emptyObject } from "@clearpoint/utils";
import { useReportFilter } from "@clearpoint/hooks";

let contentPropTypes = {
	close: PropTypes.func.isRequired,
	email: PropTypes.string,
};

let GeneratePDFModalContent = ({ close, email }) => {
	let translate = useTranslate();
	return (
		<>
			<ModalHeader>
				<ModalTitle>{translate("pdf.generatePdf")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Block marginBottom={theme.smallSpace}>
					<HTML>{translate("pdf.exportInstructions", { email })}</HTML>
				</Block>
				<SelectPageSize
					name="pageSize"
					label={translate("pdf.selectPageSize")}
					persistInSession="pdfPageSize"
					defaultValue="letter"
				/>
				<SelectPageOrientation
					name="orientation"
					label={translate("pdf.pageOrientation")}
					persistInSession="pdfPageOrientation"
					defaultValue="landscape"
				/>
			</ModalBody>
			<ModalFooter>
				<Button onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<FormButton color="primary">{translate("global.generate")}</FormButton>
			</ModalFooter>
		</>
	);
};
GeneratePDFModalContent.propTypes = contentPropTypes;

let propTypes = {
	close: PropTypes.func.isRequired,
	layoutId: PropTypes.number,
	modalVisible: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number,
	title: PropTypes.string,
};

let GeneratePDFModal = ({ close, layoutId, modalVisible, object, objectId, scorecardId, title }) => {
	let translate = useTranslate();
	let { get, set } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId } = session;
	let { reportFilter } = useReportFilter();
	let { sortField, sortObject, sortOrder } = useLocalState() || emptyObject;

	scorecardId = scorecardId || session.scorecardId;
	let profile = get({ object: "profile" });
	let { email, notificationEmail } = profile || {};
	if (notificationEmail?.length > 0) email = notificationEmail;
	let onSubmit = useCallback(
		(submitValue) => {
			let { pageSize, orientation } = submitValue;
			periodId &&
				set({
					object: layoutId ? `${object}Layout` : "detailLayout",
					objectId: layoutId || undefined,
					queryString: buildQueryString(
						layoutId
							? {
									periodId,
									pageSize,
									orientation,
									format: "pdf",
									filter: reportFilter || undefined,
									sortField,
									sortObject,
									sortOrder,
							  }
							: { scorecardId, object, objectId, periodId, pageSize, orientation, format: "pdf" }
					),
					data: {
						pageTitle: title,
					},
					method: "put",
				})
					.then(() => {
						toast.success(translate("toaster.messages.templates.templateExportGenerating"));
						close();
					})
					.catch(() => {
						toast.error(translate("options.admin.exportError"));
						close();
					});
		},
		[
			close,
			layoutId,
			object,
			objectId,
			periodId,
			reportFilter,
			scorecardId,
			set,
			sortField,
			sortObject,
			sortOrder,
			title,
			translate,
		]
	);
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<Form onSubmit={onSubmit} submitUnchangedFlag>
				<GeneratePDFModalContent close={close} email={email} />
			</Form>
		</Modal>
	);
};
GeneratePDFModal.propTypes = propTypes;

export default GeneratePDFModal;
