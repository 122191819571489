import Select from "../../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useScorecard } from "@clearpoint/hooks";


let propTypes = {
	object: PropTypes.string,
};
let SelectFilterField = ({ object, ...props }) => {
	let { scorecardId } = useScorecard();
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let filterFieldList = get({ object: "filterField", queryString: `?scorecardId=${scorecardId}&object=${object}` });
	let options = useMemo(
		() =>
			filterFieldList?.[object]?.map((filterField) => ({
				label: translate(filterField.name),
				value: filterField.fieldId,
			})),
		[filterFieldList, object, translate]
	);

	return <Select data-testid={"select-filter-field"} options={options} {...props} />;
};
SelectFilterField.propTypes = propTypes;

export default SelectFilterField;
