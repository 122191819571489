import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import HTML from "@clearpoint/old-theme/HTML";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";

// Responsiveness we're probably not going to wind up using:
// import Date from "./PodLinkListItem.Date";
import { useBuildLink } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import Date from "./PodLinkListItem.Date";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let wrapperPropTypes = {
	children: PropTypes.node,
	link: PropTypes.shape({
		completed: PropTypes.bool,
		object: PropTypes.string,
		objectId: PropTypes.number,
		scorecardId: PropTypes.number,
	}),
};

let HrefWrapper = ({ children, link }) => {
	let buildLink = useBuildLink();
	let { inModalFlag } = useLocalState();
	let { completed: completedFlag, object, objectId, scorecardId } = link;
	let categoryFlag = object === "category";
	let { get } = useOldQueryStore();
	let options = get({ object: "options" });
	let strikethroughFlag = options?.options?.strikeComplete && completedFlag;

	return (
		<StyleWrapper textDecoration={strikethroughFlag ? "line-through !important" : undefined}>
			{categoryFlag || inModalFlag ? (
				children
			) : (
				<a href={buildLink({ object, objectId, scorecardId })}>{children}</a>
			)}
		</StyleWrapper>
	);
};

let propTypes = {
	link: PropTypes.shape({
		name: PropTypes.string,
		object: PropTypes.string,
		scorecard: PropTypes.string,
		scorecardId: PropTypes.number,
		treeLevel: PropTypes.number,
		value: PropTypes.string,
	}),
};

let Name = ({ link }) => {
	let {
		session: { exportFlag, scorecardId: sessionScorecardId },
	} = useOldSession();
	let dateElementFlag = ["milestone", "initiative", "actionItem", "risk"].includes(link.object);
	let displayDateFlag = dateElementFlag && exportFlag;

	let { name, scorecard, scorecardId, treeLevel, value } = link;
	let showScorecardNameFlag = exportFlag || scorecardId !== sessionScorecardId;
	return (
		<Block flex="1" ellipsisFlag lineHeight="1" marginTop="2px" marginLeft={`${treeLevel * 20}px`}>
			<HrefWrapper link={link}>{name || value}</HrefWrapper>
			<br />
			{showScorecardNameFlag && (
				<>
					<small>{scorecard}</small>
					{displayDateFlag && (
						<>
							<HTML>&nbsp;|&nbsp;</HTML>
							<Date link={link} showFlag />
						</>
					)}
				</>
			)}
		</Block>
	);
};

HrefWrapper.propTypes = wrapperPropTypes;
Name.propTypes = propTypes;
export default Name;

// ...

// let { width: windowWidth } = useWindowSize();
// let dateElementFlag = ["milestone", "initiative", "actionItem", "risk"].includes(link.object);
// let displayDateFlag = dateElementFlag && windowWidth <= 768;
// 	return (
// 		<Block lineHeight="1.1" marginLeft={`${treeLevel * 20}px`}>
// 			<HrefWrapper link={link}>{name || value}</HrefWrapper>
// 			{showScorecardNameFlag && (
// 				<>
// 					<small>{scorecard}</small>
// 					{displayDateFlag && (
// 						<>
// 							<HTML>&nbsp;|&nbsp;</HTML>
// 							<Date link={link} showFlag />
// 						</>
// 					)}
// 				</>
// 			)}
// 		</Block>
// 	);
