import { useCallback } from "react";

import DropdownDivider from "@clearpoint/old-theme/Dropdown/Dropdown.Divider";
import GenerateExcelOnClick from "@clearpoint/old-theme/Dropdown/GenerateExcel.OnClick";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import DropdownSplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton";
import ModalEmailPageOnClick from "@clearpoint/old-theme/Modal/Modal.EmailPage.OnClick";
import ModalGeneratePDFOnClick from "@clearpoint/old-theme/Modal/Modal.GeneratePDF.OnClick";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import EditDefaultLayoutModal from "@components/Layouts/ManageLayouts/EditDefaultLayoutModal";
import EditGridLayoutModal from "@components/Layouts/ManageLayouts/EditGridLayoutModal";
import EditReferenceLayoutModal from "@components/Layouts/ManageLayouts/EditReferenceLayoutModal";
import EditDetailLayoutModal from "@components/Layouts/Shared/EditDetailLayoutModal";


let EditSplitButton = () => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { layout } = useLocalState();
	let {
		access,
		defaultLayout: defaultLayoutFlag,
		layoutId,
		name,
		object,
		referenceLayoutId,
		reportType,
		scorecardId,
	} = layout || {};
	let adminAccessFlag = checkAccess({ access, action: "admin", scorecardId });
	let editAccessFlag =
		reportType === "detail" ? checkAccess({ access, action: "edit", scorecardId }) : adminAccessFlag;
	let EditModal = referenceLayoutId
		? EditReferenceLayoutModal
		: reportType === "detail"
		? EditDetailLayoutModal
		: defaultLayoutFlag
		? EditDefaultLayoutModal
		: EditGridLayoutModal;
	let EditModalWindow;
	if ([EditDetailLayoutModal, EditGridLayoutModal].includes(EditModal)) {
		EditModalWindow = EditModal;
		EditModal = undefined;
	}
	let EditOnClick = useCallback(
		({ children }) =>
			EditModalWindow ? (
				<ModalWindowOnClick ModalWindow={EditModalWindow} layoutId={layoutId} object={object}>
					{children}
				</ModalWindowOnClick>
			) : (
				<ModalOnClick Modal={EditModal} layoutId={layoutId} object={object}>
					{children}
				</ModalOnClick>
			),
		[EditModal, EditModalWindow, layoutId, object]
	);
	return editAccessFlag ? (
		<EditOnClick>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<DropdownSplitButton icon="editReport">
					<EditOnClick>
						<DropdownItem data-testid="edit-report-dropdown-item" icon="editReport">
							{translate("layout.editLayout")}
						</DropdownItem>
					</EditOnClick>
					<DropdownDivider />
					<ModalGeneratePDFOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem data-testid="export-pdf-dropdown-item" icon="pdf">
							{translate("buttonBar.exportPDF")}
						</DropdownItem>
					</ModalGeneratePDFOnClick>
					{["grid", "matrix"].includes(reportType) && (
						<GenerateExcelOnClick object={object} layoutId={layoutId} title={translate(name)}>
							<DropdownItem data-testid="export-excel-dropdown-item" icon="excel">
								{translate("buttonBar.excelExport")}
							</DropdownItem>
						</GenerateExcelOnClick>
					)}
					<ModalEmailPageOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem data-testid="email-dropdown-item" icon="email">
							{translate("buttonBar.emailPage")}
						</DropdownItem>
					</ModalEmailPageOnClick>
				</DropdownSplitButton>
			</StyleWrapper>
		</EditOnClick>
	) : (
		<ModalGeneratePDFOnClick object={object} layoutId={layoutId} title={translate(name)}>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<DropdownSplitButton data-testid="edit-split-button" icon="pdf">
					<ModalGeneratePDFOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
					</ModalGeneratePDFOnClick>
					{["grid", "matrix"].includes(reportType) && (
						<GenerateExcelOnClick object={object} layoutId={layoutId} title={translate(name)}>
							<DropdownItem icon="excel">{translate("buttonBar.excelExport")}</DropdownItem>
						</GenerateExcelOnClick>
					)}
					<ModalEmailPageOnClick object={object} layoutId={layoutId} title={translate(name)}>
						<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
					</ModalEmailPageOnClick>
				</DropdownSplitButton>
			</StyleWrapper>
		</ModalGeneratePDFOnClick>
	);
};
export default EditSplitButton;
