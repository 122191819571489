import { Fragment, useMemo } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import Form from "@clearpoint/old-theme/Form/Form";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";

import { useCheckFeature, useMeasure, useQueryParameters } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import ViewDashboard from "../ViewDashboard";
import ViewLayoutContent from "./ViewLayoutContent";
import { useOldSession } from "@clearpoint/old-session/index";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	gridPodFlag: PropTypes.bool,
	layoutId: PropTypes.number,
	name: PropTypes.string,
	object: PropTypes.string,
	podWidthDisplayClass: PropTypes.string,
	ReferenceTitle: PropTypes.elementType,
	ReferenceEditSplitButton: PropTypes.elementType,
	ReferenceFavoriteSplitButton: PropTypes.elementType,
};

let ViewReport = ({
	gridPodFlag,
	layoutId,
	name,
	object,
	podWidthDisplayClass,
	ReferenceTitle,
	ReferenceEditSplitButton,
	ReferenceFavoriteSplitButton,
}) => {
	let checkFeature = useCheckFeature();
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let { exportFlag, object: sessionObject, layoutId: sessionLayoutId, periodId } = session;
	let { width: gridContainerWidth } = useMeasure({ query: ".grid-container" }) || {};
	let { commentId, sortField, sortObject, sortOrder } = useQueryParameters();
	// TO DO: Fix exports with custom field embedded reports
	let customFieldFlag = !!name;
	object = object || sessionObject;
	layoutId = layoutId || sessionLayoutId;
	let pageKey = `report${object}${layoutId}page`;
	let layout = layoutId && periodId && get({ object: `${object}Layout`, objectId: layoutId, periodId });
	let notFoundFlag = layout?.code === 404 || isEqual(layout, []);
	let customStyleFlag = checkFeature("customStyles");
	let elementListName = name ? `elementList${name}` : "elementList";
	let Wrapper = useMemo(
		() =>
			name
				? Fragment
				: ({ children }) => (
						<StyleWrapper
							height={exportFlag ? undefined : "100%"}
							flex="1"
							display={exportFlag ? undefined : "flex"}
							flexDirection="column"
						>
							<Form>{children}</Form>
						</StyleWrapper>
				  ),
		[name, exportFlag]
	);

	return !object || notFoundFlag ? null : (layout?.reportType === "dashboard" ? (
		<ViewDashboard
			customFieldFlag={customFieldFlag}
			layoutId={layoutId}
			object={object}
			ReferenceTitle={ReferenceTitle}
			ReferenceEditSplitButton={ReferenceEditSplitButton}
			ReferenceFavoriteSplitButton={ReferenceFavoriteSplitButton}
		/>
	) : (
		<LocalStateProvider
			customFieldFlag={!!name}
			customStyleFlag={customStyleFlag}
			discussionVisible={!!commentId}
			editTableFlag={false}
			elementListName={elementListName}
			endDate={null}
			ganttScale={null}
			gridContainerWidth={gridContainerWidth}
			gridPodFlag={gridPodFlag}
			podWidthDisplayClass={podWidthDisplayClass}
			layout={layout}
			name={name || ""}
			pageKey={pageKey}
			searchFlag={false}
			sortField={sortField || "default"}
			sortObject={sortObject || object}
			sortOrder={sortOrder || "default"}
			startDate={null}
			ReferenceTitle={ReferenceTitle}
			ReferenceEditSplitButton={ReferenceEditSplitButton}
			ReferenceFavoriteSplitButton={ReferenceFavoriteSplitButton}
		>
			<Wrapper>
				<ViewLayoutContent layoutId={layoutId} object={object} />
			</Wrapper>
		</LocalStateProvider>
	));
};
ViewReport.propTypes = propTypes;

export default ViewReport;
