import ReactDOM from "react-dom";
import { useEffect } from "react";
import { useId } from "@clearpoint/hooks";

let createPortal = (portalClassName) => {
	if (!document.querySelector(`.${portalClassName}`)) {
		let element = document.createElement("div");
		element.classList.add("portal", portalClassName);
		let rootElement = document.querySelector(".root") || document.body;
		if (rootElement) rootElement.appendChild(element);
	}
};

let Portal = ({ children }) => {
	let id = useId();
	let portalClassName = "portal" + id;
	createPortal(portalClassName);
	useEffect(() => {
		createPortal(portalClassName);
		return () => document.querySelector(`.${portalClassName}`)?.remove();
	}, [portalClassName]);
	let parentElement = document.querySelector(`.${portalClassName}`);
	return !parentElement ? null : ReactDOM.createPortal(children, document.querySelector(`.${portalClassName}`));
};

export default Portal;
