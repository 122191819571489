import CurrentPeriodTable from "./CurrentPeriodTable";
import PrintSeriesGrid from "./PrintSeriesGrid";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { notDefinedStatusIcon } from "@clearpoint/utils";

let propTypes = {
	measureId: PropTypes.number,
	periodId: PropTypes.number,
};

let SeriesGridPrint = ({ measureId, periodId }) => {
	let translate = useTranslate();
	let { seriesGridMaxColumnCount } = useOldSession().session;
	let { get } = useOldQueryStore();

	let measureGridList =
		measureId &&
		get({
			parent: "measure",
			parentId: measureId,
			object: "measureGrid",
			queryString: "?print=true",
		});
	let measureSeriesList = measureId && get({ parent: "measure", parentId: measureId, object: "measureSeries" });
	let statusList = get({ object: "status", queryString: "?includeNull" });

	statusList = useMemo(() => {
		let list = statusList?.filter((x) => x.active && (x.elements === undefined || x.elements.includes("measure")));
		if (list && !list.find((x) => x.statusId === 0)) {
			list = [
				{
					statusId: 0,
					filename: "unavailable",
					name: translate("global.notDefined"),
				},
				...list,
			];
		}
		return list;
	}, [statusList, translate]);

	measureGridList = useMemo(
		() =>
			measureGridList
				?.filter((x) => !x.hideInGrid)
				?.map((period) => {
					let status = statusList?.find((status) => period.statusId === status.statusId);
					return {
						...period,
						image: status?.image ?? notDefinedStatusIcon,
						statusName: status?.name ?? translate("global.notDefined"),
					};
				}),
		[measureGridList, statusList, translate]
	);

	measureSeriesList = useMemo(
		() => measureSeriesList?.filter((x) => x.active && !x.hiddenSeries),
		[measureSeriesList]
	);

	let currentPeriodOnlyFlag = measureSeriesList ? measureSeriesList.length > seriesGridMaxColumnCount : false;

	return !measureGridList ? null : currentPeriodOnlyFlag ? (
		<CurrentPeriodTable
			measureId={measureId}
			measureGridList={measureGridList}
			measureSeriesList={measureSeriesList}
			periodId={periodId}
		/>
	) : (
		<PrintSeriesGrid measureGridList={measureGridList} measureSeriesList={measureSeriesList} />
	);
};

SeriesGridPrint.propTypes = propTypes;
export default SeriesGridPrint;
