import Loading from "@clearpoint/old-theme/Loading/index";

import useElementList from "../useElementList";
import DashboardListDisplay from "./DashboardListDisplay";
import useChartList from "./useChartList";
import useMeasureStatusList from "./useMeasureStatusList";

let DashboardList = () => {
	let elementList = useElementList();
	let { chartList: pageChartList } = useChartList(elementList);
	let { measureStatusLoadingFlag, measureStatusList } = useMeasureStatusList(elementList);
	let loadingFlag = measureStatusLoadingFlag || pageChartList === undefined;
	return loadingFlag ? (
		<Loading />
	) : (
		<DashboardListDisplay chartList={pageChartList} measureStatusList={measureStatusList} />
	);
};

export default DashboardList;
