import Badge from "../../Badge/Badge";
import Block from "../../Block";
import FormInput from "../../Form/Input/Input";
import Icon from "../../Icon/Icon";
import Input from "../../Input/Input";
import ListItem from "../ListItem";
import StatusIcon from "../../StatusIcon";
import StyleWrapper from "../../StyleWrapper";
import Tooltip from "../../Tooltip";
import UnlinkButton from "./UnlinkButton";
import useLinkListItemFormUtils from "./useLinkListItemFormUtils";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";
import { emptyObject, getLinkScore } from "@clearpoint/utils";
import { useMeasure } from "@clearpoint/hooks";

let propTypes = {
	link: PropTypes.shape({
		access: PropTypes.string,
		fieldId: PropTypes.string,
		name: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		period: PropTypes.string,
		repeatedLinkFlag: PropTypes.bool,
		scorecard: PropTypes.string,
		statusId: PropTypes.number,
	}),
	showWeightsFlag: PropTypes.bool,
};

let ListItemLink = ({ link, showWeightsFlag }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let { access, fieldId, name, object, period, repeatedLinkFlag, score, scorecard, statusId } = link;

	let noAccessFlag = access === "No Access";
	let categoryFlag = object === "category";

	let calculatedScore = getLinkScore(link, statusList);

	let { indexInFormValue, unlinkObject } = useLinkListItemFormUtils(link);
	let { width: containerWidth } = useMeasure({ query: ".link-list-item-container" }) || emptyObject;
	let largeScreenFlag = containerWidth > 600;
	return (
		<ListItem
			className="link-list-item-container"
			ellipsisFlag
			size="small"
			boxShadow="none"
			left={
				<Tooltip text={name}>
					<Block ellipsisFlag display="flex" alignItems="center" flexShrink="0">
						<Icon name={fieldId} size="large" marginRight={theme.smallSpace} />
						{noAccessFlag ? (
							<Block ellipsisFlag>{translate("editBar.noAccess")}</Block>
						) : (
							<>
								<StatusIcon statusId={statusId} />
								<Block ellipsisFlag fontSize="16px" lineHeight="1.1">
									<Block ellipsisFlag>{name}</Block>
									<Block ellipsisFlag>
										<small>{scorecard}</small>
									</Block>
								</Block>
							</>
						)}
					</Block>
				</Tooltip>
			}
			right={
				<>
					{largeScreenFlag && !noAccessFlag && !repeatedLinkFlag && <Badge color="primary">{period}</Badge>}
					{showWeightsFlag && !categoryFlag ? (
						repeatedLinkFlag ? (
							<Block color={theme.muted}>{translate("edit.element.repeatedLink")}</Block>
						) : (
							<>
								<StyleWrapper padding="2px 3px" width="65px" fontSize="12px">
									<FormInput
										placeholder={noAccessFlag ? "0" : translate("edit.weight")}
										name={`links[${indexInFormValue}].weight`}
										type="number"
										disabled={noAccessFlag}
									/>
								</StyleWrapper>
								<Block>{"*"}</Block>
								<StyleWrapper padding="2px 3px" width="40px" fontSize="12px" textAlign="right">
									<Input value={score || 0} disabled />
								</StyleWrapper>
								<Block>{"="}</Block>
								<StyleWrapper padding="2px 3px" width="40px" fontSize="12px" textAlign="right">
									<Input value={calculatedScore?.toFixed(2)} disabled />
								</StyleWrapper>
							</>
						)
					) : null}
					<UnlinkButton fieldId={fieldId} noAccessFlag={noAccessFlag} name={name} unlinkObject={unlinkObject} />
				</>
			}
		/>
	);
};

ListItemLink.propTypes = propTypes;

export default ListItemLink;
