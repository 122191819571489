import React from "react";

let getMissingTitleError = (children) =>
	React.Children.toArray(children).forEach((child, i) => {
		if (!child.props || !child.props.title) {
			console.error(`Child of tab container with key ${child.key} was not passed a "title" prop which is required`);
		}
	});

export default getMissingTitleError;
