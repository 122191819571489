import { useCallback } from "react";
import getHandleSubmit from "./useHandleSubmit.getHandleSubmit";

let useHandleSubmit = ({ close, emptySubmitWarning, onSubmit, skipCloseFlag }) =>
	useCallback(
		(value, apiResponse, contextValue, skipCloseOnSubmitFlag) => {
			let handleSubmit = getHandleSubmit({ close, emptySubmitWarning, onSubmit, skipCloseFlag });
			return handleSubmit(value, apiResponse, contextValue, skipCloseOnSubmitFlag);
		},
		[emptySubmitWarning, onSubmit, close, skipCloseFlag]
	);
export default useHandleSubmit;
