import SelectAccess from "./Select.Access";
import SelectAccessType from "./Select.AccessType";
import SelectActionItem from "./Select.ActionItem";
import SelectActionItemLayout from "./Select.ActionItemLayout";
import SelectActivity from "./Select.Activity";
import SelectAggregateScorecardType from "./Select.AggregateScorecardType";
import SelectApiKey from "./Select.ApiKey";
import SelectAvailablePeriod from "./Select.AvailablePeriod";
import SelectBoolean from "./Select.Boolean";
import SelectBorderPosition from "./Select.BorderPosition";
import SelectBorderStyle from "./Select.BorderStyle";
import SelectBorderWidth from "./Select.BorderWidth";
import SelectCalculationType from "./Select.CalculationType";
import SelectChart from "./Select.Chart";
import SelectChartCategory from "./Select.ChartCategory";
import SelectChartMeasureSeries from "./Select.ChartMeasureSeries";
import SelectChartSeriesDataLabel from "./Select.ChartSeriesDataLabel";
import SelectChartSeriesLineStroke from "./Select.ChartSeriesLineStroke";
import SelectChartSeriesMarker from "./Select.ChartSeriesMarker";
import SelectChartSeriesShape from "./Select.ChartSeriesShape";
import SelectChartSeriesValue from "./Select.ChartSeriesValue";
import SelectChartSize from "./Select.ChartSize";
import SelectChartType from "./Select.ChartType";
import SelectChildObjectType from "./Select.ChildObjectType";
import SelectComparison from "./Select.Comparison";
import SelectContentType from "./Select.ContentType";
import SelectCurrency from "./Select.Currency";
import SelectCurrentOrSpecificElement from "./Select.CurrentOrSpecificElement";
import SelectCustomField from "./Select.CustomField";
import SelectCustomFieldType from "./Select.CustomFieldType";
import SelectCustomizationType from "./Select.CustomizationType";
import SelectDashboardSize from "./Select.DashboardSize";
import SelectDateRange from "./Select.DateRange";
import SelectDependency from "./Select.Dependency";
import SelectEmailNotificationSettings from "./Select.EmailNotificationSettings";
import SelectEmbedReportingPeriod from "./Select.EmbedReportingPeriod";
import SelectEvaluationOption from "./Select.EvaluationOption";
import SelectField from "./Select.Field";
import SelectFieldByObjectId from "./Select.FieldByObjectId";
import SelectFont from "./Select.Font";
import SelectFontSize from "./Select.FontSize";
import SelectFontStyle from "./Select.FontStyle";
import SelectFooterStyle from "./Select.FooterStyle";
import SelectGroupedObjectType from "./Select.GroupedObjectType";
import SelectHeaderStyle from "./Select.HeaderStyle";
import SelectHideLevel from "./Select.HideLevel";
import SelectInitiative from "./Select.Initiative";
import SelectInitiativeLayout from "./Select.InitiativeLayout";
import SelectLanguage from "./Select.Language";
import SelectLayout from "./Select.Layout";
import SelectLayoutAccessType from "./Select.LayoutAccessType";
import SelectLayoutPeriod from "./Select.LayoutPeriod";
import SelectLegend from "./Select.Legend";
import SelectLegendPosition from "./Select.LegendPosition";
import SelectLinkType from "./Select.LinkType";
import SelectMap from "./Select.Map";
import SelectMasterPeriod from "./Select.MasterPeriod";
import SelectMeasure from "./Select.Measure";
import SelectMeasureLayout from "./Select.MeasureLayout";
import SelectMeasureSeries from "./Select.MeasureSeries";
import SelectMilestone from "./Select.Milestone";
import SelectNotification from "./Select.Notification";
import SelectNumberFormat from "./Select.NumberFormat";
import SelectObject from "./Select.Object";
import SelectObjectType from "./Select.ObjectType";
import SelectObjective from "./Select.Objective";
import SelectObjectiveLayout from "./Select.ObjectiveLayout";
import SelectPage from "./Select.Page";
import SelectPageOrientation from "./Select.PageOrientation";
import SelectPageSize from "./Select.PageSize";
import SelectPeer from "./Select.Peer";
import SelectPeerGroup from "./Select.PeerGroup";
import SelectPeerMeasure from "./Select.PeerMeasure";
import SelectPeerSeries from "./Select.PeerSeries";
import SelectPeriod from "./Select.Period";
import SelectPeriodGroup from "./Select.PeriodGroup";
import SelectPeriodType from "./Select.PeriodType";
import SelectPodStyle from "./Select.PodStyle";
import SelectRecipient from "./Select.Recipient";
import SelectReferenceSeries from "./Select.ReferenceSeries";
import SelectReportType from "./Select.ReportType";
import SelectReportingFrequency from "./Select.ReportingFrequency";
import SelectReportingPeriods from "./Select.ReportingPeriods";
import SelectRisk from "./Select.Risk";
import SelectRiskLayout from "./Select.RiskLayout";
import SelectScheduleTaskTarget from "./Select.ScheduleTaskTarget";
import SelectScheduleTaskType from "./Select.ScheduleTaskType";
import SelectScorecard from "./Select.Scorecard";
import SelectScorecardLayout from "./Select.ScorecardLayout";
import SelectScorecardUserTypeId from "./Select.ScorecardUserTypeId";
import SelectSeriesDataType from "./Select.SeriesDataType";
import SelectShadowMeasure from "./Select.ShadowMeasure";
import SelectShadowObjectType from "./Select.ShadowObjectType";
import SelectSort from "./Select.Sort";
import SelectStatus from "./Select.Status";
import SelectStatusDisplay from "./Select.StatusDisplay";
import SelectTableStyle from "./Select.TableStyle";
import SelectTag from "./Select.Tag";
import SelectTimezone from "./Select.Timezone";
import SelectTokenType from "./Select.TokenType";
import SelectTrendline from "./Select.Trendline";
import SelectUser from "./Select.User";
import SelectGroupedUser from "./Select.GroupedUser";
import SelectZoom from "./Select.Zoom";

let SelectIndex = {};

SelectIndex.Access = SelectAccess;
SelectIndex.AccessType = SelectAccessType;
SelectIndex.ActionItem = SelectActionItem;
SelectIndex.ActionItemLayout = SelectActionItemLayout;
SelectIndex.Activity = SelectActivity;
SelectIndex.AggregateScorecardType = SelectAggregateScorecardType;
SelectIndex.ApiKey = SelectApiKey;
SelectIndex.AvailablePeriod = SelectAvailablePeriod;
SelectIndex.Boolean = SelectBoolean;
SelectIndex.BorderPosition = SelectBorderPosition;
SelectIndex.BorderStyle = SelectBorderStyle;
SelectIndex.BorderWidth = SelectBorderWidth;
SelectIndex.CalculationType = SelectCalculationType;
SelectIndex.Chart = SelectChart;
SelectIndex.ChartCategory = SelectChartCategory;
SelectIndex.ChartMeasureSeries = SelectChartMeasureSeries;
SelectIndex.ChartSeriesDataLabel = SelectChartSeriesDataLabel;
SelectIndex.ChartSeriesLineStroke = SelectChartSeriesLineStroke;
SelectIndex.ChartSeriesMarker = SelectChartSeriesMarker;
SelectIndex.ChartSeriesShape = SelectChartSeriesShape;
SelectIndex.ChartSeriesValue = SelectChartSeriesValue;
SelectIndex.ChartSize = SelectChartSize;
SelectIndex.ChartType = SelectChartType;
SelectIndex.ChildObjectType = SelectChildObjectType;
SelectIndex.Comparison = SelectComparison;
SelectIndex.ContentType = SelectContentType;
SelectIndex.Currency = SelectCurrency;
SelectIndex.CurrentOrSpecificElement = SelectCurrentOrSpecificElement;
SelectIndex.CustomField = SelectCustomField;
SelectIndex.CustomFieldType = SelectCustomFieldType;
SelectIndex.CustomizationType = SelectCustomizationType;
SelectIndex.DashboardSize = SelectDashboardSize;
SelectIndex.DateRange = SelectDateRange;
SelectIndex.Dependency = SelectDependency;
SelectIndex.EmailNotificationSettings = SelectEmailNotificationSettings;
SelectIndex.EmbedReportingPeriod = SelectEmbedReportingPeriod;
SelectIndex.EvaluationOption = SelectEvaluationOption;
SelectIndex.Field = SelectField;
SelectIndex.FieldByObjectId = SelectFieldByObjectId;
SelectIndex.FooterStyle = SelectFooterStyle;
SelectIndex.Font = SelectFont;
SelectIndex.FontSize = SelectFontSize;
SelectIndex.FontStyle = SelectFontStyle;
SelectIndex.GroupedObjectType = SelectGroupedObjectType;
SelectIndex.GroupedUser = SelectGroupedUser;
SelectIndex.HeaderStyle = SelectHeaderStyle;
SelectIndex.HideLevel = SelectHideLevel;
SelectIndex.Initiative = SelectInitiative;
SelectIndex.InitiativeLayout = SelectInitiativeLayout;
SelectIndex.Language = SelectLanguage;
SelectIndex.Layout = SelectLayout;
SelectIndex.LayoutAccessType = SelectLayoutAccessType;
SelectIndex.LayoutPeriod = SelectLayoutPeriod;
SelectIndex.Legend = SelectLegend;
SelectIndex.LegendPosition = SelectLegendPosition;
SelectIndex.LinkType = SelectLinkType;
SelectIndex.Map = SelectMap;
SelectIndex.MasterPeriod = SelectMasterPeriod;
SelectIndex.Measure = SelectMeasure;
SelectIndex.MeasureLayout = SelectMeasureLayout;
SelectIndex.MeasureSeries = SelectMeasureSeries;
SelectIndex.Milestone = SelectMilestone;
SelectIndex.Notification = SelectNotification;
SelectIndex.NumberFormat = SelectNumberFormat;
SelectIndex.Object = SelectObject;
SelectIndex.ObjectType = SelectObjectType;
SelectIndex.Objective = SelectObjective;
SelectIndex.ObjectiveLayout = SelectObjectiveLayout;
SelectIndex.Page = SelectPage;
SelectIndex.PageOrientation = SelectPageOrientation;
SelectIndex.PageSize = SelectPageSize;
SelectIndex.Peer = SelectPeer;
SelectIndex.PeerGroup = SelectPeerGroup;
SelectIndex.PeerMeasure = SelectPeerMeasure;
SelectIndex.PeerSeries = SelectPeerSeries;
SelectIndex.Period = SelectPeriod;
SelectIndex.PeriodGroup = SelectPeriodGroup;
SelectIndex.PeriodType = SelectPeriodType;
SelectIndex.PodStyle = SelectPodStyle;
SelectIndex.Recipient = SelectRecipient;
SelectIndex.ReferenceSeries = SelectReferenceSeries;
SelectIndex.ReportingFrequency = SelectReportingFrequency;
SelectIndex.ReportType = SelectReportType;
SelectIndex.ReportingPeriods = SelectReportingPeriods;
SelectIndex.Risk = SelectRisk;
SelectIndex.RiskLayout = SelectRiskLayout;
SelectIndex.ScheduleTaskTarget = SelectScheduleTaskTarget;
SelectIndex.ScheduleTaskType = SelectScheduleTaskType;
SelectIndex.Scorecard = SelectScorecard;
SelectIndex.ScorecardLayout = SelectScorecardLayout;
SelectIndex.ScorecardUserTypeId = SelectScorecardUserTypeId;
SelectIndex.SeriesDataType = SelectSeriesDataType;
SelectIndex.ShadowMeasure = SelectShadowMeasure;
SelectIndex.ShadowObjectType = SelectShadowObjectType;
SelectIndex.Sort = SelectSort;
SelectIndex.Status = SelectStatus;
SelectIndex.StatusDisplay = SelectStatusDisplay;
SelectIndex.TableStyle = SelectTableStyle;
SelectIndex.Tag = SelectTag;
SelectIndex.Trendline = SelectTrendline;
SelectIndex.Timezone = SelectTimezone;
SelectIndex.TokenType = SelectTokenType;
SelectIndex.User = SelectUser;
SelectIndex.Zoom = SelectZoom;

export default SelectIndex;
