import { useMemo } from "react";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import Select from "./FormSelect";

let propTypes = {
	filter: PropTypes.func,
};

// ruleTypeId	name
// 1	        Editor
// 2	        Updater
// 3	        Browser
// 4	        No Access
// 5	        Scorecard Admin
let SelectAccessType = ({ filter, ...props }) => {
	let translate = useTranslate();

	// HERE BE DRAGONS -- BEWARE AND DISMAY - - - - THIS IS DIFFERENT THAN USERTYPEID BUT WE DONT HAVE OTHER TRANSLATION LABELS THE VALUES ARE CORRECT
	let accessList = useMemo(
		() => [
			{ value: 4, label: translate("userTypes.user7") }, //no access
			{ value: 3, label: translate("userTypes.user4") }, //browser
			{ value: 2, label: translate("userTypes.user3") }, // updater
			{ value: 1, label: translate("userTypes.user2") }, // editor
			{ value: 5, label: translate("userTypes.user9") }, // scorecard admin
		],
		[translate]
	);

	let options = useMemo(() => {
		let optionsArray = [...accessList];

		if (filter) {
			optionsArray = optionsArray.filter(filter);
		}

		return optionsArray;
	}, [filter, accessList]);

	return <Select options={options} {...props} />;
};

SelectAccessType.propTypes = propTypes;

export default SelectAccessType;
