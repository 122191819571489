import Column from "@clearpoint/old-theme/Column";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormInputDate from "@clearpoint/old-theme/Form/Input/Input.Date";
import FormSelectChildObjectType from "@clearpoint/old-theme/Form/Select/Select.ChildObjectType";
import FormSelectDashboardSize from "@clearpoint/old-theme/Form/Select/Select.DashboardSize";
import FormSelectLayoutAccessType from "@clearpoint/old-theme/Form/Select/Select.LayoutAccessType";
import FormSelectReportType from "@clearpoint/old-theme/Form/Select/Select.ReportType";
import FormSelectTableStyle from "@clearpoint/old-theme/Form/Select/Select.TableStyle";
import Help from "@clearpoint/old-theme/Help";
import Hide from "@clearpoint/old-theme/Hide";
import Row from "@clearpoint/old-theme/Row";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useCheckAccess, useCheckFeature, useScorecard } from "@clearpoint/hooks";


let propTypes = {
	object: PropTypes.string,
};
let ElementReportTab = ({ object }) => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let checkFeature = useCheckFeature();
	let { reportType, groups, accessType } = useFormValue();
	let scorecard = useScorecard();
	let reportTypeFlag = ["measure", "initiative", "objective"].includes(object);
	let childFlag = reportType === "matrix" && object === "objective";
	let adminFlag = checkAccess({ access: scorecard?.access, action: "admin", scorecardId: false });
	let restrictedFlag = adminFlag && groups && groups.length !== 0;
	let styleFlag = checkFeature("customStyles");
	return (
		<>
			<FormFieldName
				name="name"
				label={translate("layout.reportName")}
				placeholder={translate("layout.reportName")}
			/>
			<Hide visible={reportTypeFlag}>
				<FormSelectReportType
					defaultValue="grid"
					name="reportType"
					label={translate("layout.reportType")}
					object={object}
				/>
			</Hide>
			{childFlag && (
				<FormSelectChildObjectType
					name="reportOptions.matrixChild"
					label={translate("tabGridOptions.matrixChildElement")}
					defaultFirstOptionFlag
				/>
			)}
			{reportType === "dashboard" && (
				<>
					<FormSelectDashboardSize
						name="reportOptions.dashboardSize"
						label={translate("tabGridOptions.dashboardSize")}
					/>
					<FormCheck name="reportOptions.showChartDescriptions">
						{translate("manage.chart.showChartDescription")}
					</FormCheck>
				</>
			)}
			{reportType === "gantt" && (
				<>
					<FormCheck name="reportOptions.hideGanttMilestones">{translate("layout.hideGanttMilestones")}</FormCheck>
					{adminFlag && <Help section="edit-grid-layout" feature="gantt-chart-dates" />}
					<Row>
						<Column large={6}>
							<FormInputDate name="reportOptions.ganttStartDate" label={translate("layout.ganttStartDate")} />
						</Column>
						<Column large={6}>
							<FormInputDate name="reportOptions.ganttEndDate" label={translate("layout.ganttEndDate")} />
						</Column>
					</Row>
					<FormInput
						type="number"
						name="reportOptions.gridWidth"
						label={translate("layout.ganttLeftWidth")}
						placeholder={translate("layout.ganttLeftWidthPlaceholder")}
					/>
				</>
			)}
			<Help section="edit-grid-layout" feature={adminFlag ? "report-access-type-admin" : "report-access-type"} />
			<FormSelectLayoutAccessType
				name="accessType"
				restrictedFlag={restrictedFlag}
				label={translate("options.accessType")}
			/>
			{accessType === "restricted" && (
				<FormGroup title={translate("layout.userGroups")}>
					{groups?.map((group, i) => (
						<StyleWrapper key={group.groupId} marginBottom={theme.tinySpace}>
							<FormCheck name={`groups[${i}].selected`} margin>
								{group.name}
							</FormCheck>
						</StyleWrapper>
					))}
				</FormGroup>
			)}
			{adminFlag && <Help section="sect12" feature="report-page-breaks" />}
			{["grid", "matrix"].includes(reportType) && (
				<>
					<FormInput
						data-testid="page-breaks-input"
						label={translate("layout.pageBreaks")}
						name="reportOptions.pageBreaks"
						pageBreakValidationFlag
					/>
					{styleFlag && (
						<FormSelectTableStyle
							noFacadeFlag
							name="reportOptions.tableStyleId"
							label={translate("layout.tableStyle")}
						/>
					)}
					{reportType === "grid" && (
						<>
							{adminFlag && <Help section="edit-grid-layout" feature="report-template-library" />}
							<FormCheck name="reportOptions.gridTemplate">{translate("layout.includeInLibrary")}</FormCheck>
						</>
					)}
					<FormCheck name="reportOptions.hideTitle">{translate("layout.hideExportTitles")}</FormCheck>
				</>
			)}
		</>
	);
};
ElementReportTab.propTypes = propTypes;
export default ElementReportTab;
