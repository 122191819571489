import CellWrapper from "../CellWrapper";
import FormFieldCollaborators from "@clearpoint/old-theme/Form/Field/Collaborators";
import FormFieldDisplayCollaborators from "@clearpoint/old-theme/Form/Field/Display/Collaborators";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let CollaboratorsCell = (props) => {
	let { editCellFlag, parentName } = props;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldCollaborators compactFlag name={`${parentName}.collaborators`} />
			) : (
				<FormFieldDisplayCollaborators name={`${parentName}.collaborators`} />
			)}
		</CellWrapper>
	);
};
CollaboratorsCell.propTypes = propTypes;

export default CollaboratorsCell;
