import { useEffect, useRef } from "react";
import { useMainStore } from "@clearpoint/store";
import useTranslatorState from "./useTranslatorState";
import generateTranslateFunction from "./generateTranslateFunction";
import setLanguage from "./useBaseLanguage.setLanguage";

let useBaseLanguage = () => {
	let { setTranslateLookup, clearScorecardIdList, translateLookup } = useTranslatorState();
	let localeId = useMainStore((state) => state.localeId);
	let loadingFlagRef = useRef();
	useEffect(
		() =>
			setLanguage({
				clearScorecardIdList,
				generateTranslateFunction,
				loadingFlagRef,
				localeId,
				setTranslateLookup,
				translateLookup,
			}),
		[clearScorecardIdList, localeId, setTranslateLookup, translateLookup]
	);
};
export default useBaseLanguage;
