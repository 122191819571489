import Block from "../Block";
import { theme } from "../Theme";
import { useEqualHeight } from "@clearpoint/hooks";


let DragAndDropTableCell = ({ className, ...props }) => {
	useEqualHeight(className ? "." + className : undefined);
	return (
		<Block
			padding="8px"
			border={`1px solid ${theme.lightGray4}`}
			backgroundColor={theme.white}
			className={className}
			{...props}
			overflow="hidden"
		/>
	);
};

export default DragAndDropTableCell;
