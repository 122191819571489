import Alert from "@clearpoint/old-theme/Alert/Alert";
import Block from "@clearpoint/old-theme/Block";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormError from "@clearpoint/old-theme/Form/Form.Error";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormInputTextEditor from "@clearpoint/old-theme/Form/Input/TextEditor/Input.TextEditor";
import FormLabel from "@clearpoint/old-theme/Form/Form.Label";
import FormSelectUser from "@clearpoint/old-theme/Form/Select/Select.User";
import Help from "@clearpoint/old-theme/Help";
import { useEffect } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";

let PeerToPeerSharingTab = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { userId } = get({ object: "profile" });
	let { setFormError, formError, setFormValue } = useFormContext();
	let { allowSharing: allowSharingFlag, peerGroups: peerGroupList } = useFormValue();
	useEffect(() => {
		let errorFlag = allowSharingFlag && !peerGroupList?.some((x) => x.selected);
		let noErrorFlag = !allowSharingFlag || peerGroupList?.some((x) => x.selected);
		if (!formError.peerGroups && errorFlag) {
			setFormError("peerGroups", translate("edit.element.sharing.selectGroup"));
		} else if (formError.peerGroups && noErrorFlag) {
			setFormError("peerGroups", undefined);
		}
		if (allowSharingFlag) {
			setFormValue("contactUserId", userId);
		} else {
			setFormValue("contactUserId", null);
		}
	}, [allowSharingFlag, formError, peerGroupList, userId, setFormError, setFormValue, translate]);

	return (
		<>
			<Help section="edit-element" feature="peer-sharing" />
			<FormGroup marginTop="0px" title={translate("edit.element.sharing")}>
				<FormLabel for="allowSharing">{translate("edit.element.sharing.contributionAgreement")}</FormLabel>
				<FormCheck labelWeight="normal" name="allowSharing">
					{translate("edit.element.sharing.contributionAgreementText")}
				</FormCheck>
				<Alert color="info">{translate("edit.element.sharing.shareText")}</Alert>
			</FormGroup>
			{allowSharingFlag && (
				<Block paddingBottom={theme.smallSpace}>
					<FormGroup title={translate("logFieldLabels.groups")}>
						{peerGroupList?.map((x, i) => (
							<FormCheck key={x.peerGroupId} name={`peerGroups[${i}].selected`}>
								{x.name}
							</FormCheck>
						))}
						<FormError margin={`-${theme.space} 0 0`} name="peerGroups" />
					</FormGroup>
					<FormSelectUser
						name="contactUserId"
						label={translate("measureLibrary.addEditLibraryMeasure.contactName")}
					/>
					<FormInputTextEditor
						name="definition"
						label={translate("measureLibrary.addEditLibraryMeasure.measureDefinition")}
						required
						noCssFlag
					/>
				</Block>
			)}
		</>
	);
};
export default PeerToPeerSharingTab;
