import Aggregation from "./Aggregation/Aggregation";
import AnySeries from "./AnySeries";
import Block from "../../../Block";
import Button from "../../../Button/Button";
import Column from "../../../Column";
import CurrentOrSpecificSeries from "./CurrentOrSpecificSeries";
import FormInput from "../../../Form/Input/Input";
import Icon from "../../../Icon/Icon";
import OperationButton from "./OperationButton";
import Row from "../../../Row";
import SelectFunction from "./SelectFunction";
import SpecificSeries from "./SpecificSeries/SpecificSeries";
import Test from "./Test";
import { useCallback } from "react";
import useFormValue from "../../../Form/hooks/useFormValue";
import useInsertText from "../../../Form/hooks/useInsertText";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { theme } from "../../../Theme";
import { useId, useStateObject } from "@clearpoint/hooks";

let propTypes = {
	helpFlag: PropTypes.bool,
	name: PropTypes.string,
	seriesType: PropTypes.string,
	testFlag: PropTypes.bool,
};

let InputCalculation = ({ helpFlag, name, seriesType, testFlag, ...props }) => {
	let translate = useTranslate();
	let [{ testVisible }, setState] = useStateObject({ testVisible: false });
	let id = useId();
	let formValue = useFormValue();
	let insertText = useInsertText({
		name,
		query: ".input-calculation-" + id,
	});
	let periodCountFlag = formValue._aggregation?.includes("NPeriod");
	let insertToken = useCallback(() => {
		if (periodCountFlag && (!formValue._aggregationPeriodCount || formValue._aggregationPeriodCount <= 0)) {
			toast.warning(translate("edit.series.integerN"));
			return;
		}
		let measureToken = "";
		if (seriesType === "currentOrSpecific" || seriesType === "specific")
			measureToken = `[m${formValue._specificSeriesMeasureId || ""}]`;
		let token = `${measureToken}[${formValue._seriesName}][${formValue._aggregation}]`;
		if (periodCountFlag) token = token.replace("{{NPeriod}}", formValue._aggregationPeriodCount);
		if (formValue._aggregation === "SpecificPeriod")
			token = token.replace("[SpecificPeriod]", `[${formValue._aggregationPeriodName}]`);
		insertText(token);
	}, [formValue, insertText, periodCountFlag, seriesType, translate]);
	let toggleTest = useCallback(() => setState({ testVisible: (x) => !x }), [setState]);
	return (
		<>
			<FormInput className={"input-calculation-" + id} as="textarea" rows="5" name={name} monospace {...props} />
			<Row>
				<Block marginBottom={theme.space} paddingLeft={theme.space}>
					{["(", ")", "+", "-", "*", "/"].map((x) => (
						<OperationButton key={x} text={x} insertText={insertText} />
					))}
				</Block>
				<Column marginTop={theme.space}>
					<SelectFunction insertText={insertText} />
				</Column>
				{testFlag && (
					<Column large={2} marginBottom={theme.space}>
						<Button color="primary" outlineFlag blockFlag onClick={toggleTest}>
							{translate(testVisible ? "global.close" : "global.test")}
						</Button>
					</Column>
				)}
			</Row>
			{testVisible && <Test name={name} seriesType={seriesType} />}
			{!testVisible && (
				<>
					{seriesType === "currentOrSpecific" && <CurrentOrSpecificSeries />}
					{seriesType === "any" && <AnySeries />}
					{seriesType === "specific" && (
						<Row justifyContent="space-between">
							<SpecificSeries name="_seriesName" />
							{formValue._specificSeriesMeasureId && (
								<Aggregation value="CurrentPeriod" name="_aggregation" seriesName="_seriesName" />
							)}
						</Row>
					)}
					<Block textAlign="right" display="flex" width="100%" justifyContent="right" marginRight={theme.space}>
						<Button
							color="info"
							className="js-insert-button"
							onClick={insertToken}
							disabled={
								!(
									formValue._seriesName &&
									formValue._aggregation &&
									(formValue._aggregation !== "SpecificPeriod" ||
										formValue._aggregationPeriodName !== undefined)
								)
							}
						>
							<Icon name="insert" />
							&nbsp;&nbsp;{translate("global.insert")}
						</Button>
					</Block>
				</>
			)}
		</>
	);
};

InputCalculation.propTypes = propTypes;

export default InputCalculation;
