import { useCallback, useContext, useEffect, useMemo, useRef, createContext, useState } from "react";
import useFormClear from "./internal-hooks/useFormClear";
import useFormDirty from "./internal-hooks/useFormDirty";
import useFormError from "./internal-hooks/useFormError";
import useFormState from "./internal-hooks/useFormState";
import useFormStatus from "./internal-hooks/useFormStatus";
import useOnSubmit from "./internal-hooks/useOnSubmit";
import useSetFastValue from "./internal-hooks/useSetFastValue";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import updateFormWithNewDefaultValue from "./internal-hooks/updateFormWithNewDefaultValue";
import { removeEmpty } from "@clearpoint/utils";
import { usePrevious } from "@clearpoint/hooks";

let FormContext = createContext();

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	defaultValue: PropTypes.object,
	newObjectFlag: PropTypes.bool,
	onSubmit: PropTypes.func,
	resetAfterSubmitFlag: PropTypes.bool,
	submitUnchangedFlag: PropTypes.bool,
};

let formEventLookup = {
	resetForm: "resetForm",
};

let Form = ({
	children,
	className,
	defaultValue,
	newObjectFlag,
	onSubmit,
	resetAfterSubmitFlag,
	submitUnchangedFlag,
}) => {
	// let { object, objectId } = useQueryParameters();
	// let updateFieldList = useFieldList({ object, objectId, updateFlag: true }).map((x) => x.value);
	let [keyState, setKeyState] = useState(0);
	let changeKeyState = useCallback(() => setKeyState((x) => x + 1), []);
	let formContextRef = useRef({});
	let { setOnSubmit, resetOnSubmit, handleSubmit, handlingSubmitFlag } = useOnSubmit({
		changeKeyState,
		formContextRef,
		onSubmit,
		resetAfterSubmitFlag,
		submitUnchangedFlag,
	});
	// default value
	if (!defaultValue) defaultValue = {};
	let previousDefaultValue = usePrevious(defaultValue) || defaultValue;
	let defaultValueCopy = useMemo(() => removeEmpty(defaultValue, true), [defaultValue]); // keeps empty arrays, removes empty objects
	// dependent state (updates with form value changes)
	let { formDirty, updateFormDirty, formDirtyRef } = useFormDirty({ formContextRef });
	let { formError, updateFormError, formErrorRef } = useFormError({ formContextRef });
	let { setFastValueRef, setFastValue } = useSetFastValue();
	// callbacks
	let formDefaultValueCallbackList = useMemo(() => [updateFormDirty], [updateFormDirty]);
	let formValueCallbackList = useMemo(
		() => [setFastValue, updateFormDirty, updateFormError],
		[setFastValue, updateFormDirty, updateFormError]
	);
	let formValidationCallbackList = useMemo(() => [updateFormError], [updateFormError]);
	// form state
	let [formDefaultValue, setFormDefaultValue, formDefaultValueRef] = useFormState(
		defaultValueCopy,
		"defaultFormValue",
		formDefaultValueCallbackList,
		true
	);
	let [formValue, setFormValue, formValueRef] = useFormState(
		defaultValueCopy,
		"formValue",
		formValueCallbackList,
		true
	);
	let [formValidation, setFormValidation, formValidationRef] = useFormState(
		{},
		"formValidation",
		formValidationCallbackList
	);
	let [formTouched, setFormTouched, formTouchedRef] = useFormState({}, "formTouched");
	let [customFormError, setCustomFormError, customFormErrorRef] = useFormState({}, "customFormError");
	// merge custom form error
	formError = useMemo(
		() => (isEmpty(customFormError) ? formError : merge(cloneDeep(formError), customFormError)),
		[customFormError, formError]
	);
	// calculated state
	let formStatus = useFormStatus({ formTouched, formError, formDirty }); // touched, dirty, valid
	// utility functions
	let { clearFormValue, restoreFormValue, setSavedFormValue } = useFormClear({
		formContextRef,
	});
	let resetForm = useCallback(() => {
		setFormValue(true, formDefaultValue);
		document.dispatchEvent(new Event(formEventLookup.resetForm));
	}, [formDefaultValue, setFormValue]);
	let hardResetForm = useCallback(() => {
		resetOnSubmit();
		let { setFormError, setFormDefaultValue, setFormValue, setFormTouched, setFormValidation } =
			formContextRef.current;
		for (let setterFunction of [setFormError, setFormDefaultValue, setFormValue, setFormTouched, setFormValidation]) {
			setterFunction("HARD_RESET");
		}
	}, [resetOnSubmit]);

	useEffect(() => {
		// reset default value and form value if default value prop updates
		if (defaultValue && defaultValue !== previousDefaultValue && !isEqual(defaultValue, previousDefaultValue)) {
			setFormDefaultValue(true, defaultValueCopy);
			setFormValue(true, updateFormWithNewDefaultValue(formDirty, defaultValueCopy));
		}
	}, [defaultValue, defaultValueCopy, formDirty, previousDefaultValue, setFormDefaultValue, setFormValue]);
	let saveStatusRef = useRef(false);

	let formContext = useMemo(
		() => ({
			clearFormValue,
			customFormError,
			customFormErrorRef,
			formDefaultValue,
			formDefaultValueRef,
			formDirty,
			formDirtyRef,
			formError,
			formErrorRef,
			formStatus,
			formTouched,
			formTouchedRef,
			formValidation,
			formValidationRef,
			formValue,
			formValueRef,
			handlingSubmitFlag,
			hardResetForm,
			newObjectFlag,
			onSubmit: handleSubmit,
			resetForm,
			restoreFormValue,
			saveStatusRef,
			setFastValueRef,
			setFormDefaultValue,
			setFormError: setCustomFormError,
			setFormTouched,
			setFormValidation,
			setFormValue,
			setOnSubmit,
			setSavedFormValue,
		}),
		[
			clearFormValue,
			customFormError,
			customFormErrorRef,
			formDefaultValue,
			formDefaultValueRef,
			formDirty,
			formDirtyRef,
			formError,
			formErrorRef,
			formStatus,
			formTouched,
			formTouchedRef,
			formValidation,
			formValidationRef,
			formValue,
			formValueRef,
			handleSubmit,
			handlingSubmitFlag,
			hardResetForm,
			newObjectFlag,
			resetForm,
			restoreFormValue,
			setCustomFormError,
			setFastValueRef,
			setFormDefaultValue,
			setFormTouched,
			setFormValidation,
			setFormValue,
			setOnSubmit,
			setSavedFormValue,
		]
	);
	formContextRef.current = formContext;

	return useMemo(
		() => (
			<FormContext.Provider value={formContext}>
				<form key={"" + keyState} className={className} onSubmit={handleSubmit} autoComplete="chrome-off">
					{children}
				</form>
			</FormContext.Provider>
		),
		[formContext, keyState, className, handleSubmit, children]
	);
};
Form.propTypes = propTypes;

let useFormContext = () => useContext(FormContext);

export default Form;

export { useFormContext, formEventLookup };
