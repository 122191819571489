import Block from "../../Block";
import Button from "../../Button/Button";
import HTML from "../../HTML";
import Loading from "../../Loading";
import React, { Suspense, useCallback, useState } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import classNames from "classnames";
import { theme } from "../../Theme";
let TextEditorContent = React.lazy(() => import("@clearpoint/old-theme/Input/TextEditor/Content/TextEditorContent"));
let TinyMceEditor = React.lazy(() => import("@clearpoint/old-theme/Input/TextEditor/TinyMce"));

let propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string,
	styleClass: PropTypes.string,
	tinyMceFlag: PropTypes.bool,
	value: PropTypes.string,
};
let ClickToActivate = ({ name, disabled, styleClass, tinyMceFlag, value, ...props }) => {
	let translate = useTranslate();
	let [textEditorVisible, setTextEditorVisible] = useState();
	let onClick = useCallback(() => setTextEditorVisible(true), []);
	return textEditorVisible ? (
		<Suspense fallback={<Loading />}>
			{tinyMceFlag ? (
				<TinyMceEditor name={name} styleClass={styleClass} value={value} {...props} />
			) : (
				<TextEditorContent name={name} styleClass={styleClass} value={value} {...props} />
			)}
		</Suspense>
	) : (
		<Block position="relative" height="300px" overflow="hidden" padding={theme.smallSpace}>
			<HTML className={classNames("redactor-preview", styleClass)}>{value}</HTML>
			<Block
				position="absolute"
				top={0}
				left={0}
				width="100%"
				height={`calc(100% - ${theme.space})`}
				backgroundColor="rgba(201, 201, 201, 0.1)"
				borderRadius="3px"
				display="flex"
				justifyContent="center"
				alignItems="center"
				cursor="pointer"
				onClick={disabled ? () => null : onClick}
			>
				{!disabled && (
					<Button color="success" size="small">
						{translate("customField.clickToActivate")}
					</Button>
				)}
			</Block>
		</Block>
	);
};
ClickToActivate.propTypes = propTypes;

export default ClickToActivate;
