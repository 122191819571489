import Block from "@clearpoint/old-theme/Block";
import FormFieldDisplayElapsedDays from "@clearpoint/old-theme/Form/Field/Display/ElapsedDays";

let ElapsedDays = () => (
	<Block display="flex" justifyContent="flex-end">
		<FormFieldDisplayElapsedDays name="updates.calculatedValues.elapsedDays" />
	</Block>
);

export default ElapsedDays;
