import { useEffect, useState } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

let useAgreementModalVisible = () => {
	let { get } = useOldQueryStore();

	let profile = get({ object: "profile" });
	let openAIAcceptedFlag = profile?.openAIAccepted;

	let [agreementModalVisible, setAgreementModalVisible] = useState(false);

	useEffect(() => {
		setAgreementModalVisible(!openAIAcceptedFlag);
	}, [get, openAIAcceptedFlag]);

	return agreementModalVisible;
};

export default useAgreementModalVisible;
