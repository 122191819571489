import Block from "../Block";
import InitialsCircle from "../Circle/Circle.Initials";
import TrashButton from "../Button/Button.Trash";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useDescribeTimePassed, useUserType } from "@clearpoint/hooks";


let propTypes = {
	comment: PropTypes.shape({
		comment: PropTypes.string,
		commentDate: PropTypes.string,
		discussionId: PropTypes.number,
		editFlag: PropTypes.bool,
	}),
	editFlag: PropTypes.bool,
};

let Comment = ({ comment: commentData, editFlag }) => {
	let translate = useTranslate();
	let describeTimePassed = useDescribeTimePassed();
	let { get } = useOldQueryStore();
	let { comment, commentDate, discussionId, userId } = commentData;
	let user = get({ object: "user" })?.find((x) => x.userId === userId);
	let profile = get({ object: "profile" });
	let userType = useUserType();
	let allowDeleteFlag = userId === profile?.userId || userType === "Administrator";

	return (
		<Block className="cps-comment">
			<Block display="flex" justifyContent="space-between" alignItems="flex-start" flexWrap="wrap">
				<Block display="flex" alignItems="center" lineHeight="1.1">
					<InitialsCircle
						firstName={user?.firstName || " "}
						lastName={user?.lastName || " "}
						marginRight={theme.smallSpace}
						size="tiny"
						$style={`opacity: ${!user && 0};`}
					/>
					<Block lineHeight="1.1">
						<span className="cps-name">{user?.fullName || translate("global.deletedUser")}</span>
						<br />
						<small className="cps-time">{describeTimePassed(commentDate)}</small>
					</Block>
				</Block>
				{editFlag && allowDeleteFlag && (
					<TrashButton
						body={translate("edit.confirmCommentDeleteWithButtons")}
						iconTransform=" "
						object="comment"
						objectId={discussionId}
						noFixedWidthFlag
						toast={translate("button.commentDeleted")}
					/>
				)}
			</Block>
			<Block
				margin={`${theme.tinySpace} 0px`}
				padding={theme.smallSpace}
				minHeight={theme.mediumSpace}
				backgroundColor={theme.offWhite}
				border={`1px solid ${theme.gray5}`}
				borderRadius="4px"
				boxShadow="inset 0 1px 1px rgb(0 0 0 / 5%)"
				className="well"
			>
				{comment}
			</Block>
		</Block>
	);
};
Comment.propTypes = propTypes;
export default Comment;
