import Block from "../Block";
import CopyForwardModalOnClick from "../Modal/Modal.CopyForward.OnClick";
import IconButton from "./Button.Icon";
import ModalOnClick from "../Modal/Modal.OnClick";
import ModalWindowOnClick from "../ModalWindow/ModalWindow.OnClick";
import StyleWrapper from "../StyleWrapper";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let CopyForwardButtonContent = ({ showTextFlag, ...props }) => {
	let translate = useTranslate();

	return showTextFlag ? (
		<IconButton name="copyForward" iconSize="small" {...props}>
			{" " + translate("button.copyForward")}
		</IconButton>
	) : (
		<IconButton name="copyForward" {...props} />
	);
};

let propTypes = {
	className: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	onClick: PropTypes.func,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	title: PropTypes.string,
	toast: PropTypes.string,
};

let defaultProps = {
	size: "tiny",
};

let CopyForwardButton = ({ Modal, ModalWindow, right, ...props }) => {
	return ModalWindow ? (
		<ModalWindowOnClick ModalWindow={ModalWindow} {...props}>
			<CopyForwardButtonContent {...props} />
		</ModalWindowOnClick>
	) : Modal ? (
		<ModalOnClick Modal={Modal} {...props}>
			<CopyForwardButtonContent {...props} />
		</ModalOnClick>
	) : (
		<Block position="relative" height="0" width="100%">
			<CopyForwardModalOnClick {...props}>
				<StyleWrapper position="absolute" top="-4px" right={right || "0px"}>
					<CopyForwardButtonContent {...props} />
				</StyleWrapper>
			</CopyForwardModalOnClick>
		</Block>
	);
};

CopyForwardButton.propTypes = propTypes;
CopyForwardButton.defaultProps = defaultProps;

export default CopyForwardButton;
