let objectiveLinkTypeList = [
	"categories",
	"measures",
	"initiatives",
	"parents",
	"children",
	"childMeasures",
	"childInitiatives",
	"actionItems",
	"risks",
];

export default objectiveLinkTypeList;
