import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let SelectFooterStyle = (props) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let reportStyleList = get({ object: "reportStyle" });

	let options = useMemo(() => {
		let optionList = [
			{ label: translate("briefingBook.noFooter"), value: -2 },
			{ label: translate("briefingBook.clearPointDefaultFooter"), value: 0 },
		];

		let incomingFooterStyleList = reportStyleList?.filter((x) => x.type === "footer" && x.active);
		if (incomingFooterStyleList) {
			let defaultStyle = incomingFooterStyleList?.find((x) => x.defaultStyle);
			if (defaultStyle)
				optionList.push({
					label: `${translate("briefingBook.organizationalDefaultFooter")} (${defaultStyle.name})`,
					value: -3,
				});

			let remainingFooterStyleList = incomingFooterStyleList
				?.filter((x) => !x.defaultStyle)
				.map((x) => ({ label: x.name, value: x.reportStyleId }));
			let customStyleOption = { label: translate("briefingBook.customFooter"), value: -1 };

			return [...optionList, ...remainingFooterStyleList, customStyleOption];
		}
		return [];
	}, [reportStyleList, translate]);

	return (
		<FormSelect
			label={translate("briefingBook.reportFooterStyle")}
			options={options}
			placeholder={translate("briefingBook.selectFooterStyle")}
			{...props}
		/>
	);
};

export default SelectFooterStyle;
