// READ ME!!!
// Comments show which Fields are in bulk edits/changes.
// Please update bulk edits/changes to match element Fields.

import AltText from "./AltText"; // (Chart Description) Edit: chart
import Analysis from "./Analysis"; // Update: action item, initiative, measure, milestone, objective, risk
import Archived from "./Archived"; // Edit: archived fiels
import Background from "./Background"; // Edit: Map
import ChartPeriodGroupId from "./ChartPeriodGroupId";
import ChartSize from "./ChartSize"; // Edit: chart
import ChartType from "./ChartType"; // Edit: chart
import Collaborators from "./Collaborators"; // Edit: scorecard, action item, initiative, measure, milestone, objective, risk
import CompletedDate from "./CompletedDate"; // Edit: action item, initiative, milestone, risk
import Display from "./Display";
import EnablePlotBands from "./EnablePlotBands"; // Edit: chart
import EndDate from "./EndDate"; // Edit: action item, initiative, milestone, risk
import Evaluations from "./Evaluations"; // Edit: measure series
import GrantAdminAccess from "./GrantAdminAccess"; // Add: scorecards
import HiddenPeriods from "./HiddenPeriods"; // Edit: action item, initiative, measure, objective, risk
import HiddenSeries from "./HiddenSeries"; // Edit: measure series
import HiddenSummary from "./HiddenSummary"; // Edit: chart, measure series
import HideTitle from "./HideTitle"; // Edit: map
import IsInterpolated from "./IsInterpolated"; // Edit: chart
import IsReversed from "./IsReversed"; // Edit: chart
import IsTransposed from "./IsTransposed"; // Edit: chart
import LinkMilestoneDates from "./LinkMilestoneDates"; // Edit: initiative
import Locked from "./Locked"; // Edit: measure series, scorecard
import Name from "./Name"; // Edit: action item, chart, initiative, measure, measure series, milestone, objective, risk, scorecard
import Names from "./Names"; // Add multiple: scorecard
import OwnerId from "./OwnerId"; // Edit: scorecard, action item, initiative, measure, milestone, objective, risk
import ParentId from "./ParentId"; // Edit: milestone, scorecard
import PercentComplete from "./PercentComplete"; // Update: initiative, milestone
import PeriodDisplay from "./PeriodDisplay"; // Edit: chart
import PeriodGroupId from "./PeriodGroupId"; // (Reporting Frequency) Edit: action item, chart, initiative, measure, objective, risk
import Recommendations from "./Recommendations"; // Update: initiative, measure, milestone, objective
import RequireApprovals from "./RequireApprovals"; // Edit: scorecard
import ScorecardId from "./ScorecardId"; // Edit: action item, initiative, measure, objective, risk
import SeriesData from "./SeriesData";
import SeriesDataTypeId from "./SeriesDataTypeId"; // Edit: measure series
import SeriesType from "./SeriesType"; // Edit: chart
import ShowFuture from "./ShowFuture"; // Edit: chart
import ShowLegend from "./ShowLegend"; // Edit: chart
import ShowNames from "./ShowNames"; // Edit: map
import SortSeries from "./SortSeries"; // Edit: chart
import StartAndEndDate from "./StartAndEndDate"; // Edit: action item, initiative, milestone, risk
import StartDate from "./StartDate"; // Edit: action item, initiative, milestone, risk
import StatusId from "./StatusId"; // Update: action item, initiative, measure, milestone, objective, risk
import Tags from "./Tags"; // Edit: action item, chart, initiative, measure, measure series, milestone, objective, risk, scorecard
import Title from "./Title"; // Edit: chart
import XTitle from "./XTitle"; // Edit: chart
import Y2Max from "./Y2Max"; // Edit: chart
import Y2Min from "./Y2Min"; // Edit: chart
import Y2Title from "./Y2Title"; // Edit: chart
import YAxis2Format from "./YAxis2Format"; // Edit: chart
import YAxisFormat from "./YAxisFormat"; // Edit: chart
import YMax from "./YMax"; // Edit: chart
import YMin from "./YMin"; // Edit: chart
import YTitle from "./YTitle"; // Edit: chart

let FieldIndex = {};

FieldIndex.Display = Display;

FieldIndex.AltText = AltText;
FieldIndex.Analysis = Analysis;
FieldIndex.Archived = Archived;
FieldIndex.Background = Background;
FieldIndex.ChartPeriodGroupId = ChartPeriodGroupId;
FieldIndex.ChartSize = ChartSize;
FieldIndex.ChartType = ChartType;
FieldIndex.Collaborators = Collaborators;
FieldIndex.CompletedDate = CompletedDate;
FieldIndex.EndDate = EndDate;
FieldIndex.EnablePlotBands = EnablePlotBands;
FieldIndex.Evaluations = Evaluations;
FieldIndex.GrantAdminAccess = GrantAdminAccess;
FieldIndex.HiddenPeriods = HiddenPeriods;
FieldIndex.HiddenSeries = HiddenSeries;
FieldIndex.HiddenSummary = HiddenSummary;
FieldIndex.HideTitle = HideTitle;
FieldIndex.IsInterpolated = IsInterpolated;
FieldIndex.IsReversed = IsReversed;
FieldIndex.IsTransposed = IsTransposed;
FieldIndex.LinkMilestoneDates = LinkMilestoneDates;
FieldIndex.Locked = Locked;
FieldIndex.Name = Name;
FieldIndex.Names = Names;
FieldIndex.OwnerId = OwnerId;
FieldIndex.ParentId = ParentId;
FieldIndex.PercentComplete = PercentComplete;
FieldIndex.PeriodDisplay = PeriodDisplay;
FieldIndex.PeriodGroupId = PeriodGroupId;
FieldIndex.Recommendations = Recommendations;
FieldIndex.RequireApprovals = RequireApprovals;
FieldIndex.ScorecardId = ScorecardId;
FieldIndex.SeriesData = SeriesData;
FieldIndex.SeriesDataTypeId = SeriesDataTypeId;
FieldIndex.SeriesType = SeriesType;
FieldIndex.ShowLegend = ShowLegend;
FieldIndex.SortSeries = SortSeries;
FieldIndex.ShowFuture = ShowFuture;
FieldIndex.ShowNames = ShowNames;
FieldIndex.StartAndEndDate = StartAndEndDate;
FieldIndex.StartDate = StartDate;
FieldIndex.StatusId = StatusId;
FieldIndex.Tags = Tags;
FieldIndex.Title = Title;
FieldIndex.XTitle = XTitle;
FieldIndex.Y2Max = Y2Max;
FieldIndex.Y2Min = Y2Min;
FieldIndex.Y2Title = Y2Title;
FieldIndex.YAxis2Format = YAxis2Format;
FieldIndex.YAxisFormat = YAxisFormat;
FieldIndex.YMax = YMax;
FieldIndex.YMin = YMin;
FieldIndex.YTitle = YTitle;
export default FieldIndex;
