import Block from "../Block";
import useFormError from "../Form/hooks/useFormError";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	className: PropTypes.string,
	errorMessage: PropTypes.node,
	margin: PropTypes.string,
	name: PropTypes.string,
};

let FormError = ({ errorMessage, name, className, margin }) => {
	let formErrorMessage = useFormError(name);

	errorMessage = errorMessage || formErrorMessage;
	if (typeof errorMessage !== "string") errorMessage = "";

	return formErrorMessage ? (
		<Block
			className={className}
			color={theme.danger}
			fontSize="0.9em"
			lineHeight="1.43"
			padding="2px 0 0"
			textAlign="left"
			margin={margin}
		>
			{errorMessage}
		</Block>
	) : null;
};

FormError.propTypes = propTypes;

export default FormError;
