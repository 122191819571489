import Block from "../../../Block";
import ModalWindow from "../../../ModalWindow/ModalWindow";
import ModalWindowContent from "../../../ModalWindow/ModalWindow.Content";
import ModalWindowFooter from "../../../ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "../../../ModalWindow/ModalWindow.Header";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	CalculationMapTab: PropTypes.elementType,
	objectId: PropTypes.number.isRequired,
};
let CalculationMapModal = ({ CalculationMapTab, objectId, ...props }) => {
	let translate = useTranslate();
	return (
		<ModalWindow object="measureSeries" objectId={objectId} {...props}>
			<ModalWindowHeader
				badgeIcon="measureSeries"
				badgeText={translate("labels.measureSeries")}
				title={translate("edit.map.viewCalculationMap")}
			/>
			<ModalWindowContent>
				<Block
					width="100%"
					backgroundColor={theme.white}
					padding={`${theme.space} ${theme.mediumSpace} ${theme.mediumSpace} ${theme.mediumSpace}`}
				>
					<CalculationMapTab />
				</Block>
			</ModalWindowContent>
			<ModalWindowFooter noSaveFlag />
		</ModalWindow>
	);
};
CalculationMapModal.propTypes = propTypes;
export default CalculationMapModal;
