import { useEffect, useRef } from "react";


let useDisableParentRef = () => {
	let ref = useRef();
	useEffect(() => {
		let disabledElementList = [];
		if (ref.current) {
			disabledElementList = ref.current.querySelectorAll(".disabled");
			for (let disabledElement of disabledElementList) {
				disabledElement.parentElement.style.opacity = 0.5;
				disabledElement.parentElement.style.cursor = "not-allowed";
			}
		}
		return () => {
			for (let disabledElement of disabledElementList) {
				disabledElement.parentElement.style.removeProperty("opacity");
				disabledElement.parentElement.style.removeProperty("cursor");
			}
		};
	});
	return ref;
};
export default useDisableParentRef;
