import Block from "../Block";
import Icon from "./Icon";
import PropTypes from "prop-types";
import { theme } from "../Theme";
let propTypes = {
	marginRight: PropTypes.string,
	name: PropTypes.string,
};

let StackedIcon = ({ marginRight, name }) => (
	<Block
		height="26px"
		width="26px"
		position="relative"
		marginRight={marginRight}
		$style={`& > * {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%) scale(0.9);
			
		}`}
	>
		<Icon name="square" size="huge" noTitleFlag />
		<Icon name={name} inverse color={theme.white} />
	</Block>
);

StackedIcon.propTypes = propTypes;
export default StackedIcon;
