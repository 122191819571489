import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	scorecardFlag: PropTypes.bool,
	milestoneFlag: PropTypes.bool,
	mapFlag: PropTypes.bool,
};
let SelectGroupedObjectType = ({ ...props }) => {
	let translate = useTranslate();
	let objectTypeList = useMemo(() => {
		let options = [
			{
				value: "scorecard",
				group: translate("recycleBin.group.elements"),
				label: translate("scorecard"),
			},
			{
				value: "category",
				group: translate("recycleBin.group.elements"),
				label: translate("category"),
			},
			{
				value: "map",
				group: translate("recycleBin.group.elements"),
				label: translate("recycleBin.objects.map"),
			},
			{
				value: "objective",
				group: translate("recycleBin.group.elements"),
				label: translate("objective"),
			},
			{
				value: "measure",
				group: translate("recycleBin.group.elements"),
				label: translate("measure"),
			},
			{
				value: "measureSeries",
				group: translate("recycleBin.group.elements"),
				label: translate("recycleBin.objects.measureSeries"),
			},
			{
				value: "chart",
				group: translate("recycleBin.group.elements"),
				label: translate("recycleBin.objects.chart"),
			},
			{
				value: "initiative",
				group: translate("recycleBin.group.elements"),
				label: translate("initiative"),
			},
			{
				value: "milestone",
				group: translate("recycleBin.group.elements"),
				label: translate("milestone"),
			},
			{
				value: "actionItem",
				group: translate("recycleBin.group.elements"),
				label: translate("actionItem"),
			},
			{
				value: "risk",
				group: translate("recycleBin.group.elements"),
				label: translate("risk"),
			},
			{
				value: "gridLayout",
				group: translate("recycleBin.group.reports"),
				label: translate("recycleBin.objects.gridLayout"),
			},
			{
				value: "dashboardLayout",
				group: translate("recycleBin.group.reports"),
				label: translate("recycleBin.objects.dashboardLayout"),
			},
			{
				value: "template",
				group: translate("recycleBin.group.reports"),
				label: translate("recycleBin.objects.template"),
			},
			{
				value: "period",
				group: translate("recycleBin.group.periods"),
				label: translate("recycleBin.objects.period"),
			},
			{
				value: "periodGroup",
				group: translate("recycleBin.group.periods"),
				label: translate("recycleBin.objects.periodGroup"),
			},
			{
				value: "fiscalYear",
				group: translate("recycleBin.group.periods"),
				label: translate("recycleBin.objects.fiscalYear"),
			},
			{
				value: "user",
				group: translate("recycleBin.group.users"),
				label: translate("recycleBin.objects.user"),
			},
			{
				value: "group",
				group: translate("recycleBin.group.users"),
				label: translate("recycleBin.objects.group"),
			},
			{
				value: "defaultSeries",
				group: translate("recycleBin.group.configuration"),
				label: translate("recycleBin.objects.defaultSeries"),
			},
			{
				value: "customField",
				group: translate("recycleBin.group.configuration"),
				label: translate("recycleBin.objects.customField"),
			},
			{
				value: "calculatedField",
				group: translate("recycleBin.group.configuration"),
				label: translate("recycleBin.objects.calculatedField"),
			},
			{
				value: "masterMeasure",
				group: translate("recycleBin.group.configuration"),
				label: translate("recycleBin.objects.masterMeasure"),
			},
			{
				value: "masterSeries",
				group: translate("recycleBin.group.configuration"),
				label: translate("recycleBin.objects.masterSeries"),
			},
			{
				value: "masterChart",
				group: translate("recycleBin.group.configuration"),
				label: translate("recycleBin.objects.masterChart"),
			},
			{
				value: "status",
				group: translate("recycleBin.group.customizations"),
				label: translate("recycleBin.objects.status"),
			},
			{
				value: "customChart",
				group: translate("recycleBin.group.customizations"),
				label: translate("recycleBin.objects.customChart"),
			},
			{
				value: "podStyle",
				group: translate("recycleBin.group.customizations"),
				label: translate("recycleBin.objects.podStyle"),
			},
			{
				value: "tableStyle",
				group: translate("recycleBin.group.customizations"),
				label: translate("recycleBin.objects.tableStyle"),
			},
			{
				value: "numberFormat",
				group: translate("recycleBin.group.customizations"),
				label: translate("recycleBin.objects.numberFormat"),
			},
			{
				value: "archive",
				group: translate("recycleBin.group.configuration"),
				label: translate("recycleBin.objects.archive"),
			},
			{
				value: "reminder",
				group: translate("global.automation"),
				label: translate("recycleBin.objects.reminder"),
			},
			{
				value: "schedule",
				group: translate("global.automation"),
				label: translate("recycleBin.objects.schedule"),
			},
			{
				value: "package",
				group: translate("global.automation"),
				label: translate("recycleBin.objects.package"),
			},
			{
				value: "script",
				group: translate("global.automation"),
				label: translate("recycleBin.objects.script"),
			},
			{
				value: "apiKey",
				group: translate("recycleBin.group.integrations"),
				label: translate("recycleBin.objects.apiKey"),
			},
			{
				value: "workflow",
				group: translate("global.automation"),
				label: translate("workflow.workflows"),
			},
			{
				value: "workflowEmails",
				group: translate("global.automation"),
				label: translate("workflow.workflowEmails"),
			},
		];

		return options;
	}, [translate]);
	return <FormSelect options={objectTypeList} {...props} />;
};
SelectGroupedObjectType.propTypes = propTypes;

export default SelectGroupedObjectType;
