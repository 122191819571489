let updateHiddenPeriodList = ({
	availablePeriodList,
	displayPeriodListInForm,
	hiddenPeriodListNameInForm,
	periodListAll,
	previousHiddenPeriodListLength,
	setFormValue,
}) => {
	if (!displayPeriodListInForm) return;
	let newHiddenPeriodList = periodListAll
		?.map((x) => x.periodId)
		.filter(
			(x) =>
				!displayPeriodListInForm.includes(x) ||
				!availablePeriodList?.find((availablePeriod) => availablePeriod.periodId === x)
		);
	if (newHiddenPeriodList && previousHiddenPeriodListLength !== newHiddenPeriodList.length) {
		setFormValue(hiddenPeriodListNameInForm, newHiddenPeriodList);
	}
};

export default updateHiddenPeriodList;
