import { useMemo } from "react";
import { useFormatDate } from "@clearpoint/hooks";


let useScale = ({ scale, ganttRef, endDate, startDate }) => {
	let formatDate = useFormatDate();
	let endYear = new Date(endDate).getFullYear();
	let startYear = new Date(startDate).getFullYear();
	let scaleLookup = useMemo(
		() => ({
			day: [
				{ unit: "month", step: 1, format: "%F %Y" },
				{ unit: "day", step: 1, format: "%j" },
			],
			week: [
				{ unit: "month", step: 1, format: "%F %Y" },
				{
					unit: "week",
					step: 1,
					format: (date) => {
						let gantt = ganttRef.current;
						return "W" + gantt.date.getWeek(date);
					},
				},
			],
			month: [
				{ unit: "year", step: 1, format: "%Y" },
				{ unit: "month", step: 1, format: "%M" },
			],
			quarter: [
				{ unit: "year", step: 1, format: "%Y" },
				{ unit: "quarter", step: 1, format: (date) => formatDate(date, "quarter") },
			],
			"0.5 years": [
				{ unit: "year", step: 1, format: "%Y" },
				{
					unit: "half_year",
					step: 1,
					format: (date) => {
						let gantt = ganttRef.current;
						let startDate = gantt.date.half_year_start(date);
						let endDate = new Date(startDate);
						if (endDate.getMonth() < 6) endDate.setMonth(5);
						else endDate.setMonth(11);
						return `${formatDate(startDate, "month")} - ${formatDate(endDate, "month")}`;
					},
				},
			],
			year: [
				{
					unit: "five_years",
					step: 1,
					format: (date) => {
						let gantt = ganttRef.current;
						let startDate = gantt.date.five_years_start(date);
						let scaleStartYear = Math.max(startDate.getFullYear(), startYear);
						let scaleEndYear = Math.min(startDate.getFullYear() + 4, endYear);
						return scaleStartYear === scaleEndYear ? scaleStartYear : `${scaleStartYear} - ${scaleEndYear}`;
					},
				},
				{ unit: "year", step: 1, format: "%Y" },
			],
			"5 years": [
				{
					unit: "ten_years",
					step: 1,
					format: (date) => {
						let gantt = ganttRef.current;
						let startDate = gantt.date.ten_years_start(date);
						let scaleStartYear = Math.max(startDate.getFullYear(), startYear);
						let scaleEndYear = Math.min(startDate.getFullYear() + 9, endYear);
						return scaleStartYear === scaleEndYear ? scaleStartYear : `${scaleStartYear} - ${scaleEndYear}`;
					},
				},
				{
					unit: "five_years",
					step: 1,
					format: (date) => {
						let gantt = ganttRef.current;
						let startDate = gantt.date.five_years_start(date);
						let scaleStartYear = Math.max(startDate.getFullYear(), startYear);
						let scaleEndYear = Math.min(startDate.getFullYear() + 4, endYear);
						return scaleStartYear === scaleEndYear ? scaleStartYear : `${scaleStartYear} - ${scaleEndYear}`;
					},
				},
			],
			"10 years": [
				{
					unit: "twenty_years",
					step: 1,
					format: (date) => {
						let gantt = ganttRef.current;
						let startDate = gantt.date.twenty_years_start(date);
						let scaleStartYear = Math.max(startDate.getFullYear(), startYear);
						let scaleEndYear = Math.min(startDate.getFullYear() + 19, endYear);
						return scaleStartYear === scaleEndYear ? scaleStartYear : `${scaleStartYear} - ${scaleEndYear}`;
					},
				},
				{
					unit: "ten_years",
					step: 1,
					format: (date) => {
						let gantt = ganttRef.current;
						let startDate = gantt.date.ten_years_start(date);
						let scaleStartYear = Math.max(startDate.getFullYear(), startYear);
						let scaleEndYear = Math.min(startDate.getFullYear() + 9, endYear);
						return scaleStartYear === scaleEndYear ? scaleStartYear : `${scaleStartYear} - ${scaleEndYear}`;
					},
				},
			],
		}),
		[endYear, formatDate, ganttRef, startYear]
	);
	return scaleLookup[scale];
};

export default useScale;
