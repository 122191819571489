import isArray from "lodash/isArray";

let setDisplayData = ({ pageIndex, itemsPerPage, setState, testFlag, getData }) => {
	let startIndex = pageIndex * itemsPerPage;
	if (isNaN(startIndex)) {
		setState({ displayData: testFlag ? getData() : undefined });
		return;
	}
	let data = getData(startIndex + 1, itemsPerPage);

	if (!isArray(data) || data.length === 0) {
		setState({ displayData: data });
	} else if (data.length <= itemsPerPage) {
		if (!data.total) data.total = data.length || 0;
		setState({ displayData: data });
	} else {
		let total = data.length || 0;
		data = data.slice(startIndex, startIndex + itemsPerPage);
		if (!data.total) data.total = total;
		setState({ displayData: data });
	}
};
export default setDisplayData;
