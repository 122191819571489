let setReaction = async ({
	commentId,
	currentUserId,
	discussionId,
	name,
	reactionInProgress,
	reactionState,
	set,
	setReactionInProgress,
	setReactionState,
}) => {
	if (reactionInProgress) return;
	setReactionInProgress(true);

	let updatedReactionState = { ...reactionState };

	if (!updatedReactionState.hasOwnProperty(name)) {
		updatedReactionState[name] = [];
	} else {
		updatedReactionState[name] = updatedReactionState[name].map((x) => +x);
	}
	let isUserLiked = updatedReactionState[name].includes(currentUserId);

	if (isUserLiked) {
		updatedReactionState[name] = updatedReactionState[name].filter((x) => x !== currentUserId);
	} else {
		Object.keys(updatedReactionState).forEach((key) => {
			if (key !== name && Array.isArray(updatedReactionState[key])) {
				updatedReactionState[key] = updatedReactionState[key].map((x) => +x).filter((x) => x !== currentUserId);
			}
		});
		updatedReactionState[name] = [...updatedReactionState[name], currentUserId];
	}
	let payload = updatedReactionState;
	setReactionState(payload);
	await set({
		object: "comment",
		objectId: commentId || discussionId,
		data: { reactions: payload },
		stopPropagationFlag: true,
	});
	setReactionInProgress(false);
};

export default setReaction;
