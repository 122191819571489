import Block from "../../Block";
import FormError from "../../Form/Form.Error";
import { useCallback, useContext, useEffect, useRef, createContext, memo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { theme } from "../../Theme";
import { arrayIncludes, emptyArray } from "@clearpoint/utils";

let CheckArrayContext = createContext();

let propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	stringFlag: PropTypes.bool,
};

let convertStringToArray = (value) =>
	value &&
	value
		.toString()
		.split(",")
		.map((x) => (!isNaN(+x) ? +x : x));

let CheckArray = memo(({ children, name, stringFlag, required }) => {
	let { setFormValue, setFormError } = useFormContext();
	let translate = useTranslate();
	let storedFormValue = useFormValue(name);
	let formValue = useFormValue(name) || emptyArray;
	if (stringFlag) formValue = convertStringToArray(formValue);
	let isChecked = useCallback(
		(checkValue) => arrayIncludes({ array: formValue, value: checkValue, stringFlag }),
		[formValue, stringFlag]
	);
	let formValueRef = useRef(formValue);
	formValueRef.current = formValue;
	let onClick = useCallback(
		(checkValue, checkedFlag) => {
			let array = formValueRef.current;
			if (stringFlag) array = convertStringToArray(formValueRef.current);
			if (!arrayIncludes({ array, value: checkValue }) && checkedFlag) {
				setFormValue(name, (value) => {
					value = stringFlag ? convertStringToArray(value) : value;
					value = value || [];
					let newValue = [...value, checkValue];
					return stringFlag ? newValue.join() : newValue;
				});
			} else if (arrayIncludes({ array, value: checkValue }) && (!checkedFlag || checkedFlag.target)) {
				setFormValue(name, (value) => {
					value = stringFlag ? convertStringToArray(value) : value;
					if (value) {
						let index = value.findIndex((x) => isEqual(x, checkValue));
						if (index === -1) {
							return stringFlag ? value.join() : value;
						}
						let newValue = [...value.slice(0, index), ...value.slice(index + 1)];
						return stringFlag ? newValue.join() : newValue;
					}
				});
			}
		},
		[name, setFormValue, stringFlag]
	);

	useEffect(() => {
		if (required) {
			if (!storedFormValue || storedFormValue?.length === 0) {
				setFormError(name, true);
			} else {
				setFormError(name, undefined);
			}
		}
	}, [name, required, setFormError, storedFormValue]);

	return (
		<CheckArrayContext.Provider value={{ isChecked, onClick }}>
			{required && (
				<Block paddingBottom={theme.smallSpace}>
					<FormError name={name} errorMessage={translate("error.required")} />
				</Block>
			)}
			{children}
		</CheckArrayContext.Provider>
	);
});

let useCheckArray = () => useContext(CheckArrayContext);

CheckArray.propTypes = propTypes;

export default CheckArray;
export { useCheckArray };
