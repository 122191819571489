import isArray from "lodash/isArray";

let generateOpenNextGoalCallback = (activeGroupId, goalListFilteredByStatus, currentGoalIndex, setLocalState) => () => {
	if (activeGroupId && isArray(goalListFilteredByStatus)) {
		let nextGoalIndex;
		if (currentGoalIndex === goalListFilteredByStatus.length - 1) {
			nextGoalIndex = 0;
		} else {
			nextGoalIndex = currentGoalIndex + 1;
		}
		let nextGoalId = goalListFilteredByStatus[nextGoalIndex]?.goalId;
		if (nextGoalId !== undefined) {
			setLocalState({ modalGoalId: nextGoalId });
		}
	}
};

export default generateOpenNextGoalCallback;
