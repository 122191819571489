import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "./Form";
import useSessionFormValue from "../Form/hooks/useSessionFormValue";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import { useDebounce, useUnmount } from "react-use";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import getDeepValue from "lodash/get";
import isEqual from "lodash/isEqual";
import updateFormValidation from "./InputController.updateFormValidation";
import { usePrevious } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.node,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
	elementValueTransform: PropTypes.func,
	formValueTransform: PropTypes.func,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	persistInSession: PropTypes.string,
	preventDefaultOnChangeFlag: PropTypes.bool,
	unmountClearFlag: PropTypes.bool,
	validateAfterTouchedStatusFlag: PropTypes.bool,
};

let InputController = ({
	children,
	defaultValue: defaultValueProp,
	elementValueTransform,
	formValueTransform,
	name,
	onBlur,
	onChange,
	persistInSession,
	preventDefaultOnChangeFlag,
	unmountClearFlag,
	validateAfterTouchedStatusFlag,
	...props
}) => {
	let {
		formStatus,
		formValue,
		formValueRef,
		formDefaultValue,
		clearFormValue,
		setFormValue,
		setFormTouched,
		formValidation,
		setFormValidation,
		setFormDefaultValue,
		setFastValueRef,
	} = useFormContext();
	if (!name) {
		throw new Error("Missing name in form input");
	}
	let value = getDeepValue(formValue, name);
	let defaultValue = getDeepValue(formDefaultValue, name) ?? defaultValueProp;

	let child = React.Children.only(children);

	// set default value
	if (defaultValue === undefined) defaultValue = child.props.defaultValue;
	let previousDefaultValue = usePrevious(defaultValue);
	useEffect(() => {
		let value = getDeepValue(formValueRef.current, name);
		if (
			!persistInSession &&
			(defaultValue !== undefined || previousDefaultValue !== undefined) &&
			!isEqual(defaultValue, previousDefaultValue) &&
			!/\.[0-9]+\./.test(name) &&
			!/\[[0-9]+\]/.test(name) // not inside an array
		) {
			if (value === undefined || value === previousDefaultValue) {
				setFormValue(name, defaultValue, false, true);
				setFastValue(defaultValue);
			}
		} else if (!persistInSession && value === undefined && defaultValueProp !== undefined) {
			setFormValue(name, defaultValueProp);
		}
	}, [
		defaultValue,
		defaultValueProp,
		formValueRef,
		name,
		persistInSession,
		previousDefaultValue,
		setFormDefaultValue,
		setFormValue,
		value,
	]);
	useSessionFormValue({ defaultValue, nameInForm: name, nameInSession: persistInSession });

	useUnmount(() => {
		if (unmountClearFlag) {
			clearFormValue(name);
		}
		setFormValidation(name, undefined);
		delete setFastValueRef.current[name];
	});

	// validation
	let email = child.props.type === "email" || props.type === "email" || undefined;
	let endDate = child.props.endDate || props.endDate;
	let json = child.props.json || props.json;
	let json5 = child.props.json5 || props.json5;
	let match = Object.keys(child.props).includes("match") ? child.props.match : props.match;
	let max = child.props.max || props.max;
	let maxLength = child.props.maxLength || props.maxLength;
	let min = child.props.min || props.min;
	let minLength = child.props.minLength || props.minLength;
	let notEndingWithCalcWordFlag = child.props.notEndingWithCalcWordFlag || props.notEndingWithCalcWordFlag;
	let pageBreakValidationFlag = child.props.pageBreakValidationFlag || props.pageBreakValidationFlag;
	let pattern = child.props.pattern || props.pattern;
	let required = child.props.required || props.required;
	let secureHtml = child.props.secureHtml || props.secureHtml;
	let startDate = child.props.startDate || props.startDate;
	let uniqueDate = child.props.uniqueDate || props.uniqueDate;
	let uniqueEmail = child.props.uniqueEmail || props.uniqueEmail;
	let uniqueEmailDebounce = child.props.uniqueEmailDebounce || props.uniqueEmailDebounce;
	let uniqueHostname = child.props.uniqueHostname || props.uniqueHostname;
	let uniqueDashboardLink = child.props.uniqueDashboardLink || props.uniqueDashboardLink;
	let url = child.props.url || props.url;
	// deleting child.props.* broke tests (moved prop removal to return statement)
	useEffect(() => {
		let validation = {
			email,
			endDate,
			json,
			json5,
			match,
			max,
			maxLength,
			min,
			minLength,
			notEndingWithCalcWordFlag,
			pageBreakValidationFlag,
			pattern,
			required,
			secureHtml,
			startDate,
			uniqueDate,
			uniqueEmail,
			uniqueEmailDebounce,
			uniqueHostname,
			uniqueDashboardLink,
			url,
		};
		updateFormValidation({
			formStatus,
			formValidation,
			name,
			setFormValidation,
			validateAfterTouchedStatusFlag,
			validation,
			value,
		});
	}, [
		email,
		endDate,
		formStatus,
		formValidation,
		json,
		json5,
		match,
		max,
		maxLength,
		min,
		minLength,
		name,
		notEndingWithCalcWordFlag,
		pageBreakValidationFlag,
		pattern,
		required,
		secureHtml,
		setFormValidation,
		startDate,
		uniqueDate,
		uniqueEmail,
		uniqueEmailDebounce,
		uniqueHostname,
		uniqueDashboardLink,
		url,
		validateAfterTouchedStatusFlag,
		value,
	]);

	let number = props.type === "number" || child.props.type === "number";
	let checkbox = props.type === "checkbox" || child.props.type === "checkbox";
	let childOnChange = child?.props?.onChange;
	let childOnBlur = child?.props?.onBlur;
	let [fastValue, setFastValue] = useState(value);
	setFastValueRef.current[name] = setFastValue;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let debouncedSetFormValue = useCallback(debounce(setFormValue), [setFormValue]);
	let fastValueRef = useRef(fastValue);
	fastValueRef.current = fastValue;
	let valueRef = useRef(value);
	valueRef.current = value;
	useDebounce(
		() => {
			let value = getDeepValue(formValueRef.current, name);
			let fastValue = fastValueRef.current;
			if (value !== fastValue) {
				setFastValue(value);
			}
		},
		100,
		[fastValue, name, value]
	);
	let handleChange = useCallback(
		(e) => {
			if (!preventDefaultOnChangeFlag) {
				let inputValue = e?.target ? e.target.value : e;
				if (formValueTransform) inputValue = formValueTransform(inputValue);
				if (number) inputValue = inputValue === "" ? null : +inputValue;
				if (checkbox) inputValue = e.target.checked;
				debouncedSetFormValue(name, inputValue, false, true);
				setFastValue(inputValue);
			}
			if (childOnChange) childOnChange(e);
			if (onChange) onChange(e);
		},
		[
			checkbox,
			childOnChange,
			debouncedSetFormValue,
			formValueTransform,
			name,
			number,
			onChange,
			preventDefaultOnChangeFlag,
		]
	);

	let handleBlur = useCallback(
		(e) => {
			setFormTouched(name, true);
			if (childOnBlur) childOnBlur(e);
			if (onBlur) onBlur(e);
		},
		[childOnBlur, name, onBlur, setFormTouched]
	);
	let elementValue = fastValue;
	if (elementValueTransform) elementValue = elementValueTransform(fastValue);
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();

	return useMemo(
		() =>
			React.cloneElement(child, {
				onChange: handleChange,
				onBlur: handleBlur,
				value:
					// eslint-disable-next-line no-self-compare
					(elementValue === undefined || elementValue !== elementValue) && !child.props.options
						? ""
						: translateOnlyInsideCurlyBraces(elementValue),
				name,
				defaultValue: undefined,
				...props,
				json: undefined,
				json5: undefined,
				maxLength: undefined,
				notEndingWithCalcWordFlag: undefined,
				pageBreakValidationFlag: undefined,
				uniqueDate: undefined,
				uniqueEmail: undefined,
				uniqueEmailDebounce: undefined,
				uniqueHostname: undefined,
				uniqueDashboardLink: undefined,
				url: undefined,
			}),
		[child, elementValue, handleBlur, handleChange, name, props, translateOnlyInsideCurlyBraces]
	);
};

InputController.propTypes = propTypes;

export default InputController;
