import { useMemo } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

let loadingObject = { measureGridList: [], measureGridLoadingFlag: true };
let useMeasureGridList = (elementList) => {
	let { get, isLoading } = useOldQueryStore();
	return useMemo(() => {
		if (!elementList) return loadingObject;
		let measureGridList = elementList.elements
			.filter((x) => x.object === "measure")
			.map((x) => {
				let key = { object: "measureGrid", parent: "measure", parentId: x.objectId };
				let value = get(key);
				let measureGridLoadingFlag = !value || isLoading(key);
				return { measureId: x.objectId, value, measureGridLoadingFlag };
			});
		if (measureGridList.some((x) => x.measureGridLoadingFlag)) return loadingObject;
		measureGridList = measureGridList.map((x) => ({ measureId: x.measureId, grid: x.value }));
		return { measureGridList, measureGridLoadingFlag: false };
	}, [elementList, get, isLoading]);
};

export default useMeasureGridList;
