let createErrorMessageRouter = ({ formatDate, translate }) => ({
	date: () => translate("error.validDate"),
	email: () => translate("error.validEmail"),
	endDate: (endDate) => translate("error.startDate.displayEndDate", { endDate: formatDate(endDate, "short") }),
	json5: () => translate("edit.script.invalidJSON"),
	json: () => translate("edit.script.invalidJSON"),
	match: () => translate("error.passwordMatch"),
	max: () => translate("error.max"),
	maxLength: () => translate("error.tooLong"),
	min: () => translate("error.min"),
	minLength: () => translate("error.tooShort"),
	notEndingWithCalcWordFlag: () => translate("error.notEndingWithCalcWord"),
	pageBreakValidationFlag: () => translate("error.invalidPageBreakSetting"),
	pattern: () => translate("error.validPassword"),
	required: () => translate("error.required"),
	startDate: (startDate) => translate("error.endDate.displayStartDate", { startDate: formatDate(startDate, "short") }),
	secureHtml: () => translate("error.secureHtml"),
	uniqueDate: () => translate("error.uniqueDate"),
	uniqueEmail: () => translate("error.uniqueEmail"),
	uniqueHostname: () => translate("error.uniqueHostname"),
	uniqueDashboardLink: () => translate("dashboard.form.link.unique"),
	uniqueMeasureSeriesName: () => translate("error.uniqueSeriesName"),
	url: () => translate("error.url"),
});
export default createErrorMessageRouter;
