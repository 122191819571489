import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";

let rowController = (rowList, columnKeyList, initialLength) => {
	let createRow = () => {
		let newRow = {};
		columnKeyList?.forEach((keyObject) => (newRow[keyObject.key] = ""));
		return newRow;
	};

	let addBlankRow = (syncedRowList = rowList) => {
		let newRow = createRow();
		if (Array.isArray(syncedRowList)) return [...syncedRowList, newRow];
		return { ...syncedRowList, newRow };
	};

	let rowHasValue = (row) => {
		return columnKeyList?.some((keyObject) => !!row[keyObject.key]);
	};

	let getLastRowWithValue = () => {
		let foundIndex = -1;
		for (let i = rowList.length - 1; i >= 0; i--) {
			let row = rowList[i];
			let hasValue = rowHasValue(row);
			if (hasValue) {
				foundIndex = i;
				break;
			}
		}
		return foundIndex;
	};

	let removeRowByIndex = (rowIndex) => {
		let currentRowList = cloneDeep(rowList);
		if (!isArray(currentRowList)) return;
		let rowIndexToRemove = rowIndex;

		if (!rowList?.[rowIndexToRemove]) {
			rowIndexToRemove = getLastRowWithValue();
		}

		currentRowList.splice(rowIndexToRemove, 1);
		if (currentRowList.length < initialLength) {
			for (let i = currentRowList.length; i < initialLength; i++) {
				currentRowList.push(createRow());
			}
		}

		return currentRowList;
	};

	return { addBlankRow, createRow, getLastRowWithValue, removeRowByIndex };
};

export default rowController;
