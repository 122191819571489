let sizeLookup = {
	podHeader: {
		padding: "4px 8px",
		fontSize: "12px",
	},
	tiny: {
		padding: "3px 8px !important",
		fontSize: "8px !important",
	},
	small: {
		padding: "3px 8px",
		fontSize: "12px",
	},
	medium: {
		padding: "3px 8px",
		fontSize: "16px",
	},
};

export default sizeLookup;
