import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import FormSelectField from "@clearpoint/old-theme/Form/Select/Select.Field";
import Help from "@clearpoint/old-theme/Help";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { useFieldList } from "@clearpoint/hooks";


let propTypes = {
	addFlag: PropTypes.bool,
	objectIdList: PropTypes.array,
	parentId: PropTypes.number,
};

let EditFieldsTab = ({ addFlag, objectIdList, parentId }) => {
	let scorecardId = useFormValue("scorecardId");
	let fieldList = useFieldList({ object: "milestone", multipleFlag: true, addFlag, scorecardId });
	let defaultFieldList = useMemo(() => (addFlag ? fieldList.filter((x) => x.required) : []), [addFlag, fieldList]);
	let selectedFieldList = useFormValue("_selectedFieldList");
	let displayedFieldList = useMemo(
		() =>
			fieldList && [...defaultFieldList, ...fieldList?.filter((field) => selectedFieldList?.includes(field.value))],
		[defaultFieldList, selectedFieldList, fieldList]
	);
	return (
		<>
			<AlertUpsell feature="dependencies" />
			<Help section="edit-element" feature="edit-fields" />
			<FormSelectField
				addFlag={addFlag}
				fieldList={fieldList}
				parent="initiative"
				object="milestone"
				name="_selectedFieldList"
				multipleFlag
			/>
			{displayedFieldList?.map((field) => (
				<FormField
					editFlag={!addFlag}
					fieldId={field.value}
					key={field.value}
					label={field.label}
					parent="initiative"
					parentId={parentId}
					object="milestone"
					objectIdList={objectIdList}
					editMultipleFlag={!addFlag}
					required={field.required}
				/>
			))}
		</>
	);
};
EditFieldsTab.propTypes = propTypes;
export default EditFieldsTab;
