import getDeepValue from "lodash/get";
import validationLookup from "./validationLookup";

let validate = ({ errorMessageRouter, formContextRef, name, setFormError, validation, value }) => {
	let { formDefaultValueRef, formErrorRef } = formContextRef.current;
	let errorFlag = false;
	if (validation) {
		for (let validator in validation) {
			let testValue = validation[validator];
			if (testValue === undefined) continue;
			if (!validationLookup[validator]) throw new Error("No such validator: " + validator);
			if (
				!validationLookup[validator]({
					value,
					testValue,
					setError: setFormError,
					name,
					errorMessageRouter,
					formDefaultValue: formDefaultValueRef.current,
				})
			) {
				let errorMessage = errorMessageRouter[validator](testValue);
				setFormError(name, errorMessage);
				errorFlag = true;
				break;
			}
		}
	}
	if (!errorFlag) {
		let previousErrorFlag = getDeepValue(formErrorRef.current, name);
		if (previousErrorFlag) {
			setFormError(name, undefined);
		}
	}
};

export default validate;
