let getResponseMessageHandler =
	({
		assistantChatId,
		awaitingUserResubmitFlag,
		awaitingNewAIResponseFlag,
		receivingAIResponseFlagRef,
		handleChatReset,
		setChatState,
	}) =>
	(e) => {
		let { chatId: pusherMessageChatId, json, message, objectAddedFlag, status } = e?.detail || {};
		if (!assistantChatId || assistantChatId !== pusherMessageChatId) return;
		let milestonesFlag = !!json?.milestones;
		if (status === "complete") {
			let nextChatMessage = {
				content: message,
				role: "assistant",
			};
			setChatState({
				awaitingNewAIResponseFlag: false,
				awaitingUserResubmitFlag: false,
				chatEnabledFlag: true,
				currentChatMessage: nextChatMessage,
			});
			return;
		}
		if (objectAddedFlag && !milestonesFlag) return handleChatReset();

		if (!message || awaitingUserResubmitFlag) return;

		if (status === "typing" || status === "thinking") {
			if (awaitingNewAIResponseFlag || receivingAIResponseFlagRef.current) {
				receivingAIResponseFlagRef.current = true;
				setChatState({
					awaitingNewAIResponseFlag: false,
					receivingAIResponseFlag: true,
					chatEnabledFlag: false,
					currentChatMessage: {
						content: message,
						role: "assistant",
					},
				});
			} else {
				setChatState({
					awaitingNewAIResponseFlag: false,
					receivingAIResponseFlag: false,
					chatEnabledFlag: false,
					currentChatMessage: {
						content: message,
						role: "validation",
					},
				});
			}
		} else if (status === "response") {
			receivingAIResponseFlagRef.current = false;
			setChatState({
				chatEnabledFlag: true,
				currentChatMessage: null,
				receivingAIResponseFlag: false,
			});
		}
	};

export default getResponseMessageHandler;
