import Block from "@clearpoint/old-theme/Block";
import DiscardOnClick from "@clearpoint/old-theme/Modal/Modal.Discard.OnClick";
import { HeaderIcon } from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	navigableGoalListFlag: PropTypes.bool,
	openNextGoal: PropTypes.func,
	openPreviousGoal: PropTypes.func,
};

let GoalNavigator = ({ navigableGoalListFlag, openNextGoal, openPreviousGoal }) => {
	let translate = useTranslate();
	let { newObjectFlag } = useFormContext();

	return navigableGoalListFlag && !newObjectFlag ? (
		<Block alignSelf="flex-start" display="flex" alignItems="center">
			<DiscardOnClick
				discardMessage={translate("goal.confirmDiscard.previous")}
				onDiscard={openPreviousGoal}
				closeOnDiscardFlag
			>
				<HeaderIcon name="previousArrow" onClick={openPreviousGoal} />
			</DiscardOnClick>
			<DiscardOnClick
				discardMessage={translate("goal.confirmDiscard.next")}
				onDiscard={openNextGoal}
				closeOnDiscardFlag
			>
				<HeaderIcon name="nextArrow" />
			</DiscardOnClick>
		</Block>
	) : (
		<div />
	);
};

GoalNavigator.propTypes = propTypes;
export default GoalNavigator;
