import PropTypes from "prop-types";

import { usePeriodLockedFlag } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import Icon from "../Icon/Icon";
import { theme } from "../Theme";
import Badge from "./Badge";


let propTypes = {
	color: PropTypes.string,
	hideLockIconFlag: PropTypes.bool,
	highlightFlag: PropTypes.bool,
	name: PropTypes.string,
	periodGroupId: PropTypes.number,
	periodId: PropTypes.number,
	periodLockedFlag: PropTypes.bool,
};

let PeriodBadge = ({
	color,
	hideLockIconFlag,
	highlightFlag,
	name,
	periodGroupId,
	periodId,
	periodLockedFlag,
	...props
}) => {
	let { get } = useOldQueryStore();
	let periods = get({ object: "period" });
	let period = periodId && periods && periods.find((x) => x.periodId === periodId);
	let periodGroup = period?.periodGroups?.find((x) => x.periodGroupId === periodGroupId);
	name = name || periodGroup?.periodLabel || period?.name;
	let periodLockedHookFlag = usePeriodLockedFlag(periodId);
	let locked = periodLockedFlag || periodLockedHookFlag;
	return period ? (
		<Badge data-testid="period-badge" color={color || (highlightFlag ? "warning" : "primary")} {...props} >
			{locked && !hideLockIconFlag && (
				<Icon name="lock" size="small" marginRight={theme.tinySpace} transform="up-2" />
			)}
			{name}
		</Badge>
	) : null;
};

PeriodBadge.propTypes = propTypes;

export default PeriodBadge;
