import useMeasureDetailPageWidth from "../useMeasureDetailPageWidth";
import { useOldSession } from "@clearpoint/old-session/index";

let usePodWidthDisplayClass = (areaClass, podWidth) => {
	let detailPageWidth = useMeasureDetailPageWidth();
	let { exportFlag } = useOldSession().session;
	let boostrap4SmallBreakpoint = 576;
	let bootstrap4MediumBreakpoint = 768;
	let areaColumnMinWidthBreakpointFlag = exportFlag
		? detailPageWidth >= boostrap4SmallBreakpoint
		: detailPageWidth >= bootstrap4MediumBreakpoint;
	let narrowAreaFlag = areaClass === "area1" || areaClass === "area2";
	let halfWidthColumnFlag = narrowAreaFlag && areaColumnMinWidthBreakpointFlag;
	let areaWidthDivisor = narrowAreaFlag && halfWidthColumnFlag ? 2 : 1;
	let absolutePodWidth = Math.round(detailPageWidth / (areaWidthDivisor * (12 / podWidth)));
	let isSmall = (width) => width <= 400 && width > 150;
	let isMedium = (width) => width <= 600 && width > 400;
	let isLarge = (width) => width > 600;
	let podWidthClass = isLarge(absolutePodWidth)
		? "large"
		: isMedium(absolutePodWidth)
		? "medium"
		: isSmall(absolutePodWidth)
		? "small"
		: "tiny";
	return podWidthClass;
};

export default usePodWidthDisplayClass;
