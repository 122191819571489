import Button from "./Button";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";
import { useBuildLink } from "@clearpoint/hooks";

let propTypes = {
	className: PropTypes.string,
	color: PropTypes.oneOf(["primary", "secondary", "success", "danger", "warning", "info", "dark", "light", "white"]),
	href: PropTypes.string,
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	scorecardId: PropTypes.number,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	text: PropTypes.string,
};

let defaultProps = {
	color: "white",
	size: "tiny",
	target: "_new",
};

let LinkButton = ({ className, color, hideIconFlag, href, showIconFlag, size, target, text, ...props }) => {
	let { layoutId, object, objectId, periodId, scorecardId } = props;

	let buildLink = useBuildLink();
	if (!href && scorecardId && object && (objectId || layoutId))
		href = buildLink({ scorecardId, object, objectId, layoutId, periodId });

	return (
		<Button color={color} padding="3px 6px" size={size} href={href} target={target} {...props}>
			{!hideIconFlag && <Icon name="link" transform={text ? "" : "up-2"} />}
			{text}
		</Button>
	);
};

LinkButton.propTypes = propTypes;
LinkButton.defaultProps = defaultProps;

export default LinkButton;
