import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let TrendlineSelect = ({ name, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("edit.chart.regression.actual"), value: "" },
			{ label: translate("edit.chart.regression.linear"), value: "linear" },
			{ label: translate("edit.chart.regression.exponential"), value: "exponential" },
			{ label: translate("edit.chart.regression.log"), value: "logarithmic" },
			{ label: translate("edit.chart.regression.power"), value: "power" },
			{ label: translate("edit.chart.regression.poly"), value: "polynomial" },
		],
		[translate]
	);
	return <Select placeholder={translate("edit.chart.regressionModel")} options={options} name={name} {...props} />;
};

TrendlineSelect.propTypes = propTypes;

export default TrendlineSelect;
