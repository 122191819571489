import PropTypes from "prop-types";

import Block from "../Block";
import { theme } from "../Theme";

let propTypes = {
	className: PropTypes.string,
	subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	title: PropTypes.node,
};

let PageTitle = ({ title, subTitle, className }) => (
	<Block
		alignSelf="stretch"
		className={className}
		display="flex"
		flex="1 1"
		flexDirection="column"
		justifyContent="center"
		marginRight={theme.smallSpace}
		wordBreak="normal"
		minWidth="auto"
		ellipsisFlag
	>
		{title && (
			<Block
				data-testid={`${title}-page-title`}
				display="inline"
				fontSize="20px"
				fontWeight={theme.bold}
				lineHeight="1.1"
				margin="0"
				minWidth="auto"
				ellipsisFlag
			>
				{title}
			</Block>
		)}
		{subTitle && (
			<Block
				data-testid={typeof subTitle === "object" ? "" : `${subTitle}-sub-title`}
				display="inline"
				fontSize="14px"
				fontWeight="normal"
				lineHeight="1.1"
				margin="0"
				minWidth="auto"
				ellipsisFlag
			>
				{subTitle}
			</Block>
		)}
	</Block>
);

PageTitle.propTypes = propTypes;

export default PageTitle;
