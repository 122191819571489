import { useCallback, useContext, useEffect, useMemo, createContext } from "react";
import useCheckPeriodValidOnNewScorecard from "./useCheckPeriodValidOnNewScorecard";
import { useHistory, useLocation } from "react-router-dom";
import useSessionReducer from "./useSessionReducer";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import useWatchQueryParameters from "./useWatchQueryParameters";
import PropTypes from "prop-types";
import setSessionFunction from "./setSessionFunction";
import { getTranslate } from "@clearpoint/translate";
import { luceeAxios } from "@clearpoint/services/axiosService";
import { emptyObject, getQueryParameters } from "@clearpoint/utils";
import { usePrevious } from "@clearpoint/hooks";



export const SessionContext = createContext();

let history;
let sessionReducer;

let propTypes = { children: PropTypes.node };

let Session = ({ children }) => {
	let translate = getTranslate();
	let location = useLocation();
	history = useHistory();

	sessionReducer = useSessionReducer();
	let [session, setSession] = sessionReducer;
	let clearSession = useCallback((tokenFlag) => setSession(tokenFlag ? "clearToken" : "clear"), [setSession]);

	let { get } = useOldQueryStore() || {};
	let getDataFlag =
		!!get &&
		!!luceeAxios.defaults.headers.common["Authorization"] &&
		!location.pathname.includes("login") &&
		!location.pathname.includes("reset-password");
	let profile = getDataFlag ? get({ object: "profile" }) : undefined;
	let periodList = getDataFlag ? get({ object: "period" }) : undefined;
	let scorecardList = getDataFlag ? get({ object: "scorecard" }) : undefined;
	let activeAlerts = getDataFlag
		? get({ object: "alert", queryString: "?refreshCounts=true" })?.activeAlerts
		: undefined;

	let checkPeriodValidOnNewScorecard = useCheckPeriodValidOnNewScorecard(getDataFlag);

	let previousSession = usePrevious(session) || emptyObject;

	useWatchQueryParameters({ sessionReducer, getDataFlag });

	// update session based on query parameters or profile
	// check access to scorecard
	// fallback scorecard -> home, or most current period in list? none: logout, toast with info in both cases
	useEffect(() => {
		setSessionFunction({
			checkPeriodValidOnNewScorecard,
			getQueryParameters,
			history,
			location,
			periodList,
			previousSession,
			profile,
			session,
			setSession,
			translate,
		});
	}, [
		location,
		profile,
		session,
		periodList,
		translate,
		setSession,
		scorecardList,
		previousSession.scorecardId,
		previousSession.periodId,
		previousSession.object,
		previousSession.layoutId,
		previousSession.objectId,
		previousSession,
		checkPeriodValidOnNewScorecard,
	]);
	// scorecard access ??

	useEffect(() => {
		if (profile && session && profile.localeId && session.localeId !== profile.localeId) {
			setSession({ localeId: profile.localeId || "en_us" });
		}
		if (session && session.activeAlerts === undefined && activeAlerts !== undefined) setSession({ activeAlerts });
	}, [activeAlerts, profile, session, setSession]);

	return useMemo(
		() => <SessionContext.Provider value={{ session, setSession, clearSession }}>{children}</SessionContext.Provider>,
		[children, clearSession, session, setSession]
	);
};
Session.propTypes = propTypes;
export default Session;
export { history };
export const useOldSession = () => useContext(SessionContext);
export const getSession = () => ({ session: sessionReducer[0], setSession: sessionReducer[1] });
