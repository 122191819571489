import CustomSeriesListItem from "./CustomSeriesListItem";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterWrapper from "../shared/FilterWrapper";
import NoResults from "@clearpoint/old-theme/NoResults";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";

let CustomSeriesListContent = () => {
	let translate = useTranslate();
	let { data: filteredSeriesList } = useFilter();

	return (
		<>
			<FilterInput
				name="seriesFilter"
				filterBy="name"
				placeholder={translate("adminProjectEval.filterSeries") + "..."}
			/>
			<NoResults />
			{filteredSeriesList
				?.filter((x) => x.visible)
				?.map((series, index) => (
					<CustomSeriesListItem key={series.sortOrder} series={series} index={index} />
				))}
		</>
	);
};

let CustomSeriesList = () => {
	let { chartSeries: chartSeriesList } = useFormValue();
	return (
		<FilterWrapper data={chartSeriesList}>
			<CustomSeriesListContent />
		</FilterWrapper>
	);
};

export default CustomSeriesList;
