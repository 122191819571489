import NoPeriodsForScorecardAlert from "@clearpoint/old-theme/Alert/Alert.NoPeriodsForScorecard";
import { useEffect } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import { useNoAvailablePeriodsFlag } from "@clearpoint/hooks";

let NoPeriodsWarning = () => {
	let { newObjectFlag, setFormError } = useFormContext();

	let noAvailablePeriodsFlag = useNoAvailablePeriodsFlag();
	let showWarningFlag = newObjectFlag && noAvailablePeriodsFlag;

	useEffect(() => {
		if (showWarningFlag) {
			setFormError("_availablePeriodsForScorecard", showWarningFlag);
		}
	}, [setFormError, showWarningFlag]);

	return showWarningFlag ? <NoPeriodsForScorecardAlert /> : null;
};

export default NoPeriodsWarning;
