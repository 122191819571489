import { emptyObject } from "@clearpoint/utils";

let beforePaste = (_plugin, args) => {
	let { node } = args || emptyObject;
	if (!node?.innerHTML) return;

	let attrRegex = /(style|class)="[^"]*"/g;

	node.innerHTML = node.innerHTML.replaceAll(attrRegex, "");
};

export default beforePaste;
