import Block from "../../Block";
import ButtonIcon from "../../Button/Button.Icon";
import FormDragAndDropListItem from "../../Form/DragAndDropList/DragAndDropList.Item";
import Icon from "../../Icon/Icon";
import Tooltip from "../../Tooltip";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";
import { moveToBottom, moveToTop } from "@clearpoint/utils";


let propTypes = {
	firstFlag: PropTypes.bool,
	index: PropTypes.number,
	lastFlag: PropTypes.bool,
	object: PropTypes.object,
	reportFlag: PropTypes.bool,
	setList: PropTypes.func,
};
let SortItem = ({ firstFlag, index, lastFlag, object: objectData, reportFlag, setList }) => {
	let translate = useTranslate();
	let { object, name, selectedFlag, scorecard } = objectData;
	let selectItem = useCallback(() => {
		setList((list) => {
			let beforeCurrent = list.slice(0, index).map((x) => (x.selectedFlag ? { ...x, selectedFlag: false } : x));
			let current = { ...list[index], selectedFlag: true };
			let afterCurrent = list.slice(index + 1).map((x) => (x.selectedFlag ? { ...x, selectedFlag: false } : x));
			return [...beforeCurrent, current, ...afterCurrent];
		});
	}, [index, setList]);
	let moveItemToBottom = useCallback(
		(e) => {
			setList((list) => moveToBottom(list, index));
		},
		[index, setList]
	);
	let moveItemToTop = useCallback(
		(e) => {
			setList((list) => moveToTop(list, index));
		},
		[index, setList]
	);
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	return (
		<FormDragAndDropListItem
			highlightFlag={selectedFlag}
			onMouseDown={selectItem}
			onMouseUp={selectItem}
			size="small"
			left={
				<Block display="flex" alignItems="center">
					{!reportFlag && <Icon name={object} marginRight={theme.smallSpace} />}
					<Block lineHeight="1">
						<Block data-testid={`name-index-${index}`}>{translateOnlyInsideCurlyBraces(name)}</Block>
						{!reportFlag && <small>{scorecard}</small>}
					</Block>
				</Block>
			}
			right={
				<>
					<Tooltip text={translate("layout.moveToTop")}>
						<ButtonIcon
							color="success"
							name="moveToTop"
							iconTransform="down-3"
							disabled={firstFlag}
							onMouseUp={moveItemToTop}
						/>
					</Tooltip>
					<Tooltip text={translate("layout.moveToBottom")}>
						<ButtonIcon
							color="success"
							name="moveToBottom"
							iconTransform="up-4"
							disabled={lastFlag}
							onMouseUp={moveItemToBottom}
						/>
					</Tooltip>
				</>
			}
		/>
	);
};
SortItem.propTypes = propTypes;

export default SortItem;
