import ElementList from "./ElementList";
import ElementListFilterProvider from "./ElementListFilterProvider";
import Loading from "@clearpoint/old-theme/Loading/index";
import Map from "./Map";
import MapOptionsModal from "../MapOptionsModal";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback, useMemo } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { theme } from "@clearpoint/old-theme/Theme";

let modalWindowContentPropTypes = {
	addFlag: PropTypes.bool,
};

let EditMapModalWindowContent = ({ addFlag }) => {
	let translate = useTranslate();
	let { name } = useFormValue();
	let { loadingFlag } = useWindowContext();
	return (
		<>
			<ModalWindowHeader
				badgeIcon="map"
				badgeText={translate("map")}
				title={addFlag ? translate("edit.addElement", { element: "map" }) : name}
			/>
			<ModalWindowContent>
				<ElementListFilterProvider>
					<ElementList />
					{loadingFlag ? (
						<StyleWrapper backgroundColor={theme.white}>
							<Loading />
						</StyleWrapper>
					) : (
						<Map />
					)}
				</ElementListFilterProvider>
			</ModalWindowContent>
			<ModalWindowFooter />
		</>
	);
};

let propTypes = {
	close: PropTypes.func.isRequired,
	closeMapOptionsModal: PropTypes.func,
	mapOptionsModalVisible: PropTypes.bool,
	modalWindowVisible: PropTypes.bool.isRequired,
	objectId: PropTypes.number,
};

let EditMapModalWindow = ({
	close,
	closeMapOptionsModal,
	mapOptionsModalVisible,
	modalWindowVisible,
	objectId,
	...props
}) => {
	let translate = useTranslate();
	let { get, set, clear } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId, scorecardId } = session;

	let mapData = objectId && periodId && get({ object: "map", objectId, periodId });

	let beforeSubmit = useCallback(
		(submitValue) => {
			if (!submitValue.background && mapData?.background) submitValue.background = "remove";
			else {
				delete submitValue.background;
			}
		},
		[mapData]
	);

	let onSubmit = useCallback(
		async (_submitValue, response, contextValue) => {
			let uploadingToastId;
			if (contextValue.formValue._binary?.background) {
				uploadingToastId = toast.success("Uploading...", { icon: "saving", autoClose: false });
				let formData = new FormData();
				formData.append("file", contextValue.formValue._binary.background);
				formData.append("mapId", mapData?.mapId || response?.mapId);
				formData.append("scorecardId", scorecardId);
				await set({ object: "upload", data: formData });
				clear({ object: "map", objectId });
			}
			toast.dismiss(uploadingToastId);
			toast.success(
				translate(objectId ? "toaster.messages.objects.objectUpdated" : "toaster.messages.objects.objectAdded", {
					object: "map",
				})
			);
		},
		[clear, mapData, objectId, scorecardId, set, translate]
	);

	let loadingFlag = objectId && mapData === undefined;
	let defaultValue = useMemo(
		() =>
			objectId
				? undefined
				: {
						scorecardId,
				  },
		[objectId, scorecardId]
	);
	return (
		<ModalWindowEdit
			{...props}
			beforeSubmit={beforeSubmit}
			close={close}
			color="lazur"
			defaultValue={defaultValue}
			loadingFlag={loadingFlag}
			modalWindowVisible={modalWindowVisible}
			object="map"
			objectId={objectId}
			onSubmit={onSubmit}
			periodId={periodId}
			size="large"
			submitUnchangedFlag
		>
			{<EditMapModalWindowContent addFlag={!objectId} />}
			{mapOptionsModalVisible && (
				<MapOptionsModal
					close={closeMapOptionsModal}
					modalVisible={mapOptionsModalVisible}
					objectId={objectId}
					closeMapOptionsModal={closeMapOptionsModal}
				/>
			)}
		</ModalWindowEdit>
	);
};

EditMapModalWindowContent.propTypes = modalWindowContentPropTypes;
EditMapModalWindow.propTypes = propTypes;

export default EditMapModalWindow;
