import AlertApproval from "@clearpoint/old-theme/Alert/Alert.Approval";
import FormGridSeries from "@clearpoint/old-theme/Form/Grid/Series/Series";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Loading from "@clearpoint/old-theme/Loading/index";

import EditSeriesModal from "@components/Elements/Shared/SeriesTab/EditSeriesModal";
import CalculationMapTab from "@components/Elements/Shared/SeriesTab/EditSeriesModal/CalculationMapTab";

let UpdateDataTab = () => {
	let shadowMeasureId = useFormValue("shadowMeasureId");
	let updates = useFormValue("updates");
	let loadingFlag = !updates;
	let { periodId } = updates || {};

	return loadingFlag ? null : (
		<>
			<AlertApproval type="updates" />
			<FormGridSeries
				CalculationMapTab={CalculationMapTab}
				EditSeriesModal={EditSeriesModal}
				measureId={shadowMeasureId}
				periodId={periodId}
				inModalFlag
				displayHiddenSeriesFlag
			/>
		</>
	);
};
export default UpdateDataTab;
