import { useMemo } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

let useCustomFieldData = ({ object, objectId, layoutId, periodId, scorecardId }) => {
	let { get } = useOldQueryStore();
	let scorecardFlag = object === "scorecard";

	let customFieldQuery = useMemo(() => {
		let parentId = scorecardFlag ? scorecardId : objectId;

		if (object && parentId && periodId) {
			return {
				parent: object,
				parentId,
				object: "customField",
				periodId,
				queryString: layoutId ? `layoutId=${layoutId}` : `detail=true`,
			};
		}
	}, [layoutId, object, objectId, periodId, scorecardFlag, scorecardId]);

	return {
		customFieldData: customFieldQuery && get(customFieldQuery),
		customFieldQuery,
	};
};

export default useCustomFieldData;
