let getScopedHtmlDisplayStyles = (htmlDisplayValue, containerStyleClass) => {
	let replaceCommaSeparatedSelector = (selector) =>
		selector
			.split(",")
			.map((x) => `.${containerStyleClass} ${x}`)
			.join(",");

	if (typeof htmlDisplayValue !== "string") return null;

	let styleRegEx = /<style>[\s\S]*?<\/style>/g;
	let styleHTML = styleRegEx.test(htmlDisplayValue) && htmlDisplayValue.match(styleRegEx).join("\n");

	let htmlDisplayValueWithScopedStyles;

	if (styleHTML) {
		styleHTML = `${styleHTML
			.replace(/\n/g, "")
			.replace(/<style>\s*?(\S.*?){/, (_match, selector) => `<style>${replaceCommaSeparatedSelector(selector)}{`)
			.replace(/}\s*?(\S.*?){/g, (_match, selector) => `} ${replaceCommaSeparatedSelector(selector)}{`)}`;
		htmlDisplayValueWithScopedStyles = htmlDisplayValue?.replace(/<style>[\s\S]*?<\/style>/g, styleHTML);
	}

	return { containerStyleClass, htmlDisplayValue: htmlDisplayValueWithScopedStyles || htmlDisplayValue };
};

export default getScopedHtmlDisplayStyles;
