import { useCallback } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useCheckDefunctPod, useCheckHiddenElement } from "@clearpoint/hooks";

let useShouldRenderPod = () => {
	let checkDefunctPod = useCheckDefunctPod();
	let checkHiddenElement = useCheckHiddenElement();
	let { exportFlag, htmlExportFlag } = useOldSession().session;

	return useCallback(
		(pod) => {
			let defunctPodFlag = checkDefunctPod(pod);
			let hiddenElementFlag = checkHiddenElement(pod.fieldId);
			let onlineRenderFlag = !exportFlag && !pod.hideOnline;
			let exportRenderFlag = exportFlag && !htmlExportFlag && !pod.hidePrint;
			let publishRenderFlag = exportFlag && htmlExportFlag && !pod.hidePublish;
			return !!(
				!defunctPodFlag &&
				!hiddenElementFlag &&
				(onlineRenderFlag || exportRenderFlag || publishRenderFlag)
			);
		},
		[checkDefunctPod, checkHiddenElement, exportFlag, htmlExportFlag]
	);
};

export default useShouldRenderPod;
