import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectCurrentOrSpecificElement = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: "current", label: translate("global.currentElement") },
			{ value: "specific", label: translate("global.specificElement") },
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectCurrentOrSpecificElement;
