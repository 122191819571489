import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import FormInputFilterList from "@clearpoint/old-theme/Form/Input/FilterList/FormInputFilterList";
import Help from "@clearpoint/old-theme/Help";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useCheckFeature } from "@clearpoint/hooks";


let FilterTab = () => {
	let checkFeature = useCheckFeature();
	let { get } = useOldQueryStore();
	let { object, reportType } = useFormValue();
	let options = get({ object: "options" });
	let filterFlag = checkFeature("reportFilters");
	let filterObjectList = useMemo(() => {
		let evaluationObjectList = options?.options?.evaluations?.map((x) => x.object) || [];
		evaluationObjectList.unshift("measure");
		let filterObjectList = [object];
		if (object === "initiative") {
			filterObjectList = [...filterObjectList, "milestone"];
		}
		if (evaluationObjectList.includes(object) && reportType !== "dashboard") {
			filterObjectList = [...filterObjectList, "measureSeries"];
		}
		return filterObjectList;
	}, [object, options, reportType]);
	return (
		<>
			<AlertUpsell feature="reportFilters" />
			<Help section="sect3" feature="report-filter" />
			{filterFlag && <FormInputFilterList filterObjectList={filterObjectList} />}
		</>
	);
};
export default FilterTab;
