import { luceeAxios } from "@clearpoint/services/axiosService";
import { getEnvironment } from "@clearpoint/utils";

let environment = getEnvironment();

let keyLookup = {
	prod: "97d1209bb86332feb23f",
	demo: "3c7727903608ea2b05d9",
	preview: "fcf48db0ca80bd198c63",
	dev: "84e377648e2794b2f998",
	stage: "64cf219c55147cf29bb3",
	labs: "84e377648e2794b2f998",
	localdev: "af8480145e92c552674e",
};
delete luceeAxios.defaults.headers.common["Content-Type"];

let pusherConfig = {
	clientKey: keyLookup[ environment || import.meta.env.VITE_PUSHER_ENV ],
	cluster: "us2",
	authEndpoint: "/api/v1/pusher",
	auth: {
		headers: luceeAxios.defaults.headers.common,
	},
};

export default pusherConfig;
