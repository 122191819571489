import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	filter: PropTypes.func,
	placeholder: PropTypes.string,
};

let SelectGroupedUser = ({ filter, placeholder, ...props }) => {
	let translate = useTranslate();

	let { get } = useOldQueryStore();
	let profile = get({ object: "profile" });
	let userList = useMemo(() => {
		let userData = get({ object: "user" });
		if (filter) {
			return userData?.filter(filter);
		}
		return userData;
	}, [filter, get]);

	let options = useMemo(() => {
		if (userList === undefined || profile === undefined) return;
		if (!userList) return [];

		// This order can be whatever we want, but this is what's in DEV
		let userTypeList = [
			8, // Assign Group
			7, // No Access
			9, // Scorecard Admin
			4, // Browser
			1, // Administrator
			2, // Editor
			10, // By Scorecard
			3, // Updater
		];
		let optionsArray = userTypeList
			.map((userTypeId) => userList.filter((user) => user.userTypeId === userTypeId))
			.flat()
			.map((x) => ({
				group: translate(`userTypes.user${x.userTypeId}`),
				label: x.fullName,
				value: x.userId,
			}));
		return optionsArray;
	}, [userList, profile, translate]);

	return (
		<Select
			placeholder={
				placeholder || (props.multipleFlag ? translate("edit.selectUsers") : translate("edit.selectUser"))
			}
			options={options}
			{...props}
		/>
	);
};

SelectGroupedUser.propTypes = propTypes;

export default SelectGroupedUser;
