import HeaderDisplay from "./HeaderDisplay";
import HeaderFields from "./HeaderFields";
import PropTypes from "prop-types";


let propTypes = {
	editHeaderFlag: PropTypes.bool.isRequired,
	setEditHeaderFlag: PropTypes.func.isRequired,
};

let Header = ({ editHeaderFlag, setEditHeaderFlag }) =>
	editHeaderFlag ? <HeaderFields /> : <HeaderDisplay setEditHeaderFlag={setEditHeaderFlag} />;

Header.propTypes = propTypes;
export default Header;
