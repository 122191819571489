import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { deepFreeze } from "@clearpoint/utils";

let handleDataUpdate = ({ action, newState }) => {
	let updated;
	action = cloneDeep(action);
	deepFreeze(action);
	for (let i = 0; i < newState.length; i++) {
		let state = newState[i];
		if (state && isEqual(state.storeKey, action.storeKey)) {
			newState[i] = action;
			updated = true;
			break;
		}
	}
	if (!updated) {
		newState.push(action);
	}
};

export default handleDataUpdate;
