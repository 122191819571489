import { checkFrameFilenamesFlag, checkEventTypeAndValueFlag } from "./initializeSentry.helperFunctions";

let beforeSendAction = (event, href) => {
	let eventExceptionList = event?.exception?.values;
	let eventBreadcrumbList = event?.breadcrumbs?.values;
	let loginPageFlag = href?.includes?.("login");
	let syntaxError = "SyntaxError";
	let temporaryIgnoreMessageList = [
		// NOTE: these are WIP, should be removed when fixes are deployed
		"Cannot read properties of undefined (reading 'chart')",
		"Cannot read properties of null (reading 'chartHeight')",
		"Cannot read properties of undefined (reading 'evaluationOption')",
		"Cannot read properties of null (reading 'remove')",
		"canceled",
	];

	let permanentIgnoreMessageList = [
		// NOTE: We are intending to ignore these forever; no fix will be made
		"ResizeObserver loop limit exceeded", // explanation: https://github.com/ClearPoint-Strategy/cps-react/issues/2079#issuecomment-1416190915
		"INCLUDES:Loading chunk", // handled by RetryChunkLoadPlugin in build.js but does not suppress the error,
		"INCLUDES:Loading CSS chunk",
		"TYPE: ChunkLoadError",
	];
	for (let message of [...temporaryIgnoreMessageList, ...permanentIgnoreMessageList]) {
		if (message.startsWith("INCLUDES:")) {
			message = message.split(":")[1];
			if (eventExceptionList?.some?.((x) => x.value?.includes?.(message))) return null;
		} else if (message.startsWith("TYPE:")) {
			message = message.split(":")[1];
			if (eventExceptionList?.some?.((x) => x.type?.includes?.(message))) return null;
		} else if (eventExceptionList?.some?.((x) => x.value === message)) return null;
	}

	let incorrectLoginFlag = loginPageFlag && eventBreadcrumbList?.some?.((x) => x?.data?.status_code === 403);

	let sameOriginFrameBlockedFlag = eventExceptionList?.some?.(
		(value) =>
			value?.type === "SecurityError" &&
			new RegExp('^Blocked a frame with origin "https?://.*\\.clearpointstrategy\\.com"').test(value?.value)
	);

	let maximumCallStackErrorFlag = eventExceptionList?.some?.(
		(value) =>
			checkEventTypeAndValueFlag(value, "RangeError", "Maximum call stack size exceeded") &&
			checkFrameFilenamesFlag(value, "redactor.js")
	);

	let profileApiErrorFlag = eventBreadcrumbList?.some?.(
		(x) => x?.data?.url === "/api/v1/profile?planhatPortal=true" && x?.data?.status_code === 500
	);

	let invalidUnicodeSequenceFlag =
		loginPageFlag &&
		eventExceptionList?.some?.((x) => checkEventTypeAndValueFlag(x, syntaxError, "Invalid Unicode escape sequence"));

	let oolTiprTooltipErrorFlag = eventExceptionList?.some?.((x) =>
		checkEventTypeAndValueFlag(x, syntaxError, "Unexpected identifier 'oolTipr_tooltip'")
	);

	let nonErrorPromiseRejectionFlag = eventExceptionList?.some?.((x) =>
		checkEventTypeAndValueFlag(
			x,
			"Event",
			"Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type"
		)
	);

	let acsbErrorFlag = eventExceptionList?.some?.(
		(x) =>
			x?.type === "TypeError" &&
			new RegExp(/this\[[a-zA-Z0-9]{2}\(\.\.\.\)] is not a function/).test(x?.value) &&
			checkFrameFilenamesFlag(x, "acsb.js")
	);

	let smoothDndErrorFlag =
		event?.location === "../node_modules/smooth-dnd/dist/index.js" ||
		eventExceptionList?.some?.(
			(x) =>
				(checkEventTypeAndValueFlag(x, "TypeError", "Cannot read properties of null (reading 'addedIndex')") ||
					checkEventTypeAndValueFlag(
						x,
						"TypeError",
						"Cannot read properties of undefined (reading 'getOptions')"
					)) &&
				checkFrameFilenamesFlag(x, "smooth-dnd/dist/index.js")
		);

	let filteredErrorList = [
		acsbErrorFlag,
		incorrectLoginFlag,
		invalidUnicodeSequenceFlag,
		maximumCallStackErrorFlag,
		nonErrorPromiseRejectionFlag,
		oolTiprTooltipErrorFlag,
		profileApiErrorFlag,
		sameOriginFrameBlockedFlag,
		smoothDndErrorFlag,
	];

	if (filteredErrorList.some((x) => x)) return null;

	return event;
};

export default beforeSendAction;
