import { useMemo } from "react";

import AlertApproval from "@clearpoint/old-theme/Alert/Alert.Approval";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormSelectField from "@clearpoint/old-theme/Form/Select/Select.Field";

import { useFieldList } from "@clearpoint/hooks";

import { useOldSession } from "@clearpoint/old-session/index";

let UpdateFieldsTab = () => {
	let scorecardId = useFormValue("scorecardId");
	let sessionScorecardId = useOldSession()?.session?.scorecardId;
	if (!scorecardId) scorecardId = sessionScorecardId;
	let fieldList = useFieldList({ object: "actionItem", updateFlag: true, multipleFlag: true });
	let selectedUpdateFieldList = useFormValue("_selectedUpdateFieldList");
	let displayedFieldList = useMemo(
		() => fieldList?.filter((field) => selectedUpdateFieldList?.includes(field.value)) || [],
		[selectedUpdateFieldList, fieldList]
	);
	return (
		<>
			<AlertApproval scorecardId={scorecardId} type={"updates"} />
			<FormSelectField object="actionItem" name="_selectedUpdateFieldList" multipleFlag updateFlag />
			{displayedFieldList?.map((field) => (
				<FormField
					editFlag
					fieldId={field.value}
					key={field.value}
					label={field.label}
					object="actionItem"
					parentName="updates"
				/>
			))}
		</>
	);
};
export default UpdateFieldsTab;
