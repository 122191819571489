import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import FormSelectField from "@clearpoint/old-theme/Form/Select/Select.Field";
import Help from "@clearpoint/old-theme/Help";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useFieldList } from "@clearpoint/hooks";


let UpdateFieldsTab = () => {
	let objectId = useFormValue("objectId");
	let fieldList = useFieldList({ object: "milestone", objectId, updateFlag: true, multipleFlag: true });
	let selectedFieldList = useFormValue("_selectedUpdateFieldList");
	let displayedFieldList = useMemo(
		() => fieldList && [...fieldList?.filter((field) => selectedFieldList?.includes(field.value))],
		[selectedFieldList, fieldList]
	);
	return (
		<>
			<AlertUpsell feature="dependencies" />
			<Help section="edit-element" feature="update-fields" />
			<FormSelectField
				label=""
				parent="initiative"
				object="milestone"
				name="_selectedUpdateFieldList"
				updateFlag
				multipleFlag
			/>

			{displayedFieldList?.map((field) => (
				<FormField
					editFlag
					fieldId={field.value}
					key={field.value}
					label={field.label}
					parent="initiative"
					parentName="updates"
					object="milestone"
				/>
			))}
		</>
	);
};
export default UpdateFieldsTab;
