import { useCallback, useEffect } from "react";
import getHandleClose from "./useHandleThirdPartyPlugins.getHandleClose";
import handleModalWindow from "./useHandleThirdPartyPlugins.handleModalWindow";

let useHandleThirdPartyPlugins = ({ close, modalWindowVisible, TRANSITION_DURATION }) => {
	// useHandleOpenAndClose
	let handleClose = useCallback(() => {
		getHandleClose({ close, TRANSITION_DURATION });
	}, [TRANSITION_DURATION, close]);
	useEffect(() => handleModalWindow(modalWindowVisible));
	return handleClose;
};
export default useHandleThirdPartyPlugins;
