import FormSelect from "../../../Form/Select/FormSelect";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectAggregateMeasureType = (props) => {
	let translate = useTranslate();
	let parent = useFormValue("parent");
	let options = useMemo(
		() =>
			[
				{
					label: translate("edit.allMeasures"),
					value: 0,
				},
				parent === "measure"
					? {
							label: translate("edit.childMeasures"),
							value: 1,
					  }
					: null,
				{
					label: translate("edit.measureNameEquals"),
					value: 3,
				},
				{
					label: translate("edit.measureNameContains"),
					value: 4,
				},
				{
					label: translate("edit.series.measuresTaggedWith"),
					value: 7,
				},
			].filter((x) => !!x),
		[parent, translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectAggregateMeasureType;
