import { useEffect, useMemo, useRef } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import cloneDeep from "lodash/cloneDeep";
import { emptyObject } from "@clearpoint/utils";

let useDefaultStoreFormValue = ({
	copyFlag,
	defaultValue,
	duplicateRouteFlag,
	newObjectFlag,
	object,
	objectId,
	periodId,
	queryString,
}) => {
	let translate = useTranslate();
	let { get, getStoredValue } = useOldQueryStore();
	let initialLoadFlagRef = useRef(true);
	let data = defaultValue;
	if (!defaultValue && newObjectFlag) data = emptyObject;
	let storeKey = { object, objectId, periodId, queryString };
	if (!data && initialLoadFlagRef.current) {
		data = getStoredValue(storeKey);
		if (!data) {
			get(storeKey);
		}
	} else if (!data && !initialLoadFlagRef.current) {
		data = get(storeKey);
	}
	useEffect(() => {
		if (data && initialLoadFlagRef.current) {
			initialLoadFlagRef.current = false;
		}
	});
	return useMemo(() => {
		let initialValue = cloneDeep(data) || {};
		if ((copyFlag || duplicateRouteFlag) && data?.name) {
			initialValue.name += ` (${translate("global.copy")})`;
		}
		return initialValue;
	}, [copyFlag, data, duplicateRouteFlag, translate]);
};

export default useDefaultStoreFormValue;
