import { useAIAssistant } from "../AIAssistantProvider/AIAssistantProvider";
import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import getReceiveResponseMessageHandler from "./useReceiveResponseMessage.getResponseMessageHandler";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString } from "@clearpoint/utils";
import { useOnEvent } from "@clearpoint/hooks";

let useReceiveResponseMessage = ({
	awaitingUserResubmitFlag,
	awaitingNewAIResponseFlag,
	receivingAIResponseFlag,
	setChatState,
}) => {
	let history = useHistory();
	let translate = useTranslate();
	let receivingAIResponseFlagRef = useRef(receivingAIResponseFlag);

	let {
		assistantState: { chatId },
		resetAIChat,
	} = useAIAssistant();

	let handleChatReset = useCallback(() => {
		resetAIChat();
		setChatState({
			chatEnabledFlag: true,
			currentChatMessage: null,
		});
		history.push("ai" + buildQueryString({ mode: "transcript" }));
	}, [history, resetAIChat, setChatState]);

	let handleChatMessage = useCallback(
		(e) => {
			let receiveResponseMessageHandler = getReceiveResponseMessageHandler({
				assistantChatId: chatId,
				awaitingUserResubmitFlag,
				awaitingNewAIResponseFlag,
				receivingAIResponseFlagRef,
				handleChatReset,
				setChatState,
				translate,
			});
			receiveResponseMessageHandler(e);
		},
		[awaitingUserResubmitFlag, awaitingNewAIResponseFlag, chatId, handleChatReset, setChatState, translate]
	);

	useOnEvent({
		dependencyList: [handleChatMessage],
		eventName: "chatBotEvent",
		handlerFunction: handleChatMessage,
	});
};

export default useReceiveResponseMessage;
