import { buildQueryString, emptyObject } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

let generateCreatePPT = (set, translate, translateOnlyInsideCurlyBraces) => async (layout, periodId, options) => {
	let { layoutId, name: pageTitle, object, referenceLayoutId, referenceLayoutObject } = layout || emptyObject;

	let pptObject = referenceLayoutObject || object;
	let pptLayoutId = referenceLayoutId || layoutId;

	// eslint-disable-next-line unicorn/consistent-function-scoping
	let alertError = () => toast.error(translate("options.admin.exportError"));

	if (!pptObject || !pptLayoutId) return alertError();

	try {
		await set({
			object: pptLayoutId ? `${pptObject}Layout` : pptObject,
			objectId: pptLayoutId,
			queryString: buildQueryString({
				periodId,
				pageSize: "letter",
				orientation: "landscape",
				format: "ppt",
				...options,
			}),
			data: { pageTitle: translateOnlyInsideCurlyBraces(pageTitle) },
		});
		toast.success(translate("toaster.messages.templates.templateExportGenerating"));
	} catch {
		alertError();
	}
};

export default generateCreatePPT;
