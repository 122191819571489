import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import getFilteredScorecardList from "./useFilteredMenu.getFilteredScorecardList";
import { useQueryParameters } from "@clearpoint/hooks";


let useFilteredMenu = () => {
	let { get } = useOldQueryStore();
	let { reportId, scorecardId, objectId, layoutId } = useQueryParameters();
	let report = reportId && get({ object: "report", objectId: reportId });
	let scorecardList = get({ object: "scorecard" });
	let scorecardContent = useMemo(
		() =>
			report?.contents.find((scorecard) => scorecard.scorecardId === scorecardId) ||
			report?.contents.find((scorecard) =>
				scorecard.pages.some(
					(x) =>
						[x.scorecardId, x.homeScorecardId].includes(scorecardId) &&
						(objectId ? x.objectId === objectId : x.layoutId === layoutId)
				)
			),
		[layoutId, objectId, report, scorecardId]
	);
	let pageList = scorecardContent?.pages;
	let menu = scorecardId && get({ object: "menu", scorecardId });
	let filteredScorecardList = useMemo(
		() =>
			getFilteredScorecardList({
				menu,
				pageList,
				report,
				scorecardList,
			}),
		[menu, pageList, report, scorecardList]
	);
	return useMemo(
		() =>
			!pageList || !menu || !filteredScorecardList
				? undefined
				: menu
						?.filter(
							(menuObject) =>
								menuObject.object === "scorecard" ||
								menuObject.layouts?.some((layout) =>
									pageList.some(
										(page) => page.layoutId === layout.layoutId && page.object === menuObject.object
									)
								) ||
								menuObject.objects?.some((object) =>
									pageList.some(
										(page) => page.objectId === object.objectId && page.object === menuObject.object
									)
								)
						)
						?.map((menuObject) => ({
							object: menuObject.object,
							layouts: menuObject.layouts?.filter((layout) =>
								pageList.some((page) => page.layoutId === layout.layoutId && page.object === menuObject.object)
							),
							objects:
								menuObject.object === "scorecard"
									? filteredScorecardList
									: menuObject.objects?.filter((object) =>
											pageList.some(
												(page) => page.objectId === object.objectId && page.object === menuObject.object
											)
									  ),
						})),
		[filteredScorecardList, menu, pageList]
	);
};

export default useFilteredMenu;
