import { useEffect } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import parseArguments from "./useStartAndEndDate.parseDateArguments";
import { useConvertDateStringToDateObject } from "@clearpoint/hooks";


let useStartAndEndDate = ({ startDate, endDate, ganttRef, value, ...props }) => {
	let parsedDates = parseArguments({ startDate, endDate, value, ...props });
	startDate = parsedDates.startDate;
	endDate = parsedDates.endDate;
	let { localState, setLocalState } = useLocalState();

	let convertDateStringToDateObject = useConvertDateStringToDateObject();
	useEffect(() => {
		let gantt = ganttRef.current;
		gantt.config.end_date = convertDateStringToDateObject(endDate);
		gantt.config.start_date = convertDateStringToDateObject(startDate);
		if (localState?.startDate && localState?.endDate) {
			setLocalState({
				startDate: convertDateStringToDateObject(startDate),
				endDate: convertDateStringToDateObject(endDate),
			});
		}
	}, [convertDateStringToDateObject, endDate, ganttRef, localState, setLocalState, startDate]);
	return { startDate, endDate };
};

export default useStartAndEndDate;
