import BootstrapRow from "react-bootstrap/Row";
import PropTypes from "prop-types";
import { memo } from "react";
import styled from "styled-components";

let propTypes = {
	alignItems: PropTypes.oneOf(["flex-start", "flex-end", "center", "stretch", "baseline"]),
	justifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "stretch", "baseline"]),
	children: PropTypes.node,
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	display: PropTypes.string,
	flexDirection: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),
	marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let defaultProps = {
	alignItems: "flex-start",
	display: "flex",
	flexDirection: "row",
};

let Row = ({ children, className, "data-testid": dataTestId, style }) => (
	<BootstrapRow className={className} style={style} data-testid={dataTestId}>
		{children}
	</BootstrapRow>
);

let StyledRow = styled(Row)`
	align-items: ${(props) => props.alignItems};
	justify-content: ${(props) => props.justifyContent};
	flex-direction: ${(props) => props.flexDirection};
	margin-bottom: ${(props) => props.marginBottom};
	display: ${(props) => props.display};
`;

StyledRow.propTypes = propTypes;
StyledRow.defaultProps = defaultProps;

export default memo(StyledRow);
