let setInitialGridWidth = ({ ganttRef, gridWidth, measuredGridWidth, widthCorrectedRef }) => {
	if (!measuredGridWidth || widthCorrectedRef.current) return;

	let gantt = ganttRef.current;

	if (measuredGridWidth !== gridWidth) {
		gantt.config.grid_width = gridWidth;
		gantt.render();
	}
	widthCorrectedRef.current = true;
};

export default setInitialGridWidth;
