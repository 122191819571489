let mapVisibleToList = (list, filteredSeriesList) =>
	list?.map((series) => {
		let filterSeries = filteredSeriesList?.find((filtered) => filtered?.measureSeriesId === series?.measureSeriesId);

		if (!filterSeries) return series;

		return { ...series, visible: filterSeries.visible };
	});

export default mapVisibleToList;
