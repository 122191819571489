import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { capitalizeFirstLetter } from "@clearpoint/utils";

import FormCustomField from "../../Form/CustomField";
import FormFieldIndex from "../../Form/Field/FieldIndex";
import DisplayRouter from "./Display/DisplayRouter";

let propTypes = {
	disabled: PropTypes.bool,
	fieldId: PropTypes.string,
	name: PropTypes.string,
	object: PropTypes.string,
	parentName: PropTypes.string,
	periodLockedFlag: PropTypes.bool,
	scorecardLockedFlag: PropTypes.bool,
	section: PropTypes.string,
	seriesType: PropTypes.string,
};
let InputField = ({
	disabled,
	fieldId,
	parentName,
	periodLockedFlag,
	object,
	scorecardLockedFlag,
	seriesType,
	section,
	...props
}) => {
	let { get } = useOldQueryStore();
	let customFieldFlag = fieldId.startsWith("custom");
	let customFieldList = customFieldFlag ? get({ object: "customField", queryString: `?object=${object}` }) : [];
	let customField = customFieldFlag && customFieldList?.find((x) => x.customFieldId === +fieldId.slice(6));
	let { customFieldType, picklist } = customField || {};
	let InputField =
		customFieldFlag && customFieldType
			? FormCustomField[capitalizeFirstLetter(customFieldType)]
			: !customFieldFlag
			? FormFieldIndex[capitalizeFirstLetter(fieldId)]
			: null;

	let name = `${parentName ? parentName + "." : ""}${fieldId}${customFieldFlag ? ".value" : ""}`;
	return InputField ? (
		periodLockedFlag ? (
			<DisplayRouter fieldId={fieldId} name={name} parentName={parentName} periodLockedFlag {...props} />
		) : (
			<InputField
				disabled={disabled}
				fieldId={fieldId}
				name={name}
				object={object}
				parentName={parentName}
				picklist={picklist}
				section={section}
				seriesType={seriesType}
				{...props}
			/>
		)
	) : null;
};
InputField.propTypes = propTypes;
export default InputField;
