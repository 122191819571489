import ChartsTab from "./ChartsTab";
import EditFieldsTab from "./EditFieldsTab";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { useCallback } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "@clearpoint/services/toastService/index";

let propTypes = {
	measureId: PropTypes.number.isRequired,
	objectIdList: PropTypes.arrayOf(PropTypes.number).isRequired,
};
let EditMultipleChartsModal = ({ measureId, objectIdList, ...props }) => {
	let translate = useTranslate();
	let { setChecklist } = useChecklist();

	let onSubmit = useCallback(
		(value, apiResponse, contextValue) => {
			setChecklist((checklist) => {
				checklist = cloneDeep(checklist);
				return checklist.map((x) => ({ ...x, checked: false }));
			});
			toast.success(
				translate("toaster.messages.objects.multiObjectsUpdated", {
					count: Math.max(apiResponse.edits.length, apiResponse.updates.length),
					objects: translate("charts"),
				})
			);
		},
		[setChecklist, translate]
	);

	return (
		<ModalWindowEdit
			color="desaturatedNormal"
			defaultValue={undefined}
			object="chart"
			objectIdList={objectIdList}
			size="medium"
			onSubmit={onSubmit}
			emptySubmitWarning={translate("edit.element.selectFieldsWarning")}
			{...props}
		>
			<ModalWindowHeader
				badgeIcon="charts"
				badgeText={translate("chart")}
				title={translate("edit.element.editMultiCharts")}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<ChartsTab title={translate("charts")} objectIdList={objectIdList} measureId={measureId} />
					<EditFieldsTab title={translate("edit.element.editFields")} />
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter />
		</ModalWindowEdit>
	);
};
EditMultipleChartsModal.propTypes = propTypes;
export default EditMultipleChartsModal;
