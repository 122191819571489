import { formatSeriesNumber } from "../utils";
import isArray from "lodash/isArray";
import { emptyObject } from "@clearpoint/utils";


let describeGauge = ({ chartData, series, translate }) => {
	let description = "";
	let plotBandList = chartData.chartFormat.yAxis.plotBands;
	let i = 0;
	let gauge = series?.[0] ?? emptyObject;
	let { name, numberFormat } = gauge;
	let data = gauge.data?.[0];
	let { name: periodName, y: value } = data ?? emptyObject;
	description += `\n${periodName}\n`;
	description += `${name}: ${formatSeriesNumber(value, numberFormat)}`;
	if (isArray(plotBandList)) {
		for (let plotBand of plotBandList) {
			let fromValue = plotBand.from;
			let nextFromValue = plotBandList[i + 1]?.from;
			let toValue = nextFromValue === undefined ? plotBand.to : nextFromValue;
			if (fromValue !== toValue) {
				description += `\n${translate("global.fromTo", {
					from: formatSeriesNumber(fromValue, numberFormat),
					to: formatSeriesNumber(toValue, numberFormat),
				})}: ${plotBand.status}`;
			}
			i++;
		}
	}
	return description;
};
export default describeGauge;
