import BootstrapListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "./ListGroupItem";
import PropTypes from "prop-types";
import { memo } from "react";
import { sizes } from "./values";


let listGroupPropTypes = {
	as: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	flushFlag: PropTypes.bool,
	horizontal: PropTypes.oneOf(["small", "medium", "large", "huge", true]),
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let listGroupDefaultProps = {
	as: "div",
	flushFlag: false,
};

let ListGroup = memo(({ as, children, className, "data-testid": dataTestId, flushFlag, horizontal, style }) => {
	return (
		<BootstrapListGroup
			as={as}
			className={className}
			data-testid={dataTestId}
			horizontal={sizes[horizontal] || horizontal}
			variant={flushFlag && "flush"}
			style={style}
		>
			{children}
		</BootstrapListGroup>
	);
});

ListGroup.propTypes = listGroupPropTypes;
ListGroup.defaultProps = listGroupDefaultProps;

ListGroup.Item = ListGroupItem;

export default ListGroup;
