import { useMenuColor } from "@clearpoint/hooks";

import { theme } from "../Theme";
// see gantt style docs: https://docs.dhtmlx.com/gantt/desktop__css_overview.html

let useStyle = (exportFlag) => {
	const menuColor = useMenuColor();
	return `
   .gantt_container, .gantt_task_vscroll {
      background: transparent;
      border: none;
   }
   .gantt_ver_scroll {
      border: none;
   }
   .gantt_grid_scale, .gantt_task_scale {
      position: relative;
      background: transparent !important;
      border: none;
   }
   .gantt_grid_head_cell, .gantt_scale_cell, .gantt_scale_line {
      color: ${theme.white} !important;
      background-color: ${menuColor};
      font-weight: 600;
      font-size: 13px;
      font-family: "${theme.defaultFontFamily}";
      height: 26px !important;
      line-height: 26px !important;
   }
   .gantt_grid_head_cell {
      position: absolute;
      bottom: 0px;
      text-align: left;
      padding-left: 12px;
      font-weight: 700;
      height: 25px !important;
      line-height: 25px !important;
   }
   .gantt_cell, .gantt_row_task {
      height: 26px !important;
      line-height: 26px !important;
      font-size: 13px;
      font-family: "${theme.defaultFontFamily}";
      color: ${exportFlag ? theme.black : theme.darkGray};
      padding-left: 0px;
   }
   .gantt_row, .gantt_task_row {
      background-color: ${theme.white} !important;
   }
   .gantt_row.odd, .gantt_task_row.odd {
      background-color: ${theme.lighterGray} !important;
   }
   .gantt_task_row.gantt_selected .gantt_task_cell {
      border-right-color: #ebebeb;
   }
   .gantt_resizer {
      width: 4px !important;
      background-color: ${menuColor} !important;
      border: none !important;
      margin-left: -1px;
   }
   .gantt_period_date {
      background-color: red;
      opacity: 0.6;
   }
   .gantt_task_line {
      border: none;

      &.no-move {
         .gantt_task_content, .gantt_task_drag {
            cursor: default;
         }
      }
      &.single-day {
         width: 8px !important;
      }
   }
   .gantt_link_control {
      display: none;
   }
   .gantt_side_content.gantt_left {
      left: 5px;
      right: unset;
      padding-right: 0px;
      color: white;
      font-weight: bold;
      transform: translateY(2px);
   }
   .gantt_side_content.gantt_right {
      left: calc(100% - 12px);
      padding-left: 0px;
      color: white;
      font-weight: bold;
      transform: translateY(2px);
   }
`;
};
export default useStyle;
