import { useMemo } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

let useGanttData = ({ object, objectId, periodId, scorecardId }) => {
	let { get } = useOldQueryStore();
	let ganttData =
		object === "initiative" && objectId && periodId && scorecardId
			? get({ parent: "initiative", parentId: objectId, object: "ganttData", periodId })
			: undefined;
	return useMemo(
		() =>
			ganttData?.map?.((x) => ({
				...x.tasks[0].data,
				movableFlag: x.tasks[0].movable,
				name: x.name,
				object: x.id?.match(/\D+/)?.[0],
				objectId: x.id && +x.id.match(/\d+/)?.[0],
				parent: x.parent?.match(/\D+/)?.[0],
				parentId: x.parent && +x.parent.match(/\d+/)?.[0],
			})),
		[ganttData]
	);
};

export default useGanttData;
