import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import useViewType from "../useViewType";
import generateOnSubmitCallback from "./useOnGoalSubmit.generateOnSubmitCallback";
import setSupervisorId from "./useGoalOnSubmit.setSupervisorId";
import { toast } from "@clearpoint/services/toastService/index";
import { buildQueryString, emptyObject } from "@clearpoint/utils";

let useGoalOnSubmit = (objectId) => {
	let translate = useTranslate();
	let history = useHistory();
	let { get, set } = useOldQueryStore();
	let viewType = useViewType();

	let profile = get({ object: "profile" });
	let userList = get({ object: "users" });

	let { session } = useOldSession();
	let { periodId } = session || emptyObject;

	return useCallback(
		async (submitValue, _apiResponse, formContext) => {
			let onSubmitCallback = generateOnSubmitCallback({
				buildQueryString,
				history,
				objectId,
				periodId,
				profile,
				set,
				setSupervisorId,
				toast,
				translate,
				userList,
				viewType,
			});
			return onSubmitCallback(submitValue, _apiResponse, formContext);
		},
		[history, objectId, periodId, profile, set, translate, userList, viewType]
	);
};

export default useGoalOnSubmit;
