import { useEffect } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { calculateStartAndEndDate } from "@clearpoint/utils";

let useGanttState = ({ loadingFlag, elementDetailList }) => {
	let { endDate: localEndDate, layout, setLocalState, startDate: localStartDate } = useLocalState();
	let { startDate, endDate } = loadingFlag ? {} : calculateStartAndEndDate(elementDetailList);
	startDate = layout?.reportOptions?.ganttStartDate || startDate;
	endDate = layout?.reportOptions?.ganttEndDate || endDate;
	useEffect(() => {
		if (startDate !== localStartDate || endDate !== localEndDate) {
			setLocalState({
				startDate,
				endDate,
			});
		}
	});
};
export default useGanttState;
