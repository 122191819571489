/* eslint-disable complexity */
import { useCallback, useState } from "react";
import { darken } from "polished";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import ChartPreview from "@clearpoint/old-theme/Chart/Chart.Preview";
import Column from "@clearpoint/old-theme/Column";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useBuildLink, useCheckAccess, useContentBackgroundColor } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject, formatNameForTestId } from "@clearpoint/utils";

import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import EditChartModal from "@components/Elements/Shared/ChartsTab/EditChartModal";

let columnSizeLookup = {
	default: {
		huge: 3,
		large: 4,
		medium: 6,
		small: 12,
	},
	medium: {
		huge: 4,
		large: 6,
		medium: 12,
	},
	large: {
		huge: 6,
		large: 12,
	},
	export: {
		default: {
			small: 3,
		},
		medium: {
			small: 4,
		},
		large: {
			small: 6,
		},
	},
};
let chartHeightLookup = {
	default: 225,
	medium: 325,
	large: 325,
};
let exportChartHeightLookup = {
	default: 225,
	medium: 275,
	large: 275,
};
let dashboardSizeLookup = {
	4: "small",
	3: "medium",
};
let propTypes = {
	chart: PropTypes.shape({
		altText: PropTypes.string,
		chartId: PropTypes.number,
		measureId: PropTypes.number,
		name: PropTypes.string,
	}),
	measureStatus: PropTypes.shape({
		access: PropTypes.string,
		name: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		scorecardId: PropTypes.number,
		statusId: PropTypes.number,
	}),
};
let DashboardListItem = ({ chart, measureStatus }) => {
	let translate = useTranslate();
	let contentBackgroundColor = useContentBackgroundColor();
	let buildLink = useBuildLink();
	let checkAccess = useCheckAccess();
	let { customFieldFlag, layout } = useLocalState();
	let [editChartFlag, setEditChartFlag] = useState();
	let { chartWidth, dashboardSize, showChartDescriptions } = layout.reportOptions;
	if (chartWidth && !dashboardSize) dashboardSize = dashboardSizeLookup[chartWidth];
	let { exportFlag, htmlExportFlag } = useOldSession().session;
	let { altText, chartId, measureId, name: chartName } = chart || emptyObject;
	let { access, name: measureName, object, objectId, scorecardId, statusId } = measureStatus || emptyObject;
	let columnSize = columnSizeLookup[dashboardSize] || columnSizeLookup.default;
	if (exportFlag) columnSize = columnSizeLookup.export[dashboardSize] || columnSizeLookup.export.default;
	let chartHeight = chartHeightLookup[dashboardSize] || chartHeightLookup.default;
	if (exportFlag) chartHeight = exportChartHeightLookup[dashboardSize] || exportChartHeightLookup.default;
	if (exportFlag && showChartDescriptions) chartHeight -= 40;
	let editAccessFlag = !exportFlag && checkAccess({ access, action: "edit", scorecardId });
	let edit = useCallback(() => {
		if (checkAccess({ access, action: "edit", scorecardId, toastFlag: true })) {
			setEditChartFlag(true);
		}
	}, [access, checkAccess, scorecardId]);
	let close = useCallback(() => setEditChartFlag(false), []);
	let borderFlag = customFieldFlag || (exportFlag && !htmlExportFlag);
		return (
		<>
			<Column {...columnSize}>
				<Block
					backgroundColor={theme.white}
					border={borderFlag && `1px solid ${theme.lightGray}`}
					borderRadius="8px"
					boxShadow="1px 1px 9px -6px rgb(0 0 0 / 50%)"
					data-testid="dashboard-container"
					marginBottom={theme.mediumLargeSpace}
					onDoubleClick={exportFlag ? undefined : edit}
					title={editAccessFlag ? translate("edit.doubleClick") : undefined}
				>
					<Block
						backgroundColor={darken(0.05, contentBackgroundColor)}
						borderRadius="8px 8px 0 0"
						display="flex"
						padding={`10px 5px 5px 8px`}
						alignItems="center"
					>
						<StatusIcon statusId={statusId} marginTop="-4px" marginRight="5px"/>
						<Block flex="1" lineHeight={1.05} ellipsisFlag overflow="hidden !important">
							<Block
								data-testid={`dashboard-list-item-${formatNameForTestId(chartName)}`}
								className="dashboard-list-item-text"
								ellipsisFlag
							>
								<a href={buildLink({ scorecardId, object, objectId })}>{chartName}</a>
							</Block>
							<Block className="dashboard-list-item-text" ellipsisFlag>
								<small>{measureName}</small>
							</Block>
						</Block>
					</Block>
					<Block
						borderTop={`1px solid ${theme.lightGray}`}
						padding={`${theme.mediumSpace} ${theme.smallSpace} ${theme.tinySpace} ${theme.smallSpace}`}
						wordBreak="normal"
					>
						<ChartPreview
							chart={chart}
							chartId={chartId}
							measureId={measureId}
							chartHeight={chartHeight}
							noExportButtonFlag={exportFlag}
						/>
						{showChartDescriptions && (
							<Block
								height={customFieldFlag ? "40px" : (exportFlag ? "80px" : "120px")}
								overflow={exportFlag || customFieldFlag ? "hidden !important" : "auto"}
							>
								{altText}
							</Block>
						)}
					</Block>
				</Block>
			</Column>
			{measureId && editChartFlag && (
				<EditChartModal measureId={measureId} objectId={chartId} modalWindowVisible close={close} />
			)}
		</>
	);
};

DashboardListItem.propTypes = propTypes;

export default DashboardListItem;
