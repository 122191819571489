import { toast } from "react-toastify";

let getClipboardDataAsync = async (translate) => {
	if (!navigator.clipboard.readText) {
		toast.error(translate("error.fireFox.clipboard.readText"));
		return null;
	}

	try {
		let text = await navigator.clipboard.readText();
		return text;
	} catch (error) {
		toast.error(translate("error.clipboard.permissionDenied"));
		let el = document.createElement("div");
		el.style = { position: "absolute", left: "-9999px" };
		document.body.appendChild(el);
		el.focus();
		document.execCommand("paste");
		let text = el.textContent;
		document.body.removeChild(el);
		return text === "" ? null : text;
	}
};
export default getClipboardDataAsync;
