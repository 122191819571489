export let objectPropTypeString = (props, propName, componentName) => {
	if (!props.object && !props.name && !props.onTrash) {
		return new Error(`One of props 'onTrash', 'object', or 'name' must be specified in '${componentName}'.`);
	}

	if (props.object === undefined) return;

	let receivedType = typeof props.object;
	if (receivedType !== "string") {
		return new Error(`Invalid prop ${propName} supplied to ${componentName}. Expected string, got ${receivedType}`);
	}

	if (props.object && !(props.objectId || props.objectIdList)) {
		return new Error(
			`Prop 'object' requires a value for either prop 'objectId' or prop 'objectIdList'. One was not specified in ${componentName}'.`
		);
	}
};

export let objectIdPropTypeNumber = (props, propName, componentName) => {
	if (props.objectId === undefined) return;

	let receivedType = typeof props.objectId;
	if (receivedType !== "number") {
		return new Error(`Invalid prop ${propName} supplied to ${componentName}. Expected number, got ${receivedType}`);
	}
	if (!props.object && props.objectId) {
		return new Error(
			`Prop 'objectId' requires a value for prop 'object', but did not find one in ${componentName}'.`
		);
	}
};

export let objectIdPropTypeNumberOrString = (props, propName, componentName) => {
	if (props.objectId === undefined) return;

	let receivedType = typeof props.objectId;
	if (receivedType !== "string" && receivedType !== "number") {
		return new Error(
			`Invalid prop ${propName} supplied to ${componentName}. Expected number or string, got ${receivedType}`
		);
	}
	if (!props.object && props.objectId) {
		return new Error(
			`Prop 'objectId' requires a value for prop 'object', but did not find one in ${componentName}'.`
		);
	}
};

export let objectIdListPropTypeArray = (props, propName, componentName) => {
	if (props.objectIdList === undefined) return;
	let receivedType = typeof props.objectIdList;
	if (props.objectIdList && typeof props.objectIdList !== "object" && typeof props.objectIdList.length !== "number") {
		return new Error(`Invalid prop ${propName} supplied to ${componentName}. Expected array, got ${receivedType}`);
	}
	if (!props.object && props.objectIdList) {
		return new Error(
			`Prop 'objectIdList' requires a value for prop 'object', but did not find one in ${componentName}'.`
		);
	}
};
