import { useCallback } from "react";
import useUserTimezone from "./useUserTimezone";
import convertDateStringToDateObject from "./useConvertDateStringToDateObject.convertDateStringToDateObject";

let useConvertDateStringToDateObject = () => {
	let { ianaName, offset } = useUserTimezone();
	let offsetSign = Math.sign(+offset);

	return useCallback(
		(date, ignoreTimezoneFlag) => {
			let convertDateStringToDateObjectCallback = convertDateStringToDateObject({ ianaName, offset, offsetSign });
			return convertDateStringToDateObjectCallback(date, ignoreTimezoneFlag);
		},
		[ianaName, offset, offsetSign]
	);
};

export default useConvertDateStringToDateObject;