import Button from "../Button/Button";
import IconButton from "../Button/Button.Icon";
import Input from "../Input/Input";
import StyleWrapper from "../StyleWrapper";
import { useCallback, useEffect, useState } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	advancedFilterVisible: PropTypes.bool,
	disableAdvancedFilterFlag: PropTypes.bool,
	filterSearchValue: PropTypes.string,
	logFileCount: PropTypes.number,
	setFilter: PropTypes.func.isRequired,
	toggleAdvancedFilter: PropTypes.func,
};

let Toolbar = ({
	advancedFilterVisible,
	disableAdvancedFilterFlag,
	filterSearchValue,
	logFileCount,
	setFilter,
	toggleAdvancedFilter,
}) => {
	let translate = useTranslate();
	let [searchInputValue, setSearchInput] = useState(filterSearchValue);
	let filter = useCallback(() => {
		setFilter({ field: "search", value: searchInputValue });
	}, [searchInputValue, setFilter]);

	let handlePressEnter = useCallback(
		(event) => {
			if (event.key === "Enter") {
				event.preventDefault();
				event.stopPropagation();
				filter();
			}
		},
		[filter]
	);

	useEffect(() => {
		window.addEventListener("keydown", handlePressEnter);
		return () => window.removeEventListener("keydown", handlePressEnter);
	}, [handlePressEnter]);

	let handleChange = useCallback((e) => {
		setSearchInput(e.target.value);
	}, []);
	return (
		<Input
			marginBottom="0"
			height="38px"
			value={searchInputValue}
			onChange={handleChange}
			onBlur={filter}
			placeholder={translate("revisionHistory.searchHistory")}
			left={
				!disableAdvancedFilterFlag && (
					<StyleWrapper
						borderColor={theme.gray}
						borderRadius={advancedFilterVisible ? "4px 0 0 0 !important" : undefined}
					>
						<IconButton
							data-testid="show-advanced-filter"
							color="default"
							name="dropdown"
							iconSize="large"
							iconTransform="down-1"
							noFixedWidthFlag
							onClick={toggleAdvancedFilter}
						/>
					</StyleWrapper>
				)
			}
			right={
				<StyleWrapper
					borderRadius={advancedFilterVisible && "0 4px 0 0 !important"}
					justifyContent="center"
					width="150px"
					marginRight={theme.tinySpace}
				>
					<Button data-testid="search-button" color="primary" onClick={filter}>
						{translate("search.search")}
					</Button>
				</StyleWrapper>
			}
			floatingText={logFileCount ? translate("adminTags.matchingElements", { number: logFileCount }) : undefined}
		/>
	);
};

Toolbar.propTypes = propTypes;

export default Toolbar;
