import Chart from "@clearpoint/old-theme/Chart/Chart";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useFormatChart } from "@clearpoint/hooks";


let chartListItemPropTypes = {
	chartData: PropTypes.object,
	measureGridList: PropTypes.array,
};

let ChartListItem = ({ chartData, measureGridList }) => {
	let formatChart = useFormatChart();
	let { name: title } = chartData;
	let options = formatChart({ chartData, measureGridList });

	return (
		<FormGroup title={title} marginTop="0" marginBottom={theme.smallSpace} paddingBottom={theme.space}>
			<Chart options={options} />
		</FormGroup>
	);
};

ChartListItem.propTypes = chartListItemPropTypes;

export default ChartListItem;
