import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckHiddenElement } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { createUniqueIdFromPod, emptyArray } from "@clearpoint/utils";

import { useDragAndDropContext } from "../DragAndDropWrapper/DragAndDropWrapper";
import Droppable from "./Droppable";
import SortableItem from "./SortableItem";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";

let locationLookup = {
	area0: "global.top",
	area1: "global.left",
	area2: "global.right",
	area3: "global.bottom",
};

let propTypes = {
	areaClass: PropTypes.oneOf(Object.keys(locationLookup)),
	pageNumber: PropTypes.number.isRequired,
};

let Area = ({ areaClass, pageNumber }) => {
	let translate = useTranslate();
	let areaList = useFormValue("layout");
	let checkHiddenElement = useCheckHiddenElement();
	let location = locationLookup[areaClass];
	let areaIndex = areaList.findIndex((x) => x.page === pageNumber && x.class === areaClass);
	let area = areaList[areaIndex];
	let { generateContainerId, dragAndDropPodIdLookup } = useDragAndDropContext();
	let containerId = generateContainerId(area);
	let podIdList = dragAndDropPodIdLookup[containerId] ?? emptyArray;
	let podListInForm = areaList.flatMap((x) => x.pods).filter((x) => !checkHiddenElement(x.fieldId));

	return (
		<Droppable containerId={containerId} podList={podIdList}>
			<SortableContext items={podIdList} strategy={rectSortingStrategy}>
				<Block
					backgroundColor={theme.white}
					borderRadius={theme.borderRadius}
					minHeight="100px"
					className="areaBounds"
					padding={`${theme.tinySpace} ${theme.mediumSpace}`}
					marginTop={theme.tinySpace}
					marginBottom={areaClass === "area3" ? theme.mediumSpace : "13px"}
					marginLeft={areaClass === "area2" && theme.smallSpace}
					marginRight={areaClass === "area1" && theme.smallSpace}
				>
					<StyleWrapper lineHeight="30px">
						<small>{`${translate("global.page")} ${pageNumber + 1} ${translate(location)}`}</small>
					</StyleWrapper>

					<Block marginLeft="-15px" marginRight="-15px" display="flex" flexWrap="wrap">
						{podIdList?.map((x, i) => {
							let podInForm = podListInForm.find((y) => createUniqueIdFromPod(y) === x);
							return podInForm ? (
								<SortableItem
									key={x}
									id={x}
									pod={podInForm}
									areaIndex={areaIndex}
									podIndex={i}
									containerId={containerId}
								/>
							) : (
								<SortableItem
									key={x}
									id={x}
									pod={null}
									areaIndex={areaIndex}
									podIndex={i}
									containerId={containerId}
								/>
							);
						})}
					</Block>
				</Block>
			</SortableContext>
		</Droppable>
	);
};

Area.propTypes = propTypes;

export default Area;
