import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";
import { emptyObject } from "@clearpoint/utils";


let isDoubleClicked = (td) => {
	if (!(td.lastClick && Date.now() - td.lastClick < 250)) {
		td.lastClick = Date.now();
		return false;
	}
	return true;
};

let isValidCoords = (coords) => {
	if (!coords) return false;
	let { row, col } = coords;
	return row !== null && col !== null && row > 0 && col > 0;
};

let getCellMeta = (instance, coords) => {
	try {
		return instance.getCellMeta(coords.row, coords.col);
	} catch (e) {
		console.error("Error getting cell meta");
	}
};

function toastErrorOnCalculatedFieldCell(coords, message) {
	if (isValidCoords(coords)) {
		let cellMeta = getCellMeta(this, coords);
		let { seriesType } = cellMeta?.seriesData || emptyObject;
		if (seriesType === "calculated") {
			toast.error(message);
		}
	}
}

let generateAfterOnCellMouseUpCallback = (translate) => {
	let calculatedFieldError = translate("toaster.messages.global.calculatedFieldSavePending");

	// Do not convert to arrow function or the `this` binding will be lost
	return function (_event, coords, td) {
		if (!td || !coords) return;
		if (isDoubleClicked(td)) {
			toastErrorOnCalculatedFieldCell.call(this, coords, calculatedFieldError);
		}
	};
};

let useAfterOnCellMouseUp = () => {
	let translate = useTranslate();
	let afterOnCellMouseUpCallback = generateAfterOnCellMouseUpCallback(translate);
	return afterOnCellMouseUpCallback;
};

export default useAfterOnCellMouseUp;
export { generateAfterOnCellMouseUpCallback };
