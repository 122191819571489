import { useCallback } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import generateElementListOnSubmitFunction from "./useElementListOnSubmit.generateElementListOnSubmitFunction";
import { useOldSession } from "@clearpoint/old-session/index";

let useElementListOnSubmit = (elementListName = "elementList") => {
	let { clear, set } = useOldQueryStore();
	let { session } = useOldSession();
	let onSubmit = useCallback(
		(submitValue, formContext) => {
			let elementListOnSubmit = generateElementListOnSubmitFunction({ clear, elementListName, set, session });
			elementListOnSubmit(submitValue, formContext);
		},
		[clear, elementListName, set]
	);
	return onSubmit;
};

export default useElementListOnSubmit;
