import { useEffect, useState } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";

let useSetBodyLoaded = () => {
	let { loadingFlag: storeLoadingFlag } = useOldQueryStore();
	let [loadingState, setLoadingState] = useState(null);

	useEffect(() => {
		if (loadingState === null && storeLoadingFlag) {
			setLoadingState("loading");
		}
		if (loadingState === "loading" && storeLoadingFlag === false) {
			document.body.setAttribute("loaded", "true");
		}
	}, [loadingState, storeLoadingFlag]);
};

export default useSetBodyLoaded;
