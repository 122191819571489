import replaceSingularNewlinesWithDouble from "./replaceSingularNewlinesWithDouble";

let getResponseMessageHandler =
	({ assistantChatId, scrollToBottom, setAssistantState }) =>
	(e) => {
		let { chatId, message, status } = e?.detail || {};
		if (assistantChatId !== chatId) return;
		let responseStatusFlag = status === "response";
		let validatingFlag = status === "thinking";

		setAssistantState({
			initialResponseReceivedFlag: (x) => (x === false ? responseStatusFlag : x),
			messageList: (x = []) => {
				return responseStatusFlag
					? [...x, { role: "assistant", content: replaceSingularNewlinesWithDouble(message) }]
					: x;
			},
			responseReceivedFlag: responseStatusFlag,
			validatingFlag: (previousValidatingFlag) => {
				if (status === "complete") return false;
				return previousValidatingFlag === false ? validatingFlag : previousValidatingFlag;
			},
		});

		if (!scrollToBottom) return;

		if (responseStatusFlag) {
			setTimeout(scrollToBottom, 200);
		} else {
			scrollToBottom();
		}
	};

export default getResponseMessageHandler;
