import Block from "../Block";
import DownloadButton from "../Button/Button.Download";
import EditButton from "../Button/Button.Edit";
import EditModal from "./EditModal";
import Icon from "../Icon/Icon";
import ListItem from "../ListItem/ListItem";
import PeriodBadge from "../Badge/Badge.Period";
import TrashButton from "../Button/Button.Trash";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useCheckAccess, useFormatDate, useScorecard } from "@clearpoint/hooks";


let propTypes = {
	attachment: PropTypes.shape({
		attachmentId: PropTypes.number,
		createdBy: PropTypes.number,
		filename: PropTypes.string,
		filetype: PropTypes.string,
		link: PropTypes.shape({
			url: PropTypes.string,
		}),
		name: PropTypes.string,
		periodId: PropTypes.number,
		scorecardId: PropTypes.number,
		uploadDate: PropTypes.string,
	}),
	editFlag: PropTypes.bool,
	exportFlag: PropTypes.bool,
	htmlExportFlag: PropTypes.bool,
	userId: PropTypes.number,
};

let AttachmentListItem = ({ attachment, editFlag, exportFlag, htmlExportFlag, userId }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let checkAccess = useCheckAccess();
	let { attachmentId, createdBy, filename, filetype, link, name, periodId, scorecardId, uploadDate } = attachment;
	let scorecard = useScorecard(scorecardId);
	let editAccessFlag;
	if (userId && createdBy === userId) {
		editAccessFlag = true;
	} else {
		editAccessFlag = scorecard && checkAccess({ access: scorecard.access, action: "edit", scorecardId });
	}
	let showDownloadButtonFlag = !exportFlag;
	let showDownloadLinkFlag = htmlExportFlag;
	let showEditAndTrashButtonsFlag = editFlag && editAccessFlag;
	let showPeriodBadgeFlag = !showEditAndTrashButtonsFlag && periodId !== -1;
	return (
		<ListItem
			left={
				<Block display="flex" alignItems="center" fontWeight="normal">
					<Icon noFixedWidthFlag marginRight={theme.smallSpace} name={filetype} size="large" />
					<Block ellipsisFlag display="flex" alignItems="center">
						<Block ellipsisFlag>{name}</Block>
						{!exportFlag && (
							<Block ellipsisFlag marginLeft={theme.space}>
								<small>
									{translate("components.uploaded")} {formatDate(uploadDate, "alertLong")}
								</small>
							</Block>
						)}
					</Block>
				</Block>
			}
			right={
				<>
					{showEditAndTrashButtonsFlag && (
						<>
							<TrashButton object="attachment" objectId={attachmentId} name={filename} />
							<EditButton data-testid="edit-attachment-button" Modal={EditModal} attachment={attachment} />
						</>
					)}
					{showDownloadLinkFlag && (
						<a className="attachment" href={link.url}>
							<Icon name="download" />
						</a>
					)}
					{showDownloadButtonFlag && <DownloadButton href={link.url} />}
					{showPeriodBadgeFlag && <PeriodBadge periodId={periodId} />}
				</>
			}
			size="small"
		/>
	);
};

AttachmentListItem.propTypes = propTypes;
export default AttachmentListItem;
