import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormInputDate from "@clearpoint/old-theme/Form/Input/Input.Date";
import Help from "@clearpoint/old-theme/Help";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { useScorecardLockedFlag } from "@clearpoint/hooks";

let CompletedItemsTab = () => {
	let translate = useTranslate();
	let { scorecardId, hideCompletedInitiatives, hideCompletedMilestones, hideCompletedActionItems } = useFormValue();
	let { clearFormValue, restoreFormValue } = useFormContext();
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let handleDate = useCallback(
		(name, checked) => {
			if (checked) {
				clearFormValue(name);
			} else {
				restoreFormValue(name);
			}
		},
		[clearFormValue, restoreFormValue]
	);
	let handleInitiativeDate = useCallback(
		(checked) => handleDate("hideCompletedInitiativesDate", checked),
		[handleDate]
	);
	let handleMilestoneDate = useCallback((checked) => handleDate("hideCompletedMilestonesDate", checked), [handleDate]);
	let handleActionItemDate = useCallback(
		(checked) => handleDate("hideCompletedActionItemsDate", checked),
		[handleDate]
	);
	return (
		<>
			<Help feature="completed-elements" />
			<FormGroup title={translate("options.completedItems")} marginTop="0px">
				<FormCheck disabled={scorecardLockedFlag} name="hideCompletedInitiatives" onClick={handleInitiativeDate}>
					{hideCompletedInitiatives
						? translate("edit.hideElementCompletedBefore", { element: "initiatives" })
						: translate("edit.hideCompletedElement", { element: "initiatives" })}
				</FormCheck>
				{hideCompletedInitiatives && <FormInputDate name="hideCompletedInitiativesDate" required />}
				<FormCheck disabled={scorecardLockedFlag} name="hideCompletedMilestones" onClick={handleMilestoneDate}>
					{hideCompletedMilestones
						? translate("edit.hideElementCompletedBefore", { element: "milestones" })
						: translate("edit.hideCompletedElement", { element: "milestones" })}
				</FormCheck>
				{hideCompletedMilestones && <FormInputDate name="hideCompletedMilestonesDate" required />}
				<FormCheck disabled={scorecardLockedFlag} name="hideCompletedActionItems" onClick={handleActionItemDate}>
					{hideCompletedActionItems
						? translate("edit.hideElementCompletedBefore", { element: "actionItems" })
						: translate("edit.hideCompletedElement", { element: "actionItems" })}
				</FormCheck>
				{hideCompletedActionItems && (
					<FormInputDate disabled={scorecardLockedFlag} name="hideCompletedActionItemsDate" required />
				)}
			</FormGroup>
		</>
	);
};

export default CompletedItemsTab;
