import LinkChartItem from "../../LinkChart/LinkChartItem";
import LinkChartExpandButton from "../LinkChartItem/LinkChartExpandButton";
import { renderToString } from "react-dom/server";
import { updateNodeOnHighlight, updateLinkOnHighlight, validateAndPruneLinkList } from "./renderUtils";
import { CHILD_MARGIN, MARGIN_BETWEEN, MARGIN_PAIR } from "./constants";

let renderLinkChart = ({ chartRef, d3ContainerRef, linkListRef, nodeHeight, nodeProps, nodeWidth, rotatedFlagRef }) => {
	let rootScorecardId = linkListRef.current[0]?.scorecardId;

	let render = () => {
		chartRef.current
			.container(d3ContainerRef.current)
			.data(linkListRef.current)
			.nodeWidth((_d3Node) => nodeWidth)
			.nodeHeight((_d3Node) => nodeHeight)
			.childrenMargin((_d3Node) => CHILD_MARGIN)
			.compactMarginBetween((_d3Node) => MARGIN_BETWEEN)
			.compactMarginPair((_d3Node) => MARGIN_PAIR)
			.linkUpdate(updateLinkOnHighlight)
			.nodeUpdate(updateNodeOnHighlight)
			.nodeContent((d3Node) =>
				renderToString(<LinkChartItem objectData={d3Node.data} rootScorecardId={rootScorecardId} {...nodeProps} />)
			)
			.buttonContent((d3Node) =>
				renderToString(<LinkChartExpandButton node={d3Node} rotatedFlag={rotatedFlagRef.current} />)
			)
			.render();
	};

	try {
		render();
	} catch (e) {
		linkListRef.current = validateAndPruneLinkList(linkListRef.current);
	}
};

export default renderLinkChart;
