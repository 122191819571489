import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { formatNumber, joinNameList } from "@clearpoint/utils";

let propTypes = {
	fieldId: PropTypes.string,
	name: PropTypes.string,
	parentName: PropTypes.string,
};

let NumericDisplay = ({ fieldId, name, parentName, objectData }) => {
	let fieldInForm = useFormValue(joinNameList(parentName, fieldId));
	let { numberFormat } = fieldInForm || objectData || {};
	let value = useFormValue(name);
	return value === "" ? <span /> : <span>{formatNumber({ number: value, numberFormat })}</span>;
};

NumericDisplay.propTypes = propTypes;
export default NumericDisplay;
