import * as d3 from "d3";
import { STROKE_COLOR, STROKE_HIGHLIGHT_COLOR, STROKE_HIGHLIGHT_WIDTH, STROKE_WIDTH } from "./constants";

let getHighlightFlag = (d3Node) => {
	let { _highlighted, _upToTheRootHighlighted } = d3Node?.data || {};
	return _highlighted || _upToTheRootHighlighted;
};

// required non arrow functions for `this` binding
function updateLinkOnHighlight(d3Node) {
	let highLightFlag = getHighlightFlag(d3Node);

	let lineStroke = highLightFlag ? STROKE_HIGHLIGHT_COLOR : STROKE_COLOR;
	let lineStrokeWidth = highLightFlag ? STROKE_HIGHLIGHT_WIDTH : STROKE_WIDTH;

	d3.select(this).attr("stroke", lineStroke).attr("stroke-width", lineStrokeWidth);
	if (highLightFlag) d3.select(this).raise();
}

function updateNodeOnHighlight(d3Node) {
	let highLightFlag = getHighlightFlag(d3Node);

	let nodeStroke = highLightFlag ? STROKE_HIGHLIGHT_COLOR : "none";
	let nodeStrokeWidth = highLightFlag ? STROKE_HIGHLIGHT_WIDTH * 2 : STROKE_WIDTH;

	d3.select(this).select(".node-rect").attr("stroke", nodeStroke).attr("stroke-width", nodeStrokeWidth);
}

// Validate list and remove any invalid links: those without a valid id or parent with valid id
let validateAndPruneLinkList = (linkList) => {
	let idMap = linkList.reduce((acc, link) => {
		acc[link.id] = true;
		return acc;
	}, {});

	return linkList.filter((link) => idMap[link.id] && (!link.parentId || idMap[link.parentId]));
};

export { updateLinkOnHighlight, updateNodeOnHighlight, validateAndPruneLinkList };
