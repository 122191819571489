import { useEffect } from "react";

import Block from "@clearpoint/old-theme/Block";
import HTML from "@clearpoint/old-theme/HTML";
import { theme } from "@clearpoint/old-theme/Theme";

import { useQueryParameters } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import useHtml from "./useHtml";

const getHeaderStyles = (height) => {
	return `
		overflow: hidden !important;
		max-width: 100%;
		& img {
			max-height: ${height} 
		}
	`;
};

let Header = () => {
	let { get } = useOldQueryStore();
	let { reportId } = useQueryParameters();
	let report = get({ object: "report", objectId: reportId });
	let height = report?.topMargin;
	let headerHtml = useHtml(report?.htmlHeader);
	// add no-table-pdf-tag to header html
	useEffect(() => {
		let table = document.querySelector("header table");
		if (table && !table.classList.contains("no-table-pdf-tag")) table.classList.add("no-table-pdf-tag");
	}, []);
	const heightValue = (height ? height - 2 : 10) + "mm";
	return headerHtml ? (
		<Block height={heightValue} as="header" position="relative" $style={getHeaderStyles(heightValue)}>
			<HTML>{headerHtml}</HTML>
			<Block height={theme.mediumSpace}>&nbsp;</Block>
		</Block>
	) : null;
};

export default Header;
