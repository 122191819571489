import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { useCheckFeature, useQueryParameters, useScorecardArchivedFlag } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyArray, emptyObject } from "@clearpoint/utils";

import { useDetailPageData } from "../DataProvider";
import useLoadingFlag from "./useLoadingFlag";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";
import NotFound from "@components/NotFound";

let propTypes = {
	children: PropTypes.node,
	inModalFlag: PropTypes.bool,
	periodId: PropTypes.number,
};

let StateProvider = ({ children, inModalFlag, periodId }) => {
	let { element } = useDetailPageData();
	let loadingFlag = useLoadingFlag();
	let { get } = useOldQueryStore();
	let checkFeature = useCheckFeature();
	let { commentId } = useQueryParameters();
	let scorecardArchivedFlag = useScorecardArchivedFlag(element?.scorecardId);
	const { shadowObject, shadowObjectId, code } = element || emptyObject;

	let originObject;
	if (shadowObjectId) originObject = get({ object: shadowObject, objectId: shadowObjectId });
	const activeElementFlag = originObject ? originObject?.active : element?.active ?? true;
	let notFoundFlag = scorecardArchivedFlag || code === 404 || isEqual(element, emptyArray) || !activeElementFlag;

	return notFoundFlag ? (
		<NotFound />
	) : (
		<LocalStateProvider
			discussionVisible={!!commentId}
			editElementFlag={false}
			editLayoutFlag={false}
			element={element}
			inModalFlag={inModalFlag}
			lockedPodList={emptyArray}
			loadingFlag={loadingFlag}
			pagePeriodId={periodId}
			pendingMeasureDataEditFlag={false}
			recalcPendingFlag={!!element?.recalcPending}
			detailPageResizeFlag={checkFeature("detailPageResize")}
			keyResultsDirtyFlag={false}
			openKeyResultTrayList={emptyArray}
			mostRecentlyOpenedKeyResultTray={null}
		>
			{children}
		</LocalStateProvider>
	);
};

StateProvider.propTypes = propTypes;
export default StateProvider;
