import { useCallback, useMemo } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import { useFormContext } from "../../Form/DefaultForm";
import useFormValue from "../../Form/hooks/useFormValue";
import SelectAllSelectNoneButtons from "../../Form/Select/SelectAllSelectNoneButtons";
import Select from "./FormSelect";

let propTypes = {
	activeOnlyFlag: PropTypes.bool,
	allowNotDefinedFlag: PropTypes.bool,
	compactFlag: PropTypes.bool,
	createLabel: PropTypes.func,
	excludeChildrenFlag: PropTypes.bool,
	excludeCurrentFlag: PropTypes.bool,
	multipleFlag: PropTypes.bool,
	name: PropTypes.string,
	notDefinedValue: PropTypes.any,
	object: PropTypes.string.isRequired,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
	parent: PropTypes.string,
	parentId: PropTypes.number,
	queryString: PropTypes.string,
	scorecardId: PropTypes.number,
	showAllScorecardsFlag: PropTypes.bool,
	showLevelsFlag: PropTypes.bool,
	showSelectAllSelectNoneButtonsFlag: PropTypes.bool,
	valueKey: PropTypes.string,
};

let FormSelectObject = ({
	activeOnlyFlag,
	allowNotDefinedFlag,
	compactFlag,
	createLabel,
	disableLockedFlag,
	excludeChildrenFlag,
	excludeCurrentFlag,
	multipleFlag,
	name,
	notDefinedValue,
	object,
	objectIdList,
	parent,
	parentId,
	queryString,
	scorecardId,
	showAllScorecardsFlag,
	showLevelsFlag,
	showSelectAllSelectNoneButtonsFlag,
	valueKey,
	...props
}) => {
	let translate = useTranslate();
	let { objectId } = useFormValue();
	let { setFormValue } = useFormContext();
	let { get } = useOldQueryStore();
	let objectList = useMemo(() => {
		if (parent && !parentId) return [];
		let response = get({
			object,
			scorecardId: scorecardId || undefined,
			parentId: parentId || undefined,
			parent: parent || undefined,
			queryString,
		});
		return activeOnlyFlag ? response?.filter((x) => x.active) : response;
	}, [activeOnlyFlag, get, object, parent, parentId, queryString, scorecardId]);
	let options = useMemo(() => {
		if (objectList === undefined) return;
		if (!isArray(objectList)) return [];
		if (object === "scorecard" && !showAllScorecardsFlag) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			objectList = objectList.filter((x) => !["Updater", "Browser"].includes(x.access));
		}
		let options = objectList.map((x) => ({
			label: createLabel?.(x) || x.name,
			value: valueKey ? x[valueKey] : x.objectId || x[object + "Id"] || x.fieldId || 0,
			level: object === "scorecard" && showLevelsFlag ? x.level : null,
			isDisabled: !!(disableLockedFlag && object === "scorecard" && x.locked),
		}));
		if (excludeCurrentFlag) {
			options = options.filter((x) => (objectIdList ? !objectIdList.includes(x.value) : x.value !== objectId));
		}
		if (excludeChildrenFlag) {
			let objectListWithChildren = objectList?.map((x, i) => {
				let children = [];
				for (let j = i + 1; j < objectList.length; j++) {
					let nextItem = objectList[j];
					if (nextItem && nextItem.level > x.level) {
						children = [...children, nextItem.objectId];
					} else {
						break;
					}
				}
				return { ...x, children };
			});
			let currentElement = objectListWithChildren.find((x) => x.scorecardId === objectId);
			options = options.filter((option) => !currentElement?.children?.includes(option.value));
		}
		if (allowNotDefinedFlag) {
			options = [{ label: translate("global.notDefined"), value: notDefinedValue }, ...options]; // Option for not picking a specific object
		}
		return options;
	}, [
		allowNotDefinedFlag,
		excludeChildrenFlag,
		excludeCurrentFlag,
		notDefinedValue,
		object,
		objectId,
		objectIdList,
		objectList,
		translate,
		valueKey,
	]);

	let selectAll = useCallback(() => {
		setFormValue(
			name,
			options.map((x) => x.value)
		);
	}, [name, options, setFormValue]);
	return (
		<>
			<Select
				placeholder={translate("edit.selectElement", { element: object })}
				options={options}
				loadingFlag={!objectList}
				noOptionsMessage={translate("select.noElementsFound")}
				multipleFlag={multipleFlag}
				name={name}
				{...props}
			/>
			{multipleFlag && showSelectAllSelectNoneButtonsFlag && (
				<SelectAllSelectNoneButtons
					compactFlag={compactFlag}
					marginBottom={props.marginBottom}
					name={name}
					selectAll={selectAll}
				/>
			)}
		</>
	);
};


export default FormSelectObject;
