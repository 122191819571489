import EditActionItemModal from "./ActionItems/ManageActionItems/EditActionItemModal";
import EditCategoryModal from "./Scorecards/ManageScorecards/EditScorecardModal/EditCategoriesTab/EditCategoryModal";
import EditGoalModalWindow from "./Goals/Shared/EditGoalModalWindow";
import EditInitiativeModal from "./Initiatives/ManageInitiatives/EditInitiativeModalWindow";
import EditLayoutModal from "../Layouts/ManageLayouts/EditLayoutModal";
import EditMeasureModal from "./Measures/ManageMeasures/EditMeasureModal";
import EditMilestoneModal from "./Initiatives/ManageInitiatives/EditInitiativeModalWindow/MilestonesTab/EditMilestoneModal";
import EditObjectiveModal from "./Objectives/ManageObjectives/EditObjectiveModal";
import EditRiskModal from "./Risks/ManageRisks/EditRiskModal";
import EditScorecardModal from "./Scorecards/ManageScorecards/EditScorecardModal";
import ModalWindow from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import PropTypes from "prop-types";

let editModalLookup = {
	actionItem: EditActionItemModal,
	category: EditCategoryModal,
	goal: EditGoalModalWindow,
	initiative: EditInitiativeModal,
	measure: EditMeasureModal,
	milestone: EditMilestoneModal,
	objective: EditObjectiveModal,
	risk: EditRiskModal,
	scorecard: EditScorecardModal,
};

let propTypes = {
	layoutFlag: PropTypes.bool,
	layoutId: PropTypes.number,
	object: PropTypes.oneOf(Object.keys(editModalLookup)).isRequired,
	objectId: PropTypes.number,
};

let EditElementModalWindow = ({ object, objectId, layoutFlag, layoutId, ...props }) => {
	let Modal = layoutFlag || layoutId ? EditLayoutModal : editModalLookup[object] || ModalWindow;

	return <Modal object={object} objectId={objectId} layoutId={layoutId} {...props} />;
};

EditElementModalWindow.propTypes = propTypes;
export default EditElementModalWindow;
