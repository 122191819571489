import Icon from "../Icon/Icon";
import StyleWrapper from "../StyleWrapper";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldSession } from "@clearpoint/old-session/index";

let CloseDiscussionBarButton = () => {
	let { setSession } = useOldSession();
	let { setLocalState } = useLocalState();

	let closeDiscussionBar = useCallback(() => {
		setSession({ hideSupportWidgetFlag: false });
		setLocalState({
			discussionVisible: false,
		});
	}, [setLocalState, setSession]);

	return (
		<StyleWrapper
			transition="transform 200ms"
			$style="&:hover {
					transform: scale(1.2);
					}"
		>
			<Icon name="closeDiscussionBar" onClick={closeDiscussionBar} />
		</StyleWrapper>
	);
};

export default CloseDiscussionBarButton;
