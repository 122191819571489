let handleDocumentClickFunction =
	({ setTargetHref, setDiscardModalVisible, preventNavigateCheck }) =>
	(e) => {
		e = e || window.event;
		let element = e.target;
		let anchor = element.tagName === "A" ? element : element.closest("a");
		let emptyAnchorList = ["", "#", null];
		let notEmptyHrefFlag =
			anchor !== null && !emptyAnchorList.includes(anchor.href.replace(window.location.origin + "/", ""));
		let attachmentDownloadFlag = anchor?.href.includes("amazonaws.com");
		if (notEmptyHrefFlag && preventNavigateCheck() && !attachmentDownloadFlag) {
			setTargetHref(anchor.href);
			setDiscardModalVisible(true);
			return false;
		}
	};

export default handleDocumentClickFunction;
