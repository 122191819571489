import isArray from "lodash/isArray";

let createScoredLinkList = (linkList, statusList) =>
	linkList.map((x) =>
		x.score === undefined
			? { ...x, score: statusList.find((status) => status.statusId === x.statusId)?.score || 0 }
			: x
	);

let getTotalObjectiveScore = (linkList, statusList) => {
	if (!isArray(linkList) || !isArray(statusList)) return undefined;
	let scoredLinkList = createScoredLinkList(linkList, statusList);
	return scoredLinkList.some?.((x) => typeof x.weight === "number")
		? scoredLinkList
				.filter?.((x) => !x.repeatedLinkFlag)
				.reduce((totalScore, link) => totalScore + ((link?.score || 0) * (link?.weight || 0)) / 100, 0)
		: undefined;
};

let getTotalObjectiveWeight = (linkList, statusList) => {
	if (!isArray(linkList) || !isArray(statusList)) return undefined;
	let scoredLinkList = createScoredLinkList(linkList, statusList);
	return scoredLinkList?.some((x) => typeof x.weight === "number")
		? scoredLinkList
				.filter((x) => !x.repeatedLinkFlag)
				.reduce((totalWeight, link) => totalWeight + (link?.weight || 0), 0)
		: undefined;
};

let getLinkScore = (link, statusList) => {
	let { score, statusId, weight } = link || {};
	if ([undefined, null].includes(score) && statusId !== undefined)
		score = statusList.find((x) => x.statusId === statusId)?.score || 0;
	return link && typeof weight === "number" ? (weight * (score || 0)) / 100 : undefined;
};

export { getLinkScore, getTotalObjectiveScore, getTotalObjectiveWeight };
