import { useFormContext } from "../Form";
import { useTranslate } from "@clearpoint/translate";
import getDeepValue from "lodash/get";

let useFormError = (name) => {
	let translate = useTranslate();
	let context = useFormContext();
	if (!context) return undefined;
	let { formError } = context;
	let errorMessage = getDeepValue(formError, name);
	if (typeof errorMessage === "object") {
		return Object.values(errorMessage)[0] || translate("error.generic.form.error", { name });
	}

	return name ? errorMessage : formError;
};

export default useFormError;
