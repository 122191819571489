import AIAssistantModal from "./AIAssistantModal";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import MilestonesAssistantModal from "./MilestonesAssistantModal";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import useDefaultPodNameInForm from "../Pod/DefaultPod/useDefaultPodNameInForm";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	fieldId: PropTypes.string.isRequired,
};

let AIAssistantButton = ({ fieldId, ...props }) => {
	let translate = useTranslate();

	if (fieldId === "comments") fieldId = "analysis";

	let fieldNameInForm = useDefaultPodNameInForm();

	return (
		<ModalOnClick
			Modal={fieldId === "milestones" ? MilestonesAssistantModal : AIAssistantModal}
			fieldId={fieldId}
			fieldNameInForm={fieldNameInForm}
		>
			<IconButton name="ai" iconSize="small" right={theme.smallSpace} {...props}>
				{" " + translate("global.aiAssistant")}
			</IconButton>
		</ModalOnClick>
	);
};

AIAssistantButton.propTypes = propTypes;
export default AIAssistantButton;
