import HeaderRight from "./HeaderRight";
import Pod from "@clearpoint/old-theme/Pod/Pod";
import PodContent from "./PodContent";
import useDefaultPodNameInForm from "./useDefaultPodNameInForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { usePodContext } from "../PodProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	onDoubleClick: PropTypes.func.isRequired,
	overflowX: PropTypes.string,
	podStyle: PropTypes.object,
};

let DefaultPod = ({ className, marginTop, onDoubleClick, overflowX, podStyle }) => {
	let { fieldId, hideBorderFlag, template, title, updateModeFlag } = usePodContext();

	let { get } = useOldQueryStore();
	let translate = useTranslate();
	let pageObject = useFormValue("object");

	let name = useDefaultPodNameInForm();

	let options = get({ object: "options" });
	let defaultFieldLanguageOption = useMemo(
		() => options?.defaultFields?.[pageObject]?.fields?.find?.((x) => x.default === title)?.name,
		[options, pageObject, title]
	);
	let headerText = defaultFieldLanguageOption || translate(title);
	return (
		<Pod
			data-testid={`${fieldId}-pod`}
			className={className}
			headerRightContent={<HeaderRight fieldId={fieldId} updateModeFlag={updateModeFlag} />}
			headerText={headerText}
			hideBorderFlag={hideBorderFlag}
			marginTop={marginTop}
			onDoubleClick={onDoubleClick}
			overflowX={overflowX}
			podStyle={podStyle}
		>
			<PodContent name={name} fieldId={fieldId} template={template} />
		</Pod>
	);
};

DefaultPod.propTypes = propTypes;

export default DefaultPod;
