import FormSelect from "./FormSelect";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	includeStaticValueFlag: PropTypes.bool,
	measureId: PropTypes.number,
	measureSeriesIdListToFilterFromOptions: PropTypes.arrayOf(PropTypes.number),
	name: PropTypes.string,
	scorecardId: PropTypes.number,
};

let SelectMeasureSeries = ({
	includeStaticValueFlag,
	measureId,
	measureSeriesIdListToFilterFromOptions,
	name,
	scorecardId,
	...props
}) => {
	// note, this sets measureId in hidden form field:
	// `_measureSeries${measureSeriesId}measureId`
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { setFormValue } = useFormContext();

	let measureSeriesListByMeasure =
		measureId &&
		get({
			parent: "measure",
			parentId: measureId,
			object: "measureSeries",
		});
	let measureSeriesListByScorecard =
		scorecardId &&
		get({
			object: "measureSeries",
			scorecardId,
			queryString: "?list=true",
		});

	let options = useMemo(() => {
		if (measureSeriesListByScorecard) {
			return measureSeriesListByScorecard?.map((measureSeries) => ({
				group: measureSeries.measure,
				label: measureSeries.name,
				value: measureSeries.measureSeriesId,
			}));
		} else if (measureSeriesListByMeasure) {
			let list = measureSeriesListByMeasure
				.filter(
					(measureSeries) =>
						measureSeries.active &&
						!measureSeriesIdListToFilterFromOptions?.includes(measureSeries.measureSeriesId)
				)
				.map((measureSeries) => ({
					label: measureSeries.name,
					value: measureSeries.measureSeriesId,
				}));
			if (!includeStaticValueFlag) return list;
			let staticValue = { label: translate("edit.series.staticValue"), value: -1 };
			return [staticValue, ...list];
		} else {
			return [];
		}
	}, [
		includeStaticValueFlag,
		measureSeriesIdListToFilterFromOptions,
		measureSeriesListByMeasure,
		measureSeriesListByScorecard,
		translate,
	]);

	let valueInForm = useFormValue(name);
	let formMeasureId = useFormValue(`_measureSeries${valueInForm}measureId`);
	useEffect(() => {
		let measureSeriesList = measureSeriesListByMeasure || measureSeriesListByScorecard;
		if (valueInForm) {
			let measureId = measureSeriesList?.find((x) => x.measureSeriesId === valueInForm)?.measureId;
			if (measureId !== formMeasureId) setFormValue(`_measureSeries${valueInForm}measureId`, measureId);
		}
	}, [formMeasureId, setFormValue, valueInForm, measureSeriesListByMeasure, measureSeriesListByScorecard]);

	let loadingFlag = (measureId && !measureSeriesListByMeasure) || (scorecardId && !measureSeriesListByScorecard);
	return (
		<FormSelect
			name={name}
			options={options}
			loadingFlag={loadingFlag}
			placeholder={translate("edit.selectElement", { element: "measureSeries" })}
			{...props}
		/>
	);
};

SelectMeasureSeries.propTypes = propTypes;

export default SelectMeasureSeries;
