import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormInputColor from "@clearpoint/old-theme/Form/Input/Color/FormInputColor";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let listItemPropTypes = {
	index: PropTypes.number.isRequired,
	setColorList: PropTypes.func.isRequired,
};

let ColorListItem = ({ index, setColorList }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();

	let removeColor = useCallback(() => {
		setColorList((colorList) => {
			// On manual removal of a color, make "colors" form value match state to prevent the
			// chart preview from displaying unexpected colors
			let result = [...colorList.slice(0, index), ...colorList.slice(index + 1)];
			setFormValue("colors", result);
			return result;
		});
	}, [index, setColorList, setFormValue]);

	let updateState = useCallback(
		(color) => {
			if (color) {
				setColorList((colorList) => [...colorList.slice(0, index), color, ...colorList.slice(index + 1)]);
			}
		},
		[index, setColorList]
	);

	return (
		<ListItem size="small">
			<Block flex="1">
				<FormInputColor
					name={`colors[${index}]`}
					onColorSave={updateState}
					placeholder={translate("manage.chart.selectColor")}
					size="small"
					errorTextNotVisible
				/>
			</Block>
			<Block paddingLeft={theme.smallSpace}>
				<IconButton data-testid="remove-button" color="danger" name="remove" onClick={removeColor} />
			</Block>
		</ListItem>
	);
};

let listPropTypes = {
	chartFormatData: PropTypes.shape({
		series: PropTypes.array,
	}).isRequired,
};

let ColorList = ({ chartFormatData }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { colors: colorListInForm } = useFormValue();
	let transposedSeriesListLength = chartFormatData?.series?.length;
	let [colorList, setColorList] = useState(colorListInForm);

	// set initial state
	useEffect(() => {
		if (transposedSeriesListLength) {
			setColorList(() => colorListInForm.slice(0, transposedSeriesListLength));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transposedSeriesListLength]);

	let addColor = useCallback(() => {
		if (colorList.length < colorListInForm.length) {
			setColorList((colorList) => [...colorList, colorListInForm[colorList.length]]);
		} else {
			setColorList((colorList) => [...colorList, "#6b8eb2"]);
			setFormValue("colors", (colorList) => [...colorList, "#6b8eb2"]);
		}
	}, [colorList.length, colorListInForm, setFormValue]);

	return (
		<FormGroup title={translate("edit.chart.colors")}>
			{colorList.map((x, i) => (
				<ColorListItem key={i} index={i} colorList={colorList} setColorList={setColorList} />
			))}
			<Block margin={`${theme.space} 0px ${theme.space}`} display="flex" justifyContent="flex-end">
				<Button outlineFlag size="small" onClick={addColor}>
					{translate("edit.chart.addColor")}
				</Button>
			</Block>
		</FormGroup>
	);
};

ColorListItem.propTypes = listItemPropTypes;
ColorList.propTypes = listPropTypes;
export default ColorList;
