import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editFlag: PropTypes.bool,
};
let HiddenSeries = ({ editFlag, ...props }) => {
	let translate = useTranslate();
	return editFlag ? (
		<RadioBoolean
			{...props}
			trueText={translate("global.hide")}
			falseText={translate("global.unhide")}
			label={translate("edit.hideSeries")}
		/>
	) : (
		<Check {...props} data-testid="hiddenSeries" text={translate("edit.hideSeries")} />
	);
};
HiddenSeries.propTypes = propTypes;
export default HiddenSeries;
