let isFrameworkAIAssistantPage = (x) => {
	return x.page === "ai" && x.mode === "framework";
};

let isGoalsAIAssistantPage = (x) => {
	return x.page === "ai" && x.mode === "goals";
};

let isScorecardAIAssistantPage = (x) => {
	return x.page === "ai" && x.mode === "scorecard";
};

export { isFrameworkAIAssistantPage, isGoalsAIAssistantPage, isScorecardAIAssistantPage };
