import Block from "../../../Block";
import Button from "../../../Button/Button";
import Loading from "../../../Loading";
import Modal from "../../../Modal/Modal";
import ModalBody from "../../../Modal/Modal.Body";
import ModalFooter from "../../../Modal/Modal.Footer";
import ModalHeader from "../../../Modal/Modal.Header";
import ModalTitle from "../../../Modal/Modal.Title";
import React, { Suspense, useCallback } from "react";
import { useFormContext } from "../../../Form/Form";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { presetColors } from "@clearpoint/utils";
import { useEffectOnce } from "@clearpoint/hooks";

let ColorPicker = React.lazy(() => import("@clearpoint/old-theme/Form/Input/Color/ColorPicker"));

let propTypes = {
	color: PropTypes.string,
	colorPickerModalVisible: PropTypes.bool,
	formColor: PropTypes.string,
	name: PropTypes.string.isRequired,
	onColorSave: PropTypes.func,
	setColor: PropTypes.func,
	setColorPickerModalVisible: PropTypes.func.isRequired,
};

let ColorPickerModal = ({
	color,
	colorPickerModalVisible,
	formColor,
	name,
	onColorSave,
	setColor,
	setColorPickerModalVisible,
}) => {
	let { session, setSession } = useOldSession();
	let { colors } = session;
	let saveColor = useCallback(() => {
		let newColors = colors;
		let colorIndex = newColors.filter((x) => !!x).findIndex((x) => x.toLowerCase() === color?.toLowerCase());
		if (colorIndex === -1) {
			newColors.pop();
		} else {
			newColors = [...newColors.slice(0, colorIndex), ...newColors.slice(colorIndex + 1)];
		}
		setSession({
			colors: [color, ...newColors],
		});
	}, [color, colors, setSession]);
	useEffectOnce(() => {
		if (!session.colors) {
			setSession({
				colors: [color, ...presetColors],
			});
		} else if (session.colors[0] !== color) {
			saveColor();
		}
	});
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let handleChange = useCallback(
		(color) => {
			if (color) setColor(color.hex);
		},
		[setColor]
	);
	let close = useCallback(() => {
		setColor(formColor);
		setColorPickerModalVisible(false);
	}, [formColor, setColor, setColorPickerModalVisible]);
	let save = useCallback(() => {
		saveColor();
		if (onColorSave) {
			onColorSave(color);
		}
		setFormValue(name, color);
		close();
	}, [close, color, name, onColorSave, saveColor, setFormValue]);
	return (
		<Modal size="small" modalVisible={colorPickerModalVisible} close={close}>
			<ModalHeader>
				<ModalTitle>{translate("global.selectColor")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Block display="flex" justifyContent="center">
					<Suspense fallback={<Loading />}>
						<ColorPicker disableAlpha presetColors={session.colors} color={color} onChange={handleChange} />
					</Suspense>
				</Block>
			</ModalBody>
			<ModalFooter>
				<Button onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button color="primary" onClick={save}>
					{translate("global.save")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

ColorPickerModal.propTypes = propTypes;

export default ColorPickerModal;
