let getHandlePageChange = ({ pageCount, pageIndex, pageIndexKey, sessionPageIndexFlag, setSession, setState }) => {
	if (pageIndex >= 0 && pageIndex < pageCount) {
		if (sessionPageIndexFlag) {
			setSession({ [pageIndexKey]: pageIndex });
		} else {
			setState({ pageIndexState: pageIndex });
		}
	} else if (pageIndex < 0) {
		if (sessionPageIndexFlag) {
			setSession({ [pageIndexKey]: 0 });
		} else {
			setState({ pageIndexState: 0 });
		}
	}
};

export default getHandlePageChange;
