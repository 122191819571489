let generateColor = ({ hex, lightColor, darkColor, noHashTag }) => {
	if (!hex) return darkColor;
	if (hex.indexOf("#") === 0) {
		hex = hex.slice(1);
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	if (hex.length !== 6) {
		return darkColor;
	}
	var r = parseInt(hex.slice(0, 2), 16),
		g = parseInt(hex.slice(2, 4), 16),
		b = parseInt(hex.slice(4, 6), 16);
	var color = r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
	if (noHashTag) {
		color = color.slice(1);
	}
	return color;
};

export default generateColor;
