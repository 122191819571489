import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	object: PropTypes.string,
};
let FormSelectChildObjectType = ({ object, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("objective"),
				value: "objective",
			},
			{
				label: translate("measure"),
				value: "measure",
			},
			{
				label: translate("initiative"),
				value: "initiative",
			},
		],
		[translate]
	);

	return <Select options={options} {...props} />;
};
FormSelectChildObjectType.propTypes = propTypes;

export default FormSelectChildObjectType;
