import Button from "@clearpoint/old-theme/Button/Button";
import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	openChartJsonModal: PropTypes.func.isRequired,
	openChartTypeModal: PropTypes.func.isRequired,
};

let ModalButton = ({ openChartJsonModal, openChartTypeModal }) => {
	let translate = useTranslate();
	let chartType = useFormValue("chartType");

	let customChartFlag = chartType === 0;

	let openModal = useCallback(() => {
		if (customChartFlag) {
			openChartJsonModal();
		} else {
			openChartTypeModal();
		}
	}, [customChartFlag, openChartJsonModal, openChartTypeModal]);

	return (
		<Button onClick={openModal}>
			{translate(customChartFlag ? "edit.chart.customJson" : "select.viewChartTypes")}
		</Button>
	);
};

ModalButton.propTypes = propTypes;
export default ModalButton;
