import { useMemo } from "react";
import PropTypes from "prop-types";

import Tooltip from "@clearpoint/old-theme/Tooltip";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";
import Tag from "./Tag";

let propTypes = {
	// update this pending https://github.com/ClearPoint-Strategy/clearPoint10/issues/6912
	className: PropTypes.string,
	size: PropTypes.string,
	tags: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.shape({
				color: PropTypes.string,
				name: PropTypes.string,
				tagId: PropTypes.number,
			}),
		])
	),
};

let TagList = ({ className, size, tags }) => {
	let { get } = useOldQueryStore();
	let tagList = get({ object: "tag" });
	tagList = useMemo(
		// update this pending https://github.com/ClearPoint-Strategy/clearPoint10/issues/6912
		() => {
			if (!(tags && tagList)) return [];

			return tagList
				.filter((x) => x.active)
				.filter((x) => tags.includes(x.tagId) || tags.find((y) => x.tagId === y.tagId))
				.map((x) => ({
					...x,
					name: x.name.length > 21 ? `${x.name.slice(0, 15)}...${x.name.slice(-3)}` : x.name,
					fullName: x.name.length > 21 ? x.name : x.name,
				}));
		},
		[tags, tagList]
	);
	return tagList.map((tag) => (
		<StyleWrapper key={tag.tagId} marginRight={theme.smallSpace} maxWidth="100%">
			<Tooltip placement="top" text={tag.fullName}>
				<Tag className={className} color={tag.color} size={size}>
					{tag.name}
				</Tag>
			</Tooltip>
		</StyleWrapper>
	));
};

TagList.propTypes = propTypes;

export default TagList;
