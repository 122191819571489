import { useState } from "react";
import PropTypes from "prop-types";

import Icon from "@clearpoint/old-theme/Icon/Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";

import Block from "../../../Block";
import Button from "../../../Button/Button";
import useFormValue from "../../../Form/hooks/useFormValue";
import FormSelectLinkType from "../../../Form/Select/Select.LinkType";
import FormSelectScorecard from "../../../Form/Select/Select.Scorecard";
import Modal from "../../../Modal/Modal";
import ModalBody from "../../../Modal/Modal.Body";
import ModalFooter from "../../../Modal/Modal.Footer";
import ModalHeader from "../../../Modal/Modal.Header";
import ModalTitle from "../../../Modal/Modal.Title";
import ElementChecklist from "./ElementChecklist";
import useGetLinkData from "./useGetLinkData";
import useSaveLinkData from "./useSaveLinkData";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	close: PropTypes.func.isRequired,
	linkType: PropTypes.string,
	modalVisible: PropTypes.bool.isRequired,
	name: PropTypes.string,
	object: PropTypes.string,
	size: PropTypes.string,
};

let defaultProps = {
	size: "large",
};

let LinkElementModal = ({ close, linkType: linkTypeProp, modalVisible, name, object: targetObject, size }) => {
	let translate = useTranslate();
	let [saving, setSaving] = useState(false);
	let [isModal, setIsModal] = useState(linkTypeProp === undefined);

	linkTypeProp = useFormValue("_modal.linkType") || linkTypeProp;
	let { elementList, loadingFlag } = useGetLinkData({
		linkTypeProp,
		name,
		targetObject,
	});

	let scorecardId = useFormValue("_modal.scorecardId");
	let {
		session: { scorecardId: sessionScorecardId },
	} = useOldSession();

	let save = useSaveLinkData({ close, targetObject, name });
	let title = translate(linkTypeProp ? `edit.selectCategories` : "layout.selectElements", {
		categories: linkTypeProp === "category" ? undefined : (linkTypeProp === "child" ? "children" : linkTypeProp + "s"),
	});

	let closeHandler = () => {
		close();
	};

	return (
		<Modal close={close} modalVisible={modalVisible} size={size}>
			<ModalHeader>
				<ModalTitle>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Block display="flex" height={"calc(80vh - 120px)"} flexDirection="column" justifyContent="flex-start">
					<Block marginBottom={"40px"}>
						<FormSelectScorecard
							label={translate("layout.selectObjects", { objects: "scorecard" })}
							name="_modal.scorecardId"
							defaultValue={sessionScorecardId}
						/>
						{isModal && (
							<FormSelectLinkType
								label={translate("global.linkType")}
								name="_modal.linkType"
								object={targetObject}
								defaultFirstOptionFlag={true}
								scorecardId={scorecardId}
							/>
						)}
						<ElementChecklist
							elementList={elementList}
							linkType={linkTypeProp}
							loadingFlag={loadingFlag}
							name={name}
						/>
					</Block>
				</Block>
			</ModalBody>
			<ModalFooter>
				{saving && (
					<Button disabled={true} type="button" color="primary">
						<Icon name="saving" marginRight={theme.smallSpace} spinFlag />
						{translate("button.saving")}
					</Button>
				)}
				{!saving && (
					<>
						<Button onClick={closeHandler} type="button" color="default">
							{translate("global.cancel")}
						</Button>
						<Button
							onClick={() => {
								setSaving(true);
								save();
							}}
							color="primary"
						>
							{translate("global.save")}
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
};

LinkElementModal.propTypes = propTypes;
LinkElementModal.defaultProps = defaultProps;
export default LinkElementModal;
