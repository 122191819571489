import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectAccess = (props) => {
	let translate = useTranslate();

	let options = useMemo(
		() => [
			{ value: "private", label: translate("reportingTemplates.private") },
			{ value: "global", label: translate("reportingTemplates.global") },
			{ value: "restricted", label: translate("reportingTemplates.restricted") },
		],
		[translate]
	);

	return <Select options={options} {...props} />;
};

export default SelectAccess;
