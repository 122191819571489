import { IntercomProvider as ReactUseIntercomProvider } from "react-use-intercom";
import { useEffect } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { useBootThirdPartyPlugins } from "@clearpoint/hooks";

let propTypes = { children: PropTypes.node.isRequired };

let IntercomProvider = ({ children }) => (
	<ReactUseIntercomProvider appId="rwege9m4">{children}</ReactUseIntercomProvider>
);

export let IntercomBooter = () => {
	let {
		session: { success },
	} = useOldSession();
	let { get } = useOldQueryStore();

	let bootThirdPartyPlugins = useBootThirdPartyPlugins();

	let profile = success && get({ object: "profile" });

	useEffect(() => {
		if (profile) {
			bootThirdPartyPlugins();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	return null;
};

IntercomProvider.propTypes = propTypes;
export default IntercomProvider;
