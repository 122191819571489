import Block from "../Block";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	visible: PropTypes.bool,
	x: PropTypes.number,
	y: PropTypes.number,
};

let DropdownContextMenu = ({ children, visible, x, y }) => {
	return (
		<Block
			className="dropdown-menu"
			display={visible ? "inline-block" : "none"}
			fontSize="16px"
			left={x + "px"}
			minWidth="160px"
			padding="0px"
			position="fixed"
			top={y + "px"}
		>
			{children}
		</Block>
	);
};

DropdownContextMenu.propTypes = propTypes;

export default DropdownContextMenu;
