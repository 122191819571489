import Block from "@clearpoint/old-theme/Block";
import FormFieldDisplayElapsedPercentComplete from "@clearpoint/old-theme/Form/Field/Display/ElapsedPercentComplete";

let ElapsedPercentComplete = () => (
	<Block display="flex" justifyContent="flex-end">
		<FormFieldDisplayElapsedPercentComplete name="updates.calculatedValues.elapsedPercentComplete" />
	</Block>
);

export default ElapsedPercentComplete;
