import CellWrapper from "../CellWrapper";
import FormFieldDisplayOwnerId from "@clearpoint/old-theme/Form/Field/Display/OwnerId";
import FormFieldOwnerId from "@clearpoint/old-theme/Form/Field/OwnerId";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let OwnerCell = (props) => {
	let { editCellFlag, parentName } = props;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldOwnerId name={`${parentName}.ownerId`} />
			) : (
				<FormFieldDisplayOwnerId name={`${parentName}.ownerId`} />
			)}
		</CellWrapper>
	);
};
OwnerCell.propTypes = propTypes;

export default OwnerCell;
