import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { emptyArray, removeUndefined } from "@clearpoint/utils";

let getFilter = ({
	data,
	dataState,
	filterList,
	setState,
	startFilterTransition,
	transitionOnStateUpdateFlag,
	unfilteredDataRef,
	visibleKey,
	x,
}) => {
	unfilteredDataRef.current = data;
	data = data || dataState;
	if (!data) return data;
	data = cloneDeep(data);
	data = removeUndefined(data);
	filterList.current = { ...filterList.current, ...x };
	data.forEach((x) => (x[visibleKey] = true));
	for (let filterKey in filterList.current) {
		let filter = filterList.current[filterKey];
		data.forEach((x) => {
			if (!filter(x)) x[visibleKey] = false;
		});
	}
	if (!isEqual(dataState, data)) {
		let updateDataState = () =>
			setState({
				dataState: data || emptyArray,
			});
		if (transitionOnStateUpdateFlag) {
			startFilterTransition(() => {
				updateDataState();
			});
		} else {
			updateDataState();
		}
	}
};

export default getFilter;
