import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useTranslate } from "@clearpoint/translate";

import Block from "../Block";
import Alert from "./Alert";
import useCheckApproval from "@clearpoint/hooks-dir/useCheckApproval";

let ApprovalAlert = ({ scorecardId, type }) => {
	let translate = useTranslate();
	let approvalScorecardId = useFormValue("scorecardId");
	let approvalWarning = useCheckApproval({ scorecardId: scorecardId ?? approvalScorecardId })({ type });
	return (
		approvalWarning && (
			<Alert color="warning">
				<strong>{translate("approvals.approvalRequired")}:</strong> {translate("approvals.approvalRequiredNote")}
			</Alert>
		)
	);
};

export default ApprovalAlert;
