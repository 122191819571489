import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let BooleanDisplay = ({ name }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	if (value === "0") value = false;
	return <span>{translate(value ? "global.displayYes" : "global.displayNo")}</span>;
};

BooleanDisplay.propTypes = propTypes;
export default BooleanDisplay;
