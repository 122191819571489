/* eslint-disable complexity */
import { useMemo } from "react";

import Badge from "@clearpoint/old-theme/Badge/Badge";
import UserBadge from "@clearpoint/old-theme/Badge/Badge.User";
import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import Discussion from "@clearpoint/old-theme/Discussion/index";
import Form from "@clearpoint/old-theme/Form/DefaultForm";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Input from "@clearpoint/old-theme/Form/Input/Input";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import FooterContainer from "@clearpoint/old-theme/ModalWindow/FooterContainer";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import LogEntryListItem from "@clearpoint/old-theme/RevisionHistory/LogEntryListItem";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import Table from "@clearpoint/old-theme/Table";
import { theme } from "@clearpoint/old-theme/Theme";

import { useFormatDate } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import TypeIcon from "../TypeIcon";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";

let ApprovalModalWindow = ({ close, approvalId, modalVisible, accordionName }) => {
	let { get } = useOldQueryStore();
	let approval = useMemo(() => approvalId && get({ object: "approval", objectId: approvalId }), [get, approvalId]);
	let defaultValue = { comments: approval?.comments };
	return approval ? (
		<Form defaultValue={defaultValue}>
			<ViewApprovalModalWindow
				approval={approval}
				modalVisible={modalVisible}
				close={close}
				accordionName={accordionName}
			></ViewApprovalModalWindow>
		</Form>
	) : null;
};

let ViewApprovalModalWindow = ({ close, approval, modalVisible, accordionName }) => {
	let formatDate = useFormatDate();
	let translate = useTranslate();
	let {
		object,
		objectId,
		action,
		queueDate,
		details,
		name,
		userId,
		period,
		scorecard,
		scorecardId,
		approvalStatus,
		updated,
		updatedBy,
		type,
	} = approval;
	let { set, clear } = useOldQueryStore();
	let comments = useFormValue("comments");
	let { resetForm } = useFormContext();
	let handleApprovalAction = async ({ approval, close }) => {
		await set({
			object: "approval",
			objectId: approval.approvalId,
			data: {
				approvalStatus: 1,
				comments,
			},
		});
		if (objectId) await clear({ object, objectId });
		resetForm();
		close();
	};

	let handleRejectAction = async ({ approval, close }) => {
		if (comments) {
			await set({
				object: "approval",
				objectId: approval.approvalId,
				data: {
					approvalStatus: -1,
					comments,
				},
			});
			if (objectId) await clear({ object, objectId });
			resetForm();
			close();
		} else {
			toast.error(translate("approvals.commentsPlaceholder"));
		}
	};

	let handleCancelAction = async ({ approval, close }) => {
		await set({
			object: "approval",
			objectId: approval.approvalId,
			data: {
				approvalStatus: -2,
				comments,
			},
		});
		if (objectId) await clear({ object, objectId });
		resetForm();
		close();
	};

	return (
		<ModalWindowEdit
			size="medium"
			className="view-log-entry-modal"
			modalVisible={modalVisible}
			noFormFlag
			close={close}
			data-testid="revision-history-modal"
		>
			<ModalWindowHeader title={translate("approvals.reviewChange")} />
			<LocalStateProvider discussionVisible={true}>
				<Block display="flex" flex="1 0 1px" height="100%" maxWidth="100%" overflowX="hidden">
					<Block
						padding={`${theme.space} ${theme.mediumSpace}`}
						height="100%"
						flex="1"
						overflowY="auto"
						backgroundColor={theme.white}
					>
						<Table borderedFlag approvalTableFlag>
							<colgroup>
								<col width="34%" />
								<col width="33%" />
								<col width="33%" />
							</colgroup>
							<thead>
								<StyleWrapper backgroundColor={theme.gray100}>
									<tr>
										<th colSpan="3">
											<Block display="flex">
												<Block marginTop="5px">
													<TypeIcon object={object} />
												</Block>
												<Block flex={1} lineHeight="1.1">
													{name ?? translate("approvals.newElement")} <br />
													<small>{scorecard}</small>
												</Block>
											</Block>
										</th>
									</tr>
								</StyleWrapper>
							</thead>
							<tbody>
								<tr>
									<th width="34%">{translate("global.elementType")}</th>
									<td colSpan="2">{translate(object)}</td>
								</tr>
								<tr>
									<th>{translate("approvals.approvalType")}</th>
									<td colSpan="2">{translate(`approvals.${type}`)}</td>
								</tr>
								<tr>
									<th width="34%">{translate("approvals.approvalStatus")}</th>
									<td colSpan="2">
										{approvalStatus === -2 && (
											<Badge color="muted" size="small">
												{translate(`approvals.cancelled`)}
											</Badge>
										)}
										{approvalStatus === -1 && (
											<Badge color="danger" size="small">
												{translate(`approvals.rejected`)}
											</Badge>
										)}
										{approvalStatus === 0 && (
											<Badge color="warning" size="small">
												{translate(`approvals.pending`)}
											</Badge>
										)}
										{approvalStatus === 1 && (
											<Badge color="primary" size="small">
												{translate(`approvals.approved`)}
											</Badge>
										)}
									</td>
								</tr>
								<tr>
									<th>{translate("approvals.requestedBy")}</th>
									<td colSpan="2">
										<UserBadge userId={userId} showName={true} />
									</td>
								</tr>
								<tr>
									<th width="34%">{translate("approvals.requestDate")}</th>
									<td colSpan="2">{formatDate(queueDate, "long")}</td>
								</tr>
								{action === "updated" && (
									<tr>
										<th>{translate("global.period")}</th>
										<td colSpan="2">{period}</td>
									</tr>
								)}
								{approvalStatus === 1 && (
									<>
										<tr>
											<th width="34%">{translate("approvals.approvalDate")}</th>
											<td colSpan="2">{formatDate(updated, "long")}</td>
										</tr>
										<tr>
											<th width="34%">{translate("approvals.approvedBy")}</th>
											<td colSpan="2">
												<UserBadge userId={updatedBy} showName={true} />
											</td>
										</tr>
									</>
								)}
								{approvalStatus === -1 && (
									<>
										<tr>
											<th width="34%">{translate("approvals.rejectedDate")}</th>
											<td colSpan="2">{formatDate(updated, "long")}</td>
										</tr>
										<tr>
											<th width="34%">{translate("approvals.rejectedBy")}</th>
											<td colSpan="2">
												<UserBadge userId={updatedBy} showName={true} />
											</td>
										</tr>
									</>
								)}
								{approvalStatus === -2 && (
									<tr>
										<th width="34%">{translate("approvals.cancelledDate")}</th>
										<td colSpan="2">{formatDate(updated, "long")}</td>
									</tr>
								)}
							</tbody>
						</Table>
						{(type === "edit" || type === "update") && (
							<Block marginTop={theme.mediumLargeSpace}>
								<Table borderedFlag approvalTableFlag>
									<tbody>
										<StyleWrapper backgroundColor={theme.gray100}>
											{action !== "added" && (
												<tr>
													<th width="34%">{translate("revisionHistory.field")}</th>
													<th>{translate("approvals.currentValue")}</th>
													<th>{translate("approvals.proposedValue")}</th>
												</tr>
											)}
											{action === "added" && (
												<tr>
													<th width="34%">{translate("revisionHistory.field")}</th>
													<th>{translate("approvals.proposedValue")}</th>
												</tr>
											)}
										</StyleWrapper>
										{details?.modifiedFields &&
											details.modifiedFields.map((x, i) => (
												<LogEntryListItem hideBeforeFlag={action === "added"} key={i} field={x} />
											))}
									</tbody>
								</Table>
							</Block>
						)}
						{approvalStatus === 0 && (
							<FormGroup title={translate("approvals.comments")}>
								<Input
									as="textarea"
									name="comments"
									placeholder={translate("approvals.commentsPlaceholder")}
									rows="4"
								/>
							</FormGroup>
						)}
						{approvalStatus !== 0 && comments && (
							<FormGroup title={translate("approvals.comments")}>
								<Block paddingBottom={theme.mediumSpace}>{comments}</Block>
							</FormGroup>
						)}
					</Block>
					{type !== "add" && (
						<Discussion hideCloseFlag object={object} objectId={objectId} scorecardId={scorecardId} />
					)}
				</Block>
			</LocalStateProvider>
			<FooterContainer>
				<div />
				<Block display="flex">
					<Button onClick={close} color="transparent">
						{translate("global.close")}
					</Button>
					{accordionName === "approvals.myChanges" && approvalStatus === 0 && (
						<Block marginLeft={"5px"}>
							<Button onClick={() => handleCancelAction({ approval, close })} color="warning">
								<Icon name="close" /> {translate(`approvals.cancel${type}`)}
							</Button>
						</Block>
					)}
					{accordionName === "approvals.myApprovals" && approvalStatus === 0 && (
						<>
							<Block marginLeft={"5px"}>
								<Button onClick={() => handleRejectAction({ approval, close })} color="danger">
									<Icon name="close" /> {translate(`approvals.reject${type}`)}
								</Button>
							</Block>
							<Block marginLeft={"5px"}>
								<Button
									onClick={() => handleApprovalAction({ approval, close })}
									color="primary"
									marginLeft={10}
								>
									<Icon name="check" /> {translate(`approvals.approve${type}`)}
								</Button>
							</Block>
						</>
					)}
				</Block>
			</FooterContainer>
		</ModalWindowEdit>
	);
};

export default ApprovalModalWindow;
