import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "@clearpoint/services/toastService/index";
import { emptyObject } from "@clearpoint/utils";

let useGroupColumns = () => {
	let translate = useTranslate();
	let { session } = useOldSession();
	let { setFormValue } = useFormContext();
	let groupColumns = useCallback(
		(noWarningFlag) => {
			// perform after react state updates
			setTimeout(() =>
				setFormValue(true, (oldFormState) => {
					if (oldFormState) {
						let newFormState = cloneDeep(oldFormState);
						let { _groupBy, _groupByCategory, _groupByObjective, reportColumns, reportOptions } =
							oldFormState || emptyObject;
						let groupByScorecardFlag = _groupBy === "scorecard";
						let groupByCategoryFlag = _groupByCategory;
						let groupByObjectiveFlag = _groupByObjective;
						if (!groupByScorecardFlag) {
							newFormState._groupByCategory = groupByCategoryFlag = false;
							newFormState._groupByObjective = groupByObjectiveFlag = false;
						}
						if (groupByScorecardFlag && reportOptions.customSortEnabled && !noWarningFlag) {
							toast.warning(translate("layout.disableElementOrder"));
						}
						let reportColumnsCopy = reportColumns?.map((x) => {
							let noScorecardGroupingFlag = x.object === "scorecard" && !groupByScorecardFlag;
							let noCategoryGroupingFlag = x.object === "category" && !groupByCategoryFlag;
							let noObjectiveGroupingFlag =
								session.object !== "objective" && x.object === "objective" && !groupByObjectiveFlag;
							let groupingDisabledFlag =
								noScorecardGroupingFlag || noCategoryGroupingFlag || noObjectiveGroupingFlag;
							return groupingDisabledFlag ? { ...x, fullWidth: false } : x;
						});
						reportColumnsCopy = reportColumnsCopy?.map((x) => {
							let hierarchy = ["scorecard", "category", "objective", session.object];
							let objectIndex = hierarchy.indexOf(x.object);
							if (objectIndex < -1) return x;
							let scorecardGroupingFlag = x.object === "scorecard" && groupByScorecardFlag;
							let categoryGroupingFlag = x.object === "category" && groupByCategoryFlag;
							let objectiveGroupingFlag =
								session.object !== "objective" && x.object === "objective" && groupByObjectiveFlag;
							if (!(scorecardGroupingFlag || categoryGroupingFlag || objectiveGroupingFlag)) return x;
							let forceFullWidthFlag = reportColumnsCopy?.some(
								(x) => x.fullWidth && hierarchy.indexOf(x.object) > objectIndex
							);
							return forceFullWidthFlag ? { ...x, fullWidth: true } : x;
						});
						newFormState.reportColumns = reportColumnsCopy;
						newFormState.reportOptions.hierarchy = [
							groupByScorecardFlag && "scorecard",
							groupByCategoryFlag && "category",
							groupByObjectiveFlag && "objective",
							session.object,
						]
							.filter((x) => x)
							.join(",");
						return newFormState;
					}
				})
			);
		},
		[session, setFormValue, translate]
	);
	return groupColumns;
};
export default useGroupColumns;
