import { useHistory } from "react-router-dom";
import { useOldSession } from "@clearpoint/old-session/index";
import { buildQueryString } from "@clearpoint/utils";

let useNavigateToScorecard = () => {
	let { session } = useOldSession();
	let history = useHistory();
	let { object, periodId, objectId } = session;

	return (scorecardId) => {
		if (scorecardId && session.scorecardId !== scorecardId) {
			let queryString = buildQueryString({
				scorecardId,
				object,
				objectId,
				periodId,
			});

			history.push(`${history.location.pathname}${queryString}`);
		}
	};
};

export default useNavigateToScorecard;
