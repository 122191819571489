import { useCallback } from "react";
import useCheckFeature from "../useCheckFeature";
import { useTranslate } from "@clearpoint/translate";

let useChartEditFieldList = () => {
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let tagFlag = checkFeature("tags");
	let advancedFlag = checkFeature("advancedCharting");

	return useCallback(() => {
		let fieldList = [
			{
				value: "tags",
				label: translate("global.tags"),
				group: translate("chart"),
			},
			{
				value: "title",
				label: translate("manage.chart.chartTitle"),
				group: translate("chart"),
			},
			{
				value: "showLegend",
				label: translate("manage.chart.showLegend"),
				group: translate("chart"),
			},
			{
				value: "chartType",
				label: translate("manage.chart.chartType"),
				group: translate("chart"),
			},
			{
				value: "chartSize",
				label: translate("manage.chart.chartSize"),
				group: translate("chart"),
			},
			{
				value: "isTransposed",
				label: translate("manage.chart.transposeAxes"),
				group: translate("chart"),
			},
			{
				value: "isInterpolated",
				label: translate("manage.chart.interpolateValues"),
				group: translate("chart"),
			},
			{
				value: "altText",
				label: translate("manage.chart.chartDescription"),
				group: translate("chart"),
			},
			{
				value: "periodGroupId",
				label: translate("manage.chart.reportingFrequency"),
				group: translate("chart.periods"),
			},
			{
				value: "periodDisplay",
				label: translate("manage.chart.periodsToDisplay"),
				group: translate("chart.periods"),
			},
			{
				value: "showFuture",
				label: translate("manage.chart.showFuturePeriods"),
				group: translate("chart.periods"),
			},
			{
				value: "xTitle",
				label: translate("manage.chart.xAxisTitle"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "yTitle",
				label: translate("manage.chart.yAxisTitle"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "yMin",
				label: translate("manage.chart.yAxisMinValue"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "yMax",
				label: translate("manage.chart.yAxisMaxValue"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "isReversed",
				label: translate("manage.chart.reverseYAxis"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "yAxisFormat",
				label: translate("manage.chart.yAxisFormat"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "y2Title",
				label: translate("manage.chart.yAxis2Title"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "y2Min",
				label: translate("manage.chart.yAxis2MinValue"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "y2Max",
				label: translate("manage.chart.yAxis2MaxValue"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "yAxis2Format",
				label: translate("manage.chart.yAxis2Format"),
				group: translate("chart.chartAxisFormat"),
			},
			{
				value: "sortSeries",
				label: translate("manage.chart.sortChartSeries"),
				group: translate("chart.advancedOptions"),
			},
			{
				value: "hiddenSummary",
				label: translate("manage.chart.hiddenSummary"),
				group: translate("chart.advancedOptions"),
			},
			{
				value: "enablePlotBands",
				label: translate("edit.chart.plotBands"),
				group: translate("chart.advancedOptions"),
			},
		];
		if (!tagFlag) fieldList = fieldList.filter((x) => x.value !== "tags");
		if (!advancedFlag) fieldList.filter((x) => x.value !== "enablePlotBands");

		return fieldList;
	}, [translate, advancedFlag, tagFlag]);
};
export default useChartEditFieldList;
