import HTML from "../../HTML";
import ReactMarkdown from "react-markdown";
import { useMemo } from "react";
import PropTypes from "prop-types";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { decodeHtmlEntities } from "@clearpoint/utils";


let propTypes = {
	children: PropTypes.node,
	noParserFlag: PropTypes.bool,
	parseHtmlFlag: PropTypes.bool,
};

let Parser = ({ children, noParserFlag, parseHtmlFlag }) => {
	let html = useMemo(() => {
		if (parseHtmlFlag && typeof children === "string") {
			return decodeHtmlEntities(children);
		}
		return null;
	}, [children, parseHtmlFlag]);

	return noParserFlag ? (
		children
	) : parseHtmlFlag ? (
		<HTML>{html || children}</HTML>
	) : (
		<ReactMarkdown rehypePlugins={[remarkGfm, rehypeRaw]}>{children}</ReactMarkdown>
	);
};

Parser.propTypes = propTypes;
export default Parser;
