import CalculationMapTab from "./CalculationMapTab";
import CalculationTab from "./CalculationTab";
import EvaluationTab from "./EvaluationTab";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import PeerPreviewTab from "./PeerPreviewTab/PeerPreviewTab";
import SeriesTab from "./SeriesTab/SeriesTab";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useUnmount } from "react-use";
import PropTypes from "prop-types";
import getOnSave from "./getOnSave";
import { toast } from "@clearpoint/services/toastService/index";
import { useStateObject } from "@clearpoint/hooks";


let displayPropTypes = {
	objectId: PropTypes.number,
	parentId: PropTypes.number,
};

let EditSeriesModalDisplay = ({ objectId, parentId }) => {
	let translate = useTranslate();
	let { clear, get } = useOldQueryStore();
	let { newObjectFlag, setFormDefaultValue } = useFormContext();
	let { name, peerGroupId, peerMeasureId, peerMeasureSeriesId, seriesDataTypeId, seriesType } = useFormValue();

	let peerPreview =
		seriesType === "peer" &&
		peerGroupId &&
		peerMeasureId &&
		get({
			url: `/peerGroups/${peerGroupId}/measures/${peerMeasureId}/preview`,
			parent: "preview",
			parentId: peerGroupId,
			object: "measure",
		});

	let peerPreviewFlag = !!peerPreview && peerMeasureSeriesId;
	let seriesTextDataTypeFlag = seriesDataTypeId === 11;

	let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
		saveModalVisible: false,
		savedFlag: false,
	});
	let onSave = useCallback(() => {
		let onSaveFunction = getOnSave({ name, setFormDefaultValue, setState });
		onSaveFunction();
	}, [name, setFormDefaultValue, setState]);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);
	let openSaveModal = useCallback(
		(selectTab) => {
			if (newObjectFlag) {
				setState({ saveModalVisible: true });
			} else {
				selectTab();
			}
		},
		[newObjectFlag, setState]
	);

	let title = objectId ? name : translate("edit.element.addElement", { element: "measureSeries" });

	useUnmount(() => {
		if (savedFlag && objectId) clear({ parent: "measure", parentId: parentId, object: "measureSeries", objectId });
	});

	return (
		<>
			<ModalWindowHeader
				data-testid="edit-series-modal"
				badgeIcon="measureSeries"
				badgeText={translate("labels.measureSeries")}
				title={title}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<SeriesTab title={translate("global.series")} onClick={openSaveModal} parentId={parentId} />
					{!seriesTextDataTypeFlag && (
						<CalculationTab title={translate("layout.calculation")} onClick={openSaveModal} />
					)}
					{seriesType === "calculated" && !seriesTextDataTypeFlag && (
						<CalculationMapTab title={translate("edit.map.calculationMap")} onClick={openSaveModal} />
					)}
					{!seriesTextDataTypeFlag && (
						<EvaluationTab title={translate("edit.evaluation")} onClick={openSaveModal} />
					)}
					{peerPreviewFlag && !seriesTextDataTypeFlag && (
						<PeerPreviewTab
							title={translate("edit.series.peerPreview")}
							onClick={openSaveModal}
							peerPreview={peerPreview}
							peerMeasureSeriesId={peerMeasureSeriesId}
						/>
					)}
				</ModalWindowTabContainer>
				<FormModalSave
					submitUnchangedFlag
					parent="measure"
					parentId={parentId}
					object="measureSeries"
					onSave={onSave}
					modalVisible={saveModalVisible}
					close={closeSaveModal}
				/>
			</ModalWindowContent>
			<ModalWindowFooter saveAndContinueFlag />
		</>
	);
};

EditSeriesModalDisplay.propTypes = displayPropTypes;

let modalPropTypes = {
	modalVisible: PropTypes.bool,
	objectId: PropTypes.number,
	periodGroupId: PropTypes.number,
	scorecardId: PropTypes.number,
	measureId: PropTypes.number,
};

let EditSeriesModal = ({ close, modalWindowVisible, ...props }) => {
	let [objectId, setObjectId] = useState(props.objectId);
	let { measureId, scorecardId, seriesName } = props;
	let translate = useTranslate();
	let { clear, get } = useOldQueryStore();
	let defaultValue = useMemo(() => {
		if (objectId) return undefined;
		return {
			currency: "   ",
			hiddenPeriods: [],
			measureId,
			name: undefined,
			object: "measureSeries",
			periodDisplay: "all",
			periodGroupId: -1,
			scorecardId,
			seriesDataTypeId: 4,
			tags: [],
		};
	}, [measureId, objectId, scorecardId]);

	let measureSeries = props.objectId && get({ object: "measureSeries", objectId: props.objectId });

	let onSubmit = useCallback(
		(submitValue) => {
			let customNumberFormatUnsetFlag =
				measureSeries?.seriesDataTypeId === 10 &&
				submitValue.seriesDataTypeId &&
				submitValue.seriesDataTypeId !== 10;
			let customNumberFormatSetFlag = submitValue.seriesDataTypeId === 10;
			if (customNumberFormatSetFlag || customNumberFormatUnsetFlag) {
				clear({ object: "numberFormat" });
			}
			toast.success(translate(objectId ? "toaster.messages.series.seriesUpdated" : "edit.series.seriesAdded"));
		},
		[clear, measureSeries, objectId, translate]
	);
	return (
		<ModalWindowEdit
			{...props}
			close={close}
			color="desaturatedNormal"
			defaultValue={defaultValue}
			modalWindowVisible={modalWindowVisible}
			onSubmit={onSubmit}
			parent="measure"
			parentId={measureId}
			object="measureSeries"
			submitUnchangedFlag={!objectId}
			objectId={objectId}
			setObjectId={setObjectId}
			size="medium"
			updateFlag
		>
			<EditSeriesModalDisplay parentId={measureId} objectId={objectId} seriesName={seriesName} />
		</ModalWindowEdit>
	);
};

EditSeriesModal.propTypes = modalPropTypes;
export default EditSeriesModal;
