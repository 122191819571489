import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	scorecardFlag: PropTypes.bool,
	milestoneFlag: PropTypes.bool,
	mapFlag: PropTypes.bool,
};
let FormSelectObjectType = ({ scorecardFlag, milestoneFlag, mapFlag, chartFlag, measureSeriesFlag, ...props }) => {
	let translate = useTranslate();
	let objectTypeList = useMemo(() => {
		let options = [
			{
				label: translate("objective"),
				value: "objective",
			},
			{
				label: translate("measure"),
				value: "measure",
			},
			{
				label: translate("initiative"),
				value: "initiative",
			},
			{
				label: translate("actionItem"),
				value: "actionItem",
			},
			{
				label: translate("risk"),
				value: "risk",
			},
		];
		if (mapFlag) {
			options.unshift({
				label: translate("map"),
				value: "map",
			});
		}
		if (scorecardFlag) {
			options.unshift({
				label: translate("scorecard"),
				value: "scorecard",
			});
		}
		if (milestoneFlag) {
			options.push({
				label: translate("milestone"),
				value: "milestone",
			});
		}
		if (measureSeriesFlag) {
			options.push({
				label: translate("measureSeries"),
				value: "measureSeries",
			});
		}
		if (chartFlag) {
			options.push({
				label: translate("chart"),
				value: "chart",
			});
		}
		return options;
	}, [chartFlag, mapFlag, measureSeriesFlag, milestoneFlag, scorecardFlag, translate]);
	return <FormSelect data-testid={"select-object-type"} options={objectTypeList} {...props} />;
};
FormSelectObjectType.propTypes = propTypes;

export default FormSelectObjectType;
