import cloneDeep from "lodash/cloneDeep";

import { toast } from "@clearpoint/services/toastService/index";

let revertChange = async ({ afterClose, clear, close, details, get, object, objectId, periodId, set, translate }) => {
	let element = {};
	details.modifiedFields.map((field) => {
		if (
			[null, undefined].includes(field.before) ||
			[null, undefined].includes(field.after) ||
			field.before !== field.after
		) {
			if (field.fieldId === "measureData") {
				element = cloneDeep(field.before);
			}
			if (field.fieldId === "rules" && field.after.rules.length > field.before.rules.length) {
				let rules = cloneDeep(field.after.rules);
				let lastRuleIndex = rules.length - 1;

				let targetRuleId = get({ object: "group", objectId })?.rules[lastRuleIndex].ruleId;
				rules[lastRuleIndex].deleted = true;
				rules[lastRuleIndex].ruleId = targetRuleId;
				rules[lastRuleIndex].groupId = objectId;

				element[field.fieldId] = rules;
			} else {
				element[field.fieldId] = field.before[field.fieldId];
			}
		}
		return field;
	});

	let objectData = { object, objectId, periodId };
	await set({
		...objectData,
		data: { ...element, ...objectData, periodId: object === "period" ? objectId : periodId },
	});

	if (periodId) {
		toast.success(`${translate(object)} ${translate("revisionHistory.revertedUpdate")}`);
	} else {
		toast.success(`${translate(object)} ${translate("revisionHistory.revertedEdit")}`);
	}
	clear({ object: "logFile" });
	close();
	if (afterClose) afterClose();
};

export default revertChange;
