import FormFieldCollaborators from "@clearpoint/old-theme/Form/Field/Collaborators";
import FormFieldDisplayCollaborators from "@clearpoint/old-theme/Form/Field/Display/Collaborators";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let Collaborators = ({ editPodFlag, name }) =>
	editPodFlag ? <FormFieldCollaborators name={name} /> : <FormFieldDisplayCollaborators name={name} />;

Collaborators.propTypes = propTypes;
export default Collaborators;
