import FormInput from "../../Form/Input/Input";
import FormSelect from "./FormSelect";
import useFormValue from "../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	allIsNullFlag: PropTypes.bool,
};
let SelectPeriodType = ({ name, periodCount, ...props }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let options = useMemo(
		() => [
			{ value: "all", label: translate("layout.allPeriods") },
			{ value: "cp", label: translate("layout.currentPeriod") },
			{ value: "cy", label: translate("layout.currentCalendarYear") },
			{ value: "cytd", label: translate("layout.calendarYTD") },
			{ value: "fy", label: translate("layout.currentFiscalYear") },
			{ value: "fytd", label: translate("layout.fiscalYTD") },
			{ value: "last", label: translate("layout.lastNPeriods") },
			{ value: "plusminus", label: translate("layout.plusMinusNPeriods") },
			{ value: "next", label: translate("layout.nextNPeriods") },
		],
		[translate]
	);
	return (
		<>
			<FormSelect name={name} options={options} placeholder={translate("edit.chartPeriods") + "..."} {...props} />
			{["last", "plusminus", "next"].includes(value) && (
				<FormInput name={periodCount} label={translate("manage.chart.nPeriods")} />
			)}
		</>
	);
};
SelectPeriodType.propTypes = propTypes;

export default SelectPeriodType;
