import { queryClient } from "@clearpoint/query";
import { toast } from "@clearpoint/services/toastService/index";
import { getEnvironment } from "@clearpoint/utils";
import * as Sentry from "@sentry/react";
import { createQueryKeyFromId } from "./createQueryKeyFromId";

let handleCatch = ({
	dispatch,
	e,
	http,
	id,
	makeRequest,
	method,
	payload,
	request,
	retry,
	setState,
	storeKey,
	url,
}) => {
	if (http.isCancel(e) || e?.message === "Request canceled during httpService throttle") {
		setState({ completedRequests: (x) => [...x, { id: storeKey, request }] });
		return;
	}
	let { retryRequest } = retry({ error: e, makeRequest, url });
	if (retryRequest) return retryRequest;
	if (!url.includes("profile") && ["dev", "labs", "localdev", "stage"].includes(getEnvironment()))
		toast.error(`HIDDEN IN PRODUCTION (DEBUG): ${method} ${url}`, {
			toastId: "apiError",
		});
	if (
		e?.error === "Authorization Header Required" ||
		e?.message === "Element not found" ||
		// eslint-disable-next-line eqeqeq
		e?.code == 403
	) {
		// ignore
	} else if (e?.error === "Invalid Credentials") {
		document.dispatchEvent(new CustomEvent("storeEncounteredInvalidCredentials"));
	} else {
		console.warn(JSON.stringify(e, 0, 3));
		console.warn(JSON.stringify({ method, url, payload }, 0, 3));
		Sentry.captureException(
			e?.error ? new Error(`${e?.status}: ${e.error}`) : e?.message ? new Error(`${e.code} : ${e.message}`) : e
		); // send error to Sentry without halting UI
	}
	let storeValue = { storeKey, url, ...id, data: [] };
	let queryKey = createQueryKeyFromId(id);
	queryClient.setQueryData(queryKey, []);
	dispatch(storeValue);
	setState({ completedRequests: (x) => [...x, { id: storeKey, request }] });
};

export default handleCatch;
