import ActionItemEditModalWindow from "@components/Elements/ActionItems/ManageActionItems/EditActionItemModal";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import DropdownItemEdit from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Edit";
import InitiativeEditModalWindow from "@components/Elements/Initiatives/ManageInitiatives/EditInitiativeModalWindow";
import MeasureEditModalWindow from "@components/Elements/Measures/ManageMeasures/EditMeasureModal";
import MilestoneEditModalWindow from "@components/Elements/Initiatives/ManageInitiatives/EditInitiativeModalWindow/MilestonesTab/EditMilestoneModal";
import ModalWindowLinkMapOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.LinkMap.OnClick";
import ObjectiveEditModalWindow from "@components/Elements/Objectives/ManageObjectives/EditObjectiveModal";
import RiskEditModalWindow from "@components/Elements/Risks/ManageRisks/EditRiskModal";
import ScorecardEditModalWindow from "@components/Elements/Scorecards/ManageScorecards/EditScorecardModal";
import { useTranslate } from "@clearpoint/translate";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import { useBuildLink, useUserType } from "@clearpoint/hooks";

let propTypes = {
	editAccessFlag: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number,
	scorecardId: PropTypes.number,
};
let ContextMenu = ({ editAccessFlag, object, objectId, scorecardId }) => {
	let translate = useTranslate();
	let buildLink = useBuildLink();
	let userType = useUserType();
	let { periodId } = useOldSession().session;
	let viewLinkMapFlag = editAccessFlag && userType === "Administrator";
	let EditModalLookup = {
		actionItem: ActionItemEditModalWindow,
		initiative: InitiativeEditModalWindow,
		measure: MeasureEditModalWindow,
		milestone: MilestoneEditModalWindow,
		objective: ObjectiveEditModalWindow,
		risk: RiskEditModalWindow,
		scorecard: ScorecardEditModalWindow,
	};
	let EditModalWindow = EditModalLookup[object];
	return (
		<>
			{viewLinkMapFlag && (
				<ModalWindowLinkMapOnClick object={object} objectId={objectId} periodId={periodId}>
					<DropdownItem icon="linkMap" iconSize="small">
						{translate("edit.map.viewLinkMap")}
					</DropdownItem>
				</ModalWindowLinkMapOnClick>
			)}
			{editAccessFlag && <DropdownItemEdit ModalWindow={EditModalWindow} objectId={objectId} object={object} />}
			<DropdownItem
				href={buildLink({ object, objectId, scorecardId })}
				icon="link"
				iconSize="small"
				target={`_${object}${objectId}`}
			>
				{translate("tooltips.openElement")}
			</DropdownItem>
		</>
	);
};
ContextMenu.propTypes = propTypes;
export default ContextMenu;
