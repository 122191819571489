import Block from "@clearpoint/old-theme/Block";
import FormFieldDisplayTotalDays from "@clearpoint/old-theme/Form/Field/Display/TotalDays";

let TotalDays = () => (
	<Block display="flex" justifyContent="flex-end">
		<FormFieldDisplayTotalDays name="updates.calculatedValues.totalDays" />
	</Block>
);

export default TotalDays;
