import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useCheckFeature } from "@clearpoint/hooks";


let propTypes = {
	includePageHTMLFlag: PropTypes.bool,
};

let SelectCustomFieldType = ({ includePageHTMLFlag, ...props }) => {
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let { get } = useOldQueryStore();
	let options = get({ object: "options" });
	// TO DO: check if this logic is necessary or taken care of api side
	let customFieldTypeOptions = useMemo(() => {
		if (options === undefined) return;
		let customFieldTypeList = options ? options.fieldTypes : [];
		if (!checkFeature("dataHTML")) {
			customFieldTypeList = customFieldTypeList.filter((x) => x.plan === "basic");
		}
		if (!includePageHTMLFlag) customFieldTypeList = customFieldTypeList.filter((x) => x.fieldType !== "pagehtml");
		return customFieldTypeList.map((x) => ({
			label: translate(`customFields.${x.fieldType}`),
			value: x.fieldType,
		}));
	}, [checkFeature, includePageHTMLFlag, options, translate]);

	return <Select options={customFieldTypeOptions} loadingFlag={!options} {...props} />;
};

SelectCustomFieldType.propTypes = propTypes;

export default SelectCustomFieldType;
