import Block from "../../../Block";
import Button from "../../../Button/Button";
import Collapse from "../../../Collapse/Collapse";
import Column from "../../../Column";
import FormSelectFieldByObjectId from "../../../Form/Select/Select.FieldByObjectId";
import FormSelectIndex from "../../../Form/Select/SelectIndex";
import FormSelectInitiative from "../../../Form/Select/Select.Initiative";
import FormSelectScorecard from "../../../Form/Select/Select.Scorecard";
import FormSelectTokenType from "../../../Form/Select/Select.TokenType";
import Row from "../../../Row";
import StyleWrapper from "../../../StyleWrapper";
import { useCallback, useEffect } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import useInsertText from "./useInsertText";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import buildToken from "./utils/buildToken";

import { theme } from "../../../Theme";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";
import { useId } from "@clearpoint/hooks";

let propTypes = {
	editorRef: PropTypes.object,
	id: PropTypes.number,
	offset: PropTypes.number,
	tinyMceFlag: PropTypes.bool,
};

let InsertToken = ({ editorRef, id: textEditorId, offset, tinyMceFlag }) => {
	// TO DO: Popper?
	let insertText = useInsertText(editorRef, textEditorId, tinyMceFlag, offset);
	let { session } = useOldSession();
	let defaultScorecardId = session.scorecardId;
	let translate = useTranslate();
	let id = useId();
	let { setFormValue } = useFormContext();
	let formValue = useFormValue();
	let scorecardId = formValue[`_scorecardId-${id}`];
	let tokenType = formValue[`_tokenType-${id}`];
	let objectId = formValue[`_objectId-${id}`];
	let fieldId = formValue[`_fieldId-${id}`];
	let formToken = formValue[`_token-${id}`];
	let initiativeId = formValue[`_initiativeId-${id}`];
	let measureId = formValue[`_${tokenType}${objectId}measureId`];
	let SelectObject =
		scorecardId &&
		tokenType &&
		tokenType !== "scorecard" &&
		(tokenType !== "milestone" || initiativeId) &&
		FormSelectIndex[capitalizeFirstLetter(tokenType)];
	let selectFieldFlag =
		scorecardId &&
		tokenType &&
		(tokenType === "scorecard" || objectId) &&
		!tokenType.endsWith("Layout") &&
		tokenType !== "map";
	useEffect(() => {
		// set token
		let token = buildToken({ object: tokenType, objectId, fieldId, scorecardId, measureId });
		if (token !== formToken) {
			setFormValue(`_token-${id}`, token);
		}
	}, [fieldId, formToken, id, measureId, objectId, scorecardId, setFormValue, tokenType]);
	let insertToken = useCallback(() => {
		insertText(formToken);
	}, [formToken, insertText]);
	return (
		<StyleWrapper marginTop={theme.mediumSpace}>
			<Collapse
				title={
					<>
						<Block flex={1}>{translate("customField.insertTokenText")}</Block>
						<Block fontWeight="normal" fontSize="11px" color="muted" marginRight={theme.smallSpace}>
							{formToken}
						</Block>
					</>
				}
			>
				<Row>
					<Column large={6}>
						<FormSelectScorecard
							showAllScorecardsFlag
							defaultValue={defaultScorecardId}
							name={`_scorecardId-${id}`}
							label={`${translate("global.select")} ${translate("scorecard")}`}
						/>
					</Column>
					<Column large={6}>
						<FormSelectTokenType name={`_tokenType-${id}`} />
					</Column>
					{tokenType === "milestone" && (
						<Column large={6}>
							<FormSelectInitiative
								label={translate("initiative")}
								name={`_initiativeId-${id}`}
								scorecardId={scorecardId}
								unmountClearFlag
							/>
						</Column>
					)}
					{SelectObject && (
						<Column large={6}>
							<SelectObject
								label={
									tokenType.endsWith("Layout")
										? `${translate(tokenType.slice(0, -6))} ${translate("global.report")}`
										: translate(tokenType)
								}
								name={`_objectId-${id}`}
								scorecardId={scorecardId}
								parent={tokenType === "milestone" ? "initiative" : undefined}
								parentId={tokenType === "milestone" ? initiativeId : undefined}
								unmountClearFlag
							/>
						</Column>
					)}
					{selectFieldFlag && (
						<Column large={6}>
							<FormSelectFieldByObjectId
								name={`_fieldId-${id}`}
								object={tokenType}
								objectId={objectId || (tokenType === "scorecard" && scorecardId)}
								unmountClearFlag
							/>
						</Column>
					)}
				</Row>
				<Block textAlign="right">
					<Button color="primary" size="small" disabled={!formToken} onClick={insertToken}>
						{translate("customField.insertToken")}
					</Button>
				</Block>
			</Collapse>
		</StyleWrapper>
	);
};
InsertToken.propTypes = propTypes;
export default InsertToken;
