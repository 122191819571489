import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useCallback } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useTranslate } from "@clearpoint/translate";

let AlignTopButton = () => {
	let { checklist } = useChecklist();
	let { setFormValue } = useFormContext();
	let translate = useTranslate();
	let alignTop = useCallback(
		() =>
			setFormValue("elements", (elementList) => {
				if (elementList) {
					let checkedElementList = elementList.filter((x) =>
						checklist.some((check) => check.checked && check.id === `${x.object},${x.objectId}`)
					);
					let newYPos = Math.min(...checkedElementList.map((x) => x.yPos));
					return elementList.map((x) => (checkedElementList.includes(x) ? { ...x, yPos: newYPos } : x));
				}
			}),
		[checklist, setFormValue]
	);
	let disabledFlag = !checklist.some((x) => x.checked);
	return (
		<IconButton
			color="default"
			data-testid="align-top-button"
			disabled={disabledFlag}
			iconTransform="up-4"
			name="alignTop"
			onClick={alignTop}
			rotation="90deg"
			title={disabledFlag ? translate("edit.map.noElements") : undefined}
		/>
	);
};

export default AlignTopButton;
