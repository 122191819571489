import PropTypes from "prop-types";
import ReactTooltip from "react-bootstrap/Tooltip";

import { emptyObject, formatNumber } from "@clearpoint/utils";

import Badge from "../../Badge/Badge";
import Block from "../../Block";
import Icon from "../../Icon/Icon";
import PercentCompletePie from "../../PercentCompletePie";
import StyleWrapper from "../../StyleWrapper";
import { theme } from "../../Theme";

let propTypes = {
	objectData: PropTypes.shape({
		name: PropTypes.string,
		object: PropTypes.string,
		scorecard: PropTypes.string,
		statusColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	showChildCountFlag: PropTypes.bool,
	showCompletedBadgeFlag: PropTypes.bool,
	showSeriesFlag: PropTypes.bool,
	showSeriesPreviewButtonFlag: PropTypes.bool,
	showPercentPieFlag: PropTypes.bool,
	showScoreBadgeFlag: PropTypes.bool,
	translate: PropTypes.func,
};

let ObjectDisplay = ({
	objectData,
	showChildCountFlag,
	showCompletedBadgeFlag,
	showSeriesFlag,
	showSeriesPreviewButtonFlag,
	showPercentPieFlag,
	showScoreBadgeFlag,
	translate,
}) => {
	let {
		_directSubordinates: childCount,
		calculation,
		measureData,
		measureId,
		name,
		numberFormat: calcNumberFormat,
		percentComplete,
		score,
		statusColor,
		value: calcValue,
	} = objectData || emptyObject;

	let displaySeries = measureData && measureData[0];
	let { value, numberFormat, name: seriesName } = displaySeries || emptyObject;

	return (
		<>
			{/* Percent Complete Pie Display */}
			{!showCompletedBadgeFlag && showPercentPieFlag && (
				<Block display="flex" width="95%" height="95%" position="relative" paddingTop="5%">
					<PercentCompletePie size="100%" percentComplete={percentComplete} />
					<Block
						position="absolute"
						top="56%"
						left="50.5%"
						transform="translate(-50%, -50%)"
						fontSize="12px"
						fontWeight="800"
					>
						{percentComplete + "%"}
					</Block>
				</Block>
			)}

			{/* Completed Badge Display */}
			{showCompletedBadgeFlag && (
				<StyleWrapper fontSize="14px !important">
					<Badge color="brightBlue" padding={theme.tinySpace}>
						<Block color="inherit" fontWeight="500" display="flex" justifyContent="center" alignItems="center">
							<Icon name="completed" />
							<Block paddingLeft={theme.tinySpace}>{translate && translate("logFieldLabels.completed")}</Block>
						</Block>
					</Badge>
				</StyleWrapper>
			)}

			{/* Score Badge Display */}
			{showScoreBadgeFlag && (
				<StyleWrapper fontSize="14px !important">
					<Badge color="info" padding={theme.tinySpace}>
						<Block color="inherit" fontWeight="500" display="flex" justifyContent="center" alignItems="center">
							<Block paddingLeft={theme.tinySpace}>
								{translate && translate("components.score")}: {score}
							</Block>
						</Block>
					</Badge>
				</StyleWrapper>
			)}

			{/* Child Count Display */}
			{showChildCountFlag && (
				<Block fontWeight="600" fontSize="30px" borderRadius="100%">
					{childCount}
				</Block>
			)}

			{/* Series Display */}
			{showSeriesFlag && (
				<Block>
					<Block fontWeight="800" fontSize="22px" padding={`0 ${theme.tinySpace}`} color={statusColor}>
						{formatNumber({ number: value, numberFormat })}
					</Block>
					<Block>{seriesName}</Block>
				</Block>
			)}

			{calcNumberFormat && (
				<Block
					fontWeight="800"
					fontSize="22px"
					padding={`0 ${theme.tinySpace}`}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<div>{formatNumber({ number: calcValue, numberFormat: calcNumberFormat })}</div>
				</Block>
			)}

			{/* Series Preview Button */}

			{showSeriesPreviewButtonFlag && (
				<div data-name={name} data-calculation={calculation} data-measureid={measureId} id="linkChartPreviewBtn">
					<Block
						$style="&:hover #tooltip-top { display: flex }; & .tooltip .arrow { height: 0.5rem }"
						cursor="pointer"
						marginLeft={theme.smallSpace}
						position="absolute"
						right={theme.smallSpace}
						bottom={theme.smallSpace}
						border="1px solid lightgray"
						padding={theme.tinySpace}
						borderRadius={theme.tinySpace}
					>
						<Block padding={theme.microSpace}>
							<StyleWrapper
								zIndex="9999"
								position="absolute"
								bottom="100%"
								display="none"
								alignItems="center"
								flexDirection="column"
								left="50%"
								transform="translate(-50%, 0)"
								fontSize="10px"
								opacity="100%"
								whiteSpace="nowrap"
							>
								<ReactTooltip className="tooltip-success" id="tooltip-top" placement="top">
									{translate("edit.map.viewCalculation")}
								</ReactTooltip>
							</StyleWrapper>
							<StyleWrapper color="grey">
								<Icon name="calculatedSimple" size="medium" />
							</StyleWrapper>
						</Block>
					</Block>
				</div>
			)}
		</>
	);
};

ObjectDisplay.propTypes = propTypes;

export default ObjectDisplay;
