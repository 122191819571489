import { useCallback } from "react";
import debounce from "lodash/debounce";

import { useFormContext } from "../Form/DefaultForm";

let useOnTaskUpdate = () => {
	let formContext = useFormContext();
	let formContextFlag = !!formContext;
	let object = formContext?.formValue?.object;
	let objectId = formContext?.formValue?.objectId;
	let { setFormValue } = formContext || {};

	let onTaskUpdate = useCallback(
		(_id, task) => {
			if (!formContextFlag || !task) return null;
			let { progress: percentComplete, object: taskObject, objectId: taskObjectId } = task;
			if (!percentComplete || object !== taskObject || objectId !== taskObjectId) return;
			let percentCompleteRounded = Math.round(percentComplete * 100);
			setFormValue("updates", (x) => ({ ...x, percentComplete: percentCompleteRounded }));
		},
		[formContextFlag, object, objectId, setFormValue]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	onTaskUpdate = useCallback(debounce(onTaskUpdate, 16), [onTaskUpdate]);
	return onTaskUpdate;
};

export default useOnTaskUpdate;
