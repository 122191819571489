let roundPercentComplete = (percentComplete) => {
	let lengthCutoffForRounding = percentComplete >= 10 ? 5 : 4;
	if (percentComplete.toFixed(3).length > lengthCutoffForRounding) {
		return Math.round(percentComplete * 10) / 10;
	}

	return percentComplete;
};

export default roundPercentComplete;
