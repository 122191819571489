let transformSeriesData = (initialSeriesData, seriesData) => {
	if (!seriesData) return;
	let updatedSeriesData = initialSeriesData.map((cell) => ({ periodId: cell.periodId }));
	initialSeriesData.forEach((cell, i) => {
		if (!!seriesData[i]) {
			let seriesKeyList = Object.keys(seriesData[i]).filter((key) => key.startsWith("series"));
			for (let seriesKey of seriesKeyList) {
				if (cell[seriesKey] !== seriesData[i][seriesKey]) {
					updatedSeriesData[i][seriesKey] = seriesData[i][seriesKey];
				}
			}
			if (cell.statusId !== seriesData[i].statusId) {
				updatedSeriesData[i].status = seriesData[i].statusId;
			}
		}
	});
	updatedSeriesData = updatedSeriesData.filter((cell) => Object.keys(cell).length > 1);
	if (updatedSeriesData.length === 0) return;
	return updatedSeriesData;
};

export default transformSeriesData;
