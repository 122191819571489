let allResultsFilteredFlagCallback = ({ filter, results, filteredResultList, deletedVisible }) => {
	if (!filter || results) {
		return false;
	}

	let activeResultList = filteredResultList?.filter?.(
		(x) => x.active || !Object.keys(x).includes("active") || deletedVisible
	);
	let visibleResultList = filteredResultList?.filter?.((x) => x.visible === undefined || x.visible);

	if (!activeResultList || !visibleResultList) {
		return false;
	}

	if (activeResultList.length > 0 && visibleResultList.length === 0) {
		return true;
	}
	return false;
};

export default allResultsFilteredFlagCallback;
