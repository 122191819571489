import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "@clearpoint/utils";

let propTypes = {
	index: PropTypes.number,
	periodData: PropTypes.shape({
		periodId: PropTypes.number,
	}),
	series: PropTypes.shape({
		calculated: PropTypes.bool,
		measureId: PropTypes.number,
		measureSeriesId: PropTypes.number,
		numberFormat: PropTypes.object,
		object: PropTypes.string,
		objectId: PropTypes.number,
		seriesData: PropTypes.string,
		seriesDataType: PropTypes.string,
	}),
};

let SeriesCell = ({ index, periodData, series }) => {
	let {
		calculated: calculatedFlag,
		measureId,
		numberFormat,
		object,
		objectId,
		seriesData: seriesKey,
		seriesDataType,
	} = series;
	let { periodId } = periodData;
	let seriesValueInPeriod = periodData[seriesKey];

	let trimRedSpan = useCallback((value) => {
		if (value.toString().startsWith("<span style='color:red'>")) {
			return value.slice(24, -7);
		}
		return value;
	}, []);

	let seriesValueInPeriodDisplay =
		seriesValueInPeriod === null
			? ""
			: isNaN(seriesValueInPeriod)
			? seriesValueInPeriod
			: trimRedSpan(formatNumber({ number: seriesValueInPeriod, numberFormat }));

	let style = useMemo(() => {
		let redNegativeFlag = formatNumber({ number: seriesValueInPeriod, numberFormat }).includes?.(
			"<span style='color:red'>"
		);
		return {
			color: redNegativeFlag ? "#FF0000" : "",
		};
	}, [numberFormat, seriesValueInPeriod]);

	return (
		<td
			data-col-index={index + 2}
			className="cps-td"
			data-object={object}
			data-object-id={objectId}
			data-period-id={periodId}
			data-measure-id={measureId}
			data-value={seriesValueInPeriod}
			data-field-id="value"
			data-field-type="number"
			data-number-format={JSON.stringify(numberFormat)}
			data-format={seriesDataType}
			data-calculated={calculatedFlag}
			data-update-mode={true}
			style={style}
		>
			<span>{seriesValueInPeriodDisplay}</span>
		</td>
	);
};

SeriesCell.propTypes = propTypes;
export default SeriesCell;
