let handleLinkChartResize = (chartRef, container) => {
	try {
		container = container || document?.getElementById?.("linkChartContainer");

		if (!container || !chartRef.current) return;
		let containerRect = container.getBoundingClientRect();
		if (!containerRect.height || !containerRect.width) return;
		chartRef.current.svgHeight(containerRect.height).svgWidth(containerRect.width).render();
	} catch (e) {
		return null;
	}
};

export default handleLinkChartResize;
