import { UnControlled as CodeMirror } from "react-codemirror2";
import StyleWrapper from "../../../StyleWrapper";
import { useCallback, useMemo } from "react";
import useFormValue from "../../../Form/hooks/useFormValue";
import useSetValue from "./useSetValue";
import codeMirrorOptions from "./codeMirrorOptions";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let HTMLEditor = ({ name }) => {
	let setValue = useSetValue(name);
	let value = useFormValue(name);
	let displayValue = useMemo(() => (value ? value.replace(/<style>[\s\S]*?<\/style>/g, "") : ""), [value]);

	let refreshEditor = useCallback((editor) => {
		setTimeout(() => {
			editor.refresh();
			editor.focus();
		}, 500);
	}, []);
	return (
		<StyleWrapper height="100%" $style=".CodeMirror { height: 100%; }">
			<CodeMirror
				detach
				editorDidMount={refreshEditor}
				onChange={setValue}
				options={codeMirrorOptions}
				value={displayValue}
			/>
		</StyleWrapper>
	);
};

HTMLEditor.propTypes = propTypes;

export default HTMLEditor;
