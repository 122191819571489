import Badge from "@clearpoint/old-theme/Badge/Badge";
import Hide from "@clearpoint/old-theme/Hide";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	visible: PropTypes.bool,
};

let ToolbarBadge = ({ children, color, visible }) => (
	<Hide margin="0" visible={visible}>
		<Badge color={color} display="inline" marginLeft={theme.smallSpace} size="small">
			{children}
		</Badge>
	</Hide>
);

ToolbarBadge.propTypes = propTypes;
export default ToolbarBadge;
