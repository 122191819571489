import Block from "../../Block";
import ClickToActivate from "./ClickToActivate";
import HTML from "../../HTML";
import Loading from "../../Loading";
import TinyMceEditor from "./TinyMce";
import Wrapper from "./Wrapper";
import React, { Suspense, useState } from "react";
import useStyleValue from "./useStyleValue";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let TextEditorContent = React.lazy(() => import("@clearpoint/old-theme/Input/TextEditor/Content/TextEditorContent"));

let propTypes = {
	cellFlag: PropTypes.bool,
	clickToActivateFlag: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	noFormGroupFlag: PropTypes.bool,
	simpleFlag: PropTypes.bool,
	tinyMceFlag: PropTypes.bool,
};

let TextEditor = ({
	cellFlag,
	clickToActivateFlag,
	disabled,
	label,
	name,
	noFormGroupFlag,
	simpleFlag,
	tinyMceFlag: componentTinyMceFlag,
	...props
}) => {
	let { styleClass, styleValue } = useStyleValue(name);
	let [tinyMceFlag, setTinyMceFlag] = useState(true);
	return (
		<Wrapper
			label={label}
			disabled={disabled}
			tinyMceFlag={tinyMceFlag}
			setTinyMceFlag={setTinyMceFlag}
			noFormGroupFlag={noFormGroupFlag}
		>
			<Block data-testid={`${name}-text-editor`} backgroundColor={theme.white} paddingTop={theme.tinySpace}>
				<HTML skipStyleScopingFlag>{styleValue}</HTML>
				{clickToActivateFlag || disabled ? (
					<ClickToActivate
						name={name}
						styleClass={styleClass}
						tinyMceFlag={tinyMceFlag}
						disabled={disabled}
						{...props}
					/>
				) : (
					<Suspense fallback={<Loading />}>
						{tinyMceFlag ? (
							<TinyMceEditor
								cellFlag={cellFlag}
								name={name}
								styleClass={styleClass}
								simpleFlag={simpleFlag}
								{...props}
							/>
						) : (
							<TextEditorContent name={name} styleClass={styleClass} simpleFlag={simpleFlag} {...props} />
						)}
					</Suspense>
				)}
			</Block>
		</Wrapper>
	);
};

TextEditor.propTypes = propTypes;

export default TextEditor;
