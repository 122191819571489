import { useCallback } from "react";
import getDeepValue from "lodash/get";

import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import ConfirmOnClick from "@clearpoint/old-theme/Modal/Modal.Confirm.OnClick";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let KeyResultListItemHeader = () => {
	let translate = useTranslate();
	let { setFormValue, formValue } = useFormContext();
	let { keyResultNameInForm } = useLocalState();
	let keyResultFormValue = getDeepValue(formValue, keyResultNameInForm);
	let { deletedVisible } = useFilter();

	let { active: activeFlag, name } = useFormValue(keyResultNameInForm) || emptyObject;

	let deleteKeyResult = useCallback(() => {
		setFormValue(`${keyResultNameInForm}.active`, false);
	}, [keyResultNameInForm, setFormValue]);

	let undeleteKeyResult = useCallback(() => {
		setFormValue(`${keyResultNameInForm}.active`, true);
	}, [keyResultNameInForm, setFormValue]);

	return (
		<Block
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			backgroundColor="#FAFAFB"
			border="none"
			borderRadius="0.5rem 0.5rem 0 0"
			borderTop={`2px solid ${theme.lightGray}`}
			padding={`${theme.smallSpace} ${theme.space}`}
			minHeight="48px"
			wordBreak="normal"
			overflow="hidden"
		>
			<Block display="flex" flexGrow="1" flexShrink="1" minWidth="0">
				{!deletedVisible && (
					<Block
						className="listitem-handle"
						cursor="grab"
						margin={`0 -${theme.tinySpace} !important }`}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Icon name="drag" size="large" marginRight={theme.smallSpace} />
					</Block>
				)}
				<FormInput
					name={`${keyResultNameInForm}.name`}
					placeholder={translate("goals.keyResults.placeholder.name")}
					maxLength={200}
					marginBottom="0"
					required={keyResultFormValue?.active}
					flexGrowFlag
					errorTextNotVisible
					autoFocus={!name}
				/>
			</Block>
			<Block flex="0 0 auto" display="flex" justifyContent="flex-end" marginLeft="12px">
				{activeFlag ? (
					<ConfirmOnClick
						onConfirm={deleteKeyResult}
						confirmButtonColor="danger"
						confirmButtonText={translate("global.delete")}
						body={translate("edit.element.deleteCategoryText", { category: translate("keyResult") })}
						title={translate("edit.deleteElement", { element: "keyResult" })}
					>
						<Button onClick={deleteKeyResult} color="white" padding="3px 6px" size="small">
							<Icon name="delete" transform="up-1" />
						</Button>
					</ConfirmOnClick>
				) : (
					<Button onClick={undeleteKeyResult} color="white" padding="3px 6px" size="small">
						<Icon name="undelete" transform="up-1 right-1" />
					</Button>
				)}
			</Block>
		</Block>
	);
};

export default KeyResultListItemHeader;
