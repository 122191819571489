let calculateKeyResultPercentComplete = (startValue, targetValue, currentValue) => {
	if (startValue > targetValue) {
		startValue *= -1;
		targetValue *= -1;
		currentValue *= -1;
	}
	let max = Math.max(startValue, targetValue);
	let min = Math.min(startValue, targetValue);
	let range = max - min;
	if (range === 0) return 100;
	return ((currentValue - min) * 100) / range;
};

export default calculateKeyResultPercentComplete;
