import Select from "./FormSelect";
import SelectCurrency from "./Select.Currency";
import SelectNumberFormat from "./Select.NumberFormat";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getDeepValue from "lodash/get";
import isArray from "lodash/isArray";
import { useCheckFeature } from "@clearpoint/hooks";


let propTypes = {
	currency: PropTypes.string,
	defaultFlag: PropTypes.bool,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	numberFormat: PropTypes.string,
};

let FormSelectSeriesDataType = ({ currency, defaultFlag, defaultValue, name, numberFormat, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { formDefaultValue } = useFormContext();
	let seriesDataTypeId = useFormValue(name);
	let seriesDataTypeList = get({ object: "seriesDataType" });
	let numberFormatList = get({ object: "numberFormat" });
	let checkFeature = useCheckFeature();
	let customNumberFormatsFlag = checkFeature("numberFormats");
	let numberFormatFlag =
		isArray(numberFormatList) && numberFormatList.filter((x) => x.active).length > 0 && customNumberFormatsFlag;
	if (defaultValue === undefined && getDeepValue(formDefaultValue, name) === undefined)
		defaultValue = defaultFlag ? 0 : 4;
	let selectOptions = useMemo(() => {
		if (seriesDataTypeList === undefined || numberFormatList === undefined) return;
		if (!seriesDataTypeList) return [];
		let selectOptions = seriesDataTypeList
			?.filter(
				(x) =>
					![10, 11].includes(x.seriesDataTypeId) ||
					(x.seriesDataTypeId === 10 && numberFormatFlag) ||
					(x.seriesDataTypeId === 11 && customNumberFormatsFlag)
			)
			.map((x) => ({
				label: translate("seriesTypes.seriesType" + x.seriesDataTypeId),
				value: x.seriesDataTypeId,
			}));
		if (defaultFlag) {
			selectOptions.unshift({ label: translate("global.default"), value: 0 });
		}
		return selectOptions;
	}, [customNumberFormatsFlag, defaultFlag, numberFormatFlag, numberFormatList, seriesDataTypeList, translate]);
	return (
		<>
			<Select
				options={selectOptions || []}
				loadingFlag={!selectOptions}
				defaultValue={defaultValue}
				name={name}
				placeholder={translate("select.selectSeriesType") + "..."}
				{...props}
			/>
			{currency && seriesDataTypeId === 4 && (
				<SelectCurrency label={translate("options.admin.series.currency")} name={currency} />
			)}
			{numberFormat && seriesDataTypeId === 10 && (
				<SelectNumberFormat
					label={translate("options.admin.series.customFormat")}
					name={numberFormat}
					activeOnlyFlag
					required
				/>
			)}
		</>
	);
};

FormSelectSeriesDataType.propTypes = propTypes;

export default FormSelectSeriesDataType;
