import FormCustomFieldDisplayGrid from "@clearpoint/old-theme/Form/CustomField/Display/Grid";
import HeaderButtons from "./HeaderButtons";
import Pod from "@clearpoint/old-theme/Pod/Pod";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import ViewGrid from "@components/Layouts/ViewLayout/ViewReport";
import { useCallback, useMemo } from "react";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePodContext } from "../PodProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	overflowX: PropTypes.string,
	podStyle: PropTypes.object,
};

let GridReportPod = ({ className, marginTop, overflowX, podStyle }) => {
	let translate = useTranslate();
	let { setLocalState } = useLocalState();
	let { editPodFlag, fieldId, hideBorderFlag, openEditMode, title, updateModeFlag } = usePodContext();
	let { layout } = useDetailPageData();
	let areaList = layout?.layout;

	let nameInForm = updateModeFlag ? `updates[${fieldId}]` : fieldId;

	let everyPodFieldIdOnPageExceptThisOne = useMemo(
		() =>
			areaList
				?.map((area) => area.pods.map((pod) => pod.fieldId))
				?.flat()
				.filter((x) => x !== fieldId),
		[areaList, fieldId]
	);

	let openGridEditMode = useCallback(() => {
		setLocalState({ lockedPodList: everyPodFieldIdOnPageExceptThisOne });
		openEditMode();
	}, [openEditMode, everyPodFieldIdOnPageExceptThisOne, setLocalState]);

	return (
		<Pod
			className={className}
			headerText={translate(title)}
			headerRightContent={<HeaderButtons editPodFlag={editPodFlag} nameInForm={nameInForm} />}
			hideBorderFlag={hideBorderFlag}
			marginTop={marginTop}
			onDoubleClick={openGridEditMode}
			overflowX={overflowX}
			podStyle={podStyle}
		>
			<StyleWrapper marginBottom="0px !important" paddingBottom="0px !important">
				<FormCustomFieldDisplayGrid ViewGrid={ViewGrid} name={nameInForm + ".value"} gridPodFlag />
			</StyleWrapper>
		</Pod>
	);
};

GridReportPod.propTypes = propTypes;
export default GridReportPod;
