import Block from "@clearpoint/old-theme/Block";
import ChartPreview from "@clearpoint/old-theme/Chart/Chart.Preview";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";
import { useQueryParameters } from "@clearpoint/hooks";

let Chart = () => {
	let { get } = useOldQueryStore();
	let { objectId, periodId } = useOldSession().session;
	let { smallChartFlag, showDescriptionFlag } = useQueryParameters();
	let { altText } = (objectId && periodId && get({ object: "chart", objectId, periodId })) || emptyObject;

	showDescriptionFlag = showDescriptionFlag && altText;
	let height = smallChartFlag ? 300 : 600;
	let width = smallChartFlag ? 400 : 800;
	return objectId ? (
		<Block id="chart" data-testid="chart" width={`${width}px`}>
			<ChartPreview chartId={objectId} chartHeight={height} noExportButtonFlag />
			{showDescriptionFlag ? (
				<Block height="92px" overflow="hidden !important">
					{altText}
				</Block>
			) : null}
		</Block>
	) : null;
};
export default Chart;
