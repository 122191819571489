import { chartPeriodListContainsPeriod, getChartPeriodName, sortSeries } from "../utils";

let createTransposedSeriesList = ({ seriesList, measureGridList, chartPeriodList, colorList, sortDirection }) => {
	let transposedSeries = measureGridList
		.filter((period) => chartPeriodListContainsPeriod(period, chartPeriodList))
		.map((period, index) => ({
			name: getChartPeriodName(period, chartPeriodList),
			data: seriesList.map((series) => period["series" + series.measureSeriesId] || null),
			color: colorList[index % colorList.length],
			animation: false,
		}));
	if (sortDirection !== "none") {
		transposedSeries = sortSeries(transposedSeries, sortDirection);
	}
	return transposedSeries;
};

export default createTransposedSeriesList;
