import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";

let propTypes = {
	editFlag: PropTypes.bool,
	object: PropTypes.string.isRequired,
};
let Locked = ({ editFlag, object, ...props }) => {
	let translate = useTranslate();
	let label = translate("goals.archived");
	return editFlag ? (
		<RadioBoolean
			label={label}
			trueText={translate("notifications.compare.true")}
			falseText={translate("notifications.compare.false")}
			{...props}
		/>
	) : (
		<Check data-testid="archived" text={label} {...props} label={null} />
	);
};
Locked.propTypes = propTypes;
export default Locked;
