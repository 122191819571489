import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { useScorecardName } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayScorecardId = ({ name, className }) => {
	let scorecardId = useFormValue(name);
	let scorecardName = useScorecardName({ scorecardId });
	return scorecardName ? <span className={className}>{scorecardName}</span> : null;
};
FormFieldDisplayScorecardId.propTypes = propTypes;

export default FormFieldDisplayScorecardId;
