import FormSelect from "../../Form/Select/FormSelect";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getDeepValue from "lodash/get";
import { useEffectOnce } from "@clearpoint/hooks";


let propTypes = {
	scorecardMatch: PropTypes.string.isRequired,
};
let SelectPage = ({ scorecardMatch, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let formValue = useFormValue();
	let { setFormValue } = useFormContext();
	let scorecardId =
		getDeepValue(formValue, scorecardMatch) ||
		(formValue.home?.referrer
			? formValue.home?.referrer?.scorecardId
			: formValue.home?.scorecardId || formValue.scorecardId);
	let menu = scorecardId ? get({ object: "menu", scorecardId }) : undefined;
	let object = formValue.home?.object || formValue.object;
	// format page options
	let pageOptions = useMemo(() => {
		if (menu) {
			let pageOptions = [];
			let menuObject = menu.find((x) => x.object === object);

			if (menuObject?.layouts) {
				menuObject.layouts.forEach((page) => {
					pageOptions.push({
						value: `layout${page.layoutId}`,
						label: translate(page.name),
						group: translate("myProfile.summaryReports"),
					});
				});
			}

			if (menuObject?.objects) {
				menuObject.objects.forEach((page) => {
					if (page.object !== "scorecard") {
						pageOptions.push({
							value: `object${page.objectId}`,
							label: translate(page.name),
							group: translate("myProfile.detailPages"),
						});
					}
				});
			}

			return pageOptions;
		}
	}, [menu, object, translate]);

	// set initial value for constant _homepageValue from form values
	useEffectOnce(() => {
		let valueInForm = formValue.home?.layoutId
			? { value: formValue.home.layoutId, name: "layout" }
			: formValue.home && { value: formValue.home.objectId, name: "object" };
		if (valueInForm?.value && formValue._homepageValue !== valueInForm.value)
			setFormValue("_homepageValue", valueInForm.name + valueInForm.value);
	});

	// when _homepagevalue changes, update the name that corresponds
	// to the new value with that value
	useEffect(() => {
		if (pageOptions && formValue._homepageValue) {
			let value = formValue._homepageValue;
			if (value.includes("layout")) {
				setFormValue("home.layoutId", +value.slice(6));
				setFormValue("home.objectId", undefined);
			} else if (value.includes("object")) {
				setFormValue("home.layoutId", undefined);
				setFormValue("home.objectId", +value.slice(6));
			}
		}
	}, [formValue._homepageValue, pageOptions, setFormValue]);
	return (
		<>
			<FormSelect
				placeholder={translate("myProfile.selectPage")}
				options={pageOptions}
				required
				name="_homepageValue"
				{...props}
			/>
		</>
	);
};
SelectPage.propTypes = propTypes;

export default SelectPage;
