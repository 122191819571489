import Block from "../Block";
import FilterInput from "../Filter/Filter.Input";
import FilterProvider from "@clearpoint/providers/FilterProvider";
import LinkMapContent from "./LinkMapContent";
import LinkChartControls from "../LinkChart/LinkChartControls";
import Loading from "../Loading";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	object: PropTypes.string,
	objectId: PropTypes.number,
	periodId: PropTypes.number,
};

let LinkMapWrapper = ({ object, objectId, periodId }) => {
	let filterPropList = useMemo(() => ["name", "scorecard", "periodGroup", "period"], []);
	let translate = useTranslate();

	let { get } = useOldQueryStore();
	let objectLinkList =
		objectId &&
		periodId &&
		object &&
		get({
			parent: object,
			parentId: objectId,
			object: "link",
			periodId,
			queryString: "?map=1",
			skipSortFlag: true,
		});

	return !objectLinkList ? (
		<Loading />
	) : (
		<FilterProvider data={objectLinkList}>
			<Block
				display="flex"
				flexDirection="column"
				height="100%"
				borderRadius="4px"
				overflow="Hidden"
				paddingRight={theme.microSpace}
			>
				<FilterInput
					overflowFlag
					name="highlight"
					placeholder={translate("edit.element.highlightLinks")}
					filterBy={filterPropList}
					right={<LinkChartControls />}
				/>
				<LinkMapContent />
			</Block>
		</FilterProvider>
	);
};

LinkMapWrapper.propTypes = propTypes;
export default LinkMapWrapper;
