import { useCallback } from "react";

import { useFormatDate } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import generateOnSubmitCallback from "./useStoreFormOnSubmit.generateOnSubmitCallback";
import { useOldSession } from "@clearpoint/old-session/index";

let useStoreFormOnSubmit = ({
	beforeSubmit,
	copyFlag,
	defaultValue,
	duplicateRouteFlag,
	object,
	objectId,
	objectIdList,
	onSubmit,
	parent,
	parentId,
	queryStringOnSubmit,
	scorecardId,
	skipClearFlag,
	submitUnchangedFlag,
	updateFlag,
}) => {
	let {
		session: { periodId: sessionPeriodId },
	} = useOldSession();
	let { clear, getPromise, set } = useOldQueryStore();
	let formatDate = useFormatDate();

	return useCallback(
		async (submitValue, contextValue, skipCloseFlag) => {
			let storeFormOnSubmitCallback = generateOnSubmitCallback({
				beforeSubmit,
				clear,
				copyFlag,
				defaultValue,
				duplicateRouteFlag,
				getPromise,
				object,
				objectId,
				objectIdList,
				formatDate,
				onSubmit,
				parent,
				parentId,
				queryStringOnSubmit,
				scorecardId,
				sessionPeriodId,
				set,
				skipClearFlag,
				submitUnchangedFlag,
				updateFlag,
			});
			let response = await storeFormOnSubmitCallback(submitValue, contextValue, skipCloseFlag);
			return response;
		},
		[
			beforeSubmit,
			clear,
			copyFlag,
			defaultValue,
			duplicateRouteFlag,
			formatDate,
			getPromise,
			object,
			objectId,
			objectIdList,
			onSubmit,
			parent,
			parentId,
			queryStringOnSubmit,
			scorecardId,
			sessionPeriodId,
			set,
			skipClearFlag,
			submitUnchangedFlag,
			updateFlag,
		]
	);
};

export default useStoreFormOnSubmit;

export { default as generateOnSubmitCallback } from "./useStoreFormOnSubmit.generateOnSubmitCallback";
