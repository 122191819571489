import Block from "../../Block";
import Help from "../../Help";
import InputAggregate from "../../Form/Input/Aggregate/InputAggregate";
import InputAggregateStatus from "../../Form/Input/AggregateStatus/InputAggregateStatus";
import InputCalculation from "../../Form/Input/Calculation/InputCalculation";
import InputPeerSeries from "../../Form/Input/PeerSeries/InputPeerSeries";
import InputReferenceSeries from "../../Form/Input/ReferenceSeries/InputReferenceSeries";
import SelectCalculationType from "../../Form/Select/Select.CalculationType";
import { useCallback } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	calculationName: PropTypes.string,
	name: PropTypes.string,
	parentName: PropTypes.string,
	section: PropTypes.string,
	seriesType: PropTypes.string,
};
let SeriesType = ({ calculationName, name, parentName, section, seriesType, ...props }) => {
	let translate = useTranslate();
	let formValue = useFormValue();
	let value = useFormValue(name);
	let { calcError, calcErrorMessage, parent, parentId } = formValue;
	let { setFormValidation } = useFormContext();
	let clearValidation = useCallback(() => {
		if (parentName) {
			setFormValidation(parentName, undefined);
		}
	}, [parentName, setFormValidation]);
	calculationName = calculationName || (parentName ? `${parentName}.calculation` : "calculation");
	return (
		<>
			<SelectCalculationType data-testid="seriesType" onChange={clearValidation} name={name} {...props} />
			<div data-testid="calculation">
				{value === "calculated" && (
					<>
						<Help feature="calculation-template" section={section} />
						<InputCalculation
							name={calculationName}
							seriesType={seriesType}
							testFlag={seriesType !== "currentOrSpecific"}
						/>
					</>
				)}
				{value === "aggregate" && (
					<InputAggregate calcError={calcError} name={parentName} seriesType={seriesType} />
				)}
				{value === "status" && <InputAggregateStatus name={parentName} seriesType={seriesType} />}
				{value === "reference" && (
					<InputReferenceSeries name={parentName} parent={parent || props.parent} parentId={parentId} />
				)}
				{value === "peer" && <InputPeerSeries name={parentName} />}
				{calcError && (
					<Block color={theme.danger} fontSize="0.9em" lineHeight="1.43" padding="2px 0 0" textAlign="left">
						{translate(calcErrorMessage)}
					</Block>
				)}
			</div>
		</>
	);
};
SeriesType.propTypes = propTypes;
export default SeriesType;
