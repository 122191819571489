import Block from "../../../../Block";
import FormModal from "../../../../Form/Modal/Form.Modal";
import InputValue from "./InputValue";
import SelectComparison from "./SelectFilterCompare";
import SelectFilterField from "./SelectFilterField";
import SelectObject from "./SelectObject";
import { useCallback, useEffect } from "react";
import { useFormContext } from "../../../../Form/Form";
import useFormError from "../../../../Form/hooks/useFormError";
import useFormValue from "../../../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useUnmount } from "react-use";
import PropTypes from "prop-types";
import { useFormatDate, useScorecard } from "@clearpoint/hooks";


let propTypes = {
	close: PropTypes.func,
	editFlag: PropTypes.bool,
	filterObjectList: PropTypes.array,
	modalVisible: PropTypes.bool,
	name: PropTypes.string,
	onSave: PropTypes.func,
};

let EditFilterModal = ({ close, filterObjectList, modalVisible, name, onSave }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let filterValue = useFormValue(name);
	let { compare, fieldId, object, value } = filterValue || {};
	let error = useFormError(name);
	let { setFormValue, setFormValidation, clearFormValue } = useFormContext();
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let { scorecardId } = useScorecard();
	useUnmount(() => {
		setFormValidation(name, undefined);
	});
	if (compare) compare = compare.toString();
	let valueFlag = compare && !["true", "false", "null", "notnull"].includes(compare);
	let filterFieldList = get({ object: "filterField", queryString: `?scorecardId=${scorecardId}&object=${object}` });
	let resetCompare = useCallback(() => {
		clearFormValue(`${name}.compare`);
		clearFormValue(`${name}.value`);
	}, [clearFormValue, name]);
	let resetValue = useCallback(() => clearFormValue(`${name}.value`), [clearFormValue, name]);
	useEffect(() => {
		let newValue = {};
		if (compare && (compare.includes("Plus") || compare.includes("Minus"))) {
			newValue._beforeOrAfter = compare.includes("before") ? "before" : "after";
			newValue._plusOrMinus = compare.includes("Plus") ? "plus" : "minus";
			newValue._nowOrPeriod = compare.includes("Now") ? "now" : "period";
			newValue._dayCount = value;
			setFormValue(`${name}.compare`, "range");
			setFormValue(`${name}.value`, newValue);
		}
	}, [compare, name, setFormValue, value]);
	let fixDateValue = useCallback((filterValue) => {
		let { value } = filterValue;
		if (value?._dayCount) {
			let compare = value._beforeOrAfter;
			if (value._plusOrMinus === "plus") compare += "Plus";
			if (value._plusOrMinus === "minus") compare += "Minus";
			if (value._nowOrPeriod === "now") compare += "Now";
			filterValue.value = value._dayCount;
			filterValue.compare = compare;
		}
	}, []);
	let setDescription = useCallback(
		(filterValue) => {
			let { compare, fieldId, object, value } = filterValue;
			let filterField = filterFieldList[object].find((field) => field.fieldId === fieldId);
			let comparison = session.filterCompareOptions.find((option) => option.value === compare).label;
			let description = `${translate(object)} ${translate(filterField.name)} ${comparison} `;
			let findLabel = (array, value) => array.find((x) => x.value === value).label;
			let describeValue = (key, value) => {
				return `"${findLabel(session[key + "Options"], value)}"`;
			};
			let describeArray = (key, array) => array.map((value) => describeValue(key, value)).join(" or ");
			let describeValueLookup = {
				chartType: (value) => describeValue("chartType", value),
				periodGroup: (value) => describeValue("periodGroup", value),
				periodicity: (value) => describeValue("periodGroup", value),
				tags: (array) => describeArray("tag", array),
				status: (array) => describeArray("status", array),
				statusIcon: (array) => describeArray("status", array),
				user: (value) => describeValue("user", value),
				users: (array) => describeArray("user", array),
			};
			if (value) {
				if (filterField.type === "date") {
					if (value._dayCount)
						description = description.replace(
							"Date +/- N Days",
							`${findLabel(session.beforeOrAfterOptions, value._beforeOrAfter)} ${findLabel(
								session.nowOrPeriodOptions,
								value._nowOrPeriod
							)} ${findLabel(session.plusOrMinusOptions, value._plusOrMinus)} ${value._dayCount} Days`
						);
					else description += formatDate(value, "short");
				} else if (describeValueLookup[filterField.type]) {
					description += describeValueLookup[filterField.type](value);
				} else if (value !== undefined) {
					description += `"${value}"`;
				}
			}
			filterValue.description = description.trim();
		},
		[filterFieldList, formatDate, session, translate]
	);
	let beforeSave = useCallback(
		(filterValue) => {
			setDescription(filterValue);
			fixDateValue(filterValue);
			return filterValue;
		},
		[fixDateValue, setDescription]
	);
	return (
		<FormModal
			beforeSave={beforeSave}
			close={close}
			closeButtonText={translate("global.save")}
			modalVisible={modalVisible}
			name={name}
			onSave={onSave}
			size="medium"
			title={translate("layout.editFilter")}
			valid={!error}
		>
			<Block>
				<SelectObject
					defaultFirstOptionFlag
					disabled={filterObjectList?.length === 1}
					filterObjectList={filterObjectList}
					label={translate("global.element")}
					name={`${name}.object`}
					onChange={resetCompare}
					placeholder={translate("select.selectElement") + "..."}
					required
				/>
				<SelectFilterField
					label={translate("layout.field")}
					name={`${name}.fieldId`}
					object={object}
					onChange={resetCompare}
					placeholder={translate("layout.selectField") + "..."}
					required
				/>
				{fieldId && (
					<SelectComparison
						fieldId={fieldId}
						label={translate("layout.comparison")}
						name={`${name}.compare`}
						object={object}
						onChange={resetValue}
						placeholder={translate("layout.selectComparison") + "..."}
						required
					/>
				)}
				{valueFlag && (
					<InputValue
						label={translate("layout.value")}
						name={`${name}.value`}
						compare={compare}
						fieldId={fieldId}
						object={object}
						showEvenWithNoOptionsFlag
						required
					/>
				)}
			</Block>
		</FormModal>
	);
};

EditFilterModal.propTypes = propTypes;

export default EditFilterModal;
