import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};
let ChartSeriesDataLabelSelect = ({ name, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("edit.chartSeries.chartDefault"), value: "" },
			{ label: translate("edit.chartSeries.none"), value: "none" },
			{ label: translate("edit.chartSeries.value"), value: "value" },
			{ label: translate("edit.chartSeries.rowLabel"), value: "rowlabel" },
			{ label: translate("edit.chartSeries.columnLabel"), value: "columnlabel" },
		],
		[translate]
	);
	return (
		<Select placeholder={translate("edit.chart.selectDataLabelStyle")} options={options} name={name} {...props} />
	);
};

ChartSeriesDataLabelSelect.propTypes = propTypes;
export default ChartSeriesDataLabelSelect;
