import Block from "@clearpoint/old-theme/Block";
import ChartPodHeader from "../ChartPod/ChartPodHeader";
import CustomField from "@clearpoint/old-theme/Form/CustomField/index";
import CustomFieldDisplay from "@clearpoint/old-theme/Form/CustomField/Display/index";
import HeaderRight from "../DefaultPod/HeaderRight";
import Pod from "@clearpoint/old-theme/Pod/Pod";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import ViewGrid from "@components/Layouts/ViewLayout/ViewReport";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePodContext } from "../PodProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getDeepValue from "lodash/get";
import { capitalizeFirstLetter, emptyObject } from "@clearpoint/utils";

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	onDoubleClick: PropTypes.func.isRequired,
	overflowX: PropTypes.string,
	podStyle: PropTypes.object,
};

let CustomFieldPod = ({ className, marginTop, onDoubleClick, overflowX, podStyle }) => {
	let translate = useTranslate();
	let { chartId, editPodFlag, fieldId, hideBorderFlag, title, updateModeFlag } = usePodContext();

	let { pagePeriodId } = useLocalState();

	let nameInForm = updateModeFlag ? `updates[${fieldId}]` : fieldId;
	let customField = useFormValue(nameInForm);
	let { customFieldType, picklist } = customField || emptyObject;

	let { formDirty } = useFormContext();
	let dirtyFlag = getDeepValue(formDirty, nameInForm);

	let EditField = customFieldType ? CustomField[capitalizeFirstLetter(customFieldType)] : Block;
	let DisplayField = customFieldType ? CustomFieldDisplay[capitalizeFirstLetter(customFieldType)] : Block;

	let chartCustomFieldFlag = chartId !== undefined;
	return (
		<Pod
			className={className}
			HeaderLeftElement={chartCustomFieldFlag ? ChartPodHeader : undefined}
			headerRightContent={<HeaderRight fieldId={fieldId} updateModeFlag={updateModeFlag} />}
			headerText={translate(title)}
			hideBorderFlag={hideBorderFlag}
			marginTop={marginTop}
			onDoubleClick={onDoubleClick}
			overflowX={overflowX}
			podStyle={podStyle}
		>
			{editPodFlag ? (
				<StyleWrapper marginBottom="0px">
					<EditField
						clickToActivateFlag={false}
						fieldId={fieldId}
						name={nameInForm + ".value"}
						picklist={picklist}
					/>
				</StyleWrapper>
			) : (
				<StyleWrapper marginBottom="0px !important" paddingBottom="0px !important">
					<DisplayField
						detailPageFlag
						fieldId={fieldId}
						name={nameInForm + ".value"}
						dirtyFlag={dirtyFlag}
						objectData={customField}
						periodId={pagePeriodId}
						ViewGrid={ViewGrid}
					/>
				</StyleWrapper>
			)}
		</Pod>
	);
};

CustomFieldPod.propTypes = propTypes;
export default CustomFieldPod;
