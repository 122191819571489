import Dropdown from "react-bootstrap/Dropdown";
import Icon from "../../Icon/Icon";
import KebabMenu from "./Kebab.Menu";
import React from "react";
import StyleWrapper from "../../StyleWrapper";
import PropTypes from "prop-types";
import { theme } from "../../Theme";
import { toast } from "@clearpoint/services/toastService/index";

const CustomToggle = React.forwardRef(({ color, disabled, disabledWarningMessage, onClick }, ref) => (
	<StyleWrapper
		backgroundColor={theme.white}
		border={`1px solid ${theme.lightGray}`}
		borderRadius={theme.borderRadius}
		$style={theme.shadowCss}
		padding="3px 6px"
		overflow="visible"
		cursor={disabled ? "not-allowed !important" : "pointer"}
	>
		<button
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				if (disabled && disabledWarningMessage) {
					toast.warning(disabledWarningMessage);
					return;
				} else if (disabled) {
					return;
				}
				if (e.screenX === 0 && e.screenY === 0) {
					return;
				}
				onClick(e);
			}}
		>
			<Icon name="kebab" color={color} />
		</button>
	</StyleWrapper>
));

let propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	disabledWarningMessage: PropTypes.string,
	drop: PropTypes.string,
	hideDropdownFlag: PropTypes.bool,
	icon: PropTypes.string,
	maxWidth: PropTypes.number,
	position: PropTypes.string,
	serverSideFlag: PropTypes.bool,
	title: PropTypes.string,
};

let KebabButton = ({
	children,
	color,
	disabled,
	disabledWarningMessage,
	drop,
	hideDropdownFlag,
	icon,
	maxWidth,
	position,
	serverSideFlag,
	title,
	...props
}) => {
	return (
		<StyleWrapper $style={`&&& { overflow: visible; padding-right: 0px; }`}>
			<Dropdown data-testid={"kebab-dropdown-button"} {...props}>
				<Dropdown.Toggle
					as={CustomToggle}
					id="dropdown-custom-components"
					disabled={disabled}
					disabledWarningMessage={disabledWarningMessage}
					color={color}
				/>
				{!serverSideFlag ? <KebabMenu>{children}</KebabMenu> : children}
			</Dropdown>
		</StyleWrapper>
	);
};

KebabButton.propTypes = propTypes;

export default KebabButton;
