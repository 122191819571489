import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	object: PropTypes.string,
};
let FormSelectDashboardSize = ({ object, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("tabGridOptions.chartsLarge"),
				value: "large",
			},
			{
				label: translate("tabGridOptions.chartsMedium"),
				value: "medium",
			},
			{
				label: translate("tabGridOptions.chartsSmall"),
				value: "small",
			},
		],
		[translate]
	);

	return <Select defaultValue="medium" options={options} {...props} />;
};
FormSelectDashboardSize.propTypes = propTypes;

export default FormSelectDashboardSize;
