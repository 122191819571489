import { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Mention,MentionsInput } from "react-mentions";
import styled from "styled-components";
import getCaretCoordinates from "textarea-caret";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import HTML from "../HTML";
import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";
import UserListDropdown from "./UserListDropdown";

let StyledDiv = styled.div`
	.add-comment__control {
		background-color: ${theme.white};
		height: 160px;
		border-radius: ${theme.tinySpace};

		.add-comment__highlighter {
			padding: ${theme.smallSpace};
			height: 100%;
		}

		.add-comment__input {
			padding: ${theme.smallSpace};
			border-radius: ${theme.tinySpace};
			border: 1px solid ${theme.gray};

			&:focus-visible {
				outline: none;
				border-color: ${theme.primary} !important;
			}

			::placeholder {
				color: ${theme.gray3};
			}
		}
	}
`;

let propTypes = {
	placeholderText: PropTypes.string,
	updateValue: PropTypes.func,
	value: PropTypes.string,
};

let placeholderText;

let MentionUser = ({ placeholderText: placeholderTextProp, updateValue, value }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let placeholderTextRef = useRef();

	let userList = get({ object: "user" });
	let mentionUserList = useMemo(() => {
		if (userList) {
			let newUserList = [];
			for (let user of userList) {
				newUserList = [...newUserList, { id: user.userId, display: user.fullName }];
			}
			return newUserList;
		}
	}, [userList]);

	let [caretCoordinates, setCaretCoordinates] = useState({});

	let getCoordinates = (e) => {
		let input = e.target;
		let caret = getCaretCoordinates(input, input.selectionEnd);
		setCaretCoordinates(caret);
	};

	// keep placeholder text from disappearing on open/close
	if (!placeholderTextRef.current) {
		placeholderTextRef.current =
			placeholderTextProp ?? document.querySelector(".placeholder-text-prerenderer")?.innerHTML;
		if (!placeholderText) placeholderText = placeholderTextRef.current;
	}
	if (!placeholderTextRef.current) placeholderTextRef.current = placeholderText;

	return (
		<StyledDiv>
			<StyleWrapper display="none">
				<HTML className="placeholder-text-prerenderer">{translate("global.addCommentMention")}</HTML>
			</StyleWrapper>
			<MentionsInput
				value={value}
				onChange={updateValue}
				onInput={getCoordinates}
				placeholder={placeholderTextRef.current}
				forceSuggestionsAboveCursor
				className="add-comment"
				allowSpaceInQuery
				customSuggestionsContainer={(children) => (
					<UserListDropdown children={children} caretCoordinates={caretCoordinates} />
				)}
			>
				<Mention
					appendSpaceOnAdd
					data={mentionUserList}
					displayTransform={(id, display) => `@${display}`}
					markup="[@__id__|__display__]"
					style={{ backgroundColor: "#cee4e5" }}
					trigger="@"
				/>
			</MentionsInput>
		</StyledDiv>
	);
};

MentionUser.propTypes = propTypes;

export default MentionUser;
