import { useCallback } from "react";
import setDate from "date-fns/set";
import { useFormatDate } from "@clearpoint/hooks";


let useBeginningOfMonthDate = () => {
	let formatDate = useFormatDate();

	return useCallback(
		(endOfMonthDate) => {
			let startDate = setDate(new Date(endOfMonthDate), { date: 1 });
			return formatDate(startDate, "apiDate");
		},
		[formatDate]
	);
};

export default useBeginningOfMonthDate;
