import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import { useMemo } from "react";
import averagePercentComplete from "./averagePercentComplete";
import roundPercentComplete from "./roundPercentComplete";
import { useDebounce } from "@clearpoint/hooks";


let useUpdateGoalPercentCompleteInForm = (keyResultList, percentCompleteNameInForm = "percentComplete") => {
	let { setFormValue } = useFormContext();

	let activeKeyResultList = useMemo(
		() =>
			keyResultList?.filter?.((keyResult) => {
				if (Object.hasOwn(keyResult, "active")) return keyResult.active;
				return true;
			}),
		[keyResultList]
	);

	let percentComplete = averagePercentComplete(activeKeyResultList);
	let roundedPercentComplete = roundPercentComplete(percentComplete);

	useDebounce(
		(percentCompleteNameInForm, roundedPercentComplete) => {
			setFormValue(percentCompleteNameInForm, roundedPercentComplete);
		},
		[percentCompleteNameInForm, roundedPercentComplete],
		{}
	);

	return roundedPercentComplete;
};

export default useUpdateGoalPercentCompleteInForm;
