import { useCallback } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";

let elementList = ["measure", "risk", "objective", "initiative", "actionitem", "scorecard", "map"];

let exceptionsLookup = {
	initativeMeasure: "measure",
	measureInitiative: "initiative",
};

export let checkHiddenElement = (element, menu) => {
	if (!menu) return false;

	let visibleElementList = menu.map((x) => x.object.toLowerCase());

	let match = exceptionsLookup[element] || elementList.find((x) => element.toLowerCase().includes(x));

	if (!match) {
		return false;
	}

	return !visibleElementList.includes(match.toLowerCase());
};

let useCheckHiddenElement = () => {
	let { get } = useOldQueryStore();
	let { scorecardId } = useOldSession().session;
	let menu = scorecardId && get({ object: "menu", parent: "scorecard", parentId: scorecardId });
	return useCallback((element) => checkHiddenElement(element, menu), [menu]);
};

export default useCheckHiddenElement;
