import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayVariance = ({ name }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	return <>{value === "N/A" ? translate("global.notAvailable") : value}</>;
};

FormFieldDisplayVariance.propTypes = propTypes;
export default FormFieldDisplayVariance;
