// UI Features (add names to en_us.json : "feature.x")
// Add coupling to featureCoupleLookup (disable one when the other is enabled)
// Add to environments in featureFlagListLookup
const BRIEFING_BOOK_ENHANCEMENTS = "briefingBookEnhancements";
const CLEARBOT = "clearBot";
const CUSTOM_SCRIPT_SCHEDULES = "customScriptSchedules";
const DASHBOARDS = "dashboards";
const DISCUSSION_BAR = "discussionBar";
const DISCUSSION_POD = "discussionPod";
const POD_WIDTHS = "podWidths";
const REACTIONS = "reactions";
const WORKSPACES = "workspaces";

export {
	BRIEFING_BOOK_ENHANCEMENTS,
	CLEARBOT,
	CUSTOM_SCRIPT_SCHEDULES,
	DASHBOARDS,
	DISCUSSION_BAR,
	DISCUSSION_POD,
	POD_WIDTHS,
	REACTIONS,
	WORKSPACES,
};
