import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import isPast from "date-fns/isPast";

let usePeriodLockedFlag = (periodId) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	periodId = periodId || session.periodId;

	let periodData = periodId && get({ object: "period", objectId: periodId });

	let isLockedFlag = periodData?.locked;
	let isLockedDateFlag = periodData?.periodLocks?.some(
		(period) => period.scorecardId === session.scorecardId && period.lockDate && isPast(new Date(period.lockDate))
	);

	return isLockedFlag || isLockedDateFlag || false;
};

export default usePeriodLockedFlag;
