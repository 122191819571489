import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

let queryClient = new QueryClient({
	queryCache: new QueryCache({
		// These events are used to set data in the old-query-store.
		// These events can be removed once the old-query-store is removed.
		onError: (error, query) => {
			document.dispatchEvent(new CustomEvent("queryError", { detail: { error, queryKey: query.queryKey } }));
		},
		onSuccess: (data, query) => {
			document.dispatchEvent(new CustomEvent("query", { detail: { data, queryKey: query.queryKey } }));
		},
	}),
	defaultOptions: {
		queries: {
			staleTime: Number.POSITIVE_INFINITY,
			// retry: 3 (default)
		},
		mutations: {
			retry: 1,
		},
	},
});

let QueryProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		{children}
		{/* <ReactQueryDevtools initialIsOpen={false} /> */}
	</QueryClientProvider>
);

export default QueryProvider;
export { queryClient };
