import { useCallback, useEffect } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

let useRemoveElementFromMap = () => {
	let { setFormValue } = useFormContext();
	let formElementList = useFormValue("elements");
	if (!formElementList) formElementList = [];

	let removeElementFromMap = useCallback(
		(e) => {
			// dropped in element list?
			let droppedElement = document.querySelector(".smooth-dnd-ghost");
			if (droppedElement) {
				let { pageX, pageY } = e;
				let {
					x: elementListX,
					y: elementListY,
					width,
					height,
				} = document.querySelector(".element-list").getBoundingClientRect();
				let left = pageX - elementListX;
				let top = pageY - elementListY;
				let droppedInElementListFlag = left > 0 && left < width && top > 0 && top < height;

				// currently in map?
				let mapElement = droppedElement.querySelector(".map-element");
				let object = mapElement.getAttribute("data-object");
				let objectId = +mapElement.getAttribute("data-object-id");
				let formElement = formElementList.find((x) => x.object === object && x.objectId === objectId);
				let elementInMapFlag = !!formElement;

				// remove from map
				if (droppedInElementListFlag && elementInMapFlag) {
					let elementIndex = formElementList.indexOf(formElement);
					setFormValue("elements", [
						...formElementList.slice(0, elementIndex),
						...formElementList.slice(elementIndex + 1),
					]);
				}
				droppedElement.style.opacity = 0;
				let draggableElementList = document.querySelectorAll(".smooth-dnd-draggable-wrapper");
				for (let element of draggableElementList) {
					element.classList.add("dropped");
					setTimeout(() => {
						element.classList.remove("dropped");
					}, 100);
				}
			}
		},
		[formElementList, setFormValue]
	);
	useEffect(() => {
		document.addEventListener("mouseup", removeElementFromMap);
		return () => document.removeEventListener("mouseup", removeElementFromMap);
	});
};

export default useRemoveElementFromMap;
