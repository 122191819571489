import AIAssistantProvider from "@clearpoint/old-theme/AI/AIAssistantProvider/AIAssistantProvider";
import AssistantTemplateContent from "./AssistantTemplateContent";
import Form, { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useAIContextPayload from "./useAIContextPayload";
import { useCallback, useMemo } from "react";
import useTemplateLookup from "./useTemplateLookup";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	close: PropTypes.func.isRequired,
	fieldId: PropTypes.string.isRequired,
	fieldNameInForm: PropTypes.string.isRequired,
	outerFormObject: PropTypes.string.isRequired,
	periodId: PropTypes.number.isRequired,
	periodLockedFlag: PropTypes.bool,
	template: PropTypes.string.isRequired,
};

let AssistantTemplate = ({
	close,
	fieldId,
	fieldNameInForm,
	outerFormObject,
	periodId,
	periodLockedFlag,
	template,
}) => {
	let { setFormValue } = useFormContext();
	let templateLookup = useTemplateLookup({ fieldId, outerFormObject });
	let { description, endpoint, name } = templateLookup[template] || emptyObject;

	let contextPayload = useAIContextPayload({ fieldId, outerFormObject, periodId });

	let defaultValue = useMemo(() => ({ userMessage: "" }), []);

	let setFieldFormValue = useCallback(
		(value) => {
			setFormValue(fieldNameInForm, value);
		},
		[fieldNameInForm, setFormValue]
	);

	return (
		<Form defaultValue={defaultValue}>
			<AIAssistantProvider
				contextPayload={contextPayload}
				endpoint={endpoint}
				startImmediatelyFlag
				parseHtmlFlag={template === "copyEdit"}
			>
				<AssistantTemplateContent
					close={close}
					description={description}
					name={name}
					periodLockedFlag={periodLockedFlag}
					setFieldFormValue={setFieldFormValue}
					template={template}
				/>
			</AIAssistantProvider>
		</Form>
	);
};

AssistantTemplate.propTypes = propTypes;
export default AssistantTemplate;
