import { theme } from "@clearpoint/old-theme/Theme";

function bootAccessiBe(profile) {
	var language = profile.localeId.substring(0, 2);
	var script = document.createElement("script");
	script.setAttribute("type", "text/javascript");
	script.setAttribute("async", true);
	script.setAttribute("defer", true);
	script.setAttribute("src", "https://acsbap.com/api/v1/assets/js/acsb.js");
	script.onload = function () {
		window.AccessiBe.init({
			statementLink: "",
			feedbackLink: "",
			showAllActions: false,
			keyNavStrong: false,
			hideMobile: false,
			hideTrigger: true,
			wlbl: "accessiBe",
			headerHtml: "",
			footerHtml: "ClearPoint Strategy Accessibility Options",
			language: language,
			focusInnerColor: "#146FF8",
			focusOuterColor: "#ff7216",
			leadColor: theme.navBackground,
			triggerColor: theme.navBackground,
			size: "big",
			position: "right",
			triggerRadius: "4px",
			triggerPositionX: "right",
			triggerPositionY: "bottom",
			triggerIcon: "default",
			triggerSize: "medium",
			triggerOffsetX: 0,
			triggerOffsetY: 0,
			usefulLinks: {},
			mobile: {
				triggerSize: "small",
				triggerPositionX: "right",
				triggerPositionY: "bottom",
				triggerOffsetX: 0,
				triggerOffsetY: 0,
				triggerRadius: "0",
			},
		});
	};
	document.getElementsByTagName("head")[0].appendChild(script);
}

export default bootAccessiBe;
