import { useEffect, useRef, useState } from "react";

let useScrollReplyInputIntoView = () => {
	let replyInputContainerRef = useRef(null);
	let container = document.getElementById("discussionListScrollableContainer");
	let [initialScrolledFlag, setInitialScrolledFlag] = useState(false);

	useEffect(() => {
		let target = replyInputContainerRef.current;

		if (!container || !target) return;

		let observerOptions = {
			root: container,
			rootMargin: "0px",
			threshold: 1,
		};

		let observer = new IntersectionObserver(([entry]) => {
			if (initialScrolledFlag || entry.isIntersecting) {
				setInitialScrolledFlag(true);
				return;
			}

			container.scrollTo({
				behavior: "smooth",
				top: container.scrollTop + entry.boundingClientRect.height - entry.intersectionRect.height + 30,
			});
			setInitialScrolledFlag(true);
		}, observerOptions);

		observer.observe(target);

		return () => {
			observer.unobserve(target);
		};
	}, [replyInputContainerRef, initialScrolledFlag, container]);

	return { replyInputContainerRef };
};

export default useScrollReplyInputIntoView;
