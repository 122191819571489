import BootstrapModal from "react-bootstrap/Modal";
import { useModalContext } from "./Modal";
import PropTypes from "prop-types";
import StyleWrapper from "../StyleWrapper";

let propTypes = {
	children: PropTypes.node,
	closeButtonFlag: PropTypes.bool,
};

let defaultProps = {
	closeButtonFlag: false,
};

let ModalHeader = ({ closeButtonFlag, children }) => {
	let { id } = useModalContext();
	return (
		<StyleWrapper $style={`.modal-title { font-size: 16px }`}>
			<BootstrapModal.Header className={`modal-handle${id}`} closeButton={closeButtonFlag}>
				{children}
			</BootstrapModal.Header>
		</StyleWrapper>
	);
};

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
