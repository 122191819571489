import { createGlobalStyle } from "styled-components";

import { theme } from "@clearpoint/old-theme/Theme";

let pageSizeLookup = {
	letter: "US-Letter",
	legal: "US-Legal",
	a4: "A4",
	tabloid: "US-Tabloid",
};

// watermark styling is in _print.scss due to a styled-components bug

// page break troubleshooting ref: https://www.princexml.com/forum/topic/4566/problem-with-page-break

let PrintStyle = createGlobalStyle`
	html,
	body,
	.root {
		font-size: 13px;
		height: unset;
		overflow: unset;
	}
	body {
		background: ${theme.white} !important;
		color: ${theme.black};
		font-family: ${theme.defaultFontFamily};
	}
	*:not(.briefing-book-nav-item-container, .briefing-book-nav-item-text, .dashboard-list-item-text, .dropdown-menu, .dropdown-item, .gantt_tree_content, .list-item-container *)  {
		overflow: visible;
	}
	.dashboard-list-item-text	{
		white-space: pre-wrap;
	}
	.htmlarea {
		display: block !important;
	}
	&&& th, &&& td {
		overflow: hidden !important;
	}
	table, table * {
		break-inside: auto;
	}
	table tr, .ibox, .row:not(.detail-page-row)   {
		break-inside: avoid;
	}
	.table {
		color: ${theme.black};
	}
	td,
	td div:not(.briefing-book-nav-item-container, .briefing-book-nav-item-text, .gantt_tree_content) {
		white-space: pre !important;
		white-space: pre-wrap !important;
		white-space: pre-line !important;
		white-space: -pre-wrap !important;
		white-space: -o-pre-wrap !important;
		white-space: -moz-pre-wrap !important;
		white-space: -hp-pre-wrap !important;
		word-wrap: break-word !important;
		word-break: break-word !important;
		overflow-wrap: break-word !important;
	}
	td, tr {
		min-height: min-content !important;
	}
	td div {
		min-width: 0;
	}
	header {
		flow: static(header-html);
	}
	footer {
		flow: static(footer-html);
	}
	.current-page-number {
		content: counter(page);
	}
	.total-page-number {
		content: counter(pages);
	}
	.avoid-page-break {
		break-inside: avoid;
	}
	.avoid-page-break-after {
		break-after: avoid;
	}
	.avoid-page-break-before {
		break-before: avoid;
	}
	.page-break {
		break-before: page;
	}
	.page-break-inside {
		break-inside: auto !important;
	}

	li {
		margin-left: 10px;
	}

	fieldset legend {
		position: relative;
		bottom: 20px;
		background-color: ${theme.white};
		width: max-content !important;
		margin-bottom: -10px !important;
		min-width: min-content !important;
	}

	.htmlarea {
		display: inline-block;
		width: 100%;
		a {
			font-weight: 400;
		}

		& > :not(ul):not(ol) {
			overflow-y: clip !important;
		}
	}


	.row {
		flex-wrap: nowrap;
	}

	// .detail-page-pod-wrapper:not(.col-12):has(.grid-container) {
	// 	max-width: 100%;
	// }

	.no-table-pdf-tag {
		-prince-pdf-tag-type: none;
		& td, tr, th, thead, tbody {
			-prince-pdf-tag-type: none;
		}
	}

	${(props) =>
		!props.htmlExportFlag &&
		`li > span {
			display: inline;
		}

		.gantt_close {
			margin-left: 2px;
			margin-right: ${theme.tinySpace};
			color: ${theme.blueLink}
		}
	`}


	${(props) =>
		props.width &&
		`.portal {
			width: ${props.width}px;
		}
	`}

	@page {
		size: ${(props) => pageSizeLookup[props.pageSize?.toLowerCase()] || "US-Letter"} ${(props) => props.orientation};
		margin-left: 10mm;
		margin-right: 10mm;
		margin-top: ${(props) => props.marginTop};
		margin-bottom: ${(props) => props.marginBottom};
	}
`;

export default PrintStyle;
