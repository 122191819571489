import Block from "@clearpoint/old-theme/Block";
import FormFieldDisplayVariance from "@clearpoint/old-theme/Form/Field/Display/Variance";

let Variance = () => (
	<Block display="flex" justifyContent="flex-end">
		<FormFieldDisplayVariance name="updates.calculatedValues.variance" />
	</Block>
);

export default Variance;
