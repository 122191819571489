import { emptyObject } from "@clearpoint/utils";
import { useMeasure } from "@clearpoint/hooks";


let useOverflowYFlag = ({ containerId }) => {
	let { height: hotContainerHeight } = useMeasure({ query: `#${containerId}` }) || emptyObject;
	let { height: tableHeight } = useMeasure({ query: `#${containerId} .wtHider` }) || emptyObject;
	return tableHeight > hotContainerHeight;
};

export default useOverflowYFlag;
