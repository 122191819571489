import AdvancedFilter from "./AdvancedFilter";
import Block from "../Block";
import Form from "../Form/Form";
import LogFileList from "./LogFileList";
import NoResults from "../NoResults";
import Pagination from "../Pagination/Pagination";
import React, { useCallback, useEffect, useMemo } from "react";
import Toolbar from "./Toolbar";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import { theme } from "../Theme";
import { emptyArray, emptyObject } from "@clearpoint/utils";
import { useEffectOnce, useFilteredHistoryList, useStateObject } from "@clearpoint/hooks";



let propTypes = {
	disableAdvancedFilterFlag: PropTypes.bool,
	filterOptionList: PropTypes.arrayOf(PropTypes.string),
	initialFilter: PropTypes.oneOfType([
		PropTypes.shape({
			field: PropTypes.any,
			value: PropTypes.any,
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				field: PropTypes.any,
				value: PropTypes.any,
			})
		),
	]),
	onChange: PropTypes.func,
};

let RevisionHistory = ({ disableAdvancedFilterFlag, filterOptionList, initialFilter, onChange }) => {
	let { clearFilterData, filterQuery, getFilteredHistoryList, logFileCount, setFilter } = useFilteredHistoryList();
	let { search } = filterQuery;
	let [{ advancedFilterVisible }, setState] = useStateObject({
		advancedFilterVisible: false,
	});
	let toggleAdvancedFilter = useCallback(() => {
		setState({
			advancedFilterVisible: (x) => !x,
		});
	}, [setState]);
	useEffectOnce(() => {
		if (!initialFilter) return;
		if (isArray(initialFilter)) {
			for (let { field, value } of initialFilter) {
				setFilter({ field, value });
			}
		} else {
			let { field, value } = initialFilter;
			setFilter({ field, value });
		}
	});
	useEffect(() => {
		if (onChange) onChange(filterQuery);
	}, [onChange, filterQuery]);

	let advancedFilterDefaultValue = useMemo(() => {
		if (disableAdvancedFilterFlag) return emptyObject;
		let defaultValueObject = {};
		if (isArray(initialFilter)) {
			initialFilter.forEach((filter) => {
				defaultValueObject[filter.field] = filter.value;
			});
		} else if (initialFilter) {
			defaultValueObject[initialFilter.field] = initialFilter.value;
		}
		return defaultValueObject;
	}, [disableAdvancedFilterFlag, initialFilter]);

	return (
		<Block display="flex" flexDirection="column" height="100%" overflow="hidden">
			<Block marginBottom={theme.mediumLargeSpace}>
				<Toolbar
					advancedFilterVisible={advancedFilterVisible}
					logFileCount={logFileCount}
					toggleAdvancedFilter={toggleAdvancedFilter}
					disableAdvancedFilterFlag={disableAdvancedFilterFlag}
					filterSearchValue={search}
					setFilter={setFilter}
				/>
				<Form defaultValue={advancedFilterDefaultValue}>
					{advancedFilterVisible && <AdvancedFilter filterOptionList={filterOptionList} setFilter={setFilter} />}
				</Form>
			</Block>
			{logFileCount === 0 && <NoResults results={emptyArray} />}
			<Pagination getData={getFilteredHistoryList} clearFilterData={clearFilterData} ignoreFilterProviderFlag>
				<LogFileList />
			</Pagination>
		</Block>
	);
};

RevisionHistory.propTypes = propTypes;
export default RevisionHistory;
