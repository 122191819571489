import PropTypes from "prop-types";

import FormCompletedCheckbox from "@clearpoint/old-theme/Form/Check/Form.Check.Completed";

import { useCheckAccess } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

import { useOldSession } from "@clearpoint/old-session/index";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";

let propTypes = {
	ignoreOptionsFlag: PropTypes.bool,
	link: PropTypes.shape({
		access: PropTypes.string,
		completed: PropTypes.bool,
		object: PropTypes.string,
		objectId: PropTypes.number,
		periodLocked: PropTypes.bool,
		scorecardId: PropTypes.number,
		scorecardLocked: PropTypes.bool,
	}),
	podFieldId: PropTypes.string,
};

let Checkbox = ({ ignoreOptionsFlag, link, podFieldId }) => {
	let { exportFlag } = useOldSession().session;
	let { editPodFlag, openEditMode, fieldId, template } = usePodContext();
	let initiativePodFlag = fieldId === "initiative" && template === "linkedElement";
	let { get } = useOldQueryStore();
	let { options } = get({ object: "options" }) || emptyObject;
	let { trackActionItemDate, trackDate, trackInitiativeDate, trackRiskDate } = options || emptyObject;
	let {
		access,
		completed: completedFlag,
		object,
		objectId,
		periodLocked: periodLockedFlag,
		scorecardId,
		scorecardLocked: scorecardLockedFlag,
	} = link;

	let trackingEnabledFlag;

	if (ignoreOptionsFlag) {
		let trackableList = ["risk", "milestone", "initiative", "actionItem"];
		trackingEnabledFlag = trackableList.includes(link.object);
	} else {
		trackingEnabledFlag =
			(podFieldId === "actionItems" && trackActionItemDate) ||
			(podFieldId === "initiatives" && trackInitiativeDate) ||
			(podFieldId === "milestones" && trackDate) ||
			(podFieldId === "risks" && trackRiskDate);
	}

	let showCheckboxFlag = !exportFlag && trackingEnabledFlag;

	let checkAccess = useCheckAccess();
	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });

	return showCheckboxFlag ? (
		<FormCompletedCheckbox
			defaultValue={completedFlag}
			editPodFlag={editPodFlag}
			onClick={openEditMode}
			object={object}
			objectId={objectId}
			disabled={periodLockedFlag || scorecardLockedFlag || !editAccessFlag || initiativePodFlag}
			milestoneFlag
		/>
	) : null;
};

Checkbox.propTypes = propTypes;
export default Checkbox;
