import { useOldQueryStore } from "@clearpoint/old-query-store";

let userTypeLookup = {
	1: "Administrator",
	2: "Editor",
	3: "Updater",
	4: "Browser",
	7: "No Access",
	8: "Assign Group",
	9: "Scorecard Admin",
	10: "By Scorecard",
};
let useUserType = () => {
	let { get } = useOldQueryStore();
	let profile = get({ object: "profile" });
	return profile?.userType || userTypeLookup[profile?.userTypeId] || "";
};
export default useUserType;
