let buildImagePath =
	({ environment, exportFlag, htmlExportFlag, rootPath, token }) =>
	(imageValue) => {
		let publishedLivePageFlag = environment !== "localdev" && !exportFlag;
		let dataUriFlag = imageValue?.startsWith("data:image");
		if (htmlExportFlag || dataUriFlag || publishedLivePageFlag || imageValue?.startsWith("http")) return imageValue;
		let imagePath = rootPath;
		if (!imageValue.startsWith("/")) imagePath += "/";
		imagePath += imageValue;
		if (!imageValue.includes("t=")) {
			imagePath += imageValue.includes("?") ? "&" : "?";
			imagePath += `t=${token}`;
		}
		return imagePath;
	};

export default buildImagePath;
