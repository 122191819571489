import DropdownItem from "./Dropdown.Item";
import TrashModal from "../../Modal/Modal.Trash";
import { useCallback, useState } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";

let propTypes = {
	body: PropTypes.string,
	elementFlag: PropTypes.bool,
	label: PropTypes.string,
	listKey: PropTypes.string,
	method: PropTypes.oneOf(["post", "delete"]),
	name: PropTypes.string,
	object: PropTypes.string.isRequired,
	objectId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
	onTrash: PropTypes.func,
	queryString: PropTypes.string,
	title: PropTypes.string,
	toast: PropTypes.string,
};

let DropdownItemTrash = ({
	body,
	children,
	elementFlag,
	label,
	listKey,
	method,
	name,
	object,
	objectId,
	objectIdList,
	onTrash,
	queryString,
	title,
	toast,
	...props
}) => {
	let [trashModalVisible, setTrashModalVisible] = useState(false);
	let translate = useTranslate();
	let showModal = useCallback((e) => {
		if (e) e.stopPropagation();
		setTrashModalVisible(true);
	}, []);
	let close = useCallback((e) => {
		if (e) e.stopPropagation();
		setTrashModalVisible(false);
	}, []);
	let checklist = useChecklist();
	if (!objectId && !objectIdList && checklist) {
		objectIdList = checklist.checkedObjectIdList;
	}

	return (
		<>
			<DropdownItem data-testid="dropdown-item-trash" onClick={showModal} icon="trash" {...props}>
				{children ||
					translate("global.delete", { InsertText: label ? capitalizeFirstLetter(label) : `global.${object}` })}
			</DropdownItem>
			<TrashModal
				body={body}
				close={close}
				elementFlag={elementFlag}
				listKey={listKey}
				method={method}
				modalVisible={trashModalVisible}
				name={name}
				object={object}
				objectId={objectId}
				objectIdList={objectIdList}
				onTrash={onTrash}
				queryString={queryString}
				title={title}
				toastMessage={toast}
			/>
		</>
	);
};

DropdownItemTrash.propTypes = propTypes;

export default DropdownItemTrash;
