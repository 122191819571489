import { useCallback } from "react";
import useCheckFeature from "../useCheckFeature";
import useObjectiveUpdateFieldList from "./useObjectiveUpdateFieldList";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import mergeCustomFieldList from "./mergeCustomFieldList";

let propTypes = {
	scorecardId: PropTypes.number,
};
let useObjectiveEditFieldList = ({ scorecardId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let checkFeature = useCheckFeature();
	let tagFlag = checkFeature("tags");

	let customFieldList = get({ object: "customField", queryString: "?object=objective" });

	return useCallback(() => {
		let filteredCustomFieldList = customFieldList
			?.filter((x) => !x.updateMode)
			.filter((x) => !x.scorecardId || x.scorecardId.length === 0 || x.scorecardId.includes(scorecardId));

		let fieldList = [
			{
				value: "scorecardId",
				label: translate("global.scorecard"),
				sortOrder: -999,
				group: translate("objective"),
				required: true,
			},
			{
				value: "tags",
				label: translate("global.tags"),
				sortOrder: -998,
				group: translate("objective"),
			},
			{
				value: "ownerId",
				label: translate("defaultFields.edit.objective.owner"),
				sortOrder: -997,
				group: translate("objective"),
			},
			{
				value: "collaborators",
				label: translate("collaborators"),
				sortOrder: -996,
				group: translate("objective"),
			},
			{
				value: "periodGroupId",
				label: translate("global.periodicity"),
				sortOrder: -995,
				group: translate("objective"),
			},
		];
		if (!tagFlag) fieldList = fieldList.filter((x) => x.value !== "tags");
		return mergeCustomFieldList(fieldList, filteredCustomFieldList, translate);
	}, [customFieldList, translate, scorecardId, tagFlag]);
};
useObjectiveUpdateFieldList.propTypes = propTypes;
export default useObjectiveEditFieldList;
