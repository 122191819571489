import Block from "@clearpoint/old-theme/Block";
import Table from "@clearpoint/old-theme/Table";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import getId from "../getId";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";
import { useQueryParameters } from "@clearpoint/hooks";



let TableOfContents = () => {
	let translate = useTranslate();
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let { get } = useOldQueryStore();
	let { reportId } = useQueryParameters();
	let report = get({ object: "report", objectId: reportId });
	let { toc, contents } = report || emptyObject;
	let tableOfContentsFlag = !!toc;
	let objectList = useMemo(
		() => ["scorecard", "map", "objective", "measure", "initiative", "milestone", "actionItem", "risk"],
		[]
	);

	return tableOfContentsFlag
		? contents?.map((scorecard) => (
				<Block
					key={scorecard.scorecardId}
					color={theme.black}
					$style={`*:not(a) { color: black; } ul { padding-left: 25px; }`}
				>
					<h3>{scorecard.name}</h3>
					<Table borderedFlag>
						<thead>
							<tr>
								<th>{translate("global.element")}</th>
								<th>{translate("briefingBook.summaryViews")}</th>
								<th>{translate("briefingBook.detailViews")}</th>
							</tr>
						</thead>
						<tbody>
							{objectList?.map((object) =>
								scorecard.pages.some((page) => page.object === object) ? (
									<tr key={object}>
										<td>{translate(object + "s")}</td>
										<td>
											<ul>
												{scorecard.pages
													?.filter((page) => page.object === object && page.layoutId)
													?.map((page) => (
														<li key={page.layoutId}>
															<a href={`#${getId(page)}`}>{translateOnlyInsideCurlyBraces(page.name)}</a>
														</li>
													))}
											</ul>
										</td>
										<td>
											<ul>
												{scorecard.pages
													?.filter((page) => page.object === object && page.objectId)
													?.map((page) => (
														<li key={page.objectId}>
															<a href={`#${getId(page)}`}>{translateOnlyInsideCurlyBraces(page.name)}</a>
														</li>
													))}
											</ul>
										</td>
									</tr>
								) : null
							)}
						</tbody>
					</Table>
				</Block>
		  ))
		: null;
};

export default TableOfContents;
