import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { CUSTOM_SCRIPT_SCHEDULES, DASHBOARDS } from "@clearpoint/utils";
import { useCheckFeature } from "@clearpoint/hooks";


let SelectScheduleTaskType = ({ ...props }) => {
	let { get } = useOldQueryStore();
	let optionsData = get({ object: "options" });
	let hostname = optionsData?.hostname;
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let customScriptSchedulesEnabledFlag = checkFeature(CUSTOM_SCRIPT_SCHEDULES);
	let dashboardsEnabledFlag = checkFeature(DASHBOARDS);

	let options = useMemo(() => {
		let optionList = [
			{ value: "briefingBook", label: translate("schedule.generateBook", { book: translate("labels.book") }) },
			{ value: "dataLoader", label: translate("schedule.runImport") },
			!hostname || !dashboardsEnabledFlag
				? undefined
				: { value: "dashboard", label: translate("schedule.publishDashboard") },
			{ value: "reminder", label: translate("schedule.sendReminder") },
			{ value: "recalc", label: translate("options.admin.recalculate.recalcAccount") },
			{ value: "copyForward", label: translate("schedule.copyForward") },
			{ value: "html", label: translate("schedule.generateHTML") },
		].filter((x) => !!x);

		if (customScriptSchedulesEnabledFlag) {
			optionList = [...optionList, { value: "script", label: translate("schedule.runCustomScript") }];
		}
		return optionList;
	}, [customScriptSchedulesEnabledFlag, dashboardsEnabledFlag, hostname, translate]);

	return <FormSelect data-testid="select-schedule-task-type" options={options} {...props} />;
};
export default SelectScheduleTaskType;
