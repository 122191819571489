/* eslint-disable camelcase */
import { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useBuildImagePath, useId, useStatefulRef } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray } from "@clearpoint/utils";

import "./CustomImagePlugin/setupReactApp";
import "./_angular.css";

import { useFormContext } from "../../Form/DefaultForm";
import Loading from "../../Loading/index";
import CodeMirrorModal from "./CodeMirrorModal";
import usePrettyValue from "./CSS/usePrettyValue";
import InsertToken from "./InsertToken/InsertToken";
import beforePaste from "./TinyMce.beforePaste";
import createToolbar from "./TinyMce.createToolbar";
import imageUpload from "./TinyMce.ImageUpload";
import { Editor } from "@tinymce/tinymce-react";

let propTypes = {
	cellFlag: PropTypes.bool,
	dataFlag: PropTypes.bool,
	name: PropTypes.string,
	noAlignmentFlag: PropTypes.bool,
	noCssFlag: PropTypes.bool,
	noHtmlFlag: PropTypes.bool,
	onChange: PropTypes.func,
	simpleFlag: PropTypes.bool,
	value: PropTypes.string,
};

let TinyMceEditor = ({
	cellFlag,
	dataFlag,
	name,
	noAlignmentFlag,
	noCssFlag,
	noHtmlFlag,
	onChange,
	simpleFlag,
	value,
}) => {
	let id = useId();
	let editorRef = useRef(null);
	let translate = useTranslate();
	let { setFormError } = useFormContext() || {};
	let { get } = useOldQueryStore();
	let images = get({ object: "image" });
	let buildImagePath = useBuildImagePath();

	let defaultStyles = `@import url('https://fonts.googleapis.com/css2?family=Arial&display=swap');
	a	{ font-weight: 600; color: #337ab7; }
	body { color: #676a6c; font-family: Arial; min-height: 300px;  padding: 5px; } strong { font-weight: 600; } hr { background: rgba(0, 0, 0, 0.1); border: none; height: 3px; margin: 0; }`;
	let initialCssValue = usePrettyValue(`_style${name}`);
	let tinyCssValue = useStatefulRef(initialCssValue);
	let parseAndApplyCSS = useCallback(
		(editor) => {
			if (editor && editor.dom) {
				editor.dom.remove(editor.dom.select("style"));
				editor.dom.add(editor.dom.select("head"), "style", {}, defaultStyles + tinyCssValue.current);
			}
		},
		[defaultStyles, tinyCssValue]
	);

	let contentStyleValue = useMemo(() => {
		return defaultStyles + tinyCssValue.current;
	}, [defaultStyles, tinyCssValue]);

	let [cssEditorOpenFlag, setCssEditorOpenFlag] = useState(false);
	let cssEditorRef = useStatefulRef(false);
	let toggleEditor = useCallback(
		(editor) => {
			if (editor) setCssEditorOpenFlag(!cssEditorRef.current);
			let tinyIframe = document.getElementById(`${id}_ifr`);
			tinyIframe.style.display = cssEditorRef.current ? "block" : "none";
		},
		[cssEditorRef, id]
	);
	let handleCSSEditorToggle = useCallback(
		(editor) => {
			cssEditorRef.current = !cssEditorRef.current;
			toggleEditor(editor);
			parseAndApplyCSS(editor);
		},
		[cssEditorRef, parseAndApplyCSS, toggleEditor]
	);

	let htmlEditorRef = useStatefulRef(false);
	let [htmlEditorOpenFlag, setHtmlEditorOpenFlag] = useState(false);
	let handleHtmlEditorToggle = useCallback(
		(editor) => {
			htmlEditorRef.current = !htmlEditorRef.current;
			if (editor) setHtmlEditorOpenFlag(!htmlEditorRef.current);
			let tinyIframe = document.getElementById(`${id}_ifr`);
			tinyIframe.style.display = cssEditorRef.current ? "block" : "none";
		},
		[cssEditorRef, htmlEditorRef, id]
	);

	return images ? (
		<>
			<Editor
				ref={editorRef}
				onInit={(_evt, editor) => {
					editorRef.current = editor;
					handleHtmlEditorToggle();
					handleCSSEditorToggle();
				}}
				id={"" + id}
				value={value}
				onEditorChange={onChange}
				scriptLoading={{ async: true }}
				tinymceScriptSrc={"/tinymce/tinymce.min.js"}
				init={{
					setup: (editor) => {
						if (!noHtmlFlag)
							editor.ui.registry.addButton("customhtmlplugin", {
								icon: "sourcecode",
								tooltip: "HTML Editor",
								onAction: (_) => handleHtmlEditorToggle(editor),
							});
						if (!noCssFlag)
							editor.ui.registry.addButton("customcssplugin", {
								icon: "format-painter",
								tooltip: "CSS Editor",
								onAction: (_) => handleCSSEditorToggle(editor),
							});
					},
					content_css: [
						"/_angular.css",
						"/_redactor.css",
						"https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css",
					],
					min_height: 300,
					branding: false,
					browser_spellcheck: true,
					statusbar: false,
					contextmenu: false,
					help_tabs: [
						"shortcuts", // the default shortcuts tab
						"keyboardnav",
					],
					build_image_path: buildImagePath,
					image_list: images || emptyArray,
					menubar: false,
					paste_block_drop: true,
					paste_postprocess: beforePaste,
					paste_data_images: true,
					plugins: [
						"advlist",
						"autolink",
						"lists",
						"link",
						"customimageplugin",
						"charmap",
						"preview",
						"anchor",
						"searchreplace",
						"visualblocks",
						"fullscreen",
						"insertdatetime",
						"media",
						"table",
						"help",
						"emoticons",
					],
					external_plugins: {
						customimageplugin: "./customplugins/customimage/plugin.js",
					},
					images_upload_handler: imageUpload.bind(null, translate, setFormError),
					toolbar: createToolbar({
						cellFlag,
						noAlignmentFlag,
						noCssFlag,
						noHtmlFlag,
						simpleFlag,
					}),
					toolbar_mode: "floating",
					font_family_formats:
						"Arial=arial,helvetica,sans-serif; Georgia=georgia,palatino; Helvetica=helvetica; Monospace=monospace,monaco; Times New Roman=times new roman,times;",
					block_formats:
						"Normal Text=p; Quote=blockquote; Code=pre; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;",
					content_style: contentStyleValue,
					font_size_formats: "8px 10px 12px 14px 18px 24px 36px",
				}}
			/>
			{!noHtmlFlag && (
				<CodeMirrorModal
					modalVisible={htmlEditorOpenFlag}
					editorRef={editorRef}
					handleEditorToggle={handleHtmlEditorToggle}
					name={name}
					id={id}
					htmlFlag
				/>
			)}
			{!noCssFlag && (
				<CodeMirrorModal
					modalVisible={cssEditorOpenFlag}
					editorRef={editorRef}
					handleEditorToggle={handleCSSEditorToggle}
					name={name}
					id={id}
					tinyCssValue={tinyCssValue}
				/>
			)}
			{dataFlag && <InsertToken id={id} tinyMceFlag editorRef={editorRef} />}
		</>
	) : (
		<Loading />
	);
};

TinyMceEditor.propTypes = propTypes;

export default TinyMceEditor;
