import { useCallback, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";

import { useOnEvent } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

import { linkChartEventLookup } from "../LinkChart";
import { getAncestryId } from "../utils/idHandler";
import mergeLinks from "../utils/mergeLinks";
import { useOldSession } from "@clearpoint/old-session/index";

let useLinkChartEventHandlers = ({
	chartClickCallback,
	chartRef,
	d3ContainerRef,
	orgChartFunctions,
	setChildNodes,
}) => {
	let { getPromise } = useOldQueryStore();
	let { session } = useOldSession() || emptyObject;
	let { periodId } = session || emptyObject;

	let { handleResize } = orgChartFunctions || emptyObject;
	let debouncedHandleResize = useMemo(
		() => handleResize && debounce(handleResize.bind(null, chartRef, d3ContainerRef.current), 250),
		[chartRef, d3ContainerRef, handleResize]
	);

	let eventHandlerLookup = useMemo(() => {
		if (!orgChartFunctions) return emptyObject;
		let { handleCollapse, handleExpand, handleFit, handleRotate, handleZoomIn, handleZoomOut } = orgChartFunctions;

		return {
			collapseChart: handleCollapse,
			expandChart: handleExpand,
			fit: handleFit,
			rotate: handleRotate,
			zoomIn: handleZoomIn,
			zoomOut: handleZoomOut,
		};
	}, [orgChartFunctions]);

	let loadChildrenLinks = useCallback(
		(targetDataset) => {
			if (!targetDataset) return;
			let { object, objectid: objectId, ancestryid: ancestryId, id } = targetDataset;

			if (!periodId || !objectId) return;
			getPromise({ parent: object, parentId: objectId, object: "link", periodId }).then(async (children) => {
				if (!children) return;
				let formattedChildren = children
					.filter((x) => x.object !== "category")
					.map((link) => {
						let linkAncestryId = getAncestryId({ object, objectId, ancestryId }, link);
						let newLink = { ...link, ancestryId: linkAncestryId, subParentId: id, _expanded: true };
						return newLink;
					});

				for (let link of formattedChildren) {
					let { object, objectId } = link;
					if (object !== "measure") continue;
					let measureDataList = await getPromise({
						object: "measureData",
						parent: object,
						parentId: objectId,
					});
					let measureData = measureDataList.find((x) => x.periodId === periodId);
					link.measureData = [measureData];
				}
				setChildNodes((prev) => mergeLinks(prev, formattedChildren));
			});
		},
		[getPromise, periodId, setChildNodes]
	);

	let handleChartClick = useCallback(
		(e) => {
			if (chartClickCallback) chartClickCallback(e);
			let expandBtn = e?.target?.closest("#linkChartExpandBtn");
			if (!expandBtn) return;

			loadChildrenLinks(expandBtn?.dataset);
		},
		[chartClickCallback, loadChildrenLinks]
	);

	let cleanUpEventListeners = useCallback(() => {
		for (const eventType of Object.keys(eventHandlerLookup)) {
			document.removeEventListener(linkChartEventLookup[eventType], eventHandlerLookup[eventType]);
		}
		window.removeEventListener("resize", debouncedHandleResize);
		if (d3ContainerRef.current) d3ContainerRef.current.removeEventListener("click", handleChartClick);
	}, [eventHandlerLookup, debouncedHandleResize, d3ContainerRef, handleChartClick]);

	let setupEventSubscriptions = useCallback(() => {
		cleanUpEventListeners();
		for (const eventType of Object.keys(eventHandlerLookup)) {
			document.addEventListener(linkChartEventLookup[eventType], eventHandlerLookup[eventType]);
		}
		window.addEventListener("resize", debouncedHandleResize);
		if (d3ContainerRef.current) d3ContainerRef.current.addEventListener("click", handleChartClick);
	}, [cleanUpEventListeners, eventHandlerLookup, debouncedHandleResize, d3ContainerRef, handleChartClick]);

	useEffect(() => {
		if (!handleResize) return;
		handleResize(chartRef, d3ContainerRef.current);
	}, [chartRef, d3ContainerRef, handleResize]);

	useOnEvent({ setupFunction: setupEventSubscriptions, cleanupFunction: cleanUpEventListeners });
};

export default useLinkChartEventHandlers;
