/* eslint-disable max-depth */
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import { findUpdates, removeUndefined } from "@clearpoint/utils";

let generateElementListOnSubmitFunction =
	({ clear, elementListName, set, session }) =>
	(submitValue, { formDefaultValue }) => {
		if (!submitValue[elementListName]) return;
		let elementList = cloneDeep(submitValue[elementListName]);
		for (const [i, element] of elementList.entries()) {
			let updates = [];
			let edits = [];
			if (element) {
				let defaultValue = formDefaultValue[elementListName][i];
				let { object, objectId, periodId, status } = element || {};
				if (status) {
					// eslint-disable-next-line guard-for-in
					for (let periodId in status) {
						let originalStatus = defaultValue.status[periodId];
						let updatedStatus = status[periodId];
						let fields = findUpdates({ original: originalStatus, updated: updatedStatus });
						if (fields?.calculatedValues) {
							delete fields.calculatedValues;
						}
						if (!isEmpty(fields))
							updates.push({
								fields,
								object,
								objectId,
								periodId: originalStatus.reportPeriodId || originalStatus.periodId,
							});
					}
					delete element.status;
				}
				let fields = findUpdates({ original: defaultValue, updated: element });
				if (fields.seriesData) {
					let updatedSeries = findUpdates({ original: defaultValue.seriesData, updated: element.seriesData });
					updatedSeries = Object.values(updatedSeries);
					edits.push({
						fields: {
							measureData: updatedSeries.map((x) => ({
								periodId: x.periodId,
								[`series${element.measureSeriesId}`]: x.value,
							})),
						},
						object: "measure",
						objectId: element.measureId,
					});
				}
				delete fields.seriesData;
				if (fields.percentComplete || fields.statusId) {
					let { periodId: sessionPeriodId } = session;
					let updateFields = removeUndefined({
						percentComplete: fields.percentComplete,
						statusId: fields.statusId,
					});
					updates.push({
						fields: updateFields,
						object,
						objectId,
						periodId: periodId || sessionPeriodId,
					});
					delete fields.percentComplete;
					delete fields.statusId;
				}
				if (!isEmpty(fields))
					edits.push({
						fields,
						object,
						objectId,
					});
			}
			let data = { edits, updates };
			if (edits.length > 0 || updates.length > 0) {
				set({
					object: "update",
					data,
				}).then(() => {
					// let clearedObjectLookup = {};
					// let clearChanged = (change) => {
					// 	let { object, objectId } = change;
					// 	if (!clearedObjectLookup[object + objectId]) {
					// 		clear({ object, objectId });
					// 		clearedObjectLookup[object + objectId] = true;
					// 	}
					// };
					// for (let edit of edits) clearChanged(edit);
					// for (let update of updates) clearChanged(update);
				});
			}
		}
	};

export default generateElementListOnSubmitFunction;
