import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faBell as farBell,
	faBellSlash as farBellSlash,
	faBuilding as farBuilding,
	faCalendar as farCalendar,
	faCalendarAlt as farCalendarAlt,
	faCalendarCheck as farCalendarCheck,
	faCheckSquare as farCheckSquare,
	faClipboard as farClipboard,
	faClock as farClock,
	faClone as farClone,
	faComment as farComment,
	faCopy as farCopy,
	faEnvelope as farEnvelope,
	faFile as farFile,
	faFileCode as farFileCode,
	faFileExcel as farFileExcel,
	faFilePdf as farFilePdf,
	faFilePowerpoint as farFilePowerpoint,
	faFileWord as farFileWord,
	faHandshake as farHandshake,
	faLifeRing as farLifeRing,
	faMoneyBillAlt as farMoneyBillAlt,
	faSave as farSave,
	faSquare as farSquare,
	faStar as farStar,
	faThumbsUp as farThumbsUp,
	faTrashAlt as farTrashAlt,
	faWindowRestore as farWindowRestore,
} from "@fortawesome/free-regular-svg-icons";
import {
	faArrowUpToLine as farArrowUpToLine,
	faArchive as farArchive,
	faBellExclamation as farBellExclamation,
	faCircleQuestion as farCircleQuestion,
	faCubes as farCubes,
	faDiamondExclamation as farDiamondExclamation,
	faFaceLaughBeam as farFaceLaughBeam,
	faGear as farGear,
	faHeart as farHeart,
	faHome as farHome,
	faHouse as farHouse,
	faMicrochipAi as farMicrochipAi,
	faPaste as farPaste,
	faPen as farPen,
	faPenToSquare as farPenToSquare,
	faQuestion as farQuestion,
	faSitemap as farSitemap,
	faTable as farTable,
	faTally2 as farTally2,
	faTh as farTh,
	faThumbsDown as farThumbsDown,
	faTrophy as farTrophy,
	faUniversalAccess as farUniversalAccess,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
	farArrowUpToLine,
	farArchive,
	farBell,
	farBellExclamation,
	farBellSlash,
	farBuilding,
	farCalendar,
	farCalendarAlt,
	farCalendarCheck,
	farCheckSquare,
	farCircleQuestion,
	farClipboard,
	farClock,
	farClone,
	farComment,
	farCopy,
	farCubes,
	farDiamondExclamation,
	farEnvelope,
	farFaceLaughBeam,
	farFile,
	farFileCode,
	farFileExcel,
	farFilePdf,
	farFilePowerpoint,
	farFileWord,
	farGear,
	farHandshake,
	farHeart,
	farHome,
	farHouse,
	farLifeRing,
	farMicrochipAi,
	farMoneyBillAlt,
	farPaste,
	farPen,
	farPenToSquare,
	farQuestion,
	farSave,
	farSitemap,
	farSquare,
	farStar,
	farStar,
	farTable,
	farTally2,
	farTh,
	farThumbsDown,
	farThumbsUp,
	farTrashAlt,
	farTrophy,
	farUniversalAccess,
	farWindowRestore
);
