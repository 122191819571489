import Button from "./Button";
import Icon from "../Icon/Icon";
import ModalOnClick from "../Modal/Modal.OnClick";
import ModalWindowOnClick from "../ModalWindow/ModalWindow.OnClick";
import PropTypes from "prop-types";
import classNames from "classnames";

let propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

let EditButtonInner = (props) => {
	let reportFlag = !!props.layoutId || props.reportFlag;
	return (
		<Button
			data-testid="edit-button"
			padding="3px 6px"
			color="white"
			size="tiny"
			className={classNames(props.className, "js-edit-button")}
			{...props}
		>
			<Icon
				name={reportFlag ? "editReport" : "edit"}
				transform={reportFlag ? "up-2 right-1" : "up-1"}
				color={props.iconColor}
			/>
		</Button>
	);
};

EditButtonInner.propTypes = propTypes;

let editButtonPropTypes = {
	ModalWindow: PropTypes.elementType,
	Modal: PropTypes.elementType,
	objectId: PropTypes.number,
};

let EditButton = ({ ModalWindow, Modal, objectId, modalWindowProps, ...props }) =>
	ModalWindow ? (
		<ModalWindowOnClick ModalWindow={ModalWindow} objectId={objectId} {...props} {...modalWindowProps}>
			<EditButtonInner {...props} />
		</ModalWindowOnClick>
	) : Modal ? (
		<ModalOnClick Modal={Modal} objectId={objectId} {...props}>
			<EditButtonInner {...props} />
		</ModalOnClick>
	) : (
		<EditButtonInner {...props} />
	);

EditButton.propTypes = editButtonPropTypes;

export default EditButton;
