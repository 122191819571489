import { useOldQueryStore } from "@clearpoint/old-query-store";

let useElementData = ({ object, objectId, periodId, scorecardId }) => {
	let { get } = useOldQueryStore();

	let scorecardFlag = object === "scorecard";

	let elementQuery =
		scorecardFlag && scorecardId ? { object: "scorecard", objectId: scorecardId } : { object, objectId };

	let elementStatusQuery =
		scorecardFlag && scorecardId
			? { object: "scorecard", objectId: scorecardId, periodId }
			: { object, objectId, periodId };

	return {
		element: elementQuery && get(elementQuery),
		elementQuery,
		elementStatusQuery,
		elementStatus: elementStatusQuery && get(elementStatusQuery),
	};
};

export default useElementData;
