import { useCallback, useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import getDeepValue from "lodash/get";
import useFormState from "./useFormState";
import createErrorMessageRouter from "./createErrorMessageRouter";
import validate from "./validate";
import { useFormatDate } from "@clearpoint/hooks";


let useFormError = ({ formContextRef }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let errorMessageRouter = useMemo(() => createErrorMessageRouter({ formatDate, translate }), [formatDate, translate]);

	let [formError, setFormError, formErrorRef] = useFormState({}, "formError");

	let updateFormError = useCallback(
		async ({ name, stateName, value }) => {
			let { formValidationRef } = formContextRef.current;
			let validation;
			if (stateName === "formValidation") {
				validation = value?._validation;
				let { formValueRef } = formContextRef.current;
				value = getDeepValue(formValueRef.current, name);
			}
			if (stateName === "formValue") {
				validation = getDeepValue(formValidationRef.current, name)?._validation;
			}
			validate({ errorMessageRouter, formContextRef, name, setFormError, validation, value });
		},
		[errorMessageRouter, formContextRef, setFormError]
	);
	// eslint-disable-next-line react-hooks/exhaustive-deps

	return { formError, updateFormError, formErrorRef };
};

export default useFormError;
