import { useCallback, useMemo } from "react";
import useFormState from "./useFormState";
import getDeepValue from "lodash/get";
import { emptyArray, emptyObject } from "@clearpoint/utils";


let useFormClear = ({ formContextRef }) => {
	let [, setSavedFormValue, savedFormValueRef] = useFormState(emptyObject, "savedFormValue", emptyArray, true);
	let [, setSavedFormValidation, savedFormValidationRef] = useFormState(emptyObject, "savedFormValidation");
	let clearFormValue = useCallback(
		(name) => {
			let { setFormValue, formValidationRef, setFormValidation, customFormErrorRef, setFormError } =
				formContextRef.current;
			setFormValue(name, (value) => {
				if (value !== undefined) {
					setSavedFormValue(name, value);
					return undefined;
				}
			});
			let validation = getDeepValue(formValidationRef.current, name);
			if (validation !== undefined) {
				setSavedFormValidation(name, validation);
				setFormValidation(name, undefined);
			}
			let error = getDeepValue(customFormErrorRef.current, name);
			if (error !== undefined) {
				setFormError(name, undefined);
			}
		},
		[formContextRef, setSavedFormValidation, setSavedFormValue]
	);
	let restoreFormValue = useCallback(
		(name) => {
			let { setFormValue, setFormValidation } = formContextRef.current;
			let value = getDeepValue(savedFormValueRef.current, name);
			if (value !== undefined) {
				setFormValue(name, value);
			}
			let validation = getDeepValue(savedFormValidationRef.current, name);
			if (validation !== undefined) {
				setFormValidation(name, validation);
			}
		},
		[formContextRef, savedFormValidationRef, savedFormValueRef]
	);
	return useMemo(
		() => ({ clearFormValue, restoreFormValue, setSavedFormValue }),
		[clearFormValue, restoreFormValue, setSavedFormValue]
	);
};
export default useFormClear;
