import AttachmentError from "./AttachmentError";
import { Document, Page } from "react-pdf";
import { useCallback, useState } from "react";

let PDFAttachment = ({ attachment, height, width }) => {
	let { attachmentId, link } = attachment;

	let pdfUrl = link.url;
	let [pageCount, setPageCount] = useState(null);

	let onDocumentLoadSuccess = useCallback(({ numPages }) => {
		setPageCount(numPages);
	}, []);

	let createImageFromPage = useCallback(
		({ pageNumber }) => {
			let pageElement = document.querySelector(`.attachment-${attachmentId}-page-${pageNumber} canvas`);
			let containerElement = document.createElement("div");
			containerElement.style.height = height + "px";
			let imageElement = document.createElement("img");
			imageElement.src = pageElement?.toDataURL();
			imageElement.style.overflowY = "hidden";
			imageElement.style.height = "100%";
			if (pageElement) pageElement.parentNode.style.display = "none";
			document.querySelector(`.attachment-document-${attachmentId}`).appendChild(containerElement);
			containerElement.appendChild(imageElement);
		},
		[attachmentId, height]
	);

	return (
		<Document
			className={`attachment-document-${attachmentId} page-break`}
			file={pdfUrl}
			onLoadSuccess={onDocumentLoadSuccess}
			error={<AttachmentError attachment={attachment} />}
		>
			{Array.from(new Array(pageCount), (_el, index) => (
				<Page
					className={`attachment-${attachmentId}-page-${index + 1}`}
					height={height}
					width={width}
					key={`page_${index + 1}`}
					pageNumber={index + 1}
					onRenderSuccess={createImageFromPage}
				/>
			))}
		</Document>
	);
};

export default PDFAttachment;
