import { useCallback } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import buildImagePath from "./useBuildImagePath.buildImagePath";
import { emptyObject } from "@clearpoint/utils";

let useBuildImagePath = () => {
	let session = useOldSession()?.session;
	let { environment, exportFlag, htmlExportFlag, rootPath, token } = session ?? emptyObject;

	return useCallback(
		(imageValue) => {
			let buildImagePathCallback = buildImagePath({ environment, exportFlag, htmlExportFlag, rootPath, token });
			return buildImagePathCallback(imageValue);
		},
		[environment, exportFlag, htmlExportFlag, rootPath, token]
	);
};

export default useBuildImagePath;
