import Block from "@clearpoint/old-theme/Block";
import ChecklistCheck from "@clearpoint/old-theme/Checklist/Checklist.Check";
import DeletedBadge from "@clearpoint/old-theme/Badge/Badge.Deleted";
import DropdownItemTrash from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Trash";
import DropdownItemCopy from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Copy";
import DropdownItemEdit from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Edit";
import EditChartModal from "./EditChartModal";
import FormDragAndDropListItem from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Item";
import KebabButton from "@clearpoint/old-theme/Dropdown/Kebab/KebabButton";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import TagList from "@clearpoint/old-theme/Tag/Tag.List";
import TrashButton from "@clearpoint/old-theme/Button/Button.Trash";
import UndeleteButton from "@clearpoint/old-theme/Button/Button.Undelete";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";
import { useMeasure } from "@clearpoint/hooks";

let propTypes = {
	chart: PropTypes.shape({
		active: PropTypes.bool,
		chartId: PropTypes.number,
		deleteDate: PropTypes.string,
		lastEdited: PropTypes.string,
		name: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		sortOrder: PropTypes.number,
		tags: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.shape({
					color: PropTypes.string,
					name: PropTypes.string,
					tagId: PropTypes.number,
				}),
			])
		),
		visible: PropTypes.bool,
	}),
};

let ChartListItem = ({ chart }) => {
	let translate = useTranslate();
	let { measureId, shadowMeasureId } = useFormValue();
	let { active, chartId, deleteDate, lastEdited, name, objectId, tags: tagList, visible } = chart;
	let copyDefaultValue = useMemo(
		() => ({
			name,
			measureId: measureId || shadowMeasureId,
		}),
		[measureId, name, shadowMeasureId]
	);
	let { width: containerWidth } = useMeasure({ query: ".list-item-container" }) || emptyObject;
	let largeScreenFlag = containerWidth > 800;
	return (
		<FormDragAndDropListItem
			border={`1px solid ${theme.lightGray}`}
			boxShadow={theme.modalListItemBoxShadow}
			visible={visible}
			lastEdited={lastEdited}
			color="desaturatedNormal"
			left={
				<Block ellipsisFlag display="flex" alignItems="center">
					<StyleWrapper flexShrink="0">
						<ChecklistCheck objectId={objectId} />
					</StyleWrapper>
					<ModalWindowOnClick
						ModalWindow={EditChartModal}
						measureId={measureId || shadowMeasureId}
						object="chart"
						objectId={chartId}
					>
						<Block
							marginLeft={theme.space}
							ellipsisFlag
							as="a"
							cursor="pointer"
							display="inline-block"
							fontWeight={theme.boldish}
						>
							{name}
						</Block>
					</ModalWindowOnClick>
					{largeScreenFlag && (
						<>
							<TagList tags={tagList} />
							{!active && <DeletedBadge deleteDate={deleteDate} />}
						</>
					)}
				</Block>
			}
			right={
				active ? (
					<>
						<TrashButton object="chart" objectId={chartId} name={name} />
						<KebabButton>
							<DropdownItemEdit
								data-testid="edit-button"
								ModalWindow={EditChartModal}
								measureId={measureId || shadowMeasureId}
								object="chart"
								objectId={chartId}
							/>
							<DropdownItemCopy
								data-testid="copy-button"
								title={translate("manage.chart.duplicateChart")}
								parent="measure"
								parentId={measureId || shadowMeasureId}
								object="chart"
								objectId={chartId}
								duplicateRouteFlag
								submitUnchangedFlag
								defaultValue={copyDefaultValue}
								toast={translate("toaster.messages.objects.objectsDuplicated", {
									object: translate("global.chart"),
								})}
							/>
							<DropdownItemTrash data-testid="trash-button" object="chart" objectId={chartId} name={name} />
						</KebabButton>
					</>
				) : (
					<UndeleteButton
						object="chart"
						objectId={chartId}
						name={name}
						toast={translate("edit.element.chartUndeleted")}
					/>
				)
			}
		/>
	);
};

ChartListItem.propTypes = propTypes;

export default ChartListItem;
