import Block from "../Block";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import StyleWrapper from "../StyleWrapper";
import { useCallback, useMemo } from "react";
import { linkChartEventLookup, dispatchLinkChartEvent } from "./LinkChart";
import { theme } from "../Theme";

let ControlButton = (props) => (
	<StyleWrapper border={`1px solid ${theme.gray}`} borderColor={theme.gray}>
		<Button borderRadius="0" color="default" {...props} />
	</StyleWrapper>
);

let LinkChartControls = () => {
	let orderedEventList = useMemo(() => ["fit", "rotate", "zoomIn", "zoomOut", "expandChart", "collapseChart"], []);

	let publishLinkChartEvent = useCallback((eventType) => {
		dispatchLinkChartEvent(linkChartEventLookup[eventType] || eventType);
	}, []);

	return (
		<Block
			padding="0px"
			alignItems="stretch !important"
			border="none !important"
			$style="*:not(:last-child) { border-radius: 0}"
		>
			{orderedEventList.map((eventType) => (
				<ControlButton
					key={eventType}
					data-testid={`btn-${eventType}`}
					onClick={publishLinkChartEvent.bind(null, eventType)}
				>
					<Icon name={eventType} rotation={eventType === "fit" ? "45deg" : undefined} />
				</ControlButton>
			))}
		</Block>
	);
};

export default LinkChartControls;
