import orderBy from "lodash/orderBy";


let sortSeries = (seriesDataset, sortDirection) => {
	seriesDataset.forEach(function (item) {
		item.firstValue = item.data[0];
	});
	seriesDataset = orderBy(seriesDataset, [(x) => x.firstValue], sortDirection);
	seriesDataset.forEach(function (item, index) {
		item.zIndex = index;
	});
	return seriesDataset;
};
export default sortSeries;
