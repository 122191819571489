import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";

// NOTE: When updating style wrapper, update the mock style wrapper with the same logic! (theme/__mocks__/StyleWrapper.js)

let stylePropTypes = {
	alignContent: PropTypes.string,
	alignItems: PropTypes.string,
	alignSelf: PropTypes.string,
	animation: PropTypes.string,
	background: PropTypes.string,
	backgroundColor: PropTypes.string,
	backgroundImage: PropTypes.string,
	border: PropTypes.string,
	borderBottom: PropTypes.string,
	borderColor: PropTypes.string,
	borderImage: PropTypes.string,
	borderLeft: PropTypes.string,
	borderBottomLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	borderBottomRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	borderTopLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	borderTopRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	borderRight: PropTypes.string,
	borderStyle: PropTypes.string,
	borderTop: PropTypes.string,
	borderWidth: PropTypes.string,
	bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	boxShadow: PropTypes.string,
	boxSizing: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
	columnGap: PropTypes.string,
	cursor: PropTypes.string,
	display: PropTypes.string,
	ellipsisFlag: PropTypes.bool,
	filter: PropTypes.string,
	flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	flexDirection: PropTypes.oneOf(["column", "column-reverse", "row", "row-reverse"]),
	flexGrow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	flexShrink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	flexWrap: PropTypes.string,
	float: PropTypes.oneOf(["left", "right", "none"]),
	fontFamily: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	gap: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	justifyContent: PropTypes.string,
	left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	listStylePosition: PropTypes.string,
	margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	objectFit: PropTypes.string,
	objectPosition: PropTypes.string,
	opacity: PropTypes.number,
	order: PropTypes.number,
	outline: PropTypes.string,
	outlineOffset: PropTypes.string,
	overflow: PropTypes.string,
	overflowX: PropTypes.string,
	overflowY: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeItems: PropTypes.string,
	pointerEvents: PropTypes.string,
	position: PropTypes.string,
	right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	rowGap: PropTypes.string,
	scrollMargin: PropTypes.string,
	textAlign: PropTypes.string,
	textDecoration: PropTypes.string,
	textOverflow: PropTypes.string,
	textTransform: PropTypes.string,
	top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	transform: PropTypes.string,
	transformOrigin: PropTypes.string,
	transition: PropTypes.string,
	userSelect: PropTypes.string,
	verticalAlign: PropTypes.string,
	visibility: PropTypes.string,
	whiteSpace: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	wordBreak: PropTypes.string,
	wordWrap: PropTypes.string,
	zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	$style: PropTypes.string,
};

let nonStyleProps = {
	className: PropTypes.string,
	"data-testid": PropTypes.string,
};

let Wrapper = ({ children, className, "data-testid": dataTestId, ...props }) => {
	let childProps = Object.keys(props)
		.filter((p) => !Object.keys(stylePropTypes).includes(p))
		.reduce((obj, key) => {
			return {
				...obj,
				[key]: props[key],
			};
		}, {});

	return React.Children.map(children, (child) =>
		React.isValidElement(child) ? (
			React.cloneElement(child, {
				...childProps,
				className: classNames(className, child.props.className),
				"data-testid": dataTestId || child.props["data-testid"],
			})
		) : child ? (
			<span className={className} data-testid={dataTestId} {...childProps}>
				{child}
			</span>
		) : null
	);
};

let StyleWrapper = (X) => styled(X)`
	align-content: ${(props) => props.alignContent};
	align-items: ${(props) => props.alignItems};
	align-self: ${(props) => props.alignSelf};
	animation: ${(props) => props.animation};
	background-color: ${(props) => props.theme[props.backgroundColor] || props.backgroundColor};
	background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : undefined)};
	background: ${(props) => props.theme[props.background] || props.background};
	// sort divider for border-radius properties
	border-radius: ${(props) => props.borderRadius}; // cascade above other border-radius properties
	border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
	border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
	border-top-left-radius: ${(props) => props.borderTopLeftRadius};
	border-top-right-radius: ${(props) => props.borderTopRightRadius};
	// sort divider for border propeties
	border: ${(props) => props.border}; // cascade above other border properites
	border-bottom: ${(props) => props.borderBottom};
	border-color: ${(props) => props.borderColor};
	border-image: ${(props) => props.borderImage};
	border-left: ${(props) => props.borderLeft};
	border-right: ${(props) => props.borderRight};
	border-style: ${(props) => props.borderStyle};
	border-top: ${(props) => props.borderTop};
	border-width: ${(props) => props.borderWidth};
	// end border properties
	bottom: ${(props) => props.bottom};
	box-shadow: ${(props) => props.boxShadow};
	box-sizing: ${(props) => props.boxSizing};
	color: ${(props) => props.theme[props.color] || props.color};
	column-gap: ${(props) => props.columnGap};
	cursor: ${(props) => props.cursor};
	display: ${(props) => props.display};
	filter: ${(props) => props.filter};
	flex-basis: ${(props) => props.flexBasis};
	flex-direction: ${(props) => props.flexDirection};
	flex-grow: ${(props) => props.flexGrow};
	flex-shrink: ${(props) => props.flexShrink};
	flex-wrap: ${(props) => props.flexWrap};
	flex: ${(props) => props.flex};
	float: ${(props) => props.float};
	font-family: ${(props) => props.fontFamily};
	font-size: ${(props) => props.fontSize};
	font-style: ${(props) => props.theme[props.fontStyle] || props.fontStyle};
	font-weight: ${(props) => props.theme[props.fontWeight] || props.fontWeight};
	gap: ${(props) => props.gap};
	height: ${(props) => props.height};
	justify-content: ${(props) => props.justifyContent};
	left: ${(props) => props.left};
	line-height: ${(props) => props.lineHeight};
	list-style-position: ${(props) => props.listStylePosition};
	margin-bottom: ${(props) => props.marginBottom};
	margin-left: ${(props) => props.marginLeft};
	margin-right: ${(props) => props.marginRight};
	margin-top: ${(props) => props.marginTop};
	margin: ${(props) => props.margin};
	max-height: ${(props) => props.maxHeight};
	max-width: ${(props) => props.maxWidth};
	min-height: ${(props) => props.minHeight};
	min-width: ${(props) => props.minWidth};
	object-fit: ${(props) => props.objectFit};
	object-position: ${(props) => props.objectPosition};
	opacity: ${(props) => props.opacity};
	order: ${(props) => props.order};
	outline-offset: ${(props) => props.outlineOffset};
	outline: ${(props) => props.outline};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
	overflow: ${(props) => props.overflow || (props.ellipsisFlag ? "hidden" : undefined)};
	padding-bottom: ${(props) => props.paddingBottom};
	padding-left: ${(props) => props.paddingLeft};
	padding-right: ${(props) => props.paddingRight};
	padding-top: ${(props) => props.paddingTop};
	padding: ${(props) => props.padding};
	place-items: ${(props) => props.placeItems};
	pointer-events: ${(props) => props.pointerEvents};
	position: ${(props) => props.position};
	right: ${(props) => props.right};
	scroll-margin: ${(props) => props.scrollMargin};
	row-gap: ${(props) => props.rowGap};
	text-align: ${(props) => props.textAlign};
	text-overflow: ${(props) => props.textOverflow || (props.ellipsisFlag ? "ellipsis" : undefined)};
	text-transform: ${(props) => props.textTransform};
	text-decoration: ${(props) => props.textDecoration};
	top: ${(props) => props.top};
	transform: ${(props) => props.transform || props.$transform || undefined};
	transform-origin: ${(props) => props.transformOrigin || undefined};
	transition: ${(props) => props.transition};
	user-select: ${(props) => props.userSelect};
	vertical-align: ${(props) => props.verticalAlign};
	visibility: ${(props) => props.visibility};
	white-space: ${(props) => props.whiteSpace || (props.ellipsisFlag ? "nowrap" : undefined)};
	width: ${(props) => props.width};
	word-break: ${(props) => props.wordBreak};
	word-wrap: ${(props) => props.wordWrap};
	z-index: ${(props) => props.zIndex};
	${(props) => props.$style};
`;

Wrapper.propTypes = { ...stylePropTypes, ...nonStyleProps };

export default memo(StyleWrapper(Wrapper));
export { StyleWrapper };
