import Block from "@clearpoint/old-theme/Block";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterProvider from "@clearpoint/providers/FilterProvider";
import LinkChart from "@clearpoint/old-theme/LinkChart/LinkChart";
import LinkChartControls from "@clearpoint/old-theme/LinkChart/LinkChartControls";
import Loading from "@clearpoint/old-theme/Loading/index";
import ModalPreview from "@clearpoint/old-theme/Modal/Modal.Preview";
import { Suspense, useCallback, useMemo } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";
import { useStateObject } from "@clearpoint/hooks";

let CalculationMap = () => {
	let { calculation, measureSeriesId } = useFormValue();
	let { periodId } = useOldSession()?.session || emptyObject;
	let translate = useTranslate();
	let filterProps = useMemo(() => ["name", "scorecard", "periodGroup", "period"], []);

	let [{ previewObject, previewModalVisible }, setState] = useStateObject({
		previewModalVisible: false,
		previewObject: null,
	});

	let togglePreviewModal = useCallback(
		(calculationMapPreviewObject) => {
			setState({
				previewModalVisible: (x) => !x,
				previewObject: calculationMapPreviewObject,
			});
		},
		[setState]
	);

	// measure series data
	let { get, getPromise } = useOldQueryStore();
	let calculationMapList =
		measureSeriesId &&
		periodId &&
		get({
			object: "map",
			parent: "measureSeries",
			parentId: measureSeriesId,
			periodId,
			payload: calculation ? { calculation } : undefined,
		});
	let openPreviewModal = useCallback(
		(e) => {
			let element = e.target.closest("#linkChartPreviewBtn");
			if (!element) return null;

			let { measureid: measureId, calculation, name } = element.dataset || emptyObject;
			periodId &&
				measureId &&
				calculation &&
				getPromise({
					object: "preview",
					queryString: "?mode=series",
					payload: { periodId, measureId, calculation },
				}).then((data) => {
					togglePreviewModal({ ...data, name });
				});
		},
		[getPromise, periodId, togglePreviewModal]
	);

	return !calculationMapList ? (
		<Loading />
	) : (
		<FilterProvider data={calculationMapList}>
			<Block display="flex" flexDirection="column" height="100%">
				<FilterInput
					name="highlight"
					placeholder={translate("edit.element.highlightCalculation")}
					filterBy={filterProps}
					right={<LinkChartControls />}
				/>

				<Suspense fallback={<Loading />}>
					<LinkChart
						chartClickCallback={openPreviewModal}
						linkList={calculationMapList}
						nodeWidth={275}
						nodeHeight={170}
					/>
				</Suspense>

				<ModalPreview
					body={`<div style="line-height:200%">${previewObject?.preview}</div>`}
					close={togglePreviewModal}
					modalVisible={previewModalVisible}
					noFormFlag
					title={previewObject?.name}
				/>
			</Block>
		</FilterProvider>
	);
};

export default CalculationMap;
