import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import FormSelectSeriesDataType from "@clearpoint/old-theme/Form/Select/Select.SeriesDataType";
import FormSelectTag from "@clearpoint/old-theme/Form/Select/Select.Tag";
import Help from "@clearpoint/old-theme/Help";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	parentId: PropTypes.number.isRequired,
};

let SeriesTab = ({ parentId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let measureSeriesList = get({ parent: "measure", parentId, object: "measureSeries" });
	let measureSeriesNameList = useMemo(() => measureSeriesList.map((series) => series.name), [measureSeriesList]);

	return (
		<>
			<Help section="sect5" feature="edit-series" />
			<FormFieldName
				label={translate("edit.seriesName")}
				placeholder={translate("edit.seriesName")}
				name="name"
				notEndingWithCalcWordFlag
				object="measureSeries"
				measureSeriesNameList={measureSeriesNameList}
			/>
			<FormSelectTag name="tags" multipleFlag />
			<FormSelectSeriesDataType
				label={translate("series.dataType")}
				name="seriesDataTypeId"
				currency="currency"
				numberFormat="numberFormatId"
			/>
			<FormCheck name="hiddenSeries" text={translate("edit.hideSeries")} />
			<FormCheck name="hiddenSummary" text={translate("edit.hideSummary")} />
			<FormCheck name="locked" text={translate("edit.lockSeriesForUpdaters")} />
		</>
	);
};

SeriesTab.propTypes = propTypes;

export default SeriesTab;
