import FormSelect from "../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectAggregateRiskType = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("edit.allRisks"),
				value: 0,
			},
			{
				label: translate("edit.riskNameEquals"),
				value: 3,
			},
			{
				label: translate("edit.riskNameContains"),
				value: 4,
			},
			{
				label: translate("edit.series.risksTaggedWith"),
				value: 7,
			},
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectAggregateRiskType;
