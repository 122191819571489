import CellWrapper from "../CellWrapper";
import FormFieldDisplayChart from "@clearpoint/old-theme/Form/Field/Display/Chart";
import PropTypes from "prop-types";

let propTypes = {
	parentName: PropTypes.string,
};
let ChartCell = (props) => {
	let { parentName } = props;
	return (
		<CellWrapper {...props}>
			<FormFieldDisplayChart name={parentName} />
		</CellWrapper>
	);
};
ChartCell.propTypes = propTypes;

export default ChartCell;
