import { useCallback, useEffect } from "react";
import { useStateObject } from "@clearpoint/hooks";

let useContextMenu = (ref) => {
	let [{ x, y, visible }, setState] = useStateObject({
		x: 0,
		y: 0,
		visible: false,
	});
	let showContextMenu = useCallback(
		(e) => {
			e.preventDefault();
			setState({
				x: e.pageX,
				y: e.pageY,
				visible: true,
			});
		},
		[setState]
	);
	let hideContextMenu = useCallback(
		(e) => {
			if (e.type === "click") setState({ visible: false });
			if (!ref.current || ref.current === e.target || ref.current.contains(e.target)) return;
			if (visible) setState({ visible: false });
		},
		[ref, setState, visible]
	);
	useEffect(() => {
		document.addEventListener("click", hideContextMenu);
		document.addEventListener("contextmenu", hideContextMenu);
		return () => {
			document.removeEventListener("click", hideContextMenu);
			document.removeEventListener("contextmenu", hideContextMenu);
		};
	});
	return { x, y, visible, showContextMenu };
};
export default useContextMenu;
