let buildToken = ({ fieldId, measureId, object, objectId, scorecardId }) => {
	if (object === "scorecard" && !objectId) objectId = scorecardId;
	if (objectId === undefined) return "";
	if (fieldId === undefined && !object.endsWith("Layout") && object !== "map") return "";
	if (object.endsWith("Layout") && object !== "scorecardLayout")
		return objectId !== undefined ? `{[s${scorecardId}][layout${objectId}]}` : "";
	let buildTokenLookup = {
		actionItem: () => `{[a${objectId}][${fieldId}]}`,
		initiative: () => `{[i${objectId}][${fieldId}]}`,
		measure: () => `{[m${objectId}][${fieldId}]}`,
		milestone: () => `{[t${objectId}][${fieldId}]}`,
		objective: () => `{[o${objectId}][${fieldId}]}`,
		risk: () => `{[r${objectId}][${fieldId}]}`,
		scorecard: () => `{[s${objectId}][${fieldId}]}`,
		chart: () => `{[m${measureId}][chart${objectId}${fieldId ? "|" + fieldId : ""}]}`,
		map: () => `{[p${objectId}][link]}`,
		scorecardLayout: () => `{[s${scorecardId}][dashboard${objectId}]}`,
		series: () => `{[m${measureId}][@${objectId}]}`,
		measureSeries: () => `{[m${measureId}][@${objectId}]}`,
		seriesStatus: () => `{[m${measureId}][*${objectId}]}`,
	};
	if (fieldId?.startsWith("series")) return buildTokenLookup[fieldId]();
	return buildTokenLookup[object]();
};
export default buildToken;
