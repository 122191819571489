import { useCallback } from "react";
import { useStateObject } from "@clearpoint/hooks";

let useWindowState = () => {
	let [{ helpFlag, maximizeFlag }, setState] = useStateObject({
		helpFlag: false,
		maximizeFlag: false,
	});
	let toggleHelpFlag = useCallback(
		() =>
			setState({
				helpFlag: (x) => !x,
			}),
		[setState]
	);
	let toggleSize = useCallback(
		() =>
			setState({
				maximizeFlag: (x) => !x,
			}),
		[setState]
	);
	return { helpFlag, maximizeFlag, toggleHelpFlag, toggleSize };
};

export default useWindowState;
