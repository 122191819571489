import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { useCallback, useState } from "react";
import useChartPreviewPromise from "../useChartPreviewPromise";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { theme } from "@clearpoint/old-theme/Theme";
import { beforeChartSubmit } from "@clearpoint/utils";
import { useFormatChart } from "@clearpoint/hooks";



let propTypes = {
	initialChartLoadedFlag: PropTypes.bool,
	setChartFormatData: PropTypes.func,
};

let PreviewButton = ({ initialChartLoadedFlag, setChartFormatData }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let formatChart = useFormatChart();
	let formValue = useFormValue();
	let getChartPreviewPromise = useChartPreviewPromise();
	let [previewGeneratingFlag, setPreviewGeneratingFlag] = useState(false);

	let measureGridList = get({ parent: "measure", parentId: formValue.measureId, object: "measureGrid" });

	let generatePreview = useCallback(() => {
		setPreviewGeneratingFlag(true);
		let chartData = beforeChartSubmit(formValue);

		getChartPreviewPromise(chartData)
			.then((response) => {
				setPreviewGeneratingFlag(false);
				setChartFormatData(formatChart({ chartData: response, measureGridList }));
			})
			.catch(() => {
				toast.error(translate("chart.previewError"));
				setPreviewGeneratingFlag(false);
			});
	}, [formValue, getChartPreviewPromise, formatChart, measureGridList, setChartFormatData, translate]);

	return (
		<Block flexShrink="0" marginLeft={theme.smallSpace}>
			<Button
				color="info"
				size="tiny"
				alignSelf="start"
				onClick={generatePreview}
				disabled={!initialChartLoadedFlag || previewGeneratingFlag}
			>
				{previewGeneratingFlag ? (
					translate("edit.chart.pleaseWait")
				) : (
					<>
						<Icon name="recalculate" marginRight={theme.tinySpace} />
						{translate("edit.chart.refreshPreview")}
					</>
				)}
			</Button>
		</Block>
	);
};

PreviewButton.propTypes = propTypes;
export default PreviewButton;
