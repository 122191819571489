import Alert from "./Alert";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	percentLoaded: PropTypes.number,
};

let LoadingAlert = ({ percentLoaded }) => {
	let translate = useTranslate();
	return (
		<Alert color="info" className="cps-loading">
			{translate("global.loadingPercent", { complete: percentLoaded })}
		</Alert>
	);
};

LoadingAlert.propTypes = propTypes;
export default LoadingAlert;
