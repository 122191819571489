import { useState } from "react";

function useStatefulRef(initialVal = null) {
	let [currentVal, setCurrentVal] = useState(initialVal);

	const [statefulRef] = useState({
		// eslint-disable-next-line getter-return
		get current() {},
		set current(value) {},
	});

	Object.defineProperty(statefulRef, "current", {
		get: () => currentVal,
		set: (newValue) => {
			if (!Object.is(currentVal, newValue)) {
				currentVal = newValue;
				setCurrentVal(newValue);
			}
		},
	});

	return statefulRef;
}

export default useStatefulRef;
