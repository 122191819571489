import FormField from "@clearpoint/old-theme/Form/Field/Field";
import FormSelectField from "@clearpoint/old-theme/Form/Select/Select.Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useFieldList } from "@clearpoint/hooks";


let EditFieldsTab = () => {
	let fieldList = useFieldList({ object: "chart" });
	let selectedFieldList = useFormValue("_selectedFieldList");
	let displayedFieldList = useMemo(
		() => fieldList && fieldList.filter((field) => selectedFieldList?.includes(field.value)),
		[selectedFieldList, fieldList]
	);
	return (
		<>
			<FormSelectField object="chart" name="_selectedFieldList" multipleFlag />
			{displayedFieldList?.map((field) => (
				<FormField editFlag fieldId={field.value} key={field.value} label={field.label} object="chart" />
			))}
		</>
	);
};

export default EditFieldsTab;
