import StyleWrapper from "../StyleWrapper";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	"data-testid": PropTypes.string,
	noFlexFlag: PropTypes.bool,
};
let ModalWindowContent = ({ children, "data-testid": dataTestId, noFlexFlag }) => {
	return (
		<StyleWrapper display={noFlexFlag ? undefined : "flex"} flexGrow="1" overflowY="auto">
			<div data-testid={dataTestId}>{children}</div>
		</StyleWrapper>
	);
};
ModalWindowContent.propTypes = propTypes;

export default ModalWindowContent;
