import Block from "../../Block";
import HeaderTextWrapper from "./HeaderTextWrapper";
import HeaderWrapper from "./HeaderWrapper";
import StyleWrapper from "../../StyleWrapper";
import PropTypes from "prop-types";
import { formatNameForTestId } from "@clearpoint/utils";

let propTypes = {
	headerCss: PropTypes.string,
	HeaderLeftElement: PropTypes.elementType,
	headerRightContent: PropTypes.node,
	headerText: PropTypes.string,
	headerTextCss: PropTypes.string,
};

let DefaultPodHeader = ({ headerCss, HeaderLeftElement, headerRightContent, headerText, headerTextCss }) => {
	let customHeaderStylesFlag = !!headerCss;
	return (
		<HeaderWrapper customHeaderStylesFlag={customHeaderStylesFlag} headerCss={headerCss}>
			<StyleWrapper display="table" width="100%" $style="td, th { padding: 0; }">
				<table className="no-table-pdf-tag">
					<tbody>
						<tr>
							{HeaderLeftElement ? (
								<HeaderLeftElement
									customHeaderStylesFlag={customHeaderStylesFlag}
									headerTextCss={headerTextCss}
								/>
							) : (
								<td>
									<Block
										data-testid={`${formatNameForTestId(headerText)}-pod-header`}
										marginTop="2px !important"
									>
										<HeaderTextWrapper
											customHeaderStylesFlag={customHeaderStylesFlag}
											headerTextCss={headerTextCss}
										>
											<h3>{headerText}</h3>
										</HeaderTextWrapper>
									</Block>
								</td>
							)}

							{headerRightContent && (
								<td align="right">
									<StyleWrapper float="right">
										<div>{headerRightContent}</div>
									</StyleWrapper>
								</td>
							)}
						</tr>
					</tbody>
				</table>
			</StyleWrapper>
		</HeaderWrapper>
	);
};

DefaultPodHeader.propTypes = propTypes;
export default DefaultPodHeader;
