import StyleWrapper from "../../../../StyleWrapper";
import PropTypes from "prop-types";
import { theme } from "../../../../Theme";

let defaultTableHeadStyle = {
	tableHeadHeight: "22px !important",
	tableHeadLineHeight: "15px !important",
	tableHeadPadding: "0",
};

let currentPeriodOnlyTableHeadStyle = {
	tableHeadHeight: "",
	tableHeadLineHeight: "18px !important",
	tableHeadPadding: "6px 4px !important",
};

let propTypes = {
	children: PropTypes.node.isRequired,
	currentPeriodOnlyFlag: PropTypes.bool,
};

let TableStyle = ({ children, currentPeriodOnlyFlag }) => {
	let tableHeadStyle = currentPeriodOnlyFlag ? currentPeriodOnlyTableHeadStyle : defaultTableHeadStyle;
	let { tableHeadHeight, tableHeadLineHeight, tableHeadPadding } = tableHeadStyle;

	return (
		<StyleWrapper
			overflow="hidden"
			width="100%"
			color={theme.black}
			marginBottom={currentPeriodOnlyFlag ? "0" : ""}
			$style={`
                tr {
                    height: 23px !important
                }

                th {
                    overflow: hidden;
                    text-align: center;
                    font-weight: 400;
                    height: ${tableHeadHeight};
                    line-height: ${tableHeadLineHeight};
                    padding: ${tableHeadPadding};

                    div {
                        box-sizing: content-box;
                        display: flex;
                        justify-content: center;
                        padding: 2px 4px !important;
                        margin-bottom: 2px;
                    }
                }

                td {
                    padding: 0 4px !important;
                }
            `}
		>
			{children}
		</StyleWrapper>
	);
};

TableStyle.propTypes = propTypes;
export default TableStyle;
