import { chartPeriodListContainsPeriod, getChartPeriodName, sortSeries } from "../utils";

function minZero(value) {   
    return value < 0 ? 0 : value;
}

let createRadialSeriesList = ({ seriesList, measureGridList, chartPeriodList, colorList, sortDirection }) => {
	let radialSeries = measureGridList
		.filter((period) => chartPeriodListContainsPeriod(period, chartPeriodList))
		.map((period, index) => ({
			name: getChartPeriodName(period, chartPeriodList),
			data: seriesList.map((series) => minZero(period["series" + series.measureSeriesId] || 0)),
			color: colorList[index % colorList.length],
			animation: false,
		}));
	if (sortDirection !== "none") {
		radialSeries = sortSeries(radialSeries, sortDirection);
	}
	return radialSeries;
};

export default createRadialSeriesList;
