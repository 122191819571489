import { useMainStore } from "@clearpoint/store";

let ViewSingle = ({ storeKey }) => {
	// this should only rerender when this variable is updated.
	let value = useMainStore((state) => state[storeKey]);

	return (
		<div style={{ border: "1px solid black", padding: "5px" }}>
			<div>
				{storeKey}: {value}
			</div>
		</div>
	);
};

export default ViewSingle;
