import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import mergeCustomFieldList from "./mergeCustomFieldList";

let propTypes = {
	scorecardId: PropTypes.number,
};
let useObjectiveUpdateFieldList = ({ scorecardId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();

	let customFieldList = get({ object: "customField", queryString: "?object=objective" });

	return useCallback(() => {
		let filteredCustomFieldList = customFieldList
			?.filter((x) => x.updateMode)
			.filter((x) => !x.scorecardId || x.scorecardId.length === 0 || x.scorecardId.includes(scorecardId));

		let fieldList = [
			{ value: "statusId", label: translate("global.status"), sortOrder: -994, group: translate("objective") },
			{
				value: "analysis",
				label: translate("defaultFields.update.objective.analysis"),
				sortOrder: -993,
				group: translate("objective"),
			},
			{
				value: "recommendations",
				label: translate("defaultFields.update.objective.recommendations"),
				sortOrder: -992,
				group: translate("objective"),
			},
		];
		return mergeCustomFieldList(fieldList, filteredCustomFieldList, translate);
	}, [customFieldList, scorecardId, translate]);
};
useObjectiveUpdateFieldList.propTypes = propTypes;
export default useObjectiveUpdateFieldList;
