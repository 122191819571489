import SelectObject from "./Select.Object";
import useFormValue from "../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	activeOnlyFlag: PropTypes.bool,
	allowNotDefinedFlag: PropTypes.bool,
};

let SelectMilestone = ({ activeOnlyFlag, allowNotDefinedFlag = true, ...props }) => {
	let initiativeId = useFormValue("initiativeId");
	return (
		<SelectObject
			object="milestone"
			parent="initiative"
			parentId={initiativeId}
			activeOnlyFlag={activeOnlyFlag}
			allowNotDefinedFlag={allowNotDefinedFlag}
			notDefinedValue={0}
			{...props}
		/>
	);
};

SelectMilestone.propTypes = propTypes;
export default SelectMilestone;
