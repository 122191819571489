import CellWrapper from "../CellWrapper";
import FormFieldDisplayStartDate from "@clearpoint/old-theme/Form/Field/Display/StartDate";
import FormFieldStartDate from "@clearpoint/old-theme/Form/Field/StartDate";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func,
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let StartDateCell = (props) => {
	let { close, editCellFlag, parentName, objectData } = props;
	let translate = useTranslate();
	let title = translate(objectData?.object ? `defaultFields.edit.${objectData.object}.startDate` : "edit.startDate");
	let name = `${parentName}.startDate`;
	let endDate = useFormValue(`${parentName}.endDate`);
	let startDate = useFormValue(`${parentName}.startDate`);
	let validDateFlag = Date.parse(endDate) - Date.parse(startDate) >= 0;

	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormModal title={title} name={name} modalVisible close={close} valid={validDateFlag}>
					<FormFieldStartDate name={name} object={objectData?.object} />
				</FormModal>
			) : (
				<FormFieldDisplayStartDate name={name} />
			)}
		</CellWrapper>
	);
};
StartDateCell.propTypes = propTypes;

export default StartDateCell;
