import CustomFieldDisplay from "../../CustomField/Display";
import Display from ".";
import FormGroupWrapper from "./FormGroupWrapper";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";

let propTypes = {
	customFieldType: PropTypes.string,
	fieldId: PropTypes.string,
	label: PropTypes.string,
	modalFlag: PropTypes.bool,
	name: PropTypes.string,
	parentName: PropTypes.string,
};

let DisplayField = ({ customFieldType, fieldId, label, modalFlag, name, parentName, periodLockedFlag, ...props }) => {
	let DisplayComponent;
	if (customFieldType) {
		DisplayComponent = CustomFieldDisplay[capitalizeFirstLetter(customFieldType)] || CustomFieldDisplay;
	} else {
		DisplayComponent = Display[capitalizeFirstLetter(fieldId)] || Display;
	}

	return (
		<FormGroupWrapper label={label} fieldId={fieldId} modalFlag={modalFlag}>
			<DisplayComponent
				customFieldType={customFieldType}
				fieldId={fieldId}
				name={name}
				parentName={parentName}
				modalFlag={modalFlag}
				periodLockedFlag={periodLockedFlag}
				{...props}
			/>
		</FormGroupWrapper>
	);
};

DisplayField.propTypes = propTypes;
export default DisplayField;
