import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { useCheckAccess, useConvertDateStringToDateObject, usePeriodDate } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import "@clearpoint/dhtmlx-gantt";

import Block from "../Block";
import addMarker from "./addMarker";
import addScales from "./addScales";
import useGanttData from "./useGanttData";
import useInitialGridWidth from "./useInitialGridWidth";
import useMovableFlag from "./useMovableFlag";
import useOnTaskDrag from "./useOnTaskDrag";
import useOnTaskUpdate from "./useOnTaskUpdate";
import useScale from "./useScale";
import useStartAndEndDate from "./useStartAndEndDate";
import useStyle from "./useStyle";
import { useOldSession } from "@clearpoint/old-session/index";
// Documentation: https://docs.dhtmlx.com/gantt/api__refs__gantt.html

let propTypes = {
	EditTaskModal: PropTypes.elementType,
	autosize: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	cellWidth: PropTypes.number,
	className: PropTypes.string,
	ganttEndDate: PropTypes.string,
	ganttPodFlag: PropTypes.bool,
	ganttStartDate: PropTypes.string,
	gridWidth: PropTypes.number,
	onAfterTaskDrag: PropTypes.func,
	onGridResize: PropTypes.func,
	onTaskModalSave: PropTypes.func,
	readOnlyFlag: PropTypes.bool,
	scale: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
let defaultProps = {
	autosize: true,
	scale: "month",
};
let Gantt = ({
	EditTaskModal,
	autosize,
	className,
	ganttEndDate,
	ganttPodFlag,
	ganttStartDate,
	gridWidth,
	name,
	onAfterTaskDrag,
	onGridResize,
	onTaskModalSave,
	readOnlyFlag,
	scale,
	shouldShowTaskModal,
	value,
}) => {
	let convertDateStringToDateObject = useConvertDateStringToDateObject();
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { exportFlag } = useOldSession().session;
	let elementRef = useRef();
	let ganttRef = useRef();
	let draggedFlagRef = useRef(false);
	let scrollRef = useRef();
	let [ganttState, setGanttState] = useState();
	let [selectedTaskName, setSelectedTaskName] = useState();
	let [newGridWidth, setNewGridWidth] = useState();
	let closeModal = useCallback(() => setSelectedTaskName(null), []);
	if (!ganttRef.current) {
		ganttRef.current = window.Gantt.getGanttInstance();
		addScales(ganttRef.current);
	}
	useEffect(() => {
		if (!isEqual(ganttState, ganttRef.current)) setGanttState(ganttRef.current);
	}, [ganttState]);
	let { endDate, startDate } = useStartAndEndDate({
		startDate: ganttStartDate,
		endDate: ganttEndDate,
		ganttRef,
		value,
	});
	let scales = useScale({ scale, ganttRef, endDate, startDate });
	let ganttData = useGanttData(value);

	let style = useStyle(exportFlag);
	let periodDate = usePeriodDate();
	let onTaskDrag = useOnTaskDrag({ draggedFlagRef, ganttRef, scrollRef });
	let onTaskUpdate = useOnTaskUpdate();
	gridWidth = newGridWidth || gridWidth;

	useInitialGridWidth({ ganttRef, gridWidth });

	useEffect(() => {
		// initialize/update gantt, (destroy, maintain state in callback)
		let gantt = ganttRef.current;
		gantt.plugins({ marker: true, multiselect: true });
		gantt.showLightbox = (id) => {
			let task = gantt.getTask(id);
			if (task.object === "scorecard") return;
			let index = value.findIndex((x) => x.object === task.object && x.objectId === task.objectId);
			let updateAccessFlag = checkAccess({ access: task.access, action: "update", scorecardId: task.scorecardId });
			if (!updateAccessFlag) {
				toast.warning(translate("toaster.messages.gantt.taskPermissions"));
				return;
			}
			if (!shouldShowTaskModal || shouldShowTaskModal(task)) {
				setSelectedTaskName(`${name}[${index}]`);
			}
		};
		if (!gantt.config.end_date || !gantt.config.start_date) {
			gantt.config.end_date = convertDateStringToDateObject(endDate);
			gantt.config.start_date = convertDateStringToDateObject(startDate);
		}
		let eventIdList = []; // use this to clean up events
		if (ganttData && value) {
			gantt.config = {
				...gantt.config,
				autoscroll: true,
				autosize,
				columns: [{ name: "name", label: translate("global.name"), tree: true, width: gridWidth }],
				grid_width: gridWidth || 200,
				min_column_width: scale === "week" ? 50 : 32,
				multiselect: true,
				readonly: readOnlyFlag,
				round_dnd_dates: false,
				row_height: 26,
				scale_height: 52,
				scales,
				show_markers: true,
				show_tasks_outside_timescale: true,
			};
			gantt.templates = {
				...gantt.templates,
				grid_file: () => "",
				grid_folder: () => "",
				leftside_text: (start) => {
					return new Date(start) < new Date(gantt.getScale().min_date) ? "<" : "";
				},
				rightside_text: (_start, end) => {
					return new Date(end) > new Date(gantt.getScale().max_date) ? ">" : "";
				},
				task_class: (start, end, task) => {
					let classList = [];
					if (!task.movableFlag) {
						classList = ["no-move"];
					}
					if (isEqual(start, end)) {
						classList = [...classList, "single-day"];
					}
					return classList.join(" ");
				},
			};
			gantt.init(elementRef.current);
			gantt.parse(ganttData);
			addMarker({ ganttRef, periodDate });
			if (onAfterTaskDrag) {
				let onAfterTaskDragEventId = gantt.attachEvent("onAfterTaskDrag", (id) => {
					gantt.parse(ganttData);
					onAfterTaskDrag(gantt.getTask(id));
				});
				eventIdList.push(onAfterTaskDragEventId);
			}
			let onBeforeTaskDragEventId = gantt.attachEvent("onBeforeTaskDrag", (id) => {
				let task = gantt.getTask(id);
				let editAccessFlag = checkAccess({
					access: task.access,
					action: "edit",
					scorecardId: task.scorecardId,
				});
				!editAccessFlag && toast.warning(translate("toaster.messages.gantt.taskPermissions"));
				return task.movableFlag && editAccessFlag;
			});
			eventIdList.push(onBeforeTaskDragEventId);
			let onTaskDragEventId = gantt.attachEvent("onTaskDrag", onTaskDrag);
			eventIdList.push(onTaskDragEventId);
			let onBeforeTaskDisplayId = gantt.attachEvent("onBeforeTaskDisplay", (_id, task) => !task.hiddenFlag);
			eventIdList.push(onBeforeTaskDisplayId);
			let onGridResizeEndId = gantt.attachEvent(
				"onGridResizeEnd",
				debounce((_oldGridWidth, newGridWidth) => {
					setNewGridWidth(newGridWidth);
					if (onGridResize) onGridResize(newGridWidth);
				}, 500)
			);
			eventIdList.push(onGridResizeEndId);
			let onAfterTaskUpdateId = gantt.attachEvent("onAfterTaskUpdate", onTaskUpdate);
			eventIdList.push(onAfterTaskUpdateId);
			gantt.render();
			if (scrollRef.current) gantt.scrollTo(scrollRef.current.x, scrollRef.current.y);
		}
		return () => {
			for (let id of eventIdList) gantt.detachEvent(id);
			gantt.clearAll();
		};
	}, [
		autosize,
		checkAccess,
		convertDateStringToDateObject,
		endDate,
		ganttData,
		gridWidth,
		name,
		onAfterTaskDrag,
		onGridResize,
		onTaskDrag,
		onTaskUpdate,
		periodDate,
		readOnlyFlag,
		scale,
		scales,
		shouldShowTaskModal,
		startDate,
		translate,
		value,
	]);
	let movableFlag = useMovableFlag({ ganttData, selectedTaskName });
	return (
		<>
			<Block
				data-testid="gantt-chart"
				className={className}
				height="100%"
				overflow="hidden"
				ref={elementRef}
				$style={style}
			/>
			{EditTaskModal && selectedTaskName && (
				<EditTaskModal
					modalVisible
					movableFlag={movableFlag}
					name={selectedTaskName}
					close={closeModal}
					onSave={onTaskModalSave}
					ganttPodFlag={ganttPodFlag}
				/>
			)}
		</>
	);
};
Gantt.propTypes = propTypes;
Gantt.defaultProps = defaultProps;

export default Gantt;
