import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import PodListItem from "../PodListItem";
import { useCallback } from "react";
import { useDragAndDropContext } from "../DragAndDropWrapper/DragAndDropWrapper";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import PropTypes from "prop-types";
import createNewPodForLayoutPanel from "./createNewPodForLayoutPanel";
import { createUniqueIdFromPod } from "@clearpoint/utils";

let propTypes = {
	pod: PropTypes.shape({
		fieldName: PropTypes.string,
		name: PropTypes.string,
		type: PropTypes.string,
		updateMode: PropTypes.bool,
	}),
};

let MenuPodListItem = ({ pod }) => {
	let { setFormValue } = useFormContext();
	let { data: filteredPodList, setData: setFilteredPodList } = useFilter();
	let { fieldId } = pod;
	let { setDragAndDropLookup } = useDragAndDropContext();

	let addPodToFirstArea = useCallback(() => {
		let newPod = createNewPodForLayoutPanel(pod);
		setFormValue(`layout[0].pods`, (podList) => {
			if (podList) {
				return [newPod, ...podList];
			}
		});
		setFilteredPodList(
			filteredPodList.filter((x) => x.fieldId !== fieldId),
			{ allowEmptyListFlag: true }
		);
		setDragAndDropLookup((dragAndDropPodIdLookup) => ({
			...dragAndDropPodIdLookup,
			"layout[0]": [createUniqueIdFromPod(newPod), ...dragAndDropPodIdLookup["layout[0]"]],
		}));
	}, [fieldId, filteredPodList, pod, setFilteredPodList, setFormValue, setDragAndDropLookup]);

	return (
		<PodListItem
			buttonCount={0}
			pod={pod}
			right={<IconButton data-testid="addPodButton" onClick={addPodToFirstArea} name="add" />}
		/>
	);
};

MenuPodListItem.propTypes = propTypes;

export default MenuPodListItem;
