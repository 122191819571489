import { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import DropdownContextMenu from "@clearpoint/old-theme/Dropdown/Dropdown.ContextMenu";
import CompletedCheck from "@clearpoint/old-theme/Form/Check/Form.Check.Completed";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import { useScorecardLockedFlag } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import CellWrapper from "../CellWrapper";
import ContextMenu from "./ContextMenu";
import useContextMenu from "./hooks/useContextMenu";
import Name from "./Name";
import Status from "./Status";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";

// TO DO: printing, no access
let propTypes = {
	cell: PropTypes.object,
	editAccessFlag: PropTypes.bool,
	editCellFlag: PropTypes.bool,
	objectData: PropTypes.shape({
		completed: PropTypes.bool,
		object: PropTypes.string,
		objectId: PropTypes.number,
		scorecardId: PropTypes.number,
	}),
	parentName: PropTypes.string,
	periodId: PropTypes.number,
	updateAccessFlag: PropTypes.bool,
};
let ElementCell = (props) => {
	let { cell, editAccessFlag, editCellFlag, objectData, parentName, periodId, updateAccessFlag } = props;
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { exportFlag } = useOldSession().session;
	let podContext = usePodContext();
	let { openEditMode: podOpenEditMode } = podContext || {};
	let ref = useRef();
	let { x, y, visible, showContextMenu } = useContextMenu(ref);
	let { editTableFlag, setLocalState } = useLocalState();
	let { setFormValue } = useFormContext();
	let openEditMode = useCallback(() => {
		if (podOpenEditMode) podOpenEditMode();
		setFormValue(cell?.editCellFlagName, true);
		if (!editTableFlag) setLocalState({ editTableFlag: true });
	}, [cell?.editCellFlagName, editTableFlag, podOpenEditMode, setFormValue, setLocalState]);
	let { completed: completedValue, object, objectId, scorecardId } = objectData || {};
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let { statusDisplay } = cell;
	let statusFlag = statusDisplay !== "hidden";
	// mark complete option enabled
	let options = get({ object: "options" });
	let completedKeyLookup = {
		actionItem: "trackActionItemDate",
		initiative: "trackInitiativeDate",
		milestone: "trackDate",
		risk: "trackRiskDate",
	};
	let completedKey = completedKeyLookup[object];
	let completedFlag = completedKey && options?.options?.[completedKey] && !editCellFlag && objectData;
	return useMemo(
		() => (
			<CellWrapper
				lineHeight="1.1"
				onContextMenu={showContextMenu}
				ref={ref}
				hint={translate("edit.rightClick")}
				{...props}
			>
				<Block display="flex" height="min-content" alignItems="center">
					{statusFlag && (
						<Status
							editCellFlag={editCellFlag}
							objectData={objectData}
							parentName={parentName}
							periodId={periodId}
							updateAccessFlag={updateAccessFlag}
						/>
					)}
					{completedFlag && !exportFlag && (
						<CompletedCheck
							object={object}
							objectId={objectId}
							onClick={openEditMode}
							name={`${parentName}.completed`}
							defaultValue={completedValue}
							disabled={scorecardLockedFlag || !editAccessFlag}
						/>
					)}
					<Name
						cell={cell}
						objectData={objectData}
						parentName={parentName}
						editCellFlag={editCellFlag}
						editAccessFlag={editAccessFlag}
					/>
				</Block>
				{!exportFlag && (
					<DropdownContextMenu x={x} y={y} visible={visible}>
						<ContextMenu
							editAccessFlag={editAccessFlag}
							object={object}
							objectId={objectId}
							scorecardId={scorecardId}
						/>
					</DropdownContextMenu>
				)}
			</CellWrapper>
		),
		[
			cell,
			completedFlag,
			completedValue,
			editAccessFlag,
			editCellFlag,
			openEditMode,
			exportFlag,
			object,
			objectData,
			objectId,
			parentName,
			periodId,
			props,
			scorecardId,
			showContextMenu,
			statusFlag,
			translate,
			updateAccessFlag,
			visible,
			x,
			y,
		]
	);
};
ElementCell.propTypes = propTypes;

export default ElementCell;
