import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import ChartTypeSelect from "./ChartTypeSelect";
import Column from "@clearpoint/old-theme/Column";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormSelectChartSize from "@clearpoint/old-theme/Form/Select/Select.ChartSize";
import FormSelectLegend from "@clearpoint/old-theme/Form/Select/Select.Legend";
import FormSelectLegendPosition from "@clearpoint/old-theme/Form/Select/Select.LegendPosition";
import FormSelectTag from "@clearpoint/old-theme/Form/Select/Select.Tag";
import Help from "@clearpoint/old-theme/Help";
import Row from "@clearpoint/old-theme/Row";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	setChartFormatData: PropTypes.func,
};

let ChartTab = ({ setChartFormatData }) => {
	let translate = useTranslate();
	let featureList = useMemo(() => ["advancedCharting", "customCharts"], []);

	return (
		<>
			<Help section="sect4" feature="edit-chart" />
			<FormInput required label={translate("manage.chart.chartName")} name="name" />
			<FormSelectTag name="tags" multipleFlag />
			<FormInput
				label={translate("manage.chart.chartTitle")}
				placeholder={translate("manage.chart.optionalTitle")}
				name="title"
			/>
			<ChartTypeSelect setChartFormatData={setChartFormatData} />
			<AlertUpsell feature="advancedCharting" featureList={featureList} />
			<FormSelectLegend label={translate("manage.chart.showLegend")} name="showLegend" />
			<FormSelectLegendPosition label={translate("manage.chart.selectLegendPosition")} name="legendPosition" />
			<FormSelectChartSize label={translate("manage.chart.chartSize")} name="chartSize" />
			<Row>
				<Column huge={6}>
					<FormCheck name="isInterpolated">{translate("manage.chart.interpolateValues")}</FormCheck>
				</Column>
				<Column huge={6}>
					<FormCheck name="isTransposed">{translate("manage.chart.transposeAxes")}</FormCheck>
				</Column>
			</Row>
			<FormInput
				as="textarea"
				cols={30}
				label={translate("manage.chart.chartDescription")}
				name="altText"
				rows={5}
				spellcheck="true"
			/>
		</>
	);
};

ChartTab.propTypes = propTypes;
export default ChartTab;
