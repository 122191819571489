import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useOldSession } from "@clearpoint/old-session/index";

export default function useGetScorecardId() {
	let { get } = useOldQueryStore();
	let { periodId } = useOldSession().session;
	return useCallback(
		({ object, objectId }) => {
			if (!object || !objectId) return null;
			if (object === "alert" && objectId === -1) return null;
			let objectData = periodId && get({ object, objectId, periodId: object === "map" ? periodId : undefined });
			if (!objectData) return null;
			return objectData.scorecardId;
		},
		[get, periodId]
	);
}
