import Badge from "../Badge/Badge";
import Block from "../Block";
import DocumentItem from "../DocumentItem";
import Icon from "../Icon/Icon";
import IconButton from "../Button/Button.Icon";
import ModalOnClick from "../Modal/Modal.OnClick";
import ViewLogEntryModalWindow from "./ViewLogEntryModalWindow";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	className: PropTypes.string,
	element: PropTypes.shape({
		object: PropTypes.string,
		activity: PropTypes.string,
		action: PropTypes.string,
		logFileId: PropTypes.number,
		scorecard: PropTypes.string,
		hasDetails: PropTypes.bool,
	}),
};

let Wrapper = ({ children, hasDetails, logFileId }) =>
	hasDetails ? (
		<ModalOnClick Modal={ViewLogEntryModalWindow} logFileId={logFileId}>
			{children}
		</ModalOnClick>
	) : (
		<>{children}</>
	);

let LogFileListItem = ({ className, element }) => {
	let { action, activity, apiKey, hasDetails, logFileId, object, requestCount, scorecard } = element;
	let translate = useTranslate();
	let objectIconName = object === "goal" || object === "keyResult" ? "goal" : object;
	return (
		<Wrapper hasDetails={hasDetails} logFileId={logFileId}>
			<DocumentItem
				data-testid={`revision-history-list-item`}
				className={className}
				cursor={hasDetails ? "pointer" : undefined}
				buttonCount={1}
				hoverEffectFlag
				leftHeight="48px"
				left={
					<Block
						ellipsisFlag
						alignItems="center"
						display="flex"
						flexWrap="nowrap"
						$style={`& { margin-top: ${theme.tinySpace};}`}
					>
						{object && <Icon marginRight={theme.smallSpace} name={objectIconName} size="large" />}
						{action && <Icon marginRight={theme.space} name={action} size="large" />}
						<Block display="flex" flexDirection="column" lineHeight="1.1">
							<Block>{activity || "null"}</Block>
							<Block fontSize="85%">{scorecard}</Block>
						</Block>
					</Block>
				}
				right={
					<>
						<Block marginRight={theme.tinySpace}>
							{apiKey && <Badge color="info">{apiKey.name}</Badge>}
							{requestCount > 1 && hasDetails && (
								<Badge color="info">
									{requestCount} {translate("logFieldLabels.evaluations")}
								</Badge>
							)}
							{requestCount === 1 && hasDetails && (
								<Badge color="info">
									{requestCount} {translate("edit.evaluation")}
								</Badge>
							)}
						</Block>
						{hasDetails && <IconButton name="revisionHistory" size="tiny" />}
					</>
				}
			/>
		</Wrapper>
	);
};

LogFileListItem.propTypes = propTypes;

export default LogFileListItem;
