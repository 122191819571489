import { useEffect, useState } from "react";

let URLtoDataURL = (url, callback) => {
	if (!url) return;
	let xhr = new XMLHttpRequest();
	xhr.onload = function () {
		var reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
		};
		reader.readAsDataURL(xhr.response);
	};
	xhr.open("GET", url);
	xhr.responseType = "blob";
	xhr.send();
};

let useDataURL = (url) => {
	let [dataURL, setDataURL] = useState();
	useEffect(() => {
		URLtoDataURL(url, setDataURL);
	}, [url]);
	return dataURL;
};

export default useDataURL;
