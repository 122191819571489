import { useCallback, useEffect } from "react";
import debounce from "lodash/debounce";

let useEqualHeight = (query) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let updateHeights = useCallback(
		debounce(() => {
			if (!query || !document.querySelector(query)) return;
			let elementList = document.querySelectorAll(query);
			let heightList = Array.from(elementList).map((element) => {
				let height = element.scrollHeight;
				if (isNaN(height)) height = 0;
				return height;
			});
			let height = Math.max(...heightList);
			for (let element of elementList) {
				if (+element.style.height.slice(0, -2) !== height) {
					element.style.height = height + "px";
				}
			}
		}, 17),
		[query]
	);
	// run this effect all the time, in case of change in size triggered by some state update
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(updateHeights);
	// also run on window resize, when state does not update
	useEffect(() => {
		window.addEventListener("resize", updateHeights);
		return () => window.removeEventListener("resize", updateHeights);
	}, [updateHeights]);
	// also on resize of element (for modern browsers only)
	useEffect(() => {
		let resizeObserver = new ResizeObserver(updateHeights);
		if (query && document.querySelector(query)) {
			resizeObserver.observe(document.querySelector(query));
		}
		return () => resizeObserver.disconnect();
	});
	return updateHeights;
};
export default useEqualHeight;
