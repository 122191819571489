import { createContext, useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useUnmount } from "react-use";

import { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";

import { useEffectOnce, useQueryParameters, useScrollIntoView } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { buildQueryString, emptyArray, emptyObject } from "@clearpoint/utils";

import useAssistantState from "./useAssistantState";
import useReceiveResponseMessage from "./useReceiveResponseMessage";
import useSendMessageToGPT from "./useSendMessageToGPT";
import { useOldSession } from "@clearpoint/old-session/index";

let AIAssistantContext = createContext();

let propTypes = {
	children: PropTypes.node.isRequired,
	contextPayload: PropTypes.object.isRequired,
	endpoint: PropTypes.string.isRequired,
	existingConversationData: PropTypes.object,
	parseHtmlFlag: PropTypes.bool,
	startImmediatelyFlag: PropTypes.bool,
};

let AIAssistantProvider = ({
	children,
	contextPayload,
	endpoint,
	existingConversationData,
	parseHtmlFlag,
	startImmediatelyFlag,
}) => {
	let [
		{
			assistantStartedFlag,
			chatId,
			chatRestartedFlag,
			conversationId,
			editModeFlag,
			initialResponseReceivedFlag,
			messageList,
			responseReceivedFlag,
			startIndex,
			validatingFlag,
		},
		setAssistantState,
	] = useAssistantState();
	let history = useHistory();
	let { containerRef, scrollIntoView: scrollToBottom } = useScrollIntoView();
	let {
		session: { tabbedNavigationData },
		setSession,
	} = useOldSession();
	let { clear, get } = useOldQueryStore();
	let { conversationId: queryConversationId, mode, object, type } = useQueryParameters();
	let modalContext = useWindowContext();

	useEffect(() => {
		// skip updating tab data if we're in a modal
		if (modalContext) return;
		if (chatId && !object) {
			if (queryConversationId) setAssistantState({ conversationId: queryConversationId });
			let conversationList = get({ expressFlag: true, object: "conversation" });
			if (conversationList) {
				let conversation = conversationList?.[0] || emptyObject;
				let { conversationId: recentCoversationId, conversationModel, chatId: recentChatId } = conversation;
				if (chatId === recentChatId) setAssistantState({ conversationId: recentCoversationId });
				//Setting the conversationId for the respective tab in the tab navigation data
				let currentTabIndex = -1;
				tabbedNavigationData.find((tab, index) => {
					currentTabIndex = index;
					return tab.mode === conversationModel;
				});
				if (currentTabIndex === -1 || tabbedNavigationData[currentTabIndex].conversationId) return;
				setSession({
					tabbedNavigationData: (previousState) => {
						return previousState.map((x, i) => (i === currentTabIndex ? { ...x, conversationId } : { ...x }));
					},
				});
			}
		}
	}, [
		chatId,
		conversationId,
		get,
		modalContext,
		object,
		queryConversationId,
		setAssistantState,
		setSession,
		tabbedNavigationData,
	]);

	let sendMessageToGPT = useSendMessageToGPT({
		chatId,
		context: contextPayload,
		endpoint,
		setAssistantState,
		startIndex,
	});

	useEffectOnce(() => {
		if (startImmediatelyFlag) {
			sendMessageToGPT("stream-cps-chat");
			setAssistantState({
				assistantStartedFlag: true,
				editModeFlag: false,
			});
		}
	});

	let startAIChat = useCallback(() => {
		setAssistantState({
			assistantStartedFlag: true,
			editModeFlag: false,
		});
		sendMessageToGPT("start-cps-chat");
	}, [sendMessageToGPT, setAssistantState]);

	let resetAIChat = useCallback(() => {
		setAssistantState({
			assistantStartedFlag: false,
			chatId: null,
			editModeFlag: true,
			initialResponseReceivedFlag: false,
			messageList: [],
			chatRestartedFlag: true,
		});
		setAssistantState({ conversationId: null });
		clear({ expressFlag: true, object: "conversation", objectId: conversationId });
		history.push("ai" + buildQueryString({ mode, type }));
	}, [clear, conversationId, history, mode, setAssistantState, type]);

	useReceiveResponseMessage({
		chatId,
		scrollToBottom,
		setAssistantState,
	});

	useEffect(() => {
		if (existingConversationData && !chatRestartedFlag) {
			setAssistantState({
				assistantStartedFlag: true,
				chatId: existingConversationData?.chatId,
				editModeFlag: false,
				initialResponseReceivedFlag: true,
				messageList:
					existingConversationData?.conversation
						?.slice(existingConversationData?.startIndex, existingConversationData?.conversation?.length)
						?.filter((x) => x.role !== "system" && x.content !== "") || emptyArray,
				startIndex: existingConversationData?.startIndex,
			});
		}
	}, [chatRestartedFlag, existingConversationData, setAssistantState]);

	useUnmount(() => {
		clear({ expressFlag: true, object: "conversation", objectId: conversationId });
		setAssistantState({
			chatRestartedFlag: false,
			conversationId: null,
		});
	});

	return (
		<AIAssistantContext.Provider
			value={{
				assistantState: {
					assistantStartedFlag,
					chatId,
					conversationId,
					editModeFlag,
					initialResponseReceivedFlag,
					messageList,
					responseReceivedFlag,
					startIndex,
					validatingFlag,
				},
				parseHtmlFlag,
				resetAIChat,
				scrollBottomContainerRef: containerRef,
				scrollToBottom,
				sendMessageToGPT,
				setAssistantState,
				startAIChat,
				startImmediatelyFlag,
			}}
		>
			{children}
		</AIAssistantContext.Provider>
	);
};

AIAssistantProvider.propTypes = propTypes;
export default AIAssistantProvider;
export const useAIAssistant = () => useContext(AIAssistantContext);
