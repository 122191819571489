import Block from "../../../Block";
import Image from "../../../Image";
import { memo, useCallback } from "react";
import { theme } from "../../../Theme";

let ImagePickerListItem = ({ editor, image, index, insertOrUpdateImage }) => {
	let insertImageOnClick = useCallback(() => {
		image.src = image.url;
		insertOrUpdateImage(editor, image);
		editor.windowManager.close();
	}, [editor, image, insertOrUpdateImage]);

	return (
		<Block
			key={index}
			onClick={insertImageOnClick}
			border={`2px solid ${theme.gray} !important`}
			borderRadius="3px"
			boxShadow="0 0 3px rgba(86, 96, 117, 0.7)"
			display="inline-block"
			height="94px !important"
			margin="3px !important"
			overflow="hidden"
			width="120px !important"
		>
			<Image
				alt={image.alt}
				src={image.src}
				maxHeight="94px !important"
				maxWidth="120px !important"
				objectPosition="50% 50%"
				style={{ margin: "0 4px 2px 0", cursor: "pointer" }}
			/>
		</Block>
	);
};

export default memo(ImagePickerListItem);
