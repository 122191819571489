import Block from "../Block";
import BootstrapForm from "react-bootstrap/Form";
import * as ReactICheck from "@clearpoint/react-icheck-master";
import StyleWrapper from "../StyleWrapper";
import { useCallback, memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "icheck/skins/square/green.css";
let Checkbox = ReactICheck.default.Checkbox;

let ICheckStyled = styled.div`
	text-align: left;
	cursor: pointer;
	display: flex;
	align-items: center;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

	& .icheckbox_square-green {
		flex-shrink: 0;
		transition: filter 0.15s ease-in-out;
		cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

		&.checked.disabled {
			filter: none;
		}
	}
	& .icheckbox_square-green.checked {
		filter: hue-rotate(11deg) brightness(1.41) saturate(1.1);
	}
	&:hover .icheckbox_square-green:not(.disabled) {
		filter: hue-rotate(11deg) brightness(1.15) saturate(1.1);
		background-position: -24px 0;
	}
	&:hover .icheckbox_square-green.checked:not(.disabled) {
		background-position: -48px 0;
	}
	& ins,
	& input {
		width: auto !important;
		height: auto !important;
		left: 0 !important;
		top: 0 !important;
	}

	& .icheckbox_label-content--cps {
		flex-shrink: 1;
		margin-left: 12px;
		filter: ${(props) => (props.disabled ? "opacity(50%)" : "opacity(100%)")};
	}
`;

let StyledBootstrapLabel = styled(BootstrapForm.Label)`
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: ${(props) => props.theme[props.$labelWeight || "bolder"]};
	width: 100%;
`;

let propTypes = {
	checked: PropTypes.oneOf([true, false, ""]),
	children: PropTypes.node,
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	description: PropTypes.string,
	disabled: PropTypes.bool,
	labelWeight: PropTypes.oneOf(["normal", "bold", "bolder"]),
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	text: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

let Check = ({
	checked,
	children,
	className,
	"data-testid": dataTestId,
	description,
	disabled,
	labelWeight,
	marginBottom,
	onBlur,
	onChange,
	onClick,
	style,
	text,
	value,
}) => {
	let handleClick = useCallback(
		(e) => {
			if (e) e.stopPropagation();
			if (onClick) onClick(e);
			if (onChange) onChange(e);
			if (onBlur) onBlur(e);
		},
		[onBlur, onChange, onClick]
	);
	return (
		<StyleWrapper flexShrink="0" marginBottom={marginBottom}>
			<BootstrapForm.Group style={style} className={className}>
				<StyledBootstrapLabel $labelWeight={labelWeight}>
					<ICheckStyled data-testid={dataTestId || "check"} disabled={disabled}>
						<Checkbox
							checkboxClass="icheckbox_square-green"
							increaseArea="20%"
							cursor="pointer"
							checked={!!checked || !!value}
							onClick={handleClick}
							disabled={disabled}
							style={style}
						/>
						{text || children ? <Block className="icheckbox_label-content--cps">{text || children}</Block> : ""}
					</ICheckStyled>
				</StyledBootstrapLabel>
				{description && <BootstrapForm.Text className="text-muted">{description}</BootstrapForm.Text>}
			</BootstrapForm.Group>
		</StyleWrapper>
	);
};

Check.propTypes = propTypes;

export default memo(Check);
