import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import ToolbarBadge from "./ToolbarBadgeTemplate";

let ScoreBadge = () => {
	let translate = useTranslate();
	let { autoEvaluation: autoEvaluationFlag, object, score, linkedElements } = useFormValue("updates") || emptyObject;

	let autoEvaluatedObjectiveFlag = object === "objective" && autoEvaluationFlag;

	if (linkedElements && !score) score = 0;

	return (
		<ToolbarBadge visible={autoEvaluatedObjectiveFlag} color="darkGray3">
			{`${translate("options.admin.status.score")}: ${
				score === null || score === undefined ? translate("global.notAvailable") : score?.toFixed(2)
			}`}
		</ToolbarBadge>
	);
};

export default ScoreBadge;
