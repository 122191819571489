import Block from "../../../Block";
import { cloneDeep } from "lodash";
import { memo } from "react";
import ImagePickerListItem from "./ImagePickerListItem";
import { noop } from "@clearpoint/utils";


let ImagePicker = ({ buildImagePath = () => noop, editor, imageList, insertOrUpdateImage }) => {
	return (
		<Block className="tiny-mce-image-picker" overflow="auto" height="300px" lineHeight="1" display="inline-block">
			{imageList.map((image, index) => {
				let imageCopy = cloneDeep(image);
				if (imageCopy.thumb) {
					imageCopy.thumb = buildImagePath(imageCopy.thumb);
				}
				imageCopy.url = buildImagePath(imageCopy.url);
				imageCopy.src = imageCopy.thumb;
				imageCopy.alt = imageCopy.title;
				return (
					<ImagePickerListItem
						editor={editor}
						index={index}
						image={imageCopy}
						insertOrUpdateImage={insertOrUpdateImage}
					/>
				);
			})}
		</Block>
	);
};

export default memo(ImagePicker);
