import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import GoalNavigator from "./GoalNavigator";
import GoalTab from "./GoalTab";
import KeyResultsTab from "./KeyResultsTab/KeyResultsTab";
import LinksTab from "./LinksTab";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useUnmount } from "react-use";
import { PropTypes } from "prop-types";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	navigableGoalListFlag: PropTypes.bool,
	openNextGoal: PropTypes.func,
	openPreviousGoal: PropTypes.func,
};

let EditGoalModalWindowContent = ({ navigableGoalListFlag, openNextGoal, openPreviousGoal }) => {
	let translate = useTranslate();
	let { clear } = useOldQueryStore();
	let { newObjectFlag } = useFormContext();
	let { name, objectId } = useFormValue();
	let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
		saveModalVisible: false,
		savedFlag: false,
	});
	let onSave = useCallback(() => {
		setState({ savedFlag: true });
	}, [setState]);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);
	let openSaveModal = useCallback(
		(selectTab) => {
			if (newObjectFlag) {
				setState({ saveModalVisible: true });
			} else {
				selectTab();
			}
		},
		[newObjectFlag, setState]
	);

	useUnmount(() => {
		if (savedFlag) clear({ object: "goal", objectId });
	});

	return (
		<>
			<ModalWindowHeader
				badgeIcon="goal"
				badgeText={translate("global.goal")}
				title={newObjectFlag ? translate("goals.addGoal") : name}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<GoalTab title={translate("global.goal")} visible={newObjectFlag} />
					<KeyResultsTab title={translate("goals.keyResults")} onClick={openSaveModal} />
					<GoalTab title={translate("global.goal")} visible={!newObjectFlag} />
					<LinksTab title={translate("edit.links")} onClick={openSaveModal} />
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter
				left={
					<GoalNavigator
						navigableGoalListFlag={navigableGoalListFlag}
						openNextGoal={openNextGoal}
						openPreviousGoal={openPreviousGoal}
					/>
				}
				saveAndContinueFlag
			/>
			<FormModalSave
				object="goal"
				onSave={onSave}
				modalVisible={saveModalVisible}
				submitUnchangedFlag
				close={closeSaveModal}
			/>
		</>
	);
};

EditGoalModalWindowContent.propTypes = propTypes;
export default EditGoalModalWindowContent;
