import FormSelect from "@clearpoint/old-theme/Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let VisibilitySelect = (props) => {
	let translate = useTranslate();

	let options = useMemo(
		() => [
			{ label: translate("goals.visibility.owner"), value: 1 },
			{ label: translate("goals.visibility.ownerAndManager"), value: 2 },
			{ label: translate("goals.visibility.ownerAndDepartment"), value: 3 },
			{ label: translate("goals.visibility.everyone"), value: 4 },
		],
		[translate]
	);

	return <FormSelect name="visiblity" label={translate("goals.visibility")} options={options} {...props} />;
};

export default VisibilitySelect;
