import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";
import { formatNumber } from "@clearpoint/utils";

import Badge from "../../../Badge/Badge";
import Block from "../../../Block";
import Column from "../../../Column";
import HR from "../../../HR";
import HTML from "../../../HTML";
import Row from "../../../Row";
import StyleWrapper from "../../../StyleWrapper";
import { theme } from "../../../Theme";

let propTypes = {
	previewData: PropTypes.oneOfType(
		[
			PropTypes.arrayOf(
				PropTypes.shape({
					calculation: PropTypes.string,
					expandedCalculation: PropTypes.string,
					measure: PropTypes.string,
					numberFormat: PropTypes.object,
					value: PropTypes.number,
				})
			),
			PropTypes.shape({ preview: PropTypes.string }),
		],
		PropTypes.string
	),
};

let Preview = ({ previewData }) => {
	let translate = useTranslate();

	return previewData === "Error calculating value" ? (
		<p>{translate("options.admin.calculatedFields.error")} </p>
	) : previewData?.preview ? (
		<Block marginBottom={theme.smallSpace}>
			<HTML>{previewData?.preview}</HTML>
		</Block>
	) : (
		previewData?.map?.((x) => (
			<StyleWrapper key={x.measure} marginBottom={theme.smallSpace} borderBottom={`1px solid ${theme.lightGray5}`}>
				<Row>
					<Column large={6}>
						<HTML>{x.measure}</HTML>
					</Column>
					<Column large={6}>
						<Block lineHeight="2em" marginBottom={theme.smallSpace}>
							<HTML>{x.expandedCalculation}</HTML>
							<HR size="tiny" /> = <HTML>{x.calculation}</HTML>
							<HR size="tiny" /> ={" "}
							<Badge size="small" color={x.value === null || x.value === undefined ? "danger" : "success"}>
								{formatNumber({ number: x.value, numberFormat: x.numberFormat })}
							</Badge>
						</Block>
					</Column>
				</Row>
			</StyleWrapper>
		)) || null
	);
};

Preview.propTypes = propTypes;

export default Preview;
