import Column from "@clearpoint/old-theme/Column";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormSelectPeriod from "@clearpoint/old-theme/Form/Select/Select.Period";
import FormSelectTag from "@clearpoint/old-theme/Form/Select/Select.Tag";
import FormSelectUser from "@clearpoint/old-theme/Form/Select/Select.User";
import InputDate from "@clearpoint/old-theme/Form/Input/Input.Date";
import OwnerSelect from "./OwnerSelect";
import Row from "@clearpoint/old-theme/Row";
import VisibilitySelect from "./VisibilitySelect";
import useBeginningOfMonthDate from "../useBeginningOfMonthDate";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import isObject from "lodash/isObject";

let GoalTab = () => {
	let translate = useTranslate();
	let getBeginningOfMonthDate = useBeginningOfMonthDate();
	let { get } = useOldQueryStore();
	let userList = get({ object: "user" });
	let periodList = get({ object: "period" });
	let { setFormValue } = useFormContext();

	let updateSupervisorOnOwnerSelect = useCallback(
		(option) => {
			if (!isObject(option)) return;
			let { value: userId } = option;
			if (userId) {
				let supervisorId = userList?.find?.((x) => x.userId === userId)?.supervisorId;
				if (supervisorId) {
					setFormValue("supervisorId", supervisorId);
				}
			}
		},
		[setFormValue, userList]
	);

	let updateStartAndEndDateOnPeriodSelect = useCallback(
		(option) => {
			if (!isObject(option)) return;
			let { value: periodId } = option;
			if (periodId) {
				let periodEndDate = periodList?.find?.((x) => x.periodId === periodId)?.periodDate;
				if (periodEndDate) {
					setFormValue("endDate", periodEndDate);
					setFormValue("startDate", getBeginningOfMonthDate(periodEndDate));
				}
			}
		},
		[getBeginningOfMonthDate, periodList, setFormValue]
	);

	return (
		<>
			<FormInput
				name="name"
				label={translate("global.name")}
				placeholder={translate("goals.placeholers.name")}
				required
				maxLength={200}
			/>
			<Row>
				<Column medium={6}>
					<FormSelectUser
						name="ownerId"
						label={translate("logFieldLabels.owner")}
						onChange={updateSupervisorOnOwnerSelect}
						required
					/>
				</Column>
				<Column medium={6}>
					<OwnerSelect name="supervisorId" label={translate("goals.manager")} />
				</Column>
			</Row>
			<Row>
				<Column medium={6}>
					<FormSelectPeriod
						name="periodId"
						label={translate("goals.selectPeriod")}
						defaultCurrentPeriodFlag
						onChange={updateStartAndEndDateOnPeriodSelect}
					/>
				</Column>
				<Column medium={6}>
					<InputDate noTimeFlag label={translate("logFieldLabels.endDate")} name="endDate" disabled />
				</Column>
			</Row>
			<VisibilitySelect name="visibility" />
			{/* 
			// We may be removing the priority select, but in case we don't: 
			<Row>
				<Column medium={6}>
					<VisibilitySelect name="visibility" />
				</Column>
				<Column medium={6}>
					<PrioritySelect name="priority" />
				</Column>
			</Row> */}
			<FormSelectTag name="tags" multipleFlag />
			<FormInput
				as="textarea"
				name="description"
				label={translate("global.description")}
				placeholder={translate("goals.placeholder.description")}
				rows="4"
			/>
		</>
	);
};

export default GoalTab;
