import { useFormContext } from "../Form";
import getDeepValue from "lodash/get";

let useFormTouched = (name) => {
	let { formTouched } = useFormContext();
	if (name) return getDeepValue(formTouched, name);
	return formTouched;
};

export default useFormTouched;
