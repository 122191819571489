import { useFormContext } from "../../Form/Form";
import { emptyObject } from "@clearpoint/utils";

let generateAfterBeginEditingCallback = (setFormError, formValidation) => {
	// Do not convert to arrow function or the `this` binding will be lost
	let hasOwnFormValidationFlag = Object.keys(formValidation).length > 0;
	return function customValidator(row, col) {
		let hotGridElement = document.querySelector(`#hot-grid`);
		if (!hotGridElement) return;

		let editCellTarget = hotGridElement.querySelector(".ht_clone_master textarea.handsontableInput");
		let validator = this.getCellValidator(row, col);

		if (!editCellTarget || !validator) return;

		editCellTarget.addEventListener("change", (e) => {
			let cellValidFlag;
			validator(e.target.value, (valid) => {
				cellValidFlag = valid;
			});
			if (setFormError) {
				setFormError("gridCellValidationError", !cellValidFlag && !hasOwnFormValidationFlag ? true : undefined);
			}
		});
	};
};

let useAfterBeginEditing = () => {
	let { formValidation, setFormError } = useFormContext() || emptyObject;

	let afterBeginEditingCallback = generateAfterBeginEditingCallback(setFormError, formValidation);

	return afterBeginEditingCallback;
};

export default useAfterBeginEditing;
export { generateAfterBeginEditingCallback };
