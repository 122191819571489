import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import useViewType from "../Shared/useViewType";
import goalViewLookup from "../goalViewLookup";
import isArray from "lodash/isArray";
import { NOT_DEFINED } from "../Shared/constants";
import { emptyArray, emptyObject } from "@clearpoint/utils";


let { DEPARTMENT, DEPARTMENT_PERSONAL, INDIVIDUAL, INDIVIDUAL_PERSONAL, MANAGER, MANAGER_PERSONAL, SCORECARD } =
	goalViewLookup;

let useGoalListByGroup = (groupId) => {
	let { get } = useOldQueryStore();
	let { data: filteredGoalList } = useFilter() || emptyObject;

	let viewType = useViewType();

	let profile = get({ object: "profile" });
	let { department: profileDepartment, userId: profileUserId } = profile || emptyObject;

	if (!isArray(filteredGoalList)) {
		filteredGoalList = emptyArray;
	}

	let goalList = useMemo(() => {
		let lookup = {
			[DEPARTMENT]: filteredGoalList.filter((x) => {
				if (groupId === NOT_DEFINED) {
					return !x.department;
				}
				return x.department === groupId;
			}),
			[DEPARTMENT_PERSONAL]: filteredGoalList.filter((x) => {
				if (!profileDepartment) {
					return !x.department && x.ownerId === groupId;
				}
				return x.department === profileDepartment && x.ownerId === groupId;
			}),
			[INDIVIDUAL]: filteredGoalList.filter((x) => {
				if (groupId === NOT_DEFINED) {
					return !x.ownerId;
				}
				return x.ownerId === groupId;
			}),
			[INDIVIDUAL_PERSONAL]: filteredGoalList.filter((x) => x.ownerId === groupId),
			[MANAGER]: filteredGoalList.filter((x) => {
				if (groupId === NOT_DEFINED) {
					return !x.supervisorId;
				}
				return x.supervisorId === groupId;
			}),
			[MANAGER_PERSONAL]: filteredGoalList.filter((x) => x.ownerId === groupId && x.supervisorId === profileUserId),
			[SCORECARD]: filteredGoalList.filter((x) => {
				if (groupId === NOT_DEFINED) {
					return !x.scorecard;
				}
				return x.scorecard === groupId;
			}),
		};
		return lookup[viewType] || [];
	}, [filteredGoalList, groupId, profileDepartment, profileUserId, viewType]);

	return goalList;
};

export default useGoalListByGroup;
