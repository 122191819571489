import { formatSeriesNumber } from "../utils";

let describePolar = ({ chartData, series }) => {
	let description = "";
	let measureSeriesList = chartData.chartFormat.series;
	for (let period of series) {
		let { data, name: periodName } = period;
		description += `\n${periodName}\n`;
		let i = 0;
		for (let measureSeries of measureSeriesList) {
			let { name } = measureSeries;
			let { numberFormat, y: value } = data[i];
			description += `${name}: ${formatSeriesNumber(value || 0, numberFormat)}\t`;
			i++;
		}
		description = description.slice(0, -1);
	}
	return description;
};
export default describePolar;
