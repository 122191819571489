import ProfileImage from "@clearpoint/old-theme/ProfileImage";
import Tooltip from "@clearpoint/old-theme/Tooltip";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import useViewType from "@components/Elements/Goals/Shared/useViewType";
import PropTypes from "prop-types";
import goalViewLookup from "@components/Elements/Goals/goalViewLookup";
import { emptyObject } from "@clearpoint/utils";


let { DEPARTMENT, MANAGER, SCORECARD } = goalViewLookup;
let nonOwnerViewList = [DEPARTMENT, MANAGER, SCORECARD];

let propTypes = {
	ownerId: PropTypes.number,
};

let OwnerCircle = ({ ownerId }) => {
	let { get } = useOldQueryStore();
	let userList = get({ object: "user" });
	let user = useMemo(() => ownerId && userList?.find((x) => x.userId === ownerId), [ownerId, userList]);
	let view = useViewType();
	let { fullName } = user || emptyObject;

	let renderFlag = !!(user && nonOwnerViewList.includes(view));
	return renderFlag ? (
		<Tooltip placement="top" text={fullName}>
			<ProfileImage user={user} size="tiny" />
		</Tooltip>
	) : null;
};

OwnerCircle.propTypes = propTypes;
export default OwnerCircle;
