import Block from "@clearpoint/old-theme/Block";
import DeletedBadge from "@clearpoint/old-theme/Badge/Badge.Deleted";
import DragAndDropListItem from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Item";
import DropdownItemTrash from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Trash";
import DropdownItemCopy from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Copy";
import DropdownItemEdit from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Edit";
import EditCategoryModal from "./EditCategoryModal";
import KebabButton from "@clearpoint/old-theme/Dropdown/Kebab/KebabButton";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import TrashButton from "@clearpoint/old-theme/Button/Button.Trash";
import UndeleteButton from "@clearpoint/old-theme/Button/Button.Undelete";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	category: PropTypes.shape({
		active: PropTypes.bool,
		categoryId: PropTypes.number,
		deleteDate: PropTypes.string,
		description: PropTypes.string,
		lastEdited: PropTypes.string,
		name: PropTypes.string,
		visible: PropTypes.bool,
	}),
	disabled: PropTypes.bool,
};

let CategoryListItem = ({ category, disabled }) => {
	let translate = useTranslate();
	let scorecardId = useFormValue("scorecardId");
	let { active, categoryId, deleteDate, description, lastEdited, name, visible } = category;
	let copyDefaultValue = useMemo(
		() => ({
			description,
			name,
			scorecardId,
		}),
		[description, name, scorecardId]
	);
	return (
		<DragAndDropListItem
			visible={visible}
			lastEdited={lastEdited}
			color="desaturatedNormal"
			left={
				<Block display="flex" alignItems="center" ellipsisFlag>
					<ModalWindowOnClick ModalWindow={EditCategoryModal} objectId={categoryId}>
						<Block ellipsisFlag as="a" cursor="pointer" display="inline-block" fontWeight={theme.boldish}>
							{name}
						</Block>
					</ModalWindowOnClick>
					{!active && <DeletedBadge deleteDate={deleteDate} />}
				</Block>
			}
			right={
				active ? (
					<>
						<TrashButton disabled={disabled} object="category" objectId={categoryId} name={name} />
						<KebabButton>
							<DropdownItemEdit
								ModalWindow={EditCategoryModal}
								disabled={disabled}
								object="category"
								objectId={categoryId}
							/>
							{!disabled && (
								<DropdownItemCopy
									title={translate("edit.element.duplicateCategory")}
									object="category"
									objectId={categoryId}
									duplicateRouteFlag
									parent="scorecard"
									parentId={scorecardId}
									defaultValue={copyDefaultValue}
									toast={translate("edit.element.categoryDuplicated")}
								/>
							)}
							{!disabled && <DropdownItemTrash object="category" objectId={categoryId} name={name} />}
						</KebabButton>
					</>
				) : (
					<UndeleteButton
						object="category"
						objectId={categoryId}
						name={name}
						toast={translate("toaster.messages.objects.objectUndeleted", { object: "category" })}
					/>
				)
			}
		/>
	);
};

CategoryListItem.propTypes = propTypes;

export default CategoryListItem;
