import Label from "../../Form/Form.Label";
import { useTranslate } from "@clearpoint/translate";

let MyScorecard = ({ label }) => {
	let translate = useTranslate();
	return (
		<>
			<Label>{label}</Label>
			<div data-testid="myScorecard">{translate("customField.notEditable")}</div>
			<br />
		</>
	);
};
export default MyScorecard;
