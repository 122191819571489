import Alert from "./Alert/Alert";
import Block from "./Block";
import HTML from "./HTML";
import Hide from "./Hide";
import Icon from "./Icon/Icon";
import { useEffect } from "react";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "./ModalWindow/ModalWindow";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";
import { useCreateSupportLink, usePrevious, useStateObject } from "@clearpoint/hooks";


let propTypes = {
	feature: PropTypes.string.isRequired,
	section: PropTypes.string,
};

let Help = ({ feature, section }) => {
	let translate = useTranslate();
	let { helpFlag } = useWindowContext();
	let previousHelpFlag = usePrevious(helpFlag);
	let [{ helpVisible }, setState] = useStateObject({
		helpVisible: true,
	});

	useEffect(() => {
		if (previousHelpFlag !== helpFlag) {
			setState({
				helpVisible: helpFlag,
			});
		}
	}, [helpFlag, previousHelpFlag, setState]);
	let closeHelp = () =>
		setState({
			helpVisible: false,
		});

	let supportLink = useCreateSupportLink({ componentName: "Help", feature, section });

	return (
		<Hide visible={helpFlag && helpVisible}>
			<Alert color="info">
				<Block width="100%" display="flex">
					<Block flex="1">
						<HTML>{translate(`quickHelp.${camelCase(feature)}.description`)}</HTML>
						&nbsp;
						<a href={supportLink} target="_support">
							{translate("quickHelp.learnMore")}
						</a>
					</Block>
					<Block cursor="pointer">
						<Icon name="closeHelp" onClick={closeHelp} />
					</Block>
				</Block>
			</Alert>
		</Hide>
	);
};

Help.propTypes = propTypes;

export default Help;
