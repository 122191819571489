let moveUp = (list, index) => {
	if (index === 0) return list;
	let beforePrevious = list.slice(0, index - 1);
	let previous = list[index - 1];
	let current = list[index];
	let afterCurrent = list.slice(index + 1);
	return [...beforePrevious, current, previous, ...afterCurrent];
};
let moveDown = (list, index) => {
	if (index === list.length - 1) return list;
	let beforeCurrent = list.slice(0, index);
	let current = list[index];
	let next = list[index + 1];
	let afterNext = list.slice(index + 2);
	return [...beforeCurrent, next, current, ...afterNext];
};
let moveToTop = (list, index) => {
	if (index === 0) return list;
	let beforeCurrent = list.slice(0, index);
	let current = list[index];
	let afterCurrent = list.slice(index + 1);
	return [current, ...beforeCurrent, ...afterCurrent];
};
let moveToBottom = (list, index) => {
	if (index === list.length - 1) return list;
	let beforeCurrent = list.slice(0, index);
	let current = list[index];
	let afterCurrent = list.slice(index + 1);
	return [...beforeCurrent, ...afterCurrent, current];
};
export { moveUp, moveDown, moveToTop, moveToBottom };
