import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

let updateDataFromStorage = ({ dataKey, itemsPerPage, response, setFilterCache, startIndex, storedData }) => {
	if (!response?.[dataKey]) return;
	let objects = cloneDeep(response[dataKey]);
	if (objects) {
		// update stored data
		let storedDataCopy = cloneDeep(storedData);
		for (let i = 0; i < itemsPerPage; i++) {
			let object = objects[i];
			if (object) storedDataCopy[startIndex - 1 + i] = object;
		}
		if (!isEqual(storedData, storedDataCopy)) {
			let responseKey = `${dataKey.slice(0, -1)}Count`;
			if (dataKey === "logFile") responseKey = "logCount";
			if (dataKey === "matches") responseKey = "matchCount";
			setFilterCache({
				count: (count) => response?.[responseKey] || count,
				storedData: storedDataCopy,
			});
		}
	}
};
export default updateDataFromStorage;
