let isManageUsersPage = (x) => {
	return x.page === "manage-users" || x.page === "manage-user-groups";
};

let isManagePeriodsPage = (x) => {
	return (
		x.page === "manage-periods" ||
		x.page === "manage-period-groups" ||
		x.page === "manage-fiscal-years" ||
		x.page === "manage-master-periods"
	);
};

export { isManagePeriodsPage, isManageUsersPage };
