import BriefingBookNavigation from "@components/Print/BriefingBook/Navigation";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import Title from "./Toolbar/Title";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	reportType: PropTypes.string,
};
let PrintTitle = ({ reportType }) => {
	let { briefingBookFlag } = useOldSession().session;
	return (
		<StyleWrapper marginBottom={reportType === "gantt" ? theme.smallSpace : theme.mediumSpace} width="100%">
			<table className="report-title no-table-pdf-tag avoid-page-break-after">
				<tbody>
					<tr>
						<td>
							<Title />
						</td>
						{briefingBookFlag && (
							<td>
								<BriefingBookNavigation />
							</td>
						)}
					</tr>
				</tbody>
			</table>
		</StyleWrapper>
	);
};
PrintTitle.propTypes = propTypes;
export default PrintTitle;
