import { useOldQueryStore } from "@clearpoint/old-query-store";
import { buildQueryString } from "@clearpoint/utils";

import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let useElementList = () => {
  let { session } = useOldSession();
  let { dashboardChartCount, exportFlag, exportApiHeight, exportApiWidth, format, periodId } = session;
  let { layoutId, pageKey } = useLocalState();
  let page = session[pageKey] || 1;
  let { get } = useOldQueryStore();
  return layoutId && periodId
    ? get({
      object: "element",
      parent: `measureLayout`,
      parentId: layoutId,
      queryString: buildQueryString({
        periodId,
        rowCount: dashboardChartCount,
        page,
        sortObject: "measure",
        sortField: "default",
        sortOrder: "asc",
        print: exportFlag,
        format: exportFlag ? format || "pdf" : undefined,
        pageHeight: exportFlag ? exportApiHeight : undefined,
        pageWidth: exportFlag ? exportApiWidth : undefined,
      }),
    })
    : undefined;
};
export default useElementList;
