import PropTypes from "prop-types";

import AlertApproval from "@clearpoint/old-theme/Alert/Alert.Approval";
import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";

import { useFieldList, useFormFieldsLoading } from "@clearpoint/hooks";

let propTypes = {
	addFlag: PropTypes.bool,
};
let EditFieldsTab = ({ addFlag }) => {
	let objectId = useFormValue("objectId");
	let scorecardId = useFormValue("scorecardId");
	let fieldList = useFieldList({ object: "risk", objectId, addFlag, scorecardId });
	let loadingFlag = useFormFieldsLoading({ fieldList, addFlag });

	return (
		<>
			<AlertUpsell feature="dependencies" />
			<Help section="edit-element" feature="edit-fields" />
			<AlertApproval type={addFlag ? "adds" : "edits"} />
			{fieldList?.map((field) => (
				<FormField
					disabled={loadingFlag}
					fieldId={field.value}
					key={field.value}
					label={field.label}
					object="risk"
					editObjectFlag
				/>
			))}
		</>
	);
};
EditFieldsTab.propTypes = propTypes;
export default EditFieldsTab;
