import Block from "@clearpoint/old-theme/Block";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PageTitle from "@clearpoint/old-theme/PageTitle/PageTitle";
import PageTitleScorecardSubtitle from "@clearpoint/old-theme/PageTitle/PageTitle.ScorecardSubtitle";

import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let HeaderDisplayTitle = () => {
	let { name: elementName, object, scorecardId } = useFormValue();
	let { layout } = useDetailPageData();
	let { name: layoutName } = layout || emptyObject;
	let scorecardFlag = object === "scorecard";

	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let title = translateOnlyInsideCurlyBraces(scorecardFlag ? layoutName : elementName);

	return title && (
		<Block marginTop="1px" width="100%" ellipsisFlag>
			<PageTitle title={title} subTitle={<PageTitleScorecardSubtitle scorecardId={scorecardId} />} />
		</Block>
	);
};

export default HeaderDisplayTitle;
