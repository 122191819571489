import * as ReactICheck from "@clearpoint/react-icheck-master";
import React from "react";
import useDisableParentRef from "./useDisableParentRef";
import PropTypes from "prop-types";
import styled from "styled-components";
import "icheck/skins/square/green.css";
let ICheckRadioGroup = ReactICheck.default.RadioGroup;

let StyledICheckWrapper = styled.div`
	font-weight: bold;
	text-align: left;
	& label {
		display: flex;
		cursor: pointer;
		align-items: center;
	}
	& label * {
		margin-bottom: 0px !important;
	}
	& .iradio_square-green {
		transition: filter 0.15s ease-in-out;
	}
	& .iradio_square-green.checked {
		filter: hue-rotate(11deg) brightness(1.41) saturate(1.1);
	}
	& .iradio_square-green.hover {
		filter: hue-rotate(11deg) brightness(1.15) saturate(1.1);
	}
	.iradio_square-green.disabled * {
		cursor: not-allowed;
	}
`;

let StyledLabel = styled.span`
	margin-left: ${(props) => props.theme.smallSpace};
	font-weight: ${(props) => (props.boldFlag ? props.theme.bold : props.theme.normal)};
`;

let propTypes = {
	children: PropTypes.node,
	boldFlag: PropTypes.bool,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

let RadioGroup = ({ children, boldFlag, name, onBlur, onChange, value }) => {
	let ref = useDisableParentRef();
	return (
		<StyledICheckWrapper ref={ref}>
			<ICheckRadioGroup name={name} value={value}>
				{React.Children.map(children, (x) =>
					React.cloneElement(x, {
						radioClass: "iradio_square-green",
						cursor: "true",
						increaseArea: "20%",
						label: <StyledLabel boldFlag={boldFlag}>{x.props.text}</StyledLabel>,
						onClick: () => {
							onChange(x.props.value);
							onBlur(x.props.value);
						},
						key: x.props.value,
						...x.props,
					})
				)}
			</ICheckRadioGroup>
		</StyledICheckWrapper>
	);
};

RadioGroup.propTypes = propTypes;

export default RadioGroup;
