import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useCallback, useRef } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useTranslate } from "@clearpoint/translate";
import { useInterval, useStateObject } from "@clearpoint/hooks";


let MoveDownButton = () => {
	let { checklist } = useChecklist();
	let { setFormValue } = useFormContext();
	let translate = useTranslate();
	let [{ moveDownFlag }, setState] = useStateObject({
		moveDownFlag: false,
	});
	let speedRef = useRef(1);
	let moveDown = useCallback(() => {
		setFormValue("elements", (elementList) => {
			let checkedElementList = elementList.filter((x) =>
				checklist.some((check) => check.checked && check.id === `${x.object},${x.objectId}`)
			);
			return elementList.map((x) =>
				checkedElementList.includes(x) ? { ...x, yPos: x.yPos >= 768 ? 768 : x.yPos + speedRef.current } : x
			);
		});
		speedRef.current += 1;
	}, [checklist, setFormValue]);
	useInterval(() => {
		if (moveDownFlag) moveDown();
	}, 16);
	let startMove = useCallback(() => {
		setState({ moveDownFlag: true });
	}, [setState]);
	let stopMove = useCallback(() => {
		speedRef.current = 1;
		setState({ moveDownFlag: false });
	}, [setState]);
	let disabledFlag = !checklist.some((x) => x.checked);
	return (
		<IconButton
			color="default"
			disabled={disabledFlag}
			name="moveDown"
			onMouseDown={startMove}
			onMouseUp={stopMove}
			title={disabledFlag ? translate("edit.map.noElements") : undefined}
		/>
	);
};

export default MoveDownButton;
