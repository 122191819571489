import StatusIconThemeComponent from "../../../StatusIcon";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let StatusIcon = ({ name }) => {
	let statusId = useFormValue(name);
	return <StatusIconThemeComponent statusId={+statusId} showNameFlag />;
};

StatusIcon.propTypes = propTypes;
export default StatusIcon;
