export default function buildQueryString(object) {
	let queryString = "?";
	let sortedKeys = Object.keys(object).sort();
	for (let key of sortedKeys) {
		let value = object[key];
		if (value || value === 0) {
			if (key === "object" && value?.endsWith("Layout")) value = value.slice(0, -6);
			queryString += `${key}=${value}&`;
		}
	}
	return queryString.slice(0, -1);
}
