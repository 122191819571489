import differenceInYears from "date-fns/differenceInYears";


let useDefaultGanttScale = ({ startDate, endDate, containerWidth, layout }) => {
	let nameWidth = layout?.reportOptions?.gridWidth?.ganttSide || layout?.reportOptions?.gridWidth || 200;
	let ganttWidth = containerWidth - nameWidth;

	let years = startDate && endDate ? differenceInYears(new Date(endDate), new Date(startDate)) + 1 : undefined;

	if (isNaN(ganttWidth) || !years) return;
	let yearWidth = ganttWidth / (years || 1);
	let dayWidth = yearWidth / 365;
	let monthWidth = yearWidth / 12;
	let quarterWidth = yearWidth / 4;
	let halfYearWidth = yearWidth / 2;
	let fiveYearWidth = yearWidth * 5;
	let defaultValue =
		dayWidth >= 32
			? "day"
			: monthWidth >= 32
			? "month"
			: quarterWidth >= 32
			? "quarter"
			: halfYearWidth >= 100
			? "0.5 years"
			: yearWidth > 50
			? "year"
			: fiveYearWidth >= 75
			? "5 years"
			: "10 years";
	if (years <= 1 && !["day", "month"].includes(defaultValue)) defaultValue = "month";
	else if (years < 5 && ["5 years", "10 years"].includes(defaultValue)) defaultValue = "year";
	else if (years < 10 && defaultValue === "10 years") defaultValue = "5 years";
	return defaultValue;
};

export default useDefaultGanttScale;
