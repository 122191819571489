import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import getCheckPeriodValidOnNewScorecard from "./useCheckPeriodValidOnNewScorecard.getCheckPeriodValidOnNewScorecard";
import { getQueryParameters } from "@clearpoint/utils";

let useCheckPeriodValidOnNewScorecard = (getDataFlag) => {
	let { get } = useOldQueryStore();
	let { pathname, search } = useLocation();
	let periodList = getDataFlag ? get({ object: "period" }) : undefined;
	let scorecardList = getDataFlag ? get({ object: "scorecard" }) : undefined;
	let history = useHistory();
	let queryParameters = getQueryParameters(search);

	// If scorecard changes and doesn't include current period, set periodId to scorecard's most recent period
	return useCallback(
		({ periodId, previousSession, session }) => {
			let checkPeriodValidOnNewScorecard = getCheckPeriodValidOnNewScorecard({
				history,
				pathname,
				periodList,
				queryParameters,
				scorecardList,
			});
			return checkPeriodValidOnNewScorecard({ periodId, previousSession, session });
		},
		[history, pathname, periodList, queryParameters, scorecardList]
	);
};

export default useCheckPeriodValidOnNewScorecard;
