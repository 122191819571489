import { useCallback, useEffect } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import debounce from "lodash/debounce";
import styleRegEx from "./styleRegEx";
import { usePrevious } from "@clearpoint/hooks";


let useUpdateStyleValue = (name) => {
	let { setFormValue } = useFormContext();
	let debouncedSetFormValue = useCallback(debounce(setFormValue, 250, { maxWait: 1000 }), [setFormValue]); // eslint-disable-line react-hooks/exhaustive-deps
	let styleName = `_style${name}`;
	let styleValue = useFormValue(styleName);
	let previousStyleValue = usePrevious(styleValue);
	let value = useFormValue(name) || "";
	useEffect(() => {
		if (styleValue !== previousStyleValue || (styleValue && !styleRegEx.test(value))) {
			debouncedSetFormValue(name, styleValue + value?.replace(styleRegEx, ""));
		}
	});
};
export default useUpdateStyleValue;
