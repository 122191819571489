import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import mergeCustomFieldList from "./mergeCustomFieldList";

let propTypes = {
	scorecardId: PropTypes.number,
};
let useInitiativeUpdateFieldList = ({ scorecardId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();

	let customFieldList = get({ object: "customField", queryString: "?object=initiative" });

	return useCallback(() => {
		let filteredCustomFieldList = customFieldList
			?.filter((x) => x.updateMode)
			.filter((x) => !x.scorecardId || x.scorecardId.length === 0 || x.scorecardId.includes(scorecardId));

		let fieldList = [
			{ value: "statusId", label: translate("global.status"), sortOrder: -994, group: translate("initiative") },
			{
				value: "percentComplete",
				label: translate("defaultFields.update.initiative.percentComplete"),
				sortOrder: -993,
				group: translate("initiative"),
			},
			{
				value: "analysis",
				label: translate("defaultFields.update.initiative.analysis"),
				sortOrder: -992,
				group: translate("initiative"),
			},
			{
				value: "recommendations",
				label: translate("defaultFields.update.initiative.recommendations"),
				sortOrder: -991,
				group: translate("initiative"),
			},
		];
		return mergeCustomFieldList(fieldList, filteredCustomFieldList, translate);
	}, [scorecardId, customFieldList, translate]);
};
useInitiativeUpdateFieldList.propTypes = propTypes;
export default useInitiativeUpdateFieldList;
