import createUrl from "./Store.createUrl";
import { getIds } from "@clearpoint/utils";

let generateSetLocalFunction =
	(dispatch) =>
	({ data, ...id }) => {
		if (!id.url) {
			id.url = createUrl({ ...id });
		} else {
			id = { ...id, ...getIds({ url: id.url }) };
		}
		id.storeKey = id.url;
		if (!id.objectId && id.object && id[id.object + "Id"]) id.objectId = id[id.object + "Id"];
		dispatch({ ...id, data });
	};

export default generateSetLocalFunction;
