import ElementTabContent from "./ElementTabContent";
import FilterProvider from "@clearpoint/providers/FilterProvider";
import Loading from "../../../../Loading";
import useFormValue from "../../../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import { useFilterHiddenDefaultFields } from "@clearpoint/hooks";


let propTypes = {
	object: PropTypes.string,
	objectList: PropTypes.array,
};
let ElementTab = ({ object, objectList }) => {
	let { session } = useOldSession();
	let _fieldList = useFormValue("_fieldList");

	let filterHiddenDefaultFields = useFilterHiddenDefaultFields();
	let objectFieldList = useMemo(() => _fieldList.filter((x) => x.tabObject === object), [_fieldList, object]);
	let filteredObjectFieldList = useMemo(
		() => filterHiddenDefaultFields({ fieldList: objectFieldList, object }),
		[filterHiddenDefaultFields, object, objectFieldList]
	);
	let currentObjectFlag = object === session.object;
	let loadingFlag = [objectList, objectFieldList].includes(undefined);

	return loadingFlag ? (
		<Loading />
	) : (
		<FilterProvider data={filteredObjectFieldList}>
			<ElementTabContent object={object} currentObjectFlag={currentObjectFlag} objectList={objectList} />
		</FilterProvider>
	);
};
ElementTab.propTypes = propTypes;

export default ElementTab;
