import Block from "../Block";
import Column from "../Column";
import FormSelectActivity from "../Form/Select/Select.Activity";
import FormSelectApiKey from "../Form/Select/Select.ApiKey";
import FormSelectGroupedObjectType from "../Form/Select/Select.GroupedObjectType";
import FormSelectGroupedUser from "../Form/Select/Select.GroupedUser";
import FormSelectPeriod from "../Form/Select/Select.Period";
import FormSelectScorecard from "../Form/Select/Select.Scorecard";
import Row from "../Row";
import StyleWrapper from "../StyleWrapper";
import { useEffect } from "react";
import useFormValue from "../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let selectWrapperPropTypes = {
	children: PropTypes.element.isRequired,
};
let SelectWrapper = ({ children }) => (
	<Column medium={6} large={6} className="advanced-filter">
		<StyleWrapper borderColor={theme.gray} marginBottom={theme.space}>
			{children}
		</StyleWrapper>
	</Column>
);

let propTypes = {
	filterOptionList: PropTypes.arrayOf(PropTypes.string),
	setFilter: PropTypes.func.isRequired,
};

let defaultProps = {
	filterOptionList: ["activity", "apiKey", "object", "period", "scorecard", "user"],
};

let AdvancedFilter = ({ filterOptionList, setFilter }) => {
	let translate = useTranslate();
	let { activityList, apiKeyList, objectList, periodList, scorecardList, userList } = useFormValue();
	useEffect(() => {
		setFilter({
			field: "activityList",
			value: activityList,
		});
		setFilter({
			field: "apiKeyList",
			value: apiKeyList,
		});
		setFilter({
			field: "objectList",
			value: objectList,
		});
		setFilter({
			field: "periodList",
			value: periodList,
		});
		setFilter({
			field: "scorecardList",
			value: scorecardList,
		});
		setFilter({
			field: "userList",
			value: userList,
		});
	}, [activityList, apiKeyList, objectList, periodList, scorecardList, setFilter, userList]);

	return (
		<Block
			border={`1px solid ${theme.gray}`}
			backgroundColor="white"
			borderTop="none"
			padding={`${theme.space} ${theme.mediumSpace} ${theme.tinySpace} ${theme.mediumSpace}`}
		>
			<Row>
				{filterOptionList.includes("scorecard") && (
					<SelectWrapper>
						<FormSelectScorecard
							data-testid="advanced-filter-scorecards-select"
							name="scorecardList"
							placeholder={translate("edit.selectScorecards")}
							multipleFlag
						/>
					</SelectWrapper>
				)}
				{filterOptionList.includes("period") && (
					<SelectWrapper>
						<FormSelectPeriod
							data-testid="advanced-filter-periods-select"
							name="periodList"
							placeholder={translate("layout.selectPeriods")}
							multipleFlag
							hideSelectAllSelectNoneFlag
						/>
					</SelectWrapper>
				)}
				{filterOptionList.includes("object") && (
					<SelectWrapper>
						<FormSelectGroupedObjectType
							data-testid="advanced-filter-element-types-select"
							name="objectList"
							placeholder={translate("edit.aggregate.selectElementTypes")}
							multipleFlag
							scorecardFlag
							milestoneFlag
							measureSeriesFlag
							chartFlag
						/>
					</SelectWrapper>
				)}
				{filterOptionList.includes("activity") && (
					<SelectWrapper>
						<FormSelectActivity
							data-testid="advanced-filter-action-select"
							name="activityList"
							placeholder={translate("notifications.selectAction")}
							multipleFlag
						/>
					</SelectWrapper>
				)}
				{filterOptionList.includes("user") && (
					<SelectWrapper>
						<FormSelectGroupedUser data-testid="advanced-filter-users-select" name="userList" multipleFlag />
					</SelectWrapper>
				)}
				{filterOptionList.includes("apiKey") && (
					<SelectWrapper>
						<FormSelectApiKey data-testid="advanced-filter-api-key-select" name="apiKeyList" multipleFlag />
					</SelectWrapper>
				)}
			</Row>
		</Block>
	);
};

SelectWrapper.propTypes = selectWrapperPropTypes;
AdvancedFilter.propTypes = propTypes;
AdvancedFilter.defaultProps = defaultProps;

export default AdvancedFilter;
