import Alert from "@clearpoint/old-theme/Alert/Alert";
import EvaluationsField from "@clearpoint/old-theme/Form/Field/Evaluations";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { useOldQueryStore } from "@clearpoint/old-query-store";

let EvaluationTab = () => {
	let translate = useTranslate();
	let { parent, evaluations, measureId, objectId } = useFormValue();
	let { get } = useOldQueryStore();
	let seriesList = measureId && get({ parent: "measure", parentId: measureId, object: "measureSeries" });
	let useForEvaluationFlag = seriesList?.find((x) => x.objectId === objectId)?.useForEvaluation;
	let noEvaluationEnabled = true;
	noEvaluationEnabled = !evaluations || !evaluations.some(({ enabled }) => enabled);

	return (
		<>
			<FormCheck
				defaultValue={useForEvaluationFlag}
				disabled={noEvaluationEnabled}
				name="useForEvaluation"
				text={translate("notify.useThisSeries")}
			/>
			{noEvaluationEnabled && (
				<Alert data-testid="evaluations" color="warning">
					{translate("notify.useThisSeriesWarning")}
				</Alert>
			)}
			<EvaluationsField
				name="evaluations"
				label={translate("edit.evaluationTemplate")}
				seriesType={parent || "measure"}
				seriesSelectFlag
			/>
		</>
	);
};

export default EvaluationTab;
