import { useCallback } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import checkAccess from "./useCheckAccess.checkAccess";

let useCheckAccess = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let scorecardList = get({ object: "scorecard" });
	let options = get({ object: "options" });
	let archiveFlag = options?.archive;

	return useCallback(
		({ access, action, toastFlag, scorecardId }) => {
			let checkAccessCallback = checkAccess(translate, scorecardList, archiveFlag);
			return checkAccessCallback({ access, action, toastFlag, scorecardId });
		},
		[archiveFlag, scorecardList, translate]
	);
};
export default useCheckAccess;
