import { useEffect, useMemo, useRef, useState } from "react";

import { useReportFilter } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyArray } from "@clearpoint/utils";

let loadingObject = { chartList: [], chartLoadingFlag: true };

const CHART_LOADING_BATCH_SIZE = 512; // poorer percieved performance, temporary fix

let useChartList = (elementList, exportFlag) => {
	let [loadingChartCount, setLoadingChartCount] = useState(exportFlag ? 512 : CHART_LOADING_BATCH_SIZE);

	let { get, isLoading } = useOldQueryStore();
	let { reportFilter } = useReportFilter();
	// temp fix
	// let timeoutRef = useRef(null);

	// useEffect(() => {

	// 	if (exportFlag) return;
	// 	if (timeoutRef.current || !elementList) return;
	// 	if (loadingChartCount >= elementList.length) return;

	// 	timeoutRef.current = setTimeout(() => {
	// 		setLoadingChartCount((prev) => prev + CHART_LOADING_BATCH_SIZE);
	// 		timeoutRef.current = null;
	// 	}, 7500);

	// 	return () => {
	// 		if (timeoutRef.current) clearTimeout(timeoutRef.current);
	// 	};
	// }, [elementList, exportFlag, loadingChartCount]);

	let chartReferenceList = useMemo(() => {
		if (!elementList?.elements) return [];
		return elementList.elements.filter((x) => x.object === "chart");
	}, [elementList?.elements]);

	let chartDataList = useMemo(() => {
		let chartDataList = chartReferenceList.slice(0, loadingChartCount).map((x) => {
			let chart = elementList?.charts?.find((y) => y.chartId === x.objectId);
			if (chart) {
				let value = { ...chart.chartFormat, seriesData: [...chart.chartData] };
				return { value, chartLoadingFlag: false };
			} else {
				let key = { object: "chart", objectId: x.objectId, periodId: x.periodId };
				let value = get(key);
				let chartLoadingFlag = !value || isLoading(key);
				return { value, chartLoadingFlag };
			}
		});
		if (chartDataList.some((x) => x.chartLoadingFlag)) return emptyArray;
		chartDataList = chartDataList.map((x) => x.value);
		return chartDataList;
	}, [elementList, chartReferenceList, loadingChartCount, get, isLoading]);

	// use chartDataListRef to keep track of the last chartDataList with viable data
	let chartDataListRef = useRef(null);
	useEffect(() => {
		if (chartDataList.length > 0) chartDataListRef.current = chartDataList;
	}, [chartDataList]);

	let chartList = useMemo(() => {
		let referenceList = chartDataList?.length > 0 ? chartDataList : chartDataListRef.current;
		if (!referenceList) return loadingObject.chartList;

		let lengthDiff = (chartReferenceList?.length || 0) - (referenceList?.length || 0);
		if (lengthDiff === 0) return referenceList;

		// if chartListRef has less charts than referenceList, add empty charts to chartListRef with loadingFlag
		let emptyChartList = Array.from({ length: lengthDiff }, () => ({ chartLoadingFlag: true }));
		return [...referenceList, ...emptyChartList];
	}, [chartDataList, chartReferenceList]);

	let filteredChartList = useMemo(() => {
		if (reportFilter) {
			return chartList.filter((chart) => chart.name.toLowerCase().includes(reportFilter.toLowerCase()));
		}
		return chartList;
	}, [chartList, reportFilter]);

	return { chartList: filteredChartList, chartLoadingFlag: false };
};

export default useChartList;
