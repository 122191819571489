let getEditStatusDisplayFlag = (field) => {
	if (!field) return false;
	let { fieldId, updateMode } = field;
	let seriesFlag = updateMode && fieldId === "seriesData";
	let objectiveScoreFlag = updateMode && fieldId === "objectiveScore";
	let calculatedFlag = updateMode && fieldId.slice(0, 10) === "calculated";
	return seriesFlag || objectiveScoreFlag || calculatedFlag;
};

export default getEditStatusDisplayFlag;
