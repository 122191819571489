import StyleWrapper from "../StyleWrapper";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let fieldsetPropTypes = {
	border: PropTypes.string,
	dataTestId: PropTypes.string,
	marginBottom: PropTypes.string,
	marginTop: PropTypes.string,
	paddingBottom: PropTypes.string,
};

let StyledFieldset = ({ border, dataTestId, marginBottom, marginTop, paddingBottom, ...props }) => (
	<StyleWrapper
		padding={`${theme.smallSpace} ${theme.space} ${paddingBottom || "0px"}`}
		border={border || `1px solid ${theme.gray}`}
		data-testid={dataTestId}
		minWidth="0"
		marginLeft="0"
		marginRight="0"
		marginTop={marginTop || theme.mediumSpace}
		marginBottom={marginBottom || theme.space}
		$style={`&:first-child {
			margin-top: ${marginTop || "0px"};
		}
		&:last-child {
			margin-bottom: ${marginBottom || "0px"};
		}
		& > fieldset {
			margin-top: 0;
		}		
		ul {
			padding-left: 20px;
		}
`}
	>
		<fieldset {...props} />
	</StyleWrapper>
);

let legendProptypes = {
	maxWidth: PropTypes.string,
};

let StyledLegend = ({ maxWidth, ...props }) => (
	<StyleWrapper
		fontSize="1em"
		fontWeight="700"
		padding={`0 ${theme.smallSpace}`}
		border="none"
		width="auto"
		margin="0"
		color="inherit"
		minWidth="0"
		maxWidth={maxWidth || "100%"}
		ellipsisFlag
		display="block"
		lineHeight="inherit"
		overflow="visible"
	>
		<legend {...props}>{props.children}</legend>
	</StyleWrapper>
);

let propTypes = {
	border: PropTypes.string,
	children: PropTypes.node,
	dataTestId: PropTypes.string,
	className: PropTypes.string,
	legendMaxWidth: PropTypes.string,
	marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.node,
};

let FormGroup = ({
	border,
	children,
	className,
	dataTestId,
	legendMaxWidth,
	marginBottom,
	marginTop,
	paddingBottom,
	style,
	title,
}) => {
	if (!title) return children;

	return (
		<StyledFieldset
			border={border}
			className={className}
			marginBottom={marginBottom}
			marginTop={marginTop}
			paddingBottom={paddingBottom}
			style={style}
			dataTestId={dataTestId}
		>
			<StyledLegend maxWidth={legendMaxWidth}>{title}</StyledLegend>
			{children}
		</StyledFieldset>
	);
};
StyledFieldset.propTypes = fieldsetPropTypes;
StyledLegend.propTypes = legendProptypes;
FormGroup.propTypes = propTypes;

export default FormGroup;
