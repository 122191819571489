/* eslint-disable complexity */
import isEqual from "lodash/isEqual";

import { getIds } from "@clearpoint/utils";

import createUrl from "./Store.createUrl";
import fetchData from "./Store.generateGetFunction.fetchData";

let generateGetFunction =
	({
		dispatch,
		get,
		http,
		httpExpress,
		mockData,
		objectToKey,
		requests,
		setState,
		stateRef,
		testFlag,
		waitingRequestCount,
	}) =>
	(parameters, promiseFlag) => {
		// get url and identifying data (id) from parameters
		let { expressFlag, mockDataFlag, payload, skipSortFlag, skipWaitFlag, url } = parameters;
		let id = { ...parameters };

		delete id.url;
		delete id.mockDataFlag;
		if (!url && Object.keys(id).length === 0) {
			throw new Error("Invalid call to store: get. " + JSON.stringify({ id, url }));
		}

		if (url) {
			id.originalUrl = url;
			id = { ...getIds({ url }), ...id };
		} else if (id.object) {
			url = createUrl(id);
		} else if (JSON.stringify(id) === "{}") {
			return {};
		} else {
			console.error("Error creating URL", JSON.stringify(id));
			return {};
		}
		let { object, objectId, parent } = id;
		id.url = url;
		let method =
			["preview", "count", "search"].includes(object) || (object === "map" && parent === "measureSeries" && payload)
				? "post"
				: (object === "filter"
				? "put"
				: "get");
		let storeKey = ["post", "put"].includes(method) && payload ? url + objectToKey(payload) : url;
		if (mockDataFlag) {
			return mockData(storeKey);
		}
		// return value from store if exists
		let value = stateRef.current.find((x) => x && x.storeKey === storeKey);
		if (value?.data && !value._REFRESH) return promiseFlag ? Promise.resolve(value.data) : value.data;
		// fetch data if not in store
		// data from response along with identifying data is stored as an object in the store array
		// this way data can be filtered by any property
		// array data is stored on a List key
		let httpFunction = expressFlag ? httpExpress : http;
		if (requests.current.some((x) => isEqual(x.id, storeKey))) {
			if (promiseFlag) return requests.current.find((x) => isEqual(x.id, storeKey)).request;
			if (value?._REFRESH) return value.data;
		} else {
			return fetchData({
				dispatch,
				get,
				http,
				httpFunction,
				id,
				method,
				object,
				objectId,
				payload,
				promiseFlag,
				requests,
				setState,
				skipSortFlag,
				skipWaitFlag,
				storeKey,
				testFlag,
				url,
				value,
				waitingRequestCount,
			});
		}
	};

export default generateGetFunction;
