import EditCategoryTab from "./EditCategoryTab";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";

let editCategoryModalContentPropTypes = {
	addFlag: PropTypes.bool,
	disabled: PropTypes.bool,
};

let EditCategoryModalContent = ({ addFlag, disabled }) => {
	let translate = useTranslate();
	let name = useFormValue("name");

	return (
		<>
			<ModalWindowHeader badgeIcon="category" badgeText={translate("category")} title={name} />
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<EditCategoryTab
						disabled={disabled}
						title={translate(`edit.${addFlag ? "addCategory" : "editCategory"}`)}
					/>
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter />
		</>
	);
};

let editCategoryModalPropTypes = {
	addFlag: PropTypes.bool,
	objectId: PropTypes.number,
	disabled: PropTypes.bool,
};

let EditCategoryModal = ({ addFlag, objectId, disabled, ...props }) => {
	let translate = useTranslate();
	let onSubmit = useCallback(() => {
		toast.success(
			translate(objectId ? "toaster.messages.objects.categoryUpdated" : "toaster.messages.objects.categoryAdded")
		);
	}, [objectId, translate]);
	return (
		<ModalWindowEdit size="medium" object="category" objectId={objectId} onSubmit={onSubmit} updateFlag {...props}>
			<EditCategoryModalContent addFlag={addFlag} disabled={disabled} />
		</ModalWindowEdit>
	);
};

EditCategoryModalContent.propTypes = editCategoryModalContentPropTypes;
EditCategoryModal.propTypes = editCategoryModalPropTypes;
export default EditCategoryModal;
