import List from "./List";
import { useCallback, useState } from "react";
import { useCreateObjectiveMutation } from "@clearpoint/query";
import { useMainStore } from "@clearpoint/store";

let Create = () => {
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { mutate: createObjective } = useCreateObjectiveMutation();
	let [name, setName] = useState();
	let handleName = (e) => setName(e.target.value);
	let create = useCallback(() => {
		createObjective({ name, scorecardId });
	}, [createObjective, name, scorecardId]);
	return (
		<div>
			<div>
				<label for="name">Name</label>
				<input name="name" onChange={handleName} />
			</div>
			<div>
				<button onClick={create}>Create Objective</button>
			</div>
			<List />
		</div>
	);
};

export default Create;
