let generateReorderList =
	({ list, reorderListProp, setList }) =>
	(originIndex, destinationIndex) => {
		if (reorderListProp) {
			let reorderedList = reorderListProp({ originIndex, destinationIndex, list });
			setList(reorderedList);
			return;
		}
		let selectedItem = list[originIndex];
		let listWithItemRemoved = [...list.slice(0, originIndex), ...list.slice(originIndex + 1)];
		let reorderedList = [
			...listWithItemRemoved.slice(0, destinationIndex),
			selectedItem,
			...listWithItemRemoved.slice(destinationIndex),
		];
		setList(reorderedList);
	};

export default generateReorderList;
