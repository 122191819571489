import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useFormatDate } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayStartDate = ({ name }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let startDate = useFormValue(name);
	return startDate ? <span>{formatDate(startDate, "short")}</span> : translate("global.notAvailable");
};

FormFieldDisplayStartDate.propTypes = propTypes;
export default FormFieldDisplayStartDate;
