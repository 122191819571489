import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import ModalBody from "../Modal/Modal.Body";
import ModalFooter from "../Modal/Modal.Footer";
import ModalHeader from "../Modal/Modal.Header";
import ModalTitle from "../Modal/Modal.Title";
import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import revertChange from "./RevertModal.revertChange";
import { useFormatDate } from "@clearpoint/hooks";


let propTypes = {
	afterClose: PropTypes.func,
	close: PropTypes.func.isRequired,
	date: PropTypes.string.isRequired,
	modalVisible: PropTypes.bool,
	object: PropTypes.string.isRequired,
	objectId: PropTypes.number.isRequired,
	periodId: PropTypes.number,
};

let RevertModal = ({ afterClose, close, date, details, modalVisible, object, objectId, periodId }) => {
	let { clear, get, set } = useOldQueryStore();
	let translate = useTranslate();
	let formatDate = useFormatDate();

	let revertChangeHandler = useCallback(() => {
		revertChange({ afterClose, clear, close, details, get, object, objectId, periodId, set, translate });
	}, [afterClose, clear, close, details, get, object, objectId, periodId, set, translate]);

	return object ? (
		<Modal data-testid="revert-modal" modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<ModalTitle>{translate("revisionHistory.revertChange")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{translate("revisionHistory.confirmRevert", {
					revertDate: formatDate(date, "short"),
					revertTime: formatDate(date, "time"),
				})}
			</ModalBody>
			<ModalFooter>
				<Button data-testid="cancel-button" onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button data-testid="apply-button" onClick={revertChangeHandler} color="primary">
					{translate("revisionHistory.apply")}
				</Button>
			</ModalFooter>
		</Modal>
	) : null;
};

RevertModal.propTypes = propTypes;

export default RevertModal;
