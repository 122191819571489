import { memo, useLayoutEffect, useMemo } from "react";
import { rgba } from "polished";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import { useAccountStyles, useCheckFeature } from "@clearpoint/hooks";

// Vite does not support scss imports, so manually copy from index.scss into object above.

let theme = {
	accordionBackground: "#fafafb",
	backgroundBlue: "#d4ebf9",
	backgroundGrey: "#f3f3f4",
	black: "#000000",
	blueLink: "#337ab7",
	bold: "600",
	bolder: "700",
	boldish: "500",
	borderRadius: "4px",
	boxShadowMainMenu: "5px 4px 4px rgb(0 0 0 / 12%), 3px 4px 4px rgb(0 0 0 / 24%)",
	boxShadowRoundedInput: "inset 1px 1px 9px -6px rgba(0,0,0,.5)",
	brightBlue: "#1c84c6",
	brown: "#8a6d3b",
	brownerWhite: "#faebcc",
	brownWhite: "#fcf8e3",
	cpsRed: "#f21373",
	danger: "#ed5565",
	dangerDark: "#a94442",
	darkerGray: "#333",
	darkerPrimary: "#0c594a",
	darkerWarning: "#a35705",
	darkGray: "#676a6c",
	darkGray2: "#555",
	darkGray3: "#5e5e5e",
	darkGreen: "#3c763d",
	darkIceBlue: "#7db7dc",
	darkLazur: "#81dbdd",
	darkPrimary: "#13866f",
	darkTeal: "#008597",
	darkWarning: "#f58208",
	deactivated: "#BBBBBB",
	deleted: "#ffc6c6",
	fontSizeBase: "0.875rem",
	getPlaceholderCss: (color) => `&::placeholder { color: ${color}; opacity: 0.5; } `,
	gray: "#e5e6e7",
	gray2: "#e0e0e0",
	gray3: "#a0a0a0",
	gray4: "#777777",
	gray5: "#e3e3e3",
	gray6: "#f3f3f4",
	gray7: "#cececf",
	gray100: "#f8f9fa",
	gray200: "#e9ecef",
	gray300: "#dee2e6",
	gray400: "#ced4da",
	gray500: "#adb5bd",
	gray600: "#6c757d",
	gray700: "#495057",
	gray800: "#343a40",
	greenCheck: "#2C8F7B",
	highlightYellow: "#ffffe0",
	highlightYellow2: "#ffff98",
	hugeSpace: "40px",
	iceBlue: "#dde8f0",
	info: "#23c6c8",
	large: "1rem",
	largeSpace: "30px",
	lazur: "#def5f6",
	light: 300,
	lighterBlue: "#d9edf7",
	lighterBlue2: "#daeaf5",
	lighterGray: "#f4f3f3",
	lighterPrimary: "#c1e2b3",
	lightestPrimary: "#dff0d8",
	lightGray: "#e7eaec",
	lightGray2: "#c2c2c2",
	lightGray3: "#d2d2d2",
	lightGray4: "#eeeeee",
	lightGray5: "#efefef",
	lightGray6: "#d1dade",
	lightGray7: "#cccccc",
	lightGreen: "#d6e9c6",
	lightPrimary: "#19aa8d",
	lightYellow: "#ffffcc",
	lightYellow2: "#f7f7de",
	lineHeightBase: "1.42",
	mediumBlue: "#428bca",
	mediumGray: "#bababa",
	mediumLargeSpace: "25px",
	mediumSpace: "20px",
	microSpace: "1px",
	modalListItemBoxShadow: "2px 2px 3px rgb(86 96 117 / 40%)",
	muted: "#888",
	mutedBlue: "#6b8eb2",
	mutedGreen: "#6bb28e",
	navBackground: "#27245e",
	navDarkBackground: "#27245e",
	navTextColor: "#a7b1c2",
	neutral: "#999999",
	normal: "400",
	offWhite: "#f5f5f5",
	offWhiteHover: "rgba(0, 0, 0, 0.02)",
	orange: "#f9ac5a",
	orange2: "#f8ac59",
	orange3: "rgba(247, 165, 74, 0.8)",
	orangeDisabled: "#dbcbb9",
	primary: "#1ab394",
	secondary: "#6c757d",
	shadowCss: `
		transition: all .25s ease-in-out;
		&:hover, &.hover { 
			z-index: 100;
			box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%); 
		}`,
	smallSpace: "10px",
	space: "15px",
	success: "#1d84c7",
	teal: "#1ab394",
	tinySpace: "5px",
	warning: "#f9ac5a",
	white: "#ffffff",
	yellow: "#ffff00",
};

let Theme = ({ children }) => {
	useAccountStyles();
	let checkFeature = useCheckFeature();
	let defaultFontFamily; let defaultFontSize;
	defaultFontFamily = "Roboto";
	defaultFontSize = "16px";
	let buttonBorderRadius; let buttonLineHeight; let buttonPaddingX; let buttonPaddingY;
	buttonBorderRadius = "4px";
	buttonLineHeight = 1.2;
	buttonPaddingX = "12px";
	buttonPaddingY = "6px";
	let modalAnimation = "none";
	useLayoutEffect(() => {
		let root = document.documentElement;
		if (!document.querySelector("#scrollbar")) {
			let scrollbarElement = document.createElement("style");
			scrollbarElement.id = "scrollbar";
			let css = `
					::-webkit-scrollbar {
						width: 15px;
						height: 15px;
					}
					::-webkit-scrollbar-thumb {
						background: ${rgba(160, 160, 160, 0.5)};
						border-radius: 15px;
						border: 4px transparent solid;
						background-clip: padding-box;
					}
					*:hover::-webkit-scrollbar-thumb {
						background: ${rgba(62.75, 62.75, 62.75, 0.75)};
						border-radius: 15px;
						border: 4px transparent solid;
						background-clip: padding-box;
				  }
					*:hover::-webkit-scrollbar-thumb:hover {
						background: ${theme.gray3};
						border-radius: 15px;
						border: 4px transparent solid;
						background-clip: padding-box;
				  }
			`;
			scrollbarElement.append(document.createTextNode(css));
			document.querySelector("head").append(scrollbarElement);
		}
		root.style.setProperty("--default-font-family", defaultFontFamily);
		root.style.setProperty("--default-font-size", defaultFontSize);
		root.style.setProperty("--modal-animation", modalAnimation);
		root.style.setProperty("--btn-border-radius", buttonBorderRadius);
		root.style.setProperty("--btn-line-height", buttonLineHeight);
		root.style.setProperty("--btn-padding-x", buttonPaddingX);
		root.style.setProperty("--btn-padding-y", buttonPaddingY);
	}, [
		buttonBorderRadius,
		buttonLineHeight,
		buttonPaddingX,
		buttonPaddingY,
		checkFeature,
		defaultFontFamily,
		defaultFontSize,
		modalAnimation,
	]);
	theme = useMemo(() => ({ ...theme, defaultFontFamily, defaultFontSize }), [defaultFontFamily, defaultFontSize]);
	return <ThemeProvider theme={theme || {}}>{children}</ThemeProvider>;
};

Theme.propTypes = { children: PropTypes.node.isRequired };

export default memo(Theme);
export { theme };
