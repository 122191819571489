import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectChartSize = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: "small", label: translate("global.small") },
			{ value: "medium", label: translate("global.medium") },
			{ value: "large", label: translate("global.large") },
		],
		[translate]
	);
	return <FormSelect options={options} placeholder={translate("edit.chart.selectChartSize") + "..."} {...props} />;
};

export default SelectChartSize;
