import PageTitle from "@clearpoint/old-theme/PageTitle/PageTitle";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";

let DashboardPageTitle = () => {
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let { layout, ReferenceTitle } = useLocalState();
	return ReferenceTitle ? (
		<ReferenceTitle />
	) : (
		<PageTitle title={translateOnlyInsideCurlyBraces(layout.name)} subTitle={layout.scorecard} />
	);
};

export default DashboardPageTitle;
