import FilterSelect from "@clearpoint/old-theme/Filter/Filter.Select";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let ElementTypeFilterSelect = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { scorecardId } = useOldSession().session;
	let menu = scorecardId && get({ object: "menu", parent: "scorecard", parentId: scorecardId });
	let elementTypeOptions = useMemo(
		() => [
			{ value: 0, label: translate("edit.aggregate.allElements") },
			...menu
				.map((x) => x.object)
				.filter((x) => ["objective", "measure", "initiative", "actionItem", "risk"].includes(x))
				.map((x) => ({
					value: x,
					label: translate(x),
				})),
		],
		[menu, translate]
	);
	return <FilterSelect options={elementTypeOptions} name="elementType" filterBy="object" noFilterStyleFlag />;
};

export default ElementTypeFilterSelect;
