import List from "./List";
import { useCallback } from "react";
import { useState } from "react";
import { useUpdateObjectiveMutation, useObjectiveListQuery, useObjectiveStatusQuery } from "@clearpoint/query";
import { useMainStore } from "@clearpoint/store";

let Update = () => {
	let periodId = useMainStore((state) => state.periodId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { data: objectiveList } = useObjectiveListQuery({ periodId, scorecardId });
	let { objectId } = objectiveList[0] || {};
	let { data: objectiveStatus } = useObjectiveStatusQuery({ objectId, periodId });
	let { mutate: updateObjective } = useUpdateObjectiveMutation({ objectId, periodId });
	let [analysis, setAnalysis] = useState();
	let handleAnalysis = (e) => setAnalysis(e.target.value);
	let update = useCallback(() => {
		updateObjective({ analysis });
	}, [analysis, updateObjective]);
	return (
		<div>
			<div>
				<label for="analysis">Analysis</label>
				<br />
				<textarea rows={3} defaultValue={objectiveStatus.analysis} name="analysis" onChange={handleAnalysis} />
			</div>
			<div>Current Analysis: {objectiveStatus.analysis}</div>
			<div>
				<button onClick={update}>Update Objective</button>
			</div>
			<List detail="analysis" />
		</div>
	);
};

export default Update;
