import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

let StyledIcon = styled(FontAwesomeIcon)`
	animation-delay: ${(props) => (props.spin ? "var(--fa-animation-delay, 0s) !important" : undefined)};
	animation-duration: ${(props) => (props.spin ? "var(--fa-animation-duration, 2s) !important" : undefined)};
	animation-iteration-count: ${(props) => (props.spin ? "infinite !important" : undefined)};
	color: ${(props) => props.theme[props.$color] || props.$color};
	cursor: ${(props) =>
		props.cursor || (props.onClick || props.$pointerFlag ? "pointer" : props.disabled ? "not-allowed" : undefined)};
	flex-shrink: 0;
	margin-right: ${(props) => props.$marginRight};
	margin-left: ${(props) => props.$marginLeft};
	font-size: ${(props) => props.$fontSize};
	overflow: visible !important;
	transform: rotateZ(${(props) => props.$rotation});
	width: ${(props) => (props.$noFixedWidthFlag ? undefined : "1.28em !important")};
	&:hover {
		filter: ${(props) =>
			props.onClick || props.$pointerFlag ? `brightness(${props.$hoverBrightness || 0.65})` : undefined};
	}
`;

export default StyledIcon;
