import FormInput from "../../Form/Input/Input";
import FormSelect from "./FormSelect";
import SelectPeriod from "./Select.Period";
import SelectPeriodGroup from "./Select.PeriodGroup";
import useFormValue from "../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	fieldId: PropTypes.string,
	marginBottom: PropTypes.string,
	name: PropTypes.string,
	periodCount: PropTypes.string,
	selectedPeriods: PropTypes.string,
	uppercaseLabelFlag: PropTypes.bool,
};
let FormSelectLayoutPeriod = ({
	fieldId,
	marginBottom,
	name,
	periodCount,
	selectedPeriods,
	periodGroup,
	uppercaseLabelFlag,
	...props
}) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let periodGroupId = useFormValue(periodGroup);
	let options = useMemo(
		() => [
			{ value: "cp", label: translate("layout.currentPeriod") },
			{ value: "exact", label: translate("layout.selectedPeriods") },
			{ value: "cy", label: translate("layout.currentCalendarYear") },
			{ value: "fy", label: translate("layout.currentFiscalYear") },
			{ value: "cytd", label: translate("layout.calendarYTD") },
			{ value: "fytd", label: translate("layout.fiscalYTD") },
			{ value: "last", label: translate("layout.lastNPeriods") },
			{ value: "plusminus", label: translate("layout.plusMinusNPeriods") },
			{ value: "next", label: translate("layout.nextNPeriods") },
		],
		[translate]
	);

	let reportingFrequencyFlag = fieldId !== "name";
	return (
		<>
			{reportingFrequencyFlag && (
				<SelectPeriodGroup
					label={translate("global.periodicity")}
					marginBottom={marginBottom}
					name={periodGroup}
					uppercaseLabelFlag={uppercaseLabelFlag}
				/>
			)}
			<FormSelect
				marginBottom={marginBottom}
				name={name}
				options={options}
				uppercaseLabelFlag={uppercaseLabelFlag}
				{...props}
			/>
			{["last", "plusminus", "next"].includes(value) && (
				<FormInput
					data-testid="period-count-input"
					name={periodCount}
					placeholder={translate("logFieldLabels.periodCount") + "..."}
					type="number"
					min="0"
					max="18"
				/>
			)}
			{value === "exact" && (
				<SelectPeriod
					name={selectedPeriods}
					marginBottom={marginBottom}
					multipleFlag
					compactFlag
					periodGroupId={periodGroupId}
				/>
			)}
		</>
	);
};
FormSelectLayoutPeriod.propTypes = propTypes;
export default FormSelectLayoutPeriod;
