let getPeriodBadgeVisibleFlag = (buttonCount, podWidthDisplayClass, updateModeFlag) => {
	return (
		updateModeFlag &&
		(buttonCount === 2
			? podWidthDisplayClass === "large"
			: podWidthDisplayClass === "medium" || podWidthDisplayClass === "large")
	);
};

export default getPeriodBadgeVisibleFlag;
