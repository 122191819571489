import Badge from "../../../Badge/Badge";
import Column from "../../../Column";
import FormGroup from "../../../Form/Form.Group";
import FormInput from "../../../Form/Input/Input";
import FormSelectTag from "../../../Form/Select/Select.Tag";
import Help from "../../../Help";
import RadioBoolean from "../../../Form/Radio/Radio.Boolean";
import Row from "../../../Row";
import SelectAggregateActionItemType from "./SelectAggregateActionItemType";
import SelectAggregateInitiativeType from "./SelectAggregateInitiativeType";
import SelectAggregateMeasureType from "./SelectAggregateMeasureType";
import SelectAggregateMilestoneType from "./SelectAggregateMilestoneType";
import SelectAggregateRiskType from "./SelectAggregateRiskType";
import SelectAggregateScorecardTypeSelectDisplay from "../Shared/AggregateScorecardTypeSelectDisplay";
import SelectAggregateSeriesType from "./SelectAggregateSeriesType";
import SelectAggregateType from "./SelectAggregateType";
import SelectScorecard from "../../../Form/Select/Select.Scorecard";
import SelectShadowObjectType from "../../../Form/Select/Select.ShadowObjectType";
import useCount from "./useCount";
import useFormValue from "../../../Form/hooks/useFormValue";
import { Fragment, useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import capitalize from "lodash/capitalize";
import getAggregateStatusOptions from "../AggregateStatus/getAggregateStatusOptions";
import { theme } from "../../../Theme";
import { emptyObject, joinNameList } from "@clearpoint/utils";


let selectLookup = {
	measure: SelectAggregateMeasureType,
	initiative: SelectAggregateInitiativeType,
	milestone: SelectAggregateMilestoneType,
	actionItem: SelectAggregateActionItemType,
	risk: SelectAggregateRiskType,
};

let propTypes = {
	calcError: PropTypes.bool,
	name: PropTypes.string,
	seriesType: PropTypes.string,
};

let InputAggregate = ({ calcError, name, seriesType }) => {
	let formValue = useFormValue();
	let translate = useTranslate();
	let { measureId, scorecardId, measureSeriesId } = formValue;
	let currentFormValue = useFormValue(name);
	let {
		aggregateMeasures,
		aggregateMeasureName,
		aggregateMeasureTag,
		aggregateMeasureType,
		aggregateName,
		aggregateObject,
		aggregateScorecards,
		aggregateScorecardType,
		aggregateScorecardTag,
		aggregateSeriesTag,
		aggregateSeriesType,
	} = currentFormValue || emptyObject;
	let SelectAggregateObjectType = selectLookup[aggregateObject] || Fragment;

	let namedObjectFlag = [3, 4].includes(aggregateMeasureType);
	let { hasAggregateScorecardTypeFlag } = useMemo(
		() => getAggregateStatusOptions(formValue, currentFormValue),
		[formValue, currentFormValue]
	);

	let showAggregateSeriesCount = false;
	let showAggregateMeasureCount = false;
	if (measureId && scorecardId && aggregateScorecardType !== null) {
		if (aggregateSeriesType < 2 && aggregateName) {
			showAggregateSeriesCount = true;
		} else if (aggregateSeriesType === 2 && aggregateSeriesTag) {
			showAggregateSeriesCount = true;
		}
		showAggregateMeasureCount = false;
		if ([0, 1, 2, 5, 6].indexOf(aggregateMeasureType) !== -1) {
			showAggregateMeasureCount = true;
		} else if ((aggregateMeasureType === 3 || aggregateMeasureType === 4) && aggregateMeasureName) {
			showAggregateMeasureCount = true;
		} else if (aggregateMeasureType === 7 && aggregateMeasureTag) {
			showAggregateMeasureCount = true;
		}
	}
	let countPayload = useMemo(
		() => ({
			filter: {
				scorecardId: scorecardId,
				measureId: measureId,
				measureSeriesId: measureSeriesId,
				aggregateScorecardType: aggregateScorecardType,
				aggregateScorecards: aggregateScorecards,
				aggregateMeasureType: aggregateMeasureType == null ? -1 : aggregateMeasureType,
				aggregateSeriesType: aggregateSeriesType == null ? -1 : aggregateSeriesType,
				aggregateObject: aggregateObject == null ? "" : aggregateObject,
				aggregateMeasures,
				aggregateMeasureName,
				aggregateName,
				aggregateMeasureTag,
				aggregateSeriesTag,
				aggregateScorecardTag,
			},
		}),
		[
			aggregateMeasureName,
			aggregateMeasureTag,
			aggregateMeasureType,
			aggregateMeasures,
			aggregateName,
			aggregateObject,
			aggregateScorecardTag,
			aggregateScorecardType,
			aggregateScorecards,
			aggregateSeriesTag,
			aggregateSeriesType,
			measureId,
			measureSeriesId,
			scorecardId,
		]
	);
	let count = useCount(countPayload);
	return (
		<FormGroup border={calcError ? "1px solid red" : ""} title={translate("logFieldLabels.aggregateSeries")}>
			<Help feature="aggregate-series" />
			<Row>
				<Column medium={4}>
					<SelectAggregateScorecardTypeSelectDisplay
						hasAggregateScorecardTypeFlag={hasAggregateScorecardTypeFlag}
						labelText={translate("scorecard")}
						name={joinNameList(name, "aggregateScorecardType")}
						placeholder={translate("edit.selectScorecards") + "..."}
						scorecardCount={count?.scorecardCount}
						seriesType={seriesType}
						required
					/>
				</Column>
				{aggregateScorecardType === 3 && (
					<Column medium={4}>
						<SelectScorecard
							label={translate("edit.selectSpecificScorecards")}
							name={joinNameList(name, "aggregateScorecards")}
							multipleFlag
							required
						/>
					</Column>
				)}
				{aggregateScorecardType === 4 && (
					<Column medium={4}>
						<FormSelectTag
							label={translate("measureLibrary.selectTag")}
							name={joinNameList(name, "aggregateScorecardTag")}
							required
						/>
					</Column>
				)}
			</Row>
			<Row>
				<Column medium={4}>
					<SelectShadowObjectType
						label={
							<>
								{measureId && !showAggregateMeasureCount && (
									<Badge style={{ marginRight: `${theme.smallSpace}` }} color="default">
										0
									</Badge>
								)}
								{showAggregateMeasureCount && (
									<Badge
										style={{ marginRight: `${theme.smallSpace}` }}
										color={count?.measureCount !== 0 ? "primary" : "danger"}
									>
										{count?.measureCount}
									</Badge>
								)}
								{translate("edit.series.aggregateElement")}
							</>
						}
						placeholder={translate("edit.series.selectAggregateElement") + "..."}
						name={joinNameList(name, "aggregateObject")}
					/>
				</Column>
				<Column medium={4}>
					{aggregateObject && (
						<SelectAggregateObjectType
							label={translate(`edit.select${capitalize(aggregateObject)}s`)}
							placeholder={translate(`edit.select${capitalize(aggregateObject)}s`) + "..."}
							name={joinNameList(name, "aggregateMeasureType")}
							defaultFirstOptionFlag
						/>
					)}
				</Column>
				<Column medium={4}>
					{namedObjectFlag && (
						<FormInput
							label={translate("edit.measureName", { measure: aggregateObject })}
							name={joinNameList(name, "aggregateMeasureName")}
							required
						/>
					)}
					{aggregateMeasureType === 7 && (
						<FormSelectTag
							label={translate("measureLibrary.selectTag")}
							name={joinNameList(name, "aggregateMeasureTag")}
							required
						/>
					)}
				</Column>
			</Row>
			<Row>
				<Column medium={4}>
					<SelectAggregateSeriesType
						label={
							<>
								{measureId && !showAggregateSeriesCount && (
									<Badge style={{ marginRight: `${theme.smallSpace}` }} color="default">
										0
									</Badge>
								)}
								{showAggregateSeriesCount && (
									<Badge
										style={{ marginRight: `${theme.smallSpace}` }}
										color={count?.measureSeriesCount !== 0 ? "primary" : "danger"}
									>
										{count?.measureSeriesCount}
									</Badge>
								)}
								{translate("edit.seriesMatchType")}
							</>
						}
						name={joinNameList(name, "aggregateSeriesType")}
						defaultValue={0}
					/>
				</Column>
				<Column medium={4}>
					{aggregateSeriesType === 2 ? (
						<FormSelectTag
							label={translate("measureLibrary.selectTags")}
							name={joinNameList(name, "aggregateSeriesTag")}
							required
						/>
					) : (
						<FormInput label={translate("edit.seriesName")} name={joinNameList(name, "aggregateName")} required />
					)}
				</Column>
			</Row>
			{(aggregateName || aggregateSeriesTag) && (
				<>
					<SelectAggregateType
						placeholder={translate("edit.series.selectAggregateType") + "..."}
						label={translate("edit.aggregateType")}
						name={joinNameList(name, "aggregateType")}
						defaultValue="SUM"
						required
					/>
					<RadioBoolean
						name={joinNameList(name, "aggregateNulls")}
						defaultValue={true}
						trueText={translate("edit.ignoreBlank")}
						falseText={translate("edit.convertBlank")}
						boldFlag
					/>
				</>
			)}
		</FormGroup>
	);
};

InputAggregate.propTypes = propTypes;

export default InputAggregate;
