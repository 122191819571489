import Button from "../../../Button/Button";
import Modal from "../../../Modal/Modal";
import ModalBody from "../../../Modal/Modal.Body";
import ModalFooter from "../../../Modal/Modal.Footer";
import ModalHeader from "../../../Modal/Modal.Header";
import ModalTitle from "../../../Modal/Modal.Title";
import { useCallback } from "react";
import { useFormContext } from "../../../Form/Form";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func.isRequired,
	customFieldFlag: PropTypes.bool,
	modalVisible: PropTypes.bool,
	name: PropTypes.string,
};

let ImageClearConfirmModal = ({ close, customFieldFlag, modalVisible, name }) => {
	let translate = useTranslate();
	let { clearFormValue, setFormValue } = useFormContext();

	let clearImage = useCallback(() => {
		if (customFieldFlag) {
			setFormValue(name, (customFieldData) => ({ ...customFieldData, dirty: true, displayValue: "", value: "" }));
		} else {
			clearFormValue(name);
		}
		clearFormValue("_localImage");
		close();
	}, [clearFormValue, close, customFieldFlag, name, setFormValue]);

	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<ModalTitle>{translate("components.clearImage")}</ModalTitle>
			</ModalHeader>
			<ModalBody>{translate("components.clearImageConfirm")}</ModalBody>
			<ModalFooter>
				<Button onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button onClick={clearImage} color="primary">
					{translate("global.clear")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

ImageClearConfirmModal.propTypes = propTypes;

export default ImageClearConfirmModal;
