import FormSelect from "@clearpoint/old-theme/Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import { useEvaluationEnabledLookup } from "@clearpoint/hooks";


let ElementTypeSelect = () => {
	let translate = useTranslate();
	let evaluationEnabledLookup = useEvaluationEnabledLookup();
	let options = useMemo(() => {
		let evaluationEnabledElementTypeList = ["measure", "initiative", "milestone", "actionItem", "risk"].filter(
			(x) => evaluationEnabledLookup[x]
		);

		return evaluationEnabledElementTypeList.map((x) => ({ label: translate(x), value: x }));
	}, [evaluationEnabledLookup, translate]);

	return <FormSelect label={translate("global.element")} name="chart.object" options={options} />;
};

export default ElementTypeSelect;
