let generateReducer = (initialState) => (oldState, updates) => {
	let functionUpdates = {};
	for (let key in updates) {
		if (!initialState.hasOwnProperty(key)) {
			throw new Error("Invalid key in state: " + key);
		}
		if (typeof updates[key] === "function") {
			let updateFunctionOutput = updates[key](oldState[key]);

			// avoid unintentionally rendering functional components
			if (updateFunctionOutput?.$$typeof === Symbol.for("react.element")) {
				functionUpdates[key] = updates[key];
			} else {
				functionUpdates[key] = updateFunctionOutput;
			}
		}
	}
	return { ...oldState, ...updates, ...functionUpdates };
};
export default generateReducer;
