import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormSelectAvailablePeriod from "@clearpoint/old-theme/Form/Select/Select.AvailablePeriod";
import FormSelectDateRange from "@clearpoint/old-theme/Form/Select/Select.DateRange";
import FormSelectReportingFrequency from "@clearpoint/old-theme/Form/Select/Select.ReportingFrequency";
import Help from "@clearpoint/old-theme/Help";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";

let ChartPeriodsTab = () => {
	let translate = useTranslate();
	let { periodDisplay, periodGroupId } = useFormValue();
	let { setFormValue } = useFormContext();
	let specificPeriodFlag = periodGroupId === 0;

	let onReportingFrequencyChange = useCallback(
		(selectOption) => {
			if (!selectOption) {
				return;
			}
			let { value } = selectOption;
			if (value !== -1) {
				setFormValue("hiddenPeriods", []);
			}
			if (value === 0) {
				setFormValue("periodDisplay", "all");
				setFormValue("showFuture", true);
				setFormValue("showGridHidden", true);
			}
		},
		[setFormValue]
	);

	return (
		<>
			<Help section="sect19" feature="chart-periods" />
			<FormSelectDateRange label={translate("manage.chart.chartDateRange")} name="periodDisplay" />
			{["last", "next", "plusminus"].includes(periodDisplay) && (
				<FormInput label={translate("manage.chart.nPeriods")} name="periodCount" type="number" />
			)}
			{!specificPeriodFlag && <FormCheck name="showFuture">{translate("manage.chart.showFuturePeriods")}</FormCheck>}
			<FormSelectReportingFrequency
				onChange={onReportingFrequencyChange}
				label={translate("manage.chart.reportingFrequency")}
				name="periodGroupId"
			/>
			{specificPeriodFlag ? (
				<FormSelectAvailablePeriod multipleFlag label={translate("chart.chartPeriods")} name="_displayPeriods" />
			) : (
				<FormCheck name="showGridHidden">{translate("manage.chart.includeGridHidden")}</FormCheck>
			)}
		</>
	);
};

export default ChartPeriodsTab;
