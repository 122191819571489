import PropTypes from "prop-types";

import Column from "@clearpoint/old-theme/Column";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import Row from "@clearpoint/old-theme/Row";

import { useTranslate } from "@clearpoint/translate";

let propTypes = {
	keyResultNameInForm: PropTypes.string.isRequired,
};

let StartAndTargetValue = ({ keyResultNameInForm }) => {
	let translate = useTranslate();

	return (
		<Row>
			<Column tiny={6}>
				<FormInput
					label={translate("goals.keyResults.start")}
					name={`${keyResultNameInForm}.startValue`}
					type="number"
					required
					min={0}
					step="any"
				/>
			</Column>
			<Column tiny={6}>
				<FormInput
					label={translate("goals.keyResults.target")}
					name={`${keyResultNameInForm}.targetValue`}
					type="number"
					required
					min={0}
					step="any"
				/>
			</Column>
		</Row>
	);
};

StartAndTargetValue.propTypes = propTypes;
export default StartAndTargetValue;
