import Select from "../../../Select/Select";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	insertText: PropTypes.func,
};

let SelectFunction = ({ insertText }) => {
	let translate = useTranslate();
	let functionOptions = useMemo(
		() => [
			{
				label: translate("edit.calculationFunctions.isNull"),
				value: "ISNULL(x, 0)",
			},
			{
				label: translate("edit.calculationFunctions.coalesce"),
				value: "COALESCE(x, y, 0)",
			},
			{
				label: translate("edit.calculationFunctions.abs"),
				value: "ABS()",
			},
			{
				label: translate("edit.calculationFunctions.caseWhen"),
				value: "CASE WHEN",
			},
			{
				label: translate("edit.calculationFunctions.else"),
				value: "ELSE",
			},
			{
				label: translate("edit.calculationFunctions.end"),
				value: "END",
			},
		],
		[translate]
	);
	return (
		<Select
			placeholder={translate("edit.calculationFunctions.function")}
			options={functionOptions}
			onChange={(x) => insertText(x.value)}
			inlineFlag
			value={null}
		/>
	);
};

SelectFunction.propTypes = propTypes;

export default SelectFunction;
