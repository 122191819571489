import Badge from "@clearpoint/old-theme/Badge/Badge";
import EditButton from "@clearpoint/old-theme/Button/Button.Edit";
import EditPodModal from "./EditPodModal";
import Hide from "@clearpoint/old-theme/Hide";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import PlaceChartModal from "../PlaceChartModal";
import PodListItem from "../PodListItem";
import { useCallback } from "react";
import { useDragAndDropContext } from "../DragAndDropWrapper/DragAndDropWrapper";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { usePodListContext } from "../PodListProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { createUniqueIdFromPod, emptyObject } from "@clearpoint/utils";

let badgePropTypes = {
	text: PropTypes.string,
	visible: PropTypes.bool,
};

let HiddenPodBadge = ({ text, visible }) => (
	<Hide visible={visible}>
		<Badge color="warning">
			<Icon name="hiddenPod" transform="up-1" />
			{" " + text}
		</Badge>
	</Hide>
);

HiddenPodBadge.propTypes = badgePropTypes;

let propTypes = {
	areaIndex: PropTypes.number,
	attributes: PropTypes.object,
	containerId: PropTypes.string,
	listeners: PropTypes.shape({
		onMouseDown: PropTypes.func,
		onTouchStart: PropTypes.func,
	}),
	pod: PropTypes.shape({
		fieldId: PropTypes.string,
		hideOnline: PropTypes.bool,
		hidePrint: PropTypes.bool,
		hidePublish: PropTypes.bool,
		title: PropTypes.string,
	}),
	podIndex: PropTypes.number,
	setActivatorNodeRef: PropTypes.func,
};

let LayoutPodListItem = ({ areaIndex, attributes, containerId, listeners, pod, podIndex, setActivatorNodeRef }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { data: menuPanelPodList, setData: setMenuPanelPodList } = useFilter();
	let { podListAll } = usePodListContext();

	let {
		chart,
		fieldId = "",
		hideOnline: hideOnlineFlag,
		hidePrint: hidePrintFlag,
		hidePublish: hidePublishFlag,
	} = pod || emptyObject;

	let getPlacedChartPodFlag = useCallback((fieldId) => fieldId === "kpi", []);
	let placedChartPodFlag = getPlacedChartPodFlag(fieldId);

	let { setDragAndDropLookup } = useDragAndDropContext();

	let removePod = useCallback(() => {
		setDragAndDropLookup((dragAndDropPodIdLookup) => ({
			...dragAndDropPodIdLookup,
			[containerId]: dragAndDropPodIdLookup[containerId].filter((x) => createUniqueIdFromPod(pod) !== x),
		}));
		setFormValue(`layout[${areaIndex}].pods`, (podList) => {
			if (placedChartPodFlag) {
				return podList.filter((x) => !getPlacedChartPodFlag(x.fieldId) || !isEqual(x.chart, chart));
			}
			return podList.filter((x) => x.fieldId !== fieldId);
		});
		let correspondingPodInPodList = podListAll.find((x) => x.fieldId === fieldId);
		if (correspondingPodInPodList) {
			setMenuPanelPodList([{ ...correspondingPodInPodList, visible: true }, ...menuPanelPodList]);
		}
	}, [
		setDragAndDropLookup,
		setFormValue,
		areaIndex,
		podListAll,
		containerId,
		pod,
		placedChartPodFlag,
		getPlacedChartPodFlag,
		chart,
		fieldId,
		setMenuPanelPodList,
		menuPanelPodList,
	]);

	return (
		<PodListItem
			draggableFlag
			attributes={attributes}
			listeners={listeners}
			setActivatorNodeRef={setActivatorNodeRef}
			backgroundColor="#fafafb"
			pod={pod}
			right={
				<>
					<HiddenPodBadge text={translate("layout.online")} visible={hideOnlineFlag} /> {/* see top of file */}
					<HiddenPodBadge text={translate("layout.printed")} visible={hidePrintFlag} />
					<HiddenPodBadge text={translate("layout.published")} visible={hidePublishFlag} />
					<IconButton data-testid="removePodButton" onClick={removePod} name="remove" iconTransform="" />
					{placedChartPodFlag && (
						<ModalWindowOnClick chart={chart} areaIndex={areaIndex} ModalWindow={PlaceChartModal}>
							<IconButton data-testid="duplicateChartButton" name="copy" />
						</ModalWindowOnClick>
					)}
					<EditButton
						data-testid="edit-pod-button"
						ModalWindow={EditPodModal}
						name={`layout[${areaIndex}].pods[${podIndex}]`}
					/>
				</>
			}
		/>
	);
};

LayoutPodListItem.propTypes = propTypes;

export default LayoutPodListItem;
