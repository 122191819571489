let setSupervisorId = async (supervisorId, owner, dependencies) => {
	let { set, toast, translate } = dependencies;
	if (supervisorId) {
		let originalSupervisorId = owner?.supervisorId;
		if (!originalSupervisorId) {
			await set({ object: "user", objectId: owner.userId, data: { supervisorId } });
			toast.success(translate("manage.users.profileUpdated"));
		}
	}
};

export default setSupervisorId;
