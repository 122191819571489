import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import { renderToString } from "react-dom/server";

let useHtml = (html) => {
	let translate = useTranslate();
	let { rootPath } = useOldSession().session;
	let pageNumber = useMemo(
		() =>
			renderToString(
				<>
					<span className="current-page-number" /> {translate("global.of")} <span className="total-page-number" />
				</>
			),
		[translate]
	);
	if (!html) return null;
	html = html ? html.replace(/\n/g, "") : "";
	html = html.replace(/src="\/?documents/g, 'src="' + rootPath + "/documents");
	html = html.replace(/<span id='pdfkit_page_current'>/, pageNumber);
	return html.match(/<body.*?getPdfInfo\(\)'>(.*?)<\/body>/)?.[1] || "";
};

export default useHtml;
