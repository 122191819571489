import { useCallback } from "react";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import Block from "../../../../Block";
import Column from "../../../../Column";
import FilterInput from "../../../../Filter/Filter.Input";
import FormCheck from "../../../../Form/Check/Form.Check";
import FormGroup from "../../../../Form/Form.Group";
import Row from "../../../../Row";
import { theme } from "../../../../Theme";
import SelectFilterField from "./Select.FilterField";

let propTypes = {
	currentObjectFlag: PropTypes.bool,
	elementFieldList: PropTypes.array,
	linkFieldList: PropTypes.array,
	object: PropTypes.string,
	objectFieldList: PropTypes.array,
	seriesFieldList: PropTypes.array,
	seriesFlag: PropTypes.bool,
};
let ElementTabContent = ({
	currentObjectFlag,
	elementFieldList,
	linkFieldList,
	object,
	objectFieldList,
	seriesFieldList,
	seriesFlag,
}) => {
	let translate = useTranslate();

	let customFilter = useCallback(
		(element, value) => {
			return [(element.name, translate(element.name, { objectType: object }))].some((x) =>
				x.toLowerCase().includes(value.toLowerCase())
			);
		},
		[object, translate]
	);

	return (
		<Block data-testid={`${object}-tab`}>
			<FilterInput
				name="name"
				filterBy="name"
				filter={customFilter}
				placeholder={translate("global.filterFields") + "..."}
				right={<SelectFilterField borderRadius="0 4px 4px 0" fieldList={objectFieldList} object={object} />}
			/>
			<FormGroup title={translate(object)} paddingBottom={theme.tinySpace}>
				<Row>
					{elementFieldList.map((x) => (
						<Column key={x.index} medium={6}>
							<FormCheck
								data-testid={`${translate(x.name)}-form-checkbox`}
								name={`_fieldList[${x.index}].selected`}
								marginBottom={theme.smallSpace}
							>
								{translate(x.name)}
							</FormCheck>
						</Column>
					))}
				</Row>
			</FormGroup>
			{seriesFlag && (
				<FormGroup title={`${translate(object)} ${translate("global.series")}`} paddingBottom={theme.tinySpace}>
					<Row>
						{seriesFieldList.map((x) => (
							<Column key={x.index} medium={6}>
								<FormCheck
									data-testid={`${translate(x.name)}-form-checkbox`}
									name={`_fieldList[${x.index}].selected`}
									marginBottom={theme.smallSpace}
								>
									{translate(x.name)}
								</FormCheck>
							</Column>
						))}
					</Row>
				</FormGroup>
			)}
			{currentObjectFlag && (
				<FormGroup title={translate("layout.fieldType.link")} paddingBottom={theme.tinySpace}>
					<Row>
						{linkFieldList.map((x) => (
							<Column key={x.index} medium={6}>
								<FormCheck
									data-testid={`${translate(x.name)}-form-checkbox`}
									name={`_fieldList[${x?.index}].selected`}
									marginBottom={theme.smallSpace}
								>
									{translate(x.name)}
								</FormCheck>
							</Column>
						))}
					</Row>
				</FormGroup>
			)}
		</Block>
	);
};
ElementTabContent.propTypes = propTypes;

export default ElementTabContent;
