import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let SelectFieldByObjectId = ({ object, objectId, ...props }) => {
	let { get } = useOldQueryStore();
	let fieldList = objectId && get({ object: "field", queryString: `object=${object}&objectId=${objectId}` });
	let translate = useTranslate();

	let options = useMemo(() => {
		if (fieldList === undefined) return;
		if (!fieldList) return [];
		return fieldList.map((x) => ({ value: x.id, label: translate(x.name), group: translate(x.type) }));
	}, [fieldList, translate]);

	return (
		<FormSelect
			placeholder={translate("layout.selectField") + "..."}
			label={translate("layout.field")}
			options={options}
			{...props}
		/>
	);
};
export default SelectFieldByObjectId;
