import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayElapsedDays = ({ name }) => {
	let translate = useTranslate();
	let elapsedDays = useFormValue(name);
	return <>{elapsedDays === "N/A" ? translate("global.notAvailable") : elapsedDays}</>;
};

FormFieldDisplayElapsedDays.propTypes = propTypes;
export default FormFieldDisplayElapsedDays;
