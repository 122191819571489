import Select from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import useStringToArrayFormValue from "../../Form/hooks/useStringToArrayFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
	picklist: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
let Multipick = ({ picklist, name, ...props }) => {
	let arrayName = useStringToArrayFormValue(name);
	picklist = useMemo(() => (typeof picklist === "string" ? picklist.split(",") : picklist), [picklist]);
	let options = useMemo(
		() =>
			picklist?.map((x) => ({
				label: x,
				value: x.trim?.() || x,
			})) || [],
		[picklist]
	);
	return <Select data-testid="multipick" multipleFlag name={arrayName} options={options} {...props} />;
};
Multipick.propTypes = propTypes;
export default Multipick;
