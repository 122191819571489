import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { notDefinedStatusIcon } from "@clearpoint/utils";

import StyleWrapper from "./StyleWrapper";
import Text from "./Text/Text";
import { theme } from "./Theme";


let imagePropTypes = {
	className: PropTypes.string,
	fixedWidthFlag: PropTypes.bool,
	marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginTop: PropTypes.string,
	size: PropTypes.oneOf(["small", "normal", "medium", "large"]),
	status: PropTypes.shape({
		image: PropTypes.string,
		name: PropTypes.string,
	}),
	title: PropTypes.string,
	translate: PropTypes.func.isRequired,
};

let sizeLookup = {
	large: "32px",
	medium: "24px",
	normal: "24px",
	small: "16px",
};

let StatusImage = ({
	className,
	fixedWidthFlag,
	marginLeft,
	marginRight,
	marginTop,
	size,
	status,
	title,
	translate,
}) => (
	<StyleWrapper
		className={className}
		flexShrink="0"
		fontSize="1.25em !important"
		height={sizeLookup[size]}
		marginLeft={marginLeft}
		marginRight={marginRight}
		marginTop={marginTop}
		objectFit="cover"
		width={fixedWidthFlag ? "1.28em !important" : sizeLookup[size]}
	>
		<img
			data-testid={`status-icon-${status?.name}`}
			alt={status?.name || translate("global.notDefined")}
			style={{ height: sizeLookup[size], width: fixedWidthFlag ? "1.28em !important" : sizeLookup[size] }}
			src={status?.image || notDefinedStatusIcon}
			title={title || status?.name || translate("global.notDefined")}
		/>
	</StyleWrapper>
);

let propTypes = {
	className: PropTypes.string,
	fixedWidthFlag: PropTypes.bool,
	get: PropTypes.func,
	marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	marginTop: PropTypes.string,
	showNameFlag: PropTypes.bool,
	size: PropTypes.oneOf(["small", "normal", "medium", "large"]),
	statusId: PropTypes.number,
	title: PropTypes.string,
	translate: PropTypes.func,
};

let StatusIcon = ({
	className,
	fixedWidthFlag,
	get,
	marginLeft,
	marginRight,
	marginTop,
	showNameFlag,
	size,
	statusId,
	title,
	translate,
}) => {
	let translateHook = useTranslate();
	let storeHook = useOldQueryStore();
	if (!translate) translate = translateHook;
	if (!get) ({ get } = storeHook);
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let status = statusList?.find((x) => x.statusId === +statusId);

	return showNameFlag ? (
		<StyleWrapper marginBottom="0px">
			<BootstrapForm.Label>
				<StatusImage
					className={className}
					fixedWidthFlag={fixedWidthFlag}
					marginLeft={marginLeft}
					marginRight={marginRight}
					marginTop={marginTop}
					size={size}
					status={status}
					title={title}
					translate={translate}
				/>
				<Text fontWeight={theme.bold}>{translate(status?.name || "global.notDefined")}</Text>
			</BootstrapForm.Label>
		</StyleWrapper>
	) : (
		<StatusImage
			className={className}
			fixedWidthFlag={fixedWidthFlag}
			marginLeft={marginLeft}
			marginRight={marginRight}
			marginTop={marginTop}
			size={size}
			status={status}
			title={title}
			translate={translate}
		/>
	);
};

StatusImage.propTypes = imagePropTypes;
StatusIcon.propTypes = propTypes;
StatusIcon.defaultProps = { size: "normal", marginRight: theme.smallSpace };
export default StatusIcon;
