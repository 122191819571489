import React, { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let PaginationComponent = ({ template }) => {
	let parts = template.split(/({PageNumber}|{PageNumberTotal}|{GeneratedDate})/);

	let partLookup = useMemo(
		() => ({
			"{GeneratedDate}": () => "{{GENERATION_DATE}}",
			"{PageNumber}": (index) => <span key={index} className="current-page-number" />,
			"{PageNumberTotal}": (index) => <span key={index} className="total-page-number" />,
		}),
		[]
	);

	return (
		<>
			{parts.map((part, index) => {
				if (partLookup[part]) {
					return partLookup[part](index);
				} else {
					return <React.Fragment key={index}>{part}</React.Fragment>;
				}
			})}
		</>
	);
};

let PageNumberFooter = ({ pageNumberLabel }) => {
	let translate = useTranslate();
	if (pageNumberLabel) return <PaginationComponent template={pageNumberLabel} />;

	return (
		<>
			{`{{GENERATION_DATE}} ${translate("global.page")} `}
			<span className="current-page-number" /> {translate("global.of")} <span className="total-page-number" />
		</>
	);
};

export default PageNumberFooter;
