import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import FormDragAndDropListItem from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Item";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import { theme } from "@clearpoint/old-theme/Theme";

import Buttons from "./PodLinkListItem.Buttons";
import Checkbox from "./PodLinkListItem.Checkbox";
import Date from "./PodLinkListItem.Date";
import Icons from "./PodLinkListItem.Icons";
import Name from "./PodLinkListItem.Name";
import PeriodBadge from "./PodListItem.PeriodBadge";
import { useOldSession } from "@clearpoint/old-session/index";
import GoalListItem from "@components/Elements/Goals/ManageGoals/GoalListItem/GoalListItem";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";

let propTypes = {
	clearLinkData: PropTypes.func,
	dragAndDropFlag: PropTypes.bool,
	linkedElementPodFlag: PropTypes.bool,
	link: PropTypes.object.isRequired,
	podFieldId: PropTypes.string.isRequired,
	unlink: PropTypes.func,
};

let PodLinkListItem = (props) => {
	let { clearLinkData, dragAndDropFlag, linkedElementPodFlag, link, podFieldId, unlink } = props;
	let { exportFlag } = useOldSession().session;
	let { podWidthDisplayClass } = usePodContext();
	let smallPodFlag =
		podWidthDisplayClass === "medium" || podWidthDisplayClass === "small" || podWidthDisplayClass === "tiny";
	if (podFieldId === "goals") {
		return <GoalListItem {...link} goalId={link.objectId} detailPageFlag readOnlyFlag />;
	}
	let ListItemComponent = dragAndDropFlag ? FormDragAndDropListItem : ListItem;

	return (
		<ListItemComponent
			className="avoid-page-break"
			data-testid={`${podFieldId}-pod-link-list-item`}
			size="small"
			paddingLeft={theme.tinySpace}
			height="44px"
			ellipsisFlag
			boxShadow="none"
			left={
				<Block ellipsisFlag display="flex" alignItems="center" lineHeight="100% !important">
					<Icons link={link} podFieldId={podFieldId} />
					<Checkbox link={link} podFieldId={podFieldId} ignoreOptionsFlag={linkedElementPodFlag} />
					<Name link={link} />
				</Block>
			}
			right={
				<>
					{exportFlag || smallPodFlag || dragAndDropFlag || <Date link={link} />}
					{exportFlag || smallPodFlag || dragAndDropFlag || <PeriodBadge link={link} />}
					<Buttons clearLinkData={clearLinkData} link={link} unlink={unlink} />
				</>
			}
			$style={`max-height: 44px;
					.list-item-left-child > *,
					.list-item-right-child {
						margin-bottom: 0 !important;
					}
					.list-item-right-child > * {
						margin-bottom: 0 !important;
					}`}
		/>
	);
};

PodLinkListItem.propTypes = propTypes;

export default PodLinkListItem;
