import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectBoolean = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: "true", label: translate("notifications.compare.true") },
			{ value: "false", label: translate("notifications.compare.false") },
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};
export default SelectBoolean;
