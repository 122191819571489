import AddButton from "@clearpoint/old-theme/Button/Button.Add";
import Block from "@clearpoint/old-theme/Block";
import CalculatedPercentComplete from "./CalculatedPercentComplete";
import Column from "@clearpoint/old-theme/Column";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterProvider from "@clearpoint/providers/FilterProvider";
import FilterShowDeleted from "@clearpoint/old-theme/Filter/Filter.ShowDeleted";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormDragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import GoalStatusSelect from "./GoalStatusSelect";
import KeyResultListItem from "./ListItem.KeyResult";
import Loading from "@clearpoint/old-theme/Loading/index";
import NoResults from "@clearpoint/old-theme/NoResults";
import Row from "@clearpoint/old-theme/Row";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import addNewKeyResultFunction from "./KeyResultsTab.addNewKeyResultFunction";
import { theme } from "@clearpoint/old-theme/Theme";
import { v1 as uuid } from "uuid";
import { emptyArray } from "@clearpoint/utils";


let KeyResultsTabContent = () => {
	let translate = useTranslate();
	let { data: filteredKeyResultList } = useFilter();
	let keyResultList = useFormValue("keyResults") || emptyArray;
	let { setFormValue } = useFormContext();

	let addNewKeyResult = useCallback(() => addNewKeyResultFunction({ setFormValue, uuid }), [setFormValue]);

	return (
		<>
			<Row>
				<Column medium={6}>
					<GoalStatusSelect name="statusId" defaultFirstOptionFlag />
				</Column>
				<Column medium={6}>
					<StyleWrapper height="35px" marginTop={theme.tinySpace}>
						<FormCheck name="archived">{translate("goals.archived")}</FormCheck>
					</StyleWrapper>
				</Column>
			</Row>
			<CalculatedPercentComplete />
			<FilterInput
				name="keyResultName"
				placeholder={
					translate("manage.filterElements", {
						elements: "keyResults",
					}) + "..."
				}
				filterBy="name"
				right={
					<>
						<FilterShowDeleted />
						<AddButton color="primary" onClick={addNewKeyResult} />
					</>
				}
			/>
			<Block display="flex" flexDirection="column">
				<FormDragAndDropList name="keyResults" list={keyResultList}>
					{filteredKeyResultList?.map((x, i) => (
						<KeyResultListItem key={x.keyResultId || x._id} index={i} {...x} />
					))}
				</FormDragAndDropList>
				<NoResults feature="keyResults" addFunction={addNewKeyResult} />
			</Block>
		</>
	);
};

let KeyResultsTab = () => {
	let { keyResults: keyResultList } = useFormValue();

	return keyResultList === undefined ? (
		<Loading />
	) : (
		<FilterProvider data={keyResultList}>
			<KeyResultsTabContent />
		</FilterProvider>
	);
};

export default KeyResultsTab;
