import CellWrapper from "../CellWrapper";
import FormFieldDisplayStatusId from "@clearpoint/old-theme/Form/Field/Display/StatusId";
import FormFieldStatusId from "@clearpoint/old-theme/Form/Field/StatusId";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let StatusIconCell = (props) => {
	let { cell, editCellFlag, objectData, parentName } = props;
	let { periodId } = cell;
	let status = objectData?.status?.[periodId];
	let object = objectData?.object;
	let automaticEvaluationFlag =
		status && ["evaluated", "automaticEvaluation", "autoEvaluation"].some((x) => status[x] || objectData[x]);
	let editStatusFlag = editCellFlag && !automaticEvaluationFlag;
	parentName = `${parentName}.status.${periodId}`;
	let name = `${parentName}.statusId`;
	return (
		<CellWrapper {...props}>
			{editStatusFlag ? (
				<FormFieldStatusId name={name} parentName={parentName} object={object} iconOnlyFlag />
			) : (
				<FormFieldDisplayStatusId name={name} parentName={parentName} hidePageTitleFlag />
			)}
		</CellWrapper>
	);
};
StatusIconCell.propTypes = propTypes;

export default StatusIconCell;
