import UserImageAndName from "../../../Form/shared/UserImageAndName";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let User = ({ name }) => {
	let { get } = useOldQueryStore();
	let userId = useFormValue(name);
	let userList = get({ object: "user" });
	let user = userList?.find((x) => x.userId === +userId);
	return <UserImageAndName user={user} />;
};

User.propTypes = propTypes;
export default User;
