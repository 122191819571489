import isEqual from "lodash/isEqual";

let updateDataFromCache = ({
	count,
	filterQuery,
	itemsPerPage,
	previousFilterQuery,
	setFilterCache,
	startIndex,
	storedData,
}) => {
	if (isEqual(filterQuery, previousFilterQuery)) {
		let data = [];
		if (storedData.length > 0) {
			for (let i = 0; i < itemsPerPage; i++) {
				let item = storedData[startIndex + i - 1];
				if (item) {
					data.push(item);
				} else {
					break;
				}
			}
		}
		data.total = count;

		let allDataStoredFlag = data.length >= itemsPerPage;
		let lastPageStoredFlag = startIndex - 1 + data.length === count;
		let invalidStartIndexFlag = startIndex > count;
		if (allDataStoredFlag || lastPageStoredFlag || invalidStartIndexFlag) {
			return data;
		}
	} else {
		setFilterCache({
			previousFilterQuery: filterQuery,
			storedData: [],
		});
	}
};
export default updateDataFromCache;
