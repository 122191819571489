import React, { memo } from "react";
import { StyleWrapper } from "./StyleWrapper";

// NOTE: changes here require updating the mock
let NoProps = React.forwardRef(
	(
		{
			children,
			className,
			"data-testid": dataTestId,
			id,
			onClick,
			onDoubleClick,
			onKeyDown,
			onMouseDown,
			onMouseLeave,
			onMouseOver,
			onMouseOut,
			onMouseUp,
			onPointerDown,
			onTouchEnd,
			onTouchStart,
			style,
			title,
		},
		ref
	) => (
		<div
			className={className}
			data-testid={dataTestId}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
			onMouseLeave={onMouseLeave}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			onMouseUp={onMouseUp}
			onPointerDown={onPointerDown}
			onTouchEnd={onTouchEnd}
			onTouchStart={onTouchStart}
			ref={ref}
			id={id}
			style={style}
			title={title}
		>
			{children}
		</div>
	)
);

export default memo(StyleWrapper(NoProps));
