import { useCallback, useRef, useState } from "react";
import getDeepValue from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import setDeepValue from "lodash/set";
import { useDeferredValue } from "react";
import { deepFreeze, emptyObject, removeEmpty, updateDeepValue } from "@clearpoint/utils";

// form state:
// set field: ({name, value})
// set whole form: ({name: true, value})
// enable logging: logFlag
// callbacks run on each change: ({name, value, callbackList})

let setObjectValue = ({ callback, keepArrayFlag, name, object, value }) => {
	object = updateDeepValue(object, name, value, callback);
	let emptyFlag = value === undefined || isEmpty(value) || (isArray(value) && value.every((x) => x === undefined));
	if (emptyFlag) object = removeEmpty(object, keepArrayFlag);
	return object;
};

let reducer = ({ keepArrayFlag, oldState, stateName, updates }) => {
	let { callback, logFlag, name, value } = updates;
	if (name === "HARD_RESET") return {};
	if (typeof value === "function") {
		if (name === true) {
			value = value(oldState);
		} else {
			value = value(getDeepValue(oldState, name));
		}
	}
	if (name === true && isEqual(oldState, value)) return oldState;
	if (name !== true && getDeepValue(oldState, name) === value) return oldState;
	let newState = { ...oldState };
	if (value instanceof File) {
		newState = setDeepValue(newState, name, value);
		callback({ name, stateName, value });
	} else {
		newState = setObjectValue({ callback, keepArrayFlag, name, object: newState, value });
	}
	if (logFlag) {
		console.log({
			stateName,
			name,
			value,
			oldState: name === true ? oldState : getDeepValue(oldState, name),
			newState: name === true ? newState : getDeepValue(newState, name),
		});
		console.log(Error().stack);
	}
	return isEqual(newState, oldState) ? oldState : { ...newState };
};

let useFormState = (defaultValue, stateName, callbackList, keepArrayFlag) => {
	let initialState = defaultValue || emptyObject;
	deepFreeze(initialState);
	let ref = useRef(initialState);
	let [formState, setFormState] = useState(initialState);
	let setState = useCallback(
		(name, value, logFlag, skipFirstCallbackFlag) => {
			let callback = ({ name, value }) => {
				if (callbackList) {
					for (let [index, callback] of callbackList.entries()) {
						let skipFlag = index === 0 && skipFirstCallbackFlag;
						if (!skipFlag) callback({ name, stateName, value });
					}
				}
			};
			ref.current = reducer({
				keepArrayFlag,
				oldState: ref.current,
				stateName,
				updates: { callback, logFlag, name, value },
			});
			setFormState(ref.current);
		},
		[callbackList, keepArrayFlag, stateName]
	);
	let deferredFormState = useDeferredValue(formState);
	return [deferredFormState, setState, ref];
};

export default useFormState;
