import isEqual from "lodash/isEqual";

let getGridPodFieldIdList = ({ formValue, gridPodFieldIdListRef }) => {
	let newGridPodFieldIdList = Object.keys(formValue)
		.filter((key) => formValue[key]?.customFieldType === "grid")
		.map((gridFieldKey) => formValue[gridFieldKey].fieldId);

	return !isEqual(gridPodFieldIdListRef.current, newGridPodFieldIdList)
		? newGridPodFieldIdList
		: gridPodFieldIdListRef.current;
};

export default getGridPodFieldIdList;
