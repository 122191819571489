import Input from "../../Form/Input/Input";
import StyleWrapper from "../../StyleWrapper";
let AltText = (props) => {
	return (
		<StyleWrapper height="150px !important">
			<Input as="textarea" data-testid="altText" {...props} />
		</StyleWrapper>
	);
};
export default AltText;
