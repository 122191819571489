import Block from "@clearpoint/old-theme/Block";
import Image from "@clearpoint/old-theme/Image";
import Loading from "@clearpoint/old-theme/Loading/index";
import MapElement from "./MapElement";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldSession } from "@clearpoint/old-session/index";
import { theme } from "@clearpoint/old-theme/Theme";
import { useBuildImagePath, useFilterByMenu, useImageDimensions, useMeasure, useStateObject } from "@clearpoint/hooks";

let Map = () => {
	let { exportFlag, exportHeight } = useOldSession().session;
	let { map, skipImageLoadFlag } = useLocalState();
	let { background, backgroundColor, backgroundHeight, backgroundWidth, elements: elementList, name } = map || {};
	let buildImagePath = useBuildImagePath();
	if (buildImagePath && background) background = buildImagePath(background);
	let { height, width } = useImageDimensions(background);
	let [{ imageLoadedFlag }, setState] = useStateObject({
		imageLoadedFlag: skipImageLoadFlag || false,
	});
	let setImageLoadedFlagTrue = useCallback(() => setState({ imageLoadedFlag: true }), [setState]);
	let { height: containerHeight, width: containerWidth } = useMeasure({ query: ".map-background" }) || {};
	let { height: imageHeight, width: imageWidth } = useMeasure({ query: ".map-background-image" }) || {};
	containerHeight -= 20; // padding
	containerWidth -= 25;
	height = height || backgroundHeight;
	width = width || backgroundWidth;
	let imageRatio = height / width;
	let containerRatio = containerHeight / containerWidth;
	let displayHeight, displayWidth;
	if (height && width) {
		if (imageRatio > containerRatio) {
			displayHeight = containerHeight;
			displayWidth = Math.round(displayHeight / imageRatio);
		} else {
			displayWidth = containerWidth;
			displayHeight = Math.round(displayWidth * imageRatio);
		}
	} else {
		displayHeight = containerHeight;
		displayWidth = containerWidth;
	}
	if (exportFlag) {
		displayHeight = imageHeight;
		displayWidth = imageWidth;
	}
	let exportWidth = Math.round((exportHeight - 60) / imageRatio);
	let MapBackground = background ? Image : Block;
	imageLoadedFlag = background ? imageLoadedFlag : true;
	let loadingFlag = !map || !imageLoadedFlag;
	let activeElementList = useFilterByMenu(elementList);
	return (
		<>
			{loadingFlag && <Loading />}
			<Block
				opacity={imageLoadedFlag ? 1 : 0}
				flex="1"
				overflow="hidden"
				className="map-background"
				display={exportFlag ? undefined : "flex"}
				alignItems="flex-start"
				justifyContent="center"
				$style={`.smooth-dnd-container { margin-right: ${theme.space}; }`}
				paddingLeft={exportFlag ? undefined : "25px"}
				textAlign={exportFlag ? "center" : undefined}
			>
				<Block
					position="relative"
					padding={exportFlag ? undefined : "0px 25px 20px 0px"}
					overflow="visible"
					display={exportFlag ? "inline-block" : undefined}
				>
					<StyleWrapper
						backgroundColor={!background ? backgroundColor : theme.lightGray5}
						boxShadow={exportFlag ? undefined : `5px 5px 3px ${theme.deactivated}`}
						marginBottom={exportFlag ? undefined : theme.space}
					>
						<MapBackground
							className="map-background-image"
							alt={name}
							src={background}
							width={
								exportFlag && exportWidth ? `${exportWidth}px` : displayWidth ? `${displayWidth}px` : undefined
							}
							height={exportFlag || !displayHeight ? undefined : `${displayHeight}px`}
							maxHeight={exportFlag && exportHeight ? `${exportHeight}px` : "100%"}
							maxWidth="100%"
							onLoad={setImageLoadedFlagTrue}
						/>
					</StyleWrapper>
					{activeElementList?.map((element) => {
						let left = `${Math.round((element.xPos / 1024) * displayWidth)}px`;
						let top = `${Math.round((element.yPos / 768) * displayHeight)}px`;
						return (
							<MapElement
								key={element.object + element.objectId}
								name={element.name}
								object={element.object}
								objectId={element.objectId}
								left={left}
								top={top}
								displayHeight={displayHeight}
								displayWidth={displayWidth}
								scorecardId={element.scorecardId}
								statusId={element.statusId}
							/>
						);
					})}
				</Block>
			</Block>
		</>
	);
};

export default Map;
