let getSubmitUserMessage = async ({
	e,
	formValueRef,
	messageList,
	scrollToBottom,
	sendMessageToGPT,
	setAssistantState,
	setChatState,
	setFormValue,
}) => {
	e.preventDefault();
	let messageListLength = messageList?.length;
	let userChatMessage = formValueRef.current?.userChatMessage;
	if (!userChatMessage) return;

	setAssistantState({
		messageList: (x) => [...x, { role: "user", content: userChatMessage }],
		responseReceivedFlag: false,
	});
	setFormValue("userChatMessage", "");
	if (scrollToBottom) {
		setTimeout(scrollToBottom, 200);
	}
	await sendMessageToGPT(userChatMessage, messageListLength || 0);
	setChatState({
		awaitingUserResubmitFlag: false,
		awaitingNewAIResponseFlag: true,
	});
};

export default getSubmitUserMessage;
