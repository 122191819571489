import JSON5 from "json5";

let parseChartStyle = (chartData) => {
	let {
		chartFormat: { chart },
	} = chartData;

	if (typeof chart.style === "string") {
		try {
			return JSON5.parse(chart.style);
		} catch {
			return {};
		}
	}
	return chart.style;
};

export default parseChartStyle;
