import { theme } from "./Theme";

export const colorPalettes = {
	deactivated: {
		fontWeight: theme.bold,
		borderTop: {
			default: theme.deactivated,
			highlight: theme.deactivated,
		},
		backgroundColor: theme.lightGray5,
	},
	desaturatedNormal: {
		fontWeight: theme.boldish,
		borderTop: {
			default: theme.darkIceBlue,
			highlight: theme.orange2,
		},
		backgroundColor: theme.white,
	},
	desaturatedBold: {
		fontWeight: theme.bold,
		borderTop: {
			default: theme.darkIceBlue,
			highlight: theme.orange2,
		},
		backgroundColor: theme.iceBlue,
	},
	lazur: {
		fontWeight: theme.bold,
		borderTop: {
			default: theme.darkLazur,
			highlight: theme.orange2,
		},
		backgroundColor: theme.lazur,
	},
	normal: {
		fontWeight: theme.boldish,
		borderTop: {
			default: theme.brightBlue,
			highlight: theme.orange2,
		},
		backgroundColor: theme.white,
	},
};
