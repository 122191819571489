let getuseEffectAfterRender = ({ setLoaded, disabled, window }) => {
	if (!disabled && window) {
		let frame1, frame2;
		frame1 = window.requestAnimationFrame(() => {
			frame2 = window.requestAnimationFrame(() => {
				setLoaded(true);
			});
		});
		return () => {
			window.cancelAnimationFrame(frame2);
			window.cancelAnimationFrame(frame1);
		};
	} else {
		return () => {};
	}
};

export default getuseEffectAfterRender;
