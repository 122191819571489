import DragAndDropList from "../../DragAndDropList/DragAndDropList";
import DragAndDropTable from "../../DragAndDropTable/DragAndDropTable";
import { useCallback } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

let propTypes = {
	allowEmptyListFlag: PropTypes.bool,
	chartSeriesListFlag: PropTypes.bool,
	children: PropTypes.node,
	keyList: PropTypes.array,
	list: PropTypes.array,
	listKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string.isRequired,
	setList: PropTypes.func,
	styleProps: PropTypes.object,
	tableFlag: PropTypes.bool,
};

let FormDragAndDropList = ({
	allowEmptyListFlag,
	chartSeriesListFlag,
	children,
	keyList,
	list,
	listKey,
	name,
	setList,
	size,
	styleProps,
	tableFlag,
	...props
}) => {
	// providing a list key will set the form value to an array of values from that key on list
	let { setFormValue } = useFormContext();
	let value = useFormValue(name);
	let checkListLengthValid = useCallback((list) => list.length > 0 || allowEmptyListFlag, [allowEmptyListFlag]);
	let setFormList = useCallback(
		(list) => {
			let newValue = keyList
				? list?.map((x, i) => {
						let value = {};
						for (let key of keyList) {
							value[key] = key === "sortOrder" ? i : x[key];
						}
						return value;
				  })
				: listKey
				? list?.map((x) => x?.[listKey])
				: cloneDeep(list);
			if (setList) setList(list);
			if (list && checkListLengthValid(list) && !isEqual(newValue, value)) {
				setFormValue(name, newValue);
				if (name === "milestoneSortOrder") setFormValue("milestoneCustomSortEnabled", true);
				value = newValue;
			}
		},
		[checkListLengthValid, keyList, listKey, name, setFormValue, setList, value]
	);

	let Component = tableFlag ? DragAndDropTable : DragAndDropList;

	return (
		<Component name={name} list={list} setList={setFormList} size={size} styleProps={styleProps} {...props}>
			{children}
		</Component>
	);
};

FormDragAndDropList.propTypes = propTypes;

export default FormDragAndDropList;
