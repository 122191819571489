import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import { useCheckFeature } from "@clearpoint/hooks";


let SelectCalculationType = (props) => {
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let seriesTypeOptions = useMemo(() => {
		let options = [
			{
				label: translate("edit.defaultSeries.defaultNoCalc"),
				value: "default",
			},
			{
				label: translate("edit.calculatedSeries"),
				value: "calculated",
			},
		];

		if (checkFeature("aggregateSeries")) {
			options.push({
				label: translate("edit.aggregateSeries"),
				value: "aggregate",
			});
		}
		if (checkFeature("statusSeries")) {
			options.push({
				label: translate("edit.series.aggregateStatus"),
				value: "status",
			});
		}
		if (checkFeature("referenceSeries")) {
			options.push({
				label: translate("edit.series.referenceSeries"),
				value: "reference",
			});
		}
		if (checkFeature("measureLibrary")) {
			options.push({
				label: translate("edit.series.sharedSeries"),
				value: "peer",
			});
		}

		return options;
	}, [checkFeature, translate]);

	return (
		<FormSelect
			options={seriesTypeOptions}
			placeholder={translate("edit.defaultSeries.selectSeriesCalcType") + "..."}
			{...props}
		/>
	);
};

export default SelectCalculationType;
