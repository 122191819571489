import { useCallback } from "react";
import debounce from "lodash/debounce";
import { useConvertDateStringToDateObject } from "@clearpoint/hooks";


let useOnTaskDrag = ({ draggedFlagRef, ganttRef, scrollRef }) => {
	let convertDateStringToDateObject = useConvertDateStringToDateObject();
	let moveTask = useCallback(
		(gantt, mode, id, task) => {
			let updatedFlag = false;
			if (+task.start_date <= +gantt.date.add(gantt.getState().min_date, 1, gantt.config.duration_unit)) {
				gantt.config.start_date = gantt.date.add(task.start_date, -1, gantt.config.duration_unit);
				let { x, y } = gantt.getScrollState();
				scrollRef.current = { x, y };
				updatedFlag = true;
			} else if (+task.end_date >= +gantt.date.add(gantt.getState().max_date, -1, gantt.config.duration_unit)) {
				gantt.config.end_date = gantt.date.add(task.end_date, 1, gantt.config.duration_unit);
				let { x, y } = gantt.getScrollState();
				scrollRef.current = { x, y };
				updatedFlag = true;
			}
			if (updatedFlag) {
				gantt.render();
			}
			if (mode === "move") {
				let startDate = convertDateStringToDateObject(task.startDate);
				if (startDate < new Date(gantt.getState().min_date)) {
					gantt.config.start_date = gantt.date.add(startDate, -1, gantt.config.duration_unit);
					task.start_date = startDate;

					gantt.render();
				}
				let endDate = convertDateStringToDateObject(task.endDate);
				if (endDate > new Date(gantt.getState().max_date)) {
					gantt.config.end_date = gantt.date.add(endDate, 1, gantt.config.duration_unit);
					task.start_date = startDate;
					gantt.render();
				}
				task.end_date = gantt.calculateEndDate(task);
				gantt.updateTask(id);
			}
		},
		[convertDateStringToDateObject, scrollRef]
	);
	let onTaskDrag = useCallback(
		(id, mode, task) => {
			let gantt = ganttRef.current;
			let tasks = gantt.getSelectedTasks();
			draggedFlagRef.current = true;
			if (tasks.length <= 1) {
				moveTask(gantt, mode, id, task);
			} else {
				gantt.batchUpdate(() => {
					for (let taskId of tasks) {
						let retrievedTask = gantt.getTask(taskId);
						moveTask(gantt, mode, id, retrievedTask);
					}
				});
			}
		},
		[draggedFlagRef, ganttRef, moveTask]
	);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	onTaskDrag = useCallback(debounce(onTaskDrag, 20), [onTaskDrag]);
	return onTaskDrag;
};

export default useOnTaskDrag;
