import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import ModalBody from "../Modal/Modal.Body";
import ModalFooter from "../Modal/Modal.Footer";
import ModalHeader from "../Modal/Modal.Header";
import ModalTitle from "../Modal/Modal.Title";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectAllModal = ({ close, count, selectFlag, selectAll, selectFiltered, ...props }) => {
	let translate = useTranslate();
	let onSelectFiltered = useCallback(() => {
		selectFiltered();
		close();
	}, [close, selectFiltered]);
	let onSelectAll = useCallback(() => {
		selectAll();
		close();
	}, [close, selectAll]);
	return (
		<Modal close={close} {...props}>
			<ModalHeader>
				<ModalTitle>{translate(selectFlag ? "global.selectAll" : "global.unselectAll")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{translate(selectFlag ? "manage.elements.selectAll" : "manage.elements.unselectAll", { count })}
			</ModalBody>
			<ModalFooter>
				<Button color="default" onClick={onSelectFiltered}>
					{translate("manage.elements.onlyThisPage")}
				</Button>
				<Button color="info" onClick={onSelectAll}>
					{translate("manage.elements.allNElements", { count })}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default SelectAllModal;
