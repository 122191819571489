import ChartListItem from "./ChartListItem";
import PropTypes from "prop-types";


let chartsPropTypes = {
	chartList: PropTypes.array,
	measureGridList: PropTypes.array,
};

let ChartList = ({ chartList, measureGridList }) => (
	<>
		{!!chartList &&
			chartList.map((chart) => (
				<ChartListItem key={chart.chartId} chartData={chart} measureGridList={measureGridList} />
			))}
	</>
);

ChartList.propTypes = chartsPropTypes;

export default ChartList;
