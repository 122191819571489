import FormSelect from "../../Form/Select/FormSelect";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import styled from "styled-components";

let StyledSelect = styled(FormSelect)`
	width: ${(props) => props.$width || "100%"};
	margin-top: ${(props) => props.$marginTop || "0px"};
	margin-right: ${(props) => props.$marginRight || "0px"};
	margin-bottom: ${(props) => props.$marginBottom || "0px"};
	margin-left: ${(props) => props.$marginLeft || "0px"};
	div:first-of-type {
		width: 100%;
	}
`;

let propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string,
	width: PropTypes.string,
	marginTop: PropTypes.string,
	marginRight: PropTypes.string,
	marginBottom: PropTypes.string,
	marginLeft: PropTypes.string,
};

let FormSelectScorecardUserTypeId = ({ disabled, name, width, marginTop, marginRight, marginBottom, marginLeft }) => {
	let translate = useTranslate();
	let valueInForm = useFormValue(name);
	let { setFormValue } = useFormContext();

	let optionList = useMemo(
		() => [
			{ label: translate("userTypes.user4"), value: 4 }, // browser
			{ label: translate("userTypes.user3"), value: 3 }, // updater
			{ label: translate("userTypes.user2"), value: 2 }, // editor
			{ label: translate("userTypes.user9"), value: 9 }, // scorecard admin
		],
		[translate]
	);

	useEffect(() => {
		if (valueInForm === 7) {
			setFormValue(name, 4);
		}
	}, [name, setFormValue, valueInForm]);

	return (
		<StyledSelect
			disabled={disabled}
			defaultValue={4}
			$width={width}
			name={name}
			options={optionList}
			$marginTop={marginTop}
			$marginRight={marginRight}
			$marginBottom={marginBottom}
			$marginLeft={marginLeft}
		/>
	);
};
export default FormSelectScorecardUserTypeId;

FormSelectScorecardUserTypeId.propTypes = propTypes;
