import CellWrapper from "../CellWrapper";
import FormFieldDisplayEndDate from "@clearpoint/old-theme/Form/Field/Display/EndDate";
import FormFieldEndDate from "@clearpoint/old-theme/Form/Field/EndDate";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func,
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let EndDateCell = (props) => {
	let { close, editCellFlag, parentName, objectData } = props;
	let translate = useTranslate();
	let title = translate(objectData?.object ? `defaultFields.edit.${objectData.object}.endDate` : "edit.endDate");
	let name = `${parentName}.endDate`;
	let endDate = useFormValue(`${parentName}.endDate`);
	let startDate = useFormValue(`${parentName}.startDate`);
	let validDateFlag = Date.parse(endDate) - Date.parse(startDate) >= 0;

	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormModal title={title} name={name} modalVisible close={close} valid={validDateFlag}>
					<FormFieldEndDate name={name} object={objectData?.object} />
				</FormModal>
			) : (
				<FormFieldDisplayEndDate name={name} />
			)}
		</CellWrapper>
	);
};
EndDateCell.propTypes = propTypes;

export default EndDateCell;
