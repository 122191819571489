import { useCallback } from "react";

import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

let useToastApproval = () => {
	let translate = useTranslate();
	return useCallback(
		({ response }) => {
			let changeQueued = false;
			if (response?.edits) {
				for (const element of response.edits) {
					if (element.approvalId) {
						toast.warning(
							translate("approvals.editQueued", {
								object: element.object,
							})
						);
						changeQueued = true;						
						break;
					}
				}
			}
			if (response?.updates) {
				for (const element of response.updates) {
					if (element.approvalId) {
						toast.warning(
							translate("approvals.updateQueued", {
								object: element.object,
							})
						);
						changeQueued = true;						
						break;
					}
				}
			}
			if (response?.adds) {
				for (const element of response.updates) {
					if (element.approvalId) {
						toast.warning(
							translate("approvals.addQueued", {
								object: element.object,
							})
						);
						changeQueued = true;						
						break;
					}
				}
			}
			if (response?.action === "addQueued") {
				toast.warning(
					translate("approvals.addQueued", {
						object: response.object,
					})
				);
				changeQueued = true;						
			}
			if (response?.action === "deleteQueued") {
				toast.warning(
					translate("approvals.deleteQueued", {
						object: response.object,
					})
				);
				changeQueued = true;						
			}
			if (response?.action === "copyQueued") {
				toast.warning(
					translate("approvals.copyQueued", {
						object: response.object,
					})
				);
				changeQueued = true;						
			}
			return {changeQueued};
		},
		[translate]
	);
};

export default useToastApproval;
