import { useEffect } from "react";

let useOnHideOrShowBootstrapModal = ({ modalVisible }) => {
	useEffect(() => {
		if (modalVisible) {
			document.querySelector(".modal").style.display = "none";
			document.querySelector(".modal-backdrop").style.display = "none";
		} else {
			document.querySelector(".modal").style.display = "block";
			document.querySelector(".modal-backdrop").style.display = "block";
		}
	}, [modalVisible]);
};

export default useOnHideOrShowBootstrapModal;
