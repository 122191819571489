import List from "./List";
import { useDestroyObjectiveMutation, useObjectiveListQuery } from "@clearpoint/query";
import { useMainStore } from "@clearpoint/store";

let Destroy = () => {
	let periodId = useMainStore((state) => state.periodId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { data: objectiveList } = useObjectiveListQuery({ periodId, scorecardId });
	let inactiveObjectiveList = objectiveList.filter((x) => !x.active);
	let name = inactiveObjectiveList[0]?.name;
	let active = inactiveObjectiveList[0]?.active ? "true" : "false";
	let objectId = inactiveObjectiveList[0]?.objectId;
	let { mutate: destroyObjective } = useDestroyObjectiveMutation({ objectId });

	return (
		<div>
			<div>{name}</div>
			<div>active: {active}</div>
			<div>
				<button onClick={destroyObjective}>Destroy (Hard Delete) Objective</button>
			</div>
			<List />
		</div>
	);
};

export default Destroy;
