import { useEffect, useMemo } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import useFormValue from "../../Form/hooks/useFormValue";
import Select from "../../Form/Select/FormSelect";
import { useFormContext } from "../DefaultForm";

let propTypes = {
	name: PropTypes.string,
	picklist: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
let Picklist = ({ name, picklist, ...props }) => {
	let translate = useTranslate();
	let valueInForm = useFormValue(name);
	let { setFormValue } = useFormContext();

	let options = useMemo(() => {
		const list =
			picklist
				?.filter((x) => x.trim() ?? false)
				?.map((x) => ({
					label: x,
					value: x.trim?.() || x,
				})) || [];
		return [{ label: translate("global.notDefined"), value: undefined }, ...list];
	}, [translate, picklist]);

	let noOptionsFlag = !isArray(options) || options.length === 0;
	useEffect(() => {
		if (valueInForm !== undefined && !noOptionsFlag && options.every((x) => x.value !== valueInForm)) {
			let trimmedFormValue = valueInForm?.trim?.();
			if (trimmedFormValue !== undefined) {
				let equivalentTrimmedOption = options.find((x) => x.value === trimmedFormValue)?.value;
				if (equivalentTrimmedOption) {
					setFormValue(name, equivalentTrimmedOption);
				}
			}
		}
	}, [name, noOptionsFlag, options, setFormValue, valueInForm]);

	return (
		<Select
			data-testid="picklist"
			name={name}
			options={options}
			placeholder={translate("edit.selectItem")}
			picklistFlag
			{...props}
		/>
	);
};
Picklist.propTypes = propTypes;
export default Picklist;
