import DashboardListItem from "./DashboardListItem";
import Row from "@clearpoint/old-theme/Row";
import PropTypes from "prop-types";

let propTypes = {
	chartList: PropTypes.arrayOf(
		PropTypes.shape({
			chartId: PropTypes.number,
			measureId: PropTypes.number,
		})
	),
	className: PropTypes.string,
	measureStatusList: PropTypes.arrayOf(
		PropTypes.shape({
			objectId: PropTypes.number,
		})
	),
};
let DashboardListDisplay = ({ chartList, className, measureStatusList }) => {
	return chartList?.length > 0 ? (
		<Row className={className}>
			{chartList.map((x, i) => (
				<DashboardListItem
					chart={x}
					key={x.chartId || i}
					measureStatus={measureStatusList.find((y) => y.objectId === x.measureId)}
				/>
			))}
		</Row>
	) : null;
};
DashboardListDisplay.propTypes = propTypes;

export default DashboardListDisplay;
