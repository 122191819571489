
import isArray from "lodash/isArray";
import { emptyArray } from "@clearpoint/utils";

let getLinkedUpdatesList = ({ fieldKey, submitValue, contextValue }) => {
	let originalLinks = contextValue?.formDefaultValue?.links;
	let updatedLinks = submitValue?.links;

	if (!isArray(originalLinks) || !isArray(updatedLinks)) return emptyArray;

	let matchedUpdateList = updatedLinks
		.filter((link, index) => {
			let originalLink = originalLinks[index];
			if (!originalLink) return false;
			let { object, objectId } = link;
			if (object !== originalLink.object || objectId !== originalLink.objectId) return false;

			let updateField = link[fieldKey];
			return updateField !== originalLink[fieldKey];
		})
		.map((link) => {
			let { object, objectId, periodId } = link;
			let field = link[fieldKey];

			return { object, objectId, periodId, fields: { [fieldKey]: field } };
		});
	return matchedUpdateList;
};

export default getLinkedUpdatesList;
