import React from "react";

let setCredentialsLoader = ({ authHeader, authorizationStatus, children, session, setAuthorization, setState }) => {
	if (!authHeader && session.token) {
		setState({ authorizationStatus: "running" });
		setAuthorization(session);
	}
	if (authorizationStatus !== "complete") {
		setState({ authorizationStatus: "complete" });
	}
	return authorizationStatus === "complete" ? <>{children}</> : null;
};

export default setCredentialsLoader;
