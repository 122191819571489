import { useRef, useEffect } from "react";
let outsideRef = {};

export default function usePreviousDefined(value, name) {
	const ref = useRef();
	useEffect(() => {
		if (name && value !== undefined) outsideRef[name] = value;
		else if (value !== undefined) ref.current = value;
	});
	return name ? outsideRef[name] : ref.current;
}
