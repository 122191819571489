export const sizes = {
	tiny: "xs",
	small: "sm",
	medium: "md",
	large: "lg",
	huge: "xl",
};

export const iconSizes = {
	large: "lg",
	tiny: "xs",
	small: "sm",
	huge: "2x",
};

export const colors = {
	primary: "primary",
	secondary: "secondary",
	success: "success",
	danger: "danger",
	warning: "warning",
	info: "info",
	light: "light",
	dark: "dark",
};
