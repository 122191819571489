import * as Sentry from "@sentry/react";
import { useCallback, useEffect } from "react";
import useStateObject from "./useStateObject";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import toggleFavoriteThen from "./useFavorite.toggleFavoriteThen";
import { toast } from "@clearpoint/services/toastService/index";

let useFavorite = ({ favoriteFlag, layoutId, page, object, objectId, scorecardId }) => {
	let translate = useTranslate();
	let [{ favoriteFlagState }, setState] = useStateObject({
		favoriteFlagState: favoriteFlag ?? false,
	});
	useEffect(() => {
		setState({
			favoriteFlagState: favoriteFlag,
		});
	}, [favoriteFlag, setState]);
	let { set, clear } = useOldQueryStore();
	let toggleFavorite = useCallback(() => {
		let data = {
			favorite: !favoriteFlagState,
			page,
			object,
			scorecardId,
			layoutId,
			objectId,
		};
		if (layoutId) delete data.objectId;
		if (!object) {
			Sentry.captureException(new Error("Missing object in useFavorite"));
		}
		setState({
			favoriteFlagState: (x) => !x,
		});
		set({
			object: "favorite",
			data,
		}).then(() => {
			toggleFavoriteThen({ favoriteFlagState, translate, clear, layoutId, object, objectId, toast });
		});
	}, [clear, favoriteFlagState, layoutId, object, objectId, page, scorecardId, set, setState, translate]);
	return { favoriteFlagState, toggleFavorite };
};
export default useFavorite;
