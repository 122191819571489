import { useCallback } from "react";
import { httpExpress } from "@clearpoint/services/httpService/index";
import getSendMessageToGPT from "./useSendMessageToGPT.getSendMessageToGPT";

let useSendMessageToGPT = ({ chatId, context, endpoint, setAssistantState, startIndex }) => {
	return useCallback(
		async (message, index) => {
			let sendMessageToGPT = getSendMessageToGPT({
				chatId,
				context,
				endpoint,
				httpExpress,
				setAssistantState,
				startIndex,
			});
			return await sendMessageToGPT(message, index);
		},
		[chatId, context, endpoint, setAssistantState, startIndex]
	);
};

export default useSendMessageToGPT;
