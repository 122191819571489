import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";

let useMapEditFieldList = () => {
	let translate = useTranslate();

	return useCallback(() => {
		let fieldList = [
			{
				value: "showNames",
				label: translate("edit.map.elementDisplayOptions"),
				sortOrder: -1000,
				group: translate("map"),
			},
			{
				value: "background",
				label: translate("edit.map.mapBackground"),
				sortOrder: -999,
				group: translate("map"),
			},
			{
				value: "hideTitle",
				label: translate("layout.hideExportTitles"),
				sortOrder: -998,
				group: translate("map"),
			},
		];
		return fieldList;
	}, [translate]);
};

export default useMapEditFieldList;
