import Badge from "../../../Badge/Badge";
import Column from "../../../Column";
import FormGroup from "../../../Form/Form.Group";
import FormInput from "../../../Form/Input/Input";
import FormSelectObjectType from "../../../Form/Select/Select.ObjectType";
import FormSelectTag from "../../../Form/Select/Select.Tag";
import Help from "../../../Help";
import Row from "../../../Row";
import SelectAggregateScorecardTypeSelectDisplay from "../Shared/AggregateScorecardTypeSelectDisplay";
import SelectAggregateStatusType from "./SelectAggregateStatusType";
import SelectScorecard from "../../../Form/Select/Select.Scorecard";
import SelectStatus from "../../../Form/Select/Select.Status";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getAggregateStatusOptions from "./getAggregateStatusOptions";
import { theme } from "../../../Theme";
import { capitalizeFirstLetter, emptyObject, joinNameList } from "@clearpoint/utils";


let propTypes = {
	name: PropTypes.string,
	seriesType: PropTypes.string,
};

let InputAggregateStatus = ({ name, seriesType }) => {
	let { get } = useOldQueryStore();
	let formValue = useFormValue();
	let translate = useTranslate();
	let { measureId } = formValue;

	let currentFormValue = useFormValue(name);
	let { statusScorecardType, statusType, statusObjects } = currentFormValue || emptyObject;
	let { filter, hasStatusScorecardTypeFlag, namedObjectFlag, showAggregateMeasureCount } = useMemo(
		() => getAggregateStatusOptions(formValue, currentFormValue),
		[formValue, currentFormValue]
	);
	let count = filter ? get({ object: "count", payload: { filter } }) : null;

	return (
		<FormGroup title={translate("edit.series.aggregateStatusSeries")}>
			<Help feature="aggregate-status" />
			<Row>
				<Column medium={4}>
					<SelectAggregateScorecardTypeSelectDisplay
						hasAggregateScorecardTypeFlag={hasStatusScorecardTypeFlag}
						labelText={translate("edit.selectScorecard")}
						measureId={measureId}
						name={joinNameList(name, "statusScorecardType")}
						placeholder={translate("edit.aggregate.selectScorecards") + "..."}
						scorecardCount={count?.scorecardCount}
						seriesType={seriesType}
						required
					/>
				</Column>
				<Column medium={4}>
					{statusScorecardType === 3 && (
						<SelectScorecard
							label={translate("scorecards")}
							name={joinNameList(name, "statusScorecards")}
							multipleFlag
							required
						/>
					)}
					{statusScorecardType === 4 && (
						<FormSelectTag
							label={translate("measureLibrary.selectTag")}
							name={joinNameList(name, "aggregateScorecardTag")}
						/>
					)}
				</Column>
			</Row>
			<Row>
				<Column medium={4}>
					<FormSelectObjectType
						label={
							<>
								{measureId && !showAggregateMeasureCount && (
									<Badge style={{ marginRight: `${theme.smallSpace}` }} color="default">
										0
									</Badge>
								)}
								{showAggregateMeasureCount && (
									<Badge
										style={{ marginRight: `${theme.smallSpace}` }}
										color={count?.measureCount !== 0 ? "primary" : "danger"}
									>
										{count?.measureCount}
									</Badge>
								)}
								{translate("edit.aggregate.elementTypes")}
							</>
						}
						placeholder={translate("edit.aggregate.selectElementTypes") + "..."}
						name={joinNameList(name, "statusObjects")}
						// multipleFlag
						milestoneFlag
						required
					/>
				</Column>

				{statusObjects && (
					<>
						<Column medium={4}>
							<SelectAggregateStatusType
								label={translate(`edit.select${capitalizeFirstLetter(statusObjects)}s`)}
								placeholder={translate(`edit.select${capitalizeFirstLetter(statusObjects)}s`) + "..."}
								object={statusObjects}
								name={joinNameList(name, "statusType")}
								required
							/>
						</Column>
						<Column medium={4}>
							{namedObjectFlag && (
								<FormInput
									label={translate("edit.aggregate.elementName")}
									name={joinNameList(name, "statusSeriesName")}
									required
								/>
							)}
							{statusType === 5 && (
								<FormSelectTag
									label={translate("measureLibrary.selectTag")}
									name={joinNameList(name, "aggregateMeasureTag")}
									required
								/>
							)}
						</Column>
					</>
				)}
			</Row>
			{statusObjects && (
				<SelectStatus
					label={translate("edit.aggregate.statusIndicators")}
					name={joinNameList(name, "statusStatus")}
					required
					multipleFlag
				/>
			)}
		</FormGroup>
	);
};

InputAggregateStatus.propTypes = propTypes;

export default InputAggregateStatus;
