import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Block from "@clearpoint/old-theme/Block";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import FormGantt from "@clearpoint/old-theme/Form/Gantt/Gantt";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import Pod from "@clearpoint/old-theme/Pod/Pod";

import { useDefaultGanttScale, useMeasure, useScorecardLockedFlag } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { calculateStartAndEndDate, emptyArray } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import { usePodContext } from "../PodProvider";
import CopyForwardAllModal from "./CopyForwardAllModal";
import { ganttPrintFormKey, useGanttPodPrint } from "./useGanttPodPrint";

let CopyForwardButton = () => {
	let translate = useTranslate();
	let { closeEditMode, editPodFlag } = usePodContext();

	return editPodFlag ? (
		<ModalOnClick Modal={CopyForwardAllModal} closeEditMode={closeEditMode}>
			<Block display="flex" alignItems="start">
				<IconButton name="copyForward" display="inline" color="success" iconSize="small">
					{" " + translate("button.copyForwardAll")}
				</IconButton>
			</Block>
		</ModalOnClick>
	) : null;
};

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	onDoubleClick: PropTypes.func.isRequired,
	overflowX: PropTypes.string,
	podStyle: PropTypes.object,
};

let GanttPod = ({ className, marginTop, onDoubleClick, overflowX, podStyle }) => {
	const translate = useTranslate();
	const { hideBorderFlag, openEditMode, title } = usePodContext();
	const pageObjectId = useFormValue("objectId");
	const pageScorecardId = useFormValue("scorecardId");
	let scorecardLockedFlag = useScorecardLockedFlag(pageScorecardId);
	const valueInForm = useFormValue("ganttData");
	const { isInPrint, printFormValue } = useGanttPodPrint(valueInForm);

	const shouldShowTaskModal = useCallback(
		(task) => {
			if (scorecardLockedFlag) {
				return false;
			} else if (task.object === "initiative" && task.objectId === pageObjectId) {
				toast.warning(translate("toaster.messages.gantt.editInitiative"));
				return false;
			} else {
				return true;
			}
		},
		[pageObjectId, scorecardLockedFlag, translate]
	);

	const { startDate, endDate } = calculateStartAndEndDate(valueInForm || emptyArray);
	const { width } = useMeasure({ query: ".gantt-pod" }) || {};
	const ganttScale = useDefaultGanttScale({ containerWidth: width, endDate, startDate });

	const ganttPodStyle = useMemo(() => {
		// Apply color property to Gantt label text
		if (podStyle?.backgroundCss) {
			let color = /[^-]color\s*:\s*([^;]*)/.exec(podStyle.backgroundCss)?.[1];
			if (color) {
				return {
					...podStyle,
					backgroundCss: podStyle.backgroundCss + `.gantt_cell { color: ${color} !important; }`,
				};
			}
		}
		return podStyle;
	}, [podStyle]);

	return Array.from({ length: isInPrint ? printFormValue?.length : 1 }).map((x, i) => {
		const value = isInPrint ? x : valueInForm;
		const height = (value?.length + 2) * 26;
		return (
			<Pod
				className={className}
				headerRightContent={<CopyForwardButton />}
				headerText={translate(title)}
				hideBorderFlag={hideBorderFlag}
				marginTop={marginTop}
				onDoubleClick={onDoubleClick}
				overflowX={overflowX}
				podStyle={ganttPodStyle}
				key={i}
			>
				{valueInForm ? (
					<Block height={height ? height + "px" : "auto"} className="gantt-pod">
						<FormGantt
							ganttPodFlag
							ganttStartDate={startDate}
							ganttEndDate={endDate}
							scale={ganttScale || "month"}
							name={isInPrint ? `${ganttPrintFormKey}[${i}]` : "ganttData"}
							onAfterTaskDrag={openEditMode}
							shouldShowTaskModal={shouldShowTaskModal}
						/>
					</Block>
				) : null}
			</Pod>
		);
	});
};

GanttPod.propTypes = propTypes;
export default GanttPod;
