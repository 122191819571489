import { chartPeriodListContainsPeriod } from "../utils";

let createPolarSeriesList = ({ seriesList, measureGridList, chartPeriodList, colorList }) =>
	measureGridList
		.filter((period) => chartPeriodListContainsPeriod(period, chartPeriodList))
		.map((period, index) => ({
			color: colorList[index % colorList.length],
			name: period.periodName,
			data: seriesList.map((series) => ({
				y: parseFloat(period["series" + series.measureSeriesId]) || null,
				numberFormat: series.numberFormat,
			})),
		}));

export default createPolarSeriesList;
