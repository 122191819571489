import { useCallback } from "react";

import DropdownDivider from "@clearpoint/old-theme/Dropdown/Dropdown.Divider";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import DropdownSplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton";
import ModalEmailPageOnClick from "@clearpoint/old-theme/Modal/Modal.EmailPage.OnClick";
import ModalGeneratePDFOnClick from "@clearpoint/old-theme/Modal/Modal.GeneratePDF.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import MapModalController from "@components/Elements/Maps/ManageMaps/MapModalController";


let EditSplitButton = () => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { get } = useOldQueryStore();
	let scorecardList = get({ object: "scorecard" });
	let { map, setLocalState } = useLocalState();
	let { mapId: objectId, name, scorecardId } = map || {};
	let access = scorecardList?.find((x) => x.scorecardId === scorecardId)?.access;
	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });
	let editMap = useCallback(() => setLocalState({ editMapModalWindowVisible: true }), [setLocalState]);
	return editAccessFlag ? (
		<>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<DropdownSplitButton icon="editReport" onClick={editMap}>
					<DropdownItem icon="editReport" onClick={editMap}>
						{translate("edit.editCategory", { category: "map" })}
					</DropdownItem>
					<DropdownDivider />
					<ModalGeneratePDFOnClick object="map" objectId={objectId} title={translate(name)}>
						<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
					</ModalGeneratePDFOnClick>
					<ModalEmailPageOnClick object="map" objectId={objectId} title={translate(name)}>
						<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
					</ModalEmailPageOnClick>
				</DropdownSplitButton>
			</StyleWrapper>
			<MapModalController />
		</>
	) : (
		<ModalGeneratePDFOnClick object="map" objectId={objectId} title={translate(name)}>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<DropdownSplitButton icon="pdf">
					<ModalGeneratePDFOnClick object="map" objectId={objectId} title={translate(name)}>
						<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
					</ModalGeneratePDFOnClick>
					<ModalEmailPageOnClick object="map" objectId={objectId} title={translate(name)}>
						<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
					</ModalEmailPageOnClick>
				</DropdownSplitButton>
			</StyleWrapper>
		</ModalGeneratePDFOnClick>
	);
};
export default EditSplitButton;
