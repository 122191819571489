// eslint-disable-next-line simple-import-sort/imports
import Loading from "@clearpoint/old-theme/Loading/index";
import { setAxiosProperty } from "@clearpoint/services/axiosService";
import { toast } from "@clearpoint/services/toastService/index";
import { getTranslate } from "@clearpoint/translate";
import * as Sentry from "@sentry/react";
import React, { Suspense, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
import WebFont from "webfontloader";
import "./App.css";
import Providers from "./Providers";
import QueryRoute from "./QueryRoute";
import ErrorHandler from "./components/ErrorHandler/ErrorHandler";
import ToastContainer from "./components/ToastContainer";

// Testing
import { QueryTest, StoreTest } from "@clearpoint/main-test";

// we lose styling in PDF exports if these are lazy-loaded

import { clearLocalStorageExceptKeys, refreshPage } from "@clearpoint/utils";
import ViewChart from "./components/Elements/Charts/ViewChart";
import ViewMap from "./components/Elements/Maps/ViewMap";
import ViewLayout from "./components/Layouts/ViewLayout";
import ViewDetail from "./components/Layouts/ViewLayout/ViewDetail";
import Main from "./components/Main";
import Print from "./components/Print";
import BriefingBookTableOfContents from "./components/Print/BriefingBook/TableOfContents";
import RestrictedContent from "./components/Print/RestrictedContentPage";
import StylePreview from "./components/Print/StylePreview";

// Create Custom Sentry Route component
let SentryRoute = Sentry.withSentryRouting(Route);

// lazy loaded components
let AdminOptions = React.lazy(() => import("./components/SystemSettings/AdminOptions"));
let ManageElements = React.lazy(() => import("./components/Elements/ManageElements"));
let ManageScorecards = React.lazy(() => import("./components/Elements/Scorecards/ManageScorecards"));
let ManageFiscalYears = React.lazy(() => import("./components/SystemSettings/ReportingPeriods/ManageFiscalYears"));
let ManageLayouts = React.lazy(() => import("./components/Layouts/ManageLayouts"));
let ManageMasterPeriods = React.lazy(() => import("./components/SystemSettings/ReportingPeriods/ManageMasterPeriods"));
let ManagePeriodGroups = React.lazy(() => import("./components/SystemSettings/ReportingPeriods/ManagePeriodGroups"));
let ManagePeriods = React.lazy(() => import("./components/SystemSettings/ReportingPeriods/ManagePeriods"));
let ManageReminders = React.lazy(() => import("./components/Automation/ManageReminders"));
let ManageSchedules = React.lazy(() => import("./components/Automation/ManageSchedules"));
let ManageUserGroups = React.lazy(() => import("./components/SystemSettings/UsersAndGroups/ManageUserGroups"));
let ManageUsers = React.lazy(() => import("./components/SystemSettings/UsersAndGroups/ManageUsers"));
let ManageWorkspaces = React.lazy(() => import("./components/MyClearpoint/Workspaces/ManageWorkspaces"));
let MeasureLibrary = React.lazy(() => import("./components/MeasureLibrary"));
let ManageDataAndIntegrations = React.lazy(() => import("./components/Automation/ManageDataAndIntegrations"));
let MyFavorites = React.lazy(() => import("./components/MyClearpoint/MyFavorites"));
let MyMentions = React.lazy(() => import("./components/MyClearpoint/MyMentions"));
let MyNotifications = React.lazy(() => import("./components/MyClearpoint/MyNotifications"));
let MyScorecard = React.lazy(() => import("./components/MyClearpoint/MyScorecard"));
let MyApprovals = React.lazy(() => import("./components/MyClearpoint/MyApprovals"));
let MyUpdates = React.lazy(() => import("./components/MyClearpoint/MyUpdates"));
let Notifications = React.lazy(() => import("./components/Automation/Notifications"));
let Navigation = React.lazy(() => import("./components/Navigation"));
let PageExports = React.lazy(() => import("./components/DocumentLibrary/PageExports"));
let RecycleBin = React.lazy(() => import("./components/RecycleBin"));
let ReportingWorkflows = React.lazy(() => import("./components/Automation/ReportingWorkflows"));
let RevisionHistory = React.lazy(() => import("./components/SystemSettings/RevisionHistory"));
let ScorecardAI = React.lazy(() => import("./components/AI/ScorecardAIPage"));
let Search = React.lazy(() => import("./components/Search"));
let SuccessPortal = React.lazy(() => import("./components/Navigation/TopNavigation/SuccessPortal"));
let AlertsInbox = React.lazy(() => import("./components/Navigation/TopNavigation/AlertsInbox/index"));
let GoalMap = React.lazy(() => import("./components/Elements/Goals/GoalMap"));
let Login = React.lazy(() => import("./components/Login"));
let ManageGoals = React.lazy(() => import("./components/Elements/Goals/ManageGoals"));
let ScorecardMap = React.lazy(() => import("./components/Elements/Scorecards/ScorecardMap"));
let AIAssistants = React.lazy(() => import("./components/AI/AssistantsPage"));
let AITranscripts = React.lazy(() => import("./components/AI/TranscriptsPage"));
let Attachments = React.lazy(() => import("./components/DocumentLibrary/Attachments"));
let ErrorTest = React.lazy(() => import("./components/ErrorHandler/ErrorTest"));
let GoalsAIPage = React.lazy(() => import("./components/AI/GoalsAIPage"));
let ImagesGridView = React.lazy(() => import("./components/DocumentLibrary/Images/ImagesGridView"));
let ImagesListView = React.lazy(() => import("./components/DocumentLibrary/Images/ImagesListView"));
let ListBriefingBookTemplates = React.lazy(() =>
	import("./components/DocumentLibrary/BriefingBooks/ListBriefingBookTemplates")
);
let ListBriefingBooks = React.lazy(() => import("./components/DocumentLibrary/BriefingBooks/ListBriefingBooks"));
let ListDashboardTemplates = React.lazy(() => import("./components/DocumentLibrary/Dashboards/ListDashboardTemplates"));
let ListHtmlExportTemplates = React.lazy(() =>
	import("./components/DocumentLibrary/HtmlExports/ListHtmlExportTemplates")
);
let ListHtmlExports = React.lazy(() => import("./components/DocumentLibrary/HtmlExports/ListHtmlExports"));
let Workspace = React.lazy(() => import("./components/MyClearpoint/Workspaces/Workspace"));

let initialLoadFlag = true;
let App = () => {
	if (initialLoadFlag) {
		try {
			let appUpdatedFlag = localStorage.getItem("outdatedVersionFlag");
			if (appUpdatedFlag) {
				let interval = setInterval(() => {
					let translate = getTranslate();
					let toastMessage = translate("toaster.messages.global.applicationUpdated");
					if (toastMessage) {
						toast.warning(toastMessage);
						clearInterval(interval);
					}
				}, [50]);
				localStorage.setItem("outdatedVersionFlag", false);
			}
		} catch (error) {
			console.error(error);
		}

		// fix early api calls
		try {
			let session = localStorage.getItem("session");
			session = JSON.parse(session);
			let { token, sessionId } = session || {};
			let { queryToken, querySessionId } = new URLSearchParams(window?.location?.search);
			if (queryToken && querySessionId) {
				token = queryToken;
				sessionId = querySessionId;
			}

			if (token) setAxiosProperty('defaults.headers.common["Authorization"]', token);
			if (sessionId) setAxiosProperty('defaults.headers.common["SessionId"]', sessionId);
		} catch (error) {
			if (error.name === "QuotaExceededError") {
				clearLocalStorageExceptKeys(["username"]);
				refreshPage();
			}
			console.error(error);
		}
		initialLoadFlag = false;
	}
	useLayoutEffect(() => {
		WebFont.load({
			google: {
				families: [
					"Roboto:400,500,700,400italic,500italic,700italic",
					"Open Sans:400,600,700,400italic,600italic,700italic",
				],
			},
		});
	}, []);
	return (
		<ErrorHandler>
			<Providers>
				<ToastContainer />
				{/* A <Switch> looks through its children <SentryRoute>s and
				 renders the first one that matches the current URL. */}
				<Switch>
					<SentryRoute exact path="/error-test">
						<ErrorTest />
					</SentryRoute>
					<SentryRoute exact path="/print">
						<Print>
							<QueryRoute layoutId>
								<ViewLayout />
							</QueryRoute>
							<QueryRoute objectId object="map">
								<ViewMap />
							</QueryRoute>
							<QueryRoute layoutId={undefined} objectId object="!map&!chart">
								<ViewDetail />
							</QueryRoute>
							<QueryRoute objectId object="chart">
								<ViewChart />
							</QueryRoute>
							<QueryRoute restrictedContent>
								<RestrictedContent />
							</QueryRoute>
							<QueryRoute tableOfContents>
								<BriefingBookTableOfContents />
							</QueryRoute>
							<QueryRoute stylePreview>
								<StylePreview />
							</QueryRoute>
						</Print>
					</SentryRoute>
					<SentryRoute path="/index">
						<Navigation>
							<Suspense fallback={<Loading />}>
								<Switch>
									<SentryRoute exact path="/index">
										<QueryRoute layoutId>
											<ViewLayout />
										</QueryRoute>
										<QueryRoute layoutId={undefined} objectId object="map">
											<ViewMap />
										</QueryRoute>
										<QueryRoute layoutId={undefined} objectId object="!map">
											<ViewDetail />
										</QueryRoute>
										<QueryRoute layoutId={undefined} objectId={undefined} object="scorecard">
											<ScorecardMap />
										</QueryRoute>
										<QueryRoute layoutId={undefined} objectId={undefined} object="!scorecard">
											<Main />
										</QueryRoute>
									</SentryRoute>
									<SentryRoute exact path="/index/admin-options">
										<AdminOptions />
									</SentryRoute>
									<SentryRoute exact path="/index/ai">
										<QueryRoute mode="goals">
											<GoalsAIPage />
										</QueryRoute>
										<QueryRoute mode="scorecard">
											<ScorecardAI />
										</QueryRoute>
										<QueryRoute mode="framework">
											<ScorecardAI />
										</QueryRoute>
										<QueryRoute mode={undefined}>
											<AIAssistants />
										</QueryRoute>
										<QueryRoute mode="transcript">
											<AITranscripts />
										</QueryRoute>
									</SentryRoute>
									<SentryRoute exact path="/index/audit-log">
										<RevisionHistory />
									</SentryRoute>
									<SentryRoute exact path="/index/document-library">
										<QueryRoute mode="reports" view="template">
											<ListBriefingBookTemplates />
										</QueryRoute>
										<QueryRoute mode="reports" view="file">
											<ListBriefingBooks />
										</QueryRoute>
										<QueryRoute mode="html" view="template">
											<ListHtmlExportTemplates />
										</QueryRoute>
										<QueryRoute mode="dashboard" view="template">
											<ListDashboardTemplates />
										</QueryRoute>
										<QueryRoute mode="html" view="file">
											<ListHtmlExports />
										</QueryRoute>
										<QueryRoute mode="exports">
											<PageExports />
										</QueryRoute>
										<QueryRoute mode="attachments">
											<Attachments />
										</QueryRoute>
										<QueryRoute mode="images" view="list">
											<ImagesListView />
										</QueryRoute>
										<QueryRoute mode="images" view="grid">
											<ImagesGridView />
										</QueryRoute>
									</SentryRoute>
									<SentryRoute exact path="/index/goals">
										<QueryRoute mode="list">
											<ManageGoals />
										</QueryRoute>
										<QueryRoute mode="personal">
											<ManageGoals />
										</QueryRoute>
										<QueryRoute mode="tree">
											<GoalMap />
										</QueryRoute>
									</SentryRoute>
									<SentryRoute exact path="/index/home">
										<Main />
									</SentryRoute>
									<SentryRoute exact path="/index/integrations">
										<ManageDataAndIntegrations />
									</SentryRoute>
									<SentryRoute exact path="/index/manage">
										<ManageElements />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-scorecards">
										<ManageScorecards />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-fiscal-years">
										<ManageFiscalYears />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-layouts">
										<ManageLayouts />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-master-periods">
										<ManageMasterPeriods />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-periods">
										<ManagePeriods />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-period-groups">
										<ManagePeriodGroups />
									</SentryRoute>
									<SentryRoute exact path="/index/workspace">
										<Workspace />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-workspaces">
										<ManageWorkspaces />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-users">
										<ManageUsers />
									</SentryRoute>
									<SentryRoute exact path="/index/manage-user-groups">
										<ManageUserGroups />
									</SentryRoute>
									<SentryRoute exact path="/index/my-favorites">
										<MyFavorites />
									</SentryRoute>
									<SentryRoute exact path="/index/my-scorecard">
										<MyScorecard />
									</SentryRoute>
									<SentryRoute exact path="/index/my-approvals">
										<MyApprovals />
									</SentryRoute>
									<SentryRoute exact path="/index/my-mentions">
										<MyMentions />
									</SentryRoute>
									<SentryRoute exact path="/index/my-notifications">
										<MyNotifications />
									</SentryRoute>
									<SentryRoute exact path="/index/my-updates">
										<MyUpdates />
									</SentryRoute>
									<SentryRoute exact path="/index/notifications">
										<Notifications />
									</SentryRoute>
									<SentryRoute exact path="/index/workflows">
										<ReportingWorkflows />
									</SentryRoute>
									<SentryRoute exact path="/index/recycle-bin">
										<RecycleBin />
									</SentryRoute>
									<SentryRoute exact path="/index/measure-library">
										<MeasureLibrary />
									</SentryRoute>
									<SentryRoute exact path="/index/portal">
										<SuccessPortal />
									</SentryRoute>
									<SentryRoute exact path="/index/schedules">
										<ManageSchedules />
									</SentryRoute>
									<SentryRoute exact path="/index/send-reminders">
										<ManageReminders />
									</SentryRoute>
									<SentryRoute exact path="/index/search">
										<Search />
									</SentryRoute>
									<SentryRoute exact path="/index/alert-inbox">
										<AlertsInbox />
									</SentryRoute>
									<SentryRoute exact path="/index/query-test">
										<QueryTest />
									</SentryRoute>
									<SentryRoute exact path="/index/store-test">
										<StoreTest />
									</SentryRoute>
									<SentryRoute path="/index/:anything">
										<Main />
									</SentryRoute>
								</Switch>
							</Suspense>
						</Navigation>
					</SentryRoute>
					<SentryRoute path="/login">
						<Login />
					</SentryRoute>
					<SentryRoute path="/reset-password">
						<Login resetPasswordFlag />
					</SentryRoute>
					<SentryRoute path="/">
						<Main />
					</SentryRoute>
				</Switch>
			</Providers>
		</ErrorHandler>
	);
};

export default App;
