import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { buildQueryString } from "@clearpoint/utils";

import Block from "../Block";
import NoResults from "../NoResults";
import { theme } from "../Theme";
import Thread from "./Thread";
import useDiscussionList from "./useDiscussionList";
import useScrollToComment from "./useScrollToComment";

let propTypes = {
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	showResolvedCommentsFlag: PropTypes.bool,
	testFlag: PropTypes.bool,
};

let DiscussionList = ({ layoutId, object, objectId, showResolvedCommentsFlag, testFlag }) => {
	let { loadingFlag, discussionList, threadedDiscussionList } = useDiscussionList({
		object,
		objectId,
		layoutId,
		showResolvedCommentsFlag,
		testFlag,
	});
	
	useScrollToComment(discussionList);

	return loadingFlag ? null : (
		<Block
			position="relative"
			marginTop={theme.tinySpace}
			paddingBottom={theme.tinySpace}
			flex="1 1 auto"
			overflow="hidden"
		>
			<Block
				id="discussionListScrollableContainer"
				height="100%"
				display="flex"
				flexDirection="column"
				overflowY="auto"
				// $style={`&::after { 
				// 	content: "";
				// 	position: absolute;
				// 	bottom: 0;
				// 	left: 6px;
				// 	width: 100%;
				// 	height: 20px;
				// 	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
				// 	}`}
			>				
				{threadedDiscussionList?.map((x, i) => (
					<Thread
						key={x.parent.discussionId}
						layoutId={layoutId}
						object={object}
						objectId={objectId}
						thread={x}
					/>
				))}
			</Block>
		</Block>
	);
};

DiscussionList.propTypes = propTypes;

export default DiscussionList;
