import FilterProvider from "@clearpoint/providers/FilterProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
};
let ElementListFilterProvider = ({ children }) => {
	let { get } = useOldQueryStore();
	let { scorecardId } = useOldSession().session;
	let menu = scorecardId && get({ object: "menu", parent: "scorecard", parentId: scorecardId });
	let formElementList = useFormValue("elements");
	let elementList = useMemo(
		() =>
			menu
				?.reduce((elementList, objectData) => {
					if (["objective", "measure", "initiative", "actionItem", "risk"].includes(objectData?.object))
						return [...elementList, ...(objectData.objects || [])];
					return elementList;
				}, [])
				.filter((x) => !formElementList?.some((y) => y.object === x.object && y.objectId === x.objectId)),
		[formElementList, menu]
	);
	return menu ? <FilterProvider data={elementList}>{children}</FilterProvider> : null;
};
ElementListFilterProvider.propTypes = propTypes;

export default ElementListFilterProvider;
