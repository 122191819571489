import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectPageSize = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("reportingTemplates.letter"), value: "letter" },
			{ label: translate("reportingTemplates.legal"), value: "legal" },
			{ label: translate("reportingTemplates.a4"), value: "a4" },
			{ label: translate("reportingTemplates.tabloid"), value: "tabloid" },
		],
		[translate]
	);
	return <Select options={options} {...props} />;
};

export default SelectPageSize;
