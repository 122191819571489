import Block from "../../../Block";
import CalculationMapModal from "./CalculationMapModal";
import FormGrid from "../../../Form/Grid/Grid";
import Loading from "../../../Loading";
import React, { Suspense, useCallback, useMemo } from "react";
import StyleWrapper from "../../../StyleWrapper";
import useAddCellClickEvents from "./useAddCellClickEvents";
import useColumns from "./useColumns";
import useDebouncedMeasureDataUpdate from "./useDebouncedMeasureDataUpdate";
import { useFormContext } from "../../../Form/Form";
import { useSeriesGridData } from "./Provider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useWindowContext } from "../../../ModalWindow/ModalWindow";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";
import { useCheckAccess, useStateObject } from "@clearpoint/hooks";
import CalculationMapTab from "@components/Elements/Shared/SeriesTab/EditSeriesModal/CalculationMapTab";

let propTypes = {
	CalculationMapTab: PropTypes.elementType,
	EditSeriesModal: PropTypes.elementType,
	afterBeginEditing: PropTypes.func,
	autoHeightFlag: PropTypes.bool,
	limitRowCountFlag: PropTypes.bool,
	measureId: PropTypes.number,
	onEditSeriesModalOpen: PropTypes.func,
};

let SeriesGridContent = ({
	CalculationMapTab,
	EditSeriesModal,
	afterBeginEditing,
	autoHeightFlag,
	measureId,
	onEditSeriesModalOpen,
	...props
}) => {
	let { get } = useOldQueryStore();
	let checkAccess = useCheckAccess();
	let measure = measureId && get({ object: "measure", objectId: measureId });
	let { access, scorecardId } = measure || emptyObject;
	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });

	let insideWindowFlag = !!useWindowContext();
	let modalState = useStateObject({
		calculationMapModalVisible: false,
		editSeriesModalVisible: false,
		editMeasureSeriesId: 0,
	});

	let [{ calculationMapModalVisible, editSeriesModalVisible, editMeasureSeriesId }, setModalState] = modalState;
	let closeEditSeriesModal = useCallback(() => setModalState({ editSeriesModalVisible: false }), [setModalState]);
	let closeCalculationMapModal = useCallback(
		() => setModalState({ calculationMapModalVisible: false }),
		[setModalState]
	);

	let { seriesData, statusList } = useSeriesGridData();
	let { setFormValue } = useFormContext();
	useDebouncedMeasureDataUpdate();

	let beforePaste = useCallback((data) => {
		data.forEach((row, i) => (data[i] = row.map((cell) => cell?.replace?.(/[^-0-9.-/\n/\t]+/g, "") || cell)));
	}, []);

	let onChange = useCallback(
		({ changes, newFormState }) => {
			for (let change of changes) {
				let [row, column, originalValue, newValue] = change;
				if (newValue === originalValue) return;
				if (originalValue !== newValue && afterBeginEditing) afterBeginEditing();
				if (column === "statusName") {
					let status = statusList.find((x) => x.name === newValue);
					newFormState[row].statusIcon = `<img src="${status.image}" width=16 height=16 />`;
					newFormState[row].statusId = status.statusId;
					setFormValue("_seriesData", newFormState);
				}
			}
		},
		[afterBeginEditing, setFormValue, statusList]
	);
	let stopPropagation = useCallback((e) => {
		if (e) {
			e.stopPropagation();
		}
	}, []);

	let addCellClickEvents = useAddCellClickEvents({ onEditSeriesModalOpen, setModalState });

	let columns = useColumns();
	let contextMenu = useMemo(
		() =>
			[
				!insideWindowFlag ? ["viewCalculationMap", editAccessFlag && "editSeries", "hr"] : null,
				"copy",
				"paste",
				"pasteTransposed",
				"hr2",
				"undo",
				"redo",
			]
				.flat()
				.filter((x) => !!x),
		[editAccessFlag, insideWindowFlag]
	);
	let loadingFlag = !columns;

	return (
		<>
			{loadingFlag ? (
				<Loading />
			) : (
				<StyleWrapper
					$style={`th:nth-child(2) {
					border-left: none;
				}
				table, table td, table thead th div	{
					font-size: 16px !important;
				}
				td img {
					margin-bottom: 2px;
					display: inline;
					box-sizing: border-box;
				}`}
				>
					<FormGrid
						addNewRowOnEditFlag={false}
						afterBeginEditing={afterBeginEditing}
						beforeChange={afterBeginEditing}
						afterOnCellMouseDown={addCellClickEvents}
						beforePaste={beforePaste}
						columns={columns}
						contextMenu={contextMenu}
						copyPaste
						defaultValue={seriesData}
						fixedColumnsLeft={3}
						height={autoHeightFlag ? "auto" : "100%"}
						manualColumnResize
						modalState={modalState}
						name="_seriesData"
						noControlsFlag
						onChange={onChange}
						stretchHorizontal={null}
						{...props}
					/>
				</StyleWrapper>
			)}
			<Block onDoubleClick={stopPropagation}>
				{editSeriesModalVisible && editAccessFlag && (
					<Suspense>
						<EditSeriesModal
							modalWindowVisible
							close={closeEditSeriesModal}
							objectId={editMeasureSeriesId}
							parentId={measureId}
							measureId={measureId}
						/>
					</Suspense>
				)}
				{calculationMapModalVisible && (
					<CalculationMapModal
						CalculationMapTab={CalculationMapTab}
						modalWindowVisible
						close={closeCalculationMapModal}
						objectId={editMeasureSeriesId}
					/>
				)}
			</Block>
		</>
	);
};
SeriesGridContent.propTypes = propTypes;
export default SeriesGridContent;
