import ColorList from "../Transposed/ColorList";
import CustomSeriesList from "../Custom/CustomSeriesList";
import Help from "@clearpoint/old-theme/Help";
import PropTypes from "prop-types";

let propTypes = {
	chartFormatData: PropTypes.object,
};

let CustomTransposedTab = ({ chartFormatData }) => (
	<>
		<Help feature="chart-custom-series" />
		<Help feature="chart-transposed" />
		<CustomSeriesList />
		<ColorList chartFormatData={chartFormatData} />
	</>
);

CustomTransposedTab.propTypes = propTypes;
export default CustomTransposedTab;
