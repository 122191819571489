import Action from "./Action";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	resolvingFlag: PropTypes.bool,
	resolvedFlag: PropTypes.bool,
	toggleThreadResolution: PropTypes.func,
};

let Resolve = ({ resolvingFlag, resolvedFlag, toggleThreadResolution }) => (
	<Action
		name={resolvedFlag ? "unresolve" : "resolve"}
		marginRight={theme.tinySpace}
		onClick={toggleThreadResolution}
		disabledFlag={resolvingFlag}
		spinFlag={resolvingFlag}
	/>
);

Resolve.propTypes = propTypes;
export default Resolve;
