import { useMemo, useRef } from "react";
import getGridPodFieldIdList from "./useGridPodFieldIdList.getGridPodFieldIdList";

let useGridPodFieldIdList = (formValue) => {
	let gridPodFieldIdListRef = useRef(null);
	let gridPodFieldIdList = useMemo(() => getGridPodFieldIdList({ formValue, gridPodFieldIdListRef }), [formValue]);
	gridPodFieldIdListRef.current = gridPodFieldIdList;

	return gridPodFieldIdList;
};

export default useGridPodFieldIdList;
