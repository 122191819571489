import Select from "./FormSelect";
import { useCallback, useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { validateEmail } from "@clearpoint/utils";

let SelectRecipient = (props) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let userList = get({ object: "user" });
	let options = useMemo(
		() =>
			userList?.reduce((options, user) => {
				let email = user.notificationEmail || user.email;
				if (email && validateEmail(email) && !options.some((option) => option.value === email))
					return [
						...options,
						{
							label: user.fullName,
							value: user.email,
						},
					];
				return options;
			}, []),
		[userList]
	);
	let createLabel = useCallback((inputValue) => translate("select.externalEmail", { email: inputValue }), [translate]);
	return <Select options={options} formatCreateLabel={createLabel} {...props} />;
};

export default SelectRecipient;
