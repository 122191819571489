import Block from "@clearpoint/old-theme/Block";
import DropdownSplitButtonFavorite from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Favorite";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { theme } from "@clearpoint/old-theme/Theme";
import { buildQueryString, emptyObject } from "@clearpoint/utils";


let FavoriteSplitButton = ({ layout }) => {
	let { get } = useOldQueryStore();
	let { layoutId, object, scorecardId } = layout;
	let { favorite: favoriteFlag } =
		(object && get({ object: "favorite", queryString: buildQueryString({ layoutId, object }) })) || emptyObject;
	return (
		<Block marginLeft={theme.smallSpace}>
			<DropdownSplitButtonFavorite
				favoriteFlag={favoriteFlag}
				object={object}
				layoutId={layoutId}
				scorecardId={scorecardId}
			/>
		</Block>
	);
};
export default FavoriteSplitButton;
