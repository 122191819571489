import { useEffectOnce } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";

let useGetLinkElementData = ({ linkTypeProp, name, targetObject }) => {
	let { setFormValue } = useFormContext();
	let linkList = useFormValue(name);
	let scorecardId = useFormValue("_modal.scorecardId");
	let linkTypeFormValue = useFormValue("_modal.linkType");
	let linkType = linkTypeProp || linkTypeFormValue;
	let object = ["parent", "child"].includes(linkType) ? targetObject : linkType;
	let { get } = useOldQueryStore();
	let elementList = scorecardId && object && get({ scorecardId, object, queryString: "list=true" });
	if (elementList && object === "category") {
		elementList = elementList.filter((x) => x.active);
	}
	// Pre-populate
	useEffectOnce(() => {
		let filteredList = linkList
			?.filter((x) => !["initiativeMeasures", "measureInitiatives"].includes(x.fieldId))
			.map((x) => ({
				disabled: x.fieldId?.startsWith("child") && x.fieldId !== "children",
				linkType: x.fieldId === "children" ? "child" : (x.fieldId === "parents" ? "parent" : x.object),
				object: x.object,
				objectId: x.objectId,
				scorecardId: x.scorecardId,
			}));
		setFormValue("_modal.linkObjectIdList", filteredList);
	}, []);
	let loadingFlag = scorecardId && object && !elementList;
	return {
		elementList,
		linkType,
		loadingFlag,
		object,
	};
};

export default useGetLinkElementData;
