import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { usePercentLoaded } from "@clearpoint/hooks";


let propTypes = {
	store: PropTypes.object,
	translate: PropTypes.func,
};
let LoadingText = ({ store, translate }) => {
	let translateHook = useTranslate();
	if (!translate) translate = translateHook;
	let percentLoaded = usePercentLoaded(store);
	return (
		translate(percentLoaded ? "global.loadingPercent" : "global.loading", { complete: percentLoaded }) ||
		"Loading... One Moment Please"
	);
};
LoadingText.propTypes = propTypes;

export default LoadingText;
