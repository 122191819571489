import Select from "./FormSelect";
import useFormValue from "../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray } from "@clearpoint/utils";

let SelectChartMeasureSeries = (props) => {
	let translate = useTranslate();
	let measureId = useFormValue("measureId");
	let { get } = useOldQueryStore();
	let measureSeriesList = measureId && get({ parent: "measure", parentId: measureId, object: "measureSeries" });
	let options = useMemo(
		() =>
			measureSeriesList?.filter((x) => x.active).map((x) => ({ label: x.name, value: x.measureSeriesId })) ||
			emptyArray,
		[measureSeriesList]
	);

	return <Select placeholder={translate("options.selectMeasureSeries")} options={options} {...props} />;
};

export default SelectChartMeasureSeries;
