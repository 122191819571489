import { usePodContext } from "../PodProvider";

let useDefaultPodNameInForm = () => {
	let { template, updateModeFlag } = usePodContext();
	if (template === "comments") template = "analysis";
	let name = updateModeFlag ? `updates.${template}` : template;
	return name;
};

export default useDefaultPodNameInForm;
