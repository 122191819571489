let createQueryKeyFromId = (id) => {
	let queryKey = {};
	delete id.url;
	delete id.preventLogoutFlag;
	if (id.originalUrl) id.url = id.originalUrl;
	if (Object.keys(id).includes("queryString") && !id.queryString) delete id.queryString;
	if (id.parent === "scorecard" && id.parentId && !id.scorecardId) {
		queryKey.scorecardId = id.parentId;
		delete id.parent;
		delete id.parentId;
	}
	for (let field in id) queryKey[field] = id[field];
	if (id.scorecardId) queryKey.scorecardId = id.scorecardId;
	if (["profile", "options", "dataLoader", "menu", "language"].includes(id.object)) queryKey.type = "detail";
	else if (!id.objectId && !id.periodId) queryKey.type = "list";
	else if (!id.objectId && id.periodId) queryKey.type = "statusList";
	if (id.objectId && id.periodId) queryKey.type = "status";
	else if (id.objectId) queryKey.type = "detail";
	return [queryKey];
};

export { createQueryKeyFromId };
