import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import useFormValue from "../../../Form/hooks/useFormValue";
import UserImageAndName from "../../../Form/shared/UserImageAndName";

let propTypes = {
	name: PropTypes.string,
	noInitialsFlag: PropTypes.bool,
	value: PropTypes.number,
};
let FormFieldDisplayOwnerId = ({ name, noInitialsFlag, value }) => {
	let { get } = useOldQueryStore();
	let ownerId = useFormValue(name);
	if (value) ownerId = value;
	let ownerList = get({ object: "user" });
	let owner = ownerList?.find((x) => x.userId === ownerId);
	// eslint-disable-next-line no-nested-ternary
	return owner ? (noInitialsFlag ? owner.fullName : <UserImageAndName user={owner} />) : null;
};
FormFieldDisplayOwnerId.propTypes = propTypes;

export default FormFieldDisplayOwnerId;
