import Hide from "@clearpoint/old-theme/Hide";
import PodChart from "@clearpoint/old-theme/Pod/ChartPod/ChartPod";
import PodHeaderTextWrapper from "@clearpoint/old-theme/Pod/DefaultPodHeader/HeaderTextWrapper";
import PodHeaderWrapper from "@clearpoint/old-theme/Pod/DefaultPodHeader/HeaderWrapper";
import PreviewButton from "./PreviewButton";
import { useChartPodContext } from "@clearpoint/old-theme/Pod/ChartPod/ChartPodProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useWindowSize } from "@clearpoint/hooks";


let PodHeader = () => {
	let chartName = useFormValue("name");
	let { initialChartLoadedFlag, setChartFormatData } = useChartPodContext();

	return (
		<PodHeaderWrapper display="flex" alignItems="top" justifyContent="space-between">
			<PodHeaderTextWrapper wordWrap="break-word" flexShrink="1">
				<h3>{chartName}</h3>
			</PodHeaderTextWrapper>
			<PreviewButton initialChartLoadedFlag={initialChartLoadedFlag} setChartFormatData={setChartFormatData} />
		</PodHeaderWrapper>
	);
};

let propTypes = {
	chartFormatData: PropTypes.object.isRequired,
	setChartFormatData: PropTypes.func.isRequired,
};

let ChartPreview = ({ chartFormatData, setChartFormatData }) => {
	let { displayClass } = useWindowSize();
	let { measureId, objectId } = useFormValue();

	return (
		<Hide
			visible={!["small", "tiny"].includes(displayClass)}
			background={theme.white}
			height="100%"
			overflowY="auto"
			padding={`${theme.space} ${theme.space} ${theme.tinySpace}`}
			width="40%"
		>
			<PodChart
				border={`1px solid ${theme.lightGray}`}
				chartFormatData={chartFormatData}
				chartId={objectId}
				CustomHeader={PodHeader}
				measureId={measureId}
				setChartFormatData={setChartFormatData}
			/>
		</Hide>
	);
};

ChartPreview.propTypes = propTypes;
export default ChartPreview;
