import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import Block from "../Block";
import Check from "../Check/Check";
import PageTitle from "../PageTitle/PageTitle";
import { theme } from "../Theme";
import AddComment from "./AddComment";
import CloseDiscussionBarButton from "./CloseDiscussionBarButton";
import DiscussionList from "./DiscussionList";
import LocalStateProvider, { useLocalState } from "@clearpoint/providers/LocalStateProvider";


let propTypes = {
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	ObjectSelect: PropTypes.elementType,
};

let Discussion = ({ layoutId, object, objectId, ObjectSelect, hideCloseFlag }) => {
	let { discussionVisible, inModalFlag } = useLocalState();
	let { clear } = useOldQueryStore();
	let [showResolvedCommentsFlag, setShowResolvedCommentsFlag] = useState(false);
	let translate = useTranslate();
	let toggleResolvedCommentsDisplay = useCallback(() => {
		setShowResolvedCommentsFlag((x) => !x);
		clear({ object: "comment" });
	}, [clear]);

	return (
		discussionVisible &&
		!inModalFlag && (
			<Block
				width="350px"
				minWidth="300px"
				backgroundColor={theme.white}
				boxShadow="inset 5px 0px 4px rgb(0 0 0 / 12%), inset 3px 0px 4px rgb(0 0 0 / 24%)"
				padding={`${theme.mediumSpace} ${theme.space} 0px ${theme.mediumSpace}`}
				display="flex"
				flexDirection="column"
			>
				<Block flexShrink="0" display="flex" justifyContent="space-between" alignItems="center">
					<PageTitle title={translate("detailLayouts.discussion")} />
					{!hideCloseFlag && <CloseDiscussionBarButton />}
				</Block>
				<Block padding={`${theme.space} 0px 0px`}>
					<Check
						onClick={toggleResolvedCommentsDisplay}
						checked={showResolvedCommentsFlag}
						marginBottom={0}
						text={translate("global.showResolved")}
					/>
				</Block>
				{ObjectSelect && (
					<Block padding={`${theme.space} 0px 0px`}>
						<ObjectSelect />
					</Block>
				)}
				<LocalStateProvider newCommentBoxVisible>
					<Block
						flexShrink="1"
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
						overflowY="hidden"
					>
						<DiscussionList
							object={object}
							layoutId={layoutId}
							objectId={objectId}
							showResolvedCommentsFlag={showResolvedCommentsFlag}
						/>
						<AddComment object={object} layoutId={layoutId} objectId={objectId} />
					</Block>
				</LocalStateProvider>
			</Block>
		)
	);
};

Discussion.propTypes = propTypes;

export default Discussion;
