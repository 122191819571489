import { emptyArray } from "@clearpoint/utils";

let createThreadedDiscussionList = (discussionList) => {
	let threadedDiscussionList =
		discussionList?.filter?.((x) => !x.parentId).map((x) => ({ parent: x, replyList: [] })) || emptyArray;

	discussionList?.forEach?.((x) => {
		if (x.parentId) {
			let parentIndex = threadedDiscussionList.findIndex((y) => y.parent.discussionId === x.parentId);
			if (parentIndex !== -1) {
				threadedDiscussionList[parentIndex].replyList.push(x);
			}
		}
	});
	return threadedDiscussionList;
};

export default createThreadedDiscussionList;
