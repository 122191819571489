import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import { emptyArray } from "@clearpoint/utils";


let propTypes = {
	name: PropTypes.string,
	singleColumnFlag: PropTypes.bool,
};

let FormSelectTableStyle = ({ name, singleColumnFlag, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let tableStyleList = get({ object: "tableStyle" });
	let defaultStyle = tableStyleList?.find((x) => x.defaultStyle && x.active);
	let options = useMemo(() => {
		if (tableStyleList === undefined) return;
		if (!isArray(tableStyleList)) return [];
		let options = [
			{
				label: singleColumnFlag ? translate("global.default") : translate("layout.useClearPointDefault"),
				value: singleColumnFlag ? null : -1,
			},
		];
		if (defaultStyle && !singleColumnFlag)
			options = [...options, { label: `${translate("layout.useDefaultStyle")} (${defaultStyle.name})`, value: 0 }];
		let remainingTableStyleList = tableStyleList.filter((x) => x.active && !x.defaultStyle);
		return [
			...options,
			...remainingTableStyleList.map((x) => ({
				label: x.name,
				value: x.tableStyleId,
			})),
		];
	}, [defaultStyle, singleColumnFlag, tableStyleList, translate]);

	let placeholder = singleColumnFlag ? translate("global.default") : undefined;
	return (
		<Select
			defaultValue={singleColumnFlag ? null : defaultStyle ? 0 : -1}
			name={name}
			options={options || emptyArray}
			loadingFlag={!tableStyleList}
			placeholder={placeholder}
			{...props}
		/>
	);
};
FormSelectTableStyle.propTypes = propTypes;

export default FormSelectTableStyle;
