import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import Help from "@clearpoint/old-theme/Help";
import SeriesType from "@clearpoint/old-theme/Form/Field/SeriesType";
import { useTranslate } from "@clearpoint/translate";
import { useCheckFeature } from "@clearpoint/hooks";


let CalculationTab = () => {
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let aggregateSeriesFlag = checkFeature("aggregateSeries");
	let statusSeriesFlag = checkFeature("statusSeries");
	let showAlertFlag = !aggregateSeriesFlag && !statusSeriesFlag;

	return (
		<>
			<Help feature="calculated-series" />
			{showAlertFlag && <AlertUpsell feature="aggregateSeries" />}
			<SeriesType
				label={translate("edit.defaultSeries.calculationType")}
				placeholder={translate("edit.defaultSeries.selectSeriesCalcType")}
				name="seriesType"
				calculationName="calculation"
				seriesType="specific"
			/>
		</>
	);
};
export default CalculationTab;
