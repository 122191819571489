import FormFieldAnalysis from "@clearpoint/old-theme/Form/Field/Analysis";
import FormFieldDisplayAnalysis from "@clearpoint/old-theme/Form/Field/Display/Analysis";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let Analysis = ({ editPodFlag, name }) =>
	editPodFlag ? <FormFieldAnalysis name={name} /> : <FormFieldDisplayAnalysis name={name} />;

Analysis.propTypes = propTypes;
export default Analysis;
