import EditFieldsTab from "./EditFieldsTab";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import LinksTab from "./LinksTab";
import MilestonesTab from "./MilestonesTab";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import UpdateFieldsTab from "./UpdateFieldsTab";
import { useCallback, useMemo } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import { toast } from "@clearpoint/services/toastService/index";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";
import { useFormatDate } from "@clearpoint/hooks";

let propTypes = {
	aiAddMilestonesName: PropTypes.string,
	objectId: PropTypes.number,
	parentId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let EditMultipleMilestonesModal = ({ aiAddMilestonesName, parentId, scorecardId, objectIdList, ...props }) => {
	let formatDate = useFormatDate();
	let addFlag = !objectIdList;
	let { get } = useOldQueryStore();
	let translate = useTranslate();
	let { session } = useOldSession();
	let { periodId } = session;
	let customFieldData = get({ object: "customField", queryString: "?object=milestone" });
	let periodLockedFlag = useFormValue("updates.periodLocked");
	let period = useFormValue("updates.period");
	let defaultValue = useMemo(() => {
		if (customFieldData) {
			return {
				_names: aiAddMilestonesName ?? undefined,
				completedDate: formatDate(new Date(), "apiDateWithoutTimezone"),
				endDate: formatDate(lastDayOfMonth(new Date()), "apiDateWithoutTimezone"),
				initiativeId: parentId,
				scorecardId,
				startDate: formatDate(startOfMonth(new Date()), "apiDateWithoutTimezone"),
			};
		}
	}, [aiAddMilestonesName, customFieldData, formatDate, parentId, scorecardId]);
	let { setChecklist } = useChecklist() || emptyObject;
	let onSubmit = useCallback(
		(_value, apiResponse, _contextValue) => {
			if (addFlag) {
				toast.success(
					translate("toaster.messages.objects.multiObjectsAdded", {
						count: apiResponse.objects.length,
						objects: translate("milestones"),
					})
				);
			} else {
				setChecklist((checklist) => {
					checklist = cloneDeep(checklist);
					return checklist.map((x) => ({ ...x, checked: false }));
				});
				toast.success(
					translate("toaster.messages.objects.multiObjectsUpdated", {
						count: Math.max(apiResponse.edits.length, apiResponse.updates.length),
						objects: translate("milestones"),
					})
				);
			}
		},
		[addFlag, setChecklist, translate]
	);
	let updateFieldsTabTitle = useMemo(
		() =>
			periodLockedFlag ? (
				<>
					<Icon name="locked" size="tiny" marginRight={theme.smallSpace} />
					{`${period} ${translate("global.locked")}`}
				</>
			) : (
				translate("edit.element.updateFields")
			),
		[period, periodLockedFlag, translate]
	);
	return (
		<ModalWindowEdit
			color="desaturatedNormal"
			defaultValue={addFlag ? defaultValue : undefined}
			parent="initiative"
			object="milestone"
			onSubmit={onSubmit}
			parentId={parentId}
			objectIdList={objectIdList}
			scorecardId={scorecardId}
			size="medium"
			submitUnchangedFlag={!objectIdList}
			{...props}
			updateFlag
		>
			<ModalWindowHeader
				badgeIcon="milestones"
				badgeText={translate("milestones")}
				title={`${translate(addFlag ? "edit.element.addElement" : "global.editMultiple")} ${translate(
					"milestones"
				)}`}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					{!addFlag && (
						<MilestonesTab
							title={translate("milestones")}
							parentId={parentId}
							objectIdList={objectIdList}
							periodId={periodId}
						/>
					)}
					{addFlag && (
						<EditFieldsTab
							addFlag
							objectIdList={objectIdList}
							parentId={parentId}
							title={translate("milestones")}
						/>
					)}
					{addFlag && <LinksTab title={translate("edit.links")} />}
					{!addFlag && <UpdateFieldsTab title={updateFieldsTabTitle} periodLockedFlag={periodLockedFlag} />}
					{!addFlag && (
						<EditFieldsTab
							title={translate("edit.element.editFields")}
							objectIdList={objectIdList}
							parentId={parentId}
						/>
					)}
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter />
		</ModalWindowEdit>
	);
};
EditMultipleMilestonesModal.propTypes = propTypes;
export default EditMultipleMilestonesModal;
