import isArray from "lodash/isArray";
import { emptyArray } from "@clearpoint/utils";


let createFilter = (translate) => (keys) => {
	let checkIncludes = (value, search) => {
		search = search.toLowerCase();
		return value.toLowerCase().includes(search) || translate(value).toLowerCase().includes(search);
	};

	// PARAMS: Object: item, Array: propsToSearchList, String: search -> Boolean
	// checkObjectIncludes returns true if any values = item[prop] in propsToSearch on the item include the given search string.

	let checkObjectIncludes = (item, propsToSearchList, search) => {
		for (let prop of propsToSearchList) {
			let value = item[prop] ? item[prop] : "";
			if (checkIncludes(value, search)) return true;
		}
		return false;
	};

	let validChildList = []; // closure - this will initialize to empty every time we run a new search and be populated as we iterate
	return (item, value) => {
		let filterValue = value ? value.toString() : "";
		if (!isArray(keys)) keys = [keys];

		let matchFlag = checkObjectIncludes(item, keys, filterValue);

		if (keys.includes("parent")) {
			// if this is part of the validChildList (from a previous iterations match) then we want to include it and update matchFlag accordingly
			matchFlag = matchFlag || validChildList.includes(item.name.toLowerCase());

			// if there is no children on the element we don't need to perform any further operations
			let childList = item.children ? item.children.toLowerCase().split(",") : emptyArray;
			if (!childList.length) return matchFlag;

			// if we're looking at a valid parent that matches what we're searching log it's children to search later
			if (checkObjectIncludes(item, ["name"], filterValue)) {
				validChildList = [...validChildList, ...childList];
			}
		}

		return matchFlag;
	};
};

export default createFilter;
