import FormSelectStatus from "@clearpoint/old-theme/Form/Select/Select.Status";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	objectData: PropTypes.object,
	parentName: PropTypes.string,
	periodId: PropTypes.number,
	updateAccessFlag: PropTypes.bool,
};
let Status = ({ editCellFlag, objectData, parentName, periodId, updateAccessFlag }) => {
	let object = objectData?.object;
	let status = objectData?.status?.[periodId];
	let { statusId } = status || {};
	let automaticEvaluationFlag =
		status && ["evaluated", "automaticEvaluation", "autoEvaluation"].some((x) => status[x] || objectData[x]);
	let editStatusFlag = editCellFlag && updateAccessFlag && !automaticEvaluationFlag;
	return editStatusFlag ? (
		<FormSelectStatus
			data-testid="select-status"
			iconOnlyFlag
			marginBottom="0px"
			name={`${parentName}.status.${periodId}.statusId`}
			object={object}
		/>
	) : (
		<StatusIcon statusId={statusId} />
	);
};
Status.propTypes = propTypes;
export default Status;
