import HTML from "../../../HTML";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let LinkDisplay = ({ name }) => {
	let value = useFormValue(name);
	return <HTML>{value}</HTML>;
};

LinkDisplay.propTypes = propTypes;
export default LinkDisplay;
