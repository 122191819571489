import getQueryParameters from "./getQueryParameters";

function getName(object) {
	return object === "measureSeries"
		? "measureSeries"
		: object.endsWith("ies")
		? object.slice(0, -3) + "y"
		: object.endsWith("s")
		? object.slice(0, -1)
		: object;
}

export default function getIds({ url }) {
	if (url === null || url === undefined)
		return new Error("Bad url argument passed to getIds util: expected a string, got " + url);
	let ids = {};
	if (url.includes("?")) {
		let queryString = "?" + url.split("?")[1];
		let queryParameters = getQueryParameters(queryString);
		for (let key in queryParameters) {
			if (key.endsWith("Id")) {
				ids[key] = +queryParameters[key];
			}
		}
	}
	let re = /\/[^/]+\/\d+/g;
	let matches = url.match(re) || [];
	for (let match of matches) {
		let [key, value] = match?.slice(1).split("/");
		ids[getName(key) + "Id"] = +value;
	}
	for (let id in ids) {
		if (id.endsWith("LayoutId") && !ids.layoutId) {
			ids.layoutId = +ids[id];
		}
	}
	re = /\/[^/]+\/\d+$/g;
	let urlWithoutParameters = url.includes("?") ? url.slice(0, url.indexOf("?")) : url;
	let match = urlWithoutParameters.match(re);
	if (match) {
		ids.objectId = +match[0].slice(1).split("/")[1];
	}
	match = url?.split("?")[0].match(/.*[^a-z]+([a-z]+)/i);
	if (match) {
		ids.object = getName(match[1]);
	}
	return ids;
}
