let mergeCustomFieldList = (options, customFieldList, translate) => {
	if (customFieldList) {
		options = [
			...options,
			...customFieldList.map((x) => ({
				value: `custom${x.customFieldId}`,
				label: x.name,
				group: translate("global.customFields"),
				sortOrder: x.sortOrder,
				customFieldType: x.customFieldType,
			})),
		].sort((a, b) => a.sortOrder - b.sortOrder);
		options.forEach((x) => {
			delete x.sortOrder;
		});
	}
	return options;
};
export default mergeCustomFieldList;
