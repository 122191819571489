import { useSeriesGridData } from "./Provider";
import { useOldSession } from "@clearpoint/old-session/index";
import { useScorecardLockedFlag } from "@clearpoint/hooks";

let useGridLockedFlag = () => {
	let {
		session: { presentationMode: presentationModeFlag },
	} = useOldSession();
	let { access, scorecardId } = useSeriesGridData();
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);

	return presentationModeFlag || scorecardLockedFlag || access === "Browser";
};

export default useGridLockedFlag;
