import Alert from "./Alert/Alert";
import StyleWrapper from "./StyleWrapper";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import classNames from "classnames";
import { theme } from "./Theme";
import { emptyObject } from "@clearpoint/utils";
import { useBuildImagePath, useScopedHtmlDisplayStyles } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	className: PropTypes.string,
	skipStyleScopingFlag: PropTypes.bool,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let HTML = ({ children, className, skipStyleScopingFlag, style }) => {
	let buildImagePath = useBuildImagePath();
	let translate = useTranslate();
	let { exportFlag } = useOldSession()?.session || emptyObject;
	let invalidContentWarningFlag =
		!exportFlag && typeof children === "string" && children.includes("<!-- WARNING: INVALID HTML --->");
	let { containerStyleClass, htmlDisplayValue } = useScopedHtmlDisplayStyles(children) || emptyObject;
	htmlDisplayValue = skipStyleScopingFlag ? children : htmlDisplayValue || children;
	// remove scripts
	let html = useMemo(() => {
		if (typeof htmlDisplayValue !== "string") return { __html: "" };
		let div = document.createElement("div");
		div.innerHTML = htmlDisplayValue;
		let scriptList = div.getElementsByTagName("script");
		for (let script of scriptList) {
			script.remove();
		}
		let imageList = div.getElementsByTagName("img");
		for (let image of imageList) {
			let src = image.getAttribute("src");
			if (src) {
				let imagePath = buildImagePath(src);
				image.setAttribute("src", imagePath);
			}
		}

		return { __html: div.innerHTML };
	}, [buildImagePath, htmlDisplayValue]);

	return (
		<>
			{invalidContentWarningFlag && (
				<StyleWrapper marginBottom={theme.smallSpace}>
					<Alert color="danger">{translate("detailLayouts.contentWarning")}</Alert>
				</StyleWrapper>
			)}
			<span
				className={classNames(className, { [containerStyleClass]: !skipStyleScopingFlag })}
				style={style}
				dangerouslySetInnerHTML={html}
			/>
		</>
	);
};

HTML.propTypes = propTypes;

export default HTML;
