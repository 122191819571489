/* eslint-disable react/display-name */
/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import HeadingSmall from "@clearpoint/old-theme/Heading.Small";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess, useReportFilter, useScorecardLockedFlag, useStateObject } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import useDataProps from "./useDataProps";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import useTableStyle from "@components/Layouts/ViewLayout/ViewReport/useTableStyle";

let propTypes = {
	cell: PropTypes.shape({
		class: PropTypes.string,
		colSpan: PropTypes.number,
		columnIndex: PropTypes.number,
		fieldId: PropTypes.string,
		label: PropTypes.string,
		object: PropTypes.string,
		periodId: PropTypes.number,
		prefix: PropTypes.bool,
		readOnly: PropTypes.bool,
		rowIndex: PropTypes.number,
		rowSpan: PropTypes.number,
		scorecardId: PropTypes.number,
		showCellHeader: PropTypes.bool,
		tableStyleId: PropTypes.number,
		template: PropTypes.string,
		updateMode: PropTypes.bool,
	}),
	cellStyle: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	editAccessFlag: PropTypes.bool,
	editCellFlag: PropTypes.bool,
	editCellFlagName: PropTypes.string,
	hideDataFlag: PropTypes.bool,
	hint: PropTypes.string,
	lineHeight: PropTypes.string,
	objectData: PropTypes.object,
	onContextMenu: PropTypes.func,
};
let CellWrapper = React.forwardRef(
	(
		{
			cell,
			cellStyle,
			children,
			className,
			editAccessFlag,
			editCellFlag,
			editCellFlagName,
			hideDataFlag,
			hint,
			lineHeight,
			objectData,
			onContextMenu,
			updateAccessFlag,
		},
		forwardRef
	) => {
		let translate = useTranslate();
		let checkAccess = useCheckAccess();
		let { setFormValue } = useFormContext();
		let [{ highlightFlag }, setState] = useStateObject({ highlightFlag: false });
		let { exportFlag } = useOldSession().session;
		let { reportFilter } = useReportFilter();
		let ref = useRef();
		let applyStyleFlagRef = useRef(true);
		ref = forwardRef || ref;
		let {
			access,
			class: cellClassName,
			colSpan,
			columnIndex,
			fieldId,
			label,
			object,
			periodId,
			prefix: prefixFlag,
			readOnly: readOnlyFlag,
			rowIndex,
			rowSpan,
			scorecardId,
			showCellHeader: cellHeaderFlag,
			tableStyleId,
			template,
			updateMode: updateFlag,
		} = cell;
		if (fieldId === "attachments") updateFlag = true;
		let noInlineEditingFlag = ["scorecard", "chart", "gantt"].includes(template);
		let calculatedFlag = objectData?.calculated || fieldId?.startsWith("calculated");
		let periodLockedFlag = objectData?.status?.[periodId].periodLocked;
		let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
		let approvalsPending = objectData?.approvalsPending?.length > 0;
		let linkMilestoneFlag =
			object === "initiative" && objectData?.linkMilestoneDates === 1 && ["startDate", "endDate"].includes(fieldId);
		let linkChildMilestoneFlag =
			object === "milestone" &&
			objectData?.linkMilestoneDates === 1 &&
			objectData?.hasChildren &&
			["startDate", "endDate"].includes(fieldId);
		let { customStyleFlag, editTableFlag, setLocalState } = useLocalState();
		let { tableStyle, tableStyleObject } = useTableStyle({
			customStyleFlag,
			tableStyleId,
			cellStyle,
			cellFlag: true,
		});
		let cellSeriesPeriodData = useMemo(() => {
			if (cell?.fieldId?.slice(0, 10) !== "seriesData") return null;
			return objectData?.seriesData?.find((item) => item.periodId === cell.periodId);
		}, [cell, objectData]);
		cellStyle = tableStyleObject?.cellCss ? tableStyleObject?.cellCss : cellStyle;
		let editFlag =
		!approvalsPending &&
			!exportFlag &&
			!noInlineEditingFlag &&
			!readOnlyFlag &&
			!hideDataFlag &&
			!calculatedFlag &&
			!(updateFlag && periodLockedFlag) &&
			(updateFlag ? updateAccessFlag : editAccessFlag) &&
			!!objectData;
		let defaultTableStyle;
		if (editFlag) {
			defaultTableStyle = `
			&&&&&:hover {
				background-color: #ffffe5;
				color: ${theme.darkGray};
			}
		`;
			tableStyle += defaultTableStyle;
		}
		let edit = useCallback(() => {
			if (noInlineEditingFlag) {
				toast.warning(translate("toaster.messages.global.noInlineEditing"));
				return;
			}
			if (readOnlyFlag) {
				toast.warning(translate("toaster.messages.global.readOnlyField"));
				return;
			}
			if (approvalsPending) {
				toast.warning(translate("approvals.gridPending"));
				return;
			}
			if (hideDataFlag) {
				toast.warning(translate("toaster.messages.global.cellReportingFrequency"));
				return;
			}
			if (!!cellSeriesPeriodData && cellSeriesPeriodData?.displayPeriodId !== cell.periodId) {
				toast.warning(translate("toaster.messages.global.cellReportingFrequency"));
				return;
			}
			if (calculatedFlag) {
				toast.warning(translate("toaster.messages.global.cellCalculated"));
				return;
			}
			if (linkMilestoneFlag) {
				toast.warning(translate("edit.element.linkMilestoneWarning"));
				return;
			}
			if (linkChildMilestoneFlag) {
				toast.warning(translate("edit.element.linkChildMilestoneWarning"));
				return;
			}
			if (updateFlag && periodLockedFlag) {
				toast.warning(translate("toaster.messages.global.periodLocked"));
				return;
			}
			if (scorecardLockedFlag) {
				toast.warning(translate("toaster.messages.global.lockedScorecard"));
				return;
			}
			if (!updateFlag && !checkAccess({ access, action: "edit", scorecardId, toastFlag: true })) return;
			if (updateFlag && !checkAccess({ access, action: "update", scorecardId, toastFlag: true })) return;
			setFormValue(editCellFlagName, true);
			if (!editTableFlag) setLocalState({ editTableFlag: true });
		}, [access, approvalsPending, calculatedFlag, cell.periodId, cellSeriesPeriodData, checkAccess, editCellFlagName, editTableFlag, hideDataFlag, linkChildMilestoneFlag, linkMilestoneFlag, noInlineEditingFlag, periodLockedFlag, readOnlyFlag, scorecardId, scorecardLockedFlag, setFormValue, setLocalState, translate, updateFlag]);
		useEffect(() => {
			if (!editTableFlag && !exportFlag && editCellFlag) {
				setFormValue(editCellFlagName, false);
			}
		}, [editCellFlag, editCellFlagName, editTableFlag, exportFlag, setFormValue]);
		useEffect(() => {
			let includesFilterValueFlag =
				reportFilter && ref.current.innerText?.toLowerCase().includes(reportFilter?.toLowerCase());
			if (includesFilterValueFlag && !highlightFlag) {
				setState({ highlightFlag: true });
			} else if (!includesFilterValueFlag && highlightFlag) {
				setState({ highlightFlag: false });
			}
		}, [cell, reportFilter, highlightFlag, setState]);
		let dataProps = useDataProps({ cell, objectData, columnIndex });
		useEffect(() => {
			if (exportFlag && ref.current && cellStyle && applyStyleFlagRef.current && tableStyleId) {
				// this should only be applied to exports or it will break hover
				ref.current.setAttribute("style", cellStyle);
				applyStyleFlagRef.current = false;
			}
		});
		let allowToolTipFlag = editFlag && !editTableFlag;
		return (
			<StyleWrapper
				$style={tableStyleId ? tableStyle : defaultTableStyle}
				backgroundColor={highlightFlag ? `${theme.highlightYellow2} !important` : undefined}
				lineHeight={lineHeight}
				wordBreak={exportFlag ? undefined : "normal"}
				overflow="hidden"
			>
				<td
					data-testid="cell"
					className={classNames(
						colSpan ? "full-span" : "",
						cellClassName,
						className,
						"cps-td",
						"cps-td-" + (!rowSpan || rowSpan === 1 ? rowIndex : "")
					)}
					colSpan={colSpan}
					{...dataProps}
					onContextMenu={exportFlag ? undefined : onContextMenu}
					onDoubleClick={!objectData || exportFlag ? undefined : edit}
					ref={ref}
					rowSpan={rowSpan}
					title={allowToolTipFlag ? translate("edit.doubleClick") + (hint ? `\n${hint}` : "") : undefined}
				>
					<Block height="100%">
						{cellHeaderFlag && (
							<HeadingSmall>
								{prefixFlag && <span>{object} </span>}
								{translate(label)}
							</HeadingSmall>
						)}
						<Block>{children}</Block>
					</Block>
				</td>
			</StyleWrapper>
		);
	}
);
CellWrapper.propTypes = propTypes;
export default CellWrapper;
