import Button from "../../Button/Button";
import CSSEditor from "./CSS/CSSEditor";
import Modal from "../../Modal/Modal";
import ModalBody from "../../Modal/Modal.Body";
import ModalFooter from "../../Modal/Modal.Footer";
import HTMLEditor from "./HTML/HTMLEditor";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func,
	editorRef: PropTypes.object,
	handleEditorToggle: PropTypes.func,
	htmlFlag: PropTypes.bool,
	modalVisible: PropTypes.bool,
	name: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	tinyCssValue: PropTypes.object,
};

let CodeMirrorModal = ({ close, editorRef, handleEditorToggle, htmlFlag, modalVisible, name, id, tinyCssValue }) => {
	let translate = useTranslate();
	let handleClick = useCallback(() => {
		handleEditorToggle(editorRef.current);
	}, [editorRef, handleEditorToggle]);
	return modalVisible ? (
		<Modal modalVisible={modalVisible} close={close} size="large" height="60vh">
			<ModalBody>
				{!htmlFlag ? (
					<CSSEditor
						name={`_style${name}`}
						styleClass={`tiny-mce-react-${id}`}
						redactorFlag={false}
						tinyCssValue={tinyCssValue}
					/>
				) : (
					<HTMLEditor name={name} />
				)}
			</ModalBody>
			<ModalFooter>
				<Button onClick={handleClick}>{translate("global.close")}</Button>
			</ModalFooter>
		</Modal>
	) : null;
};

CodeMirrorModal.propTypes = propTypes;

export default CodeMirrorModal;
