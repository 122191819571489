import { useEffect } from "react";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";

let useDisableLoadingModalSave = (loadingFlag) => {
	let { setFormError } = useFormContext();
	return useEffect(() => {
		if (loadingFlag !== undefined && loadingFlag) {
			setFormError("fieldListLoadingError", true);
		} else {
			setFormError("fieldListLoadingError", undefined);
		}
	}, [loadingFlag, setFormError]);
};

export default useDisableLoadingModalSave;
