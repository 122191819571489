import { useCallback, useEffect } from "react";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import cloneDeep from "lodash/cloneDeep";

let usePlaceElementOnMap = ({ displayWidth, displayHeight }) => {
	let { setFormValue } = useFormContext();
	let { data: filteredElementList } = useFilter();
	let formElementList = useFormValue("elements");
	if (!formElementList) formElementList = [];
	let placeElementOnMap = useCallback(() => {
		// dropped in map?
		let droppedElement = document.querySelector(".smooth-dnd-ghost");
		if (droppedElement) {
			let { x, y } = droppedElement.getBoundingClientRect();
			let { x: backgroundX, y: backgroundY } = document
				.querySelector(".map-background-image-edit")
				.getBoundingClientRect();
			backgroundX += document.body.scrollLeft;
			backgroundY += document.body.scrollTop;
			let left = x - backgroundX;
			let top = y - backgroundY;
			let droppedInMapFlag = left > 0 && left < displayWidth && top > 0 && top < displayHeight;

			// currently in map?
			let mapElement = droppedElement.querySelector(".map-element");
			let object = mapElement.getAttribute("data-object");
			let objectId = +mapElement.getAttribute("data-object-id");
			let formElement = formElementList.find((x) => x.object === object && x.objectId === objectId);
			let elementInMapFlag = !!formElement;
			if (droppedInMapFlag) {
				let xPos = Math.round((left / displayWidth) * 1024);
				let yPos = Math.round((top / displayHeight) * 768);
				if (elementInMapFlag) {
					// reposition
					setFormValue(`elements[${formElementList.indexOf(formElement)}]`, {
						...formElement,
						xPos,
						yPos,
					});
				} else {
					// add to map
					let element = filteredElementList.find((x) => x.object === object && x.objectId === objectId);
					let { name } = element;
					setFormValue("elements", [
						...cloneDeep(formElementList),
						{
							name,
							object,
							objectId,
							xPos,
							yPos,
						},
					]);
				}
			}
			droppedElement.style.opacity = 0;
		}
		let draggableElementList = document.querySelectorAll(".smooth-dnd-draggable-wrapper");
		for (let element of draggableElementList) {
			element.classList.add("dropped");
			setTimeout(() => {
				element.classList.remove("dropped");
			}, 100);
		}
	}, [displayHeight, displayWidth, filteredElementList, formElementList, setFormValue]);
	useEffect(() => {
		document.addEventListener("mouseup", placeElementOnMap);
		return () => document.removeEventListener("mouseup", placeElementOnMap);
	});
};

export default usePlaceElementOnMap;
