import Loading from "@clearpoint/old-theme/Loading/index";
import Row from "./Row";
import Table from "@clearpoint/old-theme/Table";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let SeriesData = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { object, objectId, shadowMeasureId } = useFormValue();
	let { pagePeriodId } = useLocalState();
	let measureId = object === "measure" ? objectId : shadowMeasureId;

	let seriesList =
		measureId &&
		pagePeriodId &&
		get({ parent: "measure", parentId: measureId, object: "measureSeries", periodId: pagePeriodId });

	return seriesList ? (
		<Table cpsSubTableFlag borderedFlag>
			<thead>
				<tr>
					<th>{translate("global.series")}</th>
					<th>{translate("global.value")}</th>
				</tr>
			</thead>
			<tbody>
				{seriesList
					.filter((series) => !series.hiddenSeries)
					.map((series) => (
						<Row key={series.measureSeriesId} series={series} />
					))}
			</tbody>
		</Table>
	) : (
		<Loading />
	);
};

export default SeriesData;
