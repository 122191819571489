import PropTypes from "prop-types";

import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FormCheckArray from "@clearpoint/old-theme/Form/CheckArray/CheckArray";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import Help from "@clearpoint/old-theme/Help";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import ScorecardListItem from "./ScorecardListItem";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";

let standardReportPropTypes = {
  object: PropTypes.string,
};

let StandardReport = ({ object }) => {
  let translate = useTranslate();
  let { data: filteredScorecardList } = useFilter();
  return (
    <>
      <Help section="sect5" feature="report-select-elements" />
      <FilterInput
        placeholder={translate("edit.filterElements", { InsertText: "scorecards" }) + "..."}
        name="scorecardName"
        filterBy="name"
      />
      <FormGroup title={translate("tabGridElements.selectScorecards")}>
        <FormCheckArray name="scorecardIdList" stringFlag required>
          {filteredScorecardList
            .filter((x) => x.visible)
            .map((x) => (
              <ScorecardListItem key={x.scorecardId} scorecard={x} object={object} />
            ))}
        </FormCheckArray>
      </FormGroup>
    </>
  );
};

StandardReport.propTypes = standardReportPropTypes;

let elementsTabPropTypes = {
  object: PropTypes.string,
};

let ElementsTab = ({ object }) => {
  let { get } = useOldQueryStore();
  let scorecardList = get({ object: "scorecard" });
  return (
    <FilterProvider data={scorecardList}>
      <StandardReport object={object} />
    </FilterProvider>
  );
};

ElementsTab.propTypes = elementsTabPropTypes;

export default ElementsTab;
