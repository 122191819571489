import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let ShadowObjectType = (props) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let options = get({ object: "options" });
	let shadowObjectTypeOptions = useMemo(() => {
		if (options === undefined) return;
		let evaluationObjectList = options?.options?.evaluations?.map((x) => x.object) || [];
		evaluationObjectList.unshift("measure");
		return evaluationObjectList.map((object) => ({
			label: translate(object),
			value: object,
		}));
	}, [options, translate]);
	return <FormSelect options={shadowObjectTypeOptions} {...props} />;
};

export default ShadowObjectType;
