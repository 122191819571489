import ImageCircle from "./Image.Circle";
import StyleWrapper from "./StyleWrapper";
import { useMemo, memo } from "react";
import PropTypes from "prop-types";
import { useBuildImagePath } from "@clearpoint/hooks";


let propTypes = {
	alt: PropTypes.string.isRequired,
	"data-testid": PropTypes.string,
	documentSrc: PropTypes.string,
	height: PropTypes.string,
	margin: PropTypes.string,
	maxHeight: PropTypes.string,
	maxWidth: PropTypes.string,
	objectFit: PropTypes.string,
	objectPosition: PropTypes.string,
	onLoad: PropTypes.func,
	src: PropTypes.string,
	style: PropTypes.object,
	title: PropTypes.string,
	width: PropTypes.string,
};

let Image = ({
	alt,
	className,
	"data-testid": dataTestId,
	documentSrc,
	height,
	margin,
	maxHeight,
	maxWidth,
	objectFit,
	objectPosition,
	onLoad,
	onMouseOver,
	onMouseOut,
	src,
	style,
	title,
	width,
}) => {
	let buildImagePath = useBuildImagePath();
	let imagePath = useMemo(() => (documentSrc ? buildImagePath(documentSrc) : src), [documentSrc, buildImagePath, src]);
	return (
		<StyleWrapper
			height={height}
			maxHeight={maxHeight}
			maxWidth={maxWidth}
			margin={margin}
			objectFit={objectFit}
			objectPosition={objectPosition}
			width={width}
		>
			<img
				data-testid={dataTestId}
				className={className}
				alt={alt}
				src={imagePath}
				onLoad={onLoad}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				style={style}
				title={title}
			/>
		</StyleWrapper>
	);
};

Image.propTypes = propTypes;
Image.Circle = ImageCircle;

export default memo(Image);
