import FormSelectAvailablePeriod from "../../Form/Select/Select.AvailablePeriod";
import FormSelectPeriodGroup from "../../Form/Select/Select.PeriodGroup";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import useWatchChartPeriods from "../../Form/hooks/useWatchChartPeriods";
import PropTypes from "prop-types";

let propTypes = {
	compactFlag: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	parentName: PropTypes.string,
};
let FormFieldChartPeriodGroupId = ({ compactFlag, label, name, parentName, ...props }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let specificPeriodFlag = value === 0;
	useWatchChartPeriods(parentName);

	return (
		<>
			<FormSelectPeriodGroup data-testid="periodGroupId" label={label} name={name} {...props} />
			{specificPeriodFlag && (
				<FormSelectAvailablePeriod
					compactFlag={compactFlag}
					multipleFlag
					label={translate("chart.chartPeriods")}
					parentName={parentName}
					name="_displayPeriods"
				/>
			)}
		</>
	);
};
export default FormFieldChartPeriodGroupId;
FormFieldChartPeriodGroupId.propTypes = propTypes;
