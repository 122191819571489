import Column from "@clearpoint/old-theme/Column";
import FormSelect from "@clearpoint/old-theme/Form/Select/FormSelect";
import FormSelectCurrency from "@clearpoint/old-theme/Form/Select/Select.Currency";
import Row from "@clearpoint/old-theme/Row";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	keyResultNameInForm: PropTypes.string,
	name: PropTypes.string,
};

let DataTypeSelect = ({ keyResultNameInForm, name, ...props }) => {
	let translate = useTranslate();

	let dataType = useFormValue(name);
	let currencyTypeFlag = dataType === 4;

	let options = useMemo(
		() => [
			{ label: translate("goals.keyResults.dataType.percent"), value: 6 },
			{ label: translate("goals.keyResults.dataType.currency"), value: 4 },
			{ label: translate("goals.keyResults.dataType.number"), value: 5 },
		],
		[translate]
	);

	return (
		<Row>
			<Column tiny={currencyTypeFlag ? 6 : 12}>
				<FormSelect label={translate("goals.keyResults.dataType")} name={name} options={options} {...props} />
			</Column>
			{currencyTypeFlag && (
				<Column tiny={6}>
					<FormSelectCurrency
						label={translate("logFieldLabels.currency")}
						name={`${keyResultNameInForm}.currencyCode`}
					/>
				</Column>
			)}
		</Row>
	);
};

DataTypeSelect.propTypes = propTypes;
export default DataTypeSelect;
