import { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";

import { noop } from "@clearpoint/utils";

import Check from "../Check/Check";
import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";
import { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	filter: PropTypes.func,
	initialChecked: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	text: PropTypes.string,
};

let FilterCheck = ({
	className,
	"data-testid": dataTestId,
	filter: customFilter,
	initialChecked,
	name,
	onChange,
	text,
}) => {
	let [checked, setChecked] = useState(initialChecked);
	let { filter = noop } = useFilter() || {};
	useLayoutEffect(() => {
		filter({ [name]: (x) => customFilter(x, checked) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let onClick = (e) => {
		setChecked(e.target.checked);
		if (onChange) onChange(e.target.checked);
		filter({ [name]: (x) => customFilter(x, e.target.checked) });
	};
	return (
		<StyleWrapper backgroundColor={theme.white} border={`1px solid ${theme.gray}`}>
			<Check
				data-testid={dataTestId || "filter-check"}
				onClick={onClick}
				checked={checked}
				text={text}
				className={className}
			/>
		</StyleWrapper>
	);
};

FilterCheck.propTypes = propTypes;

export default FilterCheck;
