import Badge from "@clearpoint/old-theme/Badge/Badge";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Hide from "@clearpoint/old-theme/Hide";

import { useDescribeTimePassed } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import { usePodContext } from "../PodProvider";


let LastCalculatedBadge = () => {
	let translate = useTranslate();
	let lastCalculated = useFormValue("lastCalculated");
	let describeTimePassed = useDescribeTimePassed();
	let { podWidthDisplayClass } = usePodContext();
	let visible = !!lastCalculated && podWidthDisplayClass !== "tiny" && podWidthDisplayClass !== "small";
	return (
		<Hide visible={visible}>
			<Badge color="info" size="podHeader">
				{`${translate("components.lastRecalc")} ${describeTimePassed(lastCalculated)}`}
			</Badge>
		</Hide>
	);
};

export default LastCalculatedBadge;
