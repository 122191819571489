import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import ModalConfirmOnClick from "@clearpoint/old-theme/Modal/Modal.Confirm.OnClick";
import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePusherContext } from "@clearpoint/providers/Pusher/PusherProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

let RecalculateMeasureDropdownItem = () => {
	let translate = useTranslate();
	let { set } = useOldQueryStore();
	let { object, objectId, shadowMeasureId } = useFormValue();
	let { setLocalState } = useLocalState();
	let { addPusherEffect, removePusherEffect } = usePusherContext();

	let measureId = object === "measure" ? objectId : shadowMeasureId;
	let pusherEffectKey = `measure${measureId}Recalculated`;

	let notifyBackgroundCalculationsComplete = useCallback(
		(incomingPusherMessage) => {
			if (!incomingPusherMessage) return;
			let { action, activity, objectId } = incomingPusherMessage;
			if (action === "recalc" && objectId === measureId && activity?.startsWith("Background calculations")) {
				toast.success(activity);
				removePusherEffect(pusherEffectKey);
			}
		},
		[measureId, pusherEffectKey, removePusherEffect]
	);

	let notifyRecalculationComplete = useCallback(
		(incomingPusherMessage) => {
			if (!incomingPusherMessage) return;
			let { action, activity, objectId } = incomingPusherMessage;
			if (
				action === "recalc" &&
				objectId === measureId &&
				activity?.startsWith("Recalculation for this element is complete")
			) {
				toast.success(activity);
				setLocalState({ recalcPendingFlag: false });
				addPusherEffect({
					key: pusherEffectKey, // overwrites existing notifyRecalculationComplete effect
					effect: notifyBackgroundCalculationsComplete,
				});
			}
		},
		[addPusherEffect, measureId, notifyBackgroundCalculationsComplete, pusherEffectKey, setLocalState]
	);

	let recalculate = useCallback(() => {
		set({ parent: "measure", parentId: measureId, object: "recalc", data: { measureId } }).then(() => {
			addPusherEffect({
				key: pusherEffectKey,
				effect: notifyRecalculationComplete,
			});
			setLocalState({ recalcPendingFlag: true });
		});
	}, [addPusherEffect, measureId, notifyRecalculationComplete, pusherEffectKey, set, setLocalState]);

	return (
		<ModalConfirmOnClick
			body={translate("button.confirmRecalculateObject", { element: translate(object).toLowerCase() })}
			confirmButtonText={translate("button.recalculate")}
			onConfirm={recalculate}
			title={translate("button.recalculateMeasure", { measure: object })}
		>
			<DropdownItem icon="recalculateMeasure">
				{translate("button.recalculateMeasure", { measure: object })}
			</DropdownItem>
		</ModalConfirmOnClick>
	);
};

export default RecalculateMeasureDropdownItem;
