import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useQueryParameters } from "@clearpoint/hooks";


let useCreateLinkElement = () => {
	let { get } = useOldQueryStore();
	let { object, objectId, periodId } = useQueryParameters();
	let { element, pagePeriodId } = useLocalState() || {};
	object = object || element?.object;
	objectId = objectId || element?.objectId;
	periodId = pagePeriodId || periodId;

	let elementDetail = get({
		object,
		objectId,
		periodId,
	});

	let linkElementList = useMemo(
		() => [
			{
				...elementDetail,
				fieldId: object + "s",
			},
		],
		[elementDetail, object]
	);

	return linkElementList;
};

export default useCreateLinkElement;
