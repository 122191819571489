import { useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { emptyObject } from "@clearpoint/utils";


// Calls callback function only when a watched value changes
let useDebounce = (callback, dependencies, options) => {
	let wait = options?.wait || 250;

	let debouncedFunctionRef = useRef(debounce(callback, wait, { maxWait: 1000, ...options }));

	useEffect(() => {
		let { current: debouncedFunction } = debouncedFunctionRef || emptyObject;
		if (!debouncedFunction) return;

		debouncedFunction(...dependencies);
		return () => debouncedFunction.cancel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies, debouncedFunctionRef]);
};

export default useDebounce;
