import FormButton from "@clearpoint/old-theme/Form/Button/Button";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalEdit from "@clearpoint/old-theme/Modal/Modal.Edit";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import SelectReferenceLayout from "./Select.ReferenceLayout";
import SelectReferenceLayoutObjectType from "./Select.ReferenceLayoutObjectType";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func,
};

let EditReferenceLayoutModalContent = ({ close }) => {
	let translate = useTranslate();
	return (
		<>
			<ModalHeader>
				<ModalTitle>{translate("layout.editLayoutReference")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<FormFieldName name="name" label={translate("layout.reportName")} />
				<SelectReferenceLayoutObjectType
					name="_objectType"
					label={translate("options.selectElement")}
					placeholder={translate("options.selectElement") + "..."}
				/>
				<SelectReferenceLayout
					name="referenceLayoutId"
					label={translate("global.reportReference")}
					placeholder={translate("edit.selectElement", { element: "global.reportReference" })}
				/>
			</ModalBody>
			<ModalFooter>
				<FormButton onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</FormButton>
				<FormButton color="primary">{translate("global.save")}</FormButton>
			</ModalFooter>
		</>
	);
};
EditReferenceLayoutModalContent.propTypes = propTypes;

let editDefaultLayoutModalPropTypes = {
	layoutId: PropTypes.number,
};
let EditReferenceLayoutModal = ({ layoutId, ...props }) => {
	let { session } = useOldSession();
	let { periodId, scorecardId } = session;
	return (
		<ModalEdit
			{...props}
			size="medium"
			object="scorecardLayout"
			objectId={layoutId}
			periodId={periodId}
			scorecardId={scorecardId}
		>
			<EditReferenceLayoutModalContent close={props.close} />
		</ModalEdit>
	);
};
EditReferenceLayoutModal.propTypes = editDefaultLayoutModalPropTypes;
export default EditReferenceLayoutModal;
