import FormGroup from "../../../Form/Form.Group";
import FormSelectObject from "../../../Form/Select/Select.Object";
import FormSelectObjectType from "../../../Form/Select/Select.ObjectType";
import Help from "../../../Help";
import SelectCurrentOrSpecificElement from "../../../Form/Select/Select.CurrentOrSpecificElement";
import SelectInitiative from "../../../Form/Select/Select.Initiative";
import SelectReferenceSeries from "../../../Form/Select/Select.ReferenceSeries";
import SelectScorecard from "../../../Form/Select/Select.Scorecard";
import { useCallback, useMemo, useRef } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getObjectId from "./InputReferenceSeries.getObjectId";
import { useInitiativeId, useMemoOnce, useScorecardId } from "@clearpoint/hooks";

let propTypes = {
	name: PropTypes.string,
	parent: PropTypes.string,
	parentId: PropTypes.number,
};

let InputReferenceSeries = ({ name, parent, parentId }) => {
	let { session } = useOldSession();
	let translate = useTranslate();
	let {
		_referenceElement: referenceElement,
		_referenceScorecardId: referenceScorecardId,
		_referenceInitiativeId: referenceInitiativeId,
	} = useFormValue();

	let { referenceObjectIdNameInForm, referenceObjectNameInForm, referenceFieldIdNameInForm } = useMemo(() => {
		let lookup = {
			referenceObjectIdNameInForm: "referenceObjectId",
			referenceObjectNameInForm: "referenceObject",
			referenceFieldIdNameInForm: "referenceFieldId",
		};

		if (name) {
			Object.keys(lookup).forEach((key) => {
				lookup[key] = `${name}.${lookup[key]}`;
			});
		}

		return lookup;
	}, [name]);

	let referenceObject = useFormValue(referenceObjectNameInForm);
	let referenceObjectId = useFormValue(referenceObjectIdNameInForm);
	let object = referenceObject || parent;
	let objectId = getObjectId({ object, parent, parentId, referenceObjectId });

	let milestoneFlag = object === "milestone";
	let milestoneId = !referenceInitiativeId && milestoneFlag && objectId;
	let defaultInitiativeId = useInitiativeId({ milestoneId });

	let currentScorecardId = useScorecardId({ object, objectId });
	let defaultScorecardFlag = useMemoOnce(() => object && objectId);
	let defaultScorecardId = useRef();
	if (defaultScorecardFlag && !defaultScorecardId.current && currentScorecardId)
		defaultScorecardId.current = currentScorecardId;

	let defaultReferenceElement = useMemoOnce(() => {
		if (!referenceElement) {
			return !referenceObjectId || referenceObjectId === parentId ? "current" : "specific";
		}
	});

	let { setFormValue } = useFormContext();
	let clearObjectId = useCallback(() => {
		setFormValue(referenceObjectIdNameInForm, undefined);
	}, [referenceObjectIdNameInForm, setFormValue]);
	let setCurrentWhenCurrent = useCallback(
		(value) => {
			if (value?.value === "current") {
				setFormValue(referenceObjectNameInForm, "");
				setFormValue(referenceObjectIdNameInForm, 0);
			}
		},
		[referenceObjectIdNameInForm, referenceObjectNameInForm, setFormValue]
	);

	return (
		<FormGroup title={translate("edit.series.referenceSeries")}>
			<Help feature="reference-series" />
			<SelectCurrentOrSpecificElement
				label={translate("global.element")}
				name="_referenceElement"
				onChange={setCurrentWhenCurrent}
				placeholder={translate("global.element")}
				defaultValue={defaultReferenceElement}
			/>
			{referenceElement === "specific" && (
				<>
					<SelectScorecard
						label={translate("edit.selectScorecard")}
						name="_referenceScorecardId"
						defaultValue={defaultScorecardId.current || session.scorecardId}
					/>
					<FormSelectObjectType
						label={translate("global.elementType")}
						name={referenceObjectNameInForm}
						placeholder={translate("options.selectElement") + "..."}
						onChange={clearObjectId}
						milestoneFlag
						required
					/>
					{milestoneFlag && (
						<SelectInitiative
							label={translate("objects.initiative")}
							name="_referenceInitiativeId"
							scorecardId={referenceScorecardId}
							defaultValue={defaultInitiativeId}
							onChange={clearObjectId}
							required
						/>
					)}
					{(milestoneFlag ? referenceInitiativeId : object) && (
						<FormSelectObject
							label={translate(milestoneFlag ? "objects.milestone" : "global.element")}
							name={referenceObjectIdNameInForm}
							parent={milestoneFlag ? "initiative" : undefined}
							parentId={referenceInitiativeId}
							scorecardId={referenceScorecardId}
							object={object}
							required
						/>
					)}
				</>
			)}
			{(referenceElement === "current" || objectId) && (
				<SelectReferenceSeries
					label={translate("edit.series.referenceField")}
					name={referenceFieldIdNameInForm}
					parent={object}
					parentId={objectId}
				/>
			)}
		</FormGroup>
	);
};

InputReferenceSeries.propTypes = propTypes;

export default InputReferenceSeries;
