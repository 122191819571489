import React from "react";

let getToast = (toastify, SuspenseToast) => {
	return {
		// to prevent multiple toasts, pass a toast id (message, {toastId: message})
		success: (message, options = {}) => {
			if (!options.toastId) options.toastId = message;
			return toastify.success(<SuspenseToast message={message} icon={options.icon} />, {
				...options,
				icon: false,
				className: "toast-success",
			});
		},
		info: (message, options = {}) => {
			if (!options.toastId) options.toastId = message;
			return toastify.success(<SuspenseToast message={message} icon={options.icon} />, {
				...options,
				icon: false,
				className: "toast-info",
			});
		},
		error: (message, options = {}) => {
			if (!options.toastId) options.toastId = message;
			return toastify.error(<SuspenseToast message={message} icon={options.icon} />, {
				...options,
				icon: false,
				className: "toast-error",
			});
		},
		warning: (message, options = {}) => {
			if (!options.toastId) options.toastId = message;
			return toastify.warning(<SuspenseToast message={message} icon={options.icon} />, {
				...options,
				icon: false,
				className: "toast-warning",
			});
		},
		dismiss: toastify.dismiss,
	};
};

export default getToast;
