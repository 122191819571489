import SelectStatus from "../../Form/Select/Select.Status";
import useStringToIntegerFormValue from "../../Form/hooks/useStringToIntegerFormValue";
import PropTypes from "prop-types";

let propTypes = {
	fieldId: PropTypes.string,
	name: PropTypes.string,
};
let StatusIcon = ({ fieldId, name, ...props }) => {
	useStringToIntegerFormValue(name);
	return (
		<SelectStatus
			customField={fieldId.startsWith("custom") ? fieldId : undefined}
			data-testid="statusIcon"
			name={name}
			{...props}
		/>
	);
};
StatusIcon.propTypes = propTypes;
export default StatusIcon;
