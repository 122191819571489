let groupTabsByTabGroup = (childList) => {
	let groupedTabList = [];
	let currentTabGroupMemberTitle = null;

	childList.forEach((element, index) => {
		let { tabGroup: tabGroupTitle } = element.props;

		if (tabGroupTitle) {
			if (tabGroupTitle !== currentTabGroupMemberTitle) {
				currentTabGroupMemberTitle = tabGroupTitle;
				let tabIndex = groupedTabList.findIndex((tab) => tab.props.title === currentTabGroupMemberTitle);
				let currentGroupTab = groupedTabList[tabIndex];

				if (tabIndex === -1) {
					groupedTabList.push({
						title: currentTabGroupMemberTitle,
						tabGroup: tabGroupTitle,
						tabGroupMemberList: [{ ...element, tabIndex: index }],
						...element,
					});
				} else {
					currentGroupTab.tabGroupMemberList.push({ ...element, tabIndex: index });
				}
			} else {
				let currentGroupTab = groupedTabList[groupedTabList.length - 1];
				currentGroupTab.tabGroupMemberList.push({ ...element, tabIndex: index });
			}
		} else {
			groupedTabList.push({ ...element, tabIndex: index });
		}
	});

	return groupedTabList;
};

export default groupTabsByTabGroup;
