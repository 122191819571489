import ObjectiveEvaluations from "./ObjectiveEvaluations";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";

let EvaluationTab = () => {
	let translate = useTranslate();
	let object = useFormValue("object");
	return (
		<ObjectiveEvaluations
			name="evaluationCriteria"
			label={translate("edit.evaluationCriteria")}
			seriesType={object}
		/>
	);
};
export default EvaluationTab;
