import { formatSeriesNumber } from "../utils";

let describeTransposed = ({ chartData, series, translate }) => {
	let description = "";
	let measureSeriesList = chartData.chartFormat.series;
	let i = 0;
	for (let measureSeries of measureSeriesList) {
		let { name, numberFormat } = measureSeries;
		description += `\n${name}\n`;
		for (let period of series) {
			let { data, name: periodName } = period;
			let value = data[i];
			description += `${periodName}: ${formatSeriesNumber(value, numberFormat, translate)}\t`;
		}
		description = description.slice(0, -1);
		i++;
	}
	return description;
};
export default describeTransposed;
