import Alert from "@clearpoint/old-theme/Alert/Alert";
import Button from "@clearpoint/old-theme/Button/Button";
import FormButton from "@clearpoint/old-theme/Form/Button/Button";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormLabel from "@clearpoint/old-theme/Form/Form.Label";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let checkboxPropTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
};

let Checkbox = ({ label, name }) => (
	<FormCheck name={name} marginBottom={theme.tinySpace} labelWeight="bold">
		{label}
	</FormCheck>
);

let propTypes = {
	close: PropTypes.func,
};

let CopyForwardAllModalContent = ({ close }) => {
	let translate = useTranslate();
	let { copyStatus, copyPercent, copyAnalysis, copyRecommendations, copyCustom } = useFormValue();

	let disabled = ![copyStatus, copyPercent, copyAnalysis, copyRecommendations, copyCustom].some((x) => x);

	return (
		<>
			<ModalHeader>
				<ModalTitle>{translate("edit.milestoneCopyForward")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Alert color="success">{translate("edit.milestoneCopyForwardHint")}</Alert>
				<FormLabel marginBottom={theme.smallSpace}>{`${translate("button.copyForward")} ${translate(
					"milestone"
				)}`}</FormLabel>
				<Checkbox label={translate("global.status")} name="copyStatus" />
				<Checkbox label={translate("edit.percentComplete")} name="copyPercent" />
				<Checkbox label={translate("edit.analysis")} name="copyAnalysis" />
				<Checkbox label={translate("edit.recommendations")} name="copyRecommendations" />
				<Checkbox label={translate("options.customFields.title")} name="copyCustom" />
			</ModalBody>
			<ModalFooter>
				<Button onClick={close} color="default">
					{translate("global.cancel")}
				</Button>
				<FormButton disabled={disabled}>{translate("button.copyForward")}</FormButton>
			</ModalFooter>
		</>
	);
};

Checkbox.propTypes = checkboxPropTypes;
CopyForwardAllModalContent.propTypes = propTypes;
export default CopyForwardAllModalContent;
