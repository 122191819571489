import JSON5 from "json5";

let isNotValidJson5 = (json) => {
	if (json?.length > 0) {
		try {
			JSON5.parse(json);
			return false;
		} catch (e) {
			return true;
		}
	}
	return true;
};
export default isNotValidJson5;
