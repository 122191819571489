import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";

let propTypes = {
	object: PropTypes.string,
	scorecardId: PropTypes.number,
};

let SelectShadowMeasure = ({ object, scorecardId, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let shadowMeasureList = !scorecardId
		? null
		: get({
				url: `/scorecards/${scorecardId}/measures?includeShadow=true`,
		  });
	let options = useMemo(() => {
		if (shadowMeasureList === undefined) return;
		if (!isArray(shadowMeasureList)) return [];
		return shadowMeasureList
			.filter((x) => x.active && (object === "measure" ? !x.shadowObject : x.shadowObject === object))
			.map((x) => {
				return {
					label: x.name,
					value: x.objectId,
				};
			});
	}, [object, shadowMeasureList]);
	return (
		<Select
			placeholder={translate("edit.selectElement", { element: object })}
			options={options || []}
			loadingFlag={!shadowMeasureList}
			noOptionsMessage={translate("select.noElementsFound")}
			{...props}
		/>
	);
};

SelectShadowMeasure.propTypes = propTypes;

export default SelectShadowMeasure;
