import { getEnvironment } from "@clearpoint/utils";

function bootFullstory(profile, options) {
	// From https://app.fullstory.com/ui/KK70C/settings/general (requires login)
	// See also: https://help.fullstory.com/hc/en-us/articles/360020624694-What-if-the-identifier-FS-is-used-by-another-script-on-my-site-
	var fsScript =
		"window['_fs_debug'] = false;window['_fs_host'] = 'fullstory.com';window['_fs_script'] = 'edge.fullstory.com/s/fs.js';window['_fs_org'] = 'KK70C';window['_fs_namespace'] = 'FullStory';(function(m,n,e,t,l,o,g,y){if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window[\"_fs_namespace\"].');} return;}g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};g.shutdown=function(){g(\"rec\",!1)};g.restart=function(){g(\"rec\",!0)};g.log = function(a,b){g(\"log\",[a,b])};g.consent=function(a){g(\"consent\",!arguments.length||a)};g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};g.clearUserCookie=function(){};g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};})(window,document,window['_fs_namespace'],'script','user');";
	var script = document.createElement("script");
	script.setAttribute("type", "text/javascript");
	script.setAttribute("defer", true);
	script.textContent = fsScript;
	document.getElementsByTagName("head")[0].appendChild(script);

	let setUserVariables = () => {
		window.FullStory.setUserVars({
			uid: profile.userId,
			displayName: profile.fullName,
			email: profile.email,
			company_str: profile.companyName,
			department_str: profile.department,
			environment_str: getEnvironment(),
			accountType_str: options.license.edition,
			districtId_int: profile.districtId,
			premiumSupport_bool: profile.premiumSupport,
			locale_str: profile.localeId,
			userType_str: profile.userType,
		});
	};

	let interval = setInterval(() => {
		if (window.FullStory) {
			setUserVariables();
			clearInterval(interval);
		}
	}, 100);
}

export default bootFullstory;
