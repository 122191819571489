let handleModalWindow = (modalWindowVisible) => {
	if (modalWindowVisible) {
		let intercomLauncher = document.querySelector(".intercom-launcher");
		let intercomContainer = document.querySelector("#intercom-container");
		if (intercomLauncher) {
			intercomLauncher.setAttribute("style", "display: none;");
		}
		if (intercomContainer) {
			intercomContainer.setAttribute("style", "display: none;");
		}
	}
};

export default handleModalWindow;
