import Block from "@clearpoint/old-theme/Block";
import DragAndDropTableCell from "@clearpoint/old-theme/DragAndDropTable/DragAndDropCell";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormLabel from "@clearpoint/old-theme/Form/Form.Label";
import Input from "@clearpoint/old-theme/Input/Input";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import useGroupColumns from "./useGroupColumns";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	name: PropTypes.string,
};
let FieldColumn = ({ name }) => {
	let translate = useTranslate();
	let groupColumns = useGroupColumns();
	let { session } = useOldSession();
	let {
		reportColumns: fieldList,
		_advancedColumnFlag,
		_groupBy,
		_groupByCategory,
		_groupByObjective,
	} = useFormValue();
	let field = useFormValue(name);
	let { fieldId, fullWidth, object } = field;
	let fieldWidth = field?.width?.width || 1;
	let totalWidth = fieldList.reduce(
		(totalWidth, field) => totalWidth + (field.fullWidth ? 0 : field?.width?.width || 1),
		0
	);
	let percentWidth = Math.round((fieldWidth / totalWidth) * 100);
	let scorecardGroupingFlag = object === "scorecard" && _groupBy === "scorecard";
	let categoryGroupingFlag = object === "category" && _groupByCategory;
	let objectiveGroupingFlag = object === "objective" && _groupByObjective;
	let fullWidthOptionFlag = useMemo(() => {
		let validObjectFlag = session.object === object;
		let validFieldFlag = !["charts", "linkedCharts", "milestones", "seriesName", "seriesData"].includes(fieldId);
		return (
			(validObjectFlag || scorecardGroupingFlag || categoryGroupingFlag || objectiveGroupingFlag) && validFieldFlag
		);
	}, [categoryGroupingFlag, fieldId, object, objectiveGroupingFlag, scorecardGroupingFlag, session.object]);
	let fullWidthDisabledFlag = useMemo(() => {
		let hierarchy = ["scorecard", "category", "objective", session.object];
		let objectIndex = hierarchy.indexOf(object);
		if (objectIndex < 0) return false;
		if (!(scorecardGroupingFlag || categoryGroupingFlag || objectiveGroupingFlag)) return false;
		return fieldList?.some((x) => x.fullWidth && hierarchy.indexOf(x.object) > objectIndex);
	}, [categoryGroupingFlag, fieldList, object, objectiveGroupingFlag, scorecardGroupingFlag, session.object]);
	return (
		<DragAndDropTableCell className="field-column-width" paddingBottom="0px">
			<FormLabel for={`${name}.width.width`} uppercaseFlag>
				{translate("tabGridColumns.width")}
			</FormLabel>
			<Block display="flex" alignItems="flex-start" justifyContent="space-between">
				{!fullWidth && (
					<Block width="48%">
						<FormInput
							defaultValue={1}
							marginBottom={theme.smallSpace}
							min="1"
							max="99"
							name={`${name}.width.width`}
							placeholder={translate("layout.columnWidth")}
							type="number"
						/>
					</Block>
				)}
				<StyleWrapper textAlign={!fullWidth ? "right" : undefined}>
					<Input
						data-testid="width-display"
						disabled
						marginBottom={theme.smallSpace}
						width={fullWidth ? "100%" : "48%"}
						uppercaseLabelFlag
						value={(fullWidth ? 100 : percentWidth) + "%"}
					/>
				</StyleWrapper>
			</Block>
			{_advancedColumnFlag && !fullWidth && (
				<FormInput
					label={`${translate("layout.minPixelWidth")} (20-500)`}
					marginBottom={theme.smallSpace}
					min="20"
					max="500"
					name={`${name}.width.minWidth`}
					placeholder={`125 (${translate("global.default")})`}
					type="number"
					uppercaseLabelFlag
				/>
			)}
			{fullWidthOptionFlag && (
				<FormCheck
					marginBottom={theme.tinySpace}
					onChange={groupColumns}
					name={`${name}.fullWidth`}
					disabled={fullWidthDisabledFlag}
				>
					{translate("tabGridColumns.fullWidth")}
				</FormCheck>
			)}
			{fullWidth && (
				<FormCheck marginBottom={theme.tinySpace} name={`${name}.showCellHeader`}>
					{translate("tabGridColumns.showHeader")}
				</FormCheck>
			)}
		</DragAndDropTableCell>
	);
};
FieldColumn.propTypes = propTypes;
export default FieldColumn;
