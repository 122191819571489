import { emptyArray } from "@clearpoint/utils";

let createUserListText = (users, userList) => {
	let slimmedUsers = users.slice(0, 5) || emptyArray;
	let userText = "";
	slimmedUsers?.forEach((userId, index) => {
		let userName = userList.find((user) => +user.userId === +userId)?.fullName;
		if (userName) {
			if (index === users.length - 2 && users.length <= 5) {
				userText += userName + ", and ";
			} else if (index === users.length - 1) {
				userText += userName;
			} else {
				userText += userName + ", ";
			}
		}
	});
	userText += users.length > 5 ? `and ${users.length - 5} others` : "";
	return userText;
};

export default createUserListText;
