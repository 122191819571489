import isMatch from "lodash/isMatch";

import { getIds, getIndexFromPathname } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import updateUrlWithPeriodId from "./setSessionFunction.updateUrlWithPeriodId";
import updateHistoryStack from "./updateHistoryStack";
import updateScorecardIdOnManagePages from "./updateScorecardIdOnManagePages";
import updateTabbedNavigationData from "./updateTabbedNavigationData";

let setSessionFunction = ({
	checkPeriodValidOnNewScorecard,
	getQueryParameters,
	history,
	location,
	periodList,
	previousSession,
	profile,
	session,
	setSession,
	translate,
}) => {
	if (!profile?.home) return;

	let { pathname } = location;
	let queryParameters = getQueryParameters(location.search);
	let ids = getIds({ url: location.search });
	queryParameters = { ...queryParameters, ...ids };

	// if scorecard changes and doesn't include current period, set periodId to scorecard's most recent period
	if (session.scorecardId !== previousSession.scorecardId) {
		let periodId = queryParameters.periodId || session.periodId || profile.periodId;
		checkPeriodValidOnNewScorecard({ periodId, previousSession, session });
		// if session period directly changed, update query parameters
	}
	if (session.periodId !== previousSession.periodId) {
		let { periodId } = session || {};
		updateUrlWithPeriodId({ history, pathname, periodId, queryParameters });
		let currentPeriod = periodList?.find((x) => x.periodId === periodId);
		if (translate && previousSession.periodId && periodId !== previousSession.periodId && currentPeriod) {
			toast.warning(translate("toaster.messages.objects.loadingObject", { object: currentPeriod.name }));
		}
	}

	let page = getIndexFromPathname(pathname);
	let historyStack = updateHistoryStack({ pathname, profile, page, session, queryParameters });
	let tabbedNavigationData = updateTabbedNavigationData({ historyStack, profile, session });
	tabbedNavigationData = updateScorecardIdOnManagePages({ tabbedNavigationData, session });

	let newSession = {
		historyStack,
		mode: queryParameters.mode,
		tabbedNavigationData,
		page,
	};

	if (!isMatch(session, newSession)) {
		setSession(newSession);
	}
};

export default setSessionFunction;
