import Block from "../Block";
import LogFileListItem from "./LogFileListItem";
import StyleWrapper from "../StyleWrapper";
import { usePagination } from "@clearpoint/providers/PaginationProvider";
import { useEffectOnce } from "@clearpoint/hooks";


let LogFileList = () => {
	let { data: logFileList, setChildQuery, setParentWidthQuery, startIndex } = usePagination();
	useEffectOnce(() => {
		setChildQuery(() => ".modal-document-item-1");
		setParentWidthQuery(() => "");
	});
	// dummy element of correct size for pagination
	let dummyElement = {
		activity: "",
		object: "",
		objectId: -1,
		scorecard: "",
		scorecardId: -1,
	};
	return (
		<Block overflow="hidden">
			{logFileList.length > 0 ? (
				logFileList.map((x, i) => (
					<LogFileListItem
						key={x.logFileId}
						element={x}
						index={startIndex + i}
						className={i === 0 ? "modal-document-item-1" : ""}
					/>
				))
			) : (
				<StyleWrapper opacity={0}>
					<LogFileListItem element={dummyElement} className="modal-document-item-1" />
				</StyleWrapper>
			)}
		</Block>
	);
};

export default LogFileList;
