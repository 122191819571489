import { useCallback, useMemo } from "react";
import useFilterCache from "./useFilterCache";
import useStateObject from "./useStateObject";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useUnmount } from "react-use";
import createFilterLookup from "./useFilteredHistoryList.createFilterLookup";
import generateGetFilteredExportList from "./useFilteredHistoryList.generateGetFilteredHistoryList";

let useFilteredHistoryList = () => {
	let { clear, get, getPromise } = useOldQueryStore();
	let [
		{ activityList, apiKeyList, object, objectId, objectList, periodList, scorecardList, search, userList },
		setFilterQuery,
	] = useStateObject({
		activityList: "",
		apiKeyList: "",
		object: "",
		objectId: "",
		objectList: "",
		periodList: "",
		scorecardList: "",
		search: "",
		userList: "",
	});
	let { count, previousFilterQuery, setFilterCache, storedData } = useFilterCache();

	useUnmount(() => {
		clear({ object: "logFile" });
	});

	let filterLookup = useMemo(() => createFilterLookup(setFilterQuery), [setFilterQuery]);

	let setFilter = useCallback(
		({ field, value }) => {
			filterLookup[field](value);
		},
		[filterLookup]
	);

	let getFilteredHistoryList = useCallback(
		(startIndex, itemsPerPage) =>
			generateGetFilteredExportList({
				activityList,
				apiKeyList,
				count,
				get,
				getPromise,
				object,
				objectId,
				objectList,
				periodList,
				previousFilterQuery,
				scorecardList,
				search,
				setFilterCache,
				storedData,
				userList,
			})(startIndex, itemsPerPage),
		[
			activityList,
			apiKeyList,
			count,
			get,
			getPromise,
			object,
			objectId,
			objectList,
			periodList,
			previousFilterQuery,
			scorecardList,
			search,
			setFilterCache,
			storedData,
			userList,
		]
	);

	let returnObject = useMemo(
		() => ({
			filterQuery: {
				activityList,
				apiKeyList,
				objectList,
				periodList,
				scorecardList,
				search,
				userList,
			},
			getFilteredHistoryList,
			logFileCount: count,
			setFilter,
		}),
		[
			activityList,
			apiKeyList,
			count,
			getFilteredHistoryList,
			objectList,
			periodList,
			scorecardList,
			search,
			setFilter,
			userList,
		]
	);
	return returnObject;
};

export default useFilteredHistoryList;
