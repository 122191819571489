import { useCallback } from "react";
import { useStateObject } from "@clearpoint/hooks";

let useChartSelectModalState = () => {
	let [{ chartTypeModalVisible, chartJsonModalVisible, clearJsonConfirmModalVisible }, setState] = useStateObject({
		chartJsonModalVisible: false,
		chartTypeModalVisible: false,
		clearJsonConfirmModalVisible: false,
	});

	let openChartJsonModal = useCallback(() => {
		setState({ chartJsonModalVisible: true });
	}, [setState]);

	let openChartTypeModal = useCallback(() => {
		setState({ chartTypeModalVisible: true });
	}, [setState]);

	let openClearJsonConfirmModal = useCallback(() => {
		setState({ clearJsonConfirmModalVisible: true });
	}, [setState]);

	let closeChartJsonModal = useCallback(() => {
		setState({ chartJsonModalVisible: false });
	}, [setState]);

	let closeChartTypeModal = useCallback(() => {
		setState({ chartTypeModalVisible: false });
	}, [setState]);

	let closeClearJsonConfirmModal = useCallback(() => {
		setState({ clearJsonConfirmModalVisible: false });
	}, [setState]);

	return {
		chartJsonModalVisible,
		chartTypeModalVisible,
		clearJsonConfirmModalVisible,
		closeChartJsonModal,
		closeChartTypeModal,
		closeClearJsonConfirmModal,
		openChartJsonModal,
		openChartTypeModal,
		openClearJsonConfirmModal,
	};
};

export default useChartSelectModalState;
