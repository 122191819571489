import { memo } from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactTooltip from "react-bootstrap/Tooltip";
import styled from "styled-components";

let StyledTooltip = styled(ReactTooltip)``;

let propTypes = {
	blockFlag: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	placement: PropTypes.oneOf(["left", "right", "top", "bottom"]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

let defaultProps = {
	color: "success",
	placement: "top",
};

let Tooltip = ({ blockFlag, children, className, color, placement, text }) => {
	return text ? (
		<OverlayTrigger
			placement={placement}
			delay={200}
			overlay={
				<StyledTooltip className={`tooltip-${color}`} id={`tooltip-${placement}`}>
					{text}
				</StyledTooltip>
			}
		>
			{blockFlag ? <div className={className}>{children}</div> : <span className={className}>{children}</span>}
		</OverlayTrigger>
	) : (
		children
	);
};

let TooltipWrapper = ({ disabled, ...props }) => {
	if (disabled) return <>{props.children}</>;
	return <Tooltip {...props} />;
};

TooltipWrapper.propTypes = propTypes;
TooltipWrapper.defaultProps = defaultProps;

export default memo(TooltipWrapper);
