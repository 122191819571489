import Mutate from "./Mutate";
import { useCallback } from "react";
import { useState } from "react";
import { OPTIONS, queryClient, useOptionsQuery } from "@clearpoint/query";

// Demo: Basic fetch and update
// In this demo, view network requests in the console.
// Note that "isLoading" is initial loading, whereas "isFetching" is as it is updating
// Fetching should only occur when the data is rendered
// If it is not, it will be fetched the next time it is rendered.

let InvalidateButton = () => {
	let invalidate = () => {
		queryClient.invalidateQueries([{ object: OPTIONS }]);
	};
	return <button onClick={invalidate}>Invalidate</button>;
};

let Hide = ({ children }) => {
	let [visible, setVisible] = useState();
	let toggle = useCallback(() => setVisible((x) => !x), []);
	return (
		<>
			<button onClick={toggle}>Toggle Options View</button>
			{visible && <div>{children}</div>}
		</>
	);
};

let OptionsView = () => {
	let { isLoading, isError, isFetching, data: options, error, status, fetchStatus } = useOptionsQuery();
	if (error) console.error(error);
	return (
		<>
			<div>Loading: {isLoading}</div>
			<div>Fetching: {isFetching}</div>
			<div>Status: {status}</div>
			<div>Fetch Status: {fetchStatus}</div>
			{options && <div>Data: {JSON.stringify(options, 0, 3)}</div>}
			<div>Error: {isError}</div>
		</>
	);
};

let OptionsTest = () => {
	return (
		<>
			<InvalidateButton />
			<Hide>
				<div>
					<Mutate />
				</div>
				<OptionsView />
			</Hide>
		</>
	);
};

export default OptionsTest;
