import classNames from "classnames";

import LoadingAlert from "@clearpoint/old-theme/Alert/Alert.Loading";
import Block from "@clearpoint/old-theme/Block";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { usePercentLoaded } from "@clearpoint/hooks";

import useChartList from "../DashboardList/useChartList";
import useMeasureGridList from "../DashboardList/useMeasureGridList";
import useMeasureStatusList from "../DashboardList/useMeasureStatusList";
import useElementList from "../useElementList";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import DashboardListDisplay from "@components/Layouts/ViewLayout/ViewDashboard/DashboardList/DashboardListDisplay";
import DashboardPageTitle from "@components/Layouts/ViewLayout/ViewDashboard/Toolbar/DashboardPageTitle";
import BriefingBookNavigation from "@components/Print/BriefingBook/Navigation";

let Export = () => {
	let { htmlExportFlag, briefingBookFlag } = useOldSession().session;
	let elementList = useElementList();
	let { chartLoadingFlag, chartList } = useChartList(elementList, true);
	let { measureStatusLoadingFlag, measureStatusList } = useMeasureStatusList(elementList);
	let { measureGridLoadingFlag } = useMeasureGridList(elementList);
	let loadingFlag = chartLoadingFlag || measureStatusLoadingFlag || measureGridLoadingFlag;
	let percentLoaded = usePercentLoaded();
	let { customFieldFlag } = useLocalState();
	return loadingFlag ? (
		<LoadingAlert percentLoaded={percentLoaded} />
	) : (
		elementList.report.map((page, i) => (
			<Block
				className={classNames("avoid-page-break", { "page-break": !customFieldFlag })}
				marginBottom={!htmlExportFlag && "-50px"}
				key={i}
				overflow="hidden !important"
				textAlign="left"
			>
				{!customFieldFlag && (!htmlExportFlag || i === 0) && (
					<Block marginBottom={theme.mediumSpace} display="flex" justifyContent="space-between">
						<DashboardPageTitle />
						{briefingBookFlag && <BriefingBookNavigation />}
					</Block>
				)}
				{page.map((row, i) => (
					<StyleWrapper key={i} flexWrap="nowrap" overflow="hidden !important">
						<DashboardListDisplay
							chartList={chartList?.filter((x) => row.some((chart) => chart.objectId === x.objectId))}
							measureStatusList={measureStatusList}
						/>
					</StyleWrapper>
				))}
			</Block>
		))
	);
};

export default Export;
