import Column from "@clearpoint/old-theme/Column";
import PrimarySeriesSelect from "./PrimarySeriesSelect";
import SelectMeasureSeries from "@clearpoint/old-theme/Form/Select/Select.MeasureSeries";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";

let DualSeries = () => {
	let translate = useTranslate();
	let { measureId } = useFormValue("chart");

	return (
		<>
			<Column tiny={6}>
				<PrimarySeriesSelect />
			</Column>
			<Column tiny={6}>
				<SelectMeasureSeries
					label={translate("global.targetSeries")}
					name="chart.targetSeriesId"
					measureId={measureId}
					required
				/>
			</Column>
		</>
	);
};

export default DualSeries;
