import { toast } from "@clearpoint/services/toastService/index";

let handleSubmit =
	({ close, emptySubmitWarning, onSubmit, skipCloseFlag }) =>
	(value, apiResponse, contextValue, skipCloseOnSubmitFlag) => {
		if (emptySubmitWarning && !apiResponse) {
			toast.warning(emptySubmitWarning);
		} else {
			if (onSubmit) onSubmit(value, apiResponse, contextValue);
			if (close && !skipCloseFlag && !skipCloseOnSubmitFlag) close();
		}
	};

export default handleSubmit;
