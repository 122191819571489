import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import getId from "../getId";
import { theme } from "@clearpoint/old-theme/Theme";
import { useQueryParameters } from "@clearpoint/hooks";



let Navigation = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { reportId, scorecardId } = useQueryParameters();
	let report = reportId && get({ object: "report", objectId: reportId });
	let homeHref = "#" + getId(report?.contents?.[0].pages?.[0]);
	let linkList = useMemo(
		() =>
			report?.contents
				?.find((x) => x.scorecardId === scorecardId)
				?.pages.reduce((linkList, page) => {
					if (linkList.find((x) => x.object === page.object)) return linkList;
					let id = getId(page);
					return [
						...linkList,
						{
							object: page.object,
							href: `#${id}`,
						},
					];
				}, []),
		[report, scorecardId]
	);
	let hideNavigationFlag = report?.hidePdfNav || !linkList;
	return hideNavigationFlag ? null : (
		<Block display="flex" justifyContent="flex-end" textAlign="center" fontSize="12px">
			<a href={homeHref}>
				<Block width="75px" marginLeft={theme.tinySpace}>
					<Icon name="homeOutline" />
					<Block lineHeight="1">{translate("labels.home")}</Block>
				</Block>
			</a>
			{linkList?.map((x) => (
				<a key={x.object} href={x.href}>
					<Block
						width="75px"
						marginLeft={theme.tinySpace}
						overflow="hidden"
						className="briefing-book-nav-item-container"
					>
						<Icon name={x.object} />
						<Block lineHeight="1" ellipsisFlag className="briefing-book-nav-item-text">
							{translate(x.object + "s")}
						</Block>
					</Block>
				</a>
			))}
		</Block>
	);
};

export default Navigation;
