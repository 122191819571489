import FormSelect from "../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectAggregateType = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("edit.sum"),
				value: "SUM",
			},
			{
				label: translate("edit.count"),
				value: "COUNT",
			},
			{
				label: translate("edit.average"),
				value: "AVERAGE",
			},
			{
				label: translate("edit.stdev"),
				value: "STDEV",
			},
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectAggregateType;
