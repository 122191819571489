let handleTab =
	({ setFormValue, name }) =>
	(e) => {
		if (e.key === "Tab") {
			e.preventDefault();
			let start = e.target.selectionStart;
			let end = e.target.selectionEnd;
			let value = e.target.value;
			e.target.value = `${value.slice(0, start)}\t${value.slice(end)}`;
			e.target.selectionStart = e.target.selectionEnd = start + 1;
			setFormValue(name, e.target.value);
		}
	};

export default handleTab;
