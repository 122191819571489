import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import Table from "@clearpoint/old-theme/Table";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { getLinkScore, getTotalObjectiveScore } from "@clearpoint/utils";
import { useBuildLink } from "@clearpoint/hooks";



let ObjectiveScoring = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let buildLink = useBuildLink();
	let { automaticEvaluation, links: linkList } = useFormValue();
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let totalScore = getTotalObjectiveScore(linkList, statusList);

	let showTableFlag = automaticEvaluation && totalScore !== undefined;

	return (
		showTableFlag && (
			<Table borderedFlag>
				<thead>
					<tr>
						<th>{translate("global.element")}</th>
						<th>{translate("global.elementScore")}</th>
						<th>* {translate("edit.weight")} (%)</th>
						<th>= {translate("edit.weightedScore")}</th>
					</tr>
				</thead>
				<tbody>
					{linkList
						?.filter((link) => typeof link?.weight === "number")
						.map((link) => {
							let { fieldId, name, object, objectId, score, scorecardId, statusId, weight } = link;

							return (
								<tr key={`${object}${objectId}`}>
									<td>
										<Icon name={fieldId} size="large" marginRight={theme.smallSpace} />
										<StatusIcon statusId={statusId} marginRight={theme.smallSpace} />
										<a href={buildLink({ object, objectId, scorecardId })}>{name}</a>
									</td>
									<td>{score}</td>
									<td>{weight}</td>
									<td>{getLinkScore(link)?.toFixed(2)}</td>
								</tr>
							);
						})}
					<Block as="tr" backgroundColor={theme.highlightYellow} fontWeight={theme.bold}>
						<td colSpan={3}>{translate("detailLayouts.objectiveScore")}</td>
						<td>{totalScore?.toFixed(2)}</td>
					</Block>
				</tbody>
			</Table>
		)
	);
};

export default ObjectiveScoring;
