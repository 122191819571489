import Hide from "./Hide";
import StyleWrapper from "./StyleWrapper";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useId } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	defaultStyle: PropTypes.string,
	doNotRenderChildrenFlag: PropTypes.bool,
	duration: PropTypes.number,
	easingFunction: PropTypes.string,
	enterDuration: PropTypes.number,
	enterEasingFunction: PropTypes.string,
	enterStyle: PropTypes.string,
	exitDuration: PropTypes.number,
	exitEasingFunction: PropTypes.string,
	exitStyle: PropTypes.string,
	visible: PropTypes.bool,
};
let defaultProps = {
	duration: 200,
	easingFunction: "ease",
};
let Transition = ({
	children,
	className,
	defaultStyle,
	doNotRenderChildrenFlag,
	duration,
	easingFunction,
	enterDuration,
	enterEasingFunction,
	enterStyle,
	exitDuration,
	exitEasingFunction,
	exitStyle,
	visible,
}) => {
	let [renderFlag, setRenderFlag] = useState(false);
	let id = useId();
	let closingFlagRef = useRef();
	useEffect(() => {
		let timeout;
		if (visible && !renderFlag) setRenderFlag(true);
		if (!visible && renderFlag && !closingFlagRef.current) {
			if (!exitStyle) {
				setRenderFlag(false);
			} else {
				closingFlagRef.current = true;
				timeout = setTimeout(() => {
					setRenderFlag(false);
					closingFlagRef.current = false;
				}, exitDuration || duration);
			}
		}
		return () => {
			if (timeout) clearTimeout(timeout);
		};
	}, [duration, exitDuration, exitStyle, renderFlag, visible]);
	let enterStyleString = defaultStyle;
	if (enterStyle)
		enterStyleString = `
			@keyframes transition-enter${id} {
				from {
					${enterStyle}
				}
				to {
					${defaultStyle}
				}
		}`;
	let exitStyleString = "";
	if (exitStyle)
		exitStyleString = `
			@keyframes transition-exit${id} {
				from {
					${defaultStyle}
				}
				to {
					${exitStyle}
				}
			}
		`;
	let style = `${enterStyleString} ${exitStyleString}`;
	let animation;
	if (visible && enterStyle)
		animation = `transition-enter${id} ${enterDuration || duration}ms ${
			enterEasingFunction || easingFunction
		} forwards !important`;
	if (!visible && exitStyle)
		animation = `transition-exit${id} ${exitDuration || duration}ms ${
			exitEasingFunction || easingFunction
		} forwards !important`;
	if (!exitStyle) renderFlag = visible;
	return useMemo(
		() => (
			<Hide className={className} visible={renderFlag}>
				<StyleWrapper animation={animation} key={id} $style={style}>
					{doNotRenderChildrenFlag && !renderFlag ? null : children}
				</StyleWrapper>
			</Hide>
		),
		[animation, children, className, doNotRenderChildrenFlag, id, renderFlag, style]
	);
};
Transition.propTypes = propTypes;
Transition.defaultProps = defaultProps;
export default Transition;
