import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectSort = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: "none", label: translate("manage.chart.none") },
			{ value: "asc", label: translate("manage.chart.ascending") },
			{ value: "desc", label: translate("manage.chart.descending") },
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectSort;
