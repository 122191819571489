import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useCallback } from "react";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useTranslate } from "@clearpoint/translate";

let AlignLeftButton = () => {
	let { checklist } = useChecklist();
	let { setFormValue } = useFormContext();
	let translate = useTranslate();
	let alignLeft = useCallback(
		() =>
			setFormValue("elements", (elementList) => {
				if (elementList) {
					let checkedElementList = elementList.filter((x) =>
						checklist.some((check) => check.checked && check.id === `${x.object},${x.objectId}`)
					);
					let newXPos = Math.min(...checkedElementList.map((x) => x.xPos));
					return elementList.map((x) => (checkedElementList.includes(x) ? { ...x, xPos: newXPos } : x));
				}
			}),
		[checklist, setFormValue]
	);
	let disabledFlag = !checklist.some((x) => x.checked);
	return (
		<IconButton
			color="default"
			disabled={disabledFlag}
			name="alignLeft"
			onClick={alignLeft}
			title={disabledFlag ? translate("edit.map.noElements") : undefined}
		/>
	);
};

export default AlignLeftButton;
