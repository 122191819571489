import Block from "../Block";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "../Theme";

let ReplyBox = ({ onClick }) => {
	let translate = useTranslate();

	return (
		<Block
			onClick={onClick}
			cursor="text"
			color={`${theme.gray3}`}
			backgroundColor={theme.white}
			borderRadius="4px"
			padding={`${theme.tinySpace} ${theme.smallSpace}`}
		>
			{translate("discussions.placeholder.reply")}
		</Block>
	);
};

export default ReplyBox;
