import Column from "../Column";
import FormLabel from "./Form.Label";
import Row from "../Row";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	label: PropTypes.string,
	name: PropTypes.string,
};

let FormLabelLeft = ({ children, label, name, labelWidth }) => (
	<Row>
		<Column large={labelWidth || 4} paddingRight="0px">
			<FormLabel wrapTextFlag for={name}>
				{label}
			</FormLabel>
		</Column>
		<Column large={labelWidth ? 12 - labelWidth : 8}>{children}</Column>
	</Row>
);

FormLabelLeft.propTypes = propTypes;

export default FormLabelLeft;
