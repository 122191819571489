import Action from "../Action";
import Block from "../../Block";
import MentionUser from "../MentionUser";
import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	commentData: PropTypes.shape({
		comment: PropTypes.string,
		commentId: PropTypes.number,
		discussionId: PropTypes.number,
	}),
	commentValue: PropTypes.string,
	setState: PropTypes.func,
};

let EditComment = ({ commentData, commentValue, setState }) => {
	let { set } = useOldQueryStore();
	let { comment, commentId, discussionId } = commentData;

	let closeEditMode = useCallback(() => {
		setState({
			commentValue: comment,
			editModeFlag: false,
		});
	}, [comment, setState]);

	let saveComment = useCallback(() => {
		set({
			object: "comment",
			objectId: commentId || discussionId,
			data: { comment: commentValue },
		}).then(() => {
			setState({
				editModeFlag: false,
			});
		});
	}, [commentId, commentValue, discussionId, set, setState]);

	let updateValue = useCallback(
		(e) => {
			setState({
				commentValue: e.target.value,
			});
		},
		[setState]
	);

	return (
		<>
			<Block margin={`${theme.tinySpace} 0px`}>
				<MentionUser value={commentValue} updateValue={updateValue} />
			</Block>
			<Block display="flex" justifyContent="flex-end" marginBottom={theme.smallSpace}>
				<Action name="close" onClick={closeEditMode} marginRight={theme.smallSpace} />
				<Action name="saveCheck" onClick={saveComment} />
			</Block>
		</>
	);
};

EditComment.propTypes = propTypes;
export default EditComment;
