import Radio from "@clearpoint/old-theme/Form/Radio/Form.Radio";
import RadioGroup from "@clearpoint/old-theme/Form/Radio/Form.RadioGroup";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import { BAR, CHART, DONUT, LINE, METRIC } from "./chartTypes";
import { theme } from "@clearpoint/old-theme/Theme";

let styleString = `div {
	div {
		display: flex;
		flex-wrap: wrap;

		label {
			display: inline-flex;
			min-width: 33%;
			max-width: 100%;
			padding-left: 5px;
			

			span {
				font-weight: ${theme.bolder}!important;
			}

			@media screen and (max-width: 992px) {
				min-width: 50%;
			}
			@media screen and (max-width: 790px) {
				min-width: 100%;
			}
		}
	}
}`;

let ChartTypeRadio = () => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: CHART, label: translate("chart.measure") },
			{ value: DONUT, label: translate("chart.kpiDonut") },
			{ value: LINE, label: translate("chart.kpiLine") },
			{ value: BAR, label: translate("chart.kpiBar") },
			{ value: METRIC, label: translate("chart.kpiMetric") },
		],
		[translate]
	);

	return (
		<StyleWrapper $style={styleString}>
			<RadioGroup name={"chart.chartType"} label={translate("logFieldLabels.chartType")}>
				{options.map((x) => (
					<Radio key={x.value} value={x.value} text={x.label} />
				))}
			</RadioGroup>
		</StyleWrapper>
	);
};

export default ChartTypeRadio;
