import { useMemo } from "react";

import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let useCalculatedColumnWidthList = ({ columnWidthList, gridRef }) => {
	let { customFieldFlag } = useLocalState();
	let { exportFlag, exportWidth } = useOldSession().session;
	let gridContainerClientWidth = gridRef.current?.clientWidth;
	let calculatedWidthList = useMemo(() => {
		if (!gridContainerClientWidth) return columnWidthList?.map((x) => (x.width || "4.16") + "px");
		let calculatedContainerWidth;

		calculatedContainerWidth = exportFlag && !customFieldFlag ? exportWidth - 80 : gridContainerClientWidth;

		if (!columnWidthList) return [];
		let widthList = columnWidthList.map(({ width, minWidth }) =>
			Math.max((width * calculatedContainerWidth) / 100, minWidth)
		);

		let totalWidth = widthList.reduce((totalWidth, width) => totalWidth + width, 0);
		let minimumWidth = columnWidthList.reduce((acc, columnWidth) => acc + columnWidth.minWidth, 0);

		if (totalWidth > calculatedContainerWidth) {
			let decreaseWidthFactor = (calculatedContainerWidth - minimumWidth) / (totalWidth - minimumWidth);
			widthList = widthList.map((width, i) => {
				let { minWidth } = columnWidthList[i];
				let calculatedWidth = decreaseWidthFactor * width;
				return Math.floor(Math.max(minWidth, calculatedWidth));
			});
			return widthList.map((x) => x + "px");
		}
		return widthList.map((width) => Math.floor(width) + "px");
	}, [gridContainerClientWidth, columnWidthList, customFieldFlag, exportFlag, exportWidth]);
	return calculatedWidthList;
};

export default useCalculatedColumnWidthList;
