import AddButton from "@clearpoint/old-theme/Button/Button.Add";
import Alert from "@clearpoint/old-theme/Alert/Alert";
import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import Block from "@clearpoint/old-theme/Block";
import Column from "@clearpoint/old-theme/Column";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import Help from "@clearpoint/old-theme/Help";
import Input from "@clearpoint/old-theme/Input/Input";
import LinkElementModal from "@clearpoint/old-theme/Form/Modal/LinkElement/LinkElementModal";
import ListItemLink from "@clearpoint/old-theme/ListItem/Link/ListItem.Link";
import NoResults from "@clearpoint/old-theme/NoResults";
import Row from "@clearpoint/old-theme/Row";
import { useCallback, useMemo } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { getTotalObjectiveScore, getTotalObjectiveWeight, objectiveLinkTypeList } from "@clearpoint/utils";
import { useObjectiveLinkListSortedByCategory } from "@clearpoint/hooks";

let contentPropTypes = {
	linkList: PropTypes.array,
	multipleFlag: PropTypes.bool,
	objectiveEvaluationAllowedFlag: PropTypes.bool,
};
let LinksTabContent = ({ linkList, multipleFlag, objectiveEvaluationAllowedFlag }) => {
	let translate = useTranslate();
	let formValue = useFormValue();
	let { get } = useOldQueryStore();
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let automaticEvaluationFlag = useFormValue("automaticEvaluation");
	let showWeightsFlag = automaticEvaluationFlag && objectiveEvaluationAllowedFlag;
	let { periodGroupId, hiddenPeriods } = formValue;
	let reportingPeriodWarningFlag =
		showWeightsFlag && (periodGroupId > 0 || (hiddenPeriods && hiddenPeriods.length > 0));

	let { data: filteredLinkList } = useFilter();
	let visibleLinkTypeList = useMemo(
		() => objectiveLinkTypeList.filter((linkType) => filteredLinkList.some((link) => link.fieldId === linkType)),
		[filteredLinkList]
	);

	let generateGroupTitle = useCallback(
		(linkType) => {
			if (linkType === "childMeasures") {
				return translate("global.childMeasures");
			} else if (linkType === "childInitiatives") {
				return translate("global.childInitiatives");
			}
			return translate(linkType);
		},
		[translate]
	);

	let totalWeight = getTotalObjectiveWeight(linkList, statusList);
	let totalScore = getTotalObjectiveScore(linkList, statusList);

	return (
		<>
			<Help section="edit-element" feature="objective-evaluations" />
			{objectiveEvaluationAllowedFlag ? (
				!multipleFlag && <FormCheck name="automaticEvaluation" text={translate("edit.enableAutoEval")} />
			) : (
				<AlertUpsell feature="objectiveEvaluation" />
			)}
			{reportingPeriodWarningFlag && <Alert color="warning">{translate("edit.element.linkWarning")}</Alert>}
			<FilterInput
				name="filterLinks"
				filterBy="name"
				placeholder={translate("edit.filterLinks") + "..."}
				right={
					<AddButton color="primary" icon="linkTag" Modal={LinkElementModal} name="links" object="objective" />
				}
			/>
			<Help section="edit-element" feature="linkages" />
			{visibleLinkTypeList.map((linkType) => (
				<FormGroup key={linkType} title={generateGroupTitle(linkType)} paddingBottom={theme.tinySpace}>
					{filteredLinkList
						.filter((link) => link.fieldId === linkType && link.visible)
						.map((link) => (
							<ListItemLink
								key={`${link.fieldId}${link.object}${link.objectId}`}
								link={link}
								showWeightsFlag={showWeightsFlag}
							/>
						))}
				</FormGroup>
			))}
			{showWeightsFlag && (
				<Block>
					<Row>
						<Column>
							<Input label={translate("edit.totalWeight")} disabled value={totalWeight} />
						</Column>
						<Column>
							<Input label={translate("edit.element.totalScore")} disabled value={totalScore?.toFixed(2)} />
						</Column>
					</Row>
					<Block marginTop={theme.space}>
						{totalWeight !== 100 && <Alert color="warning">{translate("edit.element.weightWarning")}</Alert>}
					</Block>
				</Block>
			)}
			<NoResults feature="links" results={linkList} />
		</>
	);
};
LinksTabContent.propTypes = contentPropTypes;

let tabPropTypes = {
	feature: PropTypes.string,
	multipleFlag: PropTypes.bool,
	objectiveEvaluationAllowedFlag: PropTypes.bool,
};
let LinksTab = ({ multipleFlag, objectiveEvaluationAllowedFlag }) => {
	let linkList = useFormValue("links");
	let linkListSortedByCategory = useObjectiveLinkListSortedByCategory(linkList);
	return (
		<FilterProvider data={linkListSortedByCategory}>
			<LinksTabContent
				multipleFlag={multipleFlag}
				linkList={linkListSortedByCategory}
				objectiveEvaluationAllowedFlag={objectiveEvaluationAllowedFlag}
			/>
		</FilterProvider>
	);
};

LinksTab.propTypes = tabPropTypes;

export default LinksTab;
