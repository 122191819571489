import StyleWrapper from "../../../StyleWrapper";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { useBuildLink } from "@clearpoint/hooks";


let propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	noLinkFlag: PropTypes.bool,
	parentName: PropTypes.string,
	strikethroughFlag: PropTypes.bool,
};

let FormFieldDisplayName = ({ className, name, noLinkFlag, parentName, strikethroughFlag }) => {
	let buildLink = useBuildLink();
	let value = useFormValue(name);
	let parentValue = useFormValue(parentName);
	let { object, objectId, scorecardId } = parentValue;
	return (
		<StyleWrapper
			data-testid="form-field-display-name"
			display="block"
			height="100%"
			className={className}
			textDecoration={strikethroughFlag ? "line-through !important" : undefined}
		>
			{noLinkFlag ? value : <a href={buildLink({ object, objectId, scorecardId })}>{value}</a>}
		</StyleWrapper>
	);
};

FormFieldDisplayName.propTypes = propTypes;

export default FormFieldDisplayName;
