import cloneDeep from "lodash/cloneDeep";

let removeUnderscoredKeys = (object) => {
	object = cloneDeep(object);
	for (let key in object) {
		if (key.startsWith("_")) {
			delete object[key];
		} else {
			let value = object[key];
			if (value && typeof value === "object") {
				object[key] = removeUnderscoredKeys(value);
			}
		}
	}
	return object;
};

export default removeUnderscoredKeys;
