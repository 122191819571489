import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import DragAndDropTableCell from "@clearpoint/old-theme/DragAndDropTable/DragAndDropCell";
import FieldColumn from "./FieldColumn";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormDragAndDropTable from "@clearpoint/old-theme/Form/Form.DragAndDropTable";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormLabel from "@clearpoint/old-theme/Form/Form.Label";
import FormModalFieldList from "@clearpoint/old-theme/Form/Modal/FieldList/FieldListModal";
import FormRadio from "@clearpoint/old-theme/Form/Radio/Form.Radio";
import FormRadioGroup from "@clearpoint/old-theme/Form/Radio/Form.RadioGroup";
import Help from "@clearpoint/old-theme/Help";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback, useEffect, useRef } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import useGroupColumns from "./useGroupColumns";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { useStateObject } from "@clearpoint/hooks";

let ColumnsTab = () => {
	let translate = useTranslate();
	let groupColumns = useGroupColumns();
	let { session } = useOldSession();
	let { setFormValue, formValueRef } = useFormContext();
	let { reportColumns: fieldList, _groupBy, reportOptions } = useFormValue();
	let [{ modalVisible }, setState] = useStateObject({
		modalVisible: false,
	});
	let setFieldList = useCallback((x) => setFormValue("reportColumns", x), [setFormValue]);
	let open = useCallback(() => {
		setState({
			modalVisible: true,
		});
	}, [setState]);
	let close = useCallback(() => {
		setState({
			modalVisible: false,
		});
	}, [setState]);
	let groupingCompletedFlagRef = useRef();
	useEffect(() => {
		let { _groupBy, _groupByCategory, _groupByObjective } = formValueRef.current;
		if (groupingCompletedFlagRef.current && _groupBy === "object") {
			if (_groupByCategory) setFormValue("_groupByCategory", false);
			if (_groupByObjective) setFormValue("_groupByObjective", false);
		}
	});
	useEffect(() => {
		if (reportOptions && !groupingCompletedFlagRef.current) {
			// do the things
			// group columns on load
			setFormValue("_groupBy", reportOptions?.hierarchy?.includes("scorecard") ? "scorecard" : "object");
			setFormValue("_groupByCategory", reportOptions?.hierarchy?.includes("category"));
			if (session.object !== "objective") {
				setFormValue("_groupByObjective", reportOptions?.hierarchy?.includes("objective"));
			}
			groupColumns(true);
			groupingCompletedFlagRef.current = true;
		}
	}, [groupColumns, reportOptions, session.object, setFormValue]);

	return (
		<>
			<Help section="edit-grid-layout" feature="report-columns" />
			<Block display="flex" overflowX="auto" width="100%">
				<FormDragAndDropTable
					border={`1px solid ${theme.lightGray4}`}
					dragHandleSelector=".field-column-handle"
					name="reportColumns"
					list={fieldList}
					setList={setFieldList}
					flex="1"
				>
					{fieldList?.map((x, i) => (
						<FieldColumn key={x.object + x.fieldId} name={`reportColumns[${i}]`} />
					))}
				</FormDragAndDropTable>
				<Block display="flex" flexDirection="column" borderTop={`3px solid ${theme.lightGray4}`}>
					<DragAndDropTableCell
						backgroundColor={theme.offWhite}
						className="field-column-title"
						display="flex"
						alignItems="center"
						flexShrink="0"
					>
						<Button
							data-testid="add-column-button"
							color="primary"
							size="small"
							blockFlag
							onClick={open}
							noWrapFlag
							width="120px"
						>
							{translate("tabGridColumns.addColumn")}
						</Button>
					</DragAndDropTableCell>
					<DragAndDropTableCell flex="1" backgroundColor={theme.offWhite}>
						{" "}
					</DragAndDropTableCell>
				</Block>
			</Block>
			<Block display="flex" marginTop={theme.mediumSpace} justifyContent="space-between" alignItems="flex-start">
				<FormCheck marginBottom="0px" name="_advancedColumnFlag">
					{translate("tabGridColumns.showAdvancedOptions")}
				</FormCheck>
				<Button color="primary" size="small" onClick={open} noWrapFlag>
					{translate("tabGridColumns.addColumn")}
				</Button>
			</Block>
			<FormGroup title={translate("tabGridColumns.groupBy")}>
				<FormRadioGroup boldFlag defaultValue="object" name="_groupBy" onChange={groupColumns}>
					<FormRadio value="object" text={`${translate("tabGridColumns.groupBy")} ${translate(session.object)}`} />
					<FormRadio value="scorecard" text={`${translate("tabGridColumns.groupBy")} ${translate("scorecard")}`} />
				</FormRadioGroup>
				{_groupBy === "scorecard" && (
					<Block marginLeft={theme.largeSpace}>
						<StyleWrapper fontWeight={theme.bolder} marginBottom={theme.smallSpace}>
							<FormLabel>{translate("tabGridColumns.secondaryGroupingOptions")}</FormLabel>
						</StyleWrapper>
						<FormCheck
							labelWeight="bold"
							marginBottom={theme.tinySpace}
							name="_groupByCategory"
							onChange={groupColumns}
						>
							{translate("tabGridColumns.groupBy")} {translate("category")}
						</FormCheck>
						{session.object !== "objective" && (
							<FormCheck labelWeight="bold" name="_groupByObjective" onChange={groupColumns}>
								{translate("tabGridColumns.groupBy")} {translate("objective")}
							</FormCheck>
						)}
					</Block>
				)}
			</FormGroup>
			<FormModalFieldList name="reportColumns" modalVisible={modalVisible} close={close} onSave={groupColumns} />
		</>
	);
};
export default ColumnsTab;
