import Block from "@clearpoint/old-theme/Block";
import Column from "@clearpoint/old-theme/Column";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormSelectComparison from "@clearpoint/old-theme/Form/Select/Select.Comparison";
import Help from "@clearpoint/old-theme/Help";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import Input from "@clearpoint/old-theme/Input/Input";
import Loading from "@clearpoint/old-theme/Loading/index";
import Row from "@clearpoint/old-theme/Row";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import { useEffect } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import getDeepValue from "lodash/get";
import isEqual from "lodash/isEqual";
import { theme } from "@clearpoint/old-theme/Theme";
import { getTotalObjectiveScore } from "@clearpoint/utils";
import { useObjectiveLinkListSortedByCategory } from "@clearpoint/hooks";

let calculateOnTarget = {
	gt: (total, calculation) => total > calculation,
	gte: (total, calculation) => total >= calculation,
	lt: (total, calculation) => total < calculation,
	lte: (total, calculation) => total <= calculation,
	eq: (total, calculation) => total === calculation,
	neq: (total, calculation) => total !== calculation,
	btw: (total, calculation) => {
		let bounds = calculation?.split(",");
		return total >= bounds?.[0] && total <= bounds?.[1];
	},
	any: () => true,
};

let propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	section: PropTypes.string,
	seriesType: PropTypes.string,
};
let Evaluations = ({ label, name, section, seriesType }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { setFormDefaultValue, setFormValue, formDefaultValue } = useFormContext();
	let defaultEvaluationList = getDeepValue(formDefaultValue, name);
	let evaluationList = useFormValue(name);
	let formLinkList = useFormValue("links");
	let linkList = useObjectiveLinkListSortedByCategory(formLinkList);
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let totalScore = getTotalObjectiveScore(linkList, statusList);
	let loadingFlag = statusList === undefined;

	useEffect(() => {
		let newEvaluationList = statusList
			?.filter((x) => x.name !== "Not Defined")
			.filter((x) => !seriesType || x.elements?.includes(seriesType))
			.map((status) => {
				let storedEvaluation = defaultEvaluationList?.find((evaluation) => evaluation.statusId === status.statusId);
				if (storedEvaluation) {
					storedEvaluation = cloneDeep(storedEvaluation);
					storedEvaluation.statusName = status.name;
					storedEvaluation.sortOrder = status.sortOrder;
				}
				return (
					storedEvaluation || {
						statusId: status.statusId,
						series: "",
						statusName: status.name,
						calculation: "",
						enabled: false,
						compare: "gt",
						sortOrder: status.sortOrder,
					}
				);
			});
		if (newEvaluationList && !isEqual(defaultEvaluationList, newEvaluationList)) {
			setFormDefaultValue(name, newEvaluationList);
			setFormValue(name, newEvaluationList);
		}
	}, [defaultEvaluationList, name, seriesType, setFormDefaultValue, setFormValue, statusList]);
	return loadingFlag ? (
		<Loading />
	) : (
		<FormGroup data-testid="evaluations" title={label} paddingBottom={theme.smallSpace}>
			<Help feature="evaluation-criteria" section={section} />
			{evaluationList?.map((evaluation, i) => {
				let onTargetFlag = calculateOnTarget?.[evaluation.compare]?.(totalScore, evaluation.calculation);
				return (
					<Row
						marginBottom={i === evaluationList.length - 1 ? `-${theme.space}` : undefined}
						key={evaluation.statusId}
					>
						<Column large={4}>
							<FormCheck name={`${name}[${i}].enabled`}>
								<StatusIcon statusId={evaluation.statusId} marginLeft={theme.smallSpace} />
								{evaluation.statusName}
							</FormCheck>
						</Column>
						{evaluation.enabled && (
							<>
								<Column large={3}>
									<Input disabled value={totalScore?.toFixed(2)} placeholder={translate("components.score")} />
								</Column>
								<Column large={2}>
									<FormSelectComparison
										name={`${name}[${i}].compare`}
										placeholder={translate("layout.comparison") + "..."}
									/>
								</Column>
								<Column large={3}>
									<FormInput
										name={`${name}[${i}].calculation`}
										disabled={evaluation.compare === "any"}
										placeholder={translate("options.admin.status.score")}
										data-testid="score-calculation-input"
										right={
											<Block color={onTargetFlag ? "green" : "red"}>
												<Icon name={onTargetFlag ? "onTarget" : "offTarget"} />
											</Block>
										}
									/>
								</Column>
							</>
						)}
					</Row>
				);
			})}
		</FormGroup>
	);
};

Evaluations.propTypes = propTypes;

export default Evaluations;
