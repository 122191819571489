import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";
import { idealTextColor } from "@clearpoint/utils";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.string,
	"data-testid": PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	size: PropTypes.string,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let Span = ({ children, className }) => (
	<span className={classNames(className, "tag", "cps-tag-wrap")}>{children}</span>
);

let Tag = styled(Span)`
	background-color: ${(props) => props.color};
	border-radius: 12px;
	color: ${(props) => idealTextColor(props.color)};
	display: inline-block;
	font-family: "${(props) => props.theme.defaultFontFamily}";
	font-size: ${(props) => (props.size === "large" ? "14px" : "12px")};
	font-weight: 500;
	overflow: hidden;
	padding: 3px 9px;
	text-overflow: ellipsis;
	text-shadow: none;
	white-space: nowrap;
	flex-shrink: 0;
`;

Tag.propTypes = propTypes;

export default Tag;
