import { useIntercom } from "react-use-intercom";
import { useLocation } from "react-router-dom";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import bootAccessiBe from "./useBootThirdPartyPlugins.bootAccessiBe";
import bootFullstory from "./useBootThirdPartyPlugins.bootFullstory";
import bootThirdPartyPluginCallback from "./useBootThirdPartyPlugins.bootThirdPartyPluginCallback";
const CUSTOM_INTERCOM_LAUNCHER_ID = "clearpoint_intercom_launcher";

let useBootThirdPartyPlugins = () => {
	let location = useLocation();
	let printingFlag = location.pathname.includes("print");
	let {
		session: { environment },
	} = useOldSession();

	let { boot: bootIntercom, trackEvent, update } = useIntercom();
	let { clear, getPromise } = useOldQueryStore();
	let intercom = window.Intercom;
	return (loginFlag) => {
		let bootThirdPartyPlugin = bootThirdPartyPluginCallback({
			getPromise,
			clear,
			printingFlag,
			environment,
			bootIntercom,
			trackEvent,
			update,
			bootFullstory,
			bootAccessiBe,
			intercom,
		});
		return bootThirdPartyPlugin(loginFlag);
	};
};

export default useBootThirdPartyPlugins;
export { CUSTOM_INTERCOM_LAUNCHER_ID };
