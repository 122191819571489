import { theme } from "@clearpoint/old-theme/Theme";
let style = `
   &:hover, &:active, &.active {
      border-left: 4px solid ${theme.lightPrimary};
      color: ${theme.lightPrimary};
      font-weight: ${theme.bolder};
   }
   &:active, &.active{
      background-color: ${theme.white};
   }
   & .selected {
      visibility: hidden;
   }
   &:hover .selected,
   &:active .selected,
   &.tab-active .selected {
      visibility: visible;
   }`;
export default style;
