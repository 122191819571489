import EditMapModalWindow from "./EditMapModalWindow";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let MapModalController = () => {
	let { mapOptionsModalVisible, editMapModalWindowVisible, editObjectId, setLocalState } = useLocalState();
	let closeEditMapModalWindow = useCallback(
		() => setLocalState({ editMapModalWindowVisible: false }),
		[setLocalState]
	);
	let closeMapOptionsModal = useCallback(() => setLocalState({ mapOptionsModalVisible: false }), [setLocalState]);
	return (
		<>
			{editMapModalWindowVisible && (
				<EditMapModalWindow
					close={closeEditMapModalWindow}
					modalWindowVisible={editMapModalWindowVisible}
					mapOptionsModalVisible={mapOptionsModalVisible}
					closeMapOptionsModal={closeMapOptionsModal}
					objectId={editObjectId}
				/>
			)}
		</>
	);
};

export default MapModalController;
