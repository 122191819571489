import { Draggable } from "react-smooth-dnd";
import StyleWrapper from "../StyleWrapper";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
};

let DragAndDropTableColumn = ({ children, ...props }) => {
	return (
		<StyleWrapper {...props}>
			<Draggable>{children}</Draggable>
		</StyleWrapper>
	);
};

DragAndDropTableColumn.propTypes = propTypes;

export default DragAndDropTableColumn;
