import { useMemo } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import FormSelect from "./FormSelect";

let SelectMasterPeriod = ({ name, ...props }) => {
	let { get } = useOldQueryStore();
	let masterPeriodList = get({ object: "masterPeriod" });
	let translate = useTranslate();

	let options = useMemo(() => {
		if (!masterPeriodList) return [];
		return masterPeriodList.map((x) => ({ value: x.masterPeriodId, label: x.name }));
	}, [masterPeriodList]);

	return (
		<FormSelect
			placeholder={translate("manage.periods.selectMasterPeriod") + "..."}
			label={translate("manage.periods.masterPeriod")}
			name="masterPeriodId"
			options={options}
			{...props}
		/>
	);
};
export default SelectMasterPeriod;
