import DropdownItem from "../../Dropdown/Item/Dropdown.Item";
import SplitButton from "./SplitButton";
import { useCallback } from "react";
import { useFormContext } from "../../Form/Form";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "../../ModalWindow/ModalWindow";
import { useWindowTabContext } from "../../ModalWindow/WindowTabProvider";

let SaveAndContinue = (props) => {
	let translate = useTranslate();
	let { setSelectedTab } = useWindowTabContext();
	let { close, objectId, setObjectId, object } = useWindowContext();
	let { formStatus, onSubmit } = useFormContext();
	let disabled = !formStatus.valid || props.disabled;
	let save = useCallback(
		(e) => {
			onSubmit({ skipCloseFlag: true }).then((res) => {
				if (setObjectId && !objectId) {
					setObjectId(res?.objectId || res?.layoutId || res?.[object + "Id"]);
					setSelectedTab(2);
				}
			});
		},
		[object, objectId, onSubmit, setObjectId]
	);
	let saveAndClose = useCallback(() => {
		save();
		close();
	}, [close, save]);
	let saveAndContinue = save;
	return (
		<SplitButton
			data-testid="split-button-save-and-continue"
			title={translate("global.save")}
			type="submit"
			drop="up"
			{...props}
			disabled={disabled}
		>
			<DropdownItem data-testid="save-and-close-button" onClick={saveAndClose}>
				{translate("global.saveAndClose")}
			</DropdownItem>
			<DropdownItem data-testid="save-and-continue-button" onClick={saveAndContinue}>
				{translate("global.saveAndCont")}
			</DropdownItem>
		</SplitButton>
	);
};

export default SaveAndContinue;
