import Files from "react-butterfiles";
import { useCallback, useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import { useUpload } from "@clearpoint/providers/UploadProvider";
import PropTypes from "prop-types";
import styled from "styled-components";
import { toast } from "@clearpoint/services/toastService/index";

let StyledDiv = styled.div`
	background: #f8f8f8;
	border: 2px dashed #ddd;
	min-height: 72px;
	text-align: center;
	display: grid;
	place-items: center;
	padding: ${(props) => props.theme.smallSpace};
	cursor: pointer;
	user-select: none;
`;

let propTypes = {
	acceptedFileTypes: PropTypes.array,
	dropZoneText: PropTypes.string,
	id: PropTypes.string,
	maxSize: PropTypes.string,
	multipleFlag: PropTypes.bool,
	sizeExceededMessage: PropTypes.string,
	unsupportedFileTypeMessage: PropTypes.string,
	uploadOnDropFlag: PropTypes.bool,
};

let Upload = ({
	acceptedFileTypes,
	dropZoneText,
	id,
	maxSize,
	multipleFlag,
	sizeExceededMessage,
	unsupportedFileTypeMessage,
	uploadOnDropFlag,
}) => {
	let translate = useTranslate();
	let { setErrorMessage, setSelectedFileList, selectedFileList, setUploadProgress, uploadProgress, upload } =
		useUpload();

	let dropZoneTextStatus = useMemo(() => {
		if (uploadProgress !== null) {
			return uploadProgress === 100
				? translate("components.processingFile")
				: translate("components.uploadPercentComplete", { uploadProgress });
		}
		if (selectedFileList.length > 0) {
			return `${selectedFileList.length} ${translate("library.fileCountText")}`;
		}
		return dropZoneText;
	}, [dropZoneText, selectedFileList.length, translate, uploadProgress]);

	let onSuccess = useCallback(
		(files) => {
			setSelectedFileList(files.map((file) => file.src.file));
			if (uploadOnDropFlag) {
				setTimeout(upload);
			}
		},
		[setSelectedFileList, upload, uploadOnDropFlag]
	);

	let onError = useCallback(
		(errors) => {
			setUploadProgress(null);
			let type = errors[0].type;
			let errorMessageLookup = {
				maxSizeExceeded: sizeExceededMessage,
				unsupportedFileType: unsupportedFileTypeMessage,
			};
			let errorMessage = errorMessageLookup[type] || translate("global.error");
			setErrorMessage(errorMessage);
			toast.error(errorMessage);
		},
		[setErrorMessage, setUploadProgress, sizeExceededMessage, translate, unsupportedFileTypeMessage]
	);

	return (
		<Files
			id={id}
			multiple={multipleFlag}
			maxSize={maxSize || "5mb"}
			multipleMaxSize={maxSize || "5mb"}
			accept={acceptedFileTypes}
			onSuccess={onSuccess}
			onError={onError}
		>
			{({ browseFiles, getDropZoneProps }) => (
				<StyledDiv
					onClick={() => {
						setSelectedFileList([]);
						browseFiles();
						setErrorMessage(null);
					}}
					{...getDropZoneProps({ className: "imgdropzone" })}
				>
					{dropZoneTextStatus}
				</StyledDiv>
			)}
		</Files>
	);
};

Upload.propTypes = propTypes;

export default Upload;
