import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { theme } from "@clearpoint/old-theme/Theme";
import { CONTENT_BACKGROUND_COLOR, CONTENT_BORDER_COLOR, MENU_COLOR, NAV_BAR_COLOR } from "@clearpoint/utils";

let whiteColorValueList = ["white", "#FFFFFF", "#ffffff", "#FFF", "#fff"];

let replaceWhiteWithDefaultColor = (color, defaultColor) => {
	return whiteColorValueList.includes(color) ? defaultColor : color;
};

let useAccountColor = (color, defaultColor) => {
	let store = useOldQueryStore();
	let { session } = useOldSession();
	if (!store) throw new Error("Called outside of store context");
	if (!defaultColor) throw new Error("Define a default color for " + color);
	let { get } = store;
	let options = get({ object: "options" });
	let accountColor = session[color] || options?.options?.[color];
	if (color === "navbarColor" && accountColor) return replaceWhiteWithDefaultColor(accountColor, defaultColor);
	return accountColor || defaultColor;
};

let useContentBackgroundColor = () =>
	useAccountColor("contentBackgroundColor", theme[CONTENT_BACKGROUND_COLOR] || theme.lighterGray);
let useContentBorderColor = () => useAccountColor("contentBorderColor", theme[CONTENT_BORDER_COLOR] || theme.lightGray);
let useMenuColor = () => useAccountColor("menuColor", theme[MENU_COLOR] || theme.navDarkBackground);
let useNavBarColor = () => useAccountColor("navbarColor", theme[NAV_BAR_COLOR] || theme.darkGray);

export { useContentBackgroundColor, useContentBorderColor, useMenuColor, useNavBarColor };
