import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let SelectTimezone = (props) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let timezoneList = get({ object: "timezones" });

	let options = useMemo(() => {
		if (timezoneList === undefined) return;
		if (!timezoneList) return [];
		return timezoneList.map((tz) => ({ value: tz.timeZoneId, label: tz.name }));
	}, [timezoneList]);

	return (
		<FormSelect
			label={translate("myprofile.timezone")}
			options={options}
			placeholder={translate("login.selectTimezone")}
			{...props}
		/>
	);
};
export default SelectTimezone;
