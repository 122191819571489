import { useCallback } from "react";
import getDeepValue from "lodash/get";
import PropTypes from "prop-types";

import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import { usePodContext } from "../PodProvider";

let propTypes = {
	openAddSeriesModal: PropTypes.func,
};

let AddSeriesButton = ({ openAddSeriesModal }) => {
	let translate = useTranslate();
	let { closeEditMode } = usePodContext();
	let { formDirty, formValue } = useFormContext();
	// eslint-disable-next-line no-underscore-dangle
	let formMeasureDataDirtyFlag = !!getDeepValue(formDirty, "measureData") || formValue?._measureDataEdited;
	let {
		formStatus: { dirty: formDirtyFlag },
		resetForm,
	} = useFormContext();

	let conditionalOpenAddSeriesModal = useCallback(() => {
		if (formMeasureDataDirtyFlag) {
			toast.warning(translate("toaster.messages.global.saveBeforeAdding"));
		} else {
			if (formDirtyFlag) {
				resetForm();
			}
			closeEditMode();
			openAddSeriesModal();
		}
	}, [closeEditMode, formDirtyFlag, formMeasureDataDirtyFlag, openAddSeriesModal, resetForm, translate]);

	return (
		<IconButton
			onClick={conditionalOpenAddSeriesModal}
			name="add"
			iconTransform=""
			padding="1px 5px"
			display="inline"
		>
			{" " + translate("edit.addSeries")}
		</IconButton>
	);
};

AddSeriesButton.propTypes = propTypes;
export default AddSeriesButton;
