import PeriodBadge from "@clearpoint/old-theme/Badge/Badge.Period";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Hide from "@clearpoint/old-theme/Hide";
import { theme } from "@clearpoint/old-theme/Theme";

import { useNearestPeriodId } from "@clearpoint/hooks";
import { emptyObject } from "@clearpoint/utils";

import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";



let BadgeListPeriodBadge = () => {
	let { elementStatus } = useDetailPageData() || emptyObject;
	let {
		object,
		periodId: elementPeriodId,
		periodLocked: formPeriodLockedFlag,
	} = useFormValue("updates") || emptyObject;
	let formPeriodGroupId = useFormValue("periodGroupId");

	let scorecardFlag = object === "scorecard";
	let pagePeriodId = useNearestPeriodId();

	let periodId = elementStatus?.periodId ?? elementPeriodId;
	let periodGroupId = elementStatus?.periodGroupId ?? formPeriodGroupId;
	let periodLockedFlag = elementStatus ? elementStatus.periodLocked : formPeriodLockedFlag;

	return (
		<Hide margin="0" visible={!scorecardFlag}>
			<PeriodBadge
				highlightFlag={pagePeriodId !== periodId}
				periodId={periodId}
				periodGroupId={periodGroupId}
				periodLockedFlag={periodLockedFlag}
				size="small"
				display="inline"
				marginLeft={theme.smallSpace}
				name={elementStatus?.period}
			/>
		</Hide>
	);
};

export default BadgeListPeriodBadge;
