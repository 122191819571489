import ColorInput from "@clearpoint/old-theme/Form/Input/Color/FormInputColor";
import Column from "@clearpoint/old-theme/Column";
import { useTranslate } from "@clearpoint/translate";

let SingleColor = () => {
	let translate = useTranslate();

	return (
		<Column tiny={12}>
			<ColorInput label={translate("manage.chart.color")} name="chart.color1" />
		</Column>
	);
};

export default SingleColor;
