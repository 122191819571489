import { useMemo } from "react";
import PropTypes from "prop-types";

import AddButton from "@clearpoint/old-theme/Button/Button.Add";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterShowDeleted from "@clearpoint/old-theme/Filter/Filter.ShowDeleted";
import FormDragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";
import Loading from "@clearpoint/old-theme/Loading/index";
import NoResults from "@clearpoint/old-theme/NoResults";

import { useScorecardLockedFlag } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import CategoryListItem from "./CategoryListItem";
import EditCategoryModal from "./EditCategoryModal";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	children: PropTypes.node,
};
let FilterWrapper = ({ children }) => {
	let scorecardId = useFormValue("scorecardId");
	let { get } = useOldQueryStore();
	let categoryList = scorecardId ? get({ object: "category", scorecardId }) : [];
	return !categoryList ? <Loading /> : <FilterProvider data={categoryList}>{children}</FilterProvider>;
};
FilterWrapper.propTypes = propTypes;

let EditCategoriesTabContent = () => {
	let translate = useTranslate();
	let { data: filteredCategoryList, setData: setCategoryList } = useFilter();
	let scorecardId = useFormValue("scorecardId");
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let defaultValue = useMemo(
		() => ({
			object: "category",
			scorecardId,
		}),
		[scorecardId]
	);
	return (
		<>
			<Help feature="categories" />
			<FilterInput
				name="name"
				placeholder={`${translate("global.filter")} ${translate("categories")}...`}
				filterBy="name"
				right={
					<>
						<FilterShowDeleted />
						<AddButton
							disabled={scorecardLockedFlag}
							color="primary"
							ModalWindow={EditCategoryModal}
							defaultValue={defaultValue}
							scorecardId={scorecardId}
						/>
					</>
				}
			/>
			<NoResults />
			<FormDragAndDropList
				name="categorySortOrder"
				list={filteredCategoryList}
				listKey="categoryId"
				setList={setCategoryList}
			>
				{filteredCategoryList.map((x) => (
					<CategoryListItem disabled={scorecardLockedFlag} category={x} key={x.categoryId} />
				))}
			</FormDragAndDropList>
		</>
	);
};

let EditCategoriesTab = () => {
	return (
		<FilterWrapper>
			<EditCategoriesTabContent />
		</FilterWrapper>
	);
};

export default EditCategoriesTab;
