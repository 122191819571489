import Block from "../Block";
import LinkMap from "../LinkMap/LinkMap";
import ModalWindow from "./ModalWindow";
import ModalWindowContent from "./ModalWindow.Content";
import ModalWindowFooter from "./ModalWindow.Footer";
import ModalWindowHeader from "./ModalWindow.Header";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	object: PropTypes.string,
	objectId: PropTypes.number,
	periodId: PropTypes.number,
};
let LinkMapModalWindow = ({ object, objectId, periodId, ...props }) => {
	let translate = useTranslate();
	return (
		<ModalWindow noFormFlag {...props}>
			<ModalWindowHeader
				badgeIcon={object}
				badgeText={translate(object)}
				title={translate("edit.map.viewLinkMap")}
			/>
			<ModalWindowContent>
				<Block
					height="100%"
					width="100%"
					overflow="auto"
					backgroundColor={theme.white}
					padding={`${theme.space} ${theme.mediumSpace} ${theme.mediumSpace}`}
				>
					<LinkMap object={object} objectId={objectId} periodId={periodId} />
				</Block>
			</ModalWindowContent>
			<ModalWindowFooter />
		</ModalWindow>
	);
};
LinkMapModalWindow.propTypes = propTypes;
export default LinkMapModalWindow;
