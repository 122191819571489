import Block from "@clearpoint/old-theme/Block";
import { Container, Draggable } from "react-smooth-dnd";
import ElementTypeFilter from "./ElementTypeFilterSelect";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import useDropZoneHover from "./useDropZoneHover";
import useRemoveElementFromMap from "./useRemoveElementFromMap";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";

let getBody = () => document.body;

let ElementList = () => {
	let translate = useTranslate();
	let { data: filteredElementList } = useFilter();
	let { hoverFlag, onHover, offHover } = useDropZoneHover();
	useRemoveElementFromMap();
	return (
		<Block
			backgroundColor={theme.gray2}
			flexBasis="20%"
			overflowY="auto"
			minWidth="250px"
			height="100%"
			padding={theme.mediumSpace}
			paddingBottom="0px"
		>
			<Block paddingBottom={theme.space}>
				<FilterInput
					name="element"
					filterBy="name"
					noFilterStyleFlag
					placeholder={translate("layout.filterElements") + "..."}
				/>
				<ElementTypeFilter />
			</Block>
			<Block
				className="element-list"
				outline={hoverFlag ? `2px dashed ${theme.blueLink}` : undefined}
				outlineOffset={theme.tinySpace}
				onMouseOver={onHover}
				onMouseOut={offHover}
			>
				<Container
					groupName="map"
					behaviour="drop-zone"
					getGhostParent={getBody}
					onDragStart={onHover}
					onDragEnd={offHover}
					removeOnDropOut
				>
					{filteredElementList.map(
						(x) =>
							x.visible && (
								<Draggable key={x.object + x.objectId}>
									<StyleWrapper width="100%" ellipsisFlag padding="6px 12px" textAlign="left">
										<IconButton
											as="a"
											data-object={x.object}
											data-object-id={x.objectId}
											className="map-element"
											name={x.object}
											color="info"
											marginBottom={theme.smallSpace}
											noWrapFlag
										>
											&nbsp;&nbsp;{x.name}
										</IconButton>
									</StyleWrapper>
								</Draggable>
							)
					)}
				</Container>
			</Block>
		</Block>
	);
};

export default ElementList;
