import Alert from "../../../Alert/Alert";
import Block from "../../../Block";
import Hide from "../../../Hide";
import PageTitle from "../../../PageTitle/PageTitle";
import StatusIcon from "../../../StatusIcon";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	hidePageTitleFlag: PropTypes.bool,
	marginBottom: PropTypes.string,
	marginRight: PropTypes.string,
	name: PropTypes.string,
	parentName: PropTypes.string,
};

let FormFieldDisplayStatusId = ({ hidePageTitleFlag, marginBottom, marginRight, name, parentName }) => {
	let translate = useTranslate();
	let statusId = useFormValue(name);
	let objectName = useFormValue("name");
	let periodLocked = useFormValue(parentName ? `${parentName}.periodLocked` : "periodLocked");
	let period = useFormValue(parentName ? `${parentName}.period` : "period");
	return (
		<>
			<Block display="flex" alignItems="flex-start" marginBottom={marginBottom || theme.smallSpace}>
				<StatusIcon statusId={statusId} marginRight={marginRight} />
				<Hide visible={!hidePageTitleFlag}>
					<PageTitle title={objectName} />
				</Hide>
			</Block>
			{periodLocked && <Alert color="warning">{translate("edit.element.periodLocked", { period })}</Alert>}
		</>
	);
};

FormFieldDisplayStatusId.propTypes = propTypes;
export default FormFieldDisplayStatusId;
