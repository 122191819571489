import ColorList from "./ColorList";
import Help from "@clearpoint/old-theme/Help";
import TransposedSeriesList from "./TransposedSeriesList";
import PropTypes from "prop-types";

let propTypes = {
	chartFormatData: PropTypes.object,
	gaugeFlag: PropTypes.bool,
};

let TransposedChartSeriesTab = ({ chartFormatData, gaugeFlag }) => {
	return (
		<>
			<Help feature="chart-transposed" />
			<TransposedSeriesList gaugeFlag={gaugeFlag} />
			<ColorList chartFormatData={chartFormatData} />
		</>
	);
};

TransposedChartSeriesTab.propTypes = propTypes;
export default TransposedChartSeriesTab;
