import useCheckFeature from "./useCheckFeature";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyArray } from "@clearpoint/utils";

let useChartTypeList = () => {
	let { get } = useOldQueryStore();
	let checkFeature = useCheckFeature();
	let customChartTypeFlag = checkFeature("customCharts");
	let customChartTypeList = customChartTypeFlag ? get({ object: "customChartType" }) : [];
	let defaultChartTypeList = get({ object: "chartType" });
	customChartTypeList = customChartTypeList
		?.filter((x) => x.active)
		.map((x) => ({ ...x, customChartTypeId: x.customChartTypeId * -1, id: x.customChartTypeId * -1 }));
	defaultChartTypeList = defaultChartTypeList?.map((x) => ({ ...x, id: x.chartTypeId }));
	let combinedChartTypeList = [...(customChartTypeList || emptyArray), ...(defaultChartTypeList || emptyArray)];
	return {
		customChartTypeList: customChartTypeList || emptyArray,
		defaultChartTypeList: defaultChartTypeList || emptyArray,
		combinedChartTypeList,
	};
};

export default useChartTypeList;
