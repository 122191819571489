import CellWrapper from "../CellWrapper";
import FormFieldDisplayPeriodGroupId from "@clearpoint/old-theme/Form/Field/Display/PeriodGroupId";
import FormFieldPeriodGroupId from "@clearpoint/old-theme/Form/Field/PeriodGroupId";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let PeriodGroupCell = (props) => {
	let { editCellFlag, parentName } = props;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldPeriodGroupId compactFlag name={`${parentName}.periodGroupId`} parentName={parentName} />
			) : (
				<FormFieldDisplayPeriodGroupId name={`${parentName}.periodGroupId`} parentName={parentName} />
			)}
		</CellWrapper>
	);
};
PeriodGroupCell.propTypes = propTypes;

export default PeriodGroupCell;
