import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useMemo } from "react";
import allResultsFilteredFlagCallback from "./useNoResults.allResultsFilteredFlagCallback";
import noResultsFlagCallback from "./useNoResults.noResultsFlagCallback";
import { emptyObject } from "@clearpoint/utils";


export default function useNoResults(results) {
	let filter = useFilter();
	let { data: filteredResultList, deletedVisible } = filter || emptyObject;

	let allResultsFilteredFlag = useMemo(
		() => allResultsFilteredFlagCallback({ filter, results, filteredResultList, deletedVisible }),
		[deletedVisible, filter, filteredResultList, results]
	);

	let noResultsFlag = useMemo(
		() => noResultsFlagCallback({ results, allResultsFilteredFlag, filteredResultList, deletedVisible }),
		[allResultsFilteredFlag, deletedVisible, filteredResultList, results]
	);

	return { allResultsFilteredFlag, noResultsFlag };
}
