import Dropdown from "./Dropdown";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../Theme";

let StyledToggle = styled(Dropdown.Toggle)`
	margin: ${(props) => (props.$hideCaretFlag ? "0px" : props.margin || "0px")};
	padding: ${(props) => (props.$hideCaretFlag ? "0px" : props.padding || "6px 12px")};
	:after {
		display: ${(props) => (props.$hideCaretFlag ? "none" : props.display || "inline-block")};
	}
	${(props) => (theme.shadowCss)}
`;

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		"primary",
		"secondary",
		"success",
		"danger",
		"warning",
		"info",
		"light",
		"dark",
		"default",
		"nav",
		"neutral",
		"gray",
		"blank",
	]),
	"data-selected": PropTypes.bool,
	"data-test-id": PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	hideCaretFlag: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ref: PropTypes.object,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
};

let DropdownToggle = ({
	children,
	className,
	color,
	"data-selected": dataSelectedFlag,
	"data-testid": dataTestId,
	hideCaretFlag,
	id,
	ref,
	style,
	title,
}) => {
	return (
		<StyledToggle
			className={className}
			data-testid={dataTestId}
			data-selected={dataSelectedFlag}
			variant={color}
			id={id}
			ref={ref}
			$hideCaretFlag={hideCaretFlag}
			style={style}
			title={title}
		>
			{children}
		</StyledToggle>
	);
};

DropdownToggle.propTypes = propTypes;

export default DropdownToggle;
