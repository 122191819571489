import { useState } from "react";
import OptionsTest from "./Options";
import ObjectivesTest from "./Objectives";

const OPTIONS = "options";
const OBJECTIVES = "objectives";

let testLookup = {
	[OPTIONS]: OptionsTest,
	[OBJECTIVES]: ObjectivesTest,
};

let QueryTest = () => {
	let [test, setTest] = useState(OPTIONS);
	let Test = testLookup[test];

	return (
		<div style={{ margin: "20px", whiteSpace: "pre-wrap" }}>
			<div>
				<button onClick={() => setTest(OPTIONS)}>Options Test</button>
				<button onClick={() => setTest(OBJECTIVES)}>Objectives Test</button>
			</div>
			<br />
			<Test />
		</div>
	);
};

export default QueryTest;
