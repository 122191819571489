import { buildQueryString } from "@clearpoint/utils";

let generateBuildLinkFunction =
	(session) =>
	(x, page = "") => {
		let route = `#/index/${page}`;
		let queryString = "";

		if (x) {
			queryString = buildQueryString({
				commentId: x.commentId,
				object: x.object,
				layoutId: x.layoutId,
				mode: x.mode,
				objectId: x.objectId,
				periodId: x.linkPeriodId || session?.periodId || x.periodId,
				scorecardId: x.scorecardId,
				type: x.type,
				view: x.view,
			});
		}

		return `${route}${queryString}`;
	};

export default generateBuildLinkFunction;
