import FormOwnerSelect from "@clearpoint/old-theme/Form/Field/OwnerId";
import Input from "@clearpoint/old-theme/Input/Input";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let OwnerSelect = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();

	let userList = get({ object: "users" });
	let ownerId = useFormValue("ownerId");
	let owner = ownerId && userList.find((x) => x.userId === ownerId);

	let supervisorId = owner?.supervisorId;
	let supervisor = supervisorId && userList.find((x) => x.userId === supervisorId);

	return supervisor ? (
		<Input label={translate("goals.manager")} disabled value={supervisor?.fullName} />
	) : (
		<FormOwnerSelect name="supervisorId" label={translate("goals.manager")} />
	);
};

export default OwnerSelect;
