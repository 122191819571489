import FormSelect from "@clearpoint/old-theme/Form/Select/FormSelect";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";

let propTypes = {
	scorecardFlag: PropTypes.bool,
	milestoneFlag: PropTypes.bool,
	mapFlag: PropTypes.bool,
};
let SelectReferenceLayout = (props) => {
	let { get } = useOldQueryStore();
	let { scorecardId } = useOldSession().session;
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let objectType = useFormValue("_objectType");
	let layoutList = get({ object: `${objectType || "all"}Layout`, scorecardId });
	let objectTypeOptions = useMemo(() => {
		if (!layoutList) return;
		return layoutList
			.filter((x) => x.active)
			.map((x) => ({
				label: translateOnlyInsideCurlyBraces(x.name),
				value: x.layoutId,
			}));
	}, [layoutList, translateOnlyInsideCurlyBraces]);
	return <FormSelect options={objectTypeOptions} {...props} />;
};
SelectReferenceLayout.propTypes = propTypes;

export default SelectReferenceLayout;
