import EditButton from "@clearpoint/old-theme/Button/Button.Edit";
import LinkButton from "@clearpoint/old-theme/Button/Button.Link";
import ModalWindowEditElementOnClick from "@components/Elements/ModalWindow.EditElement.OnClick";
import TrashButton from "@clearpoint/old-theme/Button/Button.Trash";
import UnlinkButton from "@clearpoint/old-theme/ListItem/Link/UnlinkButton";
import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useCheckAccess } from "@clearpoint/hooks";


let propTypes = {
	clearLinkData: PropTypes.func,
	link: PropTypes.shape({
		access: PropTypes.string,
		fieldId: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
	}),
	unlink: PropTypes.func,
};

let Buttons = ({ clearLinkData, link, unlink }) => {
	let translate = useTranslate();
	let { editPodFlag } = usePodContext();
	let { inModalFlag, pagePeriodId } = useLocalState();

	let pageObject = useFormValue("object");
	let { access, fieldId, name, object: linkObject, objectId, scorecardId } = link;

	let unlinkObject = useCallback(() => unlink({ object: linkObject, objectId }), [linkObject, objectId, unlink]);

	let milestoneLinkOnInitiativePageFlag = linkObject === "milestone" && pageObject === "initiative";

	let checkAccess = useCheckAccess();
	let allowEditFlag = linkObject !== "category" && checkAccess({ access, action: "update", scorecardId });
	let allowDeleteFlag = checkAccess({ access, action: "edit", scorecardId }) && milestoneLinkOnInitiativePageFlag;
	let allowUnlinkFlag =
		[
			"objectives",
			"categories",
			"measures",
			"initiatives",
			"milestones",
			"actionItems",
			"risks",
			"parents",
			"children",
		].includes(fieldId) && !milestoneLinkOnInitiativePageFlag;

	return inModalFlag ? (
		<LinkButton object={linkObject} objectId={objectId} scorecardId={scorecardId} periodId={pagePeriodId} />
	) : editPodFlag ? (
		<>
			{allowDeleteFlag && (
				<TrashButton
					body={translate("edit.deleteConfirm", { object: name })}
					object={linkObject}
					objectId={objectId}
					onTrash={clearLinkData}
				/>
			)}
			{allowUnlinkFlag && <UnlinkButton fieldId={fieldId} name={name} unlinkObject={unlinkObject} />}
			{allowEditFlag && (
				<ModalWindowEditElementOnClick object={linkObject} objectId={objectId} onEdit={clearLinkData}>
					<EditButton />
				</ModalWindowEditElementOnClick>
			)}
		</>
	) : null;
};

Buttons.propTypes = propTypes;
export default Buttons;
