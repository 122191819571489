import Block from "../../Block";
import HTML from "../../HTML";
import IconButton from "../../Button/Button.Icon";
import ModalConfirmOnClick from "../../Modal/Modal.Confirm.OnClick";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let unlinkButtonPropTypes = {
	fieldId: PropTypes.string,
	name: PropTypes.string,
	noAccessFlag: PropTypes.bool,
	unlinkObject: PropTypes.func.isRequired,
};

let UnlinkButton = ({ fieldId, name, noAccessFlag, unlinkObject }) => {
	let translate = useTranslate();
	let showButtonFlag = !["childMeasures", "childInitiatives"].includes(fieldId) && !noAccessFlag;
	return showButtonFlag ? (
		<ModalConfirmOnClick
			body={translate("edit.unlinkConfirm")}
			confirmButtonColor="danger"
			confirmButtonText={translate("edit.unlink")}
			onConfirm={unlinkObject}
			title={`${translate("edit.unlink")} '${name}'`}
		>
			<IconButton data-testid="unlink-button" name="unlink" />
		</ModalConfirmOnClick>
	) : (
		<Block marginLeft="33px !important">
			<HTML>&nbsp;</HTML>
		</Block>
	);
};

UnlinkButton.propTypes = unlinkButtonPropTypes;

export default UnlinkButton;
