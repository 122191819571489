import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import isArray from "lodash/isArray";
import { emptyArray, emptyObject } from "@clearpoint/utils";


let useLinkData = ({ object, objectId, periodId }) => {
	let { get } = useOldQueryStore();
	let scorecardFlag = object === "scorecard";

	let linkListQuery = useMemo(() => {
		if (scorecardFlag) return null;
		if (object && objectId && periodId) {
			return { parent: object, parentId: objectId, object: "link", periodId };
		}
	}, [object, objectId, periodId, scorecardFlag]);

	let goalLinkListQuery = useMemo(() => {
		if (scorecardFlag) return null;
		if (object && objectId && periodId) {
			return { parent: object, parentId: objectId, object: "link", periodId, queryString: "?object=goal" };
		}
	}, [object, objectId, periodId, scorecardFlag]);

	let linkList = scorecardFlag ? emptyObject : linkListQuery && get(linkListQuery);
	let goalLinkList = scorecardFlag ? emptyObject : goalLinkListQuery && get(goalLinkListQuery);

	linkList = isArray(linkList) ? linkList : emptyArray;
	goalLinkList = isArray(goalLinkList) ? goalLinkList : emptyArray;

	return {
		linkList: [...linkList, ...goalLinkList],
		linkListQuery,
	};
};

export default useLinkData;
