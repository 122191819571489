import { useMemo } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";

import { createUniqueIdFromPod } from "@clearpoint/utils";

import Pod from "./Pod";
import useShouldRenderPod from "./useShouldRenderPod";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

// look into maps
let propTypes = {
	areaClass: PropTypes.oneOf(["area0", "area1", "area2", "area3"]),
	pageNumber: PropTypes.number,
};

let Area = ({ areaClass, pageNumber }) => {
	let { layout } = useDetailPageData();
	let areaList = layout.layout;
	let shouldRenderPod = useShouldRenderPod();
	let podList = useMemo(() => {
		let areaPodList = areaList
			.find((x) => x.page === pageNumber && x.class === areaClass)
			?.pods.map((pod) => {
				return {
					...pod,
					areaClass,
					key: createUniqueIdFromPod(pod),
					podWidth: Number.parseInt(pod.podWidth?.replace("col-xs-", ""), 10) || 12,
				};
			});

		return areaPodList?.filter((pod) => shouldRenderPod(pod));
	}, [areaClass, areaList, pageNumber, shouldRenderPod]);
	return podList && podList.length > 0 ? (
		<Block alignItems="flex-start" flexWrap="wrap" marginLeft="-15px" marginRight="-15px">
			<>
				<Block width="100%">
					{podList.map((pod, i) => {
						return <Pod key={pod.key} areaClass={pod.areaClass} {...pod} />;
					})}
					<Block $style="clear: both !important;" />
				</Block>
				<Block $style="clear: both !important;" />
			</>
		</Block>
	) : null;
};

Area.propTypes = propTypes;

export default Area;
