import cloneDeep from "lodash/cloneDeep";
import getDeepValue from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { emptyObject, removeUndefined } from "@clearpoint/utils";

let updateFormValidation = ({
	formStatus,
	formValidation,
	name,
	setFormValidation,
	validateAfterTouchedStatusFlag,
	validation,
	value,
}) => {
	let previousValidation = getDeepValue(formValidation, name)?._validation;
	let validationCopy = cloneDeep(validation || emptyObject);
	for (let key in validationCopy) {
		if ([false, null, undefined].includes(validationCopy[key])) validationCopy[key] = undefined;
	}
	if (
		(!validateAfterTouchedStatusFlag || formStatus.touched || value !== undefined) &&
		(previousValidation || !isEmpty(validationCopy)) &&
		!isEqual(previousValidation || emptyObject, removeUndefined(validationCopy))
	) {
		setFormValidation(name, { _validation: validationCopy });
	}
};
export default updateFormValidation;
