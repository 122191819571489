import Block from "@clearpoint/old-theme/Block";
import useSeriesValueAtCurrentPeriod from "./useSeriesValueAtCurrentPeriod";
import PropTypes from "prop-types";

let propTypes = {
	chart: PropTypes.shape({
		measureId: PropTypes.number,
		measureSeriesId: PropTypes.number,
	}),
};

let Metric = ({ chart }) => {
	let value = useSeriesValueAtCurrentPeriod(chart);

	return (
		<Block display="flex" justifyContent="center" alignItems="center" fontSize="72px" fontWeight="600">
			{value}
		</Block>
	);
};

Metric.propTypes = propTypes;
export default Metric;
