import { useCallback, useMemo } from "react";
import { findUpdates, removeEmpty, removeUnderscoredKeys, sleep } from "@clearpoint/utils";
import { useStateObject } from "@clearpoint/hooks";

let useOnSubmit = ({ changeKeyState, formContextRef, onSubmit, submitUnchangedFlag, resetAfterSubmitFlag }) => {
	let [{ onSubmitLookup, handlingSubmitFlag }, setState] = useStateObject({
		onSubmitLookup: {},
		handlingSubmitFlag: false,
	});
	let setOnSubmit = useCallback(
		({ name, onSubmit }) => {
			setState({
				onSubmitLookup: (x) => ({ ...x, [name]: onSubmit }),
			});
		},
		[setState]
	);
	let resetOnSubmit = useCallback(() => {
		setState({});
	}, [setState]);
	let handleSubmit = useCallback(
		async (e) => {
			if (e && !e.skipCloseFlag) {
				e.preventDefault();
				e.stopPropagation();
			}
			setState({ handlingSubmitFlag: true });
			await sleep(100); // wait for form updates
			let formContext = formContextRef.current;
			let { formDefaultValueRef, formValueRef, resetForm, saveStatusRef } = formContext;
			let submitValue = removeUnderscoredKeys(formValueRef.current);
			let defaultValueNoEmpty = removeEmpty(formDefaultValueRef.current, true);
			if (!submitUnchangedFlag) submitValue = findUpdates({ original: defaultValueNoEmpty, updated: submitValue });
			for (let onSubmitKey in onSubmitLookup) {
				await onSubmitLookup[onSubmitKey](submitValue, formContext);
			}
			if (e?.submitValueTransform) submitValue = e.submitValueTransform(submitValue);
			let response = onSubmit && (await onSubmit(submitValue, formContext, e?.skipCloseFlag));
			if (resetAfterSubmitFlag) resetForm();
			setState({ handlingSubmitFlag: false });
			if (saveStatusRef.current === "saving") {
				saveStatusRef.current = "saved";
				changeKeyState();
			}
			return response;
		},
		[changeKeyState, formContextRef, onSubmit, onSubmitLookup, resetAfterSubmitFlag, setState, submitUnchangedFlag]
	);
	return useMemo(
		() => ({ setOnSubmit, resetOnSubmit, handleSubmit, handlingSubmitFlag }),
		[handleSubmit, handlingSubmitFlag, resetOnSubmit, setOnSubmit]
	);
};

export default useOnSubmit;
