import { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";

import { useMenuColor } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import useTableStyle from "@components/Layouts/ViewLayout/ViewReport/useTableStyle";

let propTypes = {
	className: PropTypes.string,
	heading: PropTypes.shape({
		colSpan: PropTypes.number,
		fieldId: PropTypes.string,
		label: PropTypes.string,
		object: PropTypes.string,
		prefix: PropTypes.bool,
		rowSpan: PropTypes.number,
		tableStyleId: PropTypes.number,
	}),
	headerStyle: PropTypes.string,
};

let HeaderCell = ({ className, heading, headerStyle }) => {
	let translate = useTranslate();
	let menuColor = useMenuColor();
	let { customStyleFlag, layout, setLocalState, sortField, sortObject, sortOrder } = useLocalState();
	const reportTableStyleId = layout?.reportOptions?.tableStyleId;
	let { colSpan, fieldId, label, object, prefix: prefixFlag, rowSpan, tableStyleId: headerTableStyleId } = heading;
	const tableStyleId = headerTableStyleId || reportTableStyleId;
	let { reportType } = layout;
	let { exportFlag } = useOldSession().session;
	let sortableFlag =
		reportType !== "matrix" &&
		!exportFlag &&
		object === sortObject &&
		!fieldId.startsWith("calculatedField") &&
		![
			"comments",
			"collaborators",
			"discussion",
			"lastUpdatedBy",
			"lastUpdated",
			"elapsedDays",
			"period",
			"elapsedPercentComplete",
			"tags",
			"today",
			"totalDays",
			"variance",
		].includes(fieldId);
	let sortedFlag = fieldId === sortField && object === sortObject;
	let sortedAscending = sortedFlag && sortOrder === "asc";
	let sortedDescending = sortedFlag && sortOrder === "desc";
	let sort = useCallback(() => {
		if (!sortableFlag) {
			toast.warning(translate("layout.notSortable"));
			return;
		}

		let newSortOrderLookup = { default: "asc", asc: "desc", desc: "default" };
		let newSortOrder = sortedFlag ? newSortOrderLookup[sortOrder] : "asc";
		setLocalState({
			sortObject: object,
			sortOrder: newSortOrder,
			sortField: newSortOrder === "default" ? "default" : fieldId,
		});
	}, [fieldId, object, setLocalState, sortOrder, sortableFlag, sortedFlag, translate]);

	let ref = useRef();
	let { tableStyleObject, defaultStyle } = useTableStyle({ customStyleFlag, cellFlag: true, tableStyleId });

	useEffect(() => {
		const clearpointDefault = `background-color: ${menuColor}`;
		const defaultStyleCSS = defaultStyle?.headerCss || clearpointDefault;
		if (tableStyleId === -1) {
			ref.current.setAttribute("style", clearpointDefault);
		} else {
			const customStyle = (tableStyleId || tableStyleId === 0) && tableStyleObject?.headerCss;
			const style = customStyle || headerStyle || defaultStyleCSS;
			ref.current.setAttribute("style", style);
		}
	}, [customStyleFlag, defaultStyle?.headerCss, headerStyle, menuColor, tableStyleId, tableStyleObject?.headerCss]);

	return (
		<StyleWrapper cursor={sortableFlag ? "pointer" : undefined} zIndex="101 !important">
			<th className={className} colSpan={colSpan} onClick={sort} ref={ref} rowSpan={rowSpan}>
				<Block whiteSpace="initial">
					{prefixFlag ? `${translate(object)} ` : ""}
					{translate(label)}

					{!exportFlag && (sortedAscending || sortedDescending) && (
						<StyleWrapper float="right">
							<Icon
								name={sortedAscending ? "sortAscending" : sortedDescending ? "sortDescending" : "sort"}
								transform="down-3"
							/>
						</StyleWrapper>
					)}
				</Block>
			</th>
		</StyleWrapper>
	);
};
HeaderCell.propTypes = propTypes;
export default HeaderCell;
