import Files from "react-butterfiles";
import FormError from "../../Form/Form.Error";
import Label from "../../Form/Form.Label";
import { useEffect } from "react";
import { useFormContext } from "../../Form/Form";
import useFormError from "../../Form/hooks/useFormError";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";
import { useId, usePrevious } from "@clearpoint/hooks";


let StyledDiv = styled.div`
	background: #f8f8f8;
	border: 2px dashed #ddd;
	height: ${(props) => props.$height};
	text-align: center;
	padding: 25px;
	cursor: pointer;
	user-select: none;
	border-color: ${(props) => (props.errorVisible ? props.theme.danger : undefined)};
	&:focus {
		border-color: ${(props) => (props.errorVisible ? props.theme.danger : undefined)};
	}
`;
let propTypes = {
	binaryFlag: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	errorMessage: PropTypes.string,
	height: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	updateName: PropTypes.string,
};

let Image = ({ binaryFlag, children, className, errorMessage, height, label, name, required, updateName }) => {
	let id = useId();
	let { setFormValue, setFormValidation } = useFormContext();
	let errorVisible = !!useFormError(name);
	let previousRequired = usePrevious(required);
	useEffect(() => {
		if (required !== previousRequired)
			setFormValidation(name, {
				_validation: {
					required,
				},
			});
	}, [name, previousRequired, required, setFormValidation]);
	return (
		<>
			<Files
				id={`image-uploader-${id}`}
				name={name}
				multiple={false}
				maxSize="5mb"
				accept={["image/png", "image/jpg", "image/jpeg", "image/gif", "image/svg"]}
				onSuccess={(files) => {
					let binary = files[0].src.file;
					let imageString = files[0].src.base64;
					if (binaryFlag) setFormValue(`_binary.${name}`, binary);
					if (updateName) {
						setFormValue(updateName, imageString);
					}
					setFormValue(name, imageString);
				}}
				convertToBase64
			>
				{({ browseFiles, getDropZoneProps, getLabelProps }) => (
					<>
						{label && (
							<Label {...getLabelProps()} error={errorVisible}>
								{label}
							</Label>
						)}
						<StyledDiv
							errorVisible={errorVisible}
							onClick={browseFiles}
							$height={height}
							{...getDropZoneProps({ className: classNames("imgdropzone", className) })}
						>
							{children}
						</StyledDiv>
					</>
				)}
			</Files>
			{errorVisible && <FormError name={name} errorMessage={errorMessage} />}
		</>
	);
};

Image.propTypes = propTypes;

export default Image;
