import { useTranslate } from "@clearpoint/translate";

export default function useUserType(id) {
	let translate = useTranslate();
	let userTypes = [
		{
			userTypeId: 7,
			name: translate("userTypes.user7"),
			filterDisplay: translate("manage.users.filterByType.noAccess"),
		},
		{
			userTypeId: 1,
			name: translate("userTypes.user1"),
			filterDisplay: translate("manage.users.filterByType.administrators"),
		},
		{
			userTypeId: 9,
			name: translate("userTypes.user9"),
			filterDisplay: translate("manage.users.filterByType.scorecardAdmins"),
		},
		{
			userTypeId: 2,
			name: translate("userTypes.user2"),
			filterDisplay: translate("manage.users.filterByType.editors"),
		},
		{
			userTypeId: 3,
			name: translate("userTypes.user3"),
			filterDisplay: translate("manage.users.filterByType.updaters"),
		},
		{
			userTypeId: 4,
			name: translate("userTypes.user4"),
			filterDisplay: translate("manage.users.filterByType.browsers"),
		},
		{
			userTypeId: 10,
			name: translate("userTypes.user10"),
			filterDisplay: translate("manage.users.filterByType.byScorecard"),
		},
		{ userTypeId: 8, name: translate("userTypes.user8") },
	];

	if (id) {
		return userTypes.findAll((x) => x.userTypeId === id);
	}

	return userTypes;
}
