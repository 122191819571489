/* eslint-disable complexity */
import isMatch from "lodash/isMatch";

let refreshState = ({ ListOnlyFlag, action, newState, stopPropagationFlag, triggerRefresh }) => {
	let {storeKey} = action;
	delete action.storeKey;
	for (let [i, newStateItem] of newState.entries()) {
		let ids = {};
		if (newStateItem) {
			for (let key of [
				"storeKey",
				"object",
				"parent",
				"objectId",
				"parentId",
				"scorecardId",
				"periodId",
				"reportPeriodId",
			]) {
				let value = newStateItem[key] || newStateItem.data?.[key];
				if (value) ids[key] = value;
			}
			if (newStateItem.object === "logFiles") {
				// clear log files on refresh state
				triggerRefresh(i);
			} else if (ids.storeKey === storeKey) {
				// key match
				triggerRefresh(i);
			} else if (ids.storeKey.includes(storeKey)) {
				// key included (for scorecards, etc.)
				triggerRefresh(i);
			} else if (!stopPropagationFlag) {
				if (isMatch(ids, action) && ((ListOnlyFlag && !ids.objectId) || !ListOnlyFlag)) {
					// clear member match, unless ListOnly then List match
					triggerRefresh(i);
				} else if (action.objectId && !ids.objectId && action.object === ids.object) {
					// clear List match: check object
					triggerRefresh(i);
				} else if (action.object === "scorecard" && action.objectId && action.objectId === ids.scorecardId) {
					triggerRefresh(i);
				} else if (
					action.object &&
					action.objectId &&
					action.object === ids.parent &&
					action.objectId === ids.parentId
				) {
					// clear children of parents: check parentId
					triggerRefresh(i);
				} else if (action.object === ids.object && action.objectId && ids.parentId && !ids.objectId) {
					// clear child Lists matching object, when member is deleted
					triggerRefresh(i);
				} else if (action.object && !action.parent && !action.objectId && action.object === ids.parent) {
					// clear children of object refresh
					triggerRefresh(i);
				}
			}
		}
	}
};

export default refreshState;
