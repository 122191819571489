import * as Sentry from "@sentry/react";

let saveLinkData = async ({
	close,
	getIndirectLinkFlag,
	getPromise,
	linkObjectIdList,
	name,
	periodId,
	previousLinkList,
	scorecardList,
	setFormValue,
	statusList,
}) => {
	let previousChildLinkList = previousLinkList?.filter(
		(x) => x.fieldId?.startsWith("child") && x.fieldId !== "children"
	);
	let previousIndirectLinkList = previousLinkList?.filter((x) => getIndirectLinkFlag(x));
	if (linkObjectIdList) {
		let lookup = {};
		let linkList = [];
		for (let linkId of linkObjectIdList) {
			if (!lookup[linkId.scorecardId + linkId.object]) {
				lookup[linkId.scorecardId + linkId.object] = await getPromise({
					scorecardId: linkId.scorecardId,
					object: linkId.object,
					periodId,
				});
			}

			let link = previousLinkList.find(
				(x) => x.object === linkId.object && x.objectId === linkId.objectId && !getIndirectLinkFlag(x)
			);

			if (!link) {
				link = lookup[linkId.scorecardId + linkId.object]?.find?.((x) => x.objectId === linkId.objectId);
			}

			if (link && !linkId.disabled && !linkList.some((x) => x.objectId === link.objectId))
				linkList.push({
					...link,
					scorecardId: link.scorecardId || linkId.scorecardId,
					linkType: linkId.linkType,
				});
			if (!link) {
				let errorMessage = `No link found for linkId: ${JSON.stringify(linkId)}`;
				Sentry.captureException(new Error(errorMessage));
				console.log(errorMessage);
			}
		}
		linkList = linkList.map((x) =>
			x.object === "category"
				? {
						fieldId: "categories",
						scorecard: scorecardList?.find((y) => y.scorecardId === x.scorecardId)?.name,
						...x,
						linkType: undefined,
				  }
				: {
						fieldId: x.linkType === "child" ? "children" : x.linkType + "s",
						score: statusList?.find((y) => y.statusId === x.statusId)?.score ?? null,
						...x,
						linkType: undefined,
				  }
		);
		linkList = [...linkList, ...previousChildLinkList, ...previousIndirectLinkList];
		setFormValue(name, linkList);
	} else {
		setFormValue(name, previousChildLinkList);
	}
	close();
};

export default saveLinkData;
