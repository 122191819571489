import Block from "@clearpoint/old-theme/Block";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import PropTypes from "prop-types";
import { formatNumber } from "@clearpoint/utils";

let propTypes = {
	series: PropTypes.shape({
		evaluated: PropTypes.bool,
		name: PropTypes.string,
		numberFormat: PropTypes.object,
		statusId: PropTypes.number,
		seriesDataTypeId: PropTypes.number,
		value: PropTypes.number,
	}),
};

let Row = ({ series }) => {
	let { evaluated: evaluatedFlag, name, numberFormat, statusId, seriesDataTypeId, value } = series;
	return (
		<tr>
			<StyleWrapper width="50%">
				<td>{name}</td>
			</StyleWrapper>
			<td>
				<Block display="flex">
					{evaluatedFlag && (
						<Block display="flex" alignItems="center">
							<StatusIcon statusId={statusId} size="small" />
						</Block>
					)}
					<Block marginLeft="auto">
						{seriesDataTypeId === 11
							? value
							: value && formatNumber({ number: value, numberFormat: numberFormat })}
					</Block>
				</Block>
			</td>
		</tr>
	);
};

Row.propTypes = propTypes;
export default Row;
