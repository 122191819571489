import { Redirect, useLocation } from "react-router-dom";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { redirect } from "@clearpoint/utils";

let Main = () => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let location = useLocation();
	let profile = session.success && get({ object: "profile" });
	let scorecardList = session.success && get({ object: "scorecard" });
	let { path, queryString } = redirect({ session, profile, location, scorecardList });
	let redirectFlag = !session.success || !!profile;
	return redirectFlag ? <Redirect to={session.success ? `/index/${path}` : `/login${queryString}`} /> : null;
};

export default Main;
