import AdvancedOptionsTab from "./AdvancedOptionsTab";
import ChartAxisFormatTab from "./ChartAxisFormatTab";
import ChartPeriodsTab from "./ChartPeriodsTab";
import ChartPreview from "./ChartPreview";
import ChartSeriesTab from "./ChartSeriesTab";
import ChartTab from "./ChartTab";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useUnmount } from "react-use";
import useWatchChartPeriods from "@clearpoint/old-theme/Form/hooks/useWatchChartPeriods";
import useWatchChartTypes from "@clearpoint/old-theme/Form/hooks/useWatchChartTypes";
import { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import PropTypes from "prop-types";
import { beforeChartSubmit, noop } from "@clearpoint/utils";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	periodId: PropTypes.number,
};

let EditChartModalDisplay = ({ periodId }) => {
	let translate = useTranslate();
	let { clear, get } = useOldQueryStore();
	let { chartFormat, colors: colorList, measureId, name, objectId } = useFormValue();
	let { newObjectFlag, setFormValue } = useFormContext();
	let { loadingFlag } = useWindowContext();
	let [chartFormatData, setChartFormatData] = useState({});

	let showPreviewFlag = !loadingFlag && chartFormat;
	let headerTitle = newObjectFlag && !name ? translate("edit.element.addChart") : name;

	// SET COLORS AND/OR FORMAT IN FORM
	let chartPeriodData = objectId && periodId && get({ object: "chart", objectId, periodId });
	useEffect(() => {
		if (chartPeriodData) {
			if (!colorList) setFormValue("colors", chartPeriodData.colors);
			if (!chartFormat) setFormValue("chartFormat", chartPeriodData.chartFormat);
		}
	}, [chartFormat, chartPeriodData, colorList, setFormValue]);

	// MODAL-SPECIFIC HOOKS
	useWatchChartPeriods();
	let { gaugeFlag } = useWatchChartTypes();

	// SAVE MODAL
	let [{ savedFlag, saveModalVisible }, setState] = useStateObject({
		savedFlag: false,
		saveModalVisible: false,
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let promptSave = useCallback(
		newObjectFlag
			? (selectTab) => {
					selectTabRef.current = selectTab;
					setState({
						saveModalVisible: true,
					});
			  }
			: undefined,
		[newObjectFlag]
	);

	let selectTabRef = useRef(noop);
	let onSaveModalSave = useCallback(
		(response) => {
			setFormValue("chartSeries", response.chartSeries);
			selectTabRef.current && selectTabRef.current();
			setState({
				savedFlag: true,
			});
		},
		[setFormValue, setState]
	);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);

	useUnmount(() => {
		if (savedFlag) clear({ parent: "measure", parentId: measureId, object: "chart" });
	});

	return (
		<>
			<ModalWindowHeader
				data-testid="edit-chart-modal"
				badgeIcon="charts"
				badgeText={translate("global.chart")}
				title={headerTitle}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<ChartTab title={translate("global.chart")} setChartFormatData={setChartFormatData} />
					<ChartSeriesTab
						title={translate("manage.chart.chartSeries")}
						onClick={promptSave}
						chartFormatData={chartFormatData}
						gaugeFlag={gaugeFlag}
					/>
					<ChartPeriodsTab title={translate("chart.chartPeriods")} onClick={promptSave} />
					<ChartAxisFormatTab title={translate("chart.chartAxisFormat")} onClick={promptSave} />
					<AdvancedOptionsTab title={translate("chart.advancedOptions")} onClick={promptSave} />
				</ModalWindowTabContainer>
				{showPreviewFlag && (
					<ChartPreview chartFormatData={chartFormatData} setChartFormatData={setChartFormatData} />
				)}
				<FormModalSave
					submitUnchangedFlag
					beforeSubmit={beforeChartSubmit}
					confirmButtonText={translate("global.save")}
					closeButtonText={translate("global.cancel")}
					toastMessage={translate("edit.chart.chartAdded")}
					parent="measure"
					parentId={measureId}
					object="chart"
					onSave={onSaveModalSave}
					modalVisible={saveModalVisible}
					saveButtonColor="info"
					close={closeSaveModal}
				/>
			</ModalWindowContent>
			<ModalWindowFooter saveAndContinueFlag />
		</>
	);
};

EditChartModalDisplay.propTypes = propTypes;
export default EditChartModalDisplay;
