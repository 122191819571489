import Block from "@clearpoint/old-theme/Block";
import ChecklistProvider from "@clearpoint/providers/ChecklistProvider";
import { Container } from "react-smooth-dnd";
import Help from "@clearpoint/old-theme/Help";
import Image from "@clearpoint/old-theme/Image";
import Loading from "@clearpoint/old-theme/Loading/index";
import MapElement from "./MapElement";
import MapToolbar from "./MapToolbar";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import useDropZoneHover from "./useDropZoneHover";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import usePlaceElementOnMap from "./usePlaceElementOnMap";
import { theme } from "@clearpoint/old-theme/Theme";
import { useBuildImagePath, useFilterByMenu, useImageDimensions, useMeasure, useStateObject } from "@clearpoint/hooks";


let getBody = () => document.body;

let Map = () => {
	let { background, backgroundColor, backgroundHeight, backgroundWidth, name } = useFormValue();
	let buildImagePath = useBuildImagePath();
	if (buildImagePath && background) background = buildImagePath(background);
	let { height, width } = useImageDimensions(background);
	let formElementList = useFormValue("elements");
	let [{ imageLoadedFlag }, setState] = useStateObject({
		imageLoadedFlag: false,
	});
	let setImageLoadedFlagTrue = useCallback(() => setState({ imageLoadedFlag: true }), [setState]);
	let { height: containerHeight, width: containerWidth } = useMeasure({ query: ".map-background-edit" }) || {};
	let { hoverFlag, onHover, offHover } = useDropZoneHover();
	containerHeight -= 15; // padding
	height = height || backgroundHeight;
	width = width || backgroundWidth;
	let imageRatio = height / width;
	let containerRatio = containerHeight / containerWidth;
	let displayHeight, displayWidth;
	if (height && width) {
		if (imageRatio > containerRatio) {
			displayHeight = containerHeight;
			displayWidth = Math.round(displayHeight / imageRatio);
		} else {
			displayWidth = containerWidth;
			displayHeight = Math.round(displayWidth * imageRatio);
		}
	} else {
		displayHeight = containerHeight;
		displayWidth = containerWidth;
	}
	usePlaceElementOnMap({ displayWidth, displayHeight });
	let MapBackground = background ? Image : Block;
	imageLoadedFlag = background ? imageLoadedFlag : true;
	let activeElementList = useFilterByMenu(formElementList);
	return (
		<ChecklistProvider>
			<Block
				backgroundColor={theme.white}
				width="80%"
				maxWidth="80%"
				padding={`${theme.space} 0px 0px ${theme.space}`}
				overflowY="hidden"
				overflowX="visible"
				display="flex"
				height="100%"
				flexDirection="column"
			>
				<Block marginRight={theme.space}>
					<Help section="edit-map" feature="strategy-map" />
					<MapToolbar />
				</Block>
				{(background || backgroundColor) && (
					<>
						{!imageLoadedFlag && <Loading />}
						<Block
							opacity={imageLoadedFlag ? 1 : 0}
							flex="1"
							overflow="hidden"
							className="map-background-edit"
							display="flex"
							alignItems="center"
							justifyContent="center"
							$style={`.smooth-dnd-container { margin-right: ${theme.space}; }`}
						>
							<Container
								groupName="map"
								behaviour="drop-zone"
								getGhostParent={getBody}
								onDragStart={onHover}
								onDragEnd={offHover}
								removeOnDropOut
							>
								<StyleWrapper
									boxShadow={`5px 5px 3px ${theme.deactivated}`}
									backgroundColor={!background ? backgroundColor : theme.lightGray5}
									marginBottom={theme.space}
									outline={hoverFlag ? "2px dashed blue" : undefined}
									outlineOffset="-2px"
								>
									<MapBackground
										className="map-background-image-edit"
										alt={name}
										src={background}
										width={`${displayWidth}px`}
										height={`${displayHeight}px`}
										maxHeight="100%"
										maxWidth="100%"
										onLoad={setImageLoadedFlagTrue}
										onMouseOver={onHover}
										onMouseOut={offHover}
									/>
								</StyleWrapper>
								{activeElementList?.map((element) => {
									let left = `${Math.round((element.xPos / 1024) * displayWidth)}px`;
									let top = `${Math.round((element.yPos / 768) * displayHeight)}px`;
									return (
										<MapElement
											key={element.object + element.objectId}
											name={element.name}
											object={element.object}
											objectId={element.objectId}
											left={left}
											top={top}
											displayHeight={displayHeight}
											displayWidth={displayWidth}
										/>
									);
								})}
							</Container>
						</Block>
					</>
				)}
			</Block>
		</ChecklistProvider>
	);
};

export default Map;
