import { sleep } from "@clearpoint/utils";

let getThrottle = (requestLookup, getRequestCount) => async (requestFunction, key) => {
	requestLookup[key] = null;
	while (getRequestCount() > 10) {
		await sleep(50);
	}
	if (requestLookup[key] === "CANCEL") {
		delete requestLookup[key];
		throw new Error("Request canceled during httpService throttle");
	}
	if (requestLookup[key]) return requestLookup[key];
	requestLookup[key] = requestFunction();
	return requestLookup[key];
};

export default getThrottle;
