import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectStatusDisplay = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("layout.orgDefault"), value: "default" },
			{ label: translate("layout.color"), value: "color" },
			{ label: translate("layout.icon"), value: "icon" },
			{ label: translate("global.none"), value: "none" },
		],
		[translate]
	);
	return <Select options={options} {...props} />;
};

export default SelectStatusDisplay;
