import { useCallback, useMemo, useState } from "react";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { theme } from "@clearpoint/old-theme/Theme";

import {
	useCheckAccess,
	useCheckFeature,
	useClearUpdatedLinks,
	useFormatDate,
	useStateObject,
} from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray, splitEditAndUpdateFieldData } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import EditFieldsTab from "./EditFieldsTab";
import LinkMapTab from "./LinkMapTab";
import LinksTab from "./LinksTab";
import UpdateDataTab from "./UpdateDataTab";
import UpdateFieldsTab from "./UpdateFieldsTab";
import useCheckApproval from "@clearpoint/hooks-dir/useCheckApproval";
import useToastApproval from "@clearpoint/hooks-dir/useToastApproval";
import { useOldSession } from "@clearpoint/old-session/index";
import ChartsTab from "@components/Elements/Shared/ChartsTab";
import NotificationsTab from "@components/Elements/Shared/NotificationsTab";
import SeriesTab from "@components/Elements/Shared/SeriesTab";

let editMilestonesModalContentPropTypes = {
	addFlag: PropTypes.bool,
	approvalRequired: PropTypes.bool,
	linkList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	onAdd: PropTypes.func,
};
let EditMilestonesModalContent = ({ addFlag, approvalRequired, linkList, onAdd }) => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { access, name, initiativeId, objectId } = useFormValue();
	let { newObjectFlag } = useFormContext();
	let editFlag = objectId ? checkAccess({ access, action: "edit" }) : true;
	let { clear, get } = useOldQueryStore();

	let checkFeature = useCheckFeature();
	let evaluationEnabledFlag = checkFeature("initiativeEvaluation");

	let options = get({ object: "options" });
	let evaluatedFlag = options?.options?.evaluations?.some(({ object, active }) => object === "milestone" && active);
	let periodLockedFlag = useFormValue("updates.periodLocked");
	let period = useFormValue("updates.period");
	let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
		saveModalVisible: false,
		savedFlag: false,
	});
	let onSave = useCallback(
		(response) => {
			if (onAdd) {
				onAdd(response);
			}
			setState({ savedFlag: true });
		},
		[onAdd, setState]
	);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);

	let openSaveModal = useCallback(
		(selectTab) => {
			if (newObjectFlag && approvalRequired) {
				toast.warning(translate("approvals.changesAfterApproval"));
			} else if (newObjectFlag) {
				setState({ saveModalVisible: true });
			} else {
				selectTab();
			}
		},
		[approvalRequired, newObjectFlag, setState, translate]
	);
	useUnmount(() => {
		if (savedFlag) clear({ object: "milestone", objectId });
	});

	let updateFieldsTabTitle = useMemo(
		() =>
			periodLockedFlag ? (
				<>
					<Icon name="locked" size="tiny" marginRight={theme.smallSpace} />
					{`${period} ${translate("global.locked")}`}
				</>
			) : (
				translate("edit.element.updateFields")
			),
		[period, periodLockedFlag, translate]
	);

	return (
		<>
			<ModalWindowHeader
				badgeIcon="milestones"
				badgeText={translate("milestone")}
				title={addFlag ? translate("edit.addElement", { element: "milestone" }) : name}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					{editFlag && (
						<EditFieldsTab
							addFlag
							title={translate("edit.addElement", { element: "milestone" })}
							visible={addFlag}
						/>
					)}
					<UpdateFieldsTab
						onClick={openSaveModal}
						title={updateFieldsTabTitle}
						periodLockedFlag={periodLockedFlag}
					/>
					{editFlag && <EditFieldsTab title={translate("edit.element.editFields")} visible={!addFlag} />}
					{evaluatedFlag && evaluationEnabledFlag && (
						<UpdateDataTab onClick={openSaveModal} title={translate("edit.element.updateElementData")} />
					)}
					{editFlag && evaluatedFlag && evaluationEnabledFlag && (
						<SeriesTab onClick={openSaveModal} title={translate("global.series")} />
					)}
					{editFlag && evaluatedFlag && evaluationEnabledFlag && (
						<ChartsTab onClick={openSaveModal} title={translate("global.charts")} />
					)}
					{editFlag && <LinksTab onClick={openSaveModal} title={translate("edit.links")} />}
					{editFlag && linkList && linkList.length > 0 && (
						<LinkMapTab onClick={openSaveModal} title={translate("edit.map.linkMap")} />
					)}
					<NotificationsTab onClick={openSaveModal} title={translate("global.notifications")} />
				</ModalWindowTabContainer>
				<FormModalSave
					submitUnchangedFlag
					parent="initiative"
					approvalRequired={approvalRequired}
					parentId={initiativeId}
					object="milestone"
					objectId={objectId}
					onSave={onSave}
					modalVisible={saveModalVisible}
					close={closeSaveModal}
				/>
			</ModalWindowContent>
			<ModalWindowFooter saveAndContinueFlag={!approvalRequired} />
		</>
	);
};
EditMilestonesModalContent.propTypes = editMilestonesModalContentPropTypes;

let propTypes = {
	objectId: PropTypes.number,
	onAdd: PropTypes.func,
	onEdit: PropTypes.func,
	parentId: PropTypes.number,
	scorecardId: PropTypes.number,
};
let EditMilestonesModal = ({ onAdd, onEdit, ...props }) => {
	let formatDate = useFormatDate();
	let translate = useTranslate();
	let [objectId, setObjectId] = useState(props.objectId);
	let { clear, get } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId, scorecardId } = session;
	let milestoneData = objectId && get({ object: "milestone", objectId });
	let milestoneUpdateData = objectId && periodId && get({ object: "milestone", objectId, periodId });
	let customFieldData = objectId
		? get({ object: "customField", parent: "milestone", parentId: objectId, periodId })
		: get({ object: "customField", queryString: "?object=milestone" });
	let linkList = objectId && periodId && get({ parent: "milestone", parentId: objectId, object: "link", periodId });
	let shadowMeasureId = milestoneData?.shadowMeasureId;
	let measureSeriesList =
		shadowMeasureId && get({ parent: "measure", parentId: shadowMeasureId, object: "measureSeries" });
	let chartList = shadowMeasureId && get({ parent: "measure", parentId: shadowMeasureId, object: "chart" });
	let loadingFlag = objectId && [milestoneData, milestoneUpdateData, customFieldData, linkList].includes(undefined);
	let clearUpdatedLinks = useClearUpdatedLinks();
	let toastApproval = useToastApproval();
	let approvalRequired = useCheckApproval({ scorecardId })({ type: objectId ? "edits" : "adds" });

	let defaultValue = useMemo(() => {
		if (milestoneData && customFieldData) {
			let { editFieldData: editCustomFieldData, updateFieldData: updateCustomFieldData } =
				splitEditAndUpdateFieldData(customFieldData);
			return {
				...milestoneData,
				...editCustomFieldData,
				updates: { ...milestoneUpdateData, ...updateCustomFieldData },
				links: linkList,
				seriesSortOrder: measureSeriesList?.map((x) => x.measureSeriesId),
				chartSortOrder: chartList?.map((x) => x.chartId),
			};
		}
		return {
			collaborators: emptyArray,
			completedDate: formatDate(new Date(), "apiDateWithoutTimezone"),
			endDate: formatDate(lastDayOfMonth(new Date()), "apiDateWithoutTimezone"),
			initiativeId: props.parentId,
			object: "milestone",
			scorecardId: props.scorecardId,
			startDate: formatDate(startOfMonth(new Date()), "apiDateWithoutTimezone"),
		};
	}, [
		chartList,
		customFieldData,
		formatDate,
		linkList,
		measureSeriesList,
		milestoneData,
		milestoneUpdateData,
		props.parentId,
		props.scorecardId,
	]);

	let onSubmit = useCallback(
		(_submitValue, response) => {
			if (!objectId && onAdd) {
				onAdd(response);
			} else if (objectId) {
				clear({ object: "detailLayout" });
				if (onEdit) onEdit();
			}
			let { changeQueued } = toastApproval({ response });
			if (!changeQueued) {
				toast.success(
					translate(objectId ? "toaster.messages.objects.objectUpdated" : "toaster.messages.objects.objectAdded", {
						object: "milestone",
					})
				);
				clearUpdatedLinks({ newLinkList: _submitValue?.links, oldLinkList: linkList });
			}
		},
		[clear, clearUpdatedLinks, linkList, objectId, onAdd, onEdit, toastApproval, translate]
	);
	return (
		<ModalWindowEdit
			size="large"
			{...props}
			color="desaturatedNormal"
			defaultValue={defaultValue}
			loadingFlag={loadingFlag}
			modalVisible={props.modalVisible}
			object="milestone"
			objectId={objectId}
			onSubmit={onSubmit}
			parent="initiative"
			setObjectId={setObjectId}
			submitUnchangedFlag={!objectId}
			updateFlag
		>
			<EditMilestonesModalContent
				approvalRequired={approvalRequired}
				addFlag={!objectId}
				linkList={linkList}
				onAdd={onAdd}
			/>
		</ModalWindowEdit>
	);
};
EditMilestonesModal.propTypes = propTypes;
export default EditMilestonesModal;
