import { memo,useCallback, useEffect, useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";
import getDeepValue from "lodash/get";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";
import styled from "styled-components";

import { useEffectOnce, useId, usePrevious } from "@clearpoint/hooks";

import Check from "../Check/Check";
import { useFormContext } from "../Form/Form";
import useFormValue from "../Form/hooks/useFormValue";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFilter } from "@clearpoint/providers/FilterProvider";

let StyledCheck = styled(Check)`
	margin-bottom: ${(props) => props.marginBottom};
	display: ${(props) => props.display};
`;

let propTypes = {
	checked: PropTypes.bool,
	"data-testid": PropTypes.string,
	disabled: PropTypes.bool,
	formFlag: PropTypes.bool,
	objectId: PropTypes.any,
	onClick: PropTypes.func,
};

let ChecklistCheck = ({
	checked,
	"data-testid": dataTestId,
	disabled,
	formFlag,
	objectId,
	onClick,
	name,
	scorecardId,
	linkType,
	...props
}) => {
	if (Object.keys(props).includes("value")) checked = props.value;
	let { checklist, setChecklist } = useChecklist();
	let id = useId();
	id = objectId || id;
	let filter = useFilter();

	if (filter?.deletedVisible) disabled = true;
	let setFormValue;
	let formContext = useFormContext();
	let formValue = useFormValue();
	let valueInForm;

	if (formFlag) {
		({ setFormValue } = formContext);
		valueInForm = getDeepValue(formValue, name);
	}
	let checklistItem = checklist.find((x) => x.id === id);

	// determine checked from lists
	let checkedFlag = useMemo(() => {
		return checklistItem?.checked;
	}, [checklistItem]);

	useEffect(() => {
		if (
			formFlag &&
			valueInForm !== undefined &&
			checklistItem?.checked !== undefined &&
			valueInForm !== checklistItem?.checked
		) {
			setFormValue(name, !!checklistItem?.checked);
		}
	}, [checklistItem, formFlag, name, setFormValue, valueInForm]);

	// populate checklist
	useEffectOnce(() => {
		setChecklist((checklist) => {
			let index = checklist.findIndex((x) => x.id === id);
			let check = index !== -1 && checklist[index];
			if (index === -1) {
				return [...checklist, { id, scorecardId, linkType, checked, name: formFlag ? name : undefined }];
			} else if (check?.unmountedFlag) {
				checklist = cloneDeep(checklist);
				if (checked) checklist[index].checked = true;
				if (!check.name && formFlag) checklist[index].name = name;
				checklist[index].unmountedFlag = false;
				return checklist;
			}
			return checklist;
		});
	});

	// controlled
	useEffect(() => {
		setChecklist((checklist) => {
			let index = checklist.findIndex((x) => x.id === id);
			let check = index !== -1 && checklist[index];
			if (!check || checked === undefined || check.checked === checked) return checklist;
			checklist = cloneDeep(checklist);
			checklist[index].checked = checked;
			return checklist;
		});
	}, [checked, id, setChecklist]);

	useUnmount(() => {
		setChecklist((checklist) => {
			let index = checklist.findIndex((x) => x.id === id);
			let check = index !== -1 && checklist[index];
			if (check && !check.unmountedFlag) {
				checklist = cloneDeep(checklist);
				checklist[index].unmountedFlag = true;
				return checklist;
			}
			return checklist;
		});
	});

	// toggle checked
	let handleClick = useCallback(
		(e) => {
			if (onClick) onClick(checkedFlag);
			setChecklist((checklist) =>
				checklist.map((x) => {
					let checkItem = { ...x };
					if (checkItem.id === id) {
						checkItem.checked = !checkItem.checked;
					}
					return checkItem;
				})
			);
		},
		[checkedFlag, id, onClick, setChecklist]
	);

	let previousCheckedFlag = usePrevious(checkedFlag);
	useEffect(() => {
		if (onClick && !!previousCheckedFlag !== !!checkedFlag) onClick(checkedFlag);
	}, [checkedFlag, onClick, previousCheckedFlag]);

	return (
		<StyledCheck
			checked={checkedFlag}
			data-testid={dataTestId || "checklist-check"}
			onClick={handleClick}
			disabled={disabled}
			name={name}
			{...props}
		/>
	);
};

ChecklistCheck.propTypes = propTypes;

export default memo(ChecklistCheck);
