import { useEffect, useRef, useCallback } from "react";

let useOnEvent = ({ cleanupFunction, dependencyList, eventName, handlerFunction, setupFunction, eventTarget }) => {
	let handlerRef = useRef(handlerFunction);
	let setupRef = useRef(setupFunction);
	let cleanupRef = useRef(cleanupFunction);

	let checkDependencyList = useCallback(() => {
		if (!dependencyList) return true;
		return dependencyList.every((x) => !!x);
	}, [dependencyList]);

	useEffect(() => {
		handlerRef.current = handlerFunction;
		setupRef.current = setupFunction;
		cleanupRef.current = cleanupFunction;
	}, [handlerFunction, setupFunction, cleanupFunction]);

	useEffect(() => {
		if (!checkDependencyList() || !(setupRef.current && cleanupRef.current)) return;
		if (setupRef.current) setupRef.current();
		return () => {
			if (cleanupRef.current) cleanupRef.current();
		};
	}, [checkDependencyList]);

	useEffect(() => {
		if (!eventName || !checkDependencyList() || !handlerRef.current) return;
		let handler = handlerRef.current;
		if (eventTarget) {
			eventTarget.addEventListener(eventName, handler);
			return () => {
				eventTarget.removeEventListener(eventName, handler);
			};
		}
		document.addEventListener(eventName, handler);
		return () => {
			document.removeEventListener(eventName, handler);
		};
	}, [checkDependencyList, dependencyList, eventName, eventTarget]);
};

export default useOnEvent;
