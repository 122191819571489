import FormFieldDisplayRecommendations from "@clearpoint/old-theme/Form/Field/Display/Recommendations";
import FormFieldRecommendations from "@clearpoint/old-theme/Form/Field/Recommendations";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let Recommendations = ({ editPodFlag, name }) =>
	editPodFlag ? <FormFieldRecommendations name={name} /> : <FormFieldDisplayRecommendations name={name} />;

Recommendations.propTypes = propTypes;
export default Recommendations;
