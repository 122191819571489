import { useCallback, useDeferredValue, useEffect } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import { useNoResults, usePreviousDefined } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { calculatePageOnRowCountChange, emptyArray, emptyObject } from "@clearpoint/utils";

import useElementListOnSubmit from "./hooks/useElementListOnSubmit";
import PrintDisplay from "./PrintDisplay";
import { updateDefaultValue } from "./updateDefaultValue";
import useDefaultValue from "./useDefaultValue";
import useGanttState from "./useGanttState";
import useLayoutData from "./useLayoutData";
import useLayoutDataLoadingFlag from "./useLayoutDataLoadingFlag";
import useTableStyle from "./useTableStyle";
import ViewLayoutDisplay from "./ViewLayoutDisplay";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	object: PropTypes.string,
	layoutId: PropTypes.number,
};
let ViewLayoutContent = ({ layoutId, object }) => {
	let { setOnSubmit, setFormDefaultValue, setFormValue, formDefaultValue } = useFormContext();
	let {
		customFieldFlag,
		customStyleFlag,
		editTableFlag,
		elementListName,
		gridPodFlag,
		layout,
		pageKey,
		searchFlag,
		setLocalState,
	} = useLocalState();

	let { get } = useOldQueryStore();
	let options = get({ object: "options" });

	let { session, setSession } = useOldSession();
	let { periodId, exportFlag, rowCount } = session;
	let page = session[pageKey] || 1;
	rowCount = rowCount || options?.options?.reportRowCount || 25;
	let previousPeriodId = usePreviousDefined(periodId);

	useEffect(() => {
		if (previousPeriodId && periodId !== previousPeriodId && editTableFlag) {
			setLocalState({ editTableFlag: false });
		}
	}, [editTableFlag, periodId, previousPeriodId, setLocalState]);

	let setPage = useCallback((page) => setSession({ [pageKey]: page }), [pageKey, setSession]);

	let setRowCount = useCallback(
		(e) => {
			setSession({
				rowCount: e.value,
				[pageKey]: calculatePageOnRowCountChange({
					currentPage: page,
					oldRowCount: rowCount,
					newRowCount: e.value,
				}),
			});
		},
		[page, pageKey, rowCount, setSession]
	);

	const tableStyleId = layout?.reportOptions?.tableStyleId;
	let { tableStyle, tableStyleObject } = useTableStyle({ cellFlag: false, customStyleFlag, tableStyleId });

	object = object || session.object;
	layoutId = layoutId || session.layoutId;

	let { elementData, elementList, elementDetailList, filteredElementDataFlag } = useLayoutData({
		object,
		layoutId,
		rowCount,
	});
	let deferredElementData = useDeferredValue(elementData);
	if (gridPodFlag) elementData = deferredElementData;
	let previousElementData = usePreviousDefined(elementData);

	let { loadingFlag, tableLoadingFlag } = useLayoutDataLoadingFlag({
		layoutId,
		elementData,
		elementList,
		elementDetailList,
	});
	useGanttState({ loadingFlag: loadingFlag || tableLoadingFlag, elementDetailList });

	let defaultValue = useDefaultValue({ elementDetailList });
	let previousDefaultValue = usePreviousDefined(defaultValue, "gridLayout" + layoutId);
	let onSubmit = useElementListOnSubmit(elementListName);

	let { noResultsFlag } = useNoResults(elementList);

	useEffect(() => {
		setOnSubmit({ name: elementListName, onSubmit });
	}, [customFieldFlag, elementListName, onSubmit, setOnSubmit]);

	useEffect(
		updateDefaultValue({
			defaultValue,
			previousDefaultValue,
			formDefaultValue,
			elementListName,
			setFormDefaultValue,
			setFormValue,
		}),
		[
			defaultValue,
			elementListName,
			formDefaultValue?.[elementListName],
			previousDefaultValue,
			setFormDefaultValue,
			setFormValue,
		]
	);
	let Display = exportFlag ? PrintDisplay : ViewLayoutDisplay;
	let renderNullFlag = customFieldFlag && noResultsFlag;
	let brokenApiCallFlag = isEqual(elementData, emptyArray) || isEqual(elementData, emptyObject);

	return loadingFlag || renderNullFlag ? null : (
		<Display
			brokenApiCallFlag={brokenApiCallFlag}
			customFieldFlag={customFieldFlag}
			elementData={elementData}
			elementList={elementList}
			filteredElementDataFlag={filteredElementDataFlag}
			layoutId={layoutId}
			noResultsFlag={noResultsFlag}
			page={page}
			previousElementData={previousElementData}
			reportType={layout?.reportType}
			rowCount={rowCount}
			searchFlag={searchFlag}
			setPage={setPage}
			setRowCount={setRowCount}
			tableLoadingFlag={tableLoadingFlag}
			tableStyle={tableStyle}
			tableStyleObject={tableStyleObject}
		/>
	);
};
ViewLayoutContent.propTypes = propTypes;

export default ViewLayoutContent;
