import BootstrapListGroupItem from "react-bootstrap/ListGroupItem";
import PropTypes from "prop-types";
import { memo } from "react";


let listGroupItemPropTypes = {
	actionFlag: PropTypes.bool,
	activeFlag: PropTypes.bool,
	as: PropTypes.oneOf(["div", "a", "button"]),
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.oneOf(["primary", "secondary", "success", "danger", "warning", "info", "dark", "light"]),
	disabled: PropTypes.bool,
	eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	hoverFlag: PropTypes.bool,
	href: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let listGroupItemDefaultProps = {
	actionFlag: false,
	disabled: false,
};

let ListGroupItem = ({
	actionFlag,
	activeFlag,
	as,
	children,
	className,
	color,
	disabled,
	eventKey,
	hoverFlag,
	href,
	onClick,
	style,
}) => (
	<BootstrapListGroupItem
		action={actionFlag}
		active={activeFlag}
		as={as || (href ? "a" : undefined)}
		className={(className || "") + (hoverFlag ? " list-group-item-action" : "") || undefined}
		disabled={disabled}
		eventKey={eventKey}
		href={href}
		onClick={onClick}
		variant={color}
		style={style}
	>
		{children}
	</BootstrapListGroupItem>
);

ListGroupItem.propTypes = listGroupItemPropTypes;
ListGroupItem.defaultProps = listGroupItemDefaultProps;

export default memo(ListGroupItem);
