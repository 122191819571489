import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let PercentDisplay = ({ name }) => {
	let value = useFormValue(name);
	return <span>{value + "%"}</span>;
};

PercentDisplay.propTypes = propTypes;
export default PercentDisplay;
