import { useCallback, useContext, useEffect, useMemo, createContext, useRef } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import getHandlePageChange from "./PaginationProvider.getHandlePageChange";
import getItemsPerPage from "./PaginationProvider.getItemsPerPage";
import setDisplayData from "./PaginationProvider.setDisplayData";
import { useMeasure, useQueryParameters, useStateObject } from "@clearpoint/hooks";

let PaginationContext = createContext();

let propTypes = {
	children: PropTypes.node,
	getData: PropTypes.func.isRequired,
	sessionPageIndexFlag: PropTypes.bool,
	setFilter: PropTypes.func,
};

let noResultsObject = [];
noResultsObject.total = 0;

let itemsPerPageOutsideRef = {};

let PaginationProvider = ({ children, getData, sessionPageIndexFlag = true, ...props }) => {
	let queryParameters = useQueryParameters();
	let { session, setSession } = useOldSession();
	let { mode, object, page, periodId, scorecardId, testFlag } = session;
	let view = page === "document-library" ? queryParameters.view : session.view;

	let pageIndexKey = `pagination${page}${object}${scorecardId}${periodId}${view}${mode}`;

	// getData: a function that retrieves data for pagination
	// setFilter: for use with useFilteredElementList, not required

	let [{ childQuery, pageIndexState, parentQuery, parentWidthQuery, paginateTrigger, displayData }, setState] =
		useStateObject({
			childQuery: undefined,
			pageIndexState: 0,
			parentQuery: undefined,
			parentWidthQuery: undefined,
			paginateTrigger: false,
			displayData: undefined,
		});

	let pageIndex = sessionPageIndexFlag ? session[pageIndexKey] || 0 : pageIndexState;

	let paginate = useCallback(() => setState({ paginateTrigger: (x) => !x }), [setState]);

	let setChildQuery = useCallback(
		(x) => {
			if (childQuery !== x) setState({ childQuery: x });
		},
		[childQuery, setState]
	);

	let setParentQuery = useCallback(
		(x) => {
			if (parentQuery !== x) setState({ parentQuery: x });
		},
		[parentQuery, setState]
	);

	let setParentWidthQuery = useCallback(
		(x) => {
			if (parentWidthQuery !== x) setState({ parentWidthQuery: x });
		},
		[parentWidthQuery, setState]
	);

	let parentMeasure = useMeasure({ query: parentQuery, subtractPaddingFlag: true });

	let parentWidthMeasure = useMeasure({ query: parentWidthQuery, subtractPaddingFlag: true });

	let childMeasure = useMeasure({ query: childQuery, addMarginFlag: true });
	let itemsPerPage = useMemo(
		() => {
			let items = getItemsPerPage({ childMeasure, parentMeasure, parentWidthMeasure });
			let openedDropdownList = document.querySelectorAll(".file-list-open")?.length;
			if (
				openedDropdownList === 0 ||
				!itemsPerPageOutsideRef[pageIndexKey] ||
				itemsPerPageOutsideRef[pageIndexKey] === 1
			) {
				itemsPerPageOutsideRef[pageIndexKey] = items;
				return items;
			} else {
				return itemsPerPageOutsideRef[pageIndexKey];
			}
		},
		// keep paginateTrigger as dependency to trigger pagination
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[childMeasure, parentMeasure, parentWidthMeasure, paginateTrigger]
	);

	useEffect(
		() => setDisplayData({ pageIndex, itemsPerPage, setState, testFlag, getData }),
		// keep paginateTrigger as dependency to trigger pagination
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getData, setState, itemsPerPage, pageIndex, paginateTrigger, testFlag]
	);

	let pageCount = useMemo(() => {
		return displayData ? Math.ceil(displayData.total / (itemsPerPage || 1)) : 0;
	}, [displayData, itemsPerPage]);

	let handlePageChange = useCallback(
		(pageIndex) =>
			getHandlePageChange({ pageCount, pageIndex, pageIndexKey, sessionPageIndexFlag, setSession, setState }),
		[sessionPageIndexFlag, pageCount, pageIndexKey, setSession, setState]
	);

	let goToNextPage = useCallback(() => {
		let newPage = pageIndex + 1;
		handlePageChange(newPage);
	}, [handlePageChange, pageIndex]);

	let goToPreviousPage = useCallback(() => {
		let newPage = pageIndex - 1;
		handlePageChange(newPage);
	}, [handlePageChange, pageIndex]);

	let goToPage = useCallback(
		(pageNumber) => {
			let newPage = pageNumber - 1;
			handlePageChange(newPage);
		},
		[handlePageChange]
	);

	useEffect(() => {
		if (displayData && displayData.length === 0 && pageIndex + 1 !== 1 && displayData.total !== 0) {
			goToPage(1);
		}
		if (pageIndex >= pageCount) {
			goToPage(1);
		}
	}, [displayData, goToPage, pageCount, pageIndex]);

	return (
		<PaginationContext.Provider
			value={{
				data: displayData || noResultsObject,
				goToNextPage,
				goToPreviousPage,
				pageCount,
				goToPage,
				currentPage: pageIndex + 1,
				itemsPerPage,
				setChildQuery,
				setParentQuery,
				setParentWidthQuery,
				paginate,
				startIndex: pageIndex * itemsPerPage,
				getData,
				...props,
			}}
		>
			{children}
		</PaginationContext.Provider>
	);
};

PaginationProvider.propTypes = propTypes;

export default PaginationProvider;
export const usePagination = () => useContext(PaginationContext);
