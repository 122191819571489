import Chart from "@clearpoint/old-theme/Chart/Chart";
import Loading from "@clearpoint/old-theme/Loading/index";
import useSingleSeriesKpiChartData from "./useSingleSeriesKpiChartData";
import PropTypes from "prop-types";

let propTypes = {
	chart: PropTypes.shape({
		color1: PropTypes.string,
		color2: PropTypes.string,
		chartType: PropTypes.string,
		measureId: PropTypes.number,
		measureSeriesId: PropTypes.number,
		title: PropTypes.string,
	}),
};
let Bar = ({ chart }) => {
	let formattedChartData = useSingleSeriesKpiChartData(chart);

	return formattedChartData ? <Chart options={formattedChartData} /> : <Loading />;
};

Bar.propTypes = propTypes;
export default Bar;
