let pageNameLookup = {
	adminOptions: "menu.settings.systemConfiguration.systemSetup",
	ai: "ai.assistants",
	aiFramework: "ai.aiAssistant.framework",
	aiGoals: "ai.aiAssistant.goals",
	aiTranscript: "ai.aiAssistant.aiTranscripts",
	aiScorecard: "ai.aiAssistant.scorecard",
	alertInbox: "labels.alertInbox",
	auditLog: "revisionHistory.revisionHistory",
	briefingBooks: "books",
	dashboards: "labels.dashboards",
	documentLibraryAttachments: "global.attachments",
	documentLibraryExports: "global.pageExports",
	documentLibraryDashboardTemplate: "labels.dashboards",
	documentLibraryHtmlFile: "{{library.publishedReports}}",
	documentLibraryHtmlTemplate: "library.publishedReports",
	documentLibraryImages: "global.images",
	documentLibraryReportsFile: "{{books}}",
	documentLibraryReportsTemplate: "books",
	goalsListDepartment: "goals.byDepartment",
	goalsListIndividual: "goals.byIndividual",
	goalsListManager: "goals.byManager",
	goalsListScorecard: "goals.byScorecard",
	goalsPersonalDepartment: "goals.department.personal",
	goalsPersonalIndividual: "goals.individual.personal",
	goalsPersonalManager: "goals.manager.personal",
	goalsTreeDepartment: "goals.byDepartment",
	goalsTreeIndividual: "goals.byIndividual",
	goalsTreeManager: "goals.byManager",
	goalsTreeScorecard: "goals.byScorecard",
	home: "edit.home",
	integrationsApi: "dataAndIntegrations.api",
	integrationsBulkChanges: "scripting.bulkChanges",
	integrationsClearpointApi: "icon.clearpointAPI",
	integrationsDataLoader: "schedule.dataLoader",
	integrationsScripting: "icon.scripting",
	integrationsZapier: "icon.zapier",
	manage: "manage.manageElements", // interpolation key: elements
	manageFiscalYears: "manage.fiscalYears.manageFiscalYears",
	manageLayouts: "manage.layout.manageObjectReports", // interpolation key: object
	manageMasterPeriods: "manage.periodGroups.manageMasterPeriods",
	managePeriodGroups: "manage.periodGroups.manageReportingFrequencies",
	managePeriods: "manage.periods.manageReportingPeriods",
	manageUserGroups: "manage.manageGroups",
	manageUsers: "manage.manageUsers",
	manageWorkspaces: "workspaces.manage",
	measureLibrary: "toaster.messages.global.measureLibrary",
	myFavorites: "notifications.alerts.myFavorites",
	myMentions: "labels.myMentions",
	myNotifications: "labels.myNotifications",
	myScorecard: "labels.myScorecard",
	myUpdates: "labels.myUpdates",
	myApprovals: "approvals.myApprovals",
	notifications: "notifications.notifications.notifications",
	portal: "topNavigation.successPortal",
	recycleBin: "global.recycleBin",
	schedules: "global.schedules",
	schedulesCalendar: "icon.calendar",
	search: "search.searchClearPoint",
	sendReminders: "notifications.reminders",
	workflows: "workflow.workflows",
};
export default pageNameLookup;
