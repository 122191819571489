import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import { DragOverlay } from "@dnd-kit/core";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import Help from "@clearpoint/old-theme/Help";
import Page from "./Page";
import PodListItemDragOverlay from "./PodListItemDragOverlay";
import ReportOptionsButton from "./ReportOptionsButton";
import { useCallback, useMemo } from "react";
import { useDragAndDropContext } from "../DragAndDropWrapper/DragAndDropWrapper";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import { theme } from "@clearpoint/old-theme/Theme";
import { useEffectOnce } from "@clearpoint/hooks";

let LayoutPanel = () => {
	let translate = useTranslate();
	let { dashboardLayoutFlag } = useWindowContext();
	let { setFormValue } = useFormContext();
	let areaList = useFormValue("layout");

	let { activeId, generateContainerId, setDragAndDropLookup } = useDragAndDropContext();

	let pageNumberList = useMemo(() => [...new Set(areaList?.map((x) => x.page) || [])], [areaList]);

	let addPage = useCallback(() => {
		let newPageNumber = pageNumberList.length === 0 ? 0 : Math.max(...pageNumberList) + 1;
		let blankArea = {
			pods: [],
			page: newPageNumber,
		};
		let newPageAreaList;
		setFormValue("layout", (areaList) => {
			newPageAreaList = new Array(4)
				.fill(blankArea)
				.map((x, i) => ({ ...x, area: areaList.length + i, class: "area" + i }));
			return [...areaList, ...newPageAreaList];
		});
		setDragAndDropLookup((dragAndDropPodIdLookup) => {
			let newAreaLookup = {};
			for (let newArea of newPageAreaList) {
				newAreaLookup[generateContainerId(newArea)] = [];
			}
			return {
				...dragAndDropPodIdLookup,
				...newAreaLookup,
			};
		});
	}, [generateContainerId, pageNumberList, setFormValue, setDragAndDropLookup]);

	useEffectOnce(() => {
		if (areaList && areaList.length === 0) {
			addPage();
		}
	});

	return (
		<Block id="layoutPanel" height="100%" flexBasis="75%" overflowY="auto">
			<Block
				backgroundColor={theme.lighterGray}
				padding={`${theme.space} ${theme.mediumSpace} ${theme.tinySpace}`}
				overflowY="auto"
				minHeight="100%"
			>
				<Help feature="edit-detail-layout" />
				{dashboardLayoutFlag && (
					<FormInput
						name="name"
						placeholder={translate("layout.layoutName")}
						required
						right={<ReportOptionsButton />}
						marginBottom={theme.largeSpace}
					/>
				)}
				{pageNumberList?.map((x) => (
					<Page key={x} pageNumber={x} />
				))}
				<DragOverlay>{activeId ? <PodListItemDragOverlay id={activeId} /> : null}</DragOverlay>
				<Block display="flex" justifyContent="flex-end">
					<Button size="small" color="primary" onClick={addPage}>
						{translate("layout.addPage")}
					</Button>
				</Block>
			</Block>
		</Block>
	);
};

export default LayoutPanel;
