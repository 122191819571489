import Block from "../Block";
import EditModalWindow from "./ModalWindow.Edit";
import ModalWindowContent from "./ModalWindow.Content";
import ModalWindowFooter from "./ModalWindow.Footer";
import ModalWindowHeader from "./ModalWindow.Header";
import RevisionHistory from "../RevisionHistory/RevisionHistory";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	close: PropTypes.func.isRequired,
	filterOptionList: PropTypes.arrayOf(PropTypes.string),
	initialFilter: PropTypes.oneOfType([
		PropTypes.shape({ field: PropTypes.string, value: PropTypes.any }),
		PropTypes.arrayOf(PropTypes.shape({ field: PropTypes.string, value: PropTypes.any })),
	]),
	modalVisible: PropTypes.bool,
};

let RevisionHistoryModal = ({ close, modalWindowVisible, filterOptionList, initialFilter }) => {
	let translate = useTranslate();

	return (
		<EditModalWindow
			size="large"
			className="view-revision-history-modal"
			modalWindowVisible={modalWindowVisible}
			noFormFlag
			close={close}
		>
			<ModalWindowHeader
				badgeIcon="revisionHistory"
				badgeText={translate("revisionHistory.revisionHistory")}
				title={translate("revisionHistory.revisionHistory")}
			/>
			<ModalWindowContent>
				<Block
					padding={`${theme.space} ${theme.mediumSpace}`}
					height="100%"
					flex="1"
					overflow="hidden"
					backgroundColor={theme.white}
				>
					<RevisionHistory filterOptionList={filterOptionList} initialFilter={initialFilter} />
				</Block>
			</ModalWindowContent>
			<ModalWindowFooter />
		</EditModalWindow>
	);
};

RevisionHistoryModal.propTypes = propTypes;
export default RevisionHistoryModal;
