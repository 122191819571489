import { useEffect } from "react";
import { useFormContext } from "../Form";
import useFormValue from "./useFormValue";
import getDeepValue from "lodash/get";
import isEqual from "lodash/isEqual";

let useStringToArrayFormValue = (name, formatValue = (x) => x) => {
	let { setFormValue, formDefaultValue, setFormDefaultValue } = useFormContext();
	let arrayName = `_${name}`;
	let value = useFormValue(name);
	let arrayValue = useFormValue(arrayName);
	let defaultValue = getDeepValue(formDefaultValue, name);
	let defaultArrayValue = getDeepValue(formDefaultValue, arrayName);
	useEffect(() => {
		if (defaultValue && defaultArrayValue === undefined) {
			setFormValue(arrayName, formatValue(defaultValue.split(",").map((x) => x.trim())));
		}
		if (defaultValue && !isEqual(defaultArrayValue, arrayValue)) {
			setFormDefaultValue(arrayName, formatValue(defaultValue.split(",").map((x) => x.trim())));
		}
		if (arrayValue !== undefined) setFormValue(name, (arrayValue || []).join(","));
	}, [
		arrayName,
		arrayValue,
		defaultArrayValue,
		defaultValue,
		formatValue,
		name,
		setFormDefaultValue,
		setFormValue,
		value,
	]);
	return arrayName;
};
export default useStringToArrayFormValue;
