import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import isArray from "lodash/isArray";
import { emptyArray, objectList } from "@clearpoint/utils";

// Returns a function that removes any fields that are globally hidden

let useFilterHiddenDefaultFields = () => {
	let { get } = useOldQueryStore();
	let options = get({ object: "options" });

	return useCallback(
		({ fieldList, object }) => {
			if (!fieldList) return undefined;
			if (!objectList.includes(object)) return fieldList;
			let defaultFieldListByObject = options?.defaultFields?.[object]?.fields || emptyArray;

			return isArray(fieldList)
				? fieldList.filter((field) => {
						let defaultField = defaultFieldListByObject.find(
							(x) => x.fieldId === field.value || x.fieldId === field.fieldId
						);
						return !defaultField?.hidden;
				  })
				: emptyArray;
		},
		[options?.defaultFields]
	);
};

export default useFilterHiddenDefaultFields;
