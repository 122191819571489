import { v1 as uuid } from "uuid";
let authInitialState = {
	machineId: uuid(),
	sessionId: null,
	success: false,
	token: null,
};
let createAuthSlice = (set) => ({
	...authInitialState,
	setAuth: ({ sessionId, token }) => set(() => ({ sessionId, token })),
});
export { authInitialState, createAuthSlice };
