import { useEffect } from "react";
import getDeepValue from "lodash/get";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import Input from "../../Form/Input/Input";

let propTypes = {
	measureSeriesNameList: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	object: PropTypes.string,
};

let FormFieldName = ({ measureSeriesNameList, name, object, ...props }) => {
	let translate = useTranslate();
	let { formDefaultValue, setFormError } = useFormContext();
	let value = useFormValue(name);
	let defaultName = getDeepValue(formDefaultValue, name) || "";

	useEffect(() => {
		if (object === "measureSeries") {
			let nameValue = value?.toLowerCase().trim();
			if (
				nameValue !== defaultName?.toLowerCase() &&
				measureSeriesNameList?.some((series) => series.toLowerCase() === nameValue)
			) {
				setFormError(name, translate("error.uniqueSeriesName"));
			} else {
				setFormError(name, undefined);
			}
		}
	}, [defaultName, measureSeriesNameList, name, object, translate, setFormError, value]);

	return (
		<Input
			data-testid="name"
			label={translate(object ? "edit.namePlaceholder" : "global.name", { object })}
			placeholder={translate(object ? "edit.namePlaceholder" : "global.name", { object })}
			{...props}
			required
			maxLength={500}
			notEndingWithCalcWordFlag={object === "measureSeries"}
			name={name}
		/>
	);
};

FormFieldName.propTypes = propTypes;

export default FormFieldName;
