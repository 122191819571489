import PropTypes from "prop-types";
import classNames from "classnames";
import styled, { css } from "styled-components";
import { minutesPassed } from "@clearpoint/utils";

let containerPropTypes = {
	highlightFlag: PropTypes.bool,
	hoverEffectFlag: PropTypes.bool,
	unreadFlag: PropTypes.bool,
};

let Container = styled.div`
	display: ${(props) => (props.visible ? "flex" : "none")};
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${(props) => props.theme.white};
	border: 1px solid ${(props) => props.theme.lightGray5};
	border-left: none;
	flex-wrap: nowrap;
	width: 100%;
	padding: ${(props) => props.theme.tinySpace} ${(props) => props.theme.space} 0px;
	height: 48px;
	user-select: none;
	cursor: ${(props) => props.cursor};
	position: relative;
	min-height: 48px;
	text-overflow: ellipsis;

	${(props) =>
		!props.ignoreDefaultChildStylesFlag &&
		css`
			& * {
				text-overflow: ellipsis;
				overflow: visible;
				vertical-align: middle;
				margin-bottom: 0px !important;
				white-space: unset !important;
			}
			&:not(:first-child) {
				border-top: none;
			}
		`}

	${(props) =>
		props.hoverEffectFlag
			? css`
					&:hover {
						background-color: ${(props) => props.theme.lightGray5};
						&::before {
							background-color: ${(props) => props.theme.primary};
							position: absolute;
							height: 48px;
							width: 4px;
							top: -1px;
							left: 0;
							content: "";
						}
					}
			  `
			: ``}

	${(props) =>
		props.unreadFlag
			? css`
					::before {
						background-color: ${(props) => props.theme.highlightYellow2};
						position: absolute;
						height: 48px;
						width: 4px;
						top: -1px;
						left: 0;
						content: "";
					}
			  `
			: ``}

	& > * {
		margin-bottom: ${(props) => (props.noSidesFlag ? props.theme.tinySpace + "!important" : undefined)};
		word-break: ${(props) => (props.noSidesFlag ? "break-word !important" : undefined)};
	}
`;

let LeftChild = styled.div`
	display: flex;
	align-items: center;
	overflow: visible !important;
	white-space: nowrap;
	word-wrap: break-word;
	max-height: ${(props) => (props.height ? props.height + "!important" : undefined)};
	& > * {
		margin-right: ${(props) => props.theme.smallSpace};
		margin-bottom: ${(props) => props.theme.tinySpace} !important;
	}
	${(props) =>
		props.pseudoPadding
			? `::after {
		content: "";
	}`
			: ``}
`;

let RightChild = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	min-width: unset !important;
	overflow: visible !important;
	white-space: nowrap;
	max-height: ${(props) => (props.height ? props.height + "!important" : undefined)};
	& > * {
		margin-left: ${(props) => props.theme.smallSpace};
		margin-bottom: ${(props) => props.theme.tinySpace} !important;
		flex-shrink: 0;
	}

	& > span:not(.badge):not(.tag),
	& > small,
	& > div:not(.form-group),
	& > a:not(.btn) {
		flex-shrink: 1;
		min-width: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

let propTypes = {
	buttonCount: PropTypes.number,
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.string,
	"data-testid": PropTypes.string,
	hoverEffectFlag: PropTypes.bool,
	ignoreDefaultChildStylesFlag: PropTypes.bool,
	lastEdited: PropTypes.string,
	left: PropTypes.node,
	leftHeight: PropTypes.string,
	leftPseudoPadding: PropTypes.string,
	padding: PropTypes.string,
	right: PropTypes.node,
	rightHeight: PropTypes.string,
	viewedFlag: PropTypes.bool,
	visible: PropTypes.bool,
};

let defaultProps = {
	visible: true,
};

// refactor styling for left and right props
let DocumentItem = ({
	buttonCount,
	children,
	className,
	color,
	"data-testid": dataTestId,
	ignoreDefaultChildStylesFlag,
	lastEdited,
	left,
	leftHeight,
	leftPseudoPadding,
	right,
	rightHeight,
	...props
}) => {
	let highlightFlag = lastEdited && minutesPassed(lastEdited) < 5;
	return (
		<Container
			data-testid={dataTestId}
			highlightFlag={highlightFlag}
			ignoreDefaultChildStylesFlag={ignoreDefaultChildStylesFlag}
			{...props}
			className={classNames("document-item-container", className)}
		>
			{left && (
				<LeftChild height={leftHeight} pseudoPadding={leftPseudoPadding} className="document-item-left-child">
					{left}
				</LeftChild>
			)}
			{children}
			{right && (
				<RightChild buttonCount={buttonCount} height={rightHeight} className="document-item-right-child">
					{right}
				</RightChild>
			)}
		</Container>
	);
};

Container.propTypes = containerPropTypes;
DocumentItem.propTypes = propTypes;
DocumentItem.defaultProps = defaultProps;

export default DocumentItem;
