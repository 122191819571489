import { useCallback, useEffect, useState } from "react";

import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import DiscussionButton from "@clearpoint/old-theme/Button/Button.Discussion";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import useElementList from "../useElementList";
import DashboardEditSplitButton from "./DashboardEditSplitButton";
import DashboardFavoriteSplitButton from "./DashboardFavoriteSplitButton";
import Search from "./Search";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let Toolbar = () => {
	let { ReferenceEditSplitButton, ReferenceFavoriteSplitButton } = useLocalState();
	let FavoriteSplitButton = ReferenceFavoriteSplitButton || DashboardFavoriteSplitButton;
	let EditSplitButton = ReferenceEditSplitButton || DashboardEditSplitButton;
	let elementList = useElementList();
	let { refresh, staleData } = useOldQueryStore();
	let [dataStale, setDataStale] = useState(false);
	let [dataRefreshing, setDataRefreshing] = useState(false);

	useEffect(() => {
		let hasStaleData = false;
		if (elementList?.elements && staleData) {
			for (let row of elementList.elements) {
				let { object, objectId } = row;
				if (staleData.some((data) => data.object === object && data.objectId === objectId)) {
					hasStaleData = true;
					break;
				}
			}
			if (hasStaleData) {
				setDataStale(true);
			} else {
				setDataRefreshing(false);
				setDataStale(false);
			}
		}
	}, [elementList?.elements, elementList?.elements?.length, staleData, staleData?.length]);

	let refreshPage = useCallback(() => {
		setDataRefreshing(true);
		refresh();
	}, [refresh]);

	return (
		<Block
			display="flex"
			alignItems="flex-start"
			justifyContent="space-between"
			margin={`${theme.mediumSpace} ${theme.mediumLargeSpace}`}
		>
			<Search />
			{(dataStale || dataRefreshing) && (
				<Button color="white" onClick={refreshPage} disabled={dataRefreshing}>
					<Icon name="refresh" spinFlag={dataRefreshing} />
				</Button>
			)}
			<Block display="flex">
				<DiscussionButton />
				<FavoriteSplitButton />
				<EditSplitButton />
			</Block>
		</Block>
	);
};

export default Toolbar;
