import Button from "@clearpoint/old-theme/Button/Button";
import LayoutSelect from "@clearpoint/old-theme/Form/Select/Select.Layout";
import Modal from "@clearpoint/old-theme/Modal/Modal";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import ObjectTypeSelect from "@clearpoint/old-theme/Form/Select/Select.ObjectType";
import ScorecardSelect from "@clearpoint/old-theme/Form/Select/Select.Scorecard";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { toast } from "@clearpoint/services/toastService/index";
import { emptyObject } from "@clearpoint/utils";


let propTypes = {
	close: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool,
	name: PropTypes.string,
};

let SelectReportModal = ({ close, modalVisible, name }) => {
	let translate = useTranslate();
	let { clearFormValue, setFormValue } = useFormContext();
	let { value } = useFormValue(name);
	let reportUpdate = useFormValue("reportUpdate") || emptyObject;
	let initialReportUpdate = useRef(reportUpdate);

	useEffect(() => {
		if (value && isEmpty(reportUpdate)) {
			let [scorecardId, objectType, layoutId] = value.split(",").map((x) => (x === "undefined" ? undefined : x));
			setFormValue("reportUpdate", {
				scorecardId: scorecardId && +scorecardId,
				objectType,
				layoutId: layoutId && +layoutId,
			});
		}
	}, [name, reportUpdate, setFormValue, value]);

	let save = useCallback(() => {
		let { scorecardId, objectType, layoutId } = reportUpdate;
		let newValue = `${scorecardId},${objectType},${layoutId}`;

		// Temporarily store value in reportUpdate, set newValue as submitValue[props.name].value in detail page's beforeSubmit
		setFormValue("reportUpdate", { field: name, value: newValue });
		toast.warning(translate("toaster.messages.reports.reportAfterSave"), { autoClose: 5000 });
		close();
	}, [close, name, reportUpdate, setFormValue, translate]);

	let discard = useCallback(() => {
		if (isEqual(initialReportUpdate.current, emptyObject)) {
			clearFormValue("reportUpdate");
		} else {
			setFormValue("reportUpdate", initialReportUpdate.current);
		}
		close();
	}, [clearFormValue, close, setFormValue]);

	let valid = useMemo(() => {
		let { scorecardId, objectType, layoutId } = reportUpdate;
		return !!(scorecardId && objectType && layoutId);
	}, [reportUpdate]);

	return (
		<Modal close={close} modalVisible={modalVisible}>
			<ModalHeader>
				<ModalTitle>{translate("layout.selectLayout")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{
					<>
						<ScorecardSelect label={translate("scorecard")} name="reportUpdate.scorecardId" />
						{reportUpdate.scorecardId && (
							<>
								<ObjectTypeSelect label={translate("options.elementType")} name="reportUpdate.objectType" />

								{reportUpdate.objectType && (
									<LayoutSelect
										label={translate("global.report")}
										object={reportUpdate.objectType}
										scorecardId={reportUpdate.scorecardId}
										name="reportUpdate.layoutId"
									/>
								)}
							</>
						)}
					</>
				}
			</ModalBody>
			<ModalFooter>
				<Button onClick={discard} color="default">
					{translate("global.cancel")}
				</Button>
				<Button data-testid="form-modal-close" color="primary" onClick={save} disabled={!valid}>
					{translate("global.save")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

SelectReportModal.propTypes = propTypes;
export default SelectReportModal;
