import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "../../Theme";

let SelectFont = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() =>
			[
				theme.defaultFontFamily,
				"Calibri",
				"Garamond",
				"Arial",
				"Verdana",
				"Helvetica",
				"Tahoma",
				"Impact",
				"Trebuchet MS",
				"Times New Roman",
				"Georgia",
				"Courier New",
			].map((x) => ({ label: x, value: x })),
		[]
	);
	return <FormSelect options={options} placeholder={translate("options.admin.styles.font.selectFont")} {...props} />;
};
export default SelectFont;
