import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../Theme";
import { stringToColor } from "@clearpoint/utils";

let StyledInitialsCircle = styled.div`
	margin: 0px ${(props) => props.marginRight || "0px"} 0px ${(props) => props.marginLeft || "0px"};
	background-color: ${(props) => stringToColor(props.firstName + " " + props.lastName)};
	border-radius: 50%;
	width: ${(props) => props.diameter};
	height: ${(props) => props.diameter};
	line-height: ${(props) => props.diameter};
	position: relative;
	letter-spacing: -0.5px;
	color: ${theme.white};
	text-align: center;
	font-weight: 700;
	font-size: ${(props) => props.fontSize};
	flex-shrink: 0;
	${(props) => props.$style}
`;

let sizes = {
	diameter: {
		tiny: "28px",
		small: "36px",
		medium: "48px",
		large: "60px",
	},
	font: {
		tiny: "12px",
		small: "16px",
		medium: "24px",
		large: "32px",
	},
	topSpacing: {
		tiny: "12px",
		small: "16px",
		medium: "23px",
		large: "29px",
	},
};

let propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	marginLeft: PropTypes.string,
	marginRight: PropTypes.string,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
};

let InitialsCircle = ({ $style, firstName, lastName, marginLeft, marginRight, size }) => {
	if (!firstName && !lastName) return null;
	return (
		<StyledInitialsCircle
			firstName={firstName}
			lastName={lastName}
			diameter={sizes.diameter[size]}
			fontSize={sizes.font[size]}
			marginRight={marginRight}
			marginLeft={marginLeft}
			$style={$style}
		>
			{firstName[0] + lastName[0]}
		</StyledInitialsCircle>
	);
};

InitialsCircle.propTypes = propTypes;

export default InitialsCircle;
