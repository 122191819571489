import Analysis from "./Analysis";
import Calculated from "./Calculated";
import Chart from "./Chart";
import Collaborators from "./Collaborators";
import Completed from "./Completed";
import CompletedDate from "./CompletedDate";
import ElapsedDays from "./ElapsedDays";
import ElapsedPercentComplete from "./ElapsedPercentComplete";
import EndDate from "./EndDate";
import LastEdited from "./LastEdited";
import LastEditedBy from "./LastEditedBy";
import LastUpdated from "./LastUpdated";
import LastUpdatedBy from "./LastUpdatedBy";
import Name from "./Name";
import ObjectId from "./ObjectId";
import ObjectiveScore from "./ObjectiveScore";
import OwnerId from "./OwnerId";
import PercentComplete from "./PercentComplete";
import Period from "./Period";
import PeriodGroupId from "./PeriodGroupId";
import Recommendations from "./Recommendations";
import ScorecardId from "./ScorecardId";
import SeriesData from "./SeriesData";
import StartDate from "./StartDate";
import StatusId from "./StatusId";
import Tags from "./Tags";
import Today from "./Today";
import TotalDays from "./TotalDays";
import Variance from "./Variance";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let Display = ({ name }) => {
	let value = useFormValue(name);
	return <span>{value}</span>;
};

Display.Analysis = Analysis;
Display.Calculated = Calculated;
Display.Chart = Chart;
Display.Collaborators = Collaborators;
Display.Completed = Completed;
Display.CompletedDate = CompletedDate;
Display.ElapsedDays = ElapsedDays;
Display.ElapsedPercentComplete = ElapsedPercentComplete;
Display.EndDate = EndDate;
Display.LastEdited = LastEdited;
Display.LastEditedBy = LastEditedBy;
Display.LastUpdated = LastUpdated;
Display.LastUpdatedBy = LastUpdatedBy;
Display.Name = Name;
Display.ObjectId = ObjectId;
Display.ObjectiveScore = ObjectiveScore;
Display.OwnerId = OwnerId;
Display.PercentComplete = PercentComplete;
Display.Period = Period;
Display.PeriodGroupId = PeriodGroupId;
Display.Recommendations = Recommendations;
Display.ScorecardId = ScorecardId;
Display.SeriesData = SeriesData;
Display.StartDate = StartDate;
Display.StatusId = StatusId;
Display.Tags = Tags;
Display.Today = Today;
Display.TotalDays = TotalDays;
Display.Variance = Variance;

Display.propTypes = propTypes;
export default Display;
