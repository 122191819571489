import FormFieldCompletedDate from "@clearpoint/old-theme/Form/Field/CompletedDate";
import FormFieldDisplayCompletedDate from "@clearpoint/old-theme/Form/Field/Display/CompletedDate";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let CompletedDate = ({ editPodFlag, name }) => {
	let translate = useTranslate();
	let completedFlag = useFormValue("completed");
	return !editPodFlag ? (
		<FormFieldDisplayCompletedDate name={name} />
	) : completedFlag ? (
		<FormFieldCompletedDate dateOnlyFlag name={name} />
	) : (
		translate("displayDetail.fields.boolean.pleaseMarkCompleted")
	);
};

CompletedDate.propTypes = propTypes;
export default CompletedDate;
