import Alert from "../../../Alert/Alert";
import Block from "../../../Block";
import Button from "../../../Button/Button";
import Column from "../../../Column";
import FormGroup from "../../../Form/Form.Group";
import FormSelectPeriod from "../../../Form/Select/Select.Period";
import Loading from "../../../Loading";
import Preview from "./Preview";
import Row from "../../../Row";
import SelectScorecard from "../../../Form/Select/Select.Scorecard";
import { useCallback, useEffect } from "react";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	className: PropTypes.string,
	seriesType: PropTypes.string,
};

let Test = ({ className, seriesType }) => {
	let translate = useTranslate();
	let { getPromise } = useOldQueryStore();
	let formValue = useFormValue() || {};
	let { calculation, seriesDataTypeId, numberFormatId, currency, measureId } = formValue;
	let { scorecardId, periodId } = formValue._preview || {};
	let [{ loadingFlag, previewVisible, previewData }, setState] = useStateObject({
		loadingFlag: false,
		previewData: null,
		previewVisible: seriesType === "specific",
	});
	let getPreviewData = useCallback(() => {
		if (calculation && periodId) {
			setState({ loadingFlag: true });
			getPromise(
				seriesType === "specific"
					? {
							object: "preview",
							queryString: "?mode=series",
							payload: {
								calculation,
								measureId,
								periodId,
							},
					  }
					: {
							object: "preview",
							queryString: "?mode=calculation",
							payload: {
								scorecardId,
								periodId,
								calculation,
								seriesDataTypeId,
								numberFormatId,
								currency,
							},
					  }
			).then((x) => setState({ previewData: x, loadingFlag: false }));
		}
	}, [
		calculation,
		currency,
		getPromise,
		measureId,
		numberFormatId,
		periodId,
		scorecardId,
		seriesDataTypeId,
		seriesType,
		setState,
	]);
	let preview = useCallback(() => {
		setState({ previewVisible: true });
		getPreviewData();
	}, [getPreviewData, setState]);
	useEffect(() => {
		if (previewVisible) {
			getPreviewData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scorecardId, periodId, previewVisible, calculation]);
	return (
		<div className={className}>
			<FormGroup title={translate("layout.testCalculation")}>
				{seriesType !== "specific" && (
					<Alert color="info">
						<Block display="flex" alignItems="center">
							<Block flex={1} marginRight={theme.smallSpace}>
								{translate("edit.series.clickToPreview")}
							</Block>
							<Button size="tiny" color="info" onClick={preview} disabled={calculation === undefined}>
								{translate("notifications.preview")}
							</Button>
						</Block>
					</Alert>
				)}
				<Row>
					{seriesType !== "specific" && (
						<Column medium={6}>
							<SelectScorecard
								name="_preview.scorecardId"
								label={translate("edit.selectScorecard")}
								onSelect={preview}
								defaultFirstOptionFlag
							/>
						</Column>
					)}
					<Column medium={6}>
						<FormSelectPeriod
							name="_preview.periodId"
							label={translate("edit.series.selectPeriod")}
							onSelect={preview}
							defaultCurrentPeriodFlag
						/>
					</Column>
				</Row>
				{loadingFlag && <Loading />}
				{previewData && !loadingFlag && <Preview previewData={previewData} />}
			</FormGroup>
		</div>
	);
};

Test.propTypes = propTypes;

export default Test;
