import Block from "@clearpoint/old-theme/Block";
import BriefingBookNavigation from "@components/Print/BriefingBook/Navigation";
import DiscussionButton from "@clearpoint/old-theme/Button/Button.Discussion";
import EditSplitButton from "./EditSplitButton";
import FavoriteSplitButton from "./FavoriteSplitButton";
import PageTitle from "@clearpoint/old-theme/PageTitle/PageTitle";
import PageTitleScorecardSubtitle from "@clearpoint/old-theme/PageTitle/PageTitle.ScorecardSubtitle";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";

let Toolbar = () => {
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let { briefingBookFlag, exportFlag } = useOldSession().session;
	let { map } = useLocalState();
	let { name } = map;
	return (
		<Block display="flex" justifyContent="space-between" padding={exportFlag ? "0px 0px 20px" : "20px 25px"}>
			<PageTitle title={translateOnlyInsideCurlyBraces(name)} subTitle={<PageTitleScorecardSubtitle />} />
			{briefingBookFlag && <BriefingBookNavigation />}
			{!exportFlag && (
				<Block display="flex" height="34px">
					<DiscussionButton />
					<FavoriteSplitButton />
					<EditSplitButton />
				</Block>
			)}
		</Block>
	);
};

export default Toolbar;
