import { useMemo } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import FormSelect from "./FormSelect";

let SelectPeriodGroupFilter = ({ additionalOptions, name, ...props }) => {
	let { get } = useOldQueryStore();
	let periodList = get({ object: "period" });
	let options = useMemo(() => {
		if (!periodList) return [];
		let periodOptions = periodList
			.filter((period) => !period.archived)
			.map((period) => ({ value: period.periodId, label: period.name }));
		if (additionalOptions?.length > 0) return [...additionalOptions, ...periodOptions];
		return periodOptions;
	}, [additionalOptions, periodList]);

	return useMemo(
		() => <FormSelect name={name} options={options} data-testid="select-reporting-period-actual" {...props} />,
		[name, options, props]
	);
};
export default SelectPeriodGroupFilter;
