import Alert from "../Alert/Alert";
import AttachmentListItem from "./AttachmentListItem";
import StyleWrapper from "../StyleWrapper";
import Upload from "../Upload/Upload";
import UploadProvider, { useUpload } from "@clearpoint/providers/UploadProvider";
import { useCallback, useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { toast } from "@clearpoint/services/toastService/index";
import { buildQueryString } from "@clearpoint/utils";
import { useId } from "@clearpoint/hooks";

let propTypes = {
	attachmentList: PropTypes.arrayOf(
		PropTypes.shape({
			filename: PropTypes.string,
			filetype: PropTypes.string,
			link: PropTypes.shape({
				url: PropTypes.string,
			}),
			uploadDate: PropTypes.string,
		})
	),
	editFlag: PropTypes.bool,
	forceMarginFlag: PropTypes.bool,
};

let AttachmentsContent = ({ editFlag, attachmentList, forceMarginFlag }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let profile = get({ object: "profile" });
	let userId = profile?.userId;
	let { exportFlag, htmlExportFlag } = useOldSession().session;
	let id = useId();
	let { errorMessage } = useUpload();
	let acceptedFileTypes = useMemo(
		() => [
			"image/png",
			"image/jpg",
			"image/jpeg",
			"image/gif",
			"image/svg",
			"application/pdf",
			"application/msword",
			"application/vnd.msword",
			"application/vnd.ms-excel",
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		],
		[]
	);
	return (
		<>
			{attachmentList?.map?.((x, i) => (
				<StyleWrapper
					key={x.attachmentId}
					marginBottom={i === attachmentList.length - 1 && !forceMarginFlag ? "0px !important" : undefined}
				>
					<AttachmentListItem
						attachment={x}
						userId={userId}
						editFlag={editFlag}
						exportFlag={exportFlag}
						htmlExportFlag={htmlExportFlag}
					/>
				</StyleWrapper>
			)) || null}
			{errorMessage && editFlag && (
				<StyleWrapper marginBottom={theme.smallSpace}>
					<Alert color="danger" marginBottom={theme.tinySpace}>
						{errorMessage}
					</Alert>
				</StyleWrapper>
			)}
			{editFlag && (
				<Upload
					id={`upload-attachment-${id}`}
					maxSize="10mb"
					dropZoneText={translate("library.dragDropHelp")}
					acceptedFileTypes={acceptedFileTypes}
					sizeExceededMessage={translate("components.fileSizeLimit")}
					unsupportedFileTypeMessage={translate("components.invalidFileType")}
					uploadOnDropFlag
					multipleFlag
				/>
			)}
		</>
	);
};
AttachmentsContent.propTypes = propTypes;

let AttachmentsPropTypes = {
	object: PropTypes.string,
	objectId: PropTypes.number,
	periodId: PropTypes.number,
};
let Attachments = ({ object, objectId, periodId, ...props }) => {
	// afterUpload, children, url
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	if (!periodId) periodId = session.periodId;
	let attachmentList =
		objectId &&
		periodId &&
		get({
			object: "attachment",
			queryString: buildQueryString({ object, objectId, periodId }),
		});
	let { clear } = useOldQueryStore();
	let afterUpload = useCallback(() => {
		toast.success(translate("components.uploadComplete"));
		clear({ object: "attachment", queryString: buildQueryString({ object, objectId, periodId }) });
	}, [clear, object, objectId, periodId, translate]);
	let data = useMemo(() => ({ object, objectId }), [object, objectId]);
	return (
		<UploadProvider afterUpload={afterUpload} url={"/upload"} data={data}>
			<AttachmentsContent attachmentList={attachmentList} {...props} />
		</UploadProvider>
	);
};
Attachments.propTypes = AttachmentsPropTypes;
export default Attachments;
