import Block from "../Block";
import PropTypes from "prop-types";
import { memo } from "react";
import styled from "styled-components";

let TextElement = ({ className, children, "data-testid": dataTestId, ellipsisFlag, style }) =>
	ellipsisFlag ? (
		<Block ellipsisFlag className={className} style={style} data-testid={dataTestId}>
			{children}
		</Block>
	) : (
		<span className={className} style={style} data-testid={dataTestId}>
			{children}
		</span>
	);

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.string,
	"data-testid": PropTypes.string,
	display: PropTypes.string,
	ellipsisFlag: PropTypes.bool,
	fontFamily: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	textAlign: PropTypes.string,
};

let Text = styled(TextElement)`
	color: ${(props) => props.theme[props.color] || props.color};
	display: ${(props) => props.display};
	font-family: ${(props) => props.fontFamily || props.theme.defaultFontFamily};
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.theme[props.fontWeight] || props.fontWeight};
	text-align: ${(props) => props.textAlign};
	margin-top: ${(props) => props.marginTop};
	margin-right: ${(props) => props.marginRight};
	margin-bottom: ${(props) => props.marginBottom};
	margin-left: ${(props) => props.marginLeft};
`;
Text.propTypes = propTypes;

export default memo(Text);
