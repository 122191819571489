import Button from "../Button/Button";
import HTML from "../HTML";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	body: PropTypes.node,
	cancelButtonText: PropTypes.string,
	close: PropTypes.func.isRequired,
	confirmButtonColor: PropTypes.string,
	confirmButtonText: PropTypes.string,
	"data-testid": PropTypes.string,
	modalVisible: PropTypes.bool,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func.isRequired,
	size: PropTypes.string,
	title: PropTypes.string,
};

let defaultProps = {
	confirmButtonColor: "primary",
	size: "medium",
};

let ModalConfirm = ({
	body,
	cancelButtonText,
	children,
	close,
	confirmButtonColor,
	confirmButtonText,
	"data-testid": dataTestId,
	modalVisible,
	onCancel,
	onConfirm,
	size,
	title,
}) => {
	let translate = useTranslate();
	return (
		<Modal data-testid={dataTestId} modalVisible={modalVisible} close={close} size={size}>
			<ModalHeader>
				<ModalTitle>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{typeof body === "string" ? <HTML>{body}</HTML> : body}
				{children}
			</ModalBody>
			<ModalFooter>
				<Button
					data-testid={`${cancelButtonText || "cancel"}-button`}
					onClick={() => {
						onCancel?.();
						close();
					}}
					type="button"
					color="default"
				>
					{cancelButtonText || translate("global.cancel")}
				</Button>
				<Button
					data-testid={`${confirmButtonText || translate("global.close")}-button`}
					onClick={() => {
						onConfirm();
						close();
					}}
					color={confirmButtonColor}
				>
					{confirmButtonText || translate("global.close")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

ModalConfirm.propTypes = propTypes;
ModalConfirm.defaultProps = defaultProps;

export default ModalConfirm;
