import { useCallback, useState } from "react";
import debounce from "lodash/debounce";

import Input from "@clearpoint/old-theme/Input/Input";

import { useReportFilter } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";


let SearchInput = () => {
  let translate = useTranslate();
  let [value, setValue] = useState();
  let { layout } = useLocalState();
  let { setReportFilter } = useReportFilter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let debouncedSetFilter = useCallback(
    debounce((value) => {
      setReportFilter(encodeURIComponent(value));
    }, 500),
    [setReportFilter]
  );
  let onChange = useCallback(
    (e) => {
      debouncedSetFilter(e.target.value);
      setValue(e.target.value);
    },
    [debouncedSetFilter]
  );
  return (
    <Input
      flexGrowFlag
      marginBottom="0px"
      onChange={onChange}
      placeholder={translate("search.findInElement", { element: layout.name || "layout.dashboard" })}
      value={value}
    />
  );
};
export default SearchInput;
