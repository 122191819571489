import BootstrapColumn from "react-bootstrap/Col";
import React, { memo } from "react";
import StyleWrapper from "./StyleWrapper";
import PropTypes from "prop-types";

let propTypes = {
	/** Prop description */
	alignItemsFlag: PropTypes.bool,
	as: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	huge: PropTypes.oneOfType([PropTypes.oneOf([true, "auto"]), PropTypes.number, PropTypes.object]),
	large: PropTypes.oneOfType([PropTypes.oneOf([true, "auto"]), PropTypes.number, PropTypes.object]),
	marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	medium: PropTypes.oneOfType([PropTypes.oneOf([true, "auto"]), PropTypes.number, PropTypes.object]),
	paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	small: PropTypes.oneOfType([PropTypes.oneOf([true, "auto"]), PropTypes.number, PropTypes.object]),
	tiny: PropTypes.oneOfType([PropTypes.oneOf([true, "auto"]), PropTypes.number, PropTypes.object]),
};

let defaultProps = {
	as: "div",
};

let Column = React.forwardRef(
	(
		{
			as,
			children,
			className,
			"data-testid": dataTestId,
			huge,
			large,
			marginBottom,
			paddingLeft,
			paddingRight,
			medium,
			small,
			style,
			tiny,
			onClick,
		},
		ref
	) => (
		<StyleWrapper marginBottom={marginBottom} paddingLeft={paddingLeft} paddingRight={paddingRight}>
			<BootstrapColumn
				ref={ref}
				className={className}
				data-testid={dataTestId}
				as={as}
				xs={tiny}
				sm={small}
				md={medium}
				lg={large}
				xl={huge}
				style={style}
				onClick={onClick}
			>
				{children}
			</BootstrapColumn>
		</StyleWrapper>
	)
);

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;

export default memo(Column);
