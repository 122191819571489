import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import SeriesGridPrint from "@clearpoint/old-theme/Form/Grid/Series/Print/Print";
import FormSeriesGrid from "@clearpoint/old-theme/Form/Grid/Series/Series";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Loading from "@clearpoint/old-theme/Loading/index";
import Pod from "@clearpoint/old-theme/Pod/Pod";

import { useCheckAccess } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import { usePodContext } from "../PodProvider";
import AddSeriesButton from "./AddSeriesButton";
import LastCalculatedBadge from "./LastCalculatedBadge";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import EditSeriesModal from "@components/Elements/Shared/SeriesTab/EditSeriesModal";
import CalculationMapTab from "@components/Elements/Shared/SeriesTab/EditSeriesModal/CalculationMapTab";

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	onDoubleClick: PropTypes.func.isRequired,
	overflowX: PropTypes.string,
	podStyle: PropTypes.object,
};

let MeasureGridPod = ({ className, marginTop, onDoubleClick, overflowX, podStyle }) => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { exportFlag } = useOldSession().session;
	let { closeEditMode, editPodFlag, hideBorderFlag, openEditMode, title } = usePodContext();
	let { editElementFlag, inModalFlag } = useLocalState();
	let {
		formStatus: { dirty: formDirtyFlag },
		resetForm,
		formValueRef,
		setFormValue,
	} = useFormContext();

	let [addSeriesModalVisible, setAddSeriesModalVisible] = useState(false);
	let openAddSeriesModal = useCallback(() => setAddSeriesModalVisible(true), []);
	let closeAddSeriesModal = useCallback(() => setAddSeriesModalVisible(false), []);

	let updates = useFormValue("updates");
	let { periodId } = updates || emptyObject;
	let { access, object, objectId, scorecardId, shadowMeasureId } = useFormValue();
	let editFlag = objectId ? checkAccess({ access, action: "edit" }) : true;
	let measureId = object === "measure" ? objectId : shadowMeasureId;
	let loadingFlag = !updates || !measureId;

	let closeDetailPageEditMode = useCallback(
		(e) => {
			if (e) e.stopPropagation(); // prevent openEditMode's setLocalState({ editElementFlag: true })
			if (formDirtyFlag) {
				resetForm();
			}
			closeEditMode();
		},
		[closeEditMode, formDirtyFlag, resetForm]
	);

	let openDetailPageEditMode = useCallback(() => {
		// eslint-disable-next-line no-underscore-dangle
		if (!formValueRef.current?._measureDataEdited) setFormValue("_measureDataEdited", true);
		if (!editPodFlag) openEditMode();
	}, [editPodFlag, formValueRef, openEditMode, setFormValue]);

	let GridComponent = exportFlag ? SeriesGridPrint : FormSeriesGrid;

	return (
		<>
			<Pod
				className={className}
				headerText={translate(title, { objectType: object })}
				headerRightContent={
					editElementFlag && editFlag ? (
						<AddSeriesButton
							openAddSeriesModal={openAddSeriesModal}
							setAddSeriesModalVisible={setAddSeriesModalVisible}
						/>
					) : (
						<LastCalculatedBadge />
					)
				}
				hideBorderFlag={hideBorderFlag}
				marginTop={marginTop}
				onDoubleClick={onDoubleClick}
				overflowX={overflowX}
				podStyle={podStyle}
			>
				{loadingFlag ? (
					null
				) : (
					<Block marginBottom="9px" minHeight="200px">
						<GridComponent
							CalculationMapTab={CalculationMapTab}
							EditSeriesModal={EditSeriesModal}
							autoHeightFlag
							afterBeginEditing={openDetailPageEditMode}
							measureId={measureId}
							inModalFlag={inModalFlag}
							limitRowCountFlag
							onEditSeriesModalOpen={closeDetailPageEditMode}
							periodId={periodId}
							scorecardId={scorecardId}
						/>
					</Block>
				)}
			</Pod>
			{addSeriesModalVisible && (
				<EditSeriesModal close={closeAddSeriesModal} modalWindowVisible measureId={measureId} />
			)}
		</>
	);
};

MeasureGridPod.propTypes = propTypes;
export default MeasureGridPod;
