import SeriesGridContent from "./SeriesGridContent";
import SeriesGridProvider from "./Provider";
import PropTypes from "prop-types";

let propTypes = {
	CalculationMapTab: PropTypes.elementType,
	EditSeriesModal: PropTypes.elementType,
	afterBeginEditing: PropTypes.func,
	autoHeightFlag: PropTypes.bool,
	displayHiddenSeriesFlag: PropTypes.bool,
	inModalFlag: PropTypes.bool,
	limitRowCountFlag: PropTypes.bool,
	measureId: PropTypes.number.isRequired,
	onEditSeriesModalOpen: PropTypes.func,
	periodId: PropTypes.number,
	scorecardId: PropTypes.number,
};

let FormGridSeries = ({
	CalculationMapTab,
	EditSeriesModal,
	afterBeginEditing,
	autoHeightFlag,
	displayHiddenSeriesFlag,
	inModalFlag,
	limitRowCountFlag,
	measureId,
	onEditSeriesModalOpen,
	periodId,
	scorecardId,
}) => (
	<SeriesGridProvider
		displayHiddenSeriesFlag={displayHiddenSeriesFlag}
		inModalFlag={inModalFlag}
		measureId={measureId}
		periodId={periodId}
		scorecardId={scorecardId}
	>
		<SeriesGridContent
			CalculationMapTab={CalculationMapTab}
			EditSeriesModal={EditSeriesModal}
			afterBeginEditing={afterBeginEditing}
			autoHeightFlag={autoHeightFlag}
			limitRowCountFlag={limitRowCountFlag}
			measureId={measureId}
			onEditSeriesModalOpen={onEditSeriesModalOpen}
		/>
	</SeriesGridProvider>
);

FormGridSeries.propTypes = propTypes;
export default FormGridSeries;
