import FormButton from "@clearpoint/old-theme/Form/Button/Button";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalEdit from "@clearpoint/old-theme/Modal/Modal.Edit";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import { useCallback } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";

let propTypes = {
	close: PropTypes.func,
};

let EditDefaultLayoutModalContent = ({ close }) => {
	let translate = useTranslate();
	return (
		<>
			<ModalHeader>
				<ModalTitle>{translate("layout.renameOrHideDefaultLayout")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<FormFieldName name="name" label={translate("layout.renameReport")} />
				<FormCheck name="hidden">{translate("layout.hideReport")}</FormCheck>
			</ModalBody>
			<ModalFooter>
				<FormButton onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</FormButton>
				<FormButton color="primary">{translate("global.save")}</FormButton>
			</ModalFooter>
		</>
	);
};
EditDefaultLayoutModalContent.propTypes = propTypes;

let editDefaultLayoutModalPropTypes = {
	layoutId: PropTypes.number,
};
let EditDefaultLayoutModal = ({ layoutId, ...props }) => {
	let translate = useTranslate();
	let { session } = useOldSession();
	let { object } = session;
	let onSubmit = useCallback(() => {
		toast.success(translate("toaster.messages.reports.saved"));
	}, [translate]);
	return (
		<ModalEdit {...props} size="medium" object={`${object}Layout`} objectId={layoutId} onSubmit={onSubmit}>
			<EditDefaultLayoutModalContent close={props.close} />
		</ModalEdit>
	);
};
EditDefaultLayoutModal.propTypes = editDefaultLayoutModalPropTypes;
export default EditDefaultLayoutModal;
