import PropTypes from "prop-types";
import styled from "styled-components";

let imagePropTypes = {
	alt: PropTypes.string.isRequired,
	"data-testid": PropTypes.string,
	height: PropTypes.string,
	maxHeight: PropTypes.string,
	maxWidth: PropTypes.string,
	onLoad: PropTypes.func,
	src: PropTypes.string,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let Image = ({ alt, src, className, "data-testid": dataTestId, onLoad, style }) => (
	<img data-testid={dataTestId} className={className} alt={alt} src={src} onLoad={onLoad} style={style} />
);

Image.propTypes = imagePropTypes;

let sizes = {
	tiny: "28px",
	small: "36px",
	medium: "48px",
	large: "60px",
};

let PictureCircle = styled(Image)`
	margin: 0px 0px 0px 0px;
	border-radius: 50%;
	display: inline-block;
	width: ${(props) => props.diameter};
	height: ${(props) => props.diameter};
	line-height: ${(props) => props.diameter};
`;

let propTypes = {
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	src: PropTypes.string,
};

let ImageCircle = ({ size, src, ...props }) => (
	<PictureCircle src={src} diameter={sizes[size] || sizes.medium} {...props} />
);

ImageCircle.propTypes = propTypes;

export default ImageCircle;
