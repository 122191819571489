import FormFieldDisplayCompleted from "@clearpoint/old-theme/Form/Field/Display/Completed";
import useCheckPodEditable from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/useCheckPodEditable";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	name: PropTypes.string,
};

let Completed = ({ name }) => {
	let { object, objectId } = useFormValue();
	let { fieldId, openEditMode, readOnlyFlag, updateModeFlag, pod } = usePodContext();
	let checkPodEditable = useCheckPodEditable(emptyObject);

	if (!pod) {
		pod = { fieldId, readOnlyFlag, updateModeFlag }; // usePodContext no longer has pod on DEV: https://github.com/ClearPoint-Strategy/cps-react/pull/2346
	}

	return (
		<FormFieldDisplayCompleted
			disabled={!checkPodEditable(pod, true)}
			name={name}
			object={object}
			objectId={objectId}
			onClick={openEditMode}
		/>
	);
};

Completed.propTypes = propTypes;
export default Completed;
