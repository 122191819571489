import Block from "../../../Block";
import Icon from "../../../Icon/Icon";
import Input from "../../../Form/Input/Input";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	periodLockedFlag: PropTypes.bool,
};
let FormFieldDisplayPercentComplete = ({ periodLockedFlag, name, ...props }) => {
	let value = useFormValue(name);
	return periodLockedFlag ? (
		<Input
			left={
				<Block>
					<Icon name="locked" color={theme.muted} size="tiny" noFixedWidthFlag />
				</Block>
			}
			name={name}
			disabled
			{...props}
		/>
	) : (
		`${value} %`
	);
};
FormFieldDisplayPercentComplete.propTypes = propTypes;

export default FormFieldDisplayPercentComplete;
