import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import PropTypes from "prop-types";

let propTypes = {
	editFlag: PropTypes.bool,
};
let HideTitle = ({ editFlag, ...props }) => {
	return editFlag ? (
		<RadioBoolean {...props} />
	) : (
		<Check data-testid="hideTitle" text={props.label} marginBottom="0px" {...props} label="" />
	);
};
HideTitle.propTypes = propTypes;
export default HideTitle;
