import isEqual from "lodash/isEqual";
import { useCallback } from "react";
import { createGlobalState } from "react-use";

let useTranslateLookupState = createGlobalState({});
let useScorecardIdListState = createGlobalState([]);

let useTranslatorState = () => {
	let [translateLookup, setTranslateLookup] = useTranslateLookupState();
	let [scorecardIdList, setScorecardIdList] = useScorecardIdListState();
	let addScorecardId = useCallback(
		(scorecardId) => {
			if (!scorecardIdList.includes(+scorecardId))
				setScorecardIdList((x) => (x.includes(+scorecardId) ? x : [...scorecardIdList, +scorecardId]));
		},
		[scorecardIdList, setScorecardIdList]
	);
	let clearScorecardIdList = useCallback(() => {
		if (!isEqual(scorecardIdList, [])) setScorecardIdList((x) => (isEqual(x, []) ? x : []));
	}, [scorecardIdList, setScorecardIdList]);
	return { translateLookup, setTranslateLookup, addScorecardId, clearScorecardIdList, scorecardIdList };
};

export default useTranslatorState;
