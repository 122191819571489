import { useCallback, useMemo } from "react";

import DropdownDivider from "@clearpoint/old-theme/Dropdown/Dropdown.Divider";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import SplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton";
import EmailPageModalOnClick from "@clearpoint/old-theme/Modal/Modal.EmailPage.OnClick";
import GeneratePDFModalOnClick from "@clearpoint/old-theme/Modal/Modal.GeneratePDF.OnClick";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess, useReportFilter } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate, useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";

import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import EditDefaultLayoutModal from "@components/Layouts/ManageLayouts/EditDefaultLayoutModal";
import EditGridLayoutModal from "@components/Layouts/ManageLayouts/EditGridLayoutModal";
import EditReferenceLayoutModal from "@components/Layouts/ManageLayouts/EditReferenceLayoutModal";
import generateCreatePPT from "@components/Layouts/ViewLayout/ViewReference/generateCreatePPT";

let EditSplitButton = () => {
	let translate = useTranslate();
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let checkAccess = useCheckAccess();
	let { set } = useOldQueryStore();
	let { session } = useOldSession();
	let { layout } = useLocalState();
	let { reportFilter } = useReportFilter();
	let { access, defaultLayout: defaultLayoutFlag, layoutId, name, object, referenceLayoutId, scorecardId } = layout;
	let adminAccessFlag = checkAccess({ access, action: "admin", scorecardId });
	let { periodId } = session;
	let EditModal = referenceLayoutId
		? EditReferenceLayoutModal
		: defaultLayoutFlag
		? EditDefaultLayoutModal
		: EditGridLayoutModal;
	let EditModalWindow;
	if (EditModal === EditGridLayoutModal) {
		EditModalWindow = EditModal;
		EditModal = undefined;
	}
	let EditOnClick = useCallback(
		({ children }) =>
			EditModalWindow ? (
				<ModalWindowOnClick ModalWindow={EditModalWindow} layoutId={layoutId} object={object}>
					{children}
				</ModalWindowOnClick>
			) : (
				<ModalOnClick Modal={EditModal} layoutId={layoutId} object={object}>
					{children}
				</ModalOnClick>
			),
		[EditModal, EditModalWindow, layoutId, object]
	);

	let createPPT = useMemo(
		() =>
			generateCreatePPT(set, translate, translateOnlyInsideCurlyBraces).bind(null, layout, periodId, {
				filter: reportFilter || undefined,
			}),
		[layout, periodId, reportFilter, set, translate, translateOnlyInsideCurlyBraces]
	);

	return !adminAccessFlag ? (
		<GeneratePDFModalOnClick object={object} layoutId={layoutId} title={translateOnlyInsideCurlyBraces(name)}>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<SplitButton icon="pdf">
					<GeneratePDFModalOnClick
						object={object}
						layoutId={layoutId}
						title={translateOnlyInsideCurlyBraces(name)}
					>
						<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
					</GeneratePDFModalOnClick>
					<DropdownItem icon="powerpoint" onClick={createPPT}>
						{translate("buttonBar.exportPPT")}
					</DropdownItem>
					<EmailPageModalOnClick object={object} layoutId={layoutId} title={translateOnlyInsideCurlyBraces(name)}>
						<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
					</EmailPageModalOnClick>
				</SplitButton>
			</StyleWrapper>
		</GeneratePDFModalOnClick>
	) : (
		<EditOnClick>
			<StyleWrapper marginLeft={theme.smallSpace}>
				<SplitButton icon="editReport">
					<EditOnClick>
						<DropdownItem icon="editReport">{translate("layout.editLayout")}</DropdownItem>
					</EditOnClick>
					<DropdownDivider />
					<GeneratePDFModalOnClick
						object={object}
						layoutId={layoutId}
						title={translateOnlyInsideCurlyBraces(name)}
					>
						<DropdownItem icon="pdf">{translate("buttonBar.exportPDF")}</DropdownItem>
					</GeneratePDFModalOnClick>
					<DropdownItem icon="powerpoint" onClick={createPPT}>
						{translate("buttonBar.exportPPT")}
					</DropdownItem>
					<EmailPageModalOnClick object={object} layoutId={layoutId} title={translateOnlyInsideCurlyBraces(name)}>
						<DropdownItem icon="email">{translate("buttonBar.emailPage")}</DropdownItem>
					</EmailPageModalOnClick>
				</SplitButton>
			</StyleWrapper>
		</EditOnClick>
	);
};
export default EditSplitButton;
