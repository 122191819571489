import { buildQueryString, clearLocalStorageExceptKeys } from "@clearpoint/utils";
import http from "@clearpoint/services/httpService/index";

let generateLogout = ({
	logoutStatus,
	logoutUrl,
	queryParameters,
	setSession,
	setState,
	toast,
	trackEvent,
	update,
}) => {
	return ({ timeoutFlag: timeout, message }) => {
		if (logoutStatus !== "running") {
			const { location } = window || {};
			trackEvent("logged-out", { timeout, message });
			update();
			setState({ logoutStatus: "running" });

			try {
				http.post("/logout");
				delete queryParameters.token;
				location.href = `/#/login${buildQueryString(queryParameters)}`;
				location.reload(true);
			} catch (error) {
				console.error(error);
			}

			if (message) {
				toast.error(message, { autoClose: false, delay: 1000 });
			}

			setSession({ logoutStatus: "running", logoutUrl, success: false });
			clearLocalStorageExceptKeys(["username"]);
		}
	};
};

export default generateLogout;
