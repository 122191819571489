import { useMemo } from "react";
import PropTypes from "prop-types";

import ChecklistCheckAll from "@clearpoint/old-theme/Checklist/Checklist.Check.All";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FormCheckArray from "@clearpoint/old-theme/Form/CheckArray/CheckArray";
import FormCheckArrayItem from "@clearpoint/old-theme/Form/CheckArray/CheckArray.Item";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Hide from "@clearpoint/old-theme/Hide";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";

import ChecklistProvider from "@clearpoint/providers/ChecklistProvider";
import { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
  name: PropTypes.string,
};
let SelectedElementsModalContent = ({ name }) => {
  let translate = useTranslate();
  let { data: filteredElementList } = useFilter();
  filteredElementList = filteredElementList || [];
  let { object, scorecardId } = useFormValue();
  let valueList = useMemo(
    () => filteredElementList.map((x) => ({ object: x.object, objectId: x.objectId })),
    [filteredElementList]
  );
  return (
    <ChecklistProvider>
      <FilterInput
        name="elementName"
        filterBy="name"
        left={<ChecklistCheckAll />}
        placeholder={translate("manage.filterElements", { elements: translate(`objects.${object}s`) })}
      />
      <FormGroup title={translate("layout.selectElements")}>
        <FormCheckArray name={name}>
          {filteredElementList.map((x, i) => (
            <Hide visible={x.visible} key={x.objectId} lineHeight="1.15">
              <FormCheckArrayItem value={valueList[i]} checklistFlag marginBottom={theme.smallSpace}>
                <div>{translate(x.name)}</div>
                <small>{x.scorecardId !== scorecardId && x.scorecard}</small>
              </FormCheckArrayItem>
            </Hide>
          ))}
        </FormCheckArray>
      </FormGroup>
    </ChecklistProvider>
  );
};
SelectedElementsModalContent.propTypes = propTypes;
export default SelectedElementsModalContent;
