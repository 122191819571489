import Button from "./Button";
import Icon from "../Icon/Icon";
import TrashModal from "../Modal/Modal.Trash";
import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

let propTypes = {
	body: PropTypes.string,
	color: PropTypes.string,
	className: PropTypes.string,
	confirmButtonText: PropTypes.string,
	"data-testid": PropTypes.string,
	disabled: PropTypes.bool,
	listKey: PropTypes.string,
	method: PropTypes.oneOf(["post", "delete"]),
	name: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.any,
	objectIdList: PropTypes.array,
	onTrash: PropTypes.func,
	padding: PropTypes.string,
	pageButtonFlag: PropTypes.bool,
	queryString: PropTypes.string,
	size: PropTypes.oneOf(["tiny", "small", "medium"]),
	title: PropTypes.string,
	toast: PropTypes.string,
};

let defaultProps = {
	color: "white",
	size: "tiny",
};

let TrashButton = ({
	body,
	color,
	className,
	confirmButtonText,
	"data-testid": dataTestId,
	disabled,
	iconTransform,
	listKey,
	method,
	name,
	object,
	objectId,
	objectIdList,
	onTrash,
	padding,
	pageButtonFlag,
	queryString,
	Modal,
	size,
	title,
	toast,
	...props
}) => {
	let [trashModalVisible, setTrashModalVisible] = useState(false);
	let showModal = useCallback((e) => {
		if (e) e.stopPropagation();
		setTrashModalVisible(true);
	}, []);
	let close = useCallback((e) => {
		if (e) e.stopPropagation();
		setTrashModalVisible(false);
	}, []);
	let ButtonTrashModal = Modal || TrashModal;
	return (
		<>
			<Button
				data-testid={dataTestId || "trash-button"}
				onClick={showModal}
				color={color}
				size={size}
				padding={(size === "tiny" && "3px 6px") || padding}
				className={classNames("js-delete-button", className)}
				disabled={disabled}
				{...props}
			>
				<Icon name={pageButtonFlag ? "trashPage" : "trash"} transform={iconTransform || "up-2"} />
			</Button>
			{trashModalVisible && (
				<ButtonTrashModal
					body={body}
					close={close}
					confirmButtonText={confirmButtonText}
					listKey={listKey}
					method={method}
					modalVisible={trashModalVisible}
					name={name}
					objectIdList={objectIdList}
					object={object}
					objectId={objectId}
					onTrash={onTrash}
					queryString={queryString}
					title={title}
					toastMessage={toast}
				/>
			)}
		</>
	);
};

TrashButton.propTypes = propTypes;
TrashButton.defaultProps = defaultProps;

export default TrashButton;
