import DefaultModal from "./Modal";
import Form from "../Form/Form";
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	children: PropTypes.element.isRequired,
	"data-testid": PropTypes.string,
	Modal: PropTypes.elementType,
	onClick: PropTypes.func,
	formFlag: PropTypes.bool,
};
let defaultProps = {
	Modal: DefaultModal,
};
let ModalOnClick = ({ children, "data-testid": dataTestId, Modal, onClick, formFlag, ...props }) => {
	let child = React.Children.only(children);
	let [{ modalVisible }, setState] = useStateObject({
		modalVisible: false,
	});
	let showModal = useCallback(
		(e) => {
			if (onClick) onClick(e);
			setState({
				modalVisible: true,
			});
		},
		[onClick, setState]
	);
	let close = useCallback(() => {
		setState({
			modalVisible: false,
		});
	}, [setState]);
	return useMemo(
		() => (
			<>
				{React.cloneElement(child, {
					onClick: showModal,
				})}
				{modalVisible &&
					(formFlag ? (
						<Form>
							<Modal
								data-testid={dataTestId}
								modalVisible={modalVisible}
								modalWindowVisible={modalVisible}
								close={close}
								{...props}
							/>
						</Form>
					) : (
						<Modal
							data-testid={dataTestId}
							modalVisible={modalVisible}
							modalWindowVisible={modalVisible}
							close={close}
							{...props}
						/>
					))}
			</>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[child, close, formFlag, modalVisible, props, showModal]
	);
};
ModalOnClick.propTypes = propTypes;
ModalOnClick.defaultProps = defaultProps;

export default ModalOnClick;
