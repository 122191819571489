// NOTE: Most data transformations should be made here

// Exception: If the data should appear differently from the saved form value
// Change it in useElementValueTransformation and
// Make sure to reverse that change here

import { useCallback, useRef } from "react";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";

let useFormValueTransform = (name) => {
	let { session } = useOldSession();
	let { token, rootPath } = session;
	let rootPathRef = useRef(rootPath);
	rootPathRef.current = rootPath;
	let styleName = `_style${name}`;
	let styleValue = useFormValue(styleName);
	return useCallback(
		(elementValue) => {
			// add back style, revert image url changes
			let formValue = (styleValue || "") + (elementValue || "");
			// handle blank case
			if (!formValue?.replace(/<p>|<\/p>|<br>/g, "")) return "";
			// revert url encoding
			formValue = formValue
				.replace(/<img src="(.*?)"/g, (_match, url) => `<img src="${decodeURI(url)}"`)
				// remove token from images
				.replace(new RegExp(`[\\?|\\&]t=${token}`, "g"), "")
				// use relative path for images
				.replace(new RegExp(`<img src="${rootPathRef.current}/`, "g"), `<img src="`)
				// remove problematic line breaks: issue #1400
				.replace(
					/style="([^"]*)"/g,
					(_match, styleAttributeContents) => `style="${styleAttributeContents.replace(/\s*\n\s*/g, "")}"`
				)
				// replace center tag: issue #1418
				.replace(
					/<center>(.*?)<\/center>/g,
					(_match, content) => `<div style="text-align: center;">${content}</div>`
				)
				// remove empty paragraphs #1511
				.replace(/<p>[ \n]<\/p>/g, "");
			return formValue;
		},
		[styleValue, token, rootPathRef]
	);
};

export default useFormValueTransform;
