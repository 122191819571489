import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";

import { capitalizeFirstLetter } from "@clearpoint/utils";

import Block from "../../Block";
import Button from "../../Button/Button";
import Icon from "../../Icon/Icon";
import { theme } from "../../Theme";
import SplitButtonMenu from "./SplitButtonMenu";

let StyledSplit = styled(Dropdown)`
	height: ${(props) => props.$dropdownHeight};
	padding-right: 0px !important;
	padding-left: 0px !important;
	border: none !important;
	position: ${(props) => props.position};
	.btn {
		width: 44px;
		height: 100%;
		transition: all 0.25s ease-in-out;
	}
	& .dropdown-toggle {
		filter: brightness(95%);
		width: 8px !important;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		background-color: ${(props) =>
			props.color === "white" ? theme.gray100 : props.theme[capitalizeFirstLetter(props.color)]};
		border-color: ${(props) => props.theme[capitalizeFirstLetter(props.color)]};
		&:hover {
			background-color: ${(props) => props.theme["dark" + capitalizeFirstLetter(props.color)]};
			border-color: transparent;
			box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
		}

		&:disabled {
			background-color: ${(props) => props.theme["dark" + capitalizeFirstLetter(props.color)]};
			border-color: ${(props) => props.theme["dark" + capitalizeFirstLetter(props.color)]};
		}

		&::before {
			margin-left: -4px !important;
		}

		&::after {
			margin-left: -4px !important;
			vertical-align: 0;
		}
	}
	.dropdown-item {
		min-width: 190px !important;
		max-width: ${(props) => props.$maxWidth ?? "250px"};
	}
`;

let StyledButton = styled(Button)`
	border-top-right-radius: ${(props) => (props.hideDropdownFlag ? "4px" : "0")};
	border-bottom-right-radius: ${(props) => (props.hideDropdownFlag ? "4px" : "0")};
	&.focus,
	&:focus {
		background-color: ${(props) => theme[props.color]} !important;
		border-color: ${(props) => theme[props.color]} !important;
	}
`;

let propTypes = {
	children: PropTypes.node,
	color: PropTypes.oneOf(["primary", "secondary", "success", "danger", "warning", "info", "dark", "light", "white"]),
	dataTestId: PropTypes.string,
	disabled: PropTypes.bool,
	drop: PropTypes.oneOf(["up", "down", "start", "end"]),
	dropdownHeight: PropTypes.string,
	hideDropdownFlag: PropTypes.bool,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	maxWidth: PropTypes.string,
	menuAlign: PropTypes.string,
	position: PropTypes.string,
	renderDropdownMenuAboveBootstrapModalFlag: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

let defaultProps = {
	color: "primary",
	dropdownHeight: "auto",
	position: "relative",
};

let SplitButton = ({
	children,
	color,
	"data-testid": dataTestId,
	disabled,
	drop,
	dropdownHeight,
	hideDropdownFlag,
	icon,
	iconColor,
	maxWidth,
	menuAlign,
	position,
	renderDropdownMenuAboveBootstrapModalFlag,
	title,
	...props
}) => {
	let splitButtonRef = useRef();
	useEffect(() => {
		if (splitButtonRef.current) splitButtonRef.current.querySelector("button").disabled = disabled;
	}, [disabled]);
	return (
		<Block data-testid={dataTestId || "split-button"} display="flex" padding="0 !important" alignItems="stretch">
			<StyledButton className="split" data-testid="save-button" color={color} disabled={disabled} {...props}>
				{title}
				{icon && <Icon name={icon} color={iconColor} />}
			</StyledButton>
			{!hideDropdownFlag && (
				<StyledSplit
					color={color}
					drop={drop}
					$dropdownHeight={dropdownHeight}
					position={position}
					data-testid="split-dropdown-button"
					$maxWidth={maxWidth}
					ref={splitButtonRef}
				>
					<Dropdown.Toggle color={color} title="" variant={color} />
					<SplitButtonMenu
						align={menuAlign}
						renderDropdownMenuAboveBootstrapModalFlag={renderDropdownMenuAboveBootstrapModalFlag}
					>
						{children}
					</SplitButtonMenu>
				</StyledSplit>
			)}
		</Block>
	);
};

SplitButton.propTypes = propTypes;
SplitButton.defaultProps = defaultProps;

export default SplitButton;
