import http from "./httpService";
import { setAxiosProperty } from "./axiosService";
import { getEnvironment } from "@clearpoint/utils";

function setAuthorization(session) {
	if (session) {
		let environment = getEnvironment();
		setAxiosProperty('defaults.headers.common["Authorization"]', session.token);
		if (environment === "localdev") setAxiosProperty('defaults.headers.common["SessionId"]', session.sessionId);
	}
	return session;
}

function login({ username, password, districtId, machineId, deviceTokens }) {
	return http
		.post("/authenticate", {
			username,
			password,
			machineId,
			deviceTokens: deviceTokens || "",
			districtId: districtId || "",
		})
		.then(setAuthorization);
}

function requestCode({ username, authOption, userId }) {
	return http.post("/authenticate", { username, authOption, userId });
}

function twoFactorLogin({
	username,
	password,
	districtId,
	machineId,
	deviceName,
	authOption,
	authCode,
	rememberDeviceFlag,
}) {
	return http
		.post("/authenticate", {
			username,
			password,
			districtId,
			machineId,
			deviceName,
			authOption,
			authCode,
			rememberDevice: rememberDeviceFlag,
		})
		.then(setAuthorization);
}

function reset({ username }) {
	return http.post("/resetPassword", { username });
}

function getPasswordRules({ token }) {
	return http.get("/resetPassword?token=" + token);
}

export { getPasswordRules, login, requestCode, reset, setAuthorization, twoFactorLogin };
