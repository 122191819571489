import isObject from "lodash/isObject.js";
import isArray from "lodash/isArray.js";
import cloneDeep from "lodash/cloneDeep.js";
let objectToKey = (object, nestedFlag) => {
	if (isObject(object) && !isArray(object)) {
		object = cloneDeep(object);
		for (let key in object) {
			object[key] = objectToKey(object[key], true);
		}
		object = Object.entries(object).sort((a, b) => (a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0));
	} else if (isArray(object)) {
		object.forEach((x, i) => {
			object[i] = objectToKey(x, true);
		});
	}
	return nestedFlag ? object : JSON.stringify(object);
};
export default objectToKey;
