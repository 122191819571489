let getFilteredScorecardList = ({ menu, pageList, report, scorecardList }) => {
	if (!menu?.[0] || !pageList || !scorecardList) return [];

	const dashboardLayoutList = report?.contents
		?.map((x) => x.pages)
		?.flat()
		?.filter((x) => x.object === "scorecard")
		// replace page name with scorecard name for label purposes
		?.map((x) => {
			return {
				...x,
				name:
					scorecardList.find((y) => {
						return +x.scorecardId === +y.objectId;
					})?.name || x.name,
			};
		});

	return dashboardLayoutList;
};

export default getFilteredScorecardList;
