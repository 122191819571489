import createUrl from "./Store.createUrl";

let generateClearFunction =
	({ dispatch, state, objectToKey }) =>
	async (id) => {
		if (typeof id !== "object" && id !== "all") {
			throw new Error("Invalid call to clear. " + JSON.stringify(id));
		}
		if (id === "all") {
			let dontClearList = new Set([
				"user",
				"status",
				"timezone",
				"period",
				"profile",
				"options",
				"alert",
				"tag",
				"image",
				"menu",
				"language",
			]);
			let filteredState = state.filter((x) => dontClearList.has(x.object));
			for (let item of filteredState) {
				dispatch({ ...item, clearFlag: true });
			}
		} else {
			if (!id.storeKey) {
				id.storeKey = id.url;
				if (id.object && !id.storeKey) {
					id.storeKey = createUrl(id);
				}
				if (id.payload) id.storeKey += objectToKey(id.payload);
			}
			dispatch({ ...id, clearFlag: true });
		}
	};

export default generateClearFunction;
