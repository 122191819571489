import { createContext, useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";

import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useCheckFeature, useEvaluationEnabledLookup } from "@clearpoint/hooks";
import { emptyArray } from "@clearpoint/utils";

import FilterProvider from "@clearpoint/providers/FilterProvider";

let PodListContext = createContext();

let propTypes = {
	children: PropTypes.node,
	podList: PropTypes.arrayOf(
		PropTypes.shape({
			hidden: PropTypes.bool,
			fieldId: PropTypes.string,
		})
	),
};

let PodListProvider = ({ children, podList }) => {
	let { formDefaultValue } = useFormContext();
	let { layout: areaList, object } = useFormValue();
	let evaluationEnabledFlag = useEvaluationEnabledLookup()[object];
	let checkFeature = useCheckFeature();
	let goalsAccessFlag = checkFeature("okrs");

	let initialLayoutPodList = useMemo(
		() =>
			formDefaultValue?.layout
				?.map((x) => x.pods)
				.flat()
				.filter((x) => !!x),
		[formDefaultValue]
	);

	let runningLayoutPodList = useMemo(
		() =>
			areaList
				?.map((x) => x.pods)
				.flat()
				.filter((x) => !!x),
		[areaList]
	);

	let generateMenuPanelPodList = useCallback(
		(layoutPodList) =>
			podList?.filter((pod) => {
				let podHiddenFlag = pod.hidden;
				let podAlreadyInLayoutFlag = layoutPodList?.some((podInLayout) => podInLayout.fieldId === pod.fieldId);
				let requiresEvaluationFlag = !evaluationEnabledFlag && ["series"].includes(pod.type);
				let hideGoalsPodFlag = pod.fieldId === "goals" && !goalsAccessFlag;

				return !podHiddenFlag && !podAlreadyInLayoutFlag && !requiresEvaluationFlag && !hideGoalsPodFlag;
			}),
		[evaluationEnabledFlag, goalsAccessFlag, podList]
	);

	// Freeze MenuPanel's intial pod list so form state updates don't interfere with FilterProvider's setData (necessary for drag-and-drop)
	let initialMenuPodList = useMemo(
		() => generateMenuPanelPodList(initialLayoutPodList),
		[generateMenuPanelPodList, initialLayoutPodList]
	);
	let runningMenuPodList = useMemo(
		() => generateMenuPanelPodList(runningLayoutPodList),
		[generateMenuPanelPodList, runningLayoutPodList]
	);

	return (
		<FilterProvider data={initialMenuPodList ?? emptyArray}>
			<PodListContext.Provider
				value={{
					menuPanelPodList: runningMenuPodList,
					podListAll: podList,
				}}
			>
				{children}
			</PodListContext.Provider>
		</FilterProvider>
	);
};

let usePodListContext = () => useContext(PodListContext);
export { usePodListContext };

PodListProvider.propTypes = propTypes;
export default PodListProvider;
