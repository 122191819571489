import EditMultipleMilestonesModal from "@components/Elements/Initiatives/ManageInitiatives/EditInitiativeModalWindow/MilestonesTab/EditMultipleMilestonesModal";
import MilestonesAIAssistant from "@components/AI/MilestonesAIAssistant";
import Modal from "@clearpoint/old-theme/Modal/Modal";
import OpenAIAgreementModal from "@components/AI/OpenAIAgreementModal/index";
import useAgreementModalVisible from "@components/AI/useAgreementModalVisible";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import useOnHideOrShowBootstrapModal from "./hooks/useOnHideOrShowBootstrapModal";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";
import { useStateObject } from "@clearpoint/hooks";


let propTypes = {
	close: PropTypes.func.isRequired,
	fieldId: PropTypes.string.isRequired,
	modalVisible: PropTypes.bool.isRequired,
};

let MilestonesAssistantModal = ({ close, fieldId, modalVisible }) => {
	let [{ editMutipleMilestonesModalVisible, aiAddMilestonesName }, setEditMultipleMilestonesModalState] =
		useStateObject({
			aiAddMilestonesName: null,
			editMutipleMilestonesModalVisible: false,
		});
	let agreementModalVisible = useAgreementModalVisible();
	let closeModalHandler = () => {
		setEditMultipleMilestonesModalState({ editMutipleMilestonesModalVisible: false });
	};
	let { elementStatus } = useDetailPageData() || emptyObject;

	useOnHideOrShowBootstrapModal({ modalVisible: editMutipleMilestonesModalVisible });

	return (
		<>
			{editMutipleMilestonesModalVisible && (
				<EditMultipleMilestonesModal
					aiAddMilestonesName={aiAddMilestonesName}
					modalVisible={editMutipleMilestonesModalVisible}
					parentId={elementStatus.objectId}
					scorecardId={elementStatus.scorecardId}
					close={closeModalHandler}
				/>
			)}
			<Modal close={close} modalVisible={modalVisible}>
				{!agreementModalVisible && (
					<MilestonesAIAssistant
						closeModal={close}
						fieldId={fieldId}
						setEditMultipleMilestonesModalState={setEditMultipleMilestonesModalState}
					/>
				)}
				<OpenAIAgreementModal close={close} modalVisible={agreementModalVisible} />
			</Modal>
		</>
	);
};

MilestonesAssistantModal.propTypes = propTypes;

export default MilestonesAssistantModal;
