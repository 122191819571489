import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayElapsedPercentComplete = ({ name }) => {
	let translate = useTranslate();
	let elapsedPercentComplete = useFormValue(name);
	return <>{elapsedPercentComplete === "N/A" ? translate("global.notAvailable") : elapsedPercentComplete}</>;
};

FormFieldDisplayElapsedPercentComplete.propTypes = propTypes;
export default FormFieldDisplayElapsedPercentComplete;
