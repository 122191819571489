import getChildMilestoneList from "./getChildMilestoneList";

let moveChildMilestonesWithParentMilestone = ({ list, destinationIndex, originIndex }) => {
	let droppedMilestone = list[originIndex];
	let listWithItemRemoved = [...list.slice(0, originIndex), ...list.slice(originIndex + 1)];
	let reorderedList = [
		...listWithItemRemoved.slice(0, destinationIndex),
		droppedMilestone,
		...listWithItemRemoved.slice(destinationIndex),
	];

	// account for filtered list items when dropping a milestone below a sibling milestone and its list of child milestones
	let listItemsFilteredFlag = list.some((x) => x.visible === false);
	let currentMilestoneAtDropIndex = list[destinationIndex];
	if (listItemsFilteredFlag && currentMilestoneAtDropIndex.treeLevel > droppedMilestone.treeLevel) {
		let droppedMilestoneSibling = list.find(
			(x) =>
				x.parentId === droppedMilestone.parentId &&
				getChildMilestoneList(list, x).find(
					(childMilestone) => childMilestone.objectId === currentMilestoneAtDropIndex.objectId
				)
		);

		let lastChildMilestoneAtDropTarget = getChildMilestoneList(list, droppedMilestoneSibling).slice(-1)[0];
		let indexOfLastChildMilestone = list.findIndex((x) => x.objectId === lastChildMilestoneAtDropTarget.objectId);
		reorderedList = [
			...listWithItemRemoved.slice(0, indexOfLastChildMilestone),
			droppedMilestone,
			...listWithItemRemoved.slice(indexOfLastChildMilestone),
		];
	}

	let childMilestoneList = getChildMilestoneList(list, droppedMilestone);

	if (childMilestoneList.length === 0) return reorderedList;

	let listWithoutChildMilestones = reorderedList.filter(
		(milestone) => !childMilestoneList.find((childMilestone) => childMilestone.objectId === milestone.objectId)
	);

	let droppedMilestoneIndex = listWithoutChildMilestones.findIndex((x) => x.objectId === droppedMilestone.objectId);
	let newList = [
		...listWithoutChildMilestones.slice(0, droppedMilestoneIndex + 1),
		...childMilestoneList,
		...listWithoutChildMilestones.slice(droppedMilestoneIndex + 1),
	];

	return newList;
};

export default moveChildMilestonesWithParentMilestone;
