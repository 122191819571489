import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import { Draggable } from "react-smooth-dnd";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import useMapCheck from "./useMapCheck";
import PropTypes from "prop-types";
import classNames from "classnames";
import { darken } from "polished";
import { theme } from "@clearpoint/old-theme/Theme";
import { useHoverFlag } from "@clearpoint/hooks";


let propTypes = {
	className: PropTypes.string,
	displayWidth: PropTypes.number,
	left: PropTypes.string,
	name: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	top: PropTypes.string,
};
let MapElement = ({ className, displayWidth, left, name, object, objectId, top, ...props }) => {
	let { hoverFlag, onMouseOver, onMouseOut } = useHoverFlag();
	let checkId = `${object},${objectId}`;
	let { checked, toggleChecked } = useMapCheck(checkId);
	let {
		borderWidth,
		boxBorder,
		boxColor,
		boxRadius,
		boxWidth,
		showNames: customStyleFlag,
		textColor,
	} = useFormValue();
	let backgroundColor, border, borderRadius, color, padding, width;
	if (customStyleFlag) {
		border = `${borderWidth}px ${boxBorder} solid !important`;
		borderRadius = boxRadius < 15 ? `${boxRadius}px` : `${boxRadius}%`;
		padding = "2px 5px 5px";
		width = `${Math.round((boxWidth / 1024) * displayWidth)}px`;
	}
	if (customStyleFlag && !checked) {
		backgroundColor = boxColor;
		color = textColor;
	}
	return (
		<StyleWrapper
			className={className}
			left={left}
			top={top}
			position="absolute"
			$style={`&:hover { z-index: 1000; } &.dropped { visibility: visible !important; transition: none !important }`}
		>
			<Draggable>
				<>
					<StyleWrapper
						border={border}
						borderRadius={borderRadius}
						backgroundColor={backgroundColor}
						color={color}
						width={width}
						padding={padding}
						textAlign="left"
						wordBreak="normal"
						overflow="hidden"
						$style={`&:hover, &.hover, &:focus, &.focus { z-index: 1000; color: ${
							color ? `${color} !important` : undefined
						}; background-color: ${
							backgroundColor ? `${darken(0.15, backgroundColor)} !important` : undefined
						}; }`}
					>
						<Button
							data-object={object}
							data-object-id={objectId}
							className={classNames("map-element", "map-element-in-map")}
							color={checked ? "warning" : "info"}
							noWrapFlag={!customStyleFlag}
							onMouseOver={onMouseOver}
							onMouseOut={onMouseOut}
							onClick={toggleChecked}
							size="tiny"
							{...props}
						>
							{customStyleFlag ? (
								<Block display="flex" alignItems="flex-start" fontSize="12px">
									<Icon name={checked ? "checkbox" : object} transform="down-4" />
									<Block marginLeft={theme.tinySpace}>{name}</Block>
								</Block>
							) : (
								<>
									<Icon name={checked ? "checkbox" : object} transform={customStyleFlag ? "down-4" : "up-1"} />
									{hoverFlag && <>&nbsp;&nbsp;{name}</>}
								</>
							)}
						</Button>
					</StyleWrapper>
				</>
			</Draggable>
		</StyleWrapper>
	);
};
MapElement.propTypes = propTypes;

export default MapElement;
