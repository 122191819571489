import Badge from "@clearpoint/old-theme/Badge/Badge";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useUserId } from "@clearpoint/hooks";


let propTypes = {
	ownerId: PropTypes.number,
};

let OwnerBadge = ({ ownerId }) => {
	let translate = useTranslate();
	let userId = useUserId();

	return (
		userId === ownerId && (
			<Badge color="primary">
				<Icon name="goalOwner" marginRight={theme.tinySpace} />
				{translate("global.owner")}
			</Badge>
		)
	);
};

OwnerBadge.propTypes = propTypes;
export default OwnerBadge;
