import handleCatch from "./Store.generateGetFunction.fetchData.handleCatch";
import handleData from "./Store.generateGetFunction.fetchData.handleData";
import generateRetry from "./Store.generateRetry";
import { sleep } from "@clearpoint/utils";

let fetchData = ({
	dispatch,
	get,
	http,
	httpFunction,
	id,
	method,
	object,
	objectId,
	payload,
	promiseFlag,
	requests,
	setState,
	skipSortFlag,
	skipWaitFlag,
	storeKey,
	testFlag,
	url,
	value,
	waitingRequestCount,
}) => {
	waitingRequestCount.current++;
	let decrementFlag = true;
	let retry = generateRetry();

	let makeRequest = () =>
		httpFunction[method](url, payload)
			.then(async (data) => {
				if (decrementFlag) waitingRequestCount.current--;
				decrementFlag = false;

				let waitForRequestsToFinish = async () => {
					setState({ pendingStoreUpdateCount: (x) => x + 1 });
					while (!testFlag && waitingRequestCount.current > 0) {
						await sleep(16);
					}
					setState({ pendingStoreUpdateCount: (x) => x - 1 });
				};

				if (!testFlag || skipWaitFlag)
					handleData({
						data,
						dispatch,
						get,
						id,
						object,
						objectId,
						request,
						setState,
						skipSortFlag,
						storeKey,
						url,
					});

				if (!testFlag) {
					return waitForRequestsToFinish().then(() =>
						handleData({
							data,
							dispatch,
							get,
							id,
							object,
							objectId,
							request,
							setState,
							skipSortFlag,
							storeKey,
							url,
						})
					);
				}
			})
			.catch((e) => {
				return handleCatch({
					dispatch,
					e,
					http,
					id,
					makeRequest,
					method,
					payload,
					request,
					retry,
					setState,
					storeKey,
					url,
				});
			})
			.finally(() => {
				if (decrementFlag) waitingRequestCount.current--;
			});

	let request = makeRequest();
	requests.current = [...requests.current, { id: storeKey, request, _PUSHER: value?._PUSHER }];
	if (promiseFlag) return request;
	if (value?._REFRESH) return value.data;
};

export default fetchData;
