import { useCallback } from "react";
import PropTypes from "prop-types";
import { useLongPress } from "react-use";

import Block from "@clearpoint/old-theme/Block";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useCheckAccess, useScorecardLockedFlag } from "@clearpoint/hooks";

import HeaderDisplayStatus from "./HeaderDisplayStatus";
import HeaderDisplayTitle from "./HeaderDisplayTitle";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	setEditHeaderFlag: PropTypes.func.isRequired,
};

let HeaderDisplay = ({ setEditHeaderFlag }) => {
	let checkAccess = useCheckAccess();
	let { setLocalState } = useLocalState();

	let { access, object, scorecardId, approvalsPending } = useFormValue();
	let approvalsPendingFlag = (approvalsPending && approvalsPending.length > 0)
	let scorecardFlag = object === "scorecard";
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let openEditMode = useCallback(() => {
		if (scorecardFlag || scorecardLockedFlag || approvalsPendingFlag) return;
		if (checkAccess({ access, action: "update", toastFlag: true, scorecardId })) {
			setLocalState({ editElementFlag: true });
			setEditHeaderFlag(true);
		}
	}, [access, checkAccess, scorecardFlag, scorecardLockedFlag, scorecardId, setEditHeaderFlag, setLocalState]);
	let longPressEvent = useLongPress(openEditMode);

	return (
		<Block display="flex" minWidth="0" alignItems="start" onDoubleClick={openEditMode} {...longPressEvent}>
			<HeaderDisplayStatus />
			<HeaderDisplayTitle />
		</Block>
	);
};

HeaderDisplay.propTypes = propTypes;
export default HeaderDisplay;
