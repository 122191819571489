import Aggregation from "./Aggregation/Aggregation";
import Column from "../../../Column";
import FormLabelLeft from "../../../Form/Form.Label.Left";
import Row from "../../../Row";
import SelectSeries from "./SelectSeries";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";

let AnySeries = () => {
	let translate = useTranslate();
	let formValue = useFormValue();
	return (
		<Row>
			<Column medium={6}>
				<FormLabelLeft label={translate("global.series")}>
					<SelectSeries name="_seriesName" />
				</FormLabelLeft>
			</Column>
			{formValue._seriesName && <Aggregation name="_aggregation" seriesName="_seriesName" />}
		</Row>
	);
};
export default AnySeries;
