import { useCallback, useMemo, useState } from "react";
import camelCase from "lodash/camelCase";
import lowerCase from "lodash/lowerCase";
import PropTypes from "prop-types";
import styled from "styled-components";

import { gettingStartedImage, noResultsImage } from "@clearpoint/assets";
import { useCreateSupportLink, useNoResults, useWindowSize } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import Block from "./Block";
import Button from "./Button/Button";
import Hide from "./Hide";
import HTML from "./HTML";
import Image from "./Image";
import StyleWrapper from "./StyleWrapper";
import { theme } from "./Theme";

let propTypes = {
	addButtonDisabledFlag: PropTypes.bool,
	addFunction: PropTypes.func,
	AddModal: PropTypes.elementType,
	AddModalWindow: PropTypes.elementType,
	className: PropTypes.string,
	feature: PropTypes.string,
	noResultsMessage: PropTypes.string,
	maxWidth: PropTypes.string,
	offsetTop: PropTypes.string,
	results: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.shape({ _loading: PropTypes.bool })]),
	section: PropTypes.string,
};

let StyledBlock = styled(Block)`
	opacity: 0;
	animation: fadeIn 0.2s;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

let NoResults = ({
	addButtonDisabledFlag,
	addFunction,
	AddModal,
	AddModalWindow,
	hideImageFlag,
	className,
	feature,
	gettingStartedFlag,
	maxWidth,
	noResultsMessage = "placeholder.noResults",
	offsetTop,
	results,
	section,
	locked,
	...props
}) => {
	let translate = useTranslate();
	let camelCasedFeature = feature && camelCase(feature);
	let { allResultsFilteredFlag, noResultsFlag } = useNoResults(results);

	let addFlag = addFunction || AddModalWindow || AddModal;

	if (feature && feature !== feature?.toLowerCase()) {
		feature = lowerCase(feature).replaceAll(" ", "-");
	}

	let supportLink = useCreateSupportLink({ componentName: "NoResults", feature, section });

	let displayFeatureFlag = feature && !allResultsFilteredFlag && !locked;
	let [modalVisible, setModalVisible] = useState(false);
	let openModal = useCallback(() => setModalVisible(true), []);
	let closeModal = useCallback(() => {
		setModalVisible(false);
	}, []);

	let { displayClass } = useWindowSize();
	let buttonSize = useMemo(() => {
		if (["tiny", "small"].includes(displayClass)) {
			return "small";
		}
		return "medium";
	}, [displayClass]);

	let showHelpButtonFlag = feature !== "discussions";
	return (
		<>
			<Hide
				className={className}
				visible={noResultsFlag}
				flex="1"
				paddingBottom={theme.space}
				overflow="visible"
				maxHeight={displayFeatureFlag ? "60%" : "75%"}
				height="100%"
			>
				<StyledBlock
					userSelect="none"
					flex="1"
					overflow="visible"
					textAlign="center"
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					marginTop={offsetTop || 0}
					data-testid="no-results-page"
				>
					<Block height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
						<Block display="flex" maxHeight={displayFeatureFlag ? "60%" : "75%"} maxWidth={maxWidth || "600px"}>
							{!hideImageFlag && (
								<Image
									objectFit="contain"
									src={displayFeatureFlag || gettingStartedFlag ? gettingStartedImage : noResultsImage}
									alt={translate(displayFeatureFlag ? "global.gettingStarted" : "search.noResults")}
									width="100%"
								/>
							)}
						</Block>
						<Block
							marginTop={theme.tinySpace}
							fontWeight={theme.bold}
							fontSize="16px"
							marginBottom={theme.smallSpace}
						>
							{translate(displayFeatureFlag ? `placeholder.${camelCasedFeature}.introText` : noResultsMessage)}
						</Block>
						{displayFeatureFlag && (
							<Block display="flex" alignItems="center" flexWrap="wrap" justifyContent="center">
								{addFlag && (
									<StyleWrapper
										display="inline-block"
										marginBottom={theme.tinySpace}
										padding="6px 12px"
										fontSize="16px"
										whiteSpace="nowrap"
									>
										<span>{translate("placeholder.whyDontYou")}</span>
										<StyleWrapper padding="0">
											<span padding="0px">
												<Button
													data-testid={`${translate(
														`placeholder.${camelCasedFeature}.buttonText`
													)}-button`}
													color="primary"
													onClick={addFunction || openModal}
													size={buttonSize}
													disabled={addButtonDisabledFlag}
												>
													{translate(`placeholder.${camelCasedFeature}.buttonText`)}
												</Button>
											</span>
										</StyleWrapper>
										<span>{translate("global.or")}</span>
									</StyleWrapper>
								)}
								{showHelpButtonFlag ? (
									<StyleWrapper whiteSpace="nowrap">
										<Button
											data-testid={`${translate(`placeholder.${camelCasedFeature}.helpText`)}-button`}
											target="support"
											color="success"
											size={buttonSize}
											marginBottom={theme.tinySpace}
											href={supportLink}
										>
											{translate(`placeholder.${camelCasedFeature}.helpText`)}
										</Button>
									</StyleWrapper>
								) : (
									<HTML>{translate(`placeholder.${camelCasedFeature}.helpText`)}</HTML>
								)}
							</Block>
						)}
					</Block>
				</StyledBlock>
			</Hide>
			{modalVisible ? (
				<>
					{AddModalWindow && (
						<AddModalWindow
							objectId={undefined}
							close={closeModal}
							modalWindowVisible={modalVisible}
							{...props}
						/>
					)}
					{AddModal && <AddModal objectId={undefined} close={closeModal} modalVisible={modalVisible} {...props} />}
				</>
			) : null}
		</>
	);
};

NoResults.propTypes = propTypes;

export default NoResults;
