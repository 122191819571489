const LOADING_TIMEOUT = 350;

let generateResumeLoadingFunction = ({
	cancelResumeLoadingRef,
	pauseLoadingFlagRef,
	requests,
	resumeLoadingTimeoutRef,
}) => {
	clearTimeout(resumeLoadingTimeoutRef.current);
	if (cancelResumeLoadingRef.current) return;
	if (requests.current.length === 0) {
		pauseLoadingFlagRef.current = false;
	} else {
		resumeLoadingTimeoutRef.current = setTimeout(() => generateResumeLoadingFunction, LOADING_TIMEOUT);
	}
};

export default generateResumeLoadingFunction;
