import { useCallback, useState } from "react";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";

import { useCreateFilter, useEffectOnce } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject, noop, pluralize } from "@clearpoint/utils";

import Input from "../Input/Input";
import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";
import { useOldSession } from "@clearpoint/old-session/index";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	"data-testid": PropTypes.string,
	filter: PropTypes.func,
	filterBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	initialValue: PropTypes.string,
	name: PropTypes.string.isRequired,
	noFilterStyleFlag: PropTypes.bool,
	object: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	adjustCheckedCount: PropTypes.number,
	adjustTotalCount: PropTypes.number,
};

let FilterInput = ({
	"data-testid": dataTestId,
	filter: customFilter,
	filterBy,
	initialValue,
	name,
	noFilterStyleFlag,
	object: objectProp,
	onChange: onChangeProp,
	placeholder,
	scorecardId,
	...props
}) => {
	let translate = useTranslate();
	let [value, setInputValue] = useState(initialValue ?? "");
	let createFilter = useCreateFilter();
	let { filter = noop, setFilterInputFlag, setFilterInputValue, startFilterTransition } = useFilter() || emptyObject;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	let debouncedFilter = useCallback(debounce(filter, 350, { leading: true }), [filter]);
	if (filterBy && !customFilter) customFilter = createFilter(filterBy);
	placeholder = placeholder || translate("global.filter") + "...";

	useEffectOnce(() => {
		debouncedFilter({ [name]: (x) => customFilter(x, value) });
	});

	let onChange = useCallback(
		(e) => {
			setInputValue(e.target.value);
			setFilterInputValue(e.target.value);
			setFilterInputFlag(!!e.target.value);
			if (onChangeProp) {
				onChangeProp(e.target.value);
			}
			startFilterTransition(() => debouncedFilter({ [name]: (x) => customFilter(x, e.target.value) }));
		},
		[
			customFilter,
			debouncedFilter,
			name,
			onChangeProp,
			setFilterInputFlag,
			setFilterInputValue,
			startFilterTransition,
		]
	);

	// checklist count
	let { session } = useOldSession() || emptyObject;
	let object = objectProp || session?.object;
	let { checklist } = useChecklist() || emptyObject;
	let totalChecked = checklist?.filter(
		(x) => x.checked && !x.unmountedFlag && x.scorecardId === scorecardId && x.linkType === object
	)?.length;
	let mountedChecklistItemCount = checklist?.filter(
		(x) => !x.unmountedFlag && x.scorecardId === scorecardId && x.linkType === object
	)?.length;
	let objectName = object ? translate(pluralize(object)) : undefined;
	if (object === " ") objectName = "";

	return (
		<StyleWrapper minWidth={noFilterStyleFlag ? undefined : "150px !important"} fontSize="16px">
			<Input
				data-testid={dataTestId || "filter-input"}
				className="filter"
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				marginBottom={theme.space}
				{...props}
				floatingText={
					checklist && totalChecked > 0
						? translate("pagination.elementsSelected", {
								count: totalChecked,
								total: mountedChecklistItemCount,
								objects: objectName,
						  })
						: props.floatingText
				}
				overflowFlag
			/>
		</StyleWrapper>
	);
};

FilterInput.propTypes = propTypes;

export default FilterInput;
