import Block from "../Block";
import PageContainer from "./PageContainer";
import PageControl from "./PageControl";
import PaginationProvider, { usePagination } from "@clearpoint/providers/PaginationProvider";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import PropTypes from "prop-types";
import { useNoResults } from "@clearpoint/hooks";

let contentPropTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	ignoreFilterProviderFlag: PropTypes.bool,
	noHeightFlag: PropTypes.bool,
	padding: PropTypes.string,
	size: PropTypes.string,
	setSortOrder: PropTypes.func,
	sortOptions: PropTypes.array,
	sortOrder: PropTypes.string,
};

let PaginationContent = ({
	children,
	color,
	ignoreFilterProviderFlag,
	noHeightFlag,
	padding,
	size,
	setSortOrder,
	sortOptions,
	sortOrder,
}) => {
	let { data } = usePagination();
	let filter = useFilter();
	let { noResultsFlag } = useNoResults(filter && data);
	if (ignoreFilterProviderFlag) noResultsFlag = false;

	return (
		<Block
			className="pagination"
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			flex={noResultsFlag ? undefined : "1"}
			padding={padding || "0px"}
			height={noHeightFlag ? undefined : noResultsFlag ? "0" : "100%"}
		>
			<PageContainer>{children}</PageContainer>
			<PageControl
				size={size}
				color={color}
				sortOptions={sortOptions}
				setSortOrder={setSortOrder}
				sortOrder={sortOrder}
			/>
		</Block>
	);
};

let propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	getData: PropTypes.func,
	ignoreFilterProviderFlag: PropTypes.bool,
	noHeightFlag: PropTypes.bool,
	padding: PropTypes.string,
	setSortOrder: PropTypes.func,
	size: PropTypes.string,
	sortOptions: PropTypes.array,
};

let Pagination = ({
	children,
	color,
	getData,
	ignoreFilterProviderFlag,
	noHeightFlag,
	padding,
	setSortOrder,
	size,
	sortOptions,
	sortOrder,
	value,
	...props
}) => {
	return (
		<PaginationProvider getData={getData} {...props}>
			<PaginationContent
				children={children}
				color={color}
				ignoreFilterProviderFlag={ignoreFilterProviderFlag}
				noHeightFlag={noHeightFlag}
				padding={padding}
				size={size}
				setSortOrder={setSortOrder}
				sortOptions={sortOptions}
				sortOrder={sortOrder}
			/>
		</PaginationProvider>
	);
};

PaginationContent.propTypes = contentPropTypes;
Pagination.propTypes = propTypes;

export default Pagination;
