import InputPercent from "../../Form/Input/Input.Percent";
import useStringToIntegerFormValue from "../../Form/hooks/useStringToIntegerFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};
let Percent = ({ name, ...props }) => {
	useStringToIntegerFormValue(name);
	return <InputPercent data-testid="percent" name={name} {...props} />;
};
Percent.propTypes = propTypes;
export default Percent;
