import { useCallback, useMemo, useState } from "react";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { theme } from "@clearpoint/old-theme/Theme";

import {
	useCheckAccess,
	useClearUpdatedLinks,
	useDisableLoadingModalSave,
	useEvaluationEnabledLookup,
	useFormatDate,
	useNavigateToScorecard,
	useStateObject,
} from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { splitEditAndUpdateFieldData } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import EditFieldsTab from "./EditFieldsTab";
import LinkMapTab from "./LinkMapTab";
import LinksTab from "./LinksTab";
import UpdateDataTab from "./UpdateDataTab";
import UpdateFieldsTab from "./UpdateFieldsTab";
import useCheckApproval from "@clearpoint/hooks-dir/useCheckApproval";
import useToastApproval from "@clearpoint/hooks-dir/useToastApproval";
import { useOldSession } from "@clearpoint/old-session/index";
import ChartsTab from "@components/Elements/Shared/ChartsTab";
import NotificationsTab from "@components/Elements/Shared/NotificationsTab";
import SeriesTab from "@components/Elements/Shared/SeriesTab";

let editRiskModalContentPropTypes = {
	addFlag: PropTypes.bool,
	approvalRequired: PropTypes.bool,
	linkList: PropTypes.array,
	onAdd: PropTypes.func,
	loadingFlag: PropTypes.bool,
};
let EditRiskModalContent = ({ addFlag, approvalRequired, linkList, onAdd, loadingFlag }) => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { clear } = useOldQueryStore();
	let evaluationEnabledFlag = useEvaluationEnabledLookup().risk;
	let { newObjectFlag } = useFormContext();
	let { access, name, objectId } = useFormValue();
	let periodLockedFlag = useFormValue("updates.periodLocked");
	let period = useFormValue("updates.period");
	let editFlag = objectId ? checkAccess({ access, action: "edit" }) : true;

	let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
		saveModalVisible: false,
		savedFlag: false,
	});
	let onSave = useCallback(
		(response) => {
			if (onAdd) {
				onAdd(response);
			}
			setState({ savedFlag: true });
		},
		[onAdd, setState]
	);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);
	let openSaveModal = useCallback(
		(selectTab) => {
			if (newObjectFlag && approvalRequired) {
				toast.warning(translate("approvals.changesAfterApproval"));
			} else if (newObjectFlag) {
				setState({ saveModalVisible: true });
			} else {
				selectTab();
			}
		},
		[approvalRequired, newObjectFlag, setState, translate]
	);
	useUnmount(() => {
		if (savedFlag) clear({ object: "risk", objectId });
	});
	let updateFieldsTabTitle = useMemo(
		() =>
			periodLockedFlag ? (
				<>
					<Icon name="locked" size="tiny" marginRight={theme.smallSpace} />
					{`${period} ${translate("global.locked")}`}
				</>
			) : (
				translate("edit.element.updateFields")
			),
		[period, periodLockedFlag, translate]
	);

	useDisableLoadingModalSave(loadingFlag);

	return (
		<>
			<ModalWindowHeader
				badgeIcon="risk"
				badgeText={translate("risk")}
				title={addFlag ? translate("edit.addElement", { element: "risk" }) : name}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					{editFlag && (
						<EditFieldsTab addFlag title={translate("edit.addElement", { element: "risk" })} visible={addFlag} />
					)}
					<UpdateFieldsTab
						onClick={openSaveModal}
						title={updateFieldsTabTitle}
						periodLockedFlag={periodLockedFlag}
					/>
					{editFlag && <EditFieldsTab title={translate("edit.element.editFields")} visible={!addFlag} />}
					{evaluationEnabledFlag && (
						<UpdateDataTab onClick={openSaveModal} title={translate("edit.element.updateElementData")} />
					)}
					{editFlag && evaluationEnabledFlag && (
						<SeriesTab onClick={openSaveModal} title={translate("global.series")} />
					)}
					{editFlag && evaluationEnabledFlag && (
						<ChartsTab onClick={openSaveModal} title={translate("global.charts")} />
					)}
					{editFlag && <LinksTab onClick={openSaveModal} title={translate("edit.links")} />}
					{editFlag && linkList && linkList.length > 0 && (
						<LinkMapTab onClick={openSaveModal} title={translate("edit.map.linkMap")} />
					)}
					<NotificationsTab onClick={openSaveModal} title={translate("global.notifications")} />
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter saveAndContinueFlag={!approvalRequired} />
			<FormModalSave
				object="risk"
				approvalRequired={approvalRequired}
				onSave={onSave}
				modalVisible={saveModalVisible}
				submitUnchangedFlag
				close={closeSaveModal}
			/>
		</>
	);
};
EditRiskModalContent.propTypes = editRiskModalContentPropTypes;

let propTypes = {
	objectId: PropTypes.number,
	onAdd: PropTypes.func,
	onEdit: PropTypes.func,
};
let EditRiskModal = ({ onAdd, onEdit, ...props }) => {
	let formatDate = useFormatDate();
	let translate = useTranslate();
	let [objectId, setObjectId] = useState(props.objectId);
	let { clear, get } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId, scorecardId } = session;
	let riskData = objectId && get({ object: "risk", objectId });
	let riskUpdateData = objectId && periodId && get({ object: "risk", objectId, periodId });
	let customFieldData =
		objectId && periodId && get({ object: "customField", parent: "risk", parentId: objectId, periodId });
	let linkList = objectId && periodId && get({ parent: "risk", parentId: objectId, object: "link", periodId });
	let shadowMeasureId = riskData?.shadowMeasureId;
	let measureSeriesList =
		shadowMeasureId && get({ parent: "measure", parentId: shadowMeasureId, object: "measureSeries" });
	let chartList = shadowMeasureId && get({ parent: "measure", parentId: shadowMeasureId, object: "chart" });
	let loadingFlag = objectId && [riskData, riskUpdateData, customFieldData, linkList].includes(undefined);
	let clearUpdatedLinks = useClearUpdatedLinks();
	let navigateToScorecard = useNavigateToScorecard();
	let toastApproval = useToastApproval();
	let approvalRequired = useCheckApproval({ scorecardId })({ type: objectId ? "edits" : "adds" });

	let defaultValue = useMemo(() => {
		if (riskData && customFieldData) {
			let { editFieldData: editCustomFieldData, updateFieldData: updateCustomFieldData } =
				splitEditAndUpdateFieldData(customFieldData);
			return {
				...riskData,
				...editCustomFieldData,
				updates: { ...riskUpdateData, ...updateCustomFieldData },
				links: linkList,
				seriesSortOrder: measureSeriesList?.map((x) => x.measureSeriesId),
				chartSortOrder: chartList?.map((x) => x.chartId),
			};
		}
		return {
			completedDate: formatDate(new Date(), "apiDateWithoutTimezone"),
			endDate: formatDate(lastDayOfMonth(new Date()), "apiDateWithoutTimezone"),
			startDate: formatDate(startOfMonth(new Date()), "apiDateWithoutTimezone"),
		};
	}, [riskData, customFieldData, formatDate, riskUpdateData, linkList, measureSeriesList, chartList]);

	let onSubmit = useCallback(
		(value, response) => {
			if (!objectId && onAdd) {
				onAdd(response);
			} else if (objectId) {
				clear({ object: "detailLayout" });
				if (onEdit) onEdit();
			}
			let { changeQueued } = toastApproval({ response });
			if (!changeQueued) {
				clearUpdatedLinks({ newLinkList: value?.links, oldLinkList: linkList });
				navigateToScorecard(value.scorecardId);
				toast.success(
					translate(objectId ? "toaster.messages.objects.objectUpdated" : "toaster.messages.objects.objectAdded", {
						object: "risk",
					})
				);
			}
		},
		[clear, clearUpdatedLinks, linkList, navigateToScorecard, objectId, onAdd, onEdit, toastApproval, translate]
	);
	return (
		<ModalWindowEdit
			{...props}
			color="desaturatedNormal"
			defaultValue={defaultValue}
			loadingFlag={loadingFlag}
			object="risk"
			objectId={objectId}
			onAdd={onAdd}
			onSubmit={onSubmit}
			setObjectId={setObjectId}
			size="large"
			submitUnchangedFlag={!objectId}
			updateFlag
		>
			<EditRiskModalContent
				approvalRequired={approvalRequired}
				loadingFlag={loadingFlag}
				addFlag={!objectId}
				linkList={linkList}
			/>
		</ModalWindowEdit>
	);
};
EditRiskModal.propTypes = propTypes;

export default EditRiskModal;
