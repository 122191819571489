import Loading from "../Loading";
import LinkChart from "../LinkChart/LinkChart";
import { useMemo, useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useOldSession } from "@clearpoint/old-session/index";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import orderBy from "lodash/orderBy";

let LinkMap = () => {
	let { data: linkList } = useFilter();
	let { session } = useOldSession();
	let { periodId } = session;
	let { get } = useOldQueryStore();

	let orderedLinkList = useMemo(() => orderBy(linkList, ["level", "parentId", "parent"]), [linkList]);

	let fetchLinkStatus = useCallback(
		({ link }) => {
			let { object, objectId } = link;
			let linkStatus =
				objectId && periodId && get({ parent: object, parentId: objectId, object: "status", periodId });
			return { linkedElementFlag: linkStatus ? linkStatus.linkedElements : false };
		},
		[periodId, get]
	);

	return !linkList ? (
		<Loading />
	) : (
		<LinkChart linkList={orderedLinkList} fetchLinkDataCallback={fetchLinkStatus} allowLoadingChildrenFlag />
	);
};

export default LinkMap;
