import Badge from "@clearpoint/old-theme/Badge/Badge";
import Block from "@clearpoint/old-theme/Block";
import Circle from "@clearpoint/old-theme/Circle/Circle";
import EditButton from "@clearpoint/old-theme/Button/Button.Edit";
import EditChartSeriesModal from "./EditChartSeriesModal";
import FormDragAndDropListItem from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Item";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ModalConfirmOnClick from "@clearpoint/old-theme/Modal/Modal.Confirm.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	index: PropTypes.number,
	list: PropTypes.array,
	setList: PropTypes.func,
	series: PropTypes.shape({
		color: PropTypes.string,
		isSecondAxis: PropTypes.bool,
		label: PropTypes.string,
		name: PropTypes.string,
		periodOffset: PropTypes.number,
		regressionModel: PropTypes.string,
		seriesType: PropTypes.oneOf(["calculated", "offset", "standard", "trendline", "none"]),
		visible: PropTypes.bool,
	}),
};

let ChartSeriesListItem = ({ index, list, series, setList }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let {
		color,
		isSecondAxis: isSecondAxisFlag,
		label,
		name,
		periodOffset,
		regressionModel,
		seriesType,
		visible,
	} = series;
	let trendlineFlag = seriesType === "trendline" && regressionModel !== "";
	let offsetFlag = seriesType === "offset" && periodOffset;
	let labelText = label || name;
	let deactivateSeries = useCallback(
		(series) => {
			let findIndex = (chartSeries, i) =>
				series.measureSeriesId === chartSeries.measureSeriesId && series.sortOrder === i;
			let trimList = (list, index) => [...list.slice(0, index), ...list.slice(index + 1)];
			let update = (list) => {
				let index = list.findIndex(findIndex);
				return index === -1 ? list : trimList(list, index);
			};
			setFormValue("chartSeries", update);
			setList(update(list));
		},
		[list, setFormValue, setList]
	);

	return (
		<FormDragAndDropListItem
			buttonCount={2}
			color="desaturatedBold"
			visible={visible}
			left={
				<Block ellipsisFlag>
					<Block>{labelText}</Block>
					<Block ellipsisFlag fontSize="85%" fontWeight={theme.normal}>
						{name}
					</Block>
				</Block>
			}
			right={
				<Block>
					{trendlineFlag && (
						<StyleWrapper marginRight={theme.smallSpace}>
							<Badge color="success">{translate("edit.chart.trendline")}</Badge>
						</StyleWrapper>
					)}
					{offsetFlag && (
						<StyleWrapper marginRight={theme.smallSpace}>
							<Badge color="warning">{translate("edit.chart.offset")}</Badge>
						</StyleWrapper>
					)}
					{isSecondAxisFlag && (
						<StyleWrapper marginRight={theme.smallSpace}>
							<Badge color="info" size="medium">
								{translate("manage.chart.secondAxis")}
							</Badge>
						</StyleWrapper>
					)}
					{color && (
						<StyleWrapper marginRight={theme.smallSpace}>
							<Circle color={color} width="20px" height="20px" />
						</StyleWrapper>
					)}
					<ModalConfirmOnClick
						confirmButtonColor="danger"
						confirmButtonText={translate("global.remove")}
						title={translate("edit.chartSeries.removeChartSeries")}
						body={translate("edit.chartSeries.removeChartSeriesConfirm")}
						onConfirm={() => deactivateSeries(series)}
					>
						<StyleWrapper marginRight={theme.smallSpace}>
							<IconButton name="remove" size="tiny" />
						</StyleWrapper>
					</ModalConfirmOnClick>
					<EditButton data-testid="editSeriesButton" Modal={EditChartSeriesModal} series={series} index={index} />
				</Block>
			}
		/>
	);
};

ChartSeriesListItem.propTypes = propTypes;

export default ChartSeriesListItem;
