import { useOldQueryStore } from "@clearpoint/old-query-store";

let loadingObject = { measureStatusList: [], measureStatusLoadingFlag: true };

let useMeasureStatusList = (elementList) => {
  let { get, isLoading } = useOldQueryStore();
  if (!elementList) return loadingObject;
  let measureStatusList = elementList.elements
    .filter((x) => x.object === "measure")
    .map((x) => {
      let key = { object: "measure", objectId: x.objectId, periodId: x.periodId, queryString: "?quick=true" };
      let status = elementList?.content?.find((y) => y.object === x.object && y.objectId === x.objectId);
      let value = status || get(key);
      let measureStatusLoadingFlag = !value || isLoading(key);
      let returnValue = { ...value, statusId: value?.access === "No Access" ? null : (value?.statusId === undefined ? value?.status[x.periodId]?.statusId : value?.statusId) };
      return { value: returnValue, measureStatusLoadingFlag };
    });
  if (measureStatusList.some((x) => x.measureStatusLoadingFlag)) return loadingObject;
  measureStatusList = measureStatusList.map((x) => x.value);
  return { measureStatusList, measureStatusLoadingFlag: false };
};

export default useMeasureStatusList;
