import FormFieldDisplayCalculated from "@clearpoint/old-theme/Form/Field/Display/Calculated";
import PropTypes from "prop-types";

let propTypes = {
	fieldId: PropTypes.string,
};

let Calculated = ({ fieldId }) => {
	if (fieldId.includes("calculated") && !fieldId.includes("Field")) {
		fieldId = fieldId.substring(0, 10) + "Field" + fieldId.substring(10, 15);
	}
	let name = `updates.calculatedValues.${fieldId}.value`;
	if (fieldId === "objectiveScore") name = `updates.score`;
	return <FormFieldDisplayCalculated name={name} noStatusIconFlag />;
};

Calculated.propTypes = propTypes;
export default Calculated;
