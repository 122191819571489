import { format } from "date-fns-tz";

let formatDate =
	({ convertDateStringToDateObject, dateConfig, session }) =>
	(date, dateFormatType, ignoreTimezoneFlag = false) => {
		if (dateFormatType !== "apiDateWithoutTimezone") date = convertDateStringToDateObject(date, ignoreTimezoneFlag);
		if (!date) return "";
		let formatByType = {
			apiDateWithoutTimezone: () => format(date, "yyyy-MM-dd"),
			apiDate: () => {
				try {
					return format(date, "yyyy-MM-dd", dateConfig);
				} catch {
					return "";
				}
			},
			short: () => {
				let dateFormat = session.localeId === "en_us" ? "M/d/yy" : "d/M/yy";
				return format(date, dateFormat, dateConfig);
			},
			datePicker: () => {
				let dateFormatLookup = {
					en_gb: "d/M/yyyy",
					en_us: "M/d/yyyy",
					es_mx: "d/M/yyyy",
					fr_ca: "yyyy-M-d",
					pt_br: "d/M/yyyy",
				};
				let dateFormat = dateFormatLookup[session.localeId];
				return format(date, dateFormat, dateConfig);
			},
			medium: () => {
				let dateFormat = session.localeId === "en_us" ? "MMM d, yyyy" : "d MMM yyyy";
				return format(date, dateFormat, dateConfig);
			}, // Dec 29th, 1999
			alertLong: () => format(date, "MMM d, y p", dateConfig), // Feb 29, 1999 12:00 PM
			apiKeyLong: () => {
				let dateFormat = session.localeId === "en_us" ? "M/d/yy" : "d/M/yy";
				return format(date, dateFormat + " p", dateConfig); // 5/19/21 4:35 AM
			},
			grid: () => format(date, session.localeId === "en_us" ? "M/d/yy @ h:mm a" : "yy-MM-dd @ kk:mm", dateConfig),
			scheduleLong: () => format(date, "EEE, MMM d, yyyy h:mm a", dateConfig), // Sun, February 29th, 1999 12:00 PM
			long: () => format(date, "PPPPp", dateConfig), // Sunday, February 29th, 1999 at 12:00 AM
			month: () => format(date, "MMMM"),
			monthAndDay: () => {
				return format(date, "MMM-d");
			},
			quarter: () => format(date, "QQQ"),
			time: () => {
				let dateFormat = session.localeId === "en_us" ? "KK:mm aaa" : "kk:mm";
				return format(date, dateFormat, dateConfig);
			},
			timezone: () => {
				// Eastern Standard Time (UTC-05:00)
				let timezone = format(date, "zzzz '(UTC'XXX')'", dateConfig);
				return timezone.slice(0, -9) + timezone.slice(-8, -2) + timezone.slice(-1);
			},
		};
		if (!formatByType[dateFormatType])
			throw new Error(
				`Invalid format: "${dateFormatType}" passed to formatDate function.\nValid types: ${Object.keys(
					formatByType
				).join(", ")}.`
			);
		return formatByType[dateFormatType]();
	};

export default formatDate;
