let iconColorLookup = {
	added: "#1d84c7",
	blacklist: "#ed5565",
	cleaned: "#ed5565",
	completed: "#23c6c8",
	deleted: "#ed5565",
	duplicated: "#1d84c7",
	edited: "#23c6c8",
	emptied: "#ed5565",
	error: "#ed5565",
	failed: "#ed5565",
	layout: "#23c6c8",
	login: "#f9ac5a",
	purged: "#ed5565",
	queued: "#1d84c7",
	started: "#1d84c7",
	undeleted: "#1d84c7",
	updated: "#23c6c8",
};

export default iconColorLookup;
