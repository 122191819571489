import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import useBooleanFormValue from "../../Form/hooks/useBooleanFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editFlag: PropTypes.bool,
	name: PropTypes.string,
};
let Boolean = ({ editFlag, name, ...props }) => {
	let translate = useTranslate();
	let testId = "boolean";
	useBooleanFormValue(name);
	return editFlag ? (
		<RadioBoolean data-testid={testId} name={name} {...props} />
	) : (
		<Check data-testid={testId} name={name} text={translate("global.displayYes")} {...props} />
	);
};
Boolean.propTypes = propTypes;
export default Boolean;
