import Button from "@clearpoint/old-theme/Button/Button";
import ConfirmButton from "@clearpoint/old-theme/Button/Button.Confirm";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import Modal from "@clearpoint/old-theme/Modal/Modal";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";

let propTypes = {
	close: PropTypes.func.isRequired,
	closeChartJsonModal: PropTypes.func,
	modalVisible: PropTypes.bool.isRequired,
};

let AddCustomChartTypeModal = ({ close, closeChartJsonModal, modalVisible }) => {
	let translate = useTranslate();
	let { set } = useOldQueryStore();
	let { setFormValue } = useFormContext();
	let { _newCustomChartTypeName, customJson, name: chartName } = useFormValue();

	let addNewCustomChartType = useCallback(() => {
		set({
			object: "customChartType",
			data: {
				chartJson: customJson,
				name: _newCustomChartTypeName,
				sortOrder: 0,
			},
		}).then((response) => {
			setFormValue("chartType", response.customChartTypeId * -1);
			toast.success(translate("edit.chart.chartTypeAdded"));
			close();
			closeChartJsonModal();
		});
	}, [close, closeChartJsonModal, customJson, _newCustomChartTypeName, set, setFormValue, translate]);

	return (
		<Modal modalVisible={modalVisible}>
			<ModalHeader>
				<ModalTitle>{translate("edit.chart.addChartType")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<FormInput
					label={translate("options.name")}
					name="_newCustomChartTypeName"
					defaultValue={chartName}
					required
				/>
			</ModalBody>
			<ModalFooter>
				<Button onClick={close} color="default">
					{translate("global.cancel")}
				</Button>
				<ConfirmButton
					color="primary"
					size="medium"
					title={translate("edit.chart.addChartType")}
					body={translate("edit.chart.addChartTypeText")}
					confirmButtonText={translate("global.save")}
					onConfirm={addNewCustomChartType}
					disabled={!_newCustomChartTypeName}
				>
					{translate("global.add")}
				</ConfirmButton>
			</ModalFooter>
		</Modal>
	);
};

AddCustomChartTypeModal.propTypes = propTypes;
export default AddCustomChartTypeModal;
