import FormCheck from "../../../../Form/Check/Form.Check";
import FormInput from "../../../../Form/Input/Input";
import FormInputDate from "../../../../Form/Input/Input.Date";
import FormSelect from "../../../../Form/Select/FormSelect";
import FormSelectChartType from "../../../../Form/Select/Select.ChartType";
import FormSelectObjectType from "../../../../Form/Select/Select.ObjectType";
import FormSelectPeriodGroup from "../../../../Form/Select/Select.PeriodGroup";
import FormSelectStatus from "../../../../Form/Select/Select.Status";
import FormSelectTag from "../../../../Form/Select/Select.Tag";
import FormSelectUser from "../../../../Form/Select/Select.User";
import InputDateRange from "./InputDateRange";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";
import { useScorecard } from "@clearpoint/hooks";


let propTypes = {
	compare: PropTypes.string,
	fieldId: PropTypes.string,
	object: PropTypes.string,
};

let InputValue = ({ compare, fieldId, object, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { scorecardId } = useScorecard();
	let filterFieldList = get({ object: "filterField", queryString: `?scorecardId=${scorecardId}&object=${object}` });
	let filterField = useMemo(() => {
		if (!object || !fieldId || !filterFieldList) return emptyObject;
		return filterFieldList[object].find((field) => field.fieldId === fieldId);
	}, [fieldId, filterFieldList, object]);
	let valueInputLookup = emptyObject;
	valueInputLookup = useMemo(() => {
		return {
			boolean: (props) => <FormCheck data-testid="boolean-value" text={translate("global.displayYes")} {...props} />,
			chartType: (props) => <FormSelectChartType data-testid="chart-type-value" {...props} />,
			date: (props) =>
				compare === "range" ? (
					<InputDateRange data-testid="date-range-value" {...props} />
				) : (
					<FormInputDate data-testid="date-value" {...props} />
				),
			default: (props) => <FormInput data-testid="default-value" {...props} />,
			elementType: (props) => <FormSelectObjectType data-testid="element-type-value" milestoneFlag {...props} />,
			multipick: (props) => (
				<FormSelect
					data-testid="multipick-value"
					options={filterField.picklist.map((x) => ({ label: x, value: x }))}
					{...props}
				/>
			),
			periodGroup: (props) => <FormSelectPeriodGroup data-testid="period-group-value" allIsNullFlag {...props} />,
			periodicity: (props) => <FormSelectPeriodGroup data-testid="period-group-value" allIsNullFlag {...props} />,
			picklist: (props) => (
				<FormSelect
					data-testid="picklist-value"
					options={filterField.picklist.map((x) => ({ label: x, value: x }))}
					{...props}
				/>
			),
			status: (props) => <FormSelectStatus data-testid="status-value" multipleFlag {...props} />,
			statusIcon: (props) => <FormSelectStatus data-testid="status-value" multipleFlag {...props} />,
			tags: (props) => <FormSelectTag data-testid="tag-value" multipleFlag {...props} />,
			user: (props) => <FormSelectUser data-testid="user-value" includeCurrentFlag {...props} />,
			users: (props) => <FormSelectUser data-testid="user-list-value" includeCurrentFlag multipleFlag {...props} />,
		};
	}, [compare, filterField, translate]);
	let InputValue = useMemo(() => {
		return valueInputLookup[filterField?.type] || valueInputLookup?.default;
	}, [filterField, valueInputLookup]);
	return <InputValue {...props} />;
};

InputValue.propTypes = propTypes;

export default InputValue;
