import * as Sentry from "@sentry/react";
let getSetSessionData =
	({ layoutId, mode, object, objectId, periodId, periodList, profile, scorecardId, scorecardList, view }) =>
	(oldSession) => {
		let newSession = {
			mode,
			view: view || "list",
			layoutId: layoutId || oldSession.layoutId,
			object: object || oldSession.object,
			objectId: objectId || oldSession.objectId,
			periodId: periodId || oldSession.periodId || profile?.periodId,
			scorecardId: scorecardList?.some((x) => x.scorecardId === scorecardId) ? scorecardId : oldSession.scorecardId,
		};
		if (!newSession.scorecardId || !newSession.object) {
			newSession.layoutId = profile?.home?.layoutId;
			newSession.objectId = profile?.home?.objectId;
			newSession.object = profile?.home?.object;
			newSession.scorecardId = profile?.home?.scorecardId;
		}
		if (layoutId) {
			newSession.objectId = undefined;
		} else {
			newSession.layoutId = undefined;
		}

		if (periodList) {
			if (!periodList.find) {
				Sentry.captureException(new Error("invalid period list" + JSON.stringify(periodList)));
			} else {
				let invalidPeriodIdFlag = !periodList.find((x) => x.periodId === newSession.periodId);
				if (invalidPeriodIdFlag) {
					let validPeriodId;
					let defaultPeriod = periodList.find(
						(x) =>
							x.defaultPeriod &&
							(x.scorecardList.length === 0 || x.scorecardList.includes(newSession.scorecardId))
					);
					if (defaultPeriod) {
						validPeriodId = defaultPeriod.periodId;
					} else {
						validPeriodId = periodList.slice(-1)[0]?.periodId;
					}

					if (validPeriodId) {
						newSession.periodId = validPeriodId;
					}
				}
			}
		}
		return newSession;
	};

export default getSetSessionData;
