import Select from "./FormSelect";
import { useCallback, useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";

let propTypes = {
	annotationFlag: PropTypes.bool,
	groupFlag: PropTypes.bool,
	measureFlag: PropTypes.bool,
	peerGroupId: PropTypes.number.isRequired,
};

let SelectPeer = ({ annotationFlag, groupFlag, measureFlag, peerGroupId, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let peerGroupData = peerGroupId && get({ object: "peerGroup", objectId: peerGroupId });
	let measureList =
		annotationFlag && peerGroupId && get({ parent: "peerGroup", parentId: peerGroupId, object: "measure" });
	let peerList = useMemo(() => peerGroupData?.peers, [peerGroupData]);

	let getAnnotation = useCallback(
		(peerId) => {
			let annotation = (measureCount) =>
				` (${measureCount} ${translate(measureCount === 1 ? "measure" : "measures")})`;
			if (annotationFlag) {
				if (peerId === "all") {
					let totalMeasureCount = measureList?.length;
					return annotation(totalMeasureCount);
				}
				let contributorFlag = peerList.find((x) => x.peerId === peerId)?.sharedMeasureCount > 0;
				if (contributorFlag) {
					let measureCount = measureList?.filter((x) => x.peerId === peerId).length;
					return annotation(measureCount);
				}
			}
			return "";
		},
		[annotationFlag, measureList, peerList, translate]
	);

	let options = useMemo(() => {
		if (peerList === undefined) return;
		if (!isArray(peerList)) return null;
		let filteredPeerList = peerList.filter((x) => (measureFlag ? x.sharedMeasureCount > 0 : true));

		return [
			{
				label: translate("peerMeasures.allPeers") + getAnnotation("all"),
				value: undefined,
				group: groupFlag ? translate("measureLibrary.contributors") : undefined,
			},
			...filteredPeerList.map((x) => ({
				label: x.name + getAnnotation(x.peerId),
				value: x.peerId,
				group: groupFlag
					? x.sharedMeasureCount > 0
						? translate("measureLibrary.contributors")
						: translate("measureLibrary.members")
					: undefined,
			})),
		];
	}, [getAnnotation, groupFlag, measureFlag, peerList, translate]);
	return <Select options={options || []} loadingFlag={!peerGroupData} {...props} />;
};

SelectPeer.propTypes = propTypes;

export default SelectPeer;
