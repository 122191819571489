import FilterList from "./FilterList";
import Preview from "./Preview";
import SelectFilterRule from "./SelectFilterRule";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	filterObjectList: PropTypes.array,
	name: PropTypes.string,
	previewObjectList: PropTypes.string,
};
let FormInputFilterList = ({ name, filterObjectList, previewObjectList }) => {
	let translate = useTranslate();
	let prefix = name ? `${name}.` : "";
	return (
		<>
			<SelectFilterRule
				defaultValue="all"
				label={translate("layout.includeElementWhen")}
				name={`${prefix}filterRule`}
			/>
			<FilterList name={`${prefix}filter`} filterObjectList={filterObjectList} />
			{previewObjectList && <Preview previewObjectList={previewObjectList} name={name} />}
		</>
	);
};
FormInputFilterList.propTypes = propTypes;

export default FormInputFilterList;
