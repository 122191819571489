import { Fragment } from "react";
import PropTypes from "prop-types";

import AlertApproval from "@clearpoint/old-theme/Alert/Alert.Approval";
import Block from "@clearpoint/old-theme/Block";
import CopyForwardButton from "@clearpoint/old-theme/Button/Button.CopyForward";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";
import { theme } from "@clearpoint/old-theme/Theme";

import { useFieldList, useFormFieldsLoading } from "@clearpoint/hooks";

let propTypes = {
	periodLockedFlag: PropTypes.bool,
};

let UpdateFieldsTab = ({ periodLockedFlag }) => {
	let objectId = useFormValue("objectId");
	let fieldList = useFieldList({ object: "risk", objectId, updateFlag: true });
	let loadingFlag = useFormFieldsLoading({ fieldList, updateFlag: true });

	return (
		<>
			<Help section="edit-element" feature="update-fields" />
			<AlertApproval type="updates"/>
			<Block>
				{!periodLockedFlag && <CopyForwardButton parentName="updates" />}
				{fieldList?.map((field, i) => (
					<Fragment key={field.value}>
						{i !== 0 && !periodLockedFlag && (
							<CopyForwardButton field={field.value} parentName="updates" right={theme.smallSpace} />
						)}
						<FormField
							disabled={loadingFlag}
							fieldId={field.value}
							label={field.label}
							object="risk"
							parentName="updates"
							periodLockedFlag={periodLockedFlag}
							customFieldType={field.customFieldType}
							modalFlag
						/>
					</Fragment>
				))}
			</Block>
		</>
	);
};

UpdateFieldsTab.propTypes = propTypes;

export default UpdateFieldsTab;
