import { useCallback } from "react";
import { useMainStore } from "@clearpoint/store";
import isEqual from "lodash/isEqual";

let useSessionReducer = () => {
	let { reset, resetExceptAuth, ...state } = useMainStore();
	let setState = useMainStore.setState;
	let setSession = useCallback(
		(updates) => {
			if (updates === "clear" || updates === "clearToken") {
				if (updates === "clearToken") {
					reset();
				} else {
					resetExceptAuth();
				}
			} else if (typeof updates === "function") {
				setState(updates);
			} else {
				setState((state) => {
					let newUpdates = { ...updates };
					for (let key in updates) {
						if (typeof updates[key] === "function") {
							newUpdates[key] = updates[key](state[key]);
						}
					}
					let newState = { ...state, ...newUpdates };
					return isEqual(state, newState) ? state : newUpdates;
				});
			}
		},
		[reset, resetExceptAuth, setState]
	);
	return [state, setSession];
};

export default useSessionReducer;
