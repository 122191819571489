import PropTypes from "prop-types";

import AlertApproval from "@clearpoint/old-theme/Alert/Alert.Approval";
import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";

import { useFieldList } from "@clearpoint/hooks";

let propTypes = {
	addFlag: PropTypes.bool,
};
let EditFieldsTab = ({ addFlag }) => {
	let objectId = useFormValue("objectId");
	let scorecardId = useFormValue("scorecardId");
	let fieldList = useFieldList({ object: "milestone", objectId, addFlag, scorecardId });
	fieldList = fieldList.filter((x) => x.customFieldType !== "myScorecard" && x.customFieldType !== "myFavorites");

	return (
		<>
			<AlertUpsell feature="dependencies" />
			<Help section="edit-element" feature="edit-fields" />
			<AlertApproval type={addFlag ? "adds" : "edits"} />
			{fieldList?.map((field) => (
				<FormField fieldId={field.value} key={field.value} label={field.label} object="milestone" />
			))}
		</>
	);
};
EditFieldsTab.propTypes = propTypes;
export default EditFieldsTab;
