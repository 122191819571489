import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import { theme } from "@clearpoint/old-theme/Theme";

import { emptyObject } from "@clearpoint/utils";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let HeaderDisplayStatus = () => {
	let { object } = useFormValue();
	let { statusId } = useFormValue("updates") || emptyObject;
	let { loadingFlag } = useLocalState();

	let scorecardFlag = object === "scorecard";

	return scorecardFlag ? null : (
		<StatusIcon marginRight={theme.smallSpace} marginTop="2px" statusId={statusId} size="large" />
	);
};

export default HeaderDisplayStatus;
