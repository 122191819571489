import formatSeriesNumber from "../utils/formatSeriesNumber";

let describeDefault = ({ chartData, series, translate }) => {
	let singleSeriesFlag = series.length === 1;
	let description = "";
	let i = 0;
	if (singleSeriesFlag) description += series[0].name;
	for (let period of chartData.chartPeriods) {
		description += "\n";
		description += period.name;
		if (!singleSeriesFlag) description += "\n";
		for (let measureSeries of series) {
			let { name, numberFormat, data } = measureSeries;
			if (singleSeriesFlag) name = "";
			description += `${name}: ${formatSeriesNumber(data[i], numberFormat, translate)}\t`;
		}
		description = description.slice(0, -1);
		i++;
	}
	return description;
};

export default describeDefault;
