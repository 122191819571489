import Block from "@clearpoint/old-theme/Block";
import FormSelect from "@clearpoint/old-theme/Form/Select/FormSelect";
import StatusIcon from "@components/Elements/Goals/ManageGoals/GoalListItem/StatusIcon";
import PropTypes from "prop-types";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import goalStatusLookup from "@components/Elements/Goals/goalStatusLookup";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	name: PropTypes.string,
};

let GoalStatusSelect = ({ name, ...props }) => {
	let translate = useTranslate();
	let statusId = useFormValue("statusId");

	let options = useMemo(
		() =>
			Object.entries(goalStatusLookup).map(([key, value]) => ({ label: translate(value.name), value: Number(key) })),
		[translate]
	);

	return (
		<Block display="flex" marginBottom={theme.space} alignItems="center">
			<StatusIcon statusId={statusId} />
			<Block flex="1">
				<FormSelect
					name={name}
					options={options}
					placeholder={translate("goals.statusSelect.placeholder")}
					marginBottom="0"
					{...props}
				/>
			</Block>
		</Block>
	);
};

GoalStatusSelect.propTypes = propTypes;
export default GoalStatusSelect;
