import React, { useCallback } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { buildQueryString } from "@clearpoint/utils";
import { useReportFilter } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.node,
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	scorecardId: PropTypes.number,
	title: PropTypes.string,
};

let GenerateExcelOnClick = ({ children, layoutId, object, objectId, scorecardId, title }) => {
	let translate = useTranslate();
	let { set } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId } = session;
	let { reportFilter } = useReportFilter();
	let generateExcel = useCallback(
		(_, close) => {
			periodId &&
				set({
					object: layoutId ? `${object}Layout` : "detailLayout",
					objectId: layoutId || undefined,
					queryString: buildQueryString({
						excelType: "standard",
						format: "xls",
						object: layoutId ? undefined : object,
						objectId: layoutId ? undefined : objectId,
						orientation: "landscape",
						pageSize: "letter",
						periodId,
						scorecardId: layoutId ? undefined : scorecardId,
						filter: reportFilter || undefined,
					}),
					data: {
						pageTitle: title,
					},
					method: "put",
				})
					.then(() => {
						toast.success(translate("toaster.messages.templates.templateExportGenerating"));
						if (close) close();
					})
					.catch(() => {
						toast.error(translate("options.admin.exportError"));
						if (close) close();
					});
		},
		[layoutId, object, objectId, periodId, reportFilter, scorecardId, set, title, translate]
	);
	let child = React.Children.only(children);
	return React.cloneElement(child, { onClick: generateExcel });
};
GenerateExcelOnClick.propTypes = propTypes;
export default GenerateExcelOnClick;
