import { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useEffectOnce } from "react-use";

import { useCheckAccess, useFormatDate } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject, getCurrentDate } from "@clearpoint/utils";

import Block from "../../../Block";
import Column from "../../../Column";
import FormCompletedCheckbox from "../../../Form/Check/Form.Check.Completed";
import FormFieldCompletedDate from "../../../Form/Field/CompletedDate";
import FormFieldDisplayStatusId from "../../../Form/Field/Display/StatusId";
import FormFieldName from "../../../Form/Field/Name";
import FormFieldPercentComplete from "../../../Form/Field/PercentComplete";
import FormFieldStartAndEndDate from "../../../Form/Field/StartAndEndDate";
import { useFormContext } from "../../../Form/Form";
import FormLabel from "../../../Form/Form.Label";
import useDefaultFormValue from "../../../Form/hooks/useDefaultFormValue";
import useFormValue from "../../../Form/hooks/useFormValue";
import FormModal from "../../../Form/Modal/Form.Modal";
import FormSelectStatus from "../../../Form/Select/Select.Status";
import Row from "../../../Row";
import { theme } from "../../../Theme";
import { useOldSession } from "@clearpoint/old-session/index";

let fieldWrapperPropTypes = {
	breakpoint: PropTypes.number,
	children: PropTypes.node,
};
let FieldWrapper = ({ breakpoint, children }) => <Column large={breakpoint || 6}>{children}</Column>;
FieldWrapper.propTypes = fieldWrapperPropTypes;

let propTypes = {
	ganttPodFlag: PropTypes.bool,
	movableFlag: PropTypes.bool,
	name: PropTypes.string,
	onSave: PropTypes.func,
};
let EditTaskModal = ({ ganttPodFlag, movableFlag, name, onSave, ...props }) => {
	let translate = useTranslate();
	let formTask = useFormValue(name);
	let formatDate = useFormatDate();
	let formTaskDefaultValue = useDefaultFormValue(name);
	let { formStatus, setFormValue } = useFormContext();
	let autoEvaluationFlag = formTask?.autoEvaluation ?? formTask?.evaluated ?? false;
	let { access, completed: completedFlag, object, objectId, scorecardId } = formTask || emptyObject;
	let checkAccess = useCheckAccess();
	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });
	let updateAccessFlag = checkAccess({ access, action: "update", scorecardId });

	let links = useFormValue("links");
	let podIndex = useMemo(
		() => Array.isArray(links) && links?.findIndex((x) => x?.objectId === objectId),
		[links, objectId]
	);
	let { periodId } = useOldSession().session;

	let statusSelectName = formTask.status ? `${name}.status[${periodId}].statusId` : `${name}.statusId`;
	let percentCompleteName = formTask.status
		? `${name}.status[${periodId}].percentComplete`
		: `${name}.percentComplete`;

	useEffectOnce(() => {
		if (formTask && (!formTask?.completedDate || !formTaskDefaultValue?.completed)) {
			setFormValue(`${name}.completedDate`, formatDate(getCurrentDate(), "apiDate"));
		}
	});

	let statusId = useFormValue(`links.${podIndex}.statusId`);
	let initialStatusId = useRef(statusId);
	let setLinkStatusId = useCallback(
		(e) => {
			let statusId = e?.target ? e.target.value : e;
			setFormValue(`links.${podIndex}.statusId`, statusId?.value);
		},
		[podIndex, setFormValue]
	);

	let [completedFlagState, setCompletedFlagState] = useState(completedFlag);
	let initialCompletedFlag = useRef(completedFlag);
	let setCompletedFlag = useCallback(
		(completedFlag) => {
			setCompletedFlagState(completedFlag);
			setFormValue(`${name}.completed`, completedFlag);
		},
		[name, setCompletedFlagState, setFormValue]
	);

	let onCancel = useCallback(() => {
		if (ganttPodFlag) {
			if (completedFlagState !== initialCompletedFlag.current && object === "milestone") {
				let milestone = `_${object}${objectId}completed`;
				setFormValue(milestone, !completedFlagState);
			}
			if (statusId !== initialStatusId.current) {
				setFormValue(`links.${podIndex}.statusId`, initialStatusId.current);
			}
		}
	}, [completedFlagState, ganttPodFlag, object, objectId, podIndex, setFormValue, statusId]);

	return formTask ? (
		<FormModal
			name={name}
			title={formTask.name}
			size="medium"
			onCancel={onCancel}
			onSave={onSave}
			valid={formStatus.valid}
			{...props}
		>
			<FormLabel for={`${name}.name`}>{translate("detailLayouts.elementNameStatus")}</FormLabel>
			<Block display="flex" alignItems="center" marginBottom={theme.space}>
				{autoEvaluationFlag ? (
					<FormFieldDisplayStatusId
						marginBottom="0px"
						name={statusSelectName}
						marginRight={theme.smallSpace}
						hidePageTitleFlag
					/>
				) : (
					<FormSelectStatus
						object={object}
						iconOnlyFlag
						marginBottom="0px"
						name={statusSelectName}
						onChange={setLinkStatusId}
					/>
				)}
				<Block width="100%">
					<FormFieldName name={`${name}.name`} label={null} marginBottom="0px" disabled={!editAccessFlag} />
				</Block>
			</Block>
			{editAccessFlag && (
				<FormFieldStartAndEndDate
					elementNameInForm={name}
					endDateNameInForm={`${name}.endDate`}
					startDateNameInForm={`${name}.startDate`}
					disabled={!movableFlag}
				/>
			)}

			<Row>
				{editAccessFlag && (
					<FieldWrapper>
						{ganttPodFlag && (
							<Block marginBottom={theme.mediumSpace}>
								<FormCompletedCheckbox
									defaultValue={completedFlag}
									object={object}
									objectId={objectId}
									text={translate(`defaultFields.edit.${object}.completed`)}
									onClick={setCompletedFlag}
									milestoneFlag
								/>
							</Block>
						)}
						{(!ganttPodFlag || completedFlag) && (
							<FormFieldCompletedDate
								parentName={name}
								name={`${name}.completedDate`}
								objectId={objectId}
								label={translate("logFieldLabels.milestoneCompletedDate")}
								dateOnlyFlag={ganttPodFlag}
							/>
						)}
					</FieldWrapper>
				)}
				<FieldWrapper breakpoint={editAccessFlag ? 6 : 12}>
					<FormFieldPercentComplete
						label={translate("logFieldLabels.percentComplete")}
						name={percentCompleteName}
						disabled={!updateAccessFlag}
					/>
				</FieldWrapper>
			</Row>
		</FormModal>
	) : null;
};
EditTaskModal.propTypes = propTypes;

export default EditTaskModal;
