import Block from "@clearpoint/old-theme/Block";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import PropTypes from "prop-types";
import { darken } from "polished";
import { theme } from "@clearpoint/old-theme/Theme";
import { useBuildLink } from "@clearpoint/hooks";


let propTypes = {
	className: PropTypes.string,
	displayWidth: PropTypes.number,
	left: PropTypes.string,
	name: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	scorecardId: PropTypes.number,
	statusId: PropTypes.number,
	top: PropTypes.string,
};
let MapElement = ({ className, displayWidth, left, name, object, objectId, scorecardId, statusId, top }) => {
	let buildLink = useBuildLink();
	let { map } = useLocalState();
	let { borderWidth, boxBorder, boxColor, boxRadius, boxWidth, showNames: customStyleFlag, textColor } = map || {};
	let backgroundColor, border, borderRadius, color, padding, width;
	if (customStyleFlag) {
		border = `${borderWidth}px ${boxBorder} solid !important`;
		borderRadius = boxRadius < 15 ? `${boxRadius}px` : `${boxRadius}%`;
		padding = "2px 5px 5px";
		width = `${Math.round((boxWidth / 1024) * displayWidth)}px`;
		backgroundColor = boxColor;
		color = textColor;
	}
	let LocalStatusIcon = useCallback(
		({ className }) => <StatusIcon className={className} marginRight="0px" statusId={statusId} title={name} />,
		[name, statusId]
	);
	return (
		<a href={buildLink({ scorecardId, object, objectId })}>
			<Block
				className={className}
				left={left}
				top={top}
				position="absolute"
				$style={`&:hover { z-index: 1000; } &.dropped { visibility: visible !important; transition: none !important }`}
			>
				{customStyleFlag ? (
					<StyleWrapper
						border={border}
						borderRadius={borderRadius}
						backgroundColor={backgroundColor}
						color={color}
						width={width}
						padding={padding}
						textAlign="left"
						wordBreak="normal"
						overflow="hidden"
						$style={`&:hover, &.hover, &:focus, &.focus { z-index: 1000; color: ${
							color ? `${color} !important` : undefined
						}; background-color: ${
							backgroundColor ? `${darken(0.15, backgroundColor)} !important` : undefined
						}; }`}
					>
						<Block display="flex" alignItems={"flex-start"} fontSize="12px">
							<StyleWrapper height="20px !important" width="20px !important">
								<LocalStatusIcon />
							</StyleWrapper>
							<Block marginLeft={theme.tinySpace}>{name}</Block>
						</Block>
					</StyleWrapper>
				) : (
					<LocalStatusIcon />
				)}
			</Block>
		</a>
	);
};
MapElement.propTypes = propTypes;

export default MapElement;
