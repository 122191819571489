import Block from "../Block";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useMenuColor } from "@clearpoint/hooks";


let containerPropTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
};
let FooterContainer = ({ children }) => {
	let menuColor = useMenuColor();
	return (
		<Block
			alignItems="center"
			backgroundColor={menuColor}
			borderTop={`1px solid ${theme.lightGray}`}
			color={theme.white}
			display="flex"
			flex="0 1 0%"
			justifyContent="space-between"
			minHeight="46px"
			padding={`${theme.smallSpace} ${theme.space}`}
			userSelect="none"
			$style={`& .btn:not(.dropdown-toggle) {
		margin-left: ${theme.tinySpace};
	}`}
		>
			{children}
		</Block>
	);
};
FooterContainer.propTypes = containerPropTypes;
export default FooterContainer;
