/* eslint-disable consistent-return */
//import Store, { useStore } from "./Store";

import React, {
	createContext,
	useCallback,
	useContext,
	useDeferredValue,
	useEffect,
	useReducer,
	useRef,
	useState,
} from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { QueryProvider } from "@clearpoint/query";
import { getRoute } from "@clearpoint/utils";
import { luceeAxios } from "@clearpoint/services/axiosService";
import http, { httpExpress } from "@clearpoint/services/httpService/index";

import objectToKey from "./objectToKey";
import createUrl from "./Store.createUrl";
import generateClearAssociatedFunction from "./Store.generateClearAssociatedFunction";
import generateClearFunction from "./Store.generateClearFunction";
import generateDestroyFunction from "./Store.generateDestroyFunction";
import generateGetFunction from "./Store.generateGetFunction";
import generatePauseLoadingFunction from "./Store.generatePauseLoadingFunction";
import generateResumeLoadingFunction from "./Store.generateResumeLoadingFunction";
import generateSetFunction from "./Store.generateSetFunction";
import generateSetLocalFunction from "./Store.generateSetLocalFunction";
import generateTrashFunction from "./Store.generateTrashFunction";
import generateUndeleteFunction from "./Store.generateUndeleteFunction";
import reducer from "./Store.reducer";
import setCompletedRequests from "./Store.setCompletedRequests";
import validateParameters from "./Store.validateParameters";
import useMockData from "./useMockData";

export const StoreContext = createContext();

/* START avoid circular dependency */

let useEffectOnce = (e) => {
	useEffect(e, []); // eslint-disable-line react-hooks/exhaustive-deps
};

let useOnEvent = ({ cleanupFunction, dependencyList, eventName, handlerFunction, setupFunction, eventTarget }) => {
	let handlerRef = useRef(handlerFunction);
	let setupRef = useRef(setupFunction);
	let cleanupRef = useRef(cleanupFunction);
	let checkDependencyList = useCallback(() => {
		if (!dependencyList) return true;
		return dependencyList.every((x) => !!x);
	}, [dependencyList]);
	useEffect(() => {
		handlerRef.current = handlerFunction;
		setupRef.current = setupFunction;
		cleanupRef.current = cleanupFunction;
	}, [handlerFunction, setupFunction, cleanupFunction]);
	useEffect(() => {
		if (!checkDependencyList() || !(setupRef.current && cleanupRef.current)) return;
		if (setupRef.current) setupRef.current();
		return () => {
			if (cleanupRef.current) cleanupRef.current();
		};
	}, [checkDependencyList]);
	useEffect(() => {
		if (!eventName || !checkDependencyList() || !handlerRef.current) return;
		let handler = handlerRef.current;
		if (eventTarget) {
			eventTarget.addEventListener(eventName, handler);
			return () => {
				eventTarget.removeEventListener(eventName, handler);
			};
		}
		document.addEventListener(eventName, handler);
		return () => {
			document.removeEventListener(eventName, handler);
		};
	}, [checkDependencyList, dependencyList, eventName, eventTarget]);
};

let generateReducer = (initialState) => (oldState, updates) => {
	let functionUpdates = {};
	for (let key in updates) {
		if (!Object.hasOwn(initialState, key)) {
			throw new Error("Invalid key in state: " + key);
		}
		if (typeof updates[key] === "function") {
			let updateFunctionOutput = updates[key](oldState[key]);

			// avoid unintentionally rendering functional components
			functionUpdates[key] =
				updateFunctionOutput?.$$typeof === Symbol.for("react.element") ? updates[key] : updateFunctionOutput;
		}
	}
	return { ...oldState, ...updates, ...functionUpdates };
};

let useStateObject = (x) => {
	let initialState = { ...x };
	return useReducer(generateReducer(initialState), x);
};

/* END avoid circular dependency */

let initialState;

let initialize = () => {
	initialState = [];
};
// structure:
// [{object, objectId, layoutId, parentId, parent, periodId, scorecardId, ...data}, ...]

// // Example uses
// let { get, set, trash } = useStore();
// // http get / retrieve value
// get({ object: "measureLayout" });
// get({ object: "measureLayout", objectId: 123 });
// // http post, create
// set({ object: "measureLayout", data: {name: "originalName"} });
// // http put, update
// set({ object: "measureLayout", objectId: 123, data: {name: "newName"} });
// // http delete
// trash({ object: "measureLayout", objectId: 123 });
initialize();

let propTypes = { children: PropTypes.node };

export const Store = ({ children }) => {
	let [state, dispatch] = useReducer(reducer, initialState);
	let testFlag = global?.testFlag;
	// remove completed requests from requests array on next render
	let [{ completedRequests, pendingStoreUpdateCount, staleData }, setState] = useStateObject({
		completedRequests: [],
		pendingStoreUpdateCount: 0,
		staleData: [],
	});
	let requests = useRef([]);
	let waitingRequestCount = useRef(0);
	let stateRef = useRef(state);
	stateRef.current = state;

	useEffect(
		() => setCompletedRequests({ completedRequests, requests, setState, waitingRequestCount }),
		[completedRequests, setState]
	);

	useEffectOnce(() => {
		waitingRequestCount.current = 0;
	});

	useEffect(() => {
		return () => {
			requests.current = [];
		};
	}, []);

	let markStale = useCallback(
		async (id) => {
			if (id === "all") {
				let allStale = stateRef.current.map((x) => ({ object: x.object, objectId: x.objectId }));
				setState({ staleData: allStale });
			} else if (
				id.object &&
				id.objectId &&
				!staleData.some((x) => x.object === id.object && x.objectId === id.objectId)
			) {
				staleData.push({ object: id.object, objectId: id.objectId });
				setState({ staleData });
			} else if (
				id.object &&
				id.objectId === undefined &&
				staleData.some((x) => x.object === id.object && x.objectId === undefined)
			) {
				staleData.push({ object: id.object });
				setState({ staleData });
			}
		},
		[setState, staleData]
	);

	let clear = useCallback(async (id) => {
		try {
			document.dispatchEvent(new CustomEvent("clear", { detail: id }));
		} catch (error) {
			console.log(error);
		}
		let clearFunction = generateClearFunction({
			dispatch,
			http,
			objectToKey,
			requests,
			state: stateRef.current,
			waitingRequestCount,
		});
		return clearFunction(id);
	}, []);

	useOnEvent({
		// this can be removed once leetio modules are refactored with correct query keys
		eventName: "fetch",
		handlerFunction: (e) => {
			let { method, url } = e?.detail || {};
			if (method !== "GET" && url.includes("comment")) {
				let objectId = url.match(/\d+$/)?.[0];
				if (objectId) clear({ object: "comment", objectId: +objectId });
			}
		},
	});

	let clearAssociated = useCallback(
		(object) => {
			let clearAssociatedFunction = generateClearAssociatedFunction({ clear });
			return clearAssociatedFunction(object);
		},
		[clear]
	);

	let pauseLoadingTimeoutRef = useRef();
	let resumeLoadingTimeoutRef = useRef();
	let pauseLoadingFlagRef = useRef();
	let cancelResumeLoadingRef = useRef();

	let resumeLoading = useCallback(() => {
		let resumeLoadingFunction = generateResumeLoadingFunction({
			cancelResumeLoadingRef,
			pauseLoadingFlagRef,
			requests,
			resumeLoadingTimeoutRef,
		});
		return resumeLoadingFunction;
	}, []);

	let pauseLoading = useCallback(() => {
		let pauseLoadingFunction = generatePauseLoadingFunction({
			cancelResumeLoadingRef,
			pauseLoadingFlagRef,
			pauseLoadingTimeoutRef,
			requests,
			resumeLoading,
		});
		return pauseLoadingFunction;
	}, [resumeLoading]);

	let isLoading = useCallback(
		(id) => {
			if (id?.object === undefined) return false;
			let storeKey = createUrl(id);
			return pauseLoadingFlagRef.current ? false : requests.current.some((x) => isEqual(x.id, storeKey));
		},
		// required to update state due to requests being a ref
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[completedRequests]
	);
	let getLoadingRequestCount = useCallback(
		() =>
			requests.current.filter((x) => !completedRequests.some((y) => x.id === y.id)).length - pendingStoreUpdateCount,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[completedRequests, pendingStoreUpdateCount]
	);

	let getStoredValue = useCallback(
		(parameters) => {
			validateParameters(parameters, "get");
			let url = createUrl(parameters);
			let { object, parent, payload } = parameters;
			let method =
				["preview", "count", "search"].includes(object) ||
				(object === "map" && parent === "measureSeries" && payload)
					? "post"
					: object === "filter"
					? "put"
					: "get";
			let storeKey = ["post", "put"].includes(method) && payload ? url + objectToKey(payload) : url;
			let value = stateRef.current.find((x) => x && x.storeKey === storeKey && !x._REFRESH)?.data;
			return value;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state]
	);

	let mockData = useMockData();

	let get = useCallback(
		(parameters, promiseFlag) => {
			if (!luceeAxios.defaults.headers.common["Authorization"] && window.location.hash.includes("login")) return;
			validateParameters(parameters, "get");
			let callFunction = generateGetFunction({
				dispatch,
				get,
				http,
				httpExpress,
				mockData,
				objectToKey,
				requests,
				setState,
				stateRef,
				testFlag,
				waitingRequestCount,
			});
			return callFunction(parameters, promiseFlag);
		},
		// get must update when state updates
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setState, state, mockData]
	);

	let getPromise = useCallback((parameters) => get(parameters, true), [get]);

	let setLocal = useCallback(({ data, ...id }) => {
		let setLocalFunction = generateSetLocalFunction(dispatch);
		return setLocalFunction({ data, ...id });
	}, []);

	useOnEvent({
		// incoming event from new query library.
		// fires whenever a query errors
		// update the state here like we would on a get error.
		eventName: "queryError",
		handlerFunction: (e) => {
			let { error, queryKey } = e?.detail || {};
			console.warn(error);
			let queryObject = queryKey[0];
			delete queryObject.type;
			setLocal({ data: [], ...queryObject });
		},
	});

	useOnEvent({
		// incoming event from new query library.
		// fires whenever a query succeeds
		// update the state here like we would on a get data.
		eventName: "query",
		handlerFunction: (e) => {
			let { data, queryKey } = e?.detail || {};
			let queryObject = queryKey[0];
			delete queryObject.type;
			setLocal({ data, ...queryObject });
		},
	});

	let refresh = useCallback(() => {
		let staleCopy = [...staleData];
		for (const element of staleCopy) {
			clear(element);
		}
		setState({ staleData: [] });
	}, []);

	let set = useCallback(
		({ data, stopPropagationFlag, skipClearFlag, method, ...id }) => {
			let setFunction = generateSetFunction({
				clear,
				clearAssociated,
				http,
			});
			return setFunction({ data, method, skipClearFlag, stopPropagationFlag, ...id });
		},
		[clear, clearAssociated]
	);
	let trash = useCallback(
		async (parameters) => {
			let trashFunction = generateTrashFunction({
				clear,
				clearAssociated,
				http,
				httpExpress,
			});
			return trashFunction(parameters);
		},
		[clear, clearAssociated]
	);
	let destroy = useCallback(
		async ({ object, objectId }) => {
			let destroyFunction = generateDestroyFunction({ clear, clearAssociated, getRoute, http });
			return destroyFunction({ object, objectId });
		},
		[clear, clearAssociated]
	);
	let undelete = useCallback(
		async ({ object, objectId }) => {
			let undeleteFunction = generateUndeleteFunction({ clear, clearAssociated, getRoute, http });
			return undeleteFunction({ object, objectId });
		},
		[clear, clearAssociated]
	);

	let resetStore = useCallback(() => {
		dispatch({ type: "HARD_SET", state: initialState });
	}, [dispatch]);

	let loadingFlag = requests.current.length > 0 || pendingStoreUpdateCount > 0;
	let deferredLoadingFlag = useDeferredValue(loadingFlag);
	if (!loadingFlag) loadingFlag = deferredLoadingFlag;
	return (
		<StoreContext.Provider
			value={{
				clear,
				clearAssociated,
				destroy,
				get,
				getLoadingRequestCount,
				getPromise,
				resetStore,
				getStoredValue,
				isLoading,
				loadingFlag,
				markStale,
				pauseLoading,
				staleData,
				refresh,
				set,
				setLocal,
				state,
				trash,
				undelete,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};

let QueryStore = ({ children }) => (
	<QueryProvider>
		<Store>{children}</Store>
	</QueryProvider>
);

Store.propTypes = propTypes;

const useStore = () => useContext(StoreContext);

export { QueryStore as OldQueryStore, useStore as useOldQueryStore };
