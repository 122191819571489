import isArray from "lodash/isArray";
import { emptyObject } from "@clearpoint/utils";


let setDefaultNotificationValuesInForm = (notificationList, setFormDefaultValue) => {
	if (isArray(notificationList)) {
		for (let notification of notificationList) {
			let { defaultValue, name } = notification || emptyObject;
			if (name) {
				setFormDefaultValue(name, defaultValue);
			}
		}
	}
};

export default setDefaultNotificationValuesInForm;
