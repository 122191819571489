import { Fragment, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import LoadingAlert from "@clearpoint/old-theme/Alert/Alert.Loading";
import Block from "@clearpoint/old-theme/Block";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Hide from "@clearpoint/old-theme/Hide";
import Portal from "@clearpoint/old-theme/Portal/Portal";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";

import { usePercentLoaded } from "@clearpoint/hooks";
import { emptyArray } from "@clearpoint/utils";

import Gantt from "./Gantt";
import Grid from "./Grid";
import PrintTitle from "./PrintTitle";
import SelectGanttScale from "./SelectGanttScale";
import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let wrapperPropTypes = {
	portalFlag: PropTypes.bool,
};
// eslint-disable-next-line react/jsx-no-useless-fragment
let Wrapper = ({ portalFlag, ...props }) => (portalFlag ? <Portal {...props} /> : <Fragment {...props} />);
Wrapper.propTypes = wrapperPropTypes;

let propTypes = {
	brokenApiCallFlag: PropTypes.bool,
	customFieldFlag: PropTypes.bool,
	elementData: PropTypes.object,
	reportType: PropTypes.string,
	tableLoadingFlag: PropTypes.bool,
	tableStyle: PropTypes.string,
	tableStyleObject: PropTypes.object,
};
let PrintDisplay = ({
	brokenApiCallFlag,
	customFieldFlag,
	elementData,
	reportType,
	tableLoadingFlag,
	tableStyle,
	tableStyleObject,
}) => {
	let { ganttTitleRenderedFlag, htmlExportFlag } = useOldSession().session;
	let { elementListName, gridPodFlag, layout } = useLocalState();
	let elementList = useFormValue(elementListName);
	let titleFlag = !layout?.reportOptions?.hideTitle && !customFieldFlag;
	let percentLoaded = usePercentLoaded();
	let ganttFlag = reportType === "gantt";

	elementData = useMemo(() => {
		if ((ganttFlag && htmlExportFlag && elementData) || (!ganttFlag && gridPodFlag && elementData)) {
			return { ...elementData, report: elementData?.report ? [elementData.report.flat()] : emptyArray };
		}
		return elementData;
	}, [elementData, ganttFlag, gridPodFlag, htmlExportFlag]);
	let TableWrapper = ganttFlag || htmlExportFlag || customFieldFlag ? Block : StyleWrapper;

	let titleRenderedFlag = titleFlag ? ganttTitleRenderedFlag : true;
	if (!ganttFlag) {
		titleRenderedFlag = true;
	}
	let renderLoadingStateFlag = brokenApiCallFlag ? false : !elementList || !titleRenderedFlag || tableLoadingFlag;

	let gridContainerFlag = ganttFlag || htmlExportFlag || customFieldFlag;
	let tableClass = gridContainerFlag ? "grid-container" : undefined;

	return renderLoadingStateFlag ? (
		<>
			<LoadingAlert percentLoaded={percentLoaded} />
			{titleFlag && <PrintTitle reportType={reportType} />}
		</>
	) : (
		<>
			{elementData.report.map((x, i) => (
				<Wrapper key={i} portalFlag={i > 0 && !customFieldFlag}>
					<Block
						className={customFieldFlag || i === 0 ? undefined : "page-break"}
						marginBottom={ganttFlag && !customFieldFlag && i === 0 ? "-50px" : "0px"}
					>
						{titleFlag && <PrintTitle reportType={reportType} />}
						<TableWrapper
							className={classNames(tableClass, i === 0 && !gridPodFlag ? "avoid-page-break-before" : undefined)}
							width="100%"
							height={ganttFlag ? `${(x.length + 2) * 26}px` : undefined}
						>
							{ganttFlag ? (
								<Gantt
									tableLoadingFlag={tableLoadingFlag}
									value={x.map((x) =>
										elementList.find((y) => y.object === x.object && y.objectId === x.objectId)
									)}
								/>
							) : (
								<Grid
									cellData={x}
									elementData={elementData}
									tableStyle={tableStyle}
									tableStyleObject={tableStyleObject}
									tableLoadingFlag={tableLoadingFlag}
								/>
							)}
						</TableWrapper>
					</Block>
				</Wrapper>
			))}
			{ganttFlag && (
				<Hide visible={false}>
					<SelectGanttScale />
				</Hide>
			)}
		</>
	);
};
PrintDisplay.propTypes = propTypes;
export default PrintDisplay;
