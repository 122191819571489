import FormFieldDisplayTags from "@clearpoint/old-theme/Form/Field/Display/Tags";
import FormFieldTags from "@clearpoint/old-theme/Form/Field/Tags";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let Tags = ({ editPodFlag, name }) =>
	editPodFlag ? <FormFieldTags label={null} name={name} /> : <FormFieldDisplayTags name={name} />;

Tags.propTypes = propTypes;
export default Tags;
