import FormFieldChartPeriodGroupId from "./ChartPeriodGroupId";
import FormSelectPeriodGroup from "../../Form/Select/Select.PeriodGroup";
import FormSelectPeriod from "../../Form/Select/Select.Period";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	compactFlag: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	object: PropTypes.string,
	parentName: PropTypes.string,
};
let FormFieldPeriodGroupId = ({ compactFlag, label, name, object, parentName, ...props }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let chartFlag = object === "chart";
	return chartFlag ? (
		<FormFieldChartPeriodGroupId
			compactFlag={compactFlag}
			label={label}
			name={name}
			object={object}
			parentName={parentName}
		/>
	) : (
		<>
			<FormSelectPeriodGroup data-testid="periodGroupId" label={label} name={name} object={object} {...props} />
			{value === 0 && (
				<FormSelectPeriod
					compactFlag={compactFlag}
					data-testid="hiddenPeriods"
					label={translate("edit.hiddenPeriods")}
					multipleFlag
					name={parentName ? `${parentName}.hiddenPeriods` : "hiddenPeriods"}
				/>
			)}
		</>
	);
};
export default FormFieldPeriodGroupId;
FormFieldPeriodGroupId.propTypes = propTypes;
