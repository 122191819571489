import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectCustomizationType = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("objects.scorecard"),
				value: "scorecard",
			},
			{
				label: translate("logFieldLabels.localeId"),
				value: "locale",
			},
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};
export default SelectCustomizationType;
