// When step is 1, max is 4, and value is 3.5, display the slider value as 3 instead of 4
let roundSliderValueAwayFromMax = ({ max, min, step, value }) => {
	value = +value;

	let sliderMax = Math.max(max, min);
	let overrideDefaultRoundingFlag = sliderMax - value < step && sliderMax > value;

	return overrideDefaultRoundingFlag ? sliderMax - step : value;
};

export default roundSliderValueAwayFromMax;
