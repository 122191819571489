import FormField from "@clearpoint/old-theme/Form/Field/Field";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import { theme } from "@clearpoint/old-theme/Theme";
import { useFieldList } from "@clearpoint/hooks";


let propTypes = {
	parentId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let MeasureSeriesTab = ({ parentId, objectIdList }) => {
	let addFlag = !objectIdList;
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let fieldList = useFieldList({ object: "measureSeries", addFlag, multipleFlag: addFlag });
	let displayedFieldList = useMemo(
		() =>
			fieldList && [...fieldList?.filter((field) => field.value !== "seriesType" && field.value !== "evaluations")],
		[fieldList]
	);
	let measureSeriesList = get({ parent: "measure", parentId, object: "measureSeries" });
	let selectedMeasureSeriesList = useMemo(
		() =>
			measureSeriesList && isArray(measureSeriesList)
				? measureSeriesList?.filter((measureSeries) =>
						objectIdList?.some((objectId) => objectId === measureSeries.objectId)
				  )
				: [],
		[measureSeriesList, objectIdList]
	);

	return (
		<>
			{addFlag ? (
				<>
					{displayedFieldList?.map((field) => (
						<FormField
							editFlag={!addFlag}
							fieldId={field.value}
							key={field.value}
							label={field.value === "hiddenSeries" || field.value === "hiddenSummary" ? "" : field.label}
							parent="measure"
							parentId={parentId}
							object="measureSeries"
							measureSeriesList={measureSeriesList}
						/>
					))}
				</>
			) : (
				<FormGroup
					title={translate("edit.element.elementsToUpdate", {
						objects: translate("objects.measureSeries"),
					})}
					paddingBottom={theme.tinySpace}
				>
					{selectedMeasureSeriesList?.map((measureSeries) => (
						<ListItem
							editFlag={!addFlag}
							key={measureSeries.objectId}
							left={<span>{measureSeries.name}</span>}
							size="small"
						/>
					))}
				</FormGroup>
			)}
		</>
	);
};
MeasureSeriesTab.propTypes = propTypes;
export default MeasureSeriesTab;
