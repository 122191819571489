import DropdownItem from "./Dropdown.Item";
import ModalOnClick from "../../Modal/Modal.OnClick";
import ModalWindowOnClick from "../../ModalWindow/ModalWindow.OnClick";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";


let propTypes = {
	children: PropTypes.node,
};
let DropdownItemEdit = ({ children, label, object, ...props }) => {
	let translate = useTranslate();
	let reportFlag = label === translate("global.report") || object === "map" || !!props.layoutId;
	return !reportFlag ? (
		<DropdownItem data-testid="dropdown-item-edit" icon={"edit"} iconSize="small" {...props}>
			{children ||
				translate("edit.editElement", {
					InsertText: label ? capitalizeFirstLetter(label) : `global.${object || "element"}`,
				})}
		</DropdownItem>
	) : (
		<DropdownItem icon={"editReport"} iconSize="small" {...props}>
			{children ||
				translate("edit.editElement", {
					InsertText: label ? capitalizeFirstLetter(label) : `global.${object || "element"}`,
				})}
		</DropdownItem>
	);
};
DropdownItemEdit.propTypes = propTypes;

let wrapperPropTypes = {
	Modal: PropTypes.elementType,
	ModalWindow: PropTypes.elementType,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let DropdownItemEditWrapper = ({ Modal, ModalWindow, label, layoutId, object, objectId, ...props }) => {
	return objectId ? (
		Modal ? (
			<ModalOnClick Modal={Modal} objectId={objectId} {...props}>
				<DropdownItemEdit label={label} object={object} {...props} />
			</ModalOnClick>
		) : ModalWindow ? (
			<ModalWindowOnClick ModalWindow={ModalWindow} objectId={objectId} {...props}>
				<DropdownItemEdit label={label} object={object} {...props} />
			</ModalWindowOnClick>
		) : (
			<DropdownItemEdit label={label} object={object} {...props} />
		)
	) : layoutId ? (
		Modal ? (
			<ModalOnClick Modal={Modal} layoutId={layoutId} object={object} {...props}>
				<DropdownItemEdit label={label} object={object} {...props} />
			</ModalOnClick>
		) : ModalWindow ? (
			<ModalWindowOnClick ModalWindow={ModalWindow} layoutId={layoutId} object={object} {...props}>
				<DropdownItemEdit label={label} object={object} {...props} />
			</ModalWindowOnClick>
		) : (
			<DropdownItemEdit label={label} object={object} {...props} />
		)
	) : null;
};
DropdownItemEditWrapper.propTypes = wrapperPropTypes;
export default DropdownItemEditWrapper;
