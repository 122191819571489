import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useFormatDate } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayEndDate = ({ name }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let endDate = useFormValue(name);
	return endDate ? <span>{formatDate(endDate, "short")}</span> : translate("global.notAvailable");
};

FormFieldDisplayEndDate.propTypes = propTypes;
export default FormFieldDisplayEndDate;
