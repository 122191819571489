import SeriesCell from "./SeriesCell";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	measureSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			hiddenSeries: PropTypes.bool,
			measureId: PropTypes.number,
			measureSeriesId: PropTypes.number,
		})
	),
	periodData: PropTypes.shape({
		periodId: PropTypes.number,
		statusId: PropTypes.number,
		statusName: PropTypes.string,
	}),
};

let PeriodRow = ({ measureSeriesList, periodData }) => {
	let translate = useTranslate();
	let { periodId, statusId, statusName } = periodData;

	return (
		<tr className="cps-tr">
			<td data-col-index="0" className="cps-td">
				{periodData.periodName}
			</td>
			<td
				data-col-index="1"
				className="cps-td"
				data-status-id={statusId}
				data-status-display="icon"
				data-field-id="statusIcon"
				data-object="measure"
				data-object-id={measureSeriesList?.[0].measureId}
				data-period-id={periodId}
			>
				<table className="no-table-pdf-tag">
					<tbody>
						<tr>
							<td>
								<StatusIcon statusId={statusId} size="normal" />
							</td>
							<td>{statusName || translate("global.notDefined")}</td>
						</tr>
					</tbody>
				</table>
			</td>
			{measureSeriesList?.map((series, i) => (
				<SeriesCell key={series.measureSeriesId} index={i} periodData={periodData} series={series} />
			))}
		</tr>
	);
};

PeriodRow.propTypes = propTypes;
export default PeriodRow;
