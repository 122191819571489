import Block from "@clearpoint/old-theme/Block";
import FormFieldDisplayOwnerId from "@clearpoint/old-theme/Form/Field/Display/OwnerId";
import FormFieldOwnerId from "@clearpoint/old-theme/Form/Field/OwnerId";
import PropTypes from "prop-types";

let propTypes = {
	editPodFlag: PropTypes.bool,
	name: PropTypes.string,
};

let Owner = ({ editPodFlag, name }) => (
	<Block minHeight="24px">
		{editPodFlag ? (
			<FormFieldOwnerId name={name} marginBottom="0px" />
		) : (
			<FormFieldDisplayOwnerId name={name} noLinkFlag marginBottom="0px" />
		)}
	</Block>
);

Owner.propTypes = propTypes;
export default Owner;
