import { useCallback, useState } from "react";

let useScrollbar = () => {
	let [scrollbarVisible, setScrollbarVisible] = useState(false);
	let showScrollbar = useCallback((e) => {
		e.preventDefault();
		setScrollbarVisible(true);
	}, []);

	let hideScrollbar = useCallback((e) => {
		e.preventDefault();
		setScrollbarVisible(false);
	}, []);

	return { scrollbarVisible, showScrollbar, hideScrollbar };
};

export default useScrollbar;
