import { emptyObject } from "@clearpoint/utils";

let getElementAndPageProps = (logEntry) => {
	let modifiedElement = logEntry?.[0] || emptyObject;

	let { page } = modifiedElement;

	let {
		layoutId: pageLayoutId,
		object: pageObject,
		objectId: pageObjectId,
		scorecardId: pageScorecardId,
	} = page || emptyObject;

	let revertModalProps = {
		date: modifiedElement.date,
		details: modifiedElement.details,
		object: modifiedElement.object,
		objectId: modifiedElement.objectId,
		periodId: modifiedElement.periodId,
	};

	let linkButtonProps = {
		layoutId: pageLayoutId,
		object: pageObject,
		objectId: pageObjectId,
		scorecardId: pageScorecardId,
	};

	return {
		linkButtonProps,
		modifiedElement,
		revertModalProps,
	};
};

export default getElementAndPageProps;
