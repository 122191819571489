import CheckAll from "@clearpoint/old-theme/Checklist/Checklist.Check.All";
import ChecklistProvider, { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import DragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import DropdownAddItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Add";
import DropdownAddMultipleItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.AddMultiple";
import DropdownEditMultipleItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.EditMultiple";
import DropdownSplitAddButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Add";
import DropdownTrashMultipleItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.TrashMultiple";
import EditMultipleMeasureSeriesModal from "./EditMultipleSeriesModal";
import EditSeriesModal from "./EditSeriesModal";
import EvaluationOptionSelect from "@clearpoint/old-theme/Form/Select/Select.EvaluationOption";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";
import Help from "@clearpoint/old-theme/Help";
import Loading from "@clearpoint/old-theme/Loading/index";
import NoResults from "@clearpoint/old-theme/NoResults";
import SeriesListItem from "./SeriesListItem";
import ShowDeleted from "@clearpoint/old-theme/Filter/Filter.ShowDeleted";
import { useCallback, useDeferredValue, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { toast } from "@clearpoint/services/toastService/index";

let propTypes = {
	children: PropTypes.node,
	seriesList: PropTypes.array,
};
let FilterWrapper = ({ children, seriesList }) => {
	return !seriesList ? (
		<Loading />
	) : (
		<ChecklistProvider>
			<FilterProvider data={seriesList}>{children}</FilterProvider>
		</ChecklistProvider>
	);
};
FilterWrapper.propTypes = propTypes;

let SeriesTabContentPropTypes = {
	seriesList: PropTypes.array,
	measureId: PropTypes.number,
};
let SeriesTabContent = ({ seriesList, measureId }) => {
	let { object, periodGroupId } = useFormValue();
	let evaluationName = object === "measure" ? "evaluationOption" : "_evaluationOption";
	let { set } = useOldQueryStore();
	let { setOnSubmit } = useFormContext();
	let evaluationOption = useFormValue(evaluationName);
	let translate = useTranslate();
	let { data: filteredSeriesList, setData: setSeriesList } = useFilter();
	let checklist = useChecklist();
	let objectIdList = checklist?.checkedObjectIdList;
	let scorecardId = useFormValue("scorecardId");

	let seriesListSortOrder = useFormValue("seriesSortOrder");
	let apiSeriesListSortOrder = useMemo(() => seriesList?.map((x) => x.measureSeriesId), [seriesList]);

	let sortedFlag = useMemo(
		() =>
			isEqual(seriesListSortOrder, apiSeriesListSortOrder) &&
			seriesListSortOrder?.every((x) => apiSeriesListSortOrder.includes(x)),
		[apiSeriesListSortOrder, seriesListSortOrder]
	);

	let onSubmit = useCallback(async () => {
		const measureSeriesData = {
			evaluationOption,
		};
		await set({ object: "measure", objectId: measureId, data: { ...measureSeriesData } });
	}, [evaluationOption, measureId, set]);
	useEffect(() => {
		if (object !== "measure") setOnSubmit({ name: "series", onSubmit });
	}, [object, onSubmit, setOnSubmit]);

	useEffect(() => {
		if (apiSeriesListSortOrder && !sortedFlag && seriesList?.length === seriesListSortOrder?.length) {
			set({
				object: "measure",
				objectId: measureId,
				data: { seriesSortOrder: seriesListSortOrder },
				skipClearFlag: true,
			}).then(() => {
				toast.success(translate("toaster.messages.global.sortOrderUpdated"));
			});
		}
	}, [apiSeriesListSortOrder, object, measureId, seriesList, seriesListSortOrder, set, sortedFlag, translate]);

	return (
		<>
			<Help feature="measure-series" />
			<EvaluationOptionSelect
				label={translate("edit.evaluationOption")}
				name={evaluationName}
				measureId={measureId}
			/>
			<FilterInput
				name="name"
				object="series"
				placeholder={`${translate("global.filter")} ${translate("series")}...`}
				filterBy="name"
				left={<CheckAll />}
				right={
					<>
						<ShowDeleted />
						<DropdownSplitAddButton
							showDropdownFlag
							ModalWindow={EditSeriesModal}
							scorecardId={scorecardId}
							measureId={measureId}
							marginLeft={theme.smallSpace}
						>
							<DropdownAddItem ModalWindow={EditSeriesModal} scorecardId={scorecardId} measureId={measureId}>
								{translate("global.add")} {translate("series")}
							</DropdownAddItem>
							<DropdownAddMultipleItem ModalWindow={EditMultipleMeasureSeriesModal} parentId={measureId} />
							<DropdownEditMultipleItem
								ModalWindow={EditMultipleMeasureSeriesModal}
								objectIdList={objectIdList}
								parentId={measureId}
							/>
							<DropdownTrashMultipleItem
								toast={translate("toaster.messages.series.multiSeriesDeleted", { count: objectIdList.length })}
								object="measureSeries"
								objectIdList={objectIdList}
								elementFlag
							/>
						</DropdownSplitAddButton>
					</>
				}
			/>
			<NoResults />
			<DragAndDropList
				name="seriesSortOrder"
				list={filteredSeriesList}
				listKey="measureSeriesId"
				setList={setSeriesList}
			>
				{filteredSeriesList.map((x) => (
					<SeriesListItem series={x} key={x.measureSeriesId} periodGroupId={periodGroupId} />
				))}
			</DragAndDropList>
		</>
	);
};
SeriesTabContent.propTypes = SeriesTabContentPropTypes;

let SeriesTab = () => {
	let object = useFormValue("object");
	let measureId = useFormValue(object === "measure" ? "measureId" : "shadowMeasureId");
	let { clear } = useOldQueryStore();

	let { get } = useOldQueryStore();
	let seriesList = measureId && get({ parent: "measure", parentId: measureId, object: "measureSeries" });

	useEffect(() => {
		return () => {
			clear({ object: "measureSeries" });
		};
	}, []);

	return (
		<FilterWrapper seriesList={seriesList} measureId={measureId}>
			<SeriesTabContent seriesList={seriesList} measureId={measureId} />
		</FilterWrapper>
	);
};

export default SeriesTab;
