import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormFieldDisplayTotalDays = ({ name }) => {
	let value = useFormValue(name);
	return <>{value}</>;
};

FormFieldDisplayTotalDays.propTypes = propTypes;
export default FormFieldDisplayTotalDays;
