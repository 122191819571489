import Block from "../../Block";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import ModalBody from "../../Modal/Modal.Body";
import ModalFooter from "../../Modal/Modal.Footer";
import ModalHeader from "../../Modal/Modal.Header";
import ModalTitle from "../../Modal/Modal.Title";
import StyleWrapper from "../../StyleWrapper";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import getDeepValue from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { sleep } from "@clearpoint/utils";
import { useMemoOnce } from "@clearpoint/hooks";


let propTypes = {
	children: PropTypes.node.isRequired,
	close: PropTypes.func.isRequired,
	closeButtonText: PropTypes.string,
	fixedHeightFlag: PropTypes.bool,
	footerLeftContent: PropTypes.node,
	modalVisible: PropTypes.bool.isRequired,
	name: PropTypes.string,
	nameList: PropTypes.arrayOf(PropTypes.string),
	onCancel: PropTypes.func,
	onSave: PropTypes.func,
	preserveEmptyValueFlag: PropTypes.bool,
	shouldSave: PropTypes.func,
	title: PropTypes.node,
	valid: PropTypes.bool,
};

let defaultProps = {
	valid: true,
};

let FormModal = ({
	beforeSave,
	children,
	close,
	closeButtonText,
	fixedHeightFlag,
	footerLeftContent,
	modalVisible,
	name,
	nameList,
	onCancel,
	onSave,
	preserveEmptyValueFlag,
	shouldSave,
	title,
	valid,
	...modalProps
}) => {
	nameList = useMemo(() => nameList ?? [name], [name, nameList]);
	let translate = useTranslate();
	let formValue = useFormValue();
	let { setFormValue } = useFormContext();

	let initialValue = useMemoOnce(() => {
		let lookup = {};
		nameList.forEach((name) => {
			let value = getDeepValue(formValue, name);
			let lookupValue = isObject(value) && isEmpty(value) && !preserveEmptyValueFlag ? undefined : cloneDeep(value);
			lookup[name] = lookupValue;
		});
		return lookup;
	});

	let restoreFormValueFromInitial = useCallback(() => {
		nameList.forEach((name) => {
			if (name.endsWith("]")) {
				// remove from array
				let index = +name.match(/\[(.+?)\]$/)?.[1];
				if (!isNaN(index) && !initialValue[name]) {
					let arrayName = name.replace(/\[.+?\]$/, "");
					let arrayValue = getDeepValue(formValue, arrayName);
					let newArrayValue = [...arrayValue.slice(0, index), ...arrayValue.slice(index + 1)];
					setFormValue(arrayName, newArrayValue);
				} else {
					setFormValue(name, initialValue[name]);
				}
			} else {
				setFormValue(name, initialValue[name]);
			}
		});
	}, [formValue, initialValue, nameList, setFormValue]);

	let cancel = useCallback(() => {
		restoreFormValueFromInitial();
		onCancel && onCancel();
		close();
	}, [close, onCancel, restoreFormValueFromInitial]);

	let onClose = useCallback(async () => {
		let saveFlag = shouldSave ? shouldSave() : true;
		if (saveFlag) {
			close();
			await sleep(100); // set values and save after unmount
			nameList.forEach((name) => {
				let value = getDeepValue(formValue, name);
				let valueCopy = cloneDeep(value);
				if (beforeSave) valueCopy = beforeSave(valueCopy) || valueCopy;
				setFormValue(name, valueCopy);
				setFormValue(name.replace("value", "displayValue"), valueCopy);
			});
			if (onSave) onSave();
		}
	}, [shouldSave, nameList, onSave, close, formValue, beforeSave, setFormValue]);

	return (
		<Modal modalVisible={modalVisible} close={cancel} {...modalProps}>
			<ModalHeader>
				<ModalTitle>{title}</ModalTitle>
			</ModalHeader>
			<StyleWrapper height={fixedHeightFlag ? "60vh" : undefined}>
				<ModalBody>{children}</ModalBody>
			</StyleWrapper>
			<ModalFooter>
				{footerLeftContent && (
					<Block marginRight="auto" $style="& > * { margin: 0.25rem }">
						{footerLeftContent}
					</Block>
				)}
				<Button onClick={cancel} color="default">
					{translate("global.cancel")}
				</Button>
				<Button data-testid="form-modal-close" color="primary" onClick={onClose} disabled={!valid}>
					{closeButtonText || translate("global.close")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

FormModal.defaultProps = defaultProps;
FormModal.propTypes = propTypes;

export default FormModal;
