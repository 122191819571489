import Loading from "../../../Loading/index";
import StyleWrapper from "../../../StyleWrapper";
import UserImageAndName from "../../../Form/shared/UserImageAndName";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	name: PropTypes.string,
};

let UserMultipickDisplay = ({ name }) => {
	let { get, isLoading } = useOldQueryStore();
	let selectedUserList = useFormValue(name);
	let userList = get({ object: "user" });
	userList = userList?.filter((x) => selectedUserList?.includes(x.userId));
	let loadingFlag = isLoading({ object: "user" });

	return loadingFlag ? (
		<Loading />
	) : userList ? (
		userList.map((user) => (
			<StyleWrapper key={user.userId} marginBottom={theme.tinySpace}>
				<UserImageAndName user={user} className="user-list-item" />
			</StyleWrapper>
		))
	) : null;
};

UserMultipickDisplay.propTypes = propTypes;
export default UserMultipickDisplay;
