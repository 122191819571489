/* eslint-disable complexity */
import { useMemo } from "react";
import PropTypes from "prop-types";

import { useBuildLink } from "@clearpoint/hooks";
import { emptyObject, truncateString } from "@clearpoint/utils";

import Block from "../../Block";
import Icon from "../../Icon/Icon";
import StyleWrapper from "../../StyleWrapper";
import { theme } from "../../Theme";
import includesObjectGenerator from "../utils/includesObjectGenerator";
import LinkChartExpandButton from "./LinkChartExpandButton";
import ObjectDisplay from "./ObjectDisplay";
import ObjectMenu from "./ObjectMenu";

let propTypes = {
	objectData: PropTypes.shape({
		name: PropTypes.string,
		object: PropTypes.string,
		scorecard: PropTypes.string,
		statusColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	translate: PropTypes.func,
	translateOnlyInsideCurlyBraces: PropTypes.func,
	windowContext: PropTypes.object,
	rootScorecardId: PropTypes.number,
	allowLoadingChildrenFlag: PropTypes.bool,
};

let LinkChartItem = ({
	objectData,
	translate,
	translateOnlyInsideCurlyBraces,
	windowContext,
	rootScorecardId,
	allowLoadingChildrenFlag,
}) => {
	const MAX_NAME_LENGTH = 50;
	const MAX_DOUBLENAME_LENGTH = 25;

	let buildLink = useBuildLink();

	let {
		_directSubordinates: childCount,
		ancestryId,
		categoryFlag,
		childrenLoadedFlag,
		completed,
		id,
		layoutId,
		linkedElementFlag,
		measure,
		name,
		noHeaderFlag,
		object,
		objectId,
		measureData,
		measureSeriesId,
		percentComplete,
		pageLink,
		parentId,
		rootFlag,
		score,
		scorecard,
		scorecardId,
		seriesType,
		statusColor,
	} = objectData || emptyObject;

	let displaySeries = measureData && measureData[0];
	let percentCompleteFlag = percentComplete !== undefined;

	let includesObject = useMemo(() => includesObjectGenerator(object), [object]);

	let showSeriesPreviewButtonFlag = measure && measureSeriesId && seriesType === "calculated";
	let showChildCountFlag = childCount !== undefined && categoryFlag && childCount > 0;
	let showCompletedBadgeFlag = (completed && percentCompleteFlag) || percentComplete === 100;
	let showPercentPieFlag = includesObject(["initiative", "goal", "user", "department"]) && percentCompleteFlag;
	let showScoreBadgeFlag = includesObject(["objective"]) && score !== undefined;
	let showSeriesFlag = includesObject(["measure"]) && !!displaySeries;
	let showObjectDataFlag =
		showSeriesFlag ||
		showScoreBadgeFlag ||
		showPercentPieFlag ||
		showCompletedBadgeFlag ||
		showChildCountFlag ||
		showSeriesPreviewButtonFlag;

	let showExpandButtonFlag =
		allowLoadingChildrenFlag && !childrenLoadedFlag && !measure ? linkedElementFlag && parentId : false;
	let showElementLinkFlag = !rootFlag && (objectId || layoutId || (object && categoryFlag)) && scorecardId;
	let goalFlag = object === "goal";

	let hideStatusBarFlagList = [noHeaderFlag, categoryFlag, layoutId, object === "map"];
	let statusBarColor = hideStatusBarFlagList.some((x) => !!x) ? "transparent" : statusColor || "#DDDDDD";
	let linkSource = object === "scorecard" ? objectData?.home || objectData : objectData;
	let elementLink = showElementLinkFlag ? buildLink(linkSource, pageLink) : undefined;
	let nameFormatted = truncateString(name, measure ? MAX_DOUBLENAME_LENGTH : MAX_NAME_LENGTH);

	let newTabProps = windowContext ? { target: "_blank", rel: "noopener noreferrer" } : emptyObject;
	let dataProps = { "data-object": object, "data-objectid": id, "data-name": name };

	if (!objectData) return null;

	return (
		<>
			<Block
				height={theme.largeSpace}
				background={statusBarColor}
				borderRadius="4px 4px 0 0"
				position="relative"
				marginBottom={`-${theme.space}`}
				flexShrink="0"
			>
				<Block
					display="flex"
					alignItems="center"
					background="white"
					borderRadius="100%"
					border="1px solid lightgray"
					height={theme.largeSpace}
					marginTop={`${theme.space}`}
					justifyContent="center"
					left="50%"
					transform="translate(-50%,-50%)"
					position="absolute"
					width={theme.largeSpace}
					fontSize="15px"
					zIndex="2022"
				>
					<Icon name={object || "home"} />
				</Block>
			</Block>
			<Block
				display="flex"
				justifyContent="center"
				flexDirection="column"
				border="1px solid lightgray"
				height={`calc(100% - ${theme.space})`}
				position="relative"
				zIndex="2021"
				borderRadius={theme.tinySpace}
				fontFamily="Roboto"
				boxShadow="1px 1px 9px -6px rgb(0 0 0 / 50%)"
				textAlign="center"
				background={theme.accordionBackground}
				cursor="default"
				paddingTop={theme.space}
			>
				{measure && (
					<Block fontWeight="800" fontSize="13px" marginBottom={theme.tinySpace} padding={`0 ${theme.tinySpace}`}>
						{truncateString(measure, MAX_DOUBLENAME_LENGTH)}
					</Block>
				)}

				<Block
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					height="65%"
					marginTop="-5%"
				>
					<StyleWrapper
						fontWeight="800"
						fontSize="15px"
						marginBottom={theme.tinySapace}
						padding={`0 ${theme.tinySpace}`}
						color={!elementLink || !goalFlag ? "inherit" : undefined}
						cursor={goalFlag ? "pointer" : undefined}
						zIndex="2025"
					>
						<a {...newTabProps} href={elementLink}>
							<div {...dataProps} id={goalFlag ? "goalEditLink" : null}>
								{translateOnlyInsideCurlyBraces(nameFormatted)}
							</div>
						</a>

						{rootScorecardId && rootScorecardId !== scorecardId && scorecard}
					</StyleWrapper>
					{object && showObjectDataFlag && (
						<ObjectDisplay
							showChildCountFlag={showChildCountFlag}
							showCompletedBadgeFlag={showCompletedBadgeFlag}
							showSeriesFlag={showSeriesFlag}
							showSeriesPreviewButtonFlag={showSeriesPreviewButtonFlag}
							showPercentPieFlag={showPercentPieFlag}
							showScoreBadgeFlag={showScoreBadgeFlag}
							objectData={objectData}
							translate={translate}
						/>
					)}
				</Block>
				{object && <ObjectMenu objectData={objectData} translate={translate} />}
			</Block>

			{showExpandButtonFlag && (
				<StyleWrapper
					height="0px"
					background={statusBarColor}
					borderRadius="4px 4px 0 0"
					position="relative"
					marginTop="-13px"
					zIndex="2030"
					marginBottom={theme.smallSpace}
					flexShrink="0"
					left="50%"
					transform="translate(-50%,-50%)"
				>
					{
						//This specific component architecture is required for the expanding link chart to function properly, do not remove
					}
					<div
						id="linkChartExpandBtn"
						data-id={id}
						data-object={object}
						data-objectid={objectId}
						data-ancestryid={ancestryId}
					>
						<LinkChartExpandButton />
					</div>
				</StyleWrapper>
			)}
		</>
	);
};

LinkChartItem.propTypes = propTypes;
export default LinkChartItem;
