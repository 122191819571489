import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import { capitalizeFirstLetter } from "@clearpoint/utils";
import { useDistrictName } from "@clearpoint/hooks";



let useAIContextPayload = ({ fieldId, outerFormObject, periodId }) => {
	let { scorecard, name, objectId } = useFormValue();
	let objectStatus = useFormValue("updates");
	let customFieldKeys = Object.keys(objectStatus).filter((x) => x.startsWith("custom"));
	let customFields = {};
	customFieldKeys.forEach((key) => {
		customFields[key] = objectStatus[key];
	});
	let newStatus = cloneDeep(objectStatus);
	newStatus.customFields = customFields;
	newStatus = omit(newStatus, customFieldKeys);
	newStatus.value = name;
	let districtName = useDistrictName();
	return useMemo(
		() =>
			fieldId === "milestones" || fieldId === "recommendations"
				? {
						analysis: objectStatus?.analysis,
						company: districtName,
						content: objectStatus?.[fieldId],
						elementType: capitalizeFirstLetter(outerFormObject),
						endDate: objectStatus?.endDate,
						fieldId,
						name: objectStatus?.name,
						object: outerFormObject,
						objectId,
						periodId,
						recommendations: objectStatus?.recommendations,
						scorecard,
						scorecardId: objectStatus?.scorecardId,
						status: newStatus,
						startDate: objectStatus?.startDate,
						topic: name,
				  }
				: {
						company: districtName,
						content: objectStatus?.[fieldId],
						elementType: capitalizeFirstLetter(outerFormObject),
						fieldId,
						object: outerFormObject,
						objectId,
						periodId,
						topic: name,
						scorecard,
						status: objectStatus,
						value: name,
				  },
		[districtName, fieldId, name, newStatus, objectId, objectStatus, outerFormObject, periodId, scorecard]
	);
};

export default useAIContextPayload;
