import Block from "../Block";
import Loading from "../Loading";
import StyleWrapper from "../StyleWrapper";
import { usePagination } from "@clearpoint/providers/PaginationProvider";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useEffectOnce, useId } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.node,
};

let PageContainer = ({ children }) => {
	let { setParentQuery, setParentWidthQuery, data } = usePagination();
	let id = useId();
	useEffectOnce(() => {
		setParentQuery((x) => (x === undefined ? ".page-container" + id : x));
		setParentWidthQuery((x) => (x === undefined ? ".page-width" + id : x));
	});
	return (
		<Block className={"page-container" + id} flex={1} marginBottom={theme.mediumLargeSpace} position="relative">
			<StyleWrapper className={"page-width" + id} overflow="visible">
				{children}
			</StyleWrapper>
			{data._loading && (
				<Block position="absolute" top="0px" left="0px" right="0px" bottom="0px">
					<Loading />
				</Block>
			)}
		</Block>
	);
};

PageContainer.propTypes = propTypes;

export default PageContainer;
