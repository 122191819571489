import Block from "@clearpoint/old-theme/Block";
import DataTypeSelect from "./DataTypeSelect";
import { Draggable } from "react-smooth-dnd";
import FormSlider from "@clearpoint/old-theme/Form/Input/Input.Slider";
import Header from "./ListItem.KeyResult.Header";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";
import Pod from "@clearpoint/old-theme/Pod/Pod";
import StartAndTargetValue from "./StartAndTargetValue";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormatKeyResultNumber from "./useFormatKeyResultNumber";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";
import { useEffectOnce, useWindowSize } from "@clearpoint/hooks";



let propTypes = {
	index: PropTypes.number,
	visible: PropTypes.bool,
};

let KeyResultListItem = ({ index, visible }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { keyResultsDirtyFlag } = useLocalState() || emptyObject;

	let keyResultNameInForm = `keyResults[${index}]`;
	let keyResultValueInForm = useFormValue(keyResultNameInForm);
	let { active: activeFlag, startValue, targetValue } = keyResultValueInForm || emptyObject;

	let { width: windowWidth } = useWindowSize();
	let showSliderFlag = windowWidth > 930;

	let formatKeyResultNumber = useFormatKeyResultNumber(keyResultNameInForm);

	useEffectOnce(() => {
		if (keyResultValueInForm) {
			let { startValue, targetValue } = keyResultValueInForm;
			if (startValue < 0) {
				setFormValue(`${keyResultNameInForm}.startValue`, Math.abs(startValue));
			}
			if (targetValue < 0) {
				setFormValue(`${keyResultNameInForm}.targetValue`, Math.abs(targetValue));
			}
		}
	});

	return (
		<Draggable>
			<Block marginBottom={theme.space} display={visible ? "block" : "none"}>
				<LocalStateProvider
					keyResultsDirtyFlag={keyResultsDirtyFlag}
					keyResultNameInForm={keyResultNameInForm}
					index={index}
				>
					<Pod CustomHeader={Header}>
						<DataTypeSelect
							name={`${keyResultNameInForm}.dataTypeId`}
							keyResultNameInForm={keyResultNameInForm}
						/>
						<StartAndTargetValue keyResultNameInForm={keyResultNameInForm} />
						{activeFlag && (
							<FormSlider
								name={`${keyResultNameInForm}.currentValue`}
								sliderHeight="14px"
								hideThumbValueFlag
								valueLabelPosition="top"
								inputPosition="right"
								label={translate("goals.keyResults.currentValue")}
								inputOnlyFlag={!showSliderFlag}
								max={targetValue ?? 0}
								min={startValue ?? 0}
								formatNumber={formatKeyResultNumber}
								step={1}
								errorTextNotVisible
							/>
						)}
					</Pod>
				</LocalStateProvider>
			</Block>
		</Draggable>
	);
};

KeyResultListItem.propTypes = propTypes;
export default KeyResultListItem;
