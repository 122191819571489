import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Text from "@clearpoint/old-theme/Text/Text";

import { useScorecardName } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { pluralize } from "@clearpoint/utils";

import ChecklistCheckAll from "../../../Checklist/Checklist.Check.All";
import FilterInput from "../../../Filter/Filter.Input";
import FormCheckArray from "../../../Form/CheckArray/CheckArray";
import FormCheckArrayItem from "../../../Form/CheckArray/CheckArray.Item";
import FormGroup from "../../../Form/Form.Group";
import useFormValue from "../../../Form/hooks/useFormValue";
import ChecklistProvider from "@clearpoint/providers/ChecklistProvider";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";

let ElementChecklistContentPropTypes = {
	loadingFlag: PropTypes.bool,
	linkType: PropTypes.string,
	name: PropTypes.string,
	hideCheckAllFlag: PropTypes.bool,
};

const Delayed = ({ children, waitBeforeShow = 500 }) => {
	const [isShown, setIsShown] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShown(true);
		}, waitBeforeShow);
		return () => clearTimeout(timer);
	}, [waitBeforeShow]);

	return isShown ? children : null;
};

let HiddenFormCheckArrayItem = styled(FormCheckArrayItem)`
	display: none;
`;

let ElementChecklistContent = ({ loadingFlag, linkType: linkTypeProp, name, hideCheckAllFlag }) => {
	let translate = useTranslate();
	let { data: filteredElementList } = useFilter();
	let scorecardId = useFormValue("_modal.scorecardId");
	let linkType = useFormValue("_modal.linkType");
	linkType = linkTypeProp || linkType;
	let objectId = useFormValue("objectId");
	let linkList = useFormValue(name);
	let linkObjectIdList = useFormValue("_modal.linkObjectIdList");
	let scorecardName = useScorecardName({ scorecardId });
	let elementsText = linkType ? translate(pluralize(linkType)) : undefined;
	let isDisabled = useCallback(
		(object, objectId) =>
			(linkList || []).some(
				(x) =>
					x.fieldId?.startsWith("child") &&
					x.fieldId !== "children" &&
					x.objectId === objectId &&
					x.object === object
			),
		[linkList]
	);

	let listItems = filteredElementList
		?.filter(
			(x) =>
				x.visible &&
				(!x.scorecardId || x.scorecardId === scorecardId) &&
				x.objectId !== objectId &&
				!(
					linkType === "child" &&
					linkObjectIdList?.some(
						({ linkType, object, objectId }) =>
							linkType === "parent" && object === x.object && objectId === x.objectId
					)
				) &&
				!(
					linkType === "parent" &&
					linkObjectIdList?.some(
						({ linkType, object, objectId }) =>
							linkType === "child" && object === x.object && objectId === x.objectId
					)
				)
		)
		.map((x) => (
			<FormCheckArrayItem
				key={`${linkType},${scorecardId},${x.object},${x.objectId}`}
				text={translate(x.name)}
				value={{
					disabled: isDisabled(x.object, x.objectId),
					linkType,
					object: x.object,
					objectId: x.objectId,
					scorecardId,
				}}
				checklistFlag
				disabled={isDisabled(x.object, x.objectId)}
			/>
		));

	let hiddenListItems = useMemo(() => {
		let hiddenElements = linkObjectIdList
			? linkObjectIdList.filter((x) => {
					return x.scorecardId !== scorecardId;
			  })
			: [];
		return hiddenElements.map((x) => {
			return (
				<HiddenFormCheckArrayItem
					key={`${x.linkType},${x.scorecardId},${x.object},${x.objectId}`}
					value={{
						disabled: x.disabled,
						linkType: x.linkType,
						object: x.object,
						objectId: x.objectId,
						scorecardId: x.scorecardId,
					}}
					hiddenFlag={true}
					defaultCheckedFlag={true}
					checklistFlag
					disabled={x.disabled}
				/>
			);
		});
	}, [scorecardId, linkObjectIdList]);

	if (!linkType || !scorecardId) return null;

	if (loadingFlag)
		return (
			<FormGroup title={scorecardName} marginTop="0px" marginBottom={"20px"} paddingBottom={"20px"}>
				<Delayed waitBeforeShow={200}>
					<Text align={"center"}>{translate("global.loading")}</Text>
				</Delayed>
			</FormGroup>
		);

	return (
		<>
			<FilterInput
				name="linkElementModal"
				filterBy="name"
				scorecardId={scorecardId}
				left={!hideCheckAllFlag && <ChecklistCheckAll disableWhenListIsEmptyFlag={true} />}
				object={linkType}
				placeholder={translate("manage.filterElements", {
					elements: elementsText,
				})}
			/>
			{listItems?.length === 0 ? (
				<FormGroup title={scorecardName} marginTop="0px" marginBottom={"20px"} paddingBottom={"20px"}>
					<Delayed>
						<Text align={"center"}>{translate("select.noElementsFound")}</Text>
					</Delayed>
				</FormGroup>
			) : (
				<FormGroup title={scorecardName} marginTop="0px" marginBottom={"20px"}>
					<FormCheckArray name="_modal.linkObjectIdList">
						{listItems}
						{hiddenListItems}
					</FormCheckArray>
				</FormGroup>
			)}
		</>
	);
};

let propTypes = {
	elementList: PropTypes.array,
	linkType: PropTypes.string,
	loadingFlag: PropTypes.bool,
	name: PropTypes.string,
	closeClicked: PropTypes.bool,
	handleClick: PropTypes.func,
};
let ElementChecklist = ({ elementList, linkType, loadingFlag, name, closeClicked, handleClick }) => (
	<FilterProvider data={elementList}>
		<ChecklistProvider>
			<ElementChecklistContent
				linkType={linkType}
				loadingFlag={loadingFlag}
				name={name}
				closeClicked={closeClicked}
				handleClick={handleClick}
			/>
		</ChecklistProvider>
	</FilterProvider>
);

ElementChecklist.propTypes = propTypes;
ElementChecklistContent.propTypes = ElementChecklistContentPropTypes;
export default ElementChecklist;
