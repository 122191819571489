let removePureInterpolationEntries = (scorecardLanguageData) => {
	for (let [key, value] of Object.entries(scorecardLanguageData)) {
		let newValue = value.toString().replace("{{", "").replace("}}", "");
		if (key === newValue) {
			delete scorecardLanguageData[key];
		}
	}
};

export default removePureInterpolationEntries;
