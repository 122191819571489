import isArray from "lodash/isArray";

let noResultsFlagCallback = ({ results, allResultsFilteredFlag, filteredResultList, deletedVisible }) => {
	if (results?.total === 0) {
		return true;
	}

	if (allResultsFilteredFlag) {
		return true;
	}

	let resultList = [results, filteredResultList].find((x) => isArray(x));
	if (resultList) {
		return (
			resultList.filter((x) => x && (x.active || !Object.keys(x).includes("active") || deletedVisible)).length === 0
		);
	}

	return false;
};

export default noResultsFlagCallback;
