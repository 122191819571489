let configurePlotOptions = (chartData) => {
	let {
		chartFormat: {
			chart: { type },
			plotOptions,
		},
	} = chartData;

	let incomingPlotOptions = plotOptions || {};
	let incomingSeriesPlotOptions = plotOptions?.series || {};

	switch (type) {
		case "pie": {
			plotOptions = {
				...incomingPlotOptions,
				pie: plotOptions?.pie || {},
				series: {
					...incomingSeriesPlotOptions,
					animation: false,
				},
			};

			break;
		}
		case "gauge":
			plotOptions = {
				...incomingPlotOptions,
				gauge: plotOptions?.gauge ?? {},
				series: { ...incomingSeriesPlotOptions, animation: false },
			};
			break;
		case "solidgauge": {
			plotOptions = {
				...incomingPlotOptions,
				solidgauge: plotOptions?.solidgauge ?? {},
				series: { ...incomingSeriesPlotOptions, animation: false },
			};
			break;
		}
		case "polar": {
			plotOptions = {
				...incomingPlotOptions,
				series: { ...incomingSeriesPlotOptions, animation: false },
			};

			break;
		}
		// No default
	}

	return plotOptions || {};
};

export default configurePlotOptions;
