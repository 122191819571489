import useCheckFeature from "./useCheckFeature";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

let useEvaluationEnabledLookup = () => {
	let { get } = useOldQueryStore();
	let { evaluations: evaluationOptionList } = get({ object: "options" })?.options || emptyObject;

	let checkFeature = useCheckFeature();
	let enterpriseFlag = checkFeature("initiativeEvaluation");

	return useMemo(() => {
		let lookup = {
			measure: true,
			actionItem: false,
			initiative: false,
			milestone: false,
			risk: false,
		};
		["actionItem", "initiative", "milestone", "risk"].forEach((object) => {
			lookup[object] = enterpriseFlag && !!evaluationOptionList?.find((x) => x.object === object)?.active;
		});

		return lookup;
	}, [enterpriseFlag, evaluationOptionList]);
};

export default useEvaluationEnabledLookup;
