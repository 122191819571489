import SelectLayout from "./Select.Layout";
import { useTranslate } from "@clearpoint/translate";

let SelectObjectiveLayout = (props) => {
	let translate = useTranslate();
	return (
		<SelectLayout label={`${translate("objective")} ${translate("global.report")}`} object="objective" {...props} />
	);
};

export default SelectObjectiveLayout;
