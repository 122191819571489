let isSchedulePage = (x) => {
	return x.page === "schedules";
};

let isIntegrationsPage = (x) => {
	return (
		(x.page === "integrations" && ["data-loader", "clearpoint-api", "zapier"].includes(x.view)) ||
		x.page === "measure-library"
	);
};

let isScriptingPage = (x) => {
	return x.page === "integrations" && ["scripting", "bulk-changes"].includes(x.view);
};

export { isIntegrationsPage, isSchedulePage, isScriptingPage };
