import Block from "../../../Block";
import FormGroup from "../../../Form/Form.Group";
import Icon from "../../../Icon/Icon";
import ListItem from "../../../ListItem/ListItem";
import PeriodBadge from "../../../Badge/Badge.Period";
import StatusIcon from "../../../StatusIcon";
import StyleWrapper from "../../../StyleWrapper";
import Text from "../../../Text/Text";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";
import { useBuildLink } from "@clearpoint/hooks";

let elementListPropTypes = {
	elementList: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			object: PropTypes.string,
			periodId: PropTypes.number,
			scorecard: PropTypes.string,
			statusId: PropTypes.number,
			type: PropTypes.string,
		})
	),
};

let ElementList = ({ elementList }) => {
	let buildLink = useBuildLink();
	let { excelFlag, exportFlag } = useOldSession().session;
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();

	return (
		<>
			{elementList.map((element, i) => {
				let { name, object, periodId, scorecard, statusId, type, layoutId, scorecardId, objectId } = element;
				let showStatusFlag = type === "element";
				let showPeriodBadgeFlag = type !== "layout" && !exportFlag;
				let iconName = type === "map" ? type : object;
				layoutId = type === "layout" ? layoutId || objectId : undefined;
				objectId = type === "layout" ? undefined : objectId;
				return excelFlag ? (
					<p key={i}>{"\n" + name}</p>
				) : (
					<StyleWrapper
						key={i}
						flexWrap="nowrap !important"
						$style={`.list-item-right-child {flex-shrink: 0 !important}`}
					>
						<ListItem
							size="small"
							left={
								<Block display="flex" alignItems="center" ellipsisFlag lineHeight={1.1}>
									<Icon name={iconName} size="large" marginRight={theme.smallSpace} />
									{showStatusFlag && <StatusIcon statusId={statusId} />}
									<Block
										ellipsisFlag
										$style="&&& { white-space: nowrap !important; } &&& * { vertical-align: unset; }"
									>
										<a href={buildLink({ scorecardId, object, objectId, layoutId, periodId })}>
											{translateOnlyInsideCurlyBraces(name)}
										</a>
										<br />
										<Text fontWeight="normal">
											<small>{scorecard}</small>
										</Text>
									</Block>
								</Block>
							}
							right={showPeriodBadgeFlag && <PeriodBadge periodId={periodId} marginLeft="0px !important" />}
						/>
					</StyleWrapper>
				);
			})}
		</>
	);
};

let scorecardListItemPropTypes = {
	children: PropTypes.node,
	detailPageFlag: PropTypes.bool,
	scorecard: PropTypes.shape({
		name: PropTypes.string,
	}),
};

let ScorecardListItem = ({ children, detailPageFlag, scorecard }) => {
	let { name } = scorecard;
	return detailPageFlag ? (
		<FormGroup title={name}>{children}</FormGroup>
	) : (
		<>
			<StyleWrapper borderBottom={`1px solid ${theme.gray}`}>
				<legend>{name}</legend>
			</StyleWrapper>
			{children}
		</>
	);
};

let propTypes = {
	detailPageFlag: PropTypes.bool,
	periodId: PropTypes.number,
};

let MyFavoritesDisplay = ({ detailPageFlag, periodId }) => {
	let { get } = useOldQueryStore();
	let { excelFlag } = useOldSession().session;
	let profile = get({ object: "profile" });
	let myFavorites = get({
		object: "myFavorite",
		parent: "user",
		parentId: profile?.userId,
		queryString: `?periodId=${periodId}`,
	});
	return (
		myFavorites?.map((scorecard, i) => (
			<ScorecardListItem key={i} detailPageFlag={detailPageFlag} scorecard={scorecard}>
				{excelFlag && "\n--------------------"}
				<ElementList elementList={scorecard.elements} />
				{excelFlag && i < myFavorites.length - 1 && "\n\n"}
			</ScorecardListItem>
		)) || null
	);
};

MyFavoritesDisplay.propTypes = propTypes;
ElementList.propTypes = elementListPropTypes;
ScorecardListItem.propTypes = scorecardListItemPropTypes;
export default MyFavoritesDisplay;
