import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useRef, useTransition } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { useCurrentRef, usePrevious, useStateObject } from "@clearpoint/hooks";
import { emptyObject } from "@clearpoint/utils";

import getFilter from "./FilterProvider.getFilter";
import getSetData from "./FilterProvider.getSetData";

let FilterContext = createContext();

let propTypes = {
	children: PropTypes.node,
	data: PropTypes.array,
	transitionOnStateUpdateFlag: PropTypes.bool,
	visibleKey: PropTypes.string,
};

let defaultProps = {
	data: [],
	visibleKey: "visible",
};

let FilterProvider = ({ children, data, transitionOnStateUpdateFlag, visibleKey }) => {
	let unfilteredDataRef = useRef(data);
	let previousData = usePrevious(data);

	let [
		{
			dataState,
			triggerFilter,
			archivedVisible,
			completedHidden,
			noAccessVisible,
			deletedVisible,
			disabledVisible,
			filterInputValue,
			filterSelectValue,
			filterInputFlag,
		},
		setState,
	] = useStateObject({
		dataState: data || [],
		triggerFilter: false,
		archivedVisible: false,
		noAccessVisible: false,
		completedHidden: false,
		deletedVisible: false,
		disabledVisible: false,
		filterInputFlag: false,
		filterInputValue: "",
		filterSelectValue: null,
		setInput: {},
	});
	let dataStateRef = useCurrentRef(dataState);
	let filterInputFlagRef = useCurrentRef(filterInputFlag);
	let [filterTransitionFlag, startFilterTransition] = useTransition();
	let filterList = useRef({});
	let filter = useCallback(
		(x, data) =>
			getFilter({
				data,
				dataState,
				filterList,
				setState,
				startFilterTransition,
				transitionOnStateUpdateFlag,
				unfilteredDataRef,
				visibleKey,
				x,
			}),
		[dataState, setState, transitionOnStateUpdateFlag, visibleKey]
	);

	let setData = useCallback(
		(x, options = emptyObject) => {
			getSetData({ dataState, options, setState, visibleKey, x });
		},
		[dataState, setState, visibleKey]
	);

	let setArchivedVisible = useCallback(
		(x) => {
			setState({
				archivedVisible: x,
			});
		},
		[setState]
	);

	let setNoAccessVisible = useCallback(
		(x) => {
			setState({ noAccessVisible: x });
		},
		[setState]
	);

	let setCompletedHidden = useCallback(
		(x) => {
			setState({
				completedHidden: x,
			});
		},
		[setState]
	);

	let setDeletedVisible = useCallback(
		(x) => {
			setState({
				deletedVisible: x,
			});
		},
		[setState]
	);
	let setDisabledVisible = useCallback(
		(x) => {
			setState({
				disabledVisible: x,
			});
		},
		[setState]
	);
	let setFilterInputFlag = useCallback(
		(x) => {
			setState({
				filterInputFlag: x,
			});
		},
		[setState]
	);
	let setFilterInputValue = useCallback(
		(x) => {
			setState({
				filterInputValue: x,
			});
		},
		[setState]
	);
	let setFilterSelectValue = useCallback(
		(x) => {
			setState({
				filterSelectValue: x,
			});
		},
		[setState]
	);
	useEffect(() => {
		if (data?.length !== undefined && !isEqual(data, previousData)) {
			filter({}, data);
		}
	}, [data, dataState, filter, previousData, setState, visibleKey]);
	useLayoutEffect(() => {
		if (triggerFilter) {
			filter({});
			setState({
				triggerFilter: false,
			});
		}
	}, [dataState, filter, setState, triggerFilter]);
	return (
		<FilterContext.Provider
			value={{
				data: dataState,
				dataRef: dataStateRef,
				filter,
				setData,
				archivedVisible,
				setArchivedVisible,
				noAccessVisible,
				setNoAccessVisible,
				completedHidden,
				setCompletedHidden,
				deletedVisible,
				setDeletedVisible,
				disabledVisible,
				setDisabledVisible,
				filterInputFlag,
				filterInputFlagRef,
				filterInputValue,
				setFilterInputFlag,
				setFilterInputValue,
				filterSelectValue,
				setFilterSelectValue,
				filterTransitionFlag: transitionOnStateUpdateFlag ? filterTransitionFlag : false,
				startFilterTransition,
			}}
		>
			{children}
		</FilterContext.Provider>
	);
};
FilterProvider.propTypes = propTypes;
FilterProvider.defaultProps = defaultProps;

export default FilterProvider;
export const useFilter = () => useContext(FilterContext);
