import { useMainStore } from "@clearpoint/store";
import ViewMultiple from "./ViewMultiple";
import ViewSingle from "./ViewSingle";
import { Profiler } from "react";

let StoreTest = () => {
	// any state change should be using an action defined in the store.
	let toggleSideNavigationVisible = useMainStore((state) => state.toggleSideNavigationVisible);
	let setDashboardChartCount = useMainStore((state) => state.setDashboardChartCount);
	let toggleView = useMainStore((state) => state.toggleView);

	// avoid the following anti-pattern, which would hide visibility of store updates.
	// let { setState } = useMainStore();
	// let toggleSideNavigationVisible = setState({toggleSideNavigationVisible: x => !x})

	// watch the console to see rerender information.
	return (
		<div style={{ margin: "20px" }}>
			<div>Watch the console to see rerender information.</div>
			<br />
			<button onClick={toggleSideNavigationVisible}>Toggle side menu</button>
			<br />
			<br />
			<div>Set Dashboard Chart Count: </div>
			<input placeholder="chart count" onChange={(e) => setDashboardChartCount(e.target.value)} />
			<br />
			<br />
			<button onClick={toggleView}>Toggle View</button>
			<br />
			<br />
			<Profiler
				onRender={(id, phase, actualDuration, baseDuration, startTime, commitTime) =>
					console.log(
						"View Multiple just rendered...\n" +
							JSON.stringify({ id, phase, actualDuration, baseDuration, startTime, commitTime }, 0, 3)
					)
				}
			>
				<ViewMultiple />
			</Profiler>
			<br />
			{["dashboardChartCount", "view"].map((storeKey) => (
				<Profiler
					onRender={(id, phase, actualDuration, baseDuration, startTime, commitTime) =>
						console.log(
							`View ${storeKey} just rendered...\n` +
								JSON.stringify({ id, phase, actualDuration, baseDuration, startTime, commitTime }, 0, 3)
						)
					}
				>
					<ViewSingle storeKey={storeKey} />
					<br />
				</Profiler>
			))}
		</div>
	);
};

export default StoreTest;
