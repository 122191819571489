import FormImage from "../../Form/Image/Form.Image";
import InputColor from "../../Form/Input/Color/FormInputColor";
import Select from "../../Form/Select/FormSelect";
import StyleWrapper from "../../StyleWrapper";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";
import { useEffectOnce } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
	parentName: PropTypes.string,
};
let Background = ({ name, parentName, ...props }) => {
	let translate = useTranslate();
	let backgroundOptions = useMemo(
		() => [
			{ label: translate("edit.map.setColor"), value: "color" },
			{ label: translate("edit.map.uploadImage"), value: "image" },
		],
		[translate]
	);
	let { clearFormValue, setFormValue, restoreFormValue } = useFormContext();
	let backgroundType = useFormValue("_backgroundType");
	let background = useFormValue("background");
	let appendParent = useCallback((name) => (parentName ? `${parentName}.name` : name), [parentName]);
	useEffectOnce(() => {
		setFormValue("_backgroundType", background ? "image" : "color");
	});
	let updateBackground = useCallback(
		(e) => {
			if (!e) return;
			if (e.value === "color") {
				clearFormValue("_binary.background");
				clearFormValue("background");
			} else {
				restoreFormValue("_binary.background");
				restoreFormValue("background");
			}
		},
		[clearFormValue, restoreFormValue]
	);
	return (
		<>
			<Select
				data-testid="background"
				options={backgroundOptions}
				{...props}
				name="_backgroundType"
				onChange={updateBackground}
			/>
			{backgroundType === "color" && (
				<InputColor name={appendParent("backgroundColor")} defaultValue={theme.lightGray5} />
			)}
			{backgroundType === "image" && (
				<StyleWrapper marginBottom={theme.space}>
					<FormImage binaryFlag name={name}>
						{translate("map.dragImage")}
					</FormImage>
				</StyleWrapper>
			)}
		</>
	);
};
Background.propTypes = propTypes;

export default Background;
