import { useCallback, useContext, useMemo, createContext } from "react";
import PropTypes from "prop-types";
import { useStateObject } from "@clearpoint/hooks";

let TabContext = createContext();

let propTypes = {
	children: PropTypes.node,
	noFormFlag: PropTypes.bool,
};
let WindowTabProvider = ({ children, noFormFlag }) => {
	let [{ selectedTab, selectedTabList, nextTab }, setState] = useStateObject({
		selectedTab: 0,
		selectedTabList: [0],
		nextTab: 0,
	});
	let setSelectedTab = useCallback(
		(x) => {
			setState({
				selectedTab: x,
				selectedTabList: (previousSelectedTabList) =>
					previousSelectedTabList.includes(x) ? previousSelectedTabList : [...previousSelectedTabList, x],
			});
			return true;
		},
		[setState]
	);
	let setNextTab = useCallback((x) => setState({ nextTab: x }), [setState]);
	let selectNextTab = useCallback(() => setSelectedTab(nextTab), [nextTab, setSelectedTab]);
	let tabContext = useMemo(
		() => ({ noFormFlag, selectedTab, selectedTabList, setSelectedTab, setNextTab, selectNextTab }),
		[noFormFlag, selectedTab, selectedTabList, setSelectedTab, setNextTab, selectNextTab]
	);
	return <TabContext.Provider value={tabContext}>{children}</TabContext.Provider>;
};
WindowTabProvider.propTypes = propTypes;
export default WindowTabProvider;
export const useWindowTabContext = () => useContext(TabContext);
