import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { theme } from "@clearpoint/old-theme/Theme";

import { useDisableLoadingModalSave, useStateObject, useUserType } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { splitEditAndUpdateFieldData } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import CompletedItemsTab from "./CompletedItemsTab";
import EditCategoriesTab from "./EditCategoriesTab";
import EditFieldsTab from "./EditFieldsTab";
import OptionsTab from "./OptionsTab";
import UpdateFieldsTab from "./UpdateFieldsTab";
import UserAccessTab from "./UserAccessTab";
import { useOldSession } from "@clearpoint/old-session/index";

let editScorecardModalContentPropTypes = {
	addFlag: PropTypes.bool,
	loadingFlag: PropTypes.bool,
};

let EditScorecardModalContent = ({ addFlag, loadingFlag }) => {
	let translate = useTranslate();
	let { clear } = useOldQueryStore();
	let { name, objectId } = useFormValue();
	let { newObjectFlag } = useFormContext();
	let period = useFormValue("updates.period");
	let periodLockedFlag = useFormValue("updates.periodLocked");
	let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
		saveModalVisible: false,
		savedFlag: false,
	});
	let userType = useUserType();
	let onSave = useCallback(() => setState({ savedFlag: true }), [setState]);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);
	let openSaveModal = useCallback(
		(selectTab) => {
			if (newObjectFlag) {
				setState({ saveModalVisible: true });
			} else {
				selectTab();
			}
		},
		[newObjectFlag, setState]
	);
	useUnmount(() => {
		if (savedFlag) clear({ object: "scorecard", objectId });
	});
	let adminFlag = userType === "Administrator";
	let updateFieldsTabTitle = useMemo(
		() =>
			periodLockedFlag ? (
				<>
					<Icon name="locked" size="tiny" marginRight={theme.smallSpace} />
					{`${period} ${translate("global.locked")}`}
				</>
			) : (
				translate("edit.element.updateFields")
			),
		[period, periodLockedFlag, translate]
	);

	useDisableLoadingModalSave(loadingFlag);

	return (
		<>
			<ModalWindowHeader
				badgeIcon="scorecard"
				badgeText={translate("scorecard")}
				title={addFlag ? translate("edit.addElement", { element: "scorecard" }) : name}
			/>
			<ModalWindowContent data-testid="edit-scorecard-modal">
				<ModalWindowTabContainer>
					<EditFieldsTab
						addFlag
						title={translate("edit.addElement", { element: "scorecard" })}
						visible={addFlag}
					/>
					<UpdateFieldsTab
						onClick={openSaveModal}
						title={updateFieldsTabTitle}
						periodLockedFlag={periodLockedFlag}
					/>
					<EditFieldsTab visible={!addFlag} title={translate("edit.element.editFields")} />
					<OptionsTab
						addFlag
						title={translate("options.admin.elementOptions", { element: "scorecard" })}
						visible={!addFlag}
					/>
					<EditCategoriesTab
						onClick={openSaveModal}
						title={`${translate("global.edit")} ${translate("categories")}`}
					/>
					<CompletedItemsTab
						onClick={openSaveModal}
						title={translate("options.admin.organization.completedItems")}
					/>
					{adminFlag && <UserAccessTab onClick={openSaveModal} title={translate("edit.userAccess")} />}
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter saveAndContinueFlag />
			<FormModalSave object="scorecard" onSave={onSave} modalVisible={saveModalVisible} close={closeSaveModal} />
		</>
	);
};
EditScorecardModalContent.propTypes = editScorecardModalContentPropTypes;

let propTypes = {
	objectId: PropTypes.number,
};
let EditScorecardModal = (props) => {
	let translate = useTranslate();
	let [objectId, setObjectId] = useState(props.objectId);
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId } = session;
	let scorecardEditData = objectId && get({ object: "scorecard", objectId });
	let scorecardUpdateData = objectId && get({ object: "scorecard", objectId, periodId });

	let customFieldData =
		objectId && periodId && get({ object: "customField", parent: "scorecard", parentId: objectId, periodId });
	let categoryList = scorecardEditData?.categories;
	let loadingFlag =
		!!objectId && [customFieldData, scorecardEditData, scorecardUpdateData].includes(undefined);
	let defaultValue = useMemo(() => {
		if (scorecardEditData && customFieldData) {
			let { editFieldData: editCustomFieldData } = splitEditAndUpdateFieldData(customFieldData);
			return {
				...scorecardEditData,
				...editCustomFieldData,
				categorySortOrder: categoryList?.map((x) => x.categoryId),
				updates: scorecardUpdateData,
			};
		}
		return {};
	}, [categoryList, customFieldData, scorecardEditData, scorecardUpdateData]);

	let onSubmit = useCallback(() => {
		toast.success(
			translate(objectId ? "toaster.messages.objects.objectUpdated" : "toaster.messages.objects.objectAdded", {
				object: "scorecard",
			})
		);
	}, [objectId, translate]);

	return (
		<ModalWindowEdit
			{...props}
			color="desaturatedNormal"
			defaultValue={defaultValue}
			loadingFlag={loadingFlag}
			object="scorecard"
			objectId={objectId}
			onSubmit={onSubmit}
			setObjectId={setObjectId}
			size="large"
			submitUnchangedFlag={!objectId}
			updateFlag
		>
			<EditScorecardModalContent addFlag={!objectId} loadingFlag={loadingFlag} />
		</ModalWindowEdit>
	);
};
EditScorecardModal.propTypes = propTypes;

export default EditScorecardModal;
