import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	object: PropTypes.string,
};
let FormSelectLayoutAccessType = ({ restrictedFlag, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(() => {
		let options = [
			{
				label: translate("layout.globalLayout"),
				value: "default",
			},
			{
				label: translate("layout.privateLayout"),
				value: "private",
			},
			{
				label: translate("layout.hiddenLayout"),
				value: "hidden",
			},
		];
		if (restrictedFlag)
			options = [
				...options,
				{
					label: translate("layout.restrictedLayout"),
					value: "restricted",
				},
			];
		return options;
	}, [restrictedFlag, translate]);

	return <Select defaultValue="default" options={options} {...props} />;
};
FormSelectLayoutAccessType.propTypes = propTypes;

export default FormSelectLayoutAccessType;
