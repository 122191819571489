import ModalWindow from "./ModalWindow";
import PropTypes from "prop-types";

let propTypes = {
	beforeSubmit: PropTypes.func,
	children: PropTypes.node,
	close: PropTypes.func.isRequired,
	color: PropTypes.string,
	copyFlag: PropTypes.bool,
	defaultValue: PropTypes.object,
	modalVisible: PropTypes.bool,
	objectId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
	onSubmit: PropTypes.func,
	skipCloseFlag: PropTypes.bool,
};

let defaultProps = {
	skipCloseFlag: false,
};

let ModalWindowEdit = ({ children, close, color, modalVisible, objectId, objectIdList, ...props }) => {
	return (
		<ModalWindow
			color={color}
			modalWindowVisible={modalVisible}
			close={close}
			objectId={objectId}
			objectIdList={objectIdList}
			newObjectFlag={!objectId && !objectIdList}
			{...props}
		>
			{children}
		</ModalWindow>
	);
};

ModalWindowEdit.propTypes = propTypes;
ModalWindowEdit.defaultProps = defaultProps;

export default ModalWindowEdit;
