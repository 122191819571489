import { useCallback } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import LinkElementFormModal from "@clearpoint/old-theme/Form/Modal/LinkElement/LinkElementModal";
import Hide from "@clearpoint/old-theme/Hide";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAiAccess } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import AIAssistantButton from "../../AIAssistants/AIAssistantButton";
import EditMilestoneModal from "@components/Elements/Initiatives/ManageInitiatives/EditInitiativeModalWindow/MilestonesTab/EditMilestoneModal";
import EditElementModalWindowOnClick from "@components/Elements/ModalWindow.EditElement.OnClick";

let propTypes = {
	clearLinkData: PropTypes.func,
	editPodFlag: PropTypes.bool,
	fieldId: PropTypes.string,
	linkType: PropTypes.string,
	milestoneCustomSortEnabled: PropTypes.bool,
};

let HeaderButtons = ({ clearLinkData, editPodFlag, fieldId, linkType, milestoneCustomSortEnabled }) => {
	let translate = useTranslate();
	let { pauseLoading, set } = useOldQueryStore();
	let { links: pageElementLinkList, object: pageObject, objectId: pageObjectId, scorecardId } = useFormValue();
	let milestoneFlag = pageObject === "initiative" && fieldId === "milestones";
	let renderAddButtonFlag = linkType && !["milestone", "category"].includes(linkType);
	let { setFormValue, formDefaultValue } = useFormContext();
	let { links: originalLinks, milestoneCustomSortEnabled: apiMilestoneCustomSortEnabled } = formDefaultValue;
	let { aiAccessFlag } = useCheckAiAccess();
	let addLink = useCallback(
		(response) => {
			pauseLoading();
			set({
				object: "update",
				data: {
					edits: [
						{
							fields: {
								links: [
									...pageElementLinkList,
									{
										fieldId,
										object: linkType,
										objectId: response.objectId,
									},
								],
							},
							object: pageObject,
							objectId: pageObjectId,
						},
					],
				},
				skipClearFlag: true,
			}).then(() => {
				pauseLoading();
			});
		},
		[fieldId, linkType, pageElementLinkList, pageObject, pageObjectId, pauseLoading, set]
	);
	let handleResetSortOrder = () => {
		if (apiMilestoneCustomSortEnabled) {
			set({
				object: "update",
				data: {
					edits: [{ fields: { milestoneCustomSortEnabled: false }, object: "initiative", objectId: pageObjectId }],
					updates: [],
				},
			}).then(() => {
				toast.success(translate("global.sortOrderReset"));
			});
		} else {
			setFormValue("links", originalLinks);
			setFormValue("milestoneCustomSortEnabled", false);
		}
	};
	return (
		editPodFlag && (
			<Hide
				visible={!!linkType}
				display="flex"
				justifyContent="flex-end"
				flexWrap="wrap"
				alignItems="start"
				gap={`${theme.tinySpace} 0`}
			>
				{milestoneFlag ? (
					<>
						<ModalWindowOnClick
							ModalWindow={EditMilestoneModal}
							parentId={pageObjectId}
							scorecardId={scorecardId}
							onAdd={addLink}
							onEdit={clearLinkData}
						>
							<IconButton name="add" iconTransform="">
								{" " + translate("components.addObject", { object: "milestone" })}
							</IconButton>
						</ModalWindowOnClick>
						{aiAccessFlag && (
							<Block>
								<AIAssistantButton fieldId={fieldId} marginLeft={theme.smallSpace} />
							</Block>
						)}
						{milestoneCustomSortEnabled && (
							<Block marginLeft={theme.smallSpace}>
								<IconButton
									name="sort"
									iconSize="small"
									right={theme.smallSpace}
									onClick={handleResetSortOrder}
								>
									{" " + translate("global.resetSortOrder")}
								</IconButton>
							</Block>
						)}
					</>
				) : (
					<>
						{renderAddButtonFlag && (
							<EditElementModalWindowOnClick object={linkType} onAdd={addLink} onEdit={clearLinkData}>
								<IconButton name="add" iconSize="small">
									{" " + translate("components.addObject", { object: linkType })}
								</IconButton>
							</EditElementModalWindowOnClick>
						)}
						<ModalOnClick
							name="links"
							Modal={LinkElementFormModal}
							linkType={fieldId === "children" ? "child" : (fieldId === "parents" ? "parent" : linkType)}
							object={linkType}
							showLinkedFlag
						>
							<IconButton name="linkTag" iconSize="small" marginLeft={theme.tinySpace}>
								{" " + translate("components.linkObject", { object: linkType })}
							</IconButton>
						</ModalOnClick>
					</>
				)}
			</Hide>
		)
	);
};

HeaderButtons.propTypes = propTypes;
export default HeaderButtons;
