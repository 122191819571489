import { buildQueryString } from "@clearpoint/utils";

let updateUrlWithPeriodId = ({ history, pathname, periodId, queryParameters }) => {
	if (periodId) {
		let newEntry = pathname + buildQueryString({ ...queryParameters, periodId })
		history.push(newEntry);
	}
};

export default updateUrlWithPeriodId;
