import { useCallback, useState } from "react";

let useBooleanState = (initialValue) => {
	let [value, setValue] = useState(initialValue);
	let setValueFalse = useCallback(() => setValue(false), []);
	let setValueTrue = useCallback(() => setValue(true), []);
	let toggleValue = useCallback(() => setValue((x) => !x), []);
	return { value, setValueFalse, setValueTrue, toggleValue };
};

export default useBooleanState;
