import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import rehypeRaw from "rehype-raw";
import remarkRehype from "remark-rehype";
import rehypeStringify from "rehype-stringify";
import { unified } from "unified";

let convertMarkdownToHtml = async (markdown) => {
	let content = await unified()
		.use(remarkParse)
		.use(remarkRehype, { allowDangerousHtml: true })
		.use(remarkGfm)
		.use(rehypeRaw)
		.use(rehypeStringify)
		.process(markdown);

	return String(content);
};

export default convertMarkdownToHtml;
