import {
	DndContext as DndKitContext,
	MouseSensor,
	TouchSensor,
	useSensors,
	useSensor,
	MeasuringStrategy,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import useCollisionDetectionStrategy from "./useCollisionDetectionStrategy";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createUniqueIdFromPod } from "@clearpoint/utils";

let DragAndDropContext = createContext();

let propTypes = {
	children: PropTypes.node,
};

let DragAndDropWrapper = ({ children }) => {
	let { setFormValue } = useFormContext();
	let areaList = useFormValue("layout");

	let generateContainerId = (area) => `layout[${area.area}]`;

	let [dragAndDropPodIdLookup, setDragAndDropLookup] = useState(() => {
		let dragAndDropPodIdLookup = {};
		for (let area of areaList) {
			dragAndDropPodIdLookup[generateContainerId(area)] = area.pods.map((x) => createUniqueIdFromPod(x));
		}
		return dragAndDropPodIdLookup;
	});

	let recentlyMovedToNewContainerFlag = useRef(false);

	let containerList = useMemo(() => areaList?.map(generateContainerId) ?? [], [areaList]);

	let [activeId, setActiveId] = useState(null);

	let collisionDetectionStrategy = useCollisionDetectionStrategy({
		activeId,
		dragAndDropPodIdLookup,
		recentlyMovedToNewContainerFlag,
	});

	let [clonedItems, setClonedItems] = useState(null);
	let sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	let findContainer = useCallback(
		(id) => {
			if (id in dragAndDropPodIdLookup) {
				return id;
			}
			return Object.keys(dragAndDropPodIdLookup).find((key) => dragAndDropPodIdLookup[key].includes(id));
		},
		[dragAndDropPodIdLookup]
	);

	let handleDragCancel = () => {
		if (clonedItems) {
			// Reset items to their original state in case items have been
			// Dragged across containers
			setDragAndDropLookup(clonedItems);
		}

		setActiveId(null);
		setClonedItems(null);
	};

	useEffect(() => {
		requestAnimationFrame(() => {
			recentlyMovedToNewContainerFlag.current = false;
		});
	}, [dragAndDropPodIdLookup]);

	let measuringStrategy = useMemo(
		() => ({
			droppable: {
				strategy: MeasuringStrategy.Always,
			},
		}),
		[]
	);

	let handleDragStart = useCallback(
		({ active }) => {
			setActiveId(active.id);
			setClonedItems(dragAndDropPodIdLookup);
		},
		[dragAndDropPodIdLookup]
	);

	let handleDragOver = useCallback(
		({ active, over }) => {
			let overId = over?.id;

			if (overId == null || active.id in dragAndDropPodIdLookup) {
				return;
			}

			let overContainer = findContainer(overId);
			let activeContainer = findContainer(active.id);

			if (!overContainer || !activeContainer) {
				return;
			}

			if (activeContainer !== overContainer) {
				setDragAndDropLookup((lookup) => {
					let activeItems = lookup[activeContainer];
					let overItems = lookup[overContainer];
					let overIndex = overItems.indexOf(overId);
					let activeIndex = activeItems.indexOf(active.id);

					let newIndex;

					if (overId in lookup) {
						newIndex = overItems.length + 1;
					} else {
						let isBelowOverItem =
							over &&
							active.rect.current.translated &&
							active.rect.current.translated.top > over.rect.top + over.rect.height;

						let modifier = isBelowOverItem ? 1 : 0;

						newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
					}

					recentlyMovedToNewContainerFlag.current = true;

					return {
						...lookup,
						[activeContainer]: lookup[activeContainer].filter((item) => item !== active.id),
						[overContainer]: [
							...lookup[overContainer].slice(0, newIndex),
							lookup[activeContainer][activeIndex],
							...lookup[overContainer].slice(newIndex, lookup[overContainer].length),
						],
					};
				});
			}
		},
		[findContainer, dragAndDropPodIdLookup]
	);

	let findPodById = useCallback(
		(podId) => {
			let podListInForm = areaList.map((x) => x.pods).flat();
			let podInForm = podListInForm.find((y) => createUniqueIdFromPod(y) === podId);
			return podInForm;
		},
		[areaList]
	);

	let handleDragEnd = useCallback(
		({ active, over }) => {
			let activeContainer = findContainer(active.id);

			if (!activeContainer) {
				setActiveId(null);
				return;
			}

			let overId = over?.id;

			if (overId == null) {
				setActiveId(null);
				return;
			}

			let overContainer = findContainer(overId);

			if (overContainer) {
				let activeIndex = dragAndDropPodIdLookup[activeContainer].indexOf(active.id);
				let overIndex = dragAndDropPodIdLookup[overContainer].indexOf(overId);

				if (activeIndex !== overIndex) {
					setDragAndDropLookup((previousItemLookup) => {
						let newItemLookup = {
							...previousItemLookup,
							[overContainer]: arrayMove(previousItemLookup[overContainer], activeIndex, overIndex),
						};

						for (let area in newItemLookup) {
							setFormValue(
								area + ".pods",
								newItemLookup[area].map((x) => findPodById(x))
							);
						}

						return newItemLookup;
					});
				} else {
					for (let area in dragAndDropPodIdLookup) {
						setFormValue(
							area + ".pods",
							dragAndDropPodIdLookup[area].map((x) => findPodById(x))
						);
					}
				}
			}

			setActiveId(null);
		},
		[findContainer, findPodById, dragAndDropPodIdLookup, setFormValue]
	);

	return (
		<DragAndDropContext.Provider
			value={{
				activeId,
				dragAndDropPodIdLookup,
				generateContainerId,
				setDragAndDropLookup,
			}}
		>
			<DndKitContext
				sensors={sensors}
				collisionDetection={collisionDetectionStrategy}
				measuring={measuringStrategy}
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
			>
				<SortableContext items={containerList}>{children}</SortableContext>
			</DndKitContext>
		</DragAndDropContext.Provider>
	);
};

export const useDragAndDropContext = () => useContext(DragAndDropContext);
DragAndDropWrapper.propTypes = propTypes;
export default DragAndDropWrapper;
