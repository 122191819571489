import CellWrapper from "../CellWrapper";
import FormFieldCompletedDate from "@clearpoint/old-theme/Form/Field/CompletedDate";
import FormFieldDisplayCompletedDate from "@clearpoint/old-theme/Form/Field/Display/CompletedDate";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func,
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let CompletedDateCell = (props) => {
	let { close, editCellFlag, parentName } = props;
	let translate = useTranslate();
	let name = `${parentName}.completedDate`;
	let completedFlag = useFormValue(`${parentName}.completed`);
	return (
		<CellWrapper {...props}>
			{editCellFlag && completedFlag ? (
				<FormModal
					title={translate("defaultFields.edit.actionItem.completedDate")}
					name={name}
					modalVisible
					close={close}
				>
					<FormFieldCompletedDate dateOnlyFlag name={name} />
				</FormModal>
			) : editCellFlag && !completedFlag ? (
				<div>{translate("displayDetail.fields.boolean.pleaseMarkCompleted")}</div>
			) : (
				<FormFieldDisplayCompletedDate name={name} parentName={parentName} />
			)}
		</CellWrapper>
	);
};
CompletedDateCell.propTypes = propTypes;

export default CompletedDateCell;
