import DropdownItem from "./Dropdown.Item";
import ModalOnClick from "../../Modal/Modal.OnClick";
import ModalWindowOnClick from "../../ModalWindow/ModalWindow.OnClick";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
};
let AddMultipleDropdownItemInner = ({ children, ...props }) => {
	let translate = useTranslate();
	return (
		<DropdownItem data-testid="dropdown-item-add-multiple" icon="addMultiple" {...props}>
			{children || translate("global.addMultiple")}
		</DropdownItem>
	);
};
AddMultipleDropdownItemInner.propTypes = propTypes;

let wrapperPropTypes = {
	Modal: PropTypes.elementType,
	ModalWindow: PropTypes.elementType,
};
let AddMultipleDropdownItem = ({ Modal, ModalWindow, ...props }) =>
	Modal ? (
		<ModalOnClick Modal={Modal} {...props}>
			<AddMultipleDropdownItemInner {...props} />
		</ModalOnClick>
	) : ModalWindow ? (
		<ModalWindowOnClick ModalWindow={ModalWindow} {...props}>
			<AddMultipleDropdownItemInner {...props} />
		</ModalWindowOnClick>
	) : (
		<AddMultipleDropdownItemInner {...props} />
	);
AddMultipleDropdownItem.propTypes = wrapperPropTypes;
export default AddMultipleDropdownItem;
