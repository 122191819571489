import Badge from "@clearpoint/old-theme/Badge/Badge";
import Block from "@clearpoint/old-theme/Block";
import ChartList from "./ChartList";
import Contact from "./Contact";
import Definition from "./Definition";
import Help from "@clearpoint/old-theme/Help";
import PageTitle from "@clearpoint/old-theme/PageTitle/PageTitle";
import PeerMeasureGrid from "@clearpoint/old-theme/Form/Grid/Series/PeerMeasureGrid";
import Text from "@clearpoint/old-theme/Text/Text";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";


let propTypes = {
	peerMeasureSeriesId: PropTypes.number,
	peerPreview: PropTypes.shape({
		charts: PropTypes.array,
		contact: PropTypes.object,
		definition: PropTypes.string,
		measureGrid: PropTypes.array,
		measureSeries: PropTypes.array,
		name: PropTypes.string,
		peer: PropTypes.string,
		statusIndicators: PropTypes.object,
	}),
};

let PeerPreviewTab = ({ peerMeasureSeriesId, peerPreview }) => {
	let {
		charts: chartList,
		contact,
		definition,
		measureGrid: measureGridList,
		measureSeries: measureSeriesList,
		name,
		peer,
		statusIndicators: statusIndicatorLookup,
	} = peerPreview || emptyObject;

	let measureSeries = measureSeriesList?.find((x) => x.measureSeriesId === peerMeasureSeriesId);
	let { name: seriesName, seriesDataType } = measureSeries || emptyObject;
	let { email, fullName, organization, phoneNumber } = contact || emptyObject;

	return (
		<>
			<Block marginBottom={theme.smallSpace}>
				<Help section="peer-measures" feature="peer-measure-preview" />
				<PageTitle title={name} subTitle={peer} />
			</Block>
			<Block
				backgroundColor={theme.backgroundGrey}
				borderLeft={`6px solid ${theme.lightGray}`}
				borderRadius="4px"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom={theme.space}
				marginTop={theme.mediumSpace}
				padding={theme.smallSpace}
			>
				<Text>{seriesName}</Text>
				<Badge color="success" size="tiny">
					{seriesDataType}
				</Badge>
			</Block>
			{definition && <Definition definition={definition} />}
			<ChartList chartList={chartList} measureGridList={measureGridList} />
			<PeerMeasureGrid
				measureGridList={measureGridList}
				measureSeriesList={measureSeriesList}
				statusIndicatorLookup={statusIndicatorLookup}
			/>
			{contact && (
				<Contact email={email} fullName={fullName} organization={organization} phoneNumber={phoneNumber} />
			)}
		</>
	);
};

PeerPreviewTab.propTypes = propTypes;

export default PeerPreviewTab;
