import ReactModal from "react-modal";

import { noop } from "@clearpoint/utils";

import StyleWrapper from "../StyleWrapper";
import Transition from "../Transition";

// TO DO: slide out on dismount, convert to react transition library
let StyledReactModal = ({ children, isOpen, TRANSITION_DURATION }) => {
	return (
		<Transition
			defaultStyle="transform: translateX(0);"
			doNotRenderChildrenFlag
			duration={TRANSITION_DURATION}
			easingFunction="ease-out"
			enterStyle="transform: translateX(100%);"
			exitStyle="transform: translateX(100%);"
			visible={isOpen}
		>
			<StyleWrapper
				alignItems="center"
				backgroundColor="transparent"
				border="0 none"
				bottom="0"
				display="flex"
				height="100%"
				justifyContent="flex-end"
				left="0"
				outline="0 none"
				right="0"
				top="0"
				width="100%"
			>
				<ReactModal isOpen onRequestClose={noop}>
					{children}
				</ReactModal>
			</StyleWrapper>
		</Transition>
	);
};

export default StyledReactModal;