import { useCallback } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import { useOldSession } from "@clearpoint/old-session/index";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import saveLinkData from "./useSaveLinkData.saveLinkData";

let useSaveLinkData = ({ close, name }) => {
	let { get, getPromise } = useOldQueryStore();
	let { setFormValue } = useFormContext();
	let { periodId } = useOldSession().session;
	let scorecardList = get({ object: "scorecard" });
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let linkObjectIdList = useFormValue("_modal.linkObjectIdList");

	let getIndirectLinkFlag = useCallback(
		(link) => ["measureInitiatives", "initiativeMeasures"].includes(link.fieldId),
		[]
	);

	let previousLinkList = useFormValue(name);
	return useCallback(
		() =>
			saveLinkData({
				close,
				getIndirectLinkFlag,
				getPromise,
				linkObjectIdList,
				name,
				periodId,
				previousLinkList: previousLinkList || [],
				scorecardList,
				setFormValue,
				statusList,
			}),
		[
			close,
			getIndirectLinkFlag,
			getPromise,
			linkObjectIdList,
			name,
			periodId,
			previousLinkList,
			scorecardList,
			setFormValue,
			statusList,
		]
	);
};

export default useSaveLinkData;
