import { useMemo } from "react";

import { useTranslate } from "@clearpoint/translate";
import { emptyArray, objectList } from "@clearpoint/utils";

import useFilterHiddenDefaultFields from "../useFilterHiddenDefaultFields";
import useScorecardId from "../useScorecardId";
import useActionItemEditFieldList from "./useActionItemEditFieldList";
import useActionItemUpdateFieldList from "./useActionItemUpdateFieldList";
import useChartEditFieldList from "./useChartEditFieldList";
import useInitiativeEditFieldList from "./useInitiativeEditFieldList";
import useInitiativeUpdateFieldList from "./useInitiativeUpdateFieldList";
import useMapEditFieldList from "./useMapEditFieldList";
import useMeasureEditFieldList from "./useMeasureEditFieldList";
import useMeasureSeriesEditFieldList from "./useMeasureSeriesEditFieldList";
import useMeasureUpdateFieldList from "./useMeasureUpdateFieldList";
import useMilestoneEditFieldList from "./useMilestoneEditFieldList";
import useMilestoneUpdateFieldList from "./useMilestoneUpdateFieldList";
import useObjectiveEditFieldList from "./useObjectiveEditFieldList";
import useObjectiveUpdateFieldList from "./useObjectiveUpdateFieldList";
import useRiskEditFieldList from "./useRiskEditFieldList";
import useRiskUpdateFieldList from "./useRiskUpdateFieldList";
import useScorecardEditFieldList from "./useScorecardEditFieldList";
import useScorecardUpdateFieldList from "./useScorecardUpdateFieldList";

let useFieldList = ({
	addFlag,
	multipleFlag,
	object,
	objectId,
	objectIdList,
	periodLockedFlag,
	scorecardId: scorecardIdParam,
	updateFlag,
}) => {
	let translate = useTranslate();
	let hookScorecardId = useScorecardId({ object, objectId });
	let scorecardId = scorecardIdParam || hookScorecardId;
	let getActionItemEdit = useActionItemEditFieldList({ addFlag, multipleFlag, scorecardId });
	let getActionItemUpdate = useActionItemUpdateFieldList({ scorecardId });
	let getChartEdit = useChartEditFieldList();
	let getInitiativeEdit = useInitiativeEditFieldList({ addFlag, multipleFlag, scorecardId });
	let getInitiativeUpdate = useInitiativeUpdateFieldList({ scorecardId });
	let getMapEdit = useMapEditFieldList();
	let getMeasureEdit = useMeasureEditFieldList({ scorecardId });
	let getMeasureUpdate = useMeasureUpdateFieldList({ scorecardId });
	let getMeasureSeriesEdit = useMeasureSeriesEditFieldList();
	let getMilestoneEdit = useMilestoneEditFieldList({ addFlag, multipleFlag, scorecardId });
	let getMilestoneUpdate = useMilestoneUpdateFieldList({ scorecardId });
	let getObjectiveEdit = useObjectiveEditFieldList({ scorecardId });
	let getObjectiveUpdate = useObjectiveUpdateFieldList({ scorecardId });
	let getRiskEdit = useRiskEditFieldList({ addFlag, multipleFlag, scorecardId });
	let getRiskUpdate = useRiskUpdateFieldList({ scorecardId });
	let getScorecardEdit = useScorecardEditFieldList({ scorecardId });
	let getScorecardUpdate = useScorecardUpdateFieldList({ scorecardId });

	let fieldListGetFunctionLookup = {
		actionItemEdit: getActionItemEdit,
		actionItemUpdate: getActionItemUpdate,
		chartEdit: getChartEdit,
		initiativeEdit: getInitiativeEdit,
		initiativeUpdate: getInitiativeUpdate,
		mapEdit: getMapEdit,
		measureEdit: getMeasureEdit,
		measureUpdate: getMeasureUpdate,
		measureSeriesEdit: getMeasureSeriesEdit,
		milestoneEdit: getMilestoneEdit,
		milestoneUpdate: getMilestoneUpdate,
		objectiveEdit: getObjectiveEdit,
		objectiveUpdate: getObjectiveUpdate,
		riskEdit: getRiskEdit,
		riskUpdate: getRiskUpdate,
		scorecardEdit: getScorecardEdit,
		scorecardUpdate: getScorecardUpdate,
	};

	let filterHiddenDefaultFields = useFilterHiddenDefaultFields();

	let addMultipleFlag = addFlag && multipleFlag;
	let fieldList = object ? fieldListGetFunctionLookup[`${object}${updateFlag ? "Update" : "Edit"}`]() : emptyArray;

	let newFieldList = useMemo(() => {
		let visibleFieldList = filterHiddenDefaultFields({ fieldList, object });
		if (!addFlag) visibleFieldList = visibleFieldList.filter((x) => !x.addFlag);

		let nameField = addMultipleFlag
			? {
					value: "names",
					label: translate(translate("edit.element.elementNames", { element: object })),
					sortOrder: -1000,
					group: translate(object),
					required: true,
			  }
			: {
					value: "name",
					label: translate(
						objectList.includes(object)
							? `defaultFields.edit.${object}.name`
							: (object
							? "edit.namePlaceholder"
							: "global.name"),
						{ object }
					),
					sortOrder: -1000,
					group: object === "measureSeries" ? null : translate(object),
					required: true,
			  };
		return updateFlag ? visibleFieldList : [nameField, ...visibleFieldList];
	}, [addFlag, addMultipleFlag, fieldList, filterHiddenDefaultFields, object, translate, updateFlag]);

	return newFieldList;
};
export default useFieldList;
