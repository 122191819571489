import { useCallback } from "react";

import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { formatNumber } from "@clearpoint/utils";

let dataTypeLookup = {
	4: "currency",
	5: "default",
	6: "percent",
	9: "integer",
};

let defaultCurrencyCodeList = ["   ", null];

let useFormatKeyResultNumber = (keyResultNameInForm) => {
	let { get } = useOldQueryStore();
	let options = get({ object: "options" });
	let currencyList = get({ object: "currency" });

	let dataType = useFormValue(`${keyResultNameInForm}.dataTypeId`);
	let currencyCodeInForm = useFormValue(`${keyResultNameInForm}.currencyCode`);
	let currencyCode = defaultCurrencyCodeList.includes(currencyCodeInForm) ? options?.currency : currencyCodeInForm;

	return useCallback(
		(number) =>
			formatNumber({
				decimalPlaces: dataType === "currency" ? undefined : 0,
				number: number || 0,
				type: dataTypeLookup[dataType],
				currencyCode,
				currencyList,
			}),
		[currencyCode, currencyList, dataType]
	);
};

export default useFormatKeyResultNumber;
