import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectPageOrientation = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("pdf.landscape"), value: "landscape" },
			{ label: translate("pdf.portrait"), value: "portrait" },
		],
		[translate]
	);
	return <Select options={options} {...props} />;
};

export default SelectPageOrientation;
