let getElementParentId = (element) => {
	if (!element) return null;
	let { parent, parentId } = element;
	return parentId || parent;
};

let getElementId = (element) => {
	if (!element) return null;
	let { measureSeriesId, objectId, layoutId, scorecardId, districtId } = element;
	return measureSeriesId || objectId || layoutId || scorecardId || districtId;
};

let getAncestryId = (parent, child) => {
	if (child.ancestryId) return child.ancestryId;
	if (!parent) return "";

	let { ancestryId } = parent;
	return `${ancestryId ? ancestryId || 0 : ""}${getElementId(parent) || 0}`.toString(16);
};

let getLinkChartId = (link) => {
	if (!link) return null;
	if (link.id) return link.id;
	let { ancestryId, object } = link;

	// Encode ancestryId and objectId to hex and remove and append trailing 0s as count
	let trailingId = BigInt(`${ancestryId || 0}${getElementId(link)}`);
	let hexId = trailingId.toString(16);
	let splitId = hexId.split(/(00)*$/)[0];

	return `${object}${splitId}${trailingId.toString().length - splitId.length}`.toLowerCase();
};

export { getAncestryId, getElementId, getElementParentId, getLinkChartId };
