import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";

import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";

import { useScorecard, useStateObject } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import ChartsTab from "./ChartsTab";
import ColumnsTab from "./ColumnsTab";
import ElementOrderTab from "./ElementOrderTab";
import ElementReportTab from "./ElementReportTab";
import ElementsTab from "./ElementsTab";
import FilterTab from "./FilterTab";
import useBeforeGridLayoutSubmit from "./hooks/useBeforeGridLayoutSubmit";
import { useOldSession } from "@clearpoint/old-session/index";

let editLayoutModalContentPropTypes = {
  object: PropTypes.string,
  objectId: PropTypes.number,
};
let EditLayoutModalContent = ({ object, objectId }) => {
  let translate = useTranslate();
  let { clear } = useOldQueryStore();
  let { layoutId, name, reportType, reportOptions } = useFormValue();
  let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
    saveModalVisible: false,
    savedFlag: false,
  });
  let onSave = useCallback(() => setState({ savedFlag: true }), [setState]);
  let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);
  let openSaveModal = useCallback(() => {
    if (reportOptions?.hierarchy === object) {
      setState({ saveModalVisible: true });
    } else {
      toast.warning(translate("layout.customOrderWarning", { object }));
    }
  }, [object, reportOptions, setState, translate]);
  useUnmount(() => {
    if (savedFlag) clear({ object: `${object}Layout`, objectId });
  });
  return (
    <>
      <ModalWindowHeader
        badgeIcon={object}
        badgeText={`${translate(object)} ${translate("global.report")}`}
        title={name}
      />
      <ModalWindowContent>
        <ModalWindowTabContainer>
          <ElementReportTab title={`${translate(object)} ${translate("global.report")}`} object={object} />
          {reportType === "grid" && <ColumnsTab title={translate("layout.columns")} />}
          {reportType === "dashboard" && <ChartsTab title={translate("global.charts")} />}
          {reportType !== "dashboard" && <ElementsTab title={translate(`${object}s`)} object={object} />}
          <FilterTab title={translate("global.filter")} />
          <ElementOrderTab onClick={openSaveModal} title={translate("global.elementOrder")} object={object} />
        </ModalWindowTabContainer>
      </ModalWindowContent>
      <ModalWindowFooter saveAndContinueFlag />
      <FormModalSave
        object={`${object}Layout`}
        objectId={layoutId}
        onSave={onSave}
        modalVisible={saveModalVisible}
        submitUnchangedFlag
        close={closeSaveModal}
      />
    </>
  );
};
EditLayoutModalContent.propTypes = editLayoutModalContentPropTypes;

let propTypes = {
  object: PropTypes.string,
  layoutId: PropTypes.number,
};
let EditLayoutModal = ({ object, layoutId, ...props }) => {
  let translate = useTranslate();
  let { periodId } = useOldSession().session;
  let [objectId, setObjectId] = useState(layoutId);
  let { clear, get } = useOldQueryStore();
  let scorecard = useScorecard();
  let layout = objectId && get({ object: `${object}Layout`, objectId });
  let groupList = get({ object: "group" });
  let loadingFlag = objectId && [groupList, layout, scorecard].includes(undefined);
  let beforeSubmit = useBeforeGridLayoutSubmit();
  let onSubmit = useCallback(() => {
    if (objectId) {
      clear({ object: `${object}Layout`, objectId, periodId });
      clear({ object: "layout", objectId, storeKey: `layoutId=${objectId}` });
    }
    toast.success(translate(objectId ? "toaster.messages.reports.saved" : "toaster.messages.reports.reportAdded"));
  }, [clear, object, objectId, periodId, translate]);
  let defaultValue = useMemo(() => {
    return objectId ? {
      ...layout,
      groups: groupList
        ?.filter((x) => x.active)
        .map((x, i) => ({
          ...x,
          selected: layout?.groups?.find((layoutGroup) => layoutGroup.groupId === x.groupId)?.selected,
        })),
      reportColumns: layout?.reportColumns?.map((column) => {
        if (column.periods?.selectedPeriods && column.periods.selectedPeriods.split(",").length > 0) {
          return {
            ...column,
            periods: {
              ...column.periods,
              selectedPeriods: column.periods.selectedPeriods.split(",").map((period) => +period),
            },
          };
        } else if (column.periods?.selectedPeriods) {
          return {
            ...column,
            periods: { ...column.periods, selectedPeriods: +column.periods.selectedPeriods.split() },
          };
        } else {
          return column;
        }
      }),
    } : {
      object,
      scorecardId: scorecard?.scorecardId,
      groups: groupList,
      reportColumns: [
        {
          fieldId: "name",
          label: translate(object + "s"),
          object,
          width: {
            width: 1,
          },
        },
      ],
      reportOptions: {
        hierarchy: object,
        hideTitle: false,
        gridTemplate: false,
      },
    };
  }, [groupList, layout, object, objectId, scorecard?.scorecardId, translate]);
  return (
    <ModalWindowEdit
      {...props}
      beforeSubmit={beforeSubmit}
      color="lazur"
      defaultValue={defaultValue}
      loadingFlag={loadingFlag}
      object={`${object}Layout`}
      objectId={objectId}
      onSubmit={onSubmit}
      scorecardId={scorecard?.scorecardId}
      setObjectId={setObjectId}
      size="large"
      submitUnchangedFlag={!objectId}
    >
      <EditLayoutModalContent addFlag={!objectId} object={object} objectId={objectId} />
    </ModalWindowEdit>
  );
};
EditLayoutModal.propTypes = propTypes;

export default EditLayoutModal;
