import { useMemo } from "react";
import { cloneDeep, isArray } from "lodash";

import { useNearestPeriodId } from "@clearpoint/hooks";

// TO DO: Printing
let useDefaultValue = ({ elementDetailList }) => {
	let periodId = useNearestPeriodId();
	let defaultValue = useMemo(() => {
		if (!elementDetailList || elementDetailList.includes(undefined)) return;
		let elementList = elementDetailList.reduce((elementList, apiData) => {
			if (!isArray(apiData)) apiData = [apiData];
			apiData = apiData.map((object) => ({
				...object,
				...Object.entries(
					object.customFields?.[periodId] || {}
				).reduce((customFieldData, [customFieldKey, customField]) => {
					if (customField.updateMode) {
						let statuses = cloneDeep(object.status);
						let newStatus = {
							...statuses,
							[periodId]: {
								...object.status?.[periodId],
								...customFieldData.status?.[periodId],
								[customFieldKey]: customField,
							},
						};
						return {
							...customFieldData,
							status: newStatus,
						};
					} else {
						return {
							...customFieldData,
							[customFieldKey]: customField,
						};
					}
				}, {}),
				customFields: undefined,
				_customFields: object.customFields,
			}));
			return [...elementList, ...apiData];
		}, []);
		// eslint-disable-next-line consistent-return
		return { elementList };
	}, [elementDetailList, periodId]);
	return defaultValue;
};

export default useDefaultValue;