let isTemplatePage = (x) => {
	return x.page === "document-library" && x.view === "template";
};

let isDocumentPage = (x) => {
	return x.page === "document-library" && ["reports", "html", "exports"].includes(x.mode) && x.mode !== "dashboard";
};

let isFilePage = (x) => {
	return x.page === "document-library" && ["attachments", "images"].includes(x.mode);
};

export { isDocumentPage, isFilePage,isTemplatePage };
