import addStartingSlashIfMissing from "./httpService.addStartingSlashIfMissing";
import axios from "axios";
import debounce from "lodash/debounce";
import { expressAxios, luceeAxios } from "../axiosService";
import getHandleError from "./httpService.getHandleError";
import getHandleResponse from "./httpService.getHandleResponse";
import getLoginRedirectFromHash from "./httpService.getLoginRedirectFromHash";
import getResolveDebounced from "./httpService.getResolveDebounced";
import getThrottle from "./httpService.getThrottle";
import { getTranslate } from "@clearpoint/translate";
import { getEnvironment } from "@clearpoint/utils";


let environment = getEnvironment();
if (environment !== "localdev") {
	luceeAxios.defaults.withCredentials = true;
	expressAxios.defaults.withCredentials = true;
}

let controller;

let resetAbortController = () => {
	controller = new AbortController();
};
resetAbortController();

let createKey = (url, method, payload, expressFlag) => {
	url = (expressFlag ? "/v2" : "/v1") + url;
	return method + url + (payload ? JSON.stringify(payload) : "");
};

let requestLookup = {};
let getRequestCount = () =>
	Object.keys(requestLookup).reduce(
		(count, key) => (requestLookup[key] && requestLookup[key] !== "CANCEL" ? count + 1 : count),
		0
	);

let cancelAndResetAbortController = async () => {
	for (let key of Object.keys(requestLookup)) {
		requestLookup[key] = "CANCEL";
	}
	controller.abort();
	resetAbortController();
};

let handleResponse = (res, key, url, payload, method) => {
	let handleResponseFunction = getHandleResponse({ getLoginRedirectFromHash, requestLookup });
	try {
		document.dispatchEvent(new CustomEvent("api-call", { detail: { res, key, url, payload, method } }));
	} catch (e) {
		console.log(e);
	}
	return handleResponseFunction(res, key);
};

let handleError = (e, key, url, payload) => {
	let handleErrorFunction = getHandleError({
		cancelAndResetAbortController,
		getLoginRedirectFromHash,
		getTranslate,
		requestLookup,
	});
	return handleErrorFunction(e, key, url, payload);
};

let getOptions = (method) => ({
	signal: method === "get" ? controller.signal : undefined,
	headers: { "x-cps-client": "react" },
});
let debouncedResult = {};

let debounced = debounce(
	(url, method, payload, expressFlag) => {
		let key = createKey(url, method, payload, expressFlag);
		let params = [url, payload, getOptions(method)].filter((x) => x !== undefined);
		let axiosInstance = expressFlag ? expressAxios : luceeAxios;
		debouncedResult[key] = axiosInstance[method](...params)
			.then((res) => handleResponse(res, key))
			.catch((e) => handleError(e, key, url));
	},
	250,
	{ maxWait: 1000 }
);

let resolveDebounced = (expressFlag, url, method, payload) => {
	url = addStartingSlashIfMissing(url);
	debounced(url, method, payload, expressFlag);

	let key = createKey(url, method, payload, expressFlag);
	return getResolveDebounced(debouncedResult, key);
};

let resolveDebouncedLucee = (url, method, payload) => resolveDebounced(false, url, method, payload);
let resolveDebouncedExpress = (url, method, payload) => resolveDebounced(true, url, method, payload);

let throttle = async (requestFunction, key) => {
	let throttleFunction = getThrottle(requestLookup, getRequestCount);
	return throttleFunction(requestFunction, key);
};

let createThrottledMethod = (expressFlag, method, url, payload, configOptions = {}) => {
	url = addStartingSlashIfMissing(url);
	let key = createKey(url, method, payload, expressFlag);
	let options = { ...getOptions(method), ...configOptions };
	let axiosInstance = expressFlag ? expressAxios : luceeAxios;

	let argumentsLookup = {
		get: [url, options],
		post: [url, payload, options],
		put: [url, payload, options],
		delete: [
			url,
			{
				data: payload,
				...options,
			},
		],
	};

	return throttle(
		() =>
			axiosInstance[method](...argumentsLookup[method])
				.then((res) => handleResponse(res, key, url, payload, method))
				.catch((e) => handleError(e, key, url, payload)),
		key
	);
};

let createThrottledMethodLucee = (method, url, payload, configOptions) =>
	createThrottledMethod(false, method, url, payload, configOptions);
let createThrottledMethodExpress = (method, url, payload, configOptions) =>
	createThrottledMethod(true, method, url, payload, configOptions);

let http = {
	get: (url, configOptions) => createThrottledMethodLucee("get", url, null, configOptions),
	debouncedGet: (url, configOptions) => resolveDebouncedLucee(url, "get", null, configOptions),
	post: (url, payload, configOptions) => createThrottledMethodLucee("post", url, payload, configOptions),
	debouncedPost: (url, payload, configOptions) => resolveDebouncedLucee(url, "post", payload, configOptions),
	put: (url, payload, configOptions) => createThrottledMethodLucee("put", url, payload, configOptions),
	delete: (url, payload, configOptions) => createThrottledMethodLucee("delete", url, payload, configOptions),
	cancel: cancelAndResetAbortController,
	isCancel: axios.isCancel,
	requestLookup,
};

let httpExpress = {
	get: (url, configOptions) => createThrottledMethodExpress("get", url, null, configOptions),
	debouncedGet: (url, configOptions) => resolveDebouncedExpress(url, "get", null, configOptions),
	post: (url, payload, configOptions) => createThrottledMethodExpress("post", url, payload, configOptions),
	debouncedPost: (url, payload, configOptions) => resolveDebouncedExpress(url, "post", payload, configOptions),
	put: (url, payload, configOptions) => createThrottledMethodExpress("put", url, payload, configOptions),
	delete: (url, payload, configOptions) => createThrottledMethodExpress("delete", url, payload, configOptions),
	cancel: cancelAndResetAbortController,
	isCancel: axios.isCancel,
	requestLookup,
};

export default http;
export { httpExpress };
