import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useEvaluationEnabledLookup } from "@clearpoint/hooks";

let useMeasureGridData = ({ element, object, objectId }) => {
	let { get } = useOldQueryStore();
	let { exportFlag } = useOldSession().session;
	let evaluationEnabledFlag = useEvaluationEnabledLookup()[object];

	let measureId = object === "measure" ? objectId : element?.shadowMeasureId;

	let measureGridQuery = useMemo(() => {
		if (measureId && evaluationEnabledFlag) {
			return {
				parent: "measure",
				parentId: measureId,
				object: "measureGrid",
				queryString: exportFlag ? "?print=true" : "",
			};
		}
	}, [exportFlag, evaluationEnabledFlag, measureId]);

	let measureSeriesQuery = useMemo(() => {
		if (measureId && evaluationEnabledFlag) {
			return { parent: "measure", parentId: measureId, object: "measureSeries" };
		}
	}, [evaluationEnabledFlag, measureId]);

	return {
		measureGridQuery,
		measureGrid: measureGridQuery && get(measureGridQuery),
		measureSeriesQuery,
		measureSeriesList: measureSeriesQuery && get(measureSeriesQuery),
	};
};

export default useMeasureGridData;
