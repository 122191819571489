import goalViewLookup from "../goalViewLookup";
import { useQueryParameters } from "@clearpoint/hooks";


let { DEPARTMENT, DEPARTMENT_PERSONAL, INDIVIDUAL, INDIVIDUAL_PERSONAL, MANAGER, MANAGER_PERSONAL } = goalViewLookup;

let personalViewLookup = {
	[DEPARTMENT]: DEPARTMENT_PERSONAL,
	[INDIVIDUAL]: INDIVIDUAL_PERSONAL,
	[MANAGER]: MANAGER_PERSONAL,
};

let useViewType = () => {
	let { mode, view } = useQueryParameters();
	if (mode === "personal") {
		return personalViewLookup[view];
	}

	return view;
};

export default useViewType;
