import { memo } from "react";
import PropTypes from "prop-types";
import BootstrapAlert from "react-bootstrap/Alert";


let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.oneOf(["primary", "info", "warning", "success", "danger"]),
	"data-testid": PropTypes.string,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let defaultProps = {
	color: "primary",
};

let Alert = ({ children, className, color, "data-testid": dataTestId, style }) => (
	<BootstrapAlert className={className} data-testid={dataTestId} style={style} variant={color}>
		{children}
	</BootstrapAlert>
);

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
