import Block from "../Block";
import ColorInput from "../Form/Input/Color/FormInputColor";
import Column from "../Column";
import IconButton from "../Button/Button.Icon";
import Input from "../Form/Input/Input";
import ListItem from "./ListItem";
import Row from "../Row";
import StyleWrapper from "../StyleWrapper";
import { useCallback } from "react";
import { useFormContext } from "../Form/Form";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../Theme";

let propTypes = {
	index: PropTypes.number.isRequired,
	plotBandListNameInForm: PropTypes.string.isRequired,
};

let ListItemPlotBand = ({ breakpoint, index, plotBandListNameInForm }) => {
	let translate = useTranslate();
	let { setFormValue, setFormValidation } = useFormContext();
	let removePlotBand = useCallback(() => {
		setFormValue(plotBandListNameInForm, (plotBandList) => {
			if (plotBandList) {
				return [...plotBandList.slice(0, index), ...plotBandList.slice(index + 1)];
			}
		});
		setFormValidation(plotBandListNameInForm, (plotBandList) => {
			if (plotBandList) {
				return [...plotBandList.slice(0, index), ...plotBandList.slice(index + 1)];
			} else {
				return plotBandList;
			}
		});
	}, [index, plotBandListNameInForm, setFormValidation, setFormValue]);
	return (
		<ListItem padding={theme.tinySpace} size="small" unsetDefaultHeightFlag visible>
			<StyleWrapper flex="1">
				<Row>
					<StyleWrapper paddingRight="0px">
						<Column {...{ [breakpoint]: 6 }}>
							<ColorInput
								name={`${plotBandListNameInForm}[${index}].color`}
								placeholder={translate("manage.chart.selectColor")}
								size="small"
								errorTextNotVisible
								required
							/>
						</Column>
						<Column {...{ [breakpoint]: 3 }}>
							<Input
								name={`${plotBandListNameInForm}[${index}].from`}
								placeholder={translate("notifications.from")}
								type="number"
								size="small"
								errorTextNotVisible
								required
							/>
						</Column>
						<Column {...{ [breakpoint]: 3 }}>
							<Input
								name={`${plotBandListNameInForm}[${index}].to`}
								placeholder={translate("notifications.to")}
								type="number"
								size="small"
								errorTextNotVisible
								required
							/>
						</Column>
					</StyleWrapper>
				</Row>
			</StyleWrapper>
			<Block paddingLeft="25px">
				<IconButton data-testid="remove-button" color="danger" name="remove" onClick={removePlotBand} />
			</Block>
		</ListItem>
	);
};

ListItemPlotBand.propTypes = propTypes;
ListItemPlotBand.defaultProps = {
	breakpoint: "large",
};

export default ListItemPlotBand;
