import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	attachment: PropTypes.object,
};

let AttachmentError = ({ attachment }) => {
	let { filename } = attachment || emptyObject;
	let fileType = filename.endsWith("pdf") ? "PDF" : "Image";
	let errorMessage = `Failed to load ${fileType} Attachment: "${filename}". Please contact ClearPoint Support at`;

	return (
		<span>
			{errorMessage} <a href="mailto:support@clearpointstrategy.com">support@clearpointstrategy.com</a>
		</span>
	);
};

AttachmentError.propTypes = propTypes;

export default AttachmentError;
