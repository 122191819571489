import { toast } from "@clearpoint/services/toastService/index";
import http from "@clearpoint/services/httpService/index";
const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5MB

let imageUpload = async (translate, setFormError, blobInfo) => {
	try {
		let { size } = blobInfo.blob();
		if (size > MAX_FILE_SIZE) {
			throw new Error("Image size exceeds limit of 5MB");
		}

		if (setFormError) setFormError("uploading", true);
		toast.warning(translate("detailLayouts.imageUploading"));

		let formData = new FormData();
		formData.append("file", blobInfo.blob(), blobInfo.filename());

		let responseData = await http.post("/images", formData);

		toast.success(translate("components.uploadComplete"));
		return responseData[0].url;
	} catch (error) {
		toast.error(translate("components.uploadError"));
		throw error;
	} finally {
		setFormError("uploading", undefined);
	}
};
export default imageUpload;
