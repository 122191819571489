import { useMemo } from "react";
import useTemplateLookup from "./useTemplateLookup";
import isEqual from "lodash/isEqual";

let useTemplateList = ({ fieldId, outerFormObject, periodLockedFlag }) => {
	let { analyze, askMeAnything, copyEdit, exceptionReport, recommendEngine, summarize } = useTemplateLookup({
		fieldId,
		outerFormObject,
	});

	return useMemo(() => {
		let dataTemplate = ["objective", "scorecard"].includes(outerFormObject) ? summarize : analyze;

		let templateListLookup = {
			analysis: [askMeAnything, dataTemplate, copyEdit],
			recommendations: [askMeAnything, dataTemplate, recommendEngine, copyEdit],
		};

		let scorecardTemplateListLookup = {
			analysis: [dataTemplate, exceptionReport, copyEdit],
			recommendations: [dataTemplate, recommendEngine, copyEdit],
		};

		let lookup = outerFormObject === "scorecard" ? scorecardTemplateListLookup : templateListLookup;

		let templateList = lookup[fieldId];

		if (!templateList) return [];

		if (periodLockedFlag) return templateList.filter((template) => !isEqual(template, copyEdit));

		return templateList;
	}, [
		analyze,
		askMeAnything,
		copyEdit,
		exceptionReport,
		fieldId,
		outerFormObject,
		periodLockedFlag,
		recommendEngine,
		summarize,
	]);
};

export default useTemplateList;
