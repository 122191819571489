import AIAssistantProvider, { useAIAssistant } from "@clearpoint/old-theme/AI/AIAssistantProvider/AIAssistantProvider";
import AIChat from "@clearpoint/old-theme/AI/AIChat/AIChat";
import Alert from "@clearpoint/old-theme/Alert/Alert";
import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import Form from "@clearpoint/old-theme/Form/Form";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalConfirm from "@clearpoint/old-theme/Modal/Modal.Confirm";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import useAIContextPayload from "@components/Layouts/ViewLayout/ViewDetail/Detail/AIAssistants/useAIContextPayload";
import { useCallback, useMemo, useRef } from "react";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyArray, emptyObject } from "@clearpoint/utils";
import { useOnEvent } from "@clearpoint/hooks";



let propTypes = {
	closeModal: PropTypes.func,
	fieldId: PropTypes.string,
	setEditMultipleMilestonesModalState: PropTypes.func,
};

let MilestonesAIAssistantContent = ({ closeModal, setEditMultipleMilestonesModalState }) => {
	let {
		assistantState: { messageList, responseReceivedFlag, validatingFlag },
		scrollBottomContainerRef,
		sendMessageToGPT,
		setAssistantState,
	} = useAIAssistant();
	let translate = useTranslate();

	const GET_JSON_MESSAGE = "send-cps-json";

	let getJson = useCallback(() => {
		sendMessageToGPT(GET_JSON_MESSAGE);
		setAssistantState({ validatingFlag: true });
	}, [sendMessageToGPT, setAssistantState]);

	let addButtonDisabledFlag = messageList.length < 5 || !responseReceivedFlag || validatingFlag;
	let milestonesRef = useRef();
	let handleAfterSubmitAddMilestones = useCallback(
		({ aiAddMilestonesName, openModal }) => {
			setAssistantState({
				validatingFlag: false,
			});

			setEditMultipleMilestonesModalState({
				aiAddMilestonesName: aiAddMilestonesName,
				editMutipleMilestonesModalVisible: openModal,
			});
		},
		[setAssistantState, setEditMultipleMilestonesModalState]
	);
	let handleChatValidation = useCallback(
		(e) => {
			let { json, message, status } = e?.detail || emptyObject;

			if (status === "error" && message === "invalidPayload") {
				setAssistantState({ validatingFlag: false });
				return;
			}

			if (["complete", "validated"].includes(status)) {
				let milestones = json.milestones || emptyArray;
				milestonesRef.current = milestones
					.map((item) => {
						return item.milestone.replace(/\.$/, "") + ` | ${item.startDate} | ${item.endDate}`;
					})
					.join("\n");
				handleAfterSubmitAddMilestones({ aiAddMilestonesName: milestonesRef.current, openModal: true });
			}
		},
		[handleAfterSubmitAddMilestones, setAssistantState]
	);
	useOnEvent({
		eventName: "chatBotEvent",
		handlerFunction: handleChatValidation,
	});

	return (
		<>
			<ModalHeader closeButtonFlag={!validatingFlag}>
				<ModalTitle>{translate("ai.assistant.projectPlan")}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Block padding={`${theme.tinySpace} ${theme.smallSpace}`} ref={scrollBottomContainerRef}>
					<StyleWrapper marginBottom="0">
						<Alert color="primary">{translate("ai.assistant.projectPlan.description")}</Alert>
					</StyleWrapper>
					<Block>
						<AIChat milestonesFlag={true} />
					</Block>
				</Block>
			</ModalBody>
			<ModalFooter>
				<Block alignSelf="flex-end">
					<Button disabled={validatingFlag} onClick={closeModal} type="button" color="default">
						{translate("global.cancel")}
					</Button>
					<ModalOnClick
						Modal={ModalConfirm}
						formFlag={false}
						body={translate("global.confirmAddToClearPoint.plural", { objects: "global.milestones" })}
						confirmButtonText={translate("global.add")}
						confirmButtonColor="info"
						title={translate("components.addObjects", { objects: "global.milestones" })}
						onConfirm={getJson}
					>
						<Button
							type="button"
							color="primary"
							className="ai-addBtn"
							disabled={addButtonDisabledFlag}
							marginLeft={theme.space}
						>
							{translate("global.addMilestones")}
						</Button>
					</ModalOnClick>
				</Block>
			</ModalFooter>
		</>
	);
};

let MilestonesAIAssistant = ({ closeModal, fieldId, setEditMultipleMilestonesModalState }) => {
	let outerFormObject = useFormValue("object");
	let { elementStatus } = useDetailPageData() || emptyObject;
	let { periodId: elementPeriodId } = useFormValue("updates") || emptyObject;
	let periodId = elementStatus?.periodId ?? elementPeriodId;
	let contextPayload = useAIContextPayload({ fieldId, outerFormObject, periodId });
	let endpoint = "/ai/text/milestones";
	let translate = useTranslate();
	let defaultValue = useMemo(() => ({ userMessage: translate("ai.assistant.projectPlan.description") }), [translate]);

	return (
		<Form defaultValue={defaultValue}>
			<AIAssistantProvider contextPayload={contextPayload} endpoint={endpoint} startImmediatelyFlag milestonesFlag>
				<MilestonesAIAssistantContent
					closeModal={closeModal}
					setEditMultipleMilestonesModalState={setEditMultipleMilestonesModalState}
				/>
			</AIAssistantProvider>
		</Form>
	);
};

MilestonesAIAssistant.propTypes = propTypes;

export default MilestonesAIAssistant;
