import BootstrapForm from "react-bootstrap/Form";
import FormError from "../../Form/Form.Error";
import HelpText from "../../Form/Form.HelpText";
import InputController from "../../Form/InputController";
import Label from "../../Form/Form.Label";
import SelectWrapper from "../../Form/Select/SelectWrapper";
import StyleWrapper from "../../StyleWrapper";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormError from "../../Form/hooks/useFormError";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import classNames from "classnames";
import getDeepValue from "lodash/get";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { usePrevious, useStateObject } from "@clearpoint/hooks";


let StyledInput = styled.input`
	border-color: ${(props) => (props.$errorVisible ? props.theme.danger : undefined)};
	&::placeholder {
		color: ${(props) => (props.$errorVisible ? props.theme.danger : undefined)};
	}
`;

let HiddenInput = styled.input`
	display: none;
`;

let StyledFormGroup = ({ display, marginLeft, marginBottom, ...props }) => (
	<StyleWrapper display={display} fontWeight="normal" marginLeft={marginLeft} marginBottom={marginBottom}>
		<BootstrapForm.Group {...props} />
	</StyleWrapper>
);

let propTypes = {
	className: PropTypes.string,
	createableFlag: PropTypes.bool,
	"data-testid": PropTypes.string,
	defaultFirstOptionFlag: PropTypes.bool,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	description: PropTypes.string,
	errorNotVisible: PropTypes.bool,
	helpText: PropTypes.string,
	initialOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
		})
	),
	label: PropTypes.node,
	loadingFlag: PropTypes.bool,
	marginBottom: PropTypes.string,
	marginLeft: PropTypes.string,
	multipleFlag: PropTypes.bool,
	name: PropTypes.string,
	noOptionsMessage: PropTypes.string,
	options: PropTypes.array,
	persistInSession: PropTypes.string,
	preventDefaultOnChangeFlag: PropTypes.bool,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	unmountClearFlag: PropTypes.bool,
	uppercaseLabelFlag: PropTypes.bool,
	width: PropTypes.string,
};

let defaultProps = {
	defaultFlag: false,
};

let FormSelect = ({
	className,
	createableFlag,
	"data-testid": dataTestId,
	defaultFirstOptionFlag,
	defaultValue: defaultValueProp,
	description,
	errorNotVisible,
	helpText,
	initialOptions,
	label,
	loadingFlag,
	marginBottom,
	marginLeft,
	multipleFlag,
	name,
	noOptionsMessage,
	options,
	persistInSession,
	preventDefaultOnChangeFlag,
	style,
	unmountClearFlag,
	uppercaseLabelFlag,
	width,
	...props
}) => {
	let translate = useTranslate();
	let { setFormValue, formDefaultValue } = useFormContext();
	let defaultValue = getDeepValue(formDefaultValue, name) ?? defaultValueProp;
	let [{ optionsState }, setState] = useStateObject({
		optionsState: options,
	});
	options = useMemo(
		() => (initialOptions ? [...initialOptions, ...(isArray(options) ? options : [])] : options),
		[initialOptions, options]
	);
	useEffect(() => {
		setState({ optionsState: options });
	}, [options, setState]);
	let setOptions = useCallback((options) => setState({ optionsState: options }), [setState]);
	if (defaultFirstOptionFlag && defaultValue === undefined && isArray(options) && options.length > 0) {
		defaultValueProp = options[0].value;
	}
	let value = useFormValue(name);
	if (multipleFlag && value && typeof value === "string") {
		value = value.split(",");
	}
	let savedValueList = useFormValue("_saved" + name);
	let previousOptions = usePrevious(options);
	// no options
	let noOptionsFlag = !isArray(options) || options.length === 0;
	noOptionsMessage = noOptionsMessage || translate("select.noElementsFound");
	loadingFlag = loadingFlag || !options;
	// handle options changes
	useEffect(() => {
		if (createableFlag) return;
		// value not in options => set undefined, save value
		if (
			defaultValueProp !== value &&
			value !== undefined &&
			!(isArray(value) && value.length === 0) &&
			(noOptionsFlag || options.every((x) => (isArray(value) ? !value.includes(x.value) : x.value !== value)))
		) {
			setFormValue("_saved" + name, savedValueList ? [value, ...savedValueList] : [value]);
			setFormValue(name, (currentValue) => (isEqual(value, currentValue) ? undefined : currentValue));
		}
		// options load and saved value is in options => set prior value
		let savedValue =
			savedValueList &&
			options &&
			(multipleFlag
				? savedValueList.find((savedValue) =>
						savedValue?.every?.((value) => options.some((x) => x.value === value))
				  ) ||
				  savedValueList.find((savedValue) => savedValue?.some?.((value) => options.some((x) => x.value === value)))
				: savedValueList.find((savedValue) => options.some((x) => x.value === savedValue)));
		if (!noOptionsFlag && (value === undefined || isEqual(value, [])) && savedValue !== undefined) {
			if (multipleFlag) {
				// restore partial saved value
				let filteredSavedValue = savedValue.filter((value) => options.some((x) => x.value === value));
				if (!isEqual(savedValue, filteredSavedValue)) savedValue = filteredSavedValue;
			}
			setFormValue(name, (currentValue) => (value === currentValue ? savedValue : currentValue));
			setFormValue("_saved" + name, undefined);
		}
	}, [
		createableFlag,
		defaultValue,
		defaultValueProp,
		multipleFlag,
		name,
		noOptionsFlag,
		options,
		previousOptions,
		savedValueList,
		setFormValue,
		value,
	]);
	useEffect(() => {
		if (createableFlag) return;
		if (multipleFlag && options) {
			// match order of value to order of options
			let oldValue = value;
			let optionsList = options;
			let newValue =
				value && isArray(value)
					? optionsList.filter((x) => value.includes(x.value)).map((x) => x.value)
					: value
					? [value]
					: value;
			if (!isEqual(oldValue, newValue)) {
				setFormValue(name, newValue);
			}
		}
	}, [createableFlag, multipleFlag, name, options, setFormValue, value]);
	let errorVisible = useFormError(name) && !errorNotVisible;

	let selectProps = useMemo(
		() => ({
			id: name,
			errorVisible,
			className: classNames(className, "form-control"),
			errorNotVisible,
			label,
			marginBottom,
			multipleFlag,
			name,
			options: optionsState || options,
			setOptions,
			width,
			createableFlag,
			"data-testid": dataTestId || "form-select",
			...props,
		}),
		[
			className,
			createableFlag,
			dataTestId,
			errorNotVisible,
			errorVisible,
			label,
			marginBottom,
			multipleFlag,
			name,
			options,
			optionsState,
			props,
			setOptions,
			width,
		]
	);
	let formValueTransform = useCallback(
		(x) => (multipleFlag && isArray(x) ? x.map((x) => x && x.value) : x && x.value),
		[multipleFlag]
	);
	let elementValueTransform = useCallback((x) => (x === null ? "" : x), []);
	return useMemo(
		() => (
			<StyledFormGroup
				display={width ? "inline-block" : undefined}
				marginBottom={marginBottom}
				marginLeft={marginLeft}
				controlId={name}
				className={className}
				style={style}
			>
				{label && (
					<Label error={errorVisible} uppercaseFlag={uppercaseLabelFlag}>
						{label}
					</Label>
				)}
				{helpText && <HelpText error={errorVisible}>{helpText}</HelpText>}
				<InputController
					name={name}
					defaultValue={defaultValueProp}
					elementValueTransform={elementValueTransform}
					formValueTransform={formValueTransform}
					unmountClearFlag={unmountClearFlag}
					preventDefaultOnChangeFlag={preventDefaultOnChangeFlag}
					persistInSession={persistInSession}
					{...props}
				>
					{loadingFlag ? (
						<HiddenInput />
					) : noOptionsFlag ? (
						<StyledInput {...selectProps} $errorVisible={errorVisible} placeholder={noOptionsMessage} disabled />
					) : (
						<SelectWrapper {...selectProps} />
					)}
				</InputController>
				{loadingFlag && (
					<StyledInput
						id={name}
						className="form-control"
						placeholder={translate("placeholder.loading")}
						disabled
						$errorVisible={errorVisible}
						data-testid={dataTestId}
					/>
				)}
				{description && <BootstrapForm.Text className="text-muted">{description}</BootstrapForm.Text>}
				{errorVisible && <FormError name={name} />}
			</StyledFormGroup>
		),
		[
			className,
			dataTestId,
			defaultValueProp,
			description,
			elementValueTransform,
			errorVisible,
			formValueTransform,
			helpText,
			label,
			loadingFlag,
			marginBottom,
			marginLeft,
			name,
			noOptionsFlag,
			noOptionsMessage,
			persistInSession,
			preventDefaultOnChangeFlag,
			props,
			selectProps,
			style,
			translate,
			unmountClearFlag,
			uppercaseLabelFlag,
			width,
		]
	);
};

FormSelect.propTypes = propTypes;
FormSelect.defaultProps = defaultProps;

export default FormSelect;
