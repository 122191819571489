import { CookiesProvider } from "react-cookie";
import CredentialsLoader from "@clearpoint/providers/CredentialsLoader";
import { HashRouter as Router } from "react-router-dom";
import IntercomProvider from "@clearpoint/providers/Intercom";
import PusherConfig from "./pusher.config";
import PusherProvider from "@clearpoint/providers/Pusher/PusherProvider";
import Session from "@clearpoint/old-session/index";
import { OldQueryStore } from "@clearpoint/old-query-store";
import Theme from "@clearpoint/old-theme/Theme";
import { Translator } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { Suspense } from "react";
import Loading from "@clearpoint/old-theme/Loading/index";
import { createBrowserHistory } from "history";

const propTypes = { children: PropTypes.node };
const history = createBrowserHistory();

const Providers = ({ children }) => (
	<CookiesProvider>
		<OldQueryStore>
			<Router history={history}>
				<IntercomProvider>
					<Session>
						<Theme>
							<Translator>
								<CredentialsLoader>
									<PusherProvider {...PusherConfig}>
										<Suspense fallback={<Loading />}>{children}</Suspense>
									</PusherProvider>
								</CredentialsLoader>
							</Translator>
						</Theme>
					</Session>
				</IntercomProvider>
			</Router>
		</OldQueryStore>
	</CookiesProvider>
);

Providers.propTypes = propTypes;

export default Providers;
