import { useRef, useState } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import useInterval from "./useInterval";

let usePercentLoaded = (store) => {
	let storeHook = useOldQueryStore();
	if (!store) store = storeHook;
	let { getLoadingRequestCount } = store || {};
	let loadingRequestCount = getLoadingRequestCount ? getLoadingRequestCount() : 0;
	let [percentLoaded, setPercentLoaded] = useState(0);
	let percentLoadedRef = useRef(0);
	let totalRequestCountRef = useRef(loadingRequestCount);
	percentLoadedRef.current = percentLoaded;

	useInterval(
		() => {
			let oldPercentLoaded = percentLoadedRef.current;
			let totalRequestCount = totalRequestCountRef.current;
			let loadingRequestCount = getLoadingRequestCount();

			totalRequestCountRef.current = Math.max(totalRequestCount, loadingRequestCount);
			let incomingPercentLoaded = Math.round(((totalRequestCount - loadingRequestCount) * 100) / totalRequestCount);
			if (incomingPercentLoaded > oldPercentLoaded) {
				setPercentLoaded(Math.max(oldPercentLoaded, incomingPercentLoaded));
			}
		},
		percentLoaded < 100 ? 500 : null
	);

	return totalRequestCountRef.current > 3 ? Math.min(Math.max(percentLoaded, 0), 100) : undefined;
};

export default usePercentLoaded;
