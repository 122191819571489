import Button from "./Button";
import Icon from "../Icon/Icon";
import ModalOnClick from "../Modal/Modal.OnClick";
import ModalWindowOnClick from "../ModalWindow/ModalWindow.OnClick";
import PropTypes from "prop-types";
import classNames from "classnames";
import { theme } from "../Theme";

let propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string,
	onClick: PropTypes.func,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let defaultProps = {
	icon: "add",
};

let AddButtonInner = ({ children, className, icon, onClick, size, style, ...props }) => (
	<Button onClick={onClick} size={size} className={classNames(className, "js-add-button")} style={style} {...props}>
		<Icon name={icon} transform={icon === "add" ? "grow-2" : ""} marginRight={children && theme.smallSpace} />
		{children}
	</Button>
);
AddButtonInner.propTypes = propTypes;
AddButtonInner.defaultProps = defaultProps;

let addButtonPropTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	ModalWindow: PropTypes.elementType,
	Modal: PropTypes.elementType,
	objectId: PropTypes.number,
};
let AddButton = ({ children, color, ModalWindow, Modal, objectId, ...props }) =>
	ModalWindow ? (
		<ModalWindowOnClick ModalWindow={ModalWindow} objectId={objectId} {...props}>
			<AddButtonInner children={children} color={color} {...props} />
		</ModalWindowOnClick>
	) : Modal ? (
		<ModalOnClick Modal={Modal} objectId={objectId} {...props}>
			<AddButtonInner children={children} color={color} {...props} />
		</ModalOnClick>
	) : (
		<AddButtonInner children={children} color={color} {...props} />
	);

AddButton.propTypes = addButtonPropTypes;

export default AddButton;
