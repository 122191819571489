import { useCallback } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import getIndexInFormValue from "./useLinkListItemFormUtils.getIndexInFormValue";
import getUnlinkObject from "./useLinkListItemFormUtils.getUnlinkObject";

let useLinkListItemFormUtils = (link) => {
	let linkList = useFormValue("links");
	let { fieldId, object, objectId } = link;

	let indexInFormValue = getIndexInFormValue({ fieldId, linkList, object, objectId });

	let { setFormValue } = useFormContext();

	let unlinkObject = useCallback(
		() => getUnlinkObject({ indexInFormValue, linkList, setFormValue }),
		[indexInFormValue, linkList, setFormValue]
	);

	return { indexInFormValue, unlinkObject };
};

export default useLinkListItemFormUtils;
