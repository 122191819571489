import BulkSetHomepageModal from "../../Modal/Modal.BulkSetHomepage";
import Button from "../../Button/Button";
import DropdownItem from "../../Dropdown/Item/Dropdown.Item";
import Icon from "../../Icon/Icon";
import ModalOnClick from "../../Modal/Modal.OnClick";
import SplitButton from "./SplitButton";
import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { useFavorite, useHomepage } from "@clearpoint/hooks";

let propTypes = {
	favoriteFlag: PropTypes.bool,
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	page: PropTypes.string,
	scorecardId: PropTypes.number,
	scorecardFlag: PropTypes.bool,
};

let DropdownSplitButtonFavorite = ({
	favoriteFlag,
	layoutId,
	object,
	objectId,
	page,
	scorecardId,
	scorecardFlag,
	...props
}) => {
	let { get } = useOldQueryStore();
	let translate = useTranslate();
	if (layoutId && objectId) objectId = undefined;
	let profile = get({ object: "profile" });
	let options = get({ object: "options" });
	let lockHomeFlag = options?.options?.lockHome;

	let adminFlag = profile?.userType === "Administrator";
	let { favoriteFlagState, toggleFavorite } = useFavorite({
		favoriteFlag,
		layoutId,
		page,
		object,
		objectId,
		scorecardId,
	});
	let { defaultHomepageFlag, homepageFlag, toggleHomepage } = useHomepage({
		layoutId,
		page,
		object,
		objectId,
		scorecardId,
	});

	let toastLockedOptions = useCallback(() => {
		toast.warning(translate("myProfile.alertHomeLocked"));
	}, [translate]);

	let icon = homepageFlag ? "home" : favoriteFlagState ? "favorite" : "favoriteOutline";
	let canAccessFlag = !lockHomeFlag || adminFlag;

	return canAccessFlag ? (
		<SplitButton
			color="white"
			data-testid="favorite-split-button"
			icon={icon}
			iconColor="warning"
			onClick={homepageFlag ? toggleHomepage : toggleFavorite}
			{...props}
		>
			{!homepageFlag && (
				<DropdownItem onClick={toggleFavorite} icon={favoriteFlagState ? "favoriteOutline" : "favorite"}>
					{translate(favoriteFlagState ? "myProfile.removeFavorite" : "myProfile.addFavorite")}
				</DropdownItem>
			)}
			{!defaultHomepageFlag && (adminFlag || !lockHomeFlag) && (
				<DropdownItem onClick={toggleHomepage} icon="homeOutline">
					{translate(homepageFlag ? "myProfile.clearHome" : "myProfile.setAsHome")}
				</DropdownItem>
			)}
			{adminFlag && (
				<ModalOnClick
					Modal={BulkSetHomepageModal}
					layoutId={layoutId}
					object={object}
					objectId={objectId}
					scorecardId={scorecardId}
				>
					<DropdownItem icon="group">{translate("myProfile.bulkSetHome")}</DropdownItem>
				</ModalOnClick>
			)}
		</SplitButton>
	) : (
		<Button
			data-testid="favorite-split-button"
			color="white"
			onClick={homepageFlag ? toastLockedOptions : toggleFavorite}
		>
			<Icon name={icon} />
		</Button>
	);
};

DropdownSplitButtonFavorite.propTypes = propTypes;

export default DropdownSplitButtonFavorite;
