import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectBorderWidth = (props) => {
	let translate = useTranslate();
	let options = useMemo(() => [...Array(15).keys()].map((x) => x + 1 + "px").map((x) => ({ label: x, value: x })), []);
	return (
		<FormSelect
			options={options}
			placeholder={translate("options.admin.styles.border.selectBorderWidth")}
			{...props}
		/>
	);
};
export default SelectBorderWidth;
