import { isEmpty } from "lodash";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

let updateFormWithNewDefaultValue = (formDirty, newDefaultValue) => (currentFormValue) => {
	// generally: keep dirty values, overwrite others with new default values recursively
	let innerUpdate = (formDirty, newDefaultValue, currentFormValue) => {
		if (formDirty === true) return currentFormValue;
		if (isObject(currentFormValue) || isObject(newDefaultValue)) {
			let newValue = isArray(currentFormValue) || isArray(newDefaultValue) ? [] : {};
			if (isEmpty(newDefaultValue) && currentFormValue) return currentFormValue; // prevent overwriting with empty objects
			if (isArray(newValue)) return newDefaultValue; // overwrite arrays completely
			let keyList = [];
			if (isObject(currentFormValue)) keyList = [...keyList, ...Object.keys(currentFormValue)];
			if (isObject(newDefaultValue)) keyList = [...keyList, ...Object.keys(newDefaultValue)];
			for (let key of keyList) {
				if (key === "ganttData") newValue[key] = newDefaultValue?.[key];
				else newValue[key] = innerUpdate(formDirty?.[key], newDefaultValue?.[key], currentFormValue?.[key]);
			}
			return newValue;
		}
		return newDefaultValue;
	};
	let updated = innerUpdate(formDirty, newDefaultValue, currentFormValue);
	return updated;
};

export default updateFormWithNewDefaultValue;
