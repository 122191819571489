import FormSelectObject from "../../Form/Select/Select.Object";
import useFormValue from "../../Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import getDeepValue from "lodash/get";

let SelectScheduleTaskTarget = ({ name, formKey, ...props }) => {
	let translate = useTranslate();
	let formValue = useFormValue();
	let subSelectPropsLookup = useMemo(
		() => ({
			pdf: ["template", `format=pdf&recent=false&all=false`, "templateId", translate("labels.books")],
			briefingBook: ["template", `format=pdf&recent=false&all=false`, "templateId", translate("labels.books")],
			dataLoader: ["package", "include=all", "packageId", translate("schedule.dataLoader")],
			html: ["template", `format=html&recent=false&all=false`, "templateId", translate("global.htmlExports")],
			reminder: ["reminder", undefined, "reminderId", translate("global.reminders")],
			script: ["script", undefined, "scriptId", translate("schedule.customScript")],
		}),
		[translate]
	);
	let [object, queryString, valueKey, label] = subSelectPropsLookup?.[getDeepValue(formValue, formKey)] || [];

	return object ? (
		<FormSelectObject
			object={object}
			queryString={queryString}
			valueKey={valueKey}
			label={label}
			name={name}
			placeholder={translate("schedule.labels.selectObject")}
			data-testid="select-schedule-task-target"
			{...props}
		/>
	) : null;
};
export default SelectScheduleTaskTarget;
