import Block from "@clearpoint/old-theme/Block";
import CellWrapper from "../CellWrapper";
import FormFieldDisplayName from "@clearpoint/old-theme/Form/Field/Display/Name";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import HTML from "@clearpoint/old-theme/HTML";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let CategoryCell = (props) => {
	let { get } = useOldQueryStore();
	let options = get({ object: "options" });
	let includeDescriptionFlag = options?.options?.includeCategoryDescription;
	let { editCellFlag, parentName } = props;
	let name = `${parentName}.name`;
	let description = useFormValue(`${parentName}.description`);
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldName label={null} name={name} />
			) : (
				<>
					<StyleWrapper fontWeight="bold">
						<FormFieldDisplayName name={name} noLinkFlag />
					</StyleWrapper>
					{includeDescriptionFlag && (
						<Block marginTop={theme.tinySpace}>
							<HTML>{description}</HTML>
						</Block>
					)}
				</>
			)}
		</CellWrapper>
	);
};
CategoryCell.propTypes = propTypes;

export default CategoryCell;
