import { getEnvironment } from "@clearpoint/utils";

let validateGetParameters = (parameters) => {
	let env = getEnvironment();

	if (env === "prod") return;

	if (typeof parameters !== "object") {
		throw new Error("Invalid call to store: get. " + JSON.stringify(parameters));
	}

	let { object, parentId, parent } = parameters;

	if (object === "element") {
		if (!parent || !parentId) {
			throw new Error(
				"Invalid call to store: get. Object of type 'element' must include parent, parentId " +
					JSON.stringify(parameters)
			);
		}
	}
};

let typeDict = {
	get: validateGetParameters,
};

let validateParameters = (parameters, type) => {
	if (!parameters) throw new Error("Invalid call to store: " + type + ". " + JSON.stringify(parameters));
	let validateFunc = typeDict[type];
	if (validateFunc) validateFunc(parameters);
	return parameters;
};

export default validateParameters;
