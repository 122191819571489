let getItemsPerPage = ({ childMeasure, parentMeasure, parentWidthMeasure }) => {
	if (!parentMeasure || !childMeasure) return;
	let parentWidth = parentWidthMeasure?.width || parentMeasure.width;
	let width = Math.floor(parentWidth / childMeasure.width);
	let height = Math.floor((parentMeasure.height - 1) / childMeasure.height);
	if (height <= 0) {
		return width || 1;
	}
	if (width === 0) {
		return height || 1;
	}
	return width * height;
};

export default getItemsPerPage;
