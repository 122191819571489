import { toast } from "@clearpoint/services/toastService/index";

let handleTabChange =
	({ formValidFlag, loadingFlag, fieldListLoadingError, noFormFlag, setNextTab, setSelectedTab, translate }) =>
	({ onClick, tabIndex }) => {
		if (loadingFlag || fieldListLoadingError) return;
		if (!noFormFlag && !formValidFlag && !fieldListLoadingError) {
			toast.error(translate("toaster.messages.global.makeCorrections"), {
				toastId: "toaster.messages.global.makeCorrections",
			});
			return false;
		} else if (onClick) {
			setNextTab(tabIndex);
			onClick(() => setSelectedTab(tabIndex), tabIndex);
		} else {
			setSelectedTab(tabIndex);
		}
	};

export default handleTabChange;
