import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import PodLinkListItem from "../../LinksPod/PodLinkListItem";

let LinkedElement = ({ fieldId }) => {
	let { get } = useOldQueryStore();
	let formValue = useFormValue();
	let object = formValue?.object;
	let periodId = formValue?.updates?.periodId;

	let link;
	if (object === "milestone") {
		let initiativeId = formValue?.initiativeId;
		link = initiativeId && periodId && get({ object: "initiative", objectId: initiativeId, periodId });
	} else {
		let objectId = formValue?.[object + "Id"];
		link = objectId && periodId && get({ object, objectId, periodId });
	}

	return link ? <PodLinkListItem podFieldId={fieldId} link={link} linkedElementPodFlag /> : null;
};

export default LinkedElement;
