import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString } from "@clearpoint/utils";

import Block from "../Block";
import { theme } from "../Theme";
import Action from "./Action";
import MentionUser from "./MentionUser";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
};

let AddComment = ({ layoutId, object, objectId }) => {
	let { newCommentBoxVisible } = useLocalState();
	let [value, setValue] = useState("");
	let [submittingFlag, setSubmittingFlag] = useState(false);
	let { set } = useOldQueryStore();
	let translate = useTranslate();

	let updateValue = useCallback((e) => setValue(e.target.value), []);

	let addComment = useCallback(() => {
		setSubmittingFlag(true);
		set({
			object: "comment",
			queryString: buildQueryString({ object, layoutId, objectId }),
			data: { comment: value },
		}).then(() => {
			setValue("");
			setSubmittingFlag(false);
		});
	}, [layoutId, object, objectId, set, value, setValue, setSubmittingFlag]);

	let addButtonDisabledFlag = !value?.replace(/\s+/m, "") || submittingFlag;

	return newCommentBoxVisible ? (
		<Block flex="0 0 auto" marginTop="2px">
			<MentionUser value={value} updateValue={updateValue} />
			<Block textAlign="right" margin={`${theme.space} 0px`}>
				<Action
					name="send"
					label={translate("global.addComment")}
					blockFlag={true}
					spinFlag={submittingFlag}
					disabledFlag={addButtonDisabledFlag}
					onClick={addComment}
				/>
			</Block>
		</Block>
	) : null;
};

AddComment.propTypes = propTypes;

export default AddComment;
