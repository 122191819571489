import Input from "../Input";
import useFormValueTransform from "./useFormValueTransform";
import useUpdateStyleValue from "./useUpdateStyleValue";
import useElementValueTransform from "./useElementValueTransform";

let FormInputTextEditor = ({ name, disabled, ...props }) => {
	let formValueTransform = useFormValueTransform(name);
	let elementValueTransform = useElementValueTransform(name);
	useUpdateStyleValue(name);
	return (
		<Input
			disabled={disabled}
			textEditorFlag
			elementValueTransform={elementValueTransform}
			formValueTransform={formValueTransform}
			name={name}
			secureHtml
			{...props}
		/>
	);
};
export default FormInputTextEditor;
