import { useCallback, useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import { getClipboardDataAsync } from "@clearpoint/utils";

let useContextMenu = (contextMenu, modalState, columns) => {
	let translate = useTranslate();
	let setModalState;
	if (modalState) [, setModalState] = modalState;
	let getMeasureSeriesId = useCallback(
		(cell) => {
			let column = cell[0].start.col;
			let editMeasureSeriesId = columns?.[column]?.data?.slice(6);
			if (editMeasureSeriesId) return +editMeasureSeriesId;
		},
		[columns]
	);

	let nonSeriesColumn = useCallback(function () {
		let selected = this.getSelectedLast();
		return [0, 1, 2].includes(selected[1]);
	}, []);

	let contextMenuItemLookup = useMemo(
		() => ({
			copy: { name: `<i class='far fa-fw fa-copy'></i> &nbsp;${translate("global.copy")}` },
			editSeries: {
				name: `<i class='fa fa-fw fa-pencil-alt'></i> &nbsp;${translate("global.editSeries")}`,
				callback: function (_name, cell) {
					let editMeasureSeriesId = getMeasureSeriesId(cell);
					setModalState({
						editSeriesModalVisible: true,
						editMeasureSeriesId,
					});
				},
				hidden: nonSeriesColumn,
			},
			paste: {
				name: `<i class='far fa-fw fa-paste'></i> &nbsp;${translate("global.paste")}`,
				callback: function () {
					getClipboardDataAsync(translate)
						.catch((error) => {
							return error;
						})
						.then((text) => {
							text = text?.replace(/\r\n$/, "");
							return this.getPlugin("CopyPaste").paste(text);
						});
				},
				hidden: nonSeriesColumn,
			},
			pasteTransposed: {
				name: `<i class='far fa-fw fa-paste'></i> &nbsp;${translate("global.pasteTransposed")}`,
				callback: function () {
					getClipboardDataAsync(translate)
						.catch((error) => {
							return error;
						})
						.then((text) => {
							text = text.replace(/\r\n$/, "");
							text = text.replace(/\r/g, "");
							let rowArray = text.split("\n");
							let grid = rowArray.map((row) => row.split("\t"));
							let transposedGrid = grid[0].map((_cell, i) => grid.map((row) => row[i]));
							let transposedRowArray = transposedGrid.map((row) => row.join("\t"));
							let transposedText = transposedRowArray.join("\n");
							this.getPlugin("CopyPaste").paste(transposedText);
						});
				},
				hidden: nonSeriesColumn,
			},
			hr: "---------",
			hr2: "---------",
			// undo and redo are broken.  Not sure if this has to do with the form component or what
			// tried this and it failed: https://handsontable.com/docs/react-modules/#overview
			undo: null,
			redo: null,
			viewCalculationMap: {
				name: `<i class='fa fa-fw fa-tv'></i> &nbsp;${translate("edit.map.viewCalculationMap")}`,
				callback: function (_name, cell) {
					let editMeasureSeriesId = getMeasureSeriesId(cell);
					setModalState({
						calculationMapModalVisible: true,
						editMeasureSeriesId,
					});
				},
				hidden: nonSeriesColumn,
			},
		}),
		[getMeasureSeriesId, setModalState, translate, nonSeriesColumn]
	);

	let generatedContextMenu = useMemo(() => {
		if (!contextMenu) return;
		let detailedContextMenu = {};
		contextMenu.forEach((item) => {
			if (contextMenuItemLookup[item]) detailedContextMenu[item] = contextMenuItemLookup[item];
		});
		return { items: detailedContextMenu };
	}, [contextMenu, contextMenuItemLookup]);

	return generatedContextMenu;
};

export default useContextMenu;
