let getResolveDebounced = (debouncedResult, key) =>
	new Promise((resolve) => {
		let interval = setInterval(() => {
			if (debouncedResult[key]) {
				resolve(debouncedResult[key]);
				debouncedResult[key] = undefined;
				clearInterval(interval);
			}
		}, 20);
	});

export default getResolveDebounced;
