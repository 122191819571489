import axios from "axios";
import setDeepValue from "lodash/set";
import unset from "lodash/unset";

let luceeAxios = axios.create({
	baseURL: "/api/v1",
});

let expressAxios = axios.create({
	baseURL:  "/api/v2",
});

let deleteAxiosProperty = (path) => {
	unset(luceeAxios, path);
	unset(expressAxios, path);
};

let setAxiosProperty = (path, value) => {
	setDeepValue(luceeAxios, path, value);
	setDeepValue(expressAxios, path, value);
};

export { deleteAxiosProperty, expressAxios, luceeAxios, setAxiosProperty };
