import Icon from "../../Icon/Icon";
import Tooltip from "../../Tooltip";
import { useCallback, useState } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	message: PropTypes.string,
};

let CopyMessageToClipboardButton = ({ message }) => {
	let translate = useTranslate();
	let [iconName, setIconName] = useState("clipboard");
	let copyMessageToClipboard = useCallback(async () => {
		try {
			await navigator.clipboard.writeText(message);
			setIconName("completed");
			setTimeout(() => {
				setIconName("clipboard");
			}, 3000);
		} catch (error) {
			console.error("Copy failed:", error);
		}
	}, [message]);

	return (
		<Tooltip placement="top" text={translate("ai.assistant.copyMessageToClipboard")}>
			<Icon marginLeft={theme.tinySpace} name={iconName} color="primary" onClick={copyMessageToClipboard} />
		</Tooltip>
	);
};

CopyMessageToClipboardButton.propTypes = propTypes;

export default CopyMessageToClipboardButton;
