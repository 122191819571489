import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import Modal from "@clearpoint/old-theme/Modal/Modal";
import ModalBody from "@clearpoint/old-theme/Modal/Modal.Body";
import ModalFooter from "@clearpoint/old-theme/Modal/Modal.Footer";
import ModalHeader from "@clearpoint/old-theme/Modal/Modal.Header";
import ModalTitle from "@clearpoint/old-theme/Modal/Modal.Title";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let displayTypes = {
	acceptOpenAIAgreement: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool,
	onCancel: PropTypes.func,
	submittingFlag: PropTypes.bool,
};

let OpenAIAgreementModalDisplay = ({ acceptOpenAIAgreement, close, modalVisible, onCancel, submittingFlag }) => {
	let translate = useTranslate();
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<Block textAlign="left" margin="0">
					<ModalTitle>OpenAI Subprocessor Acknowledgment</ModalTitle>
				</Block>
			</ModalHeader>
			<ModalBody>
				<Block
					fontSize=".875rem"
					fontFamily='"Tahoma","sans-serif"'
					lineHeight="115%"
					maxHeight="30vh"
					padding=".5rem"
					overflowY="scroll"
					$style={"li { margin-bottom: 0.5rem; } ul { padding-left:1.25rem; }"}
				>
					<p>
						This Subprocessor Acknowledgment is entered into by you, as a customer of Ascendant Strategy
						Management Group, LLC. If you are accessing the ClearPoint Strategy service on behalf of your company,
						you represent that you are authorized to enter into this acknowledgment on behalf of your company, and
						all references to “you” reference your company. By electronically accepting or otherwise agreeing to
						this Subprocessor Acknowledgment, you acknowledge and agree that:
					</p>
					<ul>
						<li>ClearPoint’s OpenAI feature is a no-charge experimental feature.</li>
						<li>
							By choosing to use this feature, you are submitting your data (as selected) to OpenAI OpCo, LLC,
							whose address is at 3180 18th Street, San Francisco, California 94110 (“OpenAI”).
						</li>
						<li>
							OpenAI will process the data for the purpose of providing AI-generated text in support of this
							feature. ClearPoint makes no guarantees as to the accuracy of the feature’s AI generated
							recommendations.
						</li>
						<li>OpenAI will be a subprocessor of any personal data you submit.</li>
					</ul>
				</Block>
			</ModalBody>
			<ModalFooter>
				<Button onClick={onCancel} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button onClick={acceptOpenAIAgreement} color="primary" disabled={submittingFlag}>
					{submittingFlag ? translate("button.saving") : "I Accept"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

OpenAIAgreementModalDisplay.propTypes = displayTypes;

let propTypes = {
	close: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool,
	navigateBackOnCancelFlag: PropTypes.bool,
	user: PropTypes.shape({
		userId: PropTypes.number.isRequired,
	}),
};

let OpenAIAgreementModal = ({ close, modalVisible, navigateBackOnCancelFlag, user }) => {
	let { clear, get, set } = useOldQueryStore();
	let [submittingFlag, setSubmittingFlag] = useState(false);

	let { userId: profileUserId } = get({ object: "profile" }) || emptyObject;
	let userId = user?.userId || profileUserId;

	let acceptOpenAIAgreement = useCallback(async () => {
		setSubmittingFlag(true);
		await set({
			object: "user",
			objectId: userId,
			data: {
				openAIAccepted: true,
				openAIAcceptedDate: new Date().toISOString(),
			},
		});

		clear({ object: "profile" });
		clear({ object: "user", objectId: userId });
	}, [clear, set, userId]);

	let history = useHistory();

	let onCancel = useCallback(() => {
		if (close) close();
		if (navigateBackOnCancelFlag) history.goBack();
	}, [close, history, navigateBackOnCancelFlag]);

	return (
		<OpenAIAgreementModalDisplay
			modalVisible={modalVisible}
			close={close}
			onCancel={onCancel}
			acceptOpenAIAgreement={acceptOpenAIAgreement}
			submittingFlag={submittingFlag}
		/>
	);
};

OpenAIAgreementModal.propTypes = propTypes;

export default OpenAIAgreementModal;
