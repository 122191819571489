import { useState } from "react";
import PropTypes from "prop-types";

import { useEffectOnce } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray, emptyObject } from "@clearpoint/utils";

import ChartPreview from "../../../Chart/Chart.Preview";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	name: PropTypes.string,
};

let ChartDisplay = ({ name }) => {
	let translate = useTranslate();
	let { excelFlag } = useOldSession().session;
	let value = useFormValue(name);
	let { setFormValue } = useFormContext();
	let { getPromise } = useOldQueryStore();

	// eslint-disable-next-line no-unused-vars
	let [_scorecardId, measureId, chartId] = value?.split(",") || emptyArray;

	let [chartValidFlag, setChartValidFlag] = useState(!chartId);
	useEffectOnce(() => {
		if (chartId && chartId !== "undefined") {
			getPromise({ object: "chart", objectId: +chartId }).then((res) => {
				if (res && !res?.active) {
					setFormValue(`${name}`, "undefined,undefined,undefined");
					setFormValue(`_chart${name}`, emptyObject);
					setChartValidFlag(false);
				} else {
					setChartValidFlag(true);
				}
			});
		}
	});

	return (
		<span>
			{excelFlag
				? translate("error.chartsUnavailable")
				: measureId && chartId && chartValidFlag && <ChartPreview chartId={+chartId} measureId={+measureId} />}
		</span>
	);
};

ChartDisplay.propTypes = propTypes;
export default ChartDisplay;
