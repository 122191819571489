import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import LayoutAccessTypeFormSelect from "@clearpoint/old-theme/Form/Select/Select.LayoutAccessType";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	close: PropTypes.func,
	modalVisible: PropTypes.bool,
};

let ReportOptionsModal = ({ close, modalVisible }) => {
	let translate = useTranslate();
	let groupList = useFormValue("groups");
	let accessType = useFormValue("accessType");

	let nameList = useMemo(() => {
		let groupNameList = groupList?.map((x, i) => `groups[${i}].selected`);
		return ["accessType", ...(groupNameList ?? []), "reportOptions.dashboardTemplate", "reportOptions.hideTitle"];
	}, [groupList]);

	return modalVisible ? (
		<FormModal
			close={close}
			modalVisible
			nameList={nameList}
			title={translate("layout.reportOptions")}
			closeButtonText={translate("global.save")}
		>
			<LayoutAccessTypeFormSelect
				label={translate("options.accessType")}
				name="accessType"
				restrictedFlag={groupList && groupList.length > 0}
			/>
			{accessType === "restricted" && (
				<FormGroup title={translate("layout.userGroups")} paddingBottom={theme.tinySpace}>
					{groupList?.map((x, i) => (
						<FormCheck key={x.groupId} name={`groups[${i}].selected`} marginBottom="0">
							{x.name}
						</FormCheck>
					))}
				</FormGroup>
			)}
			<FormCheck name="reportOptions.dashboardTemplate">{translate("layout.includeInLibrary")}</FormCheck>
			<FormCheck name="reportOptions.hideTitle">{translate("layout.hidePageTitle")}</FormCheck>
		</FormModal>
	) : null;
};

ReportOptionsModal.propTypes = propTypes;
export default ReportOptionsModal;
