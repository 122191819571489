import AssistantTemplate from "./AssistantTemplate";
import Modal from "@clearpoint/old-theme/Modal/Modal";
import OpenAIAgreementModal from "@components/AI/OpenAIAgreementModal/index";
import TemplateChooser from "./TemplateChooser";
import PropTypes from "prop-types";
import useAgreementModalVisible from "@components/AI/useAgreementModalVisible";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useState } from "react";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	close: PropTypes.func.isRequired,
	fieldId: PropTypes.oneOf(["analysis", "comments", "milestones", "recommendations"]).isRequired,
	fieldNameInForm: PropTypes.string.isRequired,
	modalVisible: PropTypes.bool.isRequired,
	periodLockedFlag: PropTypes.bool,
};

let AIAssistantModal = ({ close, fieldId, fieldNameInForm, modalVisible, periodLockedFlag }) => {
	let outerFormObject = useFormValue("object");
	let [template, setTemplate] = useState(null);

	let agreementModalVisible = useAgreementModalVisible();

	let { elementStatus } = useDetailPageData() || emptyObject;

	let { periodId: elementPeriodId } = useFormValue("updates") || emptyObject;

	let periodId = elementStatus?.periodId ?? elementPeriodId;

	return (
		<Modal close={close} modalVisible={modalVisible}>
			{template ? (
				<AssistantTemplate
					close={close}
					fieldId={fieldId}
					fieldNameInForm={fieldNameInForm}
					outerFormObject={outerFormObject}
					periodId={periodId}
					periodLockedFlag={periodLockedFlag}
					template={template}
				/>
			) : (
				<TemplateChooser
					close={close}
					fieldId={fieldId}
					outerFormObject={outerFormObject}
					periodLockedFlag={periodLockedFlag}
					setTemplate={setTemplate}
				/>
			)}
			<OpenAIAgreementModal close={close} modalVisible={agreementModalVisible} />
		</Modal>
	);
};

AIAssistantModal.propTypes = propTypes;
export default AIAssistantModal;
