import { getLinkChartId } from "./idHandler";

let mergeLinks = (currentList, subLinkList) => {
	if (!subLinkList) return currentList;
	subLinkList = subLinkList.filter(
		(x, idx, self) => idx === self.findIndex((y) => getLinkChartId(y) === getLinkChartId(x))
	);
	if (!currentList) return subLinkList;
	let currentLinkIdList = currentList.map((x) => getLinkChartId(x));
	return [...currentList, ...subLinkList.filter((x) => !currentLinkIdList.includes(getLinkChartId(x)))];
};

export default mergeLinks;
