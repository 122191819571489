import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";

let removeEmpty = (object, keepArrayFlag) => {
	let windowObject = window || global.window;
	let File = windowObject.File || function () {};
	let removeEmptyInner = (object, key) => {
		let value = object[key];
		if (value && typeof value === "object" && !(value instanceof File)) {
			if (Object.isFrozen(value)) {
				object[key] = isArray(value) ? [...value] : { ...value };
				value = object[key];
			}
			for (let key in value) {
				removeEmptyInner(value, key);
			}
			if (!isArray(value) && isEmpty(value)) {
				delete object[key];
			}
			if (!keepArrayFlag && isArray(value) && value.every((x) => x === undefined)) {
				delete object[key];
			}
		} else if (value === undefined) {
			delete object[key];
		}
	};
	if (Object.isFrozen(object)) {
		object = isArray(object) ? [...object] : { ...object };
	}
	for (let key in object) {
		removeEmptyInner(object, key);
	}
	return object;
};
export default removeEmpty;
