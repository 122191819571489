let getStyleValue = ({ replaceCommaSeparatedSelector, styleHTML }) =>
	!styleHTML
		? styleHTML
		: styleHTML
				.replace(/\n/g, "")
				.replace(
					/<style>\s*?(\S.*?){/,
					(_match, selector) => `<style> ${replaceCommaSeparatedSelector(selector)} {`
				)
				.replace(/}\s*?(\S.*?){/, (_match, selector) => `} ${replaceCommaSeparatedSelector(selector)} {`);

export default getStyleValue;
