import cloneDeep from "lodash/cloneDeep";

import { deepFreeze } from "@clearpoint/utils";

import refreshState from "./Store.refreshState";

let handleClear = ({ action, newState, ListOnlyFlag, stopPropagationFlag }) => {
	if (action.object && action.objectId && !action.parentId) {
		for (const element of newState) {
			let newStateItem = cloneDeep(element);
			let ids = {};
			if (newStateItem) {
				for (let key of ["object", "parent", "objectId", "parentId", "scorecardId"]) {
					let value = newStateItem[key] || newStateItem.data?.[key];
					if (value) ids[key] = value;
				}
				if (ids.parent && ids.parentId && ids.objectId === action.objectId && ids.object === action.object) {
					action.parent = ids.parent;
					action.parentId = ids.parentId;
					action.scorecardId = ids.scorecardId;
					break;
				} else if (ids.objectId === action.objectId && ids.object === action.object && ids.scorecardId) {
					action.scorecardId = ids.scorecardId;
					break;
				}
			}
		}
	}
	let triggerRefresh = (i) => {
		if (newState[i]?.object === "language") return;
		try {
			document.dispatchEvent(new CustomEvent("stale", { detail: newState[i] }));
		} catch (error) {
			console.log(error);
		}
		newState[i] = { ...newState[i], _REFRESH: true };
		deepFreeze(newState[i]);
	};

	refreshState({ ListOnlyFlag, action, newState, stopPropagationFlag, triggerRefresh });

	if (action.periodId) {
		action.reportPeriodId = action.periodId;
		delete action.periodId;
		refreshState({ ListOnlyFlag, action, newState, stopPropagationFlag, triggerRefresh });
	}
};

export default handleClear;
