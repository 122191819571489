var _interopRequireDefault = require("babel-runtime/helpers/interop-require-default")["default"];

Object.defineProperty(exports, "__esModule", {
	value: true,
});

var _Checkbox = require("./Checkbox");

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _Radio = require("./Radio");

var _Radio2 = _interopRequireDefault(_Radio);

var _RadioGroup = require("./RadioGroup");

var _RadioGroup2 = _interopRequireDefault(_RadioGroup);

exports.default = {
	Checkbox: _Checkbox2["default"],
	Radio: _Radio2["default"],
	RadioGroup: _RadioGroup2["default"],
};
module.exports = exports.default;
