import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import AlertApproval from "@clearpoint/old-theme/Alert/Alert.Approval";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormSelectField from "@clearpoint/old-theme/Form/Select/Select.Field";

import { useFieldList } from "@clearpoint/hooks";

import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	addFlag: PropTypes.bool,
	objectIdList: PropTypes.array,
};

let EditFieldsTab = ({ addFlag, objectIdList }) => {
	let scorecardId = useFormValue("scorecardId");
	let sessionScorecardId = useOldSession()?.session?.scorecardId;
	if (!scorecardId) scorecardId = sessionScorecardId;
	let fieldList = useFieldList({ object: "actionItem", addFlag, multipleFlag: true, scorecardId });
	let defaultFieldList = useMemo(() => (addFlag ? fieldList.filter((x) => x.required) : []), [addFlag, fieldList]);
	let selectedFieldList = useFormValue("_selectedFieldList");
	let { formValue } = useFormContext();
	let displayedFieldList = useMemo(
		() =>
			fieldList && [...defaultFieldList, ...fieldList?.filter((field) => selectedFieldList?.includes(field.value))],
		[defaultFieldList, selectedFieldList, fieldList]
	);

	useEffect(() => {
		if (selectedFieldList?.includes("tags") && !formValue.tags) {
			formValue.tags = [];
		}
	}, [formValue, selectedFieldList]);

	return (
		<>
			<AlertApproval scorecardId={scorecardId} type={"updates"} />
			<FormSelectField
				addFlag={addFlag}
				object="actionItem"
				fieldList={fieldList}
				name="_selectedFieldList"
				multipleFlag
			/>
			{displayedFieldList?.map((field) => (
				<FormField
					editFlag={!addFlag}
					fieldId={field.value}
					key={field.value}
					label={field.label}
					object="actionItem"
					objectIdList={objectIdList}
					editMultipleFlag={!addFlag}
					required={field.required}
				/>
			))}
		</>
	);
};
EditFieldsTab.propTypes = propTypes;
export default EditFieldsTab;
