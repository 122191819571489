import { createContext, useContext, useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

let ChecklistContext = createContext();

let propTypes = { children: PropTypes.node };

let ChecklistProvider = ({ children, data }) => {
	let [checklist, setChecklist] = useState([]);

	let filteredChecklist = useMemo(
		() =>
			checklist
				.filter((check) => !data || data.find((item) => check.id === item.objectId || check.id === item.userId))
				.filter((check) => !check.unmountedFlag),
		[checklist, data]
	);
	let checkedObjectIdList = useMemo(
		() => checklist.filter((x) => x.checked).map((x) => x.id || x.userId),
		[checklist]
	);

	useEffect(() => {
		if (data) {
			setChecklist((checklist) => {
				if (data.every((x) => checklist.some((check) => check.id === x.objectId || check.id === x.userId)))
					return checklist;
				checklist = cloneDeep(checklist);
				for (let item of data) {
					checklist.push({ id: item.objectId || item.userId, checked: false, unmountedFlag: true });
				}
				return checklist;
			});
		}
	}, [data]);

	return useMemo(
		() => (
			<ChecklistContext.Provider
				value={{
					checklist,
					count: checklist.length || data?.length,
					setChecklist,
					checkedObjectIdList,
					filteredChecklist,
				}}
			>
				{children}
			</ChecklistContext.Provider>
		),
		[checkedObjectIdList, checklist, children, data, filteredChecklist]
	);
};

ChecklistProvider.propTypes = propTypes;

export default ChecklistProvider;
export const useChecklist = () => useContext(ChecklistContext);
