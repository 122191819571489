import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import getLoadingFlag from "./useObjectState.getLoadingFlag";
import setObjectId from "./useObjectState.setObjectId";

let useObjectState = ({ defaultValue, newObjectFlag, object, props, queryString, scorecardId }) => {
	let { get, isLoading } = useOldQueryStore();
	// object state
	let objectId = props.objectId;
	let noObjectIdPropFlag = !Object.keys(props).includes("objectId");
	if (!noObjectIdPropFlag && !objectId && object !== "detailLayout") newObjectFlag = true;

	// objectId setter
	let setObjectIdCallback = useMemo(() => setObjectId(props), [props]);

	// loading
	let loadingFlag = getLoadingFlag({
		defaultValue,
		props,
		object,
		objectId,
		get,
		isLoading,
		scorecardId,
		queryString,
	});

	return {
		loadingFlag,
		newObjectFlag,
		noObjectIdPropFlag,
		objectId,
		setObjectId: setObjectIdCallback,
	};
};
export default useObjectState;
