import DragAndDropTableCell from "@clearpoint/old-theme/DragAndDropTable/DragAndDropCell";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormSelectLayoutPeriod from "@clearpoint/old-theme/Form/Select/Select.LayoutPeriod";
import FormSelectStatusDisplay from "@clearpoint/old-theme/Form/Select/Select.StatusDisplay";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { getEditStatusDisplayFlag } from "@clearpoint/utils";


let propTypes = {
	name: PropTypes.string,
};
let FieldColumn = ({ name }) => {
	let translate = useTranslate();
	let field = useFormValue(name);
	let { fieldId, object, updateMode } = field;
	let periodFlag = updateMode || (fieldId === "name" && !["scorecard", "measureSeries"].includes(object));
	let seriesFlag = updateMode && fieldId === "seriesData";
	let showSelectStatusDisplayFlag = getEditStatusDisplayFlag(field);

	return (
		<DragAndDropTableCell className="field-column-periods">
			{periodFlag && (
				<FormSelectLayoutPeriod
					defaultValue="cp"
					fieldId={fieldId}
					fixedHeightFlag
					label={translate("manage.chart.periodsToDisplay")}
					marginBottom={theme.smallSpace}
					name={`${name}.periods.periodDisplay`}
					periodCount={`${name}.periods.periodCount`}
					periodGroup={`${name}.periods.periodGroupId`}
					selectedPeriods={`${name}.periods.selectedPeriods`}
					uppercaseLabelFlag
				/>
			)}
			{showSelectStatusDisplayFlag && (
				<>
					<FormCheck marginBottom={theme.tinySpace} name={`${name}.periods.hideRepeated`}>
						{translate("tabGridColumns.hideRepeated")}
					</FormCheck>
					<FormSelectStatusDisplay
						label={translate(seriesFlag ? "tabGridColumns.seriesDisplay" : "layout.statusDisplay")}
						name={`${name}.statusDisplay`}
						uppercaseLabelFlag
					/>
				</>
			)}
		</DragAndDropTableCell>
	);
};
FieldColumn.propTypes = propTypes;
export default FieldColumn;
