import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";

import CellWrapper from "../CellWrapper";

let NoAccessCell = (props) => {
	let translate = useTranslate();
	return (
		<CellWrapper {...props}>
			<Block
				ellipsisFlag
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				color={theme.muted}
				height="24px"
			>
				<Block display="flex" alignItems="center" justifyContent="center">
					<Icon name="locked" marginRight={theme.tinySpace} />
					<div>{translate("userTypes.user7")}</div>
				</Block>
			</Block>
		</CellWrapper>
	);
};
export default NoAccessCell;
