import { useCallback } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import Loading from "@clearpoint/old-theme/Loading/index";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import ViewDashboard from "./ViewDashboard";
import Toolbar from "./ViewDashboard/Toolbar";
import ViewDetail from "./ViewDetail";
import ViewMap from "./ViewMap";
import EditSplitButton from "./ViewReference/EditSplitButton";
import FavoriteSplitButton from "./ViewReference/FavoriteSplitButton";
import Title from "./ViewReference/Title";
import ViewReport from "./ViewReport";
import { useOldSession } from "@clearpoint/old-session/index";
import LocalStateProvider from "@clearpoint/providers/LocalStateProvider";
import NotFound from "@components/NotFound";

let lookup = {
	dashboard: ViewDashboard,
	detail: ViewDetail,
	gantt: ViewReport,
	grid: ViewReport,
	map: ViewMap,
	matrix: ViewReport,
};

let propTypes = {
	layoutId: PropTypes.number,
	name: PropTypes.string,
	object: PropTypes.string,
};

let ViewLayout = ({ layoutId, name: nameProp, object }) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	if (!object) object = session.object;
	if (!layoutId) layoutId = session.layoutId;
	let { periodId } = session;

	let layoutData =
		object &&
		layoutId &&
		periodId &&
		get({
			object: `${object}Layout`,
			objectId: layoutId,
			periodId,
		});
	let notFoundFlag = layoutData?.code === 404 || isEqual(layoutData, []) || layoutData?.active === false;
	let { name, objectId, referenceLayoutId, referenceLayoutObject, reportType } = layoutData || {};
	let referenceFlag = !!(referenceLayoutId && referenceLayoutObject);
	let referenceLayout = referenceFlag && layoutData;
	if (referenceFlag) {
		let activeFlag = true;
		layoutData =
			session.layoutId &&
			get({
				object: `${referenceLayoutObject}Layout`,
				objectId: referenceLayoutId,
				periodId,
			});
		({
			active: activeFlag,
			layoutId,
			object,
			objectId,
			referenceLayoutId,
			referenceLayoutObject,
			reportType,
		} = layoutData || {});
		if (activeFlag === false) notFoundFlag = true;
	}
	let Component = lookup[reportType] || Loading;
	let ReferenceTitle = useCallback(() => <Title name={name} />, [name]);
	let ReferenceEditSplitButton = useCallback(() => <EditSplitButton layout={referenceLayout} />, [referenceLayout]);
	let ReferenceFavoriteSplitButton = useCallback(
		() => <FavoriteSplitButton layout={referenceLayout} />,
		[referenceLayout]
	);

	return notFoundFlag ? (
		<>
			{referenceFlag && (
				<LocalStateProvider
					ReferenceTitle={ReferenceTitle}
					ReferenceEditSplitButton={ReferenceEditSplitButton}
					ReferenceFavoriteSplitButton={ReferenceFavoriteSplitButton}
				>
					<Toolbar />
				</LocalStateProvider>
			)}
			<NotFound />
		</>
	) : (
		<Component
			customFieldFlag={!!nameProp}
			name={nameProp}
			layoutId={layoutId || session.layoutId}
			object={object || session.object}
			objectId={objectId || session.objectId}
			ReferenceTitle={referenceFlag ? ReferenceTitle : undefined}
			ReferenceEditSplitButton={referenceFlag ? ReferenceEditSplitButton : undefined}
			ReferenceFavoriteSplitButton={referenceFlag ? ReferenceFavoriteSplitButton : undefined}
		/>
	);
};
ViewLayout.propTypes = propTypes;

export default ViewLayout;
