import Aggregation from "./Aggregation/Aggregation";
import Column from "../../../Column";
import FormLabelLeft from "../../../Form/Form.Label.Left";
import Row from "../../../Row";
import SelectCurrentOrSpecificElement from "../../../Form/Select/Select.CurrentOrSpecificElement";
import SelectSeries from "./SelectSeries";
import SpecificSeries from "./SpecificSeries/SpecificSeries";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";

let CurrentOrSpecificSeries = () => {
	let translate = useTranslate();
	let formValue = useFormValue();
	return (
		<>
			<Row>
				<Column medium={6}>
					<FormLabelLeft label={translate("global.element")}>
						<SelectCurrentOrSpecificElement
							name="_element"
							placeholder={translate("global.element")}
							defaultValue="current"
						/>
					</FormLabelLeft>
				</Column>
			</Row>
			<Row>
				{formValue._element === "current" && (
					<Column medium={6}>
						<FormLabelLeft label={translate("global.series")}>
							<SelectSeries name="_seriesName" />
						</FormLabelLeft>
					</Column>
				)}
				{formValue._element === "specific" && <SpecificSeries name="_seriesName" />}
				{formValue._seriesName && <Aggregation name="_aggregation" seriesName="_seriesName" />}
			</Row>
		</>
	);
};
export default CurrentOrSpecificSeries;
