let chartLegendPositionLookup = {
	bottomRight: {
		align: "right",
		verticalAlign: "bottom",
		layout: "horizontal",
	},
	bottomCenter: {
		align: "center",
		verticalAlign: "bottom",
		layout: "horizontal",
	},
	bottomLeft: {
		align: "left",
		verticalAlign: "bottom",
		layout: "horizontal",
	},
	right: {
		align: "right",
		verticalAlign: "middle",
		layout: "vertical",
	},
	left: {
		align: "left",
		verticalAlign: "middle",
		layout: "vertical",
	},
	topRight: {
		align: "right",
		verticalAlign: "top",
		layout: "horizontal",
	},
	topCenter: {
		align: "center",
		verticalAlign: "top",
		layout: "horizontal",
	},
	topLeft: {
		align: "left",
		verticalAlign: "top",
		layout: "horizontal",
	},
};

let configureLegend = (chartData) => {
	let { chartFormat, showLegend: showLegendFlag, legendPosition } = chartData;

	if (showLegendFlag === false) {
		return { ...(chartFormat.legend || {}), enabled: false };
	}

	return {
		...(chartFormat.legend || {}),
		enabled: true,
		...chartLegendPositionLookup[legendPosition || "bottomRight"],
	};
};

export default configureLegend;
