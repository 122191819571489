import Loading from "@clearpoint/old-theme/Loading/index";
import { PieChart } from "react-minimal-pie-chart";
import useKpiDonutChartData from "./useKpiDonutChartData";
import { useMemo } from "react";
import PropTypes from "prop-types";

let propTypes = {
	chart: PropTypes.shape({
		color1: PropTypes.string,
		color2: PropTypes.string,
		measureId: PropTypes.number,
		measureSeriesId: PropTypes.number,
		targetSeriesId: PropTypes.number,
	}),
};

let Donut = ({ chart }) => {
	let data = useKpiDonutChartData(chart);

	let pieChartLibraryStyleObject = useMemo(() => ({ height: "100px" }), []);

	return data ? (
		<PieChart data={data} lineWidth={50} startAngle={270} style={pieChartLibraryStyleObject} />
	) : (
		<Loading />
	);
};

Donut.propTypes = propTypes;
export default Donut;
