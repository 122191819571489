import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";

let propTypes = {
	peerGroupId: PropTypes.number.isRequired,
	peerId: PropTypes.number,
};

let SelectPeerMeasure = ({ peerGroupId, peerId, ...props }) => {
	let { get } = useOldQueryStore();
	let peerMeasureList = peerGroupId && get({ object: "measure", parent: "peerGroup", parentId: peerGroupId });
	let options = useMemo(() => {
		if (peerMeasureList === undefined) return;
		if (!isArray(peerMeasureList)) return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (peerId) peerMeasureList = peerMeasureList.filter((x) => x.peerId === peerId);
		return peerMeasureList.map((x) => ({
			label: x.name,
			value: x.measureId,
			group: x.peer,
		}));
	}, [peerMeasureList, peerId]);
	return <Select options={options || []} loadingFlag={!peerMeasureList} {...props} />;
};

SelectPeerMeasure.propTypes = propTypes;

export default SelectPeerMeasure;
