import { useCallback } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { useTranslate } from "@clearpoint/translate";

import DropdownItemTrash from "./Dropdown.Item.Trash";

let propTypes = {
	children: PropTypes.node,
	confirmBodyText: PropTypes.string,
	object: PropTypes.string.isRequired,
	objectIdList: PropTypes.array.isRequired,
	toast: PropTypes.string,
};

let useAlertTrash = ({ approvalsRequired, count, object }) => {
	let translate = useTranslate();
	return useCallback(() => {
		let message = translate(
			approvalsRequired ? "approvals.multiObjectsDeleted" : "toaster.messages.objects.multiObjectsDeleted",
			{
				count,
				objects: object === "measureSeries" ? object : `${object}s`,
			}
		);
		if (approvalsRequired) {
			toast.warning(message);
		} else {
			toast.success(message);
		}
	}, [approvalsRequired, count, object, translate]);
};

let DropdownItemTrashMultiple = ({
	children,
	approvalsRequired,
	confirmBodyText,
	object,
	objectIdList,
	toast,
	...props
}) => {
	let translate = useTranslate();
	let count = objectIdList.length;
	let alertTrash = useAlertTrash({ approvalsRequired, count, object });

	return count > 0 ? (
		<DropdownItemTrash
			object={object}
			objectIdList={objectIdList}
			title={translate("edit.element.deleteMultiple", {
				elements: object === "measureSeries" ? `global.${object}` : `global.${object}s`,
			})}
			body={
				confirmBodyText ||
				translate("edit.deleteMultiConfirm", { object: object === "measureSeries" ? object : `${object}s` })
			}
			onTrash={alertTrash}
			toast={undefined}
			{...props}
		>
			{children || translate("global.deleteMultiple")}
		</DropdownItemTrash>
	) : null;
};
DropdownItemTrashMultiple.propTypes = propTypes;
export default DropdownItemTrashMultiple;
