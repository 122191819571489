import PropTypes from "prop-types";

import BadgePeriodBadge from "@clearpoint/old-theme/Badge/Badge.Period";
import Hide from "@clearpoint/old-theme/Hide";
import { theme } from "@clearpoint/old-theme/Theme";

import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	link: PropTypes.shape({
		object: PropTypes.string,
		period: PropTypes.string,
		periodId: PropTypes.number,
		reportPeriodId: PropTypes.number,
	}),
};

let PeriodBadge = ({ link, smallScreenFlag }) => {
	let { exportFlag, periodId: sessionPeriodId } = useOldSession().session;
	let { object, period, periodId, reportPeriodId } = link;
	let categoryFlag = object === "category";

	return (
		<Hide visible={!smallScreenFlag && (!categoryFlag || !exportFlag)}>
			<BadgePeriodBadge
				hideLockIconFlag
				highlightFlag={periodId && (periodId !== reportPeriodId || periodId !== sessionPeriodId)}
				periodId={periodId ?? reportPeriodId}
				name={period}
			/>
		</Hide>
	);
};

PeriodBadge.propTypes = propTypes;
export default PeriodBadge;

// Responsiveness we're probably not going to wind up using:

// let smallScreenFlag = props.smallScreenFlag;
// let visible = object !== "category" && !smallScreenFlag;
