import Block from "@clearpoint/old-theme/Block";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let cellPropTypes = {
	children: PropTypes.node,
	highlightFlag: PropTypes.bool,
};

let TableDataCell = ({ children, highlightFlag }) => {
	let backgroundColor = highlightFlag ? theme.highlightYellow : "none";

	return (
		<StyleWrapper backgroundColor={backgroundColor} wordBreak="normal" whiteSpace="pre-wrap">
			<td>{children}</td>
		</StyleWrapper>
	);
};

let propTypes = {
	row: PropTypes.shape({
		calculation: PropTypes.string,
		criteria: PropTypes.string,
		highlightFlag: PropTypes.bool,
		seriesName: PropTypes.string,
		spanCount: PropTypes.number,
		spanRowFlag: PropTypes.bool,
		statusName: PropTypes.string,
		statusId: PropTypes.number,
	}),
};

let Row = ({ row }) => {
	let { calculation, criteria, highlightFlag, seriesName, spanCount, spanRowFlag, statusName, statusId } = row;

	return (
		<tr>
			{spanRowFlag && (
				<StyleWrapper verticalAlign="middle !important" wordBreak="normal" whiteSpace="pre-wrap">
					<td rowSpan={spanCount}>{seriesName}</td>
				</StyleWrapper>
			)}
			<TableDataCell highlightFlag={highlightFlag}>
				<Block display="flex">
					<StatusIcon statusId={statusId} size="small" />
					<span>&nbsp;&nbsp;</span>
					{statusName}
				</Block>
			</TableDataCell>
			<TableDataCell highlightFlag={highlightFlag}>{calculation}</TableDataCell>
			<TableDataCell highlightFlag={highlightFlag}>{criteria}</TableDataCell>
		</tr>
	);
};

TableDataCell.propTypes = cellPropTypes;
Row.propTypes = propTypes;
export default Row;
