import LinkMap from "@clearpoint/old-theme/LinkMap/LinkMap";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";

let LinkMapTab = () => {
	let { object, objectId } = useFormValue();
	let { periodId } = useOldSession().session;
	return <LinkMap object={object} objectId={objectId} periodId={periodId} />;
};
export default LinkMapTab;
