import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useMeasure } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject, noop } from "@clearpoint/utils";

import { usePodContext } from "../PodProvider";
import usePodLookup from "./usePodLookup";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	areaClass: PropTypes.string,
	className: PropTypes.string,
	podResizeOnClick: PropTypes.func,
};

let PodComponent = ({ className, podResizeOnClick }) => {
	let { get } = useOldQueryStore();
	let { editPodFlag, fieldId, hideBorderFlag, openEditMode, podStyleId, podWidth, template } = usePodContext();
	let [podHeight, setPodHeight] = useState(0);
	let { editLayoutFlag } = useLocalState();

	let podStyleList = get({ object: "podStyle" });
	let defaultPodStyle = useMemo(() => podStyleList?.find((x) => x.defaultStyle) ?? undefined, [podStyleList]);
	let podStyle = useMemo(
		() => podStyleList?.filter?.((x) => x.active).find((x) => x.podStyleId === podStyleId) ?? defaultPodStyle,
		[defaultPodStyle, podStyleId, podStyleList]
	);
	let Pod = usePodLookup({ fieldId, template });
	let overflowX = editPodFlag || hideBorderFlag ? "" : "auto";
	
	// Here be dragons. This is a hack to get the grid to display correctly. Lets hope we never have to touch it again. -- will remove if this new solution works

	// let measureGridPodFlag = fieldId === "chartData";
	// let gridDisplayFlag = gridReportFlag || measureGridPodFlag;

	// let displayProp = "inline-grid";
	// let inlineBlockFlag = !gridDisplayFlag && ((podWidth !== 12 && !narrowPodFlag) || podWidthDisplayClass !== "large");
	// if (inlineBlockFlag) {
	// 	displayProp = "inline-block";
	// } else if (gridDisplayFlag && (areaClass === "area1" || areaClass === "area2")) {
	// 	displayProp = "block";
	// }

	// not needed for now
	// let calculatedPodHeight = useMeasure({ query: `.fieldId-${fieldId}` }) || emptyObject;
	// useLayoutEffect(() => {
	// 	if (calculatedPodHeight.height > podHeight) {
	// 		setPodHeight(calculatedPodHeight.height);
	// 		console.log(fieldId, calculatedPodHeight.height);
	// 	}
	// }, [calculatedPodHeight]);
	

	return (
		<Block
			display="block"
			verticalAlign="top"
			className={classNames(
				`col-${podWidth || 12}`,
				editLayoutFlag && "inline-draggable",
				"detail-page-pod-wrapper",
				"fieldId-" + fieldId
			)}
			$style={`
			    float: left;
				& > * {
					min-width: 0;
				}
			`}
		>
			<Pod
				// 'detail-page-pod' class is handled in ViewDetail\Export\index.js
				className={classNames(className, "detail-page-pod", "drag-item")}
				marginTop="25px"
				onDoubleClick={editLayoutFlag ? noop : openEditMode}
				overflowX={overflowX}
				podStyle={editPodFlag ? emptyObject : podStyle}
			/>			
		</Block>
	);
};

PodComponent.propTypes = propTypes;

export default PodComponent;
