import CellWrapper from "../CellWrapper";
import FormFieldDisplayToday from "@clearpoint/old-theme/Form/Field/Display/Today";
import PropTypes from "prop-types";

let propTypes = {
	cell: PropTypes.shape({
		periodId: PropTypes.number,
	}),
	parentName: PropTypes.string,
};
let TodayCell = (props) => {
	let { cell, parentName } = props;
	let { periodId } = cell;
	let name = `${parentName}.status.${periodId}.calculatedValues.today`;
	return (
		<CellWrapper {...props}>
			<FormFieldDisplayToday name={name} />
		</CellWrapper>
	);
};
TodayCell.propTypes = propTypes;

export default TodayCell;
