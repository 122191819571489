let generateOnDrop = (list, setList) => (dropProps) => {
	if (!dropProps) return;
	let { removedIndex, addedIndex } = dropProps;
	let reorder = (list, startIndex, endIndex) => {
		let result = [...list.slice(0, startIndex), ...list.slice(startIndex + 1)];
		let removed = list[startIndex];
		result = [...result.slice(0, endIndex), removed, ...result.slice(endIndex)];
		return result;
	};
	if (removedIndex === addedIndex) {
		return;
	}
	let newList = reorder(list, removedIndex, addedIndex);
	setList(newList);
};

export default generateOnDrop;
