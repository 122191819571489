import { useCallback } from "react";
import getDeepValue from "lodash/get";
import isEqual from "lodash/isEqual";
import useFormState from "./useFormState";
import { emptyObject } from "@clearpoint/utils";


let valuesEqualityCheck = (value, defaultValue) => {
	if (!value && !defaultValue) return true;
	if (isEqual(value, []) && !defaultValue) return true;
	if (typeof value === "string" && value.includes("\n")) {
		let filteredValue = value.replace(/\n| /g, "");
		let filteredDefaultValue = defaultValue?.replace(/\n| /g, "");
		return isEqual(filteredValue, filteredDefaultValue);
	} else {
		return isEqual(value, defaultValue);
	}
};

let useFormDirty = ({ formContextRef }) => {
	let [formDirty, setFormDirty, formDirtyRef] = useFormState(emptyObject, "formDirty");
	// compare values without weird characters and empty spaces added by HTML redactor
	let updateFormDirty = useCallback(
		({ name, stateName, value }) => {
			let { formDefaultValueRef } = formContextRef.current;
			let defaultValue;
			if (stateName === "defaultFormValue") {
				defaultValue = value;
				let { formValueRef } = formContextRef.current;
				value = getDeepValue(formValueRef.current, name);
			}
			if (stateName === "formValue") {
				defaultValue = getDeepValue(formDefaultValueRef.current, name);
			}
			if (typeof name === "string" && name.startsWith("_")) return; // ignore hidden fields
			let dirty = getDeepValue(formDirtyRef.current, name) || getDeepValue(formDirty, name);
			if (!valuesEqualityCheck(value, defaultValue)) {
				if (!dirty) setFormDirty(name, true);
			} else {
				if (dirty) setFormDirty(name, undefined);
			}
		},
		[formContextRef, formDirty, formDirtyRef, setFormDirty]
	);
	return { formDirty, updateFormDirty, formDirtyRef };
};

export default useFormDirty;
