import Select from "./FormSelect";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	defaultFlag: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
};

let defaultProps = {
	defaultFlag: true,
};

let FormSelectCurrency = ({ defaultFlag, label, name }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let value = useFormValue(name);
	let { setFormValue } = useFormContext();
	let currencyList = get({ object: "currency" });
	let options = get({ object: "options" });
	let selectOptions = useMemo(() => {
		if (currencyList === undefined || options === undefined) return;
		let selectOptions = currencyList
			?.map((x) => {
				let symbol = x.symbol === x.currencyCode ? "" : " - " + x.symbol;
				return {
					label: `${translate(x.name)} (${x.currencyCode}${symbol})`,
					value: x.currencyCode,
				};
			})
			.sort((a, b) => (a.label > b.label ? 1 : -1));
		if (defaultFlag && currencyList && options) {
			let x = currencyList.find((x) => x.currencyCode === options.currency);
			let symbol = x?.symbol === x?.currencyCode ? "" : " - " + x?.symbol;
			selectOptions.unshift({
				label: `${translate("options.defaultCurrency")} (${x?.currencyCode}${symbol})`,
				value: "   ",
			});
		}
		return selectOptions;
	}, [currencyList, defaultFlag, options, translate]);
	useEffect(() => {
		if (defaultFlag && value === "") {
			setFormValue(name, "   ");
		}
	}, [defaultFlag, name, setFormValue, value]);
	return (
		<Select
			label={label}
			name={name}
			options={selectOptions || []}
			loadingFlag={!selectOptions}
			defaultValue={defaultFlag ? "   " : undefined}
		/>
	);
};

FormSelectCurrency.propTypes = propTypes;
FormSelectCurrency.defaultProps = defaultProps;

export default FormSelectCurrency;
