import Block from "../Block";
import Icon from "../Icon/Icon";
import useFormValue from "../Form/hooks/useFormValue";
import { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../Theme";

let FormControl = styled.div`
	display: block;
	width: 100%;
	height: calc(${(props) => props.theme.lineHeightBase}em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 16px;
	font-weight: 400;
	line-height: ${(props) => props.theme.lineHeightBase};
	color: #676a6c;
	background-color: #ffffff;
	background-clip: padding-box;
	border: ${(props) => (props.inputGroupFlag ? "none !important" : "1px solid #ced4da")};
	border-radius: 4px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	color: #555;
	border-color: #e5e6e7;
	height: auto;
	position: relative;
	box-sizing: border-box;
	outline: none;
	padding: 0px;
	border: none;
	opacity: 1;
	min-width: ${(props) => (props.inputGroupFlag ? "200px" : props.minWidth)};
	margin-left: ${(props) => (props.inputGroupFlag ? "-12px" : props.marginLeft)};
	margin-right: ${(props) => (props.inputGroupFlag ? "-12px" : props.marginRight)};
`;

let SelectControl = styled.div`
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: ${(props) => (props.$disabled ? "#EEEEEE" : "hsl(0, 0%, 100%)")};
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	box-shadow: none;
	cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	min-height: ${(props) => (props.inputGroupFlag ? "34px" : "36px")};
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	box-sizing: border-box;
	border: ${(props) => (props.inputGroupFlag ? "0 !important" : "1px solid #e5e6e7")};
	padding-left: 3px;
	font-size: 16px;

	&:hover,
	&.hover {
		border-color: hsl(0, 0%, 80%);
	}
`;

let SelectValueContainer = styled.div`
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: ${(props) => props.$padding || "2px 8px"};
	-webkit-overflow-scrolling: touch;
	position: relative;
	box-sizing: border-box;
`;

let SingleValue = styled.div`
	color: ${(props) => (props.placeholderFlag ? "#676a6c" : "hsl(0, 0%, 20%)")};
	filter: ${(props) => (props.placeholderFlag ? "opacity (50%) !important" : "opacity (100%)")};
	margin-left: 2px;
	margin-right: 2px;
	max-width: calc(100% - 8px);
	position: absolute;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	box-sizing: border-box;
`;

let SelectIndicators = styled.div`
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	box-sizing: border-box;
`;

let SelectIndicatorSeparator = styled.span`
	box-sizing: inherit;
`;

let SelectIndicator = styled.div`
	color: ${theme.darkerGray};
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 8px;
	-webkit-transition: color 150ms;
	transition: color 150ms;
	box-sizing: border-box;
`;

let propTypes = {
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	disabled: PropTypes.bool,
	formatOptionLabel: PropTypes.func,
	inlineFlag: PropTypes.bool,
	inputGroupFlag: PropTypes.bool,
	marginBottom: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
		})
	),
	padding: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	}),
	width: PropTypes.string,
};

let DummySelect = ({
	className,
	"data-testid": dataTestId,
	disabled,
	formatOptionLabel,
	inlineFlag,
	inputGroupFlag,
	name,
	onClick,
	options,
	padding,
	placeholder,
	value,
	width,
}) => {
	let formValue = useFormValue(name);
	if (!name) formValue = undefined;
	disabled = disabled || options?.length === 0;
	let [text, image] = useMemo(() => {
		let currentOption;
		let targetValue = formValue || value;
		let flatOptions = options;
		if (options?.[0]?.options) flatOptions = options.reduce((options, x) => [...options, ...x.options], []);
		if (!formValue) currentOption = value;
		else currentOption = flatOptions.find((o) => o.value === targetValue);
		return [currentOption?.label || placeholder || "", currentOption?.image];
	}, [formValue, options, placeholder, value]);

	let placeholderFlag = text === placeholder;
	return useMemo(
		() => (
			<Block
				data-testid={dataTestId || disabled ? "dummy-select-disabled" : "dummy-select-enabled"}
				onClick={() => !disabled && onClick()}
				className={className}
				display={inlineFlag ? "inline-block" : undefined}
				width={width || (inlineFlag ? "150px" : undefined)}
				transform={inlineFlag ? "translateY(-2px)" : undefined}
				height="34px"
			>
				<Block fontWeight="normal" marginBottom="0px">
					<span>
						<FormControl inputGroupFlag={inputGroupFlag}>
							<SelectControl className="select-control" inputGroupFlag={inputGroupFlag} $disabled={disabled}>
								<SelectValueContainer $padding={padding}>
									<SingleValue placeholderFlag={placeholderFlag}>
										<Block ellipsisFlag textAlign="left">
											{formatOptionLabel({ label: text, image }, { inputValue: "" })}
										</Block>
									</SingleValue>
								</SelectValueContainer>
								<SelectIndicators>
									<SelectIndicatorSeparator />
									<SelectIndicator>
										<Icon name="expandSelect" color={theme.darkGray} />
									</SelectIndicator>
								</SelectIndicators>
							</SelectControl>
						</FormControl>
					</span>
				</Block>
			</Block>
		),
		[
			className,
			dataTestId,
			disabled,
			inputGroupFlag,
			formatOptionLabel,
			image,
			inlineFlag,
			onClick,
			padding,
			placeholderFlag,
			text,
			width,
		]
	);
};

DummySelect.propTypes = propTypes;

export default DummySelect;
