import PropTypes from "prop-types";
import styled from "styled-components";


let Circle = styled.div`
	display: inline-block;
	width: ${(props) => props.width || "20px"};
	height: ${(props) => props.height || "20px"};
	border-radius: 50%;
	background-color: ${(props) => props.theme[props.color] || props.color};
`;

Circle.propTypes = {
	color: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
};

export default Circle;
