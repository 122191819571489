import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";

let useFilterByMenu = (elementList) => {
	let { get } = useOldQueryStore();
	let { scorecardId } = useOldSession().session;
	let menu = scorecardId && get({ object: "menu", parent: "scorecard", parentId: scorecardId });
	let activeElementList = useMemo(
		() =>
			!menu
				? undefined
				: elementList?.filter((element) => {
						let menuObject = menu.find((menuObject) => menuObject.object === element.object);
						let menuObjectElementList = menuObject?.objects;
						let menuObjectElement = menuObjectElementList?.find((x) => x.objectId === element.objectId);
						return element.objectId === menuObjectElement?.objectId;
				  }),
		[elementList, menu]
	);

	return activeElementList;
};

export default useFilterByMenu;
