import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString, findUpdates, removeUnderscoredKeys } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import Button from "../../Button/Button";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import Modal from "../../Modal/Modal";
import ModalBody from "../../Modal/Modal.Body";
import ModalFooter from "../../Modal/Modal.Footer";
import ModalHeader from "../../Modal/Modal.Header";
import ModalTitle from "../../Modal/Modal.Title";
import { useWindowContext } from "../../ModalWindow/ModalWindow";
import { useWindowTabContext } from "../../ModalWindow/WindowTabProvider";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	beforeSubmit: PropTypes.func,
	body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	close: PropTypes.func,
	closeButtonText: PropTypes.string,
	modalVisible: PropTypes.bool,
	parent: PropTypes.string,
	parentId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onSave: PropTypes.func,
	saveButtonColor: PropTypes.string,
	submitUnchangedFlag: PropTypes.bool,
	title: PropTypes.string,
	toastMessage: PropTypes.string,
};

let FormModalSave = ({
	beforeSubmit,
	body,
	close,
	closeButtonText,
	modalVisible,
	object,
	objectId,
	onSave,
	parent,
	parentId,
	saveButtonColor,
	submitUnchangedFlag,
	skipNavigationFlag,
	title,
	toastMessage,
}) => {
	let translate = useTranslate();
	let [submittingFlag, setSubmittingFlag] = useState(false);
	let { setObjectId } = useWindowContext();
	let { session } = useOldSession();
	let { scorecardId } = session;
	let { selectNextTab } = useWindowTabContext();
	let { formDefaultValue } = useFormContext();
	let formValue = useFormValue();
	let { set, clear } = useOldQueryStore();

	let save = useCallback(
		(e) => {
			setSubmittingFlag(true);
			let reportFlag = object.endsWith("Layout");
			let payload = submitUnchangedFlag
				? formValue
				: findUpdates({ original: formDefaultValue, updated: formValue });
			payload = removeUnderscoredKeys(payload);
			payload = beforeSubmit ? beforeSubmit(payload) : payload;
			set({
				object,
				objectId,
				data: payload,
				stopPropagationFlag: true,
				queryString: reportFlag
					? buildQueryString({ scorecardId: payload.scorecardId, object: payload.object })
					: undefined,
				parent,
				parentId,
			}).then((res) => {
				if (!toastMessage && reportFlag)
					// eslint-disable-next-line react-hooks/exhaustive-deps
					toastMessage = translate(
						objectId ? "toaster.messages.reports.saved" : "toaster.messages.reports.reportAdded"
					);
				if (!toastMessage)
					toastMessage = translate(
						objectId ? "toaster.messages.objects.objectUpdated" : "toaster.messages.objects.objectAdded",
						{ object }
					);
				toast.success(toastMessage);

				if (onSave) {
					onSave(res, formValue);
				}
				let newObjectId = res?.objectId || res?.layoutId || res?.[object + "Id"];
				if (setObjectId && newObjectId) {
					setObjectId(newObjectId);
				}
				!skipNavigationFlag && selectNextTab();
				setSubmittingFlag(false);
				close();
			});
		},
		[
			submitUnchangedFlag,
			formValue,
			formDefaultValue,
			beforeSubmit,
			set,
			object,
			objectId,
			parent,
			parentId,
			toastMessage,
			translate,
			onSave,
			setObjectId,
			skipNavigationFlag,
			selectNextTab,
			close,
		]
	);

	useUnmount(() => {
		if (object) {
			clear({ object: "menu", parent: "scorecard", parentId: scorecardId });
		}
	});

	return useMemo(
		() => (
			<Modal modalVisible={modalVisible} close={close} size="medium">
				<ModalHeader>
					<ModalTitle>{title || translate("edit.element.saveToContinue")}</ModalTitle>
				</ModalHeader>
				<ModalBody>{body || translate("edit.element.saveToContinueText")}</ModalBody>
				<ModalFooter>
					<Button onClick={close} type="button" color="default">
						{closeButtonText || translate("global.cancel")}
					</Button>
					<Button
						type="button"
						data-testid="save-modal-save-button"
						onClick={save}
						color={saveButtonColor || "primary"}
						disabled={submittingFlag}
					>
						{submittingFlag ? translate("button.saving") : translate("global.save")}
					</Button>
				</ModalFooter>
			</Modal>
		),
		[body, close, closeButtonText, modalVisible, save, saveButtonColor, submittingFlag, title, translate]
	);
};

FormModalSave.propTypes = propTypes;

export default FormModalSave;
