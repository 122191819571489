import Input from "../../Form/Input/Input";
import { useCallback, useEffect } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray.js";

let propTypes = {
	measureSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
		})
	),
	name: PropTypes.string,
	object: PropTypes.string,
};

let Names = ({ measureSeriesList, object, name, ...props }) => {
	let translate = useTranslate();
	let { setFormError, setFormValue } = useFormContext();
	let bulkScriptFlag = name === "script.bulkEdit.fields.names";
	let _name = bulkScriptFlag ? name : `_${name}`;
	let value = useFormValue(_name);
	useEffect(() => {
		let maxLength = object === "scorecard" ? 150 : object === "measureSeries" ? 50 : 500;
		let errorMessage = translate(
			object === "scorecard"
				? "error.scorecardNameLength"
				: object === "measureSeries"
				? "error.seriesNameLength"
				: "error.elementNameLength"
		);

		let arrayValue = value && isArray(value) ? value : value?.split("\n");
		setFormValue(name, arrayValue);
		if (arrayValue?.some((name) => name.length > maxLength)) {
			setFormError(_name, errorMessage);
		} else {
			setFormError(_name, undefined);
		}

		if (object === "measureSeries") {
			arrayValue?.forEach((name, index) => {
				name = name.toLowerCase();
				if (
					measureSeriesList?.some?.((series) => series.name.toLowerCase() === name) ||
					(arrayValue.includes(name) && arrayValue.indexOf(name) !== index)
				) {
					setFormError(_name, translate("error.uniqueSeriesName"));
				}
			});
		}
	}, [_name, measureSeriesList, name, object, setFormError, setFormValue, translate, value]);

	let formValueTransform = useCallback(
		(elementValue) => {
			if (!bulkScriptFlag) return elementValue;
			if (typeof elementValue === "string") {
				return elementValue.split("\n");
			}
			return elementValue;
		},
		[bulkScriptFlag]
	);

	let elementValueTransform = useCallback(
		(formValue) => {
			if (!bulkScriptFlag) return formValue;
			if (isArray(formValue)) {
				return formValue.join("\n");
			}
			return formValue;
		},
		[bulkScriptFlag]
	);

	return (
		<Input
			as="textarea"
			data-testid="name"
			label={translate("edit.element.elementNames", { element: object })}
			placeholder={translate("edit.element.elementNamesPlaceholder", { element: object })}
			formValueTransform={formValueTransform}
			elementValueTransform={elementValueTransform}
			{...props}
			name={_name}
			required
			rows="6"
		/>
	);
};

Names.propTypes = propTypes;
export default Names;
