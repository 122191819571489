import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";
import FormCheckArray from "@clearpoint/old-theme/Form/CheckArray/CheckArray";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import Help from "@clearpoint/old-theme/Help";
import ScorecardListItem from "./ScorecardListItem";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let ChartsTabContent = () => {
	let translate = useTranslate();
	let { data: filteredScorecardList } = useFilter();
	return (
		<>
			<Help section="sect12" feature="report-select-elements" />
			<FilterInput
				placeholder={translate("edit.filterElements", { InsertText: "scorecards" }) + "..."}
				name="scorecardName"
				filterBy="name"
			/>
			<FormGroup title={translate("tabGridElements.selectScorecards")}>
				<FormCheckArray name="scorecardIdList" stringFlag required>
					{filteredScorecardList
						.filter((x) => x.visible)
						.map((x) => (
							<ScorecardListItem key={x.scorecardId} scorecard={x} />
						))}
				</FormCheckArray>
			</FormGroup>
		</>
	);
};
let ChartsTab = () => {
	let { get } = useOldQueryStore();
	let scorecardList = get({ object: "scorecard" });
	return (
		<FilterProvider data={scorecardList}>
			<ChartsTabContent />
		</FilterProvider>
	);
};
export default ChartsTab;
