import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectActivity = ({ ...props }) => {
	let translate = useTranslate();
	let activityList = useMemo(() => {
		let options = [
			{
				label: translate("recycleBin.activities.added"),
				value: "added",
			},
			{
				label: translate("recycleBin.activities.edited"),
				value: "edited",
			},
			{
				label: translate("recycleBin.activities.updated"),
				value: "updated",
			},
			{
				label: translate("recycleBin.activities.duplicated"),
				value: "duplicated",
			},
			{
				label: translate("recycleBin.activities.deleted"),
				value: "deleted",
			},
			{
				label: translate("recycleBin.activities.undelete"),
				value: "undeleted",
			},
			{
				label: translate("recycleBin.activities.login"),
				value: "login",
			},
			{
				label: translate("recycleBin.activities.completed"),
				value: "completed",
			},
			{
				label: translate("recycleBin.activities.queued"),
				value: "queued",
			},
			{
				label: translate("recycleBin.activities.sent"),
				value: "sent",
			},
			{
				label: translate("recycleBin.activities.received"),
				value: "received",
			},
		];
		return options;
	}, [translate]);
	return <FormSelect options={activityList} {...props} />;
};

export default SelectActivity;
