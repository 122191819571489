import useGridLockedFlag from "./useGridLockedFlag";
import { useMemo } from "react";
import useRenderFunction from "./useRenderFunction";
import { useSeriesGridData } from "./Provider";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";
import { notDefinedStatusIcon } from "@clearpoint/utils";


let useColumns = () => {
	let translate = useTranslate();
	let { seriesData, seriesList, shadowObject, statusList, statusLockedFlag } = useSeriesGridData();
	let renderFunction = useRenderFunction();
	let gridLockedFlag = useGridLockedFlag();

	let statusNameList = useMemo(
		() =>
			statusList
				?.filter((x) => x.active && (x.elements === undefined || x.elements.includes(shadowObject || "measure")))
				.map((x) => x.name),
		[shadowObject, statusList]
	);

	return useMemo(
		() =>
			seriesData &&
			seriesList &&
			statusNameList && [
				{
					data: "periodName",
					readOnly: true,
					renderer: renderFunction,
					title: translate("global.period"),
					width: 100,
				},
				{
					data: "statusIcon",
					className: `htCenter ${statusLockedFlag ? "series-locked" : ""}`,
					readOnly: true,
					renderer: renderFunction,
					title: `<img src="${notDefinedStatusIcon}" width=16 height=16 />`,
					width: 26,
				},
				{
					allowInvalid: false,
					data: "statusName",
					readOnly: statusLockedFlag,
					renderer: renderFunction,
					source: statusNameList,
					title: translate("global.status"),
					type: "dropdown",
					width: 149,
				},
				...(seriesList?.map((x) => ({
					allowInvalid: false,
					className: "htRight",
					data: x.seriesData,
					readOnly: gridLockedFlag,
					renderer: renderFunction,
					title: x.name,
					seriesData: x,
					validator: function (value, callback) {
						let validFlag = true;

						if (!isFinite(value) && x.seriesDataTypeId !== 11) {
							validFlag = false;
							toast.warning(translate("toaster.messages.global.invalidNumeric"));
						} else if (x.seriesDataTypeId === 11 && value?.length > 50) {
							validFlag = false;
							toast.warning(translate("error.seriesTextLength"));
						}

						if (callback) callback(validFlag);
						return validFlag;
					},
				})) || []),
			],
		[gridLockedFlag, renderFunction, seriesData, seriesList, statusLockedFlag, statusNameList, translate]
	);
};

export default useColumns;
