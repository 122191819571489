import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useEffectOnce } from "@clearpoint/hooks";

import Check from "../../Check/Check";
import ChecklistCheck from "../../Checklist/Checklist.Check";
import { useCheckArray } from "./CheckArray";

let StyledCheck = styled(Check)`
	margin-bottom: ${(props) => props.marginBottom};
`;

let propTypes = {
	checklistFlag: PropTypes.bool,
	marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.string,
	hiddenFlag: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
	defaultCheckedFlag: PropTypes.bool,
};

let CheckArrayItem = ({ checklistFlag, value, hiddenFlag, defaultCheckedFlag, ...props }) => {
	let { isChecked, onClick } = useCheckArray();
	let CheckComponent = checklistFlag ? ChecklistCheck : StyledCheck;
	let handleClick = useCallback(
		(checkedFlag) => {
			if (!hiddenFlag) onClick(value, checkedFlag);
		},
		[onClick, value, hiddenFlag]
	);

	useEffectOnce(() => {
		if (defaultCheckedFlag && !isChecked(value)) handleClick(true);
	});

	let checkedFlag = isChecked(value);

	return (
		<CheckComponent
			scorecardId={value?.scorecardId}
			linkType={value?.linkType}
			onClick={handleClick}
			checked={checkedFlag}
			{...props}
		/>
	);
};

CheckArrayItem.propTypes = propTypes;

export default memo(CheckArrayItem);
