import { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { useClickAwayListener } from "@clearpoint/hooks";
import { emptyObject } from "@clearpoint/utils";

import Block from "../../Block";
import ReactionButton from "../../Button/Button.Reaction";
import Portal from "../../Portal/Portal";
import { theme } from "../../Theme";



let propTypes = {
	commentId: PropTypes.number,
	display: PropTypes.string,
	setReactionHandler: PropTypes.func,
	setShowReactions: PropTypes.func,
};

let reactionList = ["heart", "thumbsUp", "thumbsDown", "laugh", "surprised", "hooray"];

let SelectReactions = ({ commentId, display, setReactionHandler, setShowReactions }) => {
	let inputRect = document.getElementById(`reaction-button-${commentId}`)?.getBoundingClientRect();
	let { left: buttonLeft, top: buttonTop } = inputRect || emptyObject;
	let ref = useRef();
	let closeReactions = useCallback(() => setShowReactions(false), [setShowReactions]);
	useClickAwayListener(ref, closeReactions);

	let hideReactionsBar = useCallback(() => {
		setShowReactions(false);
	}, [setShowReactions]);
	return (
		<Portal>
			<Block
				ref={ref}
				backgroundColor={theme.white}
				border={`1px solid ${theme.gray}`}
				borderRadius="4px"
				display={display}
				position="absolute"
				top={`${buttonTop - 25}px`}
				left={`${buttonLeft + 10}px`}
				padding="6px 6px 6px 12px "
				zIndex="999"
				flexDirection="row"
			>
				{reactionList.map((x) => (
					<ReactionButton
						commentId={commentId}
						iconSize={"18px"}
						marginRight={theme.tinySpace}
						key={x}
						name={x}
						onClick={hideReactionsBar}
						setReactionHandler={setReactionHandler}
						size="medium"
					/>
				))}
			</Block>
		</Portal>
	);
};
ReactionButton.propTypes = propTypes;
export default SelectReactions;
