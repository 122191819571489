import calculateKeyResultPercentComplete from "./calculateKeyResultPercentComplete";

let averagePercentComplete = (array) => {
	let itemCount = array?.length || 0;
	if (itemCount === 0) return 0;
	let percentComplete =
		array?.reduce((acc, keyResult) => {
			let { currentValue, startValue, targetValue } = keyResult;
			let percentComplete = calculateKeyResultPercentComplete(startValue, targetValue, currentValue);
			acc += percentComplete;
			return acc;
		}, 0) / itemCount;
	return Math.min(Math.max(percentComplete, 0), 100);
};

export default averagePercentComplete;
