import { useOldQueryStore } from "@clearpoint/old-query-store";

import { useOldSession } from "@clearpoint/old-session/index";

let useScorecardArchivedFlag = (scorecardId) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	scorecardId = scorecardId || session.scorecardId;
	let scorecard = scorecardId && get({ object: "scorecard", objectId: scorecardId });
	return scorecard?.archived;
};

export default useScorecardArchivedFlag;
