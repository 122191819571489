import Column from "../../../Column";
import Image from "../../../Image";
import Row from "../../../Row";
import StyleWrapper from "../../../StyleWrapper";
import { useCallback } from "react";
import { useFormContext } from "../../../Form/Form";
import { usePagination } from "@clearpoint/providers/PaginationProvider";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useEffectOnce } from "@clearpoint/hooks";


let ImageContainer = styled.div`
	border: ${(props) => `1px solid ${props.theme.lightGray}`};
	background-color: ${(props) => `${props.theme.white}`};
	overflow: hidden;
	height: 85px;
	cursor: pointer;
`;

let StyledImage = styled(Image)`
	width: 100%;
	object-fit: contain;
`;

let propTypes = {
	className: PropTypes.string,
	customFieldFlag: PropTypes.bool,
	name: PropTypes.string,
};

let ImageList = ({ className, customFieldFlag, name }) => {
	let { data: imageObj, setChildQuery } = usePagination();
	let { clearFormValue, setFormValue } = useFormContext();

	let setImage = useCallback(
		(file) => {
			if (customFieldFlag) {
				setFormValue(name, {
					dirty: true,
					displayValue: file.url,
					master: file.name,
					value: file.url,
				});
			} else {
				setFormValue(name, {
					displayValue: file.url,
					name: file.name,
					value: file.url,
				});
			}
			clearFormValue("_localImage");
		},
		[clearFormValue, customFieldFlag, name, setFormValue]
	);

	// Remove special characters from form name, construct child class name
	let letterAndDigitRegex = /[\d\w]+/g;
	let childClassName = "image-list-column-" + name.match(letterAndDigitRegex).join("");

	useEffectOnce(() => {
		setChildQuery("." + childClassName);
	});

	return (
		<Row className={className}>
			{imageObj?.files?.length > 0 ? (
				imageObj?.files?.map((x, i) => (
					<Column
						key={x.objectId + x.uploadDate + x.name + childClassName}
						tiny={2}
						className={i === 0 ? childClassName : undefined}
					>
						<ImageContainer
							onClick={() => {
								setImage(x);
							}}
						>
							<StyledImage documentSrc={x.thumb} alt={x.name || `Image ${i}`} />
						</ImageContainer>
					</Column>
				))
			) : (
				<StyleWrapper opacity={0}>
					<Column tiny={2} className={childClassName}>
						<ImageContainer>
							<StyledImage src="" alt="" />
						</ImageContainer>
					</Column>
				</StyleWrapper>
			)}
		</Row>
	);
};

ImageList.propTypes = propTypes;

export default ImageList;
