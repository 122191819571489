import DropdownItem from "./Dropdown.Item";
import ModalOnClick from "../../Modal/Modal.OnClick";
import ModalWindowOnClick from "../../ModalWindow/ModalWindow.OnClick";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
};
let EditMultipleDropdownItemInner = ({ children, ...props }) => {
	let translate = useTranslate();
	return (
		<DropdownItem icon="editMultiple" {...props}>
			{children || translate("global.editMultiple")}
		</DropdownItem>
	);
};
EditMultipleDropdownItemInner.propTypes = propTypes;

let wrapperPropTypes = {
	Modal: PropTypes.elementType,
	ModalWindow: PropTypes.elementType,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let EditMultipleDropdownItem = ({ Modal, ModalWindow, objectIdList, ...props }) =>
	objectIdList?.length > 0 ? (
		Modal ? (
			<ModalOnClick Modal={Modal} objectIdList={objectIdList} {...props}>
				<EditMultipleDropdownItemInner {...props} />
			</ModalOnClick>
		) : ModalWindow ? (
			<ModalWindowOnClick ModalWindow={ModalWindow} objectIdList={objectIdList} {...props}>
				<EditMultipleDropdownItemInner {...props} />
			</ModalWindowOnClick>
		) : (
			<EditMultipleDropdownItemInner {...props} />
		)
	) : null;
EditMultipleDropdownItem.propTypes = wrapperPropTypes;
export default EditMultipleDropdownItem;
