import { useAIAssistant } from "../AIAssistantProvider/AIAssistantProvider";
import { useCallback } from "react";
import { useFormContext } from "../../Form/DefaultForm";
import getSubmitUserMessage from "./useSubmitUserMessage.getSubmitUserMessage";

let useSubmitUserMessage = ({ setChatState }) => {
	let {
		assistantState: { messageList },
		scrollToBottom,
		sendMessageToGPT,
		setAssistantState,
	} = useAIAssistant();

	let { formValueRef, setFormValue } = useFormContext();
	return useCallback(
		async (e) => {
			let submitUserMessage = getSubmitUserMessage({
				e,
				formValueRef,
				messageList,
				scrollToBottom,
				sendMessageToGPT,
				setAssistantState,
				setChatState,
				setFormValue,
			});
			return submitUserMessage;
		},
		[formValueRef, messageList, scrollToBottom, sendMessageToGPT, setAssistantState, setChatState, setFormValue]
	);
};

export default useSubmitUserMessage;
