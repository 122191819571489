import CellWrapper from "../CellWrapper";
import FormFieldDisplayObjectId from "@clearpoint/old-theme/Form/Field/Display/ObjectId";
import PropTypes from "prop-types";

let propTypes = {
	parentName: PropTypes.string,
};
let ObjectIdCell = (props) => {
	let { parentName } = props;
	let name = `${parentName}.objectId`;
	return (
		<CellWrapper {...props}>
			<FormFieldDisplayObjectId name={name} />
		</CellWrapper>
	);
};
ObjectIdCell.propTypes = propTypes;

export default ObjectIdCell;
