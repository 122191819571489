import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let ActionItemsTab = ({ objectIdList }) => {
	let translate = useTranslate();
	let { session } = useOldSession();
	let { scorecardId, periodId } = session;
	let { get } = useOldQueryStore();
	let actionItemsList = scorecardId && periodId && get({ object: "actionItem", scorecardId, periodId });
	let selectedActionItemsList = useMemo(
		() => actionItemsList?.filter((actionItem) => objectIdList.some((objectId) => objectId === actionItem.objectId)),
		[objectIdList, actionItemsList]
	);
	return (
		<FormGroup
			title={translate("edit.element.elementsToUpdate", { objects: "actionItem" })}
			paddingBottom={theme.tinySpace}
		>
			{selectedActionItemsList?.map((actionItem) => (
				<ListItem
					key={actionItem.objectId}
					left={
						<>
							<StatusIcon statusId={actionItem.statusId} />
							<span>{actionItem.name}</span>
						</>
					}
					size="small"
				/>
			))}
		</FormGroup>
	);
};
ActionItemsTab.propTypes = propTypes;
export default ActionItemsTab;
