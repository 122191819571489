import { useEffectOnce } from "@clearpoint/hooks";

let StylePreview = () => {
	useEffectOnce(() => {
		document.body.setAttribute("loaded", true);
	});
	return <div />;
};

export default StylePreview;
