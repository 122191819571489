import Select from "../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectFilterRule = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{
				label: translate("layout.allAreTrue"),
				value: "all",
			},
			{
				label: translate("layout.anyAreTrue"),
				value: "any",
			},
		],
		[translate]
	);
	return <Select options={options} {...props} />;
};

export default SelectFilterRule;
