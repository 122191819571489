import { useCallback, useState } from "react";
import useDateConfig from "./useDateConfig";
import useInterval from "./useInterval";
import { formatDistanceToNow } from "date-fns";

const useDescribeTimePassed = (delay) => {
	let { dateConfig } = useDateConfig();
	let [update, setUpdate] = useState();
	let triggerUpdate = useCallback(() => setUpdate((x) => !x), []);
	useInterval(triggerUpdate, delay || 5000);
	let describeTimePassedCallback = useCallback(
		(time) => {
			if (!time) return "";
			let now = new Date();
			let then = new Date(time);
			if (now < then) then = now;
			return formatDistanceToNow(then, { addSuffix: true, ...dateConfig });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dateConfig, update]
	);
	return describeTimePassedCallback;
};

export default useDescribeTimePassed;
