import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import Block from "../../Block";
import Icon from "../../Icon/Icon";
import Dropdown from "../Dropdown";

let StyledItem = styled(Dropdown.Item)`
	color: ${(props) => (props.$noAccessFlag ? props.theme.lightGray6 : props.theme.darkGray)} !important;
	display: flex;
	font-size: 16px;
	padding: ${(props) => props.$padding || "6px 10px !important"};
	width: 100%;
	&:hover {
		color: ${(props) => props.theme.darkerGray} !important;
	}
	cursor: ${(props) => (props.$noAccessFlag ? "not-allowed" : "pointer")} !important;
`;

let propTypes = {
	"data-selected": PropTypes.bool,
	"data-testid": PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	href: PropTypes.string,
	icon: PropTypes.string,
	iconSize: PropTypes.oneOf(["tiny", "small", "large", "huge"]),
	noAccessFlag: PropTypes.bool,
	noFixedWidthFlag: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseOver: PropTypes.func,
	onMouseOut: PropTypes.func,
	padding: PropTypes.string,
	target: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

let Item = React.forwardRef(
	(
		{
			"data-selected": dataSelected,
			"data-testid": dataTestId,
			children,
			className,
			noAccessFlag,
			href,
			icon,
			iconMarginRight,
			iconSize,
			noFixedWidthFlag,
			onClick,
			onMouseOver,
			onMouseOut,
			padding,
			target,
		},
		ref
	) => {
		let translate = useTranslate();
		let noAccessWarning = useCallback(
			(e) => {
				e.preventDefault();
				toast.warning(translate("manage.access.noAccessToElement") + " " + translate("print.contactSupport"));
			},
			[translate]
		);
		return (
			<StyledItem
				$padding={padding}
				className={className}
				data-selected={dataSelected}
				data-testid={dataTestId}
				href={href}
				ref={ref}
				$noAccessFlag={noAccessFlag}
				onClick={noAccessFlag ? noAccessWarning : onClick}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				target={target}
			>
				{icon && (
					<Block width="16px" height="16px" marginRight={iconMarginRight || "12px"}>
						<Icon noFixedWidthFlag={noFixedWidthFlag} name={icon} size={iconSize} />
					</Block>
				)}
				{children}
			</StyledItem>
		);
	}
);

Item.propTypes = propTypes;

export default Item;
