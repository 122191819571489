import SelectLayout from "./Select.Layout";
import { useTranslate } from "@clearpoint/translate";

let SelectActionItemLayout = (props) => {
	let translate = useTranslate();
	return (
		<SelectLayout label={`${translate("actionItem")} ${translate("global.report")}`} object="actionItem" {...props} />
	);
};

export default SelectActionItemLayout;
