import getTranslateLookup from "./useScorecardLanguage.getTranslateLookup";

let getScorecardLanguage = ({
	generateScorecardTranslate,
	localeId,
	scorecardIdList,
	setTranslateLookup,
	token,
	translateLookup,
}) => {
	if (!localeId) return;
	let baseTranslate = translateLookup[localeId];
	if (!baseTranslate || !token || scorecardIdList.length === 0) return;
	setTranslateLookup((translateLookup) =>
		getTranslateLookup({ generateScorecardTranslate, localeId, scorecardIdList, translateLookup })
	);
};

export default getScorecardLanguage;
