let processIncomingKeyResultList = (keyResultList) =>
	keyResultList?.map?.((keyResult) => {
		let { currencyCode, currentValue, startValue, targetValue } = keyResult;
		return {
			...keyResult,
			currencyCode: currencyCode ?? "   ",
			currentValue: +currentValue,
			startValue: +startValue,
			targetValue: +targetValue,
		};
	});

export default processIncomingKeyResultList;
