import FormGroup from "../../../Form/Form.Group";
import SelectPeer from "../../../Form/Select/Select.Peer";
import SelectPeerGroup from "../../../Form/Select/Select.PeerGroup";
import SelectPeerMeasure from "../../../Form/Select/Select.PeerMeasure";
import SelectPeerSeries from "../../../Form/Select/Select.PeerSeries";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { PropTypes } from "prop-types";
import { joinNameList } from "@clearpoint/utils";

let propTypes = {
	name: PropTypes.string,
};

let InputPeerSeries = ({ name }) => {
	let translate = useTranslate();
	let value = useFormValue(name);
	let peerGroupId = value?.peerGroupId;
	let peerId = value?.peerDistrictId;
	let measureId = value?.peerMeasureId;

	return (
		<FormGroup title={translate("global.peerSeries")}>
			<SelectPeerGroup
				label={translate("options.selectPeerGroup")}
				placeholder={translate("options.selectPeerGroup") + "..."}
				name={joinNameList(name, "peerGroupId")}
			/>
			{peerGroupId ? (
				<>
					<SelectPeer
						label={translate("options.selectPeer")}
						placeholder={translate("options.selectPeer") + "..."}
						name={joinNameList(name, "peerDistrictId")}
						peerGroupId={peerGroupId}
						measureFlag
					/>
					<SelectPeerMeasure
						label={translate("objects.measure")}
						placeholder={translate("options.selectMeasure") + "..."}
						name={joinNameList(name, "peerMeasureId")}
						peerGroupId={peerGroupId}
						peerId={peerId}
					/>
				</>
			) : null}
			{measureId ? (
				<SelectPeerSeries
					label={translate("labels.measureSeries")}
					placeholder={translate("options.selectMeasureSeries") + "..."}
					name={joinNameList(name, "peerMeasureSeriesId")}
					peerGroupId={peerGroupId}
					measureId={measureId}
					required
				/>
			) : null}
		</FormGroup>
	);
};

InputPeerSeries.propTypes = propTypes;

export default InputPeerSeries;
