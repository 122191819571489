import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import Block from "../../Block";
import Button from "../../Button/Button";
import { useFormContext } from "../../Form/Form";
import { theme } from "../../Theme";
import Select from "./FormSelect";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	"data-testid": PropTypes.string,
	defaultCurrentPeriodFlag: PropTypes.bool,
	hideSelectAllSelectNoneFlag: PropTypes.bool,
	marginBottom: PropTypes.string,
	multipleFlag: PropTypes.bool,
	compactFlag: PropTypes.bool,
	periodGroupId: PropTypes.number,
	valueKey: PropTypes.string,
	name: PropTypes.string,
};
let defaultProps = {
	valueKey: "periodId",
};
let FormSelectPeriod = ({
	compactFlag,
	"data-testid": dataTestId,
	defaultCurrentPeriodFlag,
	hideSelectAllSelectNoneFlag,
	marginBottom,
	multipleFlag,
	periodGroupId,
	valueKey,
	...props
}) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { session } = useOldSession();
	let { get } = useOldQueryStore();
	let periodList = useMemo(() => {
		if (!periodGroupId || periodGroupId === -1) return get({ object: "period" });
		let periodGroupList = periodGroupId && get({ object: "periodGroup", queryString: "?include=periods" });
		return periodGroupList?.find((x) => x.periodGroupId === periodGroupId)?.periods;
	}, [get, periodGroupId]);
	if (defaultCurrentPeriodFlag)
		props.defaultValue = periodList?.find((x) => x.periodId === session.periodId)?.[valueKey];
	let options = useMemo(
		() =>
			periodList
				?.filter((period) => !period.hidden && !period.archived)
				.map((period) => ({ label: period.name, value: period[valueKey] })),
		[periodList, valueKey]
	);
	let selectAll = useCallback(() => {
		setFormValue(
			props.name,
			options.map((x) => x.value)
		);
	}, [options, props.name, setFormValue]);
	let selectNone = useCallback(() => {
		setFormValue(props.name, []);
	}, [props.name, setFormValue]);

	return (
		<>
			<Select
				data-testid={dataTestId || "form-select-period"}
				hideRemoveAllFlag
				marginBottom={marginBottom}
				multipleFlag={multipleFlag}
				placeholder={translate(multipleFlag ? "layout.selectPeriods" : "select.selectPeriod") + "..."}
				options={options}
				{...props}
			/>
			{multipleFlag && !hideSelectAllSelectNoneFlag && (
				<Block marginTop={`-${theme.tinySpace}`} marginBottom={marginBottom || theme.space} textAlign="right">
					<Button
						marginRight={compactFlag ? "0px" : theme.smallSpace}
						onClick={selectNone}
						outlineFlag
						size="small"
						type="button"
						width={compactFlag ? "50%" : undefined}
					>
						{translate(compactFlag ? "global.none" : "global.selectNone")}
					</Button>
					<Button onClick={selectAll} size="small" type="button" width={compactFlag ? "50%" : undefined}>
						{translate(compactFlag ? "global.all" : "global.selectAll")}
					</Button>
				</Block>
			)}
		</>
	);
};
FormSelectPeriod.propTypes = propTypes;
FormSelectPeriod.defaultProps = defaultProps;

export default FormSelectPeriod;
