import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyArray } from "@clearpoint/utils";

let usePodLinkList = ({ fieldId, linkListInForm, pageObject, pageObjectId }) => {
	let { get } = useOldQueryStore();
	let milestoneList;
	if (fieldId === "milestones" && pageObject === "initiative") {
		milestoneList = get({ parent: "initiative", parentId: pageObjectId, object: "milestone" });
	}
	return useMemo(() => {
		if (!fieldId || isEmpty(linkListInForm)) return emptyArray;

		let typeList;
		if (fieldId === "linkedElements") {
			// The order of these types is deliberate and affects how the list appears on the page -- ask before changing (unless you're Dylan or Andy)
			typeList = [
				"objectives",
				"measures",
				"measureInitiatives",
				"initiatives",
				"initiativeMeasures",
				"milestones",
				"parents",
				"children",
				"actionItems",
				"risks",
			];
		} else if (fieldId === "children" && pageObject === "milestone") {
			typeList = ["milestones", "subMilestones"];
		} else if (fieldId === "category") {
			typeList = ["categories"];
		} else {
			typeList = [fieldId];
		}

		let linkList =
			typeList
				.flatMap((type) => linkListInForm?.filter((link) => link?.fieldId === type))
				.filter((link) => link?.access !== "No Access" && !link?.hidden) ?? emptyArray;

		if (milestoneList) {
			linkList = linkList.map((link) => {
				let milestone = milestoneList.find((x) => x.objectId === link.objectId);
				return { ...link, ...milestone };
			});
		}
		return linkList;
	}, [fieldId, linkListInForm, milestoneList, pageObject]);
};

export default usePodLinkList;
