import isArray from "lodash/isArray";
import { getEditStatusDisplayFlag } from "@clearpoint/utils";


let beforeSubmit =
	({ periodGroupList }) =>
	(submitValue, contextValue) => {
		if (submitValue?.reportColumns) {
			for (let [idx, reportColumn] of submitValue.reportColumns.entries()) {
				reportColumn.sortOrder = idx;
				let controlledStatusDisplay = getEditStatusDisplayFlag(reportColumn);
				if (!controlledStatusDisplay) {
					reportColumn.statusDisplay = reportColumn.hideStatus ? "hidden" : "icon";
				}
				if (reportColumn.periods?.selectedPeriods && !reportColumn.periods?.periodList) {
					let { selectedPeriods } = reportColumn.periods;
					let list = selectedPeriods.map((periodId) => {
						let periodData = periodGroupList
							.find((periodGroup) => periodGroup.periodGroupId === reportColumn.periods.periodGroupId)
							?.periods.find((x) => x.periodId === periodId);
						return {
							periodId: periodId,
							periodName: periodData?.name || "",
							periodDate: periodData?.periodDate || "",
						};
					});
					reportColumn.periods.periodList = list;
					reportColumn.periods.selectedPeriodCount = selectedPeriods.length;
					reportColumn.periods.selectedPeriods = selectedPeriods.join(",");
				} else if (
					reportColumn.periods?.selectedPeriods &&
					reportColumn.periods?.periodList.length !== reportColumn.periods?.selectedPeriods.length
				) {
					let { periodList } = reportColumn.periods;
					let list = periodList.map((period) => {
						let periodData = periodGroupList
							.find((periodGroup) => periodGroup.periodGroupId === reportColumn.periods.periodGroupId)
							?.periods.find((x) => x.periodId === period.periodId);
						if (periodData) {
							return {
								periodId: period.periodId,
								periodName: periodData.name,
								periodDate: periodData.periodDate,
							};
						} else {
							return period;
						}
					});
					reportColumn.periods.periodList = list;
					reportColumn.periods.selectedPeriods = reportColumn.periods.selectedPeriods.join(",");
				} else if (isArray(reportColumn.periods?.selectedPeriods)) {
					reportColumn.periods.selectedPeriods = reportColumn.periods.selectedPeriods.join(",");
				}
				reportColumn.label = reportColumn.customLabel;
				delete reportColumn.customLabel;
			}
		}
		if (submitValue?.filter) {
			submitValue.filterProperties = submitValue.filter;
			delete submitValue.filter;
			submitValue.filterRule = contextValue.formValue.filterRule;
		}
		return submitValue;
	};

export default beforeSubmit;
