import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { useFormatDate } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};

let LastEditedDisplay = ({ name }) => {
	let formatDate = useFormatDate();
	let value = useFormValue(name);
	return <>{formatDate(value, "grid")}</>;
};

LastEditedDisplay.propTypes = propTypes;
export default LastEditedDisplay;
