import Portal from "../../Portal/Portal";
import Dropdown from "react-bootstrap/Dropdown";

let popperConfig = {
	strategy: "fixed",
};

let KebabMenu = ({ children }) => (
	<Portal>
		<Dropdown.Menu popperConfig={popperConfig}>{children}</Dropdown.Menu>
	</Portal>
);

export default KebabMenu;
