import { useEffect } from "react";
import { useFormContext } from "../Form";
import useFormValue from "./useFormValue";
import getDeepValue from "lodash/get";

let useStringToIntegerFormValue = (name) => {
	let { setFormValue, formDefaultValue, setFormDefaultValue } = useFormContext();
	let value = useFormValue(name);
	let defaultValue = getDeepValue(formDefaultValue, name);
	useEffect(() => {
		if (typeof defaultValue === "string") setFormDefaultValue(name, +value);
		if (typeof value === "string") setFormValue(name, +value);
	}, [defaultValue, name, setFormDefaultValue, setFormValue, value]);
};
export default useStringToIntegerFormValue;
