import isEqual from "lodash/isEqual";

import { emptyObject, findUpdates } from "@clearpoint/utils";

let getSubmitMilestoneGanttData = ({ formDefaultValue, ganttData, pagePeriodId, set }) => {
	if (!ganttData) return;

	let getGanttMilestoneEditFields = (array) =>
		array
			?.filter((x) => x?.object === "milestone")
			.map((milestone) => {
				let { completed, completedDate, endDate, name, objectId, startDate } = milestone;
				if (completed === false) completedDate = "";
				return { completed, completedDate, endDate, name, objectId, startDate };
			});

	let getGanttMilestoneUpdateFields = (array) =>
		array
			?.filter((x) => x?.object === "milestone")
			.map((milestone) => {
				let { objectId, percentComplete, statusId } = milestone;
				return { objectId, percentComplete, statusId };
			});

	let submitValueGanttMilestoneEditList = getGanttMilestoneEditFields(ganttData);
	let defaultValueGanttMilestoneEditList = getGanttMilestoneEditFields(formDefaultValue?.ganttData);
	let ganttMilestoneEditList = submitValueGanttMilestoneEditList.reduce((editList, milestone, i) => {
		let updatedFields = findUpdates({
			original: defaultValueGanttMilestoneEditList?.[i],
			updated: milestone,
		});
		if (isEqual(emptyObject, updatedFields)) return editList;
		return [...editList, { object: "milestone", objectId: milestone.objectId, fields: updatedFields }];
	}, []);

	let submitValueGanttMilestoneUpdateList = getGanttMilestoneUpdateFields(ganttData);
	let defaultValueGanttMilestoneUpdateList = getGanttMilestoneUpdateFields(formDefaultValue.ganttData);
	let ganttMilestoneUpdateList = submitValueGanttMilestoneUpdateList.reduce((updateList, milestone, i) => {
		let updatedFields = findUpdates({
			original: defaultValueGanttMilestoneUpdateList?.[i],
			updated: milestone,
		});
		if (isEqual(emptyObject, updatedFields)) return updateList;
		return [
			...updateList,
			{ object: "milestone", objectId: milestone.objectId, periodId: pagePeriodId, fields: updatedFields },
		];
	}, []);

	set({
		object: "update",
		data: {
			edits: ganttMilestoneEditList,
			updates: ganttMilestoneUpdateList,
		},
	});
};

export default getSubmitMilestoneGanttData;
