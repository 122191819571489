import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";

let findKeyResultUpdates = ({ original, updated }) => {
	if (!isArray(original) && !isArray(updated)) return;

	let keyResultUpdateList = [];
	updated?.forEach((updatedKeyResult, i) => {
		let originalKeyResult = original[i];

		if (!isEqual(updatedKeyResult, originalKeyResult)) {
			keyResultUpdateList.push(updatedKeyResult);
		}
	});

	return keyResultUpdateList;
};

export default findKeyResultUpdates;
