let autoFitColumnWidths = ({ columnWidthList, containerWidth }) => {
	let usedWidth = columnWidthList.reduce((usedWidth, columnWidth) => usedWidth + (columnWidth || 0), 0);
	let remainingWidth = containerWidth - usedWidth;

	let undefinedWidthColumnCount = columnWidthList.filter((x) => x === undefined).length;
	let fittedColumnWidth = Math.max(Math.floor(remainingWidth / undefinedWidthColumnCount), 100);

	let updatedColumnWidthList = columnWidthList.map((x) => (x === undefined ? fittedColumnWidth : x));

	return updatedColumnWidthList;
};

export default autoFitColumnWidths;
