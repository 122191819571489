import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import toPath from "lodash/toPath";
import removeUndefined from "./removeUndefined";

let updateDeepValue = (object, name, value, callback) => {
	// Similar to lodash set, except
	// takes true as name and updates entire object
	// takes objects as "value" and updates nested values in "object" while leaving others intact
	// note the special case: _validation key overwrites prior validation as an object
	// passing in an array results in overwritten array
	let updateWithSimpleKey = ({ key, name, object, value }) => {
		let oldValue = object[key];
		let simpleOldValueFlag = !oldValue || !isObject(oldValue);
		let simpleNewValueFlag = !isObject(value) || value.hasOwnProperty("_validation") || isArray(value);
		if (simpleOldValueFlag || simpleNewValueFlag) {
			if (isObject(value)) value = removeUndefined(value);
			object[key] = value;
			if (callback) callback({ name, value });
			return;
		}
		for (let valueKey in value) {
			let valueAtKey = value[valueKey];
			let oldValue = object[key];
			object[key] = isArray(oldValue)
				? [...oldValue]
				: isObject(oldValue)
				? { ...oldValue }
				: isArray(value)
				? []
				: {};
			updateWithSimpleKey({
				key: valueKey,
				name: `${name}.${valueKey}`,
				object: object[key],
				value: valueAtKey,
			});
		}
	};
	let newObject = object ? { ...object } : {};
	if (name === true) {
		if (!isObject(value)) return true;
		for (let key in value) {
			updateWithSimpleKey({
				key,
				name: key,
				object: newObject,
				value: value[key],
			});
		}
		return newObject;
	}
	let referenceObject = newObject;
	let key;
	let path = toPath(name).map((key) => (!isNaN(+key) ? +key : key));
	let i = 0;
	for (let key of path.slice(0, path.length - 1)) {
		let value = referenceObject[key];
		if (isArray(value)) referenceObject[key] = [...value];
		else if (isObject(value)) referenceObject[key] = { ...value };
		else {
			let arrayFlag = typeof path[i + 1] === "number";
			referenceObject[key] = arrayFlag ? [] : {};
		}
		referenceObject = referenceObject[key];
		i++;
	}
	key = path[path.length - 1];
	updateWithSimpleKey({ object: referenceObject, key, value, name });
	return isEqual(object, newObject) ? object : newObject;
};
export default updateDeepValue;
