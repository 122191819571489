import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectContentType = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: "text", label: translate("seriesTypes.seriesType11") },
			{ value: "image", label: translate("customFields.image") },
		],
		[translate]
	);
	return <FormSelect options={options} {...props} />;
};

export default SelectContentType;
