import { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { useDiscardAnchorHandler, useDiscardEventHandler } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import Block from "../Block";
import IconButton from "../Button/Button.Icon";
import Column from "../Column";
import Icon from "../Icon/Icon";
import DiscardModal from "../Modal/Modal.Discard";
import DiscardModalOnClick from "../Modal/Modal.Discard.OnClick";
import Row from "../Row";
import { theme } from "../Theme";
import FormButton from "./Button/Button";
import { useFormContext } from "./Form";

let propTypes = {
	children: PropTypes.node,
	close: PropTypes.func,
	disabled: PropTypes.bool,
	showDiscardModalOnFormDirtyFlag: PropTypes.bool,
};

let FormSaveBar = ({ children, close, disabled, showDiscardModalOnFormDirtyFlag }) => {
	let translate = useTranslate();
	let formContext = useFormContext();
	let { loadingFlag } = useOldQueryStore();
	let loadingFlagRef = useRef();
	loadingFlagRef.current = loadingFlag;
	let { saveStatusRef } = formContext;

	let cancel = useCallback(() => {
		formContext.resetForm();
		close();
	}, [close, formContext]);

	let closeAfterSubmit = useCallback(() => {
		saveStatusRef.current = "saving";
		setTimeout(close);
	}, [close, saveStatusRef]);

	let {
		discardModalVisible: anchorDiscardModalVisible,
		closeDiscardModal: anchorCloseDiscardModal,
		onDiscardModalDiscard: anchorOnDiscardModalDiscard,
	} = useDiscardAnchorHandler();

	let {
		discardModalVisible: eventDiscardModalVisible,
		closeDiscardModal: eventCloseDiscardModal,
		onDiscardModalDiscard: eventOnDiscardModalDiscard,
	} = useDiscardEventHandler();

	return (
		<>
			<DiscardModal
				close={eventCloseDiscardModal}
				modalVisible={eventDiscardModalVisible}
				onDiscard={eventOnDiscardModalDiscard}
			/>
			<DiscardModal
				close={anchorCloseDiscardModal}
				modalVisible={!eventDiscardModalVisible && anchorDiscardModalVisible}
				onDiscard={anchorOnDiscardModalDiscard}
			/>
			<Block data-testid="form-save-bar" backgroundColor={theme.orange3} padding={"10px 15px 13px 15px"}>
				<Row alignItems="center">
					<Column tiny={7}>
						<Block flex="1" padding={`0px ${theme.space}`} whiteSpace="nowrap">
							{children}
						</Block>
					</Column>
					<Column tiny={5}>
						<Block display="flex" justifyContent="flex-end" padding={`0px ${theme.space}`}>
							<DiscardModalOnClick
								close={close}
								onDiscard={cancel}
								discardMessage={translate("edit.confirmDiscard")}
								showDiscardModalOnFormDirtyFlag={showDiscardModalOnFormDirtyFlag}
							>
								<IconButton
									data-testid="save-bar-close"
									size="medium"
									name="close"
									color="danger"
									padding="6px 12px"
									marginRight={theme.space}
									iconTransform=" "
								/>
							</DiscardModalOnClick>
							<FormButton
								color="primary"
								data-testid="save-bar-submit"
								disabled={disabled}
								onClick={closeAfterSubmit}
								size="medium"
							>
								<Icon name="saveCheck" />
							</FormButton>
						</Block>
					</Column>
				</Row>
			</Block>
		</>
	);
};

FormSaveBar.propTypes = propTypes;

export default FormSaveBar;
