/* eslint-disable camelcase */
import { useCallback, useMemo } from "react";

import { useConvertDateStringToDateObject, useFormatDate } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import { useOldSession } from "@clearpoint/old-session/index";

let useGanttData = (elementList) => {
	let convertDateStringToDateObject = useConvertDateStringToDateObject();
	let { get } = useOldQueryStore();
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let { periodId } = useOldSession().session;
	let translate = useTranslate();
	let formatDate = useFormatDate();

	let getGanttElementMovableFlag = useCallback((element) => {
		if (!element) return false;

		if (Object.prototype.hasOwnProperty.call(element, "movableFlag")) return element.movableFlag;
		if (element.object === "initiative") {
			return element.linkMilestoneDates !== 1;
		} else if (element.object === "milestone") {
			return !(element.linkMilestoneDates === 1 && element.hasChildren);
		}

		return false;
	}, []);

	let ganttData = useMemo(() => {
		if (!statusList) return;
		let taskList = [];
		let parentId;
		for (let element of elementList) {
			if (element) {
				let id = taskList.length + 1;
				let hiddenFlag;
				if (taskList.some((x) => x.object === element.object && x.objectId === element.objectId)) hiddenFlag = true;
				if (element.object === "scorecard") {
					taskList.push({
						...element,
						hiddenFlag,
						id,
						open: true,
						text: element.name,
						unscheduled: true,
					});
					parentId = id;
				} else {
					let status = element.status
						? statusList.find((x) => x.statusId === element.status[periodId]?.statusId)
						: statusList.find((x) => x.statusId === element?.statusId);
					let percentComplete = element.percentComplete ?? element.status[periodId]?.percentComplete;
					taskList.push({
						...element,
						color: status?.statusColor,
						end_date: convertDateStringToDateObject(element.endDate),
						hiddenFlag,
						id,
						movableFlag: getGanttElementMovableFlag(element),
						open: true,
						// eslint-disable-next-line no-nested-ternary
						parent: element.parentId
							? elementList.findIndex((x) => x.objectId === element.parentId) + 1
							: (element.object === "initiative"
							? parentId
							: elementList.findIndex((x) => x.objectId === element.initiativeId) + 1),
						progress: percentComplete / 100,
						progressColor: status?.progressColor,
						start_date: convertDateStringToDateObject(element.startDate),
						text: "", //element.completed ? "✓ " + translate("logFieldLabels.completed") + " " + formatDate( element.completedDate, "short") : "",
					});
				}
			}
		}
		// eslint-disable-next-line consistent-return
		return {
			tasks: taskList,
		};
	}, [statusList, elementList, periodId, convertDateStringToDateObject, getGanttElementMovableFlag]);
	return ganttData;
};

export default useGanttData;
