import Block from "../../Block";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	children: PropTypes.node.isRequired,
	customHeaderStylesFlag: PropTypes.bool,
	headerCss: PropTypes.string,
};

let PodHeaderWrapper = ({ children, customHeaderStylesFlag, headerCss, ...props }) => (
	<Block
		className="avoid-page-break-after"
		backgroundColor="#FAFAFB"
		border="none"
		borderRadius="0.5rem 0.5rem 0 0"
		borderTop={customHeaderStylesFlag ? "" : `2px solid ${theme.lightGray}`}
		padding={customHeaderStylesFlag ? "5px 10px 2px 17px" : "13px 15px 8px 15px"}
		minHeight={customHeaderStylesFlag ? "" : "48px"}
		wordBreak="normal"
		overflow="hidden"
		{...props}
		$style={headerCss && `&&& { ${headerCss} }`}
	>
		{children}
	</Block>
);

PodHeaderWrapper.propTypes = propTypes;

export default PodHeaderWrapper;
