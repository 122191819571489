import CellWrapper from "../CellWrapper";
import FormFieldDisplayTags from "@clearpoint/old-theme/Form/Field/Display/Tags";
import FormFieldTags from "@clearpoint/old-theme/Form/Field/Tags";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let TagsCell = (props) => {
	let { editCellFlag, parentName } = props;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldTags name={`${parentName}.tags`} />
			) : (
				<FormFieldDisplayTags name={`${parentName}.tags`} />
			)}
		</CellWrapper>
	);
};
TagsCell.propTypes = propTypes;

export default TagsCell;
