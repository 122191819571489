import Badge from "../../../Badge/Badge";
import SelectAggregateScorecardType from "../../../Form/Select/Select.AggregateScorecardType";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	hasAggregateScorecardTypeFlag: PropTypes.bool,
	labelText: PropTypes.string,
	scorecardCount: PropTypes.number,
};

let AggregateScorecardTypeSelectDisplay = ({ hasAggregateScorecardTypeFlag, labelText, scorecardCount, ...props }) => {
	return (
		<SelectAggregateScorecardType
			label={
				<>
					{!hasAggregateScorecardTypeFlag && (
						<Badge style={{ marginRight: `${theme.smallSpace}` }} color="default">
							0
						</Badge>
					)}
					{hasAggregateScorecardTypeFlag && (
						<Badge
							style={{ marginRight: `${theme.smallSpace}` }}
							color={scorecardCount !== 0 ? "primary" : "danger"}
						>
							{scorecardCount || 0}
						</Badge>
					)}
					{labelText}
				</>
			}
			{...props}
		/>
	);
};

AggregateScorecardTypeSelectDisplay.propTypes = propTypes;

export default AggregateScorecardTypeSelectDisplay;
