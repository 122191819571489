import { useCallback } from "react";
import useCheckFeature from "../useCheckFeature";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import mergeCustomFieldList from "./mergeCustomFieldList";

let propTypes = {
	addFlag: PropTypes.bool,
	multipleFlag: PropTypes.bool,
	scorecardId: PropTypes.number,
};

let useInitiativeEditFieldList = ({ addFlag, multipleFlag, scorecardId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let checkFeature = useCheckFeature();
	let tagFlag = checkFeature("tags");
	let tagList = get({ object: "tag" });
	let dependenciesEnabledFlag = checkFeature("dependencies");

	let customFieldList = get({ object: "customField", queryString: "?object=initiative" });

	return useCallback(() => {
		let filteredCustomFieldList = customFieldList
			?.filter((x) => !x.updateMode)
			.filter((x) => !x.scorecardId || x.scorecardId.length === 0 || x.scorecardId.includes(scorecardId));

		let editMultipleFlag = multipleFlag && !addFlag;
		let startAndEndDate = editMultipleFlag
			? [
					{
						value: "startDate",
						label: translate("defaultFields.edit.initiative.startDate"),
						sortOrder: -990,
						group: translate("initiative"),
						required: false,
					},
					{
						value: "endDate",
						label: translate("defaultFields.edit.initiative.endDate"),
						sortOrder: -990,
						group: translate("initiative"),
						required: false,
					},
			  ]
			: [
					{
						value: "startAndEndDate",
						label: translate("defaultFields.edit.startDateAndEndDate", {
							startDate: translate("defaultFields.edit.initiative.startDate"),
							endDate: translate("defaultFields.edit.initiative.endDate"),
						}),
						sortOrder: -990,
						group: translate("initiative"),
						required: true,
					},
			  ];

		let fieldList = [
			{
				value: "scorecardId",
				label: translate("global.scorecard"),
				sortOrder: -999,
				group: translate("initiative"),
				required: true,
			},
			{
				value: "tags",
				label: translate("global.tags"),
				sortOrder: -998,
				group: translate("initiative"),
			},
			{
				value: "ownerId",
				label: translate("defaultFields.edit.initiative.owner"),
				sortOrder: -997,
				group: translate("initiative"),
			},
			{
				value: "collaborators",
				label: translate("collaborators"),
				sortOrder: -996,
				group: translate("initiative"),
			},
			{
				value: "periodGroupId",
				label: translate("global.periodicity"),
				sortOrder: -995,
				group: translate("initiative"),
			},
			{
				value: "linkMilestoneDates",
				label: translate("edit.dependencies"),
				sortOrder: -991,
				group: translate("initiative"),
				disabled: addFlag,
			},
			...startAndEndDate,
			{
				value: "completedDate",
				label: translate("defaultFields.edit.initiative.completedDate"),
				sortOrder: -987,
				group: translate("initiative"),
			},
		];
		if (!tagFlag || (tagList && tagList.length === 0)) fieldList = fieldList.filter((x) => x.value !== "tags");
		if (!dependenciesEnabledFlag || (addFlag && multipleFlag))
			fieldList = fieldList.filter((x) => x.value !== "linkMilestoneDates");
		return mergeCustomFieldList(fieldList, filteredCustomFieldList, translate);
	}, [tagList, addFlag, translate, dependenciesEnabledFlag, multipleFlag, scorecardId, tagFlag, customFieldList]);
};
useInitiativeEditFieldList.propTypes = propTypes;
export default useInitiativeEditFieldList;
