import { theme } from "../../Theme";

const STROKE_COLOR = "#999";
const STROKE_WIDTH = 1;

const STROKE_HIGHLIGHT_COLOR = theme.orange;
const STROKE_HIGHLIGHT_WIDTH = 5;

const CHILD_MARGIN = 40;
const MARGIN_BETWEEN = 35;
const MARGIN_PAIR = 80;

export {
	STROKE_COLOR,
	STROKE_WIDTH,
	STROKE_HIGHLIGHT_COLOR,
	STROKE_HIGHLIGHT_WIDTH,
	CHILD_MARGIN,
	MARGIN_BETWEEN,
	MARGIN_PAIR,
};
