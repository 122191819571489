import Action from "./Action";
import Block from "../Block";
import MentionUser from "./MentionUser";
import { useCallback, useEffect, useState } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import useScrollReplyInputIntoView from "./useScrollReplyInputIntoView";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { buildQueryString } from "@clearpoint/utils";


let propTypes = {
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	parentId: PropTypes.number,
	toggleReplyFlag: PropTypes.func,
};

let ReplyInput = ({ layoutId, object, objectId, parentId, toggleReplyFlag }) => {
	let { set } = useOldQueryStore();
	let { newCommentBoxVisible, setLocalState } = useLocalState();

	let [sendingFlag, setSendingFlag] = useState(false);
	let [replyValue, setReplyValue] = useState("");
	let updateValue = useCallback((e) => setReplyValue(e.target.value), []);

	let closeEditMode = useCallback(() => {
		setReplyValue("");
		setSendingFlag(false);
		toggleReplyFlag();
		setLocalState({ newCommentBoxVisible: true });
	}, [setLocalState, toggleReplyFlag]);

	let saveComment = useCallback(() => {
		setSendingFlag(true);
		set({
			object: "comment",
			queryString: buildQueryString({ object, layoutId, objectId }),
			data: { comment: replyValue, parentId },
		}).then(() => {
			closeEditMode();
		});
	}, [closeEditMode, layoutId, object, objectId, parentId, replyValue, set]);

	let { replyInputContainerRef } = useScrollReplyInputIntoView();

	let sendButtonDisabled = replyValue.length === 0 || sendingFlag;

	useEffect(() => {
		if (newCommentBoxVisible) setLocalState({ newCommentBoxVisible: false });
	}, [newCommentBoxVisible, setLocalState]);

	return (
		<Block ref={replyInputContainerRef}>
			<Block margin={`${theme.tinySpace} 0px`}>
				<MentionUser value={replyValue} updateValue={updateValue} />
			</Block>
			<Block display="flex" justifyContent="flex-end" marginBottom={theme.smallSpace}>
				<Action name="close" onClick={closeEditMode} marginRight={theme.tinySpace} />
				<Action name="send" spinFlag={sendingFlag} disabledFlag={sendButtonDisabled} onClick={saveComment} />
			</Block>
		</Block>
	);
};

ReplyInput.propTypes = propTypes;
export default ReplyInput;
