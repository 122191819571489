import { useLayoutEffect, useState } from "react";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsLollipop from "highcharts/modules/lollipop";
import highchartsSankey from "highcharts/modules/sankey";
import highchartsSolidGauge from "highcharts/modules/solid-gauge";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import useResizeChart from "./useResizeChart";

Highcharts.AST.allowedTags.push("g");
Highcharts.AST.allowedAttributes.push(
	"focusable",
	"data-prefix",
	"data-icon",
	"data-style",
	"data-testid",
	"xmlns",
	"viewBox",
	"src",
	"alt",
	"title",
	"transform"
);
highchartsMore(Highcharts);
highchartsSankey(Highcharts);
highchartsAccessibility(Highcharts);
highchartsDumbbell(Highcharts);
highchartsExporting(Highcharts);
highchartsLollipop(Highcharts);
highchartsSolidGauge(Highcharts);
Highcharts.AST.allowedReferences.push("data:");
window.Highcharts = Highcharts;

// https://api.highcharts.com/highcharts/
let yAxisPropType = PropTypes.shape({
	allowDecimals: PropTypes.bool,
	labels: PropTypes.shape({
		formatter: PropTypes.func,
	}),
	plotBands: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			from: PropTypes.number,
			to: PropTypes.number,
		})
	),
	plotLines: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			value: PropTypes.number,
			width: PropTypes.number,
		})
	),
	reversed: PropTypes.bool,
	title: PropTypes.shape({
		text: PropTypes.string,
	}),
});
let propTypes = {
	options: PropTypes.shape({
		accessibility: PropTypes.shape({
			description: PropTypes.string,
		}),
		chart: PropTypes.shape({
			backgroundColor: PropTypes.string,
			height: PropTypes.number,
			style: PropTypes.object,
			reflow: PropTypes.func,
			type: PropTypes.string,
		}),
		colors: PropTypes.arrayOf(PropTypes.string),
		credits: PropTypes.shape({
			enabled: PropTypes.bool,
		}),
		exporting: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({
				buttons: PropTypes.shape({
					contextButton: PropTypes.shape({
						align: PropTypes.string,
						menuItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
						verticalAlign: PropTypes.string,
						x: PropTypes.number,
						y: PropTypes.number,
					}),
				}),
				filename: PropTypes.string,
			}),
		]),
		legend: PropTypes.shape({
			align: PropTypes.string,
			enabled: PropTypes.bool,
			layout: PropTypes.string,
			verticalAlign: PropTypes.string,
		}),
		pane: PropTypes.object,
		plotOptions: PropTypes.shape({
			series: PropTypes.object,
		}),
		series: PropTypes.arrayOf(
			PropTypes.shape({
				animation: PropTypes.bool,
				color: PropTypes.string,
				data: PropTypes.array,
				measureSeriesId: PropTypes.number,
				name: PropTypes.string,
				numberFormat: PropTypes.shape({
					precision: PropTypes.number,
					symbol: PropTypes.string,
				}),
				periodOffset: PropTypes.number,
				regressionModal: PropTypes.string,
				tooltip: PropTypes.shape({
					headerFormat: PropTypes.string,
					pointFormatter: PropTypes.func,
				}),
				yAxis: PropTypes.number,
				zIndex: PropTypes.number,
			})
		),
		title: PropTypes.shape({
			margin: PropTypes.number,
			style: PropTypes.shape({
				color: PropTypes.string,
				fontFamily: PropTypes.string,
				fontSize: PropTypes.string,
			}),
			text: PropTypes.string,
		}),
		xAxis: PropTypes.shape({
			categories: PropTypes.arrayOf(PropTypes.string),
			title: PropTypes.shape({
				text: PropTypes.string,
			}),
		}),
		yAxis: PropTypes.oneOfType([yAxisPropType, PropTypes.arrayOf(yAxisPropType)]),
	}),
};

let Chart = ({ options }) => {
	let { clear } = useOldQueryStore();
	let title = options?.accessibility?.description;
	let [element, setElement] = useState();
	let resize = useResizeChart(element);
	useLayoutEffect(() => {
		let container = element?.container?.current;
		if (container) {
			let description = container.querySelector("desc");
			if (description) description.textContent = title;
		}
	}, [clear, element, resize, title]);
	// Edgecase for City of Virginia - Table is not a valid chart type
	if (options?.chart?.type === "table") options.chart.type = "column";
	return options ? <HighchartsReact highcharts={Highcharts} options={options} immutable ref={setElement} /> : null;
};

Chart.propTypes = propTypes;
export default Chart;
