import ToolbarBadge from "./ToolbarBadgeTemplate";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";
import { useFormatDate } from "@clearpoint/hooks";


let propTypes = {
	completedFlag: PropTypes.bool,
	completedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

let CompletedBadge = ({ completedFlag, completedDate }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { completed: formCompletedFlag, formCompletedDate } = useFormValue() || emptyObject;

	return (
		<ToolbarBadge visible={completedFlag || formCompletedFlag} color="info">
			{`${translate("logFieldLabels.completed")} ${formatDate(completedDate || formCompletedDate, "short")}`}
		</ToolbarBadge>
	);
};

CompletedBadge.propTypes = propTypes;

export default CompletedBadge;
