import { useCallback } from "react";
import * as RedactorLibrary from "@clearpoint/redactor/redactor";
let Redactor = RedactorLibrary.default;

let useInsertText = (editorRef, id, tinyMceFlag, offset) =>
	useCallback(
		(text) => {
			if (global.testFlag) global.testFunction(text);
			if (tinyMceFlag) {
				editorRef.current.insertContent(text);
			} else {
				if (offset !== undefined) {
					Redactor(`#text-editor${id}`, "insertion.insertToOffset", offset, text);
				} else {
					Redactor(`#text-editor${id}`, "insertion.insertText", text);
				}
			}
		},
		[editorRef, id, offset, tinyMceFlag]
	);
export default useInsertText;
