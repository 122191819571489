import { useDroppable } from "@dnd-kit/core";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	containerId: PropTypes.string,
	podList: PropTypes.arrayOf(PropTypes.string),
};

let Droppable = ({ children, containerId, podList }) => {
	const { setNodeRef } = useDroppable({
		id: containerId,
		data: {
			type: "container",
			children: podList,
		},
	});
	return <div ref={setNodeRef}>{children}</div>;
};

Droppable.propTypes = propTypes;
export default Droppable;
