import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import rowController from "./Grid.rowController";

let useRowControls = (columnKeyList, initialLength, name, selectedRowRef) => {
	let rowList = useFormValue(name);
	let { setFormValue } = useFormContext();

	let { addBlankRow, createRow, removeRowByIndex } = useMemo(
		() => rowController(rowList, columnKeyList, initialLength),
		[columnKeyList, initialLength, rowList]
	);

	let removeRow = useCallback(() => {
		let updatedRowList = removeRowByIndex(selectedRowRef.current);
		setFormValue(name, updatedRowList);
	}, [name, removeRowByIndex, selectedRowRef, setFormValue]);

	let addRow = useCallback(
		(newFormState) => {
			let currentRowList = rowList;
			if (newFormState && !(newFormState.nativeEvent && newFormState.nativeEvent instanceof Event)) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				currentRowList = newFormState;
			}
			let updatedRowList = addBlankRow(currentRowList);
			setFormValue(name, updatedRowList);
		},
		[addBlankRow, name, rowList, setFormValue]
	);

	return { addRow, removeRow, createRow };
};

export default useRowControls;
