import { useEffect, useState } from "react";

let HandleNetworkError = ({ children }) => {
	let [error, setError] = useState();
	if (error) throw error;
	useEffect(() => {
		let throwNetworkError = (e) => {
			if (e?.reason?.message?.includes("Too many broken requests.")) setError(e.reason);
		};
		window.addEventListener("unhandledrejection", throwNetworkError);
		return () => window.removeEventListener("unhandledrejection", throwNetworkError);
	});
	return children;
};

export default HandleNetworkError;
