import { emptyObject } from "@clearpoint/utils";

let getHandleChatValidation = ({ e, setAssistantState, handleAfterSubmitAddActionItems }) => {
	let { json, message, status } = e?.detail || emptyObject;

	if (status === "error" && message === "invalidPayload") {
		setAssistantState({ validatingFlag: false });
		return;
	}

	if (status === "complete") {
		let actionItemList = json.actionItems;
		let aiAddActionItemsName = actionItemList.map((item) => item.actionItem.replace(/\.$/, "")).join("\n");
		handleAfterSubmitAddActionItems({
			aiAddActionItemsName,
			editMultipleActionItemsModalVisible: true,
			completedMessage: message,
		});
	}
};
export default getHandleChatValidation;
