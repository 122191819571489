import { Fragment, useCallback, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { useId, useQueryParameters } from "@clearpoint/hooks";

let propTypes = {
	children: PropTypes.node.isRequired,
};
let QueryRoute = ({ children, ...props }) => {
	let id = useId();
	let [hash, setHash] = useState(window.location.hash);
	let updateHash = useCallback(() => setHash(window.location.hash), []);
	let query = useQueryParameters();
	let visible = Object.entries(props).every(([key, value]) => {
		return (
			(value === true && !!query[key]) ||
			query[key] === value ||
			(value?.startsWith?.("!") && !value.replace(/!/g, "").split("&").includes(query[key]))
		);
	});
	useLayoutEffect(() => {
		window.addEventListener("hashchange", updateHash);
		return () => {
			window.removeEventListener("hashchange", updateHash);
		};
	}, [updateHash]);
	let key = `id${id}hash${hash}`;
	return visible ? <Fragment key={key}>{children}</Fragment> : null;
};
QueryRoute.propTypes = propTypes;
export default QueryRoute;
