import PropTypes from "prop-types";

import IconButton from "../Button/Button.Icon";
import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";

let propTypes = {
	disabledFlag: PropTypes.bool,
	marginRight: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
	spinFlag: PropTypes.bool,
};

let Action = ({ disabledFlag, blockFlag, label, marginRight, name, onClick, spinFlag }) => {
	return (
		<StyleWrapper
			border={`1px solid ${theme.lightGray}`}
			borderRadius={theme.borderRadius}
			
			overflow="visible"
			width={blockFlag ? "100%" : "auto"}
			color={blockFlag ? "white" : "auto"}
			backgroundColor={blockFlag ? theme.primary : theme.white}
			cursor={disabledFlag ? "not-allowed !important" : "pointer"}
		>
			<IconButton
				color="white"
				disabled={disabledFlag}
				padding={blockFlag ? "7px 10px" : "3px 6px"}
				iconTransform={spinFlag ? "" : undefined}
				name={spinFlag ? "saving" : name}
				onClick={onClick}
				marginRight={marginRight}
				size={blockFlag ? "medium" : "tiny"}
				label={label}
				spinFlag={spinFlag}
			/>
		</StyleWrapper>
	);
};

Action.propTypes = propTypes;
export default Action;
