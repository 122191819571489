import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyArray } from "@clearpoint/utils";

let useNoAvailablePeriodsFlag = () => {
	let { get } = useOldQueryStore();

	let periodList = get({ object: "period" }) || emptyArray;
	let availablePeriodList = useMemo(() => periodList.filter((x) => x.scorecardList.length === 0), [periodList]);
	return availablePeriodList.length === 0;
};

export default useNoAvailablePeriodsFlag;
