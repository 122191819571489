import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import Badge from "@clearpoint/old-theme/Badge/Badge";
import Column from "@clearpoint/old-theme/Column";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import Help from "@clearpoint/old-theme/Help";
import Row from "@clearpoint/old-theme/Row";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";

let NotificationsTabContent = () => {
	let translate = useTranslate();
	let { data: notificationList } = useFilter();
	return (
		<>
			<AlertUpsell feature="notifications" />
			<Help section="sect2" feature="element-notifications" />
			<FilterInput
				name="notification"
				filter={(x, value) =>
					!value ||
					x.label.toLowerCase().includes(value.toLowerCase()) ||
					x.criteriaList?.some((criterion) => criterion.toLowerCase().includes(value.toLowerCase()))
				}
				placeholder={translate("components.filterNotifications") + "..."}
			/>
			<FormGroup title={translate("notifications.notifyMeOn")}>
				{notificationList
					.filter((x) => x.visible)
					.map((x) => (
						<StyleWrapper key={x.name} marginBottom={theme.smallSpace}>
							<Row>
								<Column medium={6}>
									<StyleWrapper marginBottom="0px">
										<FormCheck name={x.name} defaultValue={x.defaultValue}>
											{x.label}
										</FormCheck>
									</StyleWrapper>
								</Column>
								<Column medium={6}>
									{x.criteriaList?.map((criterion) => (
										<Badge key={criterion} size="small" color="info" marginRight={theme.tinySpace}>
											{criterion}
										</Badge>
									))}
								</Column>
							</Row>
						</StyleWrapper>
					))}
			</FormGroup>
		</>
	);
};
export default NotificationsTabContent;
