import Select from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormSelectReportingFrequency = ({ name, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let options = useMemo(() => {
		let periodGroupList = get({ object: "periodGroup" }) || [];

		let firstOption = { label: translate("manage.chart.defaultReportingFrequency"), value: -1 };
		let periodGroupOptionList = periodGroupList
			.filter((x) => x.active)
			.map((x) => ({ label: x.name, value: x.periodGroupId }));
		let lastOption = { label: translate("global.specificPeriods"), value: 0 };

		return [firstOption, ...periodGroupOptionList, lastOption];
	}, [get, translate]);
	return <Select options={options} name={name} {...props} />;
};

FormSelectReportingFrequency.propTypes = propTypes;

export default FormSelectReportingFrequency;
