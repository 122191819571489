import { useEffect, useRef } from "react";
import { useMainStore } from "@clearpoint/store";
import { noop } from "@clearpoint/utils";
import useTranslatorState from "../Translator/useTranslatorState";

let outsideRef = {};
function usePreviousDefined(value, name) {
	const ref = useRef();
	useEffect(() => {
		if (name && value !== undefined) outsideRef[name] = value;
		else if (value !== undefined) ref.current = value;
	});
	return name ? outsideRef[name] : ref.current;
}

let useTranslate = () => {
	let localeId = useMainStore((state) => state.localeId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { translateLookup } = useTranslatorState();
	let scorecardTranslate = translateLookup[scorecardId];
	let previousScorecardTranslate = usePreviousDefined(scorecardTranslate);
	let baseTranslate = translateLookup[localeId];
	return scorecardTranslate || previousScorecardTranslate || baseTranslate || noop;
};
export default useTranslate;
