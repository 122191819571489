import { useMainStore } from "@clearpoint/store";

let ViewMultiple = () => {
	// this should only rerender when one of these variables is updated.
	let dashboardChartCount = useMainStore((state) => state.dashboardChartCount);
	let view = useMainStore((state) => state.view);

	// avoid the following anti-pattern would cause rerenders with any store changes
	// let { dashboardChartCount, view } = useMainStore();

	return (
		<div style={{ border: "1px solid black", padding: "5px" }}>
			<div>
				<strong>Multiple</strong>
			</div>
			<div>dashboardChartCount: {dashboardChartCount}</div>
			<div>view: {view}</div>
		</div>
	);
};

export default ViewMultiple;
