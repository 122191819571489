import { useDeleteObjectiveMutation, useObjectiveListQuery } from "@clearpoint/query";
import List from "./List";
import { useMainStore } from "@clearpoint/store";

let Delete = () => {
	let periodId = useMainStore((state) => state.periodId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { data: objectiveList } = useObjectiveListQuery({ periodId, scorecardId });
	let activeObjectiveList = objectiveList.filter((x) => x.active);
	let name = activeObjectiveList[0]?.name;
	let active = activeObjectiveList[0]?.active ? "true" : "false";
	let objectId = activeObjectiveList[0]?.objectId;
	let { mutate: trashObjective } = useDeleteObjectiveMutation({ objectId, periodId, scorecardId });

	return (
		<div>
			<div>{name}</div>
			<div>List will update on click, without waiting for api response</div>
			<div>active: {active}</div>
			<div>
				<button onClick={trashObjective}>Delete Objective</button>
			</div>
			<List />
		</div>
	);
};

export default Delete;
