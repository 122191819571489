import { useTranslate } from "@clearpoint/translate";

export default function useTranslateBooleans() {
	let translate = useTranslate();
	return (value) => {
		if (typeof value == "boolean") {
			return translate(value ? "global.displayYes" : translate("global.displayNo"));
		}
		return value;
	};
}
