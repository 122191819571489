let getChartType = (chartData) => {
	if (!chartData) return;
	const {
		chartFormat: { chart: { polar: polarFlag = false, type = "default" } = {} } = {},
		transposed: transposedFlag = false,
	} = chartData ?? {};
	if (type === "pie") return "pie";
	if (["gauge", "solidgauge"].includes(type)) return type;
	if (polarFlag && type === "column") return "radial";
	if (polarFlag) return "polar";
	if (transposedFlag) return "transposed";
	return "default";
};
export default getChartType;
