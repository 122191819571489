import { helpPathLookup, newUIHelpPathLookup } from "./createSupportLink.helpPathLookup";

let createSupportLink = ({ componentName, feature, section }) => {
	if (feature !== feature?.toLowerCase()) {
		console.warn(
			`Uppercase character(s) detected in feature "${feature}" supplied to ${componentName}. Did you pass in a camel-case string? Feature names should be lowercase (e.g. "feature-name")-- ${componentName} will automatically camel-case the string where necessary.`
		);
	}
	let newUIHelpPath = newUIHelpPathLookup[feature];
	let newUIHref = `https://support.clearpointstrategy.com/en/?q=${newUIHelpPath}`;
	return newUIHref;
};

export { helpPathLookup };
export default createSupportLink;
