import { create } from "zustand";
import { persist } from "./middleware/persist";
import { querySync } from "./middleware/querySync";
import { authInitialState, createAuthSlice } from "./slices/authSlice";
import { configInitialState, createConfigSlice } from "./slices/configSlice";
import { createFeatureFlagSlice, featureFlagInitialState } from "./slices/featureFlagSlice";
import { createPageSlice, pageInitialState } from "./slices/pageSlice";
import { createPeriodSlice, periodInitialState } from "./slices/periodSlice";
// TO DO:
// add keys / setters for other methods.
// add tests: see authSlice for example
// migrate session code into store
// use this to connect state to query parameters: https://docs.pmnd.rs/zustand/guides/connect-to-state-with-url-hash
// migrate main app to use store directly instead of through session / setSession

let getActions = (state) =>
	Object.entries(state).reduce((actionLookup, [key, value]) => {
		if (typeof value === "function") actionLookup[key] = value;
		return actionLookup;
	}, {});

let useMainStore = create(
	persist(
		querySync((set, ...a) => ({
			...createAuthSlice(set, ...a),
			...createConfigSlice(set, ...a),
			...createFeatureFlagSlice(set, ...a),
			...createPageSlice(set, ...a),
			...createPeriodSlice(set, ...a),
			reset: () =>
				set(
					(state) => ({
						...authInitialState,
						...configInitialState,
						...featureFlagInitialState,
						...pageInitialState,
						...periodInitialState,
						...getActions(state),
					}),
					true // overwrites existing state, removes missing key/value pairs
				),
			resetExceptAuth: () =>
				set(
					(state) => ({
						...configInitialState,
						...featureFlagInitialState,
						...pageInitialState,
						...periodInitialState,
						...getActions(state),
						success: state.success,
					}),
					true // overwrites existing state, removes missing key/value pairs
				),
		}))
	)
);

export { useMainStore };
