import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

import Alert from "@clearpoint/old-theme/Alert/Alert";
import FormDragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Pod from "@clearpoint/old-theme/Pod/Pod";

import { useCheckHiddenElement } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import { usePodContext } from "../PodProvider";
import HeaderButtons from "./HeaderButtons";
import PodLinkListItem from "./PodLinkListItem";
import usePodLinkList from "./usePodLinkList";
import getCheckDropValid from "@components/Elements/Initiatives/ManageInitiatives/EditInitiativeModalWindow/MilestonesTab/getCheckDropValid";
import moveChildMilestonesWithParentMilestone from "@components/Elements/Initiatives/ManageInitiatives/EditInitiativeModalWindow/MilestonesTab/moveChildMilestonesWithParentMilestone";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let propTypes = {
	className: PropTypes.string,
	marginTop: PropTypes.string,
	linkListInForm: PropTypes.array,
	onDoubleClick: PropTypes.func.isRequired,
	overflowX: PropTypes.string,
	pageObject: PropTypes.string,
	podLinkList: PropTypes.array.isRequired,
	podStyle: PropTypes.object,
};

let LinksPod = ({
	pageObject,
	className,
	linkListInForm,
	marginTop,
	onDoubleClick,
	overflowX,
	podLinkList,
	podStyle,
}) => {
	let checkHiddenElement = useCheckHiddenElement();
	let translate = useTranslate();
	let { clear, pauseLoading } = useOldQueryStore();
	let { linkListQuery } = useDetailPageData();
	let { setFormValue, formValue } = useFormContext();
	let { milestoneCustomSortEnabled } = formValue;
	let { editPodFlag, fieldId, hideBorderFlag, title } = usePodContext();
	let clearLinkData = useCallback(() => {
		pauseLoading();
		clear(linkListQuery);
	}, [clear, linkListQuery, pauseLoading]);
	let linkType = useMemo(() => {
		if (["actionItems", "initiatives", "measures", "milestones", "objectives", "risks"].includes(fieldId)) {
			return fieldId.slice(0, -1);
		}
		if (fieldId === "parents" || fieldId === "children") return pageObject;
		if (fieldId === "category") return "category";
		return "";
	}, [fieldId, pageObject]);

	let unlink = useCallback(
		({ object, objectId }) => {
			let index = linkListInForm.findIndex((x) => x.object === object && x.objectId === objectId);
			setFormValue("links", [...linkListInForm.slice(0, index), ...linkListInForm.slice(index + 1)]);
		},
		[linkListInForm, setFormValue]
	);
	let dragAndDropFlag = fieldId === "milestones";
	let previousPodLinkListRef = useRef(null);
	let [innerPodList, setPodList] = useState(podLinkList);

	let podList = useMemo(() => {
		let uniquePodList = [];
		for (let pod of innerPodList) {
			if (!uniquePodList.some((x) => x.objectId === pod.objectId)) {
				uniquePodList.push(pod);
			}
		}
		if (fieldId === "linkedElements" && pageObject === "initiative") {
			uniquePodList = uniquePodList.filter((x) => x.object !== "milestone");
		}
		return uniquePodList;
	}, [innerPodList]);
	let podListRef = useRef(podList);
	podListRef.current = podList;

	useEffect(() => {
		if (isEqual(previousPodLinkListRef.current, podLinkList)) return;
		let newPodLinkList = [];
		for (let podLink of podLinkList) {
			if (!newPodLinkList.some((prevItem) => prevItem.objectId === podLink.objectId)) {
				newPodLinkList.push(podLink);
			}
		}
		if (isEqual(previousPodLinkListRef.current, newPodLinkList)) return;
		setPodList(newPodLinkList);
		previousPodLinkListRef.current = newPodLinkList;
	}, [dragAndDropFlag, podLinkList]);

	let checkDropValid = useCallback(
		({ addedIndex, removedIndex }) => {
			let checkDropValidFunction = getCheckDropValid({ milestoneList: podListRef.current, translate });
			return checkDropValidFunction({ addedIndex, removedIndex });
		},
		[podListRef, translate]
	);

	return (
		<Pod
			className={className}
			headerText={translate(title)}
			headerRightContent={
				<HeaderButtons
					editPodFlag={editPodFlag}
					fieldId={fieldId}
					linkType={linkType}
					milestoneCustomSortEnabled={milestoneCustomSortEnabled}
					clearLinkData={clearLinkData}
				/>
			}
			hideBorderFlag={hideBorderFlag}
			marginTop={marginTop}
			onDoubleClick={onDoubleClick}
			overflowX={overflowX}
			podStyle={podStyle}
		>
			{milestoneCustomSortEnabled && editPodFlag && (
				<Alert color="info">{translate("detailLayouts.initiative.milestoneCustomSortEnabled")}</Alert>
			)}
			{dragAndDropFlag && editPodFlag ? (
				<FormDragAndDropList
					allowEmptyListFlag
					className="drag-drop-list-wrapper"
					dragClass="reduced-opacity-drag-item"
					name="milestoneSortOrder"
					list={podList}
					listKey="objectId"
					onDrop={checkDropValid}
					setList={setPodList}
					reorderList={moveChildMilestonesWithParentMilestone}
				>
					{podList
						?.filter((x) => !checkHiddenElement(x.fieldId))
						?.map((x) => (
							<PodLinkListItem
								dragAndDropFlag={dragAndDropFlag}
								key={x.objectId + x.fieldId}
								link={x}
								linkType={linkType}
								podFieldId={fieldId}
								clearLinkData={clearLinkData}
								unlink={unlink}
							/>
						))}
				</FormDragAndDropList>
			) : (
				podList
					?.filter((x) => !checkHiddenElement(x.fieldId))
					?.map((x) => (
						<PodLinkListItem
							key={x.objectId + x.fieldId}
							link={x}
							linkType={linkType}
							podFieldId={fieldId}
							clearLinkData={clearLinkData}
							unlink={unlink}
						/>
					))
			)}
		</Pod>
	);
};

let LinksPodWrapper = (props) => {
	let linkListInForm = useFormValue("links");
	let pageObject = useFormValue("object");
	let pageObjectId = useFormValue("objectId");
	let { fieldId } = usePodContext();
	let podLinkList = usePodLinkList({ fieldId, linkListInForm, pageObject, pageObjectId });
	return <LinksPod podLinkList={podLinkList} linkListInForm={linkListInForm} pageObject={pageObject} {...props} />;
};

LinksPod.propTypes = propTypes;

export default LinksPodWrapper;
