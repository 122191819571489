import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectZoom = (props) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("reportingTemplates.zoom75"), value: 75 },
			{ label: translate("reportingTemplates.zoom80"), value: 80 },
			{ label: translate("reportingTemplates.zoom85"), value: 85 },
			{ label: translate("reportingTemplates.zoom90"), value: 90 },
			{ label: translate("reportingTemplates.zoom95"), value: 95 },
			{ label: translate("reportingTemplates.zoom100"), value: 100 },
			{ label: translate("reportingTemplates.zoom105"), value: 105 },
			{ label: translate("reportingTemplates.zoom110"), value: 110 },
			{ label: translate("reportingTemplates.zoom115"), value: 115 },
			{ label: translate("reportingTemplates.zoom120"), value: 120 },
			{ label: translate("reportingTemplates.zoom150"), value: 150 },
			{ label: translate("reportingTemplates.zoom200"), value: 200 },
		],
		[translate]
	);
	return <Select options={options} {...props} />;
};

export default SelectZoom;
