import ImagePicker from "./ImagePicker";
import { createRoot } from "react-dom/client";

export let setupReactApp = (element, rootRef, imageList, insertOrUpdateImage, editor, buildImagePath) => {
	let root;
	if (rootRef === null) {
		root = createRoot(element);
		root.render(
			<ImagePicker
				imageList={imageList}
				insertOrUpdateImage={insertOrUpdateImage}
				editor={editor}
				buildImagePath={buildImagePath}
			/>
		);
		return root;
	} else {
		rootRef.render(
			<ImagePicker
				imageList={imageList}
				insertOrUpdateImage={insertOrUpdateImage}
				editor={editor}
				buildImagePath={buildImagePath}
			/>
		);
		return rootRef;
	}
};

window.setupReactApp = setupReactApp;
