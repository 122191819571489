import { useEffect, useRef } from "react";
import { usePreviousDefined, useQueryParameters } from "@clearpoint/hooks";


let useScrollToComment = (discussionList) => {
	let { commentId } = useQueryParameters();
	let scrollableContainer = document.getElementById("discussionListScrollableContainer");
	let linkedComment = document.getElementById(`comment${commentId}`);
	let scrolledFlag = useRef(false);

	let discussionListLength = discussionList?.length;

	let previousDiscussionListLength = usePreviousDefined(discussionListLength);

	useEffect(() => {
		if (scrollableContainer && scrolledFlag.current === false) {
			if (commentId && linkedComment) {
				let commentTopPosition = linkedComment.offsetTop;
				scrollableContainer.scrollTop = commentTopPosition;
			} else {
				scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
			}
			scrolledFlag.current = true;
		}
	}, [commentId, linkedComment, scrollableContainer]);

	useEffect(() => {
		let commentAddedFlag = discussionListLength > previousDiscussionListLength;
		if (scrollableContainer && commentAddedFlag) {
			scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
		}
	});
};

export default useScrollToComment;
