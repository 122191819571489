import ChartPreview from "./ChartPreviewInListItem";
import Column from "@clearpoint/old-theme/Column";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import RadioCustom from "@clearpoint/old-theme/Radio/CustomRadio";
import { useCallback, useEffect, useMemo } from "react";
import useChartPreviewPromise from "../../useChartPreviewPromise";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { theme } from "@clearpoint/old-theme/Theme";
import { useFormatChart, useStateObject } from "@clearpoint/hooks";


let propTypes = {
	chartType: PropTypes.shape({
		chartJson: PropTypes.string,
		chartTypeId: PropTypes.number,
		customChartTypeId: PropTypes.number,
		name: PropTypes.string,
	}).isRequired,
	setModalChartFormatData: PropTypes.func.isRequired,
};

let ChartTypeListItem = ({ chartType, setModalChartFormatData }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let formatChart = useFormatChart();
	let formValue = useFormValue();
	let getChartPreviewPromise = useChartPreviewPromise();
	let [{ chartLoadingFlag, listItemChartFormatData, previewFailedFlag }, setState] = useStateObject({
		chartLoadingFlag: true,
		listItemChartFormatData: null,
		previewFailedFlag: false,
	});
	let { chartJson, chartTypeId, customChartTypeId, name } = chartType;
	let label = customChartTypeId ? name : translate("select.chartTypes.chartTypeId" + chartTypeId);
	let listItemChartTypeId = chartTypeId ?? customChartTypeId;

	let [pieFlag, gaugeFlag] = useMemo(() => {
		let pieRegex = /"type"\s*:\s*"pie"/;
		let gaugeRegex = /"type"\s*:\s*"(gauge|solidgauge)"/;
		return [pieRegex.test(chartJson), gaugeRegex.test(chartJson)];
	}, [chartJson]);

	let measureGridList = get({ parent: "measure", parentId: formValue.measureId, object: "measureGrid" });

	useEffect(() => {
		setState({ chartLoadingFlag: true });
		let chartData = cloneDeep(formValue);
		chartData.chartType = listItemChartTypeId;
		if (pieFlag || gaugeFlag) {
			chartData.periodId = -1;
			chartData.hiddenPeriods = [];
			chartData.periodDisplay = "cp";
			chartData.showFuture = true;
		}
		if (gaugeFlag) {
			chartData.chartSeries = chartData.chartSeries?.slice(0, 1);
		}
		getChartPreviewPromise(chartData)
			.then((response) => {
				setState({
					chartLoadingFlag: false,
					listItemChartFormatData: formatChart({ chartData: response, measureGridList }),
				});
			})
			.catch((error) => {
				console.warn(error);
				setState({
					chartLoadingFlag: false,
					previewFailedFlag: true,
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getChartPreviewPromise, chartTypeId, formatChart, gaugeFlag, measureGridList, pieFlag, setState, translate]);

	let updateModalWithSelection = useCallback(() => {
		setModalChartFormatData(listItemChartFormatData);
	}, [listItemChartFormatData, setModalChartFormatData]);

	return (
		<Column huge={6} large={12} marginBottom={theme.smallSpace}>
			<FormGroup
				title={<RadioCustom value={listItemChartTypeId} label={label} onClick={updateModalWithSelection} />}
				paddingBottom={theme.space}
			>
				<ChartPreview
					name={label}
					chartLoadingFlag={chartLoadingFlag}
					chartFormatData={listItemChartFormatData}
					previewFailedFlag={previewFailedFlag}
				/>
			</FormGroup>
		</Column>
	);
};

ChartTypeListItem.propTypes = propTypes;
export default ChartTypeListItem;
