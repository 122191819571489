/* eslint-disable complexity */
import { objectList } from "@clearpoint/utils";

import * as Sentry from "@sentry/react";

let generateClearAssociatedFunction =
	({ clear }) =>
	(object) => {
		if (object && !object.endsWith) Sentry.captureException(new Error(`Invalid object: ${JSON.stringify(object)}`));
		if (objectList.includes(object)) {
			for (let object of objectList) {
				if (object === "scorecard") continue;
				clear({ object: object + "Layout" });
			}
		}
		// if (
		// 	!object ||
		// 	object.endsWith?.("Layout") ||
		// 	objectList.includes(object) ||
		// 	["update", "workflow"].includes(object)
		// ) {
		// 	clear({ object: "element" });
		// 	clear({ object: "link" });
		// 	clear({ object: "measureGrid" });
		// 	clear({ object: "menu" });
		// 	clear({ object: "myFavorite" });
		// 	clear({ object: "myScorecard" });
		// 	clear({ object: "myUpdates" });
		// 	clear({ object: "peerGroup" });
		// 	clear({ object: "preview" });
		// 	clear({ object: "recent" });
		// 	clear({ object: "report", queryString: "?recent=true" });
		// 	clear({ object: "search" });
		// 	clear({ object: "workflow" });
		// 	clear({ object: "customField" });
		// }
		// if (object === "gridLayout") {
		// 	for (let object of objectList) {
		// 		if (object === "scorecard") continue;
		// 		clear({ object: object + "Layout" });
		// 	}
		// }
		if (object === "chart") {
			clear({ object: "pod" });
		}
		if (object === "approval") {
			clear({ object: "approvals" });
		}
		// if (object === "dashboardLayout") {
		// 	clear({ object: "scorecardLayout" });
		// }
		if (!object || object === "measureSeries" || object === "measure") {
			clear({ object: "chart" });
			clear({ object: "measureGrid" });
			clear({ object: "measureData" });
			clear({ object: "measureSeries" });
			clear({ object: "ganttData" });
		}
		if (object === "initiative" || object === "milestone") {
			clear({ object: "ganttData" });
		}
		if (object === "milestone" || object === "initiative") {
			clear({ object: "ganttData" });
		}
		// if (!object || object === "categories") {
		// 	clear({ object: "category" });
		// }
		// if (!object || object === "initiative") {
		// 	clear({ object: "milestone" });
		// }
		// if (!object || object === "milestone") {
		// 	clear({ object: "initiative" });
		// }
		// if (!object || object === "category") {
		// 	clear({ object: "element" });
		// 	clear({ object: "categories" });
		// 	clear({ object: "link" });
		// }
		// if (!object || object.endsWith?.("group")) {
		// 	clear({ object: "user" });
		// }
		// if (!object || object === "period") {
		// 	clear({ object: "measureGrid" });
		// 	clear({ object: "schedule" });
		// }
		// if (!object || object.endsWith?.("user")) {
		// 	clear({ object: "group" });
		// 	clear({ object: "comment" });
		// 	clear({ object: "timezone" });
		// 	clear({ object: "options" });
		// 	clear({ object: "language" });
		// 	clear({ object: "goal" });
		// }
		// if (!object || object === "goal") {
		// 	clear({ object: "link" });
		// }
		// if (!object || object === "comment") {
		// 	clear({ object: "mention" });
		// }
		// if (!object || object === "mention") {
		// 	clear({ object: "comment" });
		// }
	};

export default generateClearAssociatedFunction;
