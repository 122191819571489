import FormFieldDisplay from "@clearpoint/old-theme/Form/Field/Display/index";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";

let propTypes = {
	fieldId: PropTypes.string,
};

let DateTime = ({ fieldId }) => {
	let name = fieldId === "lastUpdated" ? "updates.lastUpdated" : "updatedDate";
	let Component = FormFieldDisplay[capitalizeFirstLetter(fieldId)];
	return <Component name={name} />;
};

DateTime.propTypes = propTypes;
export default DateTime;
