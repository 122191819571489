import { useEffect } from "react";
import useGenerateScorecardTranslate from "./useGenerateScorecardTranslate";
import { useOldSession } from "@clearpoint/old-session/index";
import useTranslatorState from "./useTranslatorState";
import getScorecardLanguage from "./useScorecardLanguage.getScorecardLanguage";
import { luceeAxios } from "@clearpoint/services/axiosService";

let useScorecardLanguage = () => {
	let { setTranslateLookup, translateLookup, scorecardIdList } = useTranslatorState();
	let { localeId } = useOldSession()?.session || {};
	let token = luceeAxios.defaults.headers.common["Authorization"];
	let generateScorecardTranslate = useGenerateScorecardTranslate();
	useEffect(
		() =>
			getScorecardLanguage({
				generateScorecardTranslate,
				localeId,
				scorecardIdList,
				setTranslateLookup,
				token,
				translateLookup,
			}),
		[generateScorecardTranslate, localeId, scorecardIdList, setTranslateLookup, token, translateLookup]
	);
};

export default useScorecardLanguage;
