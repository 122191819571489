import checkEnv from "./getEnvironment.checkEnv";

export default function getEnvironment(sentryFlag) {
	let windowObject;
	try {
		windowObject = window;
	} catch (error) {
		return checkEnv(null);
	}
	return checkEnv(windowObject?.location?.hostname, sentryFlag);
}
