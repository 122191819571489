import DragAndDropTableColumn from "@clearpoint/old-theme/DragAndDropTable/DragAndDropColumn";
import HeaderCell from "./HeaderCell";
import PeriodCell from "./PeriodCell";
import StyleCell from "./StyleCell";
import TitleCell from "./TitleCell";
import WidthCell from "./WidthCell";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	name: PropTypes.string,
};
let FieldColumn = ({ name }) => {
	let { reportColumns: fieldList, _advancedColumnFlag } = useFormValue();
	return (
		<DragAndDropTableColumn
			minWidth="150px"
			width={100 / fieldList.length + "%"}
			borderTop={`3px solid ${theme.darkLazur}`}
		>
			<TitleCell name={name} />
			<HeaderCell name={name} />
			<WidthCell name={name} />
			{_advancedColumnFlag && (
				<>
					<StyleCell name={name} />
					<PeriodCell name={name} />
				</>
			)}
		</DragAndDropTableColumn>
	);
};
FieldColumn.propTypes = propTypes;
export default FieldColumn;
