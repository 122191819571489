import HTML from "../../../../HTML";
import StatusIcon from "../../../../StatusIcon";
import StyleWrapper from "../../../../StyleWrapper";
import Table from "../../../../Table";
import TableStyle from "./TableStyle";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { formatNumber } from "@clearpoint/utils";

let propTypes = {
	measureGridList: PropTypes.arrayOf(
		PropTypes.shape({
			periodId: PropTypes.number,
			periodName: PropTypes.string,
			statusId: PropTypes.number,
			statusName: PropTypes.string,
		})
	),
	measureSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			measureSeriesId: PropTypes.number,
			numberFormat: PropTypes.object,
			seriesData: PropTypes.string,
		})
	),
};

let PrintSeriesGrid = ({ measureGridList, measureSeriesList }) => {
	let translate = useTranslate();
	return (
		<TableStyle>
			<Table borderedFlag>
				<thead>
					<tr>
						<StyleWrapper minWidth="100px" width="100px">
							<th width="100">
								<div>
									<span>{translate("global.period")}</span>
								</div>
							</th>
						</StyleWrapper>
						<StyleWrapper minWidth="25px" width="25px">
							<th width="25">
								<div>
									<StatusIcon size="small" marginRight="0" />
								</div>
							</th>
						</StyleWrapper>
						<StyleWrapper minWidth="125px" width="125x">
							<th width="125">
								<div>
									<span>{translate("global.status")}</span>
								</div>
							</th>
						</StyleWrapper>
						{measureSeriesList?.map((x) => (
							<th key={x.measureSeriesId}>
								<div>
									<span>{x.name}</span>
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{measureGridList?.map((periodData) => {
						let { periodId, periodName, statusId, statusName } = periodData;
						return (
							<tr key={periodId}>
								<td width="100">{periodName}</td>
								<StyleWrapper textAlign="center !important">
									<td width="25" className="status-icon">
										<StatusIcon size="small" statusId={statusId} marginRight="0" />
									</td>
								</StyleWrapper>
								<td width="150">{statusName}</td>
								{measureSeriesList?.map((series) => {
									let { measureSeriesId, numberFormat, seriesData: seriesKey } = series;
									let value = periodData[seriesKey];
									let displayValue =
										isNaN(value) || value === null ? value : formatNumber({ number: value, numberFormat });
									return (
										<StyleWrapper key={measureSeriesId} textAlign="right">
											<td>
												<HTML>{displayValue}</HTML>
											</td>
										</StyleWrapper>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
		</TableStyle>
	);
};

PrintSeriesGrid.propTypes = propTypes;
export default PrintSeriesGrid;
