import { useCallback } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import beforeSubmit from "./useBeforeGridLayoutSubmit.beforeSubmit";

let useBeforeGridLayoutSubmit = () => {
	let { get } = useOldQueryStore();
	let periodGroupList = get({ object: "periodGroup", queryString: "?include=periods" });

	return useCallback(
		(submitValue, contextValue) => {
			let beforeSubmitCallback = beforeSubmit({ periodGroupList });
			return beforeSubmitCallback(submitValue, contextValue);
		},
		[periodGroupList]
	);
};

export default useBeforeGridLayoutSubmit;
