import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import cloneDeep from "lodash/cloneDeep";

let useSeriesData = () => {
	let { chartSeries: chartSeriesList, measureId } = useFormValue();
	let { get } = useOldQueryStore();

	let measureSeriesList = measureId && get({ parent: "measure", parentId: measureId, object: "measureSeries" });

	let [combinedSeriesList, activatedSeriesList, deactivatedSeriesList] = useMemo(() => {
		let activatedSeriesNameList = chartSeriesList?.map((series) => series.name);
		let activatedSeriesList = cloneDeep(chartSeriesList)
			?.sort((f, s) => f.sortOrder > s.sortOrder)
			.map((x, i) => ({ ...x, dragAndDropListKey: i }));
		let deactivatedSeriesList = measureSeriesList?.filter(
			(series) => !activatedSeriesNameList?.includes(series.name)
		);
		let combinedSeriesList = deactivatedSeriesList
			? [...(chartSeriesList || []), ...(deactivatedSeriesList || [])]
			: null;

		combinedSeriesList = combinedSeriesList?.filter((series) => series.active); // filter out inactive (trashed) series

		return [combinedSeriesList, activatedSeriesList, deactivatedSeriesList];
	}, [chartSeriesList, measureSeriesList]);

	return { combinedSeriesList, activatedSeriesList, deactivatedSeriesList };
};

export default useSeriesData;
