import { useCallback } from "react";
import { useOptionsMutation, useOptionsQuery } from "@clearpoint/query";
import { useState } from "react";

// This will update the options.
// By default this will invalidate the data after the update

let Mutate = () => {
	let { data: options, isLoading } = useOptionsQuery();
	let { mutate: updateOptions } = useOptionsMutation();
	let [hostname, setHostname] = useState();
	let updateServerHostname = useCallback(() => {
		updateOptions({ hostname }); // note payload is passed to mutate function
	}, [hostname, updateOptions]);
	let updateHostname = useCallback((e) => setHostname(e.target.value), []);

	return isLoading ? null : (
		<>
			<label htmlFor="hostname">Host Name</label>
			<br />
			<input defaultValue={options.hostname} name="hostname" onChange={updateHostname} />
			<br />
			<button onClick={updateServerHostname}>Update Hostname</button>
		</>
	);
};

export default Mutate;
