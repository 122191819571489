import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectHeaderStyle = (props) => {
	let translate = useTranslate();

	let options = useMemo(
		() => [
			{ value: 0, label: translate("edit.browsersAndUp") },
			{ value: 1, label: translate("edit.updatersAndUp") },
			{ value: 2, label: translate("edit.editorsAndUp") },
			{ value: 3, label: translate("edit.scorecardAdminsAndUp") },
			{ value: 4, label: translate("edit.showForAdmins") },
		],
		[translate]
	);

	return <FormSelect label={translate("edit.hideByLevel")} options={options} {...props} />;
};

export default SelectHeaderStyle;
