import { checkErrorForRetry, emptyObject, sleep } from "@clearpoint/utils";

const RETRY_COUNT = 1;

let generateRetry = (startCount = 0) => {
	let requestCount = startCount;

	let retry = ({ data, error, makeRequest, url }) => {
		let networkErrorFlag = checkErrorForRetry(error);
		if (networkErrorFlag && requestCount < RETRY_COUNT) {
			requestCount++;
			console.log(`Network error. Retry #${requestCount}: ${url}`); // do not remove, used in tests
			let retryRequest = async () => {
				await sleep(5000);
				return makeRequest();
			};
			return { retryRequest: retryRequest() };
		}
		if (networkErrorFlag && requestCount >= RETRY_COUNT) {
			console.log(`Too many broken requests. ${url}, ${JSON.stringify(data, 0, 3)}`);
			throw new Error("Too many broken requests.  " + JSON.stringify(error));
		}
		return emptyObject;
	};

	return retry;
};

export default generateRetry;
