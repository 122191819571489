import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editFlag: PropTypes.bool,
	object: PropTypes.string.isRequired,
};
let GrantAdminAccess = ({ editFlag, object, ...props }) => {
	let translate = useTranslate();
	let label = translate("edit.element.grantAccess");
	return editFlag ? (
		<RadioBoolean label={label} {...props} />
	) : (
		<Check data-testid="grantAdminAccess" text={label} {...props} label={null} />
	);
};
GrantAdminAccess.propTypes = propTypes;
export default GrantAdminAccess;
