import DropdownItemCopy from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Copy";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	disabled: PropTypes.bool,
	objectId: PropTypes.number,
};

let DropdownItemMilestoneCopy = ({ objectId }) => {
	let translate = useTranslate();
	let { objectId: parentId } = useFormValue();
	let MilestoneCopyOptions = useCallback(
		() => (
			<>
				<FormCheck name="copyStatus">
					{translate("edit.copyStatus")} {translate("edit.andElementData", { element: "milestone" })}
				</FormCheck>
				<FormCheck name="copyLinks">{translate("edit.copyLinks")}</FormCheck>
			</>
		),
		[translate]
	);
	return (
		<DropdownItemCopy
			parent="initiative"
			parentId={parentId}
			object="milestone"
			objectId={objectId}
			duplicateRouteFlag
			toast={translate("toaster.messages.objects.objectsDuplicated", { object: "milestone" })}
			Options={MilestoneCopyOptions}
		/>
	);
};

DropdownItemMilestoneCopy.propTypes = propTypes;

export default DropdownItemMilestoneCopy;
