import { useEffect } from "react";
import PropTypes from "prop-types";

let propTypes = {
	ref: PropTypes.object,
	callback: PropTypes.func,
};

let useClickAwayListener = (ref, callback) => {
	useEffect(() => {
		function handleClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		}

		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [ref, callback]);
}

useClickAwayListener.propTypes = propTypes;

export default useClickAwayListener;
