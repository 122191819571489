import FavoriteSplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Favorite";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { theme } from "@clearpoint/old-theme/Theme";
import { buildQueryString, emptyObject } from "@clearpoint/utils";


let DashboardFavoriteSplitButton = () => {
	let { get } = useOldQueryStore();
	let { layout } = useLocalState();
	let { layoutId, object, scorecardId } = layout;
	let { favorite: favoriteFlag } =
		(object && get({ object: "favorite", queryString: buildQueryString({ layoutId, object }) })) || emptyObject;
	return (
		<StyleWrapper marginLeft={theme.smallSpace}>
			<FavoriteSplitButton
				favoriteFlag={favoriteFlag}
				object={object}
				layoutId={layoutId}
				scorecardId={scorecardId}
			/>
		</StyleWrapper>
	);
};
export default DashboardFavoriteSplitButton;
