import Block from "@clearpoint/old-theme/Block";
import FormField from "@clearpoint/old-theme/Form/Field/Field";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import { theme } from "@clearpoint/old-theme/Theme";
import { useFieldList } from "@clearpoint/hooks";


let propTypes = {
	parentId: PropTypes.number,
	periodId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let MilestonesTab = ({ parentId, periodId, objectIdList }) => {
	let addFlag = !objectIdList;
	let translate = useTranslate();
	let objectId = useFormValue("objectId");
	let { get } = useOldQueryStore();
	let fieldList = useFieldList({ object: "milestone", objectId, addFlag, multipleFlag: addFlag });
	let displayedFieldList = useMemo(
		() =>
			fieldList && [...fieldList?.filter((field) => field.value !== "seriesType" && field.value !== "evaluations")],
		[fieldList]
	);
	let milestonesList =
		!addFlag && parentId && periodId && get({ parent: "initiative", parentId, object: "milestone", periodId });

	let selectedMilestonesList = useMemo(
		() =>
			milestonesList && isArray(milestonesList)
				? milestonesList?.filter((milestone) => objectIdList.some((objectId) => objectId === milestone.objectId))
				: [],
		[milestonesList, objectIdList]
	);
	return (
		<>
			{addFlag ? (
				<>
					{displayedFieldList?.map((field) => (
						<FormField
							editFlag={!addFlag}
							fieldId={field.value}
							key={field.value}
							label={field.label}
							parent="initiative"
							parentId={parentId}
							object="milestone"
						/>
					))}
				</>
			) : (
				<FormGroup
					title={translate("edit.element.elementsToUpdate", {
						objects: translate("objects.milestone"),
					})}
					paddingBottom={theme.tinySpace}
				>
					{selectedMilestonesList?.map((milestone) => (
						<ListItem
							editFlag={!addFlag}
							key={milestone.objectId}
							left={
								<Block display="flex" alignItems="center" ellipsisFlag>
									<StatusIcon statusId={milestone.statusId} />
									<Block ellipsisFlag>{milestone.name}</Block>
								</Block>
							}
							size="small"
						/>
					))}
				</FormGroup>
			)}
		</>
	);
};
MilestonesTab.propTypes = propTypes;
export default MilestonesTab;
