import { useCallback, useEffect, useState } from "react";

import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import DiscussionButton from "@clearpoint/old-theme/Button/Button.Discussion";
import FormSaveBar from "@clearpoint/old-theme/Form/Form.SaveBar";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useOldQueryStore } from "@clearpoint/old-query-store";

import EditSplitButton from "./EditSplitButton";
import FavoriteSplitButton from "./FavoriteSplitButton";
import Search from "./Search";
import Title from "./Title";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let Toolbar = ({ elementData }) => {
	let { editTableFlag, customFieldFlag, setLocalState, ReferenceEditSplitButton, ReferenceFavoriteSplitButton } =
		useLocalState();
	let toggleSaveFlag = useCallback(() => setLocalState({ editTableFlag: (x) => !x }), [setLocalState]);
	let ToolbarFavoriteSplitButton = ReferenceFavoriteSplitButton || FavoriteSplitButton;
	let ToolbarEditSplitButton = ReferenceEditSplitButton || EditSplitButton;
	let { refresh, loadingFlag, staleData } = useOldQueryStore();
	let [dataStale, setDataStale] = useState(false);
	let [dataRefreshing, setDataRefreshing] = useState(false);

	useEffect(() => {
		if (elementData?.elements && staleData) {
			let hasStaleData = false;
			for (let row of elementData?.elements) {
				let { object, objectId } = row;
				if (staleData.some((data) => data.object === object && data.objectId === objectId)) {
					hasStaleData = true;
					break;
				}
			}
			if (hasStaleData) {
				setDataStale(true);
			} else {
				setDataRefreshing(false);
				setDataStale(false);
			}
		}
	}, [loadingFlag, elementData?.elements?.length, setDataStale, staleData?.length]);

	let refreshPage = useCallback(() => {		
		setDataRefreshing(true);
		refresh();
	}, [refresh]);

	return editTableFlag ? (
		<FormSaveBar close={toggleSaveFlag}>
			<Block marginTop="4px">
			<Title />
			</Block>
		</FormSaveBar>
	) : (
		<Block
			data-testid="toolbar"
			display="flex"
			alignItems="flex-start"
			justifyContent="space-between"
			$style="* {margin-bottom: 0px;}"
			padding={customFieldFlag ? "0px" : `${theme.mediumSpace} ${theme.largeSpace} ${theme.smallSpace}`}
			position={customFieldFlag ? "absolute" : undefined}
			bottom={customFieldFlag ? "0px" : undefined}
			left={customFieldFlag ? "0px" : undefined}
			zIndex={customFieldFlag ? 10_000 : undefined}
			width="100%"
		>
			<Search />
			{(dataStale || dataRefreshing) && (
				<Button color="white" onClick={refreshPage} disabled={dataRefreshing}>
					<Icon name="refresh" spinFlag={dataRefreshing} />
				</Button>
			)}
			<DiscussionButton />
			<ToolbarFavoriteSplitButton />
			<ToolbarEditSplitButton />
		</Block>
	);
};

export default Toolbar;
