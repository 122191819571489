import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";

import Block from "../Block";
import Icon from "./Icon";

let propTypes = {
	dropdownFlag: PropTypes.bool,
	direction: PropTypes.oneOf(["left", "right"]).isRequired,
	onClick: PropTypes.func,
	transitionFlag: PropTypes.bool,
};

let IconDropdown = ({ dropdownFlag, direction, onClick, transitionFlag, ...props }) => {
	let rotation = direction === "right" ? "rotate(90deg)" : "rotate(-90deg)";
	let icon = "dropdown" + capitalize(direction);

	return (
		<Block
			display="block"
			fontSize={"12px"}
			marginTop={dropdownFlag ? "-7px" : "0px"}
			marginLeft={dropdownFlag ? "-3px" : "0px"}
			marginRight={dropdownFlag ? "3px" : "0px"}
			transform={dropdownFlag ? rotation : undefined}
			transition={transitionFlag ? "transform 250ms ease-in-out" : undefined}
			onClick={onClick}
			color={props.color}
			style={{ cursor: "pointer" }}
		>
			<Icon name={icon} {...props} />
		</Block>
	);
};

IconDropdown.propTypes = propTypes;
export default IconDropdown;
