let calculateStartAndEndDate = (list) =>
	list?.reduce((listStartAndEndDate, item) => {
		if (!item) return listStartAndEndDate;
		let { startDate, endDate } = item;
		let { startDate: listStartDate, endDate: listEndDate } = listStartAndEndDate;
		if (startDate) {
			if (!listStartDate || new Date(startDate) < new Date(listStartDate)) listStartAndEndDate.startDate = startDate;
		}
		if (endDate) {
			if (!listEndDate || new Date(endDate) > new Date(listEndDate)) listStartAndEndDate.endDate = endDate;
		}
		return listStartAndEndDate;
	}, {}) || {};
export default calculateStartAndEndDate;
