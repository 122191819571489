import { useCallback } from "react";


let useCreatePayloadFromSubmitValue = ({ dashboardLayoutFlag, scorecardId }) => {
	let createDashboardLayoutPayload = useCallback(
		({ accessType, groups, layout, name, reportOptions, scorecardId }) => ({
			accessType,
			groups,
			layout,
			name,
			reportOptions,
			saveOption: 0,
			scorecardId,
		}),
		[]
	);

	let createDetailLayoutPayload = useCallback(
		({ layout }) => ({
			layout,
			scorecardId,
			saveOption: 0,
		}),
		[scorecardId]
	);

	let createPayloadFromSubmitValue = useCallback(
		(submitValue) => {
			if (dashboardLayoutFlag) return createDashboardLayoutPayload(submitValue);

			let submittedViaDropdownFlag = submitValue.saveOption !== undefined;
			if (submittedViaDropdownFlag) return submitValue;
			return createDetailLayoutPayload(submitValue);
		},
		[createDashboardLayoutPayload, createDetailLayoutPayload, dashboardLayoutFlag]
	);

	return { createDashboardLayoutPayload, createPayloadFromSubmitValue };
};

export default useCreatePayloadFromSubmitValue;
