import { useOldQueryStore } from "@clearpoint/old-query-store";
import useUserId from "./useUserId";

let defaultResponse = {
	ianaName: undefined,
};

let useUserTimezone = (userId) => {
	let { get } = useOldQueryStore();
	let currentUserId = useUserId();
	if (!userId) userId = currentUserId;
	let apiResponse = userId && get({ object: "timezone", queryString: `userId=${userId}` });

	// fallback value if apiResponse is empty
	if (!apiResponse?.ianaName) {
		let clientIana = Intl?.DateTimeFormat?.().resolvedOptions?.()?.timeZone;
		apiResponse = { ianaName: clientIana, offset: -(new Date().getTimezoneOffset() / 60) };
	}

	let userTimezone = apiResponse || defaultResponse;

	return userTimezone;
};

export default useUserTimezone;
