import Select from "./FormSelect";
import SelectAllSelectNoneButtons from "../../Form/Select/SelectAllSelectNoneButtons";
import useAvailablePeriods from "../hooks/useAvailablePeriods";
import { useCallback, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import PropTypes from "prop-types";

let propTypes = {
	compactFlag: PropTypes.bool,
	multipleFlag: PropTypes.bool,
	name: PropTypes.string,
	parentName: PropTypes.string,
};

let FormSelectAvailablePeriod = ({ compactFlag, multipleFlag, name, parentName, ...props }) => {
	let { setFormValue } = useFormContext();
	let availablePeriodList = useAvailablePeriods(parentName);
	let options = useMemo(
		() =>
			availablePeriodList?.map((period) => ({
				label: period.name,
				value: period.periodId,
			})),
		[availablePeriodList]
	);
	let selectAll = useCallback(() => {
		let filledArray = availablePeriodList?.map((x) => x.periodId);
		setFormValue(name, filledArray);
	}, [availablePeriodList, name, setFormValue]);

	return (
		<>
			<Select multipleFlag={multipleFlag} options={options} name={name} {...props} />
			{multipleFlag && (
				<SelectAllSelectNoneButtons
					compactFlag={compactFlag}
					marginBottom={props.marginBottom}
					name={name}
					selectAll={selectAll}
				/>
			)}
		</>
	);
};

FormSelectAvailablePeriod.propTypes = propTypes;

export default FormSelectAvailablePeriod;
