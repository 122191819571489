import Block from "@clearpoint/old-theme/Block";
import FormSelectChartMeasureSeries from "@clearpoint/old-theme/Form/Select/Select.ChartMeasureSeries";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	index: PropTypes.number,
	series: PropTypes.shape({
		label: PropTypes.string,
		measureSeriesId: PropTypes.number,
		visible: PropTypes.bool,
	}),
};

let CustomSeriesListItem = ({ index, series }) => {
	let translate = useTranslate();
	let { label, visible } = series;
	let labelText = label || `${translate("global.series")} ${index + 1}`;

	return (
		<ListItem color="desaturatedBold" visible={visible}>
			<Block flex="1" display="flex" justifyContent="space-between" flexWrap="wrap">
				<Block
					flex="0 0 100px"
					alignSelf="stretch"
					display="flex"
					alignItems="center"
					marginRight={theme.smallSpace}
					wordWrap="break-word"
				>
					<Block>{labelText}</Block>
				</Block>
				<Block flex="1 0 67%" alignSelf="stretch" display="flex" alignItems="center">
					<Block flex="1">
						<FormSelectChartMeasureSeries name={`customSeries[${index}].measureSeriesId`} />
					</Block>
				</Block>
			</Block>
		</ListItem>
	);
};

CustomSeriesListItem.propTypes = propTypes;

export default CustomSeriesListItem;
