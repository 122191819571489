let threeDigitHexToSix = (hexCode) => {
	hexCode = hexCode?.trim();
	if (!hexCode || hexCode.length !== 4 || hexCode[0] !== "#") {
		return hexCode;
	}

	return hexCode
		.split("")
		.map((char) => (char === "#" ? char : char + char))
		.join("");
};

export default threeDigitHexToSix;
