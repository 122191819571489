import Block from "../Block";
import ListGroup from "../ListGroup";
import Portal from "../Portal/Portal";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../Theme";
import { useEffect, useState } from "react";
import { emptyObject } from "@clearpoint/utils";


let StyledListGroup = styled(ListGroup)`
	list-style: none;

	li.add-comment__suggestions__item {
		padding: 5px 10px;
	}

	li.add-comment__suggestions__item--focused {
		background-color: ${theme.success};
		color: ${theme.white};
	}
`;

const REACT_MODAL_Z_INDEX = 200;

let propTypes = {
	children: PropTypes.node,
	caretCoordinates: PropTypes.object,
};

let UserListDropdown = ({ children, caretCoordinates }) => {
	let [top, setTop] = useState(0);
	let [left, setLeft] = useState(0);

	useEffect(() => {
		let inputRect = document.querySelector(".add-comment__input")?.getBoundingClientRect();
		let { left: inputLeft, top: inputTop, bottom: inputBottom } = inputRect || emptyObject;
		let { top: caretTop, left: caretLeft } = caretCoordinates;
		let dropdownHeight = document.querySelector(".userlist-dropdown")?.offsetHeight;

		setTop((caretTop > 80 ? inputBottom - 19 : inputTop + caretTop) - dropdownHeight);
		setLeft(caretLeft < 95 ? inputLeft + caretLeft : inputLeft + caretLeft - 180);
	}, [caretCoordinates]);

	return (
		<Portal>
			<Block
				backgroundColor={theme.white}
				border="medium none"
				boxShadow="0 0 3px rgb(86 96 117 / 70%)"
				className="userlist-dropdown"
				left={`${left}px`}
				maxHeight="300px"
				overflow="auto"
				position="absolute"
				top={`${top}px`}
				width="180px"
				zIndex={REACT_MODAL_Z_INDEX + 1}
			>
				<StyledListGroup>{children}</StyledListGroup>
			</Block>
		</Portal>
	);
};

UserListDropdown.propTypes = propTypes;

export default UserListDropdown;
