import { emptyArray, emptyObject } from "@clearpoint/utils";

let getAggregateStatusOptions = (formValue, currentValue) => {
	if (!formValue || !currentValue) return emptyObject;
	let {
		aggregateMeasureTag,
		aggregateScorecardTag,
		aggregateScorecardType: formAggregateScorecardType,
		measureId,
		scorecardId,
		statusScorecardType: formStatusScorecardType,
	} = formValue;
	let {
		statusSeriesName,
		statusScorecards,
		statusScorecardType: currentStatusScorecardType,
		statusType,
		statusObjects,
		aggregateScorecardType: currentAggregateScorecardType,
	} = currentValue;

	let statusScorecardType = currentStatusScorecardType ?? formStatusScorecardType;
	let aggregateScorecardType = currentAggregateScorecardType ?? formAggregateScorecardType;

	let namedObjectFlag = [3, 4].includes(statusType);
	let showAggregateMeasureCount = false;
	let filter;
	if (measureId && statusScorecardType !== null) {
		showAggregateMeasureCount = false;

		if (statusType === 0) {
			showAggregateMeasureCount = true;
		} else if (namedObjectFlag !== -1 && statusSeriesName !== "") {
			showAggregateMeasureCount = true;
		} else if (statusType === 5 && aggregateMeasureTag) {
			showAggregateMeasureCount = true;
		}

		filter = {
			scorecardId,
			statusScorecardType,
			statusScorecards: statusScorecards ? statusScorecards : emptyArray,
			statusSeriesName,
			statusType: statusType || 0,
			statusObjects: statusObjects || "",
			aggregateMeasureTag,
			aggregateScorecardTag,
		};
	}

	let checkNullish = (value) => value === null || value === undefined;
	let hasAggregateScorecardTypeFlag = !checkNullish(aggregateScorecardType);
	let hasStatusScorecardTypeFlag = !checkNullish(statusScorecardType);

	return {
		filter,
		hasAggregateScorecardTypeFlag,
		hasStatusScorecardTypeFlag,
		namedObjectFlag,
		showAggregateMeasureCount,
	};
};

export default getAggregateStatusOptions;
