import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import { createQueryKeyFromId } from "./createQueryKeyFromId";
import { queryClient } from "@clearpoint/query";

let handleData = ({ data, dispatch, get, id, object, objectId, request, setState, skipSortFlag, storeKey, url }) => {
	if (!data) data = [];
	// add additional identifying data
	let { parent, parentId, scorecardId } = data;
	if (!data.objectId && !objectId && data[object + "Id"]) id.objectId = data[object + "Id"];
	if (!data.objectId && !objectId && data.layoutId) id.objectId = data.layoutId;
	// add scorecard id from parent data if missing
	if (parentId && !scorecardId && parentId > 0) {
		// Get promise
		return get({ object: parent, objectId: parentId, skipWaitFlag: true }, true)?.then((parentData) => {
			let storeValue = {
				storeKey,
				url,
				scorecardId: parentData?.scorecardId,
				...id,
				data,
			};
			let queryKey = createQueryKeyFromId(id);
			queryClient.setQueryData(queryKey, data);
			dispatch(storeValue);
			setState({ completedRequests: (x) => [...x, { id: storeKey, request }] });
			return data;
		});
	} else {
		let storeValue = { storeKey, url, ...id };
		if (isArray(data) && !skipSortFlag) {
			if (data[0]?.sortOrder) {
				data = cloneDeep(data).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
			}
			if (data[0]?.userTypeId) {
				data = cloneDeep(data).sort((a, b) => (a.userTypeId > b.userTypeId ? 1 : a.userTypeId < b ? -1 : 0));
			}
		}
		storeValue.data = data;
		let queryKey = createQueryKeyFromId(id);
		queryClient.setQueryData(queryKey, data);
		dispatch(storeValue);
	}
	setState({ completedRequests: (x) => [...x, { id: storeKey, request }] });
	return data;
};

export default handleData;
