import { useCallback, useEffect } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let useUnsetEditTableFlagOnDetailPageSubmit = () => {
	let { setLocalState } = useLocalState();

	let onDetailPageSubmit = useCallback(() => {
		setLocalState({ editTableFlag: false });
	}, [setLocalState]);

	useEffect(() => {
		let detailPageForm = document.querySelector(".detail-page-form");
		if (detailPageForm) {
			detailPageForm.addEventListener("submit", onDetailPageSubmit);
		}
		return () => {
			if (detailPageForm) {
				detailPageForm.removeEventListener("submit", onDetailPageSubmit);
			}
		};
	});
};

export default useUnsetEditTableFlagOnDetailPageSubmit;
