import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getId from "../getId";
import { theme } from "@clearpoint/old-theme/Theme";
import { buildQueryString, getQueryParameters } from "@clearpoint/utils";
import { useQueryParameters } from "@clearpoint/hooks";



let propTypes = {
	menuItem: PropTypes.shape({
		layoutId: PropTypes.number,
		name: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		scorecardId: PropTypes.number,
	}),
	scorecardObjectFlag: PropTypes.bool,
};
let MenuItem = ({ menuItem, scorecardObjectFlag }) => {
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let { scorecardId, layoutId, object, objectId, periodId } = useQueryParameters();
	let createLink = useCallback(
		(object) =>
			`${buildQueryString(
				getQueryParameters(
					`scorecardId=${object.homeScorecardId || object.scorecardId}&${getId(object)}&periodId=${periodId}`
				)
			)}.html`.slice(1),
		[periodId]
	);
	let isCurrentObject = useCallback(
		(menuItem) =>
			menuItem.object === object &&
			(menuItem.objectId ? menuItem.objectId === objectId : menuItem.layoutId === layoutId),
		[layoutId, object, objectId]
	);
	let highlightScorecardFlag = +menuItem.scorecardId === +scorecardId;
	let boldFlag = scorecardObjectFlag ? highlightScorecardFlag : !!menuItem.layoutId;
	return menuItem ? (
		<StyleWrapper
			fontWeight={boldFlag ? theme.bold : undefined}
			backgroundColor={isCurrentObject(menuItem) && !scorecardObjectFlag ? theme.lightestPrimary : undefined}
			$style={`
            &&& {
               color: black !important;
               ${
						isCurrentObject(menuItem) && !scorecardObjectFlag
							? `
               :hover {
                  backgroundColor: ${theme.lighterPrimary};
               }`
							: ""
					}
            }`}
		>
			<DropdownItem href={createLink(menuItem)}>{translateOnlyInsideCurlyBraces(menuItem.name)}</DropdownItem>
		</StyleWrapper>
	) : null;
};
MenuItem.propTypes = propTypes;

export default MenuItem;
