import { useEffect, useState } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { dateLanguageLookup } from "@clearpoint/utils";

let useDateConfig = () => {
	let [dateConfig, setDateConfig] = useState({});
	let { session } = useOldSession();
	useEffect(() => {
		setDateConfig((x) => ({ ...x, locale: dateLanguageLookup[session.localeId] }));
	}, [session.localeId]);
	return { dateConfig, setDateConfig };
};
export default useDateConfig;
