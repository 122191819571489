/* eslint-disable unicorn/prefer-ternary */
import { isArray } from "lodash";

import { chartPeriodListContainsPeriod, formatSeriesNumber } from "../utils";

let createSolidGaugeSeriesList = ({ chartFormat, seriesList, measureGridList, chartPeriodList }) => {
	let period = measureGridList?.find((x) => chartPeriodListContainsPeriod(x, chartPeriodList));
	if (chartFormat?.pane?.background && isArray(chartFormat?.pane?.background)) {
		let returnVal = seriesList.slice(0, chartFormat?.pane?.background.length).map((series, i) => ({
			...series,
			data: [
				{
					color: series.color,
					name: period?.periodName ?? "",
					y: Number.parseFloat(period?.["series" + series.measureSeriesId]),
					radius: chartFormat.pane?.background[i]?.outerRadius,
					innerRadius: chartFormat.pane?.background[i]?.innerRadius,
				},
			],
			dataLabels: {
				formatter() {
					return formatSeriesNumber(this.y, this.series.options.numberFormat);
				},
			},
		}));
		return returnVal;
	} else {
		return seriesList.slice(0, 1).map((series) => ({
			...series,
			data: [
				{
					color: series.color,
					name: period?.periodName ?? "",
					y: Number.parseFloat(period?.["series" + series.measureSeriesId]),
				},
			],
			dataLabels: {
				formatter() {
					return formatSeriesNumber(this.y, this.series.options.numberFormat);
				},
			},
		}));
	}
};

export default createSolidGaugeSeriesList;
