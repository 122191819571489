import Area from "./Area";
import Block from "@clearpoint/old-theme/Block";
import Column from "@clearpoint/old-theme/Column";
import Row from "@clearpoint/old-theme/Row";
import PropTypes from "prop-types";

let propTypes = {
	pageNumber: PropTypes.number.isRequired,
};

let TopArea = ({ pageNumber }) => <Area areaClass="area0" pageNumber={pageNumber} />;
let LeftArea = ({ pageNumber }) => <Area areaClass="area1" pageNumber={pageNumber} />;
let RightArea = ({ pageNumber }) => <Area areaClass="area2" pageNumber={pageNumber} />;
let BottomArea = ({ pageNumber }) => <Area areaClass="area3" pageNumber={pageNumber} />;

let Page = ({ pageNumber }) => (
	<Block>
		<TopArea pageNumber={pageNumber} />
		<Row>
			<Column small={6} paddingRight="0">
				<LeftArea pageNumber={pageNumber} />
			</Column>
			<Column small={6} paddingLeft="0">
				<RightArea pageNumber={pageNumber} />
			</Column>
		</Row>
		<BottomArea pageNumber={pageNumber} />
	</Block>
);

Page.propTypes = propTypes;
export default Page;
