import FilterProvider from "@clearpoint/providers/FilterProvider";
import Loading from "@clearpoint/old-theme/Loading/index";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	data: PropTypes.array,
};

let FilterWrapper = ({ children, data }) =>
	!data ? <Loading /> : <FilterProvider data={data}>{children}</FilterProvider>;

FilterWrapper.propTypes = propTypes;

export default FilterWrapper;
