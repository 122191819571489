import { useContext, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import setLocalStateProvider from "./LocalStateProvider.setLocalStateProvider";
import { usePrevious, useStateObject } from "@clearpoint/hooks";


let LocalStateContext = createContext();

let propTypes = { children: PropTypes.node };

let LocalStateProvider = ({ children, ...props }) => {
	let [localState, setLocalState] = useStateObject(props);
	let previousProps = usePrevious(props);
	useEffect(
		() => setLocalStateProvider({ previousProps, props, setLocalState }),
		[previousProps, props, setLocalState]
	);
	return (
		<LocalStateContext.Provider
			value={{
				...localState,
				setLocalState,
			}}
		>
			{children}
		</LocalStateContext.Provider>
	);
};

LocalStateProvider.propTypes = propTypes;

export default LocalStateProvider;
export const useLocalState = () => useContext(LocalStateContext);
