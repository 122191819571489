import FormSelect from "../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";

let propTypes = {
	object: PropTypes.string,
};
let SelectAggregateStatusType = ({ object, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(() => {
		let options = [
			{
				label: translate(object ? `edit.all${capitalizeFirstLetter(object)}s` : "edit.aggregate.allElements"),
				value: 0,
			},
			{
				label: translate(object ? `edit.aggregate.${object}NameEquals` : "edit.aggregate.elementNameEquals"),
				value: 3,
			},
			{
				label: translate(object ? `edit.aggregate.${object}NameContains` : "edit.aggregate.elementNameContains"),
				value: 4,
			},
		];
		if (object)
			options = [
				...options,
				{
					label: translate(`edit.series.${object}sTaggedWith`),
					value: 5,
				},
			];
		return options;
	}, [object, translate]);
	return <FormSelect options={options} {...props} />;
};
SelectAggregateStatusType.propTypes = propTypes;

export default SelectAggregateStatusType;
