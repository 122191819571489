import { useCallback, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import setDeepValue from "lodash/set";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";

import { emptyArray } from "@clearpoint/utils";

import Check from "../Check/Check";
import { useFormContext } from "../Form/Form";
import ModalOnClick from "../Modal/Modal.OnClick";
import StyleWrapper from "../StyleWrapper";
import SelectAllModal from "./SelectAllModal";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	"data-testid": PropTypes.string,
	selectAllFlag: PropTypes.bool,
	disableWhenListIsEmptyFlag: PropTypes.bool,
};
let ChecklistCheckAll = ({ "data-testid": dataTestId, disableWhenListIsEmptyFlag, selectAllFlag, ...props }) => {
	let filter = useFilter();
	let deletedVisible = filter?.deletedVisible;
	let formContext = useFormContext();
	let setFormValue = formContext?.setFormValue;
	let { checklist, count, filteredChecklist, setChecklist } = useChecklist();
	let [checked, setChecked] = useState();
	// update state when checks are toggled
	useEffect(() => {
		if (!selectAllFlag && filteredChecklist.length > 0) {
			if (filteredChecklist.every((x) => x.checked) && !checked) setChecked(true);
			if (filteredChecklist.some((x) => !x.checked) && checked) setChecked(false);
		} else {
			if (checklist.every((x) => x.checked) && !checked) setChecked(true);
			if (checklist.some((x) => !x.checked) && checked) setChecked(false);
		}
	}, [checked, checklist, filteredChecklist, selectAllFlag, setChecklist]);

	useEffect(() => {
		if (filteredChecklist.length === 0) setChecklist(emptyArray);
	}, [filteredChecklist.length, setChecklist]);

	let onClick = useCallback(
		(e) => {
			let targetChecklist = e?.selectAllFlag ? checklist : filteredChecklist;
			if (checked) {
				setChecklist((checklist) => {
					checklist = cloneDeep(checklist);
					for (let check of targetChecklist) {
						checklist.find((x) => x.id === check.id).checked = false;
					}
					return checklist;
				});
				if (setFormValue && targetChecklist[0]?.name) {
					setFormValue(true, (oldFormValue) => {
						let newFormValue = cloneDeep(oldFormValue);
						for (let check of targetChecklist) {
							setDeepValue(newFormValue, check.name, false);
						}
						return newFormValue;
					});
				}
			} else {
				setChecklist((checklist) => {
					checklist = cloneDeep(checklist);
					for (let check of targetChecklist) {
						checklist.find((x) => x.id === check.id).checked = true;
					}
					return checklist;
				});
				if (setFormValue && targetChecklist[0]?.name) {
					setFormValue(true, (oldFormValue) => {
						let newFormValue = cloneDeep(oldFormValue);
						for (let check of targetChecklist) {
							setDeepValue(newFormValue, check.name, true);
						}
						return newFormValue;
					});
				}
			}
			setChecked((x) => !x);
		},
		[checked, checklist, filteredChecklist, setChecklist, setFormValue]
	);
	let selectAll = useCallback(() => onClick({ selectAllFlag: true }), [onClick]);
	let selectFiltered = onClick;
	return selectAllFlag && count > filteredChecklist.length ? (
		<Block height="37px">
			<ModalOnClick
				Modal={SelectAllModal}
				count={filteredChecklist.length}
				selectFlag={!checked}
				selectAll={selectAll}
				selectFiltered={selectFiltered}
			>
				<StyleWrapper marginBottom="0rem" height="22px">
					<Check
						data-testid={dataTestId || "checklist-check-all"}
						checked={checked}
						disabled={deletedVisible || (disableWhenListIsEmptyFlag === true && checklist.length === 0)}
						{...props}
					/>
				</StyleWrapper>
			</ModalOnClick>
		</Block>
	) : (
		<Block height="37px">
			<StyleWrapper marginBottom="0rem" height="22px">
				<Check
					data-testid={dataTestId || "checklist-check-all"}
					checked={checked && filteredChecklist.length > 0}
					disabled={filteredChecklist.length === 0 && disableWhenListIsEmptyFlag}
					onClick={onClick}
					{...props}
				/>
			</StyleWrapper>
		</Block>
	);
};
ChecklistCheckAll.propTypes = propTypes;
export default ChecklistCheckAll;
