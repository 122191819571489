import Block from "@clearpoint/old-theme/Block";
import KeyResultTrayListItem from "./KeyResultTrayListItem";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	goalId: PropTypes.number,
	keyResultList: PropTypes.arrayOf(
		PropTypes.shape({
			keyResultId: PropTypes.number,
		})
	),
};

let KeyResultTray = ({ goalId, keyResultList, ...props }) => (
	<Block
		backgroundColor="white"
		marginTop="-15px"
		marginBottom={theme.space}
		padding="none"
		boxShadow="2px 2px 3px rgb(86 96 117 / 40%)"
		borderLeft={`2px solid ${theme.lightGray}`}
		borderRadius="0px 0px 4px 4px"
		$style={`& > div:last-child	{ border-radius: 0px 0px 4px 4px; }`}
	>
		{keyResultList.map((keyResult, i) => (
			<KeyResultTrayListItem goalId={goalId} index={i} {...keyResult} {...props} />
		))}
	</Block>
);

KeyResultTray.propTypes = propTypes;
export default KeyResultTray;
