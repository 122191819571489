import { useCallback, useState } from "react";

let mockDataLookup = {
	"/templates/23132": {
		active: true,
		templateId: 23132,
		name: "Oakland County Dashboard",
		published: false,
		publishedDate: null,
		periodId: 220155,
		deleted: false,
		deletedBy: 0,
		visible: true,
	},
	"/templates/23133": {
		active: true,
		templateId: 23133,
		name: "Test Dashboard",
		published: true,
		publishedDate: "2022-05-25T15:28:49.507Z",
		periodId: 220155,
		deleted: false,
		deletedBy: 0,
		visible: true,
	},
	"/templates?format=dash&recent=true&all=false": [
		{
			active: true,
			templateId: 23132,
			name: "Oakland County Dashboard",
			published: false,
			publishedDate: null,
			periodId: 220155,
			deleted: false,
			deletedBy: 0,
			visible: true,
		},
		{
			active: true,
			templateId: 23133,
			name: "Test Dashboard",
			published: true,
			publishedDate: "2022-05-25T15:28:49.507Z",
			periodId: 220155,
			deleted: false,
			deletedBy: 0,
			visible: true,
		},
	],
	"/comment?reactions=true&commentId=8782": [
		{ name: "heart", users: [93832] },
		{
			name: "thumbsUp",
			users: [93832, 86793, 86793, 86793, 86793, 86793, 86793, 86793],
		},
		{ name: "thumbsDown", users: [93832] },
		{ name: "laugh", users: [93832] },
		{ name: "exclamation", users: [93832] },
		{ name: "question", users: [93832] },
	],

	// get({ mockDataFlag: true, object: "comment", queryString: "?object=measure&objectId=123" });
	"/comment?object=measure&objectId=123": [
		{
			discussionId: 7847,
			comment: "Test Comment 1",
			commentDate: "2021-08-02T18:18:54.590Z",
			userId: 110509,
			deleted: false,
			deletedBy: 0,
		},
		{
			discussionId: 7848,
			comment: "Test Comment 2",
			commentDate: "2021-08-02T18:19:22.417Z",
			userId: 110509,
			deleted: false,
			deletedBy: 0,
		},
		{
			discussionId: 7849,
			comment: "Test Comment 3",
			commentDate: "2021-08-02T18:19:22.417Z",
			userId: 110509,
			deleted: false,
			deletedBy: 0,
			parentId: 7847,
		},
	],
	//get({mockDataFlag: true, object: "mention", queryString: "?start=1&count=3&search=&activeOnly=1"});
	"/mentions?start=1&count=3&search=&activeOnly=1": [
		{
			action: "mention",
			active: true,
			alertId: 1,
			comment: "Test Comment 1 [@12345|Clearpoint Support]",
			commentId: 7847,
			commentDate: "2021-08-02T18:18:54.590Z",
			districtId: 6751,
			icon: "fa-eye",
			mentionId: 1,
			object: "measure",
			objectId: 903864,
			layoutId: null,
			readFlag: false,
			scorecardId: 123953,
			userId: 105743,
		},
		{
			action: "mention",
			active: true,
			alertId: 2,
			comment: "Test Comment 2 [@12345|Clearpoint Support]",
			commentId: 7848,
			commentDate: "2021-08-02T18:18:54.590Z",
			districtId: 6751,
			icon: "fa-eye",
			mentionId: 2,
			object: "measure",
			objectId: 903864,
			layoutId: null,
			readFlag: false,
			scorecardId: 123953,
			userId: 105743,
		},
		{
			action: "mention",
			active: true,
			alertId: 3,
			comment: "Test Comment 3 [@12345|Clearpoint Support]",
			commentId: 7849,
			commentDate: "2021-08-02T18:18:54.590Z",
			districtId: 6751,
			icon: "fa-eye",
			mentionId: 3,
			object: "objectiveLayout",
			objectId: 999178,
			layoutId: 999178,
			readFlag: true,
			scorecardId: 123953,
			userId: 105743,
		},
	],
	"/comment?layoutId=123&object=measure": [
		{
			commentId: 7847,
			comment: "Test Comment 1",
			commentDate: "2021-08-02T18:18:54.590Z",
			userId: 105744,
			deleted: false,
			deletedBy: 0,
		},
		{
			commentId: 7848,
			comment: "Test Comment 2",
			commentDate: "2021-08-02T18:19:22.417Z",
			userId: 105743,
			deleted: false,
			deletedBy: 0,
		},
	],
	//get({ mockDataFlag: true, object: "goal" });
	"/goals": [
		{
			goalId: 33,
			object: "goal",
			objectId: 33,
			name: "Created Owner 86793 Alpha",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 34,
			object: "goal",
			objectId: 34,
			name: "Created Owner 107443 Alpha",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 35,
			object: "goal",
			objectId: 35,
			name: "Created Owner 107443 Beta",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 36,
			object: "goal",
			objectId: 33,
			name: "Blocked Owner 86793 Alpha",
			type: null,
			statusId: 2,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 37,
			object: "goal",
			objectId: 34,
			name: "Blocked Owner 107443 Alpha",
			type: null,
			statusId: 2,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 38,
			object: "goal",
			objectId: 35,
			name: "Blocked Owner 107443 Beta",
			type: null,
			statusId: 2,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 39,
			object: "goal",
			objectId: 33,
			name: "In Progress Owner 86793 Alpha",
			type: null,
			statusId: 3,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 40,
			object: "goal",
			objectId: 34,
			name: "In Progress Owner 107443 Alpha",
			type: null,
			statusId: 3,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 41,
			object: "goal",
			objectId: 35,
			name: "In Progress Owner 107443 Beta",
			type: null,
			statusId: 3,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 42,
			object: "goal",
			objectId: 33,
			name: "Accomplished Owner 86793 Alpha",
			type: null,
			statusId: 4,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 43,
			object: "goal",
			objectId: 34,
			name: "Accomplished Owner 107443 Alpha",
			type: null,
			statusId: 4,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 44,
			object: "goal",
			objectId: 35,
			name: "Accomplished Owner 107443 Beta",
			type: null,
			statusId: 4,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 45,
			object: "goal",
			objectId: 33,
			name: "Created Owner 86793 Beta (archived)",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: true,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
		},
		{
			goalId: 46,
			object: "goal",
			objectId: 34,
			name: "Created Owner 107443 Charlie (deleted)",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: false,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: "2023-03-15T14:57:11.397Z",
			keyResults: null,
			tags: [],
		},
	],
	"/goals?group=scorecard": [
		{
			goalId: 33,
			object: "goal",
			objectId: 33,
			name: "Created Owner 86793 Alpha",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 34,
			object: "goal",
			objectId: 34,
			name: "Created Owner 107443 Alpha",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 35,
			object: "goal",
			objectId: 35,
			name: "Created Owner 107443 Beta",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 36,
			object: "goal",
			objectId: 33,
			name: "Blocked Owner 86793 Alpha",
			type: null,
			statusId: 2,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 37,
			object: "goal",
			objectId: 34,
			name: "Blocked Owner 107443 Alpha",
			type: null,
			statusId: 2,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 38,
			object: "goal",
			objectId: 35,
			name: "Blocked Owner 107443 Beta",
			type: null,
			statusId: 2,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 39,
			object: "goal",
			objectId: 33,
			name: "In Progress Owner 86793 Alpha",
			type: null,
			statusId: 3,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 40,
			object: "goal",
			objectId: 34,
			name: "In Progress Owner 107443 Alpha",
			type: null,
			statusId: 3,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 41,
			object: "goal",
			objectId: 35,
			name: "In Progress Owner 107443 Beta",
			type: null,
			statusId: 3,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 1",
		},
		{
			goalId: 42,
			object: "goal",
			objectId: 33,
			name: "Accomplished Owner 86793 Alpha",
			type: null,
			statusId: 4,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 2",
		},
		{
			goalId: 43,
			object: "goal",
			objectId: 34,
			name: "Accomplished Owner 107443 Alpha",
			type: null,
			statusId: 4,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 2",
		},
		{
			goalId: 44,
			object: "goal",
			objectId: 35,
			name: "Accomplished Owner 107443 Beta",
			type: null,
			statusId: 4,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-15T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:00:40.587Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 3",
		},
		{
			goalId: 45,
			object: "goal",
			objectId: 33,
			name: "Created Owner 86793 Beta (archived)",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 86793,
			completed: false,
			archived: true,
			completedDate: null,
			endDate: "2023-03-20T04:00:00Z",
			active: true,
			supervisorId: null,
			percentComplete: 0,
			department: "",
			description: null,
			lastEdited: "2023-03-15T13:45:06.640Z",
			deleteDate: null,
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 3",
		},
		{
			goalId: 46,
			object: "goal",
			objectId: 34,
			name: "Created Owner 107443 Charlie (deleted)",
			type: null,
			statusId: 1,
			priority: 2,
			visibility: 1,
			ownerId: 107443,
			completed: false,
			archived: false,
			completedDate: null,
			endDate: "2023-04-14T04:00:00Z",
			active: false,
			supervisorId: null,
			percentComplete: 0,
			department: null,
			description: null,
			lastEdited: "2023-03-15T14:57:11.397Z",
			deleteDate: "2023-03-15T14:57:11.397Z",
			keyResults: null,
			tags: [],
			scorecard: "Scorecard 3",
		},
	],
};

let generateMockDataFunction = (callResolvedLookup, setCallResolvedLookup, mockDataLookup) => (storeKey) => {
	if (!callResolvedLookup[storeKey]) {
		setTimeout(() => {
			if (!callResolvedLookup[storeKey]) {
				setCallResolvedLookup((prev) => ({ ...prev, [storeKey]: true }));
			}
		}, 100);
		return undefined;
	}

	return mockDataLookup[storeKey];
};

let useMockData = () => {
	let [callResolvedLookup, setCallResolvedLookup] = useState({});

	return useCallback(
		(storeKey) => {
			let fetchMockDataFromLookup = generateMockDataFunction(
				callResolvedLookup,
				setCallResolvedLookup,
				mockDataLookup
			);
			return fetchMockDataFromLookup(storeKey);
		},
		[callResolvedLookup]
	);
};

export { generateMockDataFunction };
export default useMockData;
