const CUSTOM_INTERCOM_LAUNCHER_ID = "clearpoint_intercom_launcher";

let bootThirdPartyPluginCallback =
	({
		getPromise,
		clear,
		printingFlag,
		environment,
		bootIntercom,
		trackEvent,
		update,
		bootAccessiBe,
		bootFullstory,
		intercom,
	}) =>
	async (loginFlag) => {
		if (!printingFlag && intercom) {
			let retryCount = 0;
			let options; let profile;
			while (retryCount < 5 && (!options || !profile)) {
				let promiseList = [getPromise({ object: "options" }), getPromise({ object: "profile" })];
				[options, profile] = await Promise.all(promiseList);
				if (!options) clear({ object: "options" });
				if (!profile) clear({ object: "profile" });
				retryCount++;
			}
			if (!options || !profile) return;
			window.checksumai?.identify(
				profile.userId, // distinct_id, required
				profile
			);
			let icToken = ["prod", "demo", "preview"].includes(environment) ? "y040loyk" : "rwege9m4";
			let intercomBootParams = {
				name: profile.fullName,
				email: profile.email,
				userId: profile.userId,
				company: {
					companyId: profile.districtId,
					name: profile.companyName,
					createdAt: profile.companySignupUnix,
					customAttributes: {
						id: profile.districtId,
						Expiration: profile.licensedUntilUnix,
					},
				},
				customAttributes: {
					app_id: icToken,
					"User Type": profile.userType,
					Locale: profile.localeId,
					"Premium Support": profile.premiumSupport,
					Environment: environment,
					Instance: profile.instance,
					Build: "-=DEV=-",
					"User in New UI":
						profile.preferences?.enableNewUI ||
						(profile.preferences?.enableNewUI === undefined && options.options?.enableNewUI) ||
						false,
					"Company in New UI or Old UI": options.options?.enableNewUI || false,
				},
			};

			intercomBootParams.customAttributes.custom_launcher_selector = "#" + CUSTOM_INTERCOM_LAUNCHER_ID;
			intercomBootParams.customAttributes.widget = {
				activator: "#IntercomDefaultWidget",
			};
			bootIntercom(intercomBootParams);

			if (loginFlag) {
				trackEvent("logged-in");
				update();
			}

			if (options?.enableFullstory && environment !== "localdev") {
				bootFullstory(profile, options);
			}

			// useCheckFeature triggers infinite login attempts
			if (options?.licensedFeatures?.split(",").includes("accessibility")) {
				bootAccessiBe(profile);
			}
		}
	};

export default bootThirdPartyPluginCallback;
