import { useCallback } from "react";
import getDeepValue from "lodash/get";
import PropTypes from "prop-types";

import { useFormatDate, useScorecardLockedFlag } from "@clearpoint/hooks";

import Gantt from "../../Gantt/Gantt";
import { useFormContext } from "../Form";
import useFormValue from "../hooks/useFormValue";
import InputController from "../InputController";
import EditTaskModal from "./EditTaskModal";
import useSaveGridWidth from "./useSaveGridWidth";

let propTypes = {
	ganttPodFlag: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onAfterTaskDrag: PropTypes.func,
};
let FormGantt = ({ ganttPodFlag, name, onAfterTaskDrag, ...props }) => {
	let formatDate = useFormatDate();
	let ganttValue = useFormValue(name);
	let formValue = useFormValue();
	let scorecardLockedFlag = useScorecardLockedFlag();
	let { object } = formValue;
	let saveGridWidth = useSaveGridWidth();
	let { setFormValue, setFormTouched, formValueRef } = useFormContext();
	let updateElement = useCallback(
		(ganttElement) => {
			if (!ganttElement) return;
			let ganttValue = getDeepValue(formValueRef.current, name);
			let formElement = ganttValue.find(
				(x) => x.object === ganttElement.object && x.objectId === ganttElement.objectId
			);
			let index = ganttValue.indexOf(formElement);
			let elementName = `${name}[${index}]`;
			let ganttStartDate = formatDate(ganttElement.start_date, "apiDate");
			let ganttEndDate = formatDate(ganttElement.end_date, "apiDate");
			let percentComplete = Math.round(ganttElement.progress * 100);
			if (ganttStartDate && ganttEndDate) {
				setFormValue(elementName, { startDate: ganttStartDate, endDate: ganttEndDate, percentComplete });
				setFormTouched(elementName, true);
				// Initiative detail page -- sync start and end dates
				if (object && object === "initiative" && formElement?.object === "initiative") {
					setFormValue("endDate", ganttEndDate);
					setFormValue("startDate", ganttStartDate);
				}
			}
			if (onAfterTaskDrag) onAfterTaskDrag();
		},
		[formValueRef, name, formatDate, onAfterTaskDrag, setFormValue, setFormTouched, object]
	);

	return ganttValue?.includes(undefined) ? null : (
		<InputController name={name}>
			<Gantt
				{...props}
				ganttPodFlag={ganttPodFlag}
				readOnlyFlag={scorecardLockedFlag}
				EditTaskModal={!scorecardLockedFlag && EditTaskModal}
				onAfterTaskDrag={updateElement}
				onGridResize={saveGridWidth}
			/>
		</InputController>
	);
};
FormGantt.propTypes = propTypes;

export default FormGantt;
