import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useScorecardName } from "@clearpoint/hooks";


let propTypes = {
	measureId: PropTypes.number,
	seriesType: PropTypes.string,
};
let SelectAggregateScorecardType = ({ measureId, seriesType, ...props }) => {
	let translate = useTranslate();
	let scorecardName = useScorecardName();
	let options = useMemo(
		() => [
			{
				label: translate("edit.elementsInAllScorecards"),
				value: 0,
			},
			{
				label: translate("edit.elementsInNamedScorecard", {
					scorecardName: seriesType === "specific" ? scorecardName : "global.currentScorecard",
				}),
				value: 1,
			},
			{
				label: measureId
					? translate("edit.aggregate.childElements", {
							scorecardName: seriesType === "specific" ? scorecardName : "global.currentScorecard",
					  })
					: translate("edit.elementsInChildren", {
							scorecardName: seriesType === "specific" ? scorecardName : "global.currentScorecard",
					  }),
				value: 2,
			},
			{
				label: translate("edit.elementsInSpecificScorecards"),
				value: 3,
			},
			{ label: translate("edit.series.scorecardsTaggedWith"), value: 4 },
		],
		[measureId, scorecardName, seriesType, translate]
	);
	return <FormSelect options={options} {...props} />;
};
SelectAggregateScorecardType.propTypes = propTypes;

export default SelectAggregateScorecardType;
