import { useCallback } from "react";

import { useCheckAccess } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

import checkSeriesLocked from "./useCheckSeriesLocked.checkSeriesLocked";

let useCheckSeriesLocked = ({ measureId }) => {
	let { get } = useOldQueryStore();
	let checkAccess = useCheckAccess();
	let measure = measureId && get({ object: "measure", objectId: measureId });
	let { access, scorecardId } = measure || emptyObject;

	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });

	return useCallback((series) => checkSeriesLocked({ editAccessFlag, series }), [editAccessFlag]);
};

export default useCheckSeriesLocked;
