import Badge from "./Badge";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useDescribeTimePassed } from "@clearpoint/hooks";


let propTypes = {
	deleteDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

let DeletedBadge = ({ deleteDate, ...props }) => {
	let translate = useTranslate();
	let describeTimePassed = useDescribeTimePassed();
	return (
		<Badge color="danger" {...props}>
			{translate("global.deleted")} {describeTimePassed(deleteDate)}
		</Badge>
	);
};

DeletedBadge.propTypes = propTypes;

export default DeletedBadge;
