let saveGridWidth = ({ adminAccessFlag, gridWidth, layout, layoutId, object, reportOptions, set }) => {
	if (layout && adminAccessFlag) {
		set({
			object: `${object}Layout`,
			objectId: layoutId,
			data: {
				reportOptions: {
					...reportOptions,
					gridWidth,
				},
			},
			stopPropagationFlag: true,
		});
	}
};

export default saveGridWidth;
