import Check from "../../Form/Check/Form.Check";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	editFlag: PropTypes.bool,
	object: PropTypes.string.isRequired,
};
let Locked = ({ editFlag, object, ...props }) => {
	let translate = useTranslate();
	let label = translate(object === "measureSeries" ? "edit.lockSeriesForUpdaters" : "global.locked");
	return editFlag ? (
		<RadioBoolean
			label={label}
			trueText={translate("global.lock")}
			falseText={translate("global.unlock")}
			{...props}
		/>
	) : (
		<Check data-testid="locked" text={label} {...props} label={null} />
	);
};
Locked.propTypes = propTypes;
export default Locked;
