import Block from "@clearpoint/old-theme/Block";
import DragAndDropTableCell from "@clearpoint/old-theme/DragAndDropTable/DragAndDropCell";
import FormLabel from "@clearpoint/old-theme/Form/Form.Label";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { toast } from "@clearpoint/services/toastService/index";
import { useMemoOnce } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};
let TitleCell = ({ name }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { reportColumns: fieldList } = useFormValue();
	let field = useFormValue(name);
	let { customLabel, object, label, name: fieldName } = field;
	let defaultLabel = useMemoOnce(() => translate(label || fieldName));
	let removeColumn = useCallback(() => {
		let index = fieldList.indexOf(field);
		setFormValue("reportColumns", [...fieldList.slice(0, index), ...fieldList.slice(index + 1)]);
	}, [field, fieldList, setFormValue]);
	let oneColumnFlag = fieldList?.length === 1;
	let toastAtLeastOneColumn = useCallback(() => {
		if (oneColumnFlag) {
			toast.warning(translate("layout.atLeastOneColumn"));
		}
	}, [oneColumnFlag, translate]);
	return (
		<DragAndDropTableCell backgroundColor={theme.offWhite} className="field-column-title" display="flex">
			<Block
				className="field-column-handle"
				cursor="grab"
				flex="1"
				paddingRight={theme.tinySpace}
				$style="& label { cursor: grab; }"
			>
				<FormLabel uppercaseFlag>{translate(object)}</FormLabel>
				<Block ellipsisFlag fontWeight={theme.bolder} paddingBottom={theme.smallSpace}>
					{customLabel || defaultLabel}
				</Block>
			</Block>
			<Block flexShrink="0" onClick={toastAtLeastOneColumn}>
				<IconButton
					name="close"
					color="warning"
					onClick={removeColumn}
					noFixedWidthFlag
					iconTransform=" "
					disabled={oneColumnFlag}
				/>
			</Block>
		</DragAndDropTableCell>
	);
};
TitleCell.propTypes = propTypes;
export default TitleCell;
