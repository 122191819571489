/* eslint-disable react-hooks/rules-of-hooks */
/* NOTE: Hook linting is disabled due to early return, see below */
/* NOTE: When editing this file, re-enable linting: */
/* NOTE: Remove first line and comment out early return */
/* NOTE: Then restore after editing */
import { useCallback, useMemo } from "react";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyArray, getEnvironment, noop } from "@clearpoint/utils";

import usePreviousDefined from "./usePreviousDefined";
import { useOldSession } from "@clearpoint/old-session/index";

let checkFeatureRef = {};
export default function useCheckFeature() {
	let store = useOldQueryStore();
	if (!store) return checkFeatureRef.current || noop; // early return for theme/LinkMap/formatNode (third party plugin)
	let { get, getStoredValue } = store;
	let session = useOldSession()?.session;
	let disabledFeatureList = session?.disabledFeatureList || emptyArray;
	let featureFlagList = session?.featureFlagList || emptyArray;
	let options = getStoredValue({ object: "options" });
	let profile = getStoredValue({ object: "profile" });
	let environment = getEnvironment();
	// NOTE: uncomment code to enable features for customer support.
	// let userList = getStoredValue({ object: "user" });
	// let supportUserFlag =
	// 	userList && profile ? userList.some((x) => x?.userId === profile.userId && x.supportUser) : undefined;
	let previousOptions = usePreviousDefined(options);
	let previousProfile = usePreviousDefined(profile);
	if (!options) options = previousOptions;
	if (!profile) profile = previousProfile;
	let edition = options?.license?.edition;
	let professional = ["professional", "pro2023", "enterprise", "government"].includes(edition);
	let enterprise = ["government", "enterprise", "pro2023", "unlimited"].includes(edition);
	let defaultCheck = useCallback(
		(feature) =>
			(!!options?.licensedFeatures?.split(",").includes(feature) || featureFlagList.includes(feature)) &&
			!disabledFeatureList.includes(feature),
		[disabledFeatureList, featureFlagList, options?.licensedFeatures]
	);
	let professionalCheck = useCallback(
		(feature) => professional || defaultCheck(feature),
		[defaultCheck, professional]
	);
	let enterpriseCheck = useCallback((feature) => enterprise || defaultCheck(feature), [defaultCheck, enterprise]);
	let checkLookup = useMemo(
		() => ({
			accessGroups: enterpriseCheck,
			advancedCharting: enterpriseCheck,
			aggregateSeries: professionalCheck,
			approvalWorkflows: () => defaultCheck("approvals"),
			ai: () => !options?.options?.disableAi,
			scripting: enterpriseCheck,
			dataHTML: professionalCheck,
			dashboards: defaultCheck,
			default: defaultCheck,
			dependencies: enterpriseCheck,
			fullstory: () => options?.enableFullstory && environment !== "localdev",
			measureLibrary: () => options && options.measureLibraries.length > 0,
			peerSharing: () => {
				let peerGroupList = get({ object: "peerGroup" });
				return !!(peerGroupList && peerGroupList.length > 0);
			},
			publishing: professionalCheck,
			referenceSeries: enterpriseCheck,
			snapshots: professionalCheck,
			sso: enterpriseCheck,
			statusSeries: professionalCheck,
			workspaces: defaultCheck,
		}),
		[defaultCheck, enterpriseCheck, environment, get, options, professionalCheck]
	);
	checkFeatureRef.current = useCallback(
		(feature) => {
			return checkLookup[feature] ? checkLookup[feature](feature) : checkLookup.default(feature);
		},
		[checkLookup]
	);
	return checkFeatureRef.current;
}
