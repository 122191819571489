import Block from "../../../Block";
import ChartPreview from "../../../Chart/Chart.Preview";
import Loading from "../../../Loading/index";
import {OldQueryStore} from "@clearpoint/old-query-store";
import { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { noop } from "@clearpoint/utils";
import { useOnScreenFlag } from "@clearpoint/hooks";


let propTypes = {
	chartHeight: PropTypes.number,
	detailPageFlag: PropTypes.bool,
	chartId: PropTypes.number,
	periodId: PropTypes.number,
	session: PropTypes.shape({
		session: PropTypes.shape({
			exportFlag: PropTypes.bool,
		}),
	}),
	translate: PropTypes.func,
};

let DataHTMLChart = ({ chartHeight, chartId, detailPageFlag, periodId, session, translate }) => {
	let ref = useRef();

	let exportFlag = session?.session?.exportFlag;
	let detailPageScrollableArea = document.querySelector(".detail-page-scrollable-container");
	let onScreenFlag = useOnScreenFlag(ref, {
		rootMargin: "1000px",
		root: detailPageScrollableArea,
	});

	if (!detailPageFlag) {
		onScreenFlag = true;
	} else if (detailPageFlag && !detailPageScrollableArea) {
		onScreenFlag = exportFlag;
	}

	let dummyStore = useMemo(
		() => ({
			getLoadingRequestCount: noop,
		}),
		[]
	);

	return (
		<Block minHeight={chartHeight + "px"} height={chartHeight + "px"} ref={ref}>
			{!onScreenFlag ? (
				<Loading store={dummyStore} />
			) : (
				<OldQueryStore>
					<ChartPreview
						chartHeight={chartHeight}
						chartId={chartId}
						hideLoadingFlag
						periodId={+periodId}
						session={session}
						showDescriptionFlag
						translate={translate}
					/>
				</OldQueryStore>
			)}
		</Block>
	);
};

DataHTMLChart.propTypes = propTypes;
export default DataHTMLChart;
