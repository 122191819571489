import { useEffect } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import setDefaultNotificationValuesInForm from "./useSetDefaultNotificationValuesInForm.setDefaultNotificationValuesInForm";

let useSetDefaultNotificationValuesInForm = (notificationList) => {
	let { setFormDefaultValue } = useFormContext();

	useEffect(() => {
		setDefaultNotificationValuesInForm(notificationList, setFormDefaultValue);
	}, [notificationList, setFormDefaultValue]);
};

export default useSetDefaultNotificationValuesInForm;
