import BootstrapModal from "react-bootstrap/Modal";
import Loading from "../Loading";
import React, { Suspense, useContext, createContext, forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { sizes as allSizes } from "../values";
import styled from "styled-components";
import { useId } from "@clearpoint/hooks";

let Draggable = React.lazy(() => import("react-draggable"));

let ModalContext = createContext();
let sizes = { ...allSizes };
delete sizes.medium; // no modal-md class

let Container = styled.div`
	background-clip: padding-box;
	background-color: #ffffff;
	border-radius: 0.1875rem;
	border: 1px solid rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	height: ${(props) => props.$height};
	outline: 0;
	pointer-events: auto;
	position: relative;
	width: 100%;
	& .modal-header {
		cursor: grab;
	}
`;

let modalPropTypes = {
	ariaLabel: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	close: PropTypes.func,
	"data-testid": PropTypes.string,
	height: PropTypes.string,
	modalVisible: PropTypes.bool,
	setModalVisible: PropTypes.func,
	size: PropTypes.oneOf(["small", "medium", "large", "huge"]),
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let modalDefaultProps = {
	modalVisible: false,
	size: "medium",
};

let Modal = memo(
	forwardRef(
		(
			{ ariaLabel, children, className, close, "data-testid": dataTestId, height, modalVisible, size, style },
			ref
		) => {
			let id = useId();
			return (
				<BootstrapModal
					aria-labelledby={ariaLabel}
					backdrop="static"
					centered
					className={className}
					data-testid={dataTestId}
					onHide={close}
					ref={ref}
					restoreFocusOptions={{ preventScroll: true }}
					show={modalVisible}
					size={sizes[size]}
					style={style}
				>
					<Suspense fallback={<Loading />}>
						<Draggable handle={`.modal-handle${id}`}>
							<Container $height={height}>
								<ModalContext.Provider value={{ id, close }}>{children}</ModalContext.Provider>
							</Container>
						</Draggable>
					</Suspense>
				</BootstrapModal>
			);
		}
	)
);
let useModalContext = () => useContext(ModalContext);

Modal.propTypes = modalPropTypes;
Modal.defaultProps = modalDefaultProps;

export default Modal;
export { useModalContext };
