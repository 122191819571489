import { useMemo } from "react";
import { flatten, isArray } from "lodash";
import PropTypes from "prop-types";

import Loading from "@clearpoint/old-theme/Loading/index";

import { usePreviousDefined } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import Cell from "./Cell";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	cellData: PropTypes.arrayOf(PropTypes.array),
	tableLoadingFlag: PropTypes.bool,
};
let Body = ({ cellData, cellStyle, tableLoadingFlag }) => {
	let { session } = useOldSession();
	let { get } = useOldQueryStore();

	let currentMenu = session.scorecardId && get({ object: "menu", scorecardId: session.scorecardId });
	let previousMenu = usePreviousDefined(currentMenu);

	currentMenu = currentMenu || previousMenu;

	let linkedElements = useMemo(() => {
		if (!currentMenu) return null;
		return flatten(currentMenu.map((element) => element.objects).filter(Boolean)).filter(
			(element) => element.homeScorecardId !== element.scorecardId
		);
	}, [currentMenu]);

	let hiddenLinkedElement = (field, object) => {
		if (!currentMenu || !object || field !== "linkedElements") return false;
		return !currentMenu.map((x) => x.object).includes(object);
	};

	let cellDataWithIndicesAndNoAccessNames = useMemo(() => {
		if (!cellData || !linkedElements || !isArray(cellData)) return null;
		return cellData?.map((row, rowIndex) =>
			row
				?.map?.((x, i) => ({
					...x,
					columnIndex: i,
					rowIndex,
					name:
						x.access === "No Access"
							? linkedElements.find((element) => element.objectId === x.objectId)?.name
							: null,
				}))
				.filter((x) => x.template !== "span")
		);
	}, [cellData, linkedElements]);

	return (
		<tbody>
			{cellDataWithIndicesAndNoAccessNames?.map((row, i) => (
				<tr className="cps-tr" key={i}>
					{row?.map(
						(cell, j) =>
							!hiddenLinkedElement(cell.fieldId, cell.object) && (
								<Cell key={j} cell={cell} cellStyle={cellStyle} />
							)
					)}
				</tr>
			))}
		</tbody>
	);
};
Body.propTypes = propTypes;
export default Body;
