import AdvancedOptions from "./AdvancedOptions";
import ChartMeasureSeriesSelect from "@clearpoint/old-theme/Form/Select/Select.ChartMeasureSeries";
import Column from "@clearpoint/old-theme/Column";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import Input from "@clearpoint/old-theme/Form/Input/Input";
import Row from "@clearpoint/old-theme/Row";
import SeriesOptions from "./SeriesOptions";
import { useCallback, useState } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import defaultSeries from "../../shared/defaultSeries";
import { useEffectOnce } from "@clearpoint/hooks";


let propTypes = {
	close: PropTypes.func,
	index: PropTypes.number,
	modalVisible: PropTypes.bool,
	onCancel: PropTypes.func,
	series: PropTypes.shape({
		label: PropTypes.string,
		name: PropTypes.string,
	}),
};

let defaultProps = {
	series: defaultSeries,
};

let EditChartSeriesModal = ({ close, modalVisible, onCancel, series, ...props }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { setFormValue } = useFormContext();
	let { chartSeries: chartSeriesList, measureId } = useFormValue();
	let [index, setIndex] = useState(props?.index);

	useEffectOnce(() => {
		if (index === undefined) {
			setFormValue(`chartSeries[${chartSeriesList.length}]`, {
				...series,
				dragAndDropListKey: chartSeriesList.length,
			});
			setIndex(chartSeriesList.length);
		}
	});

	let measureSeriesList = measureId && get({ parent: "measure", parentId: measureId, object: "measureSeries" });

	let chartSeriesNameInForm = `chartSeries.${index}`;
	let chartSeriesFormValue = useFormValue(chartSeriesNameInForm);
	let title = chartSeriesFormValue?.label || chartSeriesFormValue?.name || translate("edit.chart.addChartSeries");

	let setChartSeriesName = useCallback(
		(e) => {
			if (!e) return;
			let { name } = measureSeriesList?.find((series) => series?.measureSeriesId === e.value) || {};
			setFormValue(`${chartSeriesNameInForm}.name`, name);
		},
		[measureSeriesList, chartSeriesNameInForm, setFormValue]
	);

	return (
		<FormModal
			modalVisible={modalVisible}
			closeButtonText={translate("global.save")}
			close={close}
			name={chartSeriesNameInForm}
			size="large"
			title={title}
			onCancel={onCancel}
			valid={!!chartSeriesFormValue?.name}
		>
			<Row>
				<Column small={12} large={6}>
					<ChartMeasureSeriesSelect
						name={`${chartSeriesNameInForm}.measureSeriesId`}
						placeholder={translate("edit.chart.selectChartSeries")}
						label={translate("labels.measureSeries")}
						onChange={setChartSeriesName}
					/>
				</Column>
				<Column small={12} large={6}>
					<Input
						name={`${chartSeriesNameInForm}.label`}
						placeholder={chartSeriesFormValue?.name || series?.name}
						label={translate("global.label")}
					/>
				</Column>
			</Row>
			<SeriesOptions chartSeriesNameInForm={chartSeriesNameInForm} />
			<AdvancedOptions chartSeriesNameInForm={chartSeriesNameInForm} />
		</FormModal>
	);
};

EditChartSeriesModal.propTypes = propTypes;
EditChartSeriesModal.defaultProps = defaultProps;

export default EditChartSeriesModal;
