import { useCallback, useRef } from "react";
import handleResize from "../utils/handleLinkChartResize";

let useOrgChartFunctions = (chartRef) => {
	let rotatedFlagRef = useRef(false);

	let handleCollapse = useCallback(() => {
		chartRef.current?.collapseAll();
	}, [chartRef]);

	let handleExpand = useCallback(() => {
		chartRef.current?.expandAll();
	}, [chartRef]);

	let handleFit = useCallback(() => {
		handleResize(chartRef);
		chartRef.current?.fit();
	}, [chartRef]);

	let handleRotate = useCallback(() => {
		chartRef.current?.layout(rotatedFlagRef.current ? "top" : "left").render();
		rotatedFlagRef.current = !rotatedFlagRef.current;
		handleFit(chartRef.current);
	}, [chartRef, handleFit]);

	let handleZoomIn = useCallback(() => {
		chartRef.current?.zoomIn();
	}, [chartRef]);

	let handleZoomOut = useCallback(() => {
		chartRef.current?.zoomOut();
	}, [chartRef]);

	return {
		handleCollapse,
		handleExpand,
		handleFit,
		handleResize,
		handleRotate,
		handleZoomIn,
		handleZoomOut,
		rotatedFlagRef,
	};
};

export default useOrgChartFunctions;
