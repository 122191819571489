import Block from "../../Block";
import Button from "../../Button/Button";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	setTinyMceFlag: PropTypes.func,
	tinyMceFlag: PropTypes.bool,
};

let EditorToggleButton = ({ setTinyMceFlag, tinyMceFlag }) => {
	let translate = useTranslate();
	let toggleEditor = useCallback(() => {
		setTinyMceFlag((x) => !x);
	}, [setTinyMceFlag]);
	return (
		<Block marginTop={theme.tinySpace} display="flex" justifyContent="flex-end">
			<Button onClick={toggleEditor} color="primary" outlineFlag>
				{tinyMceFlag ? translate("textEditor.switchToOld") : translate("textEditor.switchToNew")}
			</Button>
		</Block>
	);
};

EditorToggleButton.propTypes = propTypes;
export default EditorToggleButton;
