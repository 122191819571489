let getHandleClose = ({ close, TRANSITION_DURATION }) => {
	// If Intercom has been hidden by modal window maximize, show it again on close
	setTimeout(() => {
		let intercomLauncher = document.querySelector(".intercom-launcher");
		let intercomContainer = document.querySelector("#intercom-container");
		if (intercomLauncher) intercomLauncher.setAttribute("style", "");
		if (intercomContainer) intercomContainer.setAttribute("style", "");
	}, TRANSITION_DURATION || 0);
	close();
};

export default getHandleClose;
