import { useCallback } from "react";
import useCheckFeature from "./useCheckFeature";
import { DISCUSSION_POD } from "@clearpoint/utils";

let useCheckDefunctPod = () => {
	let checkFeature = useCheckFeature();
	let discussionPodEnabledFlag = checkFeature(DISCUSSION_POD);

	return useCallback(
		(pod) => {
			if (!pod) return false;
			return pod.fieldId === "linkMilestoneDates" || (pod.fieldId === "discussion" && !discussionPodEnabledFlag);
		},
		[discussionPodEnabledFlag]
	);
};

export default useCheckDefunctPod;
