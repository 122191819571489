import isEqual from "lodash/isEqual";

export let updateDefaultValue =
	({ defaultValue, previousDefaultValue, formDefaultValue, elementListName, setFormDefaultValue, setFormValue }) =>
	() => {
		if (defaultValue && (!isEqual(defaultValue, previousDefaultValue) || !formDefaultValue?.[elementListName])) {
			setFormDefaultValue(elementListName, defaultValue.elementList);
			setFormValue(elementListName, defaultValue.elementList);
		}
	};
