import Block from "../Block";
import BootstrapForm from "react-bootstrap/Form";
import HelpText from "../Form/Form.HelpText";
import InputGroup from "react-bootstrap/InputGroup";
import Label from "../Form/Form.Label";
import StyleWrapper from "../StyleWrapper";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useEffectOnce } from "@clearpoint/hooks";

let sizeLookup = {
	large: "lg",
	small: "sm",
};

let StyledInput = ({
	$flexGrowFlag,
	$height,
	$marginBottom,
	$width,
	allowLastpassFlag,
	as,
	autoComplete,
	autoFocus,
	className,
	cols,
	"data-testid": dataTestId,
	disabled,
	left,
	max,
	maxLength,
	min,
	minLength,
	monospace,
	name,
	onBlur,
	onChange,
	onClick,
	onKeyDown,
	placeholder,
	readOnly,
	required,
	right,
	rows,
	size,
	spellcheck,
	step,
	style,
	type,
	value,
}) => {
	let newThemeBorderRadius = left && right && "0";
	if (!newThemeBorderRadius) newThemeBorderRadius = left ? "0 4px 4px 0" : right ? "4px 0 0 4px " : "4px";
	return (
		<StyleWrapper
			data-testid={dataTestId || "input"}
			display={$width ? "inline-block" : undefined}
			flex={$flexGrowFlag ? "1" : undefined}
			fontFamily={monospace ? "monospace" : undefined}
			fontSize="16px"
			height={$height}
			marginBottom={$marginBottom}
			style={style}
			width={$width}
			borderRadius={newThemeBorderRadius}
			$style={(left || right) && `&:active, &:focus { margin-left: 1px; }`}
		>
			<BootstrapForm.Control
				as={as}
				autoComplete={allowLastpassFlag ? undefined : autoComplete}
				autoFocus={autoFocus}
				className={className}
				cols={cols}
				data-style={undefined}
				data-lpignore={!allowLastpassFlag}
				data-testid={dataTestId}
				disabled={disabled}
				max={max}
				maxLength={maxLength}
				min={min}
				minLength={minLength}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				onClick={onClick}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				readOnly={readOnly}
				required={required}
				rows={rows}
				size={sizeLookup[size] || size}
				spellCheck={spellcheck}
				step={step}
				type={type}
				value={value}
			/>
		</StyleWrapper>
	);
};

let StyledInputGroup = ({ $width, $flexGrowFlag, $marginBottom, ...props }) => (
	<StyleWrapper
		flex={$flexGrowFlag ? "1" : undefined}
		width={$width}
		display={$width ? "inline-block" : undefined}
		marginBottom={$marginBottom}
	>
		<InputGroup {...props} />
	</StyleWrapper>
);

let FloatingTextWrapper = (props) => <Block position="relative" flex="1" whiteSpace="nowrap" {...props} />;

let FloatingText = (props) => (
	<Block
		background={theme.white}
		color={theme.muted}
		fontStyle="italic"
		marginRight={theme.space}
		padding={theme.tinySpace}
		position="absolute"
		right="0"
		top="50%"
		transform="translateY(-50%)"
		{...props}
	/>
);

let inputWrapperPropTypes = {
	floatingText: PropTypes.string,
};
let InputWrapper = ({ floatingText, ...props }) =>
	floatingText ? (
		<FloatingTextWrapper className={props.className}>
			<StyledInput {...props} />
			<FloatingText>{floatingText}</FloatingText>
		</FloatingTextWrapper>
	) : (
		<StyledInput {...props} />
	);
InputWrapper.propTypes = inputWrapperPropTypes;

let propTypes = {
	appendText: PropTypes.string,
	asCopy: PropTypes.string,
	autoComplete: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	disabled: PropTypes.bool,
	errorVisible: PropTypes.bool,
	flexGrowFlag: PropTypes.bool,
	floatingText: PropTypes.string,
	helpText: PropTypes.string,
	label: PropTypes.string,
	left: PropTypes.node,
	leftElementBorderRadius: PropTypes.string,
	marginBottom: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	overflowFlag: PropTypes.bool,
	prependText: PropTypes.string,
	right: PropTypes.node,
	rightElementBorderRadius: PropTypes.string,
	size: PropTypes.oneOf(["lg", "large", "sm", "small"]),
	step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.string,
	uppercaseLabelFlag: PropTypes.bool,
	width: PropTypes.string,
};

let defaultProps = {
	leftElementBorderRadius: `${theme.borderRadius} 0 0 ${theme.borderRadius}`,
	rightElementBorderRadius: `0 ${theme.borderRadius} ${theme.borderRadius} 0`,
	type: "text",
	value: "",
};

let Input = ({
	appendText,
	asCopy,
	autoComplete,
	disabled,
	errorVisible,
	flexGrowFlag,
	height,
	helpText,
	label,
	left,
	leftElementBorderRadius,
	marginBottom,
	overflowFlag,
	prependText,
	right,
	rightElementBorderRadius,
	size,
	uppercaseLabelFlag,
	width,
	...props
}) => {
	if (props.name === "password") {
		props.name = "drowssap"; // prevent password autofill
		props.autoComplete = "new-password";
	}
	useEffectOnce(() => {
		if (props.defaultValue) {
			props.value = props.defaultValue;
			if (props.onChange) {
				props.onChange({ target: { value: props.defaultValue } });
			}
		}
	});
	let as = props.as || asCopy;
	delete props.asCopy;
	return (
		<>
			{label && (
				<Label error={errorVisible} uppercaseFlag={uppercaseLabelFlag}>
					{label}
				</Label>
			)}
			{prependText && <span>{prependText}&nbsp;&nbsp;&nbsp;</span>}
			{helpText && <HelpText error={errorVisible}>{helpText}</HelpText>}
			{left || right ? (
				<StyledInputGroup $flexGrowFlag={flexGrowFlag} $width={width} $marginBottom={marginBottom}>
					{left && (
						<StyleWrapper
							minHeight={height}
							$style={`
									&&& > *	{
										font-size: 16px;
										:first-child	{
										border-radius: ${leftElementBorderRadius};
										}
									}
									`}
						>
							<InputGroup.Prepend className={overflowFlag ? "inputGroup--overflow" : undefined}>
								{left}
							</InputGroup.Prepend>
						</StyleWrapper>
					)}
					<InputWrapper
						left={left}
						right={right}
						{...props}
						as={as}
						$height={height}
						size={size}
						disabled={disabled}
					/>
					{right && (
						<StyleWrapper
							minHeight={height}
							$style={`
									&&& > *	{
										font-size: 16px;
										:last-child	{
											border-radius: ${rightElementBorderRadius};
										}
									}
									&&&	{
										overflow-y: visible;
									}
									`}
						>
							<InputGroup.Append className={overflowFlag ? "inputGroup--overflow" : undefined}>
								{right}
							</InputGroup.Append>
						</StyleWrapper>
					)}
				</StyledInputGroup>
			) : (
				<InputWrapper
					{...props}
					$flexGrowFlag={flexGrowFlag}
					as={as}
					$width={width}
					size={size}
					$marginBottom={marginBottom}
					disabled={disabled}
				/>
			)}
			{appendText && <span>&nbsp;&nbsp;&nbsp;{appendText}</span>}
		</>
	);
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
