import { useMemo } from "react";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import useFormValue from "../../Form/hooks/useFormValue";
import InputDate from "../../Form/Input/Input.Date";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	editMultipleFlag: PropTypes.bool,
	name: PropTypes.string,
	object: PropTypes.string,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
	parent: PropTypes.string,
	parentId: PropTypes.number,
	required: PropTypes.bool,
};

let FormFieldStartDate = ({
	editMultipleFlag,
	name,
	object,
	objectIdList,
	parent,
	parentId,
	required = true,
	...props
}) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { periodId, scorecardId } = useOldSession().session;
	let endDate = useFormValue(name.replace("startDate", "endDate"));
	if (!name.includes("startDate")) endDate = undefined;

	let elementListQuery = useMemo(() => {
		if (!objectIdList) return null;
		if (object === "milestone") {
			return parent && parentId && periodId
				? {
						parent,
						parentId,
						object,
						periodId,
				  }
				: null;
		}
		return object && scorecardId && periodId ? { object, scorecardId, periodId } : null;
	}, [object, objectIdList, parent, parentId, periodId, scorecardId]);

	let elementList =
		elementListQuery && get(elementListQuery)?.filter((x) => objectIdList?.some((id) => id === x.objectId));

	if (!endDate && editMultipleFlag) {
		endDate = elementList?.reduce(
			(earliestEndDate, element) => (element.endDate < earliestEndDate ? element.endDate : earliestEndDate),
			"9999-01-01"
		);
	}

	return (
		<InputDate
			data-testid="startDate"
			endDate={endDate}
			name={name}
			noTimeFlag
			required={required}
			label={translate(object ? `defaultFields.edit.${object}.startDate` : "logFieldLabels.startDate")}
			{...props}
		/>
	);
};

FormFieldStartDate.propTypes = propTypes;
export default FormFieldStartDate;
