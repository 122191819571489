import PageTitle from "@clearpoint/old-theme/PageTitle/PageTitle";
import PageTitleScorecardSubtitle from "@clearpoint/old-theme/PageTitle/PageTitle.ScorecardSubtitle";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};
let ReferenceHeading = ({ name }) => {
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	return <PageTitle title={translateOnlyInsideCurlyBraces(name)} subTitle={<PageTitleScorecardSubtitle />} />;
};
ReferenceHeading.propTypes = propTypes;

export default ReferenceHeading;
