import CellWrapper from "../CellWrapper";
import FormFieldDisplayElapsedDays from "@clearpoint/old-theme/Form/Field/Display/ElapsedDays";
import PropTypes from "prop-types";

let propTypes = {
	cell: PropTypes.shape({
		periodId: PropTypes.number,
	}),
	parentName: PropTypes.string,
};
let ElapsedDaysCell = (props) => {
	let { parentName, cell } = props;
	let { periodId } = cell;
	let name = `${parentName}.status.${periodId}.calculatedValues.elapsedDays`;
	return (
		<CellWrapper {...props}>
			<FormFieldDisplayElapsedDays name={name} />
		</CellWrapper>
	);
};
ElapsedDaysCell.propTypes = propTypes;

export default ElapsedDaysCell;
