/* eslint-disable no-nested-ternary */
import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import ToolbarBadge from "./ToolbarBadgeTemplate";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let BadgeListApprovalBadge = () => {
	let { element } = useDetailPageData() || emptyObject;
	let { approvalsPending, approvalsRequired } = element || emptyObject;
	let translate = useTranslate();
	return approvalsPending?.length > 0 ? (
		approvalsPending.map((approval) => (
			<ToolbarBadge key={approval.approvalId} visible={true} color="warning">
				{translate(`approvals.${approval.type}Pending`)}
			</ToolbarBadge>
		))
	) : (approvalsRequired?.length > 0 ? (
		<ToolbarBadge visible={true} color="primary">
			{translate("approvals.approvalsRequired")}
		</ToolbarBadge>
	) : null);
};

export default BadgeListApprovalBadge;
