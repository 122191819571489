import { useEffect, useRef } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import setInitialGridWidth from "./useInitialGridWidth.setInitialGridWidth";
import { emptyObject } from "@clearpoint/utils";
import { useMeasure } from "@clearpoint/hooks";

// In DHTMLX Gantt charts, the "grid" is the part on the left of the Gantt chart
// In our application, it displays a tree of initiatives and milestones
let useInitialGridWidth = ({ ganttRef, gridWidth }) => {
	let { exportFlag, exportWidth } = useOldSession()?.session || emptyObject;
	let { width: ganttContainerWidth } = useMeasure({ query: ".gantt_container" }) || emptyObject;
	let { width: measuredGridWidth } = useMeasure({ query: ".grid_cell" }) || emptyObject;
	let widthCorrectedRef = useRef(false);
	let ganttWidth = exportFlag ? exportWidth - 80 : ganttContainerWidth;

	if (!gridWidth) {
		if (ganttWidth < 480) {
			gridWidth = Math.max(ganttWidth / 3 - 5, 200);
		} else {
			gridWidth = Math.min(ganttWidth / 4 - 5, 500);
		}
	}
	useEffect(
		() => setInitialGridWidth({ ganttRef, gridWidth, measuredGridWidth, widthCorrectedRef }),
		[ganttRef, gridWidth, measuredGridWidth]
	);
};

export default useInitialGridWidth;
