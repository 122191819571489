let goalStatusLookup = {
	1: {
		color: "#3D4D79",
		icon: "createdGoal",
		name: "goals.status.created",
	},
	2: {
		color: "#f44336",
		icon: "blockedGoal",
		name: "goals.status.blocked",
	},
	3: {
		color: "#ffca28",
		icon: "inProgressGoal",
		name: "goals.status.inProgress",
	},
	4: {
		color: "#4daf50",
		icon: "accomplishedGoal",
		name: "goals.status.accomplished",
	},
};

export default goalStatusLookup;
