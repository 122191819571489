let isMyGoalsPage = (x) => {
	return x.page === "goals" && x.mode === "personal";
};

let isGoalsTreePage = (x) => {
	return x.page === "goals" && x.mode === "tree";
};

let isManageGoalsPage = (x) => {
	return x.page === "goals" && x.mode === "list";
};

export { isGoalsTreePage, isManageGoalsPage,isMyGoalsPage };
