import { useEffect } from "react";
import { useFormContext } from "../Form";
import useFormValue from "./useFormValue";
import getDeepValue from "lodash/get";
import { toBoolean } from "@clearpoint/utils";

let useBooleanFormValue = (name) => {
	let { formDefaultValue, setFormValue, setFormDefaultValue } = useFormContext();
	let value = useFormValue(name);
	let defaultValue = getDeepValue(formDefaultValue, name);
	useEffect(() => {
		if (typeof defaultValue !== "boolean" && defaultValue !== undefined)
			setFormDefaultValue(name, toBoolean(defaultValue));
		if (typeof value !== "boolean" && defaultValue !== undefined) setFormValue(name, toBoolean(defaultValue));
	}, [defaultValue, formDefaultValue, name, setFormDefaultValue, setFormValue, value]);
};
export default useBooleanFormValue;
