import { useRef, useLayoutEffect, useEffect } from "react";

// https://usehooks-ts.com/react-hook/use-interval
export default function useInterval(callback, delay) {
	let savedCallback = useRef();

	// Remember the latest callback.
	useLayoutEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		if (!delay && delay !== 0) return;
		let id = setInterval(() => savedCallback.current(), delay);
		return () => clearInterval(id);
	}, [delay]);
}
