import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let ChartSeriesValuesSelect = ({ name, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("edit.chartSeries.standardSeries"), value: "standard" },
			{ label: translate("edit.chartSeries.trendlineSeries"), value: "trendline" },
			{ label: translate("edit.chartSeries.offsetSeries"), value: "offset" },
		],
		[translate]
	);
	return <Select options={options} name={name} {...props} />;
};

ChartSeriesValuesSelect.propTypes = propTypes;

export default ChartSeriesValuesSelect;
