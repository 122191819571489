import PropTypes from "prop-types";

import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";

import { useMeasure, usePreviousDefined } from "@clearpoint/hooks";

import HeaderCell from "./HeaderCell";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	headerData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
	headerStyle: PropTypes.string,
};

let Header = ({ headerData, headerStyle }) => {
	let previousHeaderData = usePreviousDefined(headerData);
	headerData = headerData || previousHeaderData;
	let { height: firstRowHeight } = useMeasure({ query: `.grid-header-0` }) || {};
	let { customFieldFlag } = useLocalState();
	return !headerData && !headerStyle ? null : (
		<thead style={{ position: "sticky", top: "0", zIndex: "50", width: "100%" }}>
			{headerData?.map((headerRow, i) => (
				<tr className="cps-tr" key={i}>
					{headerRow
						.filter((x) => !x.skipCol && !x.skipRow)
						.map((heading, j) => (
							<StyleWrapper
								top={!customFieldFlag && i === 1 ? firstRowHeight + "px !important" : undefined}
								key={j}
							>
								<HeaderCell
									className={heading.rowSpan ? `` : `grid-header-${i}`}
									heading={heading}
									headerStyle={headerStyle}
								/>
							</StyleWrapper>
						))}
				</tr>
			))}
		</thead>
	);
};
Header.propTypes = propTypes;
export default Header;
