import Block from "../../Block";
import Input from "../../Form/Input/Input";
import Slider from "../../Slider/Slider";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { theme } from "../../Theme";

let propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string,
};
let Percent = ({ disabled, name, ...props }) => {
	let percentFormValue = useFormValue(name);
	let { setFormValue } = useFormContext();
	let debouncedSetFormValue = useCallback(debounce(setFormValue, 100, { maxWait: 100 }), [setFormValue]); // eslint-disable-line react-hooks/exhaustive-deps
	let [sliderValue, setSliderValue] = useState(0);
	let onSliderChange = useCallback(
		(value) => {
			debouncedSetFormValue(name, value);
			setSliderValue(value);
		},
		[debouncedSetFormValue, name]
	);
	let formValueValidFlag = !isNaN(+percentFormValue);
	let roundPercentValue = useCallback((value) => Math.round(+value), []);
	let setAndClampSliderValue = useCallback(
		(value) => {
			setSliderValue(roundPercentValue(Math.min(Math.max(0, value), 100)));
		},
		[roundPercentValue]
	);
	useEffect(() => {
		if (sliderValue !== percentFormValue && formValueValidFlag) {
			setAndClampSliderValue(percentFormValue);
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValueValidFlag]);
	let onInputChange = useCallback(
		(e) => {
			setAndClampSliderValue(e.target.value);
		},
		[setAndClampSliderValue]
	);
	return (
		<Block marginBottom={theme.space}>
			<Input
				data-testid="percent"
				defaultValue={0}
				max={Math.pow(10, 14)}
				min={0}
				name={name}
				onChange={onInputChange}
				type="number"
				disabled={disabled}
				elementValueTransform={roundPercentValue}
				formValueTransform={roundPercentValue}
				{...props}
			/>
			<Slider
				displayValue={percentFormValue}
				min={0}
				max={100}
				onChange={onSliderChange}
				value={sliderValue}
				disabled={disabled}
			/>
		</Block>
	);
};

Percent.propTypes = propTypes;

export default Percent;
