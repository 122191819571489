import PropTypes from "prop-types";

import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import AlignmentMatrix from "./AlignmentMatrix";
import StandardReport from "./StandardReport";


let elementsTabPropTypes = {
  object: PropTypes.string,
};

let ElementsTab = ({ object }) => {
  let reportType = useFormValue("reportType");
  return reportType === "matrix" ? <AlignmentMatrix object={object} /> : <StandardReport object={object} />;
};

ElementsTab.propTypes = elementsTabPropTypes;

export default ElementsTab;
