import DropdownItem from "./Dropdown.Item";
import ModalOnClick from "../../Modal/Modal.OnClick";
import ModalWindowOnClick from "../../ModalWindow/ModalWindow.OnClick";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	addElementFlag: PropTypes.bool,
	children: PropTypes.node,
	"data-testid": PropTypes.string,
	iconName: PropTypes.string,
	object: PropTypes.string,
};

let AddDropdownItemInner = ({ addElementFlag, children, "data-testid": dataTestId, iconName, object, ...props }) => {
	let translate = useTranslate();
	return (
		<DropdownItem
			icon={object || iconName ? object || iconName : "add"}
			data-testid={dataTestId || "dropdown-item-add"}
			{...props}
		>
			{children || translate("edit.element.addElement", { element: `global.${object}` })}
		</DropdownItem>
	);
};
AddDropdownItemInner.propTypes = propTypes;

let AddDropdownItemPropTypes = {
	Modal: PropTypes.elementType,
	ModalWindow: PropTypes.elementType,
};
let AddDropdownItem = ({ Modal, ModalWindow, ...props }) =>
	Modal ? (
		<ModalOnClick Modal={Modal} {...props}>
			<AddDropdownItemInner {...props} />
		</ModalOnClick>
	) : ModalWindow ? (
		<ModalWindowOnClick ModalWindow={ModalWindow} {...props}>
			<AddDropdownItemInner {...props} />
		</ModalWindowOnClick>
	) : (
		<AddDropdownItemInner {...props} />
	);
AddDropdownItem.propTypes = AddDropdownItemPropTypes;
export default AddDropdownItem;
