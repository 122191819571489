import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useOldSession } from "@clearpoint/old-session/index";

let useScorecardLockedFlag = (scorecardId) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	scorecardId = scorecardId || session.scorecardId;
	let scorecardList = get({ object: "scorecard" });
	let scorecard = scorecardList?.find((x) => x.scorecardId === scorecardId);
	return scorecard?.locked;
};

export default useScorecardLockedFlag;
