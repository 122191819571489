import * as Sentry from "@sentry/react";
import { deleteAxiosProperty } from "../axiosService";
import { toast } from "../toastService";
import { checkErrorForRetry } from "@clearpoint/utils";

let getHandleError =
	({ cancelAndResetAbortController, getLoginRedirectFromHash, getTranslate, requestLookup, testFlag }) =>
	(e, key, url, payload) => {
		delete requestLookup[key];
		if (
			e.response &&
			((url === "/profile" && e.response.status >= 400) ||
				(e.response.data && e.response.data.error === "Authorization Header Required"))
		) {
			deleteAxiosProperty('defaults.headers.common["Authorization"]');

			cancelAndResetAbortController();
			let url = getLoginRedirectFromHash(window.location.hash);
			window.location.assign(url);
		}

		if (["post", "put", "delete"].includes(e.config?.method)) {
			let loginLogoutFlag =
				e.config?.method === "post" && ["/authenticate", "/ssoAuth", "/logout"].some((x) => url.includes(x));
			let error = e.response?.data || e;
			let networkErrorFlag = checkErrorForRetry(error);
			if (!loginLogoutFlag && !networkErrorFlag) {
				let translate = getTranslate();
				if (translate?.("error.api")) {
					toast.error(translate("error.api", { method: e.config.method, url }), { toastId: "apiError" });
				}
				console.warn(`${e.config.method} ${url}`);
				console.warn(JSON.stringify(payload, 0, 3));
				Sentry.captureException(e); // send error to Sentry without halting UI
				return;
			}
		}

		if (e.response?.data) {
			if (testFlag) {
				throw JSON.stringify(e.response.data);
			} else {
				throw e.response.data;
			}
		}

		if (testFlag) {
			throw JSON.stringify(e);
		} else {
			throw e;
		}
	};

export default getHandleError;
