import Button from "./Button";
import Icon from "../Icon/Icon";
import ModalUndelete from "../Modal/Modal.Undelete";
import { useCallback, useState } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";

/* eslint-disable react-hooks/exhaustive-deps */

let propTypes = {
	"data-testid": PropTypes.string,
	body: PropTypes.string,
	className: PropTypes.string,
	confirmButtonText: PropTypes.string,
	disabledToast: PropTypes.string,
	object: PropTypes.string.isRequired,
	objectId: PropTypes.number,
	onUndelete: PropTypes.func,
	shouldOpenModal: PropTypes.func,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	toast: PropTypes.string,
};

let defaultProps = {
	size: "tiny",
};

let UndeleteButton = ({
	"data-testid": dataTestId,
	body,
	className,
	confirmButtonText,
	disabledToast,
	object,
	objectId,
	onUndelete,
	shouldOpenModal,
	size,
	style,
	title,
	toast: toastMessage,
}) => {
	let [undeleteModalVisible, setUndeleteModalVisible] = useState(false);
	let translate = useTranslate();

	let showModal = useCallback(
		(e) => {
			if (e) e.stopPropagation();
			if (shouldOpenModal) {
				if (!shouldOpenModal({ object, objectId })) return;
			}
			setUndeleteModalVisible(true);
		},
		[object, objectId, shouldOpenModal]
	);

	let onClick = useCallback(
		(e) => {
			if (disabledToast) {
				toast.warning(disabledToast);
			} else {
				showModal(e);
			}
		},
		[object, showModal, translate]
	);

	let close = useCallback((e) => {
		if (e) e.stopPropagation();
		setUndeleteModalVisible(false);
	}, []);

	return (
		<>
			<Button
				onClick={onClick}
				color="white"
				size={size}
				padding="3px 6px"
				className={className}
				style={style}
				data-testid={dataTestId || "undelete-button"}
			>
				<Icon name="undelete" transform="up-1 right-1" />
			</Button>
			{undeleteModalVisible && (
				<ModalUndelete
					modalVisible={undeleteModalVisible}
					close={close}
					object={object}
					onUndelete={onUndelete}
					objectId={objectId}
					toastMessage={toastMessage}
					title={title}
					body={body}
					confirmButtonText={confirmButtonText}
				/>
			)}
		</>
	);
};

UndeleteButton.propTypes = propTypes;
UndeleteButton.defaultProps = defaultProps;

export default UndeleteButton;
