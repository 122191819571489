import Block from "../../../Block";
import CompletedCheck from "../../../Form/Check/Form.Check.Completed";
import PropTypes from "prop-types";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "../../../Theme";
import { useOldSession } from "@clearpoint/old-session/index";
import { useScorecardLockedFlag } from "@clearpoint/hooks";

let propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	onClick: PropTypes.func,
};

let FormFieldDisplayCompleted = ({ disabled, name, object, objectId, onClick }) => {
	let translate = useTranslate();
	let { exportFlag, scorecardId } = useOldSession().session;
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let value = useFormValue(name);
	return exportFlag || scorecardLockedFlag ? (
		<Block fontWeight={theme.bold}>
			{translate(value ? "displayDetail.fields.boolean.completed" : "displayDetail.fields.boolean.notCompleted")}
		</Block>
	) : (
		<CompletedCheck
			object={object}
			objectId={objectId}
			defaultValue={value}
			disabled={disabled}
			name={name}
			onClick={onClick}
		>
			{translate("components.completed")}
		</CompletedCheck>
	);
};

FormFieldDisplayCompleted.propTypes = propTypes;
export default FormFieldDisplayCompleted;
