import Alert from "../../../Alert/Alert";
import DataHTMLChart from "./DataHTMLChart";
import StatusIcon from "../../../StatusIcon";
import { StyleSheetManager } from "styled-components";
import StyleWrapper from "../../../StyleWrapper";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useLayoutEffect, useMemo, useRef } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";
import PropTypes from "prop-types";
import classNames from "classnames";
import { createRoot } from "react-dom/client";
import { theme } from "../../../Theme";
import { emptyObject } from "@clearpoint/utils";
import { useBuildImagePath, useScopedHtmlDisplayStyles } from "@clearpoint/hooks";

let styleManagerPropTypes = {
	children: PropTypes.node.isRequired,
	htmlExportFlag: PropTypes.bool,
};

let ExportStyleManager = ({ children, htmlExportFlag }) =>
	htmlExportFlag ? <StyleSheetManager disableCSSOMInjection>{children}</StyleSheetManager> : children;

let propTypes = {
	detailPageFlag: PropTypes.bool,
	displayValue: PropTypes.string,
	modalFlag: PropTypes.bool,
	name: PropTypes.string,
};

let DataHTMLDisplay = ({ detailPageFlag, displayValue, modalFlag, name }) => {
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let translate = useTranslate();
	let session = useOldSession();
	let { exportFlag, htmlExportFlag } = session.session;
	let store = useOldQueryStore();
	let { get } = store;
	let ref = useRef();
	let buildImagePath = useBuildImagePath();

	name = name.endsWith(".value") ? `${name.slice(0, -6)}.displayValue` : name;
	let formDisplayValue = useFormValue(name);
	displayValue = displayValue || formDisplayValue;
	if (modalFlag) displayValue = displayValue?.replace(/<a\b[^>]*>/i, "")?.replace(/<\/a>/i, "");
	// TODO: check if needed after build
	let imgRegex = /(<img [^>]*src=\\*")([^\\*"]*)"/g;
	displayValue =
		displayValue?.replaceAll?.(imgRegex, (_match, tag, path) => `${tag}${buildImagePath(path)}"`) ?? displayValue;

	displayValue = translateOnlyInsideCurlyBraces(displayValue);
	let invalidContentWarningFlag = !exportFlag && displayValue?.includes("<!-- WARNING: INVALID HTML --->");

	let { containerStyleClass, htmlDisplayValue } = useScopedHtmlDisplayStyles(displayValue) || emptyObject;

	displayValue = displayValue?.replace(/<p>[ \n]?<\/p>/g, "");
	htmlDisplayValue = htmlDisplayValue?.replace(/<p>[ \n]?<\/p>/g, "");

	useLayoutEffect(() => {
		let node = document.createElement("div");
		node.innerHTML = htmlDisplayValue || displayValue;
		if (node.querySelector("display-status-icon")) {
			while (node.querySelector("display-status-icon")) {
				let status = node.querySelector("display-status-icon");
				let parent = status.parentElement;
				let size = status.getAttribute("size");
				let statusId = status.getAttribute("status-id");
				let statusNode = document.createElement("span");
				let root = createRoot(statusNode);
				root.render(
					<ExportStyleManager htmlExportFlag={htmlExportFlag}>
						<StyleWrapper maxWidth="unset !important">
							<StatusIcon get={get} size={size} statusId={+statusId} translate={translate} />
						</StyleWrapper>
					</ExportStyleManager>
				);
				parent.replaceChild(statusNode, status);
			}
		}
		if (node.querySelector("measure-chart")) {
			while (node.querySelector("measure-chart")) {
				let chart = node.querySelector("measure-chart");
				let parent = chart.parentElement;
				let height = chart.getAttribute("height");
				let chartId = chart.getAttribute("chart-id");
				let periodId = chart.getAttribute("period-id");
				let chartNode = document.createElement("div");
				let root = createRoot(chartNode);
				root.render(
					<DataHTMLChart
						detailPageFlag={detailPageFlag}
						chartHeight={+height || undefined}
						chartId={+chartId}
						periodId={+periodId}
						session={session}
						translate={translate}
					/>
				);
				parent.replaceChild(chartNode, chart);
			}
		}
		if (!ref.current.firstChild) ref.current.appendChild(node);
		else if (!node.isEqualNode(ref.current.firstChild)) {
			ref.current.firstChild?.remove();
			ref.current.appendChild(node);
		}
	}, [detailPageFlag, displayValue, get, htmlDisplayValue, htmlExportFlag, session, translate]);

	return useMemo(
		() => (
			<>
				{invalidContentWarningFlag && (
					<StyleWrapper marginBottom={theme.smallSpace}>
						<Alert color="danger">{translate("detailLayouts.contentWarning")}</Alert>
					</StyleWrapper>
				)}
				<div className={classNames("htmlarea", containerStyleClass)} ref={ref} />
			</>
		),
		[containerStyleClass, invalidContentWarningFlag, translate]
	);
};

DataHTMLDisplay.propTypes = propTypes;
ExportStyleManager.propTypes = styleManagerPropTypes;
export default DataHTMLDisplay;
