import { toast } from "@clearpoint/services/toastService/index";

let checkAccess =
	(translate, scorecardList, archiveFlag) =>
	({ access, action, toastFlag, scorecardId }) => {
		let isScorecardLocked = (scorecardId) => {
			if (scorecardId === false) return false;
			let scorecard = scorecardList?.find((x) => x.scorecardId === scorecardId);
			if (toastFlag && scorecard?.locked) {
				toast.warning(translate("toaster.messages.global.lockedScorecard"));
			}
			return scorecard?.locked;
		};
		let isArchive = () => {
			if (toastFlag) {
				toast.warning(translate("toaster.messages.global.archiveNoEdit"));
			}
			return false;
		};
		let actionLookup = {
			admin: () => {
				if (archiveFlag) return isArchive();
				//if (isScorecardLocked(scorecardId)) return false;
				if (["Administrator", "Scorecard Admin"].includes(access)) {
					return true;
				} else if (toastFlag) {
					toast.warning(translate("toaster.messages.global.adminRequired"));
				}
				return false;
			},
			edit: () => {
				if (archiveFlag) return isArchive();
				if (["Administrator", "Scorecard Admin", "Editor"].includes(access)) {
					return true;
				} else if (toastFlag) {
					toast.warning(translate("toaster.messages.global.editorRequired"));
				}
				return false;
			},
			update: () => {
				if (archiveFlag) return isArchive();
				if (["Administrator", "Scorecard Admin", "Editor", "Updater"].includes(access)) {
					return true;
				} else if (toastFlag) {
					toast.warning(translate("toaster.messages.global.updaterRequired"));
				}
				return false;
			},
		};
		// if (!actionLookup[action]) throw new Error("Invalid call to useCheckAccess, action: " + action);
		return actionLookup[action]();
	};

export default checkAccess;
