let goalViewLookup = {
	DEPARTMENT: "department",
	DEPARTMENT_PERSONAL: "departmentPersonal",
	INDIVIDUAL: "individual",
	INDIVIDUAL_PERSONAL: "individualPersonal",
	MANAGER: "manager",
	MANAGER_PERSONAL: "managerPersonal",
	SCORECARD: "scorecard",
};

export default goalViewLookup;
