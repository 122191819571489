import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	displayValue: PropTypes.string,
	name: PropTypes.string,
};

let StringDisplay = ({ displayValue, name }) => {
	let formDisplayValue = useFormValue(name);
	displayValue = displayValue || formDisplayValue;
	return <span>{displayValue}</span>;
};

StringDisplay.propTypes = propTypes;
export default StringDisplay;
