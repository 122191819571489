import { useState } from "react";
import PropTypes from "prop-types";

import { useStateObject } from "@clearpoint/hooks";

import Block from "../../Block";
import CommentHeader from "./CommentHeader";
import DisplayComment from "./DisplayComment";
import EditComment from "./EditComment";


let propTypes = {
	comment: PropTypes.shape({
		comment: PropTypes.string,
		commentId: PropTypes.number,
		discussionId: PropTypes.number,
	}),
	setReplyFlag: PropTypes.func,
	threadParentFlag: PropTypes.bool,
	updatingFlag: PropTypes.bool,
};

let Comment = ({ comment: commentData, setReplyFlag, threadParentFlag, updatingFlag }) => {
	let [resolvingFlag, setResolvingFlag] = useState(false);
	let { comment: commentText, commentId, discussionId } = commentData;
	let [{ commentValue, editModeFlag }, setState] = useStateObject({
		commentValue: commentText,
		editModeFlag: false,
	});

	return (
		<Block id={`comment${commentId || discussionId}`} className="cps-comment">
			<Block marginBottom="10px">
			<CommentHeader
				commentData={commentData}
				discussionId={discussionId}
				editModeFlag={editModeFlag}
				resolvingFlag={resolvingFlag}
				setReplyFlag={setReplyFlag}
				setState={setState}
				threadParentFlag={threadParentFlag}
			/>
			</Block>
			{editModeFlag ? (
				<EditComment commentData={commentData} commentValue={commentValue} setState={setState} />
			) : (
				<DisplayComment
					commentData={commentData}
					commentValue={commentValue}
					resolvingFlag={resolvingFlag}
					setResolvingFlag={setResolvingFlag}
					updatingFlag={updatingFlag}
				/>
			)}
		</Block>
	);
};

Comment.propTypes = propTypes;

export default Comment;
