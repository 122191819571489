import Alert from "./Alert";
import HTML from "../HTML";
import { useTranslate } from "@clearpoint/translate";

let NoPeriodsForScorecardAlert = () => {
	let translate = useTranslate();

	return (
		<Alert color="danger">
			<HTML>{translate("edit.element.noPeriods")}</HTML>
		</Alert>
	);
};

export default NoPeriodsForScorecardAlert;
