import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let ChartSeriesMarkerSelect = ({ name, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ label: translate("edit.chartSeries.chartDefault"), value: "" },
			{ label: translate("edit.chartSeries.none"), value: "none" },
			{ label: translate("edit.chartSeries.circle"), value: "circle" },
			{ label: translate("edit.chartSeries.square"), value: "square" },
			{ label: translate("edit.chartSeries.diamond"), value: "diamond" },
			{ label: translate("edit.chartSeries.triangle"), value: "triangle" },
			{ label: translate("edit.chartSeries.triangleDown"), value: "triangle-down" },
		],
		[translate]
	);
	return <Select placeholder={translate("edit.selectMarker")} options={options} name={name} {...props} />;
};

ChartSeriesMarkerSelect.propTypes = propTypes;

export default ChartSeriesMarkerSelect;
