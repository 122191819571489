import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import getHandleTabChange from "./useHandleTabChange.getHandleTabChange";

let useHandleTabChange = ({
	formValidFlag,
	loadingFlag,
	fieldListLoadingError,
	noFormFlag,
	setNextTab,
	setSelectedTab,
}) => {
	let translate = useTranslate();
	return useCallback(
		({ onClick, tabIndex }) => {
			let handleTabChange = getHandleTabChange({
				formValidFlag,
				loadingFlag,
				fieldListLoadingError,
				noFormFlag,
				setNextTab,
				setSelectedTab,
				translate,
			});
			return handleTabChange({
				onClick,
				tabIndex,
			});
		},
		[formValidFlag, loadingFlag, fieldListLoadingError, noFormFlag, setNextTab, setSelectedTab, translate]
	);
};

export default useHandleTabChange;
