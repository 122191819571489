import Input from "../../Form/Input/Input";
import useFormValue from "../../Form/hooks/useFormValue";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
	object: PropTypes.string,
};
let FormFieldSeriesData = ({ name, object, ...props }) => {
	let seriesDataTypeIdName; // 11 is "text"
	if (name?.includes("seriesData")) {
		seriesDataTypeIdName = name.slice(0, name.indexOf("seriesData") - 1) + ".seriesDataTypeId";
	}
	let seriesDataTypeId = useFormValue(seriesDataTypeIdName);
	let type = seriesDataTypeId === 11 ? "text" : "number";
	return type === "text" ? (
		<Input data-testid="text-series-value" name={name} {...props} />
	) : (
		<Input data-testid="number-series-value" max="99999999999999" name={name} type="number" {...props} />
	);
};
FormFieldSeriesData.propTypes = propTypes;
export default FormFieldSeriesData;
