import ConfirmModalOnClick from "@clearpoint/old-theme/Modal/Modal.Confirm.OnClick";
import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import SplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";

let DetailLayoutSaveButton = (props) => {
	let translate = useTranslate();
	let { close, elementName, elementScorecardId } = useWindowContext();
	let { access, object } = useFormValue();
	let { formStatus, onSubmit } = useFormContext();

	let disabled = !formStatus.valid || props.disabled;
	let adminFlag = access === "Administrator";

	// Revisit after form state optimization (written 12/7/21)
	let submitValueTransformGenerator = useCallback(
		(saveOption) => (submitValue) => ({
			layout: submitValue.layout,
			scorecardId: elementScorecardId,
			saveOption,
		}),
		[elementScorecardId]
	);

	let save = useCallback(
		({ saveOption }) => {
			onSubmit({ skipCloseFlag: true, submitValueTransform: submitValueTransformGenerator(saveOption) });
			close();
		},
		[close, onSubmit, submitValueTransformGenerator]
	);

	// "All Elements" meaning "All Elements of this type", e.g., all objectives, all measures
	let updateOnlyThisElement = useCallback(() => save({ saveOption: 0 }), [save]);
	let updateAllElementsInScorecard = useCallback(() => save({ saveOption: 1 }), [save]);
	let updateAllElementsWithSameName = useCallback(() => save({ saveOption: 2 }), [save]);
	let updateAllElementsInAccount = useCallback(() => save({ saveOption: 3 }), [save]);

	return (
		<SplitButton
			data-testid="detail-layout-save-button"
			title={translate("global.save")}
			type="submit"
			drop="up"
			maxWidth="400px"
			{...props}
			disabled={disabled}
		>
			<DropdownItem onClick={updateOnlyThisElement}>
				{translate("edit.updateOnlyThis", { InsertText: object })}
			</DropdownItem>
			<ConfirmModalOnClick
				body={translate("edit.scorecardSaveConfirm", {
					InsertText: object + "s",
					InsertText2: "scorecard",
				})}
				confirmButtonColor="primary"
				confirmButtonText={translate("global.save")}
				onConfirm={updateAllElementsInScorecard}
				title={translate("global.confirmGlobalSave")}
			>
				<DropdownItem>
					{translate("edit.updateAllIn", {
						InsertText: object + "s",
						InsertText2: "scorecard",
					})}
				</DropdownItem>
			</ConfirmModalOnClick>
			{adminFlag && (
				<>
					<ConfirmModalOnClick
						body={translate("edit.namedElementSaveConfirm", {
							InsertText: object + "s",
							InsertText2: elementName,
						})}
						confirmButtonColor="primary"
						confirmButtonText={translate("global.save")}
						onConfirm={updateAllElementsWithSameName}
						title={translate("global.confirmGlobalSave")}
					>
						<DropdownItem>
							{translate("edit.updateAllNamed", {
								InsertText: object + "s",
								InsertText2: elementName,
							})}
						</DropdownItem>
					</ConfirmModalOnClick>
					<ConfirmModalOnClick
						body={translate("edit.globalSaveConfirm", {
							InsertText: object + "s",
						})}
						confirmButtonColor="primary"
						confirmButtonText={translate("global.save")}
						onConfirm={updateAllElementsInAccount}
						title={translate("global.confirmGlobalSave")}
					>
						<DropdownItem>{translate("edit.updateAll", { InsertText: object + "s" })}</DropdownItem>
					</ConfirmModalOnClick>
				</>
			)}
		</SplitButton>
	);
};

export default DetailLayoutSaveButton;
