// these constants should be used to build paths in queries/mutations
export const ELEMENT_PATH = "/elements";
export const LINK_PATH = "/links";
export const MEASURE_GRID_PATH = "/measureGrid";
export const MENU_PATH = "/menu";
export const MY_FAVORITE_PATH = "/myFavorites";
export const MY_SCORECARD_PATH = "/myScorecard";
export const MY_UPDATES_PATH = "/myUpdates";
export const OBJECTIVE_PATH = "/objectives";
export const OPTIONS_PATH = "/options";
export const PEER_GROUP_PATH = "/peerGroups";
export const PREVIEW_PATH = "/preview";
export const RECENT_PATH = "/recents";
export const REPORT_PATH = "/reports";
export const SEARCH_PATH = "/search";
export const USER_PATH = "/users";
export const WORKFLOW_PATH = "/workflows";
