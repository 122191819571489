import useFormValue from "./useFormValue";
import { useEffect, useMemo, useState } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import getAvailablePeriodList from "./useAvailablePeriods.getAvailablePeriodList";
import { emptyArray } from "@clearpoint/utils";
import { useScorecardId } from "@clearpoint/hooks";



let useAvailablePeriods = (parentName) => {
	let { get } = useOldQueryStore();
	let { measureId } = useFormValue();

	let periodGroupIdNameInForm = parentName ? `${parentName}.periodGroupId` : "periodGroupId";
	let periodGroupId = useFormValue(periodGroupIdNameInForm);

	let [formLoadedFlag, setFormLoadedFlag] = useState(periodGroupId !== undefined);
	useEffect(() => {
		if (periodGroupId !== undefined) {
			setFormLoadedFlag(true);
		}
	}, [periodGroupId]);

	let scorecardId = useScorecardId({ object: "measure", objectId: measureId });

	let measureGridData = measureId && get({ parent: "measure", parentId: measureId, object: "measureGrid" });
	let periodGroup = periodGroupId > 0 ? get({ object: "periodGroup", objectId: periodGroupId }) : null;
	let periodList = get({ object: "period" });

	let availablePeriodList = useMemo(() => {
		return getAvailablePeriodList({
			formLoadedFlag,
			measureGridData,
			measureId,
			periodGroup,
			periodList,
			scorecardId,
		});
	}, [formLoadedFlag, measureGridData, measureId, periodGroup, periodList, scorecardId]);
	return availablePeriodList || emptyArray;
};

export default useAvailablePeriods;
