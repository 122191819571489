import PropTypes from "prop-types";

import PeriodBadge from "@clearpoint/old-theme/Badge/Badge.Period";
import Block from "@clearpoint/old-theme/Block";
import CopyForwardButton from "@clearpoint/old-theme/Button/Button.CopyForward";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import CopyForwardModal from "@clearpoint/old-theme/Modal/Modal.CopyForward";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAiAccess, useMemoOnce } from "@clearpoint/hooks";
import { emptyObject } from "@clearpoint/utils";

import { usePodContext } from "../PodProvider";
import getPeriodBadgeVisibleFlag from "./HeaderRight.getPeriodBadgeVisibleFlag";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import AIAssistantButton from "@components/Layouts/ViewLayout/ViewDetail/Detail/AIAssistants/AIAssistantButton";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";


let propTypes = {
	fieldId: PropTypes.string,
	updateModeFlag: PropTypes.bool,
};

let HeaderRight = ({ fieldId, updateModeFlag }) => {
	let { elementStatus } = useDetailPageData() || emptyObject;
	let { periodId: elementPeriodId } = useFormValue("updates") || emptyObject;
	let formPeriodGroupId = useFormValue("periodGroupId");
	let { pagePeriodId } = useLocalState();
	let { editPodFlag, podWidthDisplayClass } = usePodContext();
	let periodId = elementStatus?.periodId ?? elementPeriodId;
	let periodGroupId = elementStatus?.periodGroupId ?? formPeriodGroupId;
	let copyForwardFlag = updateModeFlag && editPodFlag;
	fieldId = fieldId === "comments" ? "analysis" : fieldId;
	let { aiAccessFlag } = useCheckAiAccess();
	let aiAssistantFieldList = useMemoOnce(() => ["analysis", "comments", "milestones", "recommendations"]);
	let aiButtonFlag = editPodFlag && aiAccessFlag && aiAssistantFieldList.includes(fieldId);

	let addPeriodFlag = updateModeFlag || (["lastUpdated", "lastUpdatedBy"].includes(fieldId));

	let buttonFlagList = useMemoOnce(() => [aiButtonFlag, copyForwardFlag]);
	let buttonCount = buttonFlagList.filter(Boolean).length;

	let periodBadgeVisibleFlag = getPeriodBadgeVisibleFlag(
		buttonCount,
		podWidthDisplayClass,
		addPeriodFlag
	);
	let visibleFlag = updateModeFlag || copyForwardFlag || periodBadgeVisibleFlag;;

	return visibleFlag ? (
		<Block display="flex">
			{periodBadgeVisibleFlag && (
				<Block marginTop={"-3px"}>
					<PeriodBadge
						periodGroupId={periodGroupId}
						periodId={periodId}
						highlightFlag={pagePeriodId !== periodId}
						size="podHeader"
					/>
				</Block>
			)}
			{copyForwardFlag && (
				<Block>
					<CopyForwardButton
						Modal={CopyForwardModal}
						color="success"
						field={fieldId}
						parentName="updates"
						marginLeft={theme.smallSpace}
						right={theme.smallSpace}
						showTextFlag
					/>
				</Block>
			)}
			{aiButtonFlag && (
				<Block>
					<AIAssistantButton fieldId={fieldId} marginLeft={theme.smallSpace} />
				</Block>
			)}
		</Block>
	) : null;
};

HeaderRight.propTypes = propTypes;
export default HeaderRight;
