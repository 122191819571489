import { useOldQueryStore } from "@clearpoint/old-query-store";
import { getLinkChartId, getElementId, getElementParentId, getAncestryId } from "../utils/idHandler";
import { useOldSession } from "@clearpoint/old-session/index";
import { useCallback } from "react";

let useFormatLinkData = (allowLoadingChildrenFlag) => {
	let { get, getPromise } = useOldQueryStore();
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let { session } = useOldSession();
	let { periodId } = session;
	return useCallback(
		async (link, _i, linkListRefCurrent) => {
			if (!link) return null;
			let { object, objectId, statusId } = link;

			let linkParent;
			let linkParentId = getElementParentId(link);
			if (linkParentId) {
				linkParent = linkListRefCurrent.find((x) => getElementId(x)?.toString() === linkParentId.toString());
			}
			let parentId = link.subParentId || (linkParent ? getLinkChartId(linkParent) : getElementParentId(link));

			let formattedLink = {
				...link,
				_expanded: link._expanded,
				ancestryId: link.ancestryId || getAncestryId(linkParent, link),
				parentId: !link.rootFlag ? parentId : null,
				statusColor: link.statusColor || (statusId && statusList.find((x) => x.statusId === statusId)?.statusColor),
			};
			formattedLink.id = link.id || getLinkChartId(formattedLink);

			let linkStatus = null;
			if (allowLoadingChildrenFlag && objectId && periodId && !link.linkedElements) {
				linkStatus = await getPromise({ parent: object, parentId: objectId, object: "status", periodId }).then(
					(x) => x
				);
			}
			let linkedElementFlag = link.linkedElements || (linkStatus ? linkStatus.linkedElements : false);

			return { ...formattedLink, linkedElementFlag };
		},
		[allowLoadingChildrenFlag, getPromise, periodId, statusList]
	);
};

export default useFormatLinkData;
