import Button from "./Button";
import Hide from "../Hide";
import Icon from "../Icon/Icon";
import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldSession } from "@clearpoint/old-session/index";
import { useUnmount } from "react-use";
import { theme } from "../Theme";
import { DISCUSSION_BAR } from "@clearpoint/utils";
import { useCheckFeature } from "@clearpoint/hooks";

let DiscussionButton = () => {
	let checkFeature = useCheckFeature();
	let discussionBarEnabledFlag = checkFeature(DISCUSSION_BAR);
	let { setSession } = useOldSession();
	let { discussionVisible, setLocalState } = useLocalState();
	let toggleDiscussion = useCallback(() => {
		setSession({ hideSupportWidgetFlag: !discussionVisible });
		setLocalState({
			discussionVisible: !discussionVisible,
		});
	}, [discussionVisible, setLocalState, setSession]);
	useUnmount(() => {
		setSession({ hideSupportWidgetFlag: false });
	});
	return (
		<Hide marginLeft={theme.smallSpace} visible={discussionBarEnabledFlag}>
			<Button color="white" onClick={toggleDiscussion}>
				<Icon name="comment" color="success" />
			</Button>
		</Hide>
	);
};

export default DiscussionButton;
