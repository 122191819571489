import Block from "@clearpoint/old-theme/Block";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { useQueryParameters } from "@clearpoint/hooks";


let propTypes = {
	stylePreviewFlag: PropTypes.bool,
	zoom: PropTypes.number,
};
let Watermark = ({ stylePreviewFlag, zoom }) => {
	let { get } = useOldQueryStore();
	let { reportId } = useQueryParameters();
	let report = get({ object: "report", objectId: reportId });
	let watermark = report?.watermark;
	return watermark ? (
		<Block
			paddingLeft={stylePreviewFlag && "50px"}
			paddingRight={stylePreviewFlag && "50px"}
			fontSize={(zoom ? Math.round(100 / zoom) : 100) + "px"}
			id="briefing_book_watermark"
		>
			{watermark}
		</Block>
	) : null;
};
Watermark.propTypes = propTypes;

export default Watermark;
