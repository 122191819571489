import ChartPreview from "@clearpoint/old-theme/Chart/Chart.Preview";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { emptyObject } from "@clearpoint/utils";

let propTypes = {
	chart: PropTypes.shape({
		chartId: PropTypes.number,
		measureId: PropTypes.number,
	}),
};

let MeasureChart = ({ chart }) => {
	let periodId = useFormValue("updates.periodId");
	let { chartId, measureId } = chart || emptyObject;

	return chartId && <ChartPreview chartId={chartId} measureId={measureId} periodId={periodId} />;
};

MeasureChart.propTypes = propTypes;
export default MeasureChart;
