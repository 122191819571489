import Block from "@clearpoint/old-theme/Block";
import Hide from "@clearpoint/old-theme/Hide";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import LinkButton from "@clearpoint/old-theme/Button/Button.Link";
import SelectReportModal from "./SelectReportModal";
import { useCallback, useState } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyArray } from "@clearpoint/utils";
import { useCheckAccess } from "@clearpoint/hooks";



let propTypes = {
	editPodFlag: PropTypes.bool,
	nameInForm: PropTypes.string,
};

let HeaderButtons = ({ editPodFlag, nameInForm }) => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { pagePeriodId } = useLocalState();
	let { access, object, scorecardId } = useFormValue();

	let [modalVisible, setModalVisbile] = useState(false);
	let openModal = useCallback(() => setModalVisbile(true), []);
	let closeModal = useCallback(() => setModalVisbile(false), []);

	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });
	let adminAccessFlag = checkAccess({ access, action: "admin", scorecardId });
	let showSelectReportButtonFlag = object === "scorecard" ? adminAccessFlag : editAccessFlag;

	let { value } = useFormValue(nameInForm);
	let [layoutScorecardId, layoutObject, layoutId] = value?.split(",") || emptyArray;
	let showViewPageButtonFlag = !!(layoutScorecardId && layoutObject && layoutId);

	return editPodFlag ? (
		<>
			<Block>
				<Hide visible={showSelectReportButtonFlag} display="inline">
					<IconButton onClick={openModal} name="editReport" iconTransform="" padding="1px 5px">
						{" " + translate("layout.selectLayout")}
					</IconButton>
				</Hide>
				<Hide visible={showViewPageButtonFlag} display="inline" marginLeft={theme.tinySpace}>
					<LinkButton
						layoutId={+layoutId}
						object={layoutObject}
						scorecardId={+layoutScorecardId}
						periodId={pagePeriodId}
						target="_self"
						text={" " + translate("notifications.viewPage")}
					/>
				</Hide>
			</Block>
			{modalVisible && <SelectReportModal modalVisible close={closeModal} name={nameInForm} />}
		</>
	) : null;
};

HeaderButtons.propTypes = propTypes;
export default HeaderButtons;
