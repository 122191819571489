import Icon from "../../Icon/Icon";
import ModalConfirm from "../../Modal/Modal.Confirm";
import Tooltip from "../../Tooltip";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useBooleanState } from "@clearpoint/hooks";


let propTypes = {
	index: PropTypes.number.isRequired,
	restartChatFromMessage: PropTypes.func.isRequired,
};

let RestartButton = ({ index, restartChatFromMessage }) => {
	let translate = useTranslate();
	let restartChat = useCallback(() => {
		restartChatFromMessage(index);
	}, [restartChatFromMessage, index]);

	let { value: confirmModalVisible, setValueFalse: closeModal, setValueTrue: openModal } = useBooleanState(false);

	return (
		<>
			<Tooltip placement="top" text={translate("ai.assistant.restartChat")}>
				<Icon name="restartChat" color="primary" onClick={openModal} />
			</Tooltip>
			<ModalConfirm
				title={translate("ai.assistant.resubmitResponse")}
				body={translate("ai.assistant.restartConfirm")}
				confirmButtonText={translate("global.ok")}
				confirmButtonColor="info"
				onConfirm={restartChat}
				close={closeModal}
				modalVisible={confirmModalVisible}
			/>
		</>
	);
};

RestartButton.propTypes = propTypes;
export default RestartButton;
