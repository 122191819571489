import Handsontable from "handsontable";
import { useCallback } from "react";
import useGridLockedFlag from "./useGridLockedFlag";
import { useSeriesGridData } from "./Provider";
import { formatMoney } from "accounting-js";
import { emptyObject } from "@clearpoint/utils";

let useRenderFunction = () => {
	let { displayPeriodId, seriesData, seriesList, statusLockedFlag } = useSeriesGridData();
	let gridLockedFlag = useGridLockedFlag();

	let checkCellLocked = useCallback(
		({ column, row, series }) => {
			let { periodLocked: periodLockedFlag } = seriesData?.[row] || emptyObject;
			if (column.includes("period")) {
				return !gridLockedFlag;
			}
			if (column.includes("status")) {
				return !gridLockedFlag && (periodLockedFlag || statusLockedFlag);
			}
			let { lockedSeries: lockedSeriesFlag } = series || emptyObject;
			return !gridLockedFlag && (periodLockedFlag || lockedSeriesFlag);
		},
		[gridLockedFlag, seriesData, statusLockedFlag]
	);

	return useCallback(
		(_instance, td, row, _col, _prop, value, cellProperties) => {
			let column = cellProperties.data;
			let series = seriesList.find((x) => x.seriesData === column);
			let cellLockedFlag = checkCellLocked({ column, row, series });

			let currentPeriodFlag = seriesData[row]?.periodId === displayPeriodId;
			if (cellLockedFlag) {
				cellProperties.readOnly = true;
				td.classList.add("series-locked");
			}
			if (currentPeriodFlag) td.classList.add("current-period");
			td.innerHTML = value == null ? "" : Handsontable.helper.stringify(value);
			if (series && (value || value === 0) && !isNaN(value) && series.seriesDataTypeId !== 11) {
				td.innerHTML = formatMoney(value, series.numberFormat);
				td.classList.add("htRight");
			}
		},
		[checkCellLocked, displayPeriodId, seriesData, seriesList]
	);
};

export default useRenderFunction;
