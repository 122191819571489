import Block from "@clearpoint/old-theme/Block";
import DetailForm from "./DetailForm";
import LayoutForm from "./LayoutForm";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	periodId: PropTypes.number,
};

let FormWrapper = ({ children, periodId }) => {
	let { editLayoutFlag } = useLocalState();

	let Form = editLayoutFlag ? LayoutForm : DetailForm;

	return (
		<Block height="100%">
			<StyleWrapper height="100%">
				<Form periodId={periodId}>{children}</Form>
			</StyleWrapper>
		</Block>
	);
};

FormWrapper.propTypes = propTypes;
export default FormWrapper;
