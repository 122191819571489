import Badge from "@clearpoint/old-theme/Badge/Badge";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	series: PropTypes.shape({
		peerDistrict: PropTypes.string,
		seriesType: PropTypes.oneOf(["aggregate", "calculated", "peer", "reference", "status"]),
	}),
};

let SeriesBadge = ({ series }) => {
	let translate = useTranslate();
	let { peerDistrict, seriesType } = series;
	let badgeColor = seriesType === "peer" ? "success" : "primary";

	let textLookup = useMemo(
		() => ({
			aggregate: translate("edit.element.aggregate"),
			calculated: translate("edit.calculated"),
			peer: peerDistrict,
			reference: translate("edit.element.reference"),
			status: translate("edit.element.status"),
		}),
		[peerDistrict, translate]
	);

	return <Badge color={badgeColor}>{textLookup[seriesType]}</Badge>;
};

SeriesBadge.propTypes = propTypes;
export default SeriesBadge;
