import Radio from "./Form.Radio";
import RadioGroup from "./Form.RadioGroup";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	boldFlag: PropTypes.bool,
	defaultValue: PropTypes.bool,
	falseText: PropTypes.string,
	trueText: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
};

let RadioBoolean = ({ boldFlag, defaultValue, falseText, trueText, label, name }) => {
	let translate = useTranslate();
	return (
		<RadioGroup name={name} label={label} boldFlag={boldFlag} defaultValue={defaultValue}>
			<Radio value={true} text={trueText || translate("notifications.compare.true")} />
			<Radio value={false} text={falseText || translate("notifications.compare.false")} />
		</RadioGroup>
	);
};

RadioBoolean.propTypes = propTypes;

export default RadioBoolean;
