import Loading from "../../../Loading/index";
import StyleWrapper from "../../../StyleWrapper";
import UserImageAndName from "../../../Form/shared/UserImageAndName";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	name: PropTypes.string,
	noInitialsFlag: PropTypes.bool,
};
let FormFieldDisplayCollaborators = ({ name, noInitialsFlag }) => {
	let { get, isLoading } = useOldQueryStore();
	let collaboratorIdList = useFormValue(name);
	let userList = get({ object: "user" });
	let collaboratorList = userList?.filter((x) => collaboratorIdList?.includes(x.userId));
	if (isLoading({ object: "user" })) return <Loading />;
	return collaboratorList
		? collaboratorList.map((collaborator) => (
				<StyleWrapper key={collaborator.userId} marginBottom={theme.tinySpace}>
					{noInitialsFlag ? (
						collaborator.fullName
					) : (
						<UserImageAndName user={collaborator} className="collaborator-list-item" />
					)}
				</StyleWrapper>
		  ))
		: null;
};
FormFieldDisplayCollaborators.propTypes = propTypes;

export default FormFieldDisplayCollaborators;
