import Alert from "../../Alert/Alert";
import Block from "../../Block";
import PageTitle from "../../PageTitle/PageTitle";
import SelectStatus from "../../Form/Select/Select.Status";
import StatusIcon from "../../StatusIcon";
import useFormValue from "../../Form/hooks/useFormValue";
import { useLocation } from "react-router-dom";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../Theme";

let propTypes = {
	name: PropTypes.string,
	object: PropTypes.string,
	parentName: PropTypes.string,
};
let FormFieldStatusId = ({ name, object, parentName, ...props }) => {
	let { pathname } = useLocation();
	let translate = useTranslate();
	let { session } = useOldSession();
	let statusId = useFormValue(name);
	let objectName = useFormValue("name");
	let automaticEvaluationFlag = useFormValue(parentName ? `${parentName}.autoEvaluation` : "autoEvaluation");
	let periodId = useFormValue(parentName ? `${parentName}.periodId` : "periodId");
	let period = useFormValue(parentName ? `${parentName}.period` : "period");
	let periodLocked = useFormValue(parentName ? `${parentName}.periodLocked` : "periodLocked");
	let myScorecardPageFlag = period && pathname === "/index/my-scorecard";
	let renderOffsetPeriodAlertFlag =
		myScorecardPageFlag || (!periodLocked && periodId && periodId !== session.periodId);
	return (
		<>
			{automaticEvaluationFlag ? (
				<Block display="flex" alignItems="flex-start" marginBottom={theme.smallSpace}>
					<StatusIcon statusId={statusId} />
					<PageTitle
						title={objectName}
						subTitle={automaticEvaluationFlag && translate("edit.elementAutoEvaluated")}
					/>
				</Block>
			) : (
				<SelectStatus data-testid="statusId" defaultValue={0} name={name} object={object} {...props} />
			)}
			{renderOffsetPeriodAlertFlag && (
				<Alert color="primary">{translate("edit.element.offsetPeriod", { period })}</Alert>
			)}
		</>
	);
};
FormFieldStatusId.propTypes = propTypes;
export default FormFieldStatusId;
