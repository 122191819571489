import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import isEmpty from "lodash/isEmpty";

export default function useScorecardName(props) {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let { object, objectId, scorecardId } = props || {};
	if (object && objectId) {
		let objectData = get({ object, objectId });
		if (!objectData) return "";
		scorecardId = objectData.scorecardId;
	}
	if (!props || isEmpty(props)) scorecardId = session.scorecardId;
	if (!scorecardId) return "";
	let scorecardData = scorecardId && get({ object: "scorecard" });
	if (!scorecardData) return "";
	scorecardData = scorecardData.find((x) => x.scorecardId === scorecardId);
	return scorecardData ? scorecardData.name : "";
}
