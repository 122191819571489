let isNotValidJson = (json) => {
	if (json?.length > 0) {
		try {
			JSON.parse(json);
			return false;
		} catch (e) {
			return true;
		}
	}
	return true;
};
export default isNotValidJson;
