import DropdownSplitButtonFavorite from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Favorite";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { buildQueryString, emptyObject } from "@clearpoint/utils";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let FavoriteSplitButton = () => {
	let { get } = useOldQueryStore();
	let { layout } = useLocalState();
	let { layoutId, object, scorecardId } = layout || {};
	let { favorite: favoriteFlag } =
		(object && layoutId && get({ object: "favorite", queryString: buildQueryString({ layoutId, object }) })) ||
		emptyObject;
	return (
		<StyleWrapper marginLeft={theme.smallSpace}>
			<DropdownSplitButtonFavorite
				favoriteFlag={favoriteFlag}
				object={object}
				layoutId={layoutId}
				scorecardId={scorecardId}
			/>
		</StyleWrapper>
	);
};
export default FavoriteSplitButton;
