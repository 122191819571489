import { useMemo } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import ProfileImage from "../ProfileImage";
import Tooltip from "../Tooltip";

let propTypes = {
	userId: PropTypes.number,
};

let UserBadge = ({ userId, showName }) => {
	let { get } = useOldQueryStore();
	let translate = useTranslate();
	let profile = get({ object: "profile" });
	let currentUserId = profile?.userId;
	let users = get({ object: "user" });
	let user = useMemo(() => {
		if (isArray(users) && currentUserId !== userId) {
			return users.find((x) => x.userId === userId);
		}
		return null;
	}, [currentUserId, userId, users]);

	const badgeUser = !!userId && userId === currentUserId ? user || profile : user;

	return badgeUser && showName ? (
		<Block display="flex">
			<ProfileImage user={badgeUser} size="tiny" />
			<Block marginLeft="10px" marginTop="4px">
			{badgeUser.fullName}
			</Block>
		</Block>
	) : (badgeUser ? (
		<Tooltip placement="top" text={translate(`${badgeUser.fullName}`)}>
			<ProfileImage user={badgeUser} size="tiny" />
		</Tooltip>
	) : null);
};

UserBadge.propTypes = propTypes;

export default UserBadge;
