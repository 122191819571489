import Block from "../../../Block";
import Files from "react-butterfiles";
import FormError from "../../../Form/Form.Error";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormError from "../../../Form/hooks/useFormError";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import http from "@clearpoint/services/httpService/index";
import { toast } from "@clearpoint/services/toastService/index";
import { theme } from "../../../Theme";
import { useId, usePrevious } from "@clearpoint/hooks";


let propTypes = {
	children: PropTypes.node,
	customFieldFlag: PropTypes.bool,
	errorMessage: PropTypes.string,
	height: PropTypes.string,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	setUploadProgress: PropTypes.func,
};

let DropZone = ({ children, customFieldFlag, errorMessage, height, name, required, setUploadProgress }) => {
	let { setFormValue, setFormValidation } = useFormContext();
	let translate = useTranslate();
	let id = useId();
	let { clear } = useOldQueryStore();
	let errorVisible = !!useFormError(name);
	let previousRequired = usePrevious(required);

	useEffect(() => {
		if (required !== previousRequired)
			setFormValidation(name, {
				_validation: {
					required,
				},
			});
	}, [name, previousRequired, required, setFormValidation]);

	let config = useMemo(
		() => ({
			onUploadProgress: (progressEvent) => {
				let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setUploadProgress(percentCompleted);
			},
		}),
		[setUploadProgress]
	);

	let onSuccess = useCallback(
		(files) => {
			let file = files[0].src.file;
			setUploadProgress(0);
			let formData = new FormData();
			formData.append("file", file);

			let reader = new FileReader();
			reader.onload = (e) => {
				setFormValue("_localImage", {
					displayValue: e.target.result,
					name: file.name,
					value: e.target.result,
				});
			};
			reader.readAsDataURL(file);

			http
				.post("/images", formData, config)
				.then((res) => {
					setUploadProgress(null);
					setFormValue(
						name,
						customFieldFlag
							? {
									dirty: true,
									displayValue: res[0].url,
									master: res[0].title,
									value: res[0].url,
							  }
							: {
									displayValue: res[0].url,
									name: res[0].title,
									value: res[0].url,
							  }
					);

					clear({ object: "image" });
					toast.success(translate("components.uploadComplete"));
				})
				.catch((e) => {
					setUploadProgress(null);
					throw new Error(e);
				});
		},
		[clear, config, customFieldFlag, name, setFormValue, setUploadProgress, translate]
	);

	let onError = useCallback(
		(errors) => {
			setUploadProgress(null);
			let type = errors[0].type;
			let errorMessageLookup = {
				maxSizeExceeded: "toaster.messages.global.imageLimitSize",
			};
			toast.error(translate(errorMessageLookup[type] || "global.error"));
		},
		[setUploadProgress, translate]
	);

	let acceptedFileTypes = useMemo(() => ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/svg"], []);

	return (
		<>
			<Files
				id={`image-uploader-${id}`}
				name={name}
				multiple={false}
				maxSize="5mb"
				accept={acceptedFileTypes}
				onSuccess={onSuccess}
				onError={onError}
			>
				{({ browseFiles, getDropZoneProps }) => (
					<Block
						onClick={browseFiles}
						background="#f8f8f8"
						border="2px dashed #ddd"
						height={height}
						textAlign="center"
						display="grid"
						placeItems="center"
						padding={theme.smallSpace}
						cursor="pointer"
						userSelect="none"
						borderColor={errorVisible ? theme.danger : undefined}
						$style={`&:focus {
						border-color: ${errorVisible ? theme.danger : undefined};
					`}
						{...getDropZoneProps({ className: "imgdropzone" })}
					>
						{children}
					</Block>
				)}
			</Files>
			{errorVisible && <FormError name={name} errorMessage={errorMessage} />}
		</>
	);
};

DropZone.propTypes = propTypes;

export default DropZone;
