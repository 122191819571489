import ElementTab from "./ElementTab";
import FormModal from "../../../Form/Modal/Form.Modal";
import Loading from "../../../Loading";
import Tabs from "../../../Tabs";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "@clearpoint/services/toastService/index";

let propTypes = {
	close: PropTypes.func,
	modalVisible: PropTypes.bool,
	name: PropTypes.string,
};
let FieldListModal = ({ close, modalVisible, name, onSave: onSaveProp }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { setFormValue } = useFormContext();
	let { session } = useOldSession();
	let { object, scorecardId } = session;
	let fieldList = useFormValue(name);
	let _fieldList = useFormValue("_fieldList");
	let customFields = get({ object: "customField" });
	let gridCustomFieldIds = useMemo(
		() =>
			customFields &&
			customFields
				.reduce((fieldList, object) => fieldList.concat(object.fields), [])
				.filter((field) => field.customFieldType === "grid")
				.map((field) => `custom${field.customFieldId}`),
		[customFields]
	);
	let menu = scorecardId && get({ object: "menu", scorecardId });
	let objectList = useMemo(() => {
		if (!menu) return;
		let menuObjectList = menu.map((x) => x.object).filter((x) => x !== "map");
		let initiativeIndex = menuObjectList.indexOf("initiative");
		if (initiativeIndex > -1) {
			return [
				...menuObjectList.slice(0, initiativeIndex + 1),
				"milestone",
				...menuObjectList.slice(initiativeIndex + 1),
			];
		}
		return menuObjectList;
	}, [menu]);
	let atLeastOneColumnFlag = _fieldList?.some((x) => x.selected);
	let gridColumnList =
		scorecardId &&
		get({
			object: "gridColumn",
			queryString: `scorecardId=${scorecardId}`,
		});
	let initialFieldList = useMemo(() => {
		if (!gridColumnList || !objectList) return;
		let initialFieldList = [...gridColumnList]
			.sort((a, b) => objectList.indexOf(a.object) - objectList.indexOf(b.object))
			.reduce((initialFieldList, tabData) => {
				let { object: tabObject, fields } = tabData;
				return [
					...initialFieldList,
					...fields
						.filter((field) => (tabObject === object ? field : field.object === field.parent))
						.filter((field) => !gridCustomFieldIds?.includes(field.fieldId))
						.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
						.map((x) => ({
							...x,
							tabObject,
							selected: fieldList?.some((y) => y.object === x.object && y.fieldId === x.fieldId),
						})),
				];
			}, []);
		return initialFieldList.map((x, i) => ({ ...x, index: i }));
	}, [fieldList, gridColumnList, gridCustomFieldIds, object, objectList]);
	useEffect(() => {
		if (initialFieldList?.length !== _fieldList?.length) {
			setFormValue("_fieldList", initialFieldList);
		}
	}, [_fieldList, initialFieldList, object, setFormValue]);
	let onSave = useCallback(() => {
		let fieldListCopy = cloneDeep(fieldList || _fieldList);
		for (let field of _fieldList) {
			let { object, fieldId, selected } = field;
			let previousField = fieldListCopy?.find((x) => x.object === object && x.fieldId === fieldId);
			if (selected && !previousField) {
				fieldListCopy = [...fieldListCopy, field];
			} else if (!selected && previousField) {
				let index = fieldListCopy?.indexOf(previousField);
				fieldListCopy = [...fieldListCopy.slice(0, index), ...fieldListCopy.slice(index + 1)];
			}
		}
		setFormValue(name, fieldListCopy);
		setFormValue("_fieldList", undefined);
		if (onSaveProp) onSaveProp();
	}, [_fieldList, fieldList, name, onSaveProp, setFormValue]);
	let loadingFlag = !_fieldList || initialFieldList?.length !== _fieldList?.length;
	if (!!_fieldList && !atLeastOneColumnFlag) {
		toast.warning(translate("layout.atLeastOneColumn"));
	}
	return (
		<FormModal
			close={close}
			closeButtonText={translate("global.save")}
			height="75%"
			modalVisible={modalVisible}
			name="_fieldList"
			onSave={onSave}
			title={translate("layout.selectFields")}
			size="huge"
			valid={atLeastOneColumnFlag}
		>
			<Tabs defaultActiveKey={objectList?.indexOf(object)}>
				{objectList?.map((object) =>
					loadingFlag ? (
						<Loading key={object} title={translate(object)} />
					) : (
						<ElementTab
							key={object}
							name={name}
							title={translate(object)}
							object={object}
							objectList={objectList}
						/>
					)
				)}
			</Tabs>
		</FormModal>
	);
};
FieldListModal.propTypes = propTypes;

let FieldListModalWrapper = (props) => (props.modalVisible ? <FieldListModal {...props} /> : null);

export default FieldListModalWrapper;
