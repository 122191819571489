import { useCallback } from "react";
import { useFormContext } from "../Form";
import useFormValue from "./useFormValue";
import insertText from "./useInsertText.insertText";
import { emptyObject } from "@clearpoint/utils";

export default function useInsertText({ name, query, value, setValue }) {
	let formValue = useFormValue(name);
	let formContext = useFormContext();
	let { formTouched } = formContext || emptyObject;
	if (name) {
		let setFormValue = formContext?.setFormValue;
		setValue = (value) => setFormValue(name, value);
		value = formValue;
	}
	let focused = document.activeElement === document.querySelector(query);
	return useCallback(
		(text) => {
			let insertTextCallback = insertText({
				focused,
				formTouched,
				name,
				query,
				setValue,
				value,
				input: document.querySelector(query),
			});
			return insertTextCallback(text);
		},
		[focused, formTouched, name, query, setValue, value]
	);
}
