import { formatNumber } from "@clearpoint/utils";

let formatSeriesNumber = (value, format, translate) => {
	if (value === null || value === undefined) {
		return translate ? translate("chart.noValue") : "";
	} else {
		return formatNumber({ number: value, numberFormat: format });
	}
};

export default formatSeriesNumber;
