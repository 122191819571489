import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let useTemplateLookup = ({ fieldId, outerFormObject }) => {
	let translate = useTranslate();

	return useMemo(() => {
		let lookup = {
			askMeAnything: {
				description: translate("ai.assistant.analysis.askMeAnything.description"),
				endpoint: "/ai/text/ama",
				icon: "commentsQuestion",
				name: translate("ai.assistant.analysis.askMeAnything"),
			},
			analyze: {
				description: translate("ai.assistant.analysis.analyze.description"),
				endpoint: "/ai/text/analyze",
				icon: "chartUser",
				name: translate("ai.assistant.analysis.analyze"),
			},
			copyEdit: {
				description: translate("ai.assistant.analysis.copyEdit.description"),
				endpoint: "/ai/text/copyedit",
				icon: "editReport",
				name: translate("ai.assistant.analysis.copyEdit"),
			},
			exceptionReport: {
				description: translate("ai.assistant.analysis.exceptionReport.description"),
				endpoint: "/ai/text/exceptionReport",
				icon: "exceptionReport",
				name: translate("ai.assistant.analysis.exceptionReport"),
			},
			recommendEngine: {
				description: translate("ai.assistant.analysis.recommendEngine.description"),
				endpoint: "/ai/text/recommend",
				icon: "recommendEngine",
				name: translate("ai.assistant.analysis.recommendEngine"),
			},
			summarize: {
				description:
					outerFormObject === "scorecard"
						? translate("ai.assistant.analysis.summarize.description.scorecard")
						: translate("ai.assistant.analysis.summarize.description", {
								field: translate(`defaultFields.update.${outerFormObject}.${fieldId}`)?.toLowerCase() || "",
						  }),
				endpoint: outerFormObject === "scorecard" ? "/ai/text/scorecardReport" : "/ai/text/summarize",
				icon: "summarize",
				name: translate("ai.assistant.analysis.summarize"),
			},
		};
		for (let [key, value] of Object.entries(lookup)) {
			value.stateKey = key;
		}

		return lookup;
	}, [fieldId, outerFormObject, translate]);
};

export default useTemplateLookup;
