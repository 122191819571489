import { emptyArray } from "@clearpoint/utils";

import createUrl from "./Store.createUrl";
import generateRetry from "./Store.generateRetry";


let generateSetFunction =
	({ clear, clearAssociated, http }) =>
	({ data, method, skipClearFlag, stopPropagationFlag, ...id }) => {
		if (typeof data !== "object" || !id.object) {
			throw new Error("Invalid call to store: set. " + JSON.stringify(id, data));
		}
		let responseData;
		let { object, periodId, scorecardId, queryString, parent, parentId, url } = id;
		let clearObjectList = [object];

		if (object === "update") {
			let { edits, updates } = data;
			let getObjects = (x) => x?.map?.((x) => x.object) || emptyArray;
			clearObjectList = [...clearObjectList, ...new Set([...getObjects(edits), ...getObjects(updates)])];
		}

		let objectId = id.objectId || id.layoutId || id[id.object + "Id"];
		if (!url) url = createUrl({ object, objectId, scorecardId, parent, parentId, periodId, queryString });

		// post or put
		let httpMethod = periodId ? "put" : method || "post";
		if (objectId) httpMethod = method || "put";
		let retry = generateRetry();
		let makeRequest = () =>
			http[httpMethod](url, data)
				.then((response) => {
					if (!skipClearFlag) {
						if (objectId) {
							clear({ object, objectId, stopPropagationFlag });
						} else {
							clear({ object, ListOnlyFlag: !periodId, stopPropagationFlag });
						}
					}
					return response;
				})
				.catch(async (error) => {
					let retryRequest = retry({
						data,
						error,
						makeRequest,
						url,
					});
					return retryRequest;
				});
		responseData = makeRequest();
		if (!skipClearFlag) {
			for (const x of clearObjectList) clearAssociated(x);
		}
		return responseData;
	};

export default generateSetFunction;
