import BootstrapBadge from "react-bootstrap/Badge";
import StyleWrapper from "../StyleWrapper";
import PropTypes from "prop-types";
import sizeLookup from "./sizeLookup";
import { theme } from "../Theme";

let colorList = ["danger", "darkTeal", "default", "info", "neutral", "primary", "success", "warning", "white"];

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.string,
	"data-testid": PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	display: PropTypes.oneOf(["inline", "inline-block"]),
	marginLeft: PropTypes.string,
	marginRight: PropTypes.string,
	size: PropTypes.oneOf(["podHeader", "tiny", "small", "medium"]),
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let defaultProps = {
	color: "primary",
	display: "inline-block",
	size: "medium",
	marginRight: "2px",
};

let Badge = ({
	children,
	className,
	color,
	"data-testid": dataTestId,
	display,
	marginLeft,
	marginRight,
	size,
	style,
}) => {
	let fontSize = sizeLookup[size]?.fontSize;
	let padding = sizeLookup[size]?.padding;
	return (
		<StyleWrapper
			backgroundColor={theme.white}
			border={`1px solid ${theme[color] || color}`}
			borderLeft={`4px solid ${theme[color] || color} !important`}
			borderRadius={0}
			color={theme[color] || color}
			cursor="default"
			display={display}
			fontWeight={500}
			fontSize={fontSize}
			marginLeft={marginLeft}
			marginRight={marginRight}
			padding={padding}
			$style={`& svg { vertical-align: middle; }`}
		>
			<BootstrapBadge
				className={className}
				data-testid={dataTestId}
				style={style}
				variant={colorList.includes(color) ? color : undefined}
			>
				{children}
			</BootstrapBadge>
		</StyleWrapper>
	);
};

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;