let generateTranslateFunction = (languageData) => (string, interpolation) => {
	string = string && Object.prototype.hasOwnProperty(string) ? string : languageData[string] || string;
	let a, b;
	if (string) {
		while ((a = string.indexOf("{{")) !== -1 && (b = string.indexOf("}}")) !== -1) {
			let key = string.slice(a + 2, b);
			let interpolatedValue = interpolation?.[key];
			interpolatedValue = typeof interpolatedValue === "number" ? interpolatedValue.toString() : interpolatedValue; // fix for zero
			let replacement = languageData[interpolatedValue] || interpolatedValue || languageData[key] || undefined;
			if (replacement === undefined) {
				//console.debug('[Translator] Replacement key "' + key + '" not found');
				string = string.slice(0, a) + string.slice(b + 2);
			} else {
				string = string.slice(0, a) + replacement + string.slice(b + 2);
			}
		}
	}
	try {
		if (global.testFlag) {
			while (string?.includes?.("  ")) {
				string = string?.replace?.("  ", " ");
			}
		}
	} catch (e) {}
	return string || "";
};

export default generateTranslateFunction;
