import SelectMilestone from "../../Form/Select/Select.Milestone";
import SelectScorecard from "../../Form/Select/Select.Scorecard";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	object: PropTypes.string,
};
let ParentId = ({ object, ...props }) => {
	let translate = useTranslate();
	let parentLookup = {
		milestone: SelectMilestone,
		scorecard: SelectScorecard,
	};
	let SelectParent = parentLookup[object];
	return SelectParent ? (
		<SelectParent
			data-testid="parent"
			excludeChildrenFlag
			excludeCurrentFlag
			label={`${translate("parent")} ${translate(object)}`}
			activeOnlyFlag
			allowNotDefinedFlag
			notDefinedValue={-1}
			{...props}
		/>
	) : null;
};
ParentId.propTypes = propTypes;
export default ParentId;
