import DragAndDropTableCell from "@clearpoint/old-theme/DragAndDropTable/DragAndDropCell";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useMemoOnce } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};
let HeaderCell = ({ name }) => {
	let translate = useTranslate();
	let field = useFormValue(name);
	let { label, name: fieldName } = field;
	let defaultLabel = useMemoOnce(() => translate(label || fieldName));
	return (
		<DragAndDropTableCell className="field-column-header">
			<FormInput
				label={translate("tabGridColumns.columnHeader")}
				marginBottom={theme.tinySpace}
				name={`${name}.customLabel`}
				placeholder={defaultLabel}
				uppercaseLabelFlag
			/>
		</DragAndDropTableCell>
	);
};
HeaderCell.propTypes = propTypes;
export default HeaderCell;
