import { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import Alert from "@clearpoint/old-theme/Alert/Alert";
import FormDragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";
import Loading from "@clearpoint/old-theme/Loading/index";
import NoResults from "@clearpoint/old-theme/NoResults";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray } from "@clearpoint/utils";

import getCheckDropValid from "./getCheckDropValid";
import MilestoneFilter from "./MilestoneFilter";
import MilestoneListItem from "./MilestoneListItem";
import moveChildMilestonesWithParentMilestone from "./moveChildMilestonesWithParentMilestone";
import { useOldSession } from "@clearpoint/old-session/index";
import ChecklistProvider from "@clearpoint/providers/ChecklistProvider";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	apiMilestoneList: PropTypes.array,
};

let MilestonesTabContent = ({ apiMilestoneList }) => {
	let translate = useTranslate();
	let { data: filteredMilestoneList, setData } = useFilter();
	let { milestoneCustomSortEnabled } = useFormValue();
	let milestoneListRef = useRef(filteredMilestoneList);

	milestoneListRef.current = filteredMilestoneList;

	let checkDropValid = useCallback(
		({ addedIndex, removedIndex }) => {
			let checkDropValidFunction = getCheckDropValid({ milestoneList: milestoneListRef.current, translate });
			return checkDropValidFunction({ addedIndex, removedIndex });
		},
		[translate]
	);

	return (
		<ChecklistProvider data={filteredMilestoneList.filter((x) => x.visible)}>
			<Help section="sect4" feature="milestones" />
			<MilestoneFilter apiMilestoneList={apiMilestoneList} />
			<NoResults results={filteredMilestoneList} />
			{milestoneCustomSortEnabled && (
				<Alert color="info">{translate("detailLayouts.initiative.milestoneCustomSortEnabled")}</Alert>
			)}
			<FormDragAndDropList
				allowEmptyListFlag
				className="drag-drop-list-wrapper"
				dragClass="reduced-opacity-drag-item"
				list={filteredMilestoneList}
				listKey="objectId"
				marginTop="0"
				name="milestoneSortOrder"
				onDrop={checkDropValid}
				reorderList={moveChildMilestonesWithParentMilestone}
				setList={setData}
			>
				{filteredMilestoneList.map((x) => (
					<MilestoneListItem key={x.objectId} milestone={x} />
				))}
			</FormDragAndDropList>
		</ChecklistProvider>
	);
};

MilestonesTabContent.propTypes = propTypes;

let MilestonesTab = () => {
	let { objectId } = useFormValue();
	let { get } = useOldQueryStore();
	let {
		session: { periodId },
	} = useOldSession();

	let milestoneList =
		objectId &&
		periodId &&
		get({
			parent: "initiative",
			parentId: objectId,
			object: "milestone",
			queryString: `?periodId=${periodId}`,
		});

	let loadingFlag = milestoneList === undefined;
	return loadingFlag ? (
		<Loading />
	) : (
		<FilterProvider data={milestoneList || emptyArray}>
			<MilestonesTabContent apiMilestoneList={milestoneList} />
		</FilterProvider>
	);
};

export default MilestonesTab;
