import { useMemo } from "react";
import PropTypes from "prop-types";

import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";

import useCalculatedColumnWidthList from "./useCalculatedColumnWidthList";
import { useOldSession } from "@clearpoint/old-session/index";

let columnPropTypes = {
	column: PropTypes.shape({
		minWidth: PropTypes.number,
		width: PropTypes.number,
	}),
};

let Column = ({ width }) => {
	let { excelFlag } = useOldSession().session;
	let columnStyle = useMemo(() => (excelFlag ? { width } : undefined), [excelFlag, width]);
	return (
		<StyleWrapper width={width}>
			<col style={columnStyle} />
		</StyleWrapper>
	);
};
Column.propTypes = columnPropTypes;

let columnGroupPropTypes = {
	columnWidthList: PropTypes.arrayOf(
		PropTypes.shape({
			minWidth: PropTypes.number,
			width: PropTypes.number,
		})
	),
	gridRef: PropTypes.object,
};

let ColumnGroup = ({ columnWidthList, gridRef }) => {
	let calculatedWidthList = useCalculatedColumnWidthList({ columnWidthList, gridRef });
	return (
		<colgroup>
			{calculatedWidthList?.map((x, i) => (
				<Column key={i} width={x} />
			))}
		</colgroup>
	);
};
ColumnGroup.propTypes = columnGroupPropTypes;

export default ColumnGroup;
