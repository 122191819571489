import { useLayoutEffect, useRef } from "react";

import Block from "@clearpoint/old-theme/Block";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import { theme } from "@clearpoint/old-theme/Theme";

import { useEffectOnce } from "@clearpoint/hooks";

import PageList from "./PageList";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let Detail = () => {
	let { object, objectId } = useDetailPageData();
	let { editLayoutFlag } = useLocalState();
	let { hardResetForm, resetForm, formFields } = useFormContext();

	// TODO: investigate why this is still needed after the changes added in https://github.com/ClearPoint-Strategy/cps-react/pull/629
	let initialLoadedFlag = useRef(false);

	useEffectOnce(() => {
		initialLoadedFlag.current = true;
	});

	// WARNING:  WTFK but this cannot be a useEffect();
	useLayoutEffect(() => {
		if (initialLoadedFlag.current) {
			hardResetForm();
		} else {
			//resetForm();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps 
	// not sure why, but adding resetForm to the dependency array breaks things
	}, [hardResetForm, object, objectId]);

	return (
		<Block className="detail-page-scrollable-container" marginTop={theme.space} height="100%" overflowY="auto">
			<Block
				overflowY="auto"
				overflowX="visible"
				padding={`0 30px 0 30px`}
				marginTop={editLayoutFlag ? "0" : `-${theme.mediumLargeSpace}`}
			>
				<PageList />
			</Block>
		</Block>
	);
};

export default Detail;
