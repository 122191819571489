import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import HideLevelFormSelect from "@clearpoint/old-theme/Form/Select/Select.HideLevel";
import PodStyleFormSelect from "@clearpoint/old-theme/Form/Select/Select.PodStyle";
import PodWidthSelect from "./PodWidthSelect";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";

let EditPodTab = () => {
	let translate = useTranslate();
	let { disableHideBorderOption: disableHideBorderFlag } = useFormValue();
	return (
		<>
			<PodWidthSelect name="podWidth" />
			<FormCheck name="hideBorder" marginBottom={theme.smallSpace} disabled={disableHideBorderFlag}>
				{translate("edit.removeBorders")}
			</FormCheck>
			<FormCheck name="hidePrint" marginBottom={theme.smallSpace}>
				{translate("edit.hidePrint")}
			</FormCheck>
			<FormCheck name="hideOnline" marginBottom={theme.smallSpace}>
				{translate("edit.hideOnline")}
			</FormCheck>
			<FormCheck name="hidePublish" marginBottom={theme.smallSpace}>
				{translate("edit.hidePublishing")}
			</FormCheck>
			<HideLevelFormSelect name="hideLevel" />
			<PodStyleFormSelect name="podStyleId" />
		</>
	);
};

export default EditPodTab;
