let insertText =
	({ focused, formTouched, name, input, setValue, value }) =>
	(text) => {
		let cursor = input.selectionStart ?? undefined;
		let newValue;
		if (!focused && !formTouched?.[name]) {
			cursor = value ? value.length : 0;
			newValue = ((value || "").trim() + (text !== ")" ? " " : "") + text + (text !== "(" ? " " : "")).trimStart();
			setValue(newValue);
			input.focus();
		} else {
			newValue = (
				(value || "").slice(0, cursor).trim() +
				(text !== ")" ? " " : "") +
				text +
				(text !== "(" ? " " : "") +
				(value || "").slice(cursor).trim()
			).trimStart();
			setValue(newValue);
			input.focus();
		}
		let cursorAddition = text.length + 2;
		if (text.includes("(")) {
			cursorAddition = text.indexOf("(") + 2;
		}
		let cursorSelectLength = 0;
		if (text.includes("(") && text.includes(",")) {
			cursorSelectLength = text.indexOf(",") - cursorAddition + 1;
		}
		if (newValue.length - 1 === text.length) {
			cursorAddition--;
		}
		setTimeout(() => {
			let newPosition = (value || "").slice(0, cursor).trim().length + cursorAddition;
			input.setSelectionRange(newPosition, newPosition + cursorSelectLength);
		});
	};

export default insertText;
