import DragAndDropTableCell from "@clearpoint/old-theme/DragAndDropTable/DragAndDropCell";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormSelectTableStyle from "@clearpoint/old-theme/Form/Select/Select.TableStyle";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";
import { useCheckFeature } from "@clearpoint/hooks";


let propTypes = {
	name: PropTypes.string,
};
let FieldColumn = ({ name }) => {
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let field = useFormValue(name);
	let { fieldId, object, type: fieldType } = field;
	let stylesFlag = checkFeature("customStyles");
	let statusFlag =
		(fieldId === "name" && !["scorecard", "measureSeries", "category"].includes(object)) ||
		(fieldType === "link" && !["category", "charts", "linkedCharts"].includes(fieldId));
	return (
		<DragAndDropTableCell className="field-column-style">
			<FormSelectTableStyle
				singleColumnFlag
				label={translate("tabGridColumns.style")}
				marginBottom={theme.smallSpace}
				name={`${name}.tableStyleId`}
				uppercaseLabelFlag
				disabled={!stylesFlag}
			/>
			{statusFlag && (
				<>
					<FormCheck marginBottom={theme.tinySpace} name={`${name}.showOwner`}>
						{translate("tabGridColumns.showOwner")}
					</FormCheck>
					<FormCheck marginBottom="0px" name={`${name}.hideStatus`}>
						{translate("tabGridColumns.hideStatus")}
					</FormCheck>
				</>
			)}
		</DragAndDropTableCell>
	);
};
FieldColumn.propTypes = propTypes;
export default FieldColumn;
