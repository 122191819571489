import Block from "@clearpoint/old-theme/Block";
import PaginationControl from "@clearpoint/old-theme/Pagination/Control";
import SelectChartCount from "./SelectChartCount";
import { useCallback } from "react";
import useElementList from "../useElementList";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldSession } from "@clearpoint/old-session/index";
import { theme } from "@clearpoint/old-theme/Theme";
import { usePreviousDefined } from "@clearpoint/hooks";

let PageControl = () => {
	let { setSession, session } = useOldSession();
	let elementList = useElementList();
	let pageCount = elementList?.pageCount;
	let { customFieldFlag, pageKey } = useLocalState();
	let page = session[pageKey] || 1;
	let previousPageCount = usePreviousDefined(pageCount);
	pageCount = pageCount || previousPageCount;
	let setPage = useCallback((page) => setSession({ [pageKey]: page }), [pageKey, setSession]);
	return !pageCount ? null : (
		<Block
			display="flex"
			justifyContent="center"
			margin={customFieldFlag ? 0 : theme.space}
			flex={customFieldFlag && 1}
		>
			<PaginationControl pageCount={pageCount} currentPage={page} onClick={setPage} />
			<SelectChartCount />
		</Block>
	);
};

export default PageControl;
