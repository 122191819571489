import { emptyObject } from "@clearpoint/utils";
import { useMeasure } from "@clearpoint/hooks";


let useMeasureDetailPageWidth = () => {
	let { width: detailPageWidth } = useMeasure({ query: ".detail-page-container" }) || emptyObject;
	let { width: printWidth } = useMeasure({ query: ".print" }) || emptyObject;
	return detailPageWidth || printWidth || 0;
};

export default useMeasureDetailPageWidth;
