import getChildMilestoneList from "./getChildMilestoneList";
import { toast } from "@clearpoint/services/toastService/index";

let getCheckDropValid =
	({ milestoneList, translate }) =>
	({ addedIndex, removedIndex }) => {
		if (addedIndex === removedIndex) return true;

		let currentMilestoneAtDropIndex = milestoneList[addedIndex];
		let milestoneToDrop = milestoneList[removedIndex];

		let validDropFlag;
		let sortedUpFlag = addedIndex < removedIndex;

		let sameParentFlag = (currentMilestoneAtDropIndex.parentId || 0) === (milestoneToDrop.parentId || 0);
		let sameTreeLevelFlag = (currentMilestoneAtDropIndex.treeLevel || 0) === (milestoneToDrop.treeLevel || 0);

		if (sortedUpFlag) {
			validDropFlag = sameParentFlag && sameTreeLevelFlag;
		} else {
			if (sameParentFlag) {
				let childMilestoneList = getChildMilestoneList(milestoneList, currentMilestoneAtDropIndex);
				validDropFlag = childMilestoneList.length === 0;
			} else {
				let siblingOfMilestoneToDrop = milestoneList.find(
					(x) =>
						x.parentId === milestoneToDrop.parentId &&
						getChildMilestoneList(milestoneList, x).find(
							(childMilestone) => childMilestone.objectId === currentMilestoneAtDropIndex.objectId
						)
				);
				if (!siblingOfMilestoneToDrop || siblingOfMilestoneToDrop.objectId === milestoneToDrop.objectId) {
					validDropFlag = false;
				} else {
					let fullChildMilestoneListAtDropIndex = getChildMilestoneList(milestoneList, siblingOfMilestoneToDrop);

					// account for filtered list items when dropping a milestone below a sibling milestone and its list of child milestones
					if (fullChildMilestoneListAtDropIndex[0].visible !== undefined) {
						fullChildMilestoneListAtDropIndex = fullChildMilestoneListAtDropIndex.filter((x) => x.visible);
					}
					let lastChildMilestoneFlag =
						currentMilestoneAtDropIndex.objectId ===
						fullChildMilestoneListAtDropIndex[fullChildMilestoneListAtDropIndex.length - 1]?.objectId;
					validDropFlag = lastChildMilestoneFlag && siblingOfMilestoneToDrop.parentId === milestoneToDrop.parentId;
				}
			}
		}

		if (!validDropFlag) {
			toast.warning(translate("toaster.messages.milestones.invalidSort"), { autoClose: 10000 });
			return false;
		}
		return true;
	};

export default getCheckDropValid;
