import CopyForwardAllModalContent from "./CopyForwardAllModalContent";
import Form, { useFormContext } from "@clearpoint/old-theme/Form/Form";
import Modal from "@clearpoint/old-theme/Modal/Modal";
import { useCallback, useMemo } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";

let propTypes = {
	close: PropTypes.func,
	closeEditMode: PropTypes.func,
	modalVisible: PropTypes.bool,
};

let CopyForwardAllModal = ({ close, closeEditMode, modalVisible }) => {
	let translate = useTranslate();
	let objectId = useFormValue("objectId");
	let {
		formStatus: { dirty: formDirtyFlag },
		resetForm,
	} = useFormContext();
	let { pagePeriodId } = useLocalState();
	let { set } = useOldQueryStore();

	let defaultValue = useMemo(
		() => ({
			copyAnalysis: false,
			copyCustom: false,
			copyPercent: false,
			copyRecommendations: false,
			copyStatus: false,
		}),
		[]
	);

	let onSubmit = useCallback(
		(submitValue) => {
			if (formDirtyFlag) {
				resetForm();
			}
			objectId &&
				pagePeriodId &&
				set({
					method: "put",
					object: "initiative",
					objectId,
					queryString: "?copyForward=1",
					data: { ...submitValue, periodId: pagePeriodId },
				});
			closeEditMode();
			toast.success(translate("toaster.messages.global.milestonesCopiedForward"));
			close();
		},
		[close, closeEditMode, formDirtyFlag, objectId, pagePeriodId, resetForm, set, translate]
	);

	return (
		<Modal close={close} modalVisible={modalVisible}>
			<Form defaultValue={defaultValue} onSubmit={onSubmit}>
				<CopyForwardAllModalContent close={close} />
			</Form>
		</Modal>
	);
};

CopyForwardAllModal.propTypes = propTypes;
export default CopyForwardAllModal;
