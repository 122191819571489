import addMonths from "date-fns/addMonths";
import addYears from "date-fns/addYears";


let addScales = (gantt) => {
	if (gantt.date.half_year_start) return;
	gantt.date.half_year_start = (date) => {
		if (!(date instanceof Date)) date = new Date(date);
		let yearStart = new Date(date.getFullYear(), 0);
		if (date.getMonth() < 6) return date; // first half
		yearStart.setMonth(6);
		return yearStart; // second half
	};
	gantt.date.add_half_year = (date, increment) => addMonths(date, 6 * increment);
	gantt.date.five_years_start = (date) => {
		if (!(date instanceof Date)) date = new Date(date);
		let year = date.getFullYear();
		year -= year % 5;
		return new Date(year, 0);
	};
	gantt.date.add_five_years = (date, increment) => addYears(date, 5 * increment);
	gantt.date.ten_years_start = (date) => {
		if (!(date instanceof Date)) date = new Date(date);
		let year = date.getFullYear();
		year -= year % 10;
		return new Date(year, 0);
	};
	gantt.date.add_ten_years = (date, increment) => addYears(date, 10 * increment);
	gantt.date.twenty_years_start = (date) => {
		if (!(date instanceof Date)) date = new Date(date);
		let year = date.getFullYear();
		year -= year % 20;
		return new Date(year, 0);
	};
	gantt.date.add_twenty_years = (date, increment) => addYears(date, 20 * increment);
};
export default addScales;
