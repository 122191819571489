import CopyModal from "../../Modal/Modal.Copy";
import DropdownItem from "./Dropdown.Item";
import ModalOnClick from "../../Modal/Modal.OnClick";
import ModalWindowOnClick from "../../ModalWindow/ModalWindow.OnClick";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";


let propTypes = {
	children: PropTypes.node,
	label: PropTypes.string,
	object: PropTypes.string,
};
let DropdownItemCopy = ({ children, label, object, ...props }) => {
	let translate = useTranslate();

	return (
		<DropdownItem data-testid="dropdown-item-copy" icon="copy" iconSize="small" {...props}>
			{children ||
				translate("edit.duplicateElement", {
					InsertText: label ? capitalizeFirstLetter(label) : `global.${object}`,
				})}
		</DropdownItem>
	);
};
DropdownItemCopy.propTypes = propTypes;

let wrapperPropTypes = {
	label: PropTypes.string,
	Modal: PropTypes.elementType,
	ModalWindow: PropTypes.elementType,
	noModalFlag: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number.isRequired,
	Options: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	scorecardId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let DropdownItemCopyWrapper = ({
	Modal,
	ModalWindow,
	label,
	noModalFlag,
	object,
	objectId,
	Options,
	scorecardId,
	...props
}) => {
	if (!Modal && !ModalWindow && !noModalFlag) Modal = CopyModal;
	props.toastMessage = props.toast;
	return objectId ? (
		Modal ? (
			<ModalOnClick Modal={Modal} Options={Options} object={object} objectId={objectId} {...props}>
				<DropdownItemCopy label={label} object={object} {...props} />
			</ModalOnClick>
		) : ModalWindow ? (
			<ModalWindowOnClick ModalWindow={ModalWindow} Options={Options} object={object} objectId={objectId} {...props}>
				<DropdownItemCopy label={label} object={object} {...props} />
			</ModalWindowOnClick>
		) : (
			<DropdownItemCopy label={label} object={object} {...props} />
		)
	) : null;
};

DropdownItemCopyWrapper.propTypes = wrapperPropTypes;
export default DropdownItemCopyWrapper;
