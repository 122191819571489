import { useCallback, useRef } from "react";
import { useFormContext } from "../../../Form/Form";
import useGridLockedFlag from "./useGridLockedFlag";
import { useSeriesGridData } from "./Provider";
import { useTranslate } from "@clearpoint/translate";
import getDeepValue from "lodash/get";
import { toast } from "@clearpoint/services/toastService/index";
import { useCheckAccess } from "@clearpoint/hooks";

let useAddCellClickEvents = ({ onEditSeriesModalOpen, setModalState }) => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { formDirty, formValue } = useFormContext();
	let formDirtyFlag = getDeepValue(formDirty, "measureData") || formValue?._measureDataEdited;

	let { access, inModalFlag, seriesData, seriesList, scorecardId } = useSeriesGridData();

	let gridLockedFlag = useGridLockedFlag();
	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });

	let checkPeriodLocked = useCallback(
		(e, coordinates, hotTableRef) => {
			let { row, col: column } = coordinates;
			let periodLockedFlag = seriesData[row]?.periodLocked;
			if (periodLockedFlag && column >= 2) {
				e.stopPropagation();
				hotTableRef.current.hotInstance.setCellMeta(row, column, "editor", false);
				toast.warning(translate("toaster.messages.global.periodLocked"));
			}
		},
		[seriesData, translate]
	);

	let openSeriesModal = useCallback(
		(e, coordinates) => {
			if (!inModalFlag && editAccessFlag) {
				let { row, col: column } = coordinates;
				if (column > 2 && row === -1) {
					if (formDirtyFlag) {
						toast.warning(translate("toaster.messages.global.saveBeforeEditing"));
					} else {
						let { measureSeriesId } = seriesList[column - 3];
						if (onEditSeriesModalOpen) onEditSeriesModalOpen(e);
						setModalState({ editMeasureSeriesId: measureSeriesId, editSeriesModalVisible: true });
					}
				} else {
					e.stopPropagation();
				}
			}
		},
		[editAccessFlag, formDirtyFlag, inModalFlag, onEditSeriesModalOpen, seriesList, setModalState, translate]
	);

	let cellRef = useRef();

	return useCallback(
		(event, coordinates, cell, hotTableRef) => {
			if (!gridLockedFlag) {
				if (cell.current !== cell) {
					cellRef.current = cell;
					cellRef.current.onclick = (e) => checkPeriodLocked(e, coordinates, hotTableRef);
					cellRef.current.ondblclick = (e) => openSeriesModal(e, coordinates);
				}
			}
		},
		[checkPeriodLocked, gridLockedFlag, openSeriesModal]
	);
};

export default useAddCellClickEvents;
