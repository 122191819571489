import { createContext,useContext, useMemo } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import { useCheckAccess } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

import useCheckSeriesLocked from "./useCheckSeriesLocked";
import { useOldSession } from "@clearpoint/old-session/index";

let SeriesGridContext = createContext();

let propTypes = {
	children: PropTypes.node,
	displayHiddenSeriesFlag: PropTypes.bool,
	inModalFlag: PropTypes.bool,
	measureId: PropTypes.number.isRequired,
	periodId: PropTypes.number,
	scorecardId: PropTypes.number,
};

let SeriesGridProvider = ({ children, displayHiddenSeriesFlag, inModalFlag, measureId, periodId, scorecardId }) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	periodId = periodId ?? session.periodId;
	let checkAccess = useCheckAccess();
	let checkSeriesLocked = useCheckSeriesLocked({ measureId });

	let measure = get({ object: "measure", objectId: measureId });
	let { evaluationOption, shadowObject, scorecardId: measureScorecardId } = measure || emptyObject;
	let statusLockedFlag = ![0, null].includes(evaluationOption);
	let measureStatus = measureId && periodId && get({ object: "measure", objectId: measureId, periodId });
	let { access, periodId: displayPeriodId } = measureStatus || emptyObject;

	let seriesList = get({ parent: "measure", parentId: measureId, object: "measureSeries" });
	let seriesData = get({ parent: "measure", parentId: measureId, object: "measureGrid" });
	let statusList = get({ object: "status", queryString: "?includeNull" });

	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId: measureScorecardId });

	seriesData = useMemo(
		() =>
			statusList &&
			isArray(seriesData) &&
			seriesData
				?.filter((x) => !x.hideInGrid)
				.map((x) => {
					let status = statusList?.find((status) => status.statusId === x.statusId);
					return status
						? {
								...x,
								statusIcon: `<img src="${status.image}" width=16 height=16 />`,
								statusName: status.name,
						  }
						: x;
				}),
		[seriesData, statusList]
	);

	seriesList = useMemo(
		() =>
			isArray(seriesList) &&
			seriesList
				.filter((x) => x.active && !x.disabled && (displayHiddenSeriesFlag || !x.hiddenSeries))
				.map((x) => {
					let {
						aggregateSeries: aggregateSeriesFlag,
						calculated: calculatedFlag,
						referenceSeries: referenceSeriesFlag,
						statusSeries: statusSeriesFlag,
					} = x;
					let calculatedSeriesFlag = [
						aggregateSeriesFlag,
						calculatedFlag,
						referenceSeriesFlag,
						statusSeriesFlag,
					].includes(true);
					return {
						...x,
						calculated: calculatedSeriesFlag,
						lockedSeries: calculatedSeriesFlag || checkSeriesLocked(x, editAccessFlag),
					};
				}),
		[checkSeriesLocked, displayHiddenSeriesFlag, editAccessFlag, seriesList]
	);

	return (
		<SeriesGridContext.Provider
			value={{
				access,
				displayPeriodId,
				inModalFlag,
				scorecardId,
				seriesData,
				seriesList,
				shadowObject,
				statusList,
				statusLockedFlag,
			}}
		>
			{children}
		</SeriesGridContext.Provider>
	);
};

SeriesGridProvider.propTypes = propTypes;
export default SeriesGridProvider;
export const useSeriesGridData = () => useContext(SeriesGridContext);
