import { useCallback } from "react";
import useCheckFeature from "../useCheckFeature";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import mergeCustomFieldList from "./mergeCustomFieldList";

let propTypes = {
	addFlag: PropTypes.bool,
	multipleFlag: PropTypes.bool,
	scorecardId: PropTypes.number,
};
let useRiskEditFieldList = ({ addFlag, multipleFlag, scorecardId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let checkFeature = useCheckFeature();
	let tagFlag = checkFeature("tags");

	let customFieldList = get({ object: "customField", queryString: "?object=risk" });

	return useCallback(() => {
		let filteredCustomFieldList = customFieldList
			?.filter((x) => !x.updateMode)
			.filter((x) => !x.scorecardId || x.scorecardId.length === 0 || x.scorecardId.includes(scorecardId));

		let editMultipleFlag = multipleFlag && !addFlag;
		let startAndEndDate = editMultipleFlag
			? [
					{
						value: "startDate",
						label: translate("defaultFields.edit.risk.startDate"),
						sortOrder: -990,
						group: translate("risk"),
						required: false,
					},
					{
						value: "endDate",
						label: translate("defaultFields.edit.risk.endDate"),
						sortOrder: -990,
						group: translate("risk"),
						required: false,
					},
			  ]
			: [
					{
						value: "startAndEndDate",
						label: translate("defaultFields.edit.startDateAndEndDate", {
							startDate: translate("defaultFields.edit.risk.startDate"),
							endDate: translate("defaultFields.edit.risk.endDate"),
						}),
						sortOrder: -990,
						group: translate("risk"),
						required: true,
					},
			  ];

		let fieldList = [
			{
				value: "scorecardId",
				label: translate("global.scorecard"),
				sortOrder: -999,
				group: translate("risk"),
				required: true,
			},
			{
				value: "tags",
				label: translate("global.tags"),
				sortOrder: -998,
				group: translate("risk"),
			},
			{
				value: "ownerId",
				label: translate("defaultFields.edit.risk.owner"),
				sortOrder: -997,
				group: translate("risk"),
			},
			{
				value: "collaborators",
				label: translate("collaborators"),
				sortOrder: -996,
				group: translate("risk"),
			},
			{
				value: "periodGroupId",
				label: translate("global.periodicity"),
				sortOrder: -995,
				group: translate("risk"),
			},
			...startAndEndDate,
			{
				value: "completedDate",
				label: translate("defaultFields.edit.risk.completedDate"),
				sortOrder: -987,
				group: translate("risk"),
			},
		];
		if (!tagFlag) fieldList = fieldList.filter((x) => x.value !== "tags");
		return mergeCustomFieldList(fieldList, filteredCustomFieldList, translate);
	}, [customFieldList, translate, addFlag, multipleFlag, scorecardId, tagFlag]);
};
useRiskEditFieldList.propTypes = propTypes;
export default useRiskEditFieldList;
