import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isFunction from "lodash/isFunction";
import { isEmpty } from "lodash";

export default function removeUndefined(object) {
	let returnObject = cloneDeep(object);
	if (!isArray(returnObject) && isObject(returnObject)) {
		Object.keys(returnObject).forEach(function (key) {
			if (typeof returnObject[key] === "undefined") {
				delete returnObject[key];
			}
			if (isObject(returnObject[key]) && !isFunction(returnObject[key])) {
				returnObject[key] = removeUndefined(returnObject[key]);
				if (!isArray(returnObject[key]) && isEmpty(returnObject[key])) delete returnObject[key];
			}
		});
		return returnObject;
	} else if (isArray(returnObject) && isObject(returnObject)) {
		return returnObject.filter((el) => el !== undefined && el !== null);
	}
}
