import PropTypes from "prop-types";

import Icon from "../Icon/Icon";
import Button from "./Button";

let propTypes = {
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	iconSize: PropTypes.oneOf(["tiny", "small", "large", "huge"]),
	name: PropTypes.string,
	noFixedWidthFlag: PropTypes.bool,
	size: PropTypes.string,
	spinFlag: PropTypes.bool,
};

let defaultProps = {
	iconTransform: "up-1",
	size: "tiny",
};

let IconButton = ({
	children,
	"data-testid": dataTestId,
	flip,
	iconSize,
	iconTransform,
	name,
	noFixedWidthFlag,
	height,
	padding,
	label,
	rotation,
	size,
	spinFlag,
	...props
}) => {
	return (
		<Button data-testid={dataTestId || "icon-button"}  size={size} padding={padding || "3px 6px"} {...props}>
			<Icon
				name={name}
				size={iconSize}
				noFixedWidthFlag={noFixedWidthFlag}
				transform={iconTransform}				
				flip={flip}
				marginRight={label ? "5px" : undefined}
				rotation={rotation}
				spinFlag={spinFlag}
			/>
			{label}
			{children}
		</Button>
	);
};

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;
