import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";
import InputGroup from "react-bootstrap/InputGroup";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import { useEffectAfterRender, useStopScrollEvent } from "@clearpoint/hooks";
import { escapeSpecialCharacters, formatNameForTestId } from "@clearpoint/utils";

import Block from "../Block";
import HTML from "../HTML";
import Icon from "../Icon/Icon";
import StyleWrapper from "../StyleWrapper";
import { theme as clearTheme } from "../Theme";
import Input from "./Input";
import SelectFacade from "./Select.Facade";

let styledInputGroupPropTypes = {
	$flexGrowFlag: PropTypes.bool,
	$marginBottom: PropTypes.string,
	$width: PropTypes.string,
};

let StyledInputGroup = ({ $flexGrowFlag, $marginBottom, $width, ...props }) => (
	<StyleWrapper
		flex={$flexGrowFlag ? "1" : undefined}
		width={$width}
		display={$width ? "inline-block" : undefined}
		marginBottom={$marginBottom}
	>
		<InputGroup {...props} />
	</StyleWrapper>
);

let leftRightPropTypes = {
	children: PropTypes.node.isRequired,
	flexGrowFlag: PropTypes.bool,
	height: PropTypes.string,
	left: PropTypes.node,
	right: PropTypes.node,
	width: PropTypes.string,
};

let LeftRightWrapper = ({ children, flexGrowFlag, height, left, marginBottom, right, width }) => {
	return left || right ? (
		<StyledInputGroup $flexGrowFlag={flexGrowFlag} $width={width} $marginBottom={marginBottom}>
			{left && (
				<StyleWrapper minHeight={height}>
					<InputGroup.Prepend>{left}</InputGroup.Prepend>
				</StyleWrapper>
			)}
			<Block flex="1">{children}</Block>
			{right && (
				<StyleWrapper minHeight={height}>
					<InputGroup.Append>{right}</InputGroup.Append>
				</StyleWrapper>
			)}
		</StyledInputGroup>
	) : (
		children
	);
};

let ReactSelectDropdownIndicator = components.DropdownIndicator;

let ReactSelectOption = components.Option;

let DropdownIndicator = ({ ...props }) => (
	<ReactSelectDropdownIndicator {...props}>
		<Icon name="expandSelect" color="darkerGray" />
	</ReactSelectDropdownIndicator>
);

let propTypes = {
	borderRadius: PropTypes.string,
	className: PropTypes.string,
	createableFlag: PropTypes.bool,
	"data-testid": PropTypes.string,
	disabled: PropTypes.bool,
	formatCreateLabel: PropTypes.func,
	formatOptionLabel: PropTypes.func,
	hideRemoveAllFlag: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inlineFlag: PropTypes.bool,
	inputGroupFlag: PropTypes.bool,
	isSearchableFlag: PropTypes.bool,
	marginBottom: PropTypes.string,
	menuPlacement: PropTypes.oneOf(["auto", "bottom", "top"]),
	multipleFlag: PropTypes.bool,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onInputChange: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string,
		})
	).isRequired,
	padding: PropTypes.string,
	placeholder: PropTypes.string,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	testActualFlag: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string,
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.any,
				label: PropTypes.string,
			})
		),
		PropTypes.number,
	]),
	width: PropTypes.string,
};

let defaultProps = {
	borderRadius: "4px",
	menuPlacement: "auto",
};

let Select = ({
	borderRadius,
	className,
	controlShouldRenderValueFlag,
	createableFlag,
	"data-testid": dataTestId,
	disabled,
	hideRemoveAllFlag,
	id,
	inlineFlag,
	isSearchableFlag,
	formatCreateLabel,
	formatOptionLabel,
	inputGroupFlag,
	left,
	marginBottom,
	menuPlacement,
	menuWidth,
	multipleFlag,
	name,
	noFacadeFlag,
	onBlur,
	onChange,
	onInputChange,
	options,
	padding,
	placeholder,
	right,
	style,
	testActualFlag,
	value,
	width,
}) => {
	if (width) inlineFlag = true;
	let selectRef = useRef();
	let [openMenuFlag, setOpenMenuFlag] = useState();
	let [openFlag, setOpenFlag] = useState(false);
	let { disableScroll, enableScroll } = useStopScrollEvent(); //todo pass selector to disable for options list to allow scrolling
	let [delayedOpenFlag, setDelayedOpenFlag] = useState(openFlag);
	let maxHeightRef = useRef(0);
	useEffectAfterRender(() => {
		setDelayedOpenFlag(openFlag);
	}, [openFlag]);
	let scrollToSelected = useCallback(() => {
		setTimeout(() => {
			if (value) selectRef.current?.setState({ focusedOption: value });
			let selectedEl = document.querySelectorAll(".select__option--is-selected")[0];
			if (selectedEl) {
				selectedEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
			}
		}, 15);
	}, [value]);
	let openMenu = useCallback(() => {
		setOpenMenuFlag(true);
		scrollToSelected();
	}, [scrollToSelected]);
	let closeMenu = useCallback(() => {
		enableScroll(".select__menu-list");
		setOpenMenuFlag(false);
	}, [enableScroll]);
	let theme = useCallback(
		(theme) => ({
			...theme,
			borderRadius: 1,
			colors: {
				...theme.colors,
				primary: clearTheme.mediumBlue,
				primary25: clearTheme.offWhite,
			},
		}),
		[]
	);

	let filterOption = useCallback((option, string) => {
		let label = option.label?.toLowerCase();
		let group = option.data.group?.toLowerCase();
		string = string?.toLowerCase();
		return label?.includes(string) || group?.includes(string);
	}, []);

	let handleBlur = useCallback(() => {
		setOpenFlag(false);
		onBlur && onBlur();
	}, [onBlur]);

	let handleInputChange = useCallback(
		(options, { action }) => {
			if (action === "menu-close") {
				setOpenFlag(false);
			}
			onInputChange && onInputChange();
		},
		[onInputChange]
	);

	let SelectComponent = testActualFlag
		? jest.requireActual("react-select").default
		: (createableFlag
		? CreatableSelect
		: ReactSelect);
	let customStyles = useMemo(
		() => ({
			clearIndicator: () => ({
				display: "none",
			}),
			dropdownIndicator: (provided, state) => ({
				...provided,
				color: clearTheme.darkerGray,
				paddingLeft: state.isMulti && state.selectProps.value?.length > 0 ? "0px" : provided.paddingLeft,
				position: state.isMulti && state.selectProps.value?.length > 0 ? "absolute" : provided.position,
				bottom: state.isMulti && state.selectProps.value?.length > 0 ? "0px" : provided.bottom,
				right: state.isMulti && state.selectProps.value?.length > 0 ? "0px" : provided.right,
			}),
			container: (provided, state) => ({
				...provided,
				outline: "none",
				"&:active": {
					outline: "1px solid " + clearTheme.teal,
				},
				display: inlineFlag ? "inline-block" : provided.display,
				width: inlineFlag ? width || "150px" : provided.width,
				transform: inlineFlag ? "translateY(2px)" : provided.transform,
				padding: "0px",
				"& .select__value-container div:last-child": {
					width: state.isMulti ? "100%" : undefined,
				},
				minWidth: inputGroupFlag ? width || "200px" : provided.minWidth,
				marginLeft: inputGroupFlag ? "-12px" : provided.marginLeft,
				marginRight: inputGroupFlag ? "-12px" : provided.marginRight,
				border: "0",
				borderRadius: "4px",
			}),
			control: (provided, state) => ({
				...provided,
				border: `1px solid ${clearTheme.gray}`,
				borderRadius,
				boxShadow: "none",
				cursor: state.isFocused ? "text" : "pointer",
				paddingLeft: "3px",
				fontSize: "1rem",
				minHeight: "35px",
				"&:hover": {
					background: state.isFocused || state.isMulti ? clearTheme.white : clearTheme.mediumGray,
					color: state.isFocused || state.isMulti ? clearTheme.darkerGray : clearTheme.white,
				},
				"&:active": {
					background: clearTheme.white,
					color: clearTheme.darkerGray,
				},
				"&:hover svg": {
					color: state.isFocused || state.isMulti ? clearTheme.darkerGray : clearTheme.white,
					cursor: "pointer",
				},
				"&:hover .select__multi-value__remove svg": {
					color: clearTheme.brightBlue,
				},
				"& .select__clear-indicator": {
					display: hideRemoveAllFlag ? "none" : undefined,
					pointerEvents: "none",
				},
			}),
			group: (provided) => ({
				...provided,
				padding: "0px",
				"&:first-of-type": {
					marginTop: clearTheme.tinySpace,
				},
				"&::after": {
					content: '""',
					display: "block",
					height: "1px",
					margin: "5px 0 10px",
					overflow: "hidden",
					backgroundColor: "#e5e5e5",
				},
				"&:last-child::after": {
					display: "none",
				},
			}),
			groupHeading: (provided) => ({
				...provided,
				fontSize: "16px",
				fontWeight: 700,
				padding: "3px 20px",
				textTransform: "none",
				margin: "0px",
				lineHeight: clearTheme.lineHeightBase,
				color: clearTheme.gray4,
			}),
			indicatorSeparator: () => ({
				display: "none",
			}),
			menu: (provided) => ({
				...provided,
				minWidth: "160px",
				width: menuWidth || provided.width,
				margin: 0,
				zIndex: 9999,
			}),
			menuList: (provided) => {
				if (provided.maxHeight !== 300) maxHeightRef.current = provided.maxHeight;
				return {
					...provided,
					paddingTop: 0,
					paddingBottom: 0,
					maxHeight: maxHeightRef.current || provided.maxHeight,
				};
			},
			menuPortal: (provided) => ({
				...provided,
				zIndex: 9999,
			}),
			multiValue: (provided) => ({
				...provided,
				borderRadius: "14px",
				alignItems: "center",
				padding: "1px 5px",
				margin: "3px",
				backgroundColor: clearTheme.brightBlue,
				cursor: "pointer",
				"&:hover": {
					backgroundColor: clearTheme.mediumGray,
					borderColor: clearTheme.mediumGray,
				},
				"& .custom-option": {
					margin: 0,
				},
				"& .custom-option img": {
					width: "20px",
					height: "20px",
				},
			}),
			multiValueLabel: (provided) => ({
				...provided,
				color: clearTheme.white,
				fontSize: "16px",
				textOverflow: "clip",
			}),
			multiValueRemove: (provided) => ({
				...provided,
				fontSize: "16px",
				lineHeight: "0.9",
				fontWeight: 800,
				backgroundColor: clearTheme.white,
				borderRadius: "50%",
				color: clearTheme.brightBlue,
				width: "19px !important",
				height: "19px",
				marginLeft: "2px",
				"& svg": {
					transform: "scale(1.75,1.75)",
				},
				"&:hover": {
					backgroundColor: clearTheme.brightBlue + " !important",
				},
				"&:hover svg": {
					color: clearTheme.white + " !important",
				},
			}),
			option: (provided, state) => ({
				...provided,
				color: state.isFocused ? clearTheme.white : clearTheme.darkerGray,
				background: state.isFocused ? clearTheme.mediumBlue : clearTheme.white,
				cursor: "pointer",
				fontSize: "16px",
				lineHeight: 20 / 14,
				paddingBottom: clearTheme.tinySpace,
				paddingLeft: "1.25rem",
				paddingTop: clearTheme.tinySpace,
				textAlign: "left",
				"& .search-text": {
					fontWeight: clearTheme.bolder,
				},
			}),
			placeholder: (provided) => ({
				...provided,
				whiteSpace: "nowrap",
			}),
			singleValue: (provided) => ({
				...provided,
				"& .custom-option": {
					margin: 0,
				},
				"& .custom-option img": {
					width: "20px",
					height: "20px",
				},
			}),
			valueContainer: (provided, state) => ({
				...provided,
				padding: state.isMulti ? "0px" : provided.padding,
				"& .select__placeholder": {
					marginLeft: state.isMulti ? "10px" : undefined,
				},
			}),
		}),
		[borderRadius, hideRemoveAllFlag, inlineFlag, inputGroupFlag, menuWidth, width]
	);
	let Option = ({ children, ...props }) => {
		return props.data.level ? (
				<ReactSelectOption {...props}>
					<Block
						data-testid={`${formatNameForTestId(props.data.label)}-select-option`}
						key={props.innerProps.key}
						marginLeft={`${props.data.level}0px`}
					>
						{children}
					</Block>
				</ReactSelectOption>
			) : (
				<ReactSelectOption {...props}>
					<Block
						color={props.isDisabled ? "muted" : undefined}
						data-testid={`${formatNameForTestId(props.data.label)}-select-option`}
					>
						{children}
					</Block>
				</ReactSelectOption>
			);
	};

	let components = useMemo(() => {
		return { DropdownIndicator, Input, Option };
	}, []);

	let stop = useCallback((e) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
	}, []);

	let defaultFormatOptionLabel = useCallback(({ label }, { inputValue }) => {
		let htmlFlag = label?.startsWith("<") && label?.endsWith(">");
		let labelText;
		if (htmlFlag) {
			let newElement = document.createElement("span");
			newElement.innerHTML = label;
			labelText = newElement.textContent;
		} else {
			labelText = label;
		}
		let bolded = labelText?.replace(
			new RegExp(escapeSpecialCharacters(inputValue), "gi"),
			(bolded) => `<span class="search-text">${bolded}</span>`
		);
		return inputValue || htmlFlag ? <HTML>{inputValue ? bolded : label}</HTML> : label;
	}, []);
	if (!formatOptionLabel) {
		formatOptionLabel = defaultFormatOptionLabel;
	}
	let handleChange = useCallback(
		(value) => {
			if (multipleFlag && value && !isArray(value)) {
				value = [value];
			}
			setOpenFlag && setOpenFlag(false);
			onChange && onChange(value);
		},
		[multipleFlag, onChange]
	);
	let openFacade = useCallback(() => {
		setOpenFlag(true);
		setOpenMenuFlag(true);
		scrollToSelected();
	}, [scrollToSelected]);

	useEffect(() => {
		if (openMenuFlag && document.querySelector(".select__menu-list")) {
			disableScroll(".select__menu-list");
		}
	}, [disableScroll, openFlag, openMenuFlag]);

	noFacadeFlag = useMemo(
		() => noFacadeFlag || (global.testFlag && !global.dummySelectTestFlag) || multipleFlag,
		[multipleFlag, noFacadeFlag]
	);

	let selectElementRef = useRef(null);
	let menuPlacementRef = useRef(menuPlacement);

	useLayoutEffect(() => {
		if (selectElementRef.current) {
			let windowHeight = window.innerHeight;
			let selectElementRect = selectElementRef.current.getBoundingClientRect();
			let selectMenuMaxHeight = windowHeight > 500 ? 300 : windowHeight / 2 - selectElementRect.height;
			let selectElementMaxBottom = selectElementRect.bottom + selectMenuMaxHeight;
			menuPlacementRef.current = selectElementMaxBottom > windowHeight ? "top" : "bottom";
		}
	}, [menuPlacement, openFlag, openMenuFlag, selectElementRef]);

	return (
		<div data-testid={dataTestId} ref={selectElementRef}>
			<LeftRightWrapper left={left} right={right} marginBottom={marginBottom}>
				<span onClick={stop}>
					{noFacadeFlag || openFlag ? (
						<SelectComponent
							autoFocus={noFacadeFlag ? undefined : true}
							className={className}
							classNamePrefix="select"
							components={components}
							controlShouldRenderValue={!openMenuFlag || multipleFlag || controlShouldRenderValueFlag || false}
							data-testid={dataTestId || "real-select"}
							filterOption={filterOption}
							formatCreateLabel={formatCreateLabel}
							formatOptionLabel={formatOptionLabel}
							id={id}
							isDisabled={!!disabled}
							isMulti={multipleFlag}
							isSearchable={isSearchableFlag}
							marginBottom={marginBottom}
							minMenuHeight={10}
							maxMenuHeight={undefined}
							menuIsOpen={noFacadeFlag ? openMenuFlag : delayedOpenFlag}
							menuPlacement={menuPlacementRef.current || menuPlacement}
							menuPortalTarget={document.querySelector(".root")}
							menuPosition="fixed"
							name={name} // if name is defined, input box is rendered (search), otherwise none
							onBlur={handleBlur}
							onChange={handleChange}
							onInputChange={handleInputChange}
							onMenuOpen={openMenu}
							onMenuClose={closeMenu}
							options={options}
							placeholder={placeholder}
							ref={selectRef}
							style={style}
							styles={customStyles}
							theme={theme}
							value={value || null}
						/>
					) : (
						<SelectFacade
							onClick={openFacade}
							value={value || null}
							name={name}
							options={options}
							disabled={!!disabled}
							inputGroupFlag={inputGroupFlag}
							padding={padding}
							placeholder={placeholder}
							marginBottom={marginBottom}
							formatOptionLabel={formatOptionLabel}
							inlineFlag={inlineFlag}
							width={width}
						/>
					)}
				</span>
			</LeftRightWrapper>
		</div>
	);
};

StyledInputGroup.propTypes = styledInputGroupPropTypes;
LeftRightWrapper.propTypes = leftRightPropTypes;
Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default memo(Select);
