import PropTypes from "prop-types";

import FormFieldDisplayCalculated from "@clearpoint/old-theme/Form/Field/Display/Calculated";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import CellStyleWrapper from "../CellStyleWrapper";
import CellWrapper from "../CellWrapper";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let CalculatedCell = (props) => {
	let { cell, parentName } = props;
	let { fieldId, hideRepeated: hideRepeatedFlag, periodId, statusDisplay } = cell;
	let name = `${parentName}.status.${periodId}.calculatedValues.${fieldId}.value`;
	let noStatusIconFlag = statusDisplay === "none" || statusDisplay === "color";
	let statusId = useFormValue(`${parentName}.status.${periodId}.calculatedValues.${fieldId}.statusId`);
	let periodStatus = useFormValue(`${parentName}.status.${periodId}`);
	let reportPeriodId = periodStatus?.reportPeriodId;
	let dataPeriodId = periodStatus?.periodId;
	let hideDataFlag = hideRepeatedFlag && reportPeriodId !== dataPeriodId;
	return (
		<CellStyleWrapper {...props} {...cell} statusId={statusId}>
			<CellWrapper hideDataFlag={hideDataFlag} {...props}>
				{!hideDataFlag && <FormFieldDisplayCalculated noStatusIconFlag={noStatusIconFlag} name={name} />}
			</CellWrapper>
		</CellStyleWrapper>
	);
};
CalculatedCell.propTypes = propTypes;

export default CalculatedCell;
