import Button from "./Button";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";

let propTypes = {
	className: PropTypes.string,
	color: PropTypes.oneOf(["primary", "secondary", "success", "danger", "warning", "info", "dark", "light", "white"]),
	href: PropTypes.string,
	size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
	text: PropTypes.string,
};

let defaultProps = {
	color: "white",
	size: "tiny",
};

let DownloadButton = ({ className, color, href, size, text, ...props }) => (
	<Button color={color} size={size} href={href} padding="3px 6px" target="_new" {...props} downloadFlag>
		{text || <Icon name="download" transform="up-2" />}
	</Button>
);

DownloadButton.propTypes = propTypes;
DownloadButton.defaultProps = defaultProps;

export default DownloadButton;
