import { useCallback } from "react";
import PropTypes from "prop-types";

import FormFieldDisplayCompleted from "@clearpoint/old-theme/Form/Field/Display/Completed";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import CellWrapper from "../CellWrapper";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";

let propTypes = {
	cell: PropTypes.shape({
		object: PropTypes.string,
		objectId: PropTypes.number,
		editCellFlagName: PropTypes.string,
	}),
	editAccessFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let CompletedCell = (props) => {
	let { cell, editAccessFlag, parentName } = props;
	let { object, objectId } = cell;
	let { editTableFlag, setLocalState } = useLocalState();
	let { setFormValue } = useFormContext();
	let podContext = usePodContext();
	let { openEditMode: podOpenEditMode } = podContext || {};

	let openEditMode = useCallback(() => {
		if (podOpenEditMode) podOpenEditMode();
		setFormValue(cell?.editCellFlagName, true);
		if (!editTableFlag) setLocalState({ editTableFlag: true });
	}, [cell?.editCellFlagName, editTableFlag, podOpenEditMode, setFormValue, setLocalState]);

	return (
		<CellWrapper {...props}>
			<FormFieldDisplayCompleted
				name={`${parentName}.completed`}
				object={object}
				objectId={objectId}
				disabled={!editAccessFlag}
				onClick={openEditMode}
			/>
		</CellWrapper>
	);
};
CompletedCell.propTypes = propTypes;
export default CompletedCell;
