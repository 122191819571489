import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

let SelectHeaderStyle = (props) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let reportStyleList = get({ object: "reportStyle" });

	let options = useMemo(() => {
		let optionList = [{ label: translate("briefingBook.noHeader"), value: 0 }];

		let headerStyleList = reportStyleList?.filter((x) => x.type === "header" && x.active);
		if (headerStyleList) {
			let defaultStyle = headerStyleList?.find((x) => x.defaultStyle);
			if (defaultStyle)
				optionList.push({
					label: `${translate("briefingBook.organizationalDefaultHeader")} (${defaultStyle.name})`,
					value: -3,
				});

			let remainingHeaderStyleList = headerStyleList
				?.filter((x) => !x.defaultStyle)
				.map((x) => ({ label: x.name, value: x.reportStyleId }));
			let customStyleOption = { label: translate("briefingBook.customHeader"), value: -1 };

			return [...optionList, ...remainingHeaderStyleList, customStyleOption];
		}
		return [];
	}, [reportStyleList, translate]);

	return (
		<FormSelect
			label={translate("briefingBook.reportHeaderStyle")}
			options={options}
			placeholder={translate("briefingBook.selectHeaderStyle")}
			{...props}
		/>
	);
};

export default SelectHeaderStyle;
