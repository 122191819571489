let isListSortedByScorecard = (list) => {
	return list.every((item, index) => {
		if (index === 0) return true;
		let currentScorecard = item.scorecard;
		let previousScorecard = list[index - 1].scorecard;
		return currentScorecard.localeCompare(previousScorecard) >= 0;
	});
};

export default isListSortedByScorecard;
