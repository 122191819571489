import { useOldSession } from "@clearpoint/old-session/index";
import PropTypes from "prop-types";
import { useEffect } from "react";
import useBaseLanguage from "./Translator/useBaseLanguage";
import useScorecardLanguage from "./Translator/useScorecardLanguage";
import useTranslatorState from "./Translator/useTranslatorState";
import useTranslate from "./hooks/useTranslate";
import useTranslateByScorecard from "./hooks/useTranslateByScorecard";
import useTranslateOnlyInsideCurlyBraces from "./hooks/useTranslateOnlyInsideCurlyBraces";

let translateRef = {
	current: () => {},
};
let propTypes = { children: PropTypes.node.isRequired };
let Translator = ({ children }) => {
	let { localeId, scorecardId } = useOldSession()?.session || {};
	let { translateLookup, addScorecardId } = useTranslatorState();
	let scorecardTranslate = translateLookup[scorecardId];
	let baseTranslate = translateLookup[localeId];
	translateRef.current = scorecardTranslate || baseTranslate || translateRef.current;
	useBaseLanguage();
	useScorecardLanguage();
	useEffect(() => {
		if (scorecardId) addScorecardId(scorecardId);
	}, [addScorecardId, scorecardId]);
	let languageLoadedFlag = Object.keys(translateLookup).length > 0;
	return languageLoadedFlag ? children : null;
};
Translator.propTypes = propTypes;

let getTranslate = () => translateRef.current;

export {
	Translator,
	getTranslate,
	useTranslate,
	useTranslateByScorecard,
	useTranslateOnlyInsideCurlyBraces,
	useBaseLanguage,
};
