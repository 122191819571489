let configureExporting = (chartData) => {
	let {
		chartFormat: { title },
		exporting,
		name,
		legendPosition,
		showLegend: showLegendFlag,
	} = chartData;

	return {
		buttons: {
			contextButton: {
				align: showLegendFlag && legendPosition === "bottomLeft" ? "right" : "left",
				menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"],
				verticalAlign: "bottom",
				theme: {
					fill: "hsla(0, 0%, 0%, 0)",
				},
				x: 0,
				y: -3,
			},
		},
		filename: title?.text ? `${name} - ${title.text}` : name,
		...(exporting ?? {}),
	};
};

export default configureExporting;
