import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import { memo } from "react";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	object: PropTypes.string,
};

let TypeIcon = memo(({ object }) => (
	<Block ellipsisFlag display="inline-block" marginRight={theme.smallSpace}>
		<Icon name={object || "myClearPoint"} size="large" />
	</Block>
));

TypeIcon.propTypes = propTypes;

export default TypeIcon;
