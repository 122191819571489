import FilterProvider from "@clearpoint/providers/FilterProvider";
import Loading from "@clearpoint/old-theme/Loading/index";
import NotificationsTabContent from "./NotificationsTabContent";
import { useCallback, useEffect, useMemo } from "react";
import useCreateDefaultNotification from "./useCreateDefaultNotification";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import useGenerateNotificationDescription from "./hooks/useGenerateNotificationDescription";
import useSetDefaultNotificationValuesInForm from "./useSetDefaultNotificationValuesInForm";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString } from "@clearpoint/utils";

let NotificationsTab = () => {
	let translate = useTranslate();
	let generateDescription = useGenerateNotificationDescription();
	let createDefaultNotification = useCreateDefaultNotification();
	let { get, set, trash, clear } = useOldQueryStore();
	let { formDefaultValue, setOnSubmit } = useFormContext();
	let { object, objectId, notification: notificationValue } = useFormValue();
	let profile = get({ object: "profile" });
	let notificationTemplateList = get({ object: "notification", queryString: "?template=true" });
	notificationTemplateList = useMemo(
		() => notificationTemplateList?.filter((x) => x.active && x.object === object),
		[notificationTemplateList, object]
	);
	let objectNotificationList = get({ object: "notification", queryString: buildQueryString({ object, objectId }) });
	let statusList = get({ object: "status", queryString: "?includeNull" });
	let userList = get({ object: "user" });
	let loadingFlag = [objectNotificationList, notificationTemplateList, statusList, userList, profile].includes(
		undefined
	);
	let isEnabled = useCallback(
		(action) =>
			objectNotificationList?.some((x) => !x.isTemplate && x.action === action && x.detectionType === "change"),
		[objectNotificationList]
	);
	let actionList = useMemo(() => ["any", "edited", "updated"], []);
	let notificationList = useMemo(
		() =>
			!loadingFlag && [
				...actionList.map((action) => ({
					name: `notification.${action}`,
					label: translate(`notifications.action.${action}`),
					defaultValue: isEnabled(action),
				})),
				...notificationTemplateList.map((x) => ({
					name: `notification.${x.notificationId}`,
					label: x.name,
					defaultValue: objectNotificationList.some((y) => y.notificationId === x.notificationId),
					criteriaList: x?.criteria?.map((y) => generateDescription(y)),
				})),
			],
		[
			actionList,
			generateDescription,
			isEnabled,
			loadingFlag,
			objectNotificationList,
			notificationTemplateList,
			translate,
		]
	);
	useSetDefaultNotificationValuesInForm(notificationList);
	let onSubmit = useCallback(async () => {
		let { notification: defaultNotificationValue } = formDefaultValue;
		for (let action of actionList) {
			let createFlag = !defaultNotificationValue?.[action] && notificationValue[action];
			let deleteFlag = defaultNotificationValue?.[action] && !notificationValue[action];
			if (createFlag) {
				let notificationData = createDefaultNotification(action);
				await set({ object: "notification", data: notificationData });
			} else if (deleteFlag) {
				let { notificationId } =
					objectNotificationList &&
					objectNotificationList.find((x) => !x.isTemplate && x.action === action && x.detectionType === "change");
				await trash({ object: "notification", objectId: notificationId });
			}
		}
		for (let notificationTemplate of notificationTemplateList) {
			let { notificationId } = notificationTemplate;
			let createFlag = !defaultNotificationValue[notificationId] && notificationValue[notificationId];
			let deleteFlag = defaultNotificationValue[notificationId] && !notificationValue[notificationId];
			if (createFlag || deleteFlag) {
				await set({
					object: "notification",
					objectId: notificationId,
					queryString: `?link=${createFlag ? "true" : "false"}`,
					data: { object, objectId, userId: profile.userId },
					skipClearFlag: true,
				});
				setTimeout(() => clear({ object: "notification" }), [100]);
			}
		}
	}, [
		actionList,
		clear,
		createDefaultNotification,
		formDefaultValue,
		notificationValue,
		objectNotificationList,
		notificationTemplateList,
		object,
		objectId,
		profile,
		set,
		trash,
	]);
	useEffect(() => {
		setOnSubmit({ name: "notification", onSubmit });
	}, [onSubmit, setOnSubmit]);
	return loadingFlag ? (
		<Loading />
	) : (
		<FilterProvider data={notificationList}>
			<NotificationsTabContent />
		</FilterProvider>
	);
};
export default NotificationsTab;
