import Hide from "../Hide";
import Pagination from "react-bootstrap/Pagination";
import StyleWrapper from "../StyleWrapper";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../Theme";

let sizes = { small: "sm", large: "lg", medium: undefined };
let colors = { primary: theme.primary, success: theme.brightBlue };

let StyledPrev = styled(Pagination.Prev)`
	z-index: 0;
	a {
		color: ${(props) => colors[props.$color]}!important;
		font-size: 16px !important;
	}
`;
let StyledNext = styled(Pagination.Next)`
	z-index: 0;

	a {
		color: ${(props) => colors[props.$color]}!important;
		font-size: 16px !important;
	}
`;

let StyledItem = styled(Pagination.Item)`
	min-width: 32px !important;
	text-align: center;
	a {
		background-color: #fff;
		color: ${(props) => colors[props.$color]}!important;
		font-size: 16px !important;
	}
	&.active {
		span {
			background-color: ${(props) => colors[props.$color]}!important;
			color: #fff !important;
			border-color: ${(props) => colors[props.$color]}!important;
			font-size: 16px !important;
			z-index: 0 !important;
		}
	}
`;

let StyledEllipsis = styled(Pagination.Ellipsis)`
	width: 32px !important;
	text-align: center;
	cursor: not-allowed;
	span {
		color: ${(props) => colors[props.$color]}!important;
		z-index: 0 !important;
	}
`;

let propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	currentPage: PropTypes.number,
	goToNextPage: PropTypes.func,
	goToPage: PropTypes.func,
	goToPreviousPage: PropTypes.func,
	pageCount: PropTypes.number,
	size: PropTypes.oneOf(["small", "medium", "large"]),
};

let defaultProps = {
	color: "success",
	size: "small",
};

let SharedControl = ({ className, color, currentPage, goToNextPage, goToPage, goToPreviousPage, pageCount, size }) => {
	let pageItems = [];
	let ellipsisFlag = pageCount >= 8;
	let ellipsisOnRightFlag = ellipsisFlag && currentPage < 6;
	let ellipsisOnLeftFlag = ellipsisFlag && currentPage >= pageCount - 4;
	let ellipsisOnBothFlag = ellipsisFlag && !ellipsisOnLeftFlag && !ellipsisOnRightFlag;
	for (let i = 1; i <= pageCount; i++) {
		if (
			i === 1 ||
			i === pageCount ||
			!ellipsisFlag ||
			(ellipsisOnRightFlag && i <= 7) ||
			(ellipsisOnLeftFlag && i >= pageCount - 6) ||
			(ellipsisOnBothFlag && i >= currentPage - 2 && i <= currentPage + 2)
		) {
			pageItems.push(
				<StyledItem
					data-testid={`pagination-page-${i}`}
					key={i}
					active={i === currentPage}
					onClick={() => goToPage(i)}
					$color={color}
				>
					{i}
				</StyledItem>
			);
		} else if (
			((ellipsisOnLeftFlag || ellipsisOnBothFlag) && i === 2) ||
			((ellipsisOnRightFlag || ellipsisOnBothFlag) && i === pageCount - 1)
		) {
			pageItems.push(<StyledEllipsis key={i} disabled $color={color} />);
		}
	}
	return (
		<Hide className={className} visible={pageCount > 1} opacityFlag alignItems="center" justifyContent="center">
			<StyleWrapper margin="0 !important">
				<Pagination size={sizes[size] || size}>
					<StyledPrev
						data-testid="pagination-prev-page-button"
						onClick={() => goToPreviousPage()}
						$color={color}
					/>
					{pageItems}
					<StyledNext data-testid="pagination-next-page-button" onClick={() => goToNextPage()} $color={color} />
				</Pagination>
			</StyleWrapper>
		</Hide>
	);
};

SharedControl.propTypes = propTypes;
SharedControl.defaultProps = defaultProps;

export default SharedControl;
