import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import Table from "@clearpoint/old-theme/Table";
import { theme } from "@clearpoint/old-theme/Theme";

import { usePreviousDefined, useStatefulRef } from "@clearpoint/hooks";
import { emptyObject } from "@clearpoint/utils";

import Body from "./Body";
import Columns from "./Columns";
import Header from "./Header";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	elementData: PropTypes.object,
	tableLoadingFlag: PropTypes.bool,
	tableStyle: PropTypes.string,
	tableStyleObject: PropTypes.object,
};

let Grid = ({ cellData, elementData, tableLoadingFlag, tableStyle, tableStyleObject }) => {
	let { exportFlag, excelFlag } = useOldSession().session;
	let previousElementData = usePreviousDefined(elementData);
	elementData = elementData || previousElementData || emptyObject;
	let stickyHeaderFlag = !exportFlag;
	let headerStyle = excelFlag ? tableStyleObject?.headerCss : undefined;
	let cellStyle = excelFlag ? tableStyleObject?.cellCss : undefined;
	let ref = useStatefulRef();
	let applyStyleFlagRef = useRef(true);
	useEffect(() => {
		if (ref.current && excelFlag && tableStyleObject?.tableCss && applyStyleFlagRef.current) {
			ref.current.setAttribute("style", tableStyleObject?.tableCss);
			applyStyleFlagRef.current = false;
		}
	}, [excelFlag, ref, tableStyleObject?.tableCss]);

	return (
		<StyleWrapper
			backgroundColor={theme.white}
			width={"100%"}
			color={exportFlag ? theme.black : theme.darkGray}
			$style={tableStyle + "& th { vertical-align: top !important; }"}
		>
			<Table cpsTableFlag borderedFlag stickyHeaderFlag={stickyHeaderFlag} id="cpsTable" ref={ref} width="100%">
				<Header headerData={elementData?.header} headerStyle={headerStyle} />
				<Columns columnWidthList={elementData.columnWidths} gridRef={ref} />
				<Body cellData={cellData} cellStyle={cellStyle} tableLoadingFlag={tableLoadingFlag} />
			</Table>
		</StyleWrapper>
	);
};

Grid.propTypes = propTypes;
export default Grid;
