import { useCallback } from "react";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";
import { emptyObject, noop } from "@clearpoint/utils";

import StyleWrapper from "../StyleWrapper";
import { theme } from "../Theme";
import FilterCheck from "./Filter.Check";
import { useFilter } from "@clearpoint/providers/FilterProvider";


let propTypes = {
	backgroundColor: PropTypes.string,
	"data-testid": PropTypes.string,
	initialChecked: PropTypes.bool,
	onChange: PropTypes.func,
};

let defaultProps = {
	backgroundColor: theme.white,
	initialChecked: false,
};

let FilterShowDeleted = ({ "data-testid": dataTestId, backgroundColor, initialChecked, onChange: onChangeProp }) => {
	let translate = useTranslate();
	let { setDeletedVisible = noop } = useFilter() || emptyObject;

	let onChange = useCallback(
		(checked) => {
			if (onChangeProp) {
				onChangeProp(checked);
			}
			setDeletedVisible(checked);
		},
		[onChangeProp, setDeletedVisible]
	);

	let filter = useCallback((x, checked) => (checked ? x : x.active), []);

	return (
		<StyleWrapper backgroundColor={backgroundColor}>
			<FilterCheck
				data-testid={dataTestId || "show-deleted-check"}
				initialChecked={initialChecked}
				name="showDeleted"
				text={translate("edit.element.showDeleted")}
				filter={filter}
				onChange={onChange}
			/>
		</StyleWrapper>
	);
};

FilterShowDeleted.defaultProps = defaultProps;
FilterShowDeleted.propTypes = propTypes;

export default FilterShowDeleted;
