import FormGrid from "../../../Form/Grid/Grid";
import FormGroup from "../../../Form/Form.Group";
import Loading from "../../../Loading";
import StyleWrapper from "../../../StyleWrapper";
import useMeasureGridData from "./useMeasureGridData";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	measureGridList: PropTypes.array,
	measureSeriesList: PropTypes.array,
	statusIndicatorLookup: PropTypes.object,
};

let PeerMeasureGrid = ({ measureGridList: peerPreviewMeasureGridList, measureSeriesList, statusIndicatorLookup }) => {
	let translate = useTranslate();
	let contextMenu = useMemo(() => ["copy"], []);

	let { columnList, measureGridList } = useMeasureGridData({
		measureGridList: peerPreviewMeasureGridList,
		measureSeriesList,
		statusIndicatorLookup,
	});

	let loadingFlag = !columnList;

	return (
		<>
			<FormGroup title={translate("options.admin.measureLibrary.measureData")}>
				{loadingFlag ? (
					<Loading />
				) : (
					<>
						<StyleWrapper marginBottom={theme.largeSpace}>
							<FormGrid
								addNewRowOnEditFlag={false}
								columns={columnList}
								contextMenu={contextMenu}
								copyPaste
								defaultValue={measureGridList}
								fixedColumnsLeft={3}
								height="auto"
								name="_seriesData"
								noControlsFlag
								stretchHorizontal={null}
								width="100%"
							/>
						</StyleWrapper>
					</>
				)}
			</FormGroup>
		</>
	);
};

PeerMeasureGrid.propTypes = propTypes;
export default PeerMeasureGrid;
