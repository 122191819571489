import PropTypes from "prop-types";

import Block from "../../../Block";
import useFormValue from "../../../Form/hooks/useFormValue";

let propTypes = {
	ViewGrid: PropTypes.elementType,
	gridPodFlag: PropTypes.bool,
	name: PropTypes.string,
	podWidthDisplayClass: PropTypes.string,
};

let GridDisplay = ({ ViewGrid, gridPodFlag, name, podWidthDisplayClass }) => {
	let value = useFormValue(name);
	let [_scorecardId, object, layoutId] = value.split(",") || [];

	// eslint-disable-next-line no-unused-vars
	return (
		<Block color="initial">
			{object && layoutId ? (
				<ViewGrid
					name={object + layoutId}
					object={object}
					layoutId={+layoutId}
					gridPodFlag={gridPodFlag}
					podWidthDisplayClass={podWidthDisplayClass}
				/>
			) : undefined}
		</Block>
	);
};
GridDisplay.propTypes = propTypes;

export default GridDisplay;
