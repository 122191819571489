import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectFontStyle = (props) => {
	let translate = useTranslate();
	let options = useMemo(() => {
		let optionsArray = ["Normal", "Bold", "Italic"].map((x) => ({
			label: x,
			value: x.toLowerCase(),
		}));

		optionsArray.push({ label: "Underlined", value: "underline" });
		return optionsArray;
	}, []);

	return (
		<FormSelect options={options} placeholder={translate("options.admin.styles.font.selectFontStyle")} {...props} />
	);
};
export default SelectFontStyle;
