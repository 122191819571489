import { useStateObject } from "@clearpoint/hooks";

let useAssistantState = () => {
	let [assistantState, setAssistantState] = useStateObject({
		assistantStartedFlag: false,
		chatId: null,
		chatRestartedFlag: false,
		conversationId: null,
		editModeFlag: true,
		initialResponseReceivedFlag: false,
		messageList: [],
		responseReceivedFlag: false,
		startIndex: 0,
		validatingFlag: false,
	});

	return [assistantState, setAssistantState];
};

export default useAssistantState;
