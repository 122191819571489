import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useEffectOnce } from "react-use";
import { useTranslate } from "@clearpoint/translate";
import { useChartTypeList, useCheckFeature } from "@clearpoint/hooks";

let SelectChartType = (props) => {
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let { setSession } = useOldSession();

	let customChartTypeFlag = checkFeature("customCharts");
	let { customChartTypeList, defaultChartTypeList } = useChartTypeList();

	let options = useMemo(() => {
		let options = [
			...(defaultChartTypeList?.map?.((x) => ({
				group: translate(x.category),
				label: translate("select.chartTypes.chartTypeId" + x.chartTypeId),
				value: x.chartTypeId,
			})) || []),
			...(customChartTypeList?.map?.((x) => ({
				group: translate("select.customChartTypes"),
				label: x.name,
				value: x.customChartTypeId,
			})) || []),
		];
		if (customChartTypeFlag)
			options.push({
				group: translate("edit.chart.customJson"),
				label: translate("edit.chart.customJson"),
				value: 0,
			});
		return options;
	}, [customChartTypeList, defaultChartTypeList, customChartTypeFlag, translate]);

	useEffectOnce(() => {
		setSession({
			chartTypeOptions: options,
		});
	});

	return <FormSelect options={options} placeholder={translate("select.selectChartType") + "..."} {...props} />;
};

export default SelectChartType;
