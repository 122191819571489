import Icon from "../../Icon/Icon";
import Tooltip from "../../Tooltip";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	generateNewResponse: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
};

let RegenerateButton = ({ generateNewResponse, index }) => {
	let translate = useTranslate();
	let regenerateOnClick = useCallback(() => {
		generateNewResponse(index);
	}, [generateNewResponse, index]);

	return (
		<Tooltip placement="top" text={translate("ai.assistant.generateNewResponse")}>
			<Icon name="regenerate" color="success" onClick={regenerateOnClick} />
		</Tooltip>
	);
};

RegenerateButton.propTypes = propTypes;
export default RegenerateButton;
