import CellWrapper from "../CellWrapper";
import FormFieldDisplay from "@clearpoint/old-theme/Form/Field/Display/index";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "@clearpoint/utils";

let propTypes = {
	cell: PropTypes.shape({
		fieldId: PropTypes.string,
		periodId: PropTypes.number,
	}),
	parentName: PropTypes.string,
};
let DateTimeCell = (props) => {
	let { cell, parentName } = props;
	let { fieldId, periodId } = cell;
	let name =
		fieldId === "lastUpdated"
			? `${parentName}.status.${periodId}.${fieldId}`
			: `${parentName}.${fieldId === "lastEdited" ? "updatedDate" : fieldId}`;
	let Component = FormFieldDisplay[capitalizeFirstLetter(fieldId)];
	return (
		<CellWrapper {...props}>
			<Component name={name} />
		</CellWrapper>
	);
};
DateTimeCell.propTypes = propTypes;

export default DateTimeCell;
