import DropdownItem from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item";
import DropdownSplitButton from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton";
import { useAIAssistant } from "@clearpoint/old-theme/AI/AIAssistantProvider/AIAssistantProvider";
import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import { convertMarkdownToHtml, decodeHtmlEntities } from "@clearpoint/utils";

let propTypes = {
	close: PropTypes.func.isRequired,
	setFieldFormValue: PropTypes.func.isRequired,
};

let CopyConversationSplitButton = ({ close, setFieldFormValue }) => {
	let translate = useTranslate();

	let {
		assistantState: { messageList, responseReceivedFlag },
	} = useAIAssistant();

	let copyLastMessage = useCallback(async () => {
		let lastMessage = messageList?.[messageList.length - 1]?.content;
		if (!lastMessage) return;

		let decodedMessage = decodeHtmlEntities(lastMessage);
		let htmlConvertedMessage = await convertMarkdownToHtml(decodedMessage);

		setFieldFormValue(htmlConvertedMessage);
		close();
	}, [close, messageList, setFieldFormValue]);

	let copyConversation = useCallback(async () => {
		if (!isArray(messageList)) return;

		let html = "";
		let i = 0;
		for (let message of messageList) {
			let decodedMessage = decodeHtmlEntities(message.content);
			let convertedMessage = await convertMarkdownToHtml(decodedMessage);

			html += convertedMessage;

			let lastMessageFlag = i === messageList.length - 1;
			if (!lastMessageFlag) html += `<hr style="margin-bottom: 1em;">`;
			i++;
		}
		setFieldFormValue(html);
		close();
	}, [close, messageList, setFieldFormValue]);

	return (
		<DropdownSplitButton
			onClick={copyConversation}
			title={translate("ai.assistant.copyConversation")}
			disabled={!responseReceivedFlag}
			renderDropdownMenuAboveBootstrapModalFlag
			menuAlign="right"
		>
			<DropdownItem onClick={copyConversation}>{translate("ai.assistant.copyConversation")}</DropdownItem>
			<DropdownItem onClick={copyLastMessage}>{translate("ai.assistant.copyLastMessage")}</DropdownItem>
		</DropdownSplitButton>
	);
};

CopyConversationSplitButton.propTypes = propTypes;
export default CopyConversationSplitButton;
