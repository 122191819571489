import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import Column from "@clearpoint/old-theme/Column";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { theme } from "@clearpoint/old-theme/Theme";

import useMeasureDetailPageWidth from "./useMeasureDetailPageWidth";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	children: PropTypes.node.isRequired,
};

let AreaColumn = ({ children }) => {
	let { exportFlag } = useOldSession().session;

	// If we change how we are measuring the detail page width, make sure to make the same change in usePodWidthDisplayClass
	let detailPageWidth = useMeasureDetailPageWidth();
	let minWidthStyleFlag = detailPageWidth >= 768;

	return exportFlag ? (
		<StyleWrapper paddingRight={theme.space} paddingLeft={theme.space}>
			<Column small={6}>{children}</Column>
		</StyleWrapper>
	) : (
		<Block
			flex={minWidthStyleFlag ? "0 0 50%" : "1"}
			maxWidth={minWidthStyleFlag ? "50%" : "100%"}
			position="relative"
			width="100%"
			paddingRight={theme.space}
			paddingLeft={theme.space}
		>
			{children}
		</Block>
	);
};

AreaColumn.propTypes = propTypes;

export default AreaColumn;
