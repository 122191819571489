import Hide from "@clearpoint/old-theme/Hide";
import SplitButtonFavorite from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Favorite";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { theme } from "@clearpoint/old-theme/Theme";
import { buildQueryString, emptyObject } from "@clearpoint/utils";
import { useWindowSize } from "@clearpoint/hooks";

let FavoriteSplitButton = () => {
	let { get } = useOldQueryStore();
	let { object, objectId, scorecardId, layoutId } = useFormValue();
	let { layout } = useDetailPageData();
	layoutId = layout?.layoutId || layoutId;
	let session = useOldSession();
	scorecardId = scorecardId || session.scorecardId;
	let { displayClass } = useWindowSize();
	let tinyWindowSizeFlag = displayClass === "tiny";

	let scorecardFlag = object === "scorecard";

	let favoriteData = useMemo(() => {
		if (scorecardFlag) {
			return layoutId
				? get({ object: "favorite", queryString: buildQueryString({ object, layoutId }) })
				: emptyObject;
		}
		return objectId ? get({ object: "favorite", queryString: buildQueryString({ object, objectId }) }) : emptyObject;
	}, [get, layoutId, object, objectId, scorecardFlag]);

	let { favorite: favoriteFlag } = favoriteData || emptyObject;

	return (
		<Hide marginLeft={theme.smallSpace} visible={!tinyWindowSizeFlag}>
			<SplitButtonFavorite
				favoriteFlag={favoriteFlag}
				object={object}
				objectId={objectId}
				layoutId={layoutId}
				scorecardId={scorecardId}
			/>
		</Hide>
	);
};
export default FavoriteSplitButton;
