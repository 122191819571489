import PropTypes from "prop-types";

import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PodHeaderTextWrapper from "@clearpoint/old-theme/Pod/DefaultPodHeader/HeaderTextWrapper";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";

import { useBuildLink } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { usePodContext } from "@components/Layouts/ViewLayout/ViewDetail/Detail/Pod/PodProvider";


let propTypes = {
	customHeaderStylesFlag: PropTypes.bool,
	headerTextCss: PropTypes.string,
};

let ChartPodHeader = ({ customHeaderStylesFlag, headerTextCss }) => {
	let { get } = useOldQueryStore();
	let buildLink = useBuildLink();
	let { object: pageObject, objectId: pageObjectId } = useFormValue();
	let { pagePeriodId } = useLocalState();
	let { chartId, editPodFlag, title } = usePodContext();

	let chartData = chartId && get({ object: "chart", objectId: chartId, periodId: pagePeriodId });
	let { name: chartName, parent, parentId } = chartData || emptyObject;

	let chartParentDiffentFromPageElementFlag = chartData && (parent !== pageObject || parentId !== pageObjectId);
	let chartParentElement =
		chartParentDiffentFromPageElementFlag &&
		parent &&
		parentId &&
		pagePeriodId &&
		get({ object: parent, objectId: parentId, periodId: pagePeriodId });

	let { object, objectId, name: parentName, statusId, scorecardId } = chartParentElement || emptyObject;

	return chartParentElement ? (
		<>
			<StyleWrapper paddingRight="8px" width="30px">
				<td>
					<StatusIcon statusId={statusId} size="normal" marginRight="0" />
				</td>
			</StyleWrapper>
			<td>
				<PodHeaderTextWrapper customHeaderStylesFlag={customHeaderStylesFlag} headerTextCss={headerTextCss}>
					<h3>
						<StyleWrapper color="inherit">
							<a href={buildLink({ scorecardId, object, objectId, periodId: pagePeriodId })}>{chartName}</a>
						</StyleWrapper>
						{!editPodFlag && (
							<>
								<br />
								<small>{parentName}</small>
							</>
						)}
					</h3>
				</PodHeaderTextWrapper>
			</td>
		</>
	) : (
		<td>
			<PodHeaderTextWrapper customHeaderStylesFlag={customHeaderStylesFlag} headerTextCss={headerTextCss}>
				<h3>{title}</h3>
			</PodHeaderTextWrapper>
		</td>
	);
};

ChartPodHeader.propTypes = propTypes;
export default ChartPodHeader;
