import { useCallback } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import { theme } from "@clearpoint/old-theme/Theme";
import Tooltip from "@clearpoint/old-theme/Tooltip";

import style from "./style";

let propTypes = {
	"data-testid": PropTypes.string,
	handleTabChange: PropTypes.func,
	onClick: PropTypes.func,
	selectedFlag: PropTypes.bool,
	tabGroupChildFlag: PropTypes.bool,
	tabGroupSelectedFlag: PropTypes.bool,
	tabIndex: PropTypes.number,
	title: PropTypes.string,
	tooltipText: PropTypes.string,
};
let TabButton = ({
	handleTabChange,
	onClick,
	selectedFlag,
	tabGroupChildFlag,
	tabGroupSelectedFlag,
	tabIndex,
	title,
	tooltipText,
}) => {
	let handleClick = useCallback(
		(e) => {
			e?.preventDefault();
			handleTabChange({ onClick, tabIndex });
		},
		[handleTabChange, onClick, tabIndex]
	);
	let paddingTop = tabGroupChildFlag ? "5px" : "10px";
	let paddingLeft = tabGroupChildFlag ? theme.largeSpace : theme.mediumSpace;
	let activeTabFlag = tabGroupChildFlag && selectedFlag;
	let activeFlag = selectedFlag || tabGroupSelectedFlag;
	return (
		<Tooltip placement="right" color="success" text={tooltipText}>
			<Block
				data-testid={`${title}-tab-button`}
				as="a"
				borderLeft="4px solid transparent"
				className={activeTabFlag ? "active tab-active" : (activeFlag ? "active" : "")}
				color={theme.darkGray}
				display="block"
				ellipsisFlag
				fontSize="16px"
				fontWeight={400}
				href=""
				onClick={handleClick}
				padding={`${paddingTop} 0px 7px ${paddingLeft}`}
				text={title}
				$style={style}
			>
				{tabGroupChildFlag && <span className="selected">&raquo;&nbsp;</span>}
				{title}
			</Block>
		</Tooltip>
	);
};
TabButton.propTypes = propTypes;
export default TabButton;
