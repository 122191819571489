import BootstrapForm from "react-bootstrap/Form";
import InputController from "../../Form/InputController";
import RadioGroup from "../../Radio/RadioGroup";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	description: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

let FormRadioGroup = ({
	boldFlag,
	children,
	className,
	"data-testid": dataTestId,
	description,
	label,
	name,
	onBlur,
	onChange,
	style,
	value,
}) => (
	<BootstrapForm.Group className={className} data-testid={dataTestId} controlId={name} style={style}>
		{label && <BootstrapForm.Label>{label}</BootstrapForm.Label>}
		<RadioGroup
			children={children}
			boldFlag={!!label || boldFlag}
			name={name}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
		/>
		{description && <BootstrapForm.Text className="text-muted">{description}</BootstrapForm.Text>}
	</BootstrapForm.Group>
);

let controlledRadioGroupPropTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
};

let ControlledRadioGroup = ({ children, name, ...props }) => {
	return (
		<>
			<InputController name={name} {...props}>
				<FormRadioGroup>{children}</FormRadioGroup>
			</InputController>
		</>
	);
};

RadioGroup.propTypes = propTypes;
ControlledRadioGroup.propTypes = controlledRadioGroupPropTypes;

export default ControlledRadioGroup;
