import { PusherProvider } from "@harelpls/use-pusher";
import { useCallback, useContext, useReducer, createContext } from "react";
import { useLocation } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { useStateObject } from "@clearpoint/hooks";


let PusherContext = createContext();

let initialState = {};
let reducer = (state, action) => {
	switch (action.type) {
		case "ADD":
			return { ...state, [action.payload.key]: action.payload.effect };
		case "REMOVE":
			let stateCopy = cloneDeep(state);
			delete stateCopy[action.payload];
			return stateCopy;
		default:
			return state;
	}
};

let CustomPusherProvider = ({ children, ...config }) => {
	let { pathname, hash } = useLocation();
	let exportFlag = pathname.includes("print") || hash.includes("print"); // defined this way to prevent tests in print mode from triggering new api calls
	let [pusherEffectLookup, dispatch] = useReducer(reducer, initialState);
	let [pusherCache, setPusherCache] = useStateObject({ percentComplete: {} });

	let addPusherEffect = useCallback(({ key, effect }) => {
		dispatch({
			type: "ADD",
			payload: { key, effect },
		});
	}, []);

	let removePusherEffect = useCallback(({ key }) => {
		dispatch({
			type: "REMOVE",
			payload: key,
		});
	}, []);

	let runEffects = useCallback(
		(message) => {
			Object.keys(pusherEffectLookup).forEach((key) => {
				pusherEffectLookup[key]?.(message);
			});
		},
		[pusherEffectLookup]
	);

	return exportFlag ? (
		children
	) : (
		<PusherContext.Provider
			value={{
				addPusherEffect,
				pusherCache,
				pusherEffectLookup,
				removePusherEffect,
				runEffects,
				setPusherCache,
			}}
		>
			<PusherProvider {...config}>{children}</PusherProvider>
		</PusherContext.Provider>
	);
};
export const usePusherContext = () => useContext(PusherContext);
export default CustomPusherProvider;
