import isEmpty from "lodash/isEmpty";

import { emptyObject } from "@clearpoint/utils";

import updateUrlWithPeriodId from "./setSessionFunction.updateUrlWithPeriodId";

let getCheckPeriodValidOnNewScorecard =
	({ history, pathname, periodList, queryParameters, scorecardList }) =>
	({ periodId, previousSession, session }) => {
		if (!periodList) return;
		let { scorecardId } = session;

		let currentPeriod = periodList.find((x) => x.periodId === periodId);

		let periodValidFlag =
			currentPeriod?.active &&
			(isEmpty(currentPeriod.scorecardList) || currentPeriod.scorecardList.includes(scorecardId));

		if (!periodValidFlag) {
			let mostRecentPeriodOnScorecard = [...periodList]
				.reverse()
				.filter((x) => new Date(x.periodDate) <= new Date())
				.find(
					(x) => x.active && !x.archived && (x.scorecardList.length === 0 || x.scorecardList.includes(scorecardId))
				);
			let { periodId } = mostRecentPeriodOnScorecard || emptyObject;

			// Navigate to user-selected element or scorecard home
			let navigateToNewElementFlag =
				session.object !== previousSession.object ||
				session.layoutId !== previousSession.layoutId ||
				session.objectId !== previousSession.objectId;
			if (navigateToNewElementFlag) {
				updateUrlWithPeriodId({ ...queryParameters, history, pathname, periodId });
			} else {
				let scorecard = scorecardList?.find((x) => x.scorecardId === scorecardId);
				let scorecardHome = scorecard?.home?.referrer || scorecard?.home || scorecard;

				if (scorecardHome) {
					let { layoutId, object, objectId, scorecardId } = scorecardHome;
					updateUrlWithPeriodId({
						history,
						pathname,
						periodId,
						queryParameters: { layoutId, object, objectId, scorecardId },
					});
				}
			}
		}
	};

export default getCheckPeriodValidOnNewScorecard;
