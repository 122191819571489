import { cloneDeep } from "lodash";
import isArray from "lodash/isArray";
import { isObject } from "lodash";
import { emptyObject } from "@clearpoint/utils";

let slimLinksPayload = (submitValue = emptyObject) => {
	if (!isArray(submitValue?.links)) return submitValue;

	let slimifiedLinkList = submitValue.links.map((x) => {
		let { fieldId, object, objectId, weight } = x;
		let slimLink = { fieldId, object, objectId, weight };
		if (slimLink.weight === undefined) {
			delete slimLink.weight;
		}
		return slimLink;
	});

	return { ...submitValue, links: slimifiedLinkList };
};

let slimCustomFieldsPayload = (submitValue = emptyObject) => {
	if (!Object.keys(submitValue).some((x) => x.startsWith("custom"))) {
		return submitValue;
	}

	let slimifiedCustomFields = {};

	for (let [key, value] of Object.entries(submitValue)) {
		if (key.startsWith("custom")) {
			if (isObject(value) && value.customFieldId) {
				let { customFieldId, value: customFieldValue } = value;
				slimifiedCustomFields[key] = { customFieldId, value: customFieldValue };
			} else {
				slimifiedCustomFields[key] = value;
			}
		}
	}

	return { ...submitValue, ...slimifiedCustomFields };
};

let slimPayload = (submitValue = emptyObject) => {
	let submitValueCopy = cloneDeep(submitValue);
	for (let callback of [slimLinksPayload, slimCustomFieldsPayload]) {
		submitValueCopy = callback(submitValueCopy);
	}

	return submitValueCopy;
};

export default slimPayload;
