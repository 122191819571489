// NOTE: Most data transformations should be made in useFormValueTransform

// Exception: If the data should appear differently from the saved form value
// Change it here and
// Make sure to reverse that change in useFormValueTransformation

import { useCallback } from "react";
import { useFormContext } from "../../../Form/Form";
import useFormValue from "../../../Form/hooks/useFormValue";
import styleRegEx from "./styleRegEx";
import { useBuildImagePath } from "@clearpoint/hooks";


let useElementValueTransform = (name) => {
	let buildImagePath = useBuildImagePath();
	let { setFormValue } = useFormContext();
	let styleName = `_style${name}`;
	let styleValue = useFormValue(styleName);
	return useCallback(
		(formValue) => {
			// get styles and set to hidden form value
			let newStyleValue = styleRegEx.test(formValue) && formValue.match(styleRegEx).join("\n");
			if (newStyleValue && !styleValue) {
				setFormValue(styleName, newStyleValue);
			}
			return (
				formValue
					// remove styles
					?.replace(/<style>[\s\S]*?<\/style>/g, "")
					// add tokens if needed
					.replace(
						/<img src="(.*?)"/g,
						(_match, url) => `<img src="${buildImagePath(encodeURI(url.replaceAll("%25", "%")))}"`
					)
			);
		},
		[buildImagePath, setFormValue, styleName, styleValue]
	);
}; // build full image paths, strip initial style
export default useElementValueTransform;
