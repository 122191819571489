import FormSelectSeriesDataType from "../../Form/Select/Select.SeriesDataType";
import { joinNameList } from "@clearpoint/utils";

let SeriesDataTypeId = ({ parentName, ...props }) => {
	return (
		<FormSelectSeriesDataType
			currency={joinNameList(parentName, "currency")}
			data-testid="seriesDataTypeId"
			numberFormat={joinNameList(parentName, "numberFormatId")}
			{...props}
		/>
	);
};
export default SeriesDataTypeId;
