import Block from "../Block";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";
import { theme } from "../Theme";
import { useScorecardLockedFlag, useScorecardName } from "@clearpoint/hooks";


let propTypes = {
	scorecardId: PropTypes.number,
};
let PageTitleScorecardSubtitle = ({ scorecardId }) => {
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let scorecardName = useScorecardName(scorecardId ? { scorecardId } : undefined);
	return (
		<Block data-testid={`${scorecardName}-sub-title`} display="flex" alignItems="center">
			{scorecardLockedFlag && <Icon marginRight={theme.tinySpace} name="locked" size="tiny" />}
			{scorecardName}
		</Block>
	);
};
PageTitleScorecardSubtitle.propTypes = propTypes;

export default PageTitleScorecardSubtitle;
