import Block from "@clearpoint/old-theme/Block";
import DocumentItem from "@clearpoint/old-theme/DocumentItem";
import FormSlider from "@clearpoint/old-theme/Form/Input/Input.Slider";
import PercentCompletePie from "@clearpoint/old-theme/PercentCompletePie";
import Tooltip from "@clearpoint/old-theme/Tooltip";
import { useCallback } from "react";
import useFormatKeyResultNumber from "../../Shared/EditGoalModalWindow/KeyResultsTab/useFormatKeyResultNumber";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import calculateKeyResultPercentComplete from "@components/Elements/Goals/Shared/calculateKeyResultPercentComplete";
import roundPercentComplete from "../../Shared/roundPercentComplete";
import { theme } from "@clearpoint/old-theme/Theme";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { toast } from "@clearpoint/services/toastService/index";
import { emptyObject } from "@clearpoint/utils";


let propTypes = {
	goalId: PropTypes.number,
	index: PropTypes.number,
	name: PropTypes.string,
	readOnlyFlag: PropTypes.bool,
	keyResultId: PropTypes.number,
};

let KeyResultTrayListItem = ({ goalId, index, name, readOnlyFlag, keyResultId }) => {
	let translate = useTranslate();
	let keyResultNameInForm = `goalData[${goalId}].keyResults[${index}]`;
	let { keyResultsDirtyFlag, setLocalState } = useLocalState();
	let { set } = useOldQueryStore();
	let keyResult = useFormValue(keyResultNameInForm) || emptyObject;
	let { currentValue, startValue, targetValue } = useFormValue(keyResultNameInForm) || emptyObject;
	let percentComplete = calculateKeyResultPercentComplete(startValue, targetValue, currentValue);
	let formatKeyResultNumber = useFormatKeyResultNumber(keyResultNameInForm);

	let onAfterChange = useCallback(
		(keyResultValue) => {
			if (!keyResultsDirtyFlag) {
				setLocalState({ hideSaveBarFlag: true, keyResultsDirtyFlag: true });
			}
			let newKeyResult = { ...keyResult, currentValue: keyResultValue };
			set({
				object: "keyResult",
				keyResultId: keyResultId,
				parent: "goal",
				parentId: goalId,
				data: newKeyResult,
			});
			setLocalState({ keyResultsDirtyFlag: false });
			toast.success(translate("goals.keyResults.keyResultsUpdated"));
		},
		[setLocalState, translate, keyResult, keyResultId, goalId, keyResultsDirtyFlag, set]
	);

	let onSliderChange = useCallback(() => {
		if (!keyResultsDirtyFlag) {
			setLocalState({ hideSaveBarFlag: true, keyResultsDirtyFlag: true });
		}
	}, [keyResultsDirtyFlag, setLocalState]);

	let onBeforeChange = useCallback(() => {
		setLocalState({ hideSaveBarFlag: true });
	}, [setLocalState]);

	return (
		<DocumentItem ignoreDefaultChildStylesFlag>
			<Block
				display="flex"
				flex="1 1 auto"
				alignItems="center"
				justifyContent="flex-start"
				marginBottom={theme.tinySpace}
				ellipsisFlag
			>
				<Tooltip
					placement="top"
					text={translate("tooltips.percentComplete", {
						percent: roundPercentComplete(percentComplete) || "0",
					})}
				>
					<PercentCompletePie
						fillColor="#23c6c8"
						backgroundColor={percentComplete ? "#a7e8e9" : theme.lightGray}
						percentComplete={percentComplete || 0}
						marginTop="0"
						size="28px"
					/>
				</Tooltip>
				<Block ellipsisFlag minWidth="0">
					<Tooltip placement="top" text={name} blockFlag>
						<Block marginLeft={theme.smallSpace} ellipsisFlag>
							{name}
						</Block>
					</Tooltip>
				</Block>
			</Block>
			<Block flex="0 0 400px" padding={theme.tinySpace} marginBottom={theme.tinySpace}>
				<FormSlider
					name={`${keyResultNameInForm}.currentValue`}
					sliderHeight="14px"
					hideThumbValueFlag
					valueLabelPosition="side"
					inputPosition="right"
					label={translate("goals.keyResults.currentValue")}
					max={targetValue ?? 0}
					min={startValue ?? 0}
					formatNumber={formatKeyResultNumber}
					onChange={onSliderChange}
					onAfterChange={onAfterChange}
					onBeforeChange={onBeforeChange}
					step={1}
					errorTextNotVisible
					disabled={readOnlyFlag}
				/>
			</Block>
		</DocumentItem>
	);
};

KeyResultTrayListItem.propTypes = propTypes;
export default KeyResultTrayListItem;
