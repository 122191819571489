import isArray from "lodash/isArray";

import { objectiveLinkTypeList } from "@clearpoint/utils";

let linkListSortedByCategory = (linkList) => {
	let sortedLinkList = [];
	if (isArray(objectiveLinkTypeList) && isArray(linkList)) {
		for (let linkType of objectiveLinkTypeList) {
			for (let link of linkList) {
				if (link?.fieldId === linkType) {
					let repeatedLinkFlag = sortedLinkList.some(
						(x) => x.object === link.object && x.objectId === link.objectId
					);
					sortedLinkList = [...sortedLinkList, { ...link, repeatedLinkFlag }];
				}
			}
		}
	}
	return sortedLinkList;
};

export default linkListSortedByCategory;
