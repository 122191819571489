import { useMemo } from "react";
import useFormValue from "../../../Form/hooks/useFormValue";
import parserCSS from "prettier/parser-postcss";
import prettier from "prettier";

let usePrettyValue = (name) => {
	let value = useFormValue(name);
	return useMemo(() => {
		let trimmedValue = value
			?.replace(/<style>/g, "")
			.replace(/<\/style>/g, "")
			.trim();
		let prettyValue;
		try {
			prettyValue = prettier.format(trimmedValue, { parser: "css", plugins: [parserCSS] });
		} catch (e) {}
		return prettyValue || trimmedValue;
	}, [value]);
};

export default usePrettyValue;
