import { chartPeriodListContainsPeriod, formatSeriesNumber } from "../utils";

function minZero(value) {   
    return value < 0 ? 0 : value;
}

let createGaugeSeriesList = ({ seriesList, measureGridList, chartPeriodList }) => {
	let period = measureGridList?.find((x) => chartPeriodListContainsPeriod(x, chartPeriodList));
	return seriesList.slice(0, 1).map((series) => ({
		...series,
		data: [
			{
				color: series.color,
				name: period?.periodName ?? "",
				y: minZero(Number.parseFloat(period?.["series" + series.measureSeriesId])),
			},
		],
		type: "gauge",
		dataLabels: {
			formatter () {
				return formatSeriesNumber(this.y, this.series.options.numberFormat);
			},
		},
	}));
};

export default createGaugeSeriesList;
