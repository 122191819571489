import FilterProvider from "@clearpoint/providers/FilterProvider";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import Loading from "@clearpoint/old-theme/Loading/index";
import SelectedElementsModalContent from "./SelectedElementsModalContent";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useEffectOnce, useMemoOnce } from "@clearpoint/hooks";


let propTypes = {
	close: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool.isRequired,
	scorecardId: PropTypes.number,
};

let SelectedElementsModal = ({ close, modalVisible, scorecardId }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { setFormValue } = useFormContext();
	let formElementList = useFormValue("elementList");
	let elementList = scorecardId && get({ scorecardId, object: "chart", queryString: "list=true" });
	let name = useMemoOnce(() => {
		let index = formElementList?.findIndex((x) => x.scorecardId === scorecardId);
		if (index === -1) {
			index = formElementList.length;
		}
		return `elementList[${index || 0}].elements`;
	});
	useEffectOnce(() => {
		if (!formElementList?.some((x) => x.scorecardId === scorecardId)) {
			setFormValue(`elementList[${formElementList?.length || 0}].scorecardId`, scorecardId);
		}
	});
	let loadingFlag = !elementList;
	return (
		<FormModal
			name={name}
			close={close}
			closeButtonText={translate("global.save")}
			modalVisible={modalVisible}
			title={translate("edit.selectElement", { element: "global.charts" })}
			fixedHeightFlag
			size="large"
			height="75%"
		>
			{loadingFlag ? (
				<Loading />
			) : (
				<FilterProvider data={elementList}>
					<SelectedElementsModalContent loadingFlag={loadingFlag} name={name} />
				</FilterProvider>
			)}
		</FormModal>
	);
};

SelectedElementsModal.propTypes = propTypes;
export default SelectedElementsModal;
