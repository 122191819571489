import Button from "../Button/Button";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	close: PropTypes.func.isRequired,
	discardMessage: PropTypes.string,
	modalVisible: PropTypes.bool,
	onDiscard: PropTypes.func.isRequired,
};

let DiscardModal = ({ close, modalVisible, onDiscard, discardMessage }) => {
	let translate = useTranslate();
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<ModalTitle>{translate("edit.confirmDiscardTitle")}</ModalTitle>
			</ModalHeader>
			<ModalBody>{discardMessage || translate("edit.confirmDiscardBeforeLeavingPage")}</ModalBody>
			<ModalFooter>
				<Button onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button data-testid={"discard-button"} onClick={onDiscard} color="danger">
					{translate("edit.discard")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

DiscardModal.propTypes = propTypes;

export default DiscardModal;
