import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { theme } from "@clearpoint/old-theme/Theme";

import {
	useCheckAccess,
	useCheckFeature,
	useClearUpdatedLinks,
	useDisableLoadingModalSave,
	useNavigateToScorecard,
	useStateObject,
} from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject, splitEditAndUpdateFieldData } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import EditFieldsTab from "./EditFieldsTab";
import EvaluationCriteriaTab from "./EvaluationCriteriaTab";
import LinkMapTab from "./LinkMapTab";
import LinksTab from "./LinksTab";
import syncChildLinkWeights from "./syncChildLinkWeights";
import UpdateFieldsTab from "./UpdateFieldsTab";
import useCheckApproval from "@clearpoint/hooks-dir/useCheckApproval";
import useToastApproval from "@clearpoint/hooks-dir/useToastApproval";
import { useOldSession } from "@clearpoint/old-session/index";
import NotificationsTab from "@components/Elements/Shared/NotificationsTab";

let editObjectiveModalContentPropTypes = {
	addFlag: PropTypes.bool,
	approvalRequired: PropTypes.bool,
	linkList: PropTypes.array,
	onAdd: PropTypes.func,
	loadingFlag: PropTypes.bool,
};

let EditObjectiveModalContent = ({ addFlag, approvalRequired, linkList, onAdd, loadingFlag }) => {
	let translate = useTranslate();
	let { clear } = useOldQueryStore();
	let checkAccess = useCheckAccess();
	let { access, name, objectId, automaticEvaluation } = useFormValue();
	let { newObjectFlag } = useFormContext();
	let period = useFormValue("updates.period");
	let periodLockedFlag = useFormValue("updates.periodLocked");
	let editFlag = objectId ? checkAccess({ access, action: "edit" }) : true;

	let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
		saveModalVisible: false,
		savedFlag: false,
	});
	let onSave = useCallback(
		(response) => {
			if (onAdd) {
				onAdd(response);
			}
			setState({ savedFlag: true });
		},
		[onAdd, setState]
	);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);
	let openSaveModal = useCallback(
		(selectTab) => {
			if (newObjectFlag && approvalRequired) {
				toast.warning(translate("approvals.changesAfterApproval"));
			} else if (newObjectFlag) {
				setState({ saveModalVisible: true });
			} else {
				selectTab();
			}
		},
		[approvalRequired, newObjectFlag, setState, translate]
	);

	useUnmount(() => {
		if (savedFlag) clear({ object: "objective", objectId });
	});

	let updateFieldsTabTitle = useMemo(
		() =>
			periodLockedFlag ? (
				<>
					<Icon name="locked" size="tiny" marginRight={theme.smallSpace} />
					{`${period} ${translate("global.locked")}`}
				</>
			) : (
				translate("edit.element.updateFields")
			),
		[period, periodLockedFlag, translate]
	);

	let feature = "objectiveEvaluation";
	let checkFeature = useCheckFeature();
	let objectiveEvaluationAllowedFlag = checkFeature(feature);

	useDisableLoadingModalSave(loadingFlag);

	return (
		<>
			<ModalWindowHeader
				badgeIcon="objective"
				badgeText={translate("objective")}
				title={addFlag ? translate("edit.addElement", { element: "objective" }) : name}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					{editFlag && (
						<EditFieldsTab
							addFlag
							title={translate("edit.addElement", { element: "objective" })}
							visible={addFlag}
						/>
					)}
					<UpdateFieldsTab
						onClick={openSaveModal}
						title={updateFieldsTabTitle}
						periodLockedFlag={periodLockedFlag}
					/>
					{editFlag && (
						<EditFieldsTab
							onClick={openSaveModal}
							title={translate("edit.element.editFields")}
							visible={!addFlag}
						/>
					)}
					{editFlag && (
						<LinksTab
							onClick={openSaveModal}
							title={translate("edit.links")}
							objectiveEvaluationAllowedFlag={objectiveEvaluationAllowedFlag}
						/>
					)}
					{editFlag && linkList && linkList.length > 0 && (
						<LinkMapTab onClick={openSaveModal} title={translate("edit.map.linkMap")} />
					)}
					{editFlag && automaticEvaluation && objectiveEvaluationAllowedFlag && (
						<EvaluationCriteriaTab onClick={openSaveModal} title={translate("edit.evaluationCriteria")} />
					)}
					<NotificationsTab onClick={openSaveModal} title={translate("edit.element.notification")} />
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter saveAndContinueFlag={!approvalRequired} />
			<FormModalSave
				object="objective"
				approvalRequired={approvalRequired}
				onSave={onSave}
				modalVisible={saveModalVisible}
				submitUnchangedFlag
				close={closeSaveModal}
			/>
		</>
	);
};
EditObjectiveModalContent.propTypes = editObjectiveModalContentPropTypes;

let propTypes = {
	objectId: PropTypes.number,
	onAdd: PropTypes.func,
	onEdit: PropTypes.func,
};
let EditObjectiveModal = ({ onAdd, onEdit, ...props }) => {
	let translate = useTranslate();
	let [objectId, setObjectId] = useState(props.objectId);
	let { clear, get } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId, scorecardId } = session;
	let clearUpdatedLinks = useClearUpdatedLinks();
	let navigateToScorecard = useNavigateToScorecard();
	let objectiveData = objectId && get({ object: "objective", objectId });
	let objectiveUpdateData = objectId && periodId && get({ object: "objective", objectId, periodId });
	let objectiveEvaluationData = objectId && get({ parent: "objective", parentId: objectId, object: "evaluation" });
	let customFieldData =
		objectId && periodId && get({ object: "customField", parent: "objective", parentId: objectId, periodId });
	let linkList = objectId && periodId && get({ parent: "objective", parentId: objectId, object: "link", periodId });
	let loadingFlag = objectId && [objectiveData, objectiveUpdateData, customFieldData, linkList].includes(undefined);
	let toastApproval = useToastApproval();
	let approvalRequired = useCheckApproval({ scorecardId })({ type: objectId ? "edits" : "adds" });

	let defaultValue = useMemo(() => {
		if (objectiveData && customFieldData) {
			let { editFieldData: editCustomFieldData, updateFieldData: updateCustomFieldData } =
				splitEditAndUpdateFieldData(customFieldData);
			return {
				...objectiveData,
				...editCustomFieldData,
				updates: { ...objectiveUpdateData, ...updateCustomFieldData },
				links: linkList,
				evaluationCriteria: objectiveEvaluationData,
			};
		}
		return emptyObject;
	}, [customFieldData, linkList, objectiveData, objectiveEvaluationData, objectiveUpdateData]);

	let onSubmit = useCallback(
		(value, response) => {
			if (!objectId && onAdd) {
				onAdd(response);
			} else if (objectId) {
				clear({ object: "detailLayout" });
				if (onEdit) onEdit();
			}
			let { changeQueued } = toastApproval({ response });
			if (!changeQueued) {
				clearUpdatedLinks({ newLinkList: value?.links, oldLinkList: linkList });
				navigateToScorecard(value.scorecardId);
				toast.success(
					translate(objectId ? "toaster.messages.objects.objectUpdated" : "toaster.messages.objects.objectAdded", {
						object: "objective",
					})
				);
			}
		},
		[clear, clearUpdatedLinks, linkList, navigateToScorecard, objectId, onAdd, onEdit, toastApproval, translate]
	);

	return (
		<ModalWindowEdit
			{...props}
			beforeSubmit={syncChildLinkWeights}
			defaultValue={defaultValue}
			loadingFlag={loadingFlag}
			object="objective"
			objectId={objectId}
			onSubmit={onSubmit}
			setObjectId={setObjectId}
			size="large"
			submitUnchangedFlag={!objectId}
			updateFlag
		>
			<EditObjectiveModalContent
				approvalRequired={approvalRequired}
				loadingFlag={loadingFlag}
				addFlag={!objectId}
				linkList={linkList}
				onAdd={onAdd}
			/>
		</ModalWindowEdit>
	);
};
EditObjectiveModal.propTypes = propTypes;

export default EditObjectiveModal;
