import { useContext, useMemo, createContext } from "react";
import PropTypes from "prop-types";


let RadioContext = createContext();

let propTypes = {
	boldFlag: PropTypes.bool,
	children: PropTypes.node,
	name: PropTypes.string,
	radioClass: PropTypes.string,
};
let defaultProps = { radioClass: "icheck-green" };

let RadioGroupCustom = ({ boldFlag, children, name, radioClass }) => {
	return useMemo(
		() => (
			<RadioContext.Provider
				value={{
					boldFlag,
					radioClass,
					name,
				}}
			>
				{children}
			</RadioContext.Provider>
		),
		[boldFlag, radioClass, name, children]
	);
};

RadioGroupCustom.propTypes = propTypes;
RadioGroupCustom.defaultProps = defaultProps;

export default RadioGroupCustom;
export const useRadio = () => useContext(RadioContext);
