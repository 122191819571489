import List from "./List";
import { useCallback } from "react";
import { useState } from "react";
import { useEditObjectiveMutation, useObjectiveListQuery } from "@clearpoint/query";
import { useMainStore } from "@clearpoint/store";

let Edit = () => {
	let periodId = useMainStore((state) => state.periodId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { data: objectiveList } = useObjectiveListQuery({ periodId, scorecardId });
	let { objectId, name: oldName } = objectiveList[0] || {};
	let { mutate: editObjective } = useEditObjectiveMutation({ objectId });
	let [name, setName] = useState();
	let handleName = (e) => setName(e.target.value);
	let edit = useCallback(() => {
		editObjective({ name });
	}, [name, editObjective]);
	return (
		<div>
			<div>
				<label for="name">Name</label>
				<br />
				<input defaultValue={oldName} name="name" onChange={handleName} />
			</div>
			<div>
				<button onClick={edit}>Edit Objective</button>
			</div>
			<List />
		</div>
	);
};

export default Edit;
