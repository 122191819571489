let createToolbar = ({ cellFlag, noAlignmentFlag, noCssFlag, noHtmlFlag, simpleFlag }) => {
	let codeButton = noHtmlFlag ? "" : " customhtmlplugin";
	let cssButton = noCssFlag ? "" : " customcssplugin";
	let alignmentButtons = noAlignmentFlag ? "" : "alignleft aligncenter alignright alignjustify";
	if (simpleFlag) {
		let simpleToolbar = "bold italic underline fontfamily fontsize forecolor" + codeButton;
		return simpleToolbar;
	}

	return cellFlag
		? "bold italic underline | strikethrough bullist numlist blocks customimageplugin table link hr fontsize fontfamily forecolor backcolor " +
				alignmentButtons +
				" outdent indent " +
				"blockquote emoticons" +
				codeButton +
				cssButton +
				" help"
		: "blocks bold italic underline strikethrough | bullist numlist | customimageplugin table link hr fontsize fontfamily forecolor backcolor " +
				alignmentButtons +
				" outdent indent " +
				"blockquote emoticons" +
				codeButton +
				cssButton +
				" help";
};

export default createToolbar;
