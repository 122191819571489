import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import FormInputTextEditor from "@clearpoint/old-theme/Form/Input/TextEditor/Input.TextEditor";

import { useTranslate } from "@clearpoint/translate";

let EditCategoryTab = ({ disabled, ...props }) => {
	let translate = useTranslate();
	return (
		<>
			<FormFieldName
				disabled={disabled}
				name="name"
				object="category"
				label={translate("edit.categoryName")}
				autoFocus
			/>
			<FormInputTextEditor
				disabled={disabled}
				name="description"
				label={translate("edit.categoryDescription")}
				noCssFlag
			/>
		</>
	);
};

export default EditCategoryTab;
