import EditElementModalWindow from "./ModalWindow.EditElement";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node.isRequired,
};

let EditElementOnClick = ({ children, ...props }) => (
	<ModalWindowOnClick ModalWindow={EditElementModalWindow} {...props}>
		{children}
	</ModalWindowOnClick>
);

EditElementOnClick.propTypes = propTypes;
export default EditElementOnClick;
