let getAvailablePeriodList = ({ formLoadedFlag, measureGridData, measureId, periodGroup, periodList, scorecardId }) => {
	if (!formLoadedFlag) return;
	// get all possible periods for this periodGroup / measure combo
	if (periodGroup) {
		return periodGroup.periods
			?.filter(
				(period) =>
					period.selected &&
					(period.scorecardList?.length === 0 || period.scorecardList?.indexOf(scorecardId) !== -1)
			)
			?.map((period) => ({
				periodId: period.periodId,
				name: period.periodLabel || period.name,
				gridHidden: period.hideInGrid,
			}));
	} else if (measureId) {
		return measureGridData?.map((period) => ({
			periodId: period.periodId,
			name: period.periodName,
		}));
	} else {
		return periodList?.map((period) => ({
			periodId: period.periodId,
			name: period.periodLabel || period.name,
		}));
	}
};

export default getAvailablePeriodList;
