import Block from "@clearpoint/old-theme/Block";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyObject } from "@clearpoint/utils";
import { useFormatDate } from "@clearpoint/hooks";

let Footer = () => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { token, periodId } = useOldSession().session;
	let { get } = useOldQueryStore();
	let options = token && get({ object: "options" });
	let period = token && periodId && get({ object: "period", objectId: periodId });
	let { confidentialityStatement } = options?.options || emptyObject;

	return (
		<Block
			as="footer"
			display="flex"
			justifyContent="space-between"
			fontSize="12px"
			padding={`0px ${theme.smallSpace} ${theme.smallSpace}`}
		>
			<Block width="33%" textAlign="left">
				{options?.name} {period?.name} {translate("global.report")}
			</Block>
			<Block width="33%" textAlign="center">
				{confidentialityStatement}
			</Block>
			<Block width="33%" textAlign="right">
				{translate("library.generated")} {formatDate(new Date(), "grid")}, {translate("global.page")} &nbsp;
				<span className="current-page-number" /> {translate("global.of")} <span className="total-page-number" />
			</Block>
		</Block>
	);
};

export default Footer;
