import useEffectOnce from "./useEffectOnce";
import { useState } from "react";

let useOnScreenFlag = function (ref, options) {
	let [isIntersecting, setIntersecting] = useState(false);
	useEffectOnce(() => {
		let refCurrent = ref.current;
		let observer = new IntersectionObserver(
			([entry]) => {
				setIntersecting(entry.isIntersecting);
			},
			{
				rootMargin: "0px",
				...options,
			}
		);
		if (refCurrent) {
			observer.observe(refCurrent);
		}
		return () => {
			observer.unobserve(refCurrent);
		};
	});
	return isIntersecting;
};

export default useOnScreenFlag;
