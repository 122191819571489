import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { emptyObject } from "@clearpoint/utils";

let useSetSeriesNameAsChartTitle = () => {
	let { title: currentTitleValue } = useFormValue("chart");
	let {
		formDefaultValue: {
			chart: { title: defaultTitleValue },
		},
		formTouched,
		setFormValue,
	} = useFormContext();

	let titleInputTouchedFlag = formTouched.chart?.title;

	let setSeriesNameAsChartTitle = useCallback(
		(option) => {
			let { label } = option || emptyObject;
			let chartTitleClearedFlag = defaultTitleValue && !currentTitleValue;
			let chartTitleUntouchedFlag = !defaultTitleValue && !titleInputTouchedFlag;

			if (chartTitleClearedFlag || chartTitleUntouchedFlag) {
				setFormValue("chart.title", label);
			}
		},
		[defaultTitleValue, currentTitleValue, titleInputTouchedFlag, setFormValue]
	);

	return setSeriesNameAsChartTitle;
};
export default useSetSeriesNameAsChartTitle;
