import { useCallback, useEffect, useMemo, useState } from "react";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";
import { useUnmount } from "react-use";

import Block from "@clearpoint/old-theme/Block";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormModalSave from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import Loading from "@clearpoint/old-theme/Loading/index";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { theme } from "@clearpoint/old-theme/Theme";

import {
	useCheckAccess,
	useClearUpdatedLinks,
	useDisableLoadingModalSave,
	useEvaluationEnabledLookup,
	useFormatDate,
	useNavigateToScorecard,
	useStateObject,
} from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray, splitEditAndUpdateFieldData } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import EditFieldsTab from "./EditFieldsTab";
import LinkMapTab from "./LinkMapTab";
import LinksTab from "./LinksTab";
import MilestonesTab from "./MilestonesTab";
import UpdateDataTab from "./UpdateDataTab";
import UpdateFieldsTab from "./UpdateFieldsTab";
import useCheckApproval from "@clearpoint/hooks-dir/useCheckApproval";
import useToastApproval from "@clearpoint/hooks-dir/useToastApproval";
import { useOldSession } from "@clearpoint/old-session/index";
import ChartsTab from "@components/Elements/Shared/ChartsTab";
import NotificationsTab from "@components/Elements/Shared/NotificationsTab";
import SeriesTab from "@components/Elements/Shared/SeriesTab";

let modalWindowContentPropTypes = {
	addFlag: PropTypes.bool,
	approvalRequired: PropTypes.bool,
	linkList: PropTypes.array,
	onAdd: PropTypes.func,
	loadingFlag: PropTypes.bool,
};

let EditInitiativeModalWindowContent = ({ addFlag, approvalRequired, linkList, onAdd, loadingFlag }) => {
	let translate = useTranslate();
	let checkAccess = useCheckAccess();
	let { clear, loading } = useOldQueryStore();
	let evaluationEnabledFlag = useEvaluationEnabledLookup().initiative;
	let { access, name, objectId } = useFormValue();
	let { newObjectFlag } = useFormContext();
	let periodLockedFlag = useFormValue("updates.periodLocked");
	let period = useFormValue("updates.period");
	let editFlag = objectId ? checkAccess({ access, action: "edit" }) : true;
	let [formLoaded, setFormLoaded] = useState(false);

	let [{ saveModalVisible, savedFlag }, setState] = useStateObject({
		saveModalVisible: false,
		savedFlag: false,
	});
	let onSave = useCallback(
		(response) => {
			if (onAdd) {
				onAdd(response);
			}
			setState({ savedFlag: true });
		},
		[onAdd, setState]
	);
	let closeSaveModal = useCallback(() => setState({ saveModalVisible: false }), [setState]);
	let openSaveModal = useCallback(
		(selectTab) => {
			if (newObjectFlag && approvalRequired) {
				toast.warning(translate("approvals.changesAfterApproval"));
			} else if (newObjectFlag) {
				setState({ saveModalVisible: true });
			} else {
				selectTab();
			}
		},
		[approvalRequired, newObjectFlag, setState, translate]
	);

	useEffect(() => {
		if (!loading) {
			setFormLoaded(true);
		}
	}, [loading]);

	useUnmount(() => {
		if (savedFlag) clear({ object: "initiative", objectId });
	});

	let updateFieldsTabTitle = useMemo(
		() =>
			periodLockedFlag ? (
				<>
					<Icon name="locked" size="tiny" marginRight={theme.smallSpace} />
					{`${period} ${translate("global.locked")}`}
				</>
			) : (
				translate("edit.element.updateFields")
			),
		[period, periodLockedFlag, translate]
	);

	useDisableLoadingModalSave(loadingFlag);

	return (
		<>
			<ModalWindowHeader
				badgeIcon="initiative"
				badgeText={translate("initiative")}
				title={addFlag ? translate("edit.addElement", { element: "initiative" }) : name}
			/>
			<ModalWindowContent>
				{!formLoaded && (
					<Block width="100%">
						<Loading />
					</Block>
				)}
				{formLoaded && (
					<ModalWindowTabContainer>
						{editFlag && (
							<EditFieldsTab
								addFlag
								title={translate("edit.addElement", { element: "initiative" })}
								visible={addFlag}
							/>
						)}
						<UpdateFieldsTab
							onClick={openSaveModal}
							title={updateFieldsTabTitle}
							periodLockedFlag={periodLockedFlag}
						/>
						{editFlag && (
							<EditFieldsTab
								onClick={openSaveModal}
								title={translate("edit.element.editFields")}
								visible={!addFlag}
							/>
						)}
						<MilestonesTab onClick={openSaveModal} title={translate("milestones")} />
						{evaluationEnabledFlag && (
							<UpdateDataTab onClick={openSaveModal} title={translate("edit.element.updateElementData")} />
						)}
						{editFlag && evaluationEnabledFlag && (
							<SeriesTab onClick={openSaveModal} title={translate("global.series")} />
						)}
						{editFlag && evaluationEnabledFlag && (
							<ChartsTab onClick={openSaveModal} title={translate("global.charts")} />
						)}
						{editFlag && <LinksTab onClick={openSaveModal} title={translate("edit.links")} />}
						{editFlag && linkList && linkList.length > 0 && (
							<LinkMapTab onClick={openSaveModal} title={translate("edit.map.linkMap")} />
						)}
						<NotificationsTab onClick={openSaveModal} title={translate("edit.element.notification")} />
					</ModalWindowTabContainer>
				)}
			</ModalWindowContent>
			<ModalWindowFooter saveAndContinueFlag={!approvalRequired} />
			<FormModalSave
				object="initiative"
				approvalRequired={approvalRequired}
				onSave={onSave}
				modalVisible={saveModalVisible}
				submitUnchangedFlag
				close={closeSaveModal}
			/>
		</>
	);
};

let EditInitiativeModalWindow = ({ close, modalWindowVisible, onAdd, onEdit, ...props }) => {
	let formatDate = useFormatDate();
	let translate = useTranslate();
	let clearUpdatedLinks = useClearUpdatedLinks();
	let navigateToScorecard = useNavigateToScorecard();
	let [objectId, setObjectId] = useState(props.objectId);
	let { clear, get } = useOldQueryStore();
	let { session } = useOldSession();
	let { periodId, scorecardId } = session;
	let initiativeData = objectId && get({ object: "initiative", objectId });
	let initiativeUpdateData = objectId && periodId && get({ object: "initiative", objectId, periodId });
	let customFieldData = objectId && get({ object: "customField", parent: "initiative", parentId: objectId, periodId });
	let linkList = objectId && periodId && get({ parent: "initiative", parentId: objectId, object: "link", periodId });
	let shadowMeasureId = initiativeData?.shadowMeasureId;
	let measureSeriesList =
		shadowMeasureId && get({ parent: "measure", parentId: shadowMeasureId, object: "measureSeries" });
	let chartList = shadowMeasureId && get({ parent: "measure", parentId: shadowMeasureId, object: "chart" });
	let milestoneList = objectId && periodId && get({ parent: "initiative", parentId: objectId, object: "milestone" });
	let toastApproval = useToastApproval();
	let approvalRequired = useCheckApproval({ scorecardId })({ type: objectId ? "edits" : "adds" });

	let defaultValue = useMemo(() => {
		if (initiativeData && customFieldData) {
			let { editFieldData: editCustomFieldData, updateFieldData: updateCustomFieldData } =
				splitEditAndUpdateFieldData(customFieldData);
			return {
				...initiativeData,
				...editCustomFieldData,
				updates: { ...initiativeUpdateData, ...updateCustomFieldData },
				links: linkList?.filter((x) => x.object !== "milestone"),
				lastCalculated: undefined,
				seriesSortOrder: isArray(measureSeriesList) ? measureSeriesList?.map((x) => x.measureSeriesId) : emptyArray,
				chartSortOrder: isArray(chartList) ? chartList?.map((x) => x.chartId) : emptyArray,
				milestoneSortOrder: isArray(milestoneList) ? milestoneList?.map((x) => x.objectId) : emptyArray,
			};
		}
		return {
			linkMilestoneDates: 0,
			completedDate: formatDate(new Date(), "apiDateWithoutTimezone"),
			endDate: formatDate(lastDayOfMonth(new Date()), "apiDateWithoutTimezone"),
			startDate: formatDate(startOfMonth(new Date()), "apiDateWithoutTimezone"),
		};
	}, [
		initiativeData,
		customFieldData,
		formatDate,
		initiativeUpdateData,
		linkList,
		measureSeriesList,
		chartList,
		milestoneList,
	]);
	let onSubmit = useCallback(
		(value, response) => {
			if (!objectId && onAdd) {
				onAdd(response);
			} else if (objectId) {
				clear({ object: "detailLayout" });
				if (onEdit) onEdit();
			}
			let { changeQueued } = toastApproval({ response });
			if (!changeQueued) {
				toast.success(
					translate(objectId ? "toaster.messages.objects.objectUpdated" : "toaster.messages.objects.objectAdded", {
						object: "initiative",
					})
				);
				clearUpdatedLinks({ newLinkList: value?.links, oldLinkList: linkList });
				navigateToScorecard(value.scorecardId);
			}
		},
		[clear, clearUpdatedLinks, linkList, navigateToScorecard, objectId, onAdd, onEdit, toastApproval, translate]
	);

	let loadingFlag = objectId && [initiativeData, initiativeUpdateData, customFieldData, linkList].includes(undefined);
	let beforeSubmit = useCallback((submitValue) => {
		return submitValue.milestoneSortOrder ? { milestoneCustomSortEnabled: true, ...submitValue } : submitValue;
	}, []);
	return (
		<ModalWindowEdit
			{...props}
			beforeSubmit={beforeSubmit}
			object="initiative"
			modalWindowVisible={modalWindowVisible}
			close={close}
			color="desaturatedNormal"
			defaultValue={defaultValue}
			objectId={objectId}
			onSubmit={onSubmit}
			setObjectId={setObjectId}
			size="large"
			submitUnchangedFlag={!objectId}
			loadingFlag={loadingFlag}
			updateFlag
		>
			<EditInitiativeModalWindowContent
				approvalRequired={approvalRequired}
				loadingFlag={loadingFlag}
				addFlag={!objectId}
				linkList={linkList}
				onAdd={onAdd}
			/>
		</ModalWindowEdit>
	);
};

EditInitiativeModalWindowContent.propTypes = modalWindowContentPropTypes;

export default EditInitiativeModalWindow;
