import ChartListItem from "./ChartListItem";
import ChecklistCheckAll from "@clearpoint/old-theme/Checklist/Checklist.Check.All";
import ChecklistProvider, { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import DropdownItemAdd from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Add";
import DropdownItemEditMultiple from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.EditMultiple";
import DropdownItemTrashMultiple from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.TrashMultiple";
import DropdownSplitButtonAdd from "@clearpoint/old-theme/Dropdown/SplitButton/SplitButton.Add";
import EditChartModal from "./EditChartModal";
import EditMultipleChartsModal from "./EditMultipleChartsModal";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";
import FilterShowDeleted from "@clearpoint/old-theme/Filter/Filter.ShowDeleted";
import FormDragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import Help from "@clearpoint/old-theme/Help";
import Loading from "@clearpoint/old-theme/Loading/index";
import NoResults from "@clearpoint/old-theme/NoResults";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	children: PropTypes.node,
};
let FilterWrapper = ({ children }) => {
	let { measureId, shadowMeasureId } = useFormValue();
	let { get } = useOldQueryStore();
	let chartList = get({ parent: "measure", parentId: measureId || shadowMeasureId, object: "chart" });

	return !chartList ? (
		<Loading />
	) : (
		<ChecklistProvider>
			<FilterProvider data={chartList}>{children}</FilterProvider>
		</ChecklistProvider>
	);
};
FilterWrapper.propTypes = propTypes;

let ChartsTabContent = () => {
	let translate = useTranslate();
	let { data: chartList, setData: setChartList } = useFilter();
	let checklist = useChecklist();
	let objectIdList = checklist?.checkedObjectIdList;
	let { measureId, periodGroupId, shadowMeasureId } = useFormValue();
	return (
		<>
			<Help section="sect1" feature="measure-charts" />
			<FilterInput
				name="name"
				placeholder={`${translate("global.filter")} ${translate("charts")}...`}
				filterBy="name"
				left={<ChecklistCheckAll />}
				right={
					<>
						<FilterShowDeleted />
						<DropdownSplitButtonAdd
							showDropdownFlag
							ModalWindow={EditChartModal}
							measureId={measureId || shadowMeasureId}
							marginLeft={theme.smallSpace}
						>
							<DropdownItemAdd ModalWindow={EditChartModal} measureId={measureId || shadowMeasureId}>
								{translate("edit.element.addChart")}
							</DropdownItemAdd>
							<DropdownItemEditMultiple
								ModalWindow={EditMultipleChartsModal}
								objectIdList={objectIdList}
								measureId={measureId || shadowMeasureId}
							>
								{translate("edit.element.editMultiCharts")}
							</DropdownItemEditMultiple>
							<DropdownItemTrashMultiple
								object="chart"
								confirmBodyText={translate("edit.element.deleteMultiChartsText")}
								objectIdList={objectIdList}
								elementFlag
							>
								{translate("edit.element.deleteMultiCharts")}
							</DropdownItemTrashMultiple>
						</DropdownSplitButtonAdd>
					</>
				}
			/>
			<NoResults />
			<FormDragAndDropList name="chartSortOrder" list={chartList} listKey="chartId" setList={setChartList}>
				{chartList.map((x) => (
					<ChartListItem chart={x} key={x.chartId} periodGroupId={periodGroupId} />
				))}
			</FormDragAndDropList>
		</>
	);
};

let ChartsTab = () => {
	return (
		<FilterWrapper>
			<ChartsTabContent />
		</FilterWrapper>
	);
};

export default ChartsTab;
