import ModalOnClick from "../../Modal/Modal.OnClick";
import ModalWindowOnClick from "../../ModalWindow/ModalWindow.OnClick";
import SplitButton from "./SplitButton";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
	icon: PropTypes.string,
	Modal: PropTypes.elementType,
	showDropdownFlag: PropTypes.bool,
};

let Add = ({ children, icon, Modal, showDropdownFlag, ...props }) => {
	let filter = useFilter();
	if (filter?.deletedVisible) props.disabled = true;
	let checklist = useChecklist();
	if (checklist?.checkedObjectIdList.length === 0 && !showDropdownFlag) props.hideDropdownFlag = true;
	if (children?.filter?.((x) => !!x)?.length === 1) props.hideDropdownFlag = true;
	return (
		<SplitButton dropdownHeight="100%" data-testid="split-button-add" icon={icon || "add"} {...props}>
			{children}
		</SplitButton>
	);
};

Add.propTypes = propTypes;

let DropdownSplitButtonAdd = ({ Modal, ModalWindow, ...props }) =>
	Modal ? (
		<ModalOnClick Modal={Modal} {...props}>
			<Add {...props} />
		</ModalOnClick>
	) : ModalWindow ? (
		<ModalWindowOnClick ModalWindow={ModalWindow} {...props}>
			<Add {...props} />
		</ModalWindowOnClick>
	) : (
		<Add {...props} />
	);

export default DropdownSplitButtonAdd;
