import { useMemo } from "react";
import PropTypes from "prop-types";

import AddButton from "@clearpoint/old-theme/Button/Button.Add";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormModalLinkElement from "@clearpoint/old-theme/Form/Modal/LinkElement/LinkElementModal";
import Help from "@clearpoint/old-theme/Help";
import ListItemLink from "@clearpoint/old-theme/ListItem/Link/ListItem.Link";
import NoResults from "@clearpoint/old-theme/NoResults";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";
import { emptyArray } from "@clearpoint/utils";

import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";

let propTypes = {
	linkList: PropTypes.array,
};

let LinksTabContent = ({ linkList }) => {
	let translate = useTranslate();
	let { data: filteredLinkList } = useFilter();
	let linkTypeList = useMemo(
		() =>
			["objectives", "measures", "initiatives", "milestones", "actionItems", "risks"].filter((linkType) =>
				filteredLinkList.some((link) => link.fieldId === linkType)
			),
		[filteredLinkList]
	);

	return (
		<>
			<Help section="edit-element" feature="linkages" />
			<FilterInput
				name="filterLinks"
				filterBy="name"
				placeholder={translate("edit.filterLinks") + "..."}
				right={<AddButton color="primary" icon="linkTag" Modal={FormModalLinkElement} name="links" object="risk" />}
			/>
			{linkTypeList.map((linkType) => (
				<FormGroup key={linkType} title={translate(linkType)} paddingBottom={theme.tinySpace}>
					{filteredLinkList
						.filter((link) => link.fieldId === linkType && link.visible)
						.map((link) => (
							<ListItemLink key={`${link.fieldId}${link.object}${link.objectId}`} link={link} />
						))}
				</FormGroup>
			))}
			{filteredLinkList && filteredLinkList.length === 0 && <NoResults feature="links" results={linkList} />}
		</>
	);
};

LinksTabContent.propTypes = propTypes;

let LinksTab = () => {
	let linkList = useFormValue("links") || emptyArray;
	return (
		<FilterProvider data={linkList}>
			<LinksTabContent linkList={linkList} />
		</FilterProvider>
	);
};

export default LinksTab;
