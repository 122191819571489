import Block from "../Block";
import { HotTable } from "@handsontable/react";
import useAfterBeginEditing from "./hooks/useAfterBeginEditing";
import useAfterOnCellMouseUp from "./hooks/useAfterOnCellMouseUp";
import useColumnWidthManager from "./useColumnWidthManager";
import useConstrainGridHeight from "./useConstrainGridHeight";
import useContextMenu from "./useContextMenu";
import { useCallback, useEffect, useMemo } from "react";
import { formEventLookup } from "../Form/Form";
import useLastSelected from "./hooks/useLastSelected";
import PropTypes from "prop-types";
import licenseKey from "@clearpoint/handsontable/license";
import { emptyObject } from "@clearpoint/utils";
import { useId, useMeasure, useOnEvent, useStatefulRef } from "@clearpoint/hooks";



let propTypes = {
	afterOnCellMouseDown: PropTypes.func,
	allowRowInsertionFlag: PropTypes.bool,
	columnHeaders: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string, PropTypes.bool]),
	columns: PropTypes.array,
	contextMenu: PropTypes.arrayOf(PropTypes.string),
	modalState: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func])),
	height: PropTypes.string,
	limitRowCountFlag: PropTypes.bool,
	rowHeaders: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string, PropTypes.bool]),
	stretchHorizontal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	width: PropTypes.string,
};

let Grid = ({
	afterOnCellMouseDown,
	allowRowInsertionFlag,
	columnHeaders,
	columns,
	contextMenu,
	data,
	modalState,
	limitRowCountFlag,
	stretchHorizontal,
	...props
}) => {
	let hotTableRef = useStatefulRef();
	let hotInstance = hotTableRef?.current?.hotInstance;

	let afterOnCellMouseDownHandler = useMemo(() => {
		if (!afterOnCellMouseDown) return;
		return (event, coordinates, cell) => afterOnCellMouseDown(event, coordinates, cell, hotTableRef);
	}, [afterOnCellMouseDown, hotTableRef]);

	let generatedContextMenu = useContextMenu(contextMenu, modalState, columns);
	let id = useId();
	let containerId = "hot-app-" + id;
	let { width: measuredContainerWidth } = useMeasure({ query: `#${containerId}` }) || emptyObject;
	let { columnListWithManuallySetWidthsRemoved, columnWidthList, overflowXFlag, updateColumnWidthList } =
		useColumnWidthManager({ columns, containerId, measuredContainerWidth });
	let loadedFlag = !!((measuredContainerWidth && columns) || columnHeaders);

	let dateValidator = useCallback((value, callback) => {
		callback(!!value && !!Date.parse(value));
	}, []);

	let afterBeginEditing = useAfterBeginEditing();
	let afterOnCellMouseUp = useAfterOnCellMouseUp();
	let { lastSelectedRef, saveLastCell } = useLastSelected();

	let resetSelectedCell = useCallback(() => {
		let selected = hotInstance?.getSelected()?.[0] || lastSelectedRef.current;
		if (!selected) return;
		let [row, col] = selected;
		if (row > 0 && col > 0) {
			let originalValue = hotInstance.getSourceDataAtCell(row, col);
			hotInstance.setDataAtCell(row, col, originalValue || "");
		}
	}, [hotInstance, lastSelectedRef]);

	let { resetForm } = formEventLookup;
	useOnEvent({ eventName: resetForm, handlerFunction: resetSelectedCell, dependencyList: [hotInstance] });

	useEffect(() => {
		if (!loadedFlag) return;

		if (Symbol.iterator in Object(columns)) {
			for (let column of columns) {
				if (column && column.type?.CELL_TYPE === "date") {
					column.type.validator = dateValidator;
				}
			}
		}

		if (!hotInstance) return;

		hotInstance.loadData(data);
		hotInstance.addHook("afterBeginEditing", afterBeginEditing);
		hotInstance.addHook("afterOnCellMouseUp", afterOnCellMouseUp);
		hotInstance.addHook("afterOnCellMouseDown", saveLastCell);
	}, [afterBeginEditing, afterOnCellMouseUp, columns, data, dateValidator, hotInstance, loadedFlag, saveLastCell]);

	let fillHandleConfig = useMemo(() => ({ autoInsertRow: true }), []);

	let constrainedHeight = useConstrainGridHeight({ containerId, data, limitRowCountFlag, overflowXFlag });
	let maxRows = allowRowInsertionFlag ? Infinity : data.length;

	return useMemo(
		() => (
			<Block
				id={containerId}
				data-testid="dataGrid"
				height={constrainedHeight || props.height}
				overflow="hidden"
				width="100%"
			>
				{loadedFlag && (
					<HotTable
						id="hot-grid"
						ref={hotTableRef}
						afterColumnResize={updateColumnWidthList}
						afterOnCellMouseDown={afterOnCellMouseDownHandler}
						allowInsertRow={allowRowInsertionFlag}
						columns={columnListWithManuallySetWidthsRemoved}
						colHeaders={columnHeaders}
						colWidths={columnWidthList}
						contextMenu={generatedContextMenu}
						fillHandle={fillHandleConfig}
						outsideClickDeselects={true}
						licenseKey={licenseKey}
						stretchH={stretchHorizontal}
						maxRows={maxRows}
						{...props}
						height={constrainedHeight || props.height}
						width={measuredContainerWidth || props.width}
					/>
				)}
			</Block>
		),
		[
			afterOnCellMouseDownHandler,
			allowRowInsertionFlag,
			columnHeaders,
			columnListWithManuallySetWidthsRemoved,
			columnWidthList,
			constrainedHeight,
			containerId,
			fillHandleConfig,
			generatedContextMenu,
			hotTableRef,
			loadedFlag,
			maxRows,
			measuredContainerWidth,
			props,
			stretchHorizontal,
			updateColumnWidthList,
		]
	);
};

Grid.propTypes = propTypes;

export default Grid;
