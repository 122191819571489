import FormSelect from "./FormSelect";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	scorecardId: PropTypes.number,
};
let SelectChart = ({ name, object, objectId, scorecardId, ...props }) => {
	// note, this sets measureId in hidden form field:
	// `_chart${chartId}measureId`
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let chartId = useFormValue(name);
	let formMeasureId = useFormValue(`_chart${chartId}measureId`);
	let { get } = useOldQueryStore();

	let chartListByElement = object && objectId && get({ parent: object, parentId: objectId, object: "chart" });
	let chartListByScorecard = scorecardId && get({ object: "chart", scorecardId, queryString: "?list=true" });

	let options = useMemo(() => {
		let chartList = chartListByScorecard || chartListByElement;
		return chartList
			?.filter((x) => x.active)
			.map((chart) => ({
				group: chartListByScorecard ? chart.measure : undefined,
				label: chart.name,
				value: chart.chartId,
			}));
	}, [chartListByElement, chartListByScorecard]);
	useEffect(() => {
		let chartList = chartListByScorecard || chartListByElement;
		if (chartId) {
			let measureId = chartList?.find((x) => x.chartId === chartId)?.measureId;
			if (measureId !== formMeasureId) setFormValue(`_chart${chartId}measureId`, measureId);
		}
	}, [chartId, chartListByElement, chartListByScorecard, formMeasureId, setFormValue]);
	return (
		<FormSelect
			name={name}
			options={options}
			placeholder={translate("edit.selectElement", { element: "chart" })}
			{...props}
		/>
	);
};
SelectChart.propTypes = propTypes;

export default SelectChart;
