import FormSelectObject from "@clearpoint/old-theme/Form/Select/Select.Object";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { beautifyJson5 } from "@clearpoint/utils";
import { usePrevious } from "@clearpoint/hooks";



let SelectChartJson = () => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let customJson = useFormValue("customJson");
	let chartJsonTemplate = useFormValue("_chartJsonTemplate");
	let previousChartJsonTemplate = usePrevious(chartJsonTemplate);

	let setTemplate = useCallback(
		(e) => {
			if (e.value === undefined) return;
			if (e.value !== previousChartJsonTemplate) {
				setFormValue("customJson", beautifyJson5(e.value));
				setFormValue("_chartJsonTemplate", undefined);
			}
		},
		[previousChartJsonTemplate, setFormValue]
	);

	return (
		<FormSelectObject
			placeholder={translate("edit.chart.selectChartJson") + "..."}
			onChange={setTemplate}
			name="_chartJsonTemplate"
			object="chartJson"
			valueKey="chartJson"
			disabled={!!customJson}
			marginBottom={theme.smallSpace}
		/>
	);
};

export default SelectChartJson;
