import { queryClient, OBJECTIVE } from "@clearpoint/query";
let Invalidate = () => {
	let invalidate = () => {
		queryClient.invalidateQueries([{ object: OBJECTIVE }]);
	};
	return (
		<div>
			<button onClick={invalidate}>Invalidate</button>
		</div>
	);
};
export default Invalidate;
