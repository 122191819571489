import FormSaveModal from "@clearpoint/old-theme/Form/Modal/Form.Modal.Save";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ReportOptionsModal from "./ReportOptionsModal";
import { useCallback } from "react";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { useWindowContext } from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import PropTypes from "prop-types";
import { buildQueryString } from "@clearpoint/utils";
import { useStateObject } from "@clearpoint/hooks";

let saveModalPropTypes = {
	close: PropTypes.func,
	modalVisible: PropTypes.bool,
};

let SaveModal = ({ close, modalVisible }) => {
	let translate = useTranslate();
	let { clear } = useOldQueryStore();
	let { createDashboardLayoutPayload, setEditLayoutModalState } = useWindowContext();
	let scorecardId = useFormValue("scorecardId");

	let onSave = useCallback(() => {
		setEditLayoutModalState({
			addFlag: false,
			openReportOptionsModalFlag: true,
		});
		clear({
			object: "dashboardLayout",
			scorecardId,
		});
	}, [clear, scorecardId, setEditLayoutModalState]);

	return (
		<FormSaveModal
			beforeSubmit={createDashboardLayoutPayload}
			body={translate("layout.saveToEditReportOptionsText")}
			close={close}
			closeButtonText={translate("global.cancel")}
			confirmButtonText={translate("global.save")}
			modalVisible={modalVisible}
			object="dashboardLayout"
			onSave={onSave}
			queryStringOnSubmit={buildQueryString({ scorecardId })}
			submitUnchangedFlag
			toastMessage={translate("toaster.messages.global.layoutSaved")}
		/>
	);
};

let ReportOptionsButton = () => {
	let name = useFormValue("name");
	let { addFlag, openReportOptionsModalFlag } = useWindowContext();

	let [{ reportOptionsModalVisible, saveModalVisible }, setState] = useStateObject({
		reportOptionsModalVisible: !!openReportOptionsModalFlag,
		saveModalVisible: false,
	});
	let closeReportOptionsModal = useCallback(() => {
		setState({ reportOptionsModalVisible: false });
	}, [setState]);
	let closeSaveModal = useCallback(() => {
		setState({ saveModalVisible: false });
	}, [setState]);

	let openModal = useCallback(() => {
		if (addFlag) {
			setState({ saveModalVisible: true });
		} else {
			setState({ reportOptionsModalVisible: true });
		}
	}, [addFlag, setState]);

	return (
		<>
			<IconButton
				color="primary"
				data-testid="report-options-button"
				name="settings"
				onClick={openModal}
				disabled={!name}
			/>
			{reportOptionsModalVisible && <ReportOptionsModal modalVisible close={closeReportOptionsModal} />}
			{saveModalVisible && <SaveModal modalVisible close={closeSaveModal} />}
		</>
	);
};

SaveModal.propTypes = saveModalPropTypes;

export default ReportOptionsButton;
