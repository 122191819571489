import Block from "../Block";
import DropdownIcon from "../Icon/Icon.Dropdown";
import React, { Suspense, useCallback, useState } from "react";
import { theme } from "../Theme";
import { useId } from "@clearpoint/hooks";

let Draggable = React.lazy(() => import("react-draggable"));

let Collapse = ({ title, children, className, style, "data-testid": dataTestId }) => {
	let id = useId();
	let [collapsedFlag, setCollapsedFlag] = useState(true);
	let toggleCollapsed = useCallback(() => setCollapsedFlag((x) => !x), []);
	return (
		<Suspense>
			<Draggable offsetParent={document.body} handle={`.collapse-handle${id}`}>
				<Block
					border={`1px solid ${theme.lightGray5}`}
					backgroundColor={theme.white}
					marginBottom={theme.space}
					className={className}
					style={style}
					data-testid={dataTestId}
				>
					<Block
						borderTop={`2px solid ${theme.lightGray}`}
						display="flex"
						height="48px"
						alignItems="center"
						className={`collapse-handle${id}`}
						cursor="grab"
						padding={`0px ${theme.mediumSpace} 0px ${theme.space}`}
					>
						<Block display="flex" alignItems="center" flex={1} fontWeight={theme.bold} fontSize="14px">
							{title}
						</Block>
						<DropdownIcon
							color="success"
							data-testid="dropdown-icon"
							direction="left"
							dropdownFlag={!collapsedFlag}
							onClick={toggleCollapsed}
						/>
					</Block>
					{!collapsedFlag && (
						<Block border={`1px solid ${theme.lightGray5}`} padding={`${theme.space} ${theme.mediumSpace}`}>
							{children}
						</Block>
					)}
				</Block>
			</Draggable>
		</Suspense>
	);
};
export default Collapse;
