import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectFontSize = (props) => {
	let translate = useTranslate();
	let options = useMemo(() => [10, 12, 14, 16, 20, 24, 36].map((x) => ({ label: x + "px", value: x + "px" })), []);
	return (
		<FormSelect options={options} placeholder={translate("options.admin.styles.font.selectFontSize")} {...props} />
	);
};
export default SelectFontSize;
