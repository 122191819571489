import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import Discussion from "@clearpoint/old-theme/Discussion/index";
import Loading from "@clearpoint/old-theme/Loading/index";

import { useCheckFeature, useNearestPeriodId } from "@clearpoint/hooks";
import { DISCUSSION_BAR } from "@clearpoint/utils";

import Detail from "./Detail";
import Export from "./Export";
import Toolbar from "./Toolbar";
import DataProvider from "./Wrappers/DataProvider";
import FormWrapper from "./Wrappers/FormWrapper";
import StateProvider from "./Wrappers/StateProvider";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	inModalFlag: PropTypes.bool,
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	periodId: PropTypes.number,
	scorecardId: PropTypes.number,
};

let ViewDetail = ({ inModalFlag, layoutId, object, objectId, periodId, scorecardId }) => {
	let { session } = useOldSession();
	layoutId = layoutId || session.layoutId;
	object = object || session.object;
	objectId = objectId || session.objectId;
	scorecardId = scorecardId || session.scorecardId;

	let nearestPeriodIdOnScorecard = useNearestPeriodId({ scorecardId });
	periodId = periodId || nearestPeriodIdOnScorecard;

	let checkFeature = useCheckFeature();
	let discussionBarEnabledFlag = checkFeature(DISCUSSION_BAR);

	return object === "map" ? (
		<Loading />
	) : (
		<DataProvider
			layoutId={layoutId}
			object={object}
			objectId={objectId}
			periodId={periodId}
			scorecardId={scorecardId}
		>
			<StateProvider inModalFlag={inModalFlag} periodId={periodId}>
				<FormWrapper periodId={periodId}>
					{session.exportFlag ? (
						<Export />
					) : (
						<Block
							data-testid={`${object}-detail-page`}
							className="detail-page-container"
							display="flex"
							flexDirection="column"
							height="100%"
						>
							<Block display="flex" flex="1 0 1px" height="100%" maxWidth="100%" overflowX="hidden">
								<Block height="100%" width="100%" minWidth="0" display="flex" flexDirection="column">
									<Toolbar />
									<Detail />
								</Block>
								{discussionBarEnabledFlag && (
									<Discussion
										object={object}
										layoutId={layoutId}
										objectId={objectId}
										scorecardId={scorecardId}
									/>
								)}
							</Block>
						</Block>
					)}
				</FormWrapper>
			</StateProvider>
		</DataProvider>
	);
};

ViewDetail.propTypes = propTypes;
export default ViewDetail;
