import Block from "../../../../Block";
import Input from "../../../../Form/Input/Input";
import Select from "../../../../Form/Select/FormSelect";
import StyleWrapper from "../../../../StyleWrapper";
import { useEffect, useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string.isRequired,
};

let InputDateRange = ({ name, ...props }) => {
	let translate = useTranslate();
	let { setSession } = useOldSession();
	let beforeOrAfterOptions = useMemo(
		() => [
			{ label: translate("layout.isAfter"), value: "after" },
			{ label: translate("layout.isBefore"), value: "before" },
		],
		[translate]
	);
	let nowOrPeriodOptions = useMemo(
		() => [
			{ label: translate("layout.currentDate"), value: "now" },
			{ label: translate("layout.periodDate"), value: "period" },
		],
		[translate]
	);
	let plusOrMinusOptions = useMemo(
		() => [
			{ label: translate("layout.plus"), value: "plus" },
			{ label: translate("layout.minus"), value: "minus" },
		],
		[translate]
	);
	useEffect(() => {
		setSession({
			beforeOrAfterOptions,
			nowOrPeriodOptions,
			plusOrMinusOptions,
		});
	}, [beforeOrAfterOptions, nowOrPeriodOptions, plusOrMinusOptions, setSession]);

	return (
		<>
			<Block display="flex" {...props}>
				<StyleWrapper flexBasis="25%">
					<Select
						data-testid="before-or-after"
						options={beforeOrAfterOptions}
						defaultValue="before"
						name={`${name}._beforeOrAfter`}
					/>
					<Select
						data-testid="now-or-period"
						options={nowOrPeriodOptions}
						defaultValue="period"
						name={`${name}._nowOrPeriod`}
					/>
					<Select
						data-testid="plus-or-minus"
						options={plusOrMinusOptions}
						defaultValue="plus"
						name={`${name}._plusOrMinus`}
					/>
					<Input
						data-testid="day-count"
						name={`${name}._dayCount`}
						type="number"
						min="0"
						placeholder={translate("layout.days")}
						required
					/>
				</StyleWrapper>
			</Block>
		</>
	);
};
InputDateRange.propTypes = propTypes;

export default InputDateRange;
