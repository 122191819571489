import { useCallback, useRef } from "react";

let useLastSelected = () => {
	let lastSelectedRef = useRef();
	let saveLastCell = useCallback((_, coords) => {
		let { row, col } = coords;
		lastSelectedRef.current = [row, col];
	}, []);
	return { saveLastCell, lastSelectedRef };
};

export default useLastSelected;
