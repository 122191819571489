import Block from "../../Block";
import ChartPreview from "../../Chart/Chart.Preview";
import ChartSelect from "../../Form/Select/Select.Chart";
import Column from "../../Column";
import FormGroup from "../../Form/Form.Group";
import Hide from "../../Hide";
import Row from "../../Row";
import ScorecardSelect from "../../Form/Select/Select.Scorecard";
import { useEffect } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import getDeepValue from "lodash/get";
import { theme } from "../../Theme";
import { emptyObject } from "@clearpoint/utils";
import { usePreviousDefined } from "@clearpoint/hooks";

let wrapperPropTypes = {
	children: PropTypes.node,
	label: PropTypes.string,
};

let FormGroupWrapper = ({ children, label }) =>
	label ? (
		<FormGroup paddingBottom={theme.tinySpace} title={label} data-testid="chart">
			{children}
		</FormGroup>
	) : (
		children
	);

let propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

let Chart = ({ label, name, disabled }) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let value = useFormValue(name);
	let _chart = useFormValue(`_chart${name}`) || emptyObject;
	let scorecardId = _chart.scorecardId || session.scorecardId;
	let chartList = scorecardId && get({ object: "chart", scorecardId, queryString: "?list=true" });
	let { setFormValue, formDefaultValue } = useFormContext();
	let defaultValue = getDeepValue(formDefaultValue, name);

	useEffect(() => {
		if (value && isEmpty(_chart)) {
			let [scorecardId, measureId, chartId] = value.split(",");
			setFormValue(`_chart${name}`, {
				scorecardId: +scorecardId || session.scorecardId,
				measureId: +measureId,
				chartId: +chartId,
			});
		}
	}, [_chart, name, session.scorecardId, setFormValue, value]);

	let measureIdValue = value?.split(",")[1] && +value?.split(",")[1];
	let chartId = usePreviousDefined(_chart.chartId);
	let previousMeasureId = usePreviousDefined(measureIdValue);

	useEffect(() => {
		if (!defaultValue && !chartList) return;
		if (chartList && scorecardId && _chart.chartId) {
			let measureId = chartList.find((chart) => chart.chartId === _chart.chartId)?.measureId;
			let value = [scorecardId, measureId || previousMeasureId, _chart.chartId].join(",");
			setFormValue(name, value);
		}
	}, [_chart, scorecardId, chartList, name, setFormValue, previousMeasureId, chartId, defaultValue]);

	let measureId = measureIdValue || previousMeasureId;

	return (
		<FormGroupWrapper label={label}>
			{!!(chartId && measureId) && (
				<Block marginBottom={theme.space} position="relative">
					<ChartPreview chartId={chartId} measureId={measureId} />
				</Block>
			)}
			<Row>
				<Column large={6}>
					<ScorecardSelect disabled={disabled} label={translate("scorecard")} name={`_chart${name}.scorecardId`} />
				</Column>
				<Column large={6}>
					<Hide visible={!!_chart.scorecardId}>
						<ChartSelect
							disabled={disabled}
							label={translate("chart")}
							scorecardId={_chart.scorecardId || session.scorecardId}
							name={`_chart${name}.chartId`}
						/>
					</Hide>
				</Column>
			</Row>
		</FormGroupWrapper>
	);
};

FormGroupWrapper.propTypes = wrapperPropTypes;
Chart.propTypes = propTypes;
export default Chart;
