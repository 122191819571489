import FormSelect from "../../Form/Select/FormSelect";
import useAvailablePeriods from "../hooks/useAvailablePeriods";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	name: PropTypes.string,
};

let FormSelectEmbedReportingPeriod = ({ name, ...props }) => {
	let translate = useTranslate();
	let availablePeriodList = useAvailablePeriods();
	let options = useMemo(() => {
		if (!availablePeriodList) return [];
		return [
			{ value: 0, label: translate("layout.currentPeriod") },
			{ value: -1, label: translate("edit.chart.showPeriodDropdown") },
			...availablePeriodList.map((period) => ({ value: period.periodId, label: period.name })),
		];
	}, [availablePeriodList, translate]);
	return <FormSelect defaultValue={-1} options={options} name={name} {...props} />;
};

FormSelectEmbedReportingPeriod.propTypes = propTypes;

export default FormSelectEmbedReportingPeriod;
