import { useObjectiveStatusQuery, useObjectiveListQuery } from "@clearpoint/query";
import { useMainStore } from "@clearpoint/store";

let Status = () => {
	let periodId = useMainStore((state) => state.periodId);
	let scorecardId = useMainStore((state) => state.scorecardId);
	let { data: objectiveList } = useObjectiveListQuery({ periodId, scorecardId });
	let objectId = objectiveList[0]?.objectId;
	let { data: objectiveStatus } = useObjectiveStatusQuery({ objectId, periodId });
	return (
		<div>
			<div>
				<strong>{objectiveStatus.name}</strong>
			</div>
			<div>{JSON.stringify(objectiveStatus, 0, 3)}</div>
		</div>
	);
};

export default Status;
