import { useCallback, useState } from "react";
import useEffectOnce from "./useEffectOnce";
import { useTranslate } from "@clearpoint/translate";
import handleDocumentClickFunction from "./useDiscardAnchorHandler.handleDocumentClickFunction";
import { toast } from "@clearpoint/services/toastService/index";

// useDiscardAnchorHandler --- Changes how anchors work based on preventNavigateCheck
// preventNavigateCheck: func that returns true or false

let originalOnClick = document.onclick;
let useDiscardAnchorHandler = (preventNavigateCheck = () => true) => {
	let [discardModalVisible, setDiscardModalVisible] = useState(false);
	let [targetHref, setTargetHref] = useState("");
	let translate = useTranslate();

	useEffectOnce(() => {
		let handleDocumentClick = handleDocumentClickFunction({
			setTargetHref,
			setDiscardModalVisible,
			preventNavigateCheck,
		});
		document.onclick = (e) => handleDocumentClick(e);

		return () => {
			setDiscardModalVisible(false);
			document.onclick = originalOnClick;
		};
	});

	let closeDiscardModal = useCallback(() => {
		toast.warning(translate("global.navigationCancelled"));
		setDiscardModalVisible(false);
	}, [translate]);

	let onDiscardModalDiscard = useCallback(() => {
		window.location.href = targetHref;
		setDiscardModalVisible(false);
	}, [targetHref]);

	return { discardModalVisible, closeDiscardModal, onDiscardModalDiscard };
};

export default useDiscardAnchorHandler;
