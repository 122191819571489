import { createContext, useContext } from "react";
import PropTypes from "prop-types";

import { useEffectOnce, useGanttData } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";

import useCustomFieldData from "./useCustomFieldData";
import useElementData from "./useElementData";
import useLayoutData from "./useLayoutData";
import useLinkData from "./useLinkData";
import useMeasureGridData from "./useMeasureGridData";
import { useOldSession } from "@clearpoint/old-session/index";

let DetailPageDataContext = createContext();

let propTypes = {
	children: PropTypes.node,
	layoutId: PropTypes.number,
	object: PropTypes.string,
	objectId: PropTypes.number,
	periodId: PropTypes.number,
	scorecardId: PropTypes.number,
};

let DataProvider = ({ children, layoutId, object, objectId, periodId, scorecardId }) => {
	let { excelFlag } = useOldSession().session;
	if (excelFlag) object = "measure";
	let { clear } = useOldQueryStore();

	let { element, elementQuery, elementStatusQuery, elementStatus } = useElementData({
		object,
		objectId,
		periodId,
		scorecardId,
	});

	let { customFieldData, customFieldQuery } = useCustomFieldData({
		object,
		layoutId,
		objectId,
		periodId,
		scorecardId,
	});

	let { layout, layoutQuery } = useLayoutData({
		element,
		layoutId,
		object,
		objectId,
		periodId,
	});

	let { linkList, linkListQuery } = useLinkData({ object, objectId, periodId });

	let { measureGridQuery, measureGrid, measureSeriesQuery, measureSeriesList } = useMeasureGridData({
		element,
		object,
		objectId,
	});

	let ganttData = useGanttData({ object, objectId, periodId, scorecardId });

	object = element?.object || object;
	objectId = element?.objectId || objectId;

	return (
		<DetailPageDataContext.Provider
			value={{
				customFieldData,
				customFieldQuery,
				element,
				elementQuery,
				elementStatus,
				elementStatusQuery,
				ganttData,
				layout,
				layoutQuery,
				linkList,
				linkListQuery,
				measureGrid,
				measureGridQuery,
				measureSeriesList,
				measureSeriesQuery,
				object,
				objectId,
			}}
		>
			{children}
		</DetailPageDataContext.Provider>
	);
};

DataProvider.propTypes = propTypes;
export default DataProvider;
export const useDetailPageData = () => useContext(DetailPageDataContext);
