import EditFieldsTab from "./EditFieldsTab";
import MeasureSeriesTab from "./MeasureSeriesTab";
import ModalWindowContent from "@clearpoint/old-theme/ModalWindow/ModalWindow.Content";
import ModalWindowEdit from "@clearpoint/old-theme/ModalWindow/ModalWindow.Edit";
import ModalWindowFooter from "@clearpoint/old-theme/ModalWindow/ModalWindow.Footer";
import ModalWindowHeader from "@clearpoint/old-theme/ModalWindow/ModalWindow.Header";
import ModalWindowTabContainer from "@clearpoint/old-theme/ModalWindow/ModalWindow.TabContainer";
import { useCallback, useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";

let propTypes = {
	parentId: PropTypes.number,
	objectIdList: PropTypes.arrayOf(PropTypes.number),
};
let EditMultipleMeasureSeriesModal = ({ parentId, objectIdList, ...props }) => {
	let addFlag = !objectIdList;
	let translate = useTranslate();
	let { session } = useOldSession();
	let { scorecardId } = session;
	let defaultValue = useMemo(
		() => ({
			scorecardId,
			tags: [],
		}),
		[scorecardId]
	);

	let onSubmit = useCallback(() => {
		toast.success(
			translate(objectIdList?.length ? "toaster.messages.series.multiSeriesUpdated" : "edit.series.seriesAdded", {
				count: objectIdList?.length,
			})
		);
	}, [objectIdList, translate]);

	return (
		<ModalWindowEdit
			color="desaturatedNormal"
			defaultValue={addFlag ? defaultValue : undefined}
			parent="measure"
			object="measureSeries"
			onSubmit={onSubmit}
			parentId={parentId}
			objectIdList={objectIdList}
			size="medium"
			submitUnchangedFlag={!objectIdList}
			{...props}
		>
			<ModalWindowHeader
				badgeIcon="measureSeries"
				badgeText={translate("labels.measureSeries")}
				title={`${translate(addFlag ? "edit.element.addElement" : "global.editMultiple")} ${translate(
					"labels.measureSeries"
				)}`}
			/>
			<ModalWindowContent>
				<ModalWindowTabContainer>
					<MeasureSeriesTab
						title={translate("labels.measureSeries")}
						parentId={parentId}
						objectIdList={objectIdList}
					/>
					{!addFlag && <EditFieldsTab title={translate("edit.element.editFields")} />}
				</ModalWindowTabContainer>
			</ModalWindowContent>
			<ModalWindowFooter />
		</ModalWindowEdit>
	);
};
EditMultipleMeasureSeriesModal.propTypes = propTypes;
export default EditMultipleMeasureSeriesModal;
