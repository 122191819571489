import Block from "../../../Block";
import Hide from "../../../Hide";
import Image from "../../../Image";
import useFormValue from "../../../Form/hooks/useFormValue";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
};

let PreviewImage = ({ label, name, onClick }) => {
	let localImage = useFormValue("_localImage");
	let imageFromAPI = useFormValue(name);
	let imageDisplayValue = localImage?.displayValue || imageFromAPI?.displayValue;
	let imageName = localImage?.name || imageFromAPI?.name;
	let imageExistsFlag = localImage || !!imageFromAPI?.value;

	let imageSrc = useMemo(
		() => ({
			[localImage ? "src" : "documentSrc"]: imageDisplayValue,
		}),
		[localImage, imageDisplayValue]
	);

	return (
		<Block
			onClick={onClick}
			data-testid="imagePaginatePreview"
			border={`3px dashed ${imageExistsFlag ? theme.danger : theme.info}`}
			backgroundColor={imageExistsFlag ? theme.white : theme.lightGray5}
			overflow="hidden"
			height="85px !important"
			minHeight="85px"
			maxHeight="85px"
			cursor={imageExistsFlag ? "pointer" : undefined}
		>
			<Hide visible={imageExistsFlag}>
				<Image {...imageSrc} alt={imageName || label} width="100%" />
			</Hide>
		</Block>
	);
};

PreviewImage.propTypes = propTypes;
export default PreviewImage;
