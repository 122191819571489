import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import Badge from "@clearpoint/old-theme/Badge/Badge";
import Block from "@clearpoint/old-theme/Block";
import UndeleteButton from "@clearpoint/old-theme/Button/Button.Undelete";
import KebabButton from "@clearpoint/old-theme/Dropdown/Kebab/KebabButton";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import PercentCompletePie from "@clearpoint/old-theme/PercentCompletePie";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import DeletedText from "@clearpoint/old-theme/Text/Text.Deleted";
import { theme } from "@clearpoint/old-theme/Theme";
import Tooltip from "@clearpoint/old-theme/Tooltip";

import { useFormatDate } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString } from "@clearpoint/utils";

import roundPercentComplete from "../../Shared/roundPercentComplete";
import useUpdateGoalPercentCompleteInForm from "../../Shared/useUpdateGoalPercentCompleteInForm";
import EditGoalDropdown from "./EditGoalDropdown";
import KeyResultTray from "./KeyResultTray";
import OwnerBadge from "./OwnerBadge";
import OwnerCircle from "./OwnerCircle";
import PriorityBadge from "./PriorityBadge";
import StatusIcon from "./StatusIcon";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	active: PropTypes.bool,
	archived: PropTypes.bool,
	deleteDate: PropTypes.string,
	detailPageFlag: PropTypes.bool,
	elementModalFlag: PropTypes.bool,
	goalId: PropTypes.number,
	groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	ownerId: PropTypes.number,
	periodId: PropTypes.number,
	priority: PropTypes.number,
	readOnlyFlag: PropTypes.bool,
	statusId: PropTypes.number,
	visible: PropTypes.bool,
};

let GoalListItem = ({
	active: activeFlag,
	archived: archivedFlag,
	deleteDate,
	detailPageFlag,
	elementModalFlag,
	goalId,
	groupId,
	name,
	ownerId,
	periodId,
	priority,
	readOnlyFlag,
	statusId,
	visible,
	...props
}) => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let formatDate = useFormatDate();
	let { setFormValue } = useFormContext();

	let keyResultList = useFormValue(`goalData[${goalId}].keyResults`);
	activeFlag = activeFlag || detailPageFlag;
	if (detailPageFlag) {
		let keyResultList = get({ parent: "goal", parentId: goalId, object: "keyResult" });
		setFormValue(`goalData[${goalId}].keyResults`, keyResultList);
	}

	let percentComplete =
		useUpdateGoalPercentCompleteInForm(keyResultList, `goalData[${goalId}].percentComplete`) || props.percentComplete;
	let periodList = get({ object: "period" });
	let endDate = periodList?.find?.((x) => x.periodId === periodId)?.periodDate;

	let mvp1Flag = true; // remove for MVP 2

	let { keyResultsDirtyFlag, openKeyResultTrayList, setLocalState } = useLocalState();

	let openGoalModal = useCallback(() => {
		setLocalState({ activeGroupId: groupId, modalGoalId: goalId, modalWindowVisible: true });
	}, [goalId, groupId, setLocalState]);

	let [listVisible, setListVisible] = useState(false);

	let addGoalIdToOpenTrayList = useCallback(() => {
		setLocalState({
			mostRecentlyOpenedKeyResultTray: goalId,
			openKeyResultTrayList: (openKeyResultTrayList) => [...new Set([...openKeyResultTrayList, goalId])],
		});
	}, [goalId, setLocalState]);

	let removeGoalIdFromOpenTrayList = useCallback(() => {
		setLocalState({
			openKeyResultTrayList: (openKeyResultTrayList) => openKeyResultTrayList.filter((id) => id !== goalId),
		});
	}, [goalId, setLocalState]);

	let toggleListVisible = useCallback(() => {
		setListVisible((listVisible) => {
			if (listVisible === false) {
				addGoalIdToOpenTrayList();
			} else {
				removeGoalIdFromOpenTrayList();
			}
			return !listVisible;
		});
	}, [addGoalIdToOpenTrayList, removeGoalIdFromOpenTrayList]);

	let allTraysClosedFlag = openKeyResultTrayList?.length === 0;

	// let ListItemComponent = allTraysClosedFlag && !elementModalFlag ? FormDragAndDropListItem : ListItem;
	// Return to the previous logic after the sorting endpoint on BE is ready.
	let ListItemComponent = ListItem;

	if (detailPageFlag) {
		ListItemComponent = ListItem;
		visible = activeFlag;
	}

	return (
		<>
			<ListItemComponent
				borderRadius={listVisible ? "4px 4px 0px 0px" : "4px"}
				visible={visible}
				left={
					<>
						<StatusIcon statusId={statusId} />
						{activeFlag && (
							<Block marginRight={theme.space}>
								<Tooltip
									placement="top"
									text={translate("tooltips.percentComplete", {
										percent: roundPercentComplete(percentComplete) || "0",
									})}
								>
									<PercentCompletePie
										fillColor="#23c6c8"
										backgroundColor={percentComplete ? "#a7e8e9" : theme.lightGray}
										percentComplete={percentComplete || 0}
										marginTop="0"
										size="28px"
									/>
								</Tooltip>
							</Block>
						)}
						{activeFlag ? (
							<Block
								as="a"
								fontSize="16px"
								cursor="pointer"
								onClick={!readOnlyFlag ? openGoalModal : undefined}
								href={
									readOnlyFlag
										? `/#/index/goals${buildQueryString({ mode: "list", view: "individual" })}`
										: undefined
								}
							>
								{name}
							</Block>
						) : (
							<>
								<Block fontSize="16px">{name}</Block>
								<Badge color="danger">{translate("global.deleted")}</Badge>
							</>
						)}
					</>
				}
				right={
					<>
						{mvp1Flag ? null : (
							<>
								{activeFlag && !archivedFlag && <PriorityBadge priority={priority} />}
								{activeFlag && !archivedFlag && <OwnerBadge ownerId={ownerId} />}
							</>
						)}
						{activeFlag ? (
							<>
								{endDate && (
									<StyleWrapper
										$style={`@media screen and (max-width: 992px) {
											display: none;
										}`}
									>
										<small>{translate("goals.dueBy", { endDate: formatDate(endDate, "medium") })}</small>
									</StyleWrapper>
								)}
								<OwnerCircle ownerId={ownerId} />
								{!readOnlyFlag && (
									<KebabButton disabled={keyResultsDirtyFlag}>
										<EditGoalDropdown name={name} objectId={goalId} discussionFlag={!elementModalFlag} />
									</KebabButton>
								)}
							</>
						) : (
							<>
								<DeletedText deleteDate={deleteDate} />
								<UndeleteButton
									object="goal"
									objectId={goalId}
									title={translate("edit.undeleteElement", { element: "goal" })}
									body={translate("button.undeleteConfirm", { name })}
									toast={translate("edit.elementUndeleted", { element: "goal" })}
								/>
							</>
						)}
						{!elementModalFlag &&
							(keyResultList?.length ? (
								<Icon
									name={listVisible ? "expanded" : "collapsed"}
									color={theme.blueLink}
									onClick={toggleListVisible}
								/>
							) : (
								<Icon name="collapsed" color="muted" />
							))}
					</>
				}
			/>
			{listVisible && visible && !elementModalFlag && (
				<KeyResultTray goalId={goalId} keyResultList={keyResultList} readOnlyFlag={readOnlyFlag} />
			)}
		</>
	);
};

GoalListItem.propTypes = propTypes;
export default GoalListItem;
