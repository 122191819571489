import Block from "@clearpoint/old-theme/Block";
import PercentCompletePie from "@clearpoint/old-theme/PercentCompletePie";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useTranslate } from "@clearpoint/translate";
import useUpdateGoalPercentCompleteInForm from "../../useUpdateGoalPercentCompleteInForm";
import { theme } from "@clearpoint/old-theme/Theme";

let CalculatedPercentComplete = () => {
	let translate = useTranslate();
	let { data: filteredKeyResultList } = useFilter();

	let percentComplete = useUpdateGoalPercentCompleteInForm(filteredKeyResultList);
	let percentCompleteToTwoDecimalPlaces = Number.parseFloat(percentComplete).toFixed(2);

	return (
		<Block
			display="flex"
			padding={theme.smallSpace}
			backgroundColor={theme.lighterGray}
			marginBottom={theme.space}
			alignItems="center"
			borderRadius="4px"
		>
			<Block marginRight={theme.space}>
				<PercentCompletePie
					fillColor="#23c6c8"
					backgroundColor={percentComplete ? "#a7e8e9" : theme.lightGray}
					percentComplete={percentComplete || 0}
					marginTop="0"
					size="28px"
				/>
			</Block>
			<Block>{`${translate("detailLayouts.elapsedPercentComplete")}: ${percentCompleteToTwoDecimalPlaces}%`}</Block>
		</Block>
	);
};

export default CalculatedPercentComplete;
