import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import Block from "@clearpoint/old-theme/Block";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import FormDragAndDropListSort from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Sort";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import Help from "@clearpoint/old-theme/Help";
import Loading from "@clearpoint/old-theme/Loading/index";
import { theme } from "@clearpoint/old-theme/Theme";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString } from "@clearpoint/utils";

import isListSortedByScorecard from "./isListSortedByScorecard";
import { useOldSession } from "@clearpoint/old-session/index";

let elementOrderTabPropTypes = {
  object: PropTypes.string,
  objectId: PropTypes.number,
};


let ElementOrderTab = ({ object }) => {
  let translate = useTranslate();
  let { get } = useOldQueryStore();
  let { session } = useOldSession();
  let sortOrderFlag = useFormValue("reportOptions.customSortEnabled");
  let { layoutId, object: formObject, reportType } = useFormValue();
  object = object || formObject;
  let { setFormValue } = useFormContext();
  let serverObjectList =
    object &&
    layoutId &&
    session.periodId &&
    get({
      object: "element",
      parent: `${object}Layout`,
      parentId: layoutId,
      queryString: buildQueryString({ object, fieldId: "default", periodId: session.periodId }),
    });
  let [objectList, setObjectListInner] = useState([]);
  let name = "reportOptions.customSort";
  let setObjectList = useCallback(
    (list) => {
      setFormValue(name, list);
      setObjectListInner(list);
    },
    [name, setFormValue]
  );

  useEffect(() => {
    if (serverObjectList) setObjectList(serverObjectList);
  }, [serverObjectList, setObjectList]);
  let keyList = ["object", "objectId", "sortOrder"];

  let ganttChartCustomSortOrderFlag = useMemo(
    () => !isListSortedByScorecard(objectList) && sortOrderFlag && reportType === "gantt",
    [objectList, sortOrderFlag, reportType]
  );

  let loadingFlag = serverObjectList === undefined;
  return loadingFlag ? (
    <Loading />
  ) : (
    <>
      <Help section="sect7" feature="report-grid-order" />
      {ganttChartCustomSortOrderFlag && (
        <Block color={theme.danger} fontSize="0.9em" lineHeight="1.43" padding="2px 0 5px" textAlign="left">
          {translate("error.ganttChartSortOrder")}
        </Block>
      )}
      <FormCheck marginBottom={theme.smallSpace} name="reportOptions.customSortEnabled">
        {translate("tabGridOrder.useCustomElementSortOrder")}
      </FormCheck>
      {sortOrderFlag && (
        <FormGroup marginTop="0px" title={translate("tabGridOrder.customSortOrder")}>
          <FormDragAndDropListSort
            keyList={keyList}
            name={name}
            object={object}
            objectList={objectList}
            setObjectList={setObjectList}
          />
        </FormGroup>
      )}
    </>
  );
};

ElementOrderTab.propTypes = elementOrderTabPropTypes;

export default ElementOrderTab;
