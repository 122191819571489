import { useEffect } from "react";
import getDeepValue from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import { useFormContext } from "../../Form/Form";
import FormGroup from "../../Form/Form.Group";
import useFormValue from "../../Form/hooks/useFormValue";
import LayoutSelect from "../../Form/Select/Select.Layout";
import ObjectTypeSelect from "../../Form/Select/Select.ObjectType";
import ScorecardSelect from "../../Form/Select/Select.Scorecard";
import { theme } from "../../Theme";

let propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};
let Grid = ({ label, name, disabled }) => {
	let translate = useTranslate();
	let formValue = useFormValue();
	let value = useFormValue(name);
	let _grid = useFormValue(`_grid${name}`) || emptyObject;
	let { setFormValue, formDefaultValue } = useFormContext();
	let defaultValue = getDeepValue(formDefaultValue, name);

	useEffect(() => {
		if (value && isEmpty(_grid)) {
			let [scorecardId, objectType, layoutId] = value.split(",");
			setFormValue(`_grid${name}`, {
				scorecardId: +scorecardId,
				objectType,
				layoutId: +layoutId,
			});
		}
	}, [_grid, name, setFormValue, value]);

	useEffect(() => {
		if (_grid.scorecardId && _grid.objectType && _grid.layoutId) {
			let value = [_grid.scorecardId, _grid.objectType, _grid.layoutId].join(",");
			setFormValue(name, value);
		}
	}, [_grid, defaultValue, formValue, name, setFormValue, value]);
	return (
		<FormGroup title={label} paddingBottom={theme.tinySpace} data-testid="grid">
			<ScorecardSelect disabled={disabled} label={translate("scorecard")} name={`_grid${name}.scorecardId`} />
			{_grid.scorecardId && (
				<ObjectTypeSelect
					disabled={disabled}
					label={translate("options.elementType")}
					name={`_grid${name}.objectType`}
				/>
			)}
			{_grid.scorecardId && _grid.objectType && (
				<LayoutSelect
					disabled={disabled}
					label={translate("global.report")}
					object={_grid.objectType}
					scorecardId={_grid.scorecardId}
					name={`_grid${name}.layoutId`}
				/>
			)}
		</FormGroup>
	);
};
Grid.propTypes = propTypes;
export default Grid;
