import Attachments from "@clearpoint/old-theme/Attachments/Attachments";
import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import CellWrapper from "../CellWrapper";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	cell: PropTypes.object,
	close: PropTypes.func,
	editCellFlag: PropTypes.bool,
};
let AttachmentsCell = (props) => {
	let translate = useTranslate();
	let { editTableFlag } = useLocalState();
	let { cell, close } = props;
	let { object, objectId } = cell;
	return (
		<CellWrapper {...props}>
			<Attachments editFlag={editTableFlag} object={object} objectId={objectId} />
			{editTableFlag && (
				<Block textAlign="right" marginTop={theme.smallSpace}>
					<Button color="default" onClick={close} size="small">
						{translate("global.close")}
					</Button>
				</Block>
			)}
		</CellWrapper>
	);
};

AttachmentsCell.propTypes = propTypes;
export default AttachmentsCell;
