import Button from "./Button";
import ModalDiscardOnClick from "../Modal/Modal.Discard.OnClick";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	className: PropTypes.string,
	onDiscard: PropTypes.func.isRequired,
	showDiscardModalFlag: PropTypes.bool,
	showDiscardModalOnFormDirtyFlag: PropTypes.bool,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

let DiscardButton = ({ className, onDiscard, showDiscardModalFlag, showDiscardModalOnFormDirtyFlag, style }) => {
	let translate = useTranslate();

	return (
		<ModalDiscardOnClick
			onDiscard={onDiscard}
			showDiscardModalFlag={showDiscardModalFlag}
			showDiscardModalOnFormDirtyFlag={showDiscardModalOnFormDirtyFlag}
		>
			<Button data-testid="cancel-button" className={className} type="button" color="transparent" style={style}>
				{translate("global.cancel")}
			</Button>
		</ModalDiscardOnClick>
	);
};

DiscardButton.propTypes = propTypes;

export default DiscardButton;
