let pluralize = (object) => {
	if (object === "data") return "data";
	if (object === "child") return "children";
	let lastChar = object.charAt(object.length - 1);
	if (lastChar === "y") return object.slice(0, -1) + "ies";
	if (lastChar !== "s") return object + "s";
	return object;
};

export default pluralize;
