import PaginationSelect from "@clearpoint/old-theme/Pagination/Select";
import { useCallback, useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useTranslate } from "@clearpoint/translate";

let SelectChartCount = () => {
	let translate = useTranslate();
	let { session, setSession } = useOldSession();
	let { dashboardChartCount } = session;
	let setDashboardChartCount = useCallback(
		(x) => {
			setSession({ dashboardChartCount: x.value });
		},
		[setSession]
	);
	let options = useMemo(
		() => [
			{ value: 8, label: translate("global.chartsPerPage", { chartCount: 8 }) },
			{ value: 12, label: translate("global.chartsPerPage", { chartCount: 12 }) },
			{ value: 24, label: translate("global.chartsPerPage", { chartCount: 24 }) },
			{ value: 48, label: translate("global.chartsPerPage", { chartCount: 48 }) },
			{ value: 999, label: translate("layout.displayAllCharts") },
		],
		[translate]
	);
	let value = options.find((x) => x.value === dashboardChartCount);
	return (
		<PaginationSelect options={options} value={value} onChange={setDashboardChartCount} minWidth="180px" inlineFlag />
	);
};
export default SelectChartCount;
