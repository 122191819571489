import { featureFlagListLookup } from "@clearpoint/utils";

let featureFlagInitialState = {
	disabledFeatureList: [],
	featureFlagList: featureFlagListLookup.featureFlagList,
};
let createFeatureFlagSlice = (set) => ({
	...featureFlagInitialState,
	setDisabledFeatureList: (disabledFeatureList) => set(() => ({ disabledFeatureList })),
	setFeatureFlagList: (featureFlagList) => set(() => ({ featureFlagList })),
});
export { featureFlagInitialState, createFeatureFlagSlice };
