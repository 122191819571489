import CellWrapper from "../CellWrapper";
import FormFieldDisplayName from "@clearpoint/old-theme/Form/Field/Display/Name";
import FormFieldName from "@clearpoint/old-theme/Form/Field/Name";
import PropTypes from "prop-types";

let propTypes = {
	editCellFlag: PropTypes.bool,
	parentName: PropTypes.string,
};
let NameCell = (props) => {
	let { editCellFlag, objectData, parentName } = props;
	return (
		<CellWrapper {...props}>
			{editCellFlag ? (
				<FormFieldName name={`${parentName}.name`} object={objectData.object} />
			) : (
				<FormFieldDisplayName name={`${parentName}.name`} noLinkFlag />
			)}
		</CellWrapper>
	);
};
NameCell.propTypes = propTypes;

export default NameCell;
