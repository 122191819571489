import CellWrapper from "../CellWrapper";
import FormFieldDisplayScorecardId from "@clearpoint/old-theme/Form/Field/Display/ScorecardId";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import PropTypes from "prop-types";

let propTypes = {
	parentName: PropTypes.string,
};
let ScorecardCell = (props) => {
	let { parentName } = props;
	let name = `${parentName}.scorecardId`;
	return (
		<CellWrapper {...props}>
			<StyleWrapper fontWeight="bold">
				<FormFieldDisplayScorecardId name={name} />
			</StyleWrapper>
		</CellWrapper>
	);
};
ScorecardCell.propTypes = propTypes;

export default ScorecardCell;
