import Block from "@clearpoint/old-theme/Block";
import LayoutPodListItem from "./LayoutPodListItem";
import { useSortable, defaultAnimateLayoutChanges } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PropTypes from "prop-types";

let animateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true });

let propTypes = {
	id: PropTypes.string,
	pod: PropTypes.shape({
		podWidth: PropTypes.string,
	}),
};

function SortableItem({ id, pod, ...props }) {
	let { attributes, listeners, setActivatorNodeRef, setNodeRef, transform, transition } = useSortable({
		animateLayoutChanges,
		id,
	});

	return (
		<Block
			className={pod?.podWidth?.replace("col-xs-", "col-")}
			ref={setNodeRef}
			transform={CSS.Translate.toString(transform)}
			transition={transition}
		>
			<LayoutPodListItem
				{...props}
				pod={pod}
				listeners={listeners}
				setActivatorNodeRef={setActivatorNodeRef}
				attributes={attributes}
			/>
		</Block>
	);
}

SortableItem.propTypes = propTypes;
export default SortableItem;
