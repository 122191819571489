let setCompletedRequests = ({ completedRequests, requests, setState, waitingRequestCount }) => {
	if (requests.current.length === 0) {
		waitingRequestCount.current = 0;
	}
	if (completedRequests.length > 0) {
		requests.current = requests.current.filter((x) => !completedRequests.some((y) => x.id === y.id));
		setState({
			completedRequests: (oldValue) => oldValue.filter((x) => !completedRequests.some((y) => x.id === y.id)),
		});
	}
};

export default setCompletedRequests;
