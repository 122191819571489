import PageTitle from "@clearpoint/old-theme/PageTitle/PageTitle";
import PageTitleScorecardSubtitle from "@clearpoint/old-theme/PageTitle/PageTitle.ScorecardSubtitle";

import { useTranslateOnlyInsideCurlyBraces } from "@clearpoint/translate";

import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let Title = () => {
	let translateOnlyInsideCurlyBraces = useTranslateOnlyInsideCurlyBraces();
	let { layout, ReferenceTitle } = useLocalState();
	let { name, scorecardId } = layout || {};

	return ReferenceTitle ? (
		<ReferenceTitle />
	) : (
		<PageTitle
			title={translateOnlyInsideCurlyBraces(name)}
			subTitle={<PageTitleScorecardSubtitle scorecardId={scorecardId} />}
		/>
	);
};
export default Title;
