import Block from "@clearpoint/old-theme/Block";
import PodListItem from "../PodListItem";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { createUniqueIdFromPod } from "@clearpoint/utils";

let propTypes = {
	id: PropTypes.string,
};

let PodListItemDragOverlay = ({ id }) => {
	let areaList = useFormValue("layout");
	let podList = areaList.map((x) => x.pods).flat();
	let pod = podList.find((x) => createUniqueIdFromPod(x) === id);

	return (
		<Block opacity="0.5">
			<PodListItem pod={pod} draggableFlag />
		</Block>
	);
};

PodListItemDragOverlay.propTypes = propTypes;
export default PodListItemDragOverlay;
