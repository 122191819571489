import { useScrollbarWidth } from "react-use/lib/useScrollbarWidth";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import constrainGridHeight from "./useConstrainGridHeight.constrainGridHeight";
import { emptyArray, emptyObject } from "@clearpoint/utils";
import { useMeasure } from "@clearpoint/hooks";

let useConstrainGridHeight = ({ containerId, data = emptyArray, limitRowCountFlag, overflowXFlag }) => {
	let { get } = useOldQueryStore();
	let scrollbarWidth = useScrollbarWidth();
	let { height: headerHeight } =
		useMeasure({ query: `#${containerId} .ht_clone_top_left_corner .wtHider` }) || emptyObject;
	let { exportFlag } = useOldSession().session;

	return constrainGridHeight({
		data,
		exportFlag,
		get,
		headerHeight,
		limitRowCountFlag,
		overflowXFlag,
		scrollbarWidth,
	});
};

export default useConstrainGridHeight;
