import { chartPeriodListContainsPeriod, formatSeriesNumber } from "../utils";

let createPieSeriesList = ({ seriesList, measureGridList, chartPeriodList }) => {
	let period = measureGridList.find((x) => chartPeriodListContainsPeriod(x, chartPeriodList));
	return [
		{
			colorByPoint: true,
			data: seriesList.map((series) => ({
				name: series.name,
				color: series.color,
				y: parseFloat(period?.["series" + series.measureSeriesId]) ?? 0,
			})),
			tooltip: {
				pointFormatter: function () {
					return formatSeriesNumber(this.y, seriesList[0].numberFormat);
				},
			},
		},
	];
};

export default createPieSeriesList;
