import ColorInput from "@clearpoint/old-theme/Form/Input/Color/FormInputColor";
import Column from "@clearpoint/old-theme/Column";
import { useTranslate } from "@clearpoint/translate";

let DualColor = () => {
	let translate = useTranslate();

	return (
		<>
			<Column tiny={6}>
				<ColorInput label={translate("global.primaryColor")} name="chart.color1" />
			</Column>
			<Column tiny={6}>
				<ColorInput label={translate("global.secondaryColor")} name="chart.color2" />
			</Column>
		</>
	);
};

export default DualColor;
