import Dropdown from "react-bootstrap/Dropdown";
import Portal from "../../Portal/Portal";
import StyleWrapper from "../../StyleWrapper";
import PropTypes from "prop-types";

let popperConfig = {
	strategy: "fixed",
};

let propTypes = {
	align: PropTypes.oneOf(["left", "right"]),
	children: PropTypes.node,
	renderDropdownMenuAboveBootstrapModalFlag: PropTypes.bool,
};

let SplitButtonMenu = ({ align, children, renderDropdownMenuAboveBootstrapModalFlag }) => (
	<Portal>
		<StyleWrapper align={align} zIndex={renderDropdownMenuAboveBootstrapModalFlag ? 1060 : undefined}>
			<Dropdown.Menu popperConfig={popperConfig}>{children}</Dropdown.Menu>
		</StyleWrapper>
	</Portal>
);

SplitButtonMenu.propTypes = propTypes;
export default SplitButtonMenu;
