import { useScrollbarWidth } from "react-use/lib/useScrollbarWidth";
import useYOverflowFlag from "./useOverflowYFlag";


let useContainerWidth = ({ measuredContainerWidth, containerId }) => {
	let yOverflowFlag = useYOverflowFlag({ containerId });
	let scrollbarWidth = useScrollbarWidth();

	return measuredContainerWidth - (yOverflowFlag ? scrollbarWidth : 0);
};

export default useContainerWidth;
