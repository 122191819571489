let getHandleResponse =
	({ getLoginRedirectFromHash, requestLookup, testFlag }) =>
	(res, key) => {
		delete requestLookup[key];
		let statusCode = res.data.statusCode || res.status;
		if (statusCode !== undefined && (statusCode > 299 || statusCode === 0)) {
			if (statusCode === 403) {
				let url = getLoginRedirectFromHash(window.location.hash);
				window.location.assign(url);
			}
			if (testFlag) {
				throw JSON.stringify(res.data);
			} else {
				throw res.data;
			}
		}
		return res.data;
	};

export default getHandleResponse;
