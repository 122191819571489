import FormSelect from "../../../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string,
};

let SelectAggregation = ({ disabled, name }) => {
	let translate = useTranslate();
	let aggregationOptions = useMemo(
		() => [
			{ value: "CurrentPeriod", label: translate("timeAggregates.currentPeriod") },
			{ value: "PreviousPeriod", label: translate("timeAggregates.previousPeriod") },
			{ value: "Back{{NPeriod}}", label: translate("timeAggregates.backNPeriod") },
			{ value: "SpecificPeriod", label: translate("timeAggregates.specificPeriod") },
			{ value: "PreviousYear", label: translate("timeAggregates.previousYear") },
			{ value: "CalendarYTDSum", label: translate("timeAggregates.calendarYTDSum") },
			{
				value: "CalendarYTDCount",
				label: translate("timeAggregates.calendarYTDCount"),
			},
			{
				value: "CalendarYTDAverage",
				label: translate("timeAggregates.calendarYTDAverage"),
			},
			{ value: "FiscalYTDSum", label: translate("timeAggregates.fiscalYTDSum") },
			{ value: "FiscalYTDCount", label: translate("timeAggregates.fiscalYTDCount") },
			{
				value: "FiscalYTDAverage",
				label: translate("timeAggregates.fiscalYTDAverage"),
			},
			{
				value: "SemesterToDateSum",
				label: translate("timeAggregates.semesterToDateSum"),
			},
			{
				value: "SemesterToDateCount",
				label: translate("timeAggregates.semesterToDateCount"),
			},
			{
				value: "SemesterToDateAverage",
				label: translate("timeAggregates.semesterToDateAverage"),
			},
			{
				value: "QuarterToDateSum",
				label: translate("timeAggregates.quarterToDateSum"),
			},
			{
				value: "QuarterToDateCount",
				label: translate("timeAggregates.quarterToDateCount"),
			},
			{
				value: "QuarterToDateAverage",
				label: translate("timeAggregates.quarterToDateAverage"),
			},
			{ value: "MonthToDateSum", label: translate("timeAggregates.monthToDateSum") },
			{
				value: "MonthToDateCount",
				label: translate("timeAggregates.monthToDateCount"),
			},
			{
				value: "MonthToDateAverage",
				label: translate("timeAggregates.monthToDateAverage"),
			},
			{ value: "Last{{NPeriod}}Sum", label: translate("timeAggregates.lastSum") },
			{ value: "Last{{NPeriod}}Count", label: translate("timeAggregates.lastCount") },
			{ value: "Last{{NPeriod}}Average", label: translate("timeAggregates.lastAverage") },
		],
		[translate]
	);
	return (
		<FormSelect
			placeholder={translate("edit.selectAggregation")}
			options={aggregationOptions}
			name={name}
			defaultValue="CurrentPeriod"
			disabled={disabled}
		/>
	);
};

SelectAggregation.propTypes = propTypes;

export default SelectAggregation;
