import ChartTypeListItem from "./ChartTypeListItem";
import FormModal from "@clearpoint/old-theme/Form/Modal/Form.Modal";
import FormSelectChartCategory from "@clearpoint/old-theme/Form/Select/Select.ChartCategory";
import RadioGroupCustom from "@clearpoint/providers/CustomRadioGroup";
import Row from "@clearpoint/old-theme/Row";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useChartTypeList } from "@clearpoint/hooks";


let propTypes = {
	close: PropTypes.func.isRequired,
	modalVisible: PropTypes.bool.isRequired,
	setChartFormatData: PropTypes.func.isRequired,
};

let ChartTypeModal = ({ close, modalVisible, setChartFormatData }) => {
	let translate = useTranslate();
	let [modalChartFormatData, setModalChartFormatData] = useState();
	let { defaultChartTypeList, customChartTypeList } = useChartTypeList();
	let chartCategory = useFormValue("category");
	let { setFormValue } = useFormContext();

	let chartTypeList = useMemo(() => {
		if (chartCategory === "chart.custom") {
			return customChartTypeList;
		}
		return defaultChartTypeList.filter((x) => x.category === chartCategory);
	}, [chartCategory, customChartTypeList, defaultChartTypeList]);

	let updateChartPreview = useCallback(() => {
		setFormValue("customSeries", undefined);
		setChartFormatData(modalChartFormatData);
	}, [modalChartFormatData, setChartFormatData, setFormValue]);

	return (
		<FormModal
			modalVisible={modalVisible}
			closeButtonText={translate("global.select")}
			close={close}
			name="chartType"
			title={translate("select.selectChartType")}
			size="huge" // probably should be large, but large modals are 800px wide in React and 900px wide in Angular, and 800px makes the Half Circle Gauge look terrible. Might be worth looking in to
			fixedHeightFlag
			onSave={updateChartPreview}
		>
			<FormSelectChartCategory name="category" />
			<RadioGroupCustom name="chartType" boldFlag>
				<Row>
					{chartTypeList.map((x) => (
						<ChartTypeListItem
							key={x.chartTypeId || x.customChartTypeId}
							chartType={x}
							setModalChartFormatData={setModalChartFormatData}
						/>
					))}
				</Row>
			</RadioGroupCustom>
		</FormModal>
	);
};

ChartTypeModal.propTypes = propTypes;
export default ChartTypeModal;
