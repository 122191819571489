import FormCheck from "../../Form/Check/Form.Check";
import PropTypes from "prop-types";

let propTypes = {
	children: PropTypes.node,
};

let FormChecklistCheck = ({ children, ...props }) => {
	return (
		<FormCheck checklistFlag {...props}>
			{children}
		</FormCheck>
	);
};

FormChecklistCheck.propTypes = propTypes;

export default FormChecklistCheck;
