import FormSelect from "../../Form/Select/FormSelect";
import { useEffect, useMemo } from "react";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import isEqual from "lodash/isEqual";
import { useMemoOnce, usePrevious } from "@clearpoint/hooks";


let SelectLegend = (props) => {
	let translate = useTranslate();
	let { name } = props;
	let { setFormValue } = useFormContext();
	let internalValue = useFormValue("_legend");
	let valueInForm = useFormValue(props.name);
	let previousValueInForm = usePrevious(valueInForm);
	let defaultValue = useMemoOnce(() => valueInForm || "chartDefault");

	// set null values without angering the select component
	useEffect(() => {
		if (!isEqual(internalValue, valueInForm) || !isEqual(valueInForm, previousValueInForm)) {
			let newValue = internalValue;
			if (newValue === "chartDefault") newValue = null;
			setFormValue(name, newValue);
		}
	}, [internalValue, previousValueInForm, name, setFormValue, valueInForm]);

	let options = useMemo(
		() => [
			{ value: "chartDefault", label: translate("edit.chartSeries.chartDefault") },
			{ value: true, label: translate("notifications.enabled") },
			{ value: false, label: translate("notifications.disabled") },
		],
		[translate]
	);

	return <FormSelect options={options} defaultValue={defaultValue} {...props} name="_legend" />;
};

export default SelectLegend;
