import fetchData from "./useFiltered.fetchData";
import returnDataWithTotal from "./useFiltered.returnDataWithTotal";
import updateDataFromCache from "./useFiltered.updateDataFromCache";
import updateDataFromStorage from "./useFiltered.updateDataFromStorage";

let generateGetFilteredExportList =
	({
		activityList,
		apiKeyList,
		count,
		get,
		getPromise,
		object,
		objectId,
		objectList,
		periodList,
		previousFilterQuery,
		scorecardList,
		search,
		setFilterCache,
		storedData,
		userList,
	}) =>
	(startIndex, itemsPerPage) => {
		if (itemsPerPage > 0 && startIndex > 0) {
			let filterQuery =
				object && objectId // may need to change the api to make this logic simpler
					? {
							activityList,
							apiKeyList,
							object,
							objectId,
							periodList,
							search,
							userList,
					  }
					: {
							activityList,
							apiKeyList,
							objectList,
							periodList,
							scorecardList,
							search,
							userList,
					  };

			// Attempt early return with stored data if filter query hasn't changed
			let returnData = updateDataFromCache({
				count,
				filterQuery,
				previousFilterQuery,
				storedData,
				itemsPerPage,
				startIndex,
				setFilterCache,
			});
			if (returnData) return returnData;
			// fetchData
			let { fetchedData, fetchPromise } = fetchData({
				dataKey: "logFile",
				filterQuery,
				get,
				getPromise,
				itemsPerPage,
				setFilterCache,
				startIndex,
			});
			fetchPromise.then((response) => {
				updateDataFromStorage({
					dataKey: "logFile",
					itemsPerPage,
					response,
					setFilterCache,
					startIndex,
					storedData,
				});
			});
			// returnDataWithTotal
			return returnDataWithTotal({ count, dataKey: "logFile", fetchedData, setFilterCache });
		}
	};

export default generateGetFilteredExportList;
