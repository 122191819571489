import { useMemo } from "react";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";

import { isEmptyOrUndefinedObject } from "@clearpoint/utils";

let useFormFieldsLoading = ({ fieldList, addFlag, updateFlag }) => {
	let { formValue } = useFormContext();

	let isLoading = useMemo(() => {
		if (addFlag) return false;
		if (isEmptyOrUndefinedObject(formValue) || (updateFlag && isEmptyOrUndefinedObject(formValue?.updates)))
			return true;

		let formValueKeys = updateFlag ? Object.keys(formValue.updates) : Object.keys(formValue);
		for (const field of fieldList) {
			switch (true) {
				case field.value === "startAndEndDate":
					if (!formValueKeys.includes("startDate") || !formValueKeys.includes("endDate")) return true;
					break;
				case field.value === "periodGroupId":
					if (!formValueKeys.includes("periodGroup")) return true;
					break;
				case field.value === "completedDate" && !formValue.completed:
					continue;
				case field.value === "parentId":
					continue;
				case field.value === "ownerId":
					continue;
				case field.value.startsWith("custom"):
					continue;
				default:
					if (!formValueKeys.includes(field.value)) {
						return true;
					}
			}
		}
		return false;
	}, [addFlag, fieldList, formValue, updateFlag]);

	return isLoading;
};

export default useFormFieldsLoading;
