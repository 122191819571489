import FormSelect from "@clearpoint/old-theme/Form/Select/FormSelect";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useEffectOnce } from "@clearpoint/hooks";

let propTypes = {
	scorecardFlag: PropTypes.bool,
	milestoneFlag: PropTypes.bool,
	mapFlag: PropTypes.bool,
};
let SelectReferenceLayoutObjectType = (props) => {
	let translate = useTranslate();
	let { get, getPromise } = useOldQueryStore();
	let { setFormValue } = useFormContext();
	let { scorecardId } = useOldSession().session;
	let layoutList = get({ object: "allLayout", scorecardId });
	let referenceLayoutId = useFormValue("referenceLayoutId");
	useEffectOnce(() => {
		if (referenceLayoutId) {
			getPromise({ object: "allLayout", scorecardId }).then((layoutList) => {
				setFormValue(props.name, layoutList?.find((x) => x.layoutId === referenceLayoutId)?.object);
			});
		}
	});
	let objectTypeOptions = useMemo(() => {
		if (!layoutList) return;
		let objectTypeList = layoutList?.reduce((objectTypeList, layout) => {
			if (!objectTypeList.includes(layout.object)) return [...objectTypeList, layout.object];
			return objectTypeList;
		}, []);
		return objectTypeList.map((x) => ({
			label: translate(x + "s"),
			value: x,
		}));
	}, [layoutList, translate]);
	return <FormSelect options={objectTypeOptions} {...props} />;
};
SelectReferenceLayoutObjectType.propTypes = propTypes;

export default SelectReferenceLayoutObjectType;
