import PropTypes from "prop-types";

import Block from "../Block";
import { theme } from "../Theme";

let propTypes = {
	maximizeFlag: PropTypes.bool,
	size: PropTypes.oneOf(["medium", "large"]),
};
let formCss = `
& form {
	height: 100%;
	display: flex;
	flex-direction: column;
}`;
let WindowContainer = ({ maximizeFlag, size, ...props }) => (
	<Block
		backgroundColor={theme.backgroundGrey}
		borderRadius="3px"
		boxShadow="0 1px 3px rgba(0, 0, 0, 0.3)"
		display="flex"
		flex="0"
		flexDirection="column"
		fontSize="16px"
		height="100%"
		minWidth={maximizeFlag ? "100%" : size === "large" ? "90%" : size === "medium" ? "80%" : null}
		outline="0 none"
		transition="all 250ms ease-out"
		$style={formCss}
		$transform={maximizeFlag ? "translate(0px, 0px) !important" : undefined}
		{...props}
	/>
);
WindowContainer.propTypes = propTypes;

export default WindowContainer;
