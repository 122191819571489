import AddActionItemButton from "./AddActionItemButton";
import Block from "../../Block";
import Button from "../../Button/Button";
import ChatMessage from "./ChatMessage";
import Icon from "../../Icon/Icon";
import Input from "../../Form/Input/Input";
import { useAIAssistant } from "../AIAssistantProvider/AIAssistantProvider";
import { useCallback, useMemo } from "react";
import useReceiveResponseMessage from "./useReceiveResponseMessage";
import useSubmitUserMessage from "./useSubmitUserMessage";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { useEffectOnce, useQueryParameters, useStateObject } from "@clearpoint/hooks";

let addActionItemsObjectList = ["objective", "initiative", "measure"];

let propTypes = {
	milestonesFlag: PropTypes.bool,
	setAiAddActionItemsModalState: PropTypes.func,
	template: PropTypes.string,
};

let AIChat = ({ milestonesFlag, setAiAddActionItemsModalState, template }) => {
	let translate = useTranslate();
	let { object } = useQueryParameters();
	let aiAddActionItemsFlag = useMemo(
		() => addActionItemsObjectList.includes(object) && !milestonesFlag,
		[milestonesFlag, object]
	);

	let [
		{
			awaitingNewAIResponseFlag,
			awaitingUserResubmitFlag,
			chatEnabledFlag,
			currentChatMessage,
			receivingAIResponseFlag,
		},
		setChatState,
	] = useStateObject({
		awaitingNewAIResponseFlag: false,
		awaitingUserResubmitFlag: false,
		chatEnabledFlag: true,
		currentChatMessage: null,
		receivingAIResponseFlag: false,
	});

	let {
		assistantState: { initialResponseReceivedFlag, messageList, validatingFlag },
		sendMessageToGPT,
		setAssistantState,
		startImmediatelyFlag,
	} = useAIAssistant();

	useEffectOnce(() => {
		if (startImmediatelyFlag) {
			setChatState({
				awaitingNewAIResponseFlag: true,
				awaitingUserResubmitFlag: false,
				currentChatMessage: {
					content: "...",
					role: "assistant",
				},
			});
		}
	});

	let generateNewResponse = useCallback(
		async (index) => {
			setAssistantState({
				messageList: (messageList) => [...messageList.slice(0, -1)],
			});
			sendMessageToGPT("", index);
			setChatState({
				awaitingNewAIResponseFlag: true,
				awaitingUserResubmitFlag: false,
				currentChatMessage: {
					content: "...",
					role: "assistant",
				},
			});
		},
		[sendMessageToGPT, setAssistantState, setChatState]
	);

	let restartChatFromMessage = useCallback(
		(messageIndex) => {
			setAssistantState({
				messageList: (messageList) => [...messageList.slice(0, messageIndex)],
				startIndex: 0,
			});
			setChatState({
				awaitingUserResubmitFlag: true,
				chatEnabledFlag: true,
				currentChatMessage: null,
			});
			sendMessageToGPT("save-cps-chat", messageIndex);
		},
		[sendMessageToGPT, setAssistantState, setChatState]
	);

	let submitUserMessage = useSubmitUserMessage({ setChatState });

	const handlePressEnter = useCallback(
		async (e) => {
			if (e.key === "Enter" && chatEnabledFlag) {
				e.preventDefault();
				await submitUserMessage(e);
			}
		},
		[chatEnabledFlag, submitUserMessage]
	);

	let handleAfterSubmitAddActionItems = useCallback(
		({ aiAddActionItemsName, completedMessage, editMultipleActionItemsModalVisible }) => {
			let nextChatMessage = {
				content: completedMessage,
				role: "assistant",
			};
			setAiAddActionItemsModalState({
				aiAddActionItemsName,
				editMultipleActionItemsModalVisible,
			});
			setChatState({
				awaitingNewAIResponseFlag: false,
				awaitingUserResubmitFlag: false,
				chatEnabledFlag: true,
				currentChatMessage: nextChatMessage,
			});
		},
		[setAiAddActionItemsModalState, setChatState]
	);

	useReceiveResponseMessage({
		awaitingUserResubmitFlag,
		awaitingNewAIResponseFlag,
		receivingAIResponseFlag,
		setChatState,
	});

	return useMemo(
		() => (
			<Block padding="1rem 0" className="ai-chat">
				<Block>
					{messageList.map((x, i) => (
						<ChatMessage
							key={i}
							message={x}
							index={i}
							generateNewResponse={generateNewResponse}
							mostRecentMessageFlag={i === messageList.length - 1 && messageList.length > 1}
							restartChatFromMessage={restartChatFromMessage}
							waitForAIResponseFlag={receivingAIResponseFlag || awaitingNewAIResponseFlag || false}
							aiAddActionItemsFlag={aiAddActionItemsFlag}
							milestonesFlag={milestonesFlag}
						/>
					))}
				</Block>
				{currentChatMessage && !awaitingUserResubmitFlag && (
					<ChatMessage
						streamFlag={!validatingFlag && !awaitingNewAIResponseFlag}
						validatingFlag={validatingFlag || currentChatMessage.role === "validation"}
						message={currentChatMessage}
						waitForAIResponseFlag={false}
						aiAddActionItemsFlag={aiAddActionItemsFlag}
						milestonesFlag={milestonesFlag}
					/>
				)}
				{initialResponseReceivedFlag && (
					<Input
						marginBottom="0"
						placeholder={translate("global.messagePlaceholder")}
						name="userChatMessage"
						onKeyDown={handlePressEnter}
						disabled={awaitingNewAIResponseFlag || receivingAIResponseFlag}
						right={
							<Button
								disabled={!chatEnabledFlag || validatingFlag}
								color="primary"
								onClick={chatEnabledFlag ? submitUserMessage : null}
							>
								<Icon name="send" />
							</Button>
						}
					/>
				)}
				{aiAddActionItemsFlag && template === "recommendEngine" && (
					<AddActionItemButton handleAfterSubmitAddActionItems={handleAfterSubmitAddActionItems} />
				)}
			</Block>
		),
		[
			aiAddActionItemsFlag,
			awaitingNewAIResponseFlag,
			awaitingUserResubmitFlag,
			chatEnabledFlag,
			currentChatMessage,
			generateNewResponse,
			handleAfterSubmitAddActionItems,
			handlePressEnter,
			initialResponseReceivedFlag,
			messageList,
			milestonesFlag,
			receivingAIResponseFlag,
			restartChatFromMessage,
			submitUserMessage,
			template,
			translate,
			validatingFlag,
		]
	);
};

AIChat.propTypes = propTypes;
export default AIChat;
