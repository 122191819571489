import { useCallback, useEffect, useRef } from "react";
import { emptyObject } from "@clearpoint/utils";

let useResizeChart = (element) => {
	let resizeOnContainerChange = useCallback(
		(chart) => {
			chart = chart || element?.chart;
			if (!chart || !element) return;
			let container = element.container.current;
			let { chartHeight, chartWidth } = chart || emptyObject;
			let { offsetHeight: containerHeight, offsetWidth: containerWidth } = container || emptyObject;
			if (chartHeight !== containerHeight || containerWidth !== chartWidth) {
				chart.reflow();
			}
		},
		[element]
	);
	let resizeRef = useRef(resizeOnContainerChange);
	resizeRef.current = resizeOnContainerChange;
	useEffect(() => {
		let resizeObserver = new ResizeObserver(() => resizeRef.current());
		let container = element?.container?.current;
		if (container) {
			resizeObserver.observe(container);
		}
		return () => resizeObserver.disconnect();
	}, [element, resizeOnContainerChange]);
	return resizeOnContainerChange;
};

export default useResizeChart;
