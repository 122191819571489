import React from "react";

let setInitialSelectedTab = ({ children, setSelectedTab }) => {
	let index = React.Children.toArray(children)
		.filter((x) => x)
		.findIndex((x) => x?.props && (Object.keys(x.props).includes("visible") ? x.props.visible : true));
	if (![-1, 0].includes(index)) setSelectedTab(index);
};

export default setInitialSelectedTab;
