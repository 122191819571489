import FormSelect from "../../Form/Select/FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	defaultFlag: PropTypes.bool,
};

let FormSelectLegendPosition = ({ defaultFlag, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(() => {
		let options = [
			{ value: "", label: translate("edit.chartSeries.chartDefault") },
			{
				value: "bottomRight",
				label: translate("edit.chart.legend.bottomRight"),
			},
			{
				value: "bottomCenter",
				label: translate("edit.chart.legend.bottomCenter"),
			},
			{
				value: "bottomLeft",
				label: translate("edit.chart.legend.bottomLeft"),
			},
			{ value: "right", label: translate("edit.chart.legend.right") },
			{ value: "left", label: translate("edit.chart.legend.left") },
			{
				value: "topRight",
				label: translate("edit.chart.legend.topRight"),
			},
			{
				value: "topCenter",
				label: translate("edit.chart.legend.topCenter"),
			},
			{
				value: "topLeft",
				label: translate("edit.chart.legend.topLeft"),
			},
		];
		if (defaultFlag)
			options = [
				...options.slice(0, 1),
				{
					value: "remove",
					label: translate("options.admin.charts.disableLegends"),
				},
				...options.slice(1),
			];

		return options;
	}, [defaultFlag, translate]);
	return <FormSelect options={options} {...props} />;
};

FormSelectLegendPosition.propTypes = propTypes;

export default FormSelectLegendPosition;
