import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import Image from "@clearpoint/old-theme/Image";
import Loading from "@clearpoint/old-theme/Loading/index";
import PageTitle from "@clearpoint/old-theme/PageTitle/PageTitle";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import PropTypes from "prop-types";
import { getTranslate } from "@clearpoint/translate";
import { gettingStartedImage } from "@clearpoint/assets";
import resetSession from "./resetSession";
import { theme } from "@clearpoint/old-theme/Theme";
import { refreshPage } from "@clearpoint/utils";


let recursionErrorMessageList = ["maximum call stack size exceeded", "too much recursion"];

let propTypes = {
	error: PropTypes.object,
	resetError: PropTypes.func.isRequired,
};
let ErrorPage = ({ error, resetError }) => {
	console.log(error);
	let translate = getTranslate();
	let networkErrorFlag = error?.message?.includes("Too many broken requests.");
	let firefoxFlag = navigator.userAgent.toLowerCase().includes("firefox");
	let importErrorList = ["dynamically imported module", "Unable to preload CSS"];
	let moduleLoadErrorFlag = importErrorList.some((e) => error?.message?.includes(e));
	if (moduleLoadErrorFlag) {
		// logic replaces webpack plugin, no similar option for vite https://github.com/vitejs/vite/issues/11804
		localStorage.setItem("outdatedVersionFlag", true);
		window.location.reload();
	}
	let recursionErrorFlag = recursionErrorMessageList.some((x) => error?.message?.toLowerCase().includes(x));
	let errorMessage = translate(
		networkErrorFlag
			? "error.networkMessage"
			: recursionErrorFlag && firefoxFlag
			? "error.fireFox.recursion"
			: "error.message"
	);
	let reset = useCallback(() => {
		resetSession();
		resetError();
		window.history.pushState({}, "", "#/index/home");
	}, [resetError]);

	return moduleLoadErrorFlag ? (
		<Loading />
	) : (
		<Block
			backgroundColor={theme.backgroundGrey}
			color={theme.darkGray}
			textAlign="center"
			height="100%"
			display="flex"
			alignItems="center"
			flexDirection="column"
			paddingTop="50px"
		>
			<StyleWrapper
				alignSelf="center !important"
				flex="0 !important"
				$style={`* { font-weight: ${theme.bold} !important`}
			>
				<PageTitle title={translate("global.error")} />
			</StyleWrapper>
			<Block maxHeight="75%" maxWidth="450px">
				<Image
					objectFit="contain"
					src={gettingStartedImage}
					alt={translate("global.gettingStarted")}
					width="100%"
				/>
			</Block>
			<Block fontWeight={theme.bold} fontSize="14px" marginBottom={theme.smallSpace} lineHeight="2">
				{errorMessage}
				<br />
				{!(recursionErrorFlag && firefoxFlag) && translate("error.contactSupport")}
			</Block>

			<Block display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
				<StyleWrapper margin={theme.tinySpace}>
					<Button color="success" onClick={refreshPage}>
						{translate("error.refreshPage")}
					</Button>
				</StyleWrapper>
				<StyleWrapper margin={theme.tinySpace}>
					<Button color="warning" onClick={reset}>
						{translate("error.reset")}
					</Button>
				</StyleWrapper>
			</Block>
		</Block>
	);
};
ErrorPage.propTypes = propTypes;
export default ErrorPage;
