import { useOldQueryStore } from "@clearpoint/old-query-store";

import { useOldSession } from "@clearpoint/old-session/index";

let useNearestPeriodId = (parameters) => {
	let { get } = useOldQueryStore();
	let { session } = useOldSession();
	let scorecardId = parameters?.scorecardId ?? session.scorecardId;
	let referencePeriodId = parameters?.periodId ?? session.periodId;
	let returnValuePeriodId;
	let periodList = get({ object: "period" });
	if (periodList) {
		let referencePeriodDate = periodList.find((x) => x.periodId === referencePeriodId)?.periodDate;
		for (const x of periodList.filter((x) => x.scorecardList.length === 0 || x.scorecardList.includes(scorecardId))) {
			if (x.periodDate <= referencePeriodDate) {
				returnValuePeriodId = x.periodId;
			}
		}
	}
	return returnValuePeriodId;
};
export default useNearestPeriodId;
