let constrainGridHeight = ({
	data,
	exportFlag,
	get,
	headerHeight,
	limitRowCountFlag,
	overflowXFlag,
	scrollbarWidth,
}) => {
	if (!limitRowCountFlag || exportFlag) return undefined;

	let options = get({ object: "options" });
	let maxRows = options?.options?.gridRowCount || 0;

	let constrainedHeight;
	if (maxRows <= data.length && maxRows !== 0) {
		let tBodyHeight = maxRows * 23 + 2;
		constrainedHeight = tBodyHeight + headerHeight + (overflowXFlag ? scrollbarWidth : 0) + "px";
	}

	return constrainedHeight;
};

export default constrainGridHeight;
