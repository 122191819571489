import FormSelect from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";

let SelectTokenType = ({ scorecardId, ...props }) => {
	let translate = useTranslate();
	let options = useMemo(
		() => [
			{ value: "scorecard", label: translate("scorecard"), group: translate("global.element") },
			{ value: "objective", label: translate("objective"), group: translate("global.element") },
			{ value: "measure", label: translate("measure"), group: translate("global.element") },
			{ value: "chart", label: translate("chart"), group: translate("global.element") },
			{ value: "measureSeries", label: translate("measureSeries"), group: translate("global.element") },
			{ value: "initiative", label: translate("initiative"), group: translate("global.element") },
			{ value: "milestone", label: translate("milestone"), group: translate("global.element") },
			{ value: "actionItem", label: translate("actionItem"), group: translate("global.element") },
			{ value: "risk", label: translate("risk"), group: translate("global.element") },
			{
				value: "scorecardLayout",
				label: `${translate("scorecard")} ${translate("global.report")}`,
				group: translate("global.summaryReport"),
			},
			{ value: "map", label: translate("map"), group: translate("global.summaryReport") },
			{
				value: "objectiveLayout",
				label: `${translate("objective")} ${translate("global.report")}`,
				group: translate("global.summaryReport"),
			},
			{
				value: "measureLayout",
				label: `${translate("measure")} ${translate("global.report")}`,
				group: translate("global.summaryReport"),
			},
			{
				value: "initiativeLayout",
				label: `${translate("initiative")} ${translate("global.report")}`,
				group: translate("global.summaryReport"),
			},
			{
				value: "actionItemLayout",
				label: `${translate("actionItem")} ${translate("global.report")}`,
				group: translate("global.summaryReport"),
			},
			{
				value: "riskLayout",
				label: `${translate("risk")} ${translate("global.report")}`,
				group: translate("global.summaryReport"),
			},
		],
		[translate]
	);
	return (
		<FormSelect
			label={translate("customField.tokenType")}
			options={options}
			placeholder={translate("customField.selectTokenType")}
			{...props}
		/>
	);
};
export default SelectTokenType;
