import Badge from "@clearpoint/old-theme/Badge/Badge";
import Block from "@clearpoint/old-theme/Block";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import FormSelectStatus from "@clearpoint/old-theme/Form/Select/Select.Status";
import StatusIcon from "@clearpoint/old-theme/StatusIcon";
import { theme } from "@clearpoint/old-theme/Theme";

import { useCheckAccess } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

let HeaderFields = () => {
	let translate = useTranslate();
	let { access, autoEvaluation: elementAutoEvaluationFlag, object, scorecardId } = useFormValue();
	let {
		autoEvaluation: elementStatusAutoEvaluationFlag,
		automaticEvaluation: automaticEvaluationFlag,
		evaluated: evaluatedFlag,
		periodLocked: periodLockedFlag,
		statusId,
	} = useFormValue("updates") || emptyObject;

	let checkAccess = useCheckAccess();
	let editAccessFlag = checkAccess({ access, action: "edit", scorecardId });
	let updateAccessFlag = checkAccess({ access, action: "update", scorecardId });

	let allowStatusUpdateFlag =
		updateAccessFlag &&
		[
			automaticEvaluationFlag,
			elementAutoEvaluationFlag,
			elementStatusAutoEvaluationFlag,
			evaluatedFlag,
			periodLockedFlag,
		].every((x) => !x);

	return (
		<Block display="flex" alignItems="center" flex="1">
			{!allowStatusUpdateFlag && (
				<Block marginRight={theme.smallSpace}>
					<StatusIcon statusId={statusId} size="large" />
					<Badge color="primary" size="small">
						{translate("edit.evaluated")}
					</Badge>
				</Block>
			)}
			<Block flex="1">
				<FormInput
					name="name"
					maxLength={500}
					marginBottom="0"
					disabled={!editAccessFlag}
					placeholder={translate("error.required")}
					required
					left={
						allowStatusUpdateFlag && (
							<FormSelectStatus
								name="updates.statusId"
								width="55px"
								iconOnlyFlag
								inputGroupFlag
								object={object}
							/>
						)
					}
				/>
			</Block>
		</Block>
	);
};

export default HeaderFields;
