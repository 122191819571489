import { useCallback } from "react";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import getSubmitMilestoneGanttData from "./useSubmitMilestoneGanttData.getSubmitMilestoneGanttData";

let useSubmitMilestoneGanttData = () => {
	let { set } = useOldQueryStore();
	let { pagePeriodId } = useLocalState();

	return useCallback(
		({ ganttData, formDefaultValue }) =>
			getSubmitMilestoneGanttData({ formDefaultValue, ganttData, pagePeriodId, set }),
		[pagePeriodId, set]
	);
};

export default useSubmitMilestoneGanttData;
