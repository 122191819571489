import { useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { emptyObject } from "@clearpoint/utils";
import { useFormatChart } from "@clearpoint/hooks";


let useSingleSeriesKpiChartData = (chart) => {
	let { get } = useOldQueryStore();
	let formatChart = useFormatChart();
	let { color1, color2, chartType, measureId, measureSeriesId, title } = chart || emptyObject;
	let periodList = get({ object: "period" });

	let measureGridList =
		measureId &&
		get({ parent: "measure", parentId: measureId, object: "measureGrid" })
			?.map((x) => ({ ...x, ["series" + measureSeriesId]: x["series" + measureSeriesId] || 0 }))
			.filter((x) => x !== null && x !== undefined);

	let primaryMeasureSeriesNumberFormat =
		measureSeriesId && get({ object: "measureSeries", objectId: measureSeriesId })?.numberFormat;

	let measureGridValueList = useMemo(
		() =>
			measureGridList?.map((x) => x["series" + measureSeriesId]).filter((x) => x !== null && x !== undefined) ?? [],
		[measureGridList, measureSeriesId]
	);

	let preformattedChartData = useMemo(() => {
		let chartFormatData = {
			chartFormat: {
				chart: { height: 100, spacingBottom: 5, spacingLeft: 0, spacingRight: 0, spacingTop: -23, type: null },
				colors: [color1],
				credits: { enabled: false },
				plotOptions: {
					area: {
						marker: {
							enabled: false,
						},
					},
				},
				series: [
					{
						color: color1,
						measureSeriesId,
						name: title,
						periodOffset: 0,
						yAxis: 0,
						zIndex: 999,
					},
				],
				title: {
					text: "",
				},
				xAxis: {
					visible: false,
				},
				yAxis: [
					{
						alignTicks: true,
						endOnTick: true,
						max: Math.max(...measureGridValueList) + 1,
						maxPadding: 0,
						min: Math.min(...measureGridValueList),
						minPadding: 0,
						showLastTick: false,
						title: {
							text: "",
						},
						visible: false,
					},
				],
			},
			chartPeriods: periodList,
			chartType: null,
			colors: [color1],
			exporting: {
				enabled: false,
			},
			name: title,
			measureId,
			reportPeriods: periodList,
			showLegend: false,
			yAxisFormat: primaryMeasureSeriesNumberFormat,
		};

		if (chartType === "line") {
			chartFormatData.chartFormat.chart.type = "area"; // not a typo
			chartFormatData.chartType = 91;
			chartFormatData.chartFormat.series[0].fillColor = color2;
			chartFormatData.chartFormat.series[0].fillOpacity = 1;
		} else if (chartType === "bar") {
			chartFormatData.chartFormat.chart.type = "column"; // not a typo
			chartFormatData.chartType = 75;
			chartFormatData.chartFormat.series[0].groupPadding = 0.005;
		}

		return chartFormatData;
	}, [
		chartType,
		color1,
		color2,
		measureGridValueList,
		measureId,
		measureSeriesId,
		periodList,
		primaryMeasureSeriesNumberFormat,
		title,
	]);

	let formattedChartData =
		measureGridList &&
		primaryMeasureSeriesNumberFormat &&
		formatChart({ chartData: preformattedChartData, measureGridList });

	return formattedChartData;
};

export default useSingleSeriesKpiChartData;
