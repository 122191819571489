import Block from "../../../Block";
import Image from "../../../Image";
import useFormValue from "../../../Form/hooks/useFormValue";
import PropTypes from "prop-types";
import { theme } from "../../../Theme";

let propTypes = {
	className: PropTypes.string,
	displayValue: PropTypes.string,
	name: PropTypes.string,
};

let ImageDisplay = ({ className, displayValue, name }) => {
	let displayFlag = !!useFormValue(name);
	name = name.endsWith(".value") ? `${name.slice(0, -6)}.displayValue` : name;
	let formDisplayValue = useFormValue(name);
	displayValue = displayValue || formDisplayValue;

	return displayFlag ? (
		// Note: in Angular this was wrapped in a Bootstrap row and then in a div with overflow: hidden
		// The row had -15px margin left and right, and the end result was that the images were slightly zoomed in on Angular
		// I talked with Dylan and we decided not to include that in React, so don't waste time "debugging" this to match Angular. --Daniel
		// TODO: Delete these comments after React is migrated
		<Block className={className} textAlign="center" paddingBottom={theme.space}>
			<Image documentSrc={displayValue} alt="Image Update" margin="0px auto" maxWidth="100%" />
		</Block>
	) : (
		<span />
	);
};

ImageDisplay.propTypes = propTypes;
export default ImageDisplay;
